import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, darken, readableColor, shade, tint, transparentize } from '@corti/style';
import { Autocomplete, Base, IconButton, InputAdornment, TextField, Tooltip } from 'lib/cortiUI';
export const TermGroup = observer(function TermGroup(props) {
    const { termGroup, isUnsuccessful, onChangeTerms, onRemoveTermGroup } = props;
    const { t } = useTranslation();
    function removeTermGroup() {
        onRemoveTermGroup(termGroup.id);
    }
    return (React.createElement(Base, { width: "100%", display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", gap: 4 },
        React.createElement(Autocomplete, { "data-cy": "transcript-search-keyword-input", size: "small", value: termGroup.terms.length > 0 ? termGroup.terms : [], multiple: true, freeSolo: true, clearOnBlur: true, clearOnEscape: true, options: [], getOptionLabel: (option) => {
                return option;
            }, onChange: (_event, nextValues) => {
                onChangeTerms(termGroup.id, nextValues);
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { autoFocus: true, error: isUnsuccessful, helperText: isUnsuccessful ? t('searchbar.noSearchResultsFound', 'No results found') : undefined, label: t('transcriptSearchApp:searchKeywords', 'Search keywords'), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { startAdornment: (React.createElement(React.Fragment, null,
                        React.createElement(InputAdornment, { position: "start" },
                            React.createElement(SearchRoundedIcon, { fontSize: "small", sx: { ml: 1 } })),
                        params.InputProps.startAdornment)) }) }))), ChipProps: {
                classes: {
                    root: css({
                        color: readableColor(darken(0.2, termGroup.style.color)),
                        backgroundColor: termGroup.style.color,
                        '&:focus': {
                            backgroundColor: transparentize(0.4, termGroup.style.color),
                        },
                    }),
                    deleteIcon: css({
                        fill: readableColor(darken(0.2, termGroup.style.color), shade(0.6, termGroup.style.color), tint(0.6, termGroup.style.color)),
                    }),
                },
            } }),
        React.createElement(Tooltip, { title: t('remove', 'Remove') },
            React.createElement(IconButton, { size: "small", icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), onClick: removeTermGroup }))));
});
