export function initBackendProxyApi(publicapi, coreApi) {
    publicapi.exposeMethod({
        name: '/backendproxy/cases/ensureCaseCustomProperties',
        paramsJSONSchema: {
            type: 'object',
            required: ['caseID', 'customProperties'],
            properties: {
                caseID: { type: 'string' },
                customProperties: {
                    type: 'object',
                    required: [],
                    additionalProperties: { type: 'string' },
                },
            },
        },
        handler: ({ caseID, customProperties }) => {
            return coreApi.cases.ensureCustomProperties(caseID, customProperties);
        },
    });
}
