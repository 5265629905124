var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { forwardRef, useMemo, useState } from 'react';
import { BaseInputBlock } from './BaseInputBlock';
import { DEBOUNCE_TIMEOUT } from './constants';
import useBlockFocus from './useBlockFocus';
export const TextInputBlock = observer(forwardRef((_a, forwardedRef) => {
    var { value, handleFinalValue, handleTemporaryValue } = _a, rest = __rest(_a, ["value", "handleFinalValue", "handleTemporaryValue"]);
    const [internalValue, setInternalValue] = useState(value);
    const handleTemporaryDebounced = useMemo(() => debounce((value) => handleTemporaryValue(value), DEBOUNCE_TIMEOUT), [handleTemporaryValue]);
    const onChange = (e) => {
        handleTemporaryDebounced(e.target.value);
        setInternalValue(e.target.value);
    };
    const onBlur = () => {
        handleFinalValue(internalValue);
    };
    const containerRef = React.useRef(null);
    useBlockFocus(containerRef, value, setInternalValue);
    return (React.createElement(BaseInputBlock, Object.assign({ value: internalValue, onChange: onChange, onBlur: onBlur, ref: forwardedRef }, rest)));
}));
