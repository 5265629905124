import { useMousetrap } from '@corti/react';
import { coreStore } from 'browser/stores';
const FOCUS_MODE_KEYBOARD_SHORTCUT = 'mod+.';
export function useFocusMode() {
    useMousetrap({
        keys: FOCUS_MODE_KEYBOARD_SHORTCUT,
        handle: () => {
            coreStore.appShell.toggleFocusMode();
        },
    }, []);
    return coreStore.appShell.focusMode;
}
