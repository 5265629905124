import { useMutation, useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Autocomplete, MenuItem, TextField } from 'lib/cortiUI';
import { annotationTypesQuery, hideAnnotationTypesMutation, } from './graphql';
export const HideAnnotationType = () => {
    var _a;
    const { organization } = useAuth();
    const { t } = useTranslation();
    const { data: getAnnotationTypesData, loading: isGetAnnotationTypesLoading } = useQuery(annotationTypesQuery, {
        variables: {
            organizationID: organization.id,
        },
    });
    const [hideAnnotationTypes, { loading: isHideAnnotationTypesLoading }] = useMutation(hideAnnotationTypesMutation);
    const annotationTypes = (_a = getAnnotationTypesData === null || getAnnotationTypesData === void 0 ? void 0 : getAnnotationTypesData.annotationTypes) !== null && _a !== void 0 ? _a : [];
    const hiddenAnnottionTypes = annotationTypes.filter((it) => it.hidden);
    async function handleHiddenAnnotationTypesChange(annotationTypes) {
        await hideAnnotationTypes({
            variables: {
                orgID: organization.id,
                ids: annotationTypes.map((it) => it.id),
            },
            refetchQueries: [
                {
                    query: annotationTypesQuery,
                    variables: {
                        organizationID: organization.id,
                    },
                },
            ],
        });
        coreStore.notifications.showNotification({
            type: 'success',
            message: t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    }
    return (React.createElement(Autocomplete, { multiple: true, disableCloseOnSelect: true, disabled: isGetAnnotationTypesLoading || isHideAnnotationTypesLoading, options: annotationTypes, getOptionLabel: (option) => `${option.objectiveName}:${option.labelName}`, value: hiddenAnnottionTypes, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_, nextValues) => {
            void handleHiddenAnnotationTypesChange(nextValues);
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('adminPanel:orgDataManagement.hiddenAnnotationTypes', 'Hidden annotation types') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), `${option.objectiveName}:${option.labelName}`)), ChipProps: {
            size: 'small',
        } }));
};
