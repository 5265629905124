import React from 'react';
import { useTranslation } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { useRealTimeCtx } from '../../RealTimeView/RealTimeContext';
import { RealTimeError } from '../../errors';
export function useStartSession({ selectedGraphVersionOption }) {
    const { t } = useTranslation();
    const realtime = useRealTimeCtx();
    const [isNewSessionLoading, setIsNewSessionLoading] = React.useState(false);
    async function createNewSession() {
        try {
            if (!(selectedGraphVersionOption === null || selectedGraphVersionOption === void 0 ? void 0 : selectedGraphVersionOption.value)) {
                return;
            }
            setIsNewSessionLoading(true);
            const session = await realtime.createTriageSession({
                graphVersionID: selectedGraphVersionOption.value,
            });
            realtime.openTriageSessionView({ id: session.id });
        }
        catch (err) {
            if (err instanceof RealTimeError) {
                coreStore.notifications.showNotification({
                    permanent: false,
                    message: t('realtime:createSessionError', 'Failed to create new session'),
                    type: 'error',
                    closable: true,
                });
            }
        }
        finally {
            setIsNewSessionLoading(false);
        }
    }
    return { createNewSession, isNewSessionLoading };
}
