import React from 'react';
import { useTranslation } from '@corti/i18n';
import { config as configManager } from 'core/configuration/browser';
import { Base, FallbackView, Loading } from 'lib/cortiUI';
export const AnalyticsApp = () => {
    var _a;
    const DEFAULT_DASHBOARD_SLUG = 'reviews';
    const baseURL = (_a = configManager.getConfig().apiHost) === null || _a === void 0 ? void 0 : _a.replace('api', 'dashboard');
    const fullURL = `${baseURL}/superset/dashboard/${DEFAULT_DASHBOARD_SLUG}?standalone=2&show_filters=1&expand_filters=0`;
    const { t } = useTranslation('analytics');
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        setIsLoading(true);
        fetch(fullURL)
            .then((response) => {
            if (!response.ok) {
                throw new Error(`iframe source - ${fullURL} - is not available`);
            }
        })
            .catch(() => setError(t('contentNotAvailable', 'Requested content is not available')))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullURL]);
    if (isLoading) {
        return (React.createElement(Base, { width: "100%", height: "100vh", display: "grid", alignItems: "center", justifyContent: "center" },
            React.createElement(Loading, { text: t('loading', 'Loading...') })));
    }
    if (error) {
        return React.createElement(FallbackView, { text: error });
    }
    return (React.createElement(Base, null,
        React.createElement("iframe", { style: {
                width: '100%',
                height: '100vh',
                border: 'none',
                overflow: 'hidden',
            }, src: fullURL })));
};
