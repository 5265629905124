import { autorun } from 'mobx';
import React from 'react';
/**
 * Hook for Mobx's `autorun`
 */
export function useAutorunEffect(effect, options) {
    React.useEffect(() => {
        const dispose = autorun(effect, options);
        return () => {
            dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
