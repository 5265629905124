/**
 * Sorts the sessions into a map with keys for Today, Yesterday, 2 days ago, and Older.
 * If the user has graph editor access, all sessions are included.
 * If the user does not have graph editor access, only the 20 most recent sessions or the ones from the past 48 hours are included.
 *
 * @param originalSessions - an array of type RecentSessionWithCall
 * @param shouldLimitSessions - whether the user has graph editor access, if they  the sessions will be limited to 20
 * @returns a map with keys for Today, Yesterday, 2 days ago, and Older - each key maps to an array of sessions
 */
export const buildSortedSessionsMap = (originalSessions, shouldLimitSessions) => {
    const sortedSessionsMap = new Map();
    const [today, yesterday, twoDaysAgo, threeDaysAgo] = generateDays(4);
    const sessions = shouldLimitSessions
        ? limitSessions(originalSessions, threeDaysAgo)
        : originalSessions;
    const { todaySessions, yesterdaySessions, twoDaysAgoSessions, olderSessions } = sessions.reduce((acc, session) => {
        const createdAt = new Date(session.createdAt);
        if (createdAt.getDate() === today.getDate()) {
            acc.todaySessions.push(session);
        }
        else if (createdAt.getDate() === yesterday.getDate()) {
            acc.yesterdaySessions.push(session);
        }
        else if (createdAt.getDate() === twoDaysAgo.getDate()) {
            acc.twoDaysAgoSessions.push(session);
        }
        else {
            acc.olderSessions.push(session);
        }
        return acc;
    }, {
        todaySessions: [],
        yesterdaySessions: [],
        twoDaysAgoSessions: [],
        olderSessions: [],
    });
    if (todaySessions.length > 0) {
        sortedSessionsMap.set('Today', todaySessions);
    }
    if (yesterdaySessions.length > 0) {
        sortedSessionsMap.set('Yesterday', yesterdaySessions);
    }
    if (twoDaysAgoSessions.length > 0) {
        sortedSessionsMap.set('2 days ago', twoDaysAgoSessions);
    }
    if (olderSessions.length > 0) {
        sortedSessionsMap.set('Older', olderSessions);
    }
    return sortedSessionsMap;
};
/**
 * This function limits the sessions to either the 20 most recent sessions or the ones from the past 48 hours.
 * @param sessions - an array of sessions
 * @param cutOffDate - the date by which the sessions should be limited
 * @returns RecentSessionWithCall[] - a list of sessions that are either the 20 most recent sessions or the ones from the past 48 hours
 */
const limitSessions = (sessions, cutOffDate) => {
    // filter out sessions that are older than 2 days
    const filteredSessions = sessions.filter((session) => new Date(session.createdAt).getDate() > cutOffDate.getDate());
    // limit sessions to 20
    const limitedSessions = sessions.slice(0, 20);
    if (filteredSessions.length > limitSessions.length) {
        return filteredSessions;
    }
    else {
        return limitedSessions;
    }
};
/**
 * This function generates an array of dates for the past n days.
 * @param days - the number of days for which to generate dates
 * @returns Date[] - an array of dates for the past n days
 */
export const generateDays = (days) => {
    const daysArray = [];
    for (let i = 0; i < days; i++) {
        const day = new Date();
        if (i > 0) {
            day.setDate(day.getDate() - i);
            day.setHours(0, 0, 0, 0);
        }
        daysArray.push(day);
    }
    return daysArray;
};
