import { BlockValueUtils, FactValueTransformer } from 'lib/triageFlow';
function getBlockType(block) {
    switch (block.type) {
        case 'ACTION':
            return 'action';
        case 'DATE_PICKER':
            return 'date-picker';
        case 'TEXT_INPUT':
            return 'text-input';
        case 'TEXTAREA_INPUT':
            return 'textarea-input';
        case 'NUMBER_INPUT':
            return 'number-input';
        case 'SELECT':
            return block.max === 1 ? 'single-select' : 'multi-select';
    }
}
function extractCommonProperties(sessionController) {
    return {
        created_by: sessionController.currentUser.id,
        session_id: sessionController.id,
        session_started_at: sessionController.startedAt.toISOString(),
        session_external_id: sessionController.externalID,
        session_created_by: sessionController.createdBy.id,
        session_case_id: sessionController.caseID,
        graph_version_id: sessionController.graphVersionID,
    };
}
export const analyticEventTransformer = {
    toActionBlockTriggered(event, sessionController) {
        var _a;
        const { node, branch, source, sourceLocation, blockInstance, blockPrototype } = event.data;
        const blockProperties = blockInstance
            ? {
                block_id: blockInstance.id,
                block_custom_properties: (_a = blockInstance.customProperties) !== null && _a !== void 0 ? _a : [],
            }
            : {
                block_id: blockPrototype.id,
                block_custom_properties: blockPrototype.customProperties,
            };
        return Object.assign(Object.assign(Object.assign({}, extractCommonProperties(sessionController)), { id: event.data.id, datetime: event.data.datetime, type: 'action-block-triggered', node_id: node === null || node === void 0 ? void 0 : node.id, view_id: node === null || node === void 0 ? void 0 : node.view.id, view_name: node === null || node === void 0 ? void 0 : node.view.name, branch_id: branch === null || branch === void 0 ? void 0 : branch.id, branch_name: branch === null || branch === void 0 ? void 0 : branch.name, block_text: blockPrototype.text.plainText, block_key: blockPrototype.name, block_type: 'action', context_type: source === 'integration' ? 'integration' : sourceLocation }), blockProperties);
    },
    toActiveStepChanged(event, sessionController) {
        const { node, branch, source, sourceLocation } = event.data;
        return Object.assign(Object.assign({}, extractCommonProperties(sessionController)), { id: event.data.id, datetime: event.data.datetime, type: 'active-step-changed', node_id: node.id, view_id: node.view.id, view_name: node.view.name, branch_id: branch.id, branch_name: branch.name, context_type: source === 'integration' ? 'integration' : sourceLocation });
    },
    toFactValuesUpdated(event, sessionController) {
        const { source } = event.data;
        const factValues = [];
        for (let it of event.data.factValues) {
            const fact = sessionController.flow.graphTraverser.getFactByID(it.factID);
            if (!fact) {
                sessionController.flow.logger.warn('failed to transform fact values updated event');
                return;
            }
            factValues.push({
                id: fact.id,
                key: fact.name,
                value: FactValueTransformer.toExternal(it.value),
            });
        }
        return Object.assign(Object.assign({}, extractCommonProperties(sessionController)), { id: event.data.id, datetime: event.data.datetime, type: 'fact-values-updated', fact_values: factValues, context_type: source === 'integration' ? 'integration' : undefined });
    },
    toBlockValuesUpdated(event, sessionController) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let blockPrototype = event.data.blockPrototype;
        if (!blockPrototype) {
            const blockPrototypeID = event.data.blockValues[0].identifier.blockPrototypeID;
            blockPrototype = sessionController.flow.graphTraverser.getBlockProtoByID(blockPrototypeID);
        }
        if (!blockPrototype ||
            !(blockPrototype.type === 'DATE_PICKER' ||
                blockPrototype.type === 'NUMBER_INPUT' ||
                blockPrototype.type === 'SELECT' ||
                blockPrototype.type === 'TEXTAREA_INPUT' ||
                blockPrototype.type === 'TEXT_INPUT')) {
            sessionController.flow.logger.warn('failed to transform block values updated event');
            return;
        }
        const blockProperties = event.data.blockInstance
            ? {
                block_id: event.data.blockInstance.id,
                block_custom_properties: (_a = event.data.blockInstance.customProperties) !== null && _a !== void 0 ? _a : [],
            }
            : {
                block_id: blockPrototype.id,
                block_custom_properties: blockPrototype.customProperties,
            };
        const blockValues = event.data.blockValues
            .filter(BlockValueUtils.isTruthy)
            .map((it) => {
            var _a;
            if (blockPrototype.type === 'SELECT') {
                return {
                    optionID: it.identifier.optionID,
                    optionText: (_a = blockPrototype.options.find((o) => o.id === it.identifier.optionID)) === null || _a === void 0 ? void 0 : _a.text.plainText,
                    value: it.value,
                };
            }
            return {
                value: it.value,
            };
        });
        return Object.assign(Object.assign(Object.assign({}, extractCommonProperties(sessionController)), { id: event.data.id, datetime: event.data.datetime, type: 'block-values-updated', node_id: (_b = event.data.node) === null || _b === void 0 ? void 0 : _b.id, view_id: (_d = (_c = event.data.node) === null || _c === void 0 ? void 0 : _c.view) === null || _d === void 0 ? void 0 : _d.id, view_name: (_f = (_e = event.data.node) === null || _e === void 0 ? void 0 : _e.view) === null || _f === void 0 ? void 0 : _f.name, branch_id: (_g = event.data.branch) === null || _g === void 0 ? void 0 : _g.id, branch_name: (_h = event.data.branch) === null || _h === void 0 ? void 0 : _h.name, block_text: blockPrototype.label.plainText, block_key: blockPrototype.name, block_type: getBlockType(blockPrototype), block_values: blockValues, context_type: event.data.source === 'integration' ? 'integration' : event.data.sourceLocation }), blockProperties);
    },
};
