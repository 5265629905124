import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { observer } from 'mobx-react';
import React, { forwardRef, useState } from 'react';
import { convertUtcToZonedTime, convertZonedToUtcTime, getDateFormat, getLocale, getTimeFormat, } from '@corti/date';
import { useLazyRef } from '@corti/react';
import { TextField } from '../TextField';
/**
 *
 * Date picker with optional time component
 */
export const DatePickerWithTime = observer(forwardRef(({ onChange, minDate, maxDate, isDateTime = false, label, selectedDate, isReadonly = false, }, ref) => {
    const currentDate = useLazyRef(() => convertZonedToUtcTime(new Date()));
    maxDate = maxDate || currentDate.current;
    const [open, setOpen] = useState(false);
    function handleDateSelect(value) {
        if (value && Date.parse(value.toDateString()))
            onChange(convertZonedToUtcTime(value));
        if (!value)
            onChange(null);
    }
    const commonProps = {
        inputRef: ref,
        disabled: isReadonly,
        value: selectedDate ? convertUtcToZonedTime(selectedDate) : null,
        renderInput: (params) => React.createElement(TextField, Object.assign({ fullWidth: true }, params, { label: label, onClick: () => setOpen(true) })),
        open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        minDate,
        maxDate,
        onChange: handleDateSelect,
        PopperProps: { placement: 'top-start' },
        disableMaskedInput: true,
        inputFormat: isDateTime ? `${getDateFormat()} ${getTimeFormat()}` : getDateFormat(),
    };
    const Picker = isDateTime ? DateTimePicker : DatePicker;
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: getLocale() },
        React.createElement(Picker, Object.assign({}, commonProps))));
}));
