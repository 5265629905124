var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { createRichText, stringifyRichText } from '@corti/richtext';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { BaseElementModel } from './BaseElementModel';
export class TextElementModel extends BaseElementModel {
    constructor(props = {}) {
        super(Object.assign(Object.assign({}, props), { attributes: Object.assign(Object.assign({}, props.attributes), { collapsable: {
                    type: 'boolean',
                    defaultValue: false,
                    name: 'collapsable',
                } }) }));
        Object.defineProperty(this, "_content", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.type = TextElementModel.typename;
        this.setContent(createRichText());
    }
    setContent(text) {
        if (typeof text === 'string') {
            this._content = createRichText(text);
        }
        else {
            this._content = text;
        }
        return this;
    }
    get content() {
        return this._content;
    }
    get readableTitle() {
        return stringifyRichText(this.content);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { content: this._content });
    }
    deserialize(value) {
        super.deserialize(value);
        this._content = value.content;
    }
    copy() {
        const i = super.copy();
        i.setContent(cloneDeep(this.content));
        return i;
    }
}
Object.defineProperty(TextElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'text'
});
Object.defineProperty(TextElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.textElement.title', 'Paragraph'),
    })
});
Object.defineProperty(TextElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const el = new TextElementModel();
        el.deserialize(val);
        return el;
    }
});
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], TextElementModel.prototype, "_content", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], TextElementModel.prototype, "setContent", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TextElementModel.prototype, "content", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TextElementModel.prototype, "readableTitle", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TextElementModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TextElementModel.prototype, "copy", null);
ElementSerializer.register(TextElementModel, 'TextElementModel');
