import { api } from '@corti/lib/coreApiService';
export function gqlMediaTypeToMediaAsset(input) {
    const base = {
        id: input.id,
        url: api.files.getFileContentsURL(input.file.id, { attachmentFileName: true }),
        name: input.file.name,
        type: input.file.mimeType,
        size: input.file.size,
        createdAt: input.createdAt,
    };
    if (input.file.mimeType.includes('image') && input.file.imageInfo) {
        return Object.assign(Object.assign({ typename: 'image' }, base), { imageInfo: input.file.imageInfo, getThumbnailURL(p) {
                // for now our server side image processor cannot deal with gifs
                if (input.file.mimeType === 'image/gif') {
                    return api.files.getFileContentsURL(input.file.id);
                }
                return api.files.getImageContentsURL(input.file.id, {
                    quality: 85,
                    maxWidth: p.maxWidth,
                    maxHeight: p.maxHeight,
                });
            } });
    }
    return Object.assign({ typename: 'file' }, base);
}
