import { DataObject } from '@mui/icons-material';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Range } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { ColorPicker } from 'lib/cortiUI/components/ColorPicker';
import { Popper } from 'lib/cortiUI/components/Popper';
import { Separator } from 'lib/cortiUI/components/Separator';
import { RichTextEditorPopover } from './RichTextEditorPopover';
import { ToolbarIconButton, ToolbarIconButtonColorSetter, ToolbarTextButton, } from './RichTextEditorToolbarButtons';
import * as Commands from './commands';
import { useRichTextEditor } from './context';
export function RichTextEditorToolbar(props) {
    const isMouseDown = useRef(false);
    const editor = useSlate();
    const richTextEditor = useRichTextEditor();
    const theme = useTheme();
    const selectionSnapshot = useRef(null);
    const [toolbarPosition, setToolbarPosition] = useState(null);
    const [secondaryPopover, setSecondaryPopover] = useState(null);
    const hasSelection = editor.selection && !Range.isCollapsed(editor.selection) && ReactEditor.isFocused(editor);
    const handleMouseUp = useCallback(() => {
        if (!isMouseDown.current) {
            return;
        }
        if (hasSelection) {
            setToolbarPosition(getToolbarAnchor());
        }
        else {
            setToolbarPosition(null);
        }
        isMouseDown.current = false;
    }, [hasSelection]);
    const handleMouseDown = useCallback(() => {
        isMouseDown.current = true;
    }, []);
    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [handleMouseUp, handleMouseDown]);
    // observe selection state change
    // this is for displaying the toolbar when selecting with keyboard
    useEffect(() => {
        if (hasSelection && ReactEditor.isFocused(editor)) {
            if (!isMouseDown.current) {
                setToolbarPosition(getToolbarAnchor());
            }
        }
        if (!hasSelection) {
            setToolbarPosition(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelection]);
    function getToolbarAnchor() {
        const selection = window.getSelection();
        if (!selection) {
            return;
        }
        const rect = selection.getRangeAt(0).getBoundingClientRect();
        return {
            getBoundingClientRect: () => rect,
        };
    }
    /**
     * Returns an anchor point position, to which the popover can attach
     */
    function getSelectionRectAnchorPoint() {
        const selection = window.getSelection();
        if (!selection) {
            return;
        }
        const rect = selection.getRangeAt(0).getBoundingClientRect();
        return {
            top: rect.top + rect.height,
            left: rect.left,
        };
    }
    return (React.createElement(React.Fragment, null,
        toolbarPosition && (React.createElement(Popper, { open: Boolean(toolbarPosition), anchorEl: toolbarPosition, placement: "top", style: {
                zIndex: theme.zIndex.tooltip,
            } },
            React.createElement(Base, { m: 4, display: 'grid', gridAutoFlow: "column", gap: 3, py: 2, px: 5, background: theme.palette.background.tooltip, borderRadius: 20 },
                React.createElement(ToolbarTextButton, { isActive: Commands.isBlockActive(editor, 'h1'), onClick: () => {
                        Commands.toggleHeading1(editor);
                    } }, 'H1'),
                React.createElement(ToolbarTextButton, { isActive: Commands.isBlockActive(editor, 'h2'), onClick: () => {
                        Commands.toggleHeading2(editor);
                    } }, 'H2'),
                React.createElement(Separator, null),
                React.createElement(ToolbarIconButton, { icon: React.createElement(FormatBoldRoundedIcon, { fontSize: "small" }), isActive: Commands.isMarkActive(editor, 'bold'), onClick: () => {
                        Commands.toggleBold(editor);
                    } }),
                React.createElement(ToolbarIconButton, { icon: React.createElement(FormatItalicRoundedIcon, { fontSize: "small" }), isActive: Commands.isMarkActive(editor, 'italic'), onClick: () => {
                        Commands.toggleItalic(editor);
                    } }),
                React.createElement(ToolbarIconButton, { icon: React.createElement(FormatUnderlinedRoundedIcon, { fontSize: "small" }), isActive: Commands.isMarkActive(editor, 'underline'), onClick: () => {
                        Commands.toggleUnderline(editor);
                    } }),
                React.createElement(ToolbarIconButton, { icon: React.createElement(StrikethroughSRoundedIcon, { fontSize: "small" }), isActive: Commands.isMarkActive(editor, 'strikethrough'), onClick: () => {
                        Commands.toggleStrikethrough(editor);
                    } }),
                React.createElement(Separator, null),
                React.createElement(ColorPicker, { onColorChange: (color) => {
                        Commands.setFillColor(editor, color);
                    }, 
                    // so that editor doesn't loose focus
                    onMouseDown: (e) => e.preventDefault(), disableAutoFocus: true }, ({ getTriggerProps }) => (React.createElement(ToolbarIconButtonColorSetter, Object.assign({ icon: React.createElement(BorderColorRoundedIcon, { fontSize: "small" }), color: Commands.getFillColor(editor) }, getTriggerProps())))),
                React.createElement(Separator, null),
                !props.disableMeta && (React.createElement(React.Fragment, null,
                    React.createElement(ToolbarIconButton, { icon: React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" }), isActive: Commands.isMarkActive(editor, 'meta'), onClick: () => {
                            Commands.toggleMetaText(editor);
                        } }),
                    React.createElement(Separator, null))),
                React.createElement(ToolbarIconButton, { icon: React.createElement(FormatListNumberedRoundedIcon, { fontSize: "small" }), isActive: Commands.isBlockActive(editor, 'ol'), onClick: () => {
                        Commands.toggleNumberedList(editor);
                    } }),
                React.createElement(ToolbarIconButton, { icon: React.createElement(FormatListBulletedRoundedIcon, { fontSize: "small" }), isActive: Commands.isBlockActive(editor, 'ul'), onClick: () => {
                        Commands.toggleBulletList(editor);
                    } }),
                React.createElement(Separator, null),
                !props.disableNotes && (React.createElement(ToolbarIconButton, { icon: React.createElement(ChatBubbleRoundedIcon, { fontSize: "small" }), isActive: Commands.isBlockActive(editor, 'hasnote'), onClick: () => {
                        selectionSnapshot.current = editor.selection;
                        setToolbarPosition(null);
                        setSecondaryPopover({
                            anchorPosition: getSelectionRectAnchorPoint(),
                            name: 'note',
                        });
                    } })),
                !props.disableLinks && (React.createElement(ToolbarIconButton, { icon: React.createElement(InsertLinkRoundedIcon, { fontSize: "small" }), isActive: Commands.isBlockActive(editor, 'link'), onClick: () => {
                        if (Commands.isBlockActive(editor, 'link')) {
                            Commands.removeLink(editor);
                            return;
                        }
                        selectionSnapshot.current = editor.selection;
                        setToolbarPosition(null);
                        setSecondaryPopover({
                            anchorPosition: getSelectionRectAnchorPoint(),
                            name: 'link',
                        });
                    } })),
                !props.disableExpressions && (React.createElement(ToolbarIconButton, { icon: React.createElement(DataObject, { fontSize: "small" }), isActive: Commands.isBlockActive(editor, 'hasexpression'), onClick: () => {
                        if (Commands.isBlockActive(editor, 'hasexpression')) {
                            Commands.removeExpression(editor);
                            return;
                        }
                        selectionSnapshot.current = editor.selection;
                        setToolbarPosition(null);
                        setSecondaryPopover({
                            anchorPosition: getSelectionRectAnchorPoint(),
                            name: 'expression',
                        });
                    } }))))),
        React.createElement(RichTextEditorPopover, { editor: editor, richTextEditor: richTextEditor, secondaryPopover: secondaryPopover, selectionSnapshot: selectionSnapshot, setSecondaryPopover: setSecondaryPopover })));
}
