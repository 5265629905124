import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Collapse, Typography } from 'lib/cortiUI';
import { SingleProperty } from './SingleProperty';
export const ExpandableProperties = ({ title, items }) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "auto 1fr", gap: 3, height: "100%", overflow: "hidden" },
        React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", onClick: () => setIsExpanded((current) => !current) },
            React.createElement(Typography, { variant: "body2", color: "default" }, title),
            isExpanded ? React.createElement(ExpandLessIcon, { fontSize: "small" }) : React.createElement(ExpandMoreIcon, { fontSize: "small" })),
        React.createElement(Collapse, { in: isExpanded, className: getScrollerCss({ theme, overflowHidden: 'none' }) },
            React.createElement(Base, { display: "grid", gap: 4 }, items.map((props) => (React.createElement(SingleProperty, Object.assign({ key: `${props.title}${props.value}` }, props))))))));
};
