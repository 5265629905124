function filterMatch(checklist, searchInputValue) {
    if (!searchInputValue) {
        return true;
    }
    const match = (val) => val === null || val === void 0 ? void 0 : val.toLowerCase().includes(searchInputValue);
    if (match(checklist.name) || match(checklist.description)) {
        return true;
    }
    return checklist.entries.some((entry) => match(entry.label) || match(entry.details));
}
export const createChecklistSlice = (set, get) => ({
    currentChecklist: null,
    checklistRows: [],
    checklists: [],
    setChecklists: (checklists) => {
        set(() => ({ checklists }));
        get().calculateRows();
    },
    calculateRows: () => {
        const { checklists, searchValue, validate } = get();
        set({
            checklistRows: checklists
                .filter((checklist) => filterMatch(checklist, searchValue))
                .map((checklist) => {
                const { id, name, key, entries, visibilityConditionEnabled, visibilityConditionExpression, } = checklist;
                const validationResult = validate(checklist);
                const validation = validationResult.issues.length
                    ? validationResult.getIssuesBySeverity('error').length
                        ? 'error'
                        : 'warning'
                    : 'valid';
                return {
                    id,
                    name,
                    key,
                    entryCount: entries.length,
                    visibility: visibilityConditionEnabled ? visibilityConditionExpression : null,
                    validation,
                };
            }),
        });
    },
});
