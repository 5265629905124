import { ArrowForward, MoreHoriz, Phone, PhoneOutlined } from '@mui/icons-material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { Base, Button, Card, IconButton, Menu, MenuItem, Tooltip, Typography } from 'lib/cortiUI';
const SessionItemCard = ({ id, caseID, externalID, createdAt, call }) => {
    var _a;
    const { t } = useTranslation('realtimeApp');
    const theme = useTheme();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const handleOpenMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleCopyLink = () => {
        void navigator.clipboard.writeText(`${window.location.origin}/real-time/${id}`);
        setShowTooltip(true);
    };
    return (React.createElement(Card, { paddingY: 24, paddingX: 23, display: "flex", flexDirection: "column", alignItems: "flex-start", height: 240, width: 360, borderRadius: 18, position: "relative" },
        React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: handleOpenMenu, className: css({
                position: 'absolute',
                right: '16px',
                top: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }), icon: React.createElement(MoreHoriz, { width: 16, height: 16 }) }),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
            rbacService.hasPermission('app:review') && (React.createElement(Link, { to: `/explore/${caseID}`, className: css({
                    pointerEvents: caseID ? 'all' : 'none',
                }) },
                React.createElement(MenuItem, { "data-cy": "open-session-in-mission-control-button", className: css({ opacity: caseID ? 1 : 0.38 }) },
                    React.createElement(Typography, { variant: "body1" }, t('landingScreen.recentSessionItem.openInMissionControl', 'Open in Mission Control'))))),
            React.createElement(Tooltip, { disableInteractive: true, placement: "top", title: t('landingScreen.recentSessionItem.linkCopied', 'Link is Copied'), leaveDelay: 1000, open: showTooltip, onClose: () => setShowTooltip(false) },
                React.createElement(MenuItem, { "data-cy": "copy-session-link-button", onClick: handleCopyLink, display: "flex", gap: 16 },
                    React.createElement(Typography, { variant: "body1" }, t('landingScreen.recentSessionItem.copyLink', 'Copy link')),
                    React.createElement(ContentCopyRoundedIcon, { fontSize: "small" })))),
        React.createElement(Typography, { variant: "body2", uppercase: true }, t('landingScreen.recentSessionItem.label', 'session')),
        React.createElement(Typography, { variant: "body2", fontWeight: 400, color: "default", className: css({
                fontSize: '1.75rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
            }) }, (_a = externalID === null || externalID === void 0 ? void 0 : externalID.split('-')[0]) !== null && _a !== void 0 ? _a : id === null || id === void 0 ? void 0 : id.split('-')[0].toLocaleUpperCase()),
        React.createElement(Typography, { variant: "body1", fontWeight: 400 }, t('landingScreen.recentSessionItem.startedAt.label', 'Started {{date}}', {
            date: formatDistanceToNow(new Date(createdAt), {
                addSuffix: true,
            }),
        })),
        (call === null || call === void 0 ? void 0 : call.number) && (React.createElement(Base, { borderRadius: 100, paddingY: "2px", paddingX: "8px", marginTop: 12, display: "flex", alignItems: "center", gap: 4, background: (call === null || call === void 0 ? void 0 : call.active) ? theme.palette.primary : 'transparent', border: (call === null || call === void 0 ? void 0 : call.active) ? 'none' : `1px solid ${theme.palette.primary.main}` },
            (call === null || call === void 0 ? void 0 : call.active) ? (React.createElement(Phone, { color: "info", className: css({ width: '12px', height: '12px' }) })) : (React.createElement(PhoneOutlined, { color: "info", className: css({ width: '12px', height: '12px' }) })),
            React.createElement(Typography, { variant: "body1", color: "primary", fontSize: "12px", lineHeight: "18px", fontWeight: 400 }, call === null || call === void 0 ? void 0 : call.number))),
        React.createElement(Link, { to: `/real-time/${id}`, className: css({ marginTop: 'auto', alignSelf: 'end' }) },
            React.createElement(Button, { variant: "text", color: "primary", className: css({ gap: '8px', padding: '8px 11px' }) },
                React.createElement(Typography, { uppercase: true }, t('landingScreen.openSessionBtn', 'Enter Session')),
                React.createElement(ArrowForward, { width: 24, height: 24 })))));
};
export default SessionItemCard;
