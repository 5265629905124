import CropSquareIcon from '@mui/icons-material/CropSquare';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { SmartTarget } from 'lib/graphEditor/ui';
export const ChecklistSmartTarget = observer(function ChecklistSmartTarget({ id, }) {
    const { editor } = useGraphEditorCtx();
    const history = useHistory();
    const checklist = editor.model.checklists.get(id);
    if (!checklist) {
        return React.createElement(SmartTarget, { label: '[missing ref]', color: "error", disabled: true, tooltipTitle: id });
    }
    const label = checklist.name || '[unnamed checklist]';
    const suffix = checklist.key;
    return (React.createElement(SmartTarget, { label: label, suffix: suffix, tooltipTitle: `${label} (${suffix})`, startIcon: React.createElement(CropSquareIcon, { style: { color: 'inherit' } }), onClick: () => {
            // Link directly to checklist edit dialog
            history.push(`/checklists/${id}`);
        } }));
});
