import React from 'react';
import { waveSurferContext } from '../../WaveSurferContext';
import { convertPxToSeconds, convertSecondsToPx, getMouseOffset } from './utils';
export class Region extends React.Component {
    constructor() {
        super(...arguments);
        // In order to correctly prevent several events from bubbling from Region to wavesurfer DOM
        // we have to apply listeners to region DOM element ref instead of React element.
        // Reason: DOM tree events are fired before React tree.
        Object.defineProperty(this, "ref", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "mouseOffsetSnapshot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeResizeHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "wrapperScrollSpeed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 5
        });
        Object.defineProperty(this, "wrapperScrollDirection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                tempStart: this.props.start,
                tempEnd: this.props.end !== undefined ? this.props.end : this.props.start,
            }
        });
        Object.defineProperty(this, "onMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const offsetFromAudio = getMouseOffset(this.wavesurfer, e);
                const offsetFromRegionStart = offsetFromAudio - this.state.tempStart;
                const offsetFromRegionEnd = this.state.tempEnd - offsetFromAudio;
                this.mouseOffsetSnapshot = {
                    fromAudio: offsetFromAudio,
                    fromRegionStart: offsetFromRegionStart,
                    fromRegionEnd: offsetFromRegionEnd,
                };
            }
        });
        Object.defineProperty(this, "onDragHandleMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.onMouseDown(e);
            }
        });
        Object.defineProperty(this, "onResizeHandleMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.activeResizeHandle = e.currentTarget.dataset.regionResizeHandle;
                this.onMouseDown(e);
            }
        });
        Object.defineProperty(this, "onMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.mouseOffsetSnapshot) {
                    const wsWrapper = this.wavesurfer.drawer.wrapper;
                    const wsWrapperStart = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft);
                    const wsWrapperEnd = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft + wsWrapper.clientWidth);
                    const currentMouseOffsetFromAudio = getMouseOffset(this.wavesurfer, e);
                    let nextTempStart = currentMouseOffsetFromAudio - this.mouseOffsetSnapshot.fromRegionStart;
                    let nextTempEnd = currentMouseOffsetFromAudio + this.mouseOffsetSnapshot.fromRegionEnd;
                    if (!this.isWrapperScrolling) {
                        if (this.isResizing) {
                            switch (this.activeResizeHandle) {
                                case 'start': {
                                    nextTempEnd =
                                        this.mouseOffsetSnapshot.fromAudio + this.mouseOffsetSnapshot.fromRegionEnd;
                                    nextTempStart = nextTempStart >= nextTempEnd ? nextTempEnd : nextTempStart;
                                    nextTempStart = nextTempStart >= 0 ? nextTempStart : 0;
                                    break;
                                }
                                case 'end': {
                                    nextTempStart =
                                        this.mouseOffsetSnapshot.fromAudio - this.mouseOffsetSnapshot.fromRegionStart;
                                    nextTempEnd = nextTempEnd <= nextTempStart ? nextTempStart : nextTempEnd;
                                    nextTempEnd = nextTempEnd <= this.audioDuration ? nextTempEnd : this.audioDuration;
                                    break;
                                }
                            }
                        }
                        else {
                            if (nextTempStart <= 0) {
                                nextTempStart = 0;
                                nextTempEnd = this.regionDuration;
                            }
                            if (nextTempEnd >= this.audioDuration) {
                                nextTempStart = this.audioDuration - this.regionDuration;
                                nextTempEnd = this.audioDuration;
                            }
                        }
                        // Checks if region intersects with wrapper boundary and handles scrolling of wavesurfer wrapper
                        if (nextTempEnd > this.state.tempEnd &&
                            nextTempEnd >= wsWrapperEnd &&
                            wsWrapperEnd >= this.state.tempEnd) {
                            this.wrapperScrollDirection = 'right';
                            window.requestAnimationFrame(() => this.handleWrapperScroll('right'));
                        }
                        else if (nextTempStart < this.state.tempStart &&
                            nextTempStart <= wsWrapperStart &&
                            wsWrapperStart <= this.state.tempStart) {
                            this.wrapperScrollDirection = 'left';
                            window.requestAnimationFrame(() => this.handleWrapperScroll('left'));
                        }
                        this.setState((prevState) => (Object.assign(Object.assign({}, prevState), { tempStart: nextTempStart, tempEnd: nextTempEnd })));
                        this.onRegionUpdate(this.asRegionEvent);
                    }
                    if (this.isWrapperScrolling) {
                        if (this.wrapperScrollDirection === 'right' && nextTempEnd < this.state.tempEnd) {
                            this.stopWrapperScroll();
                        }
                        if (this.wrapperScrollDirection === 'left' && nextTempStart > this.state.tempStart) {
                            this.stopWrapperScroll();
                        }
                    }
                }
            }
        });
        Object.defineProperty(this, "handleWrapperScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (scrollDirection) => {
                if (this.mouseOffsetSnapshot) {
                    const wsWrapper = this.wavesurfer.drawer.wrapper;
                    let nextTempEnd = this.state.tempStart;
                    let nextTempStart = this.state.tempEnd;
                    if (nextTempStart >= this.audioDuration || nextTempStart <= 0) {
                        this.stopWrapperScroll();
                    }
                    switch (scrollDirection) {
                        case 'right': {
                            wsWrapper.scrollLeft = wsWrapper.scrollLeft + this.wrapperScrollSpeed;
                            if (this.isResizing) {
                                nextTempStart =
                                    this.mouseOffsetSnapshot.fromAudio - this.mouseOffsetSnapshot.fromRegionStart;
                                nextTempEnd = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft + wsWrapper.clientWidth);
                                break;
                            }
                            else {
                                nextTempEnd = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft + wsWrapper.clientWidth);
                                nextTempStart = nextTempEnd - this.regionDuration;
                                break;
                            }
                        }
                        case 'left': {
                            wsWrapper.scrollLeft = wsWrapper.scrollLeft - this.wrapperScrollSpeed;
                            if (this.isResizing) {
                                nextTempStart = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft);
                                nextTempEnd =
                                    this.mouseOffsetSnapshot.fromAudio + this.mouseOffsetSnapshot.fromRegionEnd;
                                break;
                            }
                            else {
                                nextTempStart = convertPxToSeconds(this.wavesurfer, wsWrapper.scrollLeft);
                                nextTempEnd = nextTempStart + this.regionDuration;
                                break;
                            }
                        }
                    }
                    this.setState((prevState) => (Object.assign(Object.assign({}, prevState), { tempStart: nextTempStart, tempEnd: nextTempEnd })));
                    this.onRegionUpdate(this.asRegionEvent);
                    if (this.isWrapperScrolling) {
                        window.requestAnimationFrame(() => this.handleWrapperScroll(scrollDirection));
                    }
                }
            }
        });
        Object.defineProperty(this, "stopWrapperScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.wrapperScrollDirection = undefined;
            }
        });
        Object.defineProperty(this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.mouseOffsetSnapshot) {
                    this.mouseOffsetSnapshot = undefined;
                    this.activeResizeHandle = undefined;
                    const event = this.asRegionEvent;
                    if (event.start !== this.props.start || event.end !== this.props.end) {
                        this.onRegionUpdateEnd(event);
                    }
                    if (this.isWrapperScrolling) {
                        this.stopWrapperScroll();
                    }
                }
            }
        });
        Object.defineProperty(this, "onMouseClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.stopPropagation();
                this.onRegionClick(this.asRegionEvent);
            }
        });
        Object.defineProperty(this, "onRegionClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onClick) {
                    this.props.onClick(e);
                }
            }
        });
        Object.defineProperty(this, "onRegionUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(e);
                }
                this.fireEvent('region-update');
            }
        });
        Object.defineProperty(this, "onRegionUpdateEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onUpdateEnd) {
                    this.props.onUpdateEnd(e);
                }
                this.fireEvent('region-update-end');
            }
        });
        Object.defineProperty(this, "fireEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.wavesurfer.fireEvent(name, this.asRegionEvent);
            }
        });
        Object.defineProperty(this, "getDragHandleProps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (additionalProps) => {
                return Object.assign(Object.assign({}, additionalProps), { onMouseDown: (e) => {
                        e.stopPropagation();
                        if (additionalProps && additionalProps.onMouseDown) {
                            additionalProps.onMouseDown(e);
                        }
                        if (!this.props.disableDrag) {
                            this.onDragHandleMouseDown(e);
                        }
                    }, style: { pointerEvents: 'auto' } });
            }
        });
        Object.defineProperty(this, "getResizeStartHandleProps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (additionalProps) => {
                return Object.assign(Object.assign({}, additionalProps), { onMouseDown: (e) => {
                        e.stopPropagation();
                        if (additionalProps && additionalProps.onMouseDown) {
                            additionalProps.onMouseDown(e);
                        }
                        if (!this.props.disableResize) {
                            this.onResizeHandleMouseDown(e);
                        }
                    }, ['data-region-resize-handle']: 'start', style: { pointerEvents: 'auto' } });
            }
        });
        Object.defineProperty(this, "getResizeEndHandleProps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (additionalProps) => {
                return Object.assign(Object.assign({}, additionalProps), { onMouseDown: (e) => {
                        e.stopPropagation();
                        if (additionalProps && additionalProps.onMouseDown) {
                            additionalProps.onMouseDown(e);
                        }
                        if (!this.props.disableResize) {
                            this.onResizeHandleMouseDown(e);
                        }
                    }, ['data-region-resize-handle']: 'end', style: { pointerEvents: 'auto' } });
            }
        });
    }
    componentDidMount() {
        this.ref && this.ref.addEventListener('click', this.onMouseClick);
        document.body.addEventListener('mousemove', this.onMouseMove);
        document.body.addEventListener('mouseup', this.onMouseUp);
        this.fireEvent('region-added');
    }
    componentDidUpdate(prevProps) {
        if (prevProps.start !== this.props.start || prevProps.end !== this.props.end) {
            const tempEnd = this.props.end !== undefined ? this.props.end : this.props.start;
            this.setState({
                tempStart: this.props.start,
                tempEnd: tempEnd,
            });
        }
    }
    componentWillUnmount() {
        this.ref && this.ref.removeEventListener('click', this.onMouseClick);
        document.body.removeEventListener('mousemove', this.onMouseMove);
        document.body.removeEventListener('mouseup', this.onMouseUp);
        this.fireEvent('region-removed');
    }
    get isWrapperScrolling() {
        return !!this.wrapperScrollDirection;
    }
    get wavesurfer() {
        return this.context.wavesurfer;
    }
    get audioDuration() {
        return this.wavesurfer.getDuration();
    }
    get regionDuration() {
        return this.state.tempEnd - this.state.tempStart;
    }
    get isResizing() {
        return !!this.activeResizeHandle;
    }
    get asRegionEvent() {
        const { tempStart, tempEnd } = this.state;
        const end = tempEnd === tempStart ? undefined : tempEnd;
        return {
            id: this.props.id,
            start: tempStart,
            end: end,
            backgroundColor: this.props.backgroundColor,
        };
    }
    render() {
        const { backgroundColor, borderColor, borderWidth, id, children, orderPosition } = this.props;
        const start = convertSecondsToPx(this.wavesurfer, this.state.tempStart);
        // Both mouse and region-end positions are the same but for some reason while updating
        // the end position mouse is still outside of the region boundary and doesnt trigger any
        // events on the region. To keep mouse in the boundary we add 1px more to the end.
        // This doesnt affect data but only DOM.
        const end = convertSecondsToPx(this.wavesurfer, this.state.tempEnd) + 1;
        const border = `${borderWidth}px solid ${borderColor}`;
        return (React.createElement("div", { ref: (c) => {
                this.ref = c;
                if (this.props.refCallback) {
                    this.props.refCallback(c);
                }
            }, "data-region-id": id, style: {
                position: 'absolute',
                zIndex: orderPosition,
                height: '100%',
                top: 0,
                left: start,
                width: end - start,
                backgroundColor: backgroundColor,
                boxSizing: 'content-box',
                borderLeft: border,
                borderRight: border,
                pointerEvents: 'none',
            } }, children({
            getDragHandleProps: this.getDragHandleProps,
            getResizeStartHandleProps: this.getResizeStartHandleProps,
            getResizeEndHandleProps: this.getResizeEndHandleProps,
            disableResize: Boolean(this.props.disableResize),
            disableDrag: Boolean(this.props.disableDrag),
        })));
    }
}
Object.defineProperty(Region, "contextType", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: waveSurferContext
});
Object.defineProperty(Region, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        orderPosition: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderColor: 'transparent',
        borderWidth: 0,
    }
});
