import React from 'react';
// @ts-ignore
import CursonPluginInternal from 'wavesurfer.js-3/dist/plugin/wavesurfer.cursor.min.js';
import { Formatters } from '@corti/strings';
import { useWavesurferContext } from '../WaveSurferContext';
const formatTimeCallback = (sec) => {
    return Formatters.msToDuration(sec * 1000, { showHours: false, showMilliseconds: true });
};
export function CursorPlugin(props) {
    const { color = '#777', showTime = true } = props;
    const { wavesurfer } = useWavesurferContext();
    React.useEffect(() => {
        wavesurfer.addPlugin(CursonPluginInternal.create({
            opacity: 1,
            width: '2px',
            color: color,
            zIndex: 4,
            customShowTimeStyle: {
                backgroundColor: '#000',
                borderRadius: '2px',
                color: '#FFF',
                padding: '2px 4px',
                fontSize: '10px',
                height: '17px',
                display: 'block',
                margin: '0px',
                transform: 'translateX(-50%)',
            },
            showTime: showTime,
            followCursorY: false,
            formatTimeCallback: formatTimeCallback,
        }));
        wavesurfer.initPlugin('cursor');
        return () => {
            try {
                wavesurfer.destroyPlugin('cursor');
            }
            catch (_a) { }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
