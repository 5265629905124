import { gql } from '@apollo/client';
export const getTeamQuery = gql `
  query Team($id: String!) {
    team(id: $id) {
      id
      name
      description
      members {
        id
        user {
          id
          name
          deletedAt
        }
        team {
          id
        }
        teamRole
      }
    }
  }
`;
export const deleteTeamMemberMutation = gql `
  mutation deleteTeamMember($id: String!) {
    deleteTeamMember(id: $id)
  }
`;
export const addTeamMemberMutation = gql `
  mutation addTeamMember($teamID: String!, $userID: String!) {
    addTeamMember(teamID: $teamID, userID: $userID) {
      id
    }
  }
`;
export const getUsersQuery = gql `
  query getUsers($orgID: ID!) {
    users(organizationID: $orgID) {
      id
      name
      email
    }
  }
`;
export const updateTeamMemberMutation = gql `
  mutation updateTeamMember($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      id
    }
  }
`;
