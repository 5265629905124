import React from 'react';
import { useTheme } from '@corti/theme';
import { Box, Button, ColorPicker } from 'lib/cortiUI';
import { TEST_IDS } from './utils';
export const ColorPickerField = ({ label, value, onChange }) => {
    const { palette } = useTheme();
    return (React.createElement(ColorPicker, { colorValue: value, onColorChange: (c) => onChange(c) }, ({ colorValue, getTriggerProps }) => (React.createElement(Button, { "data-cy": TEST_IDS.colorPicker, variant: "outlined", onClick: getTriggerProps().onClick, sx: {
            '&:hover': {
                backgroundColor: `${palette.background.card} !important`,
                borderColor: palette.text.primary,
            },
            '& span': { textTransform: 'none', fontSize: 16, fontWeight: 400 },
            backgroundColor: palette.background.card,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            py: 3,
            px: 3,
        } },
        React.createElement(Box, { width: 20, height: 20, mr: 4, background: colorValue, borderRadius: 10 }),
        label))));
};
