import { Observer } from '@corti/observer';
import { PrivateStream, StreamUtils } from './PrivateStream';
import { SimpleStream } from './SimpleStream';
import { createConnection, } from './connection';
import { parseMessage, } from './message';
export class RealtimeClient {
    constructor(config = {}) {
        Object.defineProperty(this, "connection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "connectOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "globalStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "streams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "getConnectUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const wsHost = this.connectOptions.host.replace('https:', 'wss:').replace('http:', 'ws:');
                const u = new URL(wsHost + '/ws/v1.0/events');
                if (this.connectOptions.authToken) {
                    u.searchParams.append('token', this.connectOptions.authToken);
                }
                return u.toString();
            }
        });
        Object.defineProperty(this, "handleConnStateChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ current }) => {
                if (current === 'disconnected') {
                    Object.values(this.streams).forEach((s) => {
                        s === null || s === void 0 ? void 0 : s.unsubscribe();
                    });
                }
            }
        });
        Object.defineProperty(this, "handleMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (message) => {
                const msg = parseMessage(message);
                if (msg.event === 'error') {
                    this.observer.fireEvent('errorMessage', msg);
                    return;
                }
                this.observer.fireEvent('message', msg);
                this.observer.fireEvent(msg.event, msg);
                this.forwardEventToStreams(msg);
            }
        });
        Object.defineProperty(this, "forwardEventToStreams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                if (event.stream) {
                    Object.values(this.streams).forEach((it) => {
                        if (it && StreamUtils.isEqual(event.stream, it.def)) {
                            it.handleEvent(event);
                        }
                    });
                }
                else {
                    this.globalStream.handleEvent(event);
                }
            }
        });
        Object.defineProperty(this, "getStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (streamDef) => {
                return this.streams[StreamUtils.toID(streamDef)];
            }
        });
        /**
         * Bind to receiving ALL events via a connection
         */
        Object.defineProperty(this, "onEventReceived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('message', cb);
            }
        });
        /**
         * Bind a global error channel
         */
        Object.defineProperty(this, "onErrorEventReceived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('errorMessage', cb);
            }
        });
        // #region global-stream
        Object.defineProperty(this, "send", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.send(...args);
            }
        });
        /**
         * Bind event listener to a global stream
         */
        Object.defineProperty(this, "bind", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.bind(...args);
            }
        });
        Object.defineProperty(this, "unbind", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.unbind(...args);
            }
        });
        Object.defineProperty(this, "onUserConnected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.onUserConnected(...args);
            }
        });
        Object.defineProperty(this, "onUserDisconnected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.onUserDisconnected(...args);
            }
        });
        Object.defineProperty(this, "onUsersChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (...args) => {
                return this.globalStream.onUsersChanged(...args);
            }
        });
        // #endregion global-stream
        Object.defineProperty(this, "subscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                let stream = this.getStream(input);
                if (stream) {
                    return stream;
                }
                stream = new PrivateStream(this, input);
                this.streams[StreamUtils.toID(input)] = stream;
                return stream;
            }
        });
        Object.defineProperty(this, "unsubscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stream) => {
                const s = Object.values(this.streams).find((s) => s === stream);
                if (s) {
                    s.destroy();
                    delete this.streams[StreamUtils.toID(s.def)];
                }
            }
        });
        // #region testing
        /**
         * API FOR TESTING
         */
        Object.defineProperty(this, "dispatchEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                this.connection.dispatchMessageEvent(event);
            }
        });
        this.connection = createConnection(config);
        this.connection.on('message', this.handleMessage);
        this.connection.on('stateChanged', this.handleConnStateChange);
        this.globalStream = new SimpleStream(this);
    }
    connect(options) {
        this.connectOptions = options;
        this.connection.connect(this.getConnectUrl());
    }
    disconnect() {
        this.connection.disconnect();
    }
    /**
     * This method should not be confused with the similar functionality provided by calling `connection.disconnect()`
     * followed by `connection.connect()`
     *
     * This will maintain the unsent message queue. Use only when reconnecting to the exact same replica of the backend host
     */
    reconnectToAltHost(options = {}) {
        if (this.connection.state === 'initialized') {
            throw new Error('`connect` needs to be called first');
        }
        if (options.apiHost) {
            this.connectOptions.host = options.apiHost;
        }
        this.connection.reconnect(this.getConnectUrl());
    }
    get onlineUserIDs() {
        return this.globalStream.onlineUserIDs;
    }
}
