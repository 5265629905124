var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
export class ValuePublisherCollectorConfig {
    constructor(input, parent) {
        Object.defineProperty(this, "parent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prototypeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customValueFormat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.parent = parent;
        this.prototypeID = '';
        this.deserialize(input);
        makeObservable(this);
    }
    setPrototypeID(id) {
        if (!this.parent.isCollectorPrototypeIDAllowed(id)) {
            console.warn('Only one configuration per prototype ID is allowed');
            return;
        }
        this.prototypeID = id;
    }
    setCustomValueFormat(value) {
        this.customValueFormat = value;
    }
    /**
     * Get the config inherited from the parent for the same block prototype
     */
    getSuperConfig() {
        var _a;
        return (_a = this.parent.context) === null || _a === void 0 ? void 0 : _a.getConfigByPrototypeID(this.prototypeID);
    }
    getEncodableData() {
        var _a;
        return {
            prototypeID: this.prototypeID,
            customValueFormat: ((_a = this.customValueFormat) === null || _a === void 0 ? void 0 : _a.trim()) === '' ? undefined : this.customValueFormat,
        };
    }
    deserialize(input) {
        this.setPrototypeID(input.prototypeID);
        this.setCustomValueFormat(input.customValueFormat);
    }
    copy() {
        return new ValuePublisherCollectorConfig(this.getEncodableData(), this.parent);
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], ValuePublisherCollectorConfig.prototype, "prototypeID", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], ValuePublisherCollectorConfig.prototype, "customValueFormat", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ValuePublisherCollectorConfig.prototype, "setPrototypeID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ValuePublisherCollectorConfig.prototype, "setCustomValueFormat", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ValuePublisherCollectorConfig.prototype, "deserialize", null);
export class ValuePublisher {
    constructor(input) {
        Object.defineProperty(this, "collectors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "context", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.collectors = [];
        });
        if (input) {
            this.deserialize(input);
        }
    }
    setInheritanceContext(ctx) {
        this.context = ctx;
    }
    isCollectorPrototypeIDAllowed(prototypeID) {
        return !this.getConfigByPrototypeID(prototypeID);
    }
    pushNewCollectorConfig(input) {
        if (!this.isCollectorPrototypeIDAllowed(input.prototypeID)) {
            console.warn('Only one configuration per prototype ID is allowed');
            return;
        }
        this.collectors.push(new ValuePublisherCollectorConfig(input, this));
    }
    deleteCollectorConfigs() {
        this.collectors.forEach((_, idx) => this.deleteCollectorConfig(idx));
    }
    deleteCollectorConfig(idx) {
        this.collectors.splice(idx, 1);
    }
    getConfigByPrototypeID(id) {
        return this.collectors.find((c) => c.prototypeID === id);
    }
    getEncodableData() {
        return {
            collectors: this.collectors.map((d) => d.getEncodableData()),
        };
    }
    deserialize(entity) {
        this.deleteCollectorConfigs();
        entity.collectors.forEach((c) => this.pushNewCollectorConfig(c));
    }
    copy() {
        const i = new ValuePublisher(this.getEncodableData());
        i.setInheritanceContext(this.context);
        return i;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], ValuePublisher.prototype, "collectors", void 0);
__decorate([
    observable,
    __metadata("design:type", ValuePublisher)
], ValuePublisher.prototype, "context", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ValuePublisher.prototype, "setInheritanceContext", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ValuePublisher.prototype, "pushNewCollectorConfig", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ValuePublisher.prototype, "deleteCollectorConfigs", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ValuePublisher.prototype, "deleteCollectorConfig", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ValuePublisher.prototype, "deserialize", null);
export function isValuePublisher(element) {
    return 'valuePublisher' in element && element.valuePublisher instanceof ValuePublisher;
}
