import { createCoreApiService } from './coreApiService';
export * from './auth';
export * from './caseFeedback';
export * from './caseFilters';
export * from './cases';
export * from './coreApiService';
export * from './errors';
export * from './files';
export * from './graphqlSchema';
export * from './organizations';
export * from './organizationConfigs';
export * from './protocolGraphs';
export * from './richText/v2';
export * from './roles';
export * from './timelineEntryDefinitions';
export * from './triage';
export * from './types';
export * from './users';
export * from './notifications';
export * from './media';
export * from './transcripts';
export let api;
/**
 * Static
 */
export function init(config) {
    api = createCoreApiService(config);
}
