import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Route } from 'lib/router';
import { Builder } from './Builder';
import { ListView } from './List';
export const TemplatesManagement = () => {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true },
            React.createElement(ListView, null)),
        React.createElement(Route, { path: `${match.path}/:templateID`, breadcrumb: ({ match }) => {
                return `${match.params.templateID}`;
            }, render: () => React.createElement(Builder, null) })));
};
