import { BaseStream } from './BaseStream';
import { createMessageEvent } from './message';
/**
 * SimpleStream does not need to be subscribed to or authenticated.
 */
export class SimpleStream extends BaseStream {
    constructor(realtime) {
        super(realtime);
        Object.defineProperty(this, "handleConnectionStateChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                if (event.current === 'connected') {
                    this.sendMessageQueue();
                }
            }
        });
        this.bindEvents();
    }
    destroy() {
        this.unbindEvents();
        super.destroy();
    }
    send(event, data) {
        const e = createMessageEvent({ event: String(event), data });
        if (this.realtime.connection.state === 'connected') {
            this.sendNow(e);
        }
        else {
            this.queueEvent(e);
        }
    }
    bindEvents() {
        this.realtime.connection.on('stateChanged', this.handleConnectionStateChange);
    }
    unbindEvents() {
        this.realtime.connection.un('stateChanged', this.handleConnectionStateChange);
    }
}
