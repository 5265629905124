import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { produce } from 'immer';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { intl } from '@corti/i18n';
import { useMousetrap } from '@corti/react';
import { stringIncludes } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Button, FileInput, Input, InputAdornment, Tooltip, Typography, } from 'lib/cortiUI';
import { FileUploader } from './FileUploader';
import { MediaLibraryItemRenderer, UnfinishedMediaLibraryItem } from './MediaLibraryItem';
export const MediaLibraryView = observer(function MediaLibraryView(props) {
    const { mediaAssets, whitelistTypes, disableSelection, fileUploadImplementation, onMediaClick, onArchiveSelected, } = props;
    const theme = useTheme();
    const $searchInput = React.useRef();
    const fileUploader = React.useRef(new FileUploader({
        uploadFile: fileUploadImplementation,
    }));
    const [searchInput, setSearchInput] = React.useState('');
    const [selection, setSelection] = React.useState({
        selectedItemIDs: {},
    });
    useMousetrap({
        keys: '/',
        action: 'keyup',
        handle: () => {
            var _a;
            (_a = $searchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
    }, []);
    function handleItemSelected(mediaAsset) {
        if (disableSelection)
            return;
        setSelection((sel) => produce(sel, (draft) => {
            if (draft.selectedItemIDs[mediaAsset.id]) {
                delete draft.selectedItemIDs[mediaAsset.id];
            }
            else {
                draft.selectedItemIDs[mediaAsset.id] = true;
            }
            return draft;
        }));
    }
    async function handleDownloadSelected() {
        const assets = mediaAssets.filter((it) => !!selection.selectedItemIDs[it.id]);
        setSelection({ selectedItemIDs: {} });
        assets === null || assets === void 0 ? void 0 : assets.forEach((it) => saveFileToDisk(it.url, it.name));
    }
    function handleArchiveSelected() {
        const assets = mediaAssets.filter((it) => !!selection.selectedItemIDs[it.id]);
        setSelection({ selectedItemIDs: {} });
        onArchiveSelected === null || onArchiveSelected === void 0 ? void 0 : onArchiveSelected(assets);
    }
    async function handleFilesSelected(files) {
        if (files) {
            files.forEach((f) => {
                void fileUploader.current.uploadFile(f);
            });
        }
    }
    function filter(mediaAsset) {
        let passes = true;
        if (whitelistTypes) {
            passes = whitelistTypes.indexOf(mediaAsset.typename) !== -1;
        }
        passes = passes && stringIncludes(mediaAsset.name, searchInput);
        return passes;
    }
    const orderedMediaAssets = React.useMemo(() => {
        const filtered = mediaAssets.filter(filter);
        return orderBy(filtered, 'createdAt', 'desc');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaAssets, searchInput]);
    const hasSelection = Object.keys(selection.selectedItemIDs).length !== 0;
    return (React.createElement(Base, { py: 7, px: 8, display: "grid", gridTemplateRows: "auto auto 1fr", height: "100%", overflow: "auto", "data-cy": "media-library-page" },
        React.createElement(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { variant: "h6", mr: 6, color: "default" }, intl.t('mediaLibrary:title', 'Media Library')),
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                React.createElement(Input, { inputProps: {
                        ref: $searchInput,
                    }, minWidth: 300, type: "search", placeholder: intl.t('mediaLibrary:filterInput.placeholder', 'Press {{- shortcut}} to filter media library', { shortcut: '"/"' }), mr: 5, value: searchInput, onChange: (e) => setSearchInput(e.target.value), startAdornment: React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.hint }) })) }),
                React.createElement(FileInput, { accept: "image/*, application/pdf, text/html, video/*, audio/*", multiple: true, "data-cy": "upload-media-asset-input", onSelectFiles: (files) => {
                        void handleFilesSelected(files);
                    } }, ({ selectFiles }) => (React.createElement(Button, { color: "primary", onClick: selectFiles }, intl.t('mediaLibrary:uploadMediaBtn', 'Upload Media')))))),
        hasSelection && (React.createElement(Base, { display: "grid", gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center", gridGap: 4, my: 7 },
            React.createElement(Typography, { variant: "body2", color: "default" }, intl.t('mediaLibrary:selectionBar.selectedItemsCount', '{{selectCount}} of {{totalCount}} selected', {
                selectCount: Object.keys(selection.selectedItemIDs).length,
                totalCount: mediaAssets.length,
            })),
            React.createElement(Button, { size: "small", variant: "text", onClick: () => setSelection({ selectedItemIDs: {} }) }, intl.t('mediaLibrary:selectionBar.cancelBtn', 'Cancel')),
            React.createElement(Button, { size: "small", variant: "text", onClick: () => {
                    setSelection({
                        selectedItemIDs: mediaAssets.reduce((r, it) => {
                            r[it.id] = true;
                            return r;
                        }, {}),
                    });
                } }, intl.t('mediaLibrary:selectionBar.selectAllBtn', 'Select All')),
            React.createElement(Button, { size: "small", variant: "text", onClick: handleDownloadSelected }, intl.t('mediaLibrary:selectionBar.downloadSelectedBtn', 'Download Selected')),
            React.createElement(Button, { className: css({ marginLeft: 6 }), variant: "outlined", color: "error", size: "small", onClick: handleArchiveSelected }, intl.t('mediaLibrary:selectionBar.archiveSelectedBtn', 'Archive Selected')),
            React.createElement(Tooltip, { disableInteractive: true, title: intl.t('mediaLibrary:selectionBar.archiveExplanationHint', 'Archived items from your Media Library will not disappear from the graph. You will no longer be able to use the deleted media item in the editor though.') },
                React.createElement(HelpRoundedIcon, { color: "error", fontSize: "small" })))),
        React.createElement(Base, { display: "grid", gridTemplateColumns: `repeat(auto-fill, minmax(240px, 1fr))`, gridAutoRows: "280px", gridGap: 7, my: 7 },
            fileUploader.current.currentRequests.map((r) => {
                return React.createElement(UnfinishedMediaLibraryItem, { key: r.id, request: r });
            }),
            orderedMediaAssets.map((it) => (React.createElement(MediaLibraryItemRenderer, { "data-cy": "media-library-item", key: it.id, file: it, onSelect: () => {
                    onMediaClick === null || onMediaClick === void 0 ? void 0 : onMediaClick(it);
                    handleItemSelected(it);
                }, isSelected: selection.selectedItemIDs[it.id] }))))));
});
