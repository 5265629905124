import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Base } from 'lib/cortiUI';
import { ChecklistConfirmDelete } from './ChecklistConfirmDelete';
import { ChecklistHeader } from './ChecklistHeader';
import { ChecklistTable } from './ChecklistTable';
import { ChecklistToolbar } from './ChecklistToolbar';
import { ChecklistAdd } from './edit/ChecklistAdd';
import { ChecklistEdit } from './edit/ChecklistEdit';
import { Import, ImportFile, ImportJSON } from './import';
import { useChecklistView } from './useChecklistView';
import { useSyncState } from './useSyncState';
export const ChecklistsView = observer(function ChecklistsView() {
    // Just including this here to make sure to initialize the checklist hooks below
    useSyncState();
    const { path } = useRouteMatch();
    const hasSelection = useChecklistView(({ selected }) => selected.length > 0);
    const isDeleting = useChecklistView(({ isDeleting }) => isDeleting);
    return (React.createElement(Base, null,
        React.createElement(Base, null,
            hasSelection ? React.createElement(ChecklistToolbar, null) : React.createElement(ChecklistHeader, null),
            React.createElement(ChecklistTable, null),
            isDeleting && React.createElement(ChecklistConfirmDelete, null)),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${path}/draft` },
                React.createElement(ChecklistAdd, null)),
            React.createElement(Route, { exact: true, path: `${path}/file` },
                React.createElement(Import, null,
                    React.createElement(ImportFile, null))),
            React.createElement(Route, { exact: true, path: `${path}/json` },
                React.createElement(Import, null,
                    React.createElement(ImportJSON, null))),
            React.createElement(Route, { exact: true, path: `${path}/:checklistID` },
                React.createElement(ChecklistEdit, null)))));
});
