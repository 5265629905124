import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { init as initMissionControlApi } from '@corti/mission-control-api';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { repositories } from 'core/repositories';
import { Base, Box, FallbackView, Loading } from 'lib/cortiUI';
import { AppShell } from './AppShell';
export const AuthView = observer(function AuthView(_) {
    const [state, setState] = React.useState({
        isLoadingInitialContent: true,
        loadingError: undefined,
    });
    React.useEffect(() => {
        void loadRemoteState();
        // Initializg services that requires authorization.
        if (authStore.authToken) {
            initMissionControlApi({ host: config.getConfig().apiHost, authToken: authStore.authToken });
        }
    }, []);
    async function loadRemoteState() {
        repositories.users.loadAllUsers();
        setState({
            isLoadingInitialContent: true,
            loadingError: undefined,
        });
        try {
            await Promise.all([
                authStore.loadContent(),
                repositories.timelineEntryDefinitions.loadDefinitions(),
            ]);
            setState({
                isLoadingInitialContent: false,
                loadingError: undefined,
            });
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                setState({
                    isLoadingInitialContent: false,
                    loadingError: err,
                });
            }
        }
    }
    return (React.createElement(Base, { "data-cy": "auth-layout", height: '100%', overflow: "hidden" }, state.isLoadingInitialContent ? (React.createElement(Box, { "data-cy": "app-loading-view", alignItems: "center", justifyContent: "center", style: { height: '100%' } },
        React.createElement(Loading, { text: intl.t('appLoadingMessage', 'Loading...') }))) : state.loadingError ? (React.createElement(Box, { style: { height: '100%' } },
        React.createElement(FallbackView, { text: "Initial content was not loaded", actionText: "Reload content", secondaryActionText: "Go To Login Screen", secondaryAction: authStore.logout, action: loadRemoteState }))) : (React.createElement(AppShell, null))));
});
