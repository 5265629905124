import React from 'react';
import { Base, Card, Skeleton } from 'lib/cortiUI';
export function ConfigurationLoader() {
    return (React.createElement(Card, { p: 8, display: "flex", flexDirection: "column", gap: 7 },
        React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Skeleton, { variant: "rounded", width: "50%", height: 50 }),
            React.createElement(Base, { display: "flex", gap: 6 },
                React.createElement(Skeleton, { variant: "rounded", height: 32, width: 100 }),
                React.createElement(Skeleton, { variant: "rounded", height: 32, width: 100 }))),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
            React.createElement(Skeleton, { variant: "text", width: 200 }),
            React.createElement(Skeleton, { variant: "text", width: 200 }))));
}
