import React from 'react';
import { useHistory } from 'react-router';
import { Task } from './Task';
export const List = ({ items, templateID, onUnassignUser }) => {
    const history = useHistory();
    function handleTaskClick(interactionID) {
        history.push(`${history.location.pathname}/${interactionID}`);
    }
    return (React.createElement(React.Fragment, null, items.map((it) => (React.createElement(Task, { interactionOwner: it.interaction.interaction_owner, assignees: it.task.assignees, createdAt: it.task.created_at, id: it.task.id, key: it.task.id, owner: it.task.created_by, reviews: it.reviews, status: it.task.status, templateID: templateID, type: it.task.task_type, onClick: () => handleTaskClick(it.interaction.id), onUnassignUser: () => onUnassignUser(it.task.id) })))));
};
