import { ScopedCssBaseline } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ChecklistPanel, ReadOnlyChecklistPanel } from '@corti/checklists';
import { Copilot } from '@corti/corti-ui';
import { intl } from '@corti/i18n';
import { getScrollerCss } from '@corti/style';
import { ThemeProvider } from '@corti/theme';
import { ThemeUtils } from 'browser/themes';
import { Base } from 'lib/cortiUI';
import { ToolPanelContent } from 'lib/webappUI';
import { useTriageSession } from './TriageSessionContext';
export const ChecklistToolPanel = observer(function ChecklistToolPanel() {
    const ctx = useTriageSession();
    const { allChecklists, checklistProgress, updateChecklistProgress, autoCheckedEntryIds, currentChecklists, } = ctx.controller.flow.checklistStore;
    if (!(currentChecklists === null || currentChecklists === void 0 ? void 0 : currentChecklists.length))
        return null;
    const theme = ThemeUtils.getDefaultTheme().config;
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(ScopedCssBaseline, null,
            React.createElement(ToolPanelContent, { title: intl.t('realtime:checklistsView', 'Checklists') },
                React.createElement(Copilot, { className: getScrollerCss({ theme }) },
                    React.createElement(Base, { px: "1em", display: "flex", flexDirection: "column", gap: 16 }, ctx.controller.isCurrentUserOwner ? (React.createElement(ChecklistPanel, { allChecklists: allChecklists, currentChecklistIds: currentChecklists, snapshot: checklistProgress, autoCheckedEntryIds: autoCheckedEntryIds, listener: updateChecklistProgress })) : (React.createElement(ReadOnlyChecklistPanel, { allChecklists: allChecklists, currentChecklistIds: currentChecklists, snapshot: toJS(checklistProgress), autoCheckedEntryIds: autoCheckedEntryIds }))))))));
});
