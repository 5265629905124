import React from 'react';
/**
 * Hook to make component mount asynchronously
 */
export function useAsyncMount() {
    const [shouldRender, setShouldRender] = React.useState(false);
    React.useEffect(() => {
        window.requestIdleCallback(() => {
            setShouldRender(true);
        });
    }, []);
    return shouldRender;
}
