import { uuid } from '@corti/uuid';
export class StepModel {
    constructor(input, flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "node", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prevStepID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serialize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return {
                    id: this.id,
                    nodeID: this.node.id,
                    prevStepID: this.prevStepID,
                };
            }
        });
        Object.defineProperty(this, "setPrevStepID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stepID) => {
                this.prevStepID = stepID;
            }
        });
        this.id = input.id || uuid();
        this.node = input.node;
        this.prevStepID = input.prevStepID;
    }
    get branch() {
        return this.node.branch;
    }
    get view() {
        return this.node.view;
    }
    get type() {
        var _a;
        if (this.flowStore.stepStore.pinnedSteps.find((it) => it.id === this.id)) {
            return 'step:pinned';
        }
        if (((_a = this.flowStore.stepStore.favoriteStep) === null || _a === void 0 ? void 0 : _a.id) === this.id) {
            return 'step:favorite';
        }
        return 'step:flow';
    }
}
