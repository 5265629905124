import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { Box, Button, Typography } from 'lib/cortiUI';
import { GenericField } from './GenericField';
import { TEST_IDS } from './utils';
export function AnnotationForm({ data, control, onSubmit, onCancel }) {
    const { t } = useTranslation('annotationManagement');
    return (React.createElement("form", { onSubmit: onSubmit, "data-cy": TEST_IDS.timelineEntryDefinitionForm },
        React.createElement(Box, { gap: 6 }, data.length > 0 &&
            data.map((fieldData) => {
                const { name, initialValue, rules, title } = fieldData;
                return (React.createElement(Box, { key: name },
                    title && (React.createElement(Typography, { color: "default", variant: "body2", mb: 4 }, title)),
                    React.createElement(Controller, { name: name, control: control, rules: rules, defaultValue: initialValue, render: ({ field, fieldState }) => (React.createElement(GenericField, { onChange: field.onChange, error: fieldState.error, invalid: !!fieldState.error, fieldData: Object.assign(Object.assign({}, fieldData), { initialValue: field.value }) })) })));
            })),
        React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", pb: 4, pt: 6, pr: 3 },
            React.createElement(Button, { color: "primary", variant: "text", onClick: onCancel }, t('modal.cancel', 'Cancel')),
            React.createElement(Button, { color: "primary", variant: "text", type: "submit" }, t('modal.save', 'Save')))));
}
