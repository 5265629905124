import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Loading, Typography } from 'lib/cortiUI';
export const SavingIndicator = ({ isSaving }) => {
    const { t } = useTranslation('configurations', { keyPrefix: 'savingIndicator' });
    const { palette } = useTheme();
    return isSaving ? (React.createElement(Base, { display: "flex", gap: 5, alignItems: "center" },
        React.createElement(Loading, { size: "small", color: palette.primary.main }),
        React.createElement(Typography, { variant: "caption" }, t('saving', 'Saving')))) : (React.createElement(Typography, { variant: "caption" }, t('draftSaving', 'Draft saved')));
};
