import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import { forEach } from 'lodash';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { authStore } from 'core/auth';
import { IconButton } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
import { print } from './pdfPrint';
import { submissionToFormValue, transformTemplate } from './transformers';
export function CaseFeedbackDetailPrint(props) {
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    const { feedbackFormSubmission } = props;
    const { caseEntity } = caseViewState;
    const submissionFormValue = caseEntity && submissionToFormValue(feedbackFormSubmission, caseEntity);
    function getMetadataBox() {
        var _a, _b;
        const metadataTable = document.createElement('table');
        metadataTable.style.backgroundColor = theme.palette.grey[100];
        if (caseEntity) {
            const caseIdBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.caseID', 'Case ID'),
                    isBold: true,
                },
                {
                    value: ((_a = caseEntity.customReadableCaseIdDescriptor) === null || _a === void 0 ? void 0 : _a.value) || '-',
                },
                {
                    value: `(${caseEntity.readableCaseID})`,
                },
            ];
            const caseOwnerBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.caseCreatedBy', 'Created By'),
                    isBold: true,
                },
                {
                    value: caseEntity.createdBy.name,
                },
            ];
            const caseStartedBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.caseStartedAt', 'Started At'),
                    isBold: true,
                },
                {
                    value: formatDateTime(caseEntity.startedAt),
                },
            ];
            const reviewedByBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.reviewedBy', 'Reviewed By'),
                    isBold: true,
                },
                {
                    value: ((_b = feedbackFormSubmission.createdBy) === null || _b === void 0 ? void 0 : _b.name) || '-',
                },
            ];
            const reviewedAtBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.reviewedAt', 'Reviewed At'),
                    isBold: true,
                },
                {
                    value: formatDateTime(new Date(feedbackFormSubmission.createdAt)),
                },
            ];
            const acknowledgedAtBlockData = [
                {
                    value: intl.t('explore:feedbackView.pdfExport.acknowledgedAt', 'Acknowledged At'),
                    isBold: true,
                },
                {
                    value: feedbackFormSubmission.dispatcherAcknowledgedAt
                        ? formatDateTime(new Date(feedbackFormSubmission.dispatcherAcknowledgedAt))
                        : '-',
                },
            ];
            metadataTable.append(getMetadataRow([getBlockDiv(caseIdBlockData), getBlockDiv(reviewedByBlockData)]));
            metadataTable.append(getMetadataRow([getBlockDiv(caseOwnerBlockData), getBlockDiv(reviewedAtBlockData)]));
            metadataTable.append(getMetadataRow([getBlockDiv(caseStartedBlockData), getBlockDiv(acknowledgedAtBlockData)]));
            if (caseEntity.tags.length) {
                const caseTagsContainer = document.createElement('div');
                caseTagsContainer.append(getTextDiv(intl.t('explore:feedbackView.pdfExport.tags', 'Tags'), true));
                caseTagsContainer.style.padding = `${theme.space[4]}px`;
                const caseTagsDiv = document.createElement('div');
                caseTagsDiv.style.display = 'flex';
                caseTagsDiv.style.flexWrap = 'wrap';
                caseEntity.tags.forEach((tag) => {
                    var _a;
                    const tagSpan = document.createElement('span');
                    if ((_a = tag.style) === null || _a === void 0 ? void 0 : _a.color) {
                        tagSpan.style.backgroundColor = tag.style.color;
                    }
                    tagSpan.style.margin = `${theme.space[2]}px ${theme.space[2]}px`;
                    tagSpan.style.padding = `${theme.space[1]}px`;
                    tagSpan.innerHTML = tag.value;
                    caseTagsDiv.append(tagSpan);
                });
                caseTagsContainer.append(caseTagsDiv);
                const tagsCell = document.createElement('td');
                tagsCell.colSpan = 2;
                tagsCell.append(caseTagsContainer);
                const caseTagsRow = document.createElement('tr');
                caseTagsRow.append(tagsCell);
                metadataTable.append(caseTagsRow);
            }
        }
        else {
            metadataTable.append(getTextDiv(intl.t('explore:feedbackView.pdfExport.somethingWentWrong', 'Something went wrong..'), true));
            metadataTable.append(getTextDiv(intl.t('explore:feedbackView.pdfExport.noData', 'No case data')));
        }
        return metadataTable;
    }
    function getMetadataRow(data) {
        const row = document.createElement('tr');
        data.forEach((element) => {
            const dataCell = document.createElement('td');
            dataCell.append(element);
            row.append(dataCell);
        });
        return row;
    }
    function getBlockDiv(block) {
        const blockDiv = document.createElement('div');
        blockDiv.style.padding = `${theme.space[4]}px`;
        block.forEach((b) => {
            blockDiv.append(getTextDiv(b.value, b.isBold));
        });
        return blockDiv;
    }
    function getFeedbackFormBox() {
        const feedbackFormBox = document.createElement('div');
        if (caseEntity) {
            transformTemplate(feedbackFormSubmission.template, caseEntity).fields.forEach((f) => {
                feedbackFormBox.append(getFeedbackFormFieldTable(f));
            });
        }
        return feedbackFormBox;
    }
    function getFeedbackFormFieldTable(field) {
        var _a, _b;
        const submissionField = submissionFormValue === null || submissionFormValue === void 0 ? void 0 : submissionFormValue.fields[field.id];
        if (submissionField) {
            switch (field.type) {
                case 'short-text':
                case 'long-text':
                    return getMultiCellTable(field.title, [((_a = submissionField.value) === null || _a === void 0 ? void 0 : _a.toString()) || '-'], submissionField.comment);
                case 'dropdown': {
                    const selectedItem = (_b = field.choices) === null || _b === void 0 ? void 0 : _b.find((c) => c.value === submissionField.value);
                    return getMultiCellTable(field.title, [(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.text) || '-'], submissionField.comment);
                }
                case 'radio': {
                    const selectedItem = field.choices.find((c) => c.value === submissionField.value);
                    if ((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'timeline-entry') {
                        const data = [selectedItem.headerText, selectedItem.text, selectedItem.headerInfoText];
                        return getMultiCellTable(field.title, data, submissionField.comment);
                    }
                    else {
                        return getMultiCellTable(field.title, [(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.text) || '-'], submissionField.comment);
                    }
                }
            }
        }
        else {
            return getMultiCellTable(field.title, ['-']);
        }
    }
    function getMultiCellTable(title, data, comment) {
        const fieldTable = document.createElement('table');
        const fieldTableQuestionRow = document.createElement('tr');
        const fieldTableQuestionValue = document.createElement('th');
        fieldTableQuestionValue.colSpan = data.length;
        fieldTableQuestionValue.append(getTextDiv(title, true));
        fieldTableQuestionRow.append(fieldTableQuestionValue);
        fieldTable.append(fieldTableQuestionRow);
        const fieldTableFieldValueRow = document.createElement('tr');
        forEach(data, (d) => {
            const cellData = document.createElement('td');
            cellData.innerHTML = d;
            fieldTableFieldValueRow.append(cellData);
        });
        fieldTable.append(fieldTableFieldValueRow);
        if (comment) {
            const fieldTableCommentRow = document.createElement('tr');
            const commentCell = document.createElement('td');
            commentCell.colSpan = data.length;
            const titleSpan = document.createElement('span');
            titleSpan.style.fontWeight = 'bold';
            titleSpan.innerHTML = 'Comment: ';
            const valueSpan = document.createElement('span');
            valueSpan.innerHTML = comment;
            commentCell.append(titleSpan);
            commentCell.append(valueSpan);
            fieldTableCommentRow.append(commentCell);
            fieldTable.append(fieldTableCommentRow);
        }
        return fieldTable;
    }
    function getTextDiv(text, isBold) {
        const element = document.createElement('div');
        element.innerHTML = text;
        if (isBold) {
            element.style.fontWeight = 'bold';
        }
        return element;
    }
    function getGlobalPrintViewStyles() {
        const feedbackFormTableStyles = `table { border: 1px solid ${theme.palette.grey[900]}; border-collapse: collapse; width: 100%; margin-top: 20px}`;
        const feedbackFormTableCellStyles = `th, td { border: 1px solid ${theme.palette.grey[900]}; border-collapse: collapse; padding: 5px; text-align: left;}`;
        const bodyBackgroundStyles = 'body { -webkit-print-color-adjust: exact}';
        const styles = document.createElement('style');
        styles.innerHTML = feedbackFormTableStyles
            .concat(feedbackFormTableCellStyles)
            .concat(bodyBackgroundStyles);
        return styles;
    }
    function getHeaderBox() {
        const headerContainer = document.createElement('div');
        headerContainer.append(getTextDiv(authStore.organization.name, true));
        headerContainer.append(getTextDiv(intl.t('explore:feedbackView.pdfExport.formTitle', 'Corti Feedback Form')));
        const feedbackFormTitle = getTextDiv(feedbackFormSubmission.template.title);
        feedbackFormTitle.style.fontSize = `${theme.typography.fontSize * 2}px`;
        feedbackFormTitle.style.paddingTop = `${theme.space[3]}px`;
        headerContainer.append(feedbackFormTitle);
        return headerContainer;
    }
    function handlePrint() {
        var _a, _b;
        const printContainer = document.createElement('div');
        printContainer.append(getHeaderBox());
        printContainer.append(getMetadataBox());
        printContainer.append(getFeedbackFormBox());
        const documentTitleId = (_b = (_a = caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.customReadableCaseIdDescriptor) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.readableCaseID;
        print(printContainer, getGlobalPrintViewStyles(), {
            documentTitle: documentTitleId
                ? `${documentTitleId} - ${intl.t('explore:feedbackView.pdfExport.formTitle', 'Corti Feedback Form')}`
                : intl.t('explore:feedbackView.pdfExport.formTitle', 'Corti Feedback Form'),
        });
    }
    return (React.createElement(IconButton, { className: css({ marginLeft: 4 }), icon: React.createElement(LocalPrintshopRoundedIcon, { fontSize: "small" }), onClick: handlePrint }));
}
