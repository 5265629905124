var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { IconButton } from 'lib/cortiUI';
export const Accordion = React.forwardRef(function Accordion(props, ref) {
    const { children, classes } = props, rest = __rest(props, ["children", "classes"]);
    return (React.createElement(MUIAccordion, Object.assign({ ref: ref, square: true, elevation: 0, classes: {
            root: css({
                padding: 0,
                '&.MuiAccordion-root:before': {
                    display: 'none',
                },
            }, classes ? classes['root'] : {}),
            expanded: css({
                margin: `${0}px !important`,
            }),
        } }, rest), children));
});
export const AccordionSummary = React.forwardRef(function AccordionSummary(props, ref) {
    const { children, classes } = props, rest = __rest(props, ["children", "classes"]);
    return (React.createElement(MUIAccordionSummary, Object.assign({ ref: ref, expandIcon: React.createElement(IconButton, { icon: React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }) }), classes: {
            root: css({
                margin: 0,
                padding: 0,
                minHeight: `unset !important`,
            }, classes ? classes['root'] : {}),
            expanded: css({
                margin: `${0}px !important`,
                minHeight: `unset !important`,
            }),
            content: css({
                margin: `${0}px !important`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }),
            expandIconWrapper: css({
                marginRight: 0,
            }),
        } }, rest), children));
});
export const AccordionDetails = React.forwardRef(function AccordionDetails(props, ref) {
    const { children, classes } = props, rest = __rest(props, ["children", "classes"]);
    const theme = useTheme();
    return (React.createElement(MUIAccordionDetails, Object.assign({ ref: ref, classes: {
            root: css({
                padding: 0,
                maxHeight: 400,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 0,
                marginBottom: 0,
            }, getScrollerCss({ theme }), classes ? classes['root'] : {}),
        } }, rest), children));
});
