import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { createRichText, stringifyRichText } from '@corti/richtext';
import { getProtocolGraphDetails } from './graphdetails/graphql';
import { getProtocolGraphsQuery } from './graphlist/graphql';
const cloneGraphVersionMutation = gql `
  mutation cloneProtocolGraphVersion(
    $parentVersionID: String!
    $newVersion: ProtocolGraphVersionInput!
  ) {
    cloneProtocolGraphVersion(parentVersionID: $parentVersionID, newVersion: $newVersion)
  }
`;
export function useCloneGraphVersionMutation() {
    const [refreshGraphDetails] = useLazyQuery(getProtocolGraphDetails());
    const [mutate, mutationResult] = useMutation(cloneGraphVersionMutation);
    const m = async (input) => {
        const inputCopy = Object.assign({}, input);
        // @ts-expect-error
        delete inputCopy.parentVersionID;
        await mutate({
            variables: {
                parentVersionID: input.parentVersionID,
                newVersion: Object.assign(Object.assign({}, inputCopy), { description: {
                        plainText: stringifyRichText(input.description),
                        richText: input.description,
                    } }),
            },
        });
        refreshGraphDetails({ variables: { id: input.graphID } });
    };
    return [m, mutationResult];
}
const ensureGraphVersionMutation = gql `
  mutation ensureProtocolGraphVersion($input: ProtocolGraphVersionInput!) {
    ensureProtocolGraphVersion(input: $input)
  }
`;
export function useEnsureGraphVersionMutation() {
    const [refreshGraphDetails] = useLazyQuery(getProtocolGraphDetails());
    const [mutate, mutationResult] = useMutation(ensureGraphVersionMutation);
    const m = async (input) => {
        var _a;
        await mutate({
            variables: {
                input: Object.assign(Object.assign({}, input), { description: {
                        plainText: stringifyRichText(input.description),
                        richText: (_a = input.description) !== null && _a !== void 0 ? _a : createRichText(),
                    } }),
            },
        });
        refreshGraphDetails({ variables: { id: input.graphID } });
    };
    return [m, mutationResult];
}
const ensureProtocolGraphMutation = gql `
  mutation ensureProtocolGraph($input: ProtocolGraphInput!) {
    ensureProtocolGraph(input: $input)
  }
`;
export function useEnsureProtocolGraphMutation() {
    const [mutation, mutationResult] = useMutation(ensureProtocolGraphMutation);
    const [refreshGraphs] = useLazyQuery(getProtocolGraphsQuery);
    const m = async (input) => {
        await mutation({
            variables: {
                input,
            },
        });
        refreshGraphs({ variables: { orgID: input.organizationID } });
    };
    return [m, mutationResult];
}
export const ProtocolGraphVersionCommitFragment = () => gql `
  fragment ProtocolGraphVersionCommit on ProtocolGraphVersionCommit {
    id
    message
    datetime
    createdBy {
      id
      name
    }
  }
`;
