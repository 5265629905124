/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Box, Checkbox, FormControlLabel, ImageList, ImageListItem, ImageListItemBar, Typography, } from 'lib/cortiUI';
export function CaptureSourcesList({ sources, onCaptureSourcesChanged }) {
    const { t } = useTranslation('screenRecorder');
    const allSelected = sources.every((source) => source.selected);
    const handleSelection = (source) => {
        onCaptureSourcesChanged(sources.map((s) => (s.id === source.id ? Object.assign(Object.assign({}, s), { selected: !source.selected }) : s)));
    };
    const handleSelectAll = () => {
        onCaptureSourcesChanged(sources.map((s) => (Object.assign(Object.assign({}, s), { selected: !allSelected }))));
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "space-between" },
            React.createElement(Typography, { variant: "h6" }, t('sourcesTitle', 'Sources')),
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: allSelected, onChange: handleSelectAll, name: "select-all" }), label: t('selectAllInputLabel', 'Select all') })),
        React.createElement(ImageList, { variant: "masonry", cols: 3, gap: 3, sx: { padding: 1, width: '100%' } }, sources.map((source) => {
            return (React.createElement(ImageListItem, { key: source.id, onClick: () => handleSelection(source), sx: {
                    borderWidth: source.selected ? 4 : 0,
                    borderColor: 'tomato',
                    borderStyle: 'solid',
                } },
                React.createElement("img", { src: source.thumbnail, alt: source.name, style: { filter: `blur(${source.selected ? 1 : 0}px)` } }),
                React.createElement(ImageListItemBar, { position: "bottom", title: source.id.includes('screen') ? source.id : source.name })));
        }))));
}
