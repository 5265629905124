import { gql } from '@apollo/client';
/**
 * Workaround for the CommonSpirit demo: fetching this information from core.
 * This query is for the different page - performance - but fits our needs.
 */
export const getFiltersQuery = gql `
  query missionControlFilters($organizationID: ID!) {
    performanceMetricsFilter(organizationID: $organizationID) {
      users {
        id
        name
      }
      teams {
        id
        name
        members {
          user {
            id
            name
          }
        }
      }
    }
  }
`;
