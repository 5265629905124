var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import ReactDOM from 'react-dom';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
//@ts-ignore
let DefaultResizeHandle = class DefaultResizeHandle extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "HANDLE_WIDTH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 4
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                handleXOffset: undefined,
            }
        });
        Object.defineProperty(this, "onMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ handleXOffset: this.getHandleXOffset(e) });
            }
        });
        Object.defineProperty(this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.isResizing) {
                    this.setState({ handleXOffset: undefined });
                }
            }
        });
        Object.defineProperty(this, "onMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.isResizing) {
                    this.setState({ handleXOffset: this.getHandleXOffset(e) });
                }
            }
        });
        Object.defineProperty(this, "getHandleXOffset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { tableControllerState, cellRef } = this.props;
                const { minColumnWidth, getTableContainerRef } = tableControllerState;
                const tableContainerRef = getTableContainerRef();
                if (tableContainerRef) {
                    const tableContainerRect = tableContainerRef.getBoundingClientRect();
                    const cellRect = cellRef.getBoundingClientRect();
                    const maxRight = tableContainerRect.right - tableContainerRect.left - this.HANDLE_WIDTH;
                    let maxLeft = cellRect.left - tableContainerRect.left + minColumnWidth;
                    maxLeft = maxLeft > 0 ? maxLeft : 0;
                    let handleXOffset = e.clientX - tableContainerRect.left;
                    handleXOffset = handleXOffset < maxLeft ? maxLeft : handleXOffset;
                    handleXOffset = handleXOffset > maxRight ? maxRight : handleXOffset;
                    return handleXOffset;
                }
            }
        });
    }
    componentDidMount() {
        document.body.addEventListener('mouseup', this.onMouseUp);
        document.body.addEventListener('mousemove', this.onMouseMove);
    }
    componentWillUnmount() {
        document.body.removeEventListener('mouseup', this.onMouseUp);
        document.body.removeEventListener('mousemove', this.onMouseMove);
    }
    get isResizing() {
        return this.state.handleXOffset !== undefined;
    }
    render() {
        const { handleXOffset } = this.state;
        const { tableControllerState, className, theme } = this.props;
        const { getResizeHandleProps, getTableContainerRef } = tableControllerState;
        const tableContainerRef = getTableContainerRef();
        return (React.createElement(React.Fragment, null,
            !this.isResizing && (React.createElement(Base, Object.assign({}, getResizeHandleProps(this.props.columnID, {
                onMouseDown: (e) => this.onMouseDown(e),
                onClick: (e) => e.stopPropagation(),
            }), { className: css({
                    width: this.HANDLE_WIDTH,
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'e-resize',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                }, className) }))),
            this.isResizing &&
                tableContainerRef &&
                ReactDOM.createPortal(React.createElement(Base, { className: css({
                        width: this.HANDLE_WIDTH,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: handleXOffset,
                        cursor: 'e-resize',
                        backgroundColor: theme.palette.primary.light,
                    }) }), tableContainerRef)));
    }
};
DefaultResizeHandle = __decorate([
    withTheme
], DefaultResizeHandle);
export { DefaultResizeHandle };
