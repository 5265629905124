import { config } from 'core/configuration/browser';
export class ZendeskWidgetService {
    constructor(zendeskKey) {
        Object.defineProperty(this, "show", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (params) => {
                // Zendesk core api isnt very well implemented
                // so on certain situations you cannot hide widget button
                // and have to do this manually by manipulating DOM
                const zendeskWidget = document.getElementById('launcher');
                if (zendeskWidget) {
                    zendeskWidget.style.display = 'none';
                }
                if (params) {
                    this.getInstance()('webWidget', 'prefill', {
                        name: { value: params.username },
                        email: { value: params.email },
                    });
                    if (params.rumSessionId) {
                        this.getInstance()('webWidget', 'updateSettings', {
                            webWidget: {
                                contactForm: {
                                    fields: [
                                        {
                                            id: config.getConfig()['externalServices:zendesk:rumSessionIdField'],
                                            prefill: { '*': params.rumSessionId },
                                        },
                                    ],
                                },
                            },
                        });
                    }
                }
                else {
                    this.getInstance()('webWidget', 'clear');
                }
                this.getInstance()('webWidget', 'show');
                this.getInstance()('webWidget', 'open');
            }
        });
        Object.defineProperty(this, "getInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return window['zE'];
            }
        });
        Object.defineProperty(this, "init", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (zendeskKey) => {
                if (this.getInstance()) {
                    console.error('Zendesk widget is already included.');
                }
                else {
                    const zendeskScript = document.createElement('script');
                    zendeskScript.type = 'text/javascript';
                    zendeskScript.async = !0;
                    zendeskScript.setAttribute('id', 'ze-snippet');
                    zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
                    const siblingScript = document.getElementsByTagName('script')[0];
                    siblingScript.parentNode.insertBefore(zendeskScript, siblingScript);
                    // We dont want to see the widget on the page load so it has to be hidden.
                    zendeskScript.onload = () => this.getInstance()('webWidget', 'hide');
                }
            }
        });
        if (zendeskKey) {
            this.init(zendeskKey);
        }
    }
    isReady() {
        return Boolean(this.getInstance());
    }
}
