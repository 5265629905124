import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { AttributeList, AttributeRendererStatic, CustomAttribute, GenericPanelBundle, } from 'lib/graphEditor/ui';
import { NodeSmartTarget } from '../canvas/views';
export const GraphInfoPanel = observer(function GraphInfoPanel(_props) {
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const { model: graphModel } = editor;
    return (React.createElement(GenericPanelBundle, { header: t('graphInfoPanel.title', 'Graph Info') },
        React.createElement(AttributeList, null,
            React.createElement(CustomAttribute, { name: t('graphInfoPanel.startNodeAttr', 'Start Node') }, graphModel.startNode ? (React.createElement(NodeSmartTarget, { nodeID: graphModel.startNode.id })) : (React.createElement(Typography, { color: "error" }, t('graphInfoPanel.startNode.noNode', 'Node not set')))),
            React.createElement(AttributeRendererStatic, { name: t('graphInfoPanel.nodeCountAttr', 'Node Count'), value: String(graphModel.branches.reduce((r, branch) => {
                    r += branch.nodes.size;
                    return r;
                }, 0)) }),
            React.createElement(AttributeRendererStatic, { name: t('graphInfoPanel.edgeCountAttr', 'Edge Count'), value: String(graphModel.branches.reduce((r, branch) => {
                    r += branch.links.length;
                    return r;
                }, 0)) }))));
});
