import { config } from 'core/configuration/browser';
export class SatismeterService {
    constructor() {
        if (config.getConfig()['externalServices:satismeter:key']) {
            this.initSatisMeter();
        }
    }
    identifyUser(user) {
        var _a;
        if ((_a = window['satismeter']) === null || _a === void 0 ? void 0 : _a.initialized) {
            window.satismeter({
                writeKey: config.getConfig()['externalServices:satismeter:key'],
                userId: user.id,
                traits: Object.assign({}, user),
            });
        }
        else {
            console.error('Satismeter is not initialized');
        }
    }
    initSatisMeter() {
        let satismeter = (window['satismeter'] =
            window['satismeter'] ||
                function () {
                    (window['satismeter'].q = window['satismeter'].q || []).push(arguments);
                });
        if (!satismeter.initialized) {
            satismeter.l = new Date().getTime();
            let script = document.createElement('script');
            let parent = document.getElementsByTagName('script')[0].parentNode;
            script.async = true;
            script.src = 'https://app.satismeter.com/satismeter.js';
            parent === null || parent === void 0 ? void 0 : parent.appendChild(script);
        }
        else {
            console.error('Satismeter snippet is already initialized.');
        }
    }
}
