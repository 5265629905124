import { FactValueTransformer } from '../../../observers/StateObserver/utils';
export const StateDeserializer = {
    deserializeStepAdded(event, flowStore) {
        const step = flowStore.stepStore.deserializeStep({
            id: event.data.step.id,
            nodeID: event.data.step.nodeID,
            prevStepID: event.data.step.prevStepID,
        });
        if (step) {
            return {
                step,
            };
        }
    },
    deserializeActiveStepChanged(event, flowStore) {
        const step = flowStore.stepStore.getStepByID(event.data.stepID);
        if (step) {
            return {
                step,
            };
        }
    },
    deserializeFactValuesUpdated(event) {
        return {
            factValues: event.data.factValues.map((it) => (Object.assign(Object.assign({}, it), { value: FactValueTransformer.toInternal(it.value) }))),
        };
    },
    deserializeBlockValuesUpdated(event) {
        return {
            blockValues: event.data.blockValues.map((it) => ({
                identifier: {
                    blockPrototypeID: it.identifier.blockID,
                    optionID: it.identifier.optionID,
                },
                value: it.value,
            })),
        };
    },
    deserializeValueCollectorBlockValuesUpdated(event, flowStore) {
        const block = flowStore.graphTraverser.getBlockByID(event.data.blockID);
        if (!block) {
            return;
        }
        return {
            collector: {
                blockInstance: block,
                items: event.data.items.map((it) => {
                    switch (it.valueType) {
                        case 'block': {
                            return Object.assign(Object.assign({}, it), { valueType: 'block', values: it.values.map((v) => ({
                                    identifier: {
                                        blockPrototypeID: v.blockID,
                                        optionID: v.optionID,
                                    },
                                    value: v.value,
                                })) });
                        }
                        case 'block-option': {
                            return Object.assign(Object.assign({}, it), { valueType: 'block-option', values: it.values.map((v) => ({
                                    identifier: {
                                        blockPrototypeID: v.blockID,
                                        optionID: v.optionID,
                                    },
                                    value: v.value,
                                })) });
                        }
                        case 'fact': {
                            return Object.assign(Object.assign({}, it), { valueType: 'fact', values: it.values.map((v) => ({
                                    factID: v.factID,
                                    value: FactValueTransformer.toInternal(v.value),
                                })) });
                        }
                        case 'custom': {
                            return Object.assign(Object.assign({}, it), { valueType: 'custom', values: it.values.map((v) => ({
                                    value: v.value,
                                })) });
                        }
                    }
                }),
            },
        };
    },
};
