import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { orderBy } from 'lodash';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, DefaultCellRenderer, DefaultRowRenderer, Table, TableColumn, TablePagination, Tooltip, Typography, } from 'lib/cortiUI';
import { transformRequestToCaseIDCellDescriptor, transformRequestToProtocolNameCellDescriptor, transformRequestToStatusCellDescriptor, transformRequestToTimeStampCellDescriptor, } from './TableDescriptorsTransformers';
export const UploadRequestTable = observer((props) => {
    const { uploadRequestsState } = props;
    const theme = useTheme();
    const tableRowsPerPageOptions = [5, 10, 25];
    const ROW_AND_HEADER_HEIGHT = 32;
    const [tableSortDirection, setTableSortDirection] = React.useState('ASC');
    const [tableSortBy, setTableSortBy] = React.useState('fileName');
    const [tableCurrentPage, setTableCurrentPage] = React.useState(0);
    const [tableRowsPerPage, setTableRowsPerPage] = React.useState(tableRowsPerPageOptions[0]);
    const sortedRows = getSortedTableBodyRows();
    function handleTableSort(props) {
        setTableSortBy(props.sortBy);
        setTableSortDirection(props.sortDirection);
    }
    function handleChangeTablePage(_e, page) {
        setTableCurrentPage(page);
    }
    function handleChangeTableRowsPerPage(e) {
        const selectedValue = parseInt(e.target.value, 10);
        setTableRowsPerPage(selectedValue);
        setTableCurrentPage(getPageOfCurrentTopRowId(selectedValue));
    }
    function getPageOfCurrentTopRowId(tableRowsPerPage) {
        const sortedAndPaginatedList = getSortedAndPaginatedTableBodyRows();
        const topRowFileName = sortedAndPaginatedList[0].id.value;
        const topRowIndex = sortedRows.findIndex((item) => item.id.value === topRowFileName);
        const page = topRowIndex / tableRowsPerPage;
        return Math.trunc(page);
    }
    function getThemeColor(color) {
        switch (color) {
            case 'error':
                return theme.palette.error.light;
            case 'warning':
                return theme.palette.warning.light;
            case 'success':
                return theme.palette.text.success;
            case 'secondary':
                return theme.palette.text.secondary;
            case 'primary':
            default:
                return theme.palette.text.primary;
        }
    }
    function getTimeStampCellDescriptor(request) {
        const descriptor = transformRequestToTimeStampCellDescriptor(request);
        return {
            text: descriptor.text ? formatDateTime(new Date(descriptor.text)) : '',
            color: getThemeColor(descriptor.color),
            error: descriptor.error,
        };
    }
    function getCaseIDCellDescriptor(request) {
        const descriptor = transformRequestToCaseIDCellDescriptor(request);
        return {
            text: descriptor.text,
            color: getThemeColor(descriptor.color),
            error: descriptor.error,
        };
    }
    function getProtocolNameCellDescriptor(request) {
        const descriptor = transformRequestToProtocolNameCellDescriptor(request);
        return {
            text: descriptor.text,
            color: getThemeColor(descriptor.color),
            error: descriptor.error,
        };
    }
    function getStatusCellDescriptor(request) {
        const descriptor = transformRequestToStatusCellDescriptor(request);
        return {
            text: uploadRequestsState.isUploading || uploadRequestsState.hasUploaded ? descriptor.text : '',
            color: getThemeColor(descriptor.color),
            error: descriptor.error,
        };
    }
    const getSortedAndPaginatedTableBodyRows = () => {
        const pageStartIdx = tableCurrentPage * tableRowsPerPage;
        const pageEndIdx = tableCurrentPage * tableRowsPerPage + tableRowsPerPage;
        return sortedRows.slice(pageStartIdx, pageEndIdx);
    };
    function getSortedTableBodyRows() {
        return orderBy(getTableRows(), (data) => {
            switch (tableSortBy) {
                default:
                case 'fileName':
                    return data.fileName.value.toString().toLowerCase();
                case 'size':
                    return data.size.value.toString().toLowerCase();
                case 'timeStamp':
                    return new Date(data.timeStamp.value);
                case 'caseID':
                    return data.caseID.value.toString().toLowerCase();
                case 'status':
                    return data.status.value.toString().toLowerCase();
            }
        }, tableSortDirection.toLocaleLowerCase());
    }
    function getTableRows() {
        return uploadRequestsState.uploadRequests.map(createTableRow);
    }
    function createTableRow(request) {
        const timeStampDescriptor = getTimeStampCellDescriptor(request);
        const caseIDDescriptor = getCaseIDCellDescriptor(request);
        const protocolNameDescriptor = getProtocolNameCellDescriptor(request);
        const statusDescriptor = getStatusCellDescriptor(request);
        return {
            id: {
                value: request.id,
            },
            fileName: {
                value: request.file.name,
                color: theme.palette.text.primary,
            },
            size: {
                value: String(request.file.size),
                color: theme.palette.text.primary,
            },
            timeStamp: {
                value: timeStampDescriptor.text,
                color: timeStampDescriptor.color,
                error: timeStampDescriptor.error,
            },
            caseID: {
                value: caseIDDescriptor.text,
                color: caseIDDescriptor.color,
                error: caseIDDescriptor.error,
            },
            protocolName: {
                value: protocolNameDescriptor.text,
                color: protocolNameDescriptor.color,
                error: protocolNameDescriptor.error,
            },
            status: {
                value: statusDescriptor.text,
                color: statusDescriptor.color,
            },
        };
    }
    const columns = [
        {
            id: 'fileName',
            label: intl.t('explore:callsUploadView.labelFilename', 'Filename'),
            width: 180,
        },
        { id: 'size', label: intl.t('explore:callsUploadView.labelSize', 'Size'), width: 100 },
        {
            id: 'timeStamp',
            label: intl.t('explore:callsUploadView.labelDate', 'Date/Time'),
            width: 150,
        },
        { id: 'caseID', label: intl.t('explore:callsUploadView.labelCaseID', 'Case ID'), width: 250 },
        {
            id: 'protocolName',
            label: intl.t('explore:callsUploadView.labelProtocolName', 'Protocol Name'),
            width: 150,
        },
        { id: 'status', label: intl.t('explore:callsUploadView.labelStatus', 'Status'), width: 150 },
    ];
    return (React.createElement(Observer, null, () => {
        const { uploadRequests } = uploadRequestsState;
        const rows = getSortedAndPaginatedTableBodyRows();
        const tableHeight = rows.length < tableRowsPerPage
            ? ROW_AND_HEADER_HEIGHT * (rows.length + 1)
            : ROW_AND_HEADER_HEIGHT * (tableRowsPerPage + 1);
        return (React.createElement(React.Fragment, null,
            React.createElement(Base, { display: "grid", height: "100%", className: css(getScrollerCss({ theme }), { overflowX: 'visible' }) },
                React.createElement(Table, { rows: rows, columns: columns, headerHeight: ROW_AND_HEADER_HEIGHT, rowHeight: ROW_AND_HEADER_HEIGHT, height: tableHeight, disableResize: true, sort: handleTableSort, sortBy: tableSortBy, sortDirection: tableSortDirection, rowRenderer: (props) => (React.createElement(DefaultRowRenderer, { "data-cy": "table-body-row", key: props.index, requiredProps: props, className: css({
                            cursor: 'pointer',
                        }) })) }, ({ columns }) => {
                    return columns.map((col, idx) => {
                        return (React.createElement(TableColumn, { key: idx, flexGrow: col.width, width: col.width, label: col.label, dataKey: col.id, cellRenderer: (props) => (React.createElement(DefaultCellRenderer, { requiredProps: props, valueRenderer: () => (React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
                                    props.cellData.error && (React.createElement(Tooltip, { disableInteractive: true, title: props.cellData.error },
                                        React.createElement(ErrorRoundedIcon, { "data-cy": "table-row-cell-adornment", fontSize: "small", className: css({ color: props.cellData.color }) }))),
                                    React.createElement(Typography, { "data-cy": `table-body-row-cell-${col.id}`, color: "default", variant: "caption" }, props.cellData.value))) })) }));
                    });
                })),
            React.createElement(TablePagination, { rowsPerPageOptions: tableRowsPerPageOptions, component: "div", count: uploadRequests.length, rowsPerPage: tableRowsPerPage, labelRowsPerPage: intl.t('explore:callsUploadView.labelRowsPerPage', 'Rows per page'), labelDisplayedRows: ({ from, to, count }) => {
                    return intl.t('explore:callsUploadView.labelPageNavigation', {
                        from,
                        to,
                        count,
                        defaultValue: `{{from}}-{{to}} of {{count}}`,
                    });
                }, page: tableCurrentPage, onPageChange: handleChangeTablePage, onRowsPerPageChange: handleChangeTableRowsPerPage, 
                // TS throws errors and for some reason cant ignore the two string
                // values below when they are assigned to the 'data-cy' keys.
                // For that reason values are assigned to the 'name' keys.
                backIconButtonProps: {
                    name: 'table-prev-page-button',
                }, nextIconButtonProps: {
                    name: 'table-next-page-button',
                }, SelectProps: {
                    // @ts-ignore
                    'data-cy': 'table-rows-per-page-button',
                    MenuProps: {
                        MenuListProps: {
                            // @ts-ignore
                            'data-cy': 'table-rows-per-page-menu',
                        },
                    },
                }, classes: {
                    toolbar: css({ padding: 0 }),
                    select: css({ minWidth: 20 }),
                    selectRoot: css({ marginRight: theme.space[4] }),
                    actions: css({ marginLeft: theme.space[4] }),
                    selectLabel: css({
                        minWidth: 110,
                        textAlign: 'center',
                    }),
                    displayedRows: css({
                        minWidth: 110,
                        textAlign: 'center',
                    }),
                } })));
    }));
});
