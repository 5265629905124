import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { Base, IconButton, Menu } from 'lib/cortiUI';
import { Delete } from './Delete';
import { Duplicate } from './Duplicate';
import { Edit } from './Edit';
export const Actions = ({ templateID, published, onTemplateDelete }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    return (React.createElement(Base, null,
        !published && React.createElement(Edit, { templateID: templateID }),
        React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget), icon: React.createElement(MoreHorizIcon, null) }),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
            React.createElement(Duplicate, { templateID: templateID }),
            React.createElement(Delete, { templateID: templateID, published: published, onDelete: () => {
                    setMenuAnchorEl(null);
                    onTemplateDelete(templateID);
                } }))));
};
