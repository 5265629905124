import React from 'react';
import { Base, Card, CardContent, Skeleton } from 'lib/cortiUI';
export const ComplianceTrackersLoader = () => {
    const cardsArray = [0, 1, 2];
    return (React.createElement(Base, { display: "flex", "justify-content": "space-between", gap: 3, width: "100%" }, cardsArray.map((num) => (React.createElement(Card, { flex: 1, key: num },
        React.createElement(CardContent, null,
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
                React.createElement(Skeleton, { variant: "text", width: "60%" }),
                React.createElement(Base, { width: "100%", flexDirection: "column", justifyContent: "stretch", gap: 2, mb: 4 },
                    React.createElement(Base, { display: "flex", gap: 2 },
                        React.createElement(Skeleton, { variant: "text", sx: { flex: 1 } }),
                        React.createElement(Skeleton, { variant: "text", sx: { flex: 2 } })),
                    React.createElement(Skeleton, { variant: "text" })),
                React.createElement(Skeleton, { variant: "rectangular", height: 20, width: "100%" }))))))));
};
