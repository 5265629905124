import { useLazyQuery, useQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import { intl, useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Route } from 'browser/containers/Route';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, FallbackView, Modal, Sidebar, SidebarActionButton, SidebarItem, SidebarSection, State, Tag, Typography, UnstyledLink, } from 'lib/cortiUI';
import { GraphDetailsView } from '../graphdetails/GraphDetailsView';
import { MediaLibraryPage } from '../mediaLibrary/view/MediaLibraryPage';
import { CreateGraphView } from './CreateGraphView';
import { getActiveProtocolGraphIDQuery, getProtocolGraphsQuery, } from './graphql';
export const GraphListView = observer(function GraphListView() {
    var _a;
    const theme = useTheme();
    const match = useRouteMatch();
    const authStore = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const getGraphsResult = useQuery(getProtocolGraphsQuery, {
        variables: { orgID: authStore.organization.id },
    });
    const [getActiveGraphID, activeGraphIDQuery] = useLazyQuery(getActiveProtocolGraphIDQuery);
    React.useEffect(() => {
        var _a, _b;
        if (match.isExact && ((_a = activeGraphIDQuery.data) === null || _a === void 0 ? void 0 : _a.protocolGraphVersion.graph.id)) {
            history.replace(`${match.url}/${(_b = activeGraphIDQuery.data) === null || _b === void 0 ? void 0 : _b.protocolGraphVersion.graph.id}`, history.location.state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, activeGraphIDQuery]);
    React.useEffect(() => {
        var _a;
        if (authStore.organization.triageSettings.activeProtocolGraphVersionID) {
            getActiveGraphID({
                variables: {
                    versionID: (_a = authStore.organization.triageSettings.activeProtocolGraphVersionID) !== null && _a !== void 0 ? _a : '',
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.organization.triageSettings.activeProtocolGraphVersionID]);
    React.useEffect(() => {
        var _a;
        if (((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.refresh) === true) {
            refreshGraphList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state]);
    const refreshGraphList = () => {
        void getGraphsResult.refetch();
    };
    if (getGraphsResult.error) {
        return null;
    }
    const protocolGraphItems = orderBy((_a = getGraphsResult.data) === null || _a === void 0 ? void 0 : _a.protocolGraphs, (it) => it.name.toLowerCase());
    return (React.createElement(Base, { height: '100%', display: "flex", flexDirection: "row", overflowY: "hidden" },
        React.createElement(Sidebar, { defaultMode: "opened-perm" },
            React.createElement(SidebarSection, null,
                React.createElement(UnstyledLink, { disabled: !rbacService.mediaLibraryCRUD(), to: `${match.url}/media-library` }, ({ isActive }) => (React.createElement(SidebarItem, { disabled: !rbacService.mediaLibraryCRUD(), selected: isActive }, intl.t('mediaLibrary:title', 'Media Library'))))),
            React.createElement(SidebarSection, { role: "list", title: t('editorApp2:graphListView.graphListSectionTitle', 'All Graphs'), actions: React.createElement(State, { initialState: false }, ([modalOpen, setModalOpen]) => (React.createElement(React.Fragment, null,
                    React.createElement(SidebarActionButton, { "data-cy": "create-new-graph-button", tooltipTitle: t('editorApp2:graphListView.createGraphBtnTooltip', 'Create new graph'), icon: React.createElement(AddRoundedIcon, { fontSize: "small" }), onClick: () => {
                            setModalOpen(true);
                        } }),
                    React.createElement(Modal, { open: modalOpen, onClose: () => setModalOpen(false) },
                        React.createElement(CreateGraphView, { onCancel: () => setModalOpen(false) }))))) }, getGraphsResult.error ? (React.createElement(Typography, { variant: "body2", color: "error" }, t('editorApp2:graphListView.fetchErrorMsg', 'Error'))) : (protocolGraphItems
                .sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, { numeric: true });
            })
                .map((protocolGraph) => {
                return (React.createElement(UnstyledLink, { "data-cy": "graph-list-item", key: protocolGraph.id, to: `${match.url}/${protocolGraph.id}` }, ({ isActive }) => {
                    var _a;
                    return (React.createElement(SidebarItem, { size: "small", selected: isActive },
                        React.createElement(Base, { display: "flex", alignItems: "center" },
                            React.createElement(Typography, { color: "inherit", variant: "body2" }, protocolGraph.name),
                            ((_a = activeGraphIDQuery.data) === null || _a === void 0 ? void 0 : _a.protocolGraphVersion.graph.id) ===
                                protocolGraph.id && (React.createElement(Tag, { ml: 4, text: t('editorApp2:graphListView.activeGraphTag', 'Active'), color: theme.palette.success.dark })))));
                }));
            })))),
        React.createElement(Base, { flexGrow: 1 },
            React.createElement(Switch, null,
                React.createElement(Route, { path: match.path, exact: true, render: () => {
                        var _a;
                        if (!getGraphsResult.loading &&
                            getGraphsResult.called &&
                            !getGraphsResult.error &&
                            ((_a = getGraphsResult.data) === null || _a === void 0 ? void 0 : _a.protocolGraphs.length) === 0) {
                            return React.createElement(EmptyGraphsListView, null);
                        }
                        return null;
                    } }),
                React.createElement(Route, { breadcrumb: intl.t('mediaLibrary:title', 'Media Library'), isAllowed: rbacService.mediaLibraryCRUD(), path: `${match.path}/media-library`, render: () => React.createElement(MediaLibraryPage, null) }),
                React.createElement(Route, { path: `${match.path}/:graphID`, render: ({ match }) => React.createElement(GraphDetailsView, { graphID: match.params.graphID }) })))));
});
function EmptyGraphsListView() {
    const [modalOpen, setModalOpen] = React.useState(false);
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(FallbackView, { "data-cy": "no-graphs-fallback-view", title: t('editorApp2:graphListView.fallbackTextTitle', 'No content'), text: t('editorApp2:graphListView.fallbackTextSubtitle', 'You do not have any graphs in your organization'), action: () => {
                setModalOpen(true);
            }, actionText: t('editorApp2:graphListView.fallbackActionCreateGraph', 'Create your first graph') }),
        React.createElement(Modal, { open: modalOpen, onClose: () => setModalOpen(false) },
            React.createElement(CreateGraphView, { onCancel: () => setModalOpen(false) }))));
}
