import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Chip } from 'lib/cortiUI';
export const AcknowledgementChip = ({ status }) => {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'acknowledgementChip' });
    const { palette } = useTheme();
    let color = palette.grey[300];
    let label = '';
    switch (status) {
        case 'NONE':
        case 'NO_AGENT_INPUT':
            color = 'default';
            label = t('pending', 'No Agent Input');
            break;
        case 'REVIEW_REQUESTED':
            color = palette.warning.light;
            label = t('flagged', 'Review Requested');
            break;
        case 'ACKNOWLEDGEMENT_REQUESTED':
            color = palette.primary.light;
            label = t('ackRequested', 'Acknowledgement Requested');
            break;
        default:
            color = palette.success.light;
            label = t('acknowledged', 'Acknowledged');
            break;
    }
    return (React.createElement(Chip, { variant: "outlined", size: "small", borderColor: color, className: css({
            color: color,
        }), label: label }));
};
