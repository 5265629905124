import React from 'react';
import { useHistory } from 'react-router';
import { Button, FileInput } from 'lib/cortiUI';
import { saveCurrentGraph } from './storage';
export function PreloadFromFilePage() {
    const history = useHistory();
    return (React.createElement(FileInput, { accept: 'application/json', onSelectFiles: async (files) => {
            if (!files) {
                throw new Error('no files selected');
            }
            const f = files[0];
            try {
                const g = JSON.parse(await f.text());
                await saveCurrentGraph(g);
                history.push('/emergency-triage');
            }
            catch (err) {
                console.error(err);
            }
        } }, ({ selectFiles }) => (React.createElement(Button, { onClick: async () => {
            selectFiles();
        } }, 'Upload JSON'))));
}
