var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo, useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { uuid } from '@corti/uuid';
import { useChecklistView } from '../useChecklistView';
function parseChecklists(json, existingKeys, invalidError) {
    // Only accept objects or arrays
    if (typeof json !== 'object' || json === null) {
        throw new Error(invalidError);
    }
    const input = Array.isArray(json) ? json : [json];
    const parseCollectors = (item) => {
        if (!item ||
            typeof item !== 'object' ||
            !('collectors' in item) ||
            !item.collectors ||
            !Array.isArray(item.collectors)) {
            return [];
        }
        return item.collectors.map((collector) => {
            if (typeof collector !== 'object' || collector === null || !('prototypeID' in collector)) {
                return { prototypeID: '', customValueFormat: '' };
            }
            return {
                prototypeID: String(collector.prototypeID || ''),
                customValueFormat: String('customValueFormat' in collector ? collector.customValueFormat : ''),
            };
        });
    };
    // Validate all as checklists
    const checklists = input.map((item) => {
        const errors = new Set();
        // A checklist must have name, and entries, and can have other optional properties
        const { name, entries: sourceEntries } = item, rest = __rest(item, ["name", "entries"]);
        if (!name)
            errors.add('no-name');
        const { key, description, visibilityConditionEnabled, visibilityConditionExpression } = rest;
        if (key && existingKeys.includes(key))
            errors.add('duplicate-key');
        // Loop over entries and make sure they're valid
        const entries = (Array.isArray(sourceEntries) ? sourceEntries : []).map((entry, entryIndex) => {
            // Entries must have label, and can have other optional properties
            const { label, key, details, manualCompletionEnabled, automaticCompletionEnabled, automaticCompletionExpression, hasInlineNode, inlineNodeID, } = entry;
            // Generate id and rank for each and return
            return {
                id: uuid(),
                rank: entryIndex,
                label: label ? String(label) : '',
                key: key ? String(key) : '',
                details: details ? String(details) : '',
                manualCompletionEnabled: Boolean(manualCompletionEnabled),
                automaticCompletionEnabled: Boolean(automaticCompletionEnabled),
                automaticCompletionExpression: String(automaticCompletionExpression),
                hasInlineNode: Boolean(hasInlineNode),
                inlineNodeID: inlineNodeID ? String(inlineNodeID) : '',
                collectors: parseCollectors(entry),
            };
        });
        // Generate id for each and return
        const list = {
            id: uuid(),
            name: name ? String(name) : '',
            entries,
            key: key ? String(key) : '',
            description: description ? String(description) : '',
            visibilityConditionEnabled: Boolean(visibilityConditionEnabled),
            visibilityConditionExpression: String(visibilityConditionExpression),
            collectors: parseCollectors(item),
        };
        return {
            list,
            hasError: errors.size > 0,
            errors: Array.from(errors),
        };
    });
    return checklists;
}
export function useErrorMap() {
    const { t } = useTranslation('libGraphEditor');
    return {
        'duplicate-key': t('checklist.import.duplicateKey', 'Checklist with this key already exists'),
        'no-name': t('checklist.import.noName', 'Checklist has no name'),
    };
}
export function useJSONImport() {
    const { t } = useTranslation('libGraphEditor');
    const invalidError = t('checklist.import.invalidJSONType', 'JSON must be a single checklist or an array of checklists');
    const [result, setResult] = useState(null);
    const existingChecklists = useChecklistView((state) => state.checklists);
    const existingKeys = useMemo(() => existingChecklists.filter(({ key }) => key).map((checklist) => checklist.key), [existingChecklists]);
    const setRawJSON = (rawJSON) => {
        try {
            const json = JSON.parse(rawJSON);
            const checklists = parseChecklists(json, existingKeys, invalidError);
            const validChecklists = checklists.filter((checklist) => !checklist.hasError).length;
            setResult({ hasError: false, validChecklists, checklists });
        }
        catch (e) {
            setResult({
                hasError: true,
                errorMessage: e instanceof Error
                    ? e.message
                    : t('checklist.import.unknownImportError', 'Unknown import error'),
            });
        }
    };
    return { result, setRawJSON };
}
