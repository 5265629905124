import { gql } from '@apollo/client';
import { Decoder } from './decoder';
const graphNodeTargetFragment = gql `
  fragment GraphNodeTargetFragment on ProtocolGraphBranchNodeTarget {
    branchID
    nodeID
  }
`;
const graphFragment = gql `
  fragment GraphFragment on ProtocolGraphVersionObject {
    version {
      id
      valid
      updatedAt
      name
      graph {
        id
        name
      }
      release {
        releasedAt
      }
    }
    serializedData
    metadata {
      serializedEditorApp
    }
  }
`;
export const protocolGraphService = (client) => ({
    async getGraph(versionID) {
        const res = await client.query({
            query: gql `
        query getGraph($versionID: String!) {
          protocolGraphVersionObject(versionID: $versionID) {
            ...GraphFragment
          }
        }
        ${graphFragment}
      `,
            variables: {
                versionID,
            },
            fetchPolicy: 'no-cache',
        });
        const rawGraph = res.data.protocolGraphVersionObject;
        const graph = {
            version: rawGraph.version,
            data: Decoder.fromCompressedBase64JSON(rawGraph.serializedData),
            metadata: {
                editorApp: rawGraph.metadata.serializedEditorApp
                    ? Decoder.fromCompressedBase64JSON(rawGraph.metadata.serializedEditorApp)
                    : undefined,
            },
        };
        return graph;
    },
    async ensureGraph(input) {
        await client.mutate({
            mutation: gql `
        mutation commitProtocolGraphVersionObject($input: ProtocolGraphVersionObjectCommitInput!) {
          commitProtocolGraphVersionObject(input: $input) {
            id
          }
        }
      `,
            variables: {
                input: {
                    versionID: input.versionID,
                    object: {
                        data: input.graph.data,
                        metadata: input.graph.metadata,
                    },
                    commit: {
                        message: input.commitMessage,
                    },
                },
            },
        });
    },
    async validateGraph(input) {
        const res = await client.query({
            query: gql `
        query validateProtocolGraph($input: ProtocolGraphInput!) {
          validateProtocolGraph(input: $input) {
            valid
            error
          }
        }
      `,
            variables: {
                input,
            },
        });
        return res.data.validateProtocolGraph.valid;
    },
    async determineGraphNextNode(input) {
        const res = await client.query({
            query: gql `
        query getNextGraphNode(
          $graphID: String!
          $currentBranchNodeTarget: ProtocolGraphBranchNodeTargetInput!
          $gateExpressionIdentifierValues: [ProtocolGraphGateExpressionIdentifierValueInput!]!
        ) {
          determineNextProtocolGraphNode(
            graphID: $graphID
            currentBranchNodeTarget: $currentBranchNodeTarget
            gateExpressionIdentifierValues: $gateExpressionIdentifierValues
          ) {
            ...GraphNodeTargetFragment
          }
        }
        ${graphNodeTargetFragment}
      `,
            variables: Object.assign({}, input),
        });
        return res.data.determineNextProtocolGraphNode;
    },
    async getGraphVersion(graphVersionID) {
        const res = await client.query({
            query: gql `
        query ProtocolGraphVersion($id: String!) {
          protocolGraphVersion(id: $id) {
            id
            valid
            updatedAt
          }
        }
      `,
            variables: { id: graphVersionID },
            fetchPolicy: 'no-cache',
        });
        return res.data.protocolGraphVersion;
    },
});
