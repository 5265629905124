import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Modal, ModalContent, ModalHeader, Typography } from 'lib/cortiUI';
export const NavigationBlockerModal = ({ isBlocked, onCancelNavigation, onProceedNavigation, }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(Modal, { open: isBlocked, hideCloseButton: true, style: { padding: 0 }, size: "small" },
        React.createElement(Base, null,
            React.createElement(ModalHeader, { title: t('navigationWarningAlert.title', 'Are you sure you would like to leave?') }),
            React.createElement(ModalContent, { pb: 4 },
                React.createElement(Typography, { variant: "body2", color: "default" }, t('navigationWarningAlert.message', 'You will loose changes if you have not published them.')),
                React.createElement(Base, { size: "small", display: "flex", alignItems: "center", justifyContent: "flex-end", p: 5, mx: "auto" },
                    React.createElement(Button, { color: "warning", onClick: onProceedNavigation, sx: { marginRight: 5 } }, t('navigationWarningAlert.leave', 'Leave')),
                    React.createElement(Button, { size: "small", onClick: onCancelNavigation }, t('navigationWarningAlert.stay', 'Stay')))))));
};
