var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUILinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { css } from '@corti/style';
/**
 * Component displays progress, loading state or specific value in linear bar
 */
function LinearProgress(props) {
    const { classes, animationTransition = true } = props, rest = __rest(props, ["classes", "animationTransition"]);
    return (React.createElement(MUILinearProgress, Object.assign({}, rest, { classes: Object.assign({ bar: css(animationTransition ? undefined : { transition: 'none' }) }, classes) })));
}
export { LinearProgress };
