import { cloneStyles } from '@corti/dom';
function generateIframe(content, style) {
    const head = document.head.cloneNode(true);
    const iframe = document.createElement('iframe');
    iframe.style.height = `0px`;
    iframe.style.width = `0px`;
    iframe.style.position = 'fixed';
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const iframeHTML = iframeDoc.getElementsByTagName('html')[0];
    const iframeBody = iframeDoc.body;
    iframeHTML.removeChild(iframeDoc.head);
    const iframeHead = iframeHTML.insertBefore(head, iframeBody);
    cloneStyles(document, iframeDoc);
    iframeBody.appendChild(content);
    iframeHead.appendChild(style);
    return {
        iframe,
        iframeHTML,
    };
}
export function print(content, style, options) {
    var _a;
    const { iframe } = generateIframe(content, style);
    const originalTitle = document.title;
    if (options === null || options === void 0 ? void 0 : options.pageSize) {
        const pageSizeRule = `@page { size: ${options.pageSize.width}px ${options.pageSize.height}px; margin: 0 }`;
        style.sheet.insertRule(pageSizeRule);
    }
    if (options === null || options === void 0 ? void 0 : options.documentTitle) {
        document.title = options.documentTitle;
    }
    (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.print();
    if (options === null || options === void 0 ? void 0 : options.documentTitle) {
        document.title = originalTitle;
    }
    document.body.removeChild(iframe);
}
