import { observer } from 'mobx-react';
import React from 'react';
import { Base, Typography } from 'lib/cortiUI';
export const ChangelogWidget = observer(function ChangelogWidget({ changelog }) {
    return (React.createElement(Base, null,
        React.createElement(Base, { display: 'flex', flexDirection: "column", gap: 3 }, changelog.changes.map((change) => {
            return (React.createElement(Typography, { key: change.commandID },
                change.variant === 'revert' ? '[revert] ' : '',
                change.type));
        }))));
});
