import React from 'react';
/**
 * Hook to track if component did mount.
 * Useful when there's a need to prevent functions from running on initial render.
 */
export function useDidMount() {
    const [result, setResult] = React.useState(false);
    React.useEffect(() => {
        setResult(true);
    }, []);
    return result;
}
