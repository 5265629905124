import { env } from 'core/environment';
import { normalizeEvent } from './normalize';
const preventedOnce = [];
export function preventReportingOnce(error, eventID) {
    preventedOnce.push({ error, eventID });
}
export const getOptions = ({ appPath, sentryKey, apiHost, } = {}) => {
    return {
        enabled: Boolean(sentryKey),
        dsn: sentryKey,
        release: env.buildInfo.sentryRelease,
        environment: env.buildInfo.buildType,
        beforeSend: async (event, hint) => {
            if (hint === null || hint === void 0 ? void 0 : hint.originalException) {
                const preventedItem = preventedOnce.find((p) => p.error === hint.originalException);
                if (preventedItem && (!preventedItem.eventID || preventedItem.eventID !== event.event_id)) {
                    preventedOnce.splice(preventedOnce.indexOf(preventedItem), 1);
                    return null;
                }
            }
            event.tags = Object.assign(Object.assign({}, event.tags), { version: env.buildInfo.version, platform: env.buildInfo.platform, apiHost: apiHost || '' });
            normalizeEvent(event, appPath);
            return event;
        },
    };
};
