var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseEntity } from '../core';
export class BaseGraphEditorLibraryComponent extends BaseEntity {
    constructor(input) {
        var _a;
        super();
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "instances", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_wrappedItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.id = (_a = input.id) !== null && _a !== void 0 ? _a : this.id;
        if (input.wrappedItem) {
            this.setWrappedItem(input.wrappedItem);
        }
        runInAction(() => {
            var _a;
            this.name = (_a = input.name) !== null && _a !== void 0 ? _a : '';
            this.instances = new Map();
        });
    }
    setWrappedItem(item) {
        this._wrappedItem = item;
        item.setParent(this);
    }
    get wrappedItem() {
        return this._wrappedItem;
    }
    setName(name) {
        this.name = name;
    }
    registerInstance(instance) {
        this.instances.set(instance.id, instance);
    }
    unregisterInstance(instance) {
        this.instances.delete(instance.id);
    }
    deserialize(input) {
        super.deserialize(input);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { name: this.name });
    }
    remove() {
        var _a;
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.removeComponent(this);
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], BaseGraphEditorLibraryComponent.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], BaseGraphEditorLibraryComponent.prototype, "instances", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorLibraryComponent.prototype, "setName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [void 0]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorLibraryComponent.prototype, "registerInstance", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [void 0]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorLibraryComponent.prototype, "unregisterInstance", null);
