import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Separator, Typography } from 'lib/cortiUI';
export const FilesDropArea = React.forwardRef(({ isDragging, children: uploadTrigger }, ref) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'dragAndDrop' });
    const { palette } = useTheme();
    return (React.createElement(Base, { ref: ref, display: "flex", flexDirection: "column", alignItems: "center", gap: 4, borderRadius: 4, border: `2px ${palette.grey[300]} dashed`, position: "relative", p: 6, overflow: "hidden" },
        isDragging && (React.createElement(Base, { ref: ref, width: "100%", height: "100%", position: "absolute", zIndex: 9999, top: 0, left: 0, right: 0, bottom: 0, background: transparentize(0.05, palette.grey[600]), display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
            React.createElement(Typography, { variant: "subtitle1", color: "inverted" }, t('overlayTitle', 'Drop your file here...')))),
        React.createElement(UploadFileRoundedIcon, { fontSize: "large" }),
        React.createElement(Typography, null, t('description', 'Drag your document here to start uploading')),
        React.createElement(Base, { display: "flex", gap: 3, alignItems: "center" },
            React.createElement(Separator, { horizontal: true, width: 50 }),
            React.createElement(Typography, null, t('or', 'OR')),
            React.createElement(Separator, { horizontal: true, width: 50 })),
        uploadTrigger));
});
