import styled from '@emotion/styled';
import { Base } from 'lib/cortiUI';
export const BarContainer = styled(Base)({
    width: '100%',
    height: '8px',
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: 'rgba(224, 223, 223, 0)',
});
export const BarSection = styled(Base)(({ width, bgcolor }) => ({
    height: '100%',
    width: `${width}%`,
    backgroundColor: bgcolor,
    transition: 'width 0.3s ease-in-out',
    borderRadius: '99px',
}));
