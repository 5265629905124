import { subHours, subMilliseconds, subMinutes, subSeconds } from 'date-fns';
export function nulifySecMillisec(date) {
    const subMs = subMilliseconds(date, date.getMilliseconds());
    return subSeconds(subMs, subMs.getSeconds());
}
/**
 * Returns date without time in unix timestamp
 */
export function getDateInMs(date) {
    let res = nulifySecMillisec(date);
    res = subMinutes(res, res.getMinutes());
    res = subHours(res, res.getHours());
    return res.getTime();
}
/**
 * Returns time without date in unix timestamp
 */
export function getTimeInMs(date) {
    const h = date.getHours() * 60 * 60 * 1000;
    const m = date.getMinutes() * 60 * 1000;
    const s = date.getSeconds() * 1000;
    const ms = date.getMilliseconds();
    return h + m + s + ms;
}
