var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
export function ListItem(props) {
    const theme = useTheme();
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    const isDark = theme.palette.mode === 'dark';
    const hoverColor = isDark ? `rgba(255, 255, 255, 0.04)` : `rgba(0, 0, 0, 0.04)`;
    return (React.createElement(Base, Object.assign({ py: 2, px: 3, role: "button", tabIndex: 0, className: css({
            position: 'relative',
            userSelect: 'none',
            cursor: 'pointer',
            ':hover': {
                background: props.background ? undefined : hoverColor,
                filter: props.background ? 'brightness(1.3)' : undefined,
            },
        }, className) }, rest), children));
}
