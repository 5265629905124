import { BlockValueUtils } from 'lib/triageFlow';
import { getReadableCollectorItems } from 'lib/triageFlow/models/CollectorModel';
export function createStep(step, flowStore) {
    const prevStep = step.prevStepID ? flowStore.stepStore.getStepByID(step.prevStepID) : undefined;
    const title = step.branch.id !== (prevStep === null || prevStep === void 0 ? void 0 : prevStep.branch.id) ? step.branch.name : undefined;
    return {
        id: step.id,
        title,
        fields: createFields(step, flowStore),
    };
}
function createFields(step, flowStore) {
    const result = {};
    step.node.view.blocks.forEach((block) => {
        var _a;
        const { blockPrototype } = block;
        switch (blockPrototype.type) {
            case 'PARAGRAPH': {
                result[block.id] = {
                    id: block.id,
                    text: blockPrototype.text.richText || blockPrototype.text.plainText,
                    type: 'paragraph',
                };
                break;
            }
            case 'SELECT': {
                result[block.id] = {
                    id: block.id,
                    label: blockPrototype.label.richText || blockPrototype.label.plainText,
                    values: (_a = blockPrototype.options) === null || _a === void 0 ? void 0 : _a.map((it) => {
                        const value = flowStore.blockValueStore.getByID(it.id);
                        if (value && BlockValueUtils.isTruthy(value)) {
                            return it.text.richText || it.text.plainText;
                        }
                    }).filter((it) => !!it),
                    type: 'question',
                };
                break;
            }
            case 'FLOW_VALUE_COLLECTOR': {
                const col = flowStore.collectorStore.getCollectorInstanceByID(block.id);
                if (!col) {
                    return;
                }
                result[block.id] = {
                    id: block.id,
                    values: getReadableCollectorItems(col, flowStore).map(({ text }) => text),
                    type: 'question',
                };
                break;
            }
            case 'DATE_PICKER': {
                const value = flowStore.blockValueStore.getByID(blockPrototype.id);
                if (!(value && value.value)) {
                    return;
                }
                result[block.id] = {
                    id: block.id,
                    label: blockPrototype.label.richText || blockPrototype.label.plainText,
                    values: [flowStore.formatDate(new Date(value.value))],
                    type: 'question',
                };
                break;
            }
            default: {
                const value = flowStore.blockValueStore.getByID(blockPrototype.id);
                if (!(value && value.value)) {
                    return;
                }
                result[block.id] = {
                    id: block.id,
                    label: 'label' in blockPrototype
                        ? blockPrototype.label.richText || blockPrototype.label.plainText
                        : '',
                    values: [value.value],
                    type: 'question',
                };
            }
        }
    });
    return Object.values(result);
}
