import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { setThresholds } from '@corti/mission-control-api';
import { Base, Modal, ModalContent, ModalHeader, Separator, Typography } from 'lib/cortiUI';
import { useTemplate } from '../../context';
import { DragIndicator, OpenModalAction, ResetAction, SaveAction } from './Actions';
import { ThresholdsForm } from './ThresholdsForm';
import { ThresholdsSlider } from './ThresholdsSlider';
import { getDefaultThresholds, getErrorMessages, notifyError, notifySuccess } from './utils';
export const ThresholdsTrigger = ({ disabled, initialThresholds, }) => {
    const { t } = useTranslation('template', {
        keyPrefix: 'builder.configuration.evaluationCriteriaModal',
    });
    const VALUE_MIN = 0;
    const VALUE_MAX = 100;
    const ERROR_MESSAGES = getErrorMessages();
    const DEFAULT_THRESHOLDS = getDefaultThresholds(initialThresholds);
    const { isScoringEnabled, templateID } = useTemplate();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [thresholdsData, setThresholdsData] = React.useState(Object.assign(Object.assign({}, DEFAULT_THRESHOLDS), { has_threshold: true }));
    const [compliantError, setCompliantError] = React.useState();
    const thresholdsRef = React.useRef(DEFAULT_THRESHOLDS);
    React.useEffect(() => {
        setThresholdsData(Object.assign(Object.assign({}, getDefaultThresholds(initialThresholds)), { has_threshold: true }));
        thresholdsRef.current = getDefaultThresholds(initialThresholds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialThresholds]);
    function addThreshold() {
        const { partial_threshold } = thresholdsRef.current;
        setThresholdsData((c) => {
            const value = partial_threshold > 0 ? partial_threshold : Math.floor(c.threshold / 2);
            if (value >= thresholdsData.threshold) {
                setCompliantError(ERROR_MESSAGES.COMPLIANT_MUST_BE_HIGHER);
            }
            return Object.assign(Object.assign({}, c), { has_partial_threshold: true, partial_threshold: value });
        });
    }
    function removeThreshold() {
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_partial_threshold: false })));
        setCompliantError(undefined);
    }
    function handleReset() {
        setThresholdsData(thresholdsRef.current);
        setCompliantError(undefined);
    }
    function setCompliant(value) {
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_threshold: true, threshold: value })));
    }
    function handleCompliantChange(value) {
        if (value > VALUE_MAX)
            return;
        if (value < VALUE_MIN) {
            setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_threshold: true, threshold: c.has_partial_threshold ? VALUE_MIN + 2 : VALUE_MIN + 1 })));
            return;
        }
        if (thresholdsData.has_partial_threshold && value <= thresholdsData.partial_threshold) {
            setCompliantError(ERROR_MESSAGES.COMPLIANT_MUST_BE_HIGHER);
        }
        else {
            setCompliantError(undefined);
        }
        setCompliant(value);
    }
    function handlePartiallyCompliantChange(value) {
        if (value > VALUE_MAX || (value !== 0 && value >= thresholdsData.threshold))
            return;
        if (value < thresholdsData.threshold && compliantError) {
            setCompliantError(undefined);
        }
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { partial_threshold: value < VALUE_MIN ? VALUE_MIN + 1 : value })));
    }
    const validateThresholds = () => {
        const { threshold, partial_threshold, has_partial_threshold } = thresholdsData;
        if (threshold <= VALUE_MIN || threshold > VALUE_MAX) {
            notifyError(ERROR_MESSAGES.COMPLIANT_RANGE);
            return true;
        }
        if (has_partial_threshold) {
            if (partial_threshold <= VALUE_MIN) {
                notifyError(ERROR_MESSAGES.PARTIALLY_COMPLIANT_RANGE);
                return true;
            }
            if (threshold <= partial_threshold) {
                notifyError(ERROR_MESSAGES.COMPLIANT_MUST_BE_HIGHER);
                return true;
            }
        }
        return false;
    };
    const handleSave = async () => {
        const hasError = validateThresholds();
        if (hasError)
            return;
        try {
            setIsLoading(true);
            await setThresholds(templateID, thresholdsData);
            thresholdsRef.current = thresholdsData;
            setIsModalOpen(false);
            notifySuccess(t('thresholdUpdatedMsg', 'Thresholds updated successfully'));
        }
        catch (error) {
            notifyError(ERROR_MESSAGES.SAVE_ERROR);
            console.error('Failed to update thresholds:', error);
        }
        finally {
            setIsLoading(false);
        }
    };
    function handleSliderMultipleThresholdsChange(value, activeThumbIndex) {
        const activeValue = value[activeThumbIndex];
        switch (activeThumbIndex) {
            case 0:
                if (activeValue >= thresholdsData.threshold - 4)
                    return;
                setThresholdsData((c) => (Object.assign(Object.assign({}, c), { partial_threshold: activeValue < VALUE_MIN ? VALUE_MIN + 1 : activeValue })));
                break;
            case 1:
                if (activeValue <= thresholdsData.partial_threshold + 4)
                    return;
                setCompliant(activeValue);
                break;
            default:
                break;
        }
    }
    function handleSliderChange(_, value, activeThumbIndex) {
        if (!Array.isArray(value))
            return;
        if (thresholdsData.has_partial_threshold) {
            handleSliderMultipleThresholdsChange(value, activeThumbIndex);
        }
        else if (activeThumbIndex === 0) {
            setCompliant(value[activeThumbIndex]);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(OpenModalAction, { mode: thresholdsRef.current.has_threshold ? 'edit' : 'set', disabled: disabled, onClick: () => setIsModalOpen(true), hide: !isScoringEnabled }),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false), keepMounted: false },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('title', 'Compliance Criteria') }),
                React.createElement(Typography, { variant: "body1", px: 6, mb: 7 }, t('description', 'Set the threshold values that will define the Compliance status of Assessments that use this template.')),
                React.createElement(Separator, { horizontal: true }),
                React.createElement(ModalContent, { py: 6, display: "flex", alignItems: "center", gap: 9 },
                    React.createElement(Base, { display: "flex", alignItems: "center", minWidth: 230, maxWidth: "30%" },
                        React.createElement(ThresholdsForm, { compliantValue: thresholdsData.threshold, partiallyCompliantValue: thresholdsData.has_partial_threshold
                                ? thresholdsData.partial_threshold
                                : undefined, compliantError: compliantError, onCompliantChange: handleCompliantChange, onPartiallyCompliantChange: handlePartiallyCompliantChange, onAddThreshold: addThreshold, onRemoveThreshold: removeThreshold })),
                    React.createElement(Base, { flex: 1 },
                        React.createElement(ThresholdsSlider, { onChange: handleSliderChange, value: thresholdsData.has_partial_threshold
                                ? [thresholdsData.partial_threshold, thresholdsData.threshold]
                                : [thresholdsData.threshold], disabled: !!compliantError }),
                        React.createElement(DragIndicator, null))),
                React.createElement(Separator, { horizontal: true }),
                React.createElement(Base, { display: "flex", justifyContent: "flex-end", px: 7, pb: 5, pt: 5 },
                    React.createElement(ResetAction, { onClick: handleReset, disabled: isEqual(thresholdsRef.current, thresholdsData) || isLoading }),
                    React.createElement(SaveAction, { onClick: handleSave, disabled: !!compliantError || isLoading }))))));
};
