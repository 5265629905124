import SwipeRoundedIcon from '@mui/icons-material/SwipeRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export const DragIndicator = () => {
    const { t } = useTranslation('template', {
        keyPrefix: 'builder.configuration.thresholds.actions',
    });
    return (React.createElement(Base, { display: "flex", justifyContent: "center", gap: 5, alignItems: "center" },
        React.createElement(SwipeRoundedIcon, { color: "action", fontSize: "small" }),
        React.createElement(Typography, { variant: "body2", color: "disabled" }, t('drag', 'Drag'))));
};
