import { orderBy } from 'lodash';
import { DataRepository, fromSyncable } from '@corti/lib/repositories';
import { Observer } from '@corti/observer';
import { RefCountedPool } from './RefCountedPool';
import { StreamEventSubscriber } from './StreamEventSubscriber';
import { commentTransformer, flowStateChangeEventTransformer, sessionTransformer, } from './transformers';
import { flowEventUtils } from './utils';
const SYNCED_RESOURCE_GROUP_BUFFER = 10;
const DB_CLEANUP_INTERVAL = 3600000;
export class TriageSessionRepository {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        // Stream pool makes it easy to reuse the same instance of the stream
        // as triage session repository provides an abstraction over underlying streams
        // we want to make sure that we are not over-creating streams when not necessarry
        Object.defineProperty(this, "streamPool", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "streamEventSubscriber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionsRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commentsRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flowEventsRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flowAnalyticEventsRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sentFlowEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "realtimeUserDBCleanupJob", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionSyncedUnsubscriber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "connectionCheckerUnsubscriber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.input = input;
        this.observer = new Observer();
        this.sentFlowEvents = new Set();
        this.streamPool = new RefCountedPool({
            factory: (id) => {
                return this.input.realtimeClient.triageSessionStream(id);
            },
            cleanup: (stream) => {
                stream.unsubscribe();
            },
        });
        this.streamEventSubscriber = new StreamEventSubscriber({
            logger: this.input.logger,
            streamPool: this.streamPool,
        });
        this.sessionsRepository = new DataRepository({
            logger: this.input.logger,
            db: this.input.realtimeUserDB.sessions,
            onSyncOne: async (resource) => {
                const res = await this.input.api.createSession({
                    id: resource.id,
                    caseID: resource.caseID,
                    startedAt: resource.startedAt,
                    createdAt: resource.createdAt,
                    externalID: resource.externalID,
                    graphVersionID: resource.graphVersionID,
                });
                this.observer.fireEvent('session-synced', {
                    sessionID: res.id,
                    data: res,
                });
                return sessionTransformer.repoToDB(res);
            },
            onSyncAll: async (resources) => {
                await this.input.api.createSessions(resources.map((it) => ({
                    id: it.id,
                    caseID: it.caseID,
                    startedAt: it.createdAt,
                    createdAt: it.createdAt,
                    externalID: it.externalID,
                    graphVersionID: it.graphVersionID,
                })));
            },
            onRefreshOne: async (id) => {
                const result = await this.input.api.getSession(id);
                if (!result) {
                    return;
                }
                return sessionTransformer.repoToDB(result);
            },
        });
        this.flowEventsRepository = new DataRepository({
            logger: this.input.logger,
            db: this.input.realtimeUserDB.flowStateChangeEvents,
            onSyncOne: async (event) => {
                try {
                    const stream = this.streamPool.acquire(event.sessionID);
                    const transformed = flowStateChangeEventTransformer.DBToRepo(event);
                    await stream.sendWithAck(transformed.event, transformed.data);
                }
                finally {
                    this.streamPool.release(event.sessionID);
                }
                return event;
            },
            onSyncAll: async (resources) => {
                const events = resources
                    .map(flowStateChangeEventTransformer.DBToServer)
                    .filter((it) => it != null);
                await this.input.api.createFlowEvents(events);
            },
            onRefreshAllByField: async (field, value) => {
                if (field === 'sessionID') {
                    const serverEvents = await this.input.api.getFlowEventsFullBatch({
                        sessionID: value,
                    });
                    const session = await this.input.api.getSession(value);
                    if (!session) {
                        return [];
                    }
                    const traverser = await this.input.getGraphTraverser(session.graphVersionID);
                    if (!traverser) {
                        return [];
                    }
                    return serverEvents
                        .map((event) => flowStateChangeEventTransformer.serverToRepo(event, traverser))
                        .filter((e) => e != null)
                        .map((e) => flowStateChangeEventTransformer.repoToDB(e, value));
                }
                return Promise.reject('not implemented');
            },
        });
        this.flowAnalyticEventsRepository = new DataRepository({
            logger: this.input.logger,
            db: this.input.realtimeUserDB.flowAnalyticEvents,
            onSyncOne: async (event) => {
                await this.input.api.createFlowAnalyticsEvents([event]);
                return event;
            },
            onSyncAll: async (events) => {
                await this.input.api.createFlowAnalyticsEvents(events);
            },
        });
        this.commentsRepository = new DataRepository({
            logger: this.input.logger,
            db: this.input.realtimeUserDB.comments,
            onSyncOne: async (resource) => {
                const comment = await this.input.api.createComment({
                    id: resource.id,
                    datetime: resource.datetime,
                    text: resource.text,
                    triageSessionID: resource.sessionID,
                });
                return commentTransformer.repoToDB(comment, resource.sessionID);
            },
            onSyncAll: async (resources) => {
                await this.input.api.createComments(resources.map((it) => ({
                    id: it.id,
                    datetime: it.datetime,
                    text: it.text,
                    triageSessionID: it.sessionID,
                })));
            },
            onRefreshAllByField: async (field, value) => {
                if (field === 'sessionID') {
                    const result = await this.input.api.getAllComments({ sessionID: value });
                    return result.map((it) => commentTransformer.repoToDB(it, value));
                }
                return Promise.reject('not implemented');
            },
        });
        this.realtimeUserDBCleanupJob = setInterval(() => {
            void this.deleteAllSyncedGroups({
                buffer: SYNCED_RESOURCE_GROUP_BUFFER,
            });
        }, DB_CLEANUP_INTERVAL);
        void this.deleteAllSyncedGroups({
            buffer: SYNCED_RESOURCE_GROUP_BUFFER,
        });
        this.sessionSyncedUnsubscriber = this.observer.on('session-synced', (data) => {
            void this.syncAllBySession(data.sessionID);
        });
        this.connectionCheckerUnsubscriber = this.input.connectionChecker.onStateChange((e) => {
            if (e.current === 'online') {
                void this.syncAll();
            }
        });
        void this.syncAll();
    }
    destroy() {
        this.streamEventSubscriber.destroy();
        this.connectionCheckerUnsubscriber();
        this.sessionSyncedUnsubscriber();
        clearInterval(this.realtimeUserDBCleanupJob);
    }
    async syncAll() {
        // Sessions have to be synced first. All other resources depend on their existance in the backend.
        await this.sessionsRepository.syncAll();
        void this.commentsRepository.syncAll();
        void this.flowEventsRepository.syncAll();
        void this.flowAnalyticEventsRepository.syncAll();
    }
    async syncAllBySession(sessionID) {
        const comments = await this.input.realtimeUserDB.comments
            .where('sessionID')
            .equals(sessionID)
            .toArray();
        const unsyncedComments = comments.filter((it) => it.__syncstatus.synced === false);
        for (let it of unsyncedComments) {
            await this.commentsRepository.syncOne(it);
        }
        const flowStateChangeEvents = await this.input.realtimeUserDB.flowStateChangeEvents
            .where('sessionID')
            .equals(sessionID)
            .toArray();
        const unsyncedFlowStateChangeEvents = flowStateChangeEvents.filter((it) => it.__syncstatus.synced === false);
        for (let it of unsyncedFlowStateChangeEvents) {
            await this.flowEventsRepository.syncOne(it);
        }
        const flowAnalyticEvents = await this.input.realtimeUserDB.flowAnalyticEvents
            .where('session_id')
            .equals(sessionID)
            .toArray();
        const unsyncedFlowAnalyticEvents = flowAnalyticEvents.filter((it) => it.__syncstatus.synced === false);
        for (let it of unsyncedFlowAnalyticEvents) {
            await this.flowAnalyticEventsRepository.syncOne(it);
        }
    }
    /**
     * Deletes groups of resources that are fully synced.
     * A session resource together with all other resources related to it makes a group.
     */
    async deleteAllSyncedGroups(options) {
        const groups = await this.getSyncedGroups();
        const groupsToDelete = orderBy(groups, (it) => it.session.createdAt, 'desc').slice(options.buffer, groups.length);
        for (let group of groupsToDelete) {
            await this.input.realtimeUserDB.sessions.delete(group.session.id);
            await this.input.realtimeUserDB.comments.bulkDelete(group.comments.map((it) => it.id));
            await this.input.realtimeUserDB.flowStateChangeEvents.bulkDelete(group.flowStateChangeEvents.map((it) => it.id));
            await this.input.realtimeUserDB.flowAnalyticEvents.bulkDelete(group.flowAnalyticEvents.map((it) => it.id));
        }
    }
    async getSyncedGroups() {
        const result = [];
        const allSessions = await this.input.realtimeUserDB.sessions.toArray();
        const syncedSessions = allSessions.filter((it) => it.__syncstatus.synced);
        for (let session of syncedSessions) {
            const comments = await this.input.realtimeUserDB.comments
                .where('sessionID')
                .equals(session.id)
                .toArray();
            const flowStateChangeEvents = await this.input.realtimeUserDB.flowStateChangeEvents
                .where('sessionID')
                .equals(session.id)
                .toArray();
            const flowAnalyticEvents = await this.input.realtimeUserDB.flowAnalyticEvents
                .where('session_id')
                .equals(session.id)
                .toArray();
            if (comments.every((it) => it.__syncstatus.synced === true) &&
                flowStateChangeEvents.every((it) => it.__syncstatus.synced === true) &&
                flowAnalyticEvents.every((it) => it.__syncstatus.synced === true)) {
                result.push({
                    session: fromSyncable(session),
                    comments: comments.map(fromSyncable),
                    flowStateChangeEvents: flowStateChangeEvents.map(fromSyncable),
                    flowAnalyticEvents: flowAnalyticEvents.map(fromSyncable),
                });
            }
        }
        return result;
    }
    async addSession(input) {
        await this.sessionsRepository.put(sessionTransformer.repoToDB(input));
    }
    async getSession(sessionID) {
        const result = await this.sessionsRepository.get(sessionID);
        if (!result) {
            return;
        }
        return sessionTransformer.DBToRepo(result);
    }
    async addFlowStateChangeEvent(sessionID, event) {
        const internal = flowStateChangeEventTransformer.repoToDB(event, sessionID);
        this.sentFlowEvents.add(internal.id);
        await this.flowEventsRepository.put(internal);
    }
    async getAllFlowStateChangeEvents(sessionID) {
        const result = await this.flowEventsRepository.getAllByField('sessionID', sessionID);
        const transformed = result.map(flowStateChangeEventTransformer.DBToRepo);
        const sorted = orderBy(transformed, (it) => new Date(it.data.datetime).getTime(), 'asc');
        return sorted;
    }
    async addFlowIntegrationEvent(sessionID, event) {
        const stream = this.streamPool.acquire(sessionID);
        stream.send(event.event, event.data);
        this.streamPool.release(sessionID);
    }
    async addFlowAnalyticsEvent(event) {
        await this.flowAnalyticEventsRepository.put(event);
    }
    async acceptSessionOwnershipRequest(input) {
        await this.input.api.acceptSessionOwnershipRequest({
            requestID: input.requestID,
        });
    }
    async declineSessionOwnershipRequest(input) {
        await this.input.api.declineSessionOwnershipRequest({
            requestID: input.requestID,
        });
    }
    async requestSessionOwnershipHandover(sessionID, input) {
        await this.input.api.requestSessionOwnershipHandover(Object.assign({ sessionID }, input));
    }
    async requestSessionOwnershipTakeover(sessionID, input) {
        await this.input.api.requestSessionOwnershipTakeover(Object.assign({ sessionID }, input));
    }
    async addComment(sessionID, comment) {
        await this.commentsRepository.put(commentTransformer.repoToDB(comment, sessionID));
    }
    async getAllComments(sessionID) {
        const result = await this.commentsRepository.getAllByField('sessionID', sessionID);
        return result.map(commentTransformer.DBToRepo);
    }
    onFlowCommand(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-flow-command',
            cb,
        });
    }
    onFlowStateChangedEventAdded(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-flow-state-change-event-added',
            cb: (data) => {
                // This ideally should be handled by the backend
                if (this.sentFlowEvents.has(flowEventUtils.genID(data)) === false) {
                    cb(data);
                }
            },
        });
    }
    onCommentAdded(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-comment-added',
            cb: async (data) => {
                const comment = await this.input.api.getComment(data.id);
                if (comment) {
                    cb(comment);
                }
            },
        });
    }
    onSessionOwnerChanged(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-owner-changed',
            cb,
        });
    }
    onSessionOwnershipRequested(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-ownership-requested',
            cb,
        });
    }
    onSessionOwnershipDeclined(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-ownership-request-declined',
            cb,
        });
    }
    onSessionOwnershipAccepted(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-ownership-request-accepted',
            cb,
        });
    }
    onSessionOwnershipCanceled(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-ownership-request-cancelled',
            cb,
        });
    }
    onSessionTimelineEntryCreated(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-timeline-entry-created',
            cb,
        });
    }
    onSessionLiveCallStatusChanged(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-live-call-status-changed',
            cb,
        });
    }
    onUserConnected(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-user-connected',
            cb,
        });
    }
    onUserDisconnected(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-user-disconnected',
            cb,
        });
    }
    onUserPresence(sessionID, cb) {
        return this.streamEventSubscriber.subscribe(sessionID, {
            event: 'session-user-presence',
            cb,
        });
    }
    onSessionSynced(sessionID, cb) {
        return this.observer.on('session-synced', (data) => {
            if (data.sessionID === sessionID) {
                cb(data.data);
            }
        });
    }
    onRefreshRequired(cb) {
        const u = this.input.connectionChecker.onStateChange((e) => {
            if (e.current === 'online') {
                cb();
            }
        });
        return () => {
            u();
        };
    }
}
