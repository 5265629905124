import React from 'react';
import { Container, Page } from 'lib/cortiUI';
import { Header } from './Header';
import { TasksStateContainer } from './TasksStateContainer';
import { useSortingCache } from './utils';
export const TasksView = () => {
    var _a;
    const { persistActiveTab, getPersistedActiveTab } = useSortingCache();
    const [tab, setTab] = React.useState((_a = getPersistedActiveTab()) !== null && _a !== void 0 ? _a : 'PENDING');
    function handleTabChange(tab) {
        setTab(tab);
        persistActiveTab(tab);
    }
    return (React.createElement(Page, null,
        React.createElement(Container, { sx: {
                height: '100%',
                overflow: 'hidden',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                gap: 5,
            } },
            React.createElement(Header, { tab: tab, onTabChange: handleTabChange }),
            React.createElement(TasksStateContainer, { tab: tab }))));
};
