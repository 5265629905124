import React, { useState } from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { ExpressionInputConnected, ExpressionInputStyledWrapper, } from 'lib/graphEditor/expressions';
export function ExpressionCreator(props) {
    const { onSave, onCancel } = props;
    const [validationResult, setValidationResult] = useState();
    const [textValue, setTextValue] = useState('');
    return (React.createElement(Card, { minWidth: 300 },
        React.createElement(ExpressionInputStyledWrapper, { validationResult: validationResult },
            React.createElement(ExpressionInputConnected, { validate: true, includeBlockSuggestions: true, includeFactSuggestions: true, value: textValue, onChange: (val) => setTextValue(val), onValidate: (result) => {
                    setValidationResult(result);
                } })),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
            React.createElement(Button, { onClick: onCancel, size: "small" }, intl.t('cancel', 'Cancel')),
            React.createElement(Button, { onClick: (e) => {
                    if (!validationResult || validationResult.type === 'error' || !textValue)
                        return;
                    onSave === null || onSave === void 0 ? void 0 : onSave(textValue, e);
                }, size: "small", className: css({ marginLeft: 1 }) }, intl.t('save', 'Save')))));
}
