import React from 'react';
import { css } from '@corti/style';
import { Label, Switch, TextField } from 'lib/cortiUI';
export const SettingsField = ({ fieldInfo, configNameToUpdate, onChangeValue, }) => {
    const { config: { name, type }, value, } = fieldInfo;
    const handleSwitchChange = (_event, checked) => {
        onChangeValue(checked, configNameToUpdate, type, name);
    };
    const handleInputBlur = ({ target }) => {
        onChangeValue(target.value.trim(), configNameToUpdate, type, name);
    };
    return typeof value === 'boolean' ? (React.createElement(Label, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
        name,
        React.createElement(Switch, { checked: value, onChange: handleSwitchChange, className: css({ marginLeft: 20 }) }))) : (React.createElement(TextField, { label: name, placeholder: name, defaultValue: value || '', onBlur: handleInputBlur, fullWidth: true }));
};
