import { observer } from 'mobx-react';
import React from 'react';
import { useCaseViewContext } from '../CaseViewContext';
import { CaseFeedbackDetailsView } from './CaseFeedbackDetailsView';
import { CaseFeedbackListView } from './CaseFeedbackListView';
import { CreateCaseFeedbackView } from './CreateCaseFeedbackView';
export const CaseFeedbackView = observer(() => {
    const { caseViewState } = useCaseViewContext();
    switch (caseViewState.feedbackViewState.activeView) {
        case 'feedback-list': {
            return React.createElement(CaseFeedbackListView, null);
        }
        case 'feedback-create': {
            return React.createElement(CreateCaseFeedbackView, null);
        }
        case 'feedback-preview': {
            return React.createElement(CaseFeedbackDetailsView, null);
        }
        default: {
            return null;
        }
    }
});
