import { gql } from '@apollo/client';
export const getFeedbackFormTemplatesQuery = gql `
  query feedbackFormTemplates($organizationID: ID!) {
    feedbackFormTemplates(organizationID: $organizationID) {
      id
      title
      createdBy {
        name
      }
      createdAt
      anonymizeReviewers
    }
  }
`;
export const deleteFeedbackFormTemplateMutation = gql `
  mutation deleteFeedbackFormTemplate($id: String!) {
    deleteFeedbackFormTemplate(id: $id)
  }
`;
export const setFeedbackFormTemplateAnonymizeReviewersMutation = gql `
  mutation setFeedbackFormTemplateAnonymizeReviewer($id: String!, $isAnonymous: Boolean!) {
    setFeedbackFormTemplateAnonymizeReviewer(id: $id, isAnonymous: $isAnonymous)
  }
`;
