export class CoreApiError extends Error {
    constructor(input) {
        super(input.message);
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "domainErrors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /** Should be used only for debugging purposes */
        Object.defineProperty(this, "extra", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "devMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = 'CoreApiError';
        this.devMessage = input.devMessage;
        this.code = input.code;
        this.extra = input.extra;
        this.domainErrors = input.domainErrors;
    }
    static SchemaMismatch(input) {
        return new CoreApiError(Object.assign({ code: 'schema-mismatch' }, input));
    }
    static Unauthorized(input) {
        return new CoreApiError(Object.assign({ code: 'unauthorized', devMessage: 'Request does not have valid credentials for the operation' }, input));
    }
    static PermissionDenied(input) {
        return new CoreApiError(Object.assign({ code: 'permission-denied', devMessage: 'The caller does not have permissions to execute the operation' }, input));
    }
    static Internal(input) {
        return new CoreApiError(Object.assign({ code: 'internal' }, input));
    }
    static Unavailable(input) {
        return new CoreApiError(Object.assign({ code: 'unavailable', devMessage: 'Service is unavailable or client connection issues' }, input));
    }
    static DomainError(input) {
        return new CoreApiError(Object.assign({ code: 'domain-error', devMessage: 'domain server error' }, input));
    }
    static isCoreApiDomainError(err) {
        if (err instanceof CoreApiError) {
            return 'domainErrors' in err && err.domainErrors.length > 0;
        }
        else {
            return false;
        }
    }
    static isCoreApiError(err) {
        return err instanceof CoreApiError;
    }
}
