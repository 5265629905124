import React from 'react';
import { formatDateTime } from '@corti/date';
import { Base, Typography } from 'lib/cortiUI';
export function DateSelectInputText({ selectedDate, placeholder }) {
    if (selectedDate) {
        if (selectedDate instanceof Date) {
            return (React.createElement(Base, { display: "flex", flexWrap: "nowrap" },
                React.createElement(Typography, { variant: "body2", color: "default", noWrap: true, noSelect: true }, formatDateTime(selectedDate))));
        }
        return (React.createElement(Base, { display: "flex", flexWrap: "nowrap" },
            React.createElement(Typography, { variant: "footnote", noWrap: true, noSelect: true },
                formatDateTime(selectedDate.startDate),
                ' - ',
                formatDateTime(selectedDate.endDate))));
    }
    return (React.createElement(Base, { display: "flex", flexWrap: "nowrap" },
        React.createElement(Typography, { variant: "body2", color: "hint", noWrap: true, noSelect: true }, placeholder)));
}
