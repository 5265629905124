import { useActor } from '@xstate/react';
export function useChecklist(actor) {
    const [state, send] = useActor(actor);
    const onExpand = () => send('EXPAND');
    const onCollapse = () => send('COLLAPSE');
    const stateAsString = state.toStrings()[0];
    const onToggle = state.context.isExpanded ? onCollapse : onExpand;
    const mode = stateAsString === 'initializing' ? 'idle' : stateAsString;
    return Object.assign(Object.assign({ mode }, state.context), { onToggle });
}
export function useEntry(actor) {
    const [state, send] = useActor(actor);
    const onToggle = () => send('TOGGLE');
    return Object.assign(Object.assign({}, state.context), { onToggle });
}
