import { observer } from 'mobx-react';
import React from 'react';
import { BlockValueUtils } from '../../stores/BlockValueStore';
import { SelectDropdownBlock } from './SelectDropdownBlock';
import { SelectListBlock } from './SelectListBlock';
import { mapOption } from './utils';
export const SelectBlock = observer(function SelectBlock(props) {
    var _a;
    const { isReadonly = false, blockPrototype, blockValues, onChange, onPreviewEnter, onPreviewLeave, } = props;
    const options = blockPrototype.options.map(mapOption);
    const selectedOptions = blockValues
        .filter((it) => BlockValueUtils.isTruthy(it))
        .map((it) => options.find((o) => o.id === it.identifier.optionID))
        .filter((it) => !!it);
    function handleChange(options) {
        onChange(blockValues.map((it) => (Object.assign(Object.assign({}, it), { value: options.find((o) => o.id === it.identifier.optionID) ? 'true' : 'false' }))));
    }
    function handlePreviewEnter(option) {
        const blockValue = blockValues.find((it) => it.identifier.optionID === option.id);
        if (blockValue) {
            onPreviewEnter(blockValue.identifier);
        }
    }
    function handlePreviewLeave(option) {
        const blockValue = blockValues.find((it) => it.identifier.optionID === option.id);
        if (blockValue) {
            onPreviewLeave(blockValue.identifier);
        }
    }
    return ((_a = blockPrototype.selectBlock) === null || _a === void 0 ? void 0 : _a.viewType) === 'DROPDOWN' ? (React.createElement(SelectDropdownBlock, { id: blockPrototype.id, limit: blockPrototype.max === null ? undefined : blockPrototype.max, label: blockPrototype.label.richText || blockPrototype.label.plainText, isReadonly: isReadonly, options: options, selectedOptions: selectedOptions, onChange: handleChange, onOptionEnter: handlePreviewEnter, onOptionLeave: handlePreviewLeave })) : (React.createElement(SelectListBlock, { id: blockPrototype.id, limit: blockPrototype.max === null ? undefined : blockPrototype.max, label: blockPrototype.label.richText || blockPrototype.label.plainText, isSearchable: blockPrototype.isSearchable, isReadonly: isReadonly, options: options, selectedOptions: selectedOptions, onChange: handleChange, onOptionEnter: handlePreviewEnter, onOptionLeave: handlePreviewLeave }));
});
