import React from 'react';
/**
 * A hook that merges multiple refs into a single function ref.
 * Takes any number of refs that be both object or function refs
 * or even undefined because of optional props.
 */
export function useMergeRefs(...refs) {
    return React.useMemo(() => {
        if (refs.every((ref) => ref === null)) {
            return null;
        }
        return (refValue) => {
            for (const ref of refs) {
                setRef(ref, refValue);
            }
        };
    }, [refs]);
}
function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    }
    else if (ref !== null && ref !== undefined) {
        ref.current = value;
    }
}
