import React from 'react';
import { formatDateTime } from '@corti/date';
import { isElectron } from '@corti/electron-utils';
import { useTranslation } from '@corti/i18n';
import { getReadableCaseID } from '@corti/lib/coreApiService';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { Formatters } from '@corti/strings';
import { css } from '@corti/style';
import { Avatar, Base, Typography, useContextMenu } from 'lib/cortiUI';
import { ListCell } from './ListCell';
import { ListRow } from './ListRow';
import { OpenNewTab } from './OpenNewTab';
const VISITED_CALLS_LOCAL_STORAGE_KEY = 'callsSearch:visitedCalls';
export function CallList({ calls, onRowClick, onScrollToBottom }) {
    var _a;
    const { t } = useTranslation('transcriptSearchApp');
    const { openContextMenu } = useContextMenu();
    const observableElRef = React.useRef(null);
    const storedClickedRows = JSON.parse((_a = userSessionStorage.getItem(VISITED_CALLS_LOCAL_STORAGE_KEY)) !== null && _a !== void 0 ? _a : '[]');
    React.useEffect(() => {
        const intersectionObserver = new IntersectionObserver((e) => {
            if (e[0].intersectionRatio > 0) {
                onScrollToBottom === null || onScrollToBottom === void 0 ? void 0 : onScrollToBottom();
            }
        });
        if (observableElRef.current) {
            intersectionObserver.observe(observableElRef.current);
        }
        return () => {
            intersectionObserver === null || intersectionObserver === void 0 ? void 0 : intersectionObserver.disconnect();
        };
    }, [onScrollToBottom]);
    function handleRowClick(call) {
        cacheVisitedCall(call.case.id);
        onRowClick(call);
    }
    function cacheVisitedCall(id) {
        userSessionStorage.setItem(VISITED_CALLS_LOCAL_STORAGE_KEY, JSON.stringify([...new Set([...storedClickedRows, id])]));
    }
    function onContextMenu(event, id) {
        openContextMenu(event, React.createElement(React.Fragment, null,
            React.createElement(OpenNewTab, { onClick: () => {
                    cacheVisitedCall(id);
                    window.open(`/explore/${id}`, '_blank');
                } })));
    }
    return (React.createElement(Base, { "data-cy": "transcript-search-call-list" },
        React.createElement(ListRow, { className: css({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                alignItems: 'center',
            }) },
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('callTaker', 'Call-Taker'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('startTime', 'Start Time'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('caseId', 'Case ID'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('duration', 'Duration'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('protocolName', 'Protocol Name'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('graph', 'Graph')))),
        calls.map((call) => (React.createElement(CallListRow, { key: call.id, call: call, onClick: handleRowClick, isMarked: storedClickedRows.includes(call.case.id), onContextMenu: isElectron() ? undefined : (e) => onContextMenu(e, call.case.id) }))),
        React.createElement(Base, { ref: observableElRef, width: "100%", zIndex: 1, className: css({
                visibility: 'hidden',
            }) })));
}
function CallListRow({ call, onClick, isMarked, onContextMenu }) {
    var _a, _b;
    return (React.createElement(ListRow, { className: css({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            alignItems: 'center',
        }), onClick: onClick ? () => onClick(call) : undefined, isMarked: isMarked, "data-cy": "transcript-search-call-list-row", onContextMenu: onContextMenu },
        React.createElement(ListCell, null,
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 5 },
                React.createElement(Avatar, { size: "small" }, (_a = call.user) === null || _a === void 0 ? void 0 : _a.name),
                React.createElement(Typography, { color: "default", variant: "body1", noWrap: true }, (_b = call.user) === null || _b === void 0 ? void 0 : _b.name))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, formatDateTime(call.startedAt))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, call.case.id ? getReadableCaseID(call.case.id) : '')),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, Formatters.msToDuration(call.duration, { showHours: false }))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, call.protocolName)),
        React.createElement(ListCell, null, call.termGroupColors.map((color, idx) => (React.createElement(Base, { key: idx, style: {
                height: 10,
                width: 10,
                borderRadius: 100,
                marginRight: 2,
                backgroundColor: color,
            } }))))));
}
