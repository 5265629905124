import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ButtonBase, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
export const DocumentElementWidget = observer((props) => {
    const { model } = props;
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    const resolvedMedia = model.mediaAssetID
        ? editor.media.provider.resolveMediaAsset(model.mediaAssetID)
        : undefined;
    return (React.createElement(ButtonBase, { "data-cy": "document-element-widget", disabled: !!model.mediaAssetID, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 4, width: 1, p: 5, borderRadius: 2, borderWidth: 2, borderColor: theme.palette.text.hint, borderStyle: "solid", onClick: async () => {
            const file = await editor.media.pickMedia();
            if (file) {
                const propName = 'mediaAssetID';
                const propValue = file.id;
                editor.dispatch({
                    type: 'viewBuilder.setBlockProtoProperty',
                    data: {
                        protoID: model.id,
                        propName,
                        propValue,
                    },
                });
            }
        } },
        React.createElement(AttachFileRoundedIcon, { className: css({ color: theme.palette.text.hint }) }),
        !model.mediaAssetID ? (React.createElement(Typography, { variant: "body2", color: "hint" }, t('contentBuilder.documentElement.noMediaSelected', 'Click to select a document'))) : resolvedMedia ? (React.createElement(Typography, { variant: "body2", color: "default" }, resolvedMedia.name)) : ('[⚠️ unresolved media ref]')));
});
