import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Route } from 'lib/router';
import { CaseView } from '../CaseView';
import { TasksView } from './TasksView';
export const TasksApp = () => {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true, render: () => React.createElement(TasksView, null) }),
        React.createElement(Route, { path: `${match.path}/:interactionID`, render: () => React.createElement(CaseView, null), breadcrumb: ({ match }) => {
                return `${match.params.interactionID.substring(0, 8).toLocaleUpperCase()}`;
            } })));
};
