import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Typography } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
export const CallTranscriptView = observer(function CallTranscriptView() {
    var _a;
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    if (!caseViewState.caseEntity) {
        return null;
    }
    const { caseEntity } = caseViewState;
    return (React.createElement(Base, { display: "grid", gap: 7, overflow: "hidden" },
        React.createElement(Typography, { mx: 8, variant: "h6" }, intl.t('explore:caseView.transcript', 'Transcript')),
        React.createElement(Base, { className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
            React.createElement(Base, { mx: 8 }, (_a = caseEntity.activeCall) === null || _a === void 0 ? void 0 : _a.transcript))));
});
