import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { Base, IconButton, Menu } from 'lib/cortiUI';
import { RemoveMember } from './RemoveMember';
import { SetTeamRole } from './SetTeamRole';
export const Actions = ({ member }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const { isFeatureEnabled } = useAuth();
    return (React.createElement(Base, null,
        React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget), icon: React.createElement(MoreHorizIcon, null) }),
        React.createElement(Menu, { "data-cy": "team-member-actions-dialog", open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
            React.createElement(RemoveMember, { teamID: member.team.id, memberID: member.id, onRemove: () => {
                    setMenuAnchorEl(null);
                } }),
            isFeatureEnabled('mission-control-team-role') && (React.createElement(SetTeamRole, { member: member, onClose: () => {
                    setMenuAnchorEl(null);
                } })))));
};
