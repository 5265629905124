import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { protocolGraphDB } from 'core/protocolGraphDB';
export function useGraphs() {
    const auth = useAuth();
    const query = useQuery(gql `
      query ProtocolGraphs($orgID: ID!) {
        protocolGraphs(organizationID: $orgID) {
          id
          name
          versions {
            id
            name
            release {
              releasedAt
            }
          }
        }
      }
    `, {
        variables: {
            orgID: auth.organization.id,
        },
    });
    const [graphs, setGraphs] = React.useState([]);
    React.useEffect(() => {
        async function getGraphs() {
            let graphs = [];
            if (query.data) {
                graphs = query.data.protocolGraphs;
            }
            else {
                const cachedGraphs = await protocolGraphDB.getAllGraphs();
                graphs = cachedGraphs.map((it) => {
                    var _a, _b, _c, _d, _e;
                    return ({
                        id: (_b = (_a = it.version.graph) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                        name: (_d = (_c = it.version.graph) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                        versions: [
                            {
                                id: it.version.id,
                                name: (_e = it.version.name) !== null && _e !== void 0 ? _e : '',
                                release: it.version.release
                                    ? {
                                        releasedAt: it.version.release.releasedAt,
                                    }
                                    : undefined,
                            },
                        ],
                    });
                });
            }
            setGraphs(graphs);
        }
        void getGraphs();
    }, [query]);
    return graphs;
}
