import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { useMediaQuery } from '@corti/react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, IconButton, Menu } from 'lib/cortiUI';
import { ButtonsContext } from './buttonsContext';
const ToolbarButtonsDropdown = ({ children }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement(Base, null,
        React.createElement(IconButton, { icon: React.createElement(MoreHorizRoundedIcon, null), onClick: handleClick, "aria-controls": open ? 'toolbar-menu-button' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, className: css({
                color: open ? theme.palette.primary.main : undefined,
                background: open ? transparentize(0.96, theme.palette.primary.main) : undefined,
            }) }),
        React.createElement(Menu, { open: open, anchorEl: anchorEl, 
            // we need `keepMounted` for the keyboard shortcuts in
            // <IconButton/> to reader, so we can you keyboard shortcuts without
            // opening the menu first
            keepMounted: true, onClose: handleClose, MenuListProps: {
                'aria-labelledby': 'toolbar-menu',
            }, classes: { paper: css({ minWidth: '220px', maxWidth: '100%' }) } },
            React.createElement(ButtonsContext.Provider, { value: handleClose }, children))));
};
/**
 * ToolbarButtons is a util wrapper for the ToolbarButton where on small screens it is change to a dropdown
 * ToolbarButton is place in the correct area
 *
 */
export const ToolbarButtons = ({ children }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);
    return (React.createElement(Base, { className: css({
            gridArea: 'toolbar-buttons',
            marginLeft: theme.space[4],
        }) }, isSmallScreen ? React.createElement(ToolbarButtonsDropdown, null, children) : children));
};
