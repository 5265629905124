var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { autorun, makeObservable, observable, runInAction, toJS } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { combineTimelineEntryDefinitionSourceValues } from '@corti/lib/coreApiService/timelineEntryDefinitions';
import { resolveDefinition } from './utils';
export class TimelineEntryDefinitionsRepo {
    static sourceToID(source) {
        return combineTimelineEntryDefinitionSourceValues(source);
    }
    constructor(dbTable) {
        Object.defineProperty(this, "dbTable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: dbTable
        });
        Object.defineProperty(this, "definitions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.definitions = [];
        });
        autorun(async () => {
            if (this.definitions.length) {
                await this.dbTable.clear();
                await this.dbTable.bulkPut(this.definitions.map((it) => toJS(it)));
            }
        });
        api.auth.onSignedOut(async () => {
            this.definitions = [];
            await this.dbTable.clear();
        });
    }
    async loadDefinitions() {
        let definitions = [];
        try {
            const res = await api.timelineEntryDefinitions.getAll(api.auth.user.orgID);
            definitions = res.map(resolveDefinition);
        }
        catch (err) {
            console.error(err);
            // In case of the network error load data from the local db
            definitions = await this.dbTable.toArray();
        }
        finally {
            runInAction(() => {
                this.definitions = definitions;
            });
        }
    }
    getDefinitionBySource(source) {
        return this.definitions.find((d) => TimelineEntryDefinitionsRepo.sourceToID(source) === d.id);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], TimelineEntryDefinitionsRepo.prototype, "definitions", void 0);
