import React from 'react';
import { Box } from '../Box';
import { useCarousel } from './CarouselProvider';
export function CarouselItem({ item, index }) {
    const { activeStepIdx, renderStep, onChange } = useCarousel();
    return (React.createElement(Box, { id: `step-${index}`, key: item.id, position: "relative", height: "100%", flexShrink: 0, justifyContent: "center", alignItems: "stretch", width: "100%", onClick: () => {
            if (activeStepIdx !== index) {
                onChange(index);
            }
        } }, renderStep(item, index)));
}
