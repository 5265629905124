// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-text-editor ::selection {
  background-color: rgba(53, 159, 245, 0.45);
}
`, "",{"version":3,"sources":["webpack://./src/lib/cortiUI/components/RichTextEditor/richTextEditor.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C","sourcesContent":[".rich-text-editor ::selection {\n  background-color: rgba(53, 159, 245, 0.45);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
