import { ViewNodeModel } from './ViewNode';
const addLogicGate = (data) => {
    let logicGate;
    return {
        run: ({ editor }) => {
            const node = editor.model.getNodeByID(data.nodeID);
            if (!node) {
                return;
            }
            if (!(node instanceof ViewNodeModel)) {
                return;
            }
            logicGate = node.addNewLogicGate();
            if (data.id) {
                logicGate.id = data.id;
            }
        },
        revert: () => {
            if (logicGate) {
                logicGate.remove();
            }
        },
    };
};
const removeLogicGate = (data) => {
    let logicGate;
    let prevPosIdx;
    return {
        run: ({ editor }) => {
            logicGate = editor.model.getLogicGateByID(data.logicGateID);
            if (!logicGate) {
                return;
            }
            prevPosIdx = logicGate.parent.logicGates.indexOf(logicGate);
            logicGate.remove();
        },
        revert: () => {
            if (logicGate) {
                logicGate.parent.addLogicGate(logicGate, prevPosIdx);
            }
        },
    };
};
const changeLogicGatePosition = (data) => {
    let logicGate;
    let prevPosIdx;
    return {
        run: ({ editor }) => {
            logicGate = editor.model.getLogicGateByID(data.logicGateID);
            if (!logicGate) {
                return;
            }
            prevPosIdx = logicGate.parent.logicGates.indexOf(logicGate);
            logicGate.parent.changeLogicGatePosition(logicGate, data.newPosIdx);
        },
        revert: () => {
            if (logicGate) {
                logicGate.parent.changeLogicGatePosition(logicGate, prevPosIdx);
            }
        },
    };
};
export function registerCommands(editor) {
    editor.registerCommandDescriptor({
        type: 'node.addLogicGate',
        createHandler: addLogicGate,
    });
    editor.registerCommandDescriptor({
        type: 'node.removeLogicGate',
        createHandler: removeLogicGate,
    });
    editor.registerCommandDescriptor({
        type: 'node.changeLogicGatePosition',
        createHandler: changeLogicGatePosition,
    });
}
