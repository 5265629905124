var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTheme } from '@corti/theme';
import { ButtonBase } from 'lib/cortiUI/components/Button';
export function ColorPickerTrigger(props) {
    const { color } = props, buttonBaseProps = __rest(props, ["color"]);
    const theme = useTheme();
    return (React.createElement(ButtonBase, Object.assign({ borderColor: theme.palette.background.divider, borderWidth: 1, borderStyle: 'solid', borderRadius: 2, width: 24, height: 24, background: color }, buttonBaseProps)));
}
