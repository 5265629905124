import React from 'react';
import { intl } from '@corti/i18n';
import { Base, Box, CopyButton, Tooltip, Typography } from 'lib/cortiUI';
export function TopToolbarInfo({ caseViewState }) {
    const { caseEntity } = caseViewState;
    if (!caseEntity) {
        return null;
    }
    return (React.createElement(Box, { alignItems: "center" }, caseEntity.customReadableCaseIdDescriptor ? (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { disableInteractive: true, title: caseEntity.customReadableCaseIdDescriptor.propName },
            React.createElement(Typography, null, caseEntity.customReadableCaseIdDescriptor.value || '-')),
        React.createElement(Base, { display: "flex", alignItems: "center" },
            React.createElement(Typography, { color: "hint" },
                intl.t('explore:caseView.caseId', 'Case ID'),
                ": ",
                caseEntity.readableCaseID),
            React.createElement(CopyButton, { getValue: caseEntity.id, tooltipTitle: intl.t('explore:caseView.caseIdCopied', 'Case ID Copied') })))) : (React.createElement(Base, { display: "flex", alignItems: "center" },
        React.createElement(Tooltip, { disableInteractive: true, title: intl.t('explore:caseView.caseId', 'Case ID') },
            React.createElement(Typography, null, caseEntity.readableCaseID)),
        React.createElement(CopyButton, { getValue: caseEntity.id, tooltipTitle: intl.t('explore:caseView.caseIdCopied', 'Case ID Copied') })))));
}
