var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _RefCountedPool_instancesByID, _RefCountedPool_factory, _RefCountedPool_cleanup, _RefCountedObj_instance, _RefCountedObj_userCount;
export class RefCountedPool {
    constructor(input) {
        _RefCountedPool_instancesByID.set(this, new Map());
        _RefCountedPool_factory.set(this, void 0);
        _RefCountedPool_cleanup.set(this, void 0);
        __classPrivateFieldSet(this, _RefCountedPool_factory, input.factory, "f");
        __classPrivateFieldSet(this, _RefCountedPool_cleanup, input.cleanup, "f");
    }
    acquire(id) {
        if (!__classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").has(id)) {
            __classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").set(id, new RefCountedObj(() => __classPrivateFieldGet(this, _RefCountedPool_factory, "f").call(this, id), (instance) => {
                __classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").delete(id);
                __classPrivateFieldGet(this, _RefCountedPool_cleanup, "f").call(this, instance);
            }));
        }
        return __classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").get(id).acquire();
    }
    release(id) {
        const pool = __classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").get(id);
        if (!pool) {
            return;
        }
        pool.release();
    }
    get idsInUse() {
        return Array.from(__classPrivateFieldGet(this, _RefCountedPool_instancesByID, "f").keys());
    }
}
_RefCountedPool_instancesByID = new WeakMap(), _RefCountedPool_factory = new WeakMap(), _RefCountedPool_cleanup = new WeakMap();
class RefCountedObj {
    constructor(factory, cleanup) {
        Object.defineProperty(this, "factory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: factory
        });
        Object.defineProperty(this, "cleanup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: cleanup
        });
        _RefCountedObj_instance.set(this, void 0);
        _RefCountedObj_userCount.set(this, 0);
    }
    acquire() {
        var _a;
        if (!__classPrivateFieldGet(this, _RefCountedObj_instance, "f")) {
            __classPrivateFieldSet(this, _RefCountedObj_instance, this.factory(), "f");
        }
        __classPrivateFieldSet(this, _RefCountedObj_userCount, (_a = __classPrivateFieldGet(this, _RefCountedObj_userCount, "f"), _a++, _a), "f");
        return __classPrivateFieldGet(this, _RefCountedObj_instance, "f");
    }
    release() {
        var _a;
        __classPrivateFieldSet(this, _RefCountedObj_userCount, (_a = __classPrivateFieldGet(this, _RefCountedObj_userCount, "f"), _a--, _a), "f");
        if (__classPrivateFieldGet(this, _RefCountedObj_userCount, "f") === 0) {
            this.cleanup(__classPrivateFieldGet(this, _RefCountedObj_instance, "f"));
            __classPrivateFieldSet(this, _RefCountedObj_instance, undefined, "f");
        }
    }
}
_RefCountedObj_instance = new WeakMap(), _RefCountedObj_userCount = new WeakMap();
