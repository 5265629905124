var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIPopper from '@mui/material/Popper';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
export function Popper(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    const theme = useTheme();
    return React.createElement(MUIPopper, Object.assign({ className: css({ zIndex: theme.zIndex.tooltip - 1 }, className) }, rest));
}
