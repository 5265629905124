var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
export class TranscriptQueryViewState {
    constructor() {
        Object.defineProperty(this, "isSavingQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isLoadingQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isLoadingCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isLoadingTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isLoadingExportCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "calls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "timeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "setIsSavingQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isSavingQuery = value;
            }
        });
        Object.defineProperty(this, "setIsLoadingQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingQuery = value;
            }
        });
        Object.defineProperty(this, "setIsLoadingCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingCalls = value;
            }
        });
        Object.defineProperty(this, "setIsLoadingTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingTimeSeries = value;
            }
        });
        Object.defineProperty(this, "setIsLoadingExportCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingExportCalls = value;
            }
        });
        Object.defineProperty(this, "setQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => {
                this.query = query;
            }
        });
        Object.defineProperty(this, "setCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (calls) => {
                this.calls = calls;
            }
        });
        Object.defineProperty(this, "setTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (timeSeries) => {
                this.timeSeries = timeSeries;
            }
        });
        Object.defineProperty(this, "addTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (timeSeries) => {
                const target = this.timeSeries.find((it) => it.termGroupID === timeSeries.termGroupID);
                if (!target) {
                    this.timeSeries.push(timeSeries);
                }
            }
        });
        Object.defineProperty(this, "removeTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const targetIdx = this.timeSeries.findIndex((it) => it.termGroupID === id);
                if (targetIdx >= 0) {
                    this.timeSeries.splice(targetIdx, 1);
                }
            }
        });
        makeObservable(this);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "isSavingQuery", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "isLoadingQuery", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "isLoadingCalls", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "isLoadingTimeSeries", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "isLoadingExportCalls", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "query", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryViewState.prototype, "calls", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryViewState.prototype, "timeSeries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setIsSavingQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setIsLoadingQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setIsLoadingCalls", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setIsLoadingTimeSeries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setIsLoadingExportCalls", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setCalls", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "setTimeSeries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "addTimeSeries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryViewState.prototype, "removeTimeSeries", void 0);
