import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Box, ToggleButton, ToggleButtonGroup } from 'lib/cortiUI';
export const JunctionController = (props) => {
    const { activeJunction, onChange } = props;
    const buttons = [
        { text: intl.t('filterBuilder.and', 'And'), value: 'and' },
        { text: intl.t('filterBuilder.or', 'Or'), value: 'or' },
    ];
    function isActive(value) {
        return value === activeJunction;
    }
    return (React.createElement(Box, { flexDirection: "row", alignItems: "center" },
        React.createElement(ToggleButtonGroup, { "data-cy": "filter-junction-controller", exclusive: true, value: activeJunction, onChange: (_e, value) => onChange(value), size: "small", className: css({
                padding: 0,
                minWidth: 45,
                height: 30,
                textTransform: 'lowercase',
            }) }, buttons.map((b, idx) => (React.createElement(ToggleButton, { key: idx, "data-cy-state": isActive(b.value) ? 'active' : undefined, color: isActive(b.value) ? 'primary' : undefined, value: b.value }, b.text))))));
};
