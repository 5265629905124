import React from 'react';
import { useTranslation } from '@corti/i18n';
import { TextField } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const ShortTextField = ({ title, required }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(TextField, { required: required, InputProps: { readOnly: true }, sx: { width: 1 }, error: !title, helperText: !title ? t('preview.validationErrors.noTitle', 'Title is missing.') : undefined, placeholder: t('preview.placeholders.typeAnswer', 'Type your answer here...'), "data-cy": CY_SELECTORS.preview.shortTextFieldPreview, FormHelperTextProps: {
            'data-cy': CY_SELECTORS.preview.shortTextPreviewHelperText,
        } }));
};
