import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, TextField } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const CardHeader = React.memo(function CardHeader({ fieldTitle, fieldType, fieldTypeOptions, onFieldTitleChange, onFieldTypeChange, }) {
    const { t } = useTranslation('libFeedbackFormManager');
    const titleFieldRef = React.useRef(null);
    React.useEffect(() => {
        if (titleFieldRef.current) {
            titleFieldRef.current.focus();
        }
    }, []);
    return (React.createElement(Base, { my: 7, display: "flex", justifyContent: "space-between" },
        React.createElement(TextField, { inputRef: titleFieldRef, required: true, error: !fieldTitle, label: t('fieldWidget.questionFieldName', 'Question title'), value: fieldTitle, onChange: ({ target }) => onFieldTitleChange(target.value), multiline: true, sx: { flex: 1, mr: 4 }, inputProps: { 'data-cy': CY_SELECTORS.fieldWidget.questionField }, helperText: !fieldTitle ? t('fieldWidget.required', 'Required') : undefined, FormHelperTextProps: {
                'data-cy': CY_SELECTORS.fieldWidget.questionTitleHelperText,
                sx: {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    mx: 0,
                },
            } }),
        React.createElement(Autocomplete, { size: "small", options: fieldTypeOptions, getOptionLabel: (option) => option.text, value: fieldType, onChange: (_, option) => {
                if (option) {
                    onFieldTypeChange(option.value);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('fieldWidget.fieldType', 'Answer Type'), inputProps: Object.assign(Object.assign({}, params.inputProps), { 'data-cy': CY_SELECTORS.fieldWidget.questionTypeTextField }) }))), clearIcon: null, sx: { flex: 1 }, "data-cy": CY_SELECTORS.fieldWidget.questionTypeField })));
});
