var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable } from 'mobx';
import { Observer } from '@corti/observer';
export class BlockValueStore {
    constructor() {
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * Key of the map is the id of either block or option.
         */
        Object.defineProperty(this, "_all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.observer = new Observer();
        this.reset();
    }
    get all() {
        return [...this._all.values()];
    }
    reset() {
        this._all = new Map();
    }
    add(value) {
        const { blockPrototypeID, optionID } = value.identifier;
        if (optionID) {
            this._all.set(optionID, value);
            return;
        }
        this._all.set(blockPrototypeID, value);
    }
    remove(identifier) {
        const target = this.getByIdentifier(identifier);
        if (!target) {
            return;
        }
        if (identifier.optionID) {
            this._all.delete(identifier.optionID);
            return;
        }
        this._all.delete(identifier.blockPrototypeID);
    }
    update(input) {
        input.blockValues.forEach((it) => {
            const target = this.getByIdentifier(it.identifier);
            if (target) {
                this.add(it);
            }
        });
        this.observer.fireEvent('values-updated', input);
    }
    getByIdentifier(identifier) {
        return identifier.optionID
            ? this.getByID(identifier.optionID)
            : this.getByID(identifier.blockPrototypeID);
    }
    getByID(id) {
        return this._all.get(id);
    }
    onUpdate(cb) {
        return this.observer.on('values-updated', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], BlockValueStore.prototype, "_all", void 0);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BlockValueStore.prototype, "all", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BlockValueStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BlockValueStore.prototype, "add", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BlockValueStore.prototype, "remove", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BlockValueStore.prototype, "update", null);
