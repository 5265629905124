function getOrSetIfNotExists(map, key, value) {
    const existing = map.get(key);
    if (existing) {
        return existing;
    }
    map.set(key, value);
    return value;
}
function createEmptyCache() {
    const c = {
        branches: new Map(),
        nodes: new Map(),
        nodesMeta: new Map(),
        extraNodes: new Map(),
        viewInstances: new Map(),
        viewProtos: new Map(),
        blockInstances: new Map(),
        blockProtosMeta: new Map(),
        blockProtos: new Map(),
        optionProtos: new Map(),
        optionInstances: new Map(),
        gates: new Map(),
        gatesMeta: new Map(),
        facts: new Map(),
        libraryComponents: new Map(),
        checklists: new Map(),
        // indexes
        nodeIDToBranchID: new Map(),
        blockTypeToBlockProtoIDs: new Map(),
        branchIDToBlockProtoIDs: new Map(),
        componentIDToBlockProtoIDs: new Map(),
    };
    return c;
}
export function createCache(graph) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const data = graph.data;
    const metadata = (_a = graph.metadata.editorApp) !== null && _a !== void 0 ? _a : {};
    const cache = createEmptyCache();
    cache.startNode = graph.data.startBranchNode;
    (_b = metadata.connectorGates) === null || _b === void 0 ? void 0 : _b.forEach((g) => {
        cache.gatesMeta.set(g.id, g);
    });
    (_c = metadata.extraNodes) === null || _c === void 0 ? void 0 : _c.forEach((n) => {
        cache.extraNodes.set(n.id, n);
    });
    (_d = metadata.nodes) === null || _d === void 0 ? void 0 : _d.forEach((n) => {
        cache.nodesMeta.set(n.id, n);
    });
    (_f = (_e = metadata.library) === null || _e === void 0 ? void 0 : _e.components) === null || _f === void 0 ? void 0 : _f.forEach((c) => {
        cache.libraryComponents.set(c.elementID, c);
    });
    (_h = (_g = metadata.prototypes) === null || _g === void 0 ? void 0 : _g.blocks) === null || _h === void 0 ? void 0 : _h.forEach((b) => {
        cache.blockProtosMeta.set(b.id, b);
    });
    (_j = data.checklists) === null || _j === void 0 ? void 0 : _j.forEach((c) => {
        cache.checklists.set(c.id, c);
    });
    data.facts.forEach((f) => {
        cache.facts.set(f.id, f);
    });
    data.prototypes.blockOptions.forEach((b) => {
        cache.optionProtos.set(b.id, b);
    });
    data.prototypes.blocks.forEach((b) => {
        if (b.type === 'SELECT') {
            b.optionInstances.forEach((o) => {
                cache.optionInstances.set(o.id, o);
            });
        }
        cache.blockProtos.set(b.id, b);
        const indexByType = getOrSetIfNotExists(cache.blockTypeToBlockProtoIDs, b.type, new Set());
        indexByType.add(b.id);
    });
    data.prototypes.views.forEach((v) => {
        cache.viewProtos.set(v.id, v);
        v.blockInstances.forEach((b) => {
            cache.blockInstances.set(b.id, Object.assign(Object.assign({}, b), { viewProtoID: v.id }));
            const component = cache.libraryComponents.get(b.blockID);
            if (component) {
                const index = getOrSetIfNotExists(cache.componentIDToBlockProtoIDs, component.elementID, new Set());
                index.add(b.id);
            }
        });
    });
    data.branches.forEach((b) => {
        cache.branches.set(b.id, b);
        b.nodes.forEach((n) => {
            cache.nodes.set(n.id, n);
            cache.nodeIDToBranchID.set(n.id, b.id);
            const viewProto = cache.viewProtos.get(n.viewInstance.viewID);
            viewProto === null || viewProto === void 0 ? void 0 : viewProto.blockInstances.forEach((i) => {
                const index = getOrSetIfNotExists(cache.branchIDToBlockProtoIDs, b.id, new Set());
                index.add(i.blockID);
            });
            cache.viewInstances.set(n.viewInstance.id, n.viewInstance);
            n.gates.forEach((g) => {
                cache.gates.set(g.id, g);
            });
        });
    });
    return cache;
}
export const createResolvers = (cache) => {
    const resolvedObjCache = new Map();
    function memoize(fn) {
        // @ts-ignore
        return (id) => {
            if (resolvedObjCache.has(id)) {
                return resolvedObjCache.get(id);
            }
            const r = fn(id);
            if (r) {
                resolvedObjCache.set(id, r);
            }
            return r;
        };
    }
    const viewInstance = memoize(function viewInstance(id) {
        const i = cache.viewInstances.get(id);
        if (!i) {
            return;
        }
        const p = viewProto(i.viewID);
        if (!p) {
            return;
        }
        return Object.assign(Object.assign({}, i), p);
    });
    const viewProto = memoize(function viewProto(id) {
        const p = cache.viewProtos.get(id);
        if (!p) {
            return;
        }
        return Object.assign(Object.assign({}, p), { blocks: p.blockInstances.map((b) => blockInstance(b.id)) });
    });
    const blockInstance = memoize(function blockInstance(id) {
        const i = cache.blockInstances.get(id);
        if (!i) {
            return;
        }
        const p = blockProto(i.blockID);
        if (!p) {
            return;
        }
        return Object.assign(Object.assign({}, i), { blockPrototype: p });
    });
    const blockProto = memoize(function blockProto(id) {
        var _a, _b, _c, _d, _e, _f;
        const p = cache.blockProtos.get(id);
        if (!p) {
            return;
        }
        const meta = cache.blockProtosMeta.get(id);
        switch (p.type) {
            case 'DOCUMENT':
            case 'FLOW_VALUE_COLLECTOR':
            case 'IMAGE': {
                return p;
            }
            case 'TEXTAREA_INPUT':
            case 'TEXT_INPUT':
            case 'NUMBER_INPUT':
            case 'DATE_PICKER': {
                return Object.assign(Object.assign({}, p), { valuePublisherConfig: (_a = p.valuePublisherConfig) !== null && _a !== void 0 ? _a : { collectors: [] } });
            }
            case 'ACTION': {
                return Object.assign(Object.assign({}, p), { triggeredStateExpression: (_c = (_b = p.actionBlock) === null || _b === void 0 ? void 0 : _b.triggeredStateExpression) !== null && _c !== void 0 ? _c : undefined, backgroundColor: (_d = meta === null || meta === void 0 ? void 0 : meta.backgroundColor) !== null && _d !== void 0 ? _d : '' });
            }
            case 'PARAGRAPH': {
                return Object.assign(Object.assign({}, p), { collapsable: (_e = meta === null || meta === void 0 ? void 0 : meta.collapsable) !== null && _e !== void 0 ? _e : false });
            }
            case 'SELECT': {
                const bb = Object.assign(Object.assign({}, p), { valuePublisherConfig: (_f = p.valuePublisherConfig) !== null && _f !== void 0 ? _f : { collectors: [] }, options: p.optionInstances.map((it) => {
                        return optionInstance(it.id);
                    }) });
                return bb;
            }
        }
    });
    function optionInstance(id) {
        var _a;
        const i = cache.optionInstances.get(id);
        if (!i) {
            return;
        }
        const p = optionProto(i.optionID);
        if (!p) {
            return;
        }
        return Object.assign(Object.assign(Object.assign({}, i), p), { valuePublisherConfig: (_a = p.valuePublisherConfig) !== null && _a !== void 0 ? _a : { collectors: [] } });
    }
    function optionProto(id) {
        return cache.optionProtos.get(id);
    }
    const branch = memoize(function branch(id) {
        var _a;
        const b = cache.branches.get(id);
        if (!b) {
            return;
        }
        return Object.assign(Object.assign({}, b), { keywords: (_a = b.keywords) !== null && _a !== void 0 ? _a : [] });
    });
    const node = memoize(function node(id) {
        const n = cache.nodes.get(id);
        if (!n) {
            return;
        }
        const v = viewInstance(n.viewInstance.id);
        if (!v) {
            return;
        }
        const nodeMeta = cache.nodesMeta.get(id);
        const bid = cache.nodeIDToBranchID.get(id);
        if (!bid) {
            return;
        }
        return Object.assign(Object.assign({}, n), { branch: branch(bid), view: v, pinnedInTriage: (nodeMeta === null || nodeMeta === void 0 ? void 0 : nodeMeta.pinnedInTriage) || false, favoriteNode: (nodeMeta === null || nodeMeta === void 0 ? void 0 : nodeMeta.favoriteNode) || false });
    });
    const fact = memoize(function fact(id) {
        var _a;
        const f = cache.facts.get(id);
        if (!f) {
            return;
        }
        return Object.assign(Object.assign({}, f), { valuePublisherConfig: (_a = f.valuePublisherConfig) !== null && _a !== void 0 ? _a : {
                collectors: [],
            } });
    });
    return {
        blockProto,
        blockInstance,
        optionInstance,
        optionProto,
        viewInstance,
        viewProto,
        node,
        branch,
        fact,
    };
};
