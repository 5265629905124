import React from 'react';
import { trackerService } from 'browser/services/init';
import { Base } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { useInteraction } from '../../context';
import { Footer } from './Footer';
import { Header } from './Header';
import { Questions } from './Questions';
import { ReviewProvider, ValidationProvider } from './context';
export const ManualAssessment = () => {
    const { interaction } = useInteraction();
    React.useEffect(() => {
        trackerService.track('[Manual Assessment] Opened manual assessment', {
            interactionID: interaction === null || interaction === void 0 ? void 0 : interaction.id,
        });
    }, [interaction === null || interaction === void 0 ? void 0 : interaction.id]);
    return (React.createElement(ReviewProvider, null,
        React.createElement(ValidationProvider, null,
            React.createElement(Base, { overflow: "hidden", display: "grid", gridTemplateRows: "1fr auto" },
                React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", overflow: "hidden", gap: 6 },
                    React.createElement(Header, null),
                    React.createElement(Questions, null)),
                React.createElement(Footer, null)))));
};
