var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
export class TranscriptSearchViewState {
    constructor() {
        Object.defineProperty(this, "isLoadingAllQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "allQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isLoadingProtocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "protocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "isLoadingTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "teams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "setIsLoadingAllQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingAllQueries = value;
            }
        });
        Object.defineProperty(this, "setQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (queries) => {
                this.allQueries = queries;
            }
        });
        Object.defineProperty(this, "addQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => {
                if (!this.allQueries)
                    return;
                const target = this.allQueries.find((it) => it.id === query.id);
                if (!target) {
                    this.allQueries.unshift(query);
                }
            }
        });
        Object.defineProperty(this, "removeQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (queryID) => {
                if (!this.allQueries)
                    return;
                const targetIdx = this.allQueries.findIndex((it) => it.id === queryID);
                if (targetIdx >= 0) {
                    this.allQueries.splice(targetIdx, 1);
                }
            }
        });
        Object.defineProperty(this, "updateQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => {
                if (!this.allQueries)
                    return;
                const targetIdx = this.allQueries.findIndex((it) => it.id === query.id);
                if (targetIdx >= 0) {
                    this.allQueries.splice(targetIdx, 1, query);
                }
            }
        });
        Object.defineProperty(this, "setIsLoadingProtocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingProtocolNames = value;
            }
        });
        Object.defineProperty(this, "setProtocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (protocolNames) => {
                this.protocolNames = protocolNames;
            }
        });
        Object.defineProperty(this, "setIsLoadingTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isLoadingTeams = value;
            }
        });
        Object.defineProperty(this, "setTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (teams) => {
                this.teams = teams;
            }
        });
        makeObservable(this);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "isLoadingAllQueries", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "allQueries", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "isLoadingProtocolNames", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptSearchViewState.prototype, "protocolNames", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "isLoadingTeams", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptSearchViewState.prototype, "teams", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setIsLoadingAllQueries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setQueries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "addQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "removeQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "updateQuery", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setIsLoadingProtocolNames", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setProtocolNames", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setIsLoadingTeams", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptSearchViewState.prototype, "setTeams", void 0);
