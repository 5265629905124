import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { TimelineEntryDefinitionSourceType } from '@corti/lib/coreApiService';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Button, FileInput, Typography } from 'lib/cortiUI';
import { ensureTimelineEntryDefinitionsMutation, } from './graphql';
import { DefinitionPropertyNameInJson } from './types';
const DEFAULT_COLOR = '#787878';
export const TimelineEntryDefinitionImport = () => {
    const { organization } = useAuth();
    const { t } = useTranslation();
    const [ensureTimelineEntryDefinitions, { loading: isEnsureTimelineEntryDefinitionsMutationLoading },] = useMutation(ensureTimelineEntryDefinitionsMutation);
    async function handleFileSelect(files) {
        const file = files[0];
        try {
            const data = await file.text();
            const parsed = JSON.parse(data);
            const searchTermDefinitions = parseSearchTermDefinitionFromJson(parsed);
            await ensureTimelineEntryDefinitions({
                variables: { organizationID: organization.id, definitions: searchTermDefinitions },
            });
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('adminPanel:orgDataManagement.successfullyUploaded', 'Successfully uploaded'),
            });
        }
        catch (error) {
            if (error instanceof Error) {
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: error.message,
                });
            }
        }
    }
    function parseSearchTermDefinitionFromJson(data) {
        if (data.hasOwnProperty(DefinitionPropertyNameInJson.searchTermDefinition)) {
            const searchTermdefinitionsInput = data[DefinitionPropertyNameInJson.searchTermDefinition]
                .filter((it) => !!it.category)
                .map((it) => {
                var _a, _b, _c;
                return ({
                    source: {
                        type: TimelineEntryDefinitionSourceType.searchTerms,
                        entityType: it.category,
                    },
                    title: it.category,
                    text: (_a = it.name) !== null && _a !== void 0 ? _a : '',
                    style: {
                        color: (_b = it.color) !== null && _b !== void 0 ? _b : DEFAULT_COLOR,
                    },
                    keywords: (_c = it.keywords) !== null && _c !== void 0 ? _c : [],
                });
            });
            return searchTermdefinitionsInput;
        }
        else {
            throw new Error(t('adminPanel:orgDataManagement.definitionsJsonNotValid', 'The definitions JSON file is not valid'));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: "h6", color: "default", mb: 3 }, t('adminPanel:orgDataManagement.annotationCreation', 'Annotation creation')),
        React.createElement(FileInput, { accept: "application/json", onSelectFiles: (file) => handleFileSelect(file) }, ({ selectFiles }) => (React.createElement(Button, { color: "primary", onClick: selectFiles, disabled: isEnsureTimelineEntryDefinitionsMutationLoading }, t('adminPanel:orgDataManagement.searchTermDefinitionsImportBtn', 'Select Search-term definitions JSON file for import'))))));
};
