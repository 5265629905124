import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { withdrawInteractionAcknowledgement } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { repositories } from 'core/repositories';
import { Base, Card, ChipV2, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
export const Acknowledged = () => {
    var _a;
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.acknowledged' });
    const { interaction, refetchInteraction } = useInteraction();
    const { currentUserID } = useAuth();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleWithdrawAcknowledgement() {
        try {
            if (!interaction)
                return;
            setIsLoading(true);
            await withdrawInteractionAcknowledgement(interaction.id);
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('withdrawAcknowledgmentSuccess', 'Acknowledgment Withdrawn'),
            });
            trackerService.track('[Acknowledgement Status] acknowledgment withdrawn', {
                interactionID: interaction.id,
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('withdrawAcknowledgmentError', 'Failed to withdraw acknowledgment'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    if (!interaction)
        return null;
    //FIXME: Remove dependency on repositories
    const user = repositories.users.getUser(interaction.acknowledgement_status_updated_by);
    return (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 5, p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(ChipV2, { color: "success", variant: "outlined", label: t('chipLabel', 'Acknowledged'), icon: React.createElement(DownloadDoneRoundedIcon, null) }),
            currentUserID === interaction.interaction_owner && (React.createElement(Base, null,
                React.createElement(IconButton, { icon: React.createElement(MoreHorizRoundedIcon, null), size: "small", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget) }),
                React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
                    React.createElement(MenuItem, { onClick: handleWithdrawAcknowledgement, disabled: isLoading },
                        React.createElement(ListItemIcon, null,
                            React.createElement(KeyboardReturnRoundedIcon, null)),
                        React.createElement(ListItemText, { primary: t('withdrawAcknowledgment', 'Withdraw Acknowledgment') })))))),
        React.createElement(Typography, { variant: "body2", color: "default" },
            t('statusChanged', {
                defaultValue: 'By {{user}} on ',
                user: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : 'Unknown User',
            }),
            formatDateTime(new Date(interaction.acknowledgement_status_updated_at)))));
};
