import { gql } from '@apollo/client';
export const getTimelineEntryDefinitions = gql `
  query getTimelineEntryDefinitions($organizationID: ID!) {
    timelineEntryDefinitions(organizationID: $organizationID) {
      source {
        type
        entityType
        entityID
      }
      title
      text
      style {
        color
      }
    }
  }
`;
// #endregion
