import React from 'react';
import { transparentize } from '@corti/style';
import { useRichTextEditor } from './context';
export function LeafRenderer(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { leaf, attributes, children } = props;
    const richTextEditor = useRichTextEditor();
    const decorations = [];
    if ((_a = leaf.attributes) === null || _a === void 0 ? void 0 : _a.strikethrough) {
        decorations.push('line-through');
    }
    if ((_b = leaf.attributes) === null || _b === void 0 ? void 0 : _b.underline) {
        decorations.push('underline');
    }
    if (richTextEditor.hideMetaText && ((_c = leaf.attributes) === null || _c === void 0 ? void 0 : _c.meta)) {
        return null;
    }
    return (React.createElement("span", Object.assign({}, attributes, { style: {
            color: ((_d = leaf.attributes) === null || _d === void 0 ? void 0 : _d.meta) ? 'orange' : undefined,
            borderRadius: 3,
            backgroundColor: ((_e = leaf.attributes) === null || _e === void 0 ? void 0 : _e.backgroundColor) && transparentize(0.5, (_f = leaf.attributes) === null || _f === void 0 ? void 0 : _f.backgroundColor),
            fontWeight: ((_g = leaf.attributes) === null || _g === void 0 ? void 0 : _g.bold) ? 700 : undefined,
            fontStyle: ((_h = leaf.attributes) === null || _h === void 0 ? void 0 : _h.italic) ? 'italic' : undefined,
            textDecoration: decorations.join(' '),
        } }), children));
}
