var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { config } from 'core/configuration/browser';
import { Box, Button, IconButton, InputAdornment, Text, TextField } from 'lib/cortiUI';
import { unauthorizedPasswordFeedbackText } from 'lib/passwordRequirementsTexter';
// @ts-ignore
let PasswordResetSetNewForm = class PasswordResetSetNewForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                password: '',
                repeatPassword: '',
                isPasswordVisible: false,
                errorMesssage: '',
                passwordFeedback: '',
            }
        });
        Object.defineProperty(this, "handlePasswordInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ password: e.target.value });
                this.setPasswordFeedback('');
            }
        });
        Object.defineProperty(this, "handleRepeatPasswordInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ repeatPassword: e.target.value });
            }
        });
        Object.defineProperty(this, "togglePasswordVisibility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({ isPasswordVisible: !prevState.isPasswordVisible }));
            }
        });
        Object.defineProperty(this, "setPasswordFeedback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ passwordFeedback: text });
            }
        });
        Object.defineProperty(this, "handleFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.preventDefault();
                this.setState({ errorMesssage: '' });
                this.postNewPassword({
                    password: this.state.password,
                    userID: this.props.userID,
                    token: this.props.token,
                })
                    .then(async (res) => {
                    if (res.ok) {
                        this.props.onSuccess();
                    }
                    else {
                        const payload = await res.json();
                        if (payload.error.code) {
                            this.setPasswordFeedback(unauthorizedPasswordFeedbackText(payload.error.code));
                            return;
                        }
                        this.props.onFailure(new Error(res.statusText));
                    }
                })
                    .catch(() => {
                    this.setState({ errorMesssage: intl.t('remoteServerErrorMsg', 'Something went wrong') });
                });
            }
        });
    }
    postNewPassword(params) {
        const url = `${config.getConfig().apiHost}/api/v2/users/${params.userID}/set-password`;
        return fetch(url, {
            method: 'post',
            body: JSON.stringify({
                token: params.token,
                password: params.password,
            }),
        });
    }
    get isFormValid() {
        return this.state.password && this.state.password == this.state.repeatPassword;
    }
    render() {
        var _a;
        const { password, repeatPassword, isPasswordVisible, errorMesssage, passwordFeedback } = this.state;
        return (React.createElement(Box, { "data-cy": "password-reset-form" },
            React.createElement("form", { onSubmit: this.handleFormSubmit, className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: (_a = this.props.theme) === null || _a === void 0 ? void 0 : _a.space[6],
                }) },
                React.createElement(DialogContentText, null, intl.t('team:passwordReset.setNewPasswordNotice', 'Set the new password')),
                React.createElement(TextField, { fullWidth: true, label: intl.t('password', 'Password'), type: isPasswordVisible ? 'text' : 'password', value: password, onChange: this.handlePasswordInputChange, error: passwordFeedback != '', InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { onClick: this.togglePasswordVisibility, icon: this.state.isPasswordVisible ? (React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" })) : (React.createElement(VisibilityRoundedIcon, { fontSize: "small" })), size: "small" }))),
                    }, inputProps: {
                        'data-cy': 'password-reset-password-input',
                    } }),
                React.createElement(TextField, { fullWidth: true, label: intl.t('repeatPassword', 'Repeat password'), type: isPasswordVisible ? 'text' : 'password', value: repeatPassword, onChange: this.handleRepeatPasswordInputChange, helperText: passwordFeedback, error: passwordFeedback != '', inputProps: {
                        'data-cy': 'password-reset-repeat-password-input',
                    } }),
                React.createElement(Button, { className: css({ alignSelf: 'flex-end' }), type: "submit", variant: "text", "data-cy": "password-reset-btn", color: "primary", disabled: !this.isFormValid }, intl.t('confirm', 'Confirm'))),
            errorMesssage && (React.createElement(Text, { textAlign: "center", size: "small", color: "error" }, errorMesssage))));
    }
};
PasswordResetSetNewForm = __decorate([
    withTheme
], PasswordResetSetNewForm);
export { PasswordResetSetNewForm };
