import { gql } from '@apollo/client';
export const ensureTimelineEntryDefinitionsMutation = gql `
  mutation ensureTimelineEntryDefinitions(
    $definitions: [TimelineEntryDefinitionInput!]!
    $organizationID: ID!
  ) {
    ensureTimelineEntryDefinitions(definitions: $definitions, organizationID: $organizationID)
  }
`;
export const annotationTypesQuery = gql `
  query annotationTypes($organizationID: ID!) {
    annotationTypes(organizationID: $organizationID) {
      id
      hidden
      labelName
      objectiveName
    }
  }
`;
export const hideAnnotationTypesMutation = gql `
  mutation hideAnnotationTypes($ids: [String!], $orgID: ID!) {
    hideAnnotationTypes(ids: $ids, orgID: $orgID)
  }
`;
export const detectionTypesQuery = gql `
  query getDetectionTypes($organizationID: ID!) {
    detectionTypes(organizationID: $organizationID) {
      name
      hidden
    }
  }
`;
export const hideDetectionTypesMutation = gql `
  mutation setHiddenDetectionTypes($names: [String!], $organizationID: ID!) {
    setHiddenDetectionTypes(names: $names, organizationID: $organizationID)
  }
`;
