export const TRANSCRIPTS_API_ERROR_NAME = 'TranscriptsApiError';
export class TranscriptsApiError extends Error {
    constructor(input, error) {
        super(input.message);
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /** Should be used only for debugging purposes */
        Object.defineProperty(this, "extra", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "devMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = TRANSCRIPTS_API_ERROR_NAME;
        this.code = input.code;
        this.extra = error
            ? {
                response: error.response,
                request: error.request,
            }
            : undefined;
        this.devMessage = this.extra ? JSON.stringify(this.extra, null, 2) : undefined;
    }
    /** Something happened in setting up the request that triggered an Error, js error */
    static Internal(error) {
        return new TranscriptsApiError({
            code: 'internal',
            message: error === null || error === void 0 ? void 0 : error.message,
        }, error);
    }
    /**
     * The request was made and the server responded with a status code
     * that falls out of the range of 2xx
     */
    static ServerInternal(error) {
        return new TranscriptsApiError({
            code: 'server-internal',
            message: error === null || error === void 0 ? void 0 : error.message,
        }, error);
    }
    /**
     * The request was made but no response was received
     * `error.request` is an instance of XMLHttpRequest in the browser and an instance of
     * http.ClientRequest in node.js
     */
    static Unavailable(error) {
        return new TranscriptsApiError({
            code: 'unavailable',
            message: error === null || error === void 0 ? void 0 : error.message,
        }, error);
    }
}
