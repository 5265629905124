import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProvider } from '@corti/theme';
import { ContextMenuProvider } from '../components/ContextMenu';
export const CortiUIContextProvider = ({ children, theme, }) => {
    return (React.createElement(CacheProvider, { value: cache },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(ContextMenuProvider, null,
                React.createElement(CssBaseline, null),
                children))));
};
