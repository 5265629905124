import React from 'react';
import { intl } from '@corti/i18n';
import { TeamRowAction } from './actions';
export function getTeamsRows(teams) {
    return teams.map((team) => ({
        id: team.id,
        name: team.name,
        membersCount: team.members.length,
        actions: team.id,
    }));
}
export function getTeamsColumns() {
    return [
        {
            field: 'name',
            headerName: intl.t('teamsView.table.name', 'Name'),
            flex: 3,
            editable: false,
        },
        {
            field: 'membersCount',
            headerName: intl.t('teamsView.table.memberCount', 'Call-Takers'),
            flex: 3,
            editable: false,
        },
        {
            field: 'actions',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: function renderTeamActions({ value }) {
                if (!value)
                    return null;
                return React.createElement(TeamRowAction, { id: value });
            },
        },
    ];
}
