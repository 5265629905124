import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import React from 'react';
import { css, darken, readableColor } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card, FocusElement, Typography } from 'lib/cortiUI';
function getReadableTextColor(backgroundColor) {
    return readableColor(darken(0.3, backgroundColor), 'rgba(0, 0, 0, 1)', 'rgba(255, 255, 255, 1)');
}
export function Alert({ alert, onActionClick, }) {
    var _a;
    const theme = useTheme();
    const backgroundColor = (_a = alert.color) !== null && _a !== void 0 ? _a : theme.palette.background.card;
    const color = getReadableTextColor(backgroundColor);
    return (React.createElement(Card, { p: 0, width: "100%", maxWidth: 340, display: "grid", gridTemplateColumns: "1fr auto", borderRadius: 5, className: css({
            border: 'none',
            backgroundColor: backgroundColor,
        }) },
        React.createElement(Base, { py: 5, px: 7, flexGrow: 1, display: "grid", gridTemplateRows: "1fr 1fr 1fr", alignItems: "center", className: css({
                color: color,
            }) },
            React.createElement(Typography, { variant: "body1", noWrap: true, noSelect: true, fontWeight: "500" }, alert.title),
            React.createElement(Typography, { variant: "body2", noWrap: true, noSelect: true }, alert.text)),
        React.createElement(FocusElement, { defaultElementID: "alert-action-button" }, ({ registerElementRef }) => {
            return (React.createElement(Base, { "data-cy": "alert-widget-action", ref: registerElementRef, px: 6, display: "flex", alignItems: "center", justifyContent: "center", onClick: onActionClick, className: css({
                    color: color,
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }) },
                React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "large", color: "inherit", className: css({ cursor: 'inherit' }) })));
        })));
}
