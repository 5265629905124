import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Checkbox } from 'lib/cortiUI';
import { ChecklistTableRow } from './ChecklistTableRow';
import { useChecklistView } from './useChecklistView';
export function ChecklistTable() {
    const checklistRows = useChecklistView(({ checklistRows }) => checklistRows);
    const selected = useChecklistView(({ selected }) => selected);
    const toggleAllChecklistSelection = useChecklistView(({ toggleAllChecklistSelection }) => toggleAllChecklistSelection);
    const { t } = useTranslation('libGraphEditor');
    const checklistCount = checklistRows.length;
    const selectionCount = selected.length;
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { size: "medium" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { sx: { width: '5%' } },
                        React.createElement(Checkbox, { color: "primary", indeterminate: selectionCount > 0 && selectionCount < checklistCount, checked: checklistCount > 0 && selectionCount === checklistCount, onChange: toggleAllChecklistSelection, inputProps: { 'aria-label': t('checklistsView.selectAllChecklists', 'Select all') } })),
                    React.createElement(TableCell, { sx: { width: '35%' } }, t('checklistsView.columnTitleName', 'Name')),
                    React.createElement(TableCell, { sx: { width: '10%' } }, t('checklistsView.columnTitleEntries', 'Entries')),
                    React.createElement(TableCell, { sx: { width: '20%' } }, t('checklistsView.columnTitleKey', 'Key')),
                    React.createElement(TableCell, { sx: { width: '30%' } }, t('checklistsView.columnTitleVisibility', 'Visibility')))),
            React.createElement(TableBody, null, checklistRows.map((checklist) => (React.createElement(ChecklistTableRow, { key: checklist.id, checklist: checklist })))))));
}
