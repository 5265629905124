const VARIABLE_REF_REGEXP = /\${([^\}]+)\}/;
export function replaceVariable(expr, replacer) {
    return expr.replace(new RegExp(VARIABLE_REF_REGEXP, 'g'), (a) => {
        const match = a.match(VARIABLE_REF_REGEXP);
        if (match) {
            const parsed = match[1];
            return replacer(parsed);
        }
        return a;
    });
}
export function replaceVariableValue(expr, replacer) {
    return replaceVariable(expr, (v) => {
        return wrapVariable(replacer(v));
    });
}
export function getVariables(expr) {
    const matches = Array.from(expr.matchAll(new RegExp(VARIABLE_REF_REGEXP, 'gi')));
    return matches.map((m) => m[1]);
}
export function wrapVariable(variable) {
    return '${' + variable + '}';
}
export function isEmpty(expr) {
    return expr.trim() === '';
}
export const ExpressionUtils = {
    replaceVariable,
    replaceVariableValue,
    getVariables,
    wrapVariable,
    isEmpty,
};
