import { BaseStream } from './BaseStream';
import { createMessageEvent } from './message';
export class PrivateStream extends BaseStream {
    constructor(realtime, streamDef) {
        super(realtime);
        Object.defineProperty(this, "def", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'initial'
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.realtime.connection.send({
                    event: 'unsubscribe-stream',
                    stream: {
                        name: this.def.name,
                        id: this.def.id,
                    },
                });
                this.unbindEvents();
                super.destroy();
            }
        });
        Object.defineProperty(this, "bindEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.realtime.connection.on('stateChanged', this.handleConnStateChange);
                this.onStateChange((state) => {
                    if (state === 'subscribed') {
                        this.sendMessageQueue();
                    }
                });
            }
        });
        Object.defineProperty(this, "unbindEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.realtime.connection.un('stateChanged', this.handleConnStateChange);
            }
        });
        Object.defineProperty(this, "handleConnStateChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                if (event.current === 'connected') {
                    // resubscribe on reconnect
                    if (this.state === 'subscribed' || this.state === 'subscribing') {
                        void this.subscribe();
                    }
                }
            }
        });
        Object.defineProperty(this, "subscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                this.setState('subscribing');
                if (this.realtime.connection.state !== 'connected') {
                    return;
                }
                try {
                    const res = await this.sendAndWait(createMessageEvent({
                        event: 'subscribe-stream',
                        stream: {
                            name: this.def.name,
                            id: this.def.id,
                        },
                    }));
                    if (res.event === 'stream-subscription-succeeded') {
                        this.setState('subscribed');
                        return;
                    }
                    if (res.event === 'stream-subscription-failed') {
                        throw new Error('Server refused subscription');
                    }
                    throw new Error('Unknown server response to subscription handshake');
                }
                catch (err) {
                    if (err instanceof Error) {
                        if (err.message === 'already subscribed') {
                            this.setState('subscribed');
                            return;
                        }
                        if (err.message === 'REQUEST_TIMEOUT') {
                            // will retry subscribing when connection state changes
                            return;
                        }
                    }
                    this.setState('failed');
                }
            }
        });
        Object.defineProperty(this, "unsubscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.realtime.unsubscribe(this);
            }
        });
        Object.defineProperty(this, "onStateChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('stateChange', cb);
            }
        });
        // #region testing
        Object.defineProperty(this, "dispatchEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (eventName, data) => {
                this.realtime.dispatchEvent(createMessageEvent({
                    event: eventName,
                    stream: this.def,
                    data,
                }));
            }
        });
        this.def = streamDef;
        this.bindEvents();
        void this.subscribe();
    }
    send(eventName, data) {
        const event = createMessageEvent({ event: String(eventName), data });
        event.stream = {
            name: this.def.name,
            id: this.def.id,
        };
        if (this.realtime.connection.state !== 'connected' || this.state !== 'subscribed') {
            this.queueEvent(event);
            return;
        }
        else {
            this.sendNow(event);
        }
    }
    /**
     * This works similarly as a HTTP request, it will wait until a response from the server (either success or error)
     * to come back and will resolve/reject the promise.
     */
    async sendWithAck(eventName, data) {
        await this.sendAndWait(createMessageEvent({
            event: eventName.toString(),
            data: data,
            stream: {
                name: this.def.name,
                id: this.def.id,
            },
        }));
    }
    setState(state) {
        if (this.state !== state) {
            this.state = state;
            this.observer.fireEvent('stateChange', state);
        }
    }
}
function toID(streamDef) {
    return `${streamDef.name}/${streamDef.id}`;
}
function toDef(streamID) {
    const [name, id] = streamID.split('/');
    return { name, id };
}
function isEqual(str1, str2) {
    return toID(str1) === toID(str2);
}
export const StreamUtils = {
    toID,
    toDef,
    isEqual,
};
