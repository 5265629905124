var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { CustomProperties } from '../common/customProperties';
import { BaseEntity } from '../core';
export class BaseInstance extends BaseEntity {
    constructor() {
        super();
        Object.defineProperty(this, "triggerOnMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.customProperties = new CustomProperties();
    }
    get context() {
        return this.parent;
    }
    setParent(parent) {
        super.setParent(parent);
    }
    setTriggerOnMount(value) {
        this.triggerOnMount = value;
    }
    remove() {
        var _a;
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.removeElement(this);
    }
    get readableTitle() {
        return this.wrappedElement.readableTitle;
    }
    copy() {
        const i = super.copy();
        if (this.triggerOnMount != null) {
            i.setTriggerOnMount(this.triggerOnMount);
        }
        i.customProperties = this.customProperties.copy();
        return i;
    }
    deserialize(value) {
        super.deserialize(value);
        if (value.triggerOnMount != null) {
            this.setTriggerOnMount(value.triggerOnMount);
        }
        this.customProperties.deserialize(value.customProperties);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { triggerOnMount: this.triggerOnMount, customProperties: this.customProperties.getEncodableData() });
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], BaseInstance.prototype, "triggerOnMount", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseInstance.prototype, "setTriggerOnMount", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseInstance.prototype, "readableTitle", null);
