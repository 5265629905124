import { observer } from 'mobx-react';
import React from 'react';
import { FocusElement } from 'lib/cortiUI';
import { collectedItemsToClipboardContent, getReadableCollectorItems, } from '../models/CollectorModel';
import { ActionBlock } from './ActionBlock';
import { CollectorBlock } from './CollectorBlock';
import { DatePickerBlock } from './DatePickerBlock';
import { DocumentBlock } from './DocumentBlock';
import { ImageBlock } from './ImageBlock';
import { NumberInputBlock, TextInputBlock } from './InputBlock';
import { ParagraphBlock } from './ParagraphBlock';
import { SelectBlockView } from './SelectBlock';
function transformToTruthyValue(blockValue) {
    if (blockValue.identifier.optionID) {
        return Object.assign(Object.assign({}, blockValue), { value: 'true' });
    }
    return blockValue;
}
export const BlockRenderer = observer(function BlockRenderer(props) {
    const { isReadonly, block, stepModel, flowStore, shouldAutoProgress } = props;
    if (!block) {
        return null;
    }
    switch (block.blockPrototype.type) {
        case 'DATE_PICKER': {
            const { blockPrototype } = block;
            const blockValue = flowStore.blockValueStore.getByID(blockPrototype.id);
            return (React.createElement(FocusElement, { defaultElementID: blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => (React.createElement(DatePickerBlock, { isDateTime: blockPrototype.isDateTime, ref: registerElementRef, isReadonly: isReadonly, value: (blockValue === null || blockValue === void 0 ? void 0 : blockValue.value) || undefined, label: blockPrototype.label.richText || blockPrototype.label.plainText, placeholder: blockPrototype.placeholder, onChange: (value) => {
                    if (blockValue) {
                        flowStore.blockValueStore.update({
                            blockValues: [Object.assign(Object.assign({}, blockValue), { value })],
                            options: {
                                shouldAutoProgress: false,
                            },
                            source: 'user-action',
                            sourceData: {
                                step: stepModel,
                                blockInstance: block,
                                location: stepModel.type,
                            },
                        });
                    }
                } }))));
        }
        case 'PARAGRAPH': {
            const { blockPrototype } = block;
            return (React.createElement(FocusElement, { defaultElementID: blockPrototype.id, isFocusable: !isReadonly && blockPrototype.collapsable }, ({ registerElementRef }) => (React.createElement(ParagraphBlock, { ref: registerElementRef, text: blockPrototype.text.richText || blockPrototype.text.plainText, isReadonly: isReadonly, isCollapsable: blockPrototype.collapsable }))));
        }
        case 'ACTION': {
            const actionBlock = block;
            return (React.createElement(FocusElement, { defaultElementID: actionBlock.blockPrototype.id, isFocusable: !isReadonly && !actionBlock.blockPrototype.invisible }, ({ registerElementRef }) => (React.createElement(ActionBlock, { isReadonly: isReadonly, innerRef: registerElementRef, isPending: flowStore.actionStore.isPending(block.blockPrototype.id), isActivated: flowStore.actionStore.isActivated(block.blockPrototype.id), isInvisible: actionBlock.blockPrototype.invisible, text: actionBlock.blockPrototype.text.plainText, onClick: () => {
                    flowStore.actionStore.trigger({
                        blockPrototype: actionBlock.blockPrototype,
                        blockInstance: actionBlock,
                        source: 'user-action',
                        sourceData: {
                            step: stepModel,
                            location: stepModel.type,
                        },
                    });
                }, backgroundColor: actionBlock.blockPrototype.backgroundColor }))));
        }
        case 'TEXT_INPUT':
        case 'TEXTAREA_INPUT': {
            const { blockPrototype } = block;
            const blockValue = flowStore.blockValueStore.getByID(blockPrototype.id);
            return (React.createElement(FocusElement, { defaultElementID: blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => (React.createElement(TextInputBlock, { isReadonly: isReadonly, ref: registerElementRef, value: (blockValue === null || blockValue === void 0 ? void 0 : blockValue.value) || '', label: blockPrototype.label.richText || blockPrototype.label.plainText, placeholder: blockPrototype.placeholder, multiline: blockPrototype.type === 'TEXTAREA_INPUT', rows: blockPrototype.type === 'TEXTAREA_INPUT' ? 3 : undefined, handleFinalValue: (value) => {
                    if (blockValue) {
                        flowStore.blockValueStore.update({
                            blockValues: [Object.assign(Object.assign({}, blockValue), { value })],
                            options: {
                                shouldAutoProgress: false,
                            },
                            source: 'user-action',
                            sourceData: {
                                step: stepModel,
                                blockInstance: block,
                                location: stepModel.type,
                            },
                        });
                    }
                }, handleTemporaryValue: (value) => {
                    if (blockValue) {
                        flowStore.tempBlockValueStore.add(Object.assign(Object.assign({}, blockValue), { value }));
                    }
                } }))));
        }
        case 'NUMBER_INPUT': {
            const { blockPrototype } = block;
            const blockValue = flowStore.blockValueStore.getByID(blockPrototype.id);
            const { min, max, precision, step } = blockPrototype.numberBlock;
            const additionalProps = { min, max, precision, step };
            return (React.createElement(FocusElement, { defaultElementID: blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => (React.createElement(NumberInputBlock, Object.assign({ isReadonly: isReadonly, ref: registerElementRef, value: (blockValue === null || blockValue === void 0 ? void 0 : blockValue.value) || '', label: blockPrototype.label.richText || blockPrototype.label.plainText, placeholder: blockPrototype.placeholder, handleFinalValue: (value) => {
                    if (blockValue) {
                        flowStore.blockValueStore.update({
                            blockValues: [Object.assign(Object.assign({}, blockValue), { value })],
                            options: {
                                shouldAutoProgress: false,
                            },
                            source: 'user-action',
                            sourceData: {
                                step: stepModel,
                                blockInstance: block,
                                location: stepModel.type,
                            },
                        });
                    }
                }, handleTemporaryValue: (value) => {
                    if (blockValue) {
                        flowStore.tempBlockValueStore.add(Object.assign(Object.assign({}, blockValue), { value }));
                    }
                } }, additionalProps)))));
        }
        case 'SELECT': {
            const { blockPrototype } = block;
            const blockValues = blockPrototype.options
                .map((o) => flowStore.blockValueStore.getByID(o.id))
                .filter((o) => !!o);
            return (React.createElement(SelectBlockView, { isReadonly: isReadonly, blockPrototype: blockPrototype, blockValues: blockValues, onChange: (blockValues) => {
                    flowStore.blockValueStore.update({
                        blockValues,
                        options: {
                            shouldAutoProgress,
                        },
                        source: 'user-action',
                        sourceData: {
                            step: stepModel,
                            blockInstance: block,
                            location: stepModel.type,
                        },
                    });
                }, onPreviewEnter: (blockIdentifier) => {
                    const blockValue = flowStore.blockValueStore.getByIdentifier(blockIdentifier);
                    if (blockValue) {
                        flowStore.tempBlockValueStore.add(transformToTruthyValue(blockValue));
                    }
                }, onPreviewLeave: (blockIdentifier) => {
                    flowStore.tempBlockValueStore.remove(blockIdentifier);
                } }));
        }
        case 'FLOW_VALUE_COLLECTOR': {
            const { blockPrototype } = block;
            const collector = flowStore.collectorStore.getCollectorInstanceByID(block.id);
            if (!collector) {
                throw new Error('collector not found');
            }
            const readableItems = getReadableCollectorItems(collector, flowStore);
            return (React.createElement(FocusElement, { defaultElementID: block.blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => {
                var _a, _b;
                return (React.createElement(CollectorBlock, { isCopyButtonEnabled: flowStore.isCopyButtonEnabled, isReadonly: isReadonly, innerRef: registerElementRef, label: ((_a = blockPrototype.label) === null || _a === void 0 ? void 0 : _a.richText) || ((_b = blockPrototype.label) === null || _b === void 0 ? void 0 : _b.plainText), items: readableItems, onCustomValueAdd: (customValue) => {
                        collector.collectCustomValue(customValue);
                    }, onCustomValueUpdate: (item, customValue) => {
                        collector.updateCustomValue(item, customValue);
                    }, onItemClick: (item) => {
                        if (item.valueType === 'block' || item.valueType === 'block-option') {
                            const step = flowStore.stepStore.getLastStepByBlockValue(item.values[0]);
                            if (step) {
                                flowStore.changeActiveStep({
                                    step,
                                    source: 'user-action',
                                    sourceData: {
                                        location: 'block:flow-value-collector',
                                    },
                                });
                            }
                        }
                    }, onItemRemove: (item) => {
                        collector.removeCollectedItem(item);
                    }, getClipboardContents: () => {
                        const items = collectedItemsToClipboardContent(collector, flowStore);
                        const clipboardItems = {};
                        items.forEach((it) => {
                            clipboardItems[it.type] = new Blob([it.content], { type: it.type });
                        });
                        return [new ClipboardItem(clipboardItems)];
                    } }));
            }));
        }
        case 'DOCUMENT': {
            const { blockPrototype } = block;
            if (!blockPrototype.mediaAsset) {
                return null;
            }
            const mediaAsset = flowStore.mediaProvider.resolveMediaAsset(blockPrototype.mediaAsset.id);
            if (!mediaAsset) {
                return React.createElement("div", null, '⚠️ unresolved media asset');
            }
            return (React.createElement(FocusElement, { defaultElementID: blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => {
                var _a, _b, _c, _d;
                return (React.createElement(DocumentBlock, { isReadonly: isReadonly, innerRef: registerElementRef, text: mediaAsset.name, mediaAsset: mediaAsset, newWindowPositionProps: {
                        left: (_a = flowStore.childWindowOptions) === null || _a === void 0 ? void 0 : _a.defaultX,
                        top: (_b = flowStore.childWindowOptions) === null || _b === void 0 ? void 0 : _b.defaultY,
                        height: (_c = flowStore.childWindowOptions) === null || _c === void 0 ? void 0 : _c.defaultHeight,
                        width: (_d = flowStore.childWindowOptions) === null || _d === void 0 ? void 0 : _d.defaultWidth,
                    } }));
            }));
        }
        case 'IMAGE': {
            const { blockPrototype } = block;
            if (!blockPrototype.mediaAsset) {
                return null;
            }
            const mediaAsset = flowStore.mediaProvider.resolveMediaAsset(blockPrototype.mediaAsset.id);
            if (!mediaAsset) {
                return React.createElement("div", null, '⚠️ unresolved media asset');
            }
            if (mediaAsset.typename !== 'image') {
                return (React.createElement("div", null, `⚠️ unexpected media asset type resolved ${mediaAsset.typename}, expecting 'image'`));
            }
            return (React.createElement(FocusElement, { defaultElementID: block.blockPrototype.id, isFocusable: !isReadonly }, ({ registerElementRef }) => {
                var _a, _b, _c, _d;
                return (React.createElement(ImageBlock, { isReadonly: isReadonly, innerRef: registerElementRef, mediaAsset: mediaAsset, newWindowPositionProps: {
                        left: (_a = flowStore.childWindowOptions) === null || _a === void 0 ? void 0 : _a.defaultX,
                        top: (_b = flowStore.childWindowOptions) === null || _b === void 0 ? void 0 : _b.defaultY,
                        height: (_c = flowStore.childWindowOptions) === null || _c === void 0 ? void 0 : _c.defaultHeight,
                        width: (_d = flowStore.childWindowOptions) === null || _d === void 0 ? void 0 : _d.defaultWidth,
                    } }));
            }));
        }
        default: {
            const { blockPrototype } = block;
            return React.createElement("div", null, `[${blockPrototype.type}: no renderer]`);
        }
    }
});
