import React from 'react';
import { intl } from '@corti/i18n';
import { Box, Button, TextField } from 'lib/cortiUI';
import { useToolbar } from 'lib/webappUI';
export function CommentsInput({ onSubmit }) {
    const { isToolPanelOpened } = useToolbar();
    const inputRef = React.useRef(null);
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        var _a;
        if (isToolPanelOpened) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isToolPanelOpened]);
    function handleSubmit() {
        onSubmit(value);
        setValue('');
    }
    function handleCommentChange(e) {
        setValue(e.target.value);
    }
    function handleInputKeyDown(e) {
        var _a;
        if (e.key === 'Escape') {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            return;
        }
        if (e.key === 'Enter' && !e.shiftKey && canSubmit()) {
            e.preventDefault();
            handleSubmit();
        }
    }
    function canSubmit() {
        return value !== '';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { inputRef: inputRef, fullWidth: true, multiline: true, onKeyDown: handleInputKeyDown, onChange: handleCommentChange, rows: 3, label: intl.t('realtime:commentsView.inputPlaceholder', 'Add comment'), value: value }),
        React.createElement(Box, { mt: 6, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" },
            React.createElement(Button, { disabled: !canSubmit(), size: "small", variant: "text", color: "primary", onClick: handleSubmit }, intl.t('realtime:add', 'Add')))));
}
