import { useEffect, useRef } from 'react';
/**
 * If the value changes from outside while unfocussed, update the internal value
 * but importantly, don't update just because the focus changes.
 *
 * This is done to support componentized input blocks. These sync across instances,
 * so if one instance updates, others do too. If you have multiple visible on screen at
 * once, (e.g. in the same node or in the current and next node), then the input needs
 * to be able to update.
 *
 * But we *cannot* ever update from the outside while focused, because the outside value
 * will update out of sync with the internal state due to the debounce.
 */
function useUpdateWhileUnfocused(hasFocus, value, setInternalValue) {
    const focusRef = useRef(hasFocus);
    focusRef.current = hasFocus;
    useEffect(() => {
        if (!focusRef.current) {
            setInternalValue(value);
        }
    }, [setInternalValue, value]);
}
export default useUpdateWhileUnfocused;
