import { gql } from '@apollo/client';
const inputValueFieldsFragment = gql `
  fragment InputValueFieldsRef on __InputValue {
    name
  }
`;
const typeFieldsFragment = gql `
  fragment TypeFieldsRef on __Type {
    name
    kind
    ofType {
      name
      kind
    }
  }
`;
export const typeFragment = gql `
  fragment TypeRef on __Type {
    ...TypeFieldsRef
    inputFields {
      ...InputValueFieldsRef
      type {
        ...TypeFieldsRef
        inputFields {
          ...InputValueFieldsRef
          type {
            ...TypeFieldsRef
            inputFields {
              ...InputValueFieldsRef
              type {
                ...TypeFieldsRef
                inputFields {
                  ...InputValueFieldsRef
                  type {
                    ...TypeFieldsRef
                    inputFields {
                      ...InputValueFieldsRef
                      type {
                        ...TypeFieldsRef
                        inputFields {
                          ...InputValueFieldsRef
                          type {
                            ...TypeFieldsRef
                            inputFields {
                              ...InputValueFieldsRef
                              type {
                                ...TypeFieldsRef
                                inputFields {
                                  ...InputValueFieldsRef
                                  type {
                                    ...TypeFieldsRef
                                    inputFields {
                                      ...InputValueFieldsRef
                                      type {
                                        ...TypeFieldsRef
                                        inputFields {
                                          ...InputValueFieldsRef
                                          type {
                                            ...TypeFieldsRef
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${inputValueFieldsFragment}
  ${typeFieldsFragment}
`;
const getTypeInputFields = gql `
  query getTypeInputFields($typeName: String!) {
    __type(name: $typeName) {
      ...TypeRef
    }
  }
  ${typeFragment}
`;
export const graphqlSchemaQueries = (client) => ({
    async getTypeInputFields(typeName) {
        const response = await client.query({
            query: getTypeInputFields,
            variables: { typeName: typeName },
        });
        return response.data.__type;
    },
});
