import Downshift from 'downshift';
import React from 'react';
import { css } from '@corti/style';
import { useFilterGroup } from './FilterGroupProvider';
export function FilterGroupWrapper({ children }) {
    const { closeMenu, setInputValue, setIsOpen, menuView, onAttributeChange, onComparisonChange, inputValue, isOpen, downshiftProps, } = useFilterGroup();
    return (React.createElement(Downshift, { onStateChange: (change) => {
            switch (change.type) {
                case Downshift.stateChangeTypes.keyDownEscape:
                    closeMenu();
                    break;
                case Downshift.stateChangeTypes.keyDownEnter:
                    break;
                case Downshift.stateChangeTypes.blurInput:
                    break;
                default: {
                    if (change.isOpen === false) {
                        closeMenu();
                        return;
                    }
                    if (change.inputValue != null) {
                        setInputValue(change.inputValue);
                    }
                    if (change.isOpen === true) {
                        setIsOpen(true);
                    }
                }
            }
        }, itemToString: (item) => (item ? item.text : ''), onSelect: (item) => {
            if (item) {
                menuView === 'attribute' ? onAttributeChange(item) : onComparisonChange(item);
            }
        }, inputValue: inputValue, isOpen: isOpen }, (props) => {
        downshiftProps.current = props;
        return (React.createElement("div", { className: css({
                position: 'relative',
                height: '100%',
                width: '100%',
            }) }, children));
    }));
}
