import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Box, Button } from 'lib/cortiUI';
import * as GQL from '../graphql';
export function DeleteTeamView(props) {
    const { t } = useTranslation('teams');
    const auth = useAuth();
    const theme = useTheme();
    const [deleteTeamMutation, deleteTeamResult] = useMutation(GQL.deleteTeamMutation, {
        variables: { id: props.teamID },
    });
    async function handleDelete() {
        var _a;
        await deleteTeamMutation({
            refetchQueries: [
                {
                    query: GQL.getTeamsQuery,
                    variables: {
                        orgID: auth.organization.id,
                    },
                },
            ],
        });
        (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { "data-cy": "delete-team-dialog", className: css({ flexDirection: 'column', gap: theme.space[6] }) },
            React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 4 },
                React.createElement(Button, { variant: "text", color: "error", onClick: props.onClose }, t('deleteTeamModal.cancelBtn', 'Cancel')),
                React.createElement(Button, { "data-cy": "delete-team-dialog-delete-btn", color: "error", onClick: handleDelete, disabled: deleteTeamResult.loading }, t('deleteTeamModal.saveBtn', 'Delete'))))));
}
