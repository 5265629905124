var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable } from 'mobx';
export class FocusContextRegistry {
    constructor() {
        Object.defineProperty(this, "groupedElements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "registerElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (element, position) => {
                var _a;
                if (this.getElementByID(element.id)) {
                    return;
                }
                const g = (_a = this.groupedElements.get(element.groupID)) !== null && _a !== void 0 ? _a : [];
                if (position !== undefined) {
                    g.splice(position, 0, element);
                }
                else {
                    g.push(element);
                }
                this.groupedElements.set(element.groupID, g);
            }
        });
        Object.defineProperty(this, "unregisterElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (elementID) => {
                const e = this.getElementByID(elementID);
                if (!e) {
                    return;
                }
                const g = this.groupedElements.get(e.groupID);
                if (g) {
                    const idx = g.findIndex((it) => it.id === e.id);
                    if (idx >= 0) {
                        g.splice(idx, 1);
                    }
                }
            }
        });
        Object.defineProperty(this, "updateElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (elementID, input) => {
                const e = this.getElementByID(elementID);
                if (e) {
                    Object.assign(e, input);
                }
            }
        });
        Object.defineProperty(this, "registerElementGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (groupID) => {
                if (this.groupedElements.get(groupID)) {
                    return;
                }
                this.groupedElements.set(groupID, []);
            }
        });
        Object.defineProperty(this, "unregisterElementGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (groupID) => {
                this.groupedElements.delete(groupID);
            }
        });
        Object.defineProperty(this, "getElementByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (elementID) => {
                return this.elements.find((it) => it.id === elementID);
            }
        });
        makeObservable(this);
        this.groupedElements = new Map();
    }
    get elements() {
        return Array.from(this.groupedElements.values())
            .flatMap((it) => it)
            .filter((it) => !!it);
    }
}
__decorate([
    action,
    __metadata("design:type", Object)
], FocusContextRegistry.prototype, "updateElement", void 0);
