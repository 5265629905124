import React from 'react';
/**
 * Identical to `React.useEffect` hook except that it is delayed by a `wait` amount of millis
 */
export function useDebouncedEffect(cb, wait, deps) {
    const interval = React.useRef();
    React.useEffect(() => {
        interval.current = window.setTimeout(cb, wait);
        return () => {
            window.clearTimeout(interval.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
