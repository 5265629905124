import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Text } from 'lib/cortiUI/components/Text';
import { Tooltip } from 'lib/cortiUI/components/Tooltip';
import { Typography } from 'lib/cortiUI/components/Typography';
import { HasExpression } from './HasExpression';
import { HasNote } from './HasNote';
import { useRichTextEditor } from './context';
export function renderElement(props) {
    const element = props.element;
    switch (element.type) {
        case 'paragraph':
            return React.createElement(Paragraph, Object.assign({}, props));
        case 'h1':
            return React.createElement(H1, Object.assign({}, props));
        case 'h2':
            return React.createElement(H2, Object.assign({}, props));
        case 'li':
            return React.createElement(Li, Object.assign({}, props));
        case 'ul':
            return React.createElement(Ul, Object.assign({}, props));
        case 'ol':
            return React.createElement(Ol, Object.assign({}, props));
        case 'link':
            return React.createElement(Link, Object.assign({}, props));
        case 'hasnote':
            return React.createElement(HasNote, Object.assign({}, props));
        case 'hasexpression':
            return React.createElement(HasExpression, Object.assign({}, props));
        default:
            console.warn(`No rich text renderer for node type ${element.type}`);
            return (React.createElement(Typography, { variant: "body1", color: "default", display: "block" }, props.children));
    }
}
function Paragraph(props) {
    const theme = useTheme();
    return (React.createElement(Typography, Object.assign({ variant: "body1", color: "default", display: "block" }, props.attributes, { className: css({
            margin: 0,
            ':not(:last-of-type)': { marginBottom: theme.space[2] },
        }) }), props.children));
}
function H1(props) {
    const theme = useTheme();
    return (React.createElement(Typography, Object.assign({ variant: "h5", color: "default", display: "block", className: css({
            marginTop: theme.space[4],
            marginBottom: theme.space[4],
        }) }, props.attributes), props.children));
}
function H2(props) {
    const theme = useTheme();
    return (React.createElement(Typography, Object.assign({ variant: "h6", color: "default", display: "block", className: css({
            marginTop: theme.space[3],
            marginBottom: theme.space[3],
        }) }, props.attributes), props.children));
}
function Li(props) {
    return (React.createElement("li", { className: css({
            margin: 0,
            ':not(:last-of-type)': { marginBottom: 2 },
        }) },
        React.createElement(Text, Object.assign({ display: "block" }, props.attributes), props.children)));
}
function Ol(props) {
    const theme = useTheme();
    return (React.createElement("ol", Object.assign({}, props.attributes, { className: css({
            margin: `${theme.space[3]}px 0`,
            padding: 0,
            paddingLeft: theme.space[7],
            listStyle: 'disc',
            listStyleType: 'decimal',
        }) }), props.children));
}
function Ul(props) {
    const theme = useTheme();
    return (React.createElement("ul", Object.assign({}, props.attributes, { className: css({
            margin: `${theme.space[3]}px 0`,
            padding: 0,
            paddingLeft: theme.space[7],
            listStyle: 'disc',
        }) }), props.children));
}
function Link(props) {
    var _a, _b, _c;
    const theme = useTheme();
    const editor = useRichTextEditor();
    const element = props.element;
    if (editor.readOnly) {
        return (React.createElement("a", Object.assign({ rel: "noreferrer noopener", href: (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.url, target: "_blank", className: css({
                color: theme.palette.primary.main,
                cursor: 'pointer',
                textDecoration: 'underline',
            }) }, props.attributes), props.children));
    }
    else {
        return (React.createElement(Tooltip, { title: React.createElement("a", { rel: "noreferrer noopener", href: (_b = element.attributes) === null || _b === void 0 ? void 0 : _b.url, target: "_blank" }, (_c = element.attributes) === null || _c === void 0 ? void 0 : _c.url) },
            React.createElement("span", Object.assign({ className: css({
                    textDecoration: 'underline',
                    color: theme.palette.primary.main,
                }) }, props.attributes), props.children)));
    }
}
