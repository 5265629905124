var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { background as backgroundFn, border as borderFn, flexbox as flexboxFn, grid as gridFn, // color is not used, due to conflicts with other libraries
layout as layoutFn, opacity as opacityFn, position as positionFn, shadow as shadowFn, space as spaceFn, style, typography as typographyFn, } from 'styled-system';
/**
 * This function normalizes props (for now only `props.theme` inconsistencies)
 * Our theme type is a bit different from what `styled-system` utility functions expect to receive
 * e.g. we have `theme.breakpoints` which is of different type
 * This is a problem only with clashing theme fields
 */
const normalizeProps = (fn) => {
    const styleFunction = (_a) => {
        var { theme } = _a, rest = __rest(_a, ["theme"]);
        if (!theme) {
            throw new Error('When using style-system functions, make sure you provide `props.theme`');
        }
        return fn(Object.assign(Object.assign({}, rest), { theme: Object.assign(Object.assign({}, theme), { breakpoints: [
                    theme.breakpoints.values.sm + 'px',
                    theme.breakpoints.values.md + 'px',
                    theme.breakpoints.values.lg + 'px',
                    theme.breakpoints.values.xl + 'px',
                ] }) }));
    };
    // styled-system's style functions have some utility properties
    Object.assign(styleFunction, fn);
    return styleFunction;
};
// Custom css function to add "elevation" (box-shadow) to a component
export const elevation = normalizeProps(style({
    prop: 'elevation',
    cssProperty: 'boxShadow',
    key: 'shadows', // this is theme[key]
}));
export const flexGap = normalizeProps(style({
    prop: 'gap',
    cssProperty: 'gap',
    key: 'space',
}));
export const space = normalizeProps(spaceFn);
export const grid = normalizeProps(gridFn);
export const layout = normalizeProps(layoutFn);
export const flexbox = normalizeProps(flexboxFn);
export const border = normalizeProps(borderFn);
export const opacity = normalizeProps(opacityFn);
export const position = normalizeProps(positionFn);
export const background = normalizeProps(backgroundFn);
export const shadow = normalizeProps(shadowFn);
export const typography = normalizeProps(typographyFn);
