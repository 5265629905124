var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ExpressionUtils } from '@corti/lib/graphs';
import { BaseEntity } from 'lib/graphEditor/core';
import { PortModel } from '../../PortModel';
import { LinkPortalNodeModel } from '../LinkPortalNode';
export class LogicGateModel extends BaseEntity {
    constructor(input = {}) {
        super();
        Object.defineProperty(this, "port", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expression", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.port = new PortModel({
            position: { x: 0, y: 0 },
            type: 'out',
            maxLinks: 1,
        });
        if (input.parent) {
            this.setParent(input.parent);
        }
        runInAction(() => {
            var _a;
            this.expression = (_a = input.expression) !== null && _a !== void 0 ? _a : '';
        });
    }
    setExpression(value) {
        this.expression = value;
    }
    get expressionVariables() {
        return ExpressionUtils.getVariables(this.expression);
    }
    hasExpressionVariable(variable) {
        return this.expressionVariables.includes(variable);
    }
    setPort(port) {
        this.port = port;
    }
    setParent(newParent) {
        const prevParent = this.parent;
        if (prevParent === newParent) {
            return;
        }
        if (prevParent) {
            prevParent.removePort(this.port);
        }
        if (newParent) {
            newParent.addPort(this.port);
        }
        this.port.invalidatePosition();
        this.parent = newParent;
    }
    remove() {
        this.port.remove();
        this.parent.removeLogicGate(this);
    }
    get targetNode() {
        const link = this.port.links[0];
        if (link) {
            const inPort = link.inPort;
            return inPort === null || inPort === void 0 ? void 0 : inPort.parent;
        }
    }
    get finalTargetNode() {
        let nextTarget = this.targetNode;
        while (nextTarget instanceof LinkPortalNodeModel) {
            nextTarget = nextTarget.targetNode;
        }
        return nextTarget;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { expression: this.expression, portID: this.port.id });
    }
    deserialize(entity) {
        this.id = entity.id;
        this.expression = entity.expression;
    }
    copy() {
        const i = super.copy();
        i.setExpression(this.expression);
        return i;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LogicGateModel.prototype, "parent", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], LogicGateModel.prototype, "expression", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], LogicGateModel.prototype, "setExpression", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], LogicGateModel.prototype, "expressionVariables", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LogicGateModel.prototype, "setParent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], LogicGateModel.prototype, "deserialize", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LogicGateModel.prototype, "copy", null);
