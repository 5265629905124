import CheckRounded from '@mui/icons-material/CheckRounded';
import CloseRounded from '@mui/icons-material/CloseRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export const CallMetricsBreakdownItem = ({ title, value, }) => {
    const { t } = useTranslation('performanceApp');
    const getCallDetectionLabelComponent = (value) => {
        switch (value) {
            case 'detected':
                return React.createElement(CheckRounded, { fontSize: "medium", color: "success" });
            case 'not_detected':
                return React.createElement(CloseRounded, { fontSize: "medium", color: "error" });
            default:
                return (React.createElement(Typography, { variant: "subtitle2", color: "success" }, t('metricsWidget.detectionValueLabel.obvious', 'Obvious')));
        }
    };
    return (React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8 },
        React.createElement(Typography, { color: "default", variant: "subtitle1" }, title),
        getCallDetectionLabelComponent(value)));
};
