import { observer } from 'mobx-react';
import React from 'react';
import { useUpdateNodeInternals } from 'reactflow';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ChipV2, Tooltip } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { BaseGraphEditorNodeWidget, NodeMainContent } from '../common/BaseGraphEditorNodeWidget';
import { LogicGateContainerWidget } from './LogicGateWidget';
export const ViewNodeWidget = observer(function ViewNodeWidget(props) {
    const { model } = props;
    const theme = useTheme();
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const updateNodeInternals = useUpdateNodeInternals();
    // Reordering or removing logic gates, changes the position of ports, which in turn requires
    // recalculating edges. This effect will force the node to recalculate
    React.useEffect(() => {
        updateNodeInternals(model.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model.logicGates]);
    const isStartNode = editor.model.startNode === model;
    const isBranchStartNode = editor.state.activeBranch.startNode === model;
    const suffixes = [];
    if (isStartNode) {
        suffixes.push(t('viewNode.isGraphStartSuffix', 'graph start'));
    }
    if (isBranchStartNode) {
        suffixes.push(t('viewNode.isBranchStartSuffix', 'branch start'));
    }
    const suffixText = suffixes.join(',');
    return (React.createElement(BaseGraphEditorNodeWidget, { model: model, title: t('viewNode.title', 'View Node') + (suffixText ? ` (${suffixText})` : ''), isFavoriteNode: model.favoriteNode },
        React.createElement(NodeMainContent, null,
            React.createElement("span", { className: css({
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    overflow: 'hidden',
                    color: theme.palette.text.primary,
                }) }, props.model.contentSummary),
            model.logicGates.length > 0 && (React.createElement("div", { className: css({
                    marginTop: 8,
                }) },
                React.createElement(LogicGateContainerWidget, { node: model }))),
            model.uniqueValuePublishDestinationCollectorPrototypeIDs.size !== 0 && (React.createElement(Tooltip, { title: React.createElement(Base, null, [...model.uniqueValuePublishDestinationCollectorPrototypeIDs.values()]
                    .map((id) => {
                    const collector = editor.model.elementContext.getPrototypeByID(id);
                    if (!collector) {
                        return '[missing ref]';
                    }
                    return collector.readableTitle;
                })
                    .map((text, idx) => (React.createElement("div", { key: idx }, text)))) },
                React.createElement(ChipV2, { sx: { mt: 2 }, size: "small", variant: "outlined", label: `Values published` }))))));
});
