import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Typography } from 'lib/cortiUI';
export function ComparisonHeader({ onReturn, title }) {
    const theme = useTheme();
    return (React.createElement(Box, { p: 5, flexDirection: "row", alignItems: "center", justifyContent: "center", elevation: 6, className: css({ position: 'relative' }) },
        React.createElement(Box, { px: 5, onClick: onReturn, justifyContent: "center", alignItems: "center", className: css({
                cursor: 'pointer',
                position: 'absolute',
                height: '100%',
                left: 0,
            }) },
            React.createElement(KeyboardArrowLeftRoundedIcon, { fontSize: "medium", className: css({ color: theme.palette.text.hint, marginRight: 7, marginLeft: 7 }) })),
        React.createElement(Typography, { variant: "caption", noWrap: true, className: css({
                maxWidth: '60%',
            }) }, title)));
}
