var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { assign, createMachine } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
const INITIAL_ENTRY_CONTEXT = {
    id: '',
    label: '',
    details: '',
    key: '',
    inlineNodeID: '',
    hasInlineNode: false,
    manualCompletionEnabled: false,
    automaticCompletionEnabled: false,
    manuallyChecked: false,
    automaticallyChecked: false,
};
const entryMachine = createMachine({
    tsTypes: {},
    schema: {
        context: {},
        events: {},
    },
    predictableActionArguments: true,
    id: 'entry',
    initial: 'initial',
    context: INITIAL_ENTRY_CONTEXT,
    states: {
        initial: {
            always: [{ target: 'checked', cond: 'anyChecked' }, { target: 'unchecked' }],
        },
        unchecked: {
            exit: 'checkParent',
            on: {
                TOGGLE: { actions: 'manualToggle' },
                AUTOCHECK: { actions: 'autoToggle' },
            },
            always: { target: 'checked', cond: 'anyChecked' },
        },
        checked: {
            exit: 'uncheckParent',
            on: {
                AUTOCHECK: { actions: 'autoToggle' },
                AUTOUNCHECK: { actions: 'autoToggle' },
                TOGGLE: { actions: 'manualToggle' },
            },
            always: { target: 'unchecked', cond: 'noneChecked' },
        },
    },
}, {
    actions: {
        manualToggle: assign(({ manuallyChecked }) => ({ manuallyChecked: !manuallyChecked })),
        autoToggle: assign(({ automaticallyChecked }) => ({
            automaticallyChecked: !automaticallyChecked,
        })),
        checkParent: sendParent(({ id }) => ({ type: 'CHECK', id })),
        uncheckParent: sendParent(({ id }) => ({ type: 'UNCHECK', id })),
    },
    guards: {
        anyChecked: ({ manuallyChecked, automaticallyChecked }) => manuallyChecked || automaticallyChecked,
        noneChecked: ({ manuallyChecked, automaticallyChecked }) => !manuallyChecked && !automaticallyChecked,
    },
});
export function startEntryMachine(entry, snapshot) {
    var _a;
    const { rank, automaticCompletionExpression } = entry, rest = __rest(entry, ["rank", "automaticCompletionExpression"]);
    return entryMachine.withContext(Object.assign(Object.assign(Object.assign({}, INITIAL_ENTRY_CONTEXT), rest), { manuallyChecked: (_a = snapshot.manualChecksById[entry.id]) !== null && _a !== void 0 ? _a : false, automaticallyChecked: false }));
}
