var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, runInAction } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { logger } from '@corti/logger';
import { coreRealtimeClient, errorReportService, trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
export class RemoteRequestStore {
    constructor() {
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: logger.getLogger('RemoteRequestStore')
        });
        Object.defineProperty(this, "apiInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRefreshingApiInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasRefreshedApiInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.hasRefreshedApiInfo = false;
            this.isRefreshingApiInfo = false;
            this.apiInfo = undefined;
        });
        void this.refreshApiInfo();
        this.interceptRequests();
        this.registerApiInfoRefreshHook();
    }
    registerApiInfoRefreshHook() {
        coreRealtimeClient.connection.on('stateChanged', (e) => {
            if (e.current === 'connected') {
                void this.refreshApiInfo();
            }
        });
    }
    async refreshApiInfo() {
        runInAction(() => {
            this.isRefreshingApiInfo = true;
        });
        try {
            const apiInfo = await api.getServerInfo();
            runInAction(() => {
                this.isRefreshingApiInfo = false;
                this.hasRefreshedApiInfo = true;
                this.apiInfo = apiInfo;
            });
            trackerService.setCommonProperties({
                beVersion: apiInfo.version,
                beEnv: apiInfo.env,
            });
        }
        catch (err) {
            console.error(err);
            runInAction(() => {
                this.isRefreshingApiInfo = false;
                this.hasRefreshedApiInfo = true;
                this.apiInfo = undefined;
            });
        }
    }
    changeApiHost(apiHost) {
        this.logger.info(`Switching api host to ${apiHost} from ${config.getConfig().apiHost}`);
        config.setUserConfigValue('apiHost', apiHost);
        api.setHost(apiHost);
        if (coreRealtimeClient.connection.state !== 'disconnected' &&
            coreRealtimeClient.connection.state !== 'initialized') {
            coreRealtimeClient.reconnectToAltHost({
                apiHost: apiHost,
            });
        }
        void this.refreshApiInfo();
    }
    interceptRequests() {
        api.onErrorResponse((err) => {
            switch (err.code) {
                case 'unauthorized': {
                    if (authStore.isAuthenticated) {
                        errorReportService.preventReportingOnce(err);
                        this.handle401();
                    }
                    return;
                }
                case 'unavailable': {
                    errorReportService.preventReportingOnce(err);
                    return;
                }
                case 'schema-mismatch':
                case 'internal': {
                    errorReportService.captureException(err);
                    return;
                }
            }
        });
    }
    handle401() {
        authStore.logout();
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RemoteRequestStore.prototype, "apiInfo", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RemoteRequestStore.prototype, "isRefreshingApiInfo", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RemoteRequestStore.prototype, "hasRefreshedApiInfo", void 0);
