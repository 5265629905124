import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { HomeView } from './HomeView';
const mod = {
    id: 'home',
    title: () => intl.t('home:appName', 'Home'),
    path: '/home',
    icon: InboxRoundedIcon,
    requiresOrgFeature: 'inbox',
    requiresUserPermission: 'app:inbox',
    viewComponent: () => React.createElement(HomeView, null),
    navGroupe: 1,
};
export default mod;
