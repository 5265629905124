var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from '../MissionControlService';
import { formatArrayQueryParams } from '../utils';
export async function getTasksList(_a) {
    var { offset = 0, limit = 20, assignees = [], status = [] } = _a, restParams = __rest(_a, ["offset", "limit", "assignees", "status"]);
    const formattedParams = formatArrayQueryParams(['assignees', assignees], ['status', status]);
    return await api
        .get('/tasks/list', {
        params: Object.assign(Object.assign({ offset,
            limit }, formattedParams), restParams),
    })
        .then((response) => response.data);
}
/**
 *
 * @param taskID
 * @param userID Unassign current user if not passed
 * @returns
 */
export async function unassignTask(taskID, userID) {
    return await api.post(`/tasks/${taskID}/unassign`, undefined, { params: { user_id: userID } });
}
export * from './types';
