import { useEffect, useRef } from 'react';
export function useSyncChecklists({ send, allChecklists, currentChecklistIds, autoCheckedEntryIds, snapshot, }) {
    // Handle changes to checklist array
    const onChecklistAdded = (id) => {
        const checklist = allChecklists.find((c) => c.id === id);
        if (checklist) {
            send({ type: 'ADD', checklist, snapshot: snapshot.current });
        }
    };
    const onChecklistRemoved = (id) => send({ type: 'REMOVE', id });
    useListDiff(currentChecklistIds, onChecklistAdded, onChecklistRemoved);
    // Handle changes to automatic checks
    const onAutomaticCheckAdded = (id) => send({ type: 'AUTOCHECK', id, checked: true });
    const onAutomaticCheckRemoved = (id) => send({ type: 'AUTOCHECK', id, checked: false });
    useListDiff(autoCheckedEntryIds, onAutomaticCheckAdded, onAutomaticCheckRemoved, true);
}
// A utility function to get the diff between two lists. Returns a tuple of
// [added, removed] items.
function diff(oldList, newList) {
    const oldSet = new Set(oldList);
    const newSet = new Set(newList);
    const added = newList.filter((item) => !oldSet.has(item));
    const removed = oldList.filter((item) => !newSet.has(item));
    return [added, removed];
}
function useListDiff(list, onAdded, onRemoved, initiallyEmpty = false) {
    const lastKnownList = useRef(initiallyEmpty ? [] : list);
    const stableAdded = useRef(onAdded);
    const stableRemoved = useRef(onRemoved);
    useEffect(() => {
        const [added, removed] = diff(lastKnownList.current, list);
        added.forEach(stableAdded.current);
        removed.forEach(stableRemoved.current);
        // update ref
        lastKnownList.current = list;
    }, [list]);
}
