var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import TooltipMui from '@mui/material/Tooltip';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Typography } from 'lib/cortiUI/components/Typography';
/**
 * Displays information text on hover the children component
 */
export const Tooltip = React.forwardRef(function Tooltip(props, ref) {
    const { classes, title, className } = props, rest = __rest(props, ["classes", "title", "className"]);
    const theme = useTheme();
    return (React.createElement(TooltipMui, Object.assign({ ref: ref, title: !!title && typeof title === 'string' ? (React.createElement(Typography, { variant: "caption", color: "inherit" }, title)) : (title), classes: Object.assign(Object.assign({}, classes), { tooltip: css({
                backgroundColor: theme.palette.background.tooltip,
                color: theme.palette.primary.contrastText,
            }, className) }) }, rest)));
});
