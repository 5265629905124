var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Checklist as ChecklistDisplay } from '@corti/corti-ui';
import { useChecklist } from '../data';
import { useUIListener } from '../data/ChecklistUIProvider';
import { ChecklistEntry } from './ChecklistEntry';
export const Checklist = memo(function Checklist({ actor }) {
    const _a = useChecklist(actor), { entries, onToggle } = _a, data = __rest(_a, ["entries", "onToggle"]);
    const listener = useUIListener();
    const handleToggle = () => {
        listener({ type: data.isExpanded ? 'collapse' : 'expand', checklistId: data.id });
        onToggle();
    };
    return (React.createElement(ChecklistDisplay, Object.assign({ onToggle: handleToggle }, data), entries.map((entry) => (React.createElement(ChecklistEntry, { checklistId: data.id, key: entry.id, actor: entry.ref })))));
});
