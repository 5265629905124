import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { darken } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, IconButton, Typography } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
export const TimelineToolbar = observer(() => {
    var _a, _b;
    const { caseViewState } = useCaseViewContext();
    if ((_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.timelinePreview) {
        return React.createElement(PreviewModeToolbar, null);
    }
    if ((_b = caseViewState.caseEntity) === null || _b === void 0 ? void 0 : _b.timelineEditor.isDirty) {
        return React.createElement(TimelineEditingToolbar, null);
    }
    return null;
});
const TimelineEditingToolbar = observer(() => {
    var _a;
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    const timelineEditor = (_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.timelineEditor;
    if (!timelineEditor) {
        return null;
    }
    const count = timelineEditor.diff.addedItems.length +
        timelineEditor.diff.changedItems.length +
        timelineEditor.diff.removedItems.length;
    return (React.createElement(ToolbarContainer, null,
        React.createElement(IconButton, { "data-cy": "undo-btn", icon: React.createElement(UndoRoundedIcon, { fontSize: "small" }), disabled: !timelineEditor.canUndo, onClick: () => {
                timelineEditor.undo();
            } }),
        React.createElement(IconButton, { "data-cy": "redo-btn", icon: React.createElement(RedoRoundedIcon, { fontSize: "small" }), disabled: !timelineEditor.canRedo, onClick: () => {
                timelineEditor.redo();
            } }),
        React.createElement(Typography, { variant: "body2", "data-cy": "change-tracking-text", width: theme.typography.fontSize * 15, color: "hint", textAlign: "center" }, intl.t('explore:caseView.timelineEditingToolbar.changesMade', {
            count,
            defaultValue_one: `{{count}} update pending`,
            defaultValue_other: `{{count}} updates pending`,
        })),
        React.createElement(Button, { "data-cy": "reset-btn", onClick: () => {
                timelineEditor.resetAndExit();
            } }, intl.t('explore:caseView.timelineEditingToolbar.revertBtn', 'Revert')),
        React.createElement(Button, { "data-cy": "submit-btn", color: "primary", disabled: timelineEditor.isSaving || !timelineEditor.diffHasChanges, onClick: () => {
                void timelineEditor.saveAndExit();
            } }, intl.t('explore:caseView.timelineEditingToolbar.saveBtn', 'Save'))));
});
const PreviewModeToolbar = observer(() => {
    var _a;
    const { caseViewState } = useCaseViewContext();
    if ((_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.timelinePreview) {
        return (React.createElement(ToolbarContainer, null,
            React.createElement(Typography, { color: "default", variant: "body2" }, intl.t('explore:caseView.previewModeToolbar.title', 'Preview mode')),
            React.createElement(IconButton, { icon: React.createElement(HighlightOffRoundedIcon, { fontSize: "small" }), onClick: () => {
                    var _a;
                    (_a = caseViewState.caseEntity) === null || _a === void 0 ? void 0 : _a.exitTimelinePreview();
                    caseViewState.feedbackViewState.setActiveView('feedback-list');
                } })));
    }
    return null;
});
function ToolbarContainer(props) {
    const theme = useTheme();
    return (React.createElement(Base, Object.assign({ "data-cy": "timeline-toolbar-container", borderTop: `1px solid ${theme.palette.background.divider}`, background: darken(0.03, theme.palette.background.default), display: "grid", gridAutoFlow: "column", gridGap: 4, padding: 2, justifyContent: "center", alignItems: "center" }, props)));
}
