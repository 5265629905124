export function createSessionObject(session) {
    return {
        id: session.id,
        caseID: session.caseID,
        externalID: session.externalID,
        owner: {
            id: session.ownerUser.id,
            name: session.ownerUser.name,
        },
    };
}
