import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button } from 'lib/cortiUI';
export const AddPartialThresholdAction = (props) => {
    const { t } = useTranslation('template', {
        keyPrefix: 'builder.configuration.thresholds.actions',
    });
    return (React.createElement(Button, Object.assign({}, props, { startIcon: React.createElement(AddRoundedIcon, null), variant: "outlined", size: "large" }), t('addPartiallyCompliantBtn', 'Partially Compliant')));
};
