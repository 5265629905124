import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { ImportTemplateModalContent } from './ImportTemplateModalContent';
export const ImportModalTrigger = () => {
    const { t } = useTranslation('templatesManagement');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleUploadComplete = () => {
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => setIsModalOpen(true), variant: "outlined", color: "primary", "data-cy": "import-file-btn" }, t('triggerImportModalBtn', 'Import')),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('importModal.title', 'Import a JSON file') }),
                React.createElement(ModalContent, null,
                    React.createElement(ImportTemplateModalContent, { onUploadComplete: handleUploadComplete }))))));
};
