import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getDefaultTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
const initialState = {
    template: undefined,
    loading: false,
};
const TemplateContext = React.createContext(initialState);
export const TemplateProvider = ({ children }) => {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'template' });
    const [template, setTemplate] = React.useState();
    const [isTemplateLoading, setIsTemplateLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchTemplate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchTemplate = async () => {
        setIsTemplateLoading(true);
        try {
            const defaultTemplate = await getDefaultTemplate();
            setTemplate(defaultTemplate);
        }
        catch (e) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchTemplateError', 'Failed to fetch template data'),
            });
            console.error('[Mission Control] Failed to fetch template data', e);
        }
        finally {
            setIsTemplateLoading(false);
        }
    };
    const value = React.useMemo(() => ({
        template,
        loading: isTemplateLoading,
    }), [template, isTemplateLoading]);
    return React.createElement(TemplateContext.Provider, { value: value }, children);
};
export const useTemplate = () => {
    const context = React.useContext(TemplateContext);
    if (!context) {
        throw new Error('useTemplate must be used within a TemplateProvider');
    }
    return context;
};
