var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isHotkey } from 'is-hotkey';
import React from 'react';
import { Editor, Editor as SlateEditor, Element as SlateElement, Node as SlateNode, Point as SlatePoint, Range as SlateRange, Transforms, createEditor, } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { createRichText } from '@corti/richtext';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { LeafRenderer } from './LeafRenderer';
import { renderElement } from './NodeRenderer';
import { fromSlate, toSlate } from './adapter';
import * as Commands from './commands';
import { RichTextEditorContext } from './context';
import './richTextEditor.css';
import './types';
const HOTKEYS = {
    'mod+b': Commands.toggleBold,
    'mod+i': Commands.toggleItalic,
    'mod+u': Commands.toggleUnderline,
    'mod+shift+x': Commands.toggleStrikethrough,
    'mod+shift+m': Commands.toggleMetaText,
};
function enhanceEditor(editor) {
    const { isInline, deleteBackward, insertBreak } = editor;
    editor.isInline = (element) => {
        if (element.type === 'link' || element.type === 'hasnote' || element.type === 'hasexpression') {
            return true;
        }
        return isInline(element);
    };
    editor.insertBreak = (...args) => {
        const { selection } = editor;
        if (!selection || !SlateRange.isCollapsed(selection)) {
            return;
        }
        const listItemAbove = SlateEditor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === 'li',
        });
        if (listItemAbove) {
            const [listItem] = listItemAbove;
            if (SlateNode.string(listItem) === '') {
                Transforms.unwrapNodes(editor, {
                    match: (n) => SlateElement.isElement(n) && (n.type === 'ol' || n.type === 'ul'),
                    split: true,
                });
                Transforms.setNodes(editor, { type: 'paragraph' });
                return;
            }
        }
        insertBreak(...args);
    };
    editor.deleteBackward = (...args) => {
        const { selection } = editor;
        if (!selection || !SlateRange.isCollapsed(selection)) {
            return;
        }
        const blockAbove = SlateEditor.above(editor, {
            match: (n) => SlateEditor.isBlock(editor, n),
        });
        if (!blockAbove)
            return;
        const [block, path] = blockAbove;
        const start = SlateEditor.start(editor, path);
        // If caret is positioned at the beginning of a block which is not a paragraph
        // on backspace we need to switch this block type to a default "paragraph" type
        // and if it is a wrapped block type like a list, we need to unwrap
        if (SlatePoint.equals(selection.anchor, start) && block.type === 'li') {
            Transforms.unwrapNodes(editor, {
                match: (n) => SlateEditor.isBlock(editor, n) && (n.type === 'ol' || n.type === 'ul'),
                split: true,
            });
            Transforms.setNodes(editor, { type: 'paragraph' });
            return;
        }
        // yield to the original behaviour
        deleteBackward(...args);
    };
    return editor;
}
export function RichTextEditor(props) {
    const { className, disabled, value = createRichText(), onChange, children, hideMetaText, readOnly, onKeyDown, resolveNoteDetails } = props, rest = __rest(props, ["className", "disabled", "value", "onChange", "children", "hideMetaText", "readOnly", "onKeyDown", "resolveNoteDetails"]);
    const theme = useTheme();
    const [editor] = React.useState(() => enhanceEditor(withReact(withHistory(createEditor()))));
    // We have to internally store a map between slate value and our rich text value
    // otherwise slate loses track of the selection states which causes unexpected behaviour or crash
    const valueTracker = React.useRef([toSlate(value), value]);
    // When value has changed outside of slate's boundaries, we need to make sure to re-sync it with slate
    React.useEffect(() => {
        const [_, v] = valueTracker.current;
        if (v !== value) {
            const slateval = toSlate(value);
            valueTracker.current = [slateval, value];
            editor.children = slateval;
            // Since we don't know what the new value is, we collapse the selection to the start.
            // This logic could be improved by trying to apply the current selection to the new value.
            // Slate crashes if this cannot be done, so some more sophisticated selection checks would be required
            // before doing so. For now this is quick and easy solution
            const start = Editor.start(editor, []);
            Transforms.select(editor, { anchor: start, focus: start });
            // trigger internal state change of the slate editor
            editor.onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    function handleChange(slatevalue) {
        // short circuit when the change is the selection change and do not notify the parent
        const isNonValChange = editor.operations.every((op) => op.type === 'set_selection');
        if (isNonValChange) {
            valueTracker.current = [slatevalue, value];
            return;
        }
        const val = fromSlate(slatevalue);
        valueTracker.current = [slatevalue, val];
        onChange === null || onChange === void 0 ? void 0 : onChange(val);
    }
    return (React.createElement(RichTextEditorContext.Provider, { value: {
            disabled,
            readOnly,
            hideMetaText,
            resolveNoteDetails,
        } },
        React.createElement(Slate, { editor: editor, value: valueTracker.current[0], onChange: handleChange },
            React.createElement(Editable, Object.assign({ disabled: disabled, spellCheck: true, tabIndex: readOnly ? undefined : 0, renderElement: renderElement, renderLeaf: (props) => React.createElement(LeafRenderer, Object.assign({}, props)), readOnly: readOnly, className: [
                    css({
                        color: theme.palette.text.primary,
                        position: 'relative',
                        cursor: props.readOnly ? 'inherit' : 'text',
                        overflow: 'auto',
                    }, getScrollerCss({ theme })),
                    'rich-text-editor',
                    className,
                ].join(' '), onKeyDown: (event) => {
                    event.stopPropagation();
                    if (event.key === 'Enter' && event.shiftKey) {
                        event.preventDefault();
                        editor.insertText('\n');
                        return;
                    }
                    Object.entries(HOTKEYS).forEach(([key, cmd]) => {
                        if (isHotkey(key, event.nativeEvent)) {
                            event.preventDefault();
                            event.stopPropagation();
                            cmd.call(null, editor);
                            return;
                        }
                    });
                    onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
                } }, rest)),
            props.children)));
}
