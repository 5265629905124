import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { useHistory } from 'react-router';
import { saveFileToDisk } from '@corti/browser-file';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { createRichText } from '@corti/richtext';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { useCoreStores } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { ConfirmableTarget, IconButton, Menu, MenuItem, Modal } from 'lib/cortiUI';
import { CoreBackendDeserializer, CoreBackendSerializer, } from 'lib/graphEditor/coreBackendSerializer';
import { useCloneGraphVersionMutation } from '../api';
import { CreateReleaseView } from './createRelease/CreateReleaseView';
import { EditVersionDetailsView } from './editVersionDetails/EditVersionDetailsView';
import { getProtocolGraphDetails } from './graphql';
export function GraphVersionActionMenu(props) {
    const theme = useTheme();
    const { graphVersion } = props;
    const { t } = useTranslation();
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const history = useHistory();
    const gqlClient = useApolloClient();
    const [cloneGraphVersion] = useCloneGraphVersionMutation();
    const authStore = useAuth();
    const { notifications } = useCoreStores();
    const [refreshGraphDetails] = useLazyQuery(getProtocolGraphDetails(), {
        variables: { id: graphVersion.graph.id },
    });
    const [modalType, setModalType] = React.useState(null);
    const isActiveGraphVersion = authStore.organization.triageSettings['activeProtocolGraphVersionID'] === graphVersion.id;
    const closeMenu = () => setMenuAnchor(null);
    const handleCreateNewDraftVersion = async () => {
        await cloneGraphVersion({
            parentVersionID: graphVersion.id,
            id: uuid(),
            graphID: graphVersion.graph.id,
            name: `${graphVersion.name} copy`,
            description: createRichText(),
        });
    };
    const handleDeleteVersion = async () => {
        const mutation = gql `
      mutation deleteProtocolGraphVersion($id: String!) {
        deleteProtocolGraphVersion(id: $id)
      }
    `;
        await gqlClient.mutate({
            mutation,
            variables: { id: graphVersion.id },
        });
        refreshGraphDetails();
    };
    const handleActivateVersion = async () => {
        authStore.updateOrganizationTriageSettings({ activeProtocolGraphVersionID: graphVersion.id });
    };
    const handleExport = async () => {
        const defaultFilename = `${graphVersion.name}_${new Date().toISOString()}`;
        const filename = prompt('File name', defaultFilename);
        if (!filename) {
            return;
        }
        notifications.showNotification({
            message: t('editorApp2:graphVersionActionsMenu.exportRequestedNotificationMsg', 'Graph data export requested'),
            type: 'info',
        });
        try {
            const graph = await api.protocolGraphs.getGraph(graphVersion.id);
            const model = await CoreBackendDeserializer.deserialize(graph);
            const serialized = await CoreBackendSerializer.transform({
                graphData: model,
            });
            const blob = new Blob([JSON.stringify(serialized)], { type: 'application/json' });
            saveFileToDisk(blob, `${filename}.json`);
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                notifications.showNotification({
                    message: t('editorApp2:graphVersionActionsMenu.exportFailedNotificationMsg', 'Failed to export graph data'),
                    detailText: err.message,
                    type: 'error',
                    permanent: true,
                    closable: true,
                });
            }
        }
    };
    const deleteMenuItem = (React.createElement(ConfirmableTarget, { key: "deleteConfirmationMsg", message: t('editorApp2:graphVersionActionsMenu.deleteConfirmationMsg', 'The graph version will permanently be removed from your organization. It will no longer be available in the Triage application. This action cannot be reverted.'), onConfirm: () => {
            void handleDeleteVersion();
            closeMenu();
        }, color: "error" }, ({ onClick }) => (React.createElement(MenuItem, { disabled: isActiveGraphVersion, onClick: onClick }, t('editorApp2:graphVersionActionsMenu.delete', 'Delete')))));
    const exportDataMenuItem = (React.createElement(MenuItem, { key: "exportGraph", onClick: () => {
            closeMenu();
            void handleExport();
        } }, t('editorApp2:graphVersionActionsMenu.exportGraph', 'Export graph data')));
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "data-cy": "action-menu-button", icon: React.createElement(MoreHorizRoundedIcon, { sx: { color: theme.palette.text.primary } }), onClick: (e) => setMenuAnchor(e.currentTarget) }),
        React.createElement(Menu, { transitionDuration: 0, anchorEl: menuAnchor, open: Boolean(menuAnchor), onClose: closeMenu }, graphVersion.release
            ? [
                React.createElement(MenuItem, { key: "activateNow", disabled: isActiveGraphVersion, onClick: () => {
                        void handleActivateVersion();
                        closeMenu();
                    } }, t('editorApp2:graphVersionActionsMenu.activateNow', 'Activate now')),
                React.createElement(MenuItem, { "data-cy": "edit-release-notes-menu-item", key: "editReleaseNotes", onClick: () => {
                        setModalType('edit-version');
                        closeMenu();
                    } }, t('editorApp2:graphVersionActionsMenu.editReleaseNotes', 'Edit release notes')),
                React.createElement(MenuItem, { key: "openReleasedInEditor", onClick: () => {
                        history.push(`/editor-v2/editor/${graphVersion.id}`);
                    } }, t('editorApp2:graphVersionActionsMenu.openReleasedInEditor', 'Open in Editor (readonly)')),
                React.createElement(MenuItem, { key: "createDraftVersion", onClick: () => {
                        void handleCreateNewDraftVersion();
                        closeMenu();
                    } }, t('editorApp2:graphVersionActionsMenu.createDraftVersion', 'Create draft from here')),
                exportDataMenuItem,
                deleteMenuItem,
            ]
            : [
                React.createElement(MenuItem, { key: "editDraftNotes", onClick: () => {
                        setModalType('edit-version');
                        closeMenu();
                    } }, t('editorApp2:graphVersionActionsMenu.editDraftNotes', 'Edit draft notes')),
                React.createElement(MenuItem, { "data-cy": "create-release-menu-item", key: "createRelease", onClick: () => {
                        setModalType('create-release');
                        closeMenu();
                    } }, t('editorApp2:graphVersionActionsMenu.createRelease', 'Create release')),
                React.createElement(MenuItem, { key: "openDraftInEditor", onClick: () => {
                        history.push(`/editor-v2/editor/${graphVersion.id}`);
                    } }, t('editorApp2:graphVersionActionsMenu.openDraftInEditor', 'Open in Editor')),
                exportDataMenuItem,
                deleteMenuItem,
            ]),
        React.createElement(Modal, { open: !!modalType, onClose: () => setModalType(null) }, modalType === 'edit-version' ? (React.createElement(EditVersionDetailsView, { versionID: graphVersion.id, graphID: graphVersion.graph.id, onCancel: () => setModalType(null), onSuccess: () => setModalType(null) })) : (React.createElement(CreateReleaseView, { versionID: graphVersion.id, graphID: graphVersion.graph.id, onCancel: () => setModalType(null), onSuccess: () => setModalType(null) })))));
}
