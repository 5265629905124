import { observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { Sidepanel, TwoColGridLayout } from 'lib/graphEditor/ui';
import { ContentBuilderEditor } from './ContentBuilderEditor';
import { ElementCreationToolbar } from './ElementCreationToolbar';
import { ElementInfoPanel } from './ElementInfoPanel';
import { ContentBuilderCtx } from './contentBuilderCtx';
export const ContentEditorView = observer(function ContentEditorView(_) {
    const match = useRouteMatch();
    const { editor } = useGraphEditorCtx();
    const contentBuilderCtxID = match.params.elementID;
    const contentBuilderCtx = editor.model.elementContext.getContentBuilderContextByID(contentBuilderCtxID);
    if (!contentBuilderCtx) {
        return (React.createElement(Typography, { variant: "body2", color: "default" }, `Missing content builder context for ID ${contentBuilderCtxID}`));
    }
    return (React.createElement(ContentBuilderCtx.Provider, { value: contentBuilderCtx },
        React.createElement(TwoColGridLayout, { sidepanelMaxWidth: 480 },
            React.createElement(ContentBuilderEditor, { contentBuilderContext: contentBuilderCtx }),
            React.createElement(Sidepanel, null,
                React.createElement(ElementCreationToolbar, null),
                React.createElement(ElementInfoPanel, { element: contentBuilderCtx.selectedElement })))));
});
