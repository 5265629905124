var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ChipMUI from '@mui/material/Chip';
import React from 'react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI/components/baseHoc';
export const Chip = React.forwardRef((props, ref) => {
    const { classes, size = 'small' } = props, rest = __rest(props, ["classes", "size"]);
    const theme = useTheme();
    const baseCss = useBaseCss(rest);
    const filteredProps = filterBaseProps(rest);
    return (React.createElement(ChipMUI, Object.assign({ ref: ref, "data-cy": "chip", classes: Object.assign(Object.assign({}, classes), { root: css({
                color: theme.palette.text.primary,
                backgroundColor: transparentize(0.85, theme.palette.text.primary),
                '&:hover': {
                    backgroundColor: transparentize(0.85, theme.palette.text.primary),
                },
                '&:focus': {
                    backgroundColor: transparentize(0.85, theme.palette.text.primary),
                },
            }, baseCss, classes ? classes.root : {}) }) }, filteredProps, { size: size })));
});
