import { LinkPortalNodeModel, TimelineEntryAlertNode, ViewNodeModel, } from 'lib/graphEditor/canvas/nodes';
export class GraphNodeFactory {
    constructor() { }
    createNodeForType(type) {
        switch (type) {
            case 'viewnode': {
                return this.viewNode();
            }
            case 'linkPortalNode': {
                return this.linkPortalNode();
            }
            case 'timelineEntryAlertNode': {
                return this.timelineEntryAlertNode();
            }
        }
    }
    viewNode() {
        return new ViewNodeModel();
    }
    linkPortalNode() {
        return new LinkPortalNodeModel();
    }
    timelineEntryAlertNode() {
        return new TimelineEntryAlertNode();
    }
}
