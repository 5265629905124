import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Box, Button, ModalContent, ModalHeader, Typography } from 'lib/cortiUI';
import { getProtocolGraphVersionQuery } from '../graphql';
const createReleaseMutation = gql `
  mutation releaseProtocolGraphVersion($versionID: String!) {
    releaseProtocolGraphVersion(versionID: $versionID)
  }
`;
export function CreateReleaseView(props) {
    const { t } = useTranslation();
    const query = useQuery(getProtocolGraphVersionQuery(), { variables: { id: props.versionID } });
    const [createRelease, createReleaseResult] = useMutation(createReleaseMutation);
    const handleSubmit = async () => {
        var _a;
        if (!query.data) {
            return;
        }
        try {
            await createRelease({ variables: { versionID: props.versionID } });
            await query.refetch();
            (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
        }
        catch (err) {
            console.error(err);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: t('editorApp2:createReleaseView.title', 'Create release') }),
        React.createElement(ModalContent, null, query.error ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body2", color: "default" }, t('editorApp2:createReleaseView.graphRetrievalError', 'Error: unable to retrieve graph version data')))) : (React.createElement(Box, { gap: 8, "data-cy": "create-release-dialog" },
            React.createElement(Box, { gap: 4 },
                React.createElement(Typography, { variant: "body2", color: "hint" }, t('editorApp2:createReleaseView.releaseHint1', 'When release is created, you can still edit version description. Version number will be locked and graph contents will no longer be editable.')),
                React.createElement(Typography, { variant: "body2", color: "hint" }, t('editorApp2:createReleaseView.releaseHint2', 'Released graphs can be activated and used in the Triage application.'))),
            React.createElement(Box, { flexDirection: "row", alignItems: "center", gap: 4 },
                React.createElement(Button, { "data-cy": "create-release-submit-button", color: "primary", disabled: createReleaseResult.loading, onClick: () => {
                        void handleSubmit();
                    } }, t('editorApp2:createReleaseView.saveBtn', 'Save')),
                React.createElement(Button, { onClick: props.onCancel }, t('editorApp2:createReleaseView.cancelBtn', 'Cancel'))))))));
}
