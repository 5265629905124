import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';
export function CopyButton(props) {
    const { getValue, tooltipTitle } = props;
    const [showTooltip, setShowTooltip] = React.useState(false);
    async function onCopyClick() {
        const value = typeof getValue === 'function' ? getValue() : getValue;
        try {
            if (typeof value === 'string') {
                await navigator.clipboard.writeText(value);
            }
            else {
                await navigator.clipboard.write(value);
            }
            setShowTooltip(true);
        }
        catch (error) {
            // we are not handling the error, but adding a error log to help devs
            console.error(error);
        }
    }
    return (React.createElement(Tooltip, { disableInteractive: true, placement: "top", title: tooltipTitle, leaveDelay: 1000, open: showTooltip, onClose: () => setShowTooltip(false) },
        React.createElement(IconButton, { onClick: onCopyClick, size: "small", icon: React.createElement(ContentCopyRoundedIcon, { fontSize: "small" }) })));
}
