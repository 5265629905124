var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUICircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { css } from '@corti/style';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI/components/baseHoc';
export const CircularProgress = React.forwardRef(function CircularProgress(props, ref) {
    const { className, size = 30 } = props, rest = __rest(props, ["className", "size"]);
    const baseCss = useBaseCss(rest);
    const filtered = filterBaseProps(rest);
    return (React.createElement(MUICircularProgress, Object.assign({ ref: ref, size: size, className: css(baseCss, className) }, filtered)));
});
