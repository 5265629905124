export function createEmptyState() {
    return {
        junction: 'and',
        entities: [
            {
                id: '',
                junction: 'and',
                predicates: [],
            },
        ],
    };
}
export function isStateEmpty(state) {
    return state.entities.length === 1 && !state.entities[0].id;
}
export function getAttributeValueType(descriptor, attributeID) {
    const getFlatAttributeMap = (descriptor) => {
        const attributeMap = {};
        const filterList = (entityDescriptors) => {
            entityDescriptors.forEach((i) => {
                if (i.attributes) {
                    i.attributes.forEach((i) => {
                        attributeMap[i.id] = { valueType: i.valueType };
                        if (i.options) {
                            filterList(i.options);
                        }
                    });
                }
            });
        };
        filterList(descriptor.entities);
        return attributeMap;
    };
    const map = getFlatAttributeMap(descriptor);
    const attr = map[attributeID];
    if (attr) {
        return attr.valueType;
    }
}
