var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseEntity } from 'lib/graphEditor/core';
import { LinkModel } from './LinkModel';
import { Point } from './geometry';
export class PortModel extends BaseEntity {
    constructor({ type = 'in', position = { x: 0, y: 0 }, maxLinks } = {}) {
        super();
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxLinks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "position", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_links", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unreportedPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        makeObservable(this);
        this.type = type;
        this.maxLinks = maxLinks;
        runInAction(() => {
            this.position = new Point(position);
            this._links = {};
        });
    }
    /**
     * Forces the node to invalidate it's position. When position becomes unreported, the view
     * will be tasked to report it's position
     */
    invalidatePosition() {
        this.unreportedPosition = true;
    }
    setPosition(point) {
        this.position = new Point(point);
        this.unreportedPosition = false;
    }
    addLink(link) {
        this._links[link.id] = link;
    }
    link(port) {
        if (this.canLinkToPort(port)) {
            const link = new LinkModel();
            link.id = this.id + '+' + port.id;
            link.setSourcePort(this);
            link.setTargetPort(port);
            // this does not make much sense as a parent
            // but allows link model to have access to graph editor ctx
            link.setParent(this.parent);
            return link;
        }
    }
    removeLink(link) {
        if (this._links[link.id]) {
            delete this._links[link.id];
        }
    }
    canLinkToPort(port) {
        // allow only "in" - "out" links
        if (this.type === port.type) {
            return false;
        }
        // do not allow links to itself
        if (this === port) {
            return false;
        }
        // do not allow links between ports of the same node
        if (this.parent === port.parent) {
            return false;
        }
        if (this.maxLinks !== undefined && this.maxLinks <= this.links.length) {
            return false;
        }
        if (port.maxLinks !== undefined && port.maxLinks <= port.links.length) {
            return false;
        }
        return true;
    }
    remove() {
        this.parent.removePort(this);
    }
    dispose() {
        this.links.forEach((link) => link.remove());
    }
    get links() {
        return Object.values(this._links);
    }
    get permaLinks() {
        return this.links.filter((l) => !l.isTempLink);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { type: this.type, position: this.position.serialize() });
    }
    deserialize(entity) {
        super.deserialize(entity);
        this.type = entity.type;
    }
    copy() {
        const i = super.copy();
        i.position = this.position.copy();
        return i;
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Point !== "undefined" && Point) === "function" ? _a : Object)
], PortModel.prototype, "position", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Record !== "undefined" && Record) === "function" ? _b : Object)
], PortModel.prototype, "_links", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PortModel.prototype, "unreportedPosition", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PortModel.prototype, "invalidatePosition", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PortModel.prototype, "setPosition", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof LinkModel !== "undefined" && LinkModel) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], PortModel.prototype, "addLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [PortModel]),
    __metadata("design:returntype", Object)
], PortModel.prototype, "link", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof LinkModel !== "undefined" && LinkModel) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], PortModel.prototype, "removeLink", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], PortModel.prototype, "links", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], PortModel.prototype, "deserialize", null);
