var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { uuid } from '@corti/uuid';
export class NotificationStore {
    constructor() {
        Object.defineProperty(this, "notifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.notifications = [];
            }
        });
        Object.defineProperty(this, "closeNotification", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const idx = this.notifications.findIndex((n) => n.id === id);
                if (idx !== -1) {
                    this.notifications.splice(idx, 1);
                }
            }
        });
        Object.defineProperty(this, "setNotificationPermanent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (notification) => {
                notification.permanent = true;
            }
        });
        makeObservable(this);
        this.resetState();
    }
    showNotification(notification) {
        var _a;
        const id = (_a = notification.id) !== null && _a !== void 0 ? _a : uuid();
        if (!this.notifications.find((it) => it.id === id)) {
            this.notifications.push(Object.assign(Object.assign({}, notification), { id }));
        }
        return id;
    }
    get hasNotifications() {
        return this.notifications.length > 0;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], NotificationStore.prototype, "notifications", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationStore.prototype, "resetState", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", String)
], NotificationStore.prototype, "showNotification", null);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationStore.prototype, "closeNotification", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationStore.prototype, "setNotificationPermanent", void 0);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], NotificationStore.prototype, "hasNotifications", null);
