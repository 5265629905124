import { cloneStyles, toWindowFeatures } from '@corti/dom';
import { calculateWindowPosition } from './caluclateWindowPosition';
function getDefaultHeight() {
    const height = window.screen.availHeight * 0.9;
    const maxheight = 1000;
    return Math.min(maxheight, height);
}
function getDefaultWidth() {
    const width = window.screen.availWidth * 0.8;
    const maxWidth = 1200;
    return Math.min(maxWidth, width);
}
export function openNewWindow(size, title, windowFeatures, position, anchor, anchorOrigin) {
    var _a, _b;
    const width = (_a = size === null || size === void 0 ? void 0 : size.width) !== null && _a !== void 0 ? _a : getDefaultWidth();
    const height = (_b = size === null || size === void 0 ? void 0 : size.height) !== null && _b !== void 0 ? _b : getDefaultHeight();
    const { left: positionX, top: positionY } = (position === null || position === void 0 ? void 0 : position.left) != null && (position === null || position === void 0 ? void 0 : position.top) != null
        ? { left: position.left, top: position.top }
        : calculateWindowPosition({ height, width }, anchor, anchorOrigin);
    const newWindow = window.open('', '_blank', toWindowFeatures(Object.assign({ width: width, height: height, left: positionX, top: positionY, title }, windowFeatures)));
    if (!newWindow) {
        console.error('Failed to open new window');
        return { newWindow: null, container: null };
    }
    setTimeout(() => {
        cloneStyles(document, newWindow.document);
    }, 0);
    const container = newWindow.document.createElement('div');
    container.id = 'react-root';
    newWindow.document.body.appendChild(container);
    return { newWindow, container };
}
