import { getReadableCollectorItems } from '../../models/CollectorModel';
export const EventFactory = {
    createGroupedFlowValueCollectorBlocksUpdated(collectorBlockInstanceID, flowStore) {
        const blockInstance = flowStore.graphTraverser.getBlockByID(collectorBlockInstanceID);
        if (!blockInstance) {
            return;
        }
        const view = flowStore.graphTraverser.getViewProtoByID(blockInstance.viewProtoID);
        if (!view) {
            return;
        }
        const allCollectorsInView = view.blocks
            .filter((b) => b.blockPrototype.type === 'FLOW_VALUE_COLLECTOR')
            .map((b) => flowStore.collectorStore.getCollectorInstanceByID(b.id))
            .filter((it) => it !== undefined);
        return {
            event: 'grouped-flow-value-collector-blocks-updated',
            data: {
                datetime: new Date().toISOString(),
                collectors: allCollectorsInView.map((it) => transformCollector(it, flowStore)),
            },
        };
    },
};
function transformCollector(collector, flowStore) {
    const { graphTraverser } = flowStore;
    const blocks = [];
    const blockValues = [];
    const factValues = [];
    const customValues = [];
    collector.items.forEach((it) => {
        switch (it.valueType) {
            case 'block':
            case 'block-option': {
                if (it.values.length === 0)
                    return;
                const blockID = it.values[0].identifier.blockPrototypeID;
                if (!blocks.some((it) => it.id === blockID)) {
                    const block = graphTraverser.getBlockProtoByID(blockID);
                    blocks.push({
                        id: blockID,
                        label: block && 'label' in block ? block.label.plainText : '',
                        customProperties: block ? block.customProperties : [],
                    });
                }
                it.values.forEach((it) => {
                    if (it.identifier.optionID) {
                        const option = graphTraverser.getOptionByID(it.identifier.optionID);
                        blockValues.push({
                            blockPrototypeID: it.identifier.blockPrototypeID,
                            value: it.value,
                            text: option ? option.text.plainText : '',
                            customProperties: option ? option.customProperties : [],
                        });
                    }
                    else {
                        blockValues.push({
                            blockPrototypeID: it.identifier.blockPrototypeID,
                            text: it.value,
                        });
                    }
                });
                break;
            }
            case 'fact': {
                it.values.forEach((it) => {
                    factValues.push({
                        factID: it.factID,
                        value: it.value,
                    });
                });
                break;
            }
            case 'custom': {
                it.values.forEach((it) => {
                    customValues.push({
                        value: it.value,
                    });
                });
                break;
            }
        }
    });
    return {
        displayValues: getReadableCollectorItems(collector, flowStore).map((it) => ({
            text: it.text,
        })),
        blockPrototype: {
            id: collector.blockInstance.blockPrototype.id,
            name: collector.blockInstance.blockPrototype.name,
            customProperties: collector.blockInstance.blockPrototype.customProperties,
        },
        customValues: customValues,
        collectedFactValues: factValues,
        collectedBlockValues: {
            blockPrototypes: blocks,
            values: blockValues,
        },
    };
}
