import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Typography } from 'lib/cortiUI';
import { useChecklistEdit } from './useChecklistEdit';
export function ChecklistInspectorBreadcrumbs() {
    const { t } = useTranslation('libGraphEditor');
    const checklist = useChecklistEdit((state) => state.checklist);
    const selection = useChecklistEdit((state) => state.selection);
    const selectedEntry = selection !== null ? checklist.entries[selection] : null;
    return (React.createElement(Typography, { variant: "body2", sx: {
            textTransform: 'uppercase',
            fontSize: '80%',
            color: '#888',
            fontWeight: 'bold',
        } },
        checklist.name || React.createElement("em", null, t('checklist.editor.untitledChecklist', 'Untitled Checklist')),
        selectedEntry && (React.createElement(React.Fragment, null,
            ' ',
            "/",
            ' ',
            selectedEntry.label || React.createElement("em", null, t('checklist.editor.untitledEntry', 'Untitled Entry'))))));
}
