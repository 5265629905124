import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getQuestionScorePercentage, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Card, ScoreLabel } from '../../components';
import { Bar } from '../../components/Bar';
import { useParameters } from '../../context';
import { getComplianceStatusFromThresholds, getDefaultThresholds, getReviewComplianceStatusData, } from '../../utils';
import { QuestionLoader } from './QuestionLoader';
import { mergeQuestionAndStats } from './utils';
export const QuestionCard = ({ question: questionProp, template }) => {
    var _a;
    const { t } = useTranslation('questionsList', { keyPrefix: 'questionsList' });
    const { filterRequestParams } = useParameters();
    const [question, setQuestion] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRequestParams]);
    const loadData = async () => {
        setIsLoading(true);
        try {
            const questionStats = await getQuestionScorePercentage(Object.assign(Object.assign({}, filterRequestParams), { template_id: template === null || template === void 0 ? void 0 : template.id, review_question_id: questionProp.id, review_not_applicable: false }));
            setQuestion(mergeQuestionAndStats(questionProp, questionStats));
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchQuestionStatsError', 'Error fetching question metrics'),
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    if (isLoading) {
        return React.createElement(QuestionLoader, null);
    }
    if (!question) {
        return null;
    }
    const complianceStatusValue = getComplianceStatusFromThresholds((_a = question.percentage) !== null && _a !== void 0 ? _a : 0, template);
    const complianceStatusData = getReviewComplianceStatusData(complianceStatusValue);
    const defaultThresholds = getDefaultThresholds(template);
    React;
    return (React.createElement(Card, { title: question.title },
        React.createElement(ScoreLabel, { size: "medium", value: question.percentage, color: complianceStatusData.color }),
        React.createElement(Bar, { value: question.percentage, threshold: defaultThresholds.has_threshold ? defaultThresholds.threshold : undefined, partialThreshold: defaultThresholds.has_partial_threshold ? defaultThresholds.partial_threshold : undefined, complianceStatus: complianceStatusValue })));
};
