import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { Button, Dialog } from 'lib/cortiUI';
import { CreateTeamView } from './CreateUpdateTeamView';
export const CreateTeam = ({ onCreateTeam }) => {
    const { t } = useTranslation('teams');
    const { palette } = useTheme();
    const [isOpen, setIsOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "text", sx: { color: palette.text.primary }, onClick: () => setIsOpen(true), disabled: !rbacService.teamsCRUD(), "data-cy": "create-new-team-btn", startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, t('teamsView.createTeamBtn', 'New Team')),
        React.createElement(Dialog, { open: isOpen, onClose: () => setIsOpen(false), title: t('createTeamView.title', 'Create New Team'), helperText: t('teamForm.headerDescription', 'Chose a name that reflects your company or team hierarchy. We recommend you use the slash separated convention e.g. parent team/child team to reflect relations or levels of nestings.') },
            React.createElement(CreateTeamView, { onSuccess: function onCreateTeamSuccess() {
                    setIsOpen(false);
                    onCreateTeam();
                } }))));
};
