import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import React from 'react';
import { getAnswerByQuestion, } from '@corti/mission-control-api';
import { Base, Card, IconButton, Typography } from 'lib/cortiUI';
import { Answer } from './Answer';
export const Question = ({ reviewID, text, id, scoreEnabled }) => {
    const [answer, setAnswer] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    React.useEffect(() => {
        void fetchAnswer(reviewID);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewID]);
    async function fetchAnswer(reviewID) {
        setIsLoading(true);
        try {
            const response = await getAnswerByQuestion(reviewID, id);
            setAnswer(response);
        }
        catch (error) {
            console.error('Failed to fetch answer', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(Card, { key: id, px: 6, py: 5 },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { variant: "body2", color: "default" }, text),
                (answer === null || answer === void 0 ? void 0 : answer.text) && (React.createElement(IconButton, { icon: isExpanded ? React.createElement(ExpandLessRoundedIcon, null) : React.createElement(ExpandMoreRoundedIcon, null), onClick: () => setIsExpanded(!isExpanded) }))),
            React.createElement(Answer, { scoreEnabled: scoreEnabled, answer: answer, isLoading: isLoading, isExpanded: isExpanded }))));
};
