import { MISSION_CONTROL_ERROR_NAME, MissionControlError } from './MissionControlError';
import { initRestClient } from './RestClient';
class MissionControlService {
    constructor(config) {
        Object.defineProperty(this, "restClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "host", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.restClient = initRestClient({
            host: config.host,
            authToken: config.authToken,
        });
        this.host = config.host;
    }
    static isServiceError(err) {
        return err instanceof MissionControlError && err.name === MISSION_CONTROL_ERROR_NAME;
    }
    get api() {
        return this.restClient;
    }
}
export let api;
export function init(config) {
    const instance = new MissionControlService(config);
    api = instance.api;
}
