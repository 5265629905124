var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { stringIncludes } from '@corti/strings';
import { ViewNodeModel } from 'lib/graphEditor/canvas/nodes';
import { parseQuery } from 'lib/graphEditor/query';
import { BlockInstance, ComponentInstance, } from './contentBuilder';
import { ContentBuilderLibraryComponent } from './contentBuilder/ContentBuilderLibraryComponent';
import { SelectElementModel } from './contentBuilder/elements';
export class GlobalElementContext extends Observer {
    constructor(model) {
        super();
        Object.defineProperty(this, "model", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: model
        });
        Object.defineProperty(this, "instances", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "prototypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "instanceCountForPrototype", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "indexOfPrototypeIDsByType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "contentBuilderContexts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "handleNodeAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (node) => {
                if (node instanceof ViewNodeModel) {
                    this.contentBuilderContexts.set(node.contentBuilderContext.id, node.contentBuilderContext);
                    node.contentBuilderContext.getElements().forEach(this.indexElement);
                }
            }
        });
        Object.defineProperty(this, "handleNodeRemoved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (node) => {
                if (node instanceof ViewNodeModel) {
                    this.contentBuilderContexts.delete(node.contentBuilderContext.id);
                    node.contentBuilderContext.getElements().forEach(this.dropElementIndex);
                }
            }
        });
        Object.defineProperty(this, "handleBranchAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (branch) => {
                branch.nodes.forEach(this.handleNodeAdded);
            }
        });
        Object.defineProperty(this, "handleBranchRemoved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (branch) => {
                branch.nodes.forEach(this.handleNodeRemoved);
            }
        });
        Object.defineProperty(this, "indexElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (element) => {
                if (element instanceof ComponentInstance) {
                    const existingComponent = this.model.library.getComponentByID(element.definition.id);
                    if (existingComponent instanceof ContentBuilderLibraryComponent) {
                        element.setDefinition(existingComponent);
                    }
                    else {
                        this.model.library.addComponent(element.definition);
                    }
                    element.definition.registerInstance(element);
                }
                this.instances.set(element.id, element);
                this.indexPrototype(element.wrappedElement);
            }
        });
        Object.defineProperty(this, "dropElementIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (element) => {
                this.instances.delete(element.id);
                if (element instanceof ComponentInstance) {
                    element.definition.unregisterInstance(element);
                }
                this.dropPrototypeIndex(element.wrappedElement);
            }
        });
        Object.defineProperty(this, "indexPrototype", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (prototype) => {
                const proto = this.prototypes.get(prototype.id);
                if (!proto) {
                    this.prototypes.set(prototype.id, prototype);
                    this.instanceCountForPrototype[prototype.id] = 1;
                }
                else {
                    this.instanceCountForPrototype[prototype.id]++;
                }
                if (!this.indexOfPrototypeIDsByType.has(prototype.type)) {
                    this.indexOfPrototypeIDsByType.set(prototype.type, new Set());
                }
                const indexSet = this.indexOfPrototypeIDsByType.get(prototype.type);
                indexSet.add(prototype.id);
            }
        });
        Object.defineProperty(this, "dropPrototypeIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (prototype) => {
                var _a;
                if (this.instanceCountForPrototype[prototype.id]) {
                    this.instanceCountForPrototype[prototype.id]--;
                }
                if (this.instanceCountForPrototype[prototype.id] === 0) {
                    this.prototypes.delete(prototype.id);
                    delete this.instanceCountForPrototype[prototype.id];
                    const indexSet = (_a = this.indexOfPrototypeIDsByType.get(prototype.type)) !== null && _a !== void 0 ? _a : new Set();
                    indexSet.delete(prototype.id);
                }
            }
        });
        Object.defineProperty(this, "getElements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return [...this.instances.values()];
            }
        });
        Object.defineProperty(this, "getElementByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                return this.instances.get(id);
            }
        });
        Object.defineProperty(this, "getPrototypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => {
                var _a;
                if (!query) {
                    return new Map(this.prototypes.entries());
                }
                let result = new Map(this.prototypes.entries());
                if (query.type !== undefined) {
                    const ids = (_a = this.indexOfPrototypeIDsByType.get(query.type)) !== null && _a !== void 0 ? _a : new Set();
                    const tempResult = new Map();
                    [...ids.values()].forEach((id) => {
                        const p = result.get(id);
                        if (p) {
                            tempResult.set(p.id, p);
                        }
                    });
                    result = tempResult;
                }
                return result;
            }
        });
        Object.defineProperty(this, "getPrototypeByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                return this.prototypes.get(id);
            }
        });
        makeObservable(this);
        runInAction(() => {
            this.instances = new Map();
        });
        this.prototypes = new Map();
        this.instanceCountForPrototype = {};
        this.indexOfPrototypeIDsByType = new Map();
        this.contentBuilderContexts = new Map();
        this.model.on('nodeAdded', (event) => this.handleNodeAdded(event.node));
        this.model.on('nodeRemoved', (event) => this.handleNodeRemoved(event.node));
        this.model.on('branchAdded', (event) => this.handleBranchAdded(event.branch));
        this.model.on('branchRemoved', (event) => this.handleBranchRemoved(event.branch));
    }
    get componentInstances() {
        const result = new Map();
        for (const [id, r] of this.instances) {
            if (r instanceof ComponentInstance) {
                result.set(id, r);
            }
        }
        return result;
    }
    get blockInstances() {
        const result = new Map();
        for (const [id, r] of this.instances) {
            if (r instanceof BlockInstance) {
                result.set(id, r);
            }
        }
        return result;
    }
    getContentBuilderContextByID(id) {
        return this.contentBuilderContexts.get(id);
    }
    /**
     * Returns all the "searchable" elements, both standalone and from component instances
     * that are being used in the graph
     *
     * Elements that are in library definitions will not be included
     */
    get indexableElements() {
        var _a;
        const result = [];
        for (const it of this.instances.values()) {
            const baseEl = it.wrappedElement;
            const parentNode = (_a = it.parent) === null || _a === void 0 ? void 0 : _a.parent;
            const componentID = it instanceof ComponentInstance ? it.id : undefined;
            result.push({
                id: it.id,
                componentID,
                readableTitle: baseEl.readableTitle,
                tagname: baseEl.type,
                nodeID: parentNode === null || parentNode === void 0 ? void 0 : parentNode.id,
                locationDescription: parentNode === null || parentNode === void 0 ? void 0 : parentNode.branchContext.name,
            });
            if (baseEl instanceof SelectElementModel) {
                baseEl.options.forEach((o) => {
                    result.push({
                        id: it.id + o.id,
                        componentID,
                        readableTitle: o.readableTitle,
                        tagname: 'option',
                        nodeID: parentNode === null || parentNode === void 0 ? void 0 : parentNode.id,
                        locationDescription: [parentNode === null || parentNode === void 0 ? void 0 : parentNode.branchContext.name, baseEl.readableTitle].join(' / '),
                    });
                });
            }
        }
        return result;
    }
    findElementsByReadableTitle(query, options) {
        const smartQuery = parseQuery(query);
        return [...this.indexableElements]
            .filter((el) => (options.excludeComponents ? !el.componentID : true))
            .filter((el) => (options.excludeElements ? !!el.componentID : true))
            .filter((el) => {
            const typeFilters = smartQuery.getValuesForKeyword('type');
            if (!typeFilters.length) {
                return true;
            }
            return typeFilters.some((f) => el.tagname === f);
        })
            .filter((el) => {
            return stringIncludes(el.readableTitle, smartQuery.textQuery);
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], GlobalElementContext.prototype, "instances", void 0);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_b = typeof Map !== "undefined" && Map) === "function" ? _b : Object),
    __metadata("design:paramtypes", [])
], GlobalElementContext.prototype, "componentInstances", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_c = typeof Map !== "undefined" && Map) === "function" ? _c : Object),
    __metadata("design:paramtypes", [])
], GlobalElementContext.prototype, "blockInstances", null);
__decorate([
    action,
    __metadata("design:type", Object)
], GlobalElementContext.prototype, "indexElement", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], GlobalElementContext.prototype, "dropElementIndex", void 0);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], GlobalElementContext.prototype, "indexableElements", null);
