import { isObject } from 'lodash';
const readJSONContent = (jsonFile) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(jsonFile, 'UTF-8');
        reader.onloadend = (e) => {
            if (!e.target)
                return;
            const value = e.target.result;
            if (typeof value !== 'string')
                return;
            try {
                const parsedData = JSON.parse(value);
                resolve(parsedData);
            }
            catch (error) {
                resolve(null);
            }
        };
    });
};
function validateObject(object, schema) {
    const errors = Object.keys(schema)
        .filter(function (key) {
        return !schema[key](object[key]);
    })
        .map(function (key) {
        return new Error(key + ' is invalid.');
    });
    if (errors.length > 0) {
        errors.forEach(function (error) {
            console.log(error.message);
        });
        return false;
    }
    else {
        return true;
    }
}
const CONDITION_SCHEMA = {
    fieldID: function (value) {
        return typeof value === 'string';
    },
    comparison: function (value) {
        return typeof value === 'string' && ['eq', 'neq'].includes(value);
    },
    values: function (value) {
        return Array.isArray(value) && value.length > 0 && typeof value[0] === 'string';
    },
};
const VISIBLE_IF_SCHEMA = {
    junction: function (value) {
        return typeof value === 'string' && ['AND', 'OR'].includes(value);
    },
    conditions: function (value) {
        return Array.isArray(value) && value.every((cond) => validateObject(cond, CONDITION_SCHEMA));
    },
};
const CHOICE_SCHEMA = {
    value: function (value) {
        return typeof value === 'string';
    },
    text: function (value) {
        return typeof value === 'string';
    },
};
const FIELD_SCHEMA = {
    id: function (value) {
        return typeof value === 'string';
    },
    title: function (value) {
        return typeof value === 'string';
    },
    type: function (value) {
        return (typeof value === 'string' && ['short-text', 'long-text', 'radio', 'dropdown'].includes(value));
    },
    required: function (value) {
        return typeof value === 'boolean';
    },
    visibleIf: function (value) {
        if (value == undefined)
            return true;
        if (isObject(value) && !Array.isArray(value)) {
            return validateObject(value, VISIBLE_IF_SCHEMA);
        }
        else {
            return false;
        }
    },
    choices: function (value) {
        if (value == undefined)
            return true;
        if (!Array.isArray(value))
            return false;
        return value.every((choice) => {
            if (isObject(choice) && !Array.isArray(choice)) {
                return validateObject(choice, CHOICE_SCHEMA);
            }
            return false;
        });
    },
};
const TEMPLATE_SCHEMA = {
    title: function (value) {
        return typeof value === 'string';
    },
    anonymizeReviewers: function (value) {
        return typeof value === 'boolean';
    },
    fields: function (value) {
        return Array.isArray(value) && value.every((field) => validateObject(field, FIELD_SCHEMA));
    },
};
export async function validateJsonFile(file) {
    const result = {
        status: 'valid',
        data: null,
    };
    if (file.type !== 'application/json') {
        return Object.assign(Object.assign({}, result), { status: 'invalidType' });
    }
    const data = await readJSONContent(file);
    if (!data) {
        return Object.assign(Object.assign({}, result), { status: 'invalidSyntax' });
    }
    if (!validateObject(data, TEMPLATE_SCHEMA)) {
        return Object.assign(Object.assign({}, result), { status: 'invalidShape' });
    }
    return Object.assign(Object.assign({}, result), { data: data });
}
