import { createTheme as createMuiTheme, } from '@mui/material/styles';
import { merge } from 'lodash';
import { mix, rgba } from '@corti/polished';
import { theme } from './defaultTheme';
export const createTheme = (input) => {
    const newTheme = merge(theme, input);
    // Enhance our palette colors with new custom variants
    const paletteColorsToEnhance = [
        'primary',
        'secondary',
        'success',
        'warning',
        'error',
        'info',
    ];
    paletteColorsToEnhance.forEach((key) => {
        const color = newTheme.palette[key];
        if (color) {
            newTheme.palette[key] = Object.assign(Object.assign({}, color), { containedHoverBackground: mix(0.3, 'black', color.main), outlinedRestingBorder: rgba(color.main, 0.5), outlinedHoverBackground: rgba(color.main, 0.08) });
        }
    });
    const muiTheme = createMuiTheme(Object.assign(Object.assign({}, newTheme), { spacing: newTheme.space[2], typography: Object.assign(Object.assign({}, newTheme.typography), { footnote: { fontSize: '0.6rem' } }) }));
    //FIXME:
    //@ts-ignore
    return Object.assign(Object.assign({}, newTheme), muiTheme);
};
