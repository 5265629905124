import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button } from 'lib/cortiUI';
import { useImport } from './ImportContext';
export function ImportFile() {
    const { t } = useTranslation('libGraphEditor');
    const { hasError, errorMessage, setRawJSON } = useImport();
    const onChange = (evt) => {
        var _a;
        // On file selection, read the file locally and set the raw JSON
        const file = (_a = evt.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                var _a;
                const rawJSON = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.result;
                if (typeof rawJSON === 'string') {
                    setRawJSON(rawJSON);
                }
            };
            reader.readAsText(file);
        }
    };
    // Allow only json files for upload
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
        React.createElement("label", null,
            React.createElement(Button, { color: "primary", component: "span", sx: { mt: 2 } }, t('checklist.import.file.label', 'Upload JSON file')),
            React.createElement("input", { type: "file", onChange: onChange, accept: ".json", style: { display: 'none' } })),
        hasError && (React.createElement(Typography, { variant: "body1", sx: { color: 'error.main' } }, errorMessage))));
}
