var _a;
const ipcRenderer = (_a = window.Bridge) === null || _a === void 0 ? void 0 : _a.ipcRenderer;
function serializeError(err) {
    if (err instanceof Error) {
        return Object.assign(Object.assign({}, err), { name: err.name, message: err.message });
    }
    else
        return err;
}
/**
 * Asynchronously sends a message to the main process and receives data back
 */
export async function callMain(channel, data) {
    if (!ipcRenderer) {
        return Promise.reject('no ipc bridge');
    }
    return ipcRenderer.invoke(channel, data);
}
/**
 * Listens in on a channel and responds with the data returned from a callabck functions
 */
export function answerMain(channel, callback) {
    if (ipcRenderer) {
        const listener = async (_event, data) => {
            const { dataChannel, errorChannel, userData } = data;
            try {
                ipcRenderer.send(dataChannel, await callback(userData));
            }
            catch (error) {
                ipcRenderer.send(errorChannel, serializeError(error));
            }
        };
        ipcRenderer.on(channel, listener);
        return () => {
            ipcRenderer.removeListener(channel, listener);
        };
    }
}
export const simpleIpc = {
    callMain,
    answerMain,
};
