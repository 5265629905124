var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { Typography } from 'lib/cortiUI/components/Typography';
/**
 * `Page` component provides consistent padding and scrollbars
 */
export function Page(props) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    const theme = useTheme();
    return (React.createElement(Base, Object.assign({ p: 8, width: "100%", height: "100%", display: "flex", flexDirection: "column", className: css(getScrollerCss({ theme }), className) }, rest), children));
}
export function PageHeader(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(Base, Object.assign({ display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "auto auto", gridTemplateAreas: `
        "title actions"
        "description none"
      `, gap: 4, mb: 10 }, rest), children));
}
export function PageHeaderActionsSection(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(Base, Object.assign({ gridArea: "actions", display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 5, alignItems: "center" }, rest), children));
}
export function PageHeaderDescription(props) {
    return (React.createElement(Typography, Object.assign({ gridArea: "description", variant: "body1", color: "secondaryText", maxWidth: 400 }, props)));
}
export function PageHeaderTitle(props) {
    return React.createElement(Typography, Object.assign({ gridArea: "title", variant: "h3", color: "default" }, props));
}
