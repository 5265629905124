import React from 'react';
import { Base, Card, Skeleton } from 'lib/cortiUI';
export const SummaryLoader = () => {
    return (React.createElement(Card, { width: "100%", p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, null,
                React.createElement(Skeleton, { variant: "text", height: 30, width: "30%" })),
            React.createElement(Base, null,
                React.createElement(Skeleton, { variant: "text", height: 26, width: "20%" }),
                React.createElement(Skeleton, { variant: "text", height: 26, width: "60%" })),
            React.createElement(Base, null,
                React.createElement(Skeleton, { variant: "text", height: 26, width: "20%" }),
                React.createElement(Skeleton, { variant: "text", height: 26, width: "60%" })))));
};
