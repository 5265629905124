import React from 'react';
import { Base, Button, ModalContent, ModalHeader, Separator, Typography } from 'lib/cortiUI';
export const DiscardModalContent = ({ options, onClose, onConfirm, }) => {
    const { title, description, cancelText, confirmText } = options;
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: title }),
        React.createElement(ModalContent, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Typography, null, description),
            React.createElement(Separator, { horizontal: true }),
            React.createElement(Base, { display: "flex", justifyContent: "right", gap: 3 },
                React.createElement(Button, { variant: "text", onClick: onClose }, cancelText),
                React.createElement(Button, { color: "error", onClick: onConfirm }, confirmText)))));
};
