import React, { createContext, useContext } from 'react';
const UIContext = createContext(null);
export function ChecklistUIProvider({ children, listener, }) {
    return React.createElement(UIContext.Provider, { value: listener }, children);
}
export function useUIListener() {
    const listener = useContext(UIContext);
    if (!listener)
        throw new Error('No UI listener available');
    return listener;
}
