var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { last } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
export class Changelog {
    constructor(editor) {
        Object.defineProperty(this, "changesByCmdID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.reset();
        editor.onActionHandled((c) => {
            if (c.revertable) {
                this._registerActionToChangelog(c, 'run');
            }
        });
        editor.onActionReverted((c) => {
            this._registerActionToChangelog(c, 'revert');
        });
    }
    _registerActionToChangelog(c, variant) {
        const existing = this.changesByCmdID.get(c.id);
        this.changesByCmdID.set(c.id, {
            commandID: c.id,
            type: c.type,
            variant,
            canceledOut: existing ? !existing.canceledOut : false,
        });
    }
    reset() {
        this.changesByCmdID = new Map();
    }
    clear() {
        this.reset();
    }
    get changes() {
        return [...this.changesByCmdID.values()].filter((c) => !c.canceledOut);
    }
    get hasChanges() {
        return this.changes.length !== 0;
    }
    get lastChange() {
        return last(this.changes);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], Changelog.prototype, "changesByCmdID", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, String]),
    __metadata("design:returntype", void 0)
], Changelog.prototype, "_registerActionToChangelog", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Changelog.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Changelog.prototype, "clear", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_b = typeof Array !== "undefined" && Array) === "function" ? _b : Object),
    __metadata("design:paramtypes", [])
], Changelog.prototype, "changes", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], Changelog.prototype, "hasChanges", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Changelog.prototype, "lastChange", null);
