import React from 'react';
import { ConfirmPopover } from 'lib/cortiUI/components/ConfirmPopover';
import { Typography } from 'lib/cortiUI/components/Typography';
/**
 *
 * Component which wraps a clickable target that you need to add a confirmation to
 */
export function ConfirmableTarget(props) {
    const [state, setState] = React.useState(null);
    return (React.createElement(React.Fragment, null,
        props.children({
            onClick: (e) => {
                e.stopPropagation();
                setState(e.currentTarget);
            },
        }),
        React.createElement(ConfirmPopover, { requiredConfirmPhrase: props.requiredConfirmPhrase, anchorEl: state, onConfirm: (e) => {
                setState(null);
                props.onConfirm && props.onConfirm(e);
            }, onClose: (e) => {
                setState(null);
                props.onCancel && props.onCancel(e);
            }, color: props.color }, props.message && React.createElement(Typography, { variant: "body2" }, props.message))));
}
