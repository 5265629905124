import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Box, Button, ConfirmableTarget, Loading, Typography } from 'lib/cortiUI';
import { CY_SELECTORS } from '../utils';
export const ControlsPanel = ({ isLoading, onReset, onSubmit }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(Box, { pt: 10, pb: 9, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Typography, { variant: "h4", color: "default", "data-cy": CY_SELECTORS.viewTitle }, t('mainTitle', 'Template builder')),
        React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" },
            React.createElement(ConfirmableTarget, { message: t('actionsWarnings.clearTemplateData', 'Are you sure you would like to clear template data?'), onConfirm: onReset, color: "error" }, ({ onClick }) => (React.createElement(Button, { size: "small", variant: "outlined", color: "primary", disabled: isLoading, className: css({ marginLeft: 5, marginRight: 8 }), onClick: onClick, "data-cy": CY_SELECTORS.clearTemplateBtn }, t('clearBtn', 'Clear')))),
            React.createElement(ConfirmableTarget, { message: t('actionsWarnings.publish', 'Make sure you reviewed your template because you will not be able to edit it after submission.'), onConfirm: onSubmit, color: "warning" }, ({ onClick }) => (React.createElement(Button, { size: "small", variant: "contained", color: "primary", disabled: isLoading, onClick: onClick, "data-cy": CY_SELECTORS.submitTemplateBtn },
                isLoading && React.createElement(Loading, { size: "small", mr: 3 }),
                t('publishBtn', 'Publish')))))));
};
