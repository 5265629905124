import { validateConfig } from '@corti/core/callSubscriberConfig';
import { isElectron } from '@corti/electron-utils';
import { callMain } from '@corti/ipc-renderer';
const configKey = 'corti:callSubscriber-config';
function loadFromBrowser() {
    const maybeConfig = localStorage.getItem(configKey);
    try {
        const config = JSON.parse(maybeConfig);
        if (validateConfig(config)) {
            return config;
        }
    }
    catch (_a) {
        localStorage.removeItem(configKey);
    }
}
async function loadConfig() {
    if (isElectron()) {
        return await callMain('callSubscriber.configuration.getConfig');
    }
    else {
        return loadFromBrowser();
    }
}
let config;
export async function init() {
    config = await loadConfig();
}
export function getConfig() {
    return config;
}
