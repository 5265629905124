import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { differenceInMilliseconds, formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { CallTagsValue } from '@corti/lib/coreApiService';
import { Formatters } from '@corti/strings';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { FilterBuilderUtils } from 'browser/components';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Base, Box, Collapse, Dialog, FallbackView, IconButton, Loading, Menu, MenuItem, Modal, ModalContent, ModalHeader, Tooltip, Typography, } from 'lib/cortiUI';
import { useExploreViewContext } from '../ExploreViewContext';
import { CallsUploadView } from './CallsUploadView';
import { CasesExportView } from './CasesExportView';
import { AdvancedFiltering, QuickFiltering } from './CasesFiltering';
import { CasesTable } from './CasesTable';
import { CasesTableEditorView } from './CasesTableEditorView';
export const CasesView = observer((props) => {
    const { casesViewState } = props;
    const exploreCtx = useExploreViewContext();
    const theme = useTheme();
    const isAdvancedFilterStateEmpty = FilterBuilderUtils.isStateEmpty(casesViewState.advancedFilter);
    const [isAdvancedFilteringOpen, setIsAdvancedFilteringOpen] = React.useState(false);
    const [isFileUploadViewOpen, setIsFileUploadViewOpen] = React.useState(false);
    const [isFileExportViewOpen, setIsFileExportViewOpen] = React.useState(false);
    const [isColumnEditorViewOpen, setIsColumnEditorViewOpen] = React.useState(false);
    const [isUploadingCalls, setIsUploadingCalls] = React.useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const toggleAdvancedFiltering = () => {
        setIsAdvancedFilteringOpen(!isAdvancedFilteringOpen);
    };
    const toggleFileUploadView = () => {
        setMenuAnchorEl(null);
        setIsFileUploadViewOpen(!isFileUploadViewOpen);
    };
    const toggleFileExportView = () => {
        setMenuAnchorEl(null);
        setIsFileExportViewOpen(!isFileExportViewOpen);
    };
    const toggleColumnEditorView = () => {
        setMenuAnchorEl(null);
        setIsColumnEditorViewOpen(!isColumnEditorViewOpen);
    };
    const onUploadStart = () => {
        setIsUploadingCalls(true);
    };
    const onUploadEnd = () => {
        setIsUploadingCalls(false);
        void casesViewState.loadCases();
    };
    const onCasesTableEditorSave = () => {
        exploreCtx.refreshCasesList();
        toggleColumnEditorView();
    };
    const handleOpenMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    const getCasesTableData = () => {
        const { cases, casesTableEditorState } = casesViewState;
        const { tableColumns, getColumnDescriptorById } = casesTableEditorState;
        const rows = cases.map((c) => {
            const row = {};
            casesTableEditorState.tableColumns.forEach((col) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                switch (col.id) {
                    case 'caseID': {
                        row[col.id] = c.readableCaseID;
                        break;
                    }
                    case 'caseCreatedBy': {
                        row[col.id] = ((_a = c.createdBy) === null || _a === void 0 ? void 0 : _a.name) || '';
                        break;
                    }
                    case 'caseStartedAt': {
                        row[col.id] = c.startedAt ? formatDateTime(c.startedAt) : '';
                        break;
                    }
                    case 'caseEndedAt': {
                        row[col.id] = c.endedAt ? formatDateTime(c.endedAt) : '';
                        break;
                    }
                    case 'caseUpdatedAt': {
                        row[col.id] = c.updatedAt ? formatDateTime(c.updatedAt) : '';
                        break;
                    }
                    case 'caseTags': {
                        row[col.id] = c.tags
                            ? c.tags.map((tag) => { var _a; return ({ value: tag.value, color: (_a = tag.style) === null || _a === void 0 ? void 0 : _a.color }); })
                            : [];
                        break;
                    }
                    case 'caseReviewedBy': {
                        row[col.id] = uniqBy(c.feedbackFormSubmissions, (sub) => { var _a; return (_a = sub.createdBy) === null || _a === void 0 ? void 0 : _a.id; }).map((sub) => { var _a, _b; return (_b = (_a = sub.createdBy) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : intl.t(`explore:casesView.anonymous`, 'Anonymous'); });
                        break;
                    }
                    case 'sessionCreatedBy': {
                        row[col.id] = c.triageSessions
                            ? uniqBy(c.triageSessions, (session) => { var _a; return (_a = session.createdBy) === null || _a === void 0 ? void 0 : _a.id; })
                                .map((session) => { var _a; return (_a = session.createdBy) === null || _a === void 0 ? void 0 : _a.name; })
                                .filter((session) => !!session)
                            : [];
                        break;
                    }
                    case 'sessionExternalID': {
                        row[col.id] = c.triageSessions
                            ? c.triageSessions
                                .map((session) => session.externalID)
                                .filter((session) => !!session)
                            : [];
                        break;
                    }
                    case 'callUserExtension': {
                        row[col.id] = (_b = c.calls) === null || _b === void 0 ? void 0 : _b.map((call) => { var _a; return (_a = call.user) === null || _a === void 0 ? void 0 : _a.extension; }).filter((call) => !!call);
                        break;
                    }
                    case 'callExternalID': {
                        row[col.id] = (_c = c.calls) === null || _c === void 0 ? void 0 : _c.map((call) => call.externalID).filter((id) => !!id);
                        break;
                    }
                    case 'profilePhone': {
                        row[col.id] = c.profiles
                            ? c.profiles
                                .map((profile) => profile.phone)
                                .filter((profile) => !!profile)
                            : [];
                        break;
                    }
                    case 'profileAddress': {
                        row[col.id] = c.profiles
                            ? c.profiles
                                .map((profile) => profile.address)
                                .filter((profile) => !!profile)
                            : [];
                        break;
                    }
                    case 'caseReviewType': {
                        row[col.id] = uniqBy(c.feedbackFormSubmissions, (sub) => { var _a; return (_a = sub.template) === null || _a === void 0 ? void 0 : _a.id; })
                            .filter((sub) => !!sub.template)
                            .map((sub) => sub.template.title);
                        break;
                    }
                    case 'caseHasReviewAcknowledged': {
                        row[col.id] = ((_d = c.feedbackFormSubmissions) === null || _d === void 0 ? void 0 : _d.some((sub) => !!sub.dispatcherAcknowledgedAt))
                            ? intl.t(`explore:casesView.yes`, 'Yes')
                            : intl.t(`explore:casesView.no`, 'No');
                        break;
                    }
                    case 'caseAssignedTo': {
                        row[col.id] = c.reviewRequests
                            ? c.reviewRequests
                                .map((it) => !it.assignee.isAnonymised && it.assignee.user.name)
                                .filter((review) => !!review)
                            : [];
                        break;
                    }
                    case 'calledParty': {
                        row[col.id] = (_e = c.calls) === null || _e === void 0 ? void 0 : _e.map((c) => c.calledParty).filter((c) => !!c);
                        break;
                    }
                    case 'callingParty': {
                        row[col.id] = (_f = c.calls) === null || _f === void 0 ? void 0 : _f.map((c) => c.callingParty).filter((c) => !!c);
                        break;
                    }
                    case 'protocolName': {
                        row[col.id] = (_g = c.calls) === null || _g === void 0 ? void 0 : _g.map((c) => c.protocolName).filter((c) => !!c);
                        break;
                    }
                    case 'performanceMetricsExcluded': {
                        row[col.id] = (_h = c.calls) === null || _h === void 0 ? void 0 : _h.reduce((amount, call) => { var _a; return ((_a = call.tags) === null || _a === void 0 ? void 0 : _a.includes(CallTagsValue.performanceMetricsExcluded)) ? amount + 1 : amount; }, 0);
                        break;
                    }
                    case 'caseDuration': {
                        const totalDurationInMilliseconds = (_k = (_j = c.calls) === null || _j === void 0 ? void 0 : _j.reduce((partialSum, call) => partialSum +
                            (call.start && call.stop
                                ? differenceInMilliseconds(new Date(call.stop), new Date(call.start))
                                : 0), 0)) !== null && _k !== void 0 ? _k : 0;
                        row[col.id] = Formatters.msToDuration(totalDurationInMilliseconds, { showHours: true });
                        break;
                    }
                    case 'callParticipants': {
                        row[col.id] = (_l = c.calls) === null || _l === void 0 ? void 0 : _l.map((call) => { var _a; return (_a = call.participants) === null || _a === void 0 ? void 0 : _a.map((p) => p.name || ''); }).flat().filter((call) => !!call);
                        break;
                    }
                    case 'callSource': {
                        row[col.id] = (_m = c.calls) === null || _m === void 0 ? void 0 : _m.map((call) => call.callSource).filter((call) => !!call);
                        break;
                    }
                    case 'callProperties': {
                        row[col.id] = (_o = c.calls) === null || _o === void 0 ? void 0 : _o.map((call) => call.properties).flat().filter((call) => !!call).map((callProp) => `${callProp === null || callProp === void 0 ? void 0 : callProp.name}: ${callProp === null || callProp === void 0 ? void 0 : callProp.value}`).join(', ');
                        break;
                    }
                    default: {
                        row[col.id] =
                            c.customProperties && c.customProperties[col.id] ? c.customProperties[col.id] : '';
                        break;
                    }
                }
            });
            return row;
        });
        const columns = tableColumns
            .map((col) => getColumnDescriptorById(col.id))
            .filter((it) => !!it);
        return {
            rows: columns.length > 0 ? rows : [],
            columns: columns,
        };
    };
    const { rows, columns } = getCasesTableData();
    const generateContent = () => {
        if (casesViewState.cases.length > 0) {
            return React.createElement(CasesTable, { casesViewState: casesViewState, rows: rows, columns: columns });
        }
        if (casesViewState.isLoading) {
            return (React.createElement(Box, { justifyContent: "center", alignItems: "center", style: {
                    height: '100%',
                    width: '100%',
                } },
                React.createElement(Loading, { text: intl.t('explore:loadingMessage', 'Loading...') })));
        }
        if (casesViewState.error) {
            return (React.createElement(FallbackView, { title: intl.t('errorView.title', 'Error has occurred'), text: intl.t('errorView.text', 'Try to reload'), actionText: intl.t('errorView.actionText', 'Reload'), action: () => {
                    window.location.reload();
                } }));
        }
        return (React.createElement(FallbackView, { "data-cy": "cases-view-fallback", title: intl.t('explore:filters.noCasesFoundView.title', 'No results found'), text: intl.t('explore:filters.noCasesFoundView.text', 'Try to change filter settings or ask your organization administrator for access to this case.') }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { "data-cy": "cases-view", display: "grid", gridTemplateRows: "auto auto 1fr", overflow: "hidden" },
            React.createElement(Box, { py: 7, px: 8, flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Base, null,
                    React.createElement(Typography, { variant: "h6", color: "default" }, intl.t('explore:appName', 'Explore')),
                    !rbacService.casesOtherUsersRead() && (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
                        React.createElement(Typography, { variant: "subtitle2", color: "disabled", noWrap: true }, intl.t('explore:casesAccessWarning.label', 'Showing cases created by you')),
                        React.createElement(Tooltip, { title: intl.t('explore:casesAccessWarning.detailedText', 'You only have permission to see cases created by you due to your user privileges. Contact your administrator for help.') },
                            React.createElement(InfoRoundedIcon, { color: "disabled" }))))),
                React.createElement(Base, { display: "flex", alignItems: "center" },
                    React.createElement(QuickFiltering, { model: casesViewState }),
                    React.createElement(Tooltip, { disableInteractive: true, title: intl.t('explore:filters.filters', 'Filters') },
                        React.createElement(Base, null,
                            React.createElement(IconButton, { "data-cy": "advanced-filtering-view-toggle-button", onClick: toggleAdvancedFiltering, disabled: !casesViewState.casesFilterDescriptor, color: isAdvancedFilteringOpen || !isAdvancedFilterStateEmpty ? 'primary' : undefined, icon: React.createElement(FilterAltRoundedIcon, null) })))),
                React.createElement(Base, null,
                    React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: handleOpenMenu, className: css({
                            backgroundColor: transparentize(0.5, theme.palette.text.primary),
                            flexGrow: 0,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: theme.palette.action.active,
                            },
                        }), icon: React.createElement(MoreHorizIcon, null) }),
                    React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: handleCloseMenu, anchorEl: menuAnchorEl },
                        authStore.isFeatureEnabled('call-audio-meta-import') && (React.createElement(MenuItem, { "data-cy": "open-calls-upload-view-button", onClick: toggleFileUploadView },
                            React.createElement(Typography, { variant: "body1" }, intl.t('explore:callsUploadView.uploadCalls', 'Upload calls')))),
                        rbacService.casesExport() && (React.createElement(MenuItem, { "data-cy": "open-export-csv-view-button", onClick: toggleFileExportView },
                            React.createElement(Typography, { variant: "body1" }, intl.t('explore:callsExportView.export', 'Export')))),
                        React.createElement(MenuItem, { "data-cy": "open-table-editor-btn", onClick: toggleColumnEditorView },
                            React.createElement(Typography, { variant: "body1" }, intl.t('explore:casesTableEditorView.title', 'Customize columns')))))),
            React.createElement(Collapse, { in: isAdvancedFilteringOpen },
                React.createElement(AdvancedFiltering, { model: casesViewState })),
            React.createElement(Base, { py: 7, px: 8, className: css({ overflow: 'hidden' }) }, generateContent())),
        React.createElement(Modal, { open: isFileUploadViewOpen, hideCloseButton: isUploadingCalls, disableEscapeKeyDown: true, size: "large", className: css({ display: 'grid' }), onClose: (_, reason) => {
                if (reason !== 'backdropClick') {
                    toggleFileUploadView();
                }
            } },
            React.createElement(Base, { display: "grid", overflow: "hidden", height: "100%", gridTemplateRows: "auto 1fr", maxHeight: "inherit" },
                React.createElement(ModalHeader, { title: intl.t('explore:callsUploadView.title', 'Upload Calls to Corti') }),
                React.createElement(ModalContent, { className: css({
                        display: 'grid',
                        height: '100%',
                        overflow: 'hidden',
                    }) },
                    React.createElement(CallsUploadView, { onClose: toggleFileUploadView, onUploadStart: onUploadStart, onUploadEnd: onUploadEnd })))),
        React.createElement(Modal, { open: isFileExportViewOpen, onClose: toggleFileExportView, size: "small" },
            React.createElement(Base, null,
                React.createElement(ModalHeader, { title: intl.t('explore:callsExportView.title', 'Export calls from Corti') }),
                React.createElement(ModalContent, null,
                    React.createElement(CasesExportView, { onClose: toggleFileExportView, filter: casesViewState.casesInput.filter })))),
        React.createElement(Dialog, { open: isColumnEditorViewOpen, onClose: toggleColumnEditorView, size: "auto", title: intl.t('explore:casesTableEditorView.title', 'Customize columns') },
            React.createElement(Base, { width: 720 },
                React.createElement(CasesTableEditorView, { casesTableEditorState: casesViewState.casesTableEditorState, onSave: onCasesTableEditorSave })))));
});
