import React from 'react';
import { Base, Card, CardContent, Skeleton } from 'lib/cortiUI';
export const AssessmentFeedbackLoader = () => {
    const rowArr = [0, 1, 2];
    return (React.createElement(Card, { width: "100%" },
        React.createElement(CardContent, { sx: { p: 4 } },
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2 },
                React.createElement(Skeleton, { variant: "text", height: 24, width: "30%" }),
                React.createElement(Skeleton, { variant: "text", height: 20, width: "20%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 10, width: "100%", sx: { my: 7 } }),
                React.createElement(Base, { display: "flex", alignItems: "center", gap: 7 }, rowArr.map((i) => (React.createElement(Base, { key: i, display: "flex", alignItems: "center", gap: 3 },
                    React.createElement(Skeleton, { variant: "circular", height: 8, width: 8 }),
                    React.createElement(Skeleton, { variant: "text", height: 18, width: 100 })))))))));
};
