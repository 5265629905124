import { Buffer } from 'buffer';
import { InMemoryStorage } from './storage';
export class Recorder {
    constructor(storage = new InMemoryStorage(), screenId) {
        Object.defineProperty(this, "_mediaRecorder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "_storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_screenId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._storage = storage;
        this._screenId = screenId;
    }
    startRecording(stream, options, timeSlice = 100) {
        this._mediaRecorder = new MediaRecorder(stream, options);
        this._storage.initStream(this._screenId, `${this._screenId.replaceAll(':', '-')}.webm`);
        this._mediaRecorder.ondataavailable = async (e) => {
            if (e.data.size > 0) {
                const buf = Buffer.from(await e.data.arrayBuffer());
                this._storage.write(buf, this._screenId);
            }
        };
        this._mediaRecorder.start(timeSlice);
    }
    async stopRecording() {
        await new Promise((resolve, reject) => {
            if (this._mediaRecorder) {
                this._mediaRecorder.onstop = resolve;
                this._mediaRecorder.onerror = reject;
                this._mediaRecorder.stop();
            }
            else {
                resolve(null);
            }
        });
        this._mediaRecorder = null;
        this._storage.flush(this._screenId);
    }
}
