import { useCombobox } from 'downshift';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { useTranslation } from '@corti/i18n';
import { useDebouncedEffect } from '@corti/react';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Checkbox, Input, Label, Popover, Tag, Typography, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor';
export const GlobalSearchPanel = function (props) {
    const [popupOpen, setPopupOpen] = React.useState(false);
    const { editor } = useGraphEditorCtx();
    React.useEffect(() => {
        const keyboardBinder = editor.keyboardBinder.createInstance();
        keyboardBinder.bind(['/', 'l'], (e) => {
            e.preventDefault();
            setPopupOpen(true);
        });
        return () => {
            editor.keyboardBinder.remove(keyboardBinder);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Popover, { anchorEl: props.editorWidgetEl, transitionDuration: 0, anchorOrigin: { horizontal: 'center', vertical: 'top' }, open: popupOpen, transformOrigin: { horizontal: 'center', vertical: 'top' }, onClose: () => setPopupOpen(false), PaperProps: {
            style: { minWidth: 480, width: '60%', maxWidth: 800 },
        } },
        React.createElement(ElementSearchBar, { onClose: () => setPopupOpen(false) })));
};
const ElementSearchBar = observer(function ElementSearchBar(props) {
    const $input = React.useRef(null);
    const $virtualListRef = React.useRef(null);
    const { t } = useTranslation('libGraphEditor');
    const theme = useTheme();
    const { editor } = useGraphEditorCtx();
    const state = useLocalStore(() => ({
        inputValue: '',
        includeElements: true,
        includeComponents: true,
        foundElements: editor.model.elementContext.indexableElements,
    }));
    const prevInputValue = React.useRef('');
    const downshift = useCombobox({
        items: state.foundElements,
        itemToString: () => '',
        defaultHighlightedIndex: 0,
        isOpen: true,
        onHighlightedIndexChange: ({ highlightedIndex }) => {
            var _a;
            if (highlightedIndex === -1)
                return;
            (_a = $virtualListRef.current) === null || _a === void 0 ? void 0 : _a.scrollToRow(highlightedIndex);
        },
        onSelectedItemChange: ({ selectedItem }) => {
            var _a;
            if (selectedItem) {
                locateElementInGraph(selectedItem);
            }
            (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
        },
        onInputValueChange: ({ inputValue }) => {
            runInAction(() => {
                prevInputValue.current = state.inputValue;
                state.inputValue = inputValue !== null && inputValue !== void 0 ? inputValue : '';
            });
        },
        stateReducer: (currentState, actionsAndChanges) => {
            const { type, changes } = actionsAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem: {
                    return Object.assign(Object.assign({}, currentState), { selectedItem: changes.selectedItem });
                }
                case useCombobox.stateChangeTypes.InputChange: {
                    return changes;
                }
                default: {
                    return changes;
                }
            }
        },
    });
    React.useEffect(() => {
        var _a;
        (_a = $input.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    useDebouncedEffect(() => {
        if (state.inputValue === prevInputValue.current) {
            return;
        }
        runInAction(() => {
            state.foundElements = findElements();
        });
    }, 250, [state.inputValue]);
    function locateElementInGraph(element) {
        if (element.nodeID) {
            editor.dispatch([
                {
                    type: 'canvas.fitNodesIntoView',
                    data: { nodeIDs: [element.nodeID] },
                },
                {
                    type: 'canvas.selectEntities',
                    data: {
                        ids: [element.nodeID],
                        type: 'replace',
                    },
                },
            ]);
        }
    }
    function findElements() {
        const r = editor.model.elementContext.findElementsByReadableTitle(state.inputValue, {
            excludeElements: !state.includeElements,
            excludeComponents: !state.includeComponents,
        });
        return r;
    }
    return (React.createElement("div", Object.assign({ style: { width: '100%', height: '100%' } }, downshift.getComboboxProps()),
        React.createElement(Box, { p: 3, flexDirection: "row", alignItems: "center" },
            React.createElement(Input, { inputProps: Object.assign({}, downshift.getInputProps({
                    ref: $input,
                    onKeyDown: (event) => {
                        if (event.key === 'Home' || event.key === 'End') {
                            // Prevent Downshift's default 'Enter' behavior.
                            event.nativeEvent.preventDownshiftDefault = true;
                        }
                    },
                })), width: '100%', type: "search" })),
        React.createElement(Box, { px: 3, my: 4, flexDirection: "row" },
            React.createElement(Base, { display: "grid", gridGap: 4, gridAutoFlow: "column" },
                React.createElement("div", null,
                    React.createElement(Checkbox, { id: "include-blocks", onChange: (e) => {
                            runInAction(() => {
                                state.includeElements = e.target.checked;
                                state.foundElements = findElements();
                            });
                        }, checked: state.includeElements }),
                    React.createElement(Label, { htmlFor: "include-blocks" }, t('elementSearchBar.checkboxBlocks', 'Blocks'))),
                React.createElement("div", null,
                    React.createElement(Checkbox, { id: "include-components", onChange: (e) => {
                            runInAction(() => {
                                state.includeComponents = e.target.checked;
                                state.foundElements = findElements();
                            });
                        }, checked: state.includeComponents }),
                    React.createElement(Label, { htmlFor: "include-components" }, t('elementSearchBar.checkboxComponents', 'Components')))),
            React.createElement(Base, { ml: "auto" },
                React.createElement(Typography, { variant: "caption", color: "default" }, t('elementSearchBar.foundResultsMsg', 'Found {{count}}', {
                    count: state.foundElements.length,
                })))),
        React.createElement(Base, Object.assign({ my: 5 }, downshift.getMenuProps()),
            React.createElement(AutoSizer, { disableHeight: true, style: { width: '100%' } }, ({ width }) => (React.createElement(List, { className: getScrollerCss({ theme }), ref: $virtualListRef, width: width, height: Math.min(40 * state.foundElements.length, 480), rowCount: state.foundElements.length, rowHeight: 40, rowRenderer: ({ index, style, key }) => {
                    const el = state.foundElements[index];
                    const isHighlighted = downshift.highlightedIndex === index;
                    return (React.createElement(Box, Object.assign({ justifyContent: "center", width: '100%', key: key, p: 3, style: Object.assign(Object.assign({}, style), { userSelect: 'none' }), background: isHighlighted ? '#00000025' : undefined, color: theme.palette.text.secondary }, downshift.getItemProps({ item: el, index })),
                        React.createElement(RenderableElementItem, { element: el })));
                } }))))));
});
const hashcode = (s) => s.split('').reduce((r, _, idx) => r + s.charCodeAt(idx), 0);
const getColor = (str) => {
    const colors = [
        '#ff7c54',
        '#b8ac00',
        '#6000ce',
        '#e84393',
        '#6ab04c',
        '#22b3ac',
    ];
    let index = 0;
    if (str) {
        index = hashcode(str) % colors.length;
    }
    return colors[index];
};
function RenderableElementItem({ element }) {
    return (React.createElement(Base, { display: "grid", gridGap: 3, alignItems: "center", gridAutoFlow: "column", gridAutoColumns: "max-content" },
        React.createElement(CommonTag, { text: element.componentID ? 'C' : 'B', color: element.componentID ? '#6230fa' : '#23a2ff' }),
        React.createElement(Typography, { variant: "subtitle2", color: "default" }, element.readableTitle),
        element.tagname ? (React.createElement(CommonTag, { text: element.tagname, color: getColor(element.tagname) })) : undefined,
        element.locationDescription && (React.createElement(Typography, { variant: "caption", color: "hint" }, element.locationDescription))));
}
function CommonTag(props) {
    return React.createElement(Tag, Object.assign({}, props));
}
