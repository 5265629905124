var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { difference } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import Papa from 'papaparse';
import { isValid, parseISO } from '@corti/date';
import { intl } from '@corti/i18n';
import { UploadRequestModel } from './UploadRequestModel';
const CSV_ID_FIELD = 'filename';
const CSV_METADATA_FIELDS = {
    CASE_ID: 'case_id',
    TIMESTAMP: 'timestamp',
    PROTOCOL_NAME: 'protocol_name',
};
export class UploadRequestsState {
    constructor() {
        Object.defineProperty(this, "uploadRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "CSVParseWarnings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createUploadRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file, id) => {
                this.uploadRequests.push(new UploadRequestModel(file, id));
            }
        });
        Object.defineProperty(this, "initializeUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                for (let req of this.unititializedUploadRequests) {
                    await req.initialize();
                }
            }
        });
        Object.defineProperty(this, "eraseAllMetadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.uploadRequests.forEach((req) => req.eraseMetadata());
            }
        });
        Object.defineProperty(this, "parseCSVFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => {
                this.eraseCSVParseWarnings();
                Papa.parse(file, {
                    header: true,
                    complete: this.onCSVParseComplete,
                    error: () => {
                        this.addCSVParseWarning(intl.t('explore:callsUploadView.CSVParseExceptions.parseError', 'Unknown issue. Check if metadata file content is valid and try again.'));
                    },
                });
            }
        });
        Object.defineProperty(this, "onCSVParseComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (res) => {
                const parsedCSVHeader = res.meta.fields || [];
                const parsedCSVBody = res.data;
                const parsedCSVHasRequiredField = parsedCSVHeader.includes(CSV_ID_FIELD);
                const missingFields = difference(Object.values(CSV_METADATA_FIELDS), parsedCSVHeader);
                const missingCalls = difference(this.uploadRequests.map((req) => req.file.name), parsedCSVBody.map((CSVRow) => CSVRow[CSV_ID_FIELD]).filter((idField) => !!idField));
                let notValidTimestampsCount = 0;
                if (parsedCSVHasRequiredField) {
                    parsedCSVBody.forEach((CSVRow) => {
                        const uploadRequest = this.uploadRequests.find((req) => req.file.name === CSVRow[CSV_ID_FIELD]);
                        if (uploadRequest) {
                            let transformedTimeStamp;
                            const parsedDateString = parseISO(CSVRow[CSV_METADATA_FIELDS.TIMESTAMP]);
                            if (isValid(parsedDateString)) {
                                transformedTimeStamp = new Date(parsedDateString).toISOString();
                            }
                            else {
                                notValidTimestampsCount++;
                            }
                            uploadRequest.assignMetadata({
                                caseID: CSVRow[CSV_METADATA_FIELDS.CASE_ID],
                                timeStamp: transformedTimeStamp,
                                protocolName: CSVRow[CSV_METADATA_FIELDS.PROTOCOL_NAME],
                            });
                        }
                    });
                }
                else {
                    missingFields.unshift(CSV_ID_FIELD);
                }
                if (missingCalls.length > 0) {
                    this.addCSVParseWarning(intl.t('explore:callsUploadView.CSVParseExceptions.missingCalls', {
                        count: missingCalls.length,
                        defaultValue_one: `Could not match {{count}} call file.`,
                        defaultValue_other: `Could not match {{count}} call files.`,
                    }));
                }
                if (missingFields.length > 0) {
                    missingFields.forEach((field) => {
                        this.addCSVParseWarning(intl.t('explore:callsUploadView.CSVParseExceptions.missingField', 'Header field "{{ field }}" is missing or has type errors.', { field }));
                    });
                }
                if (notValidTimestampsCount > 0) {
                    this.addCSVParseWarning(intl.t('explore:callsUploadView.CSVParseExceptions.notValidFields', {
                        count: notValidTimestampsCount,
                        field: CSV_METADATA_FIELDS.TIMESTAMP,
                        defaultValue_one: `Could not parse {{count}} {{field}} field`,
                        defaultValue_other: `Could not parse {{count}} {{field}} fields`,
                    }));
                }
            }
        });
        Object.defineProperty(this, "addCSVParseWarning", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (warning) => {
                this.CSVParseWarnings.push(warning);
            }
        });
        Object.defineProperty(this, "eraseCSVParseWarnings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.CSVParseWarnings = [];
            }
        });
        makeObservable(this);
        runInAction(() => {
            this.uploadRequests = [];
            this.CSVParseWarnings = [];
        });
    }
    get isUploading() {
        return this.uploadRequests.some((req) => req.isUploading);
    }
    get hasUploaded() {
        if (this.uploadRequests.length > 0) {
            return this.uploadRequests.every((req) => {
                return !req.isUploading && (!!req.response || !!req.error);
            });
        }
        return false;
    }
    get unititializedUploadRequests() {
        return this.uploadRequests.filter((req) => !req.isUploading && !req.response && !req.error);
    }
    get fulfilledUploadRequests() {
        return this.uploadRequests.filter((req) => !!req.response);
    }
    get rejectedUploadRequests() {
        return this.uploadRequests.filter((req) => !!req.error);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], UploadRequestsState.prototype, "uploadRequests", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], UploadRequestsState.prototype, "CSVParseWarnings", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestsState.prototype, "createUploadRequest", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestsState.prototype, "eraseAllMetadata", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestsState.prototype, "addCSVParseWarning", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestsState.prototype, "eraseCSVParseWarnings", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UploadRequestsState.prototype, "isUploading", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UploadRequestsState.prototype, "hasUploaded", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UploadRequestsState.prototype, "unititializedUploadRequests", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UploadRequestsState.prototype, "fulfilledUploadRequests", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UploadRequestsState.prototype, "rejectedUploadRequests", null);
