var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, runInAction } from 'mobx';
import { intl } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { RealTimeController } from './RealTimeController';
import { RealTimeDB } from './cache';
const QUOTA_EXCEEDED_NOTIFICATION_ID = 'quota-exceeded';
export class RealTimeModule {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "module", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        void this.init();
    }
    async init() {
        var _a, _b, _c, _d, _e, _f, _g;
        const realtimeDB = new RealTimeDB();
        runInAction(() => {
            this.module = {
                status: 'initializing',
            };
        });
        try {
            await realtimeDB.open();
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error('Error received when opening realtime app database', String(err));
            runInAction(() => {
                this.module = {
                    status: 'error',
                    error: {
                        code: 'realtime-database-error',
                        error: err,
                    },
                };
            });
            return;
        }
        try {
            await realtimeDB.fix();
        }
        catch (err) {
            (_b = this.input.logger) === null || _b === void 0 ? void 0 : _b.error('Error received when fixing realtime db', String(err));
            runInAction(() => {
                this.module = {
                    status: 'error',
                    error: {
                        code: 'realtime-database-error',
                        error: err,
                    },
                };
            });
            return;
        }
        try {
            (_c = this.input.logger) === null || _c === void 0 ? void 0 : _c.info('Looking for old databases to delete');
            const res = await realtimeDB.deleteUserDBsOlderThan({ days: 2 });
            if (res.deletedCount === 0) {
                (_d = this.input.logger) === null || _d === void 0 ? void 0 : _d.info(`No user databases deleted`);
            }
            else {
                (_e = this.input.logger) === null || _e === void 0 ? void 0 : _e.info(`Deleted ${res.deletedCount} user databases`);
            }
        }
        catch (err) {
            (_f = this.input.logger) === null || _f === void 0 ? void 0 : _f.error('Error received when deleting old datases', String(err));
            runInAction(() => {
                this.module = {
                    status: 'error',
                    error: {
                        code: 'realtime-database-error',
                        error: err,
                    },
                };
            });
            return;
        }
        let realtimeUserDB;
        try {
            realtimeUserDB = await realtimeDB.openDBForUser(authStore.currentUserID);
            realtimeUserDB.onQuotaExceeded(() => {
                coreStore.notifications.showNotification({
                    id: QUOTA_EXCEEDED_NOTIFICATION_ID,
                    message: intl.t('storageQuotaExceededNotificationTitle', 'Storage limit reached, session data will not save.'),
                    type: 'warning',
                    closable: true,
                    permanent: true,
                });
            });
        }
        catch (err) {
            (_g = this.input.logger) === null || _g === void 0 ? void 0 : _g.error('Error received when opening realtime app user database', String(err));
            runInAction(() => {
                this.module = {
                    status: 'error',
                    error: {
                        code: 'realtime-database-error',
                        error: err,
                    },
                };
            });
            return;
        }
        if (this.input.protocolGraphDB.hasFailed()) {
            runInAction(() => {
                this.module = {
                    status: 'error',
                    error: {
                        code: 'realtime-database-error',
                    },
                };
            });
            return;
        }
        runInAction(() => {
            this.module = {
                status: 'ok',
                controller: new RealTimeController({
                    apiContributor: this.input.apiContributor,
                    protocolGraphDB: this.input.protocolGraphDB,
                    realtimeUserDB: realtimeUserDB,
                    logger: this.input.logger,
                }),
            };
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RealTimeModule.prototype, "module", void 0);
