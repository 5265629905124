var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Box is very similar to Base, except that by default it is `display: flex`
 */
import React from 'react';
import { Base } from 'lib/cortiUI/components/Base';
export const Box = React.forwardRef(function Box(props, ref) {
    const { inline, display = inline ? 'inline-flex' : 'flex', flexDirection = 'column' } = props, rest = __rest(props, ["inline", "display", "flexDirection"]);
    return React.createElement(Base, Object.assign({ ref: ref, display: display, flexDirection: flexDirection }, rest));
});
