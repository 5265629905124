import React from 'react';
import { Box } from 'lib/cortiUI/components/Box';
import { ImageRenderer } from './ImageRenderer';
export function FileRenderer({ url, contentType }) {
    const check = (type) => contentType === null || contentType === void 0 ? void 0 : contentType.includes(type);
    if (check('pdf')) {
        return (React.createElement("iframe", { style: {
                width: '100%',
                height: '100%',
            }, src: url }));
    }
    if (check('audio')) {
        return (React.createElement(Box, { alignItems: "center", justifyContent: "center", style: {
                width: '100%',
                height: '100%',
            } },
            React.createElement("audio", { controls: true, src: url })));
    }
    if (check('video')) {
        return (React.createElement(Box, { alignItems: "center", justifyContent: "center", style: {
                width: '100%',
                height: '100%',
            } },
            React.createElement("video", { controls: true, src: url })));
    }
    if (check('image')) {
        return React.createElement(ImageRenderer, { url: url });
    }
    if (check('html')) {
        return (React.createElement("iframe", { sandbox: ['allow-scripts'].join(','), style: {
                width: '100%',
                height: '100%',
            }, src: url }));
    }
    return (React.createElement("iframe", { sandbox: "", style: {
            width: '100%',
            height: '100%',
            border: 0,
            outline: 'none',
        }, src: url }));
}
