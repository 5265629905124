import React from 'react';
import { useTranslation } from '@corti/i18n';
import { theme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { repositories } from 'core/repositories';
import { Base, DataGrid } from 'lib/cortiUI';
export function UsersDataGrid({ users, renderActions }) {
    const { t } = useTranslation('usersDataGrid');
    const { currentUserID } = useAuth();
    const rows = users.map((user) => ({
        online: repositories.users.userPresence.isOnline(user.id),
        name: currentUserID === user.id ? `${user.name} (${t('you', 'You')})` : user.name,
        id: user.id,
        extension: user.extension,
        externalID: user.externalID,
        role: user.role.name,
        actions: user,
    }));
    const columns = [
        {
            field: 'online',
            headerName: t('columnTitle.online', 'Online'),
            flex: 1,
            renderCell: ({ value }) => (React.createElement(Base, { width: 10, height: 10, borderRadius: "50%", background: value ? theme.palette.success.main : theme.palette.grey[500] })),
        },
        { field: 'name', headerName: t('columnTitle.name', 'Name'), flex: 2 },
        { field: 'id', headerName: t('columnTitle.id', 'User ID'), flex: 2 },
        { field: 'extension', headerName: t('columnTitle.extension', 'Extension'), flex: 2 },
        { field: 'externalID', headerName: t('columnTitle.externalID', 'External ID'), flex: 2 },
        { field: 'role', headerName: t('columnTitle.role', 'Role'), flex: 2 },
        {
            field: 'actions',
            headerName: t('columnTitle.actions', 'Actions'),
            flex: 1,
            renderCell: ({ value }) => {
                return value ? renderActions(value) : null;
            },
        },
    ];
    return (React.createElement(Base, { height: "80vh", width: "100%" },
        React.createElement(DataGrid, { columns: columns, disableColumnFilter: true, disableColumnMenu: true, disableSelectionOnClick: true, rows: rows, initialState: {
                sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                },
            }, sx: {
                border: 'hidden',
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
            } })));
}
