import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { ToolPanelContent, useToolbar } from 'lib/webappUI';
import { Alerts } from '../Alerts';
import { ChecklistToolPanel } from '../ChecklistToolPanel';
import { Comments } from '../Comments';
import { FavoriteStep } from '../FavoriteStep';
import { PinnedSteps } from '../PinnedSteps';
import { useTriageSession } from '../TriageSessionContext';
export const ToolPanel = observer(() => {
    const ctx = useTriageSession();
    const { openToolID } = useToolbar();
    switch (openToolID) {
        case 'tools:alerts': {
            if (!ctx.controller.settings.isAlertsEnabled) {
                return null;
            }
            if (!ctx.controller.settings.isAlertsUIEnabled) {
                return null;
            }
            return (React.createElement(ToolPanelContent, { title: intl.t('realtime:alerts', 'Detections') },
                React.createElement(Alerts, null)));
        }
        case 'tools:pins': {
            return (React.createElement(ToolPanelContent, { title: intl.t('realtime:pinsView.title', 'Pins') },
                React.createElement(PinnedSteps, null)));
        }
        case 'tools:comments': {
            if (!ctx.controller.settings.isCommentsEnabled) {
                return null;
            }
            return (React.createElement(ToolPanelContent, { title: intl.t('realtime:commentsView.title', 'Comments') },
                React.createElement(Comments, null)));
        }
        case 'tools:favoriteStep': {
            if (!ctx.controller.flow.stepStore.favoriteStep) {
                return null;
            }
            return (React.createElement(ToolPanelContent, { title: ctx.controller.flow.stepStore.favoriteStep.node.view.name ||
                    intl.t('realtime:quickAccessNode', 'Quick access node') },
                React.createElement(FavoriteStep, null)));
        }
        case 'tools:checklists': {
            return React.createElement(ChecklistToolPanel, null);
        }
        default: {
            return null;
        }
    }
});
