import React from 'react';
import { Base, Typography } from 'lib/cortiUI';
export const GenericPanel = React.forwardRef(function GenericPanel(props, ref) {
    return React.createElement(Base, Object.assign({ ref: ref }, props));
});
export function GenericPanelHeader(props) {
    return (React.createElement(Base, { background: "#00000012", px: 6, py: 4 },
        React.createElement(Typography, { color: "default", variant: "subtitle2", noSelect: true }, props.header)));
}
export function GenericPanelContent(props) {
    return React.createElement(Base, Object.assign({}, props));
}
export function GenericPanelBundle(props) {
    return (React.createElement(GenericPanel, null,
        React.createElement(GenericPanelHeader, { header: props.header }),
        React.createElement(GenericPanelContent, { px: 6 }, props.children)));
}
