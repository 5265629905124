import LockRoundedIcon from '@mui/icons-material/LockRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Chip, Tooltip, Typography } from 'lib/cortiUI';
export const LockedViewChip = ({ creatorFullName }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'lockedViewChip' });
    return (React.createElement(Tooltip, { title: React.createElement(Typography, { variant: "caption", color: "inherit" },
            t('tooltip', 'You cannot edit this View, because you either don’t have the right permission or the View is locked by the creator. Creator: '),
            React.createElement(Typography, { variant: "caption", color: "inherit", fontStyle: "italic" }, creatorFullName)), placement: "top-end" },
        React.createElement(Chip, { label: t('label', 'Locked for edits'), color: "primary", icon: React.createElement(LockRoundedIcon, { color: "action" }), sx: { cursor: 'default' } })));
};
