import React, { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
export function SortableElement({ index, children }) {
    const draggableId = useMemo(() => Math.random().toString(36).slice(2, 9), []);
    return (React.createElement(Draggable, { draggableId: draggableId, index: index }, ({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => children({
        itemProps: Object.assign({ ref: innerRef }, draggableProps),
        handleProps: dragHandleProps,
        isDragging,
    })));
}
