import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { useHistoryBlock } from '@corti/react';
import { css, darken, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Button, FallbackView, Loading, Modal, ModalContent, ModalHeader, Typography, } from 'lib/cortiUI';
import { useExploreViewContext } from '../ExploreViewContext';
import { BottomToolbar } from './BottomToolbar';
import { CallSwitcher } from './CallSwitcher';
import { CasePlayer } from './CasePlayer';
import { CaseViewContext } from './CaseViewContext';
import { SideToolbar } from './SideToolbar';
import { TopToolbar } from './TopToolbar';
import { QUERIES } from './graphql';
export const CaseView = observer((props) => {
    const { basePath, caseViewState } = props;
    const organizationID = api.auth.user.orgID;
    const theme = useTheme();
    const exploreCtx = useExploreViewContext();
    const [casePlayerHeight, setCasePlayerHeight] = React.useState(0);
    const { loading: isTimelineEntryDefsLoading } = useQuery(QUERIES.getTimelineEntryDefinitions, {
        variables: {
            organizationID,
        },
        onCompleted: (res) => {
            void caseViewState.loadCase(props.match.params.caseID);
            caseViewState.setTimelineEntryDefinitions(res.timelineEntryDefinitions);
        },
    });
    const casePlayerContainerRef = React.useCallback((c) => {
        if (c) {
            setCasePlayerHeight(c.clientHeight);
        }
    }, []);
    React.useEffect(() => {
        caseViewState.resetState();
        const urlQueryString = new URLSearchParams(props.location.search);
        if (urlQueryString.has('sidebarView')) {
            caseViewState.setActiveSidebarView(urlQueryString.get('sidebarView'));
        }
        if (urlQueryString.has('feedbackView')) {
            caseViewState.feedbackViewState.setActiveView(urlQueryString.get('feedbackView'));
        }
        if (urlQueryString.has('feedbackID')) {
            caseViewState.feedbackViewState.setActiveFeedbackID(urlQueryString.get('feedbackID'));
        }
        if (urlQueryString.has('templateID')) {
            caseViewState.feedbackViewState.setActiveTemplateID(urlQueryString.get('templateID'));
        }
        exploreCtx.setActiveExploreView('caseView');
        exploreCtx.addCaseToVisitedCases(props.match.params.caseID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (!!caseViewState.caseEntity) {
            void caseViewState.loadCaseTimelineEntries(props.match.params.caseID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseViewState.caseEntity]);
    const { caseEntity, isLoading, error } = caseViewState;
    const renderContent = () => {
        if (isLoading || isTimelineEntryDefsLoading) {
            return (React.createElement(Box, { justifyContent: "center", alignItems: "center", style: {
                    height: '100%',
                    width: '100%',
                } },
                React.createElement(Loading, { text: intl.t('appLoadingMessage', 'Loading...') })));
        }
        else if (error) {
            return (React.createElement(Box, { style: { height: '100vh' } },
                React.createElement(FallbackView, { "data-cy": "error-fallback-view", title: intl.t('explore:caseView.failedToFetchCaseView.title', 'Could not load case'), text: intl.t('explore:caseView.failedToFetchCaseView.text', 'This case does not exist or you don’t have the permission to access it. Contact your organization administrator for access.'), actionText: intl.t('explore:caseView.failedToFetchCaseView.actionText', 'Back to list'), action: () => props.history.push(basePath) })));
        }
        else if (caseEntity) {
            return (React.createElement(Base, { "data-cy": "single-case-view", className: css({
                    display: 'grid',
                    gridTemplateAreas: `"case-view side-toolbar"`,
                    gridTemplateColumns: '1fr auto',
                    overflow: 'hidden',
                }) },
                React.createElement(Base, { className: css({ gridArea: 'case-view' }, getScrollerCss({ theme: theme })) },
                    React.createElement(Base, { className: css({
                            display: 'grid',
                            height: '100%',
                            gridTemplateRows: 'auto auto 1fr auto',
                            gridTemplateAreas: `
                        "top-toolbar"
                        "case-details"
                        "case-player"
                        "bottom-toolbar"
                      `,
                        }) },
                        React.createElement(TopToolbar, { caseViewState: caseViewState, className: css({
                                gridArea: 'top-toolbar',
                            }) }),
                        React.createElement(Base, { px: 8, pt: 5, pb: 7, className: css({
                                gridArea: 'case-details',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                alignItems: 'center',
                            }) },
                            React.createElement(CallSwitcher, { caseModel: caseEntity })),
                        React.createElement(Box, { "data-cy": "case-player-container", ref: casePlayerContainerRef, className: css({
                                gridArea: 'case-player',
                                background: darken(0.03, theme.palette.background.default),
                                borderTop: `1px solid ${theme.palette.background.divider}`,
                                borderBottom: `1px solid ${theme.palette.background.divider}`,
                                zIndex: 0,
                            }) }, casePlayerHeight && caseEntity.activeCallPlayerState ? (React.createElement(CasePlayer, { key: caseEntity.activeCallPlayerState.call.id, caseModel: caseEntity, callPlayerState: caseEntity.activeCallPlayerState, height: casePlayerHeight })) : (React.createElement(FallbackView, { title: intl.t('explore:caseView.noCall', 'No call data'), className: css({
                                width: '100%',
                                height: '100%',
                            }) }))),
                        React.createElement(BottomToolbar, { caseModel: caseEntity, className: css({
                                gridArea: 'bottom-toolbar',
                            }) }))),
                React.createElement(SideToolbar, { className: css({
                        gridArea: 'side-toolbar',
                    }) })));
        }
        else {
            return null;
        }
    };
    const shouldBlock = !!(caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.timelineEditor.diffHasChanges) ||
        !isEmpty(caseViewState.feedbackViewState.activeFeedbackFormValue.fields);
    const { isBlocked, proceed: proceedNavigation, cancel: cancelNavigation, } = useHistoryBlock(() => shouldBlock, [shouldBlock]);
    const renderNavigationWarningAlert = () => {
        return (React.createElement(Modal, { open: isBlocked, hideCloseButton: true, style: { padding: 0 }, size: "small" },
            React.createElement(Base, null,
                React.createElement(ModalHeader, { title: intl.t('explore:caseView.navigationWarningAlert.leavingViewWarning', 'Do you want to leave?') }),
                React.createElement(ModalContent, { pb: 4 },
                    React.createElement(Typography, null, intl.t('explore:caseView.navigationWarningAlert.notSavedChangesWarning', 'Changes you made may not be saved.')),
                    React.createElement(Base, { p: 5, mx: "auto" },
                        React.createElement(Button, { color: "primary", onClick: proceedNavigation, className: css({ marginRight: 5 }) }, intl.t('explore:caseView.navigationWarningAlert.leave', 'Leave')),
                        React.createElement(Button, { onClick: cancelNavigation }, intl.t('explore:caseView.navigationWarningAlert.stay', 'Stay')))))));
    };
    return (React.createElement(CaseViewContext.Provider, { value: { caseViewState } },
        renderContent(),
        renderNavigationWarningAlert()));
});
