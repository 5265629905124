import { Checklist } from '@mui/icons-material';
import IconChatBubble from '@mui/icons-material/ChatBubble';
import IconNotifications from '@mui/icons-material/Notifications';
import PushPinIcon from '@mui/icons-material/PushPin';
import StarRounded from '@mui/icons-material/StarRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { trackerService } from 'browser/services/init';
import { Base } from 'lib/cortiUI';
import { Toolbar as BaseToolbar, ToolbarButton, ToolbarButtons } from 'lib/webappUI';
import { ActionSelector } from '../ActionSelector';
import { CommentsCounter } from '../CommentsCounter';
import { ConnectedUsersWidget } from '../ConnectedUsersWidget';
import { SearchBar } from '../Search/SearchBar';
import { useTriageSession } from '../TriageSessionContext';
import { useUIState } from '../UIStateProvider';
export const Toolbar = observer(() => {
    const ctx = useTriageSession();
    const uiState = useUIState();
    // This is a poor way to disable keyboard shortcut controls.
    // Instead they should automaticaly enable/disable based on UI contexts.
    // But currently we dont have a solution for that.
    const isKeyboardShortcutsDisabled = Boolean(uiState.activePinnedStepID);
    return (React.createElement(BaseToolbar, null,
        React.createElement(Base, { display: "flex", alignItems: "center", gap: 4 },
            ctx.controller.isCurrentUserOwner && ctx.controller.settings.isSearchEnabled && (React.createElement(Base, { width: "100%", maxWidth: 220, mr: "auto" },
                React.createElement(SearchBar, { keyboardShortcut: {
                        open: '/',
                        disabled: isKeyboardShortcutsDisabled,
                    } }))),
            React.createElement(Base, { display: "flex", alignItems: "center", gap: 4, ml: "auto" },
                ctx.controller.settings.isManualActionSelectEnabled && (React.createElement(ActionSelector, { keyboardShortcuts: {
                        open: 'm',
                        disabled: isKeyboardShortcutsDisabled,
                    } })),
                React.createElement(ConnectedUsersWidget, { keyboardShortcut: {
                        open: 'u',
                        disabled: isKeyboardShortcutsDisabled,
                    } }))),
        React.createElement(ToolbarButtons, null,
            ctx.controller.settings.isChecklistViewEnabled &&
                ctx.controller.flow.checklistStore.currentChecklists.length > 0 && (React.createElement(ToolbarButton, { id: "tools:checklists", icon: React.createElement(Checklist, null), keyboardShortcut: {
                    open: 'v',
                    disabled: isKeyboardShortcutsDisabled,
                }, tooltip: intl.t('realtime:checklistView.title', 'Checklist View') })),
            ctx.controller.settings.isAlertsEnabled && ctx.controller.settings.isAlertsUIEnabled && (React.createElement(ToolbarButton, { id: "tools:alerts", icon: React.createElement(IconNotifications, null), keyboardShortcut: {
                    open: 'd',
                    disabled: isKeyboardShortcutsDisabled,
                }, tooltip: intl.t('realtime:alerts', 'Detections') })),
            React.createElement(ToolbarButton, { id: "tools:pins", icon: React.createElement(PushPinIcon, null), keyboardShortcut: {
                    open: 'p',
                    disabled: isKeyboardShortcutsDisabled,
                }, tooltip: intl.t('realtime:pinsView.title', 'Pins') }),
            ctx.controller.settings.isCommentsEnabled && (React.createElement(ToolbarButton, { id: "tools:comments", icon: React.createElement(Base, { position: "relative", display: "flex", alignItems: "center", justifyContent: "center" },
                    ctx.controller.comments.totalCount > 0 && (React.createElement(CommentsCounter, { count: ctx.controller.comments.totalCount, className: css({
                            position: 'absolute',
                            top: '-10%',
                            left: '100%',
                            zIndex: 1,
                            transform: 'translateX(-50%)',
                        }) })),
                    React.createElement(IconChatBubble, null)), keyboardShortcut: {
                    open: 'c',
                    disabled: isKeyboardShortcutsDisabled,
                }, tooltip: intl.t('realtime:commentsView.title', 'Comments') })),
            ctx.controller.flow.stepStore.favoriteStep && (React.createElement(ToolbarButton, { id: `tools:favoriteStep`, key: ctx.controller.flow.stepStore.favoriteStep.id, icon: React.createElement(StarRounded, null), keyboardShortcut: {
                    open: 'q',
                    disabled: isKeyboardShortcutsDisabled,
                }, tooltip: ctx.controller.flow.stepStore.favoriteStep.node.view.name ||
                    intl.t('realtime:quickAccessNode', 'Quick access node'), onClick: () => {
                    trackerService.addAction('[Real Time] Favorite Node clicked');
                } })))));
});
