var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Button, Card, Separator, Typography } from 'lib/cortiUI';
export function ExceptionCard(props) {
    const theme = useTheme();
    const { text, actionText = '', color = 'error', action } = props, rest = __rest(props, ["text", "actionText", "color", "action"]);
    function getColors() {
        if (color === 'error') {
            return {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            };
        }
        else {
            return {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
            };
        }
    }
    function getText() {
        if (typeof text === 'string') {
            return (React.createElement(Typography, { ml: 3, variant: "caption", color: "inherit" }, text));
        }
        return text;
    }
    return (React.createElement(Card, Object.assign({ p: 2, className: css({
            backgroundColor: getColors().backgroundColor,
        }) }, rest),
        React.createElement(Box, { minHeight: 35, flexDirection: "row", alignItems: "center", justifyContent: "space-between", className: css({
                color: getColors().color,
            }) },
            getText(),
            action && (React.createElement(Box, { height: "100%", flexDirection: "row", alignItems: "center" },
                React.createElement(Separator, { mx: 2, height: 25, color: transparentize(0.03, theme.palette.background.divider) }),
                React.createElement(Button, { "data-cy": "exception-card-action-button", variant: "text", size: "small", onClick: action }, actionText))))));
}
