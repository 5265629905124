var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
export class ConnectedUsers {
    constructor(input) {
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.logger = input.logger;
        this.observer = new Observer();
        runInAction(() => {
            this.all = input.state.connectedUsers;
        });
    }
    reset(state) {
        if (state.connectedUsers) {
            this.all = state.connectedUsers;
            this.observer.fireEvent('users-changed', state.connectedUsers);
        }
    }
    // TODO: delete
    set(users) {
        this.all = users;
    }
    getByID(userID) {
        return this.all.find((it) => it.id === userID);
    }
    connect(user) {
        var _a;
        const connectedUser = this.getByID(user.id);
        if (connectedUser) {
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.warn('user already connected');
            return;
        }
        this.all.push(user);
        this.observer.fireEvent('user-connected', user);
    }
    disconnect(user) {
        var _a;
        const connectedUser = this.getByID(user.id);
        if (!connectedUser) {
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.warn('connected user not found');
            return;
        }
        const index = this.all.findIndex((it) => it.id === user.id);
        if (index > -1) {
            this.all.splice(index, 1);
        }
        this.observer.fireEvent('user-disconnected', user);
    }
    onUserConnect(cb) {
        return this.observer.on('user-connected', cb);
    }
    onUserDisconnect(cb) {
        return this.observer.on('user-disconnected', cb);
    }
    onUsersChange(cb) {
        return this.observer.on('users-changed', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], ConnectedUsers.prototype, "all", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ConnectedUsers.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ConnectedUsers.prototype, "set", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ConnectedUsers.prototype, "connect", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ConnectedUsers.prototype, "disconnect", null);
