import React from 'react';
import { useHistory } from 'react-router-dom';
/**
 * Hook to block navigation
 * Useful when there's unfinished form or unsaved changes
 */
export function useHistoryBlock(when, deps) {
    const history = useHistory();
    const unblockHandle = React.useRef();
    const [blockedHistory, setBlockedHistory] = React.useState(undefined);
    React.useEffect(() => {
        unblockHandle.current = history.block((location, action) => {
            const shouldBlock = when(location, action);
            if (shouldBlock) {
                setBlockedHistory({ location, action });
                return false;
            }
        });
        return () => {
            unblockHandle.current && unblockHandle.current();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    function handleProceed() {
        if (unblockHandle.current) {
            unblockHandle.current();
        }
        if (blockedHistory) {
            switch (blockedHistory.action) {
                case 'POP':
                    history.goBack();
                    break;
                case 'PUSH':
                    history.push(blockedHistory.location);
                    break;
                case 'REPLACE':
                    history.replace(blockedHistory.location);
                    break;
            }
            setBlockedHistory(undefined);
        }
    }
    function handleCancel() {
        setBlockedHistory(undefined);
    }
    return {
        isBlocked: !!blockedHistory,
        proceed: handleProceed,
        cancel: handleCancel,
    };
}
