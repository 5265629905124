var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { groupBy, orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, ButtonBase, Collapsable, LinearProgress, Typography, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { GenericPanel, GenericPanelContent, GenericPanelHeader, ListItem, useGraphEditorStorage, } from 'lib/graphEditor/ui';
import { BranchSmartTarget } from '../canvas/BranchSmartTarget';
import { NodeSmartTarget } from '../canvas/views';
import { ChecklistSmartTarget } from '../canvas/views/ChecklistSmartTarget';
import { getIssueDescriptor, useGetIssueMessage, } from '../validation';
import { ValidationResultSummaryText } from '../validation/view';
export const ValidationIssuesPanel = observer(function ValidationIssuesPanel(_props) {
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    const theme = useTheme();
    const getIssueMessage = useGetIssueMessage();
    const [collapsedMenus, setCollapsedMenus] = useGraphEditorStorage(new Set(), 'ValidationIssuesPanel.collapsedMenus');
    const { validation: validationModule } = editor;
    const validationResult = validationModule.result;
    function groupResults(result) {
        return orderBy(Object.entries(groupBy(result.issues, (it) => it.type)).map(([key, value]) => {
            return {
                issueType: key,
                issues: value,
            };
        }), (it) => getIssueDescriptor(it.issueType).severity);
    }
    const issueCountBySeverity = {
        warning: 0,
        error: 0,
    };
    validationResult === null || validationResult === void 0 ? void 0 : validationResult.issues.forEach((it) => issueCountBySeverity[getIssueDescriptor(it.type).severity]++);
    const groupedResults = validationResult ? groupResults(validationResult) : undefined;
    return (React.createElement(GenericPanel, null,
        React.createElement(GenericPanelHeader, { header: t('validationIssues.title', 'Graph Issues') }),
        React.createElement(GenericPanelContent, null,
            React.createElement(LinearProgress, { style: {
                    visibility: validationModule.isValidating ? 'visible' : 'hidden',
                }, variant: "indeterminate" }),
            React.createElement(Button, { className: css({ margin: 8 }), size: "small", onClick: () => {
                    void validationModule.validate();
                } }, t('validationIssues.validateBtn', 'Validate')),
            validationResult && (React.createElement(React.Fragment, null,
                React.createElement(Base, { mx: 5, mt: 3, mb: 5 },
                    React.createElement(ValidationResultSummaryText, { result: validationResult })),
                groupedResults && groupedResults.length > 0 && (React.createElement(Base, { display: 'grid', gridAutoFlow: 'row' }, groupedResults.map((group) => (React.createElement(Collapsable, { key: group.issueType, initialCollapsed: collapsedMenus.has(group.issueType), onCollapsedChange: ({ collapsed }) => {
                        if (collapsed) {
                            collapsedMenus.add(group.issueType);
                        }
                        else {
                            collapsedMenus.delete(group.issueType);
                        }
                        setCollapsedMenus(collapsedMenus);
                    } }, ({ getHeaderProps, getBodyProps, collapsed }) => {
                    const severity = getIssueDescriptor(group.issueType).severity;
                    const color = severity === 'error'
                        ? theme.palette.error.dark
                        : theme.palette.warning.dark;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(ListItem, Object.assign({}, getHeaderProps(), { display: "grid", gridGap: 1, gridTemplateColumns: `auto 1fr auto`, alignItems: "center", background: transparentize(0.9, color) }),
                            React.createElement(CollapseButton, { mr: 1, collapsed: collapsed, className: css({ color }) }),
                            React.createElement(Typography, { noWrap: true, variant: "subtitle2", color: severity === 'error' ? 'error' : 'warning' }, getIssueMessage(group.issueType)),
                            React.createElement(Typography, { variant: "subtitle2", color: severity === 'error' ? 'error' : 'warning', ml: 2 }, group.issues.length)),
                        React.createElement("div", Object.assign({}, getBodyProps(), { style: { overflow: 'hidden' } }), Object.entries(groupBy(group.issues, (it) => { var _a; return (_a = it.target) === null || _a === void 0 ? void 0 : _a.id; })).map(([_, issues], idx) => {
                            const target = issues[0].target;
                            return target ? (React.createElement(ListItem, { key: idx, display: 'flex', flexDirection: "row", justifyContent: 'space-between', pl: 20 },
                                React.createElement(TargetRenderer, { type: target.type, id: target.id }),
                                issues.length > 1 ? React.createElement("span", null, issues.length) : null)) : null;
                        }))));
                }))))))))));
});
function CollapseButton(_a) {
    var { collapsed, className } = _a, rest = __rest(_a, ["collapsed", "className"]);
    return (React.createElement(ButtonBase, Object.assign({}, rest, { className: css({ width: 8, height: 8, fontWeight: 'bold' }, className) }), collapsed ? '+' : '-'));
}
function TargetRenderer({ id, type }) {
    switch (type) {
        case 'branch':
            return React.createElement(BranchSmartTarget, { branchID: id });
        case 'node':
            return React.createElement(NodeSmartTarget, { nodeID: id });
        case 'checklist':
            return React.createElement(ChecklistSmartTarget, { id: id });
        default:
            return React.createElement("span", null, `[unknown type ${type}]`);
    }
}
