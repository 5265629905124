import React from 'react';
const initialState = {
    isScoringEnabled: false,
    templateID: '',
    setIsScoringEnabled: () => { },
};
const TemplateContext = React.createContext(initialState);
export const TemplateProvider = ({ templateID, children }) => {
    const [isScoringEnabled, setIsScoringEnabled] = React.useState(false);
    const value = React.useMemo(() => ({
        isScoringEnabled,
        templateID,
        setIsScoringEnabled,
    }), [isScoringEnabled, templateID]);
    return React.createElement(TemplateContext.Provider, { value: value }, children);
};
export const useTemplate = () => {
    const context = React.useContext(TemplateContext);
    if (!context) {
        throw new Error('useTemplate must be used within a TemplateProvider');
    }
    return context;
};
