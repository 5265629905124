import { observer } from 'mobx-react';
import React from 'react';
import { getBezierPath } from 'reactflow';
import { useTheme } from '@corti/theme';
export const DefaultEdgeWidget = observer(function DefaultEdgeWidget({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data, }) {
    const theme = useTheme();
    const model = data.model;
    const [edgePath] = getBezierPath({
        sourceX: sourceX,
        sourceY: sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    function getStroke() {
        if (model.isSelected) {
            return theme.palette.primary.main;
        }
        if (model.isHovered) {
            return theme.palette.action.outlinedHoverBorder;
        }
        return theme.palette.action.outlinedBorder;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("path", { id: id, style: Object.assign({ stroke: getStroke(), strokeWidth: 2, opacity: model.isSelected || model.isHovered ? 1 : 0.5 }, style), className: "react-flow__edge-path", d: edgePath, markerEnd: markerEnd }),
        React.createElement("path", { fill: "none", strokeOpacity: 0, strokeWidth: 20, className: "react-flow__edge-interaction", d: edgePath })));
});
