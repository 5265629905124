import React, { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { Box } from 'lib/cortiUI/components/Box';
export function WindowedList({ items, itemSize, ItemRenderer, animationDuration = 500, scrollPosition = 0, disableWindowing = false, }) {
    const OFFSET = 1;
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [scrollPositionState, setScrollPositionState] = useState(scrollPosition);
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const { width } = containerRef.current.getBoundingClientRect();
        setContainerWidth(width);
    }, []);
    useEffect(() => {
        setScrollPositionState(scrollPosition);
    }, [scrollPosition]);
    const { startIdx, endIdx } = useMemo(() => {
        if (disableWindowing) {
            return { startIdx: 0, endIdx: items.length - 1 };
        }
        const leftOffset = scrollPositionState;
        const rightOffset = scrollPositionState + containerWidth;
        const startIdx = Math.max(0, Math.floor(leftOffset / (itemSize === 0 ? 1 : itemSize)) - OFFSET);
        const endIdx = Math.ceil(rightOffset / (itemSize === 0 ? 1 : itemSize)) - 1 + OFFSET;
        return { startIdx, endIdx };
    }, [containerWidth, disableWindowing, itemSize, items.length, scrollPositionState]);
    const padding = startIdx * itemSize;
    const itemsToRender = useMemo(() => items.slice(startIdx, endIdx + 1), [endIdx, items, startIdx]);
    return (React.createElement(Base, { ref: containerRef, width: "100%", height: "100%", overflowX: "hidden", overflowY: "hidden", position: "relative" },
        React.createElement(Box, { flexDirection: "row", height: "100%", top: 0, position: "absolute", className: css({
                transition: `${animationDuration}ms ease`,
                transform: `translateX(${-scrollPositionState}px)`,
            }) },
            React.createElement(Base, { height: "100%", width: padding }),
            itemsToRender.map((item) => {
                const origIndex = items.findIndex((i) => i.id === item.id);
                return (React.createElement(Box, { key: item.id, height: "100%", width: itemSize },
                    React.createElement(ItemRenderer, { item: item, index: origIndex })));
            }))));
}
