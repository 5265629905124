var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { ExpressionEvaluator } from '../utils';
const PENDING_TIMEOUT = 3000;
export class ActionStore {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_pendingActions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._pendingActions = new Map();
            }
        });
        makeObservable(this);
        this.observer = new Observer();
        runInAction(() => {
            this._pendingActions = new Map();
        });
    }
    trigger(input) {
        this.addToPendingStack(input.blockPrototype);
        this.observer.fireEvent('triggered', input);
    }
    addToPendingStack(blockProto) {
        const target = this._pendingActions.get(blockProto.id);
        if (target)
            return;
        this._pendingActions.set(blockProto.id, {
            blockProto: blockProto,
            timeoutRef: window.setTimeout(() => {
                this.removeFromPendingStack(blockProto.id);
            }, PENDING_TIMEOUT),
        });
    }
    removeFromPendingStack(blockProtoID) {
        const target = this._pendingActions.get(blockProtoID);
        if (!target)
            return;
        window.clearTimeout(target.timeoutRef);
        this._pendingActions.delete(blockProtoID);
    }
    isPending(blockProtoID) {
        return !!this._pendingActions.get(blockProtoID);
    }
    isActivated(blockProtoID) {
        return this.activatedActions.some((action) => action.id === blockProtoID);
    }
    get pendingActions() {
        return Array.from(this._pendingActions.values()).map((it) => it.blockProto);
    }
    get activatedActions() {
        const environment = ExpressionEvaluator.createEnvFromFlowValues({
            // The `blocks` value should not be empty
            // however, having a dependency on block values negatively affects the performance
            // so this is removed as a TEMP FIX
            // since we know that block values are not used in action trigger expressions (as they never were allowed before)
            blockValues: [],
            factValues: this.flowStore.factValueStore.all,
            graph: this.flowStore.graphTraverser,
        });
        return this.flowStore.graphTraverser
            .getBlockProtos({
            properties: { type: 'ACTION' },
        })
            .filter((it) => {
            const { triggeredStateExpression: expression, id } = it;
            if (!expression)
                return;
            const result = ExpressionEvaluator.evaluate({
                expression,
                environment,
            });
            if (result === true) {
                // FIX THIS: this call to change some state should not be in the getter function
                this.removeFromPendingStack(id);
            }
            return result;
        });
    }
    onActionBlockTriggered(cb) {
        return this.observer.on('triggered', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], ActionStore.prototype, "_pendingActions", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], ActionStore.prototype, "resetAll", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ActionStore.prototype, "addToPendingStack", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ActionStore.prototype, "removeFromPendingStack", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ActionStore.prototype, "pendingActions", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ActionStore.prototype, "activatedActions", null);
