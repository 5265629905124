var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { coreRealtimeClient } from 'browser/services/init';
export class UserPresence {
    constructor() {
        Object.defineProperty(this, "onlineUserIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "handleUsersChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (userIDs) => {
                this.onlineUserIDs = new Set(userIDs);
            }
        });
        makeObservable(this);
        this.reset();
        coreRealtimeClient.onUsersChanged(this.handleUsersChanged);
    }
    reset() {
        this.onlineUserIDs = new Set();
    }
    isOnline(id) {
        return this.onlineUserIDs.has(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
], UserPresence.prototype, "onlineUserIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserPresence.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Object)
], UserPresence.prototype, "handleUsersChanged", void 0);
