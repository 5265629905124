import React from 'react';
/**
 * Monitors if the document size matches or stops matching the media query.
 * Returns the state of the given media query.
 */
export const useMediaQuery = (mediaQuery) => {
    const [hasMatched, setHasMatched] = React.useState(!!window.matchMedia(mediaQuery).matches);
    React.useEffect(() => {
        const queries = window.matchMedia(mediaQuery);
        const handler = () => {
            setHasMatched(!!queries.matches);
        };
        queries.addListener(handler);
        handler();
        return () => {
            queries.removeListener(handler);
        };
    }, [mediaQuery]);
    return hasMatched;
};
