import { AppShellStore } from './AppShellStore';
/**
 * Since we're not doing SSR, it's not bad for us to use service locator pattern, instead of DI
 */
export let coreStore;
export function init(rootStore) {
    coreStore = {
        notifications: rootStore.notificationStore,
        remoteRequest: rootStore.remoteRequestStore,
        appShell: new AppShellStore(),
    };
}
