import React from 'react';
export const useDragAndDrop = ({ onDrop }) => {
    const [isDragging, setIsDragging] = React.useState(false);
    const dragRef = React.useRef(null);
    React.useEffect(() => {
        var _a, _b, _c, _d;
        (_a = dragRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('dragover', handleDragOver);
        (_b = dragRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('drop', handleDrop);
        (_c = dragRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('dragenter', handleDragEnter);
        (_d = dragRef.current) === null || _d === void 0 ? void 0 : _d.addEventListener('dragleave', handleDragLeave);
        return () => {
            var _a, _b, _c, _d;
            (_a = dragRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('dragover', handleDragOver);
            (_b = dragRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('drop', handleDrop);
            (_c = dragRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('dragenter', handleDragEnter);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (_d = dragRef.current) === null || _d === void 0 ? void 0 : _d.removeEventListener('dragleave', handleDragLeave);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handlePreventDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = (e) => {
        handlePreventDefault(e);
    };
    const handleDragEnter = (e) => {
        handlePreventDefault(e);
        if (e.target !== dragRef.current) {
            setIsDragging(true);
        }
    };
    const handleDragLeave = (e) => {
        handlePreventDefault(e);
        if (e.target === dragRef.current) {
            setIsDragging(false);
        }
    };
    const handleDrop = async (e) => {
        handlePreventDefault(e);
        await onDrop(e);
        setIsDragging(false);
    };
    return { isDragging, dragRef };
};
