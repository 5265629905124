import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Box, FallbackView, FocusContext } from 'lib/cortiUI';
import { Step } from 'lib/triageFlow';
import { useTriageSession } from '../TriageSessionContext';
export const PinnedStep = observer(({ stepID }) => {
    const ctx = useTriageSession();
    const focusController = React.useRef(null);
    const [stepModel, setStepModel] = React.useState(null);
    React.useEffect(() => {
        const step = ctx.controller.flow.stepStore.pinnedSteps.find((s) => {
            return s.id === stepID;
        });
        if (step) {
            setStepModel(step);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepID]);
    React.useEffect(() => {
        var _a;
        (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
    }, [stepModel]);
    if (!stepModel) {
        return (React.createElement(Box, { alignItems: "center", justifyContent: "center", className: css({
                minHeight: 200,
            }) },
            React.createElement(FallbackView, { title: intl.t('realtime:triageFlowPinnedStepNotFound', 'Pinned node was not found') })));
    }
    return (React.createElement(FocusContext, { controllerRef: focusController, disableKeyboardShortcuts: !ctx.controller.isCurrentUserOwner, keyboardShortcuts: {
            triggerFocusedElement: 'enter',
        } }, ({ registerKeyboardEventBindingElement }) => (React.createElement(Base, { ref: registerKeyboardEventBindingElement, height: "100%" },
        React.createElement(Step, { shouldAutoProgress: false, flowStore: ctx.controller.flow, stepModel: stepModel, isReadonly: !ctx.controller.isCurrentUserOwner })))));
});
