var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SubtitlesRoundedIcon from '@mui/icons-material/SubtitlesRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { Base, Box, Drawer, Tooltip, Typography } from 'lib/cortiUI';
import { CallTranscriptView } from './CallTranscriptView';
import { CaseFeedbackView } from './CaseFeedbackView';
import { CaseTimeline } from './CaseTimeline';
import { useCaseViewContext } from './CaseViewContext';
import { PerformanceMetricsView } from './PerformanceMetrics';
function SideToolbarTab(props) {
    const { icon, title, disabled, isActive, tooltip = '' } = props, rest = __rest(props, ["icon", "title", "disabled", "isActive", "tooltip"]);
    const theme = useTheme();
    return (React.createElement(Tooltip, { title: tooltip },
        React.createElement(Base, null,
            React.createElement(Box, Object.assign({ justifyContent: "center", alignItems: "center", width: 1, py: 4, px: 3, className: css({
                    cursor: 'pointer',
                    opacity: disabled ? 0.5 : undefined,
                    pointerEvents: disabled ? 'none' : undefined,
                    minHeight: 70,
                    outline: 'none',
                    '&:hover': {
                        background: theme.palette.action.hover,
                    },
                }) }, rest),
                icon,
                title && (React.createElement(Typography, { mt: 2, variant: "footnote", color: isActive ? 'primary' : 'hint', uppercase: true }, title))))));
}
export function SideToolbar(props) {
    const { className } = props;
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    const [readyToRender, setReadyToRender] = React.useState(false);
    React.useEffect(() => {
        if (caseViewState.activeSidebarView) {
            setReadyToRender(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toggleDrawer = (view) => {
        caseViewState.activeSidebarView === view ? closeDrawer() : setActiveSidebarView(view);
    };
    const setActiveSidebarView = (view) => {
        caseViewState.setActiveSidebarView(view);
        trackerService.track(`[Single case] ${view} sidebar opened`);
    };
    const closeDrawer = () => {
        caseViewState.setActiveSidebarView();
    };
    const renderView = () => {
        switch (caseViewState.activeSidebarView) {
            case 'timeline': {
                return React.createElement(CaseTimeline, null);
            }
            case 'feedback': {
                return React.createElement(CaseFeedbackView, null);
            }
            case 'score': {
                return React.createElement(PerformanceMetricsView, null);
            }
            case 'transcript': {
                return React.createElement(CallTranscriptView, null);
            }
        }
    };
    return (React.createElement(Observer, null, () => {
        const { activeSidebarView, caseEntity } = caseViewState;
        return (React.createElement(Base, { height: '100%', className: css({
                position: 'relative',
            }, className) },
            React.createElement(Base, { height: '100%', minWidth: 76, className: css({
                    display: 'grid',
                    gridTemplateRows: '1fr auto 1fr',
                    gridTemplateAreas: `"." "tabs" "."`,
                    borderLeft: `1px solid ${theme.palette.background.divider}`,
                    backgroundColor: theme.palette.background.secondary,
                    zIndex: theme.zIndex.appBar,
                    position: 'relative',
                }) },
                React.createElement(Base, { className: css({
                        display: 'grid',
                        gridArea: 'tabs',
                        gridTemplateRows: 'auto',
                    }) },
                    React.createElement(SideToolbarTab, { "data-cy": "timeline-btn", icon: React.createElement(FormatListBulletedRoundedIcon, { className: css({
                                color: activeSidebarView === 'timeline'
                                    ? theme.palette.primary.main
                                    : theme.palette.text.hint,
                            }) }), title: intl.t('explore:caseView.timeline', 'Timeline'), isActive: activeSidebarView === 'timeline', onClick: () => toggleDrawer('timeline') }),
                    caseEntity &&
                        rbacService.feedbackSubmissionRead({
                            caseOwnerID: caseEntity.createdBy.id,
                        }) && (React.createElement(SideToolbarTab, { "data-cy": "feedback-btn", icon: React.createElement(InsertDriveFileOutlinedIcon, { className: css({
                                color: activeSidebarView === 'feedback'
                                    ? theme.palette.primary.main
                                    : theme.palette.text.hint,
                            }) }), title: intl.t('explore:caseView.review', 'Review'), isActive: activeSidebarView === 'feedback', onClick: () => toggleDrawer('feedback') })),
                    rbacService.performanceViewsRead() && (React.createElement(SideToolbarTab, { "data-cy": "detections-btn", icon: React.createElement(BarChartRoundedIcon, { className: css({
                                color: activeSidebarView === 'score'
                                    ? theme.palette.primary.main
                                    : theme.palette.text.hint,
                            }) }), title: intl.t('explore:callPerformanceView.detections', 'Detections'), isActive: activeSidebarView === 'score', disabled: caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.isActiveCallExcluded, tooltip: (caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.isActiveCallExcluded)
                            ? intl.t('explore:callPerformanceView.unavailableDetectionsWarning', 'Detections are unavailable for this call. Enable performance tracking to see detections.')
                            : '', onClick: () => toggleDrawer('score') })),
                    rbacService.caseTranscriptRead() && (React.createElement(SideToolbarTab, { "data-cy": "transcript-btn", icon: React.createElement(SubtitlesRoundedIcon, { className: css({
                                color: activeSidebarView === 'transcript'
                                    ? theme.palette.primary.main
                                    : theme.palette.text.hint,
                            }) }), title: intl.t('explore:caseView.transcript', 'Transcript'), isActive: activeSidebarView === 'transcript', onClick: () => toggleDrawer('transcript') })))),
            React.createElement(Drawer, { anchor: "right", open: Boolean(activeSidebarView), variant: "persistent", SlideProps: {
                    mountOnEnter: true,
                    unmountOnExit: true,
                    timeout: 200,
                    onEntered: () => setReadyToRender(true),
                    onExit: () => setReadyToRender(false),
                }, classes: {
                    docked: css({
                        transform: 'translateX(-100%)',
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                    }),
                    paper: css({
                        zIndex: theme.zIndex.appBar - 1,
                        backgroundColor: theme.palette.background.secondary,
                        overflow: 'hidden',
                        width: 400,
                    }),
                } },
                React.createElement(Box, { my: 8, overflow: "hidden" }, readyToRender && renderView()))));
    }));
}
