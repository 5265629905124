import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { IconButton, Modal } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { useParametersContext } from '../../context/ParametersContext';
import { ViewSettingsModalContent } from './ViewSettingsModalContent/ViewSettingsModalContent';
export const SettingsButton = () => {
    const { currentUserID } = useAuth();
    const { isDraft } = useGlobalContext();
    const { title, locked, visibility, createdBy, changeTitle, changeLocked, changeVisibility, deletePerformanceView, } = useParametersContext();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleSave = (view) => {
        setIsModalOpen(false);
        changeTitle(view.title);
        changeLocked(view.locked);
        changeVisibility(view.visibility);
    };
    const handleDelete = () => {
        deletePerformanceView();
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "data-cy": "performance-view-settings-btn", size: "small", onClick: () => setIsModalOpen(true), icon: React.createElement(SettingsIcon, { color: "action" }) }),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false), size: "small" },
            React.createElement(ViewSettingsModalContent, { isDraft: isDraft, view: { title, visibility, locked }, onSave: handleSave, onDelete: handleDelete, isAdvancedSettingsEnabled: currentUserID === (createdBy === null || createdBy === void 0 ? void 0 : createdBy.id), creatorName: createdBy === null || createdBy === void 0 ? void 0 : createdBy.name }))));
};
