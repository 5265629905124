import { gql } from '@apollo/client';
const NotificationFragment = gql `
  fragment NotificationFragment on Notification {
    id
    type
    data {
      case {
        id
        customProperties
        createdBy {
          name
        }
      }
      caseReviewerName
      caseReviewRequesterName
      feedbackFormSubmissionID
      suggestedFeedbackFormTemplateID
    }
    user {
      id
      name
    }
    createdAt
  }
`;
export const notificationService = (client) => ({
    async getNotifications(input) {
        const res = await client.query({
            query: gql `
        query getNotifications($userID: ID!, $cursor: String, $first: Int) {
          notifications(userID: $userID, cursor: $cursor, first: $first) {
            cursor
            hasNextPage
            items {
              ...NotificationFragment
            }
          }
        }
        ${NotificationFragment}
      `,
            variables: Object.assign({}, input),
            fetchPolicy: 'no-cache',
        });
        return res.data.notifications;
    },
    async markNotificationDone(id) {
        await client.mutate({
            mutation: gql `
        mutation markNotificationDone($id: String!) {
          markNotificationDone(id: $id)
        }
      `,
            variables: {
                id,
            },
        });
    },
});
