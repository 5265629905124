import React from 'react';
import { intl } from '@corti/i18n';
import { ChipV2 } from 'lib/cortiUI';
import { Actions } from './Actions';
export function getColumns() {
    return [
        {
            field: 'user',
            headerName: intl.t('teams:teamView.table.user', 'Call-Taker'),
            editable: false,
            flex: 1,
        },
        {
            field: 'archived',
            headerName: '',
            editable: false,
            renderCell: ({ value }) => {
                return value ? React.createElement(ChipV2, { label: value }) : null;
            },
        },
        {
            field: 'role',
            headerName: '',
            editable: false,
            minWidth: 110,
            renderCell: ({ value }) => {
                return value ? React.createElement(ChipV2, { label: value }) : null;
            },
        },
        {
            field: 'actionToolbar',
            headerName: '',
            editable: false,
            sortable: false,
            renderCell: ({ value }) => {
                return value ? React.createElement(Actions, { member: value }) : null;
            },
        },
    ];
}
