/**
 * Since we're not doing any SSR, this is a convenience module
 * that re-exports instantiated services
 *
 * As for testing, you can use import mocks to replace services with different implementations
 */
import { api, init as initCoreApiService } from '@corti/lib/coreApiService';
import { CoreRealtimeClient } from '@corti/lib/coreRealtimeClient';
import { config } from 'core/configuration/browser';
import { RbacService } from 'core/permissions/Rbac.service';
import { ErrorReportService } from './ErrorReport.service';
import { KeybindingsRegistry } from './KeybindingsRegistry';
import { SatismeterService } from './Satismeter.service';
import { TrackerService } from './Tracker.service';
import { ZendeskWidgetService } from './zendeskWidget.service';
export let errorReportService;
// `apolloClient` should ideally be not exposed
export let apolloClient;
export let trackerService;
export let rbacService;
export let zendeskWidgetService;
export let keybindingsRegistry;
export let coreRealtimeClient;
export let satismeterService;
// This is initiated and set outside of this module, that's why it has a separate setter function
export let swWrapper;
export function setSwWrapperService(service) {
    if (swWrapper != null) {
        throw new Error('swWrapper already initiated');
    }
    swWrapper = service;
}
export const services = {
    get config() {
        return config;
    },
    get errorReportService() {
        return errorReportService;
    },
    get apiService() {
        return api;
    },
    get trackerService() {
        return trackerService;
    },
    get rbacService() {
        return rbacService;
    },
    get zendeskWidgetService() {
        return zendeskWidgetService;
    },
    get keybindingsRegistry() {
        return keybindingsRegistry;
    },
    get coreRealtimeClient() {
        return coreRealtimeClient;
    },
    get satismeterService() {
        return satismeterService;
    },
    get swWrapper() {
        return swWrapper;
    },
};
window.__cortiServices__ = services;
export async function init() {
    initCoreApiService({ baseUrl: config.getConfig().apiHost });
    // Error reporting service should be initialized as early as possible
    errorReportService = new ErrorReportService();
    await errorReportService.init();
    trackerService = new TrackerService(config.getConfig()['externalServices:segment:key'], {
        applicationId: config.getConfig()['externalServices:datadog:applicationId'],
        clientToken: config.getConfig()['externalServices:datadog:clientToken'],
    });
    rbacService = new RbacService();
    zendeskWidgetService = new ZendeskWidgetService(config.getConfig()['externalServices:zendesk:key']);
    satismeterService = new SatismeterService();
    keybindingsRegistry = new KeybindingsRegistry();
    apolloClient = api.client;
    coreRealtimeClient = new CoreRealtimeClient({
        minReconnectionDelay: config.getConfig().apiReconnectInterval,
        maxReconnectionDelay: config.getConfig().apiRealtimeMaxReconnectionDelay,
        connectionTimeout: config.getConfig().apiRealtimeConnectionTimeout,
        pongTimeout: config.getConfig().apiRealtimePongTimeout,
        healthcheckInterval: config.getConfig().apiRealtimeHealthcheckInterval,
    });
}
