import { subDays } from 'date-fns';
import { Dexie } from '@corti/lib/indexedDB/dexie';
import { RealTimeUserDB } from './RealTimeUserDB';
const userDbPrefix = `RealtimeAppUserDB_`;
const getUserDBName = (userID) => `${userDbPrefix}${userID}`;
const isRealtimeAppUserDB = (dbName) => dbName.startsWith(userDbPrefix);
const getUserIDFromDBName = (dbName) => dbName.split(userDbPrefix)[1];
/**
 * This is a "global" realtime app database that tracks status of user scoped databases
 */
export class RealTimeDB {
    constructor() {
        Object.defineProperty(this, "dexie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "perUserDBsTable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // keep track of all user-scoped dbs
        this.dexie = new Dexie('RealtimeAppDB_global');
        this.dexie.version(1).stores({
            perUserDBs: 'userID, lastUsedAt',
        });
        this.perUserDBsTable = this.dexie.table('perUserDBs');
    }
    async open() {
        await this.dexie.open();
    }
    close() {
        this.dexie.close();
    }
    /** Clears the global database and **deletes** user databases */
    async clear() {
        const dbs = await this.perUserDBsTable.toArray();
        await this.deleteUserDBs(dbs.map((d) => d.userID));
        await this.perUserDBsTable.clear();
    }
    async openDBForUser(userID) {
        await this.dexie.open();
        const dbName = getUserDBName(userID);
        const db = new RealTimeUserDB(dbName);
        await db.open();
        // add meta info about db usage
        await this.perUserDBsTable.put({
            userID,
            lastUsedAt: new Date().toISOString(),
        });
        return db;
    }
    /** Fixes global database tables and user databases in case they get out of sync */
    async fix() {
        await Promise.all([await this.fix_deleteOrphanUserDBs(), await this.fix_deleteOrphanRows()]);
    }
    async fix_deleteOrphanUserDBs() {
        const userIDs = (await Dexie.getDatabaseNames())
            .filter(isRealtimeAppUserDB)
            .map((dbName) => getUserIDFromDBName(dbName))
            .filter((userID) => userID != null);
        const allUserIDsInDB = new Set((await this.perUserDBsTable.toArray()).map((it) => it.userID));
        const dbsToDelete = userIDs.filter((id) => !allUserIDsInDB.has(id));
        await Promise.all([dbsToDelete.map((entry) => this._internalDeleteJustTheUserDB(entry))]);
    }
    async fix_deleteOrphanRows() {
        const allDBs = await this.perUserDBsTable.toArray();
        for (const dbEntry of allDBs) {
            const exists = await Dexie.exists(getUserDBName(dbEntry.userID));
            if (!exists) {
                await this.perUserDBsTable.delete(dbEntry.userID);
            }
        }
    }
    /** Convenenience higher level method to delete databases based on when they were last used */
    async deleteUserDBsOlderThan(olderThan) {
        const datesBelowTTL = subDays(Date.now(), olderThan.days);
        const allUserDBs = await this.perUserDBsTable
            .where('lastUsedAt')
            .below(datesBelowTTL.toISOString())
            .toArray();
        await this.deleteUserDBs(allUserDBs.map((d) => d.userID));
        return { deletedCount: allUserDBs.length };
    }
    async deleteUserDBs(userIDs) {
        await Promise.all(userIDs.map((uid) => this.deleteUserDB(uid)));
    }
    async deleteUserDB(userID) {
        return Promise.all([
            this.perUserDBsTable.delete(userID),
            this._internalDeleteJustTheUserDB(userID),
        ]);
    }
    // deletes just the user db, but the "meta" info in the global db is not deleted
    async _internalDeleteJustTheUserDB(userID) {
        const dbName = getUserDBName(userID);
        const exists = await Dexie.exists(dbName);
        if (!exists) {
            return;
        }
        return Dexie.delete(dbName);
    }
}
