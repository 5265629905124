import { createBrowserHistory, createHashHistory } from 'history';
import { isElectron } from '@corti/electron-utils';
import { moduleRegistry } from 'core/moduleRegistry/browser';
import { trackerService } from './services/init';
const options = {
    getUserConfirmation: (message) => {
        // Use custom logic to show confirmation dialog when navigation is blocked by some Prompt component
        // for now we're just invoking alert and do not let user choose to continue (default is window.confirm)
        return window.alert(message);
    },
};
/**
 * history object can be used to navigate the app outside of react component context
 */
export const history = isElectron() ? createHashHistory(options) : createBrowserHistory(options);
const _push = history.push;
function push(p1, p2) {
    var _a;
    const path = typeof p1 === 'string' ? p1 : (_a = p1.pathname) !== null && _a !== void 0 ? _a : history.location.pathname + p1.search;
    if (path === history.location.pathname + history.location.search) {
        if (typeof p1 === 'string') {
            history.replace(p1, p2);
        }
        else {
            history.replace(p1);
        }
        return;
    }
    if (typeof p1 === 'string') {
        // @ts-expect-error - don't know how to fix this overload
        _push.call(history, p1, p2);
    }
    else {
        _push.call(history, p1);
    }
}
// Override `push` behaviour to use `replace` when navigating to the exact same location
history.push = push;
history.listen(() => {
    if (isElectron()) {
        const pageName = window.location.hash.replace('#', '');
        trackerService.trackPageVisit(pageName);
    }
    else {
        trackerService.trackPageVisit();
    }
});
export const navigateToApp = (appName) => {
    const mod = moduleRegistry.getModule(appName);
    if (!mod) {
        return;
    }
    if (history.location.pathname.includes(mod.path.replace('/', ''))) {
        return;
    }
    history.push(mod.path);
};
export function initNavigationApi(publicApi) {
    publicApi.exposeMethod({
        name: '/router/push',
        handler: ({ path }) => {
            history.push(path);
        },
        paramsJSONSchema: {
            type: 'object',
            required: ['path'],
            properties: {
                path: { type: 'string' },
            },
        },
    });
    publicApi.exposeMethod({
        name: '/router/replace',
        handler: ({ path }) => {
            history.replace(path);
        },
        paramsJSONSchema: {
            type: 'object',
            required: ['path'],
            properties: {
                path: { type: 'string' },
            },
        },
    });
    publicApi.exposeMethod({
        name: '/app/changeApp',
        handler: ({ app }) => {
            navigateToApp(app);
        },
        paramsJSONSchema: {
            type: 'object',
            required: ['app'],
            properties: {
                app: { type: 'string' },
            },
        },
    });
}
