var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
/**
 * This component is only line to separate other components
 */
export const Separator = (props) => {
    const { thickness, color, className, horizontal } = props, rest = __rest(props, ["thickness", "color", "className", "horizontal"]);
    const theme = useTheme();
    return (React.createElement(Base, Object.assign({}, rest),
        React.createElement("div", { className: css({
                width: horizontal ? '100%' : thickness ? thickness : 1,
                height: horizontal ? (thickness ? thickness : 1) : '100%',
                background: color ? color : theme.palette.background.divider,
            }, className) })));
};
