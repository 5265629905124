export function initRemoteRequestPublicApi(publicapi, remoteRequestStore) {
    publicapi.exposeMethod({
        name: '/app/changeApiHost',
        paramsJSONSchema: {
            type: 'object',
            required: ['apiHost'],
            properties: {
                apiHost: { type: 'string' },
            },
        },
        handler: ({ apiHost }) => {
            remoteRequestStore.changeApiHost(apiHost);
            if (false) {
                return { code: 102, message: '102' };
            }
            return { code: 101, message: '101' };
        },
    });
}
