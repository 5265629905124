import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { getInteractionByCallID } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { FallbackView } from 'lib/cortiUI';
import { getCaseCallValidationQuery, } from './graphql';
export const RedirectingToNewSCV = () => {
    const { t } = useTranslation('explore', { keyPrefix: 'redirectingToNewSCV' });
    const { params: { caseID }, } = useRouteMatch();
    const history = useHistory();
    const [isInteractionLoading, setIsInteractionLoading] = React.useState(false);
    const { data, loading } = useQuery(getCaseCallValidationQuery, {
        variables: {
            id: caseID,
        },
    });
    React.useEffect(() => {
        if (!(data === null || data === void 0 ? void 0 : data.case))
            return;
        // It is product/design decision to show only first call if it exists
        const call = (data === null || data === void 0 ? void 0 : data.case.calls.length) ? data.case.calls[0] : undefined;
        if (!call)
            return;
        void fetchInteraction(call.callServiceCallId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data === null || data === void 0 ? void 0 : data.case]);
    if (!caseID) {
        throw Error('`caseID` must be provided as Route parameters to use `Case View` component ');
    }
    async function fetchInteraction(callID) {
        try {
            setIsInteractionLoading(true);
            const res = await getInteractionByCallID(callID);
            history.replace(`interaction/${res.id}`);
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchInteractionsError', 'Failed to fetch interactions. Please try again later.'),
            });
        }
        finally {
            setIsInteractionLoading(false);
        }
    }
    if (loading || isInteractionLoading) {
        // Request is really fast, so trying to avoid flushing
        return null;
    }
    if (!(data === null || data === void 0 ? void 0 : data.case)) {
        return React.createElement(FallbackView, { title: t('noCaseData', 'Could not load case') });
    }
    if (!(data === null || data === void 0 ? void 0 : data.case.calls.length)) {
        return React.createElement(FallbackView, { title: t('noCallData', 'Could not load call') });
    }
    return null;
};
