import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { InfiniteLoader } from 'react-virtualized';
import { css, darken, lighten } from '@corti/style';
import { useTheme } from '@corti/theme';
import { DefaultGhostRowRenderer, DefaultHeaderCellRenderer, DefaultRowRenderer, Table, TableColumn, } from 'lib/cortiUI';
import { CasesTableCellRenderer } from './CasesTableCellRenderer';
const THRESHOLD_ROW_COUNT = 15;
function CasesTableRaw({ casesViewState, rows, columns, history, match }) {
    const tableRef = useRef(null);
    const scrollTopRef = useRef(0);
    const theme = useTheme();
    const [rowsCount, setRowsCount] = React.useState(rows.length);
    const [ghostRowsCount, setGhostRowsCount] = React.useState(0);
    const [isLoadingRows, setIsLoadingRows] = React.useState(false);
    useEffect(() => {
        const { casesTableScrollPosition, saveCasesTableScrollPosition } = casesViewState;
        scrollTopRef.current = casesTableScrollPosition;
        if (tableRef.current) {
            tableRef.current.scrollToPosition(casesTableScrollPosition);
        }
        return () => saveCasesTableScrollPosition(scrollTopRef.current);
    }, [casesViewState]);
    useEffect(() => {
        setRowsCount(rows.length);
    }, [rows]);
    const loadMoreRows = async () => {
        const { hasNextPage, loadNextPage } = casesViewState;
        if ((ghostRowsCount === 0 || !isLoadingRows) && hasNextPage) {
            try {
                setIsLoadingRows(true);
                await loadNextPage();
            }
            catch (_a) {
            }
            finally {
                setIsLoadingRows(false);
                setGhostRowsCount(0);
            }
        }
    };
    const isRowLoaded = (params) => (params.index < rows.length ? true : false);
    const totalRowsCount = rowsCount + ghostRowsCount;
    const onScroll = (params) => {
        const { hasNextPage } = casesViewState;
        const { scrollTop, scrollHeight, clientHeight } = params;
        const tableHeight = scrollTop + clientHeight;
        scrollTopRef.current = scrollTop;
        if (tableHeight >= scrollHeight && hasNextPage) {
            setGhostRowsCount((prev) => prev + 1);
        }
    };
    const onRowClick = (caseID) => {
        history.push(`${match.path}/${caseID}`);
    };
    const getCaseIdByRowIndex = (index) => {
        const { cases } = casesViewState;
        return cases[index].id;
    };
    const onColumnResizeEnd = (columns) => {
        const { casesTableEditorState } = casesViewState;
        void casesTableEditorState.updateTableColumns(columns);
    };
    const getCasesStyles = (caseID) => {
        if (casesViewState.isCaseVisited(caseID)) {
            return theme.palette.mode === 'light'
                ? {
                    backgroundColor: darken(0.03, theme.palette.background.default),
                }
                : {
                    backgroundColor: lighten(0.03, theme.palette.background.default),
                };
        }
    };
    return (React.createElement(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: totalRowsCount + 1, threshold: THRESHOLD_ROW_COUNT }, ({ onRowsRendered, registerChild }) => {
        return (React.createElement(Table, { rows: rows, columns: columns, disableSort: true, onColumnResizeEnd: onColumnResizeEnd, onScroll: onScroll, rowCount: totalRowsCount, onRowsRendered: onRowsRendered, rowRenderer: (props) => {
                return rows[props.index] ? (React.createElement(DefaultRowRenderer, { "data-cy": "cases-table-row", key: props.index, requiredProps: props, onClick: () => onRowClick(getCaseIdByRowIndex(props.index)), className: css({
                        cursor: 'pointer',
                    }, Object.assign({}, getCasesStyles(getCaseIdByRowIndex(props.index)))) })) : (React.createElement(DefaultGhostRowRenderer, { key: props.index, requiredProps: props }));
            }, innerRef: (ref) => {
                tableRef.current = ref;
                registerChild(ref);
            } }, ({ columns }) => columns.map((col, idx) => (React.createElement(TableColumn, { key: idx, label: col.label, dataKey: col.id, width: col.width, cellRenderer: (props) => React.createElement(CasesTableCellRenderer, Object.assign({ rowId: col.id }, props)), headerRenderer: (props) => (React.createElement(DefaultHeaderCellRenderer, { "data-cy": "cases-table-header-cell", "data-cy-value": props.dataKey, requiredProps: props })) })))));
    }));
}
export const CasesTable = withRouter(CasesTableRaw);
