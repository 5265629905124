var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { findLast, hasIn, throttle } from 'lodash';
import Mousetrap from 'mousetrap';
import React from 'react';
import { Formatters } from '@corti/strings';
import { grayscale, invert, transparentize } from '@corti/style';
import { withTheme } from '@corti/theme';
import { WaveSurferWrapper } from 'browser/components/WaveSurfer';
import { CursorPlugin, MinimapPlugin, TimelinePlugin } from 'browser/components/WaveSurfer/plugins';
import { observer } from 'browser/stores';
import { authStore } from 'core/auth';
import { CallPlayerState } from '../CallPlayerState';
import { CaseViewContext } from '../CaseViewContext';
import { TimelineEntriesPlugin } from './TimelineEntriesPlugin';
// @ts-ignore
let Waveform = class Waveform extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "onReady", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ duration }) => {
                const { setDuration, setIsPlaying, setIsLoading } = this.props.callPlayerState;
                setDuration(duration);
                setIsPlaying(this.props.autoplay);
                setIsLoading(false);
                this.props.onAudioReady && this.props.onAudioReady({ duration });
            }
        });
        Object.defineProperty(this, "onFinish", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { setIsPlaying } = this.props.callPlayerState;
                setIsPlaying(false);
            }
        });
        Object.defineProperty(this, "onError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (err) => {
                const { setError } = this.props.callPlayerState;
                setError(err);
            }
        });
        Object.defineProperty(this, "bindShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const player = this.props.callPlayerState;
                // Play/pause
                Mousetrap.bind('space', (e) => {
                    e.preventDefault();
                    if (hasIn(document, ['activeElement', 'blur'])) {
                        // @ts-ignore
                        document.activeElement.blur();
                    }
                    player.togglePlay();
                });
                // Jump forward
                Mousetrap.bind('right', (e) => {
                    e.preventDefault();
                    player.setCurrentTime(player.currentTime + 1);
                });
                // Jump backward
                Mousetrap.bind('left', (e) => {
                    e.preventDefault();
                    player.setCurrentTime(player.currentTime > 1 ? player.currentTime - 1 : 0);
                });
                // Increase playback rate
                Mousetrap.bind('>', (e) => {
                    e.preventDefault();
                    const newRate = CallPlayerState.PLAYBACK_RATE_MULTIPLIERS.sort((a, b) => a - b).find((rate) => rate > player.playbackRate);
                    if (newRate) {
                        player.setPlaybackRate(newRate);
                    }
                });
                // Decrease playback rate
                Mousetrap.bind('<', (e) => {
                    e.preventDefault();
                    const newRate = findLast(CallPlayerState.PLAYBACK_RATE_MULTIPLIERS.sort((a, b) => a - b), (rate) => rate < player.playbackRate);
                    if (newRate) {
                        player.setPlaybackRate(newRate);
                    }
                });
            }
        });
        Object.defineProperty(this, "onPositionChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ currentTime, type, }) => {
                if (type === 'audioprocess') {
                    this.throttledChangeCurrentTime(currentTime);
                }
                else if (type === 'seek') {
                    this.props.callPlayerState.setCurrentTime(currentTime);
                }
            }
        });
        Object.defineProperty(this, "throttledChangeCurrentTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle((currentTime) => {
                this.props.callPlayerState.setCurrentTime(currentTime);
            }, 250)
        });
    }
    componentDidMount() {
        this.bindShortcuts();
    }
    componentWillUnmount() {
        Mousetrap.reset();
        this.throttledChangeCurrentTime.cancel();
    }
    get caseViewState() {
        return this.context.caseViewState;
    }
    get zoom() {
        return this.props.callPlayerState.zoom;
    }
    render() {
        const { caseModel, callPlayerState, height, theme, timelineHeight, minimapHeight } = this.props;
        const { isPlaying, playbackRate, volume, currentTime } = callPlayerState;
        return (React.createElement(WaveSurferWrapper, { onPositionChange: this.onPositionChange, onReady: this.onReady, onFinish: this.onFinish, onError: this.onError, waveColor: theme.palette.background.divider, progressColor: theme.palette.primary.main, audio: callPlayerState.call.getCallAudioUrl(), barWidth: 0, zoom: this.zoom, height: height, hideScrollbar: true, cursorColor: "transparent", isPlaying: isPlaying, currentTime: currentTime, playbackRate: playbackRate, volume: volume, responsive: 0, xhr: {
                requestHeaders: [
                    {
                        key: 'authorization',
                        value: authStore.authToken,
                    },
                ],
            } },
            React.createElement(TimelineEntriesPlugin, { timelineEditor: caseModel.timelineEditor, caseViewState: this.caseViewState, callPlayerState: this.props.callPlayerState }),
            React.createElement(CursorPlugin, { color: theme.palette.text.secondary }),
            React.createElement(TimelinePlugin, { container: "#timeline-plugin", height: timelineHeight, color: theme.palette.text.hint, timeFormatter: (sec) => {
                    return Formatters.msToDuration(Math.round(sec * 1000), {
                        showHours: false,
                        showMilliseconds: this.zoom > 150 ? true : false,
                    });
                } }),
            React.createElement(MinimapPlugin, { container: "#minimap-plugin", height: minimapHeight, waveColor: theme.palette.background.divider, progressColor: theme.palette.primary.main, overviewBackgroundColor: transparentize(0.85, grayscale(invert(theme.palette.background.default))), overviewBorderColor: transparentize(0.75, grayscale(invert(theme.palette.background.default))) })));
    }
};
Object.defineProperty(Waveform, "contextType", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: CaseViewContext
});
Object.defineProperty(Waveform, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        height: 400,
        autoplay: false,
    }
});
Waveform = __decorate([
    withTheme,
    observer
], Waveform);
export { Waveform };
