import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useCoreStores } from 'browser/stores';
import { IconButton, Popover } from 'lib/cortiUI';
import { useExploreViewContext } from '../../ExploreViewContext';
import { AssignForReviewPanel } from './AssignForReviewPanel';
export function AssignForReviewWidget(props) {
    const { caseEntity } = props;
    const { refreshCasesList } = useExploreViewContext();
    const coreStores = useCoreStores();
    const theme = useTheme();
    const { t } = useTranslation();
    const [widgetAnchor, setWidgetAnchor] = React.useState(null);
    const onWidgetClick = (e) => {
        setWidgetAnchor(e.currentTarget);
    };
    const onMenuClose = () => {
        setWidgetAnchor(null);
    };
    function handleUserAssign(user) {
        void caseEntity.createReviewRequest({
            assignee: {
                isAnonymised: false,
                userID: user.id,
            },
        });
        refreshCasesList();
        coreStores.notifications.showNotification({
            type: 'success',
            message: t('explore:caseView.notificationUserAssignedForReview', '{{name}} assigned as reviewer', {
                name: user.name,
            }),
        });
    }
    function handleUserRemove(user) {
        void caseEntity.removeUserFromReviewingCase(user.id);
        refreshCasesList();
        coreStores.notifications.showNotification({
            type: 'success',
            message: t('explore:caseView.notificationUserUnassignedFromReview', '{{name}} removed from reviewers list', {
                name: user.name,
            }),
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { icon: React.createElement(PersonAddAlt1RoundedIcon, null), className: css({
                backgroundColor: transparentize(0.5, theme.palette.text.primary),
                color: 'white',
                '&:hover': {
                    backgroundColor: theme.palette.action.active,
                },
            }), onClick: onWidgetClick, "data-cy": "assign-for-review-btn" }),
        React.createElement(Popover, { open: !!widgetAnchor, anchorEl: widgetAnchor, onClose: onMenuClose },
            React.createElement(AssignForReviewPanel, { caseOwner: caseEntity.createdBy, assigneeIDs: caseEntity.assignees.map((it) => it.id), onUserAssign: handleUserAssign, onUserRemove: handleUserRemove }))));
}
