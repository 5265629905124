var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Base } from 'lib/cortiUI/components/Base';
import { Typography } from 'lib/cortiUI/components/Typography';
export function SidebarSection(props) {
    const { title, children, actions, icon } = props, rest = __rest(props, ["title", "children", "actions", "icon"]);
    return (React.createElement(Base, Object.assign({}, rest),
        (title || actions || icon) && (React.createElement(Base, { minHeight: 34, alignItems: "center", mb: 5, display: "flex", gap: 4, flex: 1 },
            icon ? (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
                icon,
                title && (React.createElement(Typography, { variant: "subtitle2", noSelect: true, color: "default" }, title)))) : (title && (React.createElement(Typography, { variant: "subtitle2", noSelect: true, color: "default" }, title))),
            actions && (React.createElement(Base, { ml: "auto", display: "flex", gap: 2 }, actions)))),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2 }, children)));
}
