var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import MUIDialog from '@mui/material/Dialog';
import React from 'react';
import { css } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { Typography } from 'lib/cortiUI/components/Typography';
var Sizes;
(function (Sizes) {
    Sizes["small"] = "300px";
    Sizes["medium"] = "480px";
    Sizes["large"] = "656px";
    Sizes["auto"] = "auto";
})(Sizes || (Sizes = {}));
export function Dialog(props) {
    const { title, helperText, hideCloseButton = false, size = 'large', onClose, children, actionButtons, titleProps, contentProps } = props, rest = __rest(props, ["title", "helperText", "hideCloseButton", "size", "onClose", "children", "actionButtons", "titleProps", "contentProps"]);
    return (React.createElement(MUIDialog, Object.assign({ onClose: onClose, maxWidth: false, sx: { '& .MuiDialog-paper': { backgroundImage: 'unset' } } }, rest),
        React.createElement(Box, { className: css({ width: Sizes[size] }), onClick: (e) => e.stopPropagation() },
            React.createElement(DialogTitle, Object.assign({ className: css({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }) }, titleProps),
                title,
                !hideCloseButton && React.createElement(IconButton, { onClick: onClose, icon: React.createElement(ClearRoundedIcon, null) })),
            React.createElement(DialogContent, Object.assign({ className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    paddingBottom: '16px',
                }) }, contentProps),
                helperText && React.createElement(Typography, { variant: "body1" }, helperText),
                children),
            actionButtons && (React.createElement(DialogActions, { className: css({
                    padding: '0px 24px 16px 24px',
                }) }, actionButtons)))));
}
