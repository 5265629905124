import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Modal } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { useGlobalContext } from '../../../context';
import { TrackersConfigModalContent } from '../TrackersConfigModal';
export const TrackersPickerTrigger = () => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'trackersPanel' });
    const { isEditMode, isDraft } = useGlobalContext();
    const { appliedFilters, changeFilters, isCreateOrUpdateViewLoading } = useParametersContext();
    const [isTrackersModalOpen, setIsTrackersModalOpen] = React.useState(false);
    return (React.createElement(Base, null,
        (isDraft || isEditMode) && (React.createElement(Button, { "data-cy": "performance-trackers-selection-btn", size: "small", variant: "outlined", onClick: () => setIsTrackersModalOpen(true), disabled: isCreateOrUpdateViewLoading }, t('selectedTrackers', {
            count: appliedFilters.trackers.length,
            defaultValue_one: `{{count}} tracker`,
            defaultValue_other: `{{count}} trackers`,
        }))),
        React.createElement(Modal, { open: isTrackersModalOpen, onClose: () => setIsTrackersModalOpen(false) },
            React.createElement(TrackersConfigModalContent, { selectedMetricSourcesKeys: appliedFilters.trackers, onApply: (trackers) => {
                    changeFilters({ trackers });
                    setIsTrackersModalOpen(false);
                } }))));
};
