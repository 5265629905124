import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export function ValidationResultSummaryText(props) {
    const { result } = props;
    const { t } = useTranslation('libGraphEditor', { keyPrefix: 'validation.statusText' });
    let errorsCount = result.getIssuesBySeverity('error').length;
    let warningsCount = result.getIssuesBySeverity('warning').length;
    return (React.createElement(React.Fragment, null, result.issues.length === 0 ? (React.createElement(Typography, { color: 'success' }, t('noIssuesMsg', 'No issues'))) : (React.createElement(Base, { display: 'inline-flex', flexWrap: "wrap", gap: 3 },
        React.createElement(Typography, null, t('graphContains', 'Graph contains')),
        errorsCount !== 0 && (React.createElement(Typography, { fontWeight: 700, color: "error" }, t('numberOfcriticalIssues', {
            count: errorsCount,
            defaultValue_one: '{{count}} critical issue',
            defaultValue_other: '{{count}} critical issues',
        }))),
        warningsCount !== 0 && (React.createElement(React.Fragment, null,
            errorsCount !== 0 && React.createElement(Typography, null, t('and', 'and')),
            React.createElement(Typography, { fontWeight: 700, color: "warning" }, t('numberOfWarnings', {
                count: warningsCount,
                defaultValue_one: '{{count}} warning',
                defaultValue_other: '{{count}} warnings',
            }))))))));
}
