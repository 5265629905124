import React, { useMemo } from 'react';
import { DragDropContext, Droppable, } from 'react-beautiful-dnd';
export function SortableContainer({ handleDragEnd, handleDragStart, children, }) {
    const droppableID = useMemo(() => Math.random().toString(36).slice(2, 9), []);
    const onDragEnd = ({ source, destination }) => {
        if (!destination) {
            return;
        }
        handleDragEnd(source.index, destination.index);
    };
    const onDragStart = !handleDragStart
        ? undefined
        : ({ source }) => handleDragStart(source.index);
    return (React.createElement(DragDropContext, { onDragStart: onDragStart, onDragEnd: onDragEnd },
        React.createElement(Droppable, { droppableId: droppableID }, ({ innerRef, droppableProps, placeholder }) => children({ containerProps: Object.assign({ ref: innerRef }, droppableProps), placeholder }))));
}
