import { ExpressionEvaluator } from '../../utils';
export class NextNodeDeterminator {
    constructor(graph) {
        Object.defineProperty(this, "graph", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: graph
        });
    }
    determine(input) {
        const environment = ExpressionEvaluator.createEnvFromFlowValues({
            blockValues: input.blockValues,
            factValues: input.factValues,
            graph: this.graph,
        });
        for (let gate of input.currentNode.gates) {
            if (gate.expression.trim() === '') {
                continue;
            }
            const hasEvaluated = ExpressionEvaluator.evaluate({
                expression: gate.expression,
                environment,
            });
            if (!hasEvaluated) {
                continue;
            }
            if (!gate.targetBranchNode) {
                return;
            }
            return gate.targetBranchNode.nodeID
                ? this.graph.getNodeByID(gate.targetBranchNode.nodeID)
                : this.graph.getBranchStartNode(gate.targetBranchNode.branchID);
        }
    }
}
