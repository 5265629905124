import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
/**
 * Hook to track if element has overflowing content, using a ResizeObserver to update the state on window resize.
 * And fade out bottom of the content if it has overflow, until we reach the end of the content.
 * The type of the ref is inferred from the type of the element passed to the hook.
 * @param fadeLimit // how many pixels from the bottom of the content to start fading out
 * @returns `UseScrollFadingResult` object with the following properties:
 *   - hasOverflow: A boolean indicating whether the content has overflow.
 *   - gradientOpacity: A number representing the opacity for fading out the bottom of the content.
 *   - scrollerRef: React ref object for the tracked element.
 */
export function useScrollFading(fadeLimit = 30) {
    const scrollerRef = React.useRef(null);
    const [hasOverflow, setHasOverflow] = React.useState(false);
    const [gradientOpacity, setGradientOpacity] = React.useState(1);
    React.useLayoutEffect(() => {
        if (!scrollerRef.current) {
            return;
        }
        const { current } = scrollerRef;
        const trigger = () => {
            const isContentOverflow = current.scrollHeight > current.clientHeight;
            setHasOverflow(isContentOverflow);
        };
        const observer = new ResizeObserver(trigger);
        observer.observe(current);
        const scrollHandler = () => {
            const { scrollTop, scrollHeight, clientHeight } = current;
            const scrollBottom = scrollHeight - scrollTop - clientHeight;
            setGradientOpacity(scrollBottom > fadeLimit ? 1 : 0);
        };
        current.addEventListener('scroll', scrollHandler);
        return () => {
            observer.disconnect();
            current.removeEventListener('scroll', scrollHandler);
        };
    }, [fadeLimit]);
    return { hasOverflow, gradientOpacity, scrollerRef };
}
