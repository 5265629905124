/**
 * Convert features object to window features format (name=value,other=value).
 */
export function toWindowFeatures(obj) {
    return Object.entries(obj)
        .reduce((features, [name, value]) => {
        if (typeof value === 'boolean') {
            features.push(`${name}=${value ? 'yes' : 'no'}`);
        }
        else {
            features.push(`${name}=${value}`);
        }
        return features;
    }, [])
        .join(',');
}
