import React, { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';
import { uuid } from '@corti/uuid';
function createChecklistStore(checklist) {
    return createStore((set) => ({
        checklist,
        mode: 'edit',
        selection: null,
        setMode: (mode) => set({ mode, selection: null }),
        selectEntry: (selection) => set({ selection }),
        clearSelection: () => set({ selection: null }),
        updateChecklist: (property, value) => set((state) => ({
            checklist: Object.assign(Object.assign({}, state.checklist), { [property]: value }),
        })),
        updateEntry: (entry, property, value) => set((state) => ({
            checklist: Object.assign(Object.assign({}, state.checklist), { entries: state.checklist.entries.map((e, i) => i === entry ? Object.assign(Object.assign({}, e), { [property]: value }) : e) }),
        })),
        deleteEntry: (index) => set((state) => ({
            checklist: Object.assign(Object.assign({}, state.checklist), { entries: [
                    ...state.checklist.entries.slice(0, index),
                    ...state.checklist.entries.slice(index + 1),
                ] }),
            // Also clear selection, as the deleted entry would always have been the selected one
            selection: null,
        })),
        addEntry: () => set((state) => ({
            checklist: Object.assign(Object.assign({}, state.checklist), { entries: [
                    ...state.checklist.entries,
                    {
                        id: uuid(),
                        label: '',
                        details: '',
                        key: '',
                        rank: state.checklist.entries.length,
                        manualCompletionEnabled: true,
                        automaticCompletionEnabled: false,
                        automaticCompletionExpression: '',
                        hasInlineNode: false,
                        inlineNodeID: '',
                        collectors: [],
                    },
                ] }),
            // Also select the new entry
            selection: state.checklist.entries.length,
        })),
        reorderEntries: (source, destination) => set((state) => {
            const entries = [...state.checklist.entries];
            const [removed] = entries.splice(source, 1);
            entries.splice(destination, 0, removed);
            return {
                checklist: Object.assign(Object.assign({}, state.checklist), { entries: entries.map((entry, index) => (Object.assign(Object.assign({}, entry), { rank: index }))) }),
                // Also select the new entry
                selection: destination,
            };
        }),
    }));
}
const ChecklistEditContext = createContext(null);
export function ChecklistEditProvider({ checklist, children, }) {
    const store = useRef(createChecklistStore(checklist)).current;
    return React.createElement(ChecklistEditContext.Provider, { value: store }, children);
}
export function useChecklistEdit(selector) {
    const context = useContext(ChecklistEditContext);
    if (!context) {
        throw new Error('useChecklistEdit must be used within a ChecklistEditProvider');
    }
    return useStore(context, selector);
}
