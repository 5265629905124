import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { css } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { TableColumnsResizeController, } from './TableColumnsResizeController';
import { TableSortController } from './TableSortController';
export function TableController(props) {
    const { rows, columns, children, minColumnWidth, onColumnResizeEnd, disableSort = false, disableResize = false, } = props;
    const $tableContainerRef = React.useRef(null);
    const getTableContainerRef = () => {
        return $tableContainerRef.current;
    };
    return (React.createElement(Base, { ref: $tableContainerRef, className: css({
            position: 'relative',
            height: props.height || '100%',
            width: props.width || '100%',
        }) },
        React.createElement(AutoSizer, { className: css({
                height: '100% !important',
                width: '100% !important',
            }) }, ({ height, width }) => {
            const tableWidth = props.width || width;
            const tableHeight = props.height || height;
            return (React.createElement(TableSortController, { rows: rows, disableSort: disableSort }, ({ rows, getSortingProps }) => {
                return (React.createElement(TableColumnsResizeController, { columns: columns, disableResize: disableResize, minColumnWidth: minColumnWidth, tableContainerWidth: tableWidth, onResizeEnd: onColumnResizeEnd }, ({ columns, tableWidth, minColumnWidth, getResizeHandleProps, isResizing, }) => {
                    const childrenElement = children({
                        rows: rows,
                        columns: columns,
                        tableHeight: tableHeight,
                        tableWidth: tableWidth,
                        isSortable: !disableSort,
                        isResizable: !disableResize,
                        isResizing: isResizing,
                        minColumnWidth: minColumnWidth,
                        getTableContainerRef: getTableContainerRef,
                        getResizeHandleProps: getResizeHandleProps,
                    });
                    return React.Children.map(childrenElement, (child) => {
                        if (child) {
                            return React.cloneElement(child, Object.assign(Object.assign({}, getSortingProps()), child.props));
                        }
                    });
                }));
            }));
        })));
}
