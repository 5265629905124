import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { ExpressionInputConnected } from 'lib/graphEditor/expressions/ExpressionInputConnected';
import { ExpressionInputStyledWrapper } from 'lib/graphEditor/expressions/ExpressionInputUI';
export function ExpressionCard({ isEditable, isEditing, content, placeholder, onExpressionSave, onExpressionEdit, onExpressionRemove, onExpressionCancel, onValueChange, setValidationResult, validationResult, }) {
    const theme = useTheme();
    const onInputChange = (value) => onValueChange(value);
    const onSaveClick = (e) => {
        e.preventDefault();
        if (!validationResult || validationResult.type === 'error' || !content)
            return;
        onExpressionSave === null || onExpressionSave === void 0 ? void 0 : onExpressionSave();
    };
    const onRemoveClick = (e) => {
        e.preventDefault();
        onExpressionRemove === null || onExpressionRemove === void 0 ? void 0 : onExpressionRemove();
    };
    return (React.createElement(React.Fragment, null, isEditing ? (React.createElement(Card, { py: 4, px: 5, style: {
            background: theme.palette.background.card,
            width: 300,
        }, elevation: 6, onClick: (e) => e.stopPropagation() },
        React.createElement(ExpressionInputStyledWrapper, { validationResult: validationResult },
            React.createElement(ExpressionInputConnected, { placeholder: placeholder, validate: true, includeBlockSuggestions: true, includeFactSuggestions: true, value: content, onChange: onInputChange, onValidate: (result) => {
                    setValidationResult(result);
                } })),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                React.createElement(IconButton, { onClick: onRemoveClick, icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), size: "small" })),
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                content && (React.createElement(Button, { onClick: onExpressionCancel, size: "small" }, intl.t('cancel', 'Cancel'))),
                React.createElement(Button, { onClick: onSaveClick, size: "small", className: css({ marginLeft: 1 }) }, intl.t('save', 'Save')))))) : (React.createElement(Card, { py: 4, px: 5, style: {
            background: theme.palette.background.card,
            width: 300,
            border: `1px solid ${theme.palette.primary.main}`,
            borderTopLeftRadius: 0,
            position: 'relative',
            top: -1,
        }, elevation: 16, onClick: (e) => e.stopPropagation() },
        React.createElement(Box, null,
            React.createElement(ExpressionInputConnected, { readOnly: true, value: content })),
        isEditable && (React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", pt: 3 },
            React.createElement(IconButton, { onClick: onRemoveClick, icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), size: "small" }),
            React.createElement(Button, { onClick: onExpressionEdit, className: css({ marginLeft: 'auto' }), size: "small" }, intl.t('edit', 'Edit'))))))));
}
