import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getTimeZone } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton, LinearProgress, Loading, Tooltip, Typography } from 'lib/cortiUI';
import { useTranscriptSearchViewContext } from '../TranscriptSearchView/context';
import { CallList } from '../components';
import { useTranscriptQueryViewContext } from './context';
export const CallsPanel = observer(function CallsPanel() {
    const { t } = useTranslation('transcriptSearchApp');
    const history = useHistory();
    const { utils } = useTranscriptSearchViewContext();
    const { viewModel } = useTranscriptQueryViewContext();
    const { query, calls } = viewModel.state;
    const [isLoadingNextPage, setIsLoadingNextPage] = React.useState(false);
    async function loadMoreCalls() {
        if (viewModel.loadMoreCalls && !isLoadingNextPage) {
            try {
                setIsLoadingNextPage(true);
                await viewModel.loadMoreCalls();
            }
            finally {
                setIsLoadingNextPage(false);
            }
        }
    }
    function exportCallList() {
        if (!query)
            return;
        const dateRange = utils.toAbsoluteDateRange(query.parameters.dateRange);
        if (!dateRange)
            return;
        const combinedUsers = utils.combineUserIDsAndTeamMemberIDs(query.parameters.tempUserIDs, query.parameters.tempTeamIDs);
        // promise never rejects
        void viewModel.exportCalls({
            dateRange: dateRange,
            timeZone: getTimeZone(),
            protocolNameIDs: query.parameters.protocolNames,
            userIDs: combinedUsers,
            termGroups: query.parameters.filteredTermGroups.map((it) => ({ id: it.id, terms: it.terms })),
        });
    }
    function handleRowClick(call) {
        history.push(`/explore/${call.case.id}`);
    }
    function getCalls() {
        return calls.map((call) => (Object.assign(Object.assign({}, call), { termGroupColors: call.termGroupIDs
                .map((id) => { var _a; return (_a = query === null || query === void 0 ? void 0 : query.parameters.getTermGroupByID(id)) === null || _a === void 0 ? void 0 : _a.style.color; })
                .filter((it) => !!it) })));
    }
    if (!viewModel.state.query) {
        return null;
    }
    if (viewModel.state.isLoadingCalls) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", "data-cy": "transcript-query-calls-panel-loading" }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, null,
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", "data-cy": "transcript-query-call-list-header" },
                React.createElement(Typography, { variant: "h5", color: "default", mr: 2 }, t('callList', 'Call List')),
                React.createElement(Tooltip, { title: t('exportList', 'Export list') },
                    React.createElement(IconButton, { icon: React.createElement(FileDownloadRoundedIcon, null), onClick: exportCallList, disabled: viewModel.state.isLoadingExportCalls }))),
            React.createElement(CallList, { calls: getCalls(), onRowClick: handleRowClick, onScrollToBottom: loadMoreCalls })),
        isLoadingNextPage && (React.createElement(Base, { mt: 2 },
            React.createElement(LinearProgress, { variant: "indeterminate" })))));
});
