import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { useMousetrap } from '@corti/react';
import { Base, FallbackView, FocusContext, FocusElement, GroupedFocusContext, Loading, } from 'lib/cortiUI';
import { useBreadcrumbActionsContext } from 'lib/router/context';
import { Flow, FlowProvider } from 'lib/triageFlow';
import { ToolbarProvider } from 'lib/webappUI';
import { AlertPopup } from './AlertPopup';
import { QuickCommentDialog } from './Comments';
import { FlowOverview } from './FlowOverview';
import { PinnedStepDialog } from './PinnedSteps';
import { ToolPanel, Toolbar } from './Toolbar';
import { TriageSessionContext, useTriageSession } from './TriageSessionContext';
import { UIEventProvider } from './UIEventProvider';
import { UIStateProvider, useUIState } from './UIStateProvider';
const TRIGGER_ALERT_KEY = 'a';
export const TriageSessionView = observer(function TriageSessionView({ controller, initialUIState, onUIStateChange, onUIEvent, }) {
    const focusController = React.useRef(null);
    const context = React.useRef({ controller });
    useMousetrap({
        keys: TRIGGER_ALERT_KEY,
        handle: () => {
            var _a, _b;
            (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusElementByID('alert-action-button');
            (_b = focusController.current) === null || _b === void 0 ? void 0 : _b.triggerFocusedElement();
        },
    }, []);
    if (context.current.controller.status.type === 'loading') {
        return (React.createElement(Base, { "data-cy": "triage-session-loading-view", display: "flex", justifyContent: "center", alignItems: "center" },
            React.createElement(Loading, { text: intl.t('realtime:loading', 'Loading') })));
    }
    if (context.current.controller.status.type === 'error') {
        return (React.createElement(Base, { "data-cy": "triage-session-error-view", display: "flex", justifyContent: "center", alignItems: "center" },
            React.createElement(FallbackView, { title: context.current.controller.status.message })));
    }
    return (React.createElement(GroupedFocusContext, null,
        React.createElement(TriageSessionContext.Provider, { value: context.current },
            React.createElement(ToolbarProvider, { initialState: initialUIState },
                React.createElement(UIStateProvider, { initialState: initialUIState, onChange: onUIStateChange },
                    React.createElement(UIEventProvider, { onEvent: onUIEvent },
                        React.createElement(FocusContext, { controllerRef: focusController, disableKeyboardShortcuts: true },
                            React.createElement(TriageSession, null))))))));
});
const TriageSession = observer(function TriageSession() {
    const uiState = useUIState();
    const breadcrumbsCtx = useBreadcrumbActionsContext();
    const { controller } = useTriageSession();
    React.useEffect(() => {
        var _a;
        const branches = {};
        controller.flow.stepStore.activePath.forEach((s) => {
            const b = controller.flow.graphTraverser.getBranchByNodeID(s.node.id);
            if (b && !branches[b.id]) {
                branches[b.id] = {
                    id: b.id,
                    text: b.name || '',
                    step: s,
                };
            }
        });
        function handleBranchClick(item) {
            if (controller.isCurrentUserOwner) {
                controller.flow.changeActiveStep({
                    step: item.step,
                    source: 'user-action',
                    sourceData: {
                        location: 'breadcrumb',
                    },
                });
            }
        }
        const getActiveBranchID = () => {
            var _a;
            const nodeID = (_a = controller.flow.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.node.id;
            if (!nodeID) {
                return;
            }
            const branch = controller.flow.graphTraverser.getBranchByNodeID(nodeID);
            if (!branch) {
                return;
            }
            return branch.id;
        };
        const activeBranchID = getActiveBranchID();
        const breadcrumbs = Object.values(branches).map((branch) => ({
            active: branch.id === activeBranchID,
            onClick: () => handleBranchClick(branch),
            label: branch.text,
        }));
        const externalId = (_a = controller.externalID) !== null && _a !== void 0 ? _a : '';
        breadcrumbsCtx === null || breadcrumbsCtx === void 0 ? void 0 : breadcrumbsCtx.setSecondaryInformation(breadcrumbs, externalId);
        return () => breadcrumbsCtx === null || breadcrumbsCtx === void 0 ? void 0 : breadcrumbsCtx.clearSecondaryInformation();
    }, [
        breadcrumbsCtx,
        uiState,
        controller.flow.stepStore.activeStep,
        controller.flow,
        controller.isCurrentUserOwner,
        controller.externalID,
    ]);
    return (React.createElement(FlowProvider, { flowStore: controller.flow },
        React.createElement(Base, { "data-cy": "triage-session-view", height: "100%", display: "grid", gridTemplateRows: "auto 1fr", overflow: "hidden" },
            React.createElement(Toolbar, null),
            React.createElement(Base, { overflow: "hidden", display: "grid", gridTemplateColumns: '1fr auto', gridTemplateRows: "100%" },
                React.createElement(Base, { position: "relative", overflow: "hidden" },
                    controller.isCurrentUserOwner ? (React.createElement(FocusElement, { defaultElementID: "flow-container" }, ({ registerElementRef }) => (React.createElement(Flow, { containerRef: registerElementRef, disableWindowing: !controller.settings.isFlowWindowingEnabled })))) : (React.createElement(FlowOverview, { flowStore: controller.flow })),
                    controller.settings.isAlertsEnabled && controller.settings.isAlertsUIEnabled && (React.createElement(AlertPopup, null))),
                React.createElement(ToolPanel, null)),
            React.createElement(PinnedStepDialog, null),
            controller.settings.isCommentsEnabled && React.createElement(QuickCommentDialog, null))));
});
