var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { chain, isEqual, pick } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { api, } from '@corti/lib/coreApiService';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { CaseFeedbackViewState } from './CaseFeedbackView/CaseFeedbackViewState';
import { CaseModel } from './CaseModel';
export class CaseViewState {
    constructor() {
        Object.defineProperty(this, "isLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseEntity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeSidebarView", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feedbackViewState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_timelineEntryDefinitions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.resetState();
    }
    async loadCase(caseID) {
        runInAction(() => {
            this.isLoading = true;
            this.error = undefined;
        });
        const performanceMetricsPermission = rbacService.performanceViewsRead();
        const transcriptPermission = rbacService.caseTranscriptRead();
        try {
            const res = await api.cases.getCaseForPreview(caseID, {
                performanceMetrics: performanceMetricsPermission,
                transcript: transcriptPermission,
            });
            runInAction(() => {
                this.caseEntity = new CaseModel(res, this._timelineEntryDefinitions);
            });
        }
        catch (err) {
            runInAction(() => {
                if (err instanceof Error) {
                    this.error = err;
                }
            });
            throw err;
        }
        finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
    async loadCaseTimelineEntries(caseID) {
        var _a;
        const res = await api.cases.getTimelineEntries(caseID);
        (_a = this.caseEntity) === null || _a === void 0 ? void 0 : _a.setTimelineEntries(res);
    }
    async setCallExcludedFromPerformanceMetrics(callID, excluded) {
        await api.cases.setCallExcludedFromPerformanceMetrics({ callID, excluded });
    }
    resetState() {
        this.isLoading = true;
        this.caseEntity = undefined;
        this.error = undefined;
        this.activeSidebarView = undefined;
        this.feedbackViewState = new CaseFeedbackViewState();
    }
    setActiveSidebarView(view) {
        this.activeSidebarView = view;
    }
    setTimelineEntryDefinitions(definitions) {
        this._timelineEntryDefinitions = definitions;
    }
    get filteredTimelineEntries() {
        var _a;
        let timelineEntriesToShow = [];
        if (!this.caseEntity)
            return timelineEntriesToShow;
        const checkByTEFilters = (definition) => {
            return !this.disabledFilterTimelineEntryDefinitions.find((disabledDef) => isEqual({
                source: pick(definition.source, ['type', 'entityID', 'entityType']),
            }, disabledDef));
        };
        const checkByTECallId = (callID) => {
            var _a, _b;
            if (!callID)
                return true;
            return ((_b = (_a = this.caseEntity) === null || _a === void 0 ? void 0 : _a.activeCall) === null || _b === void 0 ? void 0 : _b.id) === callID;
        };
        timelineEntriesToShow = (_a = this.caseEntity.timelineEditor.timelineEntries) === null || _a === void 0 ? void 0 : _a.filter(({ definition, callID }) => checkByTEFilters(definition) && checkByTECallId(callID));
        return timelineEntriesToShow;
    }
    get audioRelativeTimelineEntries() {
        return chain(this.filteredTimelineEntries)
            .filter((e) => {
            if (this.caseEntity && this.caseEntity.activeCallPlayerState) {
                if (e.callID === this.caseEntity.activeCallPlayerState.call.id || !e.callID) {
                    const { call } = this.caseEntity.activeCallPlayerState;
                    const audioDuration = this.caseEntity.activeCallPlayerState.duration * 1000;
                    const entryStartOffset = e.getOffsetPosition(call.start).start;
                    const entryEndOffset = e.getOffsetPosition(call.start).end || audioDuration;
                    return (entryStartOffset <= audioDuration &&
                        entryEndOffset <= audioDuration &&
                        entryStartOffset >= 0 &&
                        entryEndOffset >= 0);
                }
            }
            return false;
        })
            .value();
    }
    get timelineEntryDefinitions() {
        return this._timelineEntryDefinitions;
    }
    get disabledFilterTimelineEntryDefinitions() {
        var _a;
        return (_a = authStore.organization.clientSettings.disabledSCVTimelineEntryFilterDefinitions) !== null && _a !== void 0 ? _a : [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CaseViewState.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof CaseModel !== "undefined" && CaseModel) === "function" ? _a : Object)
], CaseViewState.prototype, "caseEntity", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Error !== "undefined" && Error) === "function" ? _b : Object)
], CaseViewState.prototype, "error", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], CaseViewState.prototype, "activeSidebarView", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof CaseFeedbackViewState !== "undefined" && CaseFeedbackViewState) === "function" ? _c : Object)
], CaseViewState.prototype, "feedbackViewState", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CaseViewState.prototype, "_timelineEntryDefinitions", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CaseViewState.prototype, "resetState", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseViewState.prototype, "setActiveSidebarView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], CaseViewState.prototype, "setTimelineEntryDefinitions", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], CaseViewState.prototype, "filteredTimelineEntries", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], CaseViewState.prototype, "audioRelativeTimelineEntries", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], CaseViewState.prototype, "timelineEntryDefinitions", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], CaseViewState.prototype, "disabledFilterTimelineEntryDefinitions", null);
