import React from 'react';
import { getQuestions } from '@corti/mission-control-api';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { useReview, useValidation } from '../context';
import { Question } from './Question';
import { QuestionsLoader } from './QuestionsLoader';
export const Questions = () => {
    const theme = useTheme();
    const { templateID } = useReview();
    const { setRequiredQuestions } = useValidation();
    const [questions, setQuestions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        if (templateID) {
            void fetchQuestions(templateID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateID]);
    async function fetchQuestions(templateID) {
        setIsLoading(true);
        try {
            const res = await getQuestions(templateID);
            setQuestions(res);
            setRequiredQuestions(res.filter((it) => it.required).map((question) => question.id));
        }
        catch (e) {
            console.error('Could not fetch a review questions', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(QuestionsLoader, null);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) }, questions.map((question) => (React.createElement(Question, Object.assign({ key: question.id }, question))))));
};
