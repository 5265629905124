import { trimEnd } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { createCoreApiService } from '@corti/lib/coreApiService';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { config as configManager } from 'core/configuration/browser';
import { FallbackView } from 'lib/cortiUI';
import { EnvironmentInfoStatus } from '../LoginView/EnvInfoIndicator';
function isAbsoluteURL(url) {
    return url.startsWith('http');
}
function envToURL(inputValue) {
    if (isAbsoluteURL(inputValue)) {
        return trimEnd(inputValue, '/');
    }
    return `https://api.${inputValue}.motocorti.io`;
}
function urlToEnv(url) {
    return url.match(/https?.*motocorti\.io/i)
        ? url.replace('https://api.', '').replace('.motocorti.io', '')
        : url;
}
export const SSO = observer(() => {
    const KEYCLOAK_LOCAL_STORAGE_KEY = 'hasKeycloakTriggered';
    const { t } = useTranslation('sso');
    const history = useHistory();
    const [envInfo, setEnvInfo] = React.useState({
        status: EnvironmentInfoStatus.None,
    });
    const config = configManager.getConfig();
    React.useEffect(() => {
        if (config.apiHost) {
            // if the config contains an apiHost that might come from inline data
            // such as the subdomain or query params thus we need to do an initial
            // check on component mount
            void checkEnvironment(config.apiHost);
        }
        else {
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function checkEnvironment(envInput) {
        if (!envInput) {
            setEnvInfo({ status: EnvironmentInfoStatus.None });
            return;
        }
        const fullUrl = envToURL(envInput);
        // if we already had a successful url set don't do anything
        if (envInfo.status === EnvironmentInfoStatus.Success && envInfo.url === fullUrl) {
            return;
        }
        setEnvInfo({
            status: EnvironmentInfoStatus.Checking,
        });
        const api = createCoreApiService({ baseUrl: fullUrl });
        try {
            const availableAuthProviders = await api.auth.getAvailableProviders();
            if (!!availableAuthProviders.length) {
                // For now Core only supports one provider from Keycloak
                // Saving it for SSO authorisation
                authStore.setAuthProvider(availableAuthProviders[0]);
                authStore.initSSOService(urlToEnv(envInput));
            }
            else {
                authStore.resetAuthProvider();
                authStore.destroySSOService();
                history.push('/login');
            }
            setEnvInfo({
                status: EnvironmentInfoStatus.Success,
                url: fullUrl,
            });
        }
        catch (err) {
            setEnvInfo({
                status: EnvironmentInfoStatus.Error,
            });
            authStore.resetAuthProvider();
            authStore.destroySSOService();
        }
    }
    React.useEffect(() => {
        var _a;
        if (authStore.authProvider && envInfo.status === EnvironmentInfoStatus.Success) {
            if (config.apiHost !== envInfo.url) {
                coreStore.remoteRequest.changeApiHost(envInfo.url);
            }
            const hasKeycloakTriggered = JSON.parse((_a = localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY)) !== null && _a !== void 0 ? _a : 'null');
            if (hasKeycloakTriggered === null || hasKeycloakTriggered === void 0 ? void 0 : hasKeycloakTriggered.status) {
                localStorage.removeItem(KEYCLOAK_LOCAL_STORAGE_KEY);
                return;
            }
            login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [envInfo]);
    function login() {
        localStorage.setItem(KEYCLOAK_LOCAL_STORAGE_KEY, JSON.stringify({ status: true }));
        if (envInfo.status !== EnvironmentInfoStatus.Success) {
            return false;
        }
        const loginOptions = {
            rememberUser: true,
        };
        authStore.signInWithSSO(loginOptions);
    }
    return React.createElement(FallbackView, { title: t('title', 'Authenticating...') });
});
