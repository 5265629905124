import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { AbstractInputElementModel } from './AbstractInputElementModel';
export class TextInputElementModel extends AbstractInputElementModel {
    constructor(props = {}) {
        super(props, {});
        this.type = TextInputElementModel.typename;
    }
}
Object.defineProperty(TextInputElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'text-input'
});
Object.defineProperty(TextInputElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.textInputElement.title', 'Simple Text Input'),
    })
});
Object.defineProperty(TextInputElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new TextInputElementModel();
        i.deserialize(val);
        return i;
    }
});
ElementSerializer.register(TextInputElementModel, 'TextInputElementModel');
