import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import { Base, FocusElement, FocusElementGroup, IconButton } from 'lib/cortiUI';
export function ReceivedTakeoverRequestControls({ acceptTitle, declineTitle, onAccept, onDecline, }) {
    return (React.createElement(FocusElementGroup, null, ({ groupID }) => {
        return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" },
            React.createElement(FocusElement, { defaultGroupID: groupID }, ({ registerElementRef }) => (React.createElement(IconButton, { ref: registerElementRef, icon: React.createElement(CheckRoundedIcon, null), size: "small", color: "primary", onClick: onAccept }, acceptTitle))),
            React.createElement(FocusElement, { defaultGroupID: groupID }, ({ registerElementRef }) => (React.createElement(IconButton, { ref: registerElementRef, icon: React.createElement(ClearRoundedIcon, null), size: "small", color: "primary", onClick: onDecline }, declineTitle)))));
    }));
}
