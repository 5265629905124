import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { publishTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Button } from 'lib/cortiUI';
import { getBaseUrl } from 'lib/router';
export const Publish = ({ templateID, disabled }) => {
    const history = useHistory();
    const { t } = useTranslation('template', { keyPrefix: 'builder.configuration.actions.publish' });
    const [isLoading, setIsLoading] = React.useState(false);
    async function handlePublish() {
        try {
            setIsLoading(true);
            await publishTemplate(templateID);
            history.push(getBaseUrl(history.location.pathname));
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('success', 'Template is published and ready to use'),
            });
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                type: 'info',
                message: t('error', 'Failed to publish'),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(Button, { color: "primary", size: "small", disabled: disabled || isLoading, onClick: handlePublish }, t('label', 'Publish')));
};
