var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseCanvasEntity } from './BaseCanvasEntity';
export class LinkModel extends BaseCanvasEntity {
    constructor() {
        super();
        Object.defineProperty(this, "sourcePort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "targetPort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
    }
    setSourcePort(port) {
        port.addLink(this);
        this.sourcePort = port;
    }
    setTargetPort(port) {
        if (this.sourcePort === port) {
            return;
        }
        port.addLink(this);
        this.targetPort = port;
    }
    get outPort() {
        return this.sourcePort.type === 'out' ? this.sourcePort : this.targetPort;
    }
    get inPort() {
        return this.sourcePort.type === 'in' ? this.sourcePort : this.targetPort;
    }
    setEndPosition(point) {
        this.endPosition = point;
    }
    get startPoint() {
        return this.sourcePort.position;
    }
    get endPoint() {
        if (this.targetPort) {
            return this.targetPort.position;
        }
        if (this.endPosition) {
            return this.endPosition;
        }
        return this.startPoint;
    }
    get isTempLink() {
        return !this.targetPort;
    }
    remove() {
        this.sourcePort.removeLink(this);
        if (this.targetPort) {
            this.targetPort.removeLink(this);
        }
    }
    getEncodableData() {
        var _a;
        return Object.assign(Object.assign({}, super.getEncodableData()), { sourcePortID: this.sourcePort.id, targetPortID: (_a = this.targetPort) === null || _a === void 0 ? void 0 : _a.id });
    }
    deserialize(entity, ctx) {
        super.deserialize(entity, ctx);
    }
    dispose() {
        return;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LinkModel.prototype, "sourcePort", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LinkModel.prototype, "targetPort", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], LinkModel.prototype, "endPosition", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LinkModel.prototype, "setSourcePort", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LinkModel.prototype, "setTargetPort", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkModel.prototype, "outPort", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkModel.prototype, "inPort", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LinkModel.prototype, "setEndPosition", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkModel.prototype, "startPoint", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkModel.prototype, "endPoint", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkModel.prototype, "isTempLink", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LinkModel.prototype, "remove", null);
