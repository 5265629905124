import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { isAutomaticReview } from '@corti/mission-control-api';
import { useTheme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Base, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from 'lib/cortiUI';
import { GradientStarIcon } from '../components';
import { getReviewComplianceStatusData } from '../utils';
export const ReviewItem = ({ selected = false, showScore = false, onClick, created_by, status_updated_at, status, score_percentage, compliance_status, }) => {
    var _a, _b;
    const theme = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.review' });
    const scorePercentage = isNaN(score_percentage) ? undefined : Math.floor(score_percentage);
    function getReviewDetails() {
        switch (status) {
            case 'PENDING_TRANSCRIPT':
            case 'PENDING_INTERACTION':
            case 'NONE':
                return {
                    label: t('pendingTranscript', 'Processing...'),
                    icon: React.createElement(TimelapseRoundedIcon, { fontSize: "small" }),
                };
            case 'FAILED':
                return {
                    label: t('failed', 'Failed to process.'),
                    icon: React.createElement(ErrorRoundedIcon, { fontSize: "small" }),
                };
            case 'SKIPPED':
                return {
                    label: t('skipped', 'Not Suitable'),
                    icon: (React.createElement(Tooltip, { sx: { pointerEvents: 'auto' }, title: t('skippedTooltip', "AI Assessment is not suitable for this interaction. This might be because there wasn't enough data or the interaction was not a fit for the chosen assessment template. You can still manually assess it or contact support for help.") },
                        React.createElement(DoNotDisturbAltRoundedIcon, { fontSize: "small" }))),
                };
            default:
                return {
                    label: formatDateTime(new Date(status_updated_at)),
                };
        }
    }
    const reviewDetails = getReviewDetails();
    const isAiReview = isAutomaticReview(created_by);
    return (React.createElement(ListItemButton, { selected: selected, sx: { pointerEvents: selected ? 'none' : 'auto' }, onClick: onClick, border: `1px solid ${theme.palette.background.divider}`, dense: true },
        React.createElement(ListItemIcon, null, isAiReview ? React.createElement(GradientStarIcon, null) : React.createElement(PersonRoundedIcon, null)),
        React.createElement(ListItemText, { primary: React.createElement(Typography, { color: "default", variant: "body2" }, isAiReview ? 'Corti AI' : (_b = (_a = repositories.users.getUser(created_by)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''), secondary: React.createElement(Typography, { display: "flex", alignItems: "center", gap: 2 },
                !!reviewDetails.icon && reviewDetails.icon,
                React.createElement(Typography, { variant: "body2" }, reviewDetails.label)) }),
        showScore && scorePercentage !== undefined && (React.createElement(ListItemIcon, null,
            React.createElement(Base, { display: "flex", gap: 2 },
                React.createElement(BarChartRoundedIcon, { color: getReviewComplianceStatusData(compliance_status).color }),
                compliance_status === 'AUTO_FAIL' && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { color: "default" }, `${t('autoFailure', 'Auto Failure')}`),
                    React.createElement(Typography, { color: "default" }, `•`))),
                React.createElement(Typography, { color: "default" }, ` ${scorePercentage}%`))))));
};
