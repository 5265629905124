import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Box, FocusElement, FocusElementGroup, RichTextRenderer, SearchBar, Typography, } from 'lib/cortiUI';
import { Option } from '../Option';
import { Label } from '../components';
import { isOptionDisabled, mergeOptions, search } from './utils';
export function SelectListBlock(props) {
    const { id, label, isSearchable, limit, isReadonly = false, options, selectedOptions, onChange, onOptionEnter, onOptionLeave, } = props;
    const { t } = useTranslation('common');
    const theme = useTheme();
    const [searchValue, setSearchValue] = React.useState('');
    const searchFilteredOptions = React.useMemo(() => {
        return search(searchValue, options);
    }, [searchValue, options]);
    const clearSearchValue = () => {
        setSearchValue('');
    };
    const changeSearchValue = (event) => {
        const value = event.target.value;
        if (value === '') {
            return clearSearchValue();
        }
        setSearchValue(value);
    };
    function handleOptionSelect(option) {
        onChange(mergeOptions(option, selectedOptions, limit));
    }
    return (React.createElement(Box, null,
        label && (React.createElement(Base, { mb: 3 },
            React.createElement(Label, { label: label }))),
        isSearchable && (React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(Base, { mb: 7 },
            React.createElement(FocusElement, { defaultElementID: id, defaultGroupID: groupID, isFocusable: !isReadonly }, ({ registerElementRef }) => (React.createElement(SearchBar, { inputRef: registerElementRef, autoComplete: "off", onChange: changeSearchValue, value: searchValue }))))))),
        React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(Base, { display: "grid", gap: theme.space[2] },
            isSearchable && searchFilteredOptions.length === 0 && (React.createElement(Typography, { color: "secondaryText", variant: "body2" }, t('searchbar.noSearchResultsFound', 'No results found'))),
            (isSearchable ? searchFilteredOptions : options).map((option, idx) => {
                const isSelected = selectedOptions.includes(option);
                return (React.createElement(FocusElement, Object.assign({ key: option.id, defaultElementID: option.id, defaultGroupID: groupID, isFocusable: !isReadonly }, (isSearchable ? { position: idx } : {})), ({ registerElementRef }) => (React.createElement(Option, { "data-cy": 'flow:option', "data-option-id": `${option.id}`, "data-checked": isSelected, innerRef: registerElementRef, isSelected: isSelected, isDisabled: isOptionDisabled(option, selectedOptions, limit), isReadonly: isReadonly, controlType: limit === 1 ? 'radio' : 'checkbox', onSelect: () => {
                        handleOptionSelect(option);
                        if (isSearchable) {
                            clearSearchValue();
                        }
                    }, onEnter: () => onOptionEnter(option), onLeave: () => onOptionLeave(option) },
                    React.createElement(RichTextRenderer, { value: option.richText || option.plainText })))));
            }))))));
}
