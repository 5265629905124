import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Base, Box, FallbackView, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, } from 'lib/cortiUI';
import { SavedFiltersListItem } from './SavedFiltersListItem';
export const SavedFilters = (props) => {
    const theme = useTheme();
    const gridTemplateColumns = '60% 20% 1fr';
    const gridGap = theme.space[4];
    const [activeTab, setActiveTab] = React.useState('ALL');
    const [searchTerm, setSearchTerm] = React.useState('');
    const getFilterList = () => {
        const filteredFilters = props.model.caseFilters.filter((filter) => filter.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
        if (activeTab === 'SUBSCRIBED') {
            return filteredFilters.filter((filter) => !!filter.subscriptions.length);
        }
        if (activeTab === 'PRIVATE') {
            return filteredFilters.filter((filter) => filter.visibility === 'PRIVATE');
        }
        return filteredFilters;
    };
    const onClose = () => {
        setSearchTerm('');
        props.onClose();
    };
    const onSelect = (filterValue) => {
        props.model.applySavedFilter(filterValue);
        props.model.casesQuickFilterState.resetQuickFilterState();
        trackerService.track('[Explore] saved cases filter applied', {
            overall_junction: props.model.advancedFilter.junction,
            queries: props.model.advancedFilter.entities.map((it) => ({
                id: it.id,
                junction: it.junction,
                predicates: it.predicates.map((p) => ({
                    attributeID: p.attributeID,
                    comparison: p.comparison,
                    value: p.value,
                })),
            })),
        });
        onClose();
    };
    const onSubscribe = (filterID) => {
        void props.model.subscribeToCaseFilter(filterID);
    };
    const onUnsubscribe = (filterID) => {
        void props.model.unsubscribeFromCaseFilter(filterID);
    };
    const onDelete = (filterID) => {
        void props.model.deleteCaseFilter(filterID);
    };
    return (React.createElement(Observer, null, () => {
        return (React.createElement(Base, { "data-cy": "saved-filters-view", py: 7, px: 8, className: css({
                height: '100%',
                maxWidth: '70vw',
                width: 500,
                display: 'grid',
                gridGap: theme.space[5],
                gridTemplateRows: 'auto auto auto 1fr',
                backgroundColor: theme.palette.background.secondary,
            }) },
            React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { color: "default", variant: "h6" }, intl.t('explore:filters.savedFilters', 'Saved filters')),
                React.createElement(IconButton, { onClick: onClose, icon: React.createElement(ClearRoundedIcon, { fontSize: "small" }), size: "small" })),
            React.createElement(Box, { width: "100%" },
                React.createElement(Tabs, { value: activeTab, onChange: (_e, val) => setActiveTab(val), indicatorColor: "primary", textColor: "primary" },
                    React.createElement(Tab, { "data-cy": "all-filters-tab", classes: { root: css({ minWidth: 'unset' }) }, label: intl.t('explore:filters.all', 'All'), value: "ALL" }),
                    authStore.isFeatureEnabled('case-filter-subscription') && (React.createElement(Tab, { "data-cy": "subscribed-filters-tab", classes: { root: css({ minWidth: 'unset' }) }, label: intl.t('explore:filters.subscribed', 'Subscribed'), value: "SUBSCRIBED" })),
                    React.createElement(Tab, { "data-cy": "private-filters-tab", classes: { root: css({ minWidth: 'unset' }) }, label: intl.t('explore:filters.private', 'Private'), value: "PRIVATE" }))),
            React.createElement(TextField, { placeholder: intl.t('search', 'Search'), value: searchTerm, onChange: (e) => setSearchTerm(e.currentTarget.value), autoFocus: true, InputProps: {
                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.secondary, marginTop: 2 }) }))),
                } }),
            getFilterList().length > 0 ? (React.createElement(Base, { className: css({
                    display: 'grid',
                    gridGap: theme.space[5],
                    gridTemplateRows: 'auto 1fr',
                    overflow: 'hidden',
                }) },
                React.createElement(Base, { px: 6, style: {
                        display: 'grid',
                        gridTemplateColumns,
                        gridGap,
                    } },
                    React.createElement(Typography, { variant: "caption", noWrap: true }, intl.t('explore:filters.name', 'Name')),
                    React.createElement(Typography, { variant: "caption", noWrap: true, textAlign: "center" }, intl.t('explore:filters.privacy', 'Privacy'))),
                React.createElement(Base, { className: css(getScrollerCss({ theme: theme })) }, getFilterList().map((filter) => {
                    return (React.createElement(SavedFiltersListItem, { key: filter.id, filter: filter, className: css({
                            '&:not(:last-child)': {
                                marginBottom: theme.space[4],
                            },
                        }), gridTemplateColumns: gridTemplateColumns, gridGap: gridGap, onSelect: onSelect, onSubscribe: onSubscribe, onUnsubscribe: onUnsubscribe, onDelete: onDelete }));
                })))) : (React.createElement(FallbackView, { "data-cy": "saved-filters-fallback", title: intl.t('explore:filters.noFiltersFoundView.title', 'No saved filters found') }))));
    }));
};
