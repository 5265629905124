class UserSessionStorage {
    constructor(baseKey) {
        Object.defineProperty(this, "_baseKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._baseKey = baseKey;
    }
    setItem(key, value) {
        const storage = this._getStorage();
        storage[key] = value;
        this._setStorage(storage);
    }
    getItem(key) {
        const storage = this._getStorage();
        return storage[key] ? storage[key] : null;
    }
    removeItem(key) {
        const storage = this._getStorage();
        delete storage[key];
        this._setStorage(storage);
    }
    clear() {
        localStorage.removeItem(this._baseKey);
    }
    _getStorage() {
        const unparsed = localStorage.getItem(this._baseKey) || '{}';
        return JSON.parse(unparsed);
    }
    _setStorage(storage) {
        localStorage.setItem(this._baseKey, JSON.stringify(storage));
    }
}
export default new UserSessionStorage('userStorage');
