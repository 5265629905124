export function calculateWindowPosition(size, anchor, anchorOrigin) {
    var _a, _b, _c, _d, _e;
    const screenWidth = window.outerWidth;
    const screenHeight = window.outerHeight;
    const windowLeft = window.screenLeft;
    const windowTop = window.screenTop;
    // Estimate the height of the top bar taking into account if inside an iframe
    const topBarHeight = window.parent.outerHeight - window.parent.innerHeight;
    if (anchor && anchorOrigin) {
        const iframeRect = (_a = window.frameElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const anchorRect = anchor.getBoundingClientRect();
        // Adjust for the iframe position
        const anchorLeft = anchorRect.left + ((_b = iframeRect === null || iframeRect === void 0 ? void 0 : iframeRect.left) !== null && _b !== void 0 ? _b : 0);
        const anchorRight = anchorRect.right + ((_c = iframeRect === null || iframeRect === void 0 ? void 0 : iframeRect.left) !== null && _c !== void 0 ? _c : 0);
        const anchorTop = anchorRect.top + ((_d = iframeRect === null || iframeRect === void 0 ? void 0 : iframeRect.top) !== null && _d !== void 0 ? _d : 0);
        const anchorBottom = anchorRect.bottom + ((_e = iframeRect === null || iframeRect === void 0 ? void 0 : iframeRect.top) !== null && _e !== void 0 ? _e : 0);
        let positionX = 0;
        let positionY = 0;
        if (anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'left') {
            positionX = anchorLeft - size.width;
            positionY = anchorTop - size.height + topBarHeight;
        }
        else if (anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'right') {
            positionX = anchorRight;
            positionY = anchorTop - size.height + topBarHeight;
        }
        else if (anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'left') {
            positionX = anchorLeft - size.width;
            positionY = anchorBottom + topBarHeight;
        }
        else if (anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'right') {
            positionX = anchorRight;
            positionY = anchorBottom + topBarHeight;
        }
        // Adjust for screen bounds
        positionX = Math.max(0, Math.min(positionX, screenWidth - size.width)) + windowLeft;
        positionY = Math.max(0, Math.min(positionY, screenHeight - size.height)) + windowTop;
        return { left: positionX, top: positionY };
    }
    else {
        // Center the window if no anchor is provided
        return {
            top: windowTop + (screenHeight - size.height) / 2,
            left: windowLeft + (screenWidth - size.width) / 2,
        };
    }
}
