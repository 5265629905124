import { isElectron } from '@corti/electron-utils';
import { simpleIpc } from '@corti/ipc-renderer';
export function initApiBridge(browserApi) {
    if (!isElectron()) {
        return;
    }
    // For simple method calls, we need to answer to the main process and send data back
    simpleIpc.answerMain('api-channel', (msg) => {
        const scope = browserApi[msg.scope];
        const fn = scope[msg.call];
        return fn.apply(browserApi, msg.args);
    });
    // For listener function calls, we are using a channel to "push" events to the main process
    Object.entries(browserApi).forEach(([scopeKey, scope]) => {
        Object.keys(scope).forEach((functionName) => {
            if (functionName.startsWith('on')) {
                const onSomething = scope[functionName];
                onSomething((...args) => {
                    void simpleIpc.callMain('api-channel:listenerCallback', {
                        scope: scopeKey,
                        call: functionName,
                        args,
                    });
                });
            }
        });
    });
}
