import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { isEqual } from 'lodash';
import React from 'react';
import { formatDate } from '@corti/date';
import { Button, DateRangePicker as CortiUIDateRangePicker, Popover, Typography, } from 'lib/cortiUI';
export function DateRangePicker(props) {
    const { value, onChange, readOnly } = props;
    const [dateRange, setDateRange] = React.useState({
        startDate: value.startDate,
        endDate: value.endDate,
    });
    const [datePickerAnchor, setDatePickerAnchor] = React.useState(null);
    React.useEffect(() => {
        setDateRange(value);
    }, [value]);
    function handleRelativeDateRangeSelect(_, absoluteDateRange) {
        if (absoluteDateRange) {
            setDateRange({
                startDate: absoluteDateRange.start,
                endDate: absoluteDateRange.end,
            });
        }
    }
    function handleDatePickerOpen(e) {
        setDatePickerAnchor(e.currentTarget);
    }
    function handleDatePickerClose() {
        if (!isEqual(value, dateRange)) {
            onChange(dateRange);
        }
        setDatePickerAnchor(null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "text", onClick: handleDatePickerOpen, startIcon: React.createElement(CalendarTodayRoundedIcon, { fontSize: "small", color: readOnly ? 'disabled' : 'action' }), disabled: readOnly },
            React.createElement(Typography, { variant: "button" }, `${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`)),
        React.createElement(Popover, { open: Boolean(datePickerAnchor), anchorEl: datePickerAnchor, onClose: handleDatePickerClose },
            React.createElement(CortiUIDateRangePicker, { minDate: new Date(2000, 0), range: dateRange, onChange: setDateRange, relativeDateRangePickerProps: {
                    enabled: true,
                    onSelect: handleRelativeDateRangeSelect,
                    dateRanges: (ranges) => {
                        return [
                            ranges.LAST_YEAR,
                            ranges.THIS_YEAR,
                            ranges.LAST_MONTH,
                            ranges.THIS_MONTH,
                            ranges.LAST_WEEK,
                            ranges.LAST_30_DAYS,
                            ranges.YESTERDAY,
                        ];
                    },
                }, withTime: true }))));
}
