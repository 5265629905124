import React, { createContext, useContext } from 'react';
// There's no clean way to type this, so we're using any
// it's being cast back to generic in useCarousel
const CarouselContext = createContext(null);
export function CarouselProvider({ children, onChange, activeStepIdx, renderStep, }) {
    return (React.createElement(CarouselContext.Provider, { value: { onChange, activeStepIdx, renderStep } }, children));
}
export function useCarousel() {
    const ctx = useContext(CarouselContext);
    if (!ctx) {
        throw new Error('useCarousel must be used within CarouselProvider');
    }
    return ctx;
}
