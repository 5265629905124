import { observer } from 'mobx-react';
import React from 'react';
import { Base } from 'lib/cortiUI';
import { useTriageSession } from '../TriageSessionContext';
import { useUIEventObserver } from '../UIEventProvider';
import { Alert } from './Alert';
export const AlertPopup = observer(function AlertPopup() {
    const observer = useUIEventObserver();
    const { controller } = useTriageSession();
    const { priorityAlert } = controller.alerts;
    if (!priorityAlert) {
        return null;
    }
    const handleActionClick = () => {
        controller.alerts.triggerAction(priorityAlert);
        observer.fireEvent({
            type: 'alert-action-button-clicked',
            data: priorityAlert,
        });
    };
    return (React.createElement(Base, { "data-cy": "alert-widget", m: 6, position: "absolute", top: 0, right: 0, zIndex: 0 },
        React.createElement(Alert, { alert: priorityAlert, onActionClick: handleActionClick })));
});
