import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { uuid } from '@corti/uuid';
import { Base, Button, Typography, UnstyledLink } from 'lib/cortiUI';
import { FilesDataGrid } from './FilesDataGrid';
import { FilesDropArea } from './FilesDropArea';
import { SelectFilesTrigger } from './SelectFilesTrigger';
import { useDragAndDrop } from './useDragAndDrop';
import { validateJsonFile } from './utils';
export const ImportTemplateModalContent = ({ onUploadComplete }) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'importModal' });
    const history = useHistory();
    const [file, setFile] = React.useState(null);
    const { isDragging, dragRef } = useDragAndDrop({ onDrop: handleDrop });
    const uploadInputRef = React.useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => onUploadComplete(), []);
    async function addFile(file) {
        const { status, data } = await validateJsonFile(file);
        setFile({ id: uuid(), status, data, file });
    }
    async function handleDrop(e) {
        const dataTransfer = e.dataTransfer;
        if (dataTransfer && dataTransfer.files.length) {
            await addFile(dataTransfer.files[0]);
        }
    }
    function handleUploadClick() {
        if (uploadInputRef.current) {
            uploadInputRef.current.click();
        }
    }
    async function handleSelectFiles({ currentTarget }) {
        var _a;
        if ((_a = currentTarget.files) === null || _a === void 0 ? void 0 : _a.length) {
            const selectedFilesList = [...currentTarget.files];
            await addFile(selectedFilesList[0]);
        }
    }
    function handleResetUploadInputValue({ currentTarget }) {
        currentTarget.value = '';
    }
    function handleRemoveFile() {
        setFile(null);
    }
    return (React.createElement(Base, null,
        React.createElement(Base, { mb: 5 },
            React.createElement(Typography, { sx: { mt: 4, mb: 2 } }, t('description', 'Import a .json file that contains a feedback template representation. We use this file content to automatically fill in a feedback template builder with data.')),
            file && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { sx: { mt: 4, mb: 2 }, variant: "h6" }, t('uploadedFiles', 'Uploaded files')),
                React.createElement(FilesDataGrid, { filesList: [file], onRemove: handleRemoveFile })))),
        React.createElement(FilesDropArea, { ref: dragRef, isDragging: isDragging },
            React.createElement(SelectFilesTrigger, { ref: uploadInputRef, onUploadTriggerClick: handleUploadClick, onInputValueChange: handleSelectFiles, onResetInputValue: handleResetUploadInputValue })),
        React.createElement(Base, { py: 4, display: "flex", alignItems: "center", justifyContent: "flex-end" },
            React.createElement(UnstyledLink, { disabled: Boolean(file === null || file.data === null), to: {
                    pathname: `${history.location.pathname}/builder`,
                    state: { uploadedTemplate: file === null || file === void 0 ? void 0 : file.data },
                }, className: css({ display: 'flex', alignItems: 'center', justifyContent: 'center' }), render: () => (React.createElement(Button, { disabled: Boolean(file === null || file.data === null), color: "primary", "data-cy": "import-file-confirm-btn" }, t('confirmButton', 'Confirm'))) }))));
};
