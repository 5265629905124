import { ExpressionUtils } from '@corti/lib/graphs';
export const ExpressionInputValue = {
    create() {
        return [{ type: 'root', children: [] }];
    },
    fromString: fromString,
    toString: toString,
};
export function isCustomElement(element) {
    return 'type' in element && element.type === 'ref-element';
}
const regexp = new RegExp(/(\${[^\}]+\})/, 'g');
function fromString(input) {
    const [paragraph] = ExpressionInputValue.create();
    const tokens = input.replace(/\n/g, ' ').split(regexp);
    tokens.forEach((t) => {
        if (t.startsWith('${') && t.endsWith('}')) {
            const refID = t.substring(2, t.length - 1);
            paragraph.children.push({
                type: 'ref-element',
                data: {
                    refID,
                },
                children: [{ type: 'text', text: '' }],
            });
        }
        else {
            paragraph.children.push({ type: 'text', text: t });
        }
    });
    return [paragraph];
}
function toString(value) {
    const [firstParagraph] = value;
    const textarr = firstParagraph.children.map((node) => {
        var _a;
        if (isCustomElement(node)) {
            return ExpressionUtils.wrapVariable(node.data.refID);
        }
        return (_a = node.text) !== null && _a !== void 0 ? _a : '';
    });
    return textarr.join('');
}
