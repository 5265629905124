import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { isImgType } from '@corti/lib/graphs/media';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ButtonBase, ProgressiveImage, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
export const ImageElementWidget = observer(function ImageElementWidget(props) {
    const { model } = props;
    const { editor } = useGraphEditorCtx();
    if (!model.mediaAssetID) {
        return React.createElement(FilePickerPlaceholder, { model: model });
    }
    const mediaAsset = editor.media.provider.resolveMediaAsset(model.mediaAssetID);
    if (!mediaAsset) {
        return React.createElement(MissingImgReferencePlaceholder, { id: model.mediaAssetID });
    }
    if (isImgType(mediaAsset)) {
        return React.createElement(ImagePreview, { mediaAsset: mediaAsset });
    }
    return React.createElement(FilePreview, { mediaAsset: mediaAsset });
});
function FilePickerPlaceholder(props) {
    const { model } = props;
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    return (React.createElement(ButtonBase, { display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: 300, background: theme.palette.background.divider, onClick: async () => {
            const img = await editor.media.pickMedia({ whitelistTypes: ['image'] });
            if ((img === null || img === void 0 ? void 0 : img.typename) === 'image') {
                const propName = 'mediaAssetID';
                const propValue = img.id;
                editor.dispatch({
                    type: 'viewBuilder.setBlockProtoProperty',
                    data: {
                        protoID: model.id,
                        propName,
                        propValue,
                    },
                });
            }
        } },
        React.createElement(Typography, { variant: "body2", color: "default" }, t('contentBuilder.imageElement.noFileSelected', 'Click to select an image'))));
}
function ImagePreview({ mediaAsset }) {
    const theme = useTheme();
    return (React.createElement(ProgressiveImage, { width: mediaAsset.imageInfo.width, height: mediaAsset.imageInfo.height, className: css({
            // these settings will make the image scale to fit inside of parent
            // but it will maintain the aspect ratio
            maxWidth: '100%',
            height: 'auto',
            background: theme.palette.background.secondary,
        }), src: mediaAsset.getThumbnailURL({
            maxWidth: devicePixelRatio * 450,
        }), lowQualitySrc: mediaAsset.getThumbnailURL({
            maxWidth: 225,
        }) }));
}
function FilePreview({ mediaAsset }) {
    return React.createElement("div", null, mediaAsset.name);
}
function MissingImgReferencePlaceholder(props) {
    const theme = useTheme();
    return (React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.background.divider, height: 300, width: '100%' },
        React.createElement(MissingReferencePlaceholder, { id: props.id })));
}
function MissingReferencePlaceholder(props) {
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(Base, { display: "flex", gap: 4, alignItems: "center", flexWrap: 'wrap' },
        React.createElement(WarningRoundedIcon, { color: 'warning' }),
        React.createElement(Typography, { variant: "caption", color: "default" },
            t('contentBuilder.imageElement.missingRef', 'Missing reference to '),
            ' '),
        React.createElement("span", { style: { fontFamily: 'monospace', background: theme.palette.background.secondary } }, props.id)));
}
