import { intl } from '@corti/i18n';
export function getReviewComplianceStatusPaletteColor(status, palette) {
    switch (status) {
        case 'COMPLIANT':
            return palette.success.light;
        case 'NON_COMPLIANT':
        case 'AUTO_FAIL':
            return palette.error.light;
        case 'PARTIALLY_COMPLIANT':
            return palette.warning.light;
        case 'NONE':
        default:
            return;
    }
}
export function getReviewComplianceStatusData(status) {
    switch (status) {
        case 'COMPLIANT':
            return {
                color: 'success',
                label: intl.t('caseView:complianceStatus.compliant', 'Compliant'),
            };
        case 'NON_COMPLIANT':
            return {
                color: 'error',
                label: intl.t('caseView:complianceStatus.nonCompliant', 'Non Compliant'),
            };
        case 'PARTIALLY_COMPLIANT':
            return {
                color: 'warning',
                label: intl.t('caseView:complianceStatus.partiallyCompliant', 'Partially Compliant'),
            };
        case 'AUTO_FAIL':
            return {
                color: 'error',
                label: intl.t('caseView:complianceStatus.autoFail', 'Auto Failure'),
            };
        case 'NONE':
        default:
            return {
                label: '-',
            };
    }
}
