import CloudOffIcon from '@mui/icons-material/CloudOff';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { trimEnd } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { createCoreApiService } from '@corti/lib/coreApiService';
import { usePrevious } from '@corti/react';
import { isValidEmail } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { BuildInfo } from 'browser/components';
import { REDIRECT_ROUTE_QUERY_PARAM } from 'browser/containers';
import { PasswordResetForm } from 'browser/pages/OrganizationSettingsView/Users/PasswordResetForm';
import { zendeskWidgetService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { config as configManager, shouldUseSubdomainAsEnv } from 'core/configuration/browser';
import { env } from 'core/environment';
import { Base, Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, Modal, ModalContent, ModalHeader, TextField, Tooltip, Typography, } from 'lib/cortiUI';
import { EnvInfoRenderer, EnvironmentInfoStatus } from './EnvInfoIndicator';
import EnvironmentInput from './EnvironmentInput';
import { LinkButton } from './LinkButton';
function isAbsoluteURL(url) {
    return url.startsWith('http');
}
function envToURL(inputValue) {
    if (isAbsoluteURL(inputValue)) {
        return trimEnd(inputValue, '/');
    }
    return `https://api.${inputValue}.motocorti.io`;
}
function urlToEnv(url) {
    return url.match(/https?.*motocorti\.io/i)
        ? url.replace('https://api.', '').replace('.motocorti.io', '')
        : url;
}
export const LoginView = observer(function LoginView(props) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = React.useState(false);
    const [isAuthenticating, setIsAuthenticating] = React.useState(false);
    const [envInfo, setEnvInfo] = React.useState({
        status: EnvironmentInfoStatus.None,
    });
    const { remoteRequest } = coreStore;
    const config = configManager.getConfig();
    const [rememberUser, setRememberUser] = React.useState(config['login:enableRememberMe'] ? true : false);
    const prevEnvInfo = usePrevious(envInfo);
    const theme = useTheme();
    const shouldShowEnvInput = !shouldUseSubdomainAsEnv(location.host);
    React.useEffect(() => {
        if (config.apiHost) {
            // if the config contains an apiHost that might come from inline data
            // such as the subdomain or query params thus we need to do an initial
            // check on component mount
            void checkEnvironment(config.apiHost);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        // comparing if urls have changed is trickier than comparing statuses
        // whenever urls change, the new url initially has status === "checking"
        // so we need to wait until success
        if (prevEnvInfo.status !== envInfo.status) {
            if (envInfo.status === EnvironmentInfoStatus.Success) {
                remoteRequest.changeApiHost(envInfo.url);
                setShowPasswordResetModal(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [envInfo]);
    async function checkEnvironment(envInput) {
        if (!envInput) {
            setEnvInfo({ status: EnvironmentInfoStatus.None });
            return;
        }
        const fullUrl = envToURL(envInput);
        // if we already had a successful url set don't do anything
        if (envInfo.status === EnvironmentInfoStatus.Success && envInfo.url === fullUrl) {
            return;
        }
        setEnvInfo({
            status: EnvironmentInfoStatus.Checking,
        });
        const api = createCoreApiService({ baseUrl: fullUrl });
        try {
            const availableAuthProviders = await api.auth.getAvailableProviders();
            if (!!availableAuthProviders.length) {
                // For now Core only supports one provider from Keycloak
                // Saving it for SSO authorisation
                authStore.setAuthProvider(availableAuthProviders[0]);
                authStore.initSSOService(urlToEnv(envInput));
            }
            else {
                authStore.resetAuthProvider();
                authStore.destroySSOService();
            }
            setEnvInfo({
                status: EnvironmentInfoStatus.Success,
                url: fullUrl,
            });
        }
        catch (err) {
            setEnvInfo({
                status: EnvironmentInfoStatus.Error,
            });
            authStore.resetAuthProvider();
            authStore.destroySSOService();
        }
    }
    async function handleSubmit(event) {
        event.preventDefault();
        if (envInfo.status === EnvironmentInfoStatus.Success && config.apiHost !== envInfo.url) {
            remoteRequest.changeApiHost(envInfo.url);
        }
        void login();
    }
    async function login() {
        setIsAuthenticating(true);
        const loginOptions = {
            rememberUser: rememberUser,
        };
        try {
            await authStore.signIn(email, password, loginOptions);
            const initialRoute = new URLSearchParams(props.location.search).get(REDIRECT_ROUTE_QUERY_PARAM);
            if (initialRoute) {
                props.history.replace(initialRoute);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                coreStore.notifications.showNotification({
                    type: EnvironmentInfoStatus.Error,
                    message: err.message,
                });
            }
        }
        finally {
            setIsAuthenticating(false);
        }
    }
    function isValid() {
        return (email && password && isValidEmail(email) && envInfo.status === EnvironmentInfoStatus.Success);
    }
    function closeModal() {
        setShowPasswordResetModal(false);
    }
    function startPasswordReset() {
        setShowPasswordResetModal(true);
    }
    function togglePasswordVisibility() {
        setIsPasswordVisible(!isPasswordVisible);
    }
    return (React.createElement(Base, { "data-cy": "login-page", height: '100vh', display: 'flex', flexDirection: 'column', className: css({ background: theme.palette.background.default }) },
        React.createElement(Button, { sx: { m: 4, visibility: envInfo.status === 'error' ? 'visible' : 'hidden' }, variant: "outlined", style: { alignSelf: 'flex-end' }, "data-cy": "offline-triaging-btn", startIcon: React.createElement(CloudOffIcon, null), onClick: () => {
                props.history.push(`/emergency-triage/loadgraph/indexdb`);
            } }, intl.t('offlineTriaging', 'Offline Triaging')),
        React.createElement(Base, { flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
            React.createElement(Card, { display: "flex", flexDirection: "column", gap: 8, p: 8, width: "90%", maxWidth: 450 },
                React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
                    React.createElement(Typography, { variant: "h4", color: "default" }, intl.t('authProviders.native.loginFormTitle', 'Sign in')),
                    React.createElement(EnvInfoRenderer, { envInfo: envInfo, apiInfo: remoteRequest.apiInfo })),
                React.createElement("form", { onSubmit: handleSubmit, className: css({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        gap: theme.space[7],
                    }) },
                    shouldShowEnvInput && (React.createElement(EnvironmentInput, { initialUrl: config.apiHost, onChange: checkEnvironment })),
                    React.createElement(TextField, { fullWidth: true, label: intl.t('authProviders.native.usernameLabel', 'Email'), name: "email", autoComplete: "email", value: email, onChange: (e) => setEmail(e.target.value), InputLabelProps: {
                            shrink: true,
                        } }),
                    React.createElement(TextField, { fullWidth: true, label: intl.t('authProviders.native.passwordLabel', 'Password'), type: isPasswordVisible ? 'text' : 'password', name: "password", autoComplete: "current-password", value: password, onChange: (e) => setPassword(e.target.value), InputLabelProps: {
                            shrink: true,
                        }, InputProps: {
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { onClick: togglePasswordVisibility, icon: isPasswordVisible ? (React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" })) : (React.createElement(VisibilityRoundedIcon, { fontSize: "small" })), size: "small" }))),
                        } }),
                    React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
                        config['login:enableRememberMe'] && (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: "remember", color: "primary", checked: rememberUser, onChange: (_e, checked) => setRememberUser(checked) }), label: intl.t('rememberMe', 'Keep me signed in') })),
                        React.createElement(LinkButton, { "data-cy": "forgot-password-btn", onClick: startPasswordReset, disabled: envInfo.status !== EnvironmentInfoStatus.Success }, intl.t('password-forgot', 'Forgot password?'))),
                    React.createElement(Button, { className: css({ width: '100%' }), color: "primary", type: "submit", disabled: isAuthenticating || !isValid() }, intl.t('login', 'Sign in'))),
                authStore.authProvider && (React.createElement(Button, { className: css({ width: '100%' }), color: "primary", variant: "outlined", disabled: isAuthenticating || envInfo.status !== EnvironmentInfoStatus.Success, onClick: () => {
                        if (envInfo.status !== EnvironmentInfoStatus.Success) {
                            return false;
                        }
                        const loginOptions = {
                            rememberUser: rememberUser,
                        };
                        authStore.signInWithSSO(loginOptions);
                    } }, intl.t('loginSSO', 'Single Sign On (SSO)')))),
            React.createElement(Tooltip, { disableInteractive: true, placement: "top", title: React.createElement(BuildInfo, { serverInfo: envInfo.status === EnvironmentInfoStatus.Success ? remoteRequest.apiInfo : undefined, buildInfo: env.buildInfo, textColor: "inverted" }) },
                React.createElement(Typography, { variant: "caption", color: "default", noSelect: true, mt: 8 },
                    intl.t('version', 'Version'),
                    ": ",
                    env.buildInfo.version)),
            React.createElement(Base, { mt: 5 },
                React.createElement(LinkButton, { onClick: () => zendeskWidgetService.show(), disabled: !zendeskWidgetService.isReady(), "data-cy": "support-button" }, intl.t('support', 'Support')))),
        React.createElement(Modal, { open: showPasswordResetModal, onClose: closeModal, size: "small" },
            React.createElement(Base, null,
                React.createElement(ModalHeader, { title: intl.t('team:passwordResetForm.title', 'Reset User Password') }),
                React.createElement(ModalContent, null,
                    React.createElement(PasswordResetForm, { onSubmit: closeModal }))))));
});
