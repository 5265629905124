var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, autorun, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { DEFAULT_LOCALE_KEY, isDateFormatValid, isLocaleKeyValid, isTimeFormatValid, isTimeZoneValid, setDateFormat, setLocale, setTimeFormat, setTimeZone, } from '@corti/date';
import { intl } from '@corti/i18n';
import { api, } from '@corti/lib/coreApiService';
import { Observer } from '@corti/observer';
import { errorReportService, rbacService, satismeterService, trackerService, } from 'browser/services/init';
import { KeycloakService } from 'browser/services/keycloak.service';
import { ThemeUtils } from 'browser/themes';
import { repositories } from 'core/repositories';
import { createCurrentUser } from './CurrentUserModel';
export class AuthStore extends Observer {
    constructor(appDB) {
        super();
        Object.defineProperty(this, "appDB", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: appDB
        });
        Object.defineProperty(this, "keycloakService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_currentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_organization", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authProvider", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "signIn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (email, password, options = {}) => {
                this.performPreSigning(options);
                return await api.auth.signInWithEmailAndPassword({ email, password });
            }
        });
        Object.defineProperty(this, "setSentryUserContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                errorReportService.setUserContext({
                    id: this.currentUser.id,
                    username: this.currentUser.name,
                    organizationID: this.currentUser.organization.id,
                });
            }
        });
        Object.defineProperty(this, "logout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                api.auth.signOut();
            }
        });
        /**
         * Check if the feature is enabled for current organization
         */
        Object.defineProperty(this, "isFeatureEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (featureID) => {
                if (!this._organization) {
                    return false;
                }
                return Boolean(this._organization.features.find((f) => f.featureID === featureID));
            }
        });
        makeObservable(this);
        runInAction(() => {
            this._currentUser = undefined;
            this._organization = undefined;
            this.authToken = api.auth.getAuthToken();
            this.authProvider = undefined;
        });
        autorun(async () => {
            if (this._currentUser) {
                await this.appDB.currentUser.put(toJS(this._currentUser));
            }
        });
        autorun(async () => {
            if (this._organization) {
                await this.appDB.organization.put(toJS(this._organization));
            }
        });
        api.auth.onSignedIn((user) => {
            runInAction(() => {
                this.authToken = user.token;
            });
            trackerService.track('Logged In');
        });
        api.auth.onSignedOut(async () => {
            rbacService.setUser(undefined);
            trackerService.track('Logged Out');
            errorReportService.clearUserContext();
            runInAction(() => {
                this.authToken = undefined;
                this._currentUser = undefined;
                this._organization = undefined;
            });
            await this.appDB.currentUser.clear();
            await this.appDB.organization.clear();
        });
        repositories.roles.onRoleUpdated((role) => {
            if (role.id === this.currentUser.role.id) {
                runInAction(() => {
                    this.currentUser.role = role;
                    rbacService.setUser({
                        id: this.currentUser.id,
                        permissions: this.currentUser.role.permissions.map((p) => p.id),
                    });
                });
            }
        });
    }
    async loadContent() {
        const cachedUser = api.auth.user;
        if (!cachedUser) {
            throw new Error('Unable to fetch user profile when no user is logged in');
        }
        try {
            const user = await api.users.getCurrentUserAndOrganization(cachedUser.id);
            runInAction(() => {
                this._currentUser = createCurrentUser(user);
                this._organization = user.organization;
            });
        }
        catch (err) {
            console.error(err);
            // In case of the network error load data from the local db
            const currentUser = await this.appDB.currentUser.get(cachedUser.id);
            const organization = await this.appDB.organization.get(cachedUser.orgID);
            runInAction(() => {
                this._currentUser = currentUser;
                this._organization = organization;
            });
        }
        finally {
            if (this.currentUser.settings.locale) {
                await intl.changeLanguage(this.currentUser.settings.locale);
                isLocaleKeyValid(this.currentUser.settings.locale)
                    ? setLocale(this.currentUser.settings.locale)
                    : setLocale(DEFAULT_LOCALE_KEY);
            }
            this.setSentryUserContext();
            trackerService.identifyUser(this.currentUser);
            trackerService.identifyGroup(this.organization);
            satismeterService.identifyUser(this.currentUser);
            rbacService.setUser({
                id: this.currentUserID,
                permissions: this.currentUser.role.permissions.map((p) => p.id),
            });
            this.setDateFormatterParameters();
            this.setAllowTracking();
            this.fireEvent('loaded');
        }
    }
    async signInWithSSO(options = {}) {
        var _a;
        if (!this.authProvider)
            return;
        this.performPreSigning(options);
        await ((_a = this.keycloakService) === null || _a === void 0 ? void 0 : _a.login());
    }
    async setAuthProvider(authProvider) {
        this.authProvider = authProvider;
    }
    async resetAuthProvider() {
        this.authProvider = undefined;
    }
    initSSOService(envInput) {
        if (!this.keycloakService) {
            this.keycloakService = new KeycloakService(envInput);
        }
        this.keycloakService.onSignedIn(async () => {
            await this.signInWithSSOTokenToCore();
        });
    }
    destroySSOService() {
        this.keycloakService = undefined;
    }
    async signInWithSSOTokenToCore() {
        var _a;
        if (((_a = this.keycloakService) === null || _a === void 0 ? void 0 : _a.isAuthenticated) &&
            this.keycloakService.keycloakIDToken &&
            this.authProvider) {
            return await api.auth.signInWithKeycloakToken(this.authProvider.id, this.keycloakService.keycloakIDToken);
        }
    }
    async signInWithToken(token, options = {}) {
        this.performPreSigning(options);
        if (api.auth.getAuthToken() === token) {
            return;
        }
        if (this.isAuthenticated) {
            this.logout();
        }
        return await api.auth.signInWithToken({ token });
    }
    performPreSigning(arg) {
        api.auth.setPersistence(arg.rememberUser ? 'local' : 'session');
    }
    get isAuthenticated() {
        return Boolean(this.authToken);
    }
    get isLoaded() {
        return Boolean(this._organization && this._currentUser);
    }
    get organization() {
        if (!this._organization) {
            throw new Error('API only allowed when auth store has loaded the organization. Call `isLoaded` first');
        }
        return this._organization;
    }
    get currentUser() {
        if (!this._currentUser) {
            throw new Error('API only allowed when store has loaded the current user. Call `isLoaded` first');
        }
        return this._currentUser;
    }
    setDateFormatterParameters() {
        const { clientSettings } = this.organization;
        if (clientSettings.dateFormat && isDateFormatValid(clientSettings.dateFormat)) {
            setDateFormat(clientSettings.dateFormat);
        }
        if (clientSettings.timeFormat && isTimeFormatValid(clientSettings.timeFormat)) {
            setTimeFormat(clientSettings.timeFormat);
        }
        if (clientSettings.timeZone && isTimeZoneValid(clientSettings.timeZone)) {
            setTimeZone(clientSettings.timeZone);
        }
    }
    setAllowTracking() {
        var _a;
        const { clientSettings } = this.organization;
        trackerService.setupDatadogInfo({
            allowSessionRecording: (_a = clientSettings.allowSessionRecording) !== null && _a !== void 0 ? _a : false,
        });
    }
    get themeID() {
        const themeID = this._currentUser && this._currentUser.settings.themeID;
        const fallbackThemeID = ThemeUtils.getDefaultTheme().id;
        return themeID ? themeID : fallbackThemeID;
    }
    get theme() {
        const userTheme = ThemeUtils.getTheme(this.themeID);
        return userTheme ? userTheme.config : ThemeUtils.getDefaultTheme().config;
    }
    get currentUserID() {
        return this.currentUser.id;
    }
    async updateUserProfile(input) {
        try {
            const settings = Object.assign(Object.assign({}, this.currentUser.settings), input.settings);
            const updatedUser = await api.users.update(this.currentUser.id, Object.assign(Object.assign({}, input), { settings }));
            runInAction(() => {
                this.currentUser.name = updatedUser.name;
                this.currentUser.email = updatedUser.email;
                this.currentUser.extension = updatedUser.extension;
                this.currentUser.settings = settings;
            });
            return { data: updatedUser };
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                return { error: { message: err.message } };
            }
        }
    }
    async updateOrganizationName(name) {
        try {
            const updatedName = await api.orgs.updateOrganizationName(this.organization.id, name);
            runInAction(() => {
                this.organization.name = updatedName;
            });
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                return { error: { message: err.message } };
            }
        }
    }
    async updateOrganizationClientSettings(input) {
        try {
            const updatedOrgClientSettings = await api.orgs.updateOrganizationClientSettings(this.organization.id, Object.assign(Object.assign({}, this.organization.clientSettings), input));
            runInAction(() => {
                this.organization.clientSettings = updatedOrgClientSettings;
            });
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                return { error: { message: err.message } };
            }
        }
    }
    async updateOrganizationTriageSettings(input) {
        try {
            const updatedTriageSettings = await api.orgs.updateTriageSettings(this.organization.id, Object.assign(Object.assign({}, this.organization.triageSettings), input));
            runInAction(() => {
                this.organization.triageSettings = updatedTriageSettings;
            });
        }
        catch (err) {
            console.error(err);
            if (err instanceof Error) {
                return { error: { message: err.message } };
            }
        }
    }
    onLogout(cb) {
        return api.auth.onSignedOut(cb);
    }
    /**
     * Event fired when user has fully logged in (auth + user profile)
     */
    onLoaded(cb) {
        return this.on('loaded', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AuthStore.prototype, "_currentUser", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Organization !== "undefined" && Organization) === "function" ? _a : Object)
], AuthStore.prototype, "_organization", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AuthStore.prototype, "authToken", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], AuthStore.prototype, "authProvider", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], AuthStore.prototype, "setAuthProvider", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AuthStore.prototype, "resetAuthProvider", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "isAuthenticated", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "isLoaded", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "organization", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "currentUser", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "themeID", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "theme", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], AuthStore.prototype, "currentUserID", null);
