import { css } from './styled';
export function getFocusCss({ theme, asStyleObject = false, }) {
    const focusStyles = {
        ':focus': {
            outline: `${theme.palette.primary.dark} solid 2px`,
            outlineOffset: '1px',
        },
    };
    if (asStyleObject) {
        return focusStyles;
    }
    return css(focusStyles);
}
