import { Typography } from '@mui/material';
import React from 'react';
import { Base, ChipV2, Tooltip } from 'lib/cortiUI';
export function SmartTarget(props) {
    const { label, suffix, tooltipTitle, color, startIcon, disabled, onClick } = props;
    const main = (React.createElement(ChipV2, { color: color, icon: startIcon, label: React.createElement(Base, { display: "inline-flex", width: '100%', gap: 2 },
            React.createElement(Typography, { noWrap: true, variant: "inherit" }, label),
            suffix && (React.createElement(Typography, { noWrap: true, variant: "inherit", flexShrink: 0 },
                "#",
                suffix))), clickable: !disabled, variant: 'outlined', sx: {
            borderRadius: '6px',
        }, onClick: onClick }));
    if (tooltipTitle) {
        return (React.createElement(Tooltip, { placement: "right", title: tooltipTitle }, main));
    }
    return main;
}
