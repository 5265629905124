import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, ChipV2, ListItemText, Menu, MenuItem, Tooltip, Typography } from 'lib/cortiUI';
export const TemplateSelector = ({ options, selectedTemplateID, onChange, }) => {
    const { t } = useTranslation('caseView', {
        keyPrefix: 'rightSidePanel.components.templateSelector',
    });
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const selectedOption = options.find((it) => it.id === selectedTemplateID);
    if (!selectedOption)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(ChipV2, { onClick: options.length <= 1
                ? undefined
                : ({ currentTarget }) => {
                    setMenuAnchorEl(currentTarget);
                }, label: React.createElement(Base, { display: "grid", gridTemplateColumns: "auto auto", gap: 3 },
                React.createElement(Typography, { variant: "caption", noWrap: true }, selectedOption.primaryText),
                options.length > 1 ? (Boolean(menuAnchorEl) ? (React.createElement(KeyboardArrowUpRoundedIcon, { fontSize: "small" })) : (React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }))) : null) }),
        options.length > 1 && (React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl, disablePortal: true }, options.map((option) => (React.createElement(MenuItem, { onClick: () => {
                onChange(option.id);
                setMenuAnchorEl(null);
            }, selected: option.id === selectedTemplateID, gap: 9, key: option.id },
            React.createElement(ListItemText, { primary: option.primaryText, secondary: option.secondaryText }),
            React.createElement(Base, { display: "flex", gap: 3 },
                option.default && React.createElement(ChipV2, { label: t('default', 'Default') }),
                option.rightText && (React.createElement(Tooltip, { title: option.rightTextTooltip },
                    React.createElement(Typography, { color: "secondaryText" }, option.rightText)))))))))));
};
