import React from 'react';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { Base, Skeleton } from 'lib/cortiUI';
export const CallTakersDataGridLoader = () => {
    var _a, _b;
    const rowArr = [0, 1, 2];
    // This is the duplication key from admin-panel
    const USER_SESSION_STORAGE_TOGGLE_TREND_KEY = 'adminPanel:toggleTrendValue';
    const isTrendEnabled = (_b = JSON.parse((_a = userSessionStorage.getItem(USER_SESSION_STORAGE_TOGGLE_TREND_KEY)) !== null && _a !== void 0 ? _a : 'null')) !== null && _b !== void 0 ? _b : false;
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2 }, rowArr.map((num) => (React.createElement(React.Fragment, { key: num },
        React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 1 }),
        React.createElement(Base, { display: "flex", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 3 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            isTrendEnabled && React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } })))))));
};
