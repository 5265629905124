import React from 'react';
import { css } from '@corti/style';
import { CardContent, CardHeader, Card as CortiUICard } from 'lib/cortiUI';
import { CardSubheader } from './CardSubheader';
import { CardTitle } from './CardTitle';
import { GradientCard } from './GradientCard';
export const Card = ({ title, description, tooltip, gradientColor, children, }) => {
    const CardComponent = gradientColor ? GradientCard : CortiUICard;
    return (React.createElement(CardComponent, { height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gradientColor: gradientColor !== null && gradientColor !== void 0 ? gradientColor : '' },
        React.createElement(CardHeader, { sx: { p: 2 }, disableTypography: true, title: React.createElement(CardTitle, { text: title, tooltip: tooltip }), subheader: description && React.createElement(CardSubheader, { text: description }) }),
        React.createElement(CardContent, { sx: { p: 2, py: 0 }, classes: {
                root: css({
                    '&:last-child': {
                        paddingBottom: 8,
                    },
                }),
            } }, children)));
};
