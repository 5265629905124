var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Observer } from 'mobx-react';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Avatar, Base, Box, Card, Typography } from 'lib/cortiUI';
export function CaseFeedbackListItem(props) {
    const theme = useTheme();
    return (React.createElement(Observer, null, () => {
        const { feedbackFormSubmission } = props, rest = __rest(props, ["feedbackFormSubmission"]);
        const { createdAt, createdBy, template, dispatcherAcknowledgedAt } = feedbackFormSubmission;
        const isAcknowledged = !!dispatcherAcknowledgedAt;
        return (React.createElement(Card, Object.assign({ p: 6, clickable: true, height: "100%", width: "100%" }, rest),
            React.createElement(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "center", mb: 2 },
                React.createElement(Typography, { noWrap: true, variant: "caption", color: "secondaryText" }, formatDateTime(new Date(createdAt))),
                React.createElement(Box, { flexDirection: "row", alignItems: "center", "data-cy-state": `is-acknowledged-${isAcknowledged}` },
                    React.createElement(Typography, { noWrap: true, variant: "caption", color: "secondaryText" }, isAcknowledged
                        ? intl.t('explore:feedbackView.submissionAcknowledged', 'Acknowledged')
                        : intl.t('explore:feedbackView.submissionNotAcknowledged', 'Confirmation needed')),
                    React.createElement(Base, { ml: 3, className: css({
                            height: 7,
                            width: 7,
                            borderRadius: '50%',
                            backgroundColor: isAcknowledged
                                ? theme.palette.success.main
                                : theme.palette.warning.main,
                        }) }))),
            React.createElement(Typography, { variant: "body1", mt: 4 }, template.title),
            createdBy && (React.createElement(Box, { flexDirection: "row", alignItems: "center", mt: 4, gap: 4 },
                React.createElement(Avatar, { size: "small" }, createdBy.name),
                React.createElement(Typography, { variant: "body2", noWrap: true }, createdBy.name)))));
    }));
}
