import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, ListItem, Paper, Popper, Typography } from 'lib/cortiUI';
export function TopToolbarCaseProp({ customProperties }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const isPopperOpen = Boolean(anchorEl);
    const handleOpenPopper = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    return (React.createElement(Base, { display: "flex", justifyContent: "flex-start" },
        React.createElement(Button, { size: "small", variant: "text", onClick: handleOpenPopper, endIcon: isPopperOpen ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null) },
            React.createElement(Typography, { variant: "body2" }, intl.t('explore:caseView.caseProperties', 'Case properties'))),
        React.createElement(Popper, { sx: { height: '80%', overflow: 'hidden' }, anchorEl: anchorEl, open: isPopperOpen, disablePortal: true, placement: "bottom-start" },
            React.createElement(Base, { display: "flex", maxHeight: "100%" },
                React.createElement(Paper, { className: css(getScrollerCss({ theme })) }, Object.entries(customProperties).map(([key, value]) => (React.createElement(ListItem, { dense: true, key: key, style: { backgroundColor: 'transparent' } },
                    React.createElement(Base, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { color: "default", variant: "body2" }, key),
                        React.createElement(Typography, { color: "secondaryText", variant: "body2" }, value))))))))));
}
