var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { computed, makeObservable } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { GqlMediaProvider } from '@corti/lib/graphs/media/gqlMediaProvider';
export class MediaModuleProvider extends GqlMediaProvider {
    constructor() {
        super();
        Object.defineProperty(this, "uploadFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (file) => {
                var _a;
                if (!api.auth.user) {
                    return;
                }
                await api.media.createMediaAsset(file, (_a = api.auth.user) === null || _a === void 0 ? void 0 : _a.orgID);
                void this.refreshMediaAssets();
            }
        });
        makeObservable(this);
    }
    get mediaAssets() {
        return Array.from(this.mediaAssetsByID.values());
    }
}
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], MediaModuleProvider.prototype, "mediaAssets", null);
