import { isEqual } from 'lodash';
import React from 'react';
import { Autocomplete, TextField } from 'lib/cortiUI';
import { TEST_IDS } from './utils';
export const SingleAutocompleteField = ({ name, options, value, onChange, label, freeSolo, rules, error, errorMessage, }) => {
    const handleChange = (_e, nextValue) => {
        const getValue = () => {
            if (typeof nextValue !== 'string')
                return nextValue;
            const trimmedValue = nextValue.trim();
            if (!trimmedValue)
                return null;
            return { label: trimmedValue, value: trimmedValue };
        };
        onChange(getValue());
    };
    return (React.createElement(Autocomplete, { "data-cy": TEST_IDS.singleAutocomplete, options: options, freeSolo: freeSolo || !options.length, disableClearable: true, clearOnEscape: true, autoSelect: true, value: value, includeInputInList: true, isOptionEqualToValue: (o, v) => isEqual(o, v), onChange: handleChange, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { name: name, label: label, placeholder: label, required: Boolean(rules === null || rules === void 0 ? void 0 : rules.required), error: error, helperText: errorMessage }))) }));
};
