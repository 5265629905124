import React from 'react';
import { intl } from '@corti/i18n';
import { EnvIndicator } from 'browser/components';
import { Loading } from 'lib/cortiUI';
export var EnvironmentInfoStatus;
(function (EnvironmentInfoStatus) {
    EnvironmentInfoStatus["None"] = "none";
    EnvironmentInfoStatus["Checking"] = "checking";
    EnvironmentInfoStatus["Success"] = "success";
    EnvironmentInfoStatus["Error"] = "error";
})(EnvironmentInfoStatus || (EnvironmentInfoStatus = {}));
function getEnvInfoIndicator(envInfo, apiInfo) {
    switch (envInfo.status) {
        case EnvironmentInfoStatus.Success:
            return {
                children: apiInfo === null || apiInfo === void 0 ? void 0 : apiInfo.name,
                type: (apiInfo === null || apiInfo === void 0 ? void 0 : apiInfo.isPrimary) ? 'success' : 'warning',
                tooltipText: (apiInfo === null || apiInfo === void 0 ? void 0 : apiInfo.isPrimary)
                    ? intl.t('envIndicator.prodEnv', 'Production environment.')
                    : intl.t('envIndicator.nonProdEnv', 'Non production environment.'),
            };
        case EnvironmentInfoStatus.Checking:
            return {
                children: React.createElement(Loading, { size: "small" }),
                type: 'default',
                tooltipText: '',
            };
        case EnvironmentInfoStatus.Error:
            return {
                children: 'Error',
                type: 'error',
                tooltipText: intl.t('envIndicator.error', 'Environment unavailable'),
            };
        case EnvironmentInfoStatus.None:
            return {
                children: '-',
                type: 'default',
                tooltipText: intl.t('envIndicator.missingEnv', 'You need to enter environment'),
            };
    }
}
export function EnvInfoRenderer(props) {
    const envInfoIndicator = getEnvInfoIndicator(props.envInfo, props.apiInfo);
    return envInfoIndicator ? (React.createElement(EnvIndicator, { "data-cy": "env-indicator-tag", "data-cy-env-status": props.envInfo.status, type: envInfoIndicator.type, tooltipText: envInfoIndicator.tooltipText }, envInfoIndicator.children)) : null;
}
