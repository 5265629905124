import { FormTemplateUtils } from './FormTemplate';
function getFieldValue(id, formValue) {
    const field = formValue.fields[id];
    return field ? field.value : undefined;
}
function getFieldComment(id, formValue) {
    const field = formValue.fields[id];
    return field ? field.comment : undefined;
}
function isEmpty(value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }
    return value == null;
}
function getNotFilledRequiredFields(formValue, template) {
    return template.fields
        .filter((field) => {
        const fieldValue = getFieldValue(field.id, formValue);
        if (isFieldVisible(field, formValue) && field.isRequired && isEmpty(fieldValue)) {
            return true;
        }
        return false;
    })
        .map((field) => field.id);
}
function isFieldVisible(field, formValue) {
    if (field.visibleIf == null) {
        return true;
    }
    if (field.visibleIf.conditions.length === 0) {
        return true;
    }
    return field.visibleIf.conditions[field.visibleIf.junction === 'AND' ? 'every' : 'some']((condition) => {
        const targetFieldValue = getFieldValue(condition.fieldID, formValue);
        if (targetFieldValue === undefined) {
            return false;
        }
        switch (condition.comparison) {
            case 'eq':
                return targetFieldValue === condition.values[0];
            case 'neq':
                return targetFieldValue !== condition.values[0];
            default:
                throw new Error(`Unknown visibility condition comparison: ${condition.comparison}`);
        }
    });
}
function formValueToSubmission(formValue, template) {
    const submission = { fields: {} };
    Object.entries(formValue.fields).forEach(([fieldID, val]) => {
        const field = FormTemplateUtils.getField(template, fieldID);
        if (field &&
            isFieldVisible(field, formValue) &&
            (!isEmpty(val.value) || !isEmpty(val.comment))) {
            submission.fields[fieldID] = {
                field: field,
                value: val.value,
                comment: val.comment,
            };
        }
    });
    return submission;
}
export const FormValueUtils = {
    isFieldVisible,
    getFieldValue,
    getFieldComment,
    getNotFilledRequiredFields,
    formValueToSubmission,
};
