import React from 'react';
import { useTranslation } from '@corti/i18n';
import { createQuestion, deleteQuestion, getQuestions, } from '@corti/mission-control-api';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, Typography } from 'lib/cortiUI';
import { QuestionProvider, useSaving, useTemplate } from '../context';
import { Actions } from './Actions';
import { Question } from './Question';
import { QuestionLoader } from './QuestionLoader';
export function Questions() {
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions' });
    const { templateID } = useTemplate();
    const { setIsSaving } = useSaving();
    const theme = useTheme();
    const [questions, setQuestions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [activeQuestionID, setActiveQuestionID] = React.useState(null);
    React.useEffect(() => {
        void loadQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateID]);
    const loadQuestions = async () => {
        var _a;
        setLoading(true);
        try {
            const response = await getQuestions(templateID);
            activateQuestion((_a = response[0]) === null || _a === void 0 ? void 0 : _a.id);
            setQuestions(response);
        }
        catch (err) {
            coreStore.notifications.showNotification({
                message: t('actions.loadError', 'An error occurred while loading questions'),
                type: 'error',
            });
            console.error('[Template Builder]: Load template questions', err);
        }
        finally {
            setLoading(false);
        }
    };
    const activateQuestion = (questionID) => {
        if (!questionID) {
            console.error('Must provide a questionID to focus on');
            return;
        }
        if (activeQuestionID === questionID)
            return;
        setActiveQuestionID(questionID);
    };
    const removeQuestion = async (questionID) => {
        try {
            setIsSaving(true);
            await deleteQuestion(templateID, questionID);
            setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== questionID));
            coreStore.notifications.showNotification({
                message: t('actions.deleteSuccess', 'Question is deleted!'),
                type: 'success',
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.deleteError', 'Failed to delete question'),
                type: 'error',
            });
            console.error(`[Template Builder] Delete question with ID: ${questionID}`, error);
        }
        finally {
            setIsSaving(false);
        }
    };
    const addNewQuestion = async () => {
        try {
            setIsSaving(true);
            const DEFAULT_QUESTION_PAYLOAD = {
                answer_type: 'MULTIPLE_CHOICE',
                required: false,
                question: 'New question',
            };
            const response = await createQuestion(templateID, DEFAULT_QUESTION_PAYLOAD);
            const newQuestion = Object.assign(Object.assign(Object.assign({}, response), DEFAULT_QUESTION_PAYLOAD), { template_id: templateID, max_score: 0, text: DEFAULT_QUESTION_PAYLOAD.question });
            setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
            setActiveQuestionID(response.id);
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.addQuestionError', 'Failed to add a new question'),
                type: 'error',
            });
            console.error('[Template Builder]: Add a question', error);
        }
        finally {
            setIsSaving(false);
        }
    };
    const handleCopy = (question) => {
        setQuestions((prevQuestions) => [...prevQuestions, question]);
        setActiveQuestionID(question.id);
    };
    if (loading) {
        return (React.createElement(Base, { display: "grid", gap: 6 },
            React.createElement(QuestionLoader, null),
            React.createElement(QuestionLoader, null)));
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, pb: 6, className: css(getScrollerCss({ theme })) },
        React.createElement(Typography, null, t('sectionTitle', 'Questions')),
        questions.map((question) => (React.createElement(QuestionProvider, { key: question.id, questionID: question.id },
            React.createElement(Question, { isActive: activeQuestionID === question.id, onClick: activateQuestion, onDelete: () => removeQuestion(question.id), 
                // FIXME: update when BE returns `answer_type` and `required` fields
                question: question, onCopy: handleCopy })))),
        React.createElement(Actions, { onCreateQuestion: addNewQuestion })));
}
