import { reaction } from 'mobx';
import { EntityFactory } from 'lib/triageSession/utils';
export class TakeoverRequestsAdapter {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "unsubscribers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "init", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { sessionController, sessionRepository } = this.input;
                this.unsubscribers = [
                    sessionController.connectedUsers.onUsersChange(this.handleUsersChange),
                    sessionController.connectedUsers.onUserDisconnect(this.handleUserDisconnect),
                    sessionController.takeoverRequests.onCreate(this.handleTakeoverRequestCreate),
                    sessionController.takeoverRequests.onAccept(this.handleTakeoverRequestAccept),
                    sessionController.takeoverRequests.onDecline(this.handleTakeoverRequestDecline),
                    sessionRepository.onSessionOwnershipRequested(sessionController.id, this.handleOwnershipRequestCreate),
                    sessionRepository.onSessionOwnershipAccepted(sessionController.id, this.handleOwnershipRequestAccept),
                    sessionRepository.onSessionOwnershipDeclined(sessionController.id, this.handleOwnershipRequestDecline),
                    sessionRepository.onSessionOwnershipCanceled(sessionController.id, this.handleOwnershipRequestCancel),
                ];
            }
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.unsubscribers.forEach((it) => it());
                this.unsubscribers = [];
            }
        });
        Object.defineProperty(this, "handleUserDisconnect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                if (this.input.sessionController.isOwnerUser(user.id) &&
                    !this.input.sessionController.isCurrentUser(user.id)) {
                    this.input.sessionNotifications.showTakeoverSuggestedNotification({
                        onAccept: () => {
                            this.input.sessionController.takeoverRequests.create(EntityFactory.createTakeoverRequest(this.input.sessionController.currentUser.id, this.input.sessionController.currentUser));
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "handleTakeoverRequestCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (request) => {
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    if (this.input.sessionController.isOwnerUser(request.createdBy.id)) {
                        await this.input.sessionRepository.requestSessionOwnershipHandover(this.input.sessionController.id, {
                            id: request.id,
                            createdAt: request.createdAt,
                            newOwnerUserID: request.newOwnerUserID,
                        });
                        return;
                    }
                    await this.input.sessionRepository.requestSessionOwnershipTakeover(this.input.sessionController.id, {
                        id: request.id,
                        createdAt: request.createdAt,
                        newOwnerUserID: request.newOwnerUserID,
                    });
                    return;
                }
                if (this.input.sessionController.isOwnerUser(request.createdBy.id)) {
                    this.input.sessionNotifications.showHandoverRequestedNotification({
                        request: request,
                        onAccept: () => this.input.sessionController.takeoverRequests.accept(request.id),
                        onDecline: () => this.input.sessionController.takeoverRequests.decline(request.id),
                    });
                    return;
                }
                this.input.sessionNotifications.showTakeoverRequestedNotification({
                    user: this.input.sessionController.connectedUsers.getByID(request.newOwnerUserID),
                    request: request,
                    onAccept: () => this.input.sessionController.takeoverRequests.accept(request.id),
                    onDecline: () => this.input.sessionController.takeoverRequests.decline(request.id),
                    autoAcceptTimeout: this.input.sessionController.settings.isAutoSessionTakeoverEnabled
                        ? this.input.sessionController.settings.autoSessionTakeoverTimeout * 1000
                        : undefined,
                });
            }
        });
        Object.defineProperty(this, "handleTakeoverRequestAccept", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (request) => {
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    return;
                }
                await this.input.sessionRepository.acceptSessionOwnershipRequest({ requestID: request.id });
                this.input.sessionNotifications.clearNotificationByRequestID(request.id);
            }
        });
        Object.defineProperty(this, "handleTakeoverRequestDecline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (request) => {
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    const user = this.input.sessionController.isOwnerUser(request.createdBy.id)
                        ? this.input.sessionController.connectedUsers.getByID(request.newOwnerUserID)
                        : this.input.sessionController.ownerUser;
                    this.input.sessionNotifications.showUserDeclinedRequestNotification(user);
                    return;
                }
                await this.input.sessionRepository.declineSessionOwnershipRequest({ requestID: request.id });
                this.input.sessionNotifications.clearNotificationByRequestID(request.id);
            }
        });
        Object.defineProperty(this, "handleOwnershipRequestCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                const createdByID = data.type === 'HANDOVER' ? this.input.sessionController.ownerUser.id : data.newOwnerUserID;
                if (!this.input.sessionController.isCurrentUser(createdByID)) {
                    this.input.sessionController.takeoverRequests.create({
                        id: data.requestID,
                        newOwnerUserID: data.newOwnerUserID,
                        createdAt: data.createdAt,
                        createdBy: {
                            id: createdByID,
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "handleOwnershipRequestAccept", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                const request = this.input.sessionController.takeoverRequests.getByID(data.requestID);
                if (!request) {
                    return;
                }
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    this.input.sessionController.takeoverRequests.accept(data.requestID);
                }
            }
        });
        Object.defineProperty(this, "handleOwnershipRequestDecline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                const request = this.input.sessionController.takeoverRequests.getByID(data.requestID);
                if (!request) {
                    return;
                }
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    this.input.sessionController.takeoverRequests.decline(data.requestID);
                }
            }
        });
        Object.defineProperty(this, "handleOwnershipRequestCancel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                const request = this.input.sessionController.takeoverRequests.getByID(data.requestID);
                if (!request) {
                    return;
                }
                if (this.input.sessionController.isCurrentUser(request.createdBy.id)) {
                    this.input.sessionController.takeoverRequests.cancel(data.requestID);
                }
            }
        });
        Object.defineProperty(this, "handleUsersChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.input.sessionController.isOwnerUserConnected) {
                    this.input.sessionNotifications.showTakeoverSuggestedNotification({
                        onAccept: () => {
                            this.input.sessionController.takeoverRequests.create(EntityFactory.createTakeoverRequest(this.input.sessionController.currentUser.id, this.input.sessionController.currentUser));
                        },
                    });
                }
            }
        });
        reaction(() => this.input.sessionController.settings.isTakeoverEnabled, (isEnabled) => (isEnabled ? this.init() : this.destroy()), { fireImmediately: true });
    }
}
