var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { sortBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { authStore } from 'core/auth';
export class CaseFeedbackViewState {
    constructor() {
        Object.defineProperty(this, "activeView", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeFeedbackID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeFeedbackFormValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeTemplateID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "templates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.activeView = 'feedback-list';
            this.activeFeedbackID = undefined;
            this.activeFeedbackFormValue = { fields: {} };
            this.activeTemplateID = undefined;
            this.templates = [];
        });
    }
    async loadTemplates() {
        const templates = await api.caseFeedback.getFeedbackFormTemplates(authStore.organization.id);
        runInAction(() => {
            this.templates = sortBy(templates, 'title');
            if (this.templates.length && !this.templates.some((t) => t.id === this.activeTemplateID)) {
                this.activeTemplateID = this.templates[0].id;
            }
        });
    }
    setActiveView(view) {
        this.activeView = view;
    }
    setActiveFeedbackID(feedbackID) {
        this.activeFeedbackID = feedbackID;
    }
    setFeedbackFormValue(feedbackFormValue) {
        this.activeFeedbackFormValue = feedbackFormValue;
    }
    resetFeedbackFormValue() {
        this.activeFeedbackFormValue = { fields: {} };
    }
    setActiveTemplateID(templateID) {
        this.activeTemplateID = templateID;
    }
    get activeTemplate() {
        return this.templates.find((t) => t.id === this.activeTemplateID);
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], CaseFeedbackViewState.prototype, "activeView", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], CaseFeedbackViewState.prototype, "activeFeedbackID", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseFeedbackViewState.prototype, "activeFeedbackFormValue", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], CaseFeedbackViewState.prototype, "activeTemplateID", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CaseFeedbackViewState.prototype, "templates", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseFeedbackViewState.prototype, "setActiveView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseFeedbackViewState.prototype, "setActiveFeedbackID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CaseFeedbackViewState.prototype, "setFeedbackFormValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CaseFeedbackViewState.prototype, "resetFeedbackFormValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseFeedbackViewState.prototype, "setActiveTemplateID", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseFeedbackViewState.prototype, "activeTemplate", null);
