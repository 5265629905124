import React from 'react';
import { useTranslation } from '@corti/i18n';
import { ChipV2 } from 'lib/cortiUI';
export function ScoreChip({ value, status, isHidden = false }) {
    const { t } = useTranslation('tasks', { keyPrefix: 'scoreChip' });
    function getReviewComplianceStatusPaletteColor(status) {
        switch (status) {
            case 'COMPLIANT':
                return 'success';
            case 'NON_COMPLIANT':
            case 'AUTO_FAIL':
                return 'error';
            case 'PARTIALLY_COMPLIANT':
                return 'warning';
            default:
                return 'default';
        }
    }
    function getLabel(status, value) {
        if (status === 'AUTO_FAIL') {
            return t('autoFail', 'Auto failure');
        }
        if (value) {
            return `${Math.floor(value)}%`;
        }
        return '-';
    }
    if (!status && !value) {
        return React.createElement(ChipV2, { label: "-", color: "default", size: "medium" });
    }
    return (React.createElement(ChipV2, { label: getLabel(status, value), color: getReviewComplianceStatusPaletteColor(status), sx: {
            visibility: isHidden ? 'hidden' : 'visible',
        }, size: "medium" }));
}
