import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Card, FallbackView, FocusContext } from 'lib/cortiUI';
import { BlockRenderer } from 'lib/triageFlow';
import { useToolbar } from 'lib/webappUI';
import { useTriageSession } from './TriageSessionContext';
export const FavoriteStep = observer(() => {
    const ctx = useTriageSession();
    const { isToolPanelOpened } = useToolbar();
    const theme = useTheme();
    const focusController = React.useRef(null);
    const stepModel = ctx.controller.flow.stepStore.favoriteStep;
    React.useEffect(() => {
        var _a;
        if (isToolPanelOpened) {
            (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
        }
    }, [isToolPanelOpened]);
    if (!stepModel) {
        return (React.createElement(Box, { alignItems: "center", justifyContent: "center", className: css({
                minHeight: 200,
            }) },
            React.createElement(FallbackView, { title: intl.t('realtime:triageFlowFavoriteStepNotFound', 'Quick access node was not found') })));
    }
    return (React.createElement(FocusContext, { controllerRef: focusController, disableKeyboardShortcuts: !ctx.controller.isCurrentUserOwner, keyboardShortcuts: {
            triggerFocusedElement: 'enter',
        } }, ({ registerKeyboardEventBindingElement }) => (React.createElement(Base, { ref: registerKeyboardEventBindingElement, height: "100%" },
        React.createElement(Card, { "data-cy": "favorite-step-view", p: 0, width: "100%", maxHeight: "100%", elevation: 0, className: css(getScrollerCss({ theme: theme }), {
                boxSizing: 'border-box',
                background: 'transparent',
                border: 'none',
            }) },
            React.createElement(Base, { pt: 0, pb: 7, px: 7, width: "100%", display: "grid", gridGap: theme.space[8], gridTemplateColumns: "minmax(0, 100%)", className: css({
                    boxSizing: 'border-box',
                }) }, stepModel.node.view.blocks.map((block, idx) => {
                return (React.createElement(BlockRenderer, { key: `${block.id}-${idx}`, block: block, stepModel: stepModel, flowStore: ctx.controller.flow, isReadonly: !ctx.controller.isCurrentUserOwner, shouldAutoProgress: false }));
            })))))));
});
