var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { config } from 'core/configuration/browser';
export class AppShellStore {
    constructor() {
        Object.defineProperty(this, "focusMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setFocusMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                config.setUserConfigValue('app:focusMode', value);
                this.focusMode = value;
            }
        });
        Object.defineProperty(this, "toggleFocusMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setFocusMode(!this.focusMode);
            }
        });
        makeObservable(this);
        const cfg = config.getConfig();
        runInAction(() => {
            this.focusMode = cfg['app:focusMode'];
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AppShellStore.prototype, "focusMode", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AppShellStore.prototype, "setFocusMode", void 0);
