import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { intl } from '@corti/i18n';
import { TemplatesManagement } from 'browser/pages/TemplatesManagement';
import { TemplatesManagement as OldTemplateManagement } from 'browser/pages/TemplatesManagementView';
import { rbacService } from 'browser/services/init';
import { Base, Sidebar, SidebarItem, SidebarSection, Typography, UnstyledLink } from 'lib/cortiUI';
import { Route } from 'lib/router';
import { AnnotationManagementView } from './AnnotationManagementView';
import { ModelManagementView } from './ModelManagementView';
import { TeamsView } from './Teams';
import { UserRolesView } from './UserRolesView';
import { UsersView } from './Users';
export function OrganizationSettingsView(props) {
    function getRoutes() {
        const defaultRoutes = [
            {
                id: 'users',
                title: intl.t('team:usersTab', 'Users'),
                path: `${props.match.url}/users`,
                view: React.createElement(UsersView, null),
            },
            {
                id: 'teams',
                title: intl.t('settings:navigationBar.teams', 'Teams'),
                path: `${props.match.url}/teams`,
                view: React.createElement(TeamsView, null),
            },
        ];
        if (rbacService.usersAndRolesCRUD()) {
            defaultRoutes.push({
                id: 'roles',
                title: intl.t('team:rolesTab', 'User Roles'),
                path: `${props.match.url}/roles-management`,
                view: React.createElement(UserRolesView, null),
            });
        }
        if (rbacService.timelineEntryDefinitionsCRUD()) {
            defaultRoutes.push({
                id: 'annotationManagement',
                title: intl.t('settings:navigationBar.annotationManagementTab', 'Annotations'),
                path: `${props.match.url}/annotation-management`,
                view: React.createElement(AnnotationManagementView, null),
            });
        }
        if (rbacService.aiModelDetectSubscriptionsRead()) {
            defaultRoutes.push({
                id: 'modelManagement',
                title: intl.t('settings:navigationBar.modelManagementTab', 'Topic Management'),
                path: `${props.match.url}/model-management`,
                view: React.createElement(ModelManagementView, null),
            });
        }
        if (rbacService.feedbackTemplatesCRUD()) {
            defaultRoutes.push({
                id: 'templatesManagement',
                title: intl.t('settings:navigationBar.templatesManagement', 'Feedback Templates'),
                path: `${props.match.url}/templates-management`,
                view: React.createElement(OldTemplateManagement, null),
            });
        }
        if (rbacService.missionControlAssessmentTemplatesCRUD()) {
            defaultRoutes.push({
                id: 'templates',
                title: intl.t('settings:navigationBar.templates', 'Templates'),
                path: `${props.match.url}/templates`,
                view: React.createElement(TemplatesManagement, null),
            });
        }
        return defaultRoutes;
    }
    const routes = getRoutes();
    return (React.createElement(Base, { width: "100%", height: "100%", overflow: "hidden", display: "grid", gridTemplateColumns: "auto 1fr", "data-cy": "org-settings-app" },
        React.createElement(Sidebar, { defaultMode: "opened-perm", "data-cy": "org-settings-sidebar" },
            React.createElement(SidebarSection, null, routes.map((route) => (React.createElement(UnstyledLink, { key: route.id, to: route.path, render: ({ isActive }) => (React.createElement(SidebarItem, { size: "small", selected: isActive, "data-cy": `org-settings-app-${route.id}-btn` },
                    React.createElement(Typography, { color: isActive ? 'default' : 'secondaryText' }, route.title))) }))))),
        React.createElement(Base, { overflow: "hidden", width: "100%", height: "100%", display: "grid" },
            React.createElement(Route, { exact: true, path: props.match.path },
                React.createElement(Redirect, { to: routes[0].path })),
            React.createElement(Switch, null, routes.map((route) => (React.createElement(Route, { key: route.id, path: route.path, breadcrumb: route.title }, route.view)))))));
}
