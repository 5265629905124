var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Base } from 'lib/cortiUI';
import htmlIcon from './assets/htmlIcon.svg';
import pdfIcon from './assets/pdfIcon.svg';
import unknownFileIcon from './assets/unknownIcon.svg';
function getIconForFileType(mimeType) {
    switch (mimeType) {
        case 'application/pdf':
            return pdfIcon;
        case 'text/html':
            return htmlIcon;
        default:
            return unknownFileIcon;
    }
}
export function FileTypePreview(props) {
    const { mimeType, size = 64 } = props, baseProps = __rest(props, ["mimeType", "size"]);
    const icon = getIconForFileType(mimeType);
    return (React.createElement(Base, Object.assign({ style: { backgroundImage: `url(${icon})` }, backgroundSize: "50%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: size, height: size }, baseProps)));
}
