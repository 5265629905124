import styled from '@emotion/styled';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import AutomaticIcon from '@mui/icons-material/ElectricBolt';
import ManualIcon from '@mui/icons-material/Person';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Warning from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import React from 'react';
const Unchecked = styled(UncheckedIcon) `
  color: rgba(64, 64, 88, 0.8);
`;
const Checked = () => React.createElement(CheckedIcon, { sx: { fill: 'url(#successGradient)' } });
const AutoChecked = () => React.createElement(CheckedIcon, { sx: { fill: 'url(#primaryGradient)' } });
export function Checkbox({ manualEnabled, manualChecked, autoEnabled, autoChecked, reveal, }) {
    const checked = manualChecked || autoChecked;
    const Wrapper = ({ children }) => (React.createElement(Box, { sx: {
            minWidth: 52,
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        } }, children));
    if (!manualEnabled && !autoEnabled) {
        return (React.createElement(Wrapper, null,
            React.createElement(Typography, { variant: "body2", color: "error" },
                React.createElement(Warning, null))));
    }
    if (manualEnabled && !autoEnabled) {
        return React.createElement(Wrapper, null, manualChecked ? React.createElement(Checked, null) : React.createElement(Unchecked, null));
    }
    if (!manualEnabled && autoEnabled) {
        return (React.createElement(Wrapper, null,
            React.createElement(Box, { display: "flex", position: "relative" },
                autoChecked ? React.createElement(AutoChecked, null) : React.createElement(Unchecked, null),
                React.createElement(AutoBadge, { checked: autoChecked }))));
    }
    // Okay, both options are enabled, let's either display a combined checkbox if not hovered, or
    // two separate checkboxes with badges if hovered
    if (!reveal) {
        return (React.createElement(Wrapper, null,
            React.createElement(Box, { display: "flex", position: "relative" },
                checked ? React.createElement(Checked, null) : React.createElement(Unchecked, null),
                React.createElement(ManualBadge, { checked: manualChecked }),
                React.createElement(AutoBadge, { checked: autoChecked }))));
    }
    // Return two checkboxes, one for manual and one for auto
    return (React.createElement(Wrapper, null,
        React.createElement(Box, { display: "flex", position: "relative", sx: { margin: '0 -1px' } },
            manualChecked ? React.createElement(Checked, null) : React.createElement(Unchecked, null),
            React.createElement(ManualBadge, { checked: manualChecked })),
        React.createElement(Box, { display: "flex", position: "relative", sx: { margin: '0 -1px' } },
            autoChecked ? React.createElement(AutoChecked, null) : React.createElement(Unchecked, null),
            React.createElement(AutoBadge, { checked: autoChecked }))));
}
function ManualBadge({ checked }) {
    return (React.createElement(Box, { position: "absolute", top: -6, left: -6, display: "flex" },
        React.createElement(ManualIcon, { sx: {
                color: checked ? 'var(--success-color)' : '#999',
                fontSize: '80%',
                opacity: checked ? 1 : 0.3,
            } })));
}
function AutoBadge({ checked }) {
    return (React.createElement(Box, { position: "absolute", top: -6, right: -6, display: "flex" },
        React.createElement(AutomaticIcon, { sx: {
                color: checked ? 'var(--primary-color)' : '#999',
                fontSize: '80%',
                opacity: checked ? 1 : 0.3,
            } })));
}
