import { useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Base, Checkbox, List, ListItemButton, ListItemIcon, ListItemText, SearchBar, Typography, } from 'lib/cortiUI';
import { AssignForReviewPanelLoader } from './AssignForReviewPanelLoader';
import { getUsersForAssignationQuery, } from './graphql';
export const AssignForReviewPanel = ({ caseOwner, assigneeIDs, onUserAssign, onUserRemove, }) => {
    const { t } = useTranslation();
    const { organization } = useAuth();
    const theme = useTheme();
    const { data: users, loading } = useQuery(getUsersForAssignationQuery, {
        variables: {
            organizationID: organization.id,
            permissions: ['case_feedback_form_submissions:crud', 'cases:other_users:read'],
        },
    });
    const [searchInputValue, setSearchInputValue] = React.useState('');
    function getUsers() {
        var _a;
        return uniqBy([...((_a = users === null || users === void 0 ? void 0 : users.users) !== null && _a !== void 0 ? _a : []), caseOwner], 'id').filter((user) => !searchInputValue || stringIncludes(user.name, searchInputValue));
    }
    function handleUserClick(user) {
        const isUserAssigned = assigneeIDs.includes(user.id);
        if (isUserAssigned) {
            onUserRemove(user);
        }
        else {
            onUserAssign(user);
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7, py: 6, px: 7, width: 480 },
        React.createElement(Typography, { variant: "subtitle2" }, t('explore:caseView.assignForReview', 'Assign for review to')),
        loading ? (React.createElement(AssignForReviewPanelLoader, null)) : (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7 },
            React.createElement(SearchBar, { placeholder: t('search', 'Search'), value: searchInputValue, onChange: (e) => setSearchInputValue(e.target.value) }),
            React.createElement(Base, null,
                React.createElement(Typography, { variant: "overline" }, t('explore:caseView.callTakersWithAccess', 'call-takers with access to this call')),
                React.createElement(Base, { display: "flex", height: 340, className: css(getScrollerCss({ theme })) },
                    React.createElement(List, null, getUsers().map((user) => (React.createElement(ListItemButton, { onClick: () => handleUserClick(user), classes: {
                            root: css({
                                borderRadius: 0,
                                backgroundColor: theme.palette.background.card,
                                '&:not(:last-child)': {
                                    marginBottom: 0,
                                },
                            }),
                        }, key: user.id },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Checkbox, { checked: assigneeIDs.includes(user.id), tabIndex: -1, disableRipple: true })),
                        React.createElement(ListItemText, { "data-cy": "user-search-menu-list-item-text" }, user.name)))))))))));
};
