var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CaseViewState } from './CaseView/CaseViewState';
import { CasesViewState } from './CasesView/CasesViewState';
export class ExploreViewState {
    constructor() {
        Object.defineProperty(this, "activeView", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "casesViewState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseViewState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.activeView = 'casesView';
        });
        this.casesViewState = new CasesViewState();
        this.caseViewState = new CaseViewState();
    }
    setActiveView(view) {
        this.activeView = view;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], ExploreViewState.prototype, "activeView", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ExploreViewState.prototype, "setActiveView", null);
