var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { TranscriptQueryParametersModel } from './TranscriptQueryParametersModel';
export class TranscriptQueryModel {
    constructor(query) {
        Object.defineProperty(this, "query", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: query
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "updatedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "parameters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "changeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.name = name;
            }
        });
        makeObservable(this);
        this.id = query.id;
        this.updatedAt = query.updatedAt;
        this.createdAt = query.createdAt;
        this.createdBy = query.createdByUser;
        this.parameters = new TranscriptQueryParametersModel(query.id, query.parameters);
        runInAction(() => {
            this.name = query.name;
        });
    }
    get isDraft() {
        return !this.createdAt;
    }
    get isUnsaved() {
        return (!isEqual(this.name, this.query.name) ||
            !isEqual(this.parameters.tempDateRange, this.query.parameters.dateRange) ||
            !isEqual(this.parameters.tempTermGroups, this.query.parameters.termGroups) ||
            !isEqual(this.parameters.tempUserIDs, this.query.parameters.userIDs) ||
            !isEqual(this.parameters.tempTeamIDs, this.query.parameters.teamIDs) ||
            !isEqual(this.parameters.tempProtocolNames, this.query.parameters.protocolNames));
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], TranscriptQueryModel.prototype, "name", void 0);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TranscriptQueryModel.prototype, "isUnsaved", null);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryModel.prototype, "changeName", void 0);
