import React from 'react';
import { css } from '@corti/style';
import { Base, Button } from 'lib/cortiUI';
export function NotificationActions({ actions, textColor, onActionClick, }) {
    if (!actions)
        return null;
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: 4 }, actions === null || actions === void 0 ? void 0 : actions.map((action, idx) => {
        return (React.createElement(Button, { key: `action-${idx}`, variant: "text", size: "small", className: css({ flexShrink: 0 }), style: { color: textColor }, onClick: () => onActionClick(action) }, action.text));
    })));
}
