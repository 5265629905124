import React from 'react';
import { SLIDE_SPEED } from './config';
const ToolbarContext = React.createContext(null);
/**
 * Holds and controls the state of the open tool panels and state of toolbar buttons.
 * This can be used as a controlled component or uncontrolled component
 */
export function ToolbarProvider({ children, initialState, onChange, }) {
    var _a;
    const [isToolPanelOpened, setIsToolPanelOpened] = React.useState(false);
    const [openToolID, setOpenToolID] = React.useState((_a = initialState === null || initialState === void 0 ? void 0 : initialState.openToolID) !== null && _a !== void 0 ? _a : null);
    React.useEffect(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange({ openToolID });
    }, [openToolID, onChange]);
    React.useEffect(() => {
        let timeoutID = undefined;
        if (openToolID === null) {
            setIsToolPanelOpened(false);
        }
        else {
            timeoutID = setTimeout(() => {
                setIsToolPanelOpened(true);
            }, SLIDE_SPEED);
        }
        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [openToolID]);
    const value = React.useMemo(() => ({ openToolID, isToolPanelOpened, setOpenToolID }), [openToolID, isToolPanelOpened, setOpenToolID]);
    return React.createElement(ToolbarContext.Provider, { value: value }, children);
}
export function useToolbar() {
    const context = React.useContext(ToolbarContext);
    if (!context) {
        throw new Error('useToolbar must be used within a ToolbarProvider');
    }
    return context;
}
