import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { Box } from 'lib/cortiUI';
import { FilterButton, GroupOuterShell } from '../components';
import { FilterGroupDropdown } from './FilterGroupDropdown';
import { FilterGroupPopup } from './FilterGroupPopup';
import { FilterGroupProvider } from './FilterGroupProvider';
import { FilterGroupWrapper } from './FilterGroupWrapper';
/**
 * Renders one single "filter group" that consists of
 * - attribute
 * - comparison
 * - value
 * @example 'First Name Contains Mart'
 */
export function FilterGroup({ value, attributes, removable, onRemove, onChange, }) {
    return (React.createElement(FilterGroupProvider, { attributes: attributes, value: value, onChange: onChange },
        React.createElement(Box, { "data-cy": "filter-group", flexDirection: "row", alignItems: "center" },
            React.createElement(GroupOuterShell, null,
                React.createElement(FilterGroupWrapper, null,
                    React.createElement(FilterGroupDropdown, null),
                    React.createElement(FilterGroupPopup, null))),
            removable && (React.createElement(FilterButton, { "data-cy": "filter-group-remove-button", icon: React.createElement(DeleteRoundedIcon, null), onClick: onRemove })))));
}
