import { useLazyQuery } from '@apollo/client';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { css } from '@corti/style';
import { useAuth } from 'core/auth/browser';
import { IconButton, Menu } from 'lib/cortiUI';
import { getTeamsQuery } from '../graphql';
import { DeleteTeam } from './DeleteTeam';
import { EditTeam } from './EditTeam';
export function TeamRowAction({ id }) {
    const { organization } = useAuth();
    const [fetchTeams] = useLazyQuery(getTeamsQuery, {
        variables: {
            orgID: organization.id,
        },
    });
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "data-cy": "teams-table-row-action-btn", className: css({ marginLeft: 'auto' }), icon: React.createElement(MoreHorizRoundedIcon, null), onClick: (e) => {
                e.stopPropagation();
                setMenuAnchor(e.currentTarget);
            } }),
        React.createElement(Menu, { open: Boolean(menuAnchor), anchorEl: menuAnchor, onClose: () => setMenuAnchor(null) },
            React.createElement(EditTeam, { id: id, onClose: function onEditActionClick() {
                    setMenuAnchor(null);
                }, onSubmit: fetchTeams }),
            React.createElement(DeleteTeam, { id: id, onClose: function onDeleteActionClick() {
                    setMenuAnchor(null);
                }, onSubmit: fetchTeams }))));
}
