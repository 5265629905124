import React from 'react';
import { useHistory } from 'react-router';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { Base, Button, DataGrid, Typography } from 'lib/cortiUI';
import { useDataContext, } from '../../../activeView/context/dataContext';
import { useGlobalContext } from '../../../context';
import { NoRowsOverlay } from '../../components/NoRowsOverlay';
import { CallMetricsWidget } from './CallMetricsWidget';
import { CallsDataGridLoader } from './CallsDataGridLoader';
import { Header } from './Header';
export const CallsDataGrid = ({ viewID }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'callsTable' });
    const theme = useTheme();
    const { isEditMode, isDraft } = useGlobalContext();
    const { performanceViewMetricsGroupByCall, isPerformanceViewMetricsGroupByCallLoading, fetchMorePerformanceViewMetricsGroupByCall, } = useDataContext();
    const loadMoreRef = React.useRef(null);
    const history = useHistory();
    const [selectedCallIDs, setSelectedCallIDs] = React.useState([]);
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
    };
    const readOnly = isEditMode || isDraft;
    const handleObserver = React.useCallback(async (entries) => {
        const [target] = entries;
        if (target.isIntersecting &&
            !isPerformanceViewMetricsGroupByCallLoading &&
            performanceViewMetricsGroupByCall &&
            performanceViewMetricsGroupByCall.cursor &&
            !readOnly) {
            await fetchMorePerformanceViewMetricsGroupByCall({
                variables: {
                    offset: performanceViewMetricsGroupByCall.cursor,
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performanceViewMetricsGroupByCall.cursor]);
    const observer = new IntersectionObserver(handleObserver, observerOptions);
    React.useEffect(() => {
        if (loadMoreRef.current)
            observer.observe(loadMoreRef.current);
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMoreRef, observerOptions]);
    const handleRowsSelect = (rowIDs) => {
        setSelectedCallIDs(rowIDs);
    };
    const handleSelectedRowsClear = () => {
        setSelectedCallIDs([]);
    };
    const handleRowClick = (id) => {
        history.push(`/explore/${id}?sidebarView=score&performanceViewID=${viewID}`);
    };
    const handleActionClick = (performanceMetrics) => {
        history.push(`/explore/${performanceMetrics.case.id}?sidebarView=feedback&feedbackView=feedback-create`);
        trackerService.track('[Performance] add review button in the calls table clicked', {
            data: {
                detections: performanceMetrics === null || performanceMetrics === void 0 ? void 0 : performanceMetrics.metrics.map((it) => it.value),
                caseID: performanceMetrics.case.id,
                callID: performanceMetrics.call.id,
                protocolName: performanceMetrics.call.protocolName,
            },
        });
    };
    const getColumns = () => [
        {
            field: 'dateTime',
            headerName: t('callsDataGrid.dateTime', 'Time & Date'),
            editable: false,
            sortable: false,
            flex: 3,
            renderCell: (props) => (React.createElement(Typography, { color: "default", variant: "body2", noWrap: true }, formatDateTime(new Date(props.value)))),
        },
        {
            field: 'caseID',
            headerName: t('callsDataGrid.caseID', 'Case ID'),
            editable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'externalID',
            headerName: t('callsDataGrid.externalID', 'External ID'),
            editable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'detections',
            headerName: t('callsDataGrid.detections', 'Detections'),
            editable: false,
            sortable: false,
            flex: 2,
            renderCell: ({ value }) => {
                if (!value)
                    return null;
                return React.createElement(CallMetricsWidget, { callMetrics: value });
            },
        },
        {
            field: 'protocolName',
            headerName: t('callsDataGrid.protocolName', 'Protocol Name'),
            editable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'action',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 2,
            renderCell: ({ value }) => {
                if (!value)
                    return null;
                return (React.createElement(Base, null,
                    React.createElement(Button, { color: "primary", disabled: readOnly, size: "small", variant: "outlined", onClick: (e) => {
                            e.stopPropagation();
                            handleActionClick(value);
                        } }, t('callsDataGrid.addReviewBtn', 'Add review'))));
            },
        },
    ];
    const getRows = () => {
        var _a, _b;
        return (_b = (_a = performanceViewMetricsGroupByCall.performanceMetrics) === null || _a === void 0 ? void 0 : _a.map((it) => ({
            id: it.call.id,
            dateTime: it.call.start,
            caseID: it.case.id,
            detections: it.metrics,
            protocolName: it.call.protocolName,
            externalID: it.call.externalID,
            action: it,
        }))) !== null && _b !== void 0 ? _b : [];
    };
    const rows = getRows();
    const columns = getColumns();
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, className: readOnly ? css({ opacity: 0.5, pointerEvents: 'none' }) : undefined },
        React.createElement(Header, { selectedCalls: selectedCallIDs, onSelectedCallsClear: handleSelectedRowsClear }),
        React.createElement(DataGrid, { rows: rows, columns: columns, pageSize: rows.length, checkboxSelection: rbacService.performanceMetricsExcludeCalls() && !readOnly, autoHeight: true, disableColumnFilter: true, disableColumnMenu: true, hideFooter: true, onSelectionModelChange: readOnly
                ? undefined
                : (rowIDs) => {
                    handleRowsSelect(rowIDs);
                }, selectionModel: selectedCallIDs, onRowClick: readOnly ? undefined : (params) => handleRowClick(params.row.caseID), loading: isPerformanceViewMetricsGroupByCallLoading, components: {
                LoadingOverlay: !rows.length ? CallsDataGridLoader : Base,
                NoRowsOverlay: !rows.length && isPerformanceViewMetricsGroupByCallLoading ? Base : NoRowsOverlay,
            }, classes: {
                virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
            }, sx: {
                border: 'hidden',
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none',
                },
            } }),
        !!rows.length && isPerformanceViewMetricsGroupByCallLoading && React.createElement(CallsDataGridLoader, null),
        React.createElement(Base, { ref: loadMoreRef, width: "100%", zIndex: 1, className: css({
                visibility: 'hidden',
            }) })));
};
