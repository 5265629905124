var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import FormatColorResetRoundedIcon from '@mui/icons-material/FormatColorResetRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { ButtonBase } from 'lib/cortiUI/components/Button';
import { Typography } from 'lib/cortiUI/components/Typography';
const defaultColors = [
    '#9E66F5',
    '#5266D6',
    '#017AE4',
    '#56DBB2',
    '#EBF1F8',
    '#40C130',
    '#FACD0D',
    '#F89538',
    '#F02A09',
    '#F009AF',
];
export function ColorPickerMenu(props) {
    const { colorOptions, onColorChange, onColorClick, defaultColorValue } = props, baseProps = __rest(props, ["colorOptions", "onColorChange", "onColorClick", "defaultColorValue"]);
    const inputRef = React.useRef(null);
    const theme = useTheme();
    const colors = colorOptions !== null && colorOptions !== void 0 ? colorOptions : defaultColors;
    return (React.createElement(Base, Object.assign({ background: theme.palette.background.card, maxWidth: "max-content", display: "grid", gridAutoFlow: "row", gridGap: 3, p: 3 }, baseProps),
        React.createElement(ButtonBase, { p: 2, borderRadius: 2, size: "small", width: "100%", background: theme.palette.background.default, onClick: (e) => {
                onColorClick === null || onColorClick === void 0 ? void 0 : onColorClick(e, '');
                onColorChange === null || onColorChange === void 0 ? void 0 : onColorChange('');
            } },
            React.createElement(FormatColorResetRoundedIcon, { className: css({ marginRight: 5, color: theme.palette.text.secondary }) }),
            React.createElement(Typography, { variant: "button", color: "secondaryText", uppercase: false }, intl.t('colorPicker.noColorLabel', 'None'))),
        React.createElement(Base, { display: "grid", gridTemplateColumns: "repeat(5, 30px)", gridGap: 3 }, colors.map((color, i) => (React.createElement(ButtonBase, { "data-cy": `color-variant-${i}`, key: color, borderRadius: 2, width: "100%", height: "30px", background: color, onClick: (e) => {
                onColorClick === null || onColorClick === void 0 ? void 0 : onColorClick(e, color);
                onColorChange === null || onColorChange === void 0 ? void 0 : onColorChange(color);
            } })))),
        React.createElement(ButtonBase, { p: 2, position: "relative", borderRadius: 2, size: "small", width: "100%", background: theme.palette.background.default, onClick: () => {
                var _a;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
            } },
            React.createElement(Typography, { variant: "button", color: "secondaryText", uppercase: false }, intl.t('colorPicker.customColorLabel', 'Custom')),
            React.createElement("input", { style: {
                    height: 0,
                    width: 0,
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    visibility: 'hidden',
                }, type: "color", ref: inputRef, onChange: (e) => {
                    onColorChange === null || onColorChange === void 0 ? void 0 : onColorChange(e.target.value);
                }, defaultValue: defaultColorValue }))));
}
