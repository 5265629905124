var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { debounce, isEqual } from 'lodash';
import { action, makeObservable } from 'mobx';
import { logger } from '@corti/logger';
import { Observer } from '@corti/observer';
import { CustomObserver, IntegrationObserver, StateObserver, } from '../../observers';
import { ActionStore } from '../ActionStore';
import { BlockValueStore, BlockValueUtils } from '../BlockValueStore';
import { ChecklistStore } from '../ChecklistStore';
import { CollectorStore } from '../CollectorStore';
import { FactValueStore } from '../FactValueStore';
import { StepStore } from '../StepStore';
import { TempBlockValueStore } from '../TempBlockValueStore';
import { createBaseMediaProvider } from './MediaProvider';
import { StateHandler } from './StateHandler';
const DEFAULT_DATE_FORMATTER = (date) => date.toDateString();
const DIRTY_BLOCK_VALUE_CHANGE_TIMEOUT = 300;
const canAutoProgressToNextStep = ({ step, options, settings, blockValueStore, }) => {
    if (!settings.automaticProgressToNextStep || options.shouldAutoProgress === false) {
        return false;
    }
    const { blocks } = step.node.view;
    if (blocks.some((it) => ['TEXTAREA_INPUT', 'TEXT_INPUT', 'DATE_PICKER'].includes(it.blockPrototype.type))) {
        return false;
    }
    const selectBlocks = blocks.filter((it) => it.blockPrototype.type === 'SELECT');
    if (selectBlocks.length !== 1) {
        return false;
    }
    const selectBlock = selectBlocks[0];
    if (selectBlock.blockPrototype.max !== 1) {
        return false;
    }
    const truthyValues = selectBlock.blockPrototype.options
        .map((it) => blockValueStore.getByIdentifier({
        blockPrototypeID: selectBlock.id,
        optionID: it.id,
    }))
        .filter((it) => (it === null || it === void 0 ? void 0 : it.value) === 'true');
    if (truthyValues.length !== 1) {
        return false;
    }
    return true;
};
export class FlowStore {
    constructor(graphTraverser, settings = {}) {
        var _a, _b, _c, _d;
        Object.defineProperty(this, "graphTraverser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: graphTraverser
        });
        Object.defineProperty(this, "settings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: settings
        });
        Object.defineProperty(this, "stateChangeEventHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "integrationObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stateObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * @deprecated
         */
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stepStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "factValueStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "blockValueStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempBlockValueStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "actionStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "collectorStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checklistStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mediaProvider", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCopyButtonEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "formatDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "childWindowOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.stepStore.resetAll();
                this.factValueStore.reset();
                this.blockValueStore.reset();
                this.tempBlockValueStore.reset();
                this.actionStore.resetAll();
                this.collectorStore.resetAll();
                this.registerPinnedSteps();
                this.registerFavoriteStep();
            }
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.resetAll();
                this.observer.unAll();
            }
        });
        /**
         * Initialises flow from the given node.
         * If node is not provided, will use the start node of the graph.
         */
        Object.defineProperty(this, "initFlow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (nodeID) => {
                const node = nodeID
                    ? this.graphTraverser.getNodeByID(nodeID)
                    : this.graphTraverser.getStartNode();
                if (!node) {
                    this.logger.error('Failed to initialise flow: cannot find starting node');
                    return;
                }
                const step = this.stepStore.createStep(node);
                this.addStep(step);
                this.changeActiveStep({
                    step,
                    source: 'user-action',
                    sourceData: {
                        location: 'flow',
                    },
                });
            }
        });
        Object.defineProperty(this, "addStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.stepStore.addStep(step);
            }
        });
        Object.defineProperty(this, "changeActiveStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                var _a, _b, _c;
                let step = input.step;
                if (this.stepStore.activeStep &&
                    ((_b = (_a = this.stepStore.previewStep) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.id) === step.id &&
                    this.stepStore.previewStep.type === 'unconfirmed') {
                    const tempStep = this.stepStore.determineStep(this.stepStore.activeStep, this.blockValueStore.all, this.factValueStore.all);
                    if (!tempStep) {
                        return;
                    }
                    step = tempStep;
                }
                this.handleTemporaryBlockValueChanged.cancel();
                const exists = this.stepStore.getStepByID(step.id);
                if (!exists) {
                    this.stepStore.addStep(step);
                }
                if (!this.stepStore.pathContains(step.id)) {
                    const path = this.stepStore.calculatePath(step);
                    this.stepStore.changeActivePath(path);
                    const lastStep = path[path.length - 1];
                    if (lastStep) {
                        lastStep.node.view.blocks.map((it) => {
                            if (it.blockPrototype.type === 'ACTION' && it.triggerOnMount) {
                                this.actionStore.trigger({
                                    blockPrototype: it.blockPrototype,
                                    blockInstance: it,
                                    source: input.source,
                                    sourceData: {
                                        step: lastStep,
                                        location: 'step:flow',
                                    },
                                });
                            }
                        });
                    }
                }
                this.tempBlockValueStore.reset();
                this.stepStore.resetPreviewStep();
                this.stepStore.changeActiveStep(Object.assign(Object.assign({}, input), { step }));
                const canAutoProgressToNextStep = (step) => {
                    if (!this.settings.automaticProgressToNextStep) {
                        return false;
                    }
                    const { blocks } = step.node.view;
                    if (blocks.length !== 1) {
                        return false;
                    }
                    const block = blocks[0];
                    if (block.blockPrototype.type !== 'ACTION') {
                        return false;
                    }
                    if (!block.triggerOnMount) {
                        return false;
                    }
                    return true;
                };
                if (this.stepStore.isLastActivePathStep(step.id)) {
                    this.determinePreviewStep(step);
                    if (canAutoProgressToNextStep(step) && ((_c = this.stepStore.previewStep) === null || _c === void 0 ? void 0 : _c.model)) {
                        this.changeActiveStep({
                            step: this.stepStore.previewStep.model,
                            source: input.source,
                            sourceData: {
                                location: 'flow',
                            },
                        });
                    }
                }
            }
        });
        Object.defineProperty(this, "handleFactValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.stepStore.activeStep) {
                    return;
                }
                this.determinePreviewStep(this.stepStore.activeStep);
            }
        });
        Object.defineProperty(this, "handleBlockValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                this.handleTemporaryBlockValueChanged.cancel();
                if (!this.stepStore.activeStep) {
                    return;
                }
                if (canAutoProgressToNextStep({
                    blockValueStore: this.blockValueStore,
                    step: this.stepStore.activeStep,
                    options: Object.assign({ shouldAutoProgress: true }, input.options),
                    settings: this.settings,
                })) {
                    const nextStep = this.stepStore.determineStep(this.stepStore.activeStep, this.blockValueStore.all, this.factValueStore.all);
                    if (nextStep) {
                        this.changeActiveStep({
                            step: nextStep,
                            source: input.source,
                            sourceData: {
                                location: 'flow',
                            },
                        });
                    }
                    return;
                }
                this.determinePreviewStep(this.stepStore.activeStep);
            }
        });
        Object.defineProperty(this, "handleTemporaryBlockValueChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(() => {
                if (!this.stepStore.activeStep) {
                    return;
                }
                this.determinePreviewStep(this.stepStore.activeStep);
            }, DIRTY_BLOCK_VALUE_CHANGE_TIMEOUT)
        });
        Object.defineProperty(this, "handleStepAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.registerBlockValues(step);
            }
        });
        Object.defineProperty(this, "determinePreviewStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (currentStep) => {
                if (this.tempBlockValueStore.activeValue) {
                    const combinedBlockValues = [
                        ...this.blockValueStore.all.filter((it) => !isEqual(it.identifier, this.tempBlockValueStore.activeValue.identifier)),
                        this.tempBlockValueStore.activeValue,
                    ];
                    const previewStep = this.stepStore.determineStep(currentStep, combinedBlockValues, this.factValueStore.all);
                    this.stepStore.changePreviewStep({
                        type: 'unconfirmed',
                        model: previewStep,
                    });
                    return;
                }
                const previewStep = this.stepStore.determineStep(currentStep, this.blockValueStore.all, this.factValueStore.all);
                this.stepStore.changePreviewStep({
                    type: 'confirmed',
                    model: previewStep,
                });
            }
        });
        Object.defineProperty(this, "registerPinnedSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const steps = this.graphTraverser
                    .getNodes()
                    .filter((n) => n.pinnedInTriage)
                    .map((n) => {
                    const step = this.stepStore.createStep(n);
                    this.registerBlockValues(step);
                    return step;
                });
                this.stepStore.changePinnedSteps(steps);
            }
        });
        Object.defineProperty(this, "registerFavoriteStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const steps = this.graphTraverser
                    .getNodes()
                    .filter((n) => n.favoriteNode)
                    .map((n) => {
                    const step = this.stepStore.createStep(n);
                    this.registerBlockValues(step);
                    return step;
                });
                // Editor only supports 1 favorite node per graph now.
                // UI is also not made for supporting more than 1 (e.g. keyboard shortcuts)
                // So this is a quick fix to not deal with multiple favorite steps
                this.stepStore.changeFavoriteStep(steps[0]);
            }
        });
        Object.defineProperty(this, "registerBlockValues", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                step.node.view.blocks.forEach((block) => {
                    const values = BlockValueUtils.getDefaultValuesForBlock(block.blockPrototype);
                    if (values) {
                        values.forEach((it) => {
                            if (!this.blockValueStore.getByIdentifier(it.identifier)) {
                                this.blockValueStore.add(it);
                            }
                        });
                    }
                });
            }
        });
        makeObservable(this);
        this.observer = new Observer();
        this.stepStore = new StepStore(this);
        this.factValueStore = new FactValueStore(this);
        this.blockValueStore = new BlockValueStore();
        this.tempBlockValueStore = new TempBlockValueStore(this.blockValueStore);
        this.checklistStore = new ChecklistStore(this);
        this.actionStore = new ActionStore(this);
        this.mediaProvider = (_a = settings.mediaProvider) !== null && _a !== void 0 ? _a : createBaseMediaProvider();
        this.collectorStore = new CollectorStore(this);
        this.isCopyButtonEnabled = (_b = settings.isCopyButtonEnabled) !== null && _b !== void 0 ? _b : false;
        this.formatDate = (_c = settings.formatDate) !== null && _c !== void 0 ? _c : DEFAULT_DATE_FORMATTER;
        this.childWindowOptions = settings.childWindowOptions;
        this.logger = (_d = settings.logger) !== null && _d !== void 0 ? _d : logger.getLogger('FlowStore');
        this.stateObserver = new StateObserver(this);
        this.stateChangeEventHandler = new StateHandler(this, this.stateObserver);
        this.integrationObserver = new IntegrationObserver(this);
        this.customObserver = new CustomObserver(this);
        this.registerPinnedSteps();
        this.registerFavoriteStep();
        this.stepStore.onStepAdded(this.handleStepAdded);
        this.factValueStore.onUpdate(this.handleFactValuesUpdated);
        this.blockValueStore.onUpdate(this.handleBlockValuesUpdated);
        this.tempBlockValueStore.onAdd(this.handleTemporaryBlockValueChanged);
        this.tempBlockValueStore.onRemove(this.handleTemporaryBlockValueChanged);
    }
    applyStateEvent(event) {
        this.stateChangeEventHandler.applyStateChangeEvent(event);
    }
    // This could be optimised to apply all state events in one batch to avoid many re-renderings
    applyStateEvents(events) {
        events.map((e) => this.applyStateEvent(e));
    }
    onStateEvent(cb) {
        return this.stateObserver.onEvent(cb);
    }
    onCustomEvent(cb) {
        return this.customObserver.onEvent(cb);
    }
    onIntegrationEvent(cb) {
        return this.integrationObserver.onEvent(cb);
    }
}
__decorate([
    action,
    __metadata("design:type", Object)
], FlowStore.prototype, "changeActiveStep", void 0);
