import { cloneDeepWith } from 'lodash';
import { uuid } from '@corti/uuid';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { ActionElementModel } from 'lib/graphEditor/contentBuilder/elements/ActionElementModel';
import { DatePickerElementModel } from 'lib/graphEditor/contentBuilder/elements/DatePickerElementModel';
import { DocumentElementModel } from 'lib/graphEditor/contentBuilder/elements/DocumentElementModel';
import { FlowValueCollectorElementModel } from 'lib/graphEditor/contentBuilder/elements/FlowValueCollectorElementModel';
import { ImageElementModel } from 'lib/graphEditor/contentBuilder/elements/ImageElementModel';
import { SelectElementModel } from 'lib/graphEditor/contentBuilder/elements/SelectElementModel';
import { TextAreaElementModel } from 'lib/graphEditor/contentBuilder/elements/TextAreaElementModel';
import { TextElementModel } from 'lib/graphEditor/contentBuilder/elements/TextElementModel';
import { TextInputElementModel } from 'lib/graphEditor/contentBuilder/elements/TextInputElementModel';
import { NumberInputElementModel } from './elements';
export const KNOWN_ELEMENTS = [
    NumberInputElementModel,
    TextInputElementModel,
    TextElementModel,
    SelectElementModel,
    TextAreaElementModel,
    ActionElementModel,
    DocumentElementModel,
    FlowValueCollectorElementModel,
    ImageElementModel,
    DatePickerElementModel,
];
export const ElementFactory = {
    createElement(type) {
        const f = KNOWN_ELEMENTS.find((e) => e.typename === type);
        if (f) {
            return new f({});
        }
    },
    async cloneElement(element) {
        let serialized = ElementSerializer.serialize(element);
        const idReplacements = new Map();
        serialized = cloneDeepWith(serialized, (val, key) => {
            if (key === 'id') {
                if (!idReplacements.get(val)) {
                    const newid = uuid();
                    idReplacements.set(val, newid);
                }
                return idReplacements.get(val);
            }
        });
        const result = await ElementSerializer.deserialize(serialized);
        return [...result.entities.values()][0];
    },
    text() {
        return new TextElementModel({});
    },
    textInput() {
        return new TextInputElementModel({});
    },
    numberInput() {
        return new NumberInputElementModel({});
    },
    textArea() {
        return new TextAreaElementModel({});
    },
    select() {
        return new SelectElementModel({});
    },
    action() {
        return new ActionElementModel({});
    },
    document() {
        return new DocumentElementModel({});
    },
    flowValueCollector() {
        return new FlowValueCollectorElementModel({});
    },
    image() {
        return new ImageElementModel({});
    },
    datePicker() {
        return new DatePickerElementModel({});
    },
};
