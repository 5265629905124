import React from 'react';
import { formatTime } from '@corti/date';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Typography } from 'lib/cortiUI';
export function CommentsListItem(props) {
    const { comment } = props;
    const theme = useTheme();
    return (React.createElement(Box, { "data-cy": "comments-list-item" },
        React.createElement(Box, { mb: 4, alignItems: "flex-start", justifyContent: "center" },
            React.createElement(Typography, { "data-cy": "comments-list-item-user-name", fontSize: 14, fontWeight: 600, noWrap: true, className: css({
                    color: theme.palette.text.primary,
                }) }, comment.createdBy.name),
            React.createElement(Typography, { fontSize: 12, noWrap: true, className: css({
                    color: theme.palette.text.secondary,
                }) }, formatTime(new Date(comment.datetime), 'HH:mm:ss'))),
        React.createElement(Typography, { "data-cy": "comments-list-item-text", className: css({
                color: theme.palette.text.primary,
            }) }, comment.text)));
}
