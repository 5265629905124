import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Dialog, List, ListItem, Typography } from 'lib/cortiUI';
import { useChecklistStorage } from './useChecklistStorage';
import { useChecklistView } from './useChecklistView';
export function ChecklistConfirmDelete() {
    const { t } = useTranslation('libGraphEditor');
    const selected = useChecklistView(({ selected }) => selected);
    const abortDelete = useChecklistView(({ abortDelete }) => abortDelete);
    const confirmDelete = useChecklistView(({ confirmDelete }) => confirmDelete);
    const { removeChecklists } = useChecklistStorage();
    const onDelete = () => {
        removeChecklists(selected);
        confirmDelete();
    };
    const selectedChecklists = useChecklistView(({ selected, checklists }) => checklists.filter(({ id }) => selected.includes(id)));
    const selectionCount = selected.length;
    const deletionText = t('checklistsView.confirmDelete', {
        count: selectionCount,
        defaultValue_one: 'Delete checklist',
        defaultValue_other: 'Delete checklists',
    });
    const actionButtons = (React.createElement(React.Fragment, null,
        React.createElement(Button, { color: "primary", onClick: onDelete }, deletionText),
        React.createElement(Button, { type: "button", onClick: abortDelete }, t('checklistsView.cancelDeleteBtn', 'Cancel'))));
    return (React.createElement(Dialog, { size: "medium", open: true, onClose: abortDelete, title: deletionText, actionButtons: actionButtons },
        React.createElement(Typography, { variant: "body1" }, t('checklistsView.deleteIntro', {
            count: selectionCount,
            defaultValue_one: 'You are about to delete this checklist:',
            defaultValue_other: 'You are about to delete these checklists:',
        })),
        React.createElement(Base, { mx: 8 },
            React.createElement(List, { sx: { listStyleType: 'bullet' } }, selectedChecklists.map(({ id, name, key }) => (React.createElement(ListItem, { key: id, sx: { display: 'list-item' } },
                name,
                key && (React.createElement(React.Fragment, null,
                    ' ',
                    "(",
                    React.createElement("code", null, key),
                    ")")))))))));
}
