import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { AbstractInputElementModel } from './AbstractInputElementModel';
export class TextAreaElementModel extends AbstractInputElementModel {
    constructor(props = {}) {
        super(props, {});
        this.type = TextAreaElementModel.typename;
    }
}
Object.defineProperty(TextAreaElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'text-area'
});
Object.defineProperty(TextAreaElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.textAreaElement.title', 'Multiline Text Input'),
    })
});
Object.defineProperty(TextAreaElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new TextAreaElementModel();
        i.deserialize(val);
        return i;
    }
});
ElementSerializer.register(TextAreaElementModel, 'TextAreaElementModel');
