import React from 'react';
const initialState = {
    loadingAnswers: [],
    notAnsweredRequiredQuestions: [],
    addLoadingAnswer: () => { },
    removeLoadingAnswer: () => { },
    addAnsweredQuestion: () => { },
    setRequiredQuestions: () => { },
};
const ValidationContext = React.createContext(initialState);
export const ValidationProvider = ({ children }) => {
    const [requiredQuestions, setRequiredQuestions] = React.useState([]);
    const [answeredQuestions, setAnsweredQuestions] = React.useState([]);
    const [loadingAnswers, setLoadingAnswers] = React.useState([]);
    const addLoadingAnswer = React.useCallback((id) => {
        if (!loadingAnswers.includes(id))
            setLoadingAnswers((prevState) => [...prevState, id]);
    }, [loadingAnswers]);
    const removeLoadingAnswer = React.useCallback((id) => {
        setLoadingAnswers((prevState) => [...prevState].filter((question) => question !== id));
    }, [setLoadingAnswers]);
    const notAnsweredRequiredQuestions = React.useMemo(() => requiredQuestions.filter((question) => !answeredQuestions.includes(question)), [requiredQuestions, answeredQuestions]);
    const addAnsweredQuestion = React.useCallback((id) => {
        if (!answeredQuestions.includes(id))
            setAnsweredQuestions((prevState) => [...prevState, id]);
    }, [answeredQuestions]);
    const value = React.useMemo(() => ({
        notAnsweredRequiredQuestions,
        setRequiredQuestions,
        addAnsweredQuestion,
        addLoadingAnswer,
        removeLoadingAnswer,
        loadingAnswers,
    }), [
        notAnsweredRequiredQuestions,
        loadingAnswers,
        addLoadingAnswer,
        removeLoadingAnswer,
        addAnsweredQuestion,
    ]);
    return React.createElement(ValidationContext.Provider, { value: value }, children);
};
export const useValidation = () => {
    const context = React.useContext(ValidationContext);
    if (!context) {
        throw new Error('useValidation must be used within a ValidationProvider');
    }
    return context;
};
