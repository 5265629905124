import React, { useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { isEmpty } from '@corti/richtext';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { NoteCard } from './NoteCard';
import { RichTextElement } from './RichTextElement';
import * as Commands from './commands';
import { useRichTextEditor } from './context';
export function HasNote(props) {
    var _a, _b;
    const element = props.element;
    const richTextEditor = useRichTextEditor();
    const slateEditor = useSlate();
    const theme = useTheme();
    const [isEditingNote, setIsEditingNote] = useState(false);
    const [tempNoteContent, setTempNoteContent] = useState(element.attributes.note.content);
    const onNoteRemove = () => {
        const path = ReactEditor.findPath(slateEditor, props.element);
        Transforms.unwrapNodes(slateEditor, { at: path });
    };
    const startEditingNote = () => {
        setIsEditingNote(true);
        setTempNoteContent(element.attributes.note.content);
    };
    const onNoteSave = () => {
        var _a;
        const path = ReactEditor.findPath(slateEditor, props.element);
        if (isEmpty(tempNoteContent)) {
            Commands.removeNote(slateEditor, { at: path });
            return;
        }
        const note = Object.assign({ content: tempNoteContent }, (_a = richTextEditor.resolveNoteDetails) === null || _a === void 0 ? void 0 : _a.call(richTextEditor));
        Transforms.setNodes(slateEditor, { type: 'hasnote', attributes: { note }, children: [] }, { at: path });
        setIsEditingNote(false);
        setTempNoteContent(null);
    };
    const onNoteCancel = () => {
        setIsEditingNote(false);
        setTempNoteContent(null);
    };
    const noteCardContent = (React.createElement(NoteCard, { isEditable: !richTextEditor.readOnly, isEditing: isEditingNote, content: isEditingNote ? tempNoteContent : element.attributes.note.content, date: (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.note.date, userName: (_b = element.attributes) === null || _b === void 0 ? void 0 : _b.note.author, showMeta: !richTextEditor.readOnly, onNoteSave: onNoteSave, onNoteRemove: onNoteRemove, onNoteEdit: startEditingNote, onNoteCancel: onNoteCancel, onValueChange: (val) => {
            setTempNoteContent(val);
        } }));
    return (React.createElement(RichTextElement, { renderCard: noteCardContent, isEditing: isEditingNote, attributes: props.attributes, children: props.children, className: css({
            textDecoration: `underline ${theme.palette.primary.main} solid`,
            textDecorationThickness: '2px',
            ':focus': {
                outline: `1px solid ${theme.palette.primary.main}`,
            },
        }) }));
}
