import { darken as darkenPolished, desaturate as desaturatePolished, grayscale as grayscalePolished, invert as invertPolished, lighten as lightenPolished, mix as mixPolished, readableColor as readableColorPolished, rgbToColorString as rgbToColorStringPolished, rgba as rgbaPolished, shade as shadePolished, tint as tintPolished, transparentize as transparentizePolished, } from 'polished';
export const FALLBACK_COLOR_STRING = 'transparent';
function commonErrorHandler(err) {
    if (err instanceof Error) {
        console.warn(`${err} Function returned "${FALLBACK_COLOR_STRING}"\n`);
    }
    return FALLBACK_COLOR_STRING;
}
function wrapWithError(fn) {
    return (...args) => {
        try {
            const result = fn(...args);
            return result;
        }
        catch (err) {
            return commonErrorHandler(err);
        }
    };
}
export const desaturate = wrapWithError(desaturatePolished);
export const transparentize = wrapWithError(transparentizePolished);
export const tint = wrapWithError(tintPolished);
export const shade = wrapWithError(shadePolished);
export const darken = wrapWithError(darkenPolished);
export const lighten = wrapWithError(lightenPolished);
export const mix = wrapWithError(mixPolished);
export const rgba = wrapWithError(rgbaPolished);
export const readableColor = wrapWithError(readableColorPolished);
export const grayscale = wrapWithError(grayscalePolished);
export const invert = wrapWithError(invertPolished);
export const rgbToColorString = wrapWithError(rgbToColorStringPolished);
