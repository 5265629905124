var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUISwitch from '@mui/material/Switch';
import React from 'react';
/**
 * Simple Material UI switch. They accept all Material UI props
 */
export function Switch(_a) {
    var { size = 'small', color = 'primary', sx } = _a, rest = __rest(_a, ["size", "color", "sx"]);
    return (React.createElement(MUISwitch, Object.assign({ color: color, size: size, sx: Object.assign({ '& .MuiSwitch-thumb': {
                boxShadow: ' 0 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 16px rgba(0, 0, 0, 0.02)',
            } }, sx) }, rest)));
}
