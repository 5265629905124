import { css } from './styled';
export const getScrollerCss = (props) => {
    var _a;
    const { theme, overflowHidden = 'horizontal', scrollWidth = 8, showScroller = 'hover', trackColor = 'transparent', thumbColor = (_a = theme === null || theme === void 0 ? void 0 : theme.palette.background.divider) !== null && _a !== void 0 ? _a : '#aaa', } = props;
    return css({
        overflowY: overflowHidden === 'none'
            ? 'overlay'
            : overflowHidden === 'vertical'
                ? 'hidden'
                : 'overlay',
        overflowX: overflowHidden === 'none'
            ? 'overlay'
            : overflowHidden === 'vertical'
                ? 'overlay'
                : 'hidden',
        '&::-webkit-scrollbar': {
            width: scrollWidth,
            height: scrollWidth,
        },
        '&::-webkit-scrollbar-thumb': {
            background: showScroller === 'always' ? thumbColor : 'transparent',
            backgroundClip: 'padding-box',
            borderRadius: scrollWidth / 2,
        },
        '&:hover::-webkit-scrollbar-thumb': {
            background: thumbColor,
        },
        '&::-webkit-scrollbar-track': {
            background: trackColor,
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent',
        },
    });
};
