import { Typography } from '@mui/material';
import React from 'react';
const headingSizes = {
    h1: 40,
    h2: 32,
    h3: 24,
    h4: 24,
    h5: 20,
    h6: 16,
};
function BigHeading({ variant, color = 'default', children }) {
    const sx = {
        fontSize: headingSizes[variant],
        backgroundImage: `var(--heading-background, var(--${color}-gradient))`,
        backgroundClip: 'text',
        textFillColor: 'transparent',
        fontWeight: 500,
        lineHeight: 1.1,
        textAlign: 'left',
    };
    return (React.createElement(Typography, { variant: variant, sx: sx }, children));
}
export function Heading1(props) {
    return React.createElement(BigHeading, Object.assign({ variant: "h1" }, props));
}
export function Heading2(props) {
    return React.createElement(BigHeading, Object.assign({ variant: "h2" }, props));
}
export function Heading3(props) {
    return React.createElement(BigHeading, Object.assign({ variant: "h2" }, props));
}
function SmallHeading({ variant, color, children }) {
    const sx = {
        fontSize: headingSizes[variant],
        color: `var(--${color}-color, var(--heading-color, var(--default-color)))`,
        fontWeight: 300,
        lineHeight: '100%',
        textAlign: 'left',
    };
    return (React.createElement(Typography, { variant: variant, sx: sx }, children));
}
export function Heading4(props) {
    return React.createElement(SmallHeading, Object.assign({ variant: "h4" }, props));
}
export function Heading5(props) {
    return React.createElement(SmallHeading, Object.assign({ variant: "h5" }, props));
}
export function Heading6(props) {
    return React.createElement(SmallHeading, Object.assign({ variant: "h6" }, props));
}
