import React from 'react';
import { reorder } from '@corti/collections';
import { Base, SortableContainer, SortableElement } from 'lib/cortiUI';
import { SelectedColumnsListItem } from './SelectedColumnsListItem';
export function SelectedColumnsList(props) {
    const { columnsDictionary: allColumns, selectedColumnsIDs, onOrderChange, onRemove } = props;
    const columns = selectedColumnsIDs.map((id) => allColumns[id]).filter(Boolean);
    return (React.createElement(SortableContainer, { handleDragEnd: (sourceIdx, destinationIdx) => onOrderChange(reorder(selectedColumnsIDs, sourceIdx, destinationIdx)) }, ({ containerProps, placeholder }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Base, Object.assign({}, containerProps, { "data-cy": "selected-columns-list" }), columns.map((column, idx) => (React.createElement(SortableElement, { key: column.id, index: idx }, ({ itemProps, handleProps }) => (React.createElement(SelectedColumnsListItem, { column: column, onRemove: onRemove, handleProps: handleProps, itemProps: itemProps })))))),
            placeholder));
    }));
}
