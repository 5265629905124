import React from 'react';
import { useAsyncMount } from '../hooks';
export function asyncMountedComponent(Component) {
    return function AsyncMountedComponent(props) {
        const shouldRender = useAsyncMount();
        if (!shouldRender) {
            return null;
        }
        return React.createElement(Component, Object.assign({}, props));
    };
}
