import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton, TextField, Tooltip, Typography } from 'lib/cortiUI';
export function EditableTitle(props) {
    const { onChange } = props;
    const { t } = useTranslation('transcriptSearchApp');
    const [canEdit, setCanEdit] = React.useState(false);
    const [title, setTitle] = React.useState(props.title);
    React.useEffect(() => {
        setTitle(props.title);
    }, [props.title]);
    function enterEditMode() {
        setCanEdit(true);
    }
    function exitEditMode() {
        setCanEdit(false);
    }
    function changeTitle(e) {
        setTitle(e.target.value);
    }
    function submitChange(e) {
        e.preventDefault();
        if (title.trim() === '') {
            setTitle(props.title);
        }
        else {
            onChange(title);
        }
        exitEditMode();
    }
    function onBlur() {
        if (title.trim() === '') {
            setTitle(props.title);
        }
        else {
            onChange(title);
        }
        exitEditMode();
    }
    if (canEdit) {
        return (React.createElement(Base, { mt: 5, "data-cy": "transcript-query-view-header-editable-title" },
            React.createElement("form", { onSubmit: submitChange },
                React.createElement(TextField, { autoFocus: true, value: title, onChange: changeTitle, onBlur: onBlur, label: "Search title", name: "title" }))));
    }
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center", gap: 5 },
        React.createElement(Typography, { variant: "h3", color: "default", noWrap: true, "data-cy": "transcript-query-view-header-title" }, title),
        React.createElement(Tooltip, { title: t('editTitle', 'Edit title') },
            React.createElement(IconButton, { size: "small", icon: React.createElement(EditRoundedIcon, { fontSize: "small" }), onClick: enterEditMode, "data-cy": "transcript-query-view-header-title-edit-btn" }))));
}
