import { cloneDeep } from 'lodash';
import { Text as SlateText } from 'slate';
import { createRichText, createRichTextFromNodes } from '@corti/richtext';
export function toSlate(input) {
    // For our RichText it is valid to have an empty array of nodes, but
    // for slatejs this causes issues, as it expects at least one text element to exist
    if (input.nodes.length === 0) {
        return toSlate(createRichText());
    }
    return input.nodes.map(nodeToSlateNode);
}
export function fromSlate(input) {
    return createRichTextFromNodes(input.map(nodeFromSlateNode));
}
function nodeFromSlateNode(slateNode) {
    if (SlateText.isText(slateNode)) {
        const newNode = Object.assign({ type: 'text' }, slateNode);
        return newNode;
    }
    const newNode = {
        type: slateNode.type,
        children: slateNode.children.map(nodeFromSlateNode),
        attributes: Object.assign({}, slateNode.attributes),
    };
    return newNode;
}
function nodeToSlateNode(node) {
    var _a, _b;
    const nodeClone = cloneDeep(node);
    if (nodeClone.type === 'text') {
        // @ts-expect-error - `text` type elements cannot have `children` property
        // this is required here to support rich text that were saved with the older version of the app
        // and has not been migrated in the database
        delete nodeClone.children;
        return Object.assign(Object.assign({}, nodeClone), { attributes: Object.assign({}, nodeClone.attributes) });
    }
    // @ts-expect-error - non text type elements cannot have `text` property
    delete nodeClone.text;
    let slateNode = {
        type: nodeClone.type,
        attributes: Object.assign({}, nodeClone.attributes),
        // @ts-expect-error
        children: (_b = (_a = nodeClone.children) === null || _a === void 0 ? void 0 : _a.map(nodeToSlateNode)) !== null && _b !== void 0 ? _b : [],
    };
    return slateNode;
}
