var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { formatDateTime } from '@corti/date';
import { Typography } from 'lib/cortiUI/components/Typography';
import { isDateType, isObjectType } from './utils';
export function DefaultValueRenderer(props) {
    const { value } = props, typographyProps = __rest(props, ["value"]);
    const { variant = 'body1', color = 'default', noWrap = true, noSelect = true } = typographyProps, restTypographyProps = __rest(typographyProps, ["variant", "color", "noWrap", "noSelect"]);
    let parsedValue = value;
    if (isObjectType(parsedValue)) {
        parsedValue = parsedValue.value;
    }
    if (isDateType(parsedValue)) {
        parsedValue = formatDateTime(parsedValue);
    }
    return (React.createElement(Typography, Object.assign({ variant: variant, color: color, noWrap: noWrap, noSelect: noSelect }, restTypographyProps), parsedValue === null || parsedValue === void 0 ? void 0 : parsedValue.toString()));
}
