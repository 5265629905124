import MUIInput from '@mui/material/Input';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from '../baseHoc';
export const getInputFocusCss = ({ theme }) => css({
    boxShadow: `0 0 0 1px ${theme.palette.background.divider}`,
    ':focus-within': {
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
});
/**
 * @deprecated Use component TextField instead.
 */
export const Input = React.forwardRef(function Input(props, ref) {
    var _a, _b;
    const restProps = filterBaseProps(props);
    const theme = useTheme();
    const basecss = useBaseCss(Object.assign({ borderRadius: 2, pl: 4, pr: 4 }, props));
    return (React.createElement(MUIInput, Object.assign({ inputRef: ref, disableUnderline: true }, restProps, { classes: {
            input: css(getScrollerCss({ theme }), (_a = props.classes) === null || _a === void 0 ? void 0 : _a.input),
            root: css({
                fontSize: theme.typography.fontSize,
            }, getInputFocusCss({ theme }), basecss, (_b = props.classes) === null || _b === void 0 ? void 0 : _b.root),
            inputTypeSearch: css({ paddingRight: theme.space[3] }),
        } })));
});
