var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _DataRepository_instances, _DataRepository_refreshOne, _DataRepository_updateOneDB, _DataRepository_refreshAllByField;
import { fromSyncable, toSyncable } from './syncable';
export class DataRepository {
    constructor(input) {
        _DataRepository_instances.add(this);
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
    }
    async put(input) {
        const resource = toSyncable(input);
        await this.input.db.put(resource);
        void this.syncOne(resource);
    }
    async syncOne(resource) {
        var _a;
        try {
            if (!this.input.onSyncOne) {
                return;
            }
            if (resource.__syncstatus.synced) {
                return;
            }
            const res = await this.input.onSyncOne(fromSyncable(resource));
            const resSyncable = toSyncable(res);
            resSyncable.__syncstatus.synced = true;
            await this.input.db.put(resSyncable);
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.warn(`Failed to sync resource "${resource.id}": ${err}`);
        }
    }
    /**
     * Tries to sync all unsynced resources from the db.
     */
    async syncAll() {
        var _a;
        try {
            if (!this.input.onSyncAll) {
                return;
            }
            const allResources = await this.input.db.toArray();
            const unsyncedResources = allResources.filter((c) => c.__syncstatus.synced === false);
            if (unsyncedResources.length === 0) {
                return;
            }
            await this.input.onSyncAll(unsyncedResources.map((it) => fromSyncable(it)));
            await this.input.db.bulkPut(unsyncedResources.map((it) => (Object.assign(Object.assign({}, it), { __syncstatus: Object.assign(Object.assign({}, it.__syncstatus), { synced: true }) }))));
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.warn(`Failed to sync all resources: ${err}`);
        }
    }
    // Hardcoded strategy used - network first, then db
    async get(id) {
        try {
            await __classPrivateFieldGet(this, _DataRepository_instances, "m", _DataRepository_refreshOne).call(this, id);
        }
        finally {
            const resource = await this.input.db.get(id);
            if (resource) {
                return fromSyncable(resource);
            }
        }
    }
    // Hardcoded strategy used - network first, then db
    async getAllByField(field, value) {
        var _a;
        try {
            await __classPrivateFieldGet(this, _DataRepository_instances, "m", _DataRepository_refreshAllByField).call(this, field, value);
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.warn(`Failed to refresh all resources by field "${String(field)}": ${err}`);
        }
        finally {
            const result = await this.input.db
                .where(field)
                .equals(value)
                .toArray();
            return result.map(fromSyncable);
        }
    }
}
_DataRepository_instances = new WeakSet(), _DataRepository_refreshOne = async function _DataRepository_refreshOne(id) {
    var _a;
    if (!this.input.onRefreshOne) {
        return;
    }
    try {
        const resource = await this.input.onRefreshOne(id);
        if (!resource) {
            return;
        }
        await __classPrivateFieldGet(this, _DataRepository_instances, "m", _DataRepository_updateOneDB).call(this, resource);
    }
    catch (err) {
        (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.warn(`Failed to refresh resource "${id}": ${err}`);
    }
}, _DataRepository_updateOneDB = async function _DataRepository_updateOneDB(item) {
    const s = toSyncable(item);
    s.__syncstatus.synced = true;
    await this.input.db.put(s);
}, _DataRepository_refreshAllByField = async function _DataRepository_refreshAllByField(field, value) {
    if (!this.input.onRefreshAllByField) {
        return;
    }
    const items = await this.input.onRefreshAllByField(field, value);
    await Promise.all(items.map((it) => {
        return __classPrivateFieldGet(this, _DataRepository_instances, "m", _DataRepository_updateOneDB).call(this, it);
    }));
};
