import { useQuery } from '@apollo/client';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Autocomplete, Base, Box, MenuItem, Skeleton, TextField, Typography } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
import { PerformanceMetricsWidget } from './PerformanceMetricsWidget';
import { getPerformanceViewsQuery, } from './graphql';
export const PerformanceMetricsView = () => {
    var _a;
    const theme = useTheme();
    const { organization, currentUserID } = useAuth();
    const { caseViewState: { caseEntity }, } = useCaseViewContext();
    const { t } = useTranslation('explore', { keyPrefix: 'callPerformanceView' });
    const location = useLocation();
    const [selectedView, setSelectedView] = React.useState(null);
    const { data: getPerformanceViews, loading } = useQuery(getPerformanceViewsQuery, {
        variables: {
            organizationID: organization.id,
            userID: currentUserID,
        },
    });
    React.useEffect(() => {
        var _a;
        const urlQueryString = new URLSearchParams(location.search);
        if (urlQueryString.has('performanceViewID')) {
            const view = (_a = getPerformanceViews === null || getPerformanceViews === void 0 ? void 0 : getPerformanceViews.performanceViews.find((it) => it.id === urlQueryString.get('performanceViewID'))) !== null && _a !== void 0 ? _a : null;
            setSelectedView(view);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, getPerformanceViews]);
    return (React.createElement(Base, { "data-cy": "call-detections-view", display: "grid", gap: 7, overflow: "hidden", mx: 8 },
        React.createElement(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" },
            React.createElement(Typography, { variant: "h5" }, t('detections', 'Detections'))),
        loading ? (React.createElement(Skeleton, { variant: "rounded", height: 48 })) : (React.createElement(Autocomplete, { size: "small", options: (_a = getPerformanceViews === null || getPerformanceViews === void 0 ? void 0 : getPerformanceViews.performanceViews.slice().sort((a, b) => a.title.localeCompare(b.title))) !== null && _a !== void 0 ? _a : [], getOptionLabel: (option) => option.title, value: selectedView, onChange: (_, value) => setSelectedView(value), clearIcon: null, disabled: loading, isOptionEqualToValue: (option, value) => option.id === value.id, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('viewLabel', 'Compliance View'), placeholder: t('viewInputPlaceholder', 'Select a Compliance View') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected }), option.title)) })),
        selectedView && (caseEntity === null || caseEntity === void 0 ? void 0 : caseEntity.activeCall) && (React.createElement(Base, { className: getScrollerCss({ theme }) },
            React.createElement(PerformanceMetricsWidget, { callID: caseEntity.activeCall.id, performanceViewID: selectedView.id })))));
};
