export class ConnectionChecker {
    constructor(coreRealtimeClient) {
        Object.defineProperty(this, "coreRealtimeClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: coreRealtimeClient
        });
    }
    getState() {
        if (this.coreRealtimeClient.connection.state === 'connected') {
            return 'online';
        }
        return 'offline';
    }
    onStateChange(cb) {
        return this.coreRealtimeClient.connection.on('stateChanged', (state) => {
            if (state.current === 'connected') {
                cb({ current: 'online' });
            }
            if (state.current === 'unavailable') {
                cb({ current: 'offline' });
            }
        });
    }
}
