const matchers = {
    number: /\b\d+(?:\.\d+)?\b/gim,
    string: /('[^']*')|("[^"]*")/g,
    operator: /==|!=|&&|\|\||<=|>=|!|\?|>|<|\+|-|\bin\b/gim,
    keyword: /\b(true|false|unknown|in|contains)\b/gim,
    function: /\b(dateDiff|now|countTrue)\b/gim,
    group: /\(|\)/gim,
};
function tokenize(input) {
    let tokens = [];
    for (let [type, regexp] of Object.entries(matchers)) {
        const it = input.matchAll(regexp);
        for (const match of it) {
            tokens.push({
                type: type,
                text: match[0],
                start: match.index,
            });
        }
    }
    return tokens;
}
export { tokenize };
