var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from '@corti/i18n';
import { Checkbox } from '../checklist/Checkbox';
const Input = styled.input `
  font: inherit;
  border: 0;
  background: transparent;
  width: 100%;
`;
export const EditableChecklistEntry = forwardRef(function EditableChecklistEntry(_a, ref) {
    var { label, labelPlaceholder, details, detailsPlaceholder, manual, automatic, isSelected, handleChange, handleSelect, handleDelete, children } = _a, rest = __rest(_a, ["label", "labelPlaceholder", "details", "detailsPlaceholder", "manual", "automatic", "isSelected", "handleChange", "handleSelect", "handleDelete", "children"]);
    const { t } = useTranslation();
    const onDelete = (evt) => {
        evt.stopPropagation();
        handleDelete();
    };
    const selectionProps = isSelected
        ? { borderLeftColor: '#1774FF', background: 'rgba(23, 116, 255, 0.05);' }
        : {};
    return (React.createElement(Box, Object.assign({ sx: Object.assign({ display: 'flex', alignItems: 'center', gap: 3, borderLeft: '3px solid transparent', paddingLeft: 3, py: 2 }, selectionProps), onClick: handleSelect, ref: ref }, rest),
        children,
        React.createElement(Checkbox, { manualEnabled: manual, autoEnabled: automatic, manualChecked: false, autoChecked: false, reveal: false }),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                flexGrow: 1,
                alignItems: 'stretch',
            } },
            React.createElement(Typography, { variant: "body1" },
                React.createElement(Input, { value: label, name: "label", placeholder: labelPlaceholder, onChange: handleChange })),
            React.createElement(Typography, { variant: "body2" },
                React.createElement(Input, { value: details, name: "details", placeholder: detailsPlaceholder, onChange: handleChange }))),
        isSelected && (React.createElement(Tooltip, { title: t('cortiui.copilot.checklisteditor.DeleteEntry', 'Delete entry') },
            React.createElement(IconButton, { onClick: onDelete },
                React.createElement(CancelIcon, null))))));
});
