var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { forwardRef, useMemo, useState } from 'react';
import { useMergeRefs } from '@corti/react';
import { Base } from 'lib/cortiUI';
import { BaseInputBlock } from './BaseInputBlock';
import { StepperButton } from './StepperButton';
import { DEBOUNCE_TIMEOUT } from './constants';
import formatNumber from './formatNumber';
import useBlockFocus from './useBlockFocus';
import useHover from './useHover';
import useNumberInputStepper from './useNumberInputStepper';
export const NumberInputBlock = observer(forwardRef((_a, forwardedRef) => {
    var { min, max, precision, step, value, handleFinalValue, handleTemporaryValue } = _a, rest = __rest(_a, ["min", "max", "precision", "step", "value", "handleFinalValue", "handleTemporaryValue"]);
    const [internalValue, setInternalValue] = useState(value);
    const containerRef = React.useRef(null);
    const hasFocus = useBlockFocus(containerRef, value, setInternalValue);
    const isHovered = useHover(containerRef);
    const stepperIsVisible = hasFocus || isHovered;
    const handleTemporaryDebounced = useMemo(() => debounce((value) => handleTemporaryValue(value), DEBOUNCE_TIMEOUT), [handleTemporaryValue]);
    const onBlur = () => {
        const finalValue = formatNumber({ value: internalValue, min, max, precision });
        handleFinalValue(finalValue);
        setInternalValue(finalValue);
    };
    const onChange = (e) => {
        const value = e.target.value;
        setInternalValue(value);
        handleTemporaryDebounced(formatNumber({ value, min, max, precision }));
    };
    const { inputRef, onIncrement, onDecrement } = useNumberInputStepper({
        setInternalValue,
        handleTemporaryValue,
        min,
        max,
        step,
        precision,
    });
    const containerRefs = useMergeRefs(forwardedRef, containerRef);
    return (React.createElement(BaseInputBlock, Object.assign({ value: internalValue, ref: containerRefs, inputRef: inputRef, onBlur: onBlur, onChange: onChange }, rest), stepperIsVisible && (React.createElement(Base, { position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", pr: 4 },
        React.createElement(StepperButton, { onClick: onIncrement },
            React.createElement(ArrowDropUpRoundedIcon, null)),
        React.createElement(StepperButton, { onClick: onDecrement },
            React.createElement(ArrowDropDownRoundedIcon, null))))));
}));
