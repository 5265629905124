import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Box, Button, Input, ModalContent, ModalHeader, Typography } from 'lib/cortiUI';
import { ValidationResultSummaryText } from 'lib/graphEditor/validation/view';
import { ProtocolGraphVersionCommitFragment } from '../api';
import { CommitsList } from '../components/commits';
const getVersionCommitsQuery = () => gql `
  query protocolGraphVersion($id: String!) {
    protocolGraphVersion(id: $id) {
      commits {
        ...ProtocolGraphVersionCommit
      }
    }
  }
  ${ProtocolGraphVersionCommitFragment()}
`;
export const GraphSaveDialog = observer(function GraphSaveDialog({ editor, onCancel, onSuccess, }) {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { validation } = editor;
    const [commitMessage, setCommitMessage] = useState('');
    // validation result might exist before opening this dialog
    // this state is to avoid flashing and only show the result when it has validated
    const [hasValidated, setHasValidated] = useState(false);
    useEffect(() => {
        editor.validation.validate().then(() => setHasValidated(true));
    }, [editor]);
    const commitHistory = useQuery(getVersionCommitsQuery(), { variables: { id: editor.model.id } });
    const criticalIssuesCount = (_b = (_a = validation.result) === null || _a === void 0 ? void 0 : _a.getIssuesBySeverity('error').length) !== null && _b !== void 0 ? _b : 0;
    const canSave = hasValidated && criticalIssuesCount === 0;
    return (React.createElement("form", { onSubmit: (e) => {
            e.preventDefault();
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess({ message: commitMessage });
        } },
        React.createElement(ModalHeader, { title: t('editorApp2:graphEditorView.saveDialog.title', 'Add commit message') }),
        React.createElement(ModalContent, null,
            React.createElement(Box, { gap: 5, "data-cy": "save-graph-dialog" },
                React.createElement(Base, { "data-cy": "save-graph-dialog-validation-status-text", mb: 6 }, !hasValidated ? (React.createElement(Typography, null, t('editorApp2:graphEditorView.saveDialog.validation.validating', 'Validating...'))) : validation.result ? (React.createElement(React.Fragment, null,
                    React.createElement(ValidationResultSummaryText, { result: validation.result }),
                    criticalIssuesCount > 0 && (React.createElement(React.Fragment, null,
                        React.createElement("span", null, '. '),
                        React.createElement(Typography, null, t('editorApp2:graphEditorView.saveDialog.validation.fixesNeeded', 'Please fix critical issues and try to save the graph again')))))) : null),
                React.createElement(Input, { autoFocus: true, fullWidth: true, placeholder: t('editorApp2:graphEditorView.saveDialog.commitInputPlaceholder', 'E.g. Added 1 node in branch X. Removed 2 nodes from branch Y'), value: commitMessage, onChange: (e) => setCommitMessage(e.target.value) }),
                React.createElement(CommitsList, { initialCollapsed: true, commits: (_d = (_c = commitHistory.data) === null || _c === void 0 ? void 0 : _c.protocolGraphVersion.commits) !== null && _d !== void 0 ? _d : [] }),
                React.createElement(Box, { flexDirection: "row", alignItems: "center", gap: 4, mt: 7 },
                    React.createElement(Button, { type: "submit", color: "primary", "data-cy": "save-graph-submit-button", disabled: !canSave }, t('editorApp2:graphEditorView.saveDialog.saveBtn', 'Save')),
                    React.createElement(Button, { onClick: onCancel }, t('editorApp2:graphEditorView.saveDialog.cancelBtn', 'Cancel')))))));
});
