import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button } from 'lib/cortiUI';
import { useParametersContext } from '../../context/ParametersContext';
export const ResetButton = () => {
    const { t } = useTranslation('performanceApp');
    const { isCreateOrUpdateViewLoading, resetView } = useParametersContext();
    return (React.createElement(Button, { "data-cy": "performance-view-revert-btn", variant: "text", size: "small", disabled: isCreateOrUpdateViewLoading, onClick: () => {
            resetView();
        } }, t('reset', 'Revert to default')));
};
