import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import { isCompatible } from './isCompatible';
export function BuildInfo(props) {
    var _a, _b;
    const { buildInfo, serverInfo, textColor } = props;
    const { t } = useTranslation();
    return (React.createElement("div", null,
        React.createElement(Base, { display: "flex", flexDirection: "column", p: 3 },
            React.createElement(Base, { display: "flex", flexDirection: "column", my: 3 },
                React.createElement(Typography, { variant: "caption", color: textColor, fontWeight: "bold" }, t('clientInfoSectionTitle', 'Client info')),
                React.createElement(Typography, { variant: "caption", color: textColor },
                    t('clientVersion', 'Version'),
                    ": ",
                    buildInfo.version),
                React.createElement(Typography, { variant: "caption", color: textColor },
                    t('apiHost', 'API Host'),
                    ": ",
                    (serverInfo === null || serverInfo === void 0 ? void 0 : serverInfo.url) || '-')),
            React.createElement(Base, { display: "flex", flexDirection: "column", my: 3 },
                React.createElement(Typography, { variant: "caption", color: textColor, fontWeight: "bold" }, t('requiredServicesSectionTitle', 'Required services')),
                Object.entries(buildInfo.backendEnv.services).map(([serviceID, serviceDescriptor]) => {
                    return (React.createElement(Typography, { key: serviceID, variant: "caption", color: textColor },
                        serviceID,
                        ": ",
                        serviceDescriptor.version));
                })),
            React.createElement(Base, { display: "flex", flexDirection: "column", my: 3 },
                React.createElement(Typography, { variant: "caption", color: textColor, fontWeight: "bold" }, t('environmentSectionTitle', 'Environment info')),
                React.createElement(Typography, { variant: "caption", color: serverInfo &&
                        isCompatible({
                            expected: buildInfo.backendEnv.services.core.version,
                            actual: serverInfo.version,
                        })
                        ? 'success'
                        : 'error' },
                    t('apiVersion', 'API Version'),
                    ": ", (_a = serverInfo === null || serverInfo === void 0 ? void 0 : serverInfo.version) !== null && _a !== void 0 ? _a : '-',
                    " (",
                    'expected ',
                    buildInfo.backendEnv.services.core.version,
                    ")"),
                React.createElement(Typography, { variant: "caption", color: textColor },
                    t('apiEnv', 'API Environment'),
                    ": ", (_b = serverInfo === null || serverInfo === void 0 ? void 0 : serverInfo.env) !== null && _b !== void 0 ? _b : '-')))));
}
