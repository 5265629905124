import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { requestInteractionAcknowledgement } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { Base, Button } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
export const ManagerActions = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.managerActions' });
    const { interaction, refetchInteraction } = useInteraction();
    const [isActionLoading, setIsActionLoading] = React.useState(false);
    async function handleCompleteReview() {
        try {
            if (!interaction)
                return null;
            setIsActionLoading(true);
            await requestInteractionAcknowledgement(interaction.id, 'USER_REQUESTED');
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('requestAcknowledgmentSuccess', 'Acknowledgment requested'),
            });
            trackerService.track('[Acknowledgement Status] acknowledgment requested', {
                interactionID: interaction.id,
                status: 'USER_REQUESTED',
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('requestAcknowledgmentError', 'Failed to request acknowledgment'),
            });
        }
        finally {
            setIsActionLoading(false);
        }
    }
    if (!interaction)
        return null;
    return (React.createElement(Base, { display: "flex", flexDirection: "row", gap: 5 },
        React.createElement(Button, { disabled: isActionLoading, color: "primary", size: "small", variant: "text", onClick: handleCompleteReview },
            React.createElement(ShortcutOutlinedIcon, null),
            t('requestAcknowledgmentFromAgent', 'Request acknowledgment from agent'))));
};
