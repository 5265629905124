var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import React, { Fragment } from 'react';
import { Base } from '../Base';
import { Breadcrumb } from './Breadcrumb';
import { BreadcrumbWrapper } from './BreadcrumbWrapper';
/**
 * Breadcrumbs allow users to make selections from a range of values (often path/history of actions or navigation). Items are passed as the elements prop
 */
export function Breadcrumbs(_a) {
    var { elements, hasLeadingSeparator = false, hasFocusContext = false, focusKey } = _a, rest = __rest(_a, ["elements", "hasLeadingSeparator", "hasFocusContext", "focusKey"]);
    return (React.createElement(BreadcrumbWrapper, { hasFocusContext: hasFocusContext, focusKey: focusKey },
        React.createElement(Base, Object.assign({ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "nowrap", gap: 6 }, rest),
            hasLeadingSeparator && React.createElement(ChevronRightRoundedIcon, { fontSize: "small" }),
            elements.map((crumb, idx) => {
                const isLast = idx === elements.length - 1;
                return (React.createElement(Fragment, { key: idx },
                    React.createElement(Breadcrumb, { onClick: crumb.onClick, active: crumb.active, hasFocusStyling: hasFocusContext }, crumb.label),
                    !isLast && React.createElement(ChevronRightRoundedIcon, { fontSize: "small" })));
            }))));
}
