import React from 'react';
import { useLazyRef } from '@corti/react';
import { FlowViewStore } from '../../stores/FlowViewStore/FlowViewStore';
const FlowContext = React.createContext(null);
export function FlowProvider({ flowStore, children, }) {
    const ctx = useLazyRef(() => ({
        flowStore: flowStore,
        flowViewStore: new FlowViewStore(flowStore),
    }));
    return React.createElement(FlowContext.Provider, { value: ctx.current }, children);
}
export function useFlow() {
    const context = React.useContext(FlowContext);
    if (!context) {
        throw new Error('useFlow must be used within a FlowContext');
    }
    return context;
}
