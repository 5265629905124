import { protocolGraphDB } from 'core/protocolGraphDB';
export async function load() {
    const graphs = await protocolGraphDB.getAllGraphs();
    if (graphs.length === 0) {
        throw new Error('no graphs in realtime app storage');
    }
    if (graphs.length === 1) {
        return graphs[0];
    }
    console.warn(`Found more than one graph in app's storage. Selecting the first graph from storage`);
    return graphs[0];
}
