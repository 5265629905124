var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { computed, makeObservable } from 'mobx';
import { BaseEntity } from 'lib/graphEditor/core/BaseEntity';
/**
 * Canvas entity is what can be drawn on a canvas, that is Nodes or Links
 */
export class BaseCanvasEntity extends BaseEntity {
    constructor() {
        super();
        makeObservable(this);
    }
    get isHovered() {
        var _a;
        if (!this.graphEditor) {
            return false;
        }
        return (_a = this.graphEditor) === null || _a === void 0 ? void 0 : _a.state.hoveredEntityIDs.has(this.id);
    }
    get isSelected() {
        var _a;
        if (!this.graphEditor) {
            return false;
        }
        return (_a = this.graphEditor) === null || _a === void 0 ? void 0 : _a.state.selectedEntityIDs.has(this.id);
    }
}
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseCanvasEntity.prototype, "isHovered", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseCanvasEntity.prototype, "isSelected", null);
