import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { queueMacrotask } from '@corti/timers';
import { Autocomplete, Base, MenuItem, TextField, createFilterOptions, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { NodeSmartTarget } from '../../views';
import { ViewNodeModel } from '../ViewNode';
import { BaseGraphEditorNodeWidget, NodeMainContent } from '../common/BaseGraphEditorNodeWidget';
export const LinkPortalNodeWidget = observer((props) => {
    var _a;
    const { model } = props;
    const [suggestions, setSuggestions] = React.useState(new Map());
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    React.useEffect(() => {
        recalculateSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model.isSelected]);
    function getViewNodeSuggestions() {
        const nodes = new Map();
        for (const node of editor.model.nodes.values()) {
            if (node.branchContext != null &&
                node.branchContext !== model.branchContext &&
                node instanceof ViewNodeModel) {
                nodes.set(node.id, {
                    text: `${node.readableTitle} (${node.branchContext.name})`,
                    value: node.id,
                    model: node,
                });
            }
        }
        return nodes;
    }
    function recalculateSuggestions() {
        void queueMacrotask()
            .then(() => {
            return getViewNodeSuggestions();
        })
            .then((s) => {
            setSuggestions(s);
        });
    }
    const filterOptions = createFilterOptions({
        limit: 20,
    });
    return (React.createElement(BaseGraphEditorNodeWidget, { model: model, title: t('linkPortalNode.title', 'Link Portal') },
        React.createElement(NodeMainContent, null,
            React.createElement(Autocomplete, { "data-cy": "link-portal-selector", size: "small", options: Array.from(suggestions.values()), filterOptions: filterOptions, getOptionLabel: (option) => option.text, groupBy: (option) => { var _a; return (_a = option.sectionId) !== null && _a !== void 0 ? _a : ''; }, value: (_a = (model.targetNode && suggestions.get(model.targetNode.id))) !== null && _a !== void 0 ? _a : null, onChange: (_, option) => {
                    if (option) {
                        model.setTargetNode(option.model.id);
                    }
                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('linkPortalNode.select.label', 'Select node'), onClick: recalculateSuggestions }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "link-portal-menu-item", selected: state.selected, key: option.value }), option.text)), clearIcon: null }),
            model.targetNodeID ? (React.createElement(Base, { mt: 4 },
                React.createElement(NodeSmartTarget, { nodeID: model.targetNodeID }))) : null)));
});
