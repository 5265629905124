import { useHistory, useRouteMatch } from 'react-router-dom';
export function useChecklistRouting() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const startEditing = (id) => history.push(`${path}/${id}`, { fromTable: true });
    const startAdding = () => history.push(`${path}/draft`, { fromTable: true });
    const startImport = (src) => () => history.push(`${path}/${src}`, { fromTable: true });
    const closeDialog = () => {
        var _a;
        // If the current location state contain fromTable (i.e. came from one of the above functions),
        // go back to the previous path, which should be the table listing
        if ((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.fromTable) {
            history.goBack();
            return;
        }
        // Otherwise, replace the current path with the parent path to move to the table listing
        // without adding a new entry to the history stack
        history.replace(`${path}/..`);
    };
    return { startEditing, startAdding, startImport, closeDialog };
}
