var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Badge, Base, CardContent, Tooltip } from 'lib/cortiUI';
import { DropdownField } from './DropdownField';
import { FieldTitle } from './FieldTitle';
import { LongTextField } from './LongTextField';
import { RadioField } from './RadioField';
import { ShortTextField } from './ShortTextField';
export function FieldPreview(_a) {
    var { fieldType, choices, visibilityConditionsCount } = _a, rest = __rest(_a, ["fieldType", "choices", "visibilityConditionsCount"]);
    const { t } = useTranslation('libFeedbackFormManager');
    const renderField = () => {
        switch (fieldType) {
            case 'short-text':
                return React.createElement(ShortTextField, Object.assign({}, rest));
            case 'long-text':
                return React.createElement(LongTextField, Object.assign({}, rest));
            case 'radio':
                return React.createElement(RadioField, Object.assign({}, rest, { choices: choices }));
            default:
                return React.createElement(DropdownField, Object.assign({}, rest, { choices: choices }));
        }
    };
    return (React.createElement(CardContent, null,
        React.createElement(Base, { mb: 5, display: "flex", justifyContent: "space-between" },
            React.createElement(FieldTitle, { title: rest.title, isRequired: rest.required }),
            Boolean(visibilityConditionsCount && visibilityConditionsCount > 0) && (React.createElement(Tooltip, { title: t('preview.hasVisibilityConditions', {
                    count: visibilityConditionsCount,
                    defaultValue_one: `This field has {{count}} visibility condition.`,
                    defaultValue_other: `This field has {{count}} visibility conditions.`,
                }) },
                React.createElement(Badge, { color: "primary", variant: "standard", badgeContent: visibilityConditionsCount },
                    React.createElement(VisibilityIcon, null))))),
        React.createElement(Base, { width: 0.5 }, renderField())));
}
