import { gql } from '@apollo/client';
export const QUERIES = {
    getTimelineEntryDefinitions: gql `
    query getTimelineEntryDefinitions($organizationID: ID!) {
      timelineEntryDefinitions(organizationID: $organizationID) {
        source {
          type
          entityType
          entityID
        }
        title
        text
        isEntryCreatable
        style {
          color
        }
      }
    }
  `,
};
