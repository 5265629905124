import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { Base, FallbackView, Loading } from 'lib/cortiUI';
import { Route } from 'lib/router';
import { HomeView } from '../HomeView';
import { TriageSessionLoader, TriageSessionView } from '../TriageSessionView';
import { RealTimeCtx } from './RealTimeContext';
export const RealTimeView = observer(function RealTimeView({ module, match }) {
    switch (module.module.status) {
        case 'initializing': {
            return (React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center" },
                React.createElement(Loading, { text: intl.t('realtime:loading', 'Loading') })));
        }
        case 'error': {
            const error = module.module.error;
            let errorMessage = '';
            switch (error.code) {
                case 'realtime-database-error': {
                    errorMessage = intl.t('realtime:realtimeAppInitializationError', 'Realtime app failed to initialize');
                    break;
                }
                default: {
                    errorMessage = intl.t('realtime:unknownError', 'Unknown error');
                    break;
                }
            }
            if (error.error)
                console.error(String(error.error));
            return (React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center" },
                React.createElement(FallbackView, { title: intl.t('realtime:generalErrorTitle', 'Error'), text: errorMessage })));
        }
        case 'ok': {
            return (React.createElement(RealTimeCtx.Provider, { value: module.module.controller },
                React.createElement(Route, { path: match.path, exact: true, component: HomeView }),
                React.createElement(Route, { path: `${match.path}/:sessionID`, breadcrumb: ({ match }) => `${match.params.sessionID.substring(0, 6)}`, component: TriageSessionLoader }),
                module.module.controller.activeTriageSession && (React.createElement(TriageSessionView, { triageSession: module.module.controller.activeTriageSession }))));
        }
    }
});
