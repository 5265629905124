import React from 'react';
import { Formatters } from '@corti/strings';
import { css } from '@corti/style';
import { Base, Typography } from 'lib/cortiUI';
export const Phrase = ({ time, text, isActive, onClick }) => {
    return (React.createElement(Base, { display: "grid", gap: 6, gridTemplateColumns: "auto 1fr", onClick: onClick, className: css({
            opacity: isActive ? 1 : 0.6,
            cursor: 'pointer',
        }) },
        React.createElement(Typography, { className: css({ fontVariantNumeric: 'tabular-nums' }), variant: "body2" }, Formatters.msToDuration(time * 1000, { showHours: false })),
        React.createElement(Base, null,
            React.createElement(Typography, { variant: "subtitle2", color: "default" }, text))));
};
