import userSessionStorage from '@corti/lib/userSessionStorage';
/**
 *
 * @param palette theme palete object
 * @param targetScore form 0 to 100
 * @param currentScore from 0 to 100
 * @param threshold from 0 to 100
 * @returns
 */
export function getPerformanceStatusColor(palette, currentScore, threshold, targetScore) {
    if (!targetScore) {
        return {
            status: 'undefined',
            color: palette.primary.main,
        };
    }
    if (currentScore >= targetScore) {
        return {
            status: 'strong',
            color: palette.success.light,
        };
    }
    if (currentScore >= targetScore - threshold) {
        return {
            status: 'moderate',
            color: palette.warning.light,
        };
    }
    return {
        status: 'poor',
        color: palette.error.light,
    };
}
export const useParametersCache = (viewID) => {
    const LOCAL_STORAGE_DATE_RANGE_KEY = `performance:dateRange`;
    const LOCAL_STORAGE_GOAL_KEY = `performance:goal-${viewID}`;
    const LOCAL_STORAGE_FILTERS_KEY = `performance:filters-${viewID}`;
    function persistDateRange(newDateRange) {
        userSessionStorage.setItem(LOCAL_STORAGE_DATE_RANGE_KEY, JSON.stringify(newDateRange));
    }
    function persistFilters(filters) {
        userSessionStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(filters));
    }
    function persistGoal(goal) {
        userSessionStorage.setItem(LOCAL_STORAGE_GOAL_KEY, JSON.stringify(goal));
    }
    function getPersistedDateRange() {
        var _a;
        const persistedDateRange = JSON.parse((_a = userSessionStorage.getItem(LOCAL_STORAGE_DATE_RANGE_KEY)) !== null && _a !== void 0 ? _a : 'null');
        if (!persistedDateRange)
            return null;
        return {
            startDate: new Date(persistedDateRange.startDate),
            endDate: new Date(persistedDateRange.endDate),
        };
    }
    function getPersistedFilters() {
        var _a;
        const persistedFilters = JSON.parse((_a = userSessionStorage.getItem(LOCAL_STORAGE_FILTERS_KEY)) !== null && _a !== void 0 ? _a : 'null');
        return persistedFilters;
    }
    function getPersistedGoal() {
        var _a;
        const goal = JSON.parse((_a = userSessionStorage.getItem(LOCAL_STORAGE_GOAL_KEY)) !== null && _a !== void 0 ? _a : 'null');
        return goal;
    }
    function clearCache() {
        userSessionStorage.removeItem(LOCAL_STORAGE_FILTERS_KEY);
        userSessionStorage.removeItem(LOCAL_STORAGE_GOAL_KEY);
    }
    return {
        persistDateRange,
        persistFilters,
        persistGoal,
        getPersistedDateRange,
        getPersistedFilters,
        getPersistedGoal,
        clearCache,
    };
};
