var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Base, Box, Card, ConfirmPopover, IconButton, Menu, MenuItem, Typography, } from 'lib/cortiUI';
export const SavedFiltersListItem = (props) => {
    const { filter, onSelect, onSubscribe, onUnsubscribe, onDelete, gridTemplateColumns, gridGap } = props, rest = __rest(props, ["filter", "onSelect", "onSubscribe", "onUnsubscribe", "onDelete", "gridTemplateColumns", "gridGap"]);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [confirmAnchorEl, setConfirmAnchorEl] = React.useState(null);
    return (React.createElement(Observer, null, () => {
        const isSubscribed = filter.subscriptions.length > 0;
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, Object.assign({ "data-cy": "saved-filters-list-item", p: 6, width: "100%", clickable: true, onClick: () => onSelect(filter.data) }, rest),
                React.createElement(Base, { className: css({
                        minHeight: 40,
                        display: 'grid',
                        gridTemplateColumns,
                        gridGap,
                        alignItems: 'center',
                    }) },
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "body2" }, filter.name),
                        isSubscribed && (React.createElement(Typography, { variant: "caption" }, intl.t('explore:filters.subscribed', 'Subscribed')))),
                    React.createElement(Box, { alignItems: "center", justifyContent: "center" }, filter.visibility === 'PRIVATE' ? (React.createElement(LockRoundedIcon, null)) : (React.createElement(RemoveRedEyeRoundedIcon, null))),
                    React.createElement(Box, { alignItems: "flex-end", justifyContent: "center" },
                        React.createElement(IconButton, { "data-cy": "open-edit-menu-button", onClick: (e) => {
                                e.stopPropagation();
                                setMenuAnchorEl(e.currentTarget);
                            }, icon: React.createElement(MoreHorizRoundedIcon, null), size: "small" })))),
            React.createElement(Menu, { open: !!menuAnchorEl, anchorEl: menuAnchorEl, onClose: () => setMenuAnchorEl(null) },
                authStore.isFeatureEnabled('case-filter-subscription') && (React.createElement(React.Fragment, null, isSubscribed ? (React.createElement(MenuItem, { "data-cy": "unsubscribe-button", onClick: () => {
                        setMenuAnchorEl(null);
                        onUnsubscribe(filter.id);
                    } },
                    React.createElement(Typography, { color: "default", variant: "body2" }, intl.t('explore:filters.unsubscribe', 'Unsubscribe')))) : (React.createElement(MenuItem, { "data-cy": "subscribe-button", onClick: () => {
                        setMenuAnchorEl(null);
                        onSubscribe(filter.id);
                    } },
                    React.createElement(Typography, { color: "default", variant: "body2" }, intl.t('explore:filters.subscribe', 'Subscribe')))))),
                (filter.visibility !== 'PUBLIC' || rbacService.casesPublicFiltersCRUD()) && (React.createElement(MenuItem, { "data-cy": "delete-button", onClick: (e) => {
                        setConfirmAnchorEl(e.currentTarget);
                    } },
                    React.createElement(Typography, { color: "default", variant: "body2" }, intl.t('explore:filters.delete', 'Delete'))))),
            React.createElement(ConfirmPopover, { color: "error", onConfirm: () => {
                    setMenuAnchorEl(null);
                    setConfirmAnchorEl(null);
                    onDelete(filter.id);
                }, onClose: () => {
                    setConfirmAnchorEl(null);
                }, anchorEl: confirmAnchorEl },
                React.createElement(Typography, { variant: "caption" }, intl.t('explore:filters.deleteConfirmation.text', 'Are you sure you want to delete this filter?')))));
    }));
};
