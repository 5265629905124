import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { IconButton } from 'lib/cortiUI';
import { getFocusCss } from '../../utils';
import { ValueTag } from './ValueTag';
export const AddValueButton = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const [isEditable, setIsEditable] = React.useState(false);
    const clickHandler = () => {
        setIsEditable(true);
    };
    const emitHandler = () => {
        props.onEmit();
        setIsEditable(false);
    };
    const submitHandler = (value) => {
        props.onSubmit(value);
        setIsEditable(false);
    };
    return isEditable ? (React.createElement(ValueTag, { valueType: "custom", onSubmit: submitHandler, onEmit: emitHandler, isEditable: true })) : (React.createElement(IconButton, { "data-cy": "add-value-button", ref: ref, className: css({
            background: theme.palette.action.active,
            color: theme.palette.text.inverted,
            width: '16px',
            height: '16px',
            margin: '0 8px',
            '&:hover': {
                background: theme.palette.action.active,
                color: theme.palette.text.inverted,
            },
        }, getFocusCss({ theme })), icon: React.createElement(AddRoundedIcon, { fontSize: "small" }), onClick: clickHandler }));
});
