var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { StepModel } from '../../models/StepModel';
import { NextNodeDeterminator } from './NextNodeDeterminator';
export class StepStore {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextNodeDeterminator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pinnedSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "favoriteStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activePath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "previewStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.pinnedSteps = [];
                this.favoriteStep = undefined;
                this._steps = new Map();
                this.activeStep = undefined;
                this.activePath = [];
                this.resetPreviewStep();
            }
        });
        Object.defineProperty(this, "changeActivePath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (path = []) => {
                this.activePath = path;
                this.observer.fireEvent('active-path-changed', path);
            }
        });
        Object.defineProperty(this, "changeActiveStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                this.activeStep = input.step;
                this.observer.fireEvent('active-step-changed', input);
            }
        });
        Object.defineProperty(this, "changePreviewStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.previewStep = step;
            }
        });
        Object.defineProperty(this, "resetPreviewStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.previewStep = undefined;
            }
        });
        Object.defineProperty(this, "changePinnedSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (steps) => {
                this.pinnedSteps = steps;
            }
        });
        Object.defineProperty(this, "changeFavoriteStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this.favoriteStep = step;
            }
        });
        Object.defineProperty(this, "addStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                this._steps.set(step.id, step);
                this.observer.fireEvent('step-added', step);
            }
        });
        Object.defineProperty(this, "createStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (node) => {
                var _a;
                return new StepModel({
                    node: node,
                    prevStepID: (_a = this.activePath[this.activePath.length - 1]) === null || _a === void 0 ? void 0 : _a.id,
                }, this.flowStore);
            }
        });
        Object.defineProperty(this, "deserializeStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stepSerialized) => {
                const { graphTraverser } = this.flowStore;
                const node = graphTraverser.getNodeByID(stepSerialized.nodeID);
                if (node) {
                    return new StepModel({
                        id: stepSerialized.id,
                        node: node,
                        prevStepID: stepSerialized.prevStepID,
                    }, this.flowStore);
                }
            }
        });
        Object.defineProperty(this, "getAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return Array.from(this._steps.values());
            }
        });
        Object.defineProperty(this, "getStepByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stepID) => {
                return this._steps.get(stepID);
            }
        });
        Object.defineProperty(this, "getStepsByNodeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (nodeID) => {
                return this.getAll().filter((it) => it.node.id === nodeID);
            }
        });
        Object.defineProperty(this, "getLastStepByNodeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (nodeID) => {
                return this.getStepsByNodeID(nodeID).pop();
            }
        });
        Object.defineProperty(this, "getStepsByBlockValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockValue) => {
                return this.getAll().filter((it) => {
                    return it.node.view.blocks.some((b) => b.blockPrototype.id === blockValue.identifier.blockPrototypeID);
                });
            }
        });
        Object.defineProperty(this, "getLastStepByBlockValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockValue) => {
                return this.getStepsByBlockValue(blockValue).pop();
            }
        });
        Object.defineProperty(this, "isLastActivePathStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stepID) => {
                const lastStep = this.activePath[this.activePath.length - 1];
                if (lastStep) {
                    return lastStep.id === stepID;
                }
                else {
                    return false;
                }
            }
        });
        Object.defineProperty(this, "determineStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (currentStep, blockValues, factValues) => {
                const node = this.nextNodeDeterminator.determine({
                    currentNode: currentStep.node,
                    blockValues: blockValues,
                    factValues: factValues,
                });
                if (!node) {
                    return null;
                }
                const existing = this.getStepsByNodeID(node.id).find((it) => it.prevStepID === currentStep.id);
                if (existing) {
                    return existing;
                }
                const step = this.createStep(node);
                step.setPrevStepID(currentStep.id);
                return step;
            }
        });
        Object.defineProperty(this, "calculatePath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (targetStep) => {
                const result = [];
                let target = targetStep;
                while (target) {
                    result.unshift(target);
                    if (target.prevStepID) {
                        target = this.getStepByID(target.prevStepID);
                    }
                    else {
                        target = undefined;
                    }
                }
                return result;
            }
        });
        Object.defineProperty(this, "pathContains", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stepID) => {
                return this.activePath.some((it) => it.id === stepID);
            }
        });
        Object.defineProperty(this, "onActiveStepChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('active-step-changed', cb);
            }
        });
        Object.defineProperty(this, "onActivePathChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('active-path-changed', cb);
            }
        });
        Object.defineProperty(this, "onStepAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('step-added', cb);
            }
        });
        makeObservable(this);
        this.observer = new Observer();
        this.pinnedSteps = [];
        this.favoriteStep = undefined;
        this.nextNodeDeterminator = new NextNodeDeterminator(this.flowStore.graphTraverser);
        runInAction(() => {
            this._steps = new Map();
            this.activeStep = undefined;
            this.previewStep = undefined;
            this.activePath = [];
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], StepStore.prototype, "_steps", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], StepStore.prototype, "activePath", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof StepModel !== "undefined" && StepModel) === "function" ? _b : Object)
], StepStore.prototype, "activeStep", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], StepStore.prototype, "previewStep", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "resetAll", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "changeActivePath", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "changeActiveStep", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "changePreviewStep", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "resetPreviewStep", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "addStep", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], StepStore.prototype, "determineStep", void 0);
