import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { Dialog, FallbackView } from 'lib/cortiUI';
import { CreateTeamView } from './actions/CreateUpdateTeamView';
export const NoTeamView = ({ onCreateTeam }) => {
    const { t } = useTranslation('teams');
    const [isOpen, setIsOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(FallbackView, { "data-cy": "no-teams-fallback-view", flexGrow: 1, title: t('teamsView.fallbackView.title', 'No teams created'), text: t('teamsView.fallbackView.text', 'Organize your users into teams for organizational, reporting, and partitioning purposes.'), actionText: rbacService.teamsCRUD() ? t('teamsView.fallbackView.actionBtn', 'Create team') : undefined, action: rbacService.teamsCRUD() ? () => setIsOpen(true) : undefined }),
        rbacService.teamsCRUD() && (React.createElement(Dialog, { open: isOpen, onClose: () => setIsOpen(false), title: t('createTeamView.title', 'Create New Team'), helperText: t('teamForm.headerDescription', 'Chose a name that reflects your company or team hierarchy. We recommend you use the slash separated convention e.g. parent team/child team to reflect relations or levels of nestings.') },
            React.createElement(CreateTeamView, { onSuccess: function onCreateTeamSuccess() {
                    setIsOpen(false);
                    onCreateTeam();
                } })))));
};
