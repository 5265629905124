import React from 'react';
import { FallbackView } from 'lib/cortiUI/components/FallbackView';
import { Loading } from 'lib/cortiUI/components/Loading';
import { FileRenderer } from './FileRenderer';
import { useLoadFile } from './useLoadFile';
/**
 * This component will fetch file contents from the provided `url` and will try to render the preview of it
 */
export function FilePreview({ url, headers, loadingErrorFallbackText }) {
    const fileLoadData = useLoadFile({ url, headers });
    const { isLoadingFile, fileLoadingError, fileContentsUrl, contentType } = fileLoadData;
    if (isLoadingFile) {
        return React.createElement(Loading, null);
    }
    if (fileLoadingError) {
        return React.createElement(FallbackView, { title: loadingErrorFallbackText !== null && loadingErrorFallbackText !== void 0 ? loadingErrorFallbackText : 'Unable to load the file' });
    }
    return React.createElement(FileRenderer, { url: fileContentsUrl, contentType: contentType });
}
