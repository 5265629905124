import { isUndefined, omitBy } from 'lodash';
import React from 'react';
import userSessionStorage from '@corti/lib/userSessionStorage';
// If a breaking change occurs to the state structure type, bump this number
const CACHE_VERSION = 2;
const CACHE_KEY = 'TriageSessionUIState';
/**
 * Triage Session UI state cache.
 * State is shared across all triage sessions.
 */
// eslint-disable-next-line import/no-unused-modules
export const UIStateCache = {
    save(userID, state) {
        userSessionStorage.setItem(CACHE_KEY, JSON.stringify({
            version: CACHE_VERSION,
            userID: userID,
            uiState: state,
        }));
    },
    restore(userID) {
        const string = userSessionStorage.getItem(CACHE_KEY);
        if (!string) {
            return;
        }
        let cache;
        try {
            cache = JSON.parse(string);
        }
        catch (_a) {
            userSessionStorage.removeItem(CACHE_KEY);
            return;
        }
        if (typeof cache !== 'object' || !('version' in cache)) {
            userSessionStorage.removeItem(CACHE_KEY);
            return;
        }
        if (cache.version !== CACHE_VERSION) {
            userSessionStorage.removeItem(CACHE_KEY);
            return;
        }
        if (cache.userID !== userID) {
            userSessionStorage.removeItem(CACHE_KEY);
            return;
        }
        return omitBy(cache.uiState, isUndefined);
    },
};
export function useUIStateCache(session) {
    const [cache, setCache] = React.useState();
    React.useEffect(() => {
        setCache(UIStateCache.restore(session.currentUser.id));
    }, [session]);
    const handleSetCache = React.useCallback((state) => {
        setCache(state);
        UIStateCache.save(session.currentUser.id, { openToolID: state.openToolID });
    }, [session]);
    return {
        cache,
        setCache: handleSetCache,
    };
}
