var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { autorun, makeObservable, observable, runInAction, toJS } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import * as ApiTypes from '@corti/lib/coreApiService';
import { UserPresence } from './UserPresence';
export class UsersRepo {
    constructor(dbTable) {
        Object.defineProperty(this, "dbTable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: dbTable
        });
        Object.defineProperty(this, "_users", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userPresence", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this._users = new Map();
        });
        this.userPresence = new UserPresence();
        autorun(async () => {
            if (this.users.length) {
                await this.dbTable.clear();
                await this.dbTable.bulkPut(this.users.map((it) => toJS(it)));
            }
        });
        api.auth.onSignedOut(async () => {
            this._users = new Map();
            await this.dbTable.clear();
        });
    }
    async loadAllUsers() {
        let users = [];
        try {
            users = await api.users.getAll(api.auth.user.orgID);
        }
        catch (err) {
            // In case of the network error load data from the local db
            users = await this.dbTable.toArray();
        }
        finally {
            runInAction(() => {
                this._users = new Map(users.map((it) => [it.id, it]));
            });
        }
    }
    async createUser(input) {
        try {
            const newUser = await api.users.create(input);
            runInAction(() => {
                this._users.set(newUser.id, newUser);
            });
            return { data: newUser };
        }
        catch (err) {
            if (err instanceof ApiTypes.CoreApiError) {
                return { error: err };
            }
            return { error: { message: 'Create user: unknown error' } };
        }
    }
    async updateUser(userId, input) {
        const userToUpdate = this.getUser(userId);
        if (!userToUpdate) {
            return { error: { message: 'User does not exist' } };
        }
        try {
            const updatedUser = await api.users.update(userId, input);
            runInAction(() => {
                this._users.set(userId, updatedUser);
            });
            return { data: updatedUser };
        }
        catch (err) {
            if (err instanceof ApiTypes.CoreApiError) {
                return { error: err };
            }
            return { error: { message: 'Update user: unknown error' } };
        }
    }
    async deleteUser(userId) {
        try {
            await api.users.delete(userId);
            runInAction(() => {
                this._users.delete(userId);
            });
            return { data: undefined };
        }
        catch (err) {
            if (err instanceof ApiTypes.CoreApiError) {
                return { error: { message: err.message } };
            }
            return { error: { message: 'Delete user: unknown error' } };
        }
    }
    get users() {
        return Array.from(this._users.values());
    }
    getUser(id) {
        return this._users.get(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof UserMap !== "undefined" && UserMap) === "function" ? _a : Object)
], UsersRepo.prototype, "_users", void 0);
