/**
 * Normalizes URLs in exceptions and stacktraces so Sentry can fingerprint
 * across platforms.
 */
function normalizeUrl(url, appPath) {
    return decodeURI(url)
        .replace(/\\/g, '/')
        .replace(new RegExp(`(file:\/\/)?\/*${appPath}\/*`, 'ig'), 'app:///');
}
/**
 * Returns a reference to the exception stack trace in the given event.
 */
function getStacktrace(event) {
    const { stacktrace, exception } = event;
    // Try the main event stack trace first
    if (stacktrace) {
        return stacktrace;
    }
    if (exception) {
        // Raven Node adheres to the Event interface
        // @ts-ignore
        if (exception[0]) {
            // @ts-ignore
            // tslint:disable-next-line:no-unsafe-any
            return exception[0].stacktrace;
        }
        // Raven JS uses the full values interface, which has been removed
        const raven = exception;
        if (raven.values && raven.values[0]) {
            return raven.values[0].stacktrace;
        }
    }
    return undefined;
}
/**
 * Normalizes all URLs in an event. See {@link normalizeUrl} for more
 * information. Mutates the passed in event.
 */
export function normalizeEvent(event, appPath) {
    if (!appPath) {
        return;
    }
    // Retrieve stack traces and normalize their URLs. Without this, grouping
    // would not work due to user folders in file names.
    const stacktrace = getStacktrace(event);
    if (stacktrace && stacktrace.frames) {
        stacktrace.frames.forEach((frame) => {
            if (frame.filename) {
                frame.filename = normalizeUrl(frame.filename, appPath);
            }
        });
    }
    const { request = {} } = event;
    if (request.url) {
        request.url = normalizeUrl(request.url, appPath);
    }
}
