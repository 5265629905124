/**
 * Use this component to group focusable elements if they are loaded
 * dynamically and their number might change during the runtime.
 *
 * Group component should not be loaded dynamically as its purpose
 * is to register group and its position in the list of all
 * focusable elements.
 */
import React from 'react';
import { uuid } from '@corti/uuid';
import { useFocusContext } from './FocusContext';
export function FocusElementGroup(props) {
    const { defaultGroupID, children } = props;
    const id = React.useRef(defaultGroupID !== null && defaultGroupID !== void 0 ? defaultGroupID : uuid());
    const { registry } = useFocusContext();
    React.useEffect(() => {
        registry.registerElementGroup(id.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => registry.unregisterElementGroup(id.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return React.createElement(React.Fragment, null, children({ groupID: id.current }));
}
