var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import AvatarMUI from '@mui/material/Avatar';
import React from 'react';
import { getColor, getInitials, getSize } from './utils';
/**
 * Component that displays initials of provided title text and applies random colors
 */
export const Avatar = React.forwardRef(function Avatar(props, ref) {
    const { children, size = 'regular', sizeInPx, color, bgColor } = props, rest = __rest(props, ["children", "size", "sizeInPx", "color", "bgColor"]);
    const defaultColors = getColor(children);
    const _sizeInPx = sizeInPx !== null && sizeInPx !== void 0 ? sizeInPx : getSize(size);
    return (React.createElement(AvatarMUI, Object.assign({ children: getInitials(children !== null && children !== void 0 ? children : ''), ref: ref, sx: {
            bgcolor: bgColor !== null && bgColor !== void 0 ? bgColor : defaultColors.bgColor,
            width: _sizeInPx,
            height: _sizeInPx,
            color: color !== null && color !== void 0 ? color : defaultColors.color,
            fontSize: _sizeInPx / 2.6,
            fontWeight: 500,
        } }, rest)));
});
