import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, RichTextEditor, RichTextEditorToolbar } from 'lib/cortiUI';
export const FormElementWidget = observer(function FormElementWidget({ model, skipToolbar = false, children, }) {
    const { t } = useTranslation('libGraphEditor');
    const hasToolbar = !skipToolbar;
    return (React.createElement(Base, { "data-cy": "node-section", display: "flex", flexDirection: "column", gap: 6 },
        React.createElement(RichTextEditor, { "data-cy": "node-title", style: { maxHeight: 160 }, placeholder: t('contentBuilder.formElement.labelAttrPlaceholder', 'Add label text'), value: model.label, onChange: (value) => {
                model.setLabel(value);
            } }, hasToolbar && React.createElement(RichTextEditorToolbar, null)),
        children));
});
