var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { DEFAULT_DEFINITION, api, } from '@corti/lib/coreApiService';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { authStore } from 'core/auth';
export class TimelineEntryModel {
    constructor(timelineEntry) {
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "timelineEditor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "clientID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sourceID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "definition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_isEditable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startDatetime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "root", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endDatetime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "callID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.clientID = uuid();
        this.sourceID = timelineEntry.sourceID;
        runInAction(() => {
            var _a, _b;
            this._text = timelineEntry.text;
            this.definition = (_a = timelineEntry.definition) !== null && _a !== void 0 ? _a : DEFAULT_DEFINITION;
            this.startDatetime = timelineEntry.startDatetime;
            this.endDatetime = timelineEntry.endDatetime;
            this.callID = timelineEntry.callID;
            this._isEditable = Boolean(timelineEntry.isEditable);
            this.createdBy = timelineEntry.createdBy;
            this.createdAt = timelineEntry.createdAt || new Date();
            this.root = {
                createdAt: ((_b = timelineEntry.root) === null || _b === void 0 ? void 0 : _b.createdAt)
                    ? new Date(timelineEntry.root.createdAt)
                    : this.createdAt,
            };
        });
    }
    static serverValueToInput(timelineEntry) {
        var _a;
        return {
            sourceID: timelineEntry.sourceID,
            startDatetime: new Date(timelineEntry.startDateTime),
            endDatetime: timelineEntry.endDateTime ? new Date(timelineEntry.endDateTime) : undefined,
            definition: timelineEntry.definition,
            isEditable: timelineEntry.mods.includes('EDITABLE'),
            callID: timelineEntry.callID,
            text: timelineEntry.text,
            createdBy: (_a = timelineEntry.createdBy) === null || _a === void 0 ? void 0 : _a.name,
            createdAt: new Date(timelineEntry.createdAt),
            root: {
                createdAt: new Date(timelineEntry.root.createdAt),
            },
        };
    }
    static getDiff(a, b) {
        const changes = {};
        Object.entries(b.state).forEach(([bKey, bVal]) => {
            const aValue = a.state[bKey];
            if (aValue !== bVal) {
                changes[bKey] = bVal;
            }
        });
        return changes;
    }
    setParent(parent) {
        this.timelineEditor = parent;
    }
    get id() {
        return this.clientID;
    }
    /**
     * Sync with the backend
     */
    async persist() {
        const newSourceID = uuid();
        const res = await api.cases.createTimelineEntry(authStore.organization.id, {
            sourceID: newSourceID,
            startDateTime: this.startDatetime.toISOString(),
            endDateTime: this.endDatetime ? this.endDatetime.toISOString() : undefined,
            definitionSource: this.definition.source,
            callID: this.callID,
        }, this.sourceID);
        this.sourceID = res.sourceID;
        runInAction(() => {
            var _a;
            this._isEditable = res.mods.includes('EDITABLE');
            this.createdBy = (_a = res.createdBy) === null || _a === void 0 ? void 0 : _a.name;
            this.createdAt = new Date(res.createdAt);
            this.root = {
                createdAt: new Date(res.root.createdAt),
            };
        });
    }
    get isActive() {
        var _a;
        return ((_a = this.timelineEditor) === null || _a === void 0 ? void 0 : _a.activeTimelineEntryID) === this.id;
    }
    updatePosition(newPosition) {
        if (this.isEditable) {
            this.handleBeforeChange();
            const { startDatetime, endDatetime } = this.ensurePositionWithinBoundaries(newPosition);
            this.startDatetime = startDatetime;
            this.endDatetime = endDatetime;
        }
    }
    setDefinition(def) {
        if (this.isEditable) {
            this.handleBeforeChange();
            this.definition = def;
        }
    }
    remove() {
        if (this.isEditable && this.timelineEditor) {
            this.timelineEditor.removeTimelineEntry(this.id);
        }
    }
    get call() {
        return this.callID && this.timelineEditor
            ? this.timelineEditor.getCall(this.callID)
            : undefined;
    }
    get caseModel() {
        var _a;
        return (_a = this.timelineEditor) === null || _a === void 0 ? void 0 : _a.caseModel;
    }
    updateOffsetPosition(newPosition, baseDatetime) {
        this.updatePosition({
            startDatetime: new Date(baseDatetime.getTime() + newPosition.offsetStart),
            endDatetime: newPosition.offsetEnd
                ? new Date(baseDatetime.getTime() + newPosition.offsetEnd)
                : undefined,
        });
    }
    getOffsetPosition(baseDatetime) {
        return {
            start: this.startDatetime.getTime() - baseDatetime.getTime(),
            end: this.endDatetime ? this.endDatetime.getTime() - baseDatetime.getTime() : undefined,
        };
    }
    get isEditable() {
        var _a;
        return Boolean(this._isEditable && ((_a = this.call) === null || _a === void 0 ? void 0 : _a.callAudioDurationInSeconds) && !this.isLocked);
    }
    get boundaries() {
        if (!this.caseModel) {
            return {
                left: this.startDatetime,
                right: this.endDatetime || this.startDatetime,
            };
        }
        if (!this.call) {
            return {
                left: this.caseModel.startedAt,
                right: this.caseModel.endedAt || this.caseModel.startedAt,
            };
        }
        return {
            left: this.call.start,
            right: new Date(this.call.start.getTime() + (this.call.callAudioDurationInSeconds || 0) * 1000),
        };
    }
    ensurePositionWithinBoundaries(position) {
        let startDatetime = position.startDatetime;
        let endDatetime = position.endDatetime;
        if (position.startDatetime < this.boundaries.left) {
            startDatetime = this.boundaries.left;
        }
        else if (position.startDatetime > this.boundaries.right) {
            startDatetime = this.boundaries.right;
        }
        if (position.endDatetime == null) {
            endDatetime = undefined;
        }
        else if (position.endDatetime < startDatetime) {
            endDatetime = startDatetime;
        }
        else if (position.endDatetime > this.boundaries.right) {
            endDatetime = this.boundaries.right;
        }
        return {
            startDatetime,
            endDatetime,
        };
    }
    get text() {
        return this._text || this.definition.text;
    }
    captureSnapshot() {
        const snap = {
            state: {
                timelineEditor: this.timelineEditor,
                startDatetime: this.startDatetime,
                endDatetime: this.endDatetime,
                definition: this.definition,
            },
        };
        return snap;
    }
    resetToSnapshot(snapshot) {
        Object.assign(this, snapshot.state);
    }
    handleBeforeChange() {
        this.observer.fireEvent('beforeChange');
    }
    get isDirty() {
        var _a;
        return Boolean((_a = this.timelineEditor) === null || _a === void 0 ? void 0 : _a.diff.changedItems.includes(this));
    }
    get isLocked() {
        return Boolean(this.timelineEditor && this.timelineEditor.isTimelineLocked);
    }
    onBeforeChange(cb) {
        return this.observer.on('beforeChange', cb);
    }
    unAll() {
        return this.observer.unAll();
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEntryModel.prototype, "definition", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], TimelineEntryModel.prototype, "_isEditable", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], TimelineEntryModel.prototype, "startDatetime", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], TimelineEntryModel.prototype, "createdAt", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEntryModel.prototype, "root", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], TimelineEntryModel.prototype, "_text", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof Date !== "undefined" && Date) === "function" ? _c : Object)
], TimelineEntryModel.prototype, "endDatetime", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], TimelineEntryModel.prototype, "callID", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], TimelineEntryModel.prototype, "createdBy", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "isActive", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEntryModel.prototype, "updatePosition", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEntryModel.prototype, "setDefinition", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "call", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "caseModel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, typeof (_d = typeof Date !== "undefined" && Date) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], TimelineEntryModel.prototype, "updateOffsetPosition", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "isEditable", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "text", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], TimelineEntryModel.prototype, "captureSnapshot", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEntryModel.prototype, "resetToSnapshot", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEntryModel.prototype, "handleBeforeChange", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "isDirty", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryModel.prototype, "isLocked", null);
