import { getReadableCaseID, } from '@corti/lib/coreApiService';
import { authStore } from 'core/auth';
export class CaseMergeModel {
    constructor(input) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = input.id;
        this.startedAt = new Date(input.startedAt);
        this.createdBy = input.createdBy;
        this.customProperties = input.customProperties;
    }
    get readableCaseID() {
        return getReadableCaseID(this.id);
    }
    get customReadableCaseID() {
        const customPropertyAsCaseID = authStore.organization.clientSettings.customPropertyAsCaseID;
        if (customPropertyAsCaseID) {
            return this.customProperties[customPropertyAsCaseID] || '-';
        }
    }
}
