import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { FeedbackFormManagerView } from 'browser/pages/TemplatesManagementView/feedbackFormManager';
import { Route } from 'lib/router';
import { FeedbackFormTemplatesView } from './FeedbackFormTemplatesView';
export const TemplatesManagement = () => {
    const match = useRouteMatch();
    const { t } = useTranslation('templatesManagement');
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true },
            React.createElement(FeedbackFormTemplatesView, null)),
        React.createElement(Route, { path: `${match.path}/builder`, breadcrumb: t('templateBuilder', 'Template builder'), render: () => React.createElement(FeedbackFormManagerView, null) })));
};
