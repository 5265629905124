import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { Base, ChipV2, IconButton, Menu, MenuItem, Typography } from 'lib/cortiUI';
export function TopToolbarActions({ caseEntity, handleOpenMenu, menuAnchorEl, handleCloseMenu, handleCallExcludingToggle, openCasesMergeView, onCreateFeedbackButtonClick, openScreenRecordingsView, }) {
    const theme = useTheme();
    return (React.createElement(Base, { display: "flex", gap: 6, alignItems: "center", justifyContent: "flex-end" },
        caseEntity.activeCall && caseEntity.isActiveCallExcluded && (React.createElement(ChipV2, { size: "medium", icon: React.createElement(DoDisturbRoundedIcon, null), label: intl.t('explore:caseView.callExcludedChip', 'Excluded from performance tracking') })),
        React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: handleOpenMenu, className: css({
                backgroundColor: transparentize(0.1, theme.palette.action.disabled),
                '&:hover': {
                    backgroundColor: theme.palette.action.disabled,
                },
            }), icon: React.createElement(MoreHorizRoundedIcon, null) }),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: handleCloseMenu, anchorEl: menuAnchorEl },
            rbacService.feedbackSubmissionCRUD({ caseOwnerID: caseEntity.createdBy.id }) && (React.createElement(MenuItem, { "data-cy": "start-review-btn", onClick: onCreateFeedbackButtonClick },
                React.createElement(Typography, null, intl.t('explore:caseView.startReview', 'Start review')))),
            rbacService.casesMerge() && (React.createElement(MenuItem, { "data-cy": "merge-cases-btn", onClick: openCasesMergeView },
                React.createElement(Typography, null, intl.t('explore:caseView.caseMerge.mergeCases', 'Merge cases')))),
            caseEntity.activeCall && rbacService.performanceMetricsExcludeCalls() && (React.createElement(MenuItem, { onClick: handleCallExcludingToggle },
                React.createElement(Typography, null, caseEntity.isActiveCallExcluded
                    ? intl.t('explore:caseView.includeCall', 'Include in performance tracking')
                    : intl.t('explore:caseView.excludeCall', 'Exclude from performance tracking')))),
            rbacService.appScreenRecorder() && (React.createElement(MenuItem, { onClick: openScreenRecordingsView },
                React.createElement(Typography, null, intl.t('explore:caseView.openScreenRecorderModal', 'View recordings')))))));
}
