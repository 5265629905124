var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { observer } from 'mobx-react';
import Mousetrap from 'mousetrap';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { useMergeRefs } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { Base, Box } from 'lib/cortiUI';
import { getFocusWithinCss } from '../../utils';
import { Label, TextInput } from '../components';
import { FOCUS_CONTAINER_KEY } from './constants';
export const BaseInputBlock = observer(forwardRef((_a, forwardedRef) => {
    var { value, isReadonly, label, children, inputRef: parentInputRef } = _a, inputProps = __rest(_a, ["value", "isReadonly", "label", "children", "inputRef"]);
    const theme = useTheme();
    const hoverCss = css({
        '&:hover': { border: `1px solid ${theme.palette.action.outlinedHoverBorder}` },
    });
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    // If not readonly, attach mousetrap to make 'esc' move focus up to container
    useEffect(() => {
        if (isReadonly || !containerRef.current || !inputRef.current) {
            return;
        }
        const inputKeybinder = new Mousetrap(inputRef.current);
        inputKeybinder.bind(FOCUS_CONTAINER_KEY, (e) => {
            var _a;
            e.stopPropagation();
            (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        });
        return () => void inputKeybinder.reset();
    }, [isReadonly]);
    const handleContainerClick = (e) => {
        var _a;
        e.preventDefault();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const inputId = useMemo(() => uuid(), []);
    const containerRefs = useMergeRefs(forwardedRef, containerRef);
    const inputRefs = useMergeRefs(parentInputRef, inputRef);
    return (React.createElement(Box, null,
        label && (React.createElement(Base, { mb: 3 },
            React.createElement(Label, { htmlFor: inputId, label: label }))),
        React.createElement(Base, { tabIndex: -1, ref: containerRefs, onClick: handleContainerClick, position: "relative", className: css({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: Number(theme.shape.borderRadius),
                border: `1px solid ${theme.palette.action.outlinedBorder}`,
                backgroundColor: theme.palette.background.paper,
                paddingLeft: theme.space[5],
                paddingRight: theme.space[5],
                paddingTop: theme.space[6],
                paddingBottom: theme.space[6],
                minHeight: 55,
            }, isReadonly ? undefined : hoverCss, isReadonly ? undefined : getFocusWithinCss({ theme })) },
            React.createElement(TextInput, Object.assign({ ref: inputRefs, id: inputId, value: value, readOnly: isReadonly, inputProps: {
                    tabIndex: isReadonly ? -1 : 0,
                } }, inputProps)),
            children)));
}));
