import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Button, Tooltip } from 'lib/cortiUI';
import { FeedbackFormField } from './FeedbackFormField';
import { FormValueUtils } from './FormValue';
export const FeedbackForm = observer((props) => {
    const { disabled, isPreviewMode, formValue, template, onChoiceClick, onChoiceHintClick, onSubmit, onChange, onCreateTimelineEntry, } = props;
    const notFilledRequiredQuestions = FormValueUtils.getNotFilledRequiredFields(formValue, template);
    const isFormValid = !notFilledRequiredQuestions.length;
    const submissionEnabled = isFormValid && !disabled;
    const formValueRef = React.useRef(formValue);
    const [isHighlighted, setIsHighlighted] = React.useState(false);
    React.useEffect(() => {
        formValueRef.current = formValue;
    }, [formValue]);
    function handleSubmit() {
        if (onSubmit && !isPreviewMode && submissionEnabled) {
            onSubmit(FormValueUtils.formValueToSubmission(formValue, template));
        }
    }
    function handleClear() {
        setIsHighlighted(false);
        onChange && onChange({ fields: {} });
    }
    function handleOnChange(val, fieldDescriptor) {
        onChange &&
            onChange({
                fields: Object.assign(Object.assign({}, formValueRef.current.fields), { [fieldDescriptor.id]: Object.assign(Object.assign({}, formValueRef.current.fields[fieldDescriptor.id]), { value: val }) }),
            });
    }
    function handleOnCommentChange(val, fieldDescriptor) {
        onChange &&
            onChange({
                fields: Object.assign(Object.assign({}, formValueRef.current.fields), { [fieldDescriptor.id]: Object.assign(Object.assign({}, formValueRef.current.fields[fieldDescriptor.id]), { comment: val }) }),
            });
    }
    function handleOnFieldReset(fieldDescriptor) {
        onChange &&
            onChange({
                fields: Object.assign(Object.assign({}, formValueRef.current.fields), { [fieldDescriptor.id]: Object.assign(Object.assign({}, formValueRef.current.fields[fieldDescriptor.id]), { value: '', comment: '' }) }),
            });
    }
    function isFieldHighlighted(fieldId) {
        return !isPreviewMode && notFilledRequiredQuestions.includes(fieldId) && isHighlighted;
    }
    return (React.createElement(Base, { "data-cy": "feedback-form", display: "grid", gridGap: 7 },
        template.fields.map((fieldDescriptor) => {
            if (FormValueUtils.isFieldVisible(fieldDescriptor, formValue)) {
                return (React.createElement(FeedbackFormField, { key: fieldDescriptor.id, isPreviewMode: isPreviewMode, isHighlighted: isFieldHighlighted(fieldDescriptor.id), descriptor: fieldDescriptor, value: FormValueUtils.getFieldValue(fieldDescriptor.id, formValue), commentValue: FormValueUtils.getFieldComment(fieldDescriptor.id, formValue), onChange: (val) => handleOnChange(val, fieldDescriptor), onCommentChange: (val) => handleOnCommentChange(val, fieldDescriptor), onFieldReset: () => handleOnFieldReset(fieldDescriptor), onChoiceClick: (val) => {
                        if (onChoiceClick) {
                            onChoiceClick(fieldDescriptor, val);
                        }
                    }, onCreateTimelineEntry: (definition) => {
                        if (onCreateTimelineEntry) {
                            onCreateTimelineEntry(fieldDescriptor, definition);
                        }
                    }, onChoiceHintClick: (timelineEntry) => {
                        if (onChoiceHintClick) {
                            onChoiceHintClick(timelineEntry);
                        }
                    } }));
            }
        }),
        !isPreviewMode && (React.createElement(Base, { display: "flex", justifyContent: "space-between", mb: 2, mx: 5 },
            !isFormValid && (React.createElement(Tooltip, { disableInteractive: true, title: intl.t('feedbackForm.highlightRequiredFields', 'Highlight remaining questions') },
                React.createElement(Button, { noWrap: true, size: "small", onClick: () => setIsHighlighted(true) }, intl.t('feedbackForm.remainingQuestions', {
                    count: notFilledRequiredQuestions.length,
                    defaultValue_one: `{{count}} question remaining`,
                    defaultValue_other: `{{count}} questions remaining`,
                })))),
            isFormValid && (React.createElement(Button, { color: "primary", disabled: !submissionEnabled, onClick: handleSubmit, "data-cy": "feedback-form-submit-btn", className: css({ marginRight: 2 }) }, intl.t('feedbackForm.submitBtn', 'Submit'))),
            React.createElement(Button, { disabled: isEmpty(formValue.fields), onClick: handleClear }, intl.t('cancel', 'Cancel'))))));
});
