var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { InfoRounded } from '@mui/icons-material';
import AddRounded from '@mui/icons-material/AddRounded';
import { isEqual, partial } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { useHistoryBlock } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Base, Button, Card, CardContent, Checkbox, Container, FormControlLabel, IconButton, Modal, ModalContent, TextField, Tooltip, Typography, } from 'lib/cortiUI';
import { ControlsPanel, FieldPreview, FieldWidget, NavigationBlockerModal, VisibleIfWidget, } from './components';
import { getTimelineEntryDefinitions } from './graphql';
import { CY_SELECTORS, copyTemplateFields, getConditionQuestionTitle, getDefaultField, transformStateToJSON, } from './utils';
export function FeedbackFormManagerView() {
    const { t } = useTranslation('libFeedbackFormManager');
    const location = useLocation();
    const authStore = useAuth();
    const theme = useTheme();
    const history = useHistory();
    const organizationID = authStore.organization.id;
    const { data: definitionsData } = useQuery(getTimelineEntryDefinitions, {
        variables: {
            organizationID,
        },
    });
    const [formFields, setFormFields] = React.useState([]);
    const { control, handleSubmit: onSubmit, reset, getValues, setValue, unregister, watch, } = useForm();
    const DEFAULT_FIELD = getDefaultField();
    const DEFAULT_FORM = {
        title: '',
        anonymizeReviewers: false,
        [DEFAULT_FIELD.id]: DEFAULT_FIELD,
    };
    const [visibleIfActiveFieldId, setVisibleIfActiveFieldId] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    //TODO: Instead of hardcoded checks use 'isDirty' from 'useForm'.
    //To use isDirty needs to refactor fields logic to use useFieldArray.
    const shouldBlock = (function checkIsBlocked() {
        const currentValue = watch();
        const currentFieldList = getFieldsList();
        const areDefaultFields = currentFieldList.length === 1 &&
            currentFieldList[0].type === DEFAULT_FIELD.type &&
            currentFieldList[0].title === DEFAULT_FIELD.title &&
            currentFieldList[0].required === DEFAULT_FIELD.required &&
            formFields.length === 1;
        return (currentValue.title !== DEFAULT_FORM.title ||
            currentValue.anonymizeReviewers !== false ||
            !areDefaultFields);
    })();
    const { isBlocked, proceed: proceedNavigation, cancel: cancelNavigation, } = useHistoryBlock(() => shouldBlock, [shouldBlock]);
    const [focusedFieldId, setFocusedFieldId] = React.useState();
    React.useEffect(() => {
        var _a, _b;
        const templateID = (_a = location.state) === null || _a === void 0 ? void 0 : _a.templateID;
        if (templateID) {
            api.caseFeedback
                .getFeedbackFormTemplateByID(templateID)
                .then((template) => {
                if (template) {
                    setValue('title', `${template.title} (${t('copy', 'Copy')})`);
                    setValue('anonymizeReviewers', template.anonymizeReviewers);
                    const transformedFields = copyTemplateFields(template);
                    setFormFields(transformedFields);
                    setFocusedFieldId(transformedFields[0].id);
                }
            })
                .catch(addNewField);
        }
        else if ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.uploadedTemplate) {
            const uploadedTemplate = location.state.uploadedTemplate;
            setValue('title', uploadedTemplate.title);
            setValue('anonymizeReviewers', uploadedTemplate.anonymizeReviewers);
            const transformedFields = copyTemplateFields(uploadedTemplate);
            setFormFields(transformedFields);
            if (transformedFields[0]) {
                setFocusedFieldId(transformedFields[0].id);
            }
        }
        else {
            addNewField();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getFieldsList() {
        return Object.values(getValues()).filter((it) => !!it.id);
    }
    function getVisibilityConditionsAvailableFields(fieldId) {
        const fields = getFieldsList();
        if (!fieldId)
            return fields;
        let currentFieldIndx = undefined;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id === fieldId) {
                currentFieldIndx = i;
                break;
            }
        }
        if (typeof currentFieldIndx === 'undefined')
            return fields;
        return fields.slice(0, currentFieldIndx);
    }
    function addNewField() {
        const newField = getDefaultField();
        setFormFields((prevState) => [...prevState, newField]);
        setFocusedFieldId(newField.id);
    }
    function removeField(id) {
        setFormFields([...formFields.filter((f) => f.id !== id)]);
        const fields = getFieldsList();
        const fieldsTitlesContainingDeletedFieldAsCondition = [];
        fields.forEach((field, i) => {
            var _a, _b;
            const conditions = (_a = field.visibleIf) === null || _a === void 0 ? void 0 : _a.conditions;
            if (conditions) {
                const includesDeletedField = conditions.find((cond) => cond.fieldID === id);
                if (includesDeletedField && field.visibleIf) {
                    fieldsTitlesContainingDeletedFieldAsCondition.push(field.title ? field.title : getConditionQuestionTitle(i));
                    const updatedVisibleIf = Object.assign(Object.assign({}, field.visibleIf), { conditions: (_b = field.visibleIf) === null || _b === void 0 ? void 0 : _b.conditions.filter((c) => c.fieldID !== id) });
                    setValue(field.id, Object.assign(Object.assign({}, field), { visibleIf: updatedVisibleIf }));
                }
            }
        });
        if (fieldsTitlesContainingDeletedFieldAsCondition.length) {
            coreStore.notifications.showNotification({
                type: 'warning',
                message: t('warnings.removeField', {
                    defaultValue: 'Visibility conditions of {{number}} field has been changed! Field: {{titles}}.',
                    number: fieldsTitlesContainingDeletedFieldAsCondition.length,
                    titles: fieldsTitlesContainingDeletedFieldAsCondition.join(', ').toUpperCase(),
                }),
            });
        }
        unregister(id);
    }
    function updateFieldType(onChangeClb, field, newType) {
        const hasTypeChangedFromTextToMultipleChoice = (field.type === 'radio' || field.type === 'dropdown') &&
            (newType === 'short-text' || newType === 'long-text');
        const hasTypeChangedFromMultipleChoiceToText = (field.type === 'short-text' || field.type === 'long-text') &&
            (newType === 'radio' || newType === 'dropdown');
        if (hasTypeChangedFromTextToMultipleChoice || hasTypeChangedFromMultipleChoiceToText) {
            const fields = getFieldsList();
            const fieldsHaveCurrentFieldInVisibleIf = fields.find(({ visibleIf }) => visibleIf === null || visibleIf === void 0 ? void 0 : visibleIf.conditions.find((c) => c.fieldID === field.id));
            if (fieldsHaveCurrentFieldInVisibleIf) {
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: t('errors.changeType', 'This field has been used in visibility conditions configuration for other fields. Remove this field from visibility conditions in other places and try changing type again.'),
                });
                return;
            }
        }
        const updatedField = Object.assign(Object.assign({}, field), { type: newType });
        if (hasTypeChangedFromMultipleChoiceToText) {
            updatedField.choices = [
                {
                    value: uuid(),
                    text: '',
                },
            ];
        }
        onChangeClb(updatedField);
    }
    function updateChoice(onChangeClb, updatedField, choice) {
        if (!updatedField.choices)
            return;
        const updatedChoices = updatedField.choices.map((c) => c.value === choice.value ? Object.assign(Object.assign({}, c), { text: choice.text }) : Object.assign({}, c));
        onChangeClb(Object.assign(Object.assign({}, updatedField), { choices: updatedChoices }));
        // Update condition if field has this choice selected as a value in `field.visibleId.conditions` list
        const fieldsHaveCurrentFieldOptionInVisibleIf = [];
        getFieldsList().forEach((f) => {
            var _a;
            const conditionIncludesCurrentField = (_a = f.visibleIf) === null || _a === void 0 ? void 0 : _a.conditions.find(({ fieldID, values }) => fieldID === updatedField.id &&
                values[0].value === choice.value);
            if (conditionIncludesCurrentField)
                fieldsHaveCurrentFieldOptionInVisibleIf.push(f);
        });
        fieldsHaveCurrentFieldOptionInVisibleIf.forEach((f) => {
            var _a;
            const { visibleIf, id } = f;
            if (visibleIf) {
                const updatedConditions = (_a = visibleIf.conditions) === null || _a === void 0 ? void 0 : _a.map((cond) => {
                    const currentCondition = cond.fieldID === updatedField.id;
                    const [conditionValue] = cond.values;
                    if (currentCondition &&
                        typeof conditionValue === 'object' &&
                        isEqual(conditionValue.value, choice.value)) {
                        return Object.assign(Object.assign({}, cond), { values: [{ value: choice.value, label: choice.text }] });
                    }
                    return cond;
                });
                setValue(id, Object.assign(Object.assign({}, f), { visibleIf: Object.assign(Object.assign({}, visibleIf), { conditions: updatedConditions }) }));
            }
        });
    }
    function removeOption(onChangeClb, field, removedOptionValue) {
        var _a;
        const fieldsHaveCurrentFieldOptionInVisibleIf = [];
        const fields = getFieldsList();
        // Show error when user tries to delete an option that is used in some fields' visible conditions
        fields.forEach(({ visibleIf, title }, i) => {
            const conditionHasCurrentField = visibleIf === null || visibleIf === void 0 ? void 0 : visibleIf.conditions.find((condition) => condition.fieldID === field.id &&
                typeof condition.values[0] !== 'string' &&
                condition.values[0].value === removedOptionValue);
            if (conditionHasCurrentField)
                fieldsHaveCurrentFieldOptionInVisibleIf.push(title ? title : getConditionQuestionTitle(i));
        });
        if (fieldsHaveCurrentFieldOptionInVisibleIf.length > 0) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('errors.removeOption', 'This option has been used in visibility conditions  for {{number}} field. Remove this option from visibility conditions in this field and try changing type again.  Field: {{titles}}.', {
                    number: fieldsHaveCurrentFieldOptionInVisibleIf.length,
                    titles: fieldsHaveCurrentFieldOptionInVisibleIf.join(', ').toUpperCase(),
                }),
            });
            return;
        }
        const updatedChoices = (_a = field.choices) === null || _a === void 0 ? void 0 : _a.filter((choice) => choice.value !== removedOptionValue);
        onChangeClb(Object.assign(Object.assign({}, field), { choices: updatedChoices }));
    }
    function copyField(field) {
        const newField = Object.assign(Object.assign({}, field), { id: uuid() });
        setFormFields((current) => [...current, newField]);
        setFocusedFieldId(newField.id);
    }
    function openVisibleIfWidget(fieldID) {
        setVisibleIfActiveFieldId(fieldID);
    }
    function closeVisibleIfWidget() {
        setVisibleIfActiveFieldId(undefined);
    }
    function resetForm() {
        const initialTitle = '';
        const initialAnonymizeReviewers = false;
        const initialField = getDefaultField();
        setFormFields([initialField]);
        reset({
            title: initialTitle,
            anonymizeReviewers: initialAnonymizeReviewers,
            [initialField.id]: initialField,
        });
    }
    function validateFields() {
        var _a;
        const data = {
            isValid: true,
            messages: {
                formTitle: {
                    present: false,
                    message: t('errors.noFormTitle', 'Feedback template title is required.'),
                },
                field: {
                    present: false,
                    message: t('errors.noFieldTitle', 'Some question fields do not have titles.'),
                },
                option: {
                    present: false,
                    message: t('errors.noOptions', 'Some multiple choice or dropdown fields do not have options.'),
                },
                optionTitle: {
                    present: false,
                    message: t('errors.noOptionTitles', 'Some multiple choice or dropdown fields options do not have titles.'),
                },
            },
        };
        const formValuesObject = getValues();
        const fields = getFieldsList();
        if (!formValuesObject.title) {
            data.isValid = false;
            data.messages.formTitle.present = true;
        }
        for (const field of fields) {
            const { title, type } = field;
            if (!title) {
                data.isValid = false;
                data.messages.field.present = true;
            }
            if (type === 'dropdown' || type === 'radio') {
                if (!field.choices) {
                    data.isValid = false;
                    data.messages.option.present = true;
                }
                (_a = field.choices) === null || _a === void 0 ? void 0 : _a.forEach((choice) => {
                    if (!choice.text) {
                        data.isValid = false;
                        data.messages.optionTitle.present = true;
                    }
                });
            }
        }
        return data;
    }
    async function handleSubmit(data) {
        const { title, anonymizeReviewers } = data, formFields = __rest(data, ["title", "anonymizeReviewers"]);
        const validationData = validateFields();
        if (!validationData.isValid) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: Object.values(validationData.messages).reduce((errorMessage, data) => data.present ? errorMessage.concat(`${data.message}`) : errorMessage, ''),
            });
            return;
        }
        const inputField = transformStateToJSON(title, anonymizeReviewers, organizationID, Object.values(formFields)).template.fields;
        try {
            setIsLoading(true);
            await api.caseFeedback.createFeedbackFormTemplate({
                title,
                anonymizeReviewers,
                organizationID,
                fields: inputField,
            });
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('successMessage', 'Template has been created'),
            });
            resetForm();
            history.push(`/organisation-settings/templates-management`);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(Container, { "data-cy": CY_SELECTORS.view, className: css(getScrollerCss({ theme })) },
        React.createElement(Base, { width: 1, display: "flex", justifyContent: "center", pb: 10 },
            React.createElement(Base, { width: 0.8 },
                React.createElement(Base, { width: 1 },
                    React.createElement(ControlsPanel, { isLoading: isLoading, onReset: resetForm, onSubmit: onSubmit(handleSubmit) }),
                    React.createElement(Card, null,
                        React.createElement(CardContent, { sx: { width: 1 } },
                            React.createElement(Base, { display: "flex", flexDirection: 'column', gap: 6 },
                                React.createElement(Controller, { name: "title", control: control, defaultValue: '', rules: {
                                        required: t('errors.noFormTitle', 'Feedback template title is required.'),
                                    }, render: ({ field, fieldState }) => {
                                        var _a;
                                        return (React.createElement(TextField, { required: true, sx: { width: 1 }, name: field.name, error: Boolean(fieldState.error), helperText: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, value: field.value, onChange: ({ target }) => field.onChange(target.value), label: t('formTitleLabel', 'Template title'), inputProps: { 'data-cy': CY_SELECTORS.templateTitleField } }));
                                    } }),
                                React.createElement(Controller, { name: "anonymizeReviewers", control: control, defaultValue: false, render: ({ field }) => (React.createElement(Base, { display: "flex", justifyContent: "flex-start", gap: 1 },
                                        React.createElement(FormControlLabel, { className: css({ marginRight: 0 }), label: t('anonymizeReviewers', 'Anonymize template'), control: React.createElement(Checkbox, { checked: field.value, "data-cy": "anonymize-template-chk", disableRipple: true, onChange: ({ target }) => field.onChange(target.checked) }) }),
                                        React.createElement(Tooltip, { title: t('actionsHints.anonymize', 'Marking a template as “Anonymous” will hide the identity of all users who submit feedback using this template') },
                                            React.createElement(IconButton, { className: css({ margin: 1 }), icon: React.createElement(InfoRounded, { fontSize: "small" }) })))) })))),
                    React.createElement(Base, { alignItems: "center", display: "flex", mt: 9 },
                        React.createElement(Typography, { variant: "h6", color: "default", mb: 6, "data-cy": CY_SELECTORS.questionsTitle }, t('fieldsListSectionTitle', 'Questions'))),
                    React.createElement(Base, { height: "100%", display: "flex", flexDirection: "column", gap: 8, "data-cy": "template-form-fields" }, formFields.map((f, i) => (React.createElement(Controller, { key: f.id, name: f.id, control: control, defaultValue: f, render: ({ field }) => {
                            var _a, _b, _c;
                            return f.id === focusedFieldId ? (React.createElement(FieldWidget, { fieldData: field.value, timelineEntryDefinitions: (_a = definitionsData === null || definitionsData === void 0 ? void 0 : definitionsData.timelineEntryDefinitions) !== null && _a !== void 0 ? _a : [], onFieldUpdate: field.onChange, onTypeUpdate: partial(updateFieldType, field.onChange), onChoiceUpdate: partial(updateChoice, field.onChange), onCopy: () => copyField(field.value), onRemove: () => removeField(f.id), onOptionRemove: partial(removeOption, field.onChange), onOpenVisibleIfWidget: openVisibleIfWidget, renderVisibilityConditionsTrigger: i > 0 })) : (React.createElement(Card, { px: 6, py: 0, onClick: () => setFocusedFieldId(field.value.id), style: { cursor: 'pointer' }, "data-cy": CY_SELECTORS.preview.previewCard },
                                React.createElement(FieldPreview, { fieldType: field.value.type, title: field.value.title, required: field.value.required, choices: field.value.type === 'radio' || field.value.type === 'dropdown'
                                        ? field.value.choices
                                        : undefined, visibilityConditionsCount: (_c = (_b = field.value.visibleIf) === null || _b === void 0 ? void 0 : _b.conditions) === null || _c === void 0 ? void 0 : _c.length })));
                        } }))))),
                React.createElement(Base, { width: 1, display: "flex", justifyContent: "flex-end", py: 8 },
                    React.createElement(Button, { endIcon: React.createElement(AddRounded, { fontSize: "small" }), color: "primary", size: "small", className: css({ marginLeft: 5 }), onClick: () => addNewField(), "data-cy": CY_SELECTORS.addFieldBtn }, t('addNewFieldBtn', 'Add New Field'))))),
        React.createElement(Modal, { open: Boolean(visibleIfActiveFieldId), onClose: closeVisibleIfWidget, "data-cy": CY_SELECTORS.visibleIfWidget.visibleIfWidgetModal },
            React.createElement(ModalContent, { paddingBottom: 0 },
                React.createElement(Controller, { name: visibleIfActiveFieldId, control: control, render: ({ field }) => (React.createElement(VisibleIfWidget, { data: field.value.visibleIf, fields: getVisibilityConditionsAvailableFields(field.value.id), onChange: (visibleIf) => field.onChange(Object.assign(Object.assign({}, field.value), { visibleIf })), onClose: closeVisibleIfWidget })) }))),
        React.createElement(NavigationBlockerModal, { isBlocked: isBlocked, onCancelNavigation: cancelNavigation, onProceedNavigation: proceedNavigation })));
}
