import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Typography } from 'lib/cortiUI';
export const NoRowsOverlay = () => {
    const { t } = useTranslation('performanceApp');
    return (React.createElement(Typography, { className: css({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }) }, t('noResultsFound', 'No results found')));
};
