import { calculatePerformanceScore, } from '../../../activeView/context/dataContext';
export function getUserTrend(lastPeriodBreakdown, previousPeriodBreakdown) {
    if (lastPeriodBreakdown.length === 0 || previousPeriodBreakdown.length === 0) {
        return undefined;
    }
    else {
        const lastTrend = calculatePerformanceScore(lastPeriodBreakdown);
        const previousTrend = calculatePerformanceScore(previousPeriodBreakdown);
        if (lastTrend !== 0 && previousTrend !== 0) {
            return lastTrend / previousTrend - 1;
        }
        else {
            return undefined;
        }
    }
}
