import { trimEnd } from 'lodash';
export function generateUrlSegments(url) {
    const [, ...parts] = trimEnd(url, '/').split('/');
    return parts.map((segmentName, idx) => {
        let segmentUrl = '/' + parts.slice(0, idx + 1).join('/');
        return {
            url: segmentUrl,
            segmentName,
        };
    });
}
export function getBaseUrl(url) {
    return url.split('/').slice(0, -1).join('/');
}
