import { intl } from '@corti/i18n';
import { authStore } from 'core/auth';
const commonTranslations = {
    lower: (count) => intl.t('password:requirements.lower', {
        count,
        defaultValue_one: '{{count}} lowercase letter',
        defaultValue_other: '{{count}} lowercase letters',
    }),
    capital: (count) => intl.t('password:requirements.capital', {
        count,
        defaultValue_one: '{{count}} capital letter',
        defaultValue_other: '{{count}} capital letters',
    }),
    numeral: (count) => intl.t('password:requirements.numeral', {
        count,
        defaultValue_one: '{{count}} numeral',
        defaultValue_other: '{{count}} numerals',
    }),
    symbol: (count) => intl.t('password:requirements.symbol', {
        count,
        defaultValue_one: '{{count}} special character',
        defaultValue_other: '{{count}} special characters',
    }),
    mustHave: () => intl.t('password:requirements.mustHave', 'Password must have at least'),
    unfulfilledPasswordRequirements: () => intl.t('password:feedback.unfulfilledPasswordRequirements', "Your chosen password doesn't fulfill your organization's password requirements. Please choose a stronger password."),
    passwordReused: () => intl.t('password:feedback.passwordReused', 'You may not reuse a previous password. Please choose a new one.'),
    charsLength: (count) => intl.t('password:requirements.chars', '{{count}} characters', {
        count,
    }),
};
export function passwordRequirementsText() {
    const passwordRequirements = authStore.organization.organizationSecuritySettings.passwordRequirements;
    const enumerationComponents = [];
    if (passwordRequirements === null || passwordRequirements === void 0 ? void 0 : passwordRequirements.numberOfLowercase) {
        enumerationComponents.push(commonTranslations.lower(passwordRequirements.numberOfLowercase));
    }
    if (passwordRequirements === null || passwordRequirements === void 0 ? void 0 : passwordRequirements.numberOfCapitals) {
        enumerationComponents.push(commonTranslations.capital(passwordRequirements.numberOfCapitals));
    }
    if (passwordRequirements === null || passwordRequirements === void 0 ? void 0 : passwordRequirements.numberOfSymbols) {
        enumerationComponents.push(commonTranslations.symbol(passwordRequirements.numberOfSymbols));
    }
    if (passwordRequirements === null || passwordRequirements === void 0 ? void 0 : passwordRequirements.numberOfNumbers) {
        enumerationComponents.push(commonTranslations.numeral(passwordRequirements.numberOfNumbers));
    }
    return `${commonTranslations.mustHave()} 
  ${commonTranslations.charsLength(passwordRequirements.minLength)} 
  ${intl.t('password:requirements.andMustAtLeastContain', 'and must at least contain')} ${enumerationComponents.join(', ')}.`;
}
export function passwordFeedbackText(issues) {
    const passwordRequirements = authStore.organization.organizationSecuritySettings.passwordRequirements;
    const enumerationComponents = [];
    let text = intl.t('unknownError', 'Unknown Error');
    issues.forEach((issue) => {
        switch (issue) {
            case 'INVALID_CHARS':
                text = intl.t('password:feedback.invalidChars', 'Password contains invalid characters. Allowed are latin letters, numerals, white spaces and these symbols: ~!@#$%^*()_-+={}[]|:;",?');
                return;
            case 'INVALID_PASSWORD':
                text = intl.t('password:feedback.oldPasswordInvalid', 'The old password you entered is not correct.');
                return;
            case 'UNFULFILLED_PASSWORD_REQUIREMENTS':
                text = commonTranslations.unfulfilledPasswordRequirements();
                return;
            case 'PASSWORD_REUSED':
                text = commonTranslations.passwordReused();
                return;
            case 'LENGTH_REQUIREMENT':
                enumerationComponents.push(commonTranslations.charsLength(passwordRequirements.minLength));
                break;
            case 'LOWER_REQUIREMENT':
                enumerationComponents.push(commonTranslations.lower(passwordRequirements.numberOfLowercase));
                break;
            case 'CAPITAL_REQUIREMENT':
                enumerationComponents.push(commonTranslations.capital(passwordRequirements.numberOfCapitals));
                break;
            case 'NUMERAL_REQUIREMENT':
                enumerationComponents.push(commonTranslations.numeral(passwordRequirements.numberOfNumbers));
                break;
            case 'SYMBOL_REQUIREMENT':
                enumerationComponents.push(commonTranslations.symbol(passwordRequirements.numberOfSymbols));
                break;
            default:
                break;
        }
        text = `${commonTranslations.mustHave()} ${enumerationComponents.join(', ')}.`;
    });
    return text;
}
export function unauthorizedPasswordFeedbackText(issue) {
    switch (issue) {
        case 'UNFULFILLED_PASSWORD_REQUIREMENTS':
            return commonTranslations.unfulfilledPasswordRequirements();
        case 'PASSWORD_REUSED':
            return commonTranslations.passwordReused();
    }
    return intl.t('password:feedback.unknownError', 'The was an error validating your password. Try setting a different one.');
}
