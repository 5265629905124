var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable } from 'mobx';
import { BaseEntity } from 'lib/graphEditor/core';
import { isValuePublisher } from 'lib/graphEditor/valuePublisher';
import { CustomProperties } from '../../common/customProperties';
import { ContentBuilderLibraryComponent } from '../ContentBuilderLibraryComponent';
import { BaseAttributeModel } from './BaseAttributeModel';
export class BaseElementModel extends BaseEntity {
    constructor({ attributes }) {
        super();
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "attributes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.customProperties = new CustomProperties();
        this.attributes = new BaseAttributeModel(Object.assign(Object.assign({}, attributes), { name: {
                type: 'string',
                name: 'Name',
                defaultValue: '',
            } }));
    }
    get context() {
        var _a;
        if (this.parent instanceof ContentBuilderLibraryComponent) {
            return;
        }
        return (_a = this.parent) === null || _a === void 0 ? void 0 : _a.context;
    }
    get name() {
        return this.attributes.values.name;
    }
    setName(value) {
        this.attributes.setAttributeValue('name', value);
    }
    get valuePublisherItems() {
        const r = new Map();
        if (isValuePublisher(this)) {
            r.set(this.id, this);
        }
        return r;
    }
    get referenceableItems() {
        return new Map();
    }
    deserialize(value) {
        super.deserialize(value);
        this.customProperties.deserialize(value.customProperties);
        this.attributes.deserialize(value.attributes);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { customProperties: this.customProperties.getEncodableData(), attributes: this.attributes.getEncodableData() });
    }
    copy() {
        const i = super.copy();
        i.customProperties = this.customProperties.copy();
        i.attributes = this.attributes.copy();
        return i;
    }
}
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseElementModel.prototype, "name", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], BaseElementModel.prototype, "deserialize", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseElementModel.prototype, "copy", null);
