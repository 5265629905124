import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import React, { useContext } from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Typography } from 'lib/cortiUI';
import { FilterBuilderContext } from '../FilterBuilderContext';
import { Input } from '../components';
import { useFilterGroup } from './FilterGroupProvider';
import { PredicateValueTextRenderer } from './PredicateValueTextRenderer';
export function FilterGroupDropdown() {
    var _a;
    const theme = useTheme();
    const { value, resolvedAttribute, tempFilterValue, inputValue, inputRef, containerRef, openMenu, downshiftProps, } = useFilterGroup();
    const getInputProps = ((_a = downshiftProps.current) === null || _a === void 0 ? void 0 : _a.getInputProps) || (() => ({}));
    const { getComparisonText } = useContext(FilterBuilderContext);
    const areFilterCriteriaSelected = !!tempFilterValue.attributeID && !!tempFilterValue.comparison;
    const getFilterCriteriasTitle = () => {
        if (resolvedAttribute && value.comparison) {
            const comparison = getComparisonText ? getComparisonText(value.comparison) : value.comparison;
            return `${resolvedAttribute.text} ${comparison}`;
        }
        return intl.t('filterBuilder.unknown', 'Unknown');
    };
    const isValueRendererNeeded = !['isset', 'isnotset'].includes(value.comparison);
    return (React.createElement(Box, { onClick: openMenu, ref: containerRef, height: "100%" }, areFilterCriteriaSelected && resolvedAttribute ? (React.createElement(Box, { className: css({ height: '100%' }) },
        React.createElement(Base, { flex: "1 0 auto" },
            React.createElement(Typography, { variant: "caption", pr: 6, noWrap: true, noSelect: true }, getFilterCriteriasTitle())),
        React.createElement(Base, { flex: "1 0 auto" }, isValueRendererNeeded && (React.createElement(PredicateValueTextRenderer, { type: resolvedAttribute.valueType, options: resolvedAttribute.options || [], values: value.value, comparison: value.comparison }))))) : (React.createElement(Box, { "data-cy": "filter-group-input", flexDirection: "row", alignItems: "center", justifyContent: "space-between", className: css({
            height: '100%',
            width: '100%',
            cursor: 'pointer',
        }) },
        React.createElement(Input, Object.assign({ placeholder: intl.t('filterBuilder.selectFilter', 'Select Filter...') }, getInputProps({ value: inputValue || '' }), { ref: inputRef })),
        React.createElement(Box, { px: 4, justifyContent: "center", alignContent: "center", style: { height: '100%' } },
            React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: 'small', sx: { color: theme === null || theme === void 0 ? void 0 : theme.palette.text.hint } }))))));
}
