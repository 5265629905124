import { FactValueTransformer } from 'lib/triageFlow';
export const flowIntegrationEventTransformer = {
    toRepo(event) {
        switch (event.event) {
            case 'grouped-flow-value-collector-blocks-updated': {
                return {
                    event: 'triage-flow-grouped-flow-value-collector-blocks-updated',
                    data: {
                        datetime: event.data.datetime,
                        collectors: event.data.collectors.map((it) => ({
                            id: it.blockPrototype.id,
                            name: it.blockPrototype.name,
                            displayValues: it.displayValues,
                            customProperties: it.blockPrototype.customProperties.map((cp) => ({
                                key: cp.key,
                                value: cp.value,
                            })),
                            customValues: it.customValues.map((cv) => ({
                                value: cv.value,
                            })),
                            collectedFactValues: it.collectedFactValues.map((cfv) => (Object.assign(Object.assign({}, cfv), { value: FactValueTransformer.toExternal(cfv.value) }))),
                            collectedBlockValues: {
                                blocks: it.collectedBlockValues.blockPrototypes.map((b) => ({
                                    id: b.id,
                                    label: b.label,
                                    customProperties: b.customProperties.map((cp) => ({
                                        key: cp.key,
                                        value: cp.value,
                                    })),
                                })),
                                values: it.collectedBlockValues.values.map((v) => {
                                    var _a;
                                    return ({
                                        blockID: v.blockPrototypeID,
                                        value: v.value,
                                        text: v.text,
                                        customProperties: (_a = v.customProperties) === null || _a === void 0 ? void 0 : _a.map((cp) => ({
                                            key: cp.key,
                                            value: cp.value,
                                        })),
                                    });
                                }),
                            },
                        })),
                    },
                };
            }
            default: {
                return;
            }
        }
    },
};
