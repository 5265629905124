import { trimEnd } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { useHistory, useLocation } from 'react-router';
import { generateUrlSegments } from './utils';
const BreadcrumbActionsContext = createContext(null);
export function useBreadcrumbActionsContext() {
    return useContext(BreadcrumbActionsContext);
}
const BreadcrumbsContext = createContext(null);
export function useBreadcrumbs() {
    const r = useContext(BreadcrumbsContext);
    if (r == null) {
        throw new Error('useBreadcrumbs must be used inside BreadcrumbContextProvider');
    }
    return r;
}
const EMPTY_BREADCRUMB_FALLBACK_VALUE = '...';
export function BreadcrumbContextProvider({ children }) {
    // Not using state here because it needs to be synchronously updated
    // as soon as Route component calls to register the breadcrumb
    const registeredRoutes = useRef({});
    const [forceUpdate, setForceUpdate] = useState(0);
    // Using state for breadcrumbs because they cannot be derived from the location
    // as soon as it changes because Route components need to have a chance to register first
    const [primary, setPrimary] = useState([]);
    // Secondary breadcrumbs are used for Triage view only
    const [secondary, setSecondary] = useState([]);
    const [externalId, setExternalId] = useState(undefined);
    const location = useLocation();
    const { push } = useHistory();
    const actions = useRef({
        registerRoute: (path, breadcrumb) => {
            registeredRoutes.current[trimEnd(path, '/')] = breadcrumb;
            setForceUpdate(Math.random());
        },
        unregisterRoute: (path) => {
            delete registeredRoutes.current[trimEnd(path, '/')];
        },
        setSecondaryInformation: (breadcrumbs, externalId) => {
            setSecondary(breadcrumbs);
            setExternalId(externalId);
        },
        clearSecondaryInformation: () => {
            setSecondary([]);
            setExternalId(undefined);
        },
    });
    useEffect(() => {
        setPrimary(() => {
            const crumbs = generateUrlSegments(location.pathname)
                .map((b) => {
                const getter = registeredRoutes.current[b.url];
                if (!getter)
                    return;
                return {
                    onClick: () => void push(b.url),
                    label: getter() || EMPTY_BREADCRUMB_FALLBACK_VALUE,
                };
            })
                .filter((b) => Boolean(b));
            if (crumbs.length > 0) {
                crumbs[crumbs.length - 1].active = true;
            }
            return crumbs;
        });
    }, [location, forceUpdate, push]);
    const context = useMemo(() => ({ primary, secondary, externalId }), [primary, secondary, externalId]);
    // Two separate context providers are used to optimize re-rendering of consumer components.
    // Actions context only uses mutable values so it only needs to be passed through as stable reference
    return (React.createElement(BreadcrumbActionsContext.Provider, { value: actions.current },
        React.createElement(BreadcrumbsContext.Provider, { value: context }, children)));
}
