import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, FallbackView, Loading, Typography } from 'lib/cortiUI';
import { NotificationsTable } from './NotificationsTable';
import { NotificationsViewState } from './NotificationsViewState';
export const NotificationsView = observer(() => {
    const notificationsViewState = React.useRef(new NotificationsViewState()).current;
    const theme = useTheme();
    React.useEffect(() => {
        void notificationsViewState.loadNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Base, { className: css({ display: 'grid', gridGap: theme.space[4], gridTemplateRows: 'auto 1fr' }), p: 7, "data-cy": "notifications-view" },
        React.createElement(Typography, { mb: 2, color: "default", variant: "h6" }, intl.t('home:notificationsModule.title', 'New Notifications')),
        notificationsViewState.notifications.length ? (React.createElement(Base, { className: css({ overflow: 'hidden' }), "data-cy": "notifications-table" },
            React.createElement(NotificationsTable, { notificationsViewState: notificationsViewState }))) : notificationsViewState.isLoading ? (React.createElement(Loading, null)) : (React.createElement(FallbackView, { "data-cy": "notifications-fallback", title: intl.t('home:notificationsModule.noItems', 'No Notifications') }))));
});
