import { isValidRegExp } from '@corti/strings';
import { uuid } from '@corti/uuid';
import { FilterBuilderUtils, } from 'browser/components/FilterBuilder';
function serializeValues(values, valueType, comparison) {
    const firstValue = values[0];
    // backend only supports "OR" between values for "multiselect" type,
    // thus we only take firstValue into account
    // but we still need to provide it as an Array to the backend
    if (!firstValue) {
        return;
    }
    switch (valueType) {
        case 'datetime':
            return comparison === 'between'
                ? {
                    type: 'datetime',
                    objects: [
                        firstValue.startDate.toISOString(),
                        firstValue.endDate.toISOString(),
                    ],
                }
                : {
                    type: 'datetime',
                    objects: [firstValue.toISOString()],
                };
        case 'age':
            if (!firstValue.value) {
                return;
            }
            return {
                type: 'age',
                objects: [firstValue.unit, String(firstValue.value)],
            };
        case 'multiselect': {
            return {
                type: 'multiselect',
                objects: values,
            };
        }
        default:
            return {
                objects: [firstValue.toString()],
                type: valueType,
            };
    }
}
/**
 * Given the current state of active filters from FilterBuilder component,
 * will transform it into what the backend expects to receive
 * `undefined` is returned when filter query has invalid form
 */
export function filterBuilderStatetoValidQuery(descriptor, value) {
    if (value.entities.length === 0 || !value.junction) {
        return;
    }
    const transformPredicate = (predicate) => {
        if (predicate.attributeID && predicate.comparison) {
            const valueType = FilterBuilderUtils.getAttributeValueType(descriptor, predicate.attributeID);
            if (!valueType) {
                return;
            }
            // These comparisons are able to be filtered without any value
            if (predicate.comparison === 'isset' || predicate.comparison === 'isnotset') {
                return {
                    attributeID: predicate.attributeID,
                    comparison: predicate.comparison,
                    value: {
                        objects: [],
                        type: valueType,
                    },
                };
            }
            if (predicate.value) {
                const serializedValues = serializeValues(predicate.value, valueType, predicate.comparison);
                if (!serializedValues) {
                    return;
                }
                if ((predicate.comparison === 'contains' || predicate.comparison === 'ncontains') &&
                    !isValidRegExp(predicate.value[0].toString())) {
                    return;
                }
                return {
                    attributeID: predicate.attributeID,
                    comparison: predicate.comparison,
                    value: serializedValues,
                };
            }
        }
    };
    const query = {
        junction: value.junction,
        entities: value.entities
            .map((entity) => {
            return {
                id: entity.id,
                junction: entity.junction,
                predicates: entity.predicates.map((p) => transformPredicate(p)).filter((p) => p != null),
            };
        })
            .filter((e) => e.predicates.length !== 0),
    };
    if (query.entities.length !== 0) {
        return query;
    }
}
/**
 * From server value to FilterBuilder supported values
 */
function parseValue(value, comparison) {
    switch (value.type) {
        case 'boolean': {
            return [JSON.parse(value.objects[0])];
        }
        case 'int': {
            return [JSON.parse(value.objects[0])];
        }
        case 'select': {
            return [value.objects[0]];
        }
        case 'string': {
            return [value.objects[0]];
        }
        case 'multiselect': {
            return value.objects;
        }
        case 'datetime': {
            if (comparison === 'between') {
                return [{ startDate: new Date(value.objects[0]), endDate: new Date(value.objects[1]) }];
            }
            return [new Date(value.objects[0])];
        }
        case 'age': {
            return [
                {
                    unit: value.objects[0],
                    value: parseInt(value.objects[1]),
                },
            ];
        }
        default: {
            return value.objects;
        }
    }
}
export function caseFilterToFilterBuilderState(value) {
    return {
        junction: value.junction,
        entities: value.entities.map((entity) => {
            return {
                id: entity.id,
                junction: entity.junction,
                predicates: entity.predicates.map((predicate) => {
                    return {
                        id: uuid(),
                        attributeID: predicate.attributeID,
                        comparison: predicate.comparison,
                        value: parseValue(predicate.value, predicate.comparison),
                    };
                }),
            };
        }),
    };
}
