import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { TheFinderView } from './TheFinderView';
import { initTranscriptsApiService } from './services';
const mod = {
    id: 'the-finder',
    title: () => intl.t('theFinder:appName', 'The Finder'),
    path: '/the-finder',
    icon: PersonSearchRoundedIcon,
    requiresUserPermission: 'app:the_finder',
    requiresOrgFeature: 'the-finder',
    viewComponent: () => React.createElement(TheFinderView, null),
    onInit: () => {
        initTranscriptsApiService({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
    },
    navGroupe: 1,
};
export default mod;
