var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from '../../MissionControlService';
import { formatArrayQueryParams } from '../../utils';
function createApiRequest(endpoint) {
    return async function (_a) {
        var { user_ids = [], status = [], review_status = [], review_compliance_status = [] } = _a, params = __rest(_a, ["user_ids", "status", "review_status", "review_compliance_status"]);
        const transformedParams = formatArrayQueryParams(['user_ids', user_ids], ['review_status', review_status], ['status', status], ['review_compliance_status', review_compliance_status]);
        return await api
            .get(endpoint, { params: Object.assign(Object.assign({}, params), transformedParams) })
            .then((response) => response.data);
    };
}
export const getAcknowledgementStatusBreakdown = createApiRequest('/aggregations/breakdown/interaction-status');
export const getReviewStatusBreakdown = createApiRequest('/aggregations/breakdown/review-status');
export const getReviewScorePercentage = createApiRequest('/aggregations/score/review-score-percentage');
export const getQuestionScorePercentage = createApiRequest('/aggregations/score/question-score-percentage');
export const getTimeBasedScoreBreakdown = createApiRequest('/aggregations/histogram/review-score-percentage');
export * from './types';
