import axios from 'axios';
import { MissionControlError } from './MissionControlError';
const REST_API_ENDPOINT = '/mission-control';
export function initRestClient(config) {
    const restClient = axios.create({
        baseURL: config.host + REST_API_ENDPOINT,
        headers: {
            Authorization: config.authToken,
        },
    });
    restClient.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        throw createError(error);
    });
    return restClient;
}
function createError(error) {
    if (error.response) {
        return MissionControlError.ServerInternal(error);
    }
    if (error.request) {
        return MissionControlError.Unavailable(error);
    }
    return MissionControlError.Internal(error);
}
