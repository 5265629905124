import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import React from 'react';
import { formatDistanceToNow } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ButtonBase, Collapsable, Typography } from 'lib/cortiUI';
function CommitItem(props) {
    const { commit } = props;
    return (React.createElement(Base, { ml: 3, display: "flex", flexDirection: "row", alignItems: "flex-start", "data-cy": "graph-version-commit-list-item" },
        React.createElement(Typography, { variant: "body2", color: "default", mr: 2 }, '\u2022'),
        React.createElement(Base, { display: "flex", flexDirection: "column", ml: 2 },
            React.createElement(Typography, { variant: "body2", color: "default" }, commit.message),
            React.createElement(Typography, { variant: "footnote" },
                React.createElement(Base, { display: "flex", alignItems: "center" },
                    React.createElement(Typography, { variant: "subtitle2", color: "default", mr: 1 }, commit.createdBy.name),
                    React.createElement(Typography, { color: "default", variant: "body2" },
                        formatDistanceToNow(new Date(commit.datetime), {
                            addSuffix: true,
                        }),
                        ' '))))));
}
export function CommitsList(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { commits, initialCollapsed } = props;
    return (React.createElement(Base, { my: 6, "data-cy": "graph-version-commit-list" },
        React.createElement(Collapsable, { initialCollapsed: initialCollapsed }, ({ getBodyProps, getHeaderProps, collapsed }) => (React.createElement(React.Fragment, null,
            React.createElement(ButtonBase, Object.assign({}, getHeaderProps(), { display: "flex", alignItems: "center", mb: 4, gap: 3 }),
                collapsed ? (React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "small", className: css({ marginBottom: 0.25, marginRight: -5, marginLeft: -5 }) })) : (React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small", className: css({ marginBottom: 0.25, marginRight: -5, marginLeft: -5 }) })),
                React.createElement(Typography, { color: "default", variant: "subtitle2" }, t('editorApp2:commits.title', 'Commits')),
                React.createElement(Typography, { variant: "caption", sx: { background: theme.palette.background.divider }, borderRadius: 100, px: 4, color: "default" }, commits.length)),
            React.createElement(Base, Object.assign({}, getBodyProps(), { display: "grid", gridGap: 4 }), commits.map((commit) => {
                return React.createElement(CommitItem, { key: commit.id, commit: commit });
            })))))));
}
