import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import ReactFlow, { Background, Controls, MiniMap, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow, } from 'reactflow';
import 'reactflow/dist/style.css';
import { useTheme } from '@corti/theme';
import { useContextMenu } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { DefaultEdgeWidget } from '../DefaultEdgeWidget';
import { LinkModel } from '../LinkModel';
import { pointsAreEqual } from '../geometry';
import { LinkPortalNodeWidget } from '../nodes/LinkPortalNode/LinkPortalNodeWidget';
import { TimelineEntryAlertNodeWidget } from '../nodes/TimelineEntryAlertNode/TimelineEntryAlertNodeWidget';
import { ViewNodeWidget } from '../nodes/ViewNode/ViewNodeWidget';
import { getEntitiesPathFromNode } from '../pathsUtils';
import { ContextMenu } from './ContextMenu';
import { NodeContextMenu } from './NodeContextMenu';
function enhanceNodeComponent(Component) {
    function WrappedNode(props) {
        return React.createElement(Component, { model: props.data.model });
    }
    return WrappedNode;
}
const nodeTypes = {
    viewnode: enhanceNodeComponent(ViewNodeWidget),
    linkPortalNode: enhanceNodeComponent(LinkPortalNodeWidget),
    timelineEntryAlertNode: enhanceNodeComponent(TimelineEntryAlertNodeWidget),
};
const edgeTypes = {
    default: DefaultEdgeWidget,
};
function convertNodeToReactFlowNode(node) {
    return {
        id: node.id,
        type: node.type,
        position: node.position,
        selected: node.isSelected,
        data: { model: node },
    };
}
function convertNodes(nodes) {
    return nodes.map(convertNodeToReactFlowNode);
}
function convertLinkToReactFlowEdge(link) {
    return {
        id: link.id,
        type: 'default',
        data: {
            model: link,
        },
        source: link.outPort.parent.id,
        target: link.inPort.parent.id,
        sourceHandle: link.outPort.id,
        targetHandle: link.inPort.id,
        selected: link.isSelected,
    };
}
function convertLinksToReactFlowEdges(links) {
    return links.map((l) => convertLinkToReactFlowEdge(l));
}
const ReactFlowWrapper = observer(function ReactFlowWrapper(props) {
    const { editor } = useGraphEditorCtx();
    const { openContextMenu } = useContextMenu();
    const reactFlow = useReactFlow();
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);
    const reactFlowWrapper = useRef(null);
    const [highlightType, setHighlightType] = React.useState('default');
    const [hoveredEntity, setHoveredEntity] = React.useState(undefined);
    const draggingState = useRef(undefined);
    const branch = editor.model.getBranchByID(props.branchID);
    const theme = useTheme();
    React.useEffect(function syncStateToReactflowState() {
        const d1 = autorun(() => {
            setNodes(convertNodes([...branch.nodes.values()]));
        });
        const d2 = autorun(() => {
            setEdges(convertLinksToReactFlowEdges(branch.links));
        });
        return () => {
            d1();
            d2();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function syncViewportStateWithReactflow() {
        if (!branch.viewport) {
            return;
        }
        reactFlow.setViewport(branch.viewport);
    }, [reactFlow, branch.viewport]);
    React.useEffect(() => {
        branch.$canvasElement = reactFlowWrapper.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactFlow]);
    React.useEffect(function setHighlightedEntities() {
        if (hoveredEntity) {
            const entityIDs = getHighilghtedEntityIDs(hoveredEntity, highlightType);
            editor.state.setHoveredEntityIDs(entityIDs);
        }
        else {
            editor.state.unsetHoveredEntities();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoveredEntity, highlightType]);
    React.useEffect(function registerHighlightModifierKeys() {
        const onKeyToggle = (e) => {
            if (e.altKey && e.shiftKey) {
                e.preventDefault();
                setHighlightType('full-path');
                return;
            }
            if (e.altKey) {
                e.preventDefault();
                setHighlightType('neighbour');
                return;
            }
            setHighlightType('default');
        };
        document.addEventListener('keydown', onKeyToggle);
        document.addEventListener('keyup', onKeyToggle);
        return () => {
            document.removeEventListener('keydown', onKeyToggle);
            document.removeEventListener('keyup', onKeyToggle);
        };
    }, [setHighlightType]);
    React.useEffect(function bindKBShortcuts() {
        const keyboardBinder = editor.keyboardBinder.createInstance();
        keyboardBinder.bind('shift+1', (e) => {
            e.preventDefault();
            const nodes = branch.selectedNodes.length !== 0 ? branch.selectedNodes : [...branch.nodes.values()];
            editor.dispatch({
                type: 'canvas.fitNodesIntoView',
                data: {
                    nodeIDs: nodes.map((n) => n.id),
                },
            });
        });
        keyboardBinder.bind('mod+a', (e) => {
            e.preventDefault();
            editor.dispatch({
                type: 'canvas.selectEntities',
                data: {
                    ids: branch.entities.map((it) => it.id),
                    type: 'replace',
                },
            });
        });
        keyboardBinder.bind(['del', 'backspace'], () => {
            editor.dispatch({
                type: 'canvas.deleteEntities',
                data: {
                    ids: editor.state.activeBranch.selectedEntities.map((it) => it.id),
                },
            });
        });
        keyboardBinder.bind('mod+z', () => {
            editor.undo();
        });
        keyboardBinder.bind('mod+shift+z', () => {
            editor.redo();
        });
        keyboardBinder.bind('esc', () => {
            editor.dispatch({ type: 'canvas.selectEntities', data: { ids: [], type: 'replace' } });
        });
        keyboardBinder.bind('mod+c', async () => {
            editor.dispatch({
                type: 'canvas.copyNodes',
                data: {
                    nodeIDs: editor.state.activeBranch.selectedNodes.map((it) => it.id),
                },
            });
        });
        keyboardBinder.bind('mod+v', async () => {
            editor.dispatch({ type: 'canvas.pasteNodes', data: {} });
        });
        return () => {
            editor.keyboardBinder.remove(keyboardBinder);
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlow]);
    function onConnect(connection) {
        editor.dispatch({
            type: 'canvas.connectNodes',
            data: {
                source: {
                    nodeID: connection.source,
                    portID: connection.sourceHandle,
                },
                target: {
                    nodeID: connection.target,
                },
            },
        });
    }
    const onNodesChange = (changes) => {
        const { selectionChanges, positionChanges, dimensionChanges } = groupChangesByChangeType(changes);
        handleEntitySelectionChanges(selectionChanges);
        if (positionChanges.length !== 0) {
            runInAction(() => {
                positionChanges.forEach((p) => {
                    const n = editor.model.getNodeByID(p.id);
                    if (n && p.position) {
                        n.setPosition(p.position.x, p.position.y);
                    }
                });
            });
        }
        dimensionChanges.forEach((change) => {
            switch (change.type) {
                case 'dimensions': {
                    const model = editor.model.getNodeByID(change.id);
                    if (change.dimensions) {
                        model.width = change.dimensions.width;
                        model.height = change.dimensions.height;
                    }
                    break;
                }
            }
        });
    };
    const onEdgesChange = (changes) => {
        handleEntitySelectionChanges(changes.filter((s) => s.type === 'select'));
    };
    function handleEntitySelectionChanges(changes) {
        if (changes.length === 0) {
            return;
        }
        if (highlightType !== 'default') {
            editor.dispatch({
                type: 'canvas.selectEntities',
                data: {
                    ids: [...editor.state.hoveredEntityIDs.values()],
                    type: 'replace',
                },
            });
        }
        else {
            let finalSelection = new Set(editor.state.selectedEntityIDs);
            changes.forEach((c) => {
                if (c.selected) {
                    finalSelection.add(c.id);
                }
                else {
                    finalSelection.delete(c.id);
                }
            });
            editor.dispatch({
                type: 'canvas.selectEntities',
                data: {
                    ids: [...finalSelection.values()],
                    type: 'replace',
                },
            });
        }
    }
    const onNodeDragStart = React.useCallback((_, node, nodes) => {
        draggingState.current = { node, nodes };
    }, []);
    const onNodeDragStop = React.useCallback((_e, _n, nodes) => {
        let state = draggingState.current;
        draggingState.current = undefined;
        if (!state) {
            return;
        }
        const newPositions = nodes
            .map((n, idx) => {
            return {
                id: n.id,
                position: n.position,
                initialPosition: state.nodes[idx].position,
            };
        })
            // ignore nodes that didn't move a pixel
            // Reactflow still emits changes for this non-moves for some reason
            .filter((n) => !pointsAreEqual(n.initialPosition, n.position));
        if (newPositions.length === 0) {
            return;
        }
        // even that the nodes are moved on every position change,
        // we need to still record the the end of the move action for undo/redo
        editor.dispatch({
            type: 'canvas.moveNodes',
            data: {
                nodes: newPositions,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onEdgeMouseEnter = React.useCallback((_, edge) => {
        setHoveredEntity(edge);
    }, []);
    const onEdgeMouseLeave = React.useCallback(() => {
        setHoveredEntity(undefined);
    }, []);
    const onNodeMouseEnter = React.useCallback((_, node) => {
        setHoveredEntity(node);
    }, []);
    const onNodeMouseLeave = React.useCallback(() => {
        setHoveredEntity(undefined);
    }, []);
    const onPaneContextMenu = (event) => {
        openContextMenu(event, React.createElement(ContextMenu, { editor: editor, point: htmlPointToCanvasPoint({ x: event.clientX, y: event.clientY }) }));
    };
    const onNodeContextMenu = (event, node) => {
        openContextMenu(event, React.createElement(NodeContextMenu, { editor: editor, node: node.data.model, point: htmlPointToCanvasPoint({ x: event.clientX, y: event.clientY }) }));
    };
    const onMouseMove = (e) => {
        if (e.shiftKey) {
            // moving the mouse when holding down the mouse key is the default browser's behaviour to select texts
            // this will prevent it when the intention is to create a selection rectangle on the canvas
            e.preventDefault();
        }
        branch.setPointerPosition(htmlPointToCanvasPoint({ x: e.clientX, y: e.clientY }));
    };
    const onMouseDown = React.useCallback((e) => {
        if (e.shiftKey) {
            // holding down the shift key and clicking is a browser's default behaviour to select texts
            // this will prevent text selection when the intention is to draw a selection rectangle
            e.preventDefault();
        }
    }, []);
    function htmlPointToCanvasPoint(point) {
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const p1 = {
            x: point.x - left,
            y: point.y - top,
        };
        return reactFlow.project({ x: p1.x, y: p1.y });
    }
    return (React.createElement("div", { id: "react-flow-wrapper", ref: reactFlowWrapper, style: { width: '100%', height: '100%' } },
        React.createElement(ReactFlow, { nodes: nodes, edges: edges, proOptions: { hideAttribution: true }, minZoom: 0.1, snapGrid: [25, 25], snapToGrid: true, elevateEdgesOnSelect: true, multiSelectionKeyCode: navigator.userAgent.includes('Mac') ? 'Meta' : 'Control', edgeUpdaterRadius: 20, onMoveEnd: (_, viewport) => {
                branch.setViewport(viewport);
            }, onNodesChange: onNodesChange, onEdgesChange: onEdgesChange, onConnect: onConnect, onEdgeMouseEnter: onEdgeMouseEnter, onEdgeMouseLeave: onEdgeMouseLeave, onNodeMouseEnter: onNodeMouseEnter, onNodeMouseLeave: onNodeMouseLeave, onNodeDragStop: onNodeDragStop, onNodeDragStart: onNodeDragStart, onNodeContextMenu: onNodeContextMenu, nodeTypes: nodeTypes, edgeTypes: edgeTypes, onlyRenderVisibleElements: true, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onPaneContextMenu: onPaneContextMenu },
            React.createElement(MiniMap, { ariaLabel: "Branch Mini-Map", pannable: true, zoomable: true, nodeColor: theme.palette.action.active, maskColor: theme.palette.action.disabledBackground, style: {
                    background: theme.palette.background.paper,
                    opacity: 0.6,
                } }),
            React.createElement(Controls, null),
            React.createElement(Background, { gap: 25 }))));
});
export function CanvasRenderer(props) {
    return (React.createElement(ReactFlowProvider, null,
        React.createElement(ReactFlowWrapper, Object.assign({}, props))));
}
function getHighilghtedEntityIDs(entity, type) {
    let model = entity.data.model;
    if (model instanceof LinkModel) {
        if (type === 'neighbour') {
            return [model.outPort.parent.id, model.id, model.inPort.parent.id];
        }
        if (type === 'full-path') {
            return [
                model.outPort.parent.id,
                model.id,
                ...getEntitiesPathFromNode(model.inPort.parent).map((it) => it.id),
            ];
        }
        return [entity.id];
    }
    if (type === 'full-path') {
        return getEntitiesPathFromNode(model).map((it) => it.id);
    }
    if (type === 'neighbour') {
        return getEntitiesPathFromNode(model, 1).map((it) => it.id);
    }
    return [entity.id];
}
function groupChangesByChangeType(changes) {
    // batching the changes by type is a performance optimisation
    // instead of handling every single change separatelly
    let selectionChanges = [];
    let positionChanges = [];
    let dimensionChanges = [];
    changes.forEach((change) => {
        if (change.type === 'position' && change.position !== undefined) {
            positionChanges.push(change);
        }
        if (change.type === 'select') {
            selectionChanges.push(change);
        }
        if (change.type === 'dimensions') {
            dimensionChanges.push(change);
        }
    });
    return { selectionChanges, positionChanges, dimensionChanges };
}
