var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { cloneDeep, merge } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { FlowStore } from 'lib/triageFlow';
import { Alerts } from './Alerts';
import { Comments } from './Comments';
import { ConnectedUsers } from './ConnectedUsers';
import { TakeoverRequests } from './TakeoverRequests';
import { DEFAULT_SETTINGS } from './settings';
/**
 * Triage session API / controller.
 * Stores data, settings and methods to control business logic of the session.
 */
export class TriageSessionController {
    constructor(input) {
        var _a;
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "takeoverRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "comments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alerts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphVersionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "connectedUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ownerUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "settings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.observer = new Observer();
        this.id = input.state.session.id;
        this.createdAt = new Date(input.state.session.createdAt);
        this.startedAt = new Date(input.state.session.startedAt);
        this.graphVersionID = input.state.session.graphVersionID;
        this.caseID = input.state.session.caseID;
        this.externalID = input.state.session.externalID;
        this.currentUser = input.state.currentUser;
        this.createdBy = input.state.session.createdBy;
        this.logger = input.logger;
        runInAction(() => {
            this.settings = merge(DEFAULT_SETTINGS, input.settings);
            this.ownerUser = input.state.session.ownerUser;
            this.status = {
                type: 'ok',
            };
        });
        // TODO: think how to extract and share the common bootstrap configuration.
        // Almost identical flow controller is used in the editor in the "graph preview"
        // both of these should be using the same settings for bootsrapping.
        this.flow = new FlowStore(input.graph, {
            mediaProvider: input.media,
            formatDate: this.settings.formatDate,
            isCopyButtonEnabled: this.settings.isFlowValueCollectorCopyEnabled,
            automaticProgressToNextStep: this.settings.isAutoProgressToNextStepEnabled,
            childWindowOptions: (_a = this.settings.flowSettings) === null || _a === void 0 ? void 0 : _a.childWindowOptions,
        });
        this.connectedUsers = new ConnectedUsers({
            logger: this.logger,
            state: {
                connectedUsers: [this.currentUser],
            },
        });
        this.alerts = new Alerts({
            session: this,
            state: {
                alerts: [],
                priorityAlertID: null,
            },
        });
        this.comments = new Comments({
            logger: this.logger,
            state: {
                comments: [],
                totalCommentsCount: 0,
            },
        });
        this.takeoverRequests = new TakeoverRequests({
            session: this,
            state: {
                takeoverRequests: [],
            },
        });
    }
    destroy() {
        this.flow.destroy();
        this.observer.unAll();
    }
    updateSettings(settings) {
        this.settings = merge(this.settings, settings);
    }
    setStatus(status) {
        this.status = status;
        this.observer.fireEvent('status-changed', this.status);
    }
    reset(state) {
        const prevState = this.serialize();
        if (state.session != null) {
            this.caseID = state.session.caseID;
            this.externalID = state.session.externalID;
            this.ownerUser = state.session.ownerUser;
        }
        this.comments.reset({
            comments: state.comments,
            totalCommentsCount: state.totalCommentsCount,
        });
        this.alerts.reset({
            alerts: state.alerts,
            priorityAlertID: state.priorityAlertID,
        });
        this.takeoverRequests.reset({
            takeoverRequests: state.takeoverRequests,
        });
        this.observer.fireEvent('state-reset', {
            currentState: this.serialize(),
            prevState,
        });
    }
    changeOwnerUser(userID) {
        var _a;
        const user = this.connectedUsers.getByID(userID);
        if (!user) {
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.warn('owner user not found');
            return;
        }
        this.ownerUser = user;
        this.takeoverRequests.reset({ takeoverRequests: [] });
        this.observer.fireEvent('owner-user-changed', user);
    }
    isCurrentUser(userID) {
        return this.currentUser.id === userID;
    }
    isOwnerUser(userID) {
        return this.ownerUser.id === userID;
    }
    get isCurrentUserOwner() {
        return this.currentUser.id === this.ownerUser.id;
    }
    get isOwnerUserConnected() {
        return Boolean(this.connectedUsers.getByID(this.ownerUser.id));
    }
    serializeSession() {
        return {
            id: this.id,
            createdAt: this.createdAt.toISOString(),
            startedAt: this.startedAt.toISOString(),
            graphVersionID: this.graphVersionID,
            caseID: this.caseID,
            externalID: this.externalID,
            createdBy: cloneDeep(this.createdBy),
            ownerUser: cloneDeep(this.ownerUser),
        };
    }
    serialize() {
        return Object.assign(Object.assign(Object.assign({ session: this.serializeSession() }, this.takeoverRequests.serialize()), this.alerts.serialize()), this.comments.serialize());
    }
    onStatusChange(cb) {
        return this.observer.on('status-changed', cb);
    }
    onOwnerUserChange(cb) {
        return this.observer.on('owner-user-changed', cb);
    }
    onStateReset(cb) {
        return this.observer.on('state-reset', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TriageSessionController.prototype, "ownerUser", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TriageSessionController.prototype, "settings", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TriageSessionController.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof DeepPartial !== "undefined" && DeepPartial) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TriageSessionController.prototype, "updateSettings", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TriageSessionController.prototype, "setStatus", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], TriageSessionController.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TriageSessionController.prototype, "changeOwnerUser", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TriageSessionController.prototype, "isCurrentUserOwner", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TriageSessionController.prototype, "isOwnerUserConnected", null);
