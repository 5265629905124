import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { MissionControlApp } from './MissionControlApp';
const mod = {
    id: 'missionControl',
    title: () => intl.t('missionControlApp:appName', 'Mission Control'),
    path: '/mission-control',
    icon: SpeedRoundedIcon,
    requiresOrgFeature: 'mission-control-assessment-dashboard',
    requiresUserPermission: 'mission_control_assessment_stats:read',
    viewComponent: () => React.createElement(MissionControlApp, null),
    navGroupe: 1,
};
export default mod;
