import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { Input } from 'lib/cortiUI/components/TextField';
export function LinkCreator(props) {
    const { onSave, onCancel } = props;
    const [textValue, setTextValue] = React.useState('');
    return (React.createElement(Card, { minWidth: 300 },
        React.createElement(Input, { placeholder: intl.t('richTextEditor.linkCreator.placeholder', 'Add new link...'), value: textValue, onChange: (e) => setTextValue(e.target.value), my: 4, fullWidth: true }),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
            React.createElement(Button, { onClick: onCancel, size: "small" }, intl.t('cancel', 'Cancel')),
            React.createElement(Button, { onClick: (e) => onSave === null || onSave === void 0 ? void 0 : onSave(textValue, e), size: "small", className: css({ marginLeft: 1 }) }, intl.t('save', 'Save')))));
}
