export var FormFieldVariant;
(function (FormFieldVariant) {
    FormFieldVariant[FormFieldVariant["radio"] = 0] = "radio";
    FormFieldVariant[FormFieldVariant["textfield"] = 1] = "textfield";
    FormFieldVariant[FormFieldVariant["checkbox"] = 2] = "checkbox";
    FormFieldVariant[FormFieldVariant["colorPicker"] = 3] = "colorPicker";
    FormFieldVariant[FormFieldVariant["autocompleteSingle"] = 4] = "autocompleteSingle";
    FormFieldVariant[FormFieldVariant["autocompleteMultiple"] = 5] = "autocompleteMultiple";
    FormFieldVariant[FormFieldVariant["toggle"] = 6] = "toggle";
})(FormFieldVariant || (FormFieldVariant = {}));
/**
 * Unique identifier of the field
 */
export var FieldsNames;
(function (FieldsNames) {
    FieldsNames["category"] = "category";
    FieldsNames["color"] = "color";
    FieldsNames["isCreatable"] = "isCreatable";
    FieldsNames["name"] = "name";
    FieldsNames["searchTerms"] = "searchTerms";
    FieldsNames["isObvious"] = "isObvious";
})(FieldsNames || (FieldsNames = {}));
