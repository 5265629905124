var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _SwWrapper_instances, _SwWrapper_installingUnsub, _SwWrapper_registerInstallingEvents;
import { Observer } from '@corti/observer';
import { wait } from '@corti/timers';
export function createSwWrapper(registration) {
    return new SwWrapper(registration);
}
export class SwWrapper {
    constructor(registration) {
        _SwWrapper_instances.add(this);
        Object.defineProperty(this, "registration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: registration
        });
        Object.defineProperty(this, "hasFoundUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        _SwWrapper_installingUnsub.set(this, void 0);
        Object.defineProperty(this, "obs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "internalState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: this.registration.installing
                ? 'installing'
                : this.registration.waiting
                    ? 'waiting'
                    : navigator.serviceWorker.controller
                        ? 'active'
                        : 'initial'
        });
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            this.obs.fireEvent('controllerchange');
        });
        // Fired any time the ServiceWorkerRegistration.installing property acquires a new service worker.
        registration.addEventListener('updatefound', () => {
            this.hasFoundUpdate = true;
            if (registration.installing == null) {
                throw new Error('js spec says it should not happen');
            }
            this.obs.fireEvent('statechange', { type: 'installing' });
            __classPrivateFieldGet(this, _SwWrapper_instances, "m", _SwWrapper_registerInstallingEvents).call(this, registration.installing);
        });
    }
    async checkForUpdate() {
        this.hasFoundUpdate = null;
        this.obs.fireEvent('statechange', { type: 'checking-for-update' });
        const updatePromise = this.registration.update();
        try {
            // Wait for the update operation to finish or reject after a timeout, whichever happens first
            await Promise.race([
                updatePromise,
                new Promise((_, reject) => setTimeout(() => reject('update check timed out'), 5000)),
            ]);
        }
        catch (err) {
            console.error(err);
            this.obs.fireEvent('statechange', { type: 'update-check-failed' });
            return;
        }
        // since `registration.update()` does not return a result of the update check (either update found or not)
        // we're waiting 1ms to make sure that the event callbacks are called which may set the `hasFoundUpdate` state
        // if not set, we assume that no update was found on the server
        await wait(1);
        if (this.hasFoundUpdate !== true) {
            this.obs.fireEvent('statechange', { type: 'noupdate' });
        }
    }
    currentState() {
        return this.internalState;
    }
    skipWaiting() {
        var _a;
        (_a = this.registration.waiting) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'SKIP_WAITING' });
    }
    onStateChange(cb) {
        return this.obs.on('statechange', cb);
    }
    onNewController(cb) {
        navigator.serviceWorker.addEventListener('controllerchange', cb);
        return () => {
            navigator.serviceWorker.removeEventListener('controllerchange', cb);
        };
    }
}
_SwWrapper_installingUnsub = new WeakMap(), _SwWrapper_instances = new WeakSet(), _SwWrapper_registerInstallingEvents = function _SwWrapper_registerInstallingEvents(installing) {
    var _a;
    (_a = __classPrivateFieldGet(this, _SwWrapper_installingUnsub, "f")) === null || _a === void 0 ? void 0 : _a.call(this);
    __classPrivateFieldSet(this, _SwWrapper_installingUnsub, undefined, "f");
    // wait until the new Service worker is actually installed (ready to take over)
    installing.addEventListener('statechange', () => {
        if (this.registration.waiting) {
            // if there's an existing controller (previous Service Worker), show the prompt
            if (navigator.serviceWorker.controller) {
                this.obs.fireEvent('statechange', { type: 'waiting' });
            }
            else {
                // this is the first time install of sw
                this.obs.fireEvent('statechange', { type: 'active' });
            }
        }
    });
};
