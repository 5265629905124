import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import { useDataContext } from '../../../activeView/context/dataContext';
import { ComplianceTrackersLoader } from './ComplianceTrackersLoader';
import { TrackersList } from './TrackersList';
import { TrackersPickerTrigger } from './TrackersPickerTrigger';
export const ComplianceTrackersPanel = () => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'trackersPanel' });
    const { performanceViewMetrics: { metricsBreakdown }, isPerformanceViewMetricsLoading, } = useDataContext();
    return (React.createElement(Base, { "data-cy": "performance-trackers-section" },
        React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "space-between", mb: 5, gap: 5 },
            React.createElement(Typography, { variant: "body1", color: "default" }, t('title', 'Trackers')),
            React.createElement(TrackersPickerTrigger, null)),
        isPerformanceViewMetricsLoading ? (React.createElement(ComplianceTrackersLoader, null)) : (React.createElement(TrackersList, { items: metricsBreakdown }))));
};
