import { gql } from '@apollo/client';
export const getGraphQuery = gql `
  query protocolGraphWithActiveGraphVersion($id: String!, $activeProtocolGraphVersionID: String!) {
    protocolGraph(id: $id) {
      id
      name
    }
    protocolGraphVersion(id: $activeProtocolGraphVersionID) {
      id
      graph {
        id
      }
    }
  }
`;
export const ensureGraph = gql `
  mutation ensureProtocolGraph($input: ProtocolGraphInput!) {
    ensureProtocolGraph(input: $input)
  }
`;
export const deleteGraphMutation = gql `
  mutation deleteProtocolGraph($id: String!) {
    deleteProtocolGraph(id: $id)
  }
`;
//#endregion
