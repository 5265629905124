var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { generateCustomId } from '@corti/uuid';
import { ContentBuilderLibraryComponent } from 'lib/graphEditor/contentBuilder';
import { BaseEntity } from '../core';
function getFallbackName() {
    return `#${generateCustomId(4)}`;
}
export class GraphEditorLibrary extends BaseEntity {
    constructor(model) {
        super(model);
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.items = new Map();
        });
    }
    addComponent(component) {
        component.setParent(this);
        this.items.set(component.id, component);
    }
    addElementComponent(element, name) {
        const component = new ContentBuilderLibraryComponent({
            element,
            name: name || getFallbackName(),
        });
        element.setParent(component);
        this.addComponent(component);
        return component;
    }
    removeComponent(component) {
        this.items.delete(component.id);
        component.dispose();
    }
    /**
     * Will only remove component from the library items collection
     * without properly disposing it and letting cleanup after removal
     */
    forceRemoveComponent(component) {
        this.items.delete(component.id);
    }
    getComponentByID(id) {
        return this.items.get(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], GraphEditorLibrary.prototype, "items", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], GraphEditorLibrary.prototype, "addComponent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], GraphEditorLibrary.prototype, "removeComponent", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof ContentBuilderLibraryComponent !== "undefined" && ContentBuilderLibraryComponent) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], GraphEditorLibrary.prototype, "forceRemoveComponent", null);
