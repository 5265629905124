import { isPlainObject } from 'lodash';
import { isDate } from '@corti/date';
export function isSimpleType(value) {
    return (typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        isDate(value));
}
export function isDateType(value) {
    return isDate(value);
}
export function isObjectType(value) {
    return isPlainObject(value) && value.hasOwnProperty('value') && isSimpleType(value.value);
}
