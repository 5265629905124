var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUITextField from '@mui/material/TextField';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
export const TextField = React.forwardRef((_a, ref) => {
    var _b, _c, _d;
    var { hideCaret } = _a, props = __rest(_a, ["hideCaret"]);
    const theme = useTheme();
    return (React.createElement(MUITextField, Object.assign({ ref: ref, size: "small" }, props, { hiddenLabel: false, variant: "filled", InputProps: Object.assign(Object.assign({ disableUnderline: true }, props.InputProps), { classes: Object.assign(Object.assign({}, (_b = props.InputProps) === null || _b === void 0 ? void 0 : _b.classes), { root: css({
                    /**
                     * we are using boxShadow instead of border to not change the height on the
                     * on the Autocomplete component. By using borders the Autocomplete add that
                     * and therefor it will have a higher height.
                     */
                    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.action.outlinedBorder}`,
                    overflow: 'hidden',
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: `${theme.palette.background.paper} !important`,
                    '&:hover': {
                        boxShadow: `inset 0px 0px 0px 1px ${props.disabled
                            ? theme.palette.action.disabled
                            : theme.palette.action.outlinedHoverBorder}`,
                    },
                }, hideCaret ? { caretColor: 'transparent' } : {}), focused: css({
                    color: theme.palette.primary.main,
                    boxShadow: `inset 0px 0px 0px 2px currentColor`,
                    '&:hover': {
                        boxShadow: `inset 0px 0px 0px 2px currentColor`,
                    },
                }), error: css({
                    color: `${theme.palette.error.main} !important`,
                    boxShadow: `inset 0px 0px 0px 1px currentColor`,
                    '&:hover': {
                        boxShadow: `inset 0px 0px 0px 1px currentColor`,
                    },
                }), disabled: css({
                    color: `${theme.palette.action.disabled} !important`,
                }), input: css({
                    color: theme.palette.text.primary,
                    '&::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                }), inputMultiline: css(getScrollerCss({ theme, scrollWidth: 3 }), (_d = (_c = props.InputProps) === null || _c === void 0 ? void 0 : _c.classes) === null || _d === void 0 ? void 0 : _d.multiline) }) }) })));
});
