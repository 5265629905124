var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button as MUIButton, } from '@mui/material';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Typography } from 'lib/cortiUI/components/Typography';
import { getCommonButtonStyles } from './ButtonBase';
export const Button = React.forwardRef(function Button(props, ref) {
    const { variant = 'contained', size = 'medium', children, className, color, sx, disableUppercasing, noWrap } = props, rest = __rest(props, ["variant", "size", "children", "className", "color", "sx", "disableUppercasing", "noWrap"]);
    const theme = useTheme();
    const getNormalizedChildren = () => {
        const normalize = (children, index) => {
            if (typeof children === 'string') {
                return (React.createElement(Typography, { variant: "button", key: index, uppercase: !disableUppercasing, noWrap: noWrap }, children === ' ' ? React.createElement("pre", { style: { margin: 0 } }, children) : children));
            }
            return children;
        };
        if (Array.isArray(children)) {
            return children.map((c, idx) => normalize(c, idx));
        }
        else {
            return normalize(children);
        }
    };
    return (React.createElement(MUIButton, Object.assign({ disableRipple: true, ref: ref, variant: variant, sx: Object.assign(Object.assign({ textTransform: 'none' }, getCommonButtonStyles({ variant, color, disabled: props.disabled, theme })), sx), classes: {
            root: css(className),
        }, size: size }, rest), getNormalizedChildren()));
});
