import { produce } from 'immer';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Button, Separator } from 'lib/cortiUI';
import { FilterBuilderContext } from './FilterBuilderContext';
import { FilterRow } from './FilterRow';
import { JunctionController } from './components/JunctionController';
export function FilterBuilder(props) {
    const { onChange, value, descriptor, getComparisonText } = props;
    const theme = useTheme();
    const onAddRow = () => {
        onChange(produce(value, (draft) => {
            draft.entities.push({
                id: '',
                junction: 'and',
                predicates: [],
            });
        }));
    };
    const renderAddRowButton = () => {
        const lastEntity = value.entities[value.entities.length - 1];
        const lastPredicate = lastEntity &&
            lastEntity.predicates &&
            lastEntity.predicates[lastEntity.predicates.length - 1];
        const isLastPredicateValidWithoutValue = (lastPredicate === null || lastPredicate === void 0 ? void 0 : lastPredicate.comparison) === 'isset' || (lastPredicate === null || lastPredicate === void 0 ? void 0 : lastPredicate.comparison) === 'isnotset';
        const isLastPredicateValueSet = lastPredicate &&
            ((lastPredicate.value && !!lastPredicate.value.length) || isLastPredicateValidWithoutValue);
        return (React.createElement(Button, { "data-cy": "filter-row-add-button", className: css({ marginTop: 6 }), variant: "outlined", color: "primary", onClick: onAddRow, disabled: !isLastPredicateValueSet }, intl.t('filterBuilder.addGroup', 'Add Filter Group')));
    };
    return (React.createElement(FilterBuilderContext.Provider, { value: {
            getComparisonText,
        } },
        React.createElement(Box, { width: '100%', alignItems: "flex-start", maxHeight: 240, className: css(getScrollerCss({ theme: theme })) },
            value.entities.map((entity, idx) => {
                return (React.createElement(Box, { key: idx, width: '100%' },
                    idx !== 0 && (React.createElement(Box, { my: 6, flexDirection: "row", alignItems: "center" },
                        React.createElement(JunctionController, { activeJunction: value.junction, onChange: (junction) => {
                                onChange(Object.assign(Object.assign({}, value), { junction }));
                            } }),
                        React.createElement(Separator, { ml: 6, horizontal: true, width: "100%" }))),
                    React.createElement(FilterRow, { "data-cy": `filter-row-${idx}`, removable: value.entities.length !== 1, onRemove: () => {
                            onChange(produce(value, (draft) => {
                                draft.entities.splice(idx, 1);
                            }));
                        }, entities: descriptor.entities, value: entity, onChange: (newValue) => {
                            onChange(produce(value, (draft) => {
                                draft.entities[idx] = newValue;
                            }));
                        } })));
            }),
            renderAddRowButton())));
}
