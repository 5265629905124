import { produce } from 'immer';
import { uniqBy } from 'lodash';
import React, { createContext, useContext, useMemo, useRef, useState, } from 'react';
import { uuid } from '@corti/uuid';
const FilterGroupContext = createContext(null);
export function useFilterGroup() {
    const ctx = useContext(FilterGroupContext);
    if (!ctx) {
        throw new Error('useFilterGroup must be used within a FilterGroupProvider');
    }
    return ctx;
}
export function FilterGroupProvider({ value, attributes, onChange, children, }) {
    const inputRef = useRef(null);
    const predicateInputRef = useRef(null);
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [tempFilterValue, setTempFilterValue] = useState(value);
    const isInitiallyComparison = !!value.attributeID && !!value.comparison && !value.value.length;
    const [menuView, setMenuView] = useState(isInitiallyComparison ? 'comparison' : 'attribute');
    const [isInsidePopupOpen, setIsInsidePopupOpen] = useState(isInitiallyComparison);
    const downshiftProps = useRef(null);
    const areFilterCriteriaSelected = !!tempFilterValue.attributeID && !!tempFilterValue.comparison;
    const uniqueAttributes = useMemo(() => uniqBy(attributes, ({ id }) => id), [attributes]);
    const resolvedAttribute = useMemo(() => tempFilterValue.attributeID
        ? attributes.find((a) => a.id === tempFilterValue.attributeID)
        : undefined, [attributes, tempFilterValue.attributeID]);
    const closeMenu = () => {
        var _a;
        if (!isInsidePopupOpen) {
            setIsOpen(false);
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            if (!tempFilterValue.attributeID || !tempFilterValue.comparison) {
                setTempFilterValue(value);
            }
        }
    };
    const openMenu = () => {
        var _a;
        setIsOpen(true);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (areFilterCriteriaSelected) {
            setMenuView('comparison');
        }
    };
    const onAttributeChange = (attribute) => {
        setMenuView('comparison');
        setInputValue('');
        setTempFilterValue({
            id: uuid(),
            attributeID: attribute.id,
            comparison: attribute.comparisons[0],
            value: [],
        });
        onChange(produce(value, (draft) => {
            draft.attributeID = attribute.id;
            draft.comparison = attribute.comparisons[0];
            draft.value = [];
        }));
    };
    const onComparisonChange = (comparison) => {
        var _a;
        setInputValue('');
        setTempFilterValue((oldFilterValue) => (Object.assign(Object.assign({}, oldFilterValue), { comparison, value: [] })));
        onChange(produce(value, (draft) => {
            draft.attributeID = tempFilterValue.attributeID;
            draft.comparison = comparison;
            draft.value = [];
        }));
        (_a = predicateInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const onPredicateValueChange = (newValues) => {
        setTempFilterValue((oldFilterValue) => (Object.assign(Object.assign({}, oldFilterValue), { value: newValues })));
        onChange(produce(value, (draft) => {
            draft.value = newValues;
        }));
    };
    const onReturnToAttributeMenu = () => {
        var _a;
        setMenuView('attribute');
        setInputValue('');
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const ctx = {
        inputRef,
        predicateInputRef,
        containerRef,
        value,
        isOpen,
        setIsOpen,
        inputValue,
        setInputValue,
        tempFilterValue,
        setTempFilterValue,
        menuView,
        setMenuView,
        isInsidePopupOpen,
        setIsInsidePopupOpen,
        downshiftProps,
        uniqueAttributes,
        resolvedAttribute,
        closeMenu,
        openMenu,
        onAttributeChange,
        onComparisonChange,
        onPredicateValueChange,
        onReturnToAttributeMenu,
    };
    return React.createElement(FilterGroupContext.Provider, { value: ctx }, children);
}
