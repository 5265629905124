import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
const TOOLBAR_HEIGHT = 64;
export function Toolbar(props) {
    const theme = useTheme();
    return (React.createElement(Base, { className: css({
            position: 'relative',
            padding: `0px ${theme.space[7]}px`,
            borderBottom: `1px solid ${theme.palette.background.divider}`,
            height: `${TOOLBAR_HEIGHT}px`,
        }) },
        React.createElement(Base, { className: css({
                display: 'grid',
                gridTemplateAreas: '"content toolbar-buttons"',
                gridTemplateColumns: '1fr auto',
                alignItems: 'center',
                height: '100%',
            }) }, props.children)));
}
