import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export default function MetadataLoader() {
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "1fr auto" },
        React.createElement(Base, { display: "grid", gridTemplateRows: "auto 1fr", gap: 5 },
            React.createElement(Skeleton, { variant: "text" }),
            React.createElement(Base, { display: "grid", gridTemplateRows: "auto auto auto 1fr", gap: 3 },
                React.createElement(Skeleton, { variant: "rounded", height: 45 }),
                React.createElement(Skeleton, { variant: "rounded", height: 45 }),
                React.createElement(Skeleton, { variant: "rounded", height: 45 }),
                React.createElement(Skeleton, { variant: "rounded", height: 45 }))),
        React.createElement(Base, { display: "grid", gap: 3 },
            React.createElement(Skeleton, { variant: "rounded" }),
            React.createElement(Skeleton, { variant: "rounded" }))));
}
