import React from 'react';
import { css } from '@corti/style';
import { Dialog } from 'lib/cortiUI';
import { useUIState } from '../UIStateProvider';
import { PinnedStep } from './PinnedStep';
export function PinnedStepDialog() {
    const uiState = useUIState();
    return (React.createElement(Dialog, { size: "medium", transitionDuration: 0, open: !!uiState.activePinnedStepID, onClose: () => uiState.setActivePinnedStepID(null), hideCloseButton: true, titleProps: {
            className: css({
                padding: 0,
            }),
        }, contentProps: {
            className: css({
                padding: 0,
            }),
        } },
        React.createElement(PinnedStep, { stepID: uiState.activePinnedStepID })));
}
