import React from 'react';
import { Base, Card, Skeleton } from 'lib/cortiUI';
export const InteractionStatusLoader = () => {
    return (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 3 },
        React.createElement(Skeleton, { variant: "rounded", height: 30, width: "30%" }),
        React.createElement(Skeleton, { variant: "text", height: 40 }),
        React.createElement(Base, { display: "flex", flexDirection: "row", gap: 6, justifyContent: "space-between" },
            React.createElement(Skeleton, { variant: "rounded", height: 35, width: "45%" }),
            React.createElement(Skeleton, { variant: "rounded", height: 35, width: "45%" }))));
};
