import React from 'react';
const SavingContext = React.createContext({
    isSaving: false,
    setIsSaving: () => { },
});
export const SavingProvider = ({ children }) => {
    const [isSavingState, setIsSavingState] = React.useState(0);
    function setIsSaving(param) {
        setIsSavingState((prev) => {
            const newVal = param ? prev + 1 : prev - 1;
            return newVal <= 0 ? 0 : newVal;
        });
    }
    const value = React.useMemo(() => ({
        isSaving: Boolean(isSavingState),
        setIsSaving,
    }), [isSavingState]);
    return React.createElement(SavingContext.Provider, { value: value }, children);
};
export const useSaving = () => {
    const context = React.useContext(SavingContext);
    if (!context) {
        throw new Error('useSaving must be used within a SavingProvider');
    }
    return context;
};
