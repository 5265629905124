import { useTranslation } from '@corti/i18n';
import { ExpressionEvaluator, ExpressionUtils } from '@corti/lib/graphs';
import { queueMacrotask } from '@corti/timers';
import { LinkPortalNodeModel, TimelineEntryAlertNode, ViewNodeModel, } from 'lib/graphEditor/canvas/nodes';
import { DocumentElementModel, ImageElementModel, SelectElementModel, } from '../contentBuilder/elements';
import { BaseFormElementModel } from '../contentBuilder/elements/BaseFormElementModel';
import { isValuePublisher } from '../valuePublisher';
export function getIssueDescriptor(issueType) {
    switch (issueType) {
        case 'graph.no-nodes':
        case 'graph.no-start-node':
        case 'branch.no-start-node':
        case 'logicgate.invalid-expression':
        case 'node.timeline-entry-alert-node-source-already-in-use':
        case 'node.timeline-entry-alert-node-source-not-set':
        case 'block.custom-property-empty-key':
        case 'checklist.duplicate-key':
        case 'checklist.invalid-visibility-expression':
        case 'checklist.no-name':
        case 'checklist.no-entries':
        case 'checklist-entry.invalid-automatic-expression':
        case 'checklist-entry.empty-automatic-expression':
        case 'checklist-entry.invalid-completion-settings': {
            return { severity: 'error' };
        }
        default: {
            return { severity: 'warning' };
        }
    }
}
const createResult = () => {
    const result = {
        issues: [],
        getIssuesBySeverity(severity) {
            return this.issues.filter((it) => {
                const descriptor = getIssueDescriptor(it.type);
                return descriptor.severity === severity;
            });
        },
    };
    return result;
};
function mergeResults(...sources) {
    const receiver = createResult();
    receiver.issues = sources.flatMap((s) => s.issues);
    return receiver;
}
export async function validateGraph(model) {
    const context = { graph: model, __linkPortalInputNodes: new Map() };
    [...model.nodes.values()]
        .filter((n) => n instanceof LinkPortalNodeModel)
        .forEach((l) => {
        var _a;
        if ((_a = l.targetNode) === null || _a === void 0 ? void 0 : _a.id) {
            context.__linkPortalInputNodes.set(l.targetNode.id, true);
        }
    });
    const result = createResult();
    if (!model.startNode) {
        result.issues.push({ type: 'graph.no-start-node' });
    }
    if (model.nodes.size === 0) {
        result.issues.push({ type: 'graph.no-nodes' });
    }
    const branchResults = [];
    for (const branch of model.branches) {
        await queueMacrotask();
        const result = validateBranch(branch, context);
        branchResults.push(result);
    }
    const checklistResults = [];
    const checklistContext = { checklists: model.checklists.items };
    for (const checklist of model.checklists.items) {
        await queueMacrotask();
        const result = validateChecklist(checklist, checklistContext);
        checklistResults.push(result);
    }
    return mergeResults(result, ...branchResults, ...checklistResults);
}
function validateBranch(branch, validationCtx = {}) {
    validationCtx.branch = branch;
    const result = createResult();
    if (!branch.startNode) {
        result.issues.push({ type: 'branch.no-start-node', target: { type: 'branch', id: branch.id } });
    }
    if (branch.nodes.size === 0) {
        result.issues.push({ type: 'branch.no-nodes', target: { type: 'branch', id: branch.id } });
    }
    const nodeResults = [];
    for (const node of branch.nodes.values()) {
        nodeResults.push(validateNode(node, validationCtx));
    }
    return mergeResults(result, ...nodeResults);
}
function validateNode(node, validationCtx = {}) {
    var _a;
    validationCtx.node = node;
    let result = createResult();
    for (const validator of nodeValidators) {
        result.issues = [...result.issues, ...((_a = validator(node, validationCtx)) !== null && _a !== void 0 ? _a : [])];
    }
    return result;
}
const nodeValidators = [
    validateNodeInputs,
    validateTimelineEntryAlertNode,
    validateViewNodeContent,
    validateViewNodeLogicGates,
];
function validateNodeInputs(node, validationCtx = {}) {
    var _a;
    const issues = [];
    if (node.portIn) {
        if (node.portIn.links.length === 0 &&
            !((_a = validationCtx.__linkPortalInputNodes) === null || _a === void 0 ? void 0 : _a.get(node.id)) &&
            node.graphEditorModelContext.startNode !== node &&
            node.branchContext.startNode !== node) {
            if (node instanceof ViewNodeModel && node.pinnedInTriage) {
                // legit use case
            }
            else {
                issues.push({ type: 'node.no-inputs', target: { id: node.id, type: 'node' } });
            }
        }
    }
    return issues;
}
function validateViewNodeContent(node, validationCtx = {}) {
    if (!(node instanceof ViewNodeModel)) {
        return;
    }
    const issues = [];
    if (Object.keys(node.contentBuilderContext.elements).length === 0) {
        issues.push({ type: 'node.viewnode.no-content', target: { id: node.id, type: 'node' } });
    }
    for (const baseInstance of node.contentBuilderContext.elements) {
        const i = validateElInstance(baseInstance, validationCtx);
        i.forEach((it) => {
            issues.push({ type: it.type, target: { id: node.id, type: 'node' } });
        });
    }
    return issues;
}
function validateTimelineEntryAlertNode(node, validationCtx = {}) {
    var _a, _b;
    if (!(node instanceof TimelineEntryAlertNode)) {
        return;
    }
    const issues = [];
    // count sourcenode id references
    validationCtx.teanodecache = (_a = validationCtx.teanodecache) !== null && _a !== void 0 ? _a : {};
    const cache = validationCtx.teanodecache;
    if (node.sourceID) {
        cache[node.sourceID] = (_b = cache[node.sourceID]) !== null && _b !== void 0 ? _b : 0;
        cache[node.sourceID]++;
    }
    if (!node.sourceID) {
        issues.push({
            type: 'node.timeline-entry-alert-node-source-not-set',
            target: { type: 'node', id: node.id },
        });
    }
    else if (cache[node.sourceID] > 1) {
        issues.push({
            type: 'node.timeline-entry-alert-node-source-already-in-use',
            target: { type: 'node', id: node.id },
        });
    }
    return issues;
}
function validateViewNodeLogicGates(node, validationCtx = {}) {
    if (!(node instanceof ViewNodeModel)) {
        return;
    }
    let issues = [];
    for (const g of node.logicGates) {
        issues = [...issues, ...validateLogicGate(g, validationCtx)];
    }
    return issues;
}
function validateLogicGate(gate, validationCtx = {}) {
    const issues = [];
    if (!gate.finalTargetNode) {
        issues.push({
            type: 'logicgate.no-gate-target-node',
            target: { type: 'node', id: gate.parent.id },
        });
    }
    if (ExpressionUtils.isEmpty(gate.expression)) {
        issues.push({
            type: 'logicgate.empty-expression',
            target: { id: gate.parent.id, type: 'node' },
        });
    }
    const validationResult = ExpressionEvaluator.validate(gate.expression);
    if (validationResult.type === 'error') {
        issues.push({
            type: 'logicgate.invalid-expression',
            target: { id: gate.parent.id, type: 'node' },
        });
    }
    if (validationCtx.graph) {
        const variables = gate.expressionVariables;
        for (const v of variables) {
            const ref = validationCtx.graph.ctx.logicContext.getRefItemByID(v);
            if (!ref) {
                issues.push({ type: 'unresolved-ref', target: { id: gate.parent.id, type: 'node' } });
            }
        }
    }
    return issues;
}
function validateElInstance(it, validationCtx = {}) {
    return validateElementProto(it.wrappedElement, validationCtx);
}
function validateElementProto(el, validationCtx = {}) {
    let issues = [];
    issues = [...issues, ...validateCustomAttributes(el, validationCtx)];
    if (el instanceof BaseFormElementModel) {
        issues = [...issues, ...validateRichTextExpression(el, validationCtx)];
    }
    if (isValuePublisher(el)) {
        issues = [...issues, ...validateValuePublisher(el.valuePublisher, validationCtx)];
        if (el instanceof SelectElementModel) {
            el.options.forEach((o) => {
                issues = [...issues, ...validateValuePublisher(o.valuePublisher, validationCtx)];
            });
        }
    }
    if (el instanceof ImageElementModel) {
        issues = [...issues, ...validateImageElement(el, validationCtx)];
    }
    if (el instanceof DocumentElementModel) {
        issues = [...issues, ...validateDocumentElement(el, validationCtx)];
    }
    return issues;
}
function validateCustomAttributes(el, validationCtx = {}) {
    let issues = [];
    el.customProperties.items.forEach((prop) => {
        if (prop.hasEmptyKey() && !prop.hasEmptyValue()) {
            const target = validationCtx.node
                ? { target: { type: 'node', id: validationCtx.node.id } }
                : undefined;
            issues.push(Object.assign({ type: 'block.custom-property-empty-key' }, target));
        }
    });
    return issues;
}
function validateImageElement(el, _validationCtx = {}) {
    if (el.mediaAssetID && !el.mediaAsset) {
        return [{ type: 'unresolved-ref' }];
    }
    return [];
}
function validateDocumentElement(el, _validationCtx = {}) {
    if (el.mediaAssetID && !el.mediaAsset) {
        return [{ type: 'unresolved-ref' }];
    }
    return [];
}
function validateValuePublisher(valuePublisher, validationCtx = {}) {
    let issues = [];
    if (!validationCtx.graph) {
        return [];
    }
    valuePublisher.collectors.forEach((c) => {
        if (!validationCtx.graph.elementContext.getPrototypeByID(c.prototypeID)) {
            issues.push({
                type: 'value-publisher-config-unresolved-ref',
                target: validationCtx.node ? { type: 'node', id: validationCtx.node.id } : undefined,
            });
        }
    });
    return issues;
}
function validateRichTextExpression(el, validationCtx = {}) {
    let issues = [];
    const graph = validationCtx.graph;
    if (!graph) {
        return [];
    }
    el.expressionVariables.forEach((v) => {
        const ref = graph.ctx.logicContext.getRefItemByID(v);
        if (!ref) {
            issues.push({
                type: 'unresolved-ref',
                target: validationCtx.node ? { type: 'node', id: validationCtx.node.id } : undefined,
            });
        }
    });
    return issues;
}
export function validateChecklist(checklist, context) {
    const result = createResult();
    const { id, name, key, visibilityConditionEnabled, visibilityConditionExpression, entries } = checklist;
    if (!name) {
        result.issues.push({ type: 'checklist.no-name', target: { type: 'checklist', id } });
    }
    const otherKeys = context.checklists.filter((c) => c.id !== id).map((c) => c.key);
    if (otherKeys.includes(key)) {
        result.issues.push({ type: 'checklist.duplicate-key', target: { type: 'checklist', id } });
    }
    if (entries.length === 0) {
        result.issues.push({ type: 'checklist.no-entries', target: { type: 'checklist', id } });
    }
    if (visibilityConditionEnabled) {
        if (!visibilityConditionExpression) {
            result.issues.push({
                type: 'checklist.empty-visibility-expression',
                target: { type: 'checklist', id },
            });
        }
        else {
            const validationResult = ExpressionEvaluator.validate(visibilityConditionExpression);
            if (validationResult.type === 'error') {
                result.issues.push({
                    type: 'checklist.invalid-visibility-expression',
                    target: { type: 'checklist', id },
                });
            }
        }
    }
    const unlabelledEntries = entries.filter((e) => !e.label);
    if (unlabelledEntries.length > 0) {
        result.issues.push({ type: 'checklist-entry.unlabelled', target: { type: 'checklist', id } });
    }
    const entryKeys = entries.map((e) => e.key);
    const duplicateEntryKeys = entryKeys.filter((k, i) => k && entryKeys.indexOf(k) !== i);
    if (duplicateEntryKeys.length > 0) {
        result.issues.push({
            type: 'checklist-entry.duplicate-key',
            target: { type: 'checklist', id },
        });
    }
    const hasEmptyAutoExpression = checklist.entries.some(({ automaticCompletionEnabled, automaticCompletionExpression }) => automaticCompletionEnabled && !automaticCompletionExpression);
    if (hasEmptyAutoExpression) {
        result.issues.push({
            type: 'checklist-entry.empty-automatic-expression',
            target: { type: 'checklist', id },
        });
    }
    const hasInvalidAutoExpression = checklist.entries.some(({ automaticCompletionEnabled, automaticCompletionExpression }) => automaticCompletionEnabled &&
        automaticCompletionExpression &&
        ExpressionEvaluator.validate(automaticCompletionExpression).type === 'error');
    if (hasInvalidAutoExpression) {
        result.issues.push({
            type: 'checklist-entry.invalid-automatic-expression',
            target: { type: 'checklist', id },
        });
    }
    const hasNoCompletionSetting = checklist.entries.some(({ automaticCompletionEnabled, manualCompletionEnabled }) => !automaticCompletionEnabled && !manualCompletionEnabled);
    if (hasNoCompletionSetting) {
        result.issues.push({
            type: 'checklist-entry.invalid-completion-settings',
            target: { type: 'checklist', id },
        });
    }
    return result;
}
export function useGetIssueMessage() {
    const { t } = useTranslation();
    return (issue) => {
        switch (issue) {
            case 'logicgate.empty-expression': {
                return t('validation.emptyExpression', 'Expression is empty');
            }
            case 'branch.no-start-node': {
                return t('validation.noBranchStartNode', 'Branch does not have a start node');
            }
            case 'graph.no-start-node': {
                return t('validation.noGraphStartNode', 'Graph does not have a start node');
            }
            case 'logicgate.no-gate-target-node': {
                return t('validation.noGateTargetNode', 'Logic gate has no end target');
            }
            case 'logicgate.invalid-expression': {
                return t('validation.invalidExpression', 'Invalid expression');
            }
            case 'node.no-inputs': {
                return t('validation.noHasNoInputs', 'Node has no inputs');
            }
            case 'branch.no-nodes': {
                return t('validation.emptyBranch', 'Branch is empty');
            }
            case 'graph.no-nodes': {
                return t('validation.emptyGraph', 'Graph is empty');
            }
            case 'node.viewnode.no-content': {
                return t('validation.emptyViewnode', 'View node has no content');
            }
            case 'unresolved-ref': {
                return t('validation.unresolvedRef', 'Missing reference');
            }
            case 'node.timeline-entry-alert-node-source-already-in-use': {
                return t('validation.timelineEntryAlertNodeSourceAlreadyInUse', 'Detection trigger node - same source is set in multiple nodes');
            }
            case 'node.timeline-entry-alert-node-source-not-set': {
                return t('validation.timelineEntryAlertNodeSourceNotSet', 'Detection trigger node - source is not set');
            }
            case 'block.custom-property-empty-key': {
                return t('validation.customPropertyEmptyKey', 'No empty key allowed for custom property');
            }
            case 'checklist.duplicate-key':
                return t('validation.duplicateKey', 'Duplicate checklist key');
            case 'checklist.no-name':
                return t('validation.noName', 'Checklist has no name');
            case 'checklist.no-entries':
                return t('validation.noEntries', 'Checklist has no entries');
            case 'checklist.empty-visibility-expression':
                return t('validation.emptyVisibilityExpression', 'Checklist visibility expression is empty');
            case 'checklist.invalid-visibility-expression':
                return t('validation.invalidVisibilityExpression', 'Invalid checklist visibility expression');
            case 'checklist-entry.duplicate-key':
                return t('validation.duplicateEntryKey', 'Checklist has entries with duplicate keys');
            case 'checklist-entry.unlabelled':
                return t('validation.unlabelledEntries', 'Checklist has unlabelled entries');
            case 'checklist-entry.empty-automatic-expression':
                return t('validation.emptyAutomaticExpression', 'Checklist has entry with empty automatic completion expression');
            case 'checklist-entry.invalid-automatic-expression':
                return t('validation.invalidAutomaticExpression', 'Checklist has entry with invalid automatic completion expression');
            case 'checklist-entry.invalid-completion-settings':
                return t('validation.invalidCompletionSettings', 'Checklist has entry with invalid completion settings');
            default:
                return issue;
        }
    };
}
