import React, { useEffect, useState } from 'react';
import { BrowserRouter, useRouteMatch } from 'react-router-dom';
import { formatDate } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { TimelineEntryDefinitionSourceType } from '@corti/lib/coreApiService';
import { useHistoryBlock } from '@corti/react';
import { useAuth } from 'core/auth/browser';
import { config } from 'core/configuration/browser';
import { repositories } from 'core/repositories';
import { Button, Dialog } from 'lib/cortiUI';
import { GraphEditor, GraphEditorWidget } from 'lib/graphEditor';
import { GraphToolbox } from './GraphToolbox';
import { MediaModuleProvider } from './mediaModuleProvider';
export function GraphEditorView({ graphVersion, graphModel, isReleased }) {
    const { i18n, t } = useTranslation();
    const routematch = useRouteMatch();
    const authStore = useAuth();
    const [editor] = useState(() => {
        const appConf = config.getConfig();
        const mediaModule = new MediaModuleProvider();
        const editor = new GraphEditor({
            mediaProvider: mediaModule,
            graphPreviewSettings: {
                triageSessionSettings: {
                    formatDate: formatDate,
                    isFlowValueCollectorCopyEnabled: !!authStore.organization.config.triage.isCopyButtonEnabled,
                    isAutoProgressToNextStepEnabled: !!authStore.organization.triageSettings.automaticProgressToNextStep,
                    flowSettings: {
                        childWindowOptions: {
                            defaultHeight: appConf['childWindowOptions:defaultHeight'],
                            defaultWidth: appConf['childWindowOptions:defaultWidth'],
                            defaultX: appConf['childWindowOptions:defaultX'],
                            defaultY: appConf['childWindowOptions:defaultY'],
                        },
                    },
                    isAlertsEnabled: false,
                    isCommentsEnabled: false,
                    isTakeoverEnabled: false,
                    isSearchEnabled: true,
                    isManualActionSelectEnabled: true,
                    isChecklistViewEnabled: authStore.isFeatureEnabled('smart-checklists'),
                },
            },
            checklistSettings: {
                enabled: authStore.isFeatureEnabled('smart-checklists'),
            },
        });
        editor.setModel(graphModel);
        return editor;
    });
    useEffect(function initEditorModules() {
        repositories.timelineEntryDefinitions.loadDefinitions().then(() => {
            editor.detections.setDetectionDefinitions(repositories.timelineEntryDefinitions.definitions.filter((d) => d.source.type === TimelineEntryDefinitionSourceType.callAnnotation));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { isBlocked, proceed, cancel } = useHistoryBlock((nextLocation) => {
        if (nextLocation.pathname.includes(routematch.url)) {
            return false;
        }
        return editor.changelog.changes.length !== 0;
    }, [routematch]);
    // this is a desktop only solution and will not work perfectly on mobile
    // as explained https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
    // however, since our use case for the editor is Desktop, this solution is good enough
    useEffect(() => {
        const beforeUnload = (e) => {
            if (editor.changelog.changes.length !== 0) {
                e.preventDefault();
                // setting this string value has no affect in
                // the brower will show it's default implementation of the promp dialog with the default phrase
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', beforeUnload, { capture: true });
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [editor]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BrowserRouter, { basename: routematch.url },
            React.createElement(GraphToolbox, { graphVersion: graphVersion, editor: editor, isReleased: isReleased }),
            React.createElement(GraphEditorWidget, { editor: editor, i18n: i18n, i18nDefaultNS: 'libGraphEditor' })),
        React.createElement(Dialog, { open: isBlocked, onClose: () => cancel(), style: { padding: 0 }, title: t('editorApp2:graphEditorView.exitDialog.title', 'Save changes?'), helperText: t('editorApp2:graphEditorView.exitDialog.text', "Your unsaved changes will be lost if you don't save them."), actionButtons: [
                React.createElement(Button, { variant: "text", color: "primary", onClick: () => proceed() }, t('editorApp2:graphEditorView.exitDialog.exitUnsavedButton', 'Exit anyway')),
                React.createElement(Button, { variant: "text", color: "primary", onClick: () => {
                        cancel();
                    } }, t('editorApp2:graphEditorView.exitDialog.stayButton', 'Stay')),
            ] })));
}
