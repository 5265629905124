import { Error, Warning } from '@mui/icons-material';
import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Checkbox, Tooltip } from 'lib/cortiUI';
import { ChecklistExpressionDisplay } from './ChecklistExpressionDisplay';
import { useChecklistRouting } from './useChecklistRouting';
import { useChecklistView } from './useChecklistView';
export function ChecklistTableRow({ checklist }) {
    const { t } = useTranslation('libGraphEditor');
    const selected = useChecklistView(({ selected }) => selected);
    const toggleChecklistSelection = useChecklistView(({ toggleChecklistSelection }) => toggleChecklistSelection);
    const handleCheckboxClick = (evt) => {
        evt.stopPropagation();
        toggleChecklistSelection(checklist.id);
    };
    const { startEditing } = useChecklistRouting();
    const handleRowClick = () => startEditing(checklist.id);
    const handleCheckboxKeyDown = (evt) => evt.stopPropagation();
    const handleRowKeyDown = (evt) => evt.key === 'Enter' && startEditing(checklist.id);
    const isSelected = selected.includes(checklist.id);
    const nameCellId = `checklist-row-${checklist.id}`;
    return (React.createElement(TableRow, { sx: {
            bgcolor: checklist.validation === 'error' ? 'error.outlinedHoverBackground' : undefined,
            cursor: 'pointer',
        }, role: "button", onClick: handleRowClick, onKeyDown: handleRowKeyDown, tabIndex: 0 },
        React.createElement(TableCell, { onClick: handleCheckboxClick, onKeyDown: handleCheckboxKeyDown },
            React.createElement(Checkbox, { color: "primary", checked: isSelected, inputProps: { 'aria-labelledby': nameCellId }, onClick: handleCheckboxClick })),
        React.createElement(TableCell, null,
            React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" },
                React.createElement("span", { id: nameCellId }, checklist.name),
                checklist.validation === 'error' && (React.createElement(Tooltip, { title: t('checklist.editor.checklistWithErrors', 'Checklist has errors') },
                    React.createElement(Typography, { variant: "body2", sx: { color: 'error.main' } },
                        React.createElement(Error, { fontSize: "small" })))),
                checklist.validation === 'warning' && (React.createElement(Tooltip, { title: t('checklist.editor.checklistWithWarnings', 'Checklist has warnings') },
                    React.createElement(Typography, { variant: "body2", sx: { color: 'warning.light' } },
                        React.createElement(Warning, { fontSize: "small" })))))),
        React.createElement(TableCell, null, checklist.entryCount),
        React.createElement(TableCell, null,
            React.createElement("code", null, checklist.key)),
        React.createElement(TableCell, null, checklist.visibility ? (React.createElement(ChecklistExpressionDisplay, { value: checklist.visibility })) : (React.createElement("em", null, t('checklist.editor.visibilityAlways', 'Always'))))));
}
