import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export const ChoiceInputLoader = ({ answer_type }) => {
    function renderInputLoader() {
        switch (answer_type) {
            case 'DROPDOWN':
                return React.createElement(Skeleton, { variant: "rounded", width: 220, height: 50 });
            case 'MULTIPLE_CHOICE':
                return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 3 },
                    React.createElement(Skeleton, { variant: "text", width: 220 }),
                    React.createElement(Skeleton, { variant: "text", width: 220 })));
        }
    }
    return renderInputLoader();
};
