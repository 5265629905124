import React from 'react';
import { Observer } from '@corti/observer';
import { useLazyRef } from '@corti/react';
const UIEventContext = React.createContext(null);
/**
 * Provides API for publishing/subscribing UI events.
 */
export function UIEventProvider({ children, onEvent, }) {
    const observer = useLazyRef(() => new Observer());
    const context = useLazyRef(() => {
        return {
            fireEvent: (e) => observer.current.fireEvent('event', e),
            onEvent: (cb) => observer.current.on('event', cb),
        };
    });
    React.useEffect(() => {
        if (!onEvent) {
            return;
        }
        return context.current.onEvent(onEvent);
    }, [context, onEvent]);
    return React.createElement(UIEventContext.Provider, { value: context.current }, children);
}
export function useUIEventObserver() {
    const result = React.useContext(UIEventContext);
    if (!result) {
        throw new Error('useUIEventObserver should be used within UIEventContext');
    }
    return result;
}
