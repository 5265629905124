import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Input, TextField } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { ChecklistExpression } from './ChecklistExpression';
import { ChecklistInspectorAttribute } from './ChecklistInspectorAttribute';
import { useChecklistEdit } from './useChecklistEdit';
const MonospaceInput = styled(Input) `
  font-family: 'Roboto Mono', monospace;
`;
export function ChecklistInspectorEntry() {
    const { t } = useTranslation('libGraphEditor');
    const selection = useChecklistEdit((state) => state.selection);
    const updateEntry = useChecklistEdit((state) => state.updateEntry);
    const checklist = useChecklistEdit((state) => state.checklist);
    const { editor } = useGraphEditorCtx();
    const nodeListForAutocomplete = Array.from(editor.model.nodes.values()).map(({ id, readableTitle: label }) => ({ id, label }));
    if (selection === null) {
        return null;
    }
    const selectedEntry = checklist.entries[selection];
    return (React.createElement(React.Fragment, null,
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.checklistEntryKey', 'Entry Key'), help: t('checklist.checklistInfo.checklistKeyHelp', 'The entry key is a locally unique identifier that has to be unique inside each checklist, but not between checklists.'), hasSwitch: false },
            React.createElement(MonospaceInput, { value: selectedEntry.key, onChange: (evt) => updateEntry(selection, 'key', evt.target.value) })),
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.manualCompletionEnabled', 'Manual Completion'), help: t('checklist.checklistInfo.manualCompletionHelp', 'If enabled, this entry can be manually completed by checking the checkbox. If disabled, automatic completion must be enabled.'), hasSwitch: true, switchValue: selectedEntry.manualCompletionEnabled, handleSwitchChange: (value) => updateEntry(selection, 'manualCompletionEnabled', value) }),
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.automaticCompletionEnabled', 'Automatic Completion'), help: t('checklist.checklistInfo.automaticCompletionHelp', 'If enabled, this entry will be automatically completed/uncompleted based on the expression below.'), hasSwitch: true, switchValue: selectedEntry.automaticCompletionEnabled, handleSwitchChange: (value) => updateEntry(selection, 'automaticCompletionEnabled', value) },
            React.createElement(ChecklistExpression, { value: selectedEntry.automaticCompletionExpression, onChange: (value) => updateEntry(selection, 'automaticCompletionExpression', value), placeholder: selectedEntry.automaticCompletionEnabled ? undefined : '', disabled: !selectedEntry.automaticCompletionEnabled })),
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.inlineNodeId', 'Inline Node'), help: t('checklist.checklistInfo.inlineNodeIdHelp', 'If enabled, a popup can be opened to follow a guided flow through the protocol to help completing this task.'), hasSwitch: true, switchValue: selectedEntry.hasInlineNode, handleSwitchChange: (value) => updateEntry(selection, 'hasInlineNode', value) },
            React.createElement(Autocomplete, { renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "Select node" })), options: nodeListForAutocomplete, sx: { width: '100%' }, value: selectedEntry.inlineNodeID
                    ? nodeListForAutocomplete.find((node) => node.id === selectedEntry.inlineNodeID)
                    : null, onChange: (_evt, value) => updateEntry(selection, 'inlineNodeID', (value === null || value === void 0 ? void 0 : value.id) || '') }))));
}
