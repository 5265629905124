import { observer } from 'mobx-react';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
const staticStyles = {
    port: css({
        width: '14px !important',
        height: '14px !important',
        borderWidth: '2px !important',
        borderStyle: 'solid !important',
        top: 'unset !important',
        transform: 'unset !important',
        ':hover': { background: '#548AFF' },
    }),
};
export const DefaultPortWidget = observer(function DefaultPortWidget(props) {
    const { model } = props;
    const theme = useTheme();
    const hasSelectedLinks = model.links.some((l) => l.isSelected);
    const hasHoveredLinks = model.links.some((l) => l.isHovered);
    const backgroundColor = hasSelectedLinks
        ? theme.palette.primary.light
        : theme.palette.background.paper;
    const borderColor = hasSelectedLinks
        ? theme.palette.primary.main
        : hasHoveredLinks
            ? theme.palette.action.outlinedHoverBorder
            : theme.palette.action.outlinedBorder;
    return (React.createElement(Handle, { id: model.id, "data-cy": "port", "data-portid": model.id, "data-nodeid": model.parent.id, position: model.type === 'in' ? Position.Left : Position.Right, type: model.type === 'in' ? 'target' : 'source', style: {
            // hardcoded pixel positioning
            // this is very custom to the current design, if things start breaking often in the future
            // because of these hardcoded values, refactoring might be a good idea
            right: model.type === 'in' ? undefined : -6,
            left: model.type === 'in' ? -14 : undefined,
            background: backgroundColor,
            borderColor: borderColor,
        }, className: staticStyles.port }));
});
