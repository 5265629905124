var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIList from '@mui/material/List';
import React from 'react';
import { css } from '@corti/style';
/**
 * List and ListItem are built on top of Material UI List and ListItem components so they accept their props. Also any of Material UI List components can be passed as children to compose more complex lists.
 */
export const List = React.forwardRef(function List(props, ref) {
    const { classes } = props, rest = __rest(props, ["classes"]);
    return (React.createElement(MUIList, Object.assign({ ref: ref, disablePadding: true, classes: Object.assign(Object.assign({}, classes), { root: css({
                width: '100%',
            }, classes === null || classes === void 0 ? void 0 : classes.root) }) }, rest)));
});
