var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { computed, makeObservable } from 'mobx';
import { NodeUtils } from '../../utils';
export class Navigator {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "navigateToStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (targetStep) => {
                this.flowStore.changeActiveStep({
                    step: targetStep,
                    source: 'user-action',
                    sourceData: {
                        location: 'flow',
                    },
                });
            }
        });
        Object.defineProperty(this, "hasBackwardSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                return ((_a = this.flowStore.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.prevStepID) ? true : false;
            }
        });
        Object.defineProperty(this, "hasForwardSteps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { activeStep } = this.flowStore.stepStore;
                return activeStep ? NodeUtils.hasLogicalGates(activeStep.node) : false;
            }
        });
        Object.defineProperty(this, "navigateToNextStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.nextStep) {
                    this.navigateToStep(this.nextStep);
                }
            }
        });
        Object.defineProperty(this, "navigateToPrevStep", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.prevStep) {
                    this.navigateToStep(this.prevStep);
                }
            }
        });
        makeObservable(this);
    }
    get canNavigateForward() {
        if (this.nextStep) {
            return true;
        }
        else {
            return false;
        }
    }
    get nextStep() {
        const { stepStore: steps } = this.flowStore;
        const activeStepIdx = this.renderPath.findIndex((it) => { var _a; return it.id === ((_a = steps.activeStep) === null || _a === void 0 ? void 0 : _a.id); });
        const targetIdx = activeStepIdx + 1;
        if (targetIdx <= this.renderPath.length - 1) {
            return this.renderPath[targetIdx];
        }
    }
    get prevStep() {
        const { stepStore: steps } = this.flowStore;
        const activeStepIdx = this.renderPath.findIndex((it) => { var _a; return it.id === ((_a = steps.activeStep) === null || _a === void 0 ? void 0 : _a.id); });
        const targetIdx = activeStepIdx - 1;
        if (targetIdx >= 0) {
            return this.renderPath[targetIdx];
        }
    }
    get renderPath() {
        const { stepStore: steps } = this.flowStore;
        let path = steps.activePath.slice();
        if (steps.previewStep && steps.activeStep) {
            path = steps.calculatePath(steps.activeStep);
            if (steps.previewStep.model) {
                path.push(steps.previewStep.model);
            }
        }
        return path;
    }
}
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Navigator.prototype, "canNavigateForward", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Navigator.prototype, "nextStep", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Navigator.prototype, "prevStep", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], Navigator.prototype, "renderPath", null);
