var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
export function LinkButton(props) {
    const { disabled, className, type = 'button' } = props, rest = __rest(props, ["disabled", "className", "type"]);
    const theme = useTheme();
    return (React.createElement("button", Object.assign({ disabled: disabled, className: css({
            userSelect: 'none',
            pointerEvents: props.disabled ? 'none' : undefined,
            backgroundColor: 'transparent',
            color: props.disabled ? theme.palette.action.disabled : theme.palette.primary.main,
            fontSize: theme.typography.fontSize,
            '&:hover': {
                textDecoration: props.disabled ? 'none' : 'underline',
                cursor: props.disabled ? 'default' : 'pointer',
            },
        }, className), type: type }, rest)));
}
