var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIIconButton from '@mui/material/IconButton';
import React from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Loading } from 'lib/cortiUI/components/Loading';
export const getButtonSizeInPx = (size) => {
    switch (size) {
        case 'small': {
            return 34;
        }
        case 'medium': {
            return 40;
        }
        case 'large': {
            return 46;
        }
    }
};
export const IconButton = React.forwardRef(function IconButton(props, ref) {
    const theme = useTheme();
    const { icon, className, loading, size = 'medium' } = props, rest = __rest(props, ["icon", "className", "loading", "size"]);
    const sizeInPx = getButtonSizeInPx(size);
    return (React.createElement(MUIIconButton, Object.assign({ ref: ref, disableTouchRipple: true, disableFocusRipple: true, classes: {
            root: css({ height: sizeInPx, width: sizeInPx, flex: 'none' }, getFocusCss({ theme }), className),
        } }, rest), loading ? React.createElement(Loading, { size: "small" }) : icon));
});
