import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { FilterBuilder, FilterBuilderUtils, } from 'browser/components/FilterBuilder';
import { trackerService } from 'browser/services/init';
import { Base, Box, Button, Drawer, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { SavedFilters } from '../SavedFilters';
import { SaveFilterForm } from './SaveFilterForm';
export function AdvancedFiltering(props) {
    const { model } = props;
    const theme = useTheme();
    const [isSavedFiltersViewOpen, setIsSavedFiltersViewOpen] = React.useState(false);
    const [isSaveFilterModalOpen, setSaveFilterModalOpen] = React.useState(false);
    const toggleSavedFiltersView = () => {
        setIsSavedFiltersViewOpen(isSavedFiltersViewOpen ? false : true);
    };
    const toggleSaveFilterModal = () => {
        setSaveFilterModalOpen(isSaveFilterModalOpen ? false : true);
    };
    const handleFilterSave = (input) => {
        toggleSaveFilterModal();
        void model.saveCurrentCaseFilter(input);
    };
    const applyFilters = () => {
        model.applyAdvancedFilter();
        trackerService.track('[Explore] advanced cases filter applied', {
            overall_junction: model.advancedFilter.junction,
            queries: model.advancedFilter.entities.map((it) => ({
                id: it.id,
                junction: it.junction,
                predicates: it.predicates.map((p) => ({
                    attributeID: p.attributeID,
                    comparison: p.comparison,
                    value: p.value,
                })),
            })),
        });
    };
    const clearFilters = () => {
        model.resetAllFilters();
        model.casesQuickFilterState.resetQuickFilterState();
        trackerService.track('[Explore] cases filter cleared');
    };
    const onFilterChange = (filterValue) => {
        model.setAdvancedFilter(filterValue);
        model.casesQuickFilterState.resetQuickFilterState();
    };
    const isAdvancedFilterStateEmpty = FilterBuilderUtils.isStateEmpty(model.advancedFilter);
    const comparisonTranslations = {
        eq: intl.t('filters:eq', 'is'),
        neq: intl.t('filters:neq', 'is not'),
        gt: intl.t('filters:gt', 'is greater than'),
        lt: intl.t('filters:lt', 'is less than'),
        gte: intl.t('filters:gte', 'is greater than or equals'),
        lte: intl.t('filters:lte', 'is less than or equals'),
        contains: intl.t('filters:contains', 'contains'),
        ncontains: intl.t('filters:ncontains', 'does not contain'),
        between: intl.t('filters:between', 'between'),
        isset: intl.t('filters:isset', 'is set'),
        isnotset: intl.t('filters:isnotset', 'is not set'),
        after: intl.t('filters:after', 'after'),
        before: intl.t('filters:before', 'before'),
    };
    if (model.casesFilterDescriptor) {
        return (React.createElement(Observer, null, () => (React.createElement(React.Fragment, null,
            React.createElement(Box, { "data-cy": "advanced-filtering-view", py: 7, px: 8, mx: 8, className: css({
                    backgroundColor: theme.palette.background.secondary,
                    borderRadius: theme.shape.borderRadius,
                }) },
                React.createElement(FilterBuilder, { descriptor: model.casesFilterDescriptor, value: model.advancedFilter, onChange: onFilterChange, getComparisonText: (comparisonID) => comparisonTranslations[comparisonID] }),
                React.createElement(Box, { mt: 4, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
                    !isAdvancedFilterStateEmpty && (React.createElement(Button, { "data-cy": "clear-filters-button", className: css({ marginRight: 5 }), variant: "text", color: "primary", onClick: clearFilters }, intl.t('explore:filters.clearFilters', 'Clear all filters'))),
                    React.createElement(Button, { "data-cy": "saved-filters-button", className: css({ marginRight: 5 }), variant: "outlined", color: "primary", onClick: toggleSavedFiltersView }, intl.t('explore:filters.myFilters', 'My filters')),
                    React.createElement(Button, { variant: "outlined", className: css({ marginRight: 5 }), "data-cy": "save-new-filter-button", color: "primary", onClick: toggleSaveFilterModal, disabled: !model.isAdvancedFilterValid }, intl.t('save', 'Save')),
                    React.createElement(Button, { "data-cy": "apply-filters-button", color: "primary", onClick: applyFilters, disabled: !model.isAdvancedFilterValid }, intl.t('explore:filters.applyFilters', 'Apply filters')))),
            React.createElement(Drawer, { anchor: "right", open: isSavedFiltersViewOpen, onClose: toggleSavedFiltersView },
                React.createElement(SavedFilters, { model: model, onClose: toggleSavedFiltersView })),
            React.createElement(Modal, { open: isSaveFilterModalOpen, onClose: toggleSaveFilterModal },
                React.createElement(Base, null,
                    React.createElement(ModalHeader, { title: intl.t('explore:filters.saveFilters', 'Save new filters') }),
                    React.createElement(ModalContent, null,
                        React.createElement(SaveFilterForm, { onSave: handleFilterSave }))))))));
    }
    else {
        return null;
    }
}
