var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ChipV2, Tooltip, Typography } from 'lib/cortiUI';
export function EnvIndicator(props) {
    const { tooltipText, type, children } = props, rest = __rest(props, ["tooltipText", "type", "children"]);
    return (React.createElement(Tooltip, { disableInteractive: true, title: React.createElement(Typography, { fontWeight: 600, color: "inherit", variant: "caption" }, tooltipText) },
        React.createElement(ChipV2, Object.assign({ label: children, color: type, variant: "outlined", size: "medium" }, rest))));
}
