import TitleIcon from '@mui/icons-material/Title';
import { observer } from 'mobx-react';
import React from 'react';
import { InputAdornment, TextField } from 'lib/cortiUI';
import { FormElementWidget } from './FormElementWidget';
export const TextInputElementWidget = observer(function TextInputElementWidget({ model, }) {
    return (React.createElement(FormElementWidget, { model: model },
        React.createElement(TextField, { disabled: true, fullWidth: true, value: "", InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(TitleIcon, { fontSize: "small" }))),
            } })));
});
