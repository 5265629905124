import styled from '@emotion/styled';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Checkbox } from './Checkbox';
const Wrapper = styled(motion.div) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 4px;
  border-radius: 8px;
  width: 100%;
  position: relative;
  pointer-events: none;
  ${({ $clickable, $readOnly }) => $clickable &&
    !$readOnly &&
    `
    cursor: pointer;
    pointer-events: auto;
    &:hover,
    &:focus {
      background-color: color-mix(in srgb, var(--primary-color) 10%, transparent);
    }
  `};
`;
const labelVariants = {
    initial: { opacity: 0, left: 5, transition: { duration: 0.2 } },
    animate: { opacity: 1, left: 0, transition: { duration: 0.2 } },
    exit: { opacity: 0, left: 0, transition: { duration: 0.2 } },
};
export function ChecklistEntry(props) {
    const { label, details, manual = false, automatic = false, manuallyChecked = false, automaticallyChecked = false, readOnly = false, hasInlineNode = false, } = props;
    const [hovered, setHovered] = useState(false);
    const onHover = () => setHovered(true);
    const onHoverEnd = () => setHovered(false);
    const onKeyPress = (event) => {
        if (!props.readOnly && ['enter', 'space'].includes(event.key.toLowerCase())) {
            props.onToggle();
        }
    };
    const manualProps = {
        tabIndex: 0,
        onClick: props.readOnly ? undefined : props.onToggle,
        onKeyPress,
        role: 'checkbox',
        'aria-checked': manuallyChecked,
    };
    const onLaunch = (e) => {
        var _a;
        e.stopPropagation();
        if (props.readOnly)
            return;
        (_a = props.onLaunch) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (React.createElement(Wrapper, Object.assign({ "$clickable": manual, "$readOnly": readOnly, variants: labelVariants, onPointerOver: onHover, onPointerLeave: onHoverEnd }, (manual && manualProps)),
        React.createElement(Checkbox, { autoEnabled: automatic, manualEnabled: manual, autoChecked: automaticallyChecked, manualChecked: manuallyChecked, reveal: hovered }),
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { variant: "body1" }, label),
            details && React.createElement(Typography, { variant: "body2" }, details)),
        hasInlineNode && (React.createElement(Box, { flexGrow: "1", display: "flex", justifyContent: "flex-end" },
            React.createElement(Button, { onClick: onLaunch },
                React.createElement(OpenInNewIcon, null))))));
}
