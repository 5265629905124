var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIListItemButton from '@mui/material/ListItemButton';
import React from 'react';
import { css, rgba } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI/components/baseHoc';
export const ListItemButton = React.forwardRef(function ListItem(props, ref) {
    const { children, classes, className } = props, rest = __rest(props, ["children", "classes", "className"]);
    const theme = useTheme();
    const baseCss = useBaseCss(rest);
    const filtered = filterBaseProps(rest);
    return (React.createElement(MUIListItemButton, Object.assign({ ref: ref, className: css(baseCss, className), classes: Object.assign(Object.assign({}, classes), { root: css({
                backgroundColor: theme.palette.background.secondary,
                borderRadius: theme.shape.borderRadius,
                '&:not(:last-child)': {
                    marginBottom: theme.space[3],
                },
            }, classes === null || classes === void 0 ? void 0 : classes.root), selected: css({
                backgroundColor: `${rgba(theme.palette.primary.main, 0.2)} !important`,
            }, classes === null || classes === void 0 ? void 0 : classes.selected) }) }, filtered), children));
});
