import React from 'react';
import { useTranslation } from '@corti/i18n';
import { updateTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { TextField } from 'lib/cortiUI';
import { useSaving, useTemplate } from '../context';
export const TitleInput = ({ disabled, initialName }) => {
    const { t } = useTranslation('template', { keyPrefix: 'builder.configuration' });
    const { templateID } = useTemplate();
    const { setIsSaving } = useSaving();
    const [name, setName] = React.useState(initialName !== null && initialName !== void 0 ? initialName : '');
    const [isSavingTemplate, setIsSavingTemplate] = React.useState(false);
    const serverNameRef = React.useRef(initialName);
    async function handleTemplateUpdate() {
        const newValue = name.trim();
        if (!newValue || newValue === serverNameRef.current) {
            if (serverNameRef.current)
                setName(serverNameRef.current);
            return;
        }
        setIsSavingTemplate(true);
        setIsSaving(true);
        try {
            await updateTemplate(templateID, { name: newValue });
            setName(newValue);
            serverNameRef.current = newValue;
            coreStore.notifications.showNotification({
                message: t('templateUpdateSuccess', 'Title saved'),
            });
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                message: t('templateUpdateError', 'Failed to save title'),
                type: 'error',
            });
        }
        finally {
            setIsSavingTemplate(false);
            setIsSaving(false);
        }
    }
    return (React.createElement(TextField, { sx: { width: '50%' }, value: name, onChange: ({ target }) => {
            setName(target.value);
        }, onBlur: handleTemplateUpdate, label: t('templateTitle', 'Template title'), disabled: disabled || isSavingTemplate }));
};
