var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
export class BaseAttributeModel {
    constructor(attributeDescriptors) {
        Object.defineProperty(this, "attributeDescriptors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.attributeDescriptors = attributeDescriptors;
        });
    }
    get values() {
        const map = {};
        Object.keys(this.attributeDescriptors).forEach((name) => {
            var _a;
            map[name] =
                (_a = this.attributeDescriptors[name].value) !== null && _a !== void 0 ? _a : this.attributeDescriptors[name].defaultValue;
        });
        return map;
    }
    setAttributeValue(name, value) {
        const descriptor = this.attributeDescriptors[name];
        descriptor.value = value;
    }
    getAttributeValue(name) {
        var _a;
        const descriptor = this.attributeDescriptors[name];
        return ((_a = descriptor.value) !== null && _a !== void 0 ? _a : descriptor.defaultValue);
    }
    deserialize(value) {
        Object.entries(value).forEach(([name, value]) => {
            this.setAttributeValue(name, value);
        });
    }
    getEncodableData() {
        return Object.assign({}, this.values);
    }
    copy() {
        const i = new BaseAttributeModel({});
        i.attributeDescriptors = cloneDeep(this.attributeDescriptors);
        return i;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], BaseAttributeModel.prototype, "attributeDescriptors", void 0);
__decorate([
    computed,
    __metadata("design:type", void 0),
    __metadata("design:paramtypes", [])
], BaseAttributeModel.prototype, "values", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof K !== "undefined" && K) === "function" ? _a : Object, Object]),
    __metadata("design:returntype", void 0)
], BaseAttributeModel.prototype, "setAttributeValue", null);
