import { Observer } from '@corti/observer';
export class BaseStream {
    constructor(realtime) {
        Object.defineProperty(this, "realtime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: realtime
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "onlineUserIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
        Object.defineProperty(this, "messageQueue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "onUserConnected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('user-connected', cb);
            }
        });
        Object.defineProperty(this, "onUserDisconnected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('user-disconnected', cb);
            }
        });
        Object.defineProperty(this, "onUsersChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('users-changed', () => {
                    cb(Array.from(this.onlineUserIDs));
                });
            }
        });
        // #region testing
        Object.defineProperty(this, "dispatchEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (eventName, data) => {
                this.realtime.dispatchEvent({
                    event: eventName,
                    data,
                });
            }
        });
        /**
         * Send an event and wait for the server responding with event
         */
        Object.defineProperty(this, "sendAndWait", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                let allEventsHandle;
                let allErrorsHandle;
                let connectionStateHandle;
                this.realtime.connection.send(event);
                return new Promise((resolve, reject) => {
                    allEventsHandle = this.realtime.onEventReceived((receivedEvent) => {
                        if (receivedEvent.correlationID === event.correlationID) {
                            resolve(receivedEvent);
                        }
                    });
                    allErrorsHandle = this.realtime.onErrorEventReceived((errEvent) => {
                        if (errEvent.correlationID && errEvent.correlationID === event.correlationID) {
                            reject(new Error(errEvent.data.message));
                        }
                    });
                    connectionStateHandle = this.realtime.connection.on('stateChanged', ({ current }) => {
                        if (current === 'disconnected') {
                            reject(new Error('DISCONNECTED'));
                            return;
                        }
                        if (current !== 'connected') {
                            reject(new Error('REQUEST_TIMEOUT'));
                            return;
                        }
                    });
                }).finally(() => {
                    allEventsHandle();
                    allErrorsHandle();
                    connectionStateHandle();
                });
            }
        });
        Object.defineProperty(this, "handleConnChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (change) => {
                if (change.current === 'disconnected') {
                    this.messageQueue = [];
                }
            }
        });
        this.bindInternalEvents();
    }
    destroy() {
        this.unbindInternalEvents();
        this.observer.unAll();
    }
    handleEvent(e) {
        switch (e.event) {
            case 'user-presence': {
                const event = e;
                this.onlineUserIDs = new Set(event.data.userIDs);
                this.observer.fireEvent('users-channel', event);
                this.observer.fireEvent('users-changed');
                break;
            }
            case 'user-connected': {
                const event = e;
                this.onlineUserIDs.add(event.data.userID);
                this.observer.fireEvent('users-channel', event);
                this.observer.fireEvent('user-connected', event.data.userID);
                this.observer.fireEvent('users-changed');
                break;
            }
            case 'user-disconnected': {
                const event = e;
                this.onlineUserIDs.delete(event.data.userID);
                this.observer.fireEvent('users-channel', event);
                this.observer.fireEvent('user-disconnected', event.data.userID);
                this.observer.fireEvent('users-changed');
                break;
            }
            case 'ack': {
                break;
            }
            default: {
                this.observer.fireEvent('streamEvent', e);
                this.observer.fireEvent(e.event, e.data);
            }
        }
    }
    /**
     * Bind to all events in this stream
     */
    bindAll(cb) {
        return this.observer.on('streamEvent', cb);
    }
    /**
     * Bind to all user presence events in this stream
     */
    bindUsersChannel(cb) {
        return this.observer.on('users-channel', cb);
    }
    bind(event, cb) {
        return this.observer.on(event, cb);
    }
    unbind(event, cb) {
        return this.observer.un(event, cb);
    }
    // #endregion testing
    sendNow(event) {
        this.realtime.connection.send(event);
    }
    sendMessageQueue() {
        this.messageQueue.forEach((msg) => {
            this.realtime.connection.send(msg);
        });
        this.messageQueue = [];
    }
    queueEvent(event) {
        this.messageQueue.push(event);
    }
    bindInternalEvents() {
        this.realtime.connection.on('stateChanged', this.handleConnChange);
    }
    unbindInternalEvents() {
        this.realtime.connection.un('stateChanged', this.handleConnChange);
    }
}
