import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { ScreenRecorderService } from '@corti/lib/screenRecorderService';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { ExploreView } from './ExploreView';
import { ExploreViewState } from './ExploreViewState';
let exploreStore;
let screenRecorderApi;
const mod = {
    id: 'explore',
    title: () => intl.t('explore:appName', 'Explore'),
    path: '/explore',
    requiresUserPermission: 'app:review',
    requiresOrgFeature: 'review',
    icon: ManageSearchRoundedIcon,
    viewComponent: (props) => (React.createElement(ExploreView, Object.assign({}, props, { store: exploreStore, screenRecorderAPI: screenRecorderApi }))),
    onInit: () => {
        exploreStore = new ExploreViewState();
        screenRecorderApi = new ScreenRecorderService({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
    },
    onDestroy: () => {
        exploreStore = undefined;
    },
    navGroupe: 1,
};
export default mod;
