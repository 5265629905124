import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Box, Stack, Typography } from 'lib/cortiUI';
import { CaptureSettings, CaptureSourcesList, ScreenRecorderCard, SettingsControlsPanel, } from './components';
import { useSettingsContext } from './context';
export const ScreenRecorderSettings = () => {
    const { t } = useTranslation('screenRecorder');
    const { palette } = useTheme();
    const { capturingSettings, availableCaptureSources, updateAvailableCaptureSources, updateCapturingSettings, } = useSettingsContext();
    return (React.createElement(Stack, { px: 6, py: 4, minHeight: "100vh", gap: 4 },
        React.createElement(SettingsControlsPanel, null),
        React.createElement(Box, { maxWidth: 1280, display: "flex", flexDirection: "column", margin: 2, gap: 3 },
            React.createElement(ScreenRecorderCard, null,
                React.createElement(CaptureSettings, { initialSettings: capturingSettings, onSettingsChanged: updateCapturingSettings })),
            React.createElement(ScreenRecorderCard, null,
                React.createElement(CaptureSourcesList, { sources: availableCaptureSources, onCaptureSourcesChanged: updateAvailableCaptureSources })),
            !availableCaptureSources.some((s) => s.selected) && (React.createElement(Base, { display: "flex", alignItems: "center", borderRadius: 4, px: 7, py: 7, background: palette.grey[200], gap: 4 },
                React.createElement(Typography, { color: "error" }, t('selectSourceWarning', 'Please, select at least 1 source to enable automatic screen recording when a call starts.')))))));
};
