import React from 'react';
// @ts-ignore
import TimelinePluginInternal from 'wavesurfer.js-3/dist/plugin/wavesurfer.timeline.min.js';
import { useWavesurferContext } from '../WaveSurferContext';
const getTimeInterval = (pxPerSec, minNotchGap = 10) => {
    const floatingInterval = 1 / (pxPerSec / minNotchGap);
    let fixedInterval = 1;
    let reducer = 1;
    for (let i = 1; i > 0; i++) {
        if (floatingInterval > 1 * reducer) {
            fixedInterval = 2 * reducer;
            break;
        }
        else if (floatingInterval > 0.5 * reducer) {
            fixedInterval = 1 * reducer;
            break;
        }
        else if (floatingInterval > 0.2 * reducer) {
            fixedInterval = 0.5 * reducer;
            break;
        }
        else if (floatingInterval > 0.1 * reducer) {
            fixedInterval = 0.2 * reducer;
            break;
        }
        reducer = reducer / 10;
    }
    return fixedInterval;
};
export function TimelinePlugin(props) {
    const { container, height = 50, color = '#676767', minNotchGap = 10, timeFormatter } = props;
    const { wavesurfer } = useWavesurferContext();
    React.useEffect(() => {
        wavesurfer.addPlugin(TimelinePluginInternal.create({
            container: container,
            height: height,
            notchPercentHeight: 30,
            fontSize: 12,
            formatTimeCallback: timeFormatter,
            timeInterval: (pxPerSec) => getTimeInterval(pxPerSec, minNotchGap),
            unlabeledNotchColor: color,
            primaryLabelInterval: 10,
            secondaryLabelInterval: 10,
            primaryColor: color,
            secondaryColor: color,
            primaryFontColor: color,
            secondaryFontColor: color,
        }));
        wavesurfer.initPlugin('timeline');
        return () => {
            try {
                wavesurfer.destroyPlugin('timeline');
            }
            catch (_a) { }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
