import { gql } from '@apollo/client';
export const getTeamsQuery = gql `
  query Teams($orgID: String!) {
    teams(organizationID: $orgID) {
      id
      name
      members {
        user {
          id
        }
      }
    }
  }
`;
