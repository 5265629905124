import { uuid } from '@corti/uuid';
export const EntityFactory = {
    createComment: (text, createdBy) => {
        return {
            id: uuid(),
            text: text,
            datetime: new Date().toISOString(),
            createdBy: createdBy,
        };
    },
    createAlert: (input) => {
        var _a, _b;
        return {
            id: uuid(),
            title: input.title,
            text: (_a = input.text) !== null && _a !== void 0 ? _a : null,
            color: input.color,
            datetime: input.datetime,
            alertNode: (_b = input.alertNode) !== null && _b !== void 0 ? _b : null,
        };
    },
    createTakeoverRequest: (newOwnerUserID, createdBy) => {
        return {
            id: uuid(),
            newOwnerUserID: newOwnerUserID,
            createdAt: new Date().toISOString(),
            createdBy: {
                id: createdBy.id,
            },
        };
    },
};
