import { initRestClient } from './RestClient';
import { TRANSCRIPTS_API_ERROR_NAME, TranscriptsApiError } from './TranscriptsApiError';
export class TranscriptsApiService {
    constructor(config) {
        Object.defineProperty(this, "restClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getTimeSeriesDataPoints", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const url = `/proxy/elastic-api/v2/time-series`;
                const serverInput = {
                    time_zone: input.timeZone,
                    term_groups: input.termGroups.map((it) => ({ id: it.id, terms: it.terms })),
                    date_range: {
                        start: input.dateRange.start.toISOString(),
                        end: input.dateRange.end.toISOString(),
                    },
                    user_ids: input.userIDs,
                    protocol_name_ids: input.protocolNameIDs,
                };
                const res = await this.restClient.post(url, serverInput);
                return {
                    data: res.data.data.map((it) => ({
                        totalHitsCount: it.total_hits_count,
                        hitsCount: it.hits_count,
                        termGroupID: it.term_group_id,
                        dataPoints: it.data_points.map((it) => ({
                            startOfPeriod: new Date(it.start_of_period),
                            callCount: it.call_count,
                        })),
                    })),
                };
            }
        });
        Object.defineProperty(this, "getCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const url = `/proxy/elastic-api/v2/call-list`;
                const serverInput = {
                    page_token: input.pageToken,
                    page_size: input.pageSize,
                    time_zone: input.timeZone,
                    term_groups: input.termGroups.map((it) => ({ id: it.id, terms: it.terms })),
                    date_range: {
                        start: input.dateRange.start.toISOString(),
                        end: input.dateRange.end.toISOString(),
                    },
                    call_time_range: input.callTimeRange
                        ? {
                            start: input.callTimeRange.start,
                            end: input.callTimeRange.end,
                        }
                        : undefined,
                    user_ids: input.userIDs,
                    protocol_name_ids: input.protocolNameIDs,
                };
                const res = await this.restClient.post(url, serverInput);
                return {
                    nextPageToken: res.data.next_page_token,
                    data: res.data.data.map((it) => ({
                        id: it.call_id,
                        startedAt: new Date(it.date),
                        duration: it.duration,
                        termGroupIDs: it.term_group_ids,
                    })),
                };
            }
        });
        Object.defineProperty(this, "getTheFinderData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const url = `/proxy/elastic-api/v2/the-finder`;
                const serverInput = {
                    question_id: input.questionID,
                    title: input.title,
                    scripted_phrases: input.scriptedPhrases,
                    not_scripted_phrases: input.notScriptedPhrases,
                    before_time: input.beforeTime,
                    after_time: input.afterTime,
                    selected_protocols: input.selectedProtocols,
                };
                const res = await this.restClient.post(url, serverInput);
                return res.data.map((v) => {
                    return {
                        callID: v.call_id,
                        before: v.before,
                        match: v.match,
                        after: v.after,
                        fullText: v.full_text,
                        protocolName: v.protocol_name,
                        questionID: v.question_id,
                        title: v.title,
                        asWritten: v.as_written,
                        startedAt: v.started_at,
                    };
                });
            }
        });
        Object.defineProperty(this, "getCallTranscript", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (callID) => {
                const url = `/proxy/elastic-api/v2/transcripts/${callID}`;
                const res = await this.restClient.get(url);
                return res.data;
            }
        });
        this.restClient = initRestClient({
            host: config.host,
            authToken: config.authToken,
        });
    }
    static isServiceError(err) {
        return err instanceof TranscriptsApiError && err.name === TRANSCRIPTS_API_ERROR_NAME;
    }
}
