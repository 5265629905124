import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Box, Button, ConfirmableTarget, ModalContent, ModalHeader, Radio, RadioGroup, Separator, SettingsCheckInput, Switch, TextField, Tooltip, Typography, } from 'lib/cortiUI';
import { SettingsSection } from './SettingsSection';
export const ViewSettingsModalContent = ({ view, isDraft, isAdvancedSettingsEnabled, onSave, onDelete, creatorName, }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'createUpdateView' });
    const [title, setTitle] = React.useState(view.title);
    const [visibility, setVisibility] = React.useState(view.visibility);
    const [locked, setLocked] = React.useState(view.locked);
    const hasDataChanged = !isEqual(view, { title, visibility, locked });
    const handleChangeVisibility = (_e, value) => {
        setVisibility(value);
        if (value === 'PRIVATE') {
            setLocked(false);
        }
        else {
            setLocked(view.locked);
        }
    };
    const handleSave = () => {
        onSave(Object.assign(Object.assign({}, view), { title, visibility, locked }));
    };
    const VISIBILITY_OPTIONS = [
        {
            value: 'PRIVATE',
            label: t('visibilityOptions.privateLabel', 'Private'),
            description: t('visibilityOptions.privateDescription', 'Visible only to you'),
        },
        {
            value: 'PUBLIC',
            label: t('visibilityOptions.publicLabel', 'Public'),
            description: t('visibilityOptions.publicDescription', 'Visible to everyone in your organization'),
        },
    ];
    return (React.createElement(Box, { "data-cy": "performance-save-view-modal" },
        React.createElement(ModalHeader, { title: !isDraft ? t('editView', 'Edit View') : t('saveView', 'Save View') }),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(ModalContent, null,
            React.createElement(Base, { display: "flex", gap: 7, flexDirection: "column" },
                React.createElement(Base, null,
                    React.createElement(TextField, { "data-cy": "performance-save-view-name-input", fullWidth: true, label: t('viewTitleLabel', 'View Name'), placeholder: t('viewTitlePlaceholder', 'Type a name for this view'), value: title, onChange: (e) => {
                            setTitle(e.target.value);
                        } })),
                React.createElement(SettingsSection, { title: t('visibilitySectionTitle', 'Visibility') },
                    React.createElement(Tooltip, { title: isAdvancedSettingsEnabled ? undefined : (React.createElement(Typography, { variant: "caption", color: "inherit" },
                            t('visibilityDisabledTooltip', 'You don’t have permission to change the Visibility of this View, as you are not the creator. Creator: '),
                            React.createElement(Typography, { variant: "caption", color: "inherit", fontStyle: "italic" }, creatorName))), placement: "top-end" },
                        React.createElement(RadioGroup, { name: t('visibility', 'Visibility'), "aria-label": t('visibility', 'Visibility'), value: visibility, onChange: handleChangeVisibility },
                            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 }, VISIBILITY_OPTIONS.map((option) => (React.createElement(SettingsCheckInput, { key: option.value, label: option.label, description: option.description, inputComponent: React.createElement(Radio, { value: option.value }), disabled: !isAdvancedSettingsEnabled }))))))),
                isAdvancedSettingsEnabled && visibility === 'PUBLIC' && (React.createElement(SettingsSection, { title: t('advancedSectionTitle', 'Advanced') },
                    React.createElement(SettingsCheckInput, { label: t('lockedLabel', 'Lock for edits'), description: t('lockedDescription', 'Only you can make permanent edits to this View'), inputComponent: React.createElement(Switch, { checked: locked, onChange: (_e, checked) => setLocked(checked) }) }))))),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(Base, { display: "flex", justifyContent: "flex-start", px: 7, pb: 5, pt: 5 },
            !isDraft && onDelete && (React.createElement(ConfirmableTarget, { message: t('warningOnDelete', 'Delete View permanently? This action cannot be undone.'), onConfirm: onDelete, color: "error" }, ({ onClick }) => (React.createElement(Button, { "data-cy": "performance-delete-view-btn", variant: "text", color: "error", onClick: onClick }, t('delete', 'Delete'))))),
            React.createElement(Button, { "data-cy": "performance-save-view-save-btn", variant: "text", onClick: handleSave, sx: { ml: 'auto' }, disabled: !hasDataChanged && !isDraft }, isDraft ? t('save', 'Save') : t('apply', 'Apply')))));
};
