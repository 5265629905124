var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Typography } from 'lib/cortiUI/components/Typography';
/**
 * Component to show that some parts of view is loading or waiting for data
 */
export const Loading = (props) => {
    const { text, size = 'medium', color } = props, rest = __rest(props, ["text", "size", "color"]);
    const theme = useTheme();
    const sizes = {
        small: 16,
        medium: 32,
        large: 48,
    };
    return (React.createElement(Box, Object.assign({}, rest, { alignItems: "center" }),
        React.createElement(CircularProgress, { style: { color: color !== null && color !== void 0 ? color : theme.palette.text.hint }, size: sizes[size] }),
        text && (React.createElement(Typography, { variant: "body1", color: "hint", mt: 3 }, text))));
};
