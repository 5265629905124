import React from 'react';
import { Base } from 'lib/cortiUI';
import { CallTakersDataGrid, CallsDataGrid, ComplianceTrackersPanel, GeneralPerformancePanel, PerformanceChart, } from '../../ui';
import { useParametersContext } from '../context/ParametersContext';
export const DataPanel = ({ viewID }) => {
    const { appliedFilters: { userIDs }, } = useParametersContext();
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 8 },
        React.createElement(Base, { display: "flex", gap: 5 },
            React.createElement(Base, { flex: 1 },
                React.createElement(GeneralPerformancePanel, null)),
            React.createElement(Base, { flex: 2 },
                React.createElement(PerformanceChart, null))),
        React.createElement(ComplianceTrackersPanel, null),
        userIDs.length ? React.createElement(CallsDataGrid, { viewID: viewID }) : React.createElement(CallTakersDataGrid, null)));
};
