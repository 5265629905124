import { formatDistanceToNow } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { usePeriodicRerender } from '@corti/react';
export function useGraphStatus({ editor, isReleased, savingState, }) {
    const { t } = useTranslation();
    // Rerender every 30 seconds to update the "last saved" status
    usePeriodicRerender(30000);
    function getLastSavedStatusWording(date) {
        const w = formatDistanceToNow(date, {
            addSuffix: true,
        });
        return t('editorApp2:graphEditorView.statusText.graphSaved', 'Graph saved {{ timeAgo }}', {
            timeAgo: w,
        });
    }
    if (isReleased) {
        return {
            canSave: false,
            text: t('editorApp2:graphEditorView.statusText.readOnly', 'This graph is read-only'),
        };
    }
    switch (savingState.type) {
        case 'error': {
            return {
                canSave: true,
                text: t('editorApp2:graphEditorView.statusText.savingError', 'Error'),
            };
        }
        case 'saving': {
            return {
                canSave: false,
                text: t('editorApp2:graphEditorView.statusText.saving', 'Saving...'),
            };
        }
        case 'initial':
        case 'saved': {
            const canSave = editor.changelog.changes.length !== 0;
            return {
                canSave,
                text: canSave
                    ? t('editorApp2:graphEditorView.statusText.hasUnsavedChanges', 'Unsaved changes')
                    : getLastSavedStatusWording(savingState.lastChangeDate),
            };
        }
    }
}
