import React from 'react';
import { Base, Container, Page } from 'lib/cortiUI';
import { NavigationBlockerModal } from '../ui';
import { DataPanel, Header, Toolbar } from './containers';
import { ParametersProvider } from './context/ParametersContext';
import { DataContextProvider } from './context/dataContext';
export const ActiveView = ({ performanceViewID, draftTrackers, onDiscardDraftView, }) => {
    return (React.createElement(Page, null,
        React.createElement(Container, null,
            React.createElement(ParametersProvider, { performanceViewID: performanceViewID, draftTrackers: draftTrackers, onDiscardDraftView: onDiscardDraftView },
                React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7 },
                    React.createElement(Header, null),
                    React.createElement(Toolbar, null),
                    React.createElement(DataContextProvider, null,
                        React.createElement(DataPanel, { viewID: performanceViewID }))),
                React.createElement(NavigationBlockerModal, null)))));
};
