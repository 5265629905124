var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { CoreApiError, api } from '@corti/lib/coreApiService';
import { Observer } from '@corti/observer';
export class RolesRepo {
    constructor() {
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "_roles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "permissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._roles = {};
                this.permissions = [];
            }
        });
        makeObservable(this);
        this.resetStore();
        api.auth.onSignedOut(this.resetStore);
    }
    async loadAllRoles() {
        const roles = await api.roles.getAll(api.auth.user.orgID);
        runInAction(() => {
            roles.forEach((role) => {
                this._roles[role.id] = role;
            });
        });
    }
    async loadPremissions() {
        const loadedPermissions = await api.roles.getPermissions(api.auth.user.orgID);
        runInAction(() => {
            this.permissions = loadedPermissions;
        });
    }
    async createRole(input) {
        const orgId = api.auth.user.orgID;
        try {
            const newRole = await api.roles.create(input, orgId);
            runInAction(() => {
                this._roles[newRole.id] = newRole;
            });
            return { data: newRole };
        }
        catch (err) {
            console.error(err);
            if (err instanceof CoreApiError) {
                return { error: { message: err.message } };
            }
            return { error: { message: 'Create role: unknown error' } };
        }
    }
    async updateRole(roleId, input) {
        const orgId = api.auth.user.orgID;
        const roleToUpdate = this._roles[roleId];
        if (!roleToUpdate) {
            return { error: { message: 'Role does not exist' } };
        }
        try {
            const updatedRole = await api.roles.update(roleId, input, orgId);
            runInAction(() => {
                Object.assign(roleToUpdate, updatedRole);
            });
            this.observer.fireEvent('roleUpdated', roleToUpdate);
            return { data: updatedRole };
        }
        catch (err) {
            console.error(err);
            if (err instanceof CoreApiError) {
                return { error: { message: err.message } };
            }
            return { error: { message: 'Update role: unknown error' } };
        }
    }
    async deleteRole(roleId) {
        const orgId = api.auth.user.orgID;
        try {
            await api.roles.delete(roleId, orgId);
            runInAction(() => {
                delete this._roles[roleId];
            });
            return { data: undefined };
        }
        catch (err) {
            console.error(err);
            if (err instanceof CoreApiError) {
                return { error: { message: err.message } };
            }
            return { error: { message: 'Delete role: unknown error' } };
        }
    }
    get roles() {
        return Object.values(this._roles);
    }
    onRoleUpdated(cb) {
        return this.observer.on('roleUpdated', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RolesRepo.prototype, "_roles", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], RolesRepo.prototype, "permissions", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RolesRepo.prototype, "resetStore", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], RolesRepo.prototype, "loadAllRoles", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], RolesRepo.prototype, "loadPremissions", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof CreateRoleInput !== "undefined" && CreateRoleInput) === "function" ? _a : Object]),
    __metadata("design:returntype", typeof (_b = typeof Promise !== "undefined" && Promise) === "function" ? _b : Object)
], RolesRepo.prototype, "createRole", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, typeof (_c = typeof UpdateRoleInput !== "undefined" && UpdateRoleInput) === "function" ? _c : Object]),
    __metadata("design:returntype", typeof (_d = typeof Promise !== "undefined" && Promise) === "function" ? _d : Object)
], RolesRepo.prototype, "updateRole", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
], RolesRepo.prototype, "deleteRole", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], RolesRepo.prototype, "roles", null);
