import { isEqual } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { FEATURE_IDS_LIST } from '@corti/lib/coreApiService';
import { Autocomplete, MenuItem, TextField, } from 'lib/cortiUI';
import { SettingsSection } from './SettingsSection';
import { ToggleSwitch } from './ToggleSwitch';
const featureItemsList = FEATURE_IDS_LIST.map((featureID) => ({
    value: featureID,
    text: featureID,
}));
export const GeneralSettings = ({ organizationName, organizationFeatures = [], ssoEnabled, onUpdateOrganizationName, onSetOrganizationFeatures, onDeleteOrganizationFeatures, onToggleSsoEnabled, }) => {
    const [selectedFeatures, setSelectedFeatures] = React.useState(organizationFeatures.map((translatedFeature) => ({
        value: translatedFeature.featureID,
        text: translatedFeature.featureID,
    })) || []);
    const handleNameInputBlur = ({ target }) => {
        onUpdateOrganizationName(target.value);
    };
    const handleSelectFeaturesChange = (features, reason, details) => {
        setSelectedFeatures(features);
        if (reason === 'removeOption' && details) {
            return onDeleteOrganizationFeatures([details.option.value]);
        }
        if (reason === 'clear') {
            return onDeleteOrganizationFeatures(organizationFeatures.map((feature) => feature.featureID));
        }
        onSetOrganizationFeatures(features.map((feature) => feature.value));
    };
    return (React.createElement(SettingsSection, { title: intl.t('adminPanel:generalSettings.sectionTitle', 'General Settings') },
        React.createElement(TextField, { placeholder: intl.t('adminPanel:generalSettings.organizationName', 'Organization name'), defaultValue: organizationName, label: intl.t('adminPanel:generalSettings.organizationName', 'Organization name'), onBlur: handleNameInputBlur }),
        React.createElement(Autocomplete, { multiple: true, disableCloseOnSelect: true, options: featureItemsList, getOptionLabel: (option) => option.text, value: selectedFeatures, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_, nextValues, reason, details) => {
                handleSelectFeaturesChange(nextValues, reason, details);
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('adminPanel:generalSettings.enabledFeatures', 'Features'), placeholder: intl.t('adminPanel:generalSettings.enabledFeatures', 'Features') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.value }), option.text)), ChipProps: {
                size: 'small',
            } }),
        React.createElement(ToggleSwitch, { label: intl.t(`adminPanel:generalSettings.enableSSO`, 'Enable SSO authentication'), checked: ssoEnabled, onChange: (_, checked) => onToggleSsoEnabled(checked) })));
};
