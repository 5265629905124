import React from 'react';
import { intl } from '@corti/i18n';
import { Base, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { ScreenRecordingsView } from './ScreenRecordingsView';
export function TopToolbarRecordModal({ caseEntity, isScreenRecordingsViewOpen, closeScreenRecordingsView, }) {
    return (React.createElement(Modal, { open: isScreenRecordingsViewOpen, onClose: closeScreenRecordingsView, keepMounted: false },
        React.createElement(Base, null,
            React.createElement(ModalHeader, { title: intl.t('explore:caseView.screenRecorder.screenRecordings', 'Screen Recordings') }),
            React.createElement(ModalContent, null,
                React.createElement(ScreenRecordingsView, { caseID: caseEntity.id })))));
}
