var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { observer } from 'mobx-react';
import React from 'react';
import { readFileAsDataURL } from '@corti/browser-file';
import { isImgType } from '@corti/lib/graphs/media';
import { formatBytesToHumanReadable } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card, LinearProgress, Tag, Typography } from 'lib/cortiUI';
import { FileTypePreview } from './FileTypePreview';
function isFileType(f) {
    return f instanceof File;
}
export function MediaLibraryItemRenderer(props) {
    const { title, subtitle, file, onSelect, isSelected } = props, cardProps = __rest(props, ["title", "subtitle", "file", "onSelect", "isSelected"]);
    const [supertype, typeFromMimeType] = file.type.split('/');
    const imageInfo = isFileType(file) ? null : isImgType(file) ? file.imageInfo : null;
    return (React.createElement(MediaLibraryItem, Object.assign({ title: title !== null && title !== void 0 ? title : file.name, subtitle: subtitle
            ? subtitle
            : imageInfo
                ? `${imageInfo.width} x ${imageInfo.height} px`
                : formatBytesToHumanReadable(file.size), type: typeFromMimeType, onSelect: onSelect, isSelected: isSelected }, cardProps), supertype === 'image' ? (React.createElement(ImagePreview, { imgUrl: isFileType(file)
            ? file
            : isImgType(file)
                ? file.getThumbnailURL({ maxWidth: 240 * devicePixelRatio })
                : file.url })) : (React.createElement(Base, { height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
        React.createElement(FileTypePreview, { height: "50%", width: "50%", minWidth: 64, mimeType: file.type })))));
}
function MediaLibraryItem(props) {
    const { title, subtitle, type, onSelect, isSelected, children, style } = props, cardProps = __rest(props, ["title", "subtitle", "type", "onSelect", "isSelected", "children", "style"]);
    return (React.createElement(Card, Object.assign({ style: Object.assign({ boxShadow: isSelected ? '0 0 0 3px #578cff' : undefined }, style), position: "relative", p: 6, display: "flex", flexDirection: "column", onClick: () => onSelect === null || onSelect === void 0 ? void 0 : onSelect() }, cardProps),
        React.createElement(Base, { flex: 1, width: '100%', overflow: "hidden" }, children),
        React.createElement(Base, { mt: 4 },
            React.createElement(Typography, { noWrap: true, variant: "subtitle2", color: "default" }, title || '\u200B'),
            React.createElement(Base, { display: "flex", alignItems: "center", flexWrap: "wrap", gap: 3 },
                typeof subtitle === 'string' ? (React.createElement(Typography, { noWrap: true, variant: "caption", color: "default", flexGrow: 1, minWidth: 160 }, subtitle || '\u200B')) : (subtitle),
                React.createElement(Tag, { opacity: type ? 1 : 0, text: type || '\u200B' })))));
}
function ImagePreview(props) {
    const theme = useTheme();
    const { imgUrl } = props;
    const [url, setUrl] = React.useState(null);
    React.useEffect(() => {
        if (typeof imgUrl === 'string') {
            setUrl(imgUrl);
            return;
        }
        void readFileAsDataURL(imgUrl).then((r) => setUrl(r));
    }, [imgUrl]);
    return (React.createElement(Base, { position: "relative", height: "100%", width: "100%", background: `repeating-conic-gradient(${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.05)'} 0% 25%, ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0.2)'} 0% 50%)
      50% / 12px 12px` },
        React.createElement("img", { className: css({ width: '100%', height: '100%', objectFit: 'contain' }), src: url !== null && url !== void 0 ? url : undefined })));
}
export const UnfinishedMediaLibraryItem = observer(function UnfinishedMediaLibraryItem(props) {
    const { request } = props, rest = __rest(props, ["request"]);
    return (React.createElement(MediaLibraryItemRenderer, Object.assign({}, rest, { disabled: true, file: request.file, subtitle: React.createElement(LinearProgress, { variant: "determinate", value: (request.progress / request.total) * 100, style: {
                flex: 1,
            } }) })));
});
