import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import React from 'react';
import { intl, useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Button, Dialog, IconButton, ListItemButton, ListItemText, Menu, MenuItem, } from 'lib/cortiUI';
import { AddUpdateUserRoleForm } from './AddUpdateUserRoleForm';
export function UserRoleListItem(props) {
    const { userRole, onClick } = props;
    const theme = useTheme();
    const { t } = useTranslation('team');
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [showEditUserRoleDialog, setShowEditUserRoleDialog] = React.useState(false);
    const [showDeleteUserRoleDialog, setShowDeleteUserRoleDialog] = React.useState(false);
    const closeModal = (e) => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        setShowEditUserRoleDialog(false);
        setShowDeleteUserRoleDialog(false);
    };
    const handleDeleteRole = async () => {
        const { error } = await repositories.roles.deleteRole(userRole.id);
        if (!error) {
            closeModal();
        }
    };
    return (React.createElement(ListItemButton, { disableTouchRipple: true, onClick: () => { var _a; return (_a = onClick === null || onClick === void 0 ? void 0 : onClick(userRole)) !== null && _a !== void 0 ? _a : setShowEditUserRoleDialog(true); }, classes: {
            root: css({
                backgroundColor: theme.palette.background.card,
                borderRadius: theme.shape.borderRadius,
                '&:not(:last-child)': {
                    marginBottom: theme.space[2],
                },
            }),
        } },
        React.createElement(ListItemText, null, userRole.name),
        !userRole.isUsed && (React.createElement(IconButton, { "data-cy": "user-roles-table-row-action-btn", className: css({ marginLeft: 'auto' }), icon: React.createElement(MoreHorizRoundedIcon, null), onClick: (e) => {
                e.stopPropagation();
                setMenuAnchorEl(e.currentTarget);
            } })),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), anchorEl: menuAnchorEl, onClose: () => setMenuAnchorEl(null) },
            React.createElement(MenuItem, { "data-cy": "delete-user-role-btn", onClick: (e) => {
                    e.stopPropagation();
                    setShowDeleteUserRoleDialog(true);
                    setMenuAnchorEl(null);
                } }, t('teamsView.table.row.delete', 'Delete'))),
        showEditUserRoleDialog && (React.createElement(Dialog, { title: intl.t('team:userRole.editUserRole', 'Edit user role'), open: showEditUserRoleDialog, onClose: (e) => {
                closeModal(e);
            } },
            React.createElement(AddUpdateUserRoleForm, { userRole: userRole, onSubmit: () => closeModal() }))),
        React.createElement(Dialog, { title: intl.t('team:userRole.deleteUserRoleTitle', 'Permanently delete user role?'), helperText: intl.t('team:userRole.deleteUserRoleMessage', 'You are about to permanently delete the user role. The action cannot be undone.'), open: showDeleteUserRoleDialog, onClose: (e) => {
                closeModal(e);
            }, actionButtons: [
                React.createElement(Button, { variant: "text", color: "error", onClick: (e) => closeModal(e) }, t('deleteTeamModal.cancelBtn', 'Cancel')),
                React.createElement(Button, { "data-cy": "delete-user-role-dialog-delete-btn", color: "error", onClick: (e) => {
                        void handleDeleteRole();
                        closeModal(e);
                    } }, t('deleteTeamModal.saveBtn', 'Delete')),
            ] })));
}
