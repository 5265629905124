import { observer } from 'mobx-react';
import React from 'react';
import { Base } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { Sidepanel, TwoColGridLayout } from 'lib/graphEditor/ui';
import { GlobalSearchPanel } from 'lib/graphEditor/views/GlobalSearchPanel';
import { CanvasRenderer } from './CanvasRenderer';
import { NodeInfoPanel } from './NodeInfoPanel';
export const CanvasView = observer(function CanvasView(_) {
    const $rootContainer = React.useRef(null);
    const { editor } = useGraphEditorCtx();
    return (React.createElement(TwoColGridLayout, null,
        React.createElement(Base, { ref: (r) => (editor.$canvasElement = r) },
            React.createElement(CanvasRenderer, { key: editor.state.activeBranchID, branchID: editor.state.activeBranchID })),
        editor.state.activeBranch.selectedNodes.length === 1 && (React.createElement(Sidepanel, null,
            React.createElement(NodeInfoPanel, null))),
        React.createElement(GlobalSearchPanel, { editorWidgetEl: () => $rootContainer.current })));
});
