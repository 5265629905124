import QueryStatsRounded from '@mui/icons-material/QueryStatsRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { TranscriptSearchView } from './TranscriptSearch/TranscriptSearchView';
import { initTranscriptsApiService } from './services';
const mod = {
    id: 'transcript-search',
    title: () => intl.t('transcriptSearchApp:appName', 'Call Search'),
    path: '/transcript-search',
    icon: QueryStatsRounded,
    requiresOrgFeature: 'transcript-queries',
    requiresUserPermission: 'app:transcript_queries',
    viewComponent: () => React.createElement(TranscriptSearchView, null),
    onInit: () => {
        initTranscriptsApiService({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
    },
    navGroupe: 1,
};
export default mod;
