import { omitBy, random } from 'lodash';
const DEFAULT_CONFIG = {
    /**
     * Delay will not go below this floor. If `jitter` is used though, it can still deviate below this value
     */
    minDelay: 1000,
    /**
     * Delay will not go past this ceiling. If `jitter` is used though, it can still deviate above this value
     */
    maxDelay: 10000,
    /**
     * How fast the delay increases with every retry
     */
    growthFactor: 1.5,
    /**
     * A value between 0 and 1
     * 0.1 will result in a small deviations from the actual delay
     * 0.9 will result in big deviation from the actual delay
     */
    jitter: 0.3,
};
export class Backoff {
    constructor(config = {}) {
        Object.defineProperty(this, "retryCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.retryCount = 0;
        this.config = Object.assign(Object.assign({}, DEFAULT_CONFIG), omitBy(config, (v) => v == null));
    }
    incRetryCounter() {
        this.retryCount++;
    }
    reset() {
        this.retryCount = 0;
    }
    getNextDelay() {
        let delay = Math.min(this.config.minDelay * Math.pow(this.config.growthFactor, this.retryCount), this.config.maxDelay);
        // we accept jitter as any number, but will effectely convert to a value in the range of 0..1
        let normalizedJitter = Math.abs(this.config.jitter % 1);
        // apply randomness
        if (normalizedJitter !== 0 && normalizedJitter !== 1) {
            const calculatedJitter = random(0, normalizedJitter);
            const deviation = delay * calculatedJitter;
            delay = random(true) < 0.5 ? delay - deviation : delay + deviation;
        }
        return Math.abs(Math.round(delay));
    }
}
