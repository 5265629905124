/**
 * API service to exchange files with the backend
 */
export class FilesService {
    constructor(fetcher, auth) {
        Object.defineProperty(this, "auth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: auth
        });
        Object.defineProperty(this, "fetcher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.fetcher = fetcher;
    }
    async uploadFile(input) {
        const data = new FormData();
        data.append('file', input.file);
        data.append('mimeType', input.file.type);
        data.append('size', input.file.size.toString());
        data.append('name', input.file.name);
        const res = await this.fetcher({
            method: 'post',
            url: '/files',
            data,
            onUploadProgress: input.onUploadProgress,
        });
        return res.data;
    }
    /**
     * Generates URL to direct access of the file. Works with any type of file
     * @param fileID
     * @param options.attachmentFileName - will add Content-Disposition header to the response with the provided filename
     * or if boolean provided - server will return the file with it's original name
     */
    getFileContentsURL(fileID, options = {}) {
        const url = new URL(`${this.fetcher.defaults.baseURL}/files/${fileID}`);
        url.searchParams.append('token', this.auth.getAuthToken());
        if (options.attachmentFileName) {
            url.searchParams.append('filename', typeof options.attachmentFileName === 'string' ? options.attachmentFileName : 'true');
        }
        return url.toString();
    }
    /**
     * Generates URL to direct access of the **image** file and applies provided transformations on the server side
     */
    getImageContentsURL(fileID, transforms = {}) {
        if (Object.keys(transforms).length === 0) {
            return this.getFileContentsURL(fileID);
        }
        const url = new URL(`${this.fetcher.defaults.baseURL}/files/${fileID}/image`);
        url.searchParams.append('token', this.auth.getAuthToken());
        if (transforms.maxWidth != null) {
            url.searchParams.append('maxWidth', transforms.maxWidth.toString());
        }
        if (transforms.maxHeight != null) {
            url.searchParams.append('maxHeight', transforms.maxHeight.toString());
        }
        if (transforms.quality != null) {
            url.searchParams.append('quality', transforms.quality.toString());
        }
        return url.toString();
    }
    /**
     * @deprecated
     */
    async uploadOrganizationFileV0_deprecated(organizationID, file, onUploadProgress) {
        const url = `/clients/files?organization_id=${organizationID}`;
        const data = new FormData();
        data.append('file', file);
        const res = await this.fetcher({
            method: 'post',
            url,
            data,
            onUploadProgress,
        });
        return res.data;
    }
    async uploadCaseAudioFile(file, metadata, onUploadProgress) {
        const url = '/cases/upload/audio';
        const data = new FormData();
        data.append('file', file);
        if (metadata === null || metadata === void 0 ? void 0 : metadata.caseID) {
            data.append('case_id', metadata.caseID);
        }
        if (metadata === null || metadata === void 0 ? void 0 : metadata.timeStamp) {
            data.append('timestamp', metadata.timeStamp);
        }
        if (metadata === null || metadata === void 0 ? void 0 : metadata.protocolName) {
            data.append('protocol_name', metadata.protocolName);
        }
        const res = await this.fetcher({
            method: 'post',
            url,
            data,
            onUploadProgress,
        });
        return res.data;
    }
    async downloadFile(url) {
        const res = await this.fetcher.get(url, {
            transformResponse: (data) => data,
            responseType: 'blob',
        });
        return res.data;
    }
}
