import React from 'react';
import { formatDateTime } from '@corti/date';
import { Tag } from 'lib/cortiUI/components/Tag';
import { isDateType, isObjectType } from './utils';
export function DefaultMultiValueRenderer(props) {
    let parsedValue = props.value;
    if (isObjectType(parsedValue)) {
        parsedValue = parsedValue.value;
    }
    if (isDateType(parsedValue)) {
        parsedValue = formatDateTime(parsedValue);
    }
    return React.createElement(Tag, { variant: "pill", text: parsedValue.toString() });
}
