import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import React from 'react';
import { useHistory } from 'react-router';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { EnvIndicator } from 'browser/components';
import { coreStore } from 'browser/stores';
import { Base, ButtonBase, CortiLogo, IconButton, List, ListItem, Typography } from 'lib/cortiUI';
import { NavListItemRenderer } from './NavListItemRenderer';
import { CLOSED_SIZE, COLLAPSED_SIZE, EXPANDED_SIZE } from './constants';
const EXPAND_BUTTON_TOP_OFFSET = 54;
const EXPAND_BUTTON_OPEN_LEFT_OFFSET = '190px';
const EXPAND_BUTTON_CLOSED_LEFT_OFFSET = '54px';
export const NavSidebar = ({ navItems = [], openSpeed = 200, isSmallScreen, isOpen, onOpen, onClose, }) => {
    var _a;
    const [firstGroupContainerHeight, setFirstGroupContainerHeight] = React.useState(0);
    const _navItems = [...navItems];
    const lastGroup = (_a = _navItems.pop()) !== null && _a !== void 0 ? _a : [];
    const apiInfo = coreStore === null || coreStore === void 0 ? void 0 : coreStore.remoteRequest.apiInfo;
    const theme = useTheme();
    const history = useHistory();
    React.useEffect(() => {
        var _a;
        const height = (_a = document.getElementById('first-group')) === null || _a === void 0 ? void 0 : _a.offsetHeight;
        if (height) {
            setFirstGroupContainerHeight(height);
        }
    }, []);
    const onClickHandler = (item) => {
        var _a;
        (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
        item.path && history.push(item.path);
    };
    const getSidebarWidth = () => {
        if (isSmallScreen) {
            return isOpen ? EXPANDED_SIZE : CLOSED_SIZE;
        }
        return isOpen ? EXPANDED_SIZE : COLLAPSED_SIZE;
    };
    return (React.createElement(Base, { "data-cy": "navigation-side-bar", className: css(getScrollerCss({ theme }), {
            gridArea: 'navSidebar',
            width: getSidebarWidth(),
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[16],
            whiteSpace: 'nowrap',
            transition: `all ${openSpeed}ms ease-in-out`,
            zIndex: theme.zIndex.drawer + 5,
            display: 'flex',
            flexDirection: 'column',
            // styles override needed to not overlap expand button with scroll
            '&::-webkit-scrollbar': {
                width: 0,
            },
        }) },
        !isSmallScreen && (React.createElement(ButtonBase, { "data-cy": "toggle-sidebar-button", onClick: isOpen ? onClose : onOpen, className: css({
                position: 'absolute',
                top: firstGroupContainerHeight + EXPAND_BUTTON_TOP_OFFSET,
                left: isOpen ? EXPAND_BUTTON_OPEN_LEFT_OFFSET : EXPAND_BUTTON_CLOSED_LEFT_OFFSET,
                zIndex: theme.zIndex.drawer + 10,
                transition: `left ${openSpeed}ms ease-in-out`,
                cursor: 'pointer',
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.action.outlinedBorder}`,
                borderRadius: '50%',
                '&:hover': {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            }) }, isOpen ? (React.createElement(KeyboardArrowLeftRoundedIcon, { fontSize: "small" })) : (React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "small" })))),
        React.createElement(List, { className: css({
                borderBottom: `1px solid ${theme.palette.background.divider}`,
            }) },
            React.createElement(ListItem, { "data-cy": "corti-logo", classes: {
                    root: css({
                        background: 'inherit',
                        padding: '16px 20px 15px 20px',
                    }),
                } },
                React.createElement(Base, { className: css({ width: '24px', marginRight: '13px', cursor: 'pointer' }), onClick: onOpen },
                    React.createElement(CortiLogo, null)),
                React.createElement(Typography, { variant: "h6", className: css({
                        color: theme.palette.text.primary,
                        visibility: isOpen ? 'visible' : 'hidden',
                        transition: `all ${openSpeed}ms ease-in-out`,
                    }) }, 'Corti'),
                isSmallScreen && (React.createElement(IconButton, { size: "small", icon: React.createElement(ArrowBackIosNewRoundedIcon, { fontSize: "small" }), onClick: onClose, className: css({
                        marginLeft: 'auto',
                    }) })))),
        React.createElement(List, { classes: { root: css({ display: 'flex', flexFlow: 'column', height: '100%' }) } },
            _navItems.map((navItemGroup, idx) => {
                return (React.createElement(List, { id: idx === 0 ? 'first-group' : undefined, key: idx, className: css({
                        padding: '8px 4px',
                        '&:not(:first-child)': {
                            borderTop: `1px solid ${theme.palette.background.divider}`,
                        },
                    }) }, navItemGroup.map((item, idx) => (React.createElement(NavListItemRenderer, { key: item.text, isOpen: isOpen, item: item, idx: idx, onClick: onClickHandler })))));
            }),
            React.createElement(Base, { className: css({
                    marginTop: 'auto',
                }) },
                apiInfo && (React.createElement("div", { className: css({
                        padding: '16px 20px',
                        display: 'flex',
                        alignItems: 'center',
                        visibility: isOpen ? 'visible' : 'hidden',
                        transition: `all ${openSpeed}ms ease-in-out`,
                    }) },
                    React.createElement(Base, null,
                        React.createElement(EnvIndicator, { type: apiInfo.isPrimary ? 'success' : 'warning', tooltipText: apiInfo.isPrimary
                                ? intl.t('envIndicator.prodEnv', 'Production environment.')
                                : intl.t('envIndicator.nonProdEnv', 'Non production environment.') }, apiInfo.name || '-')))),
                React.createElement(List, { className: css({
                        padding: '8px 4px',
                        borderTop: `1px solid ${theme.palette.background.divider}`,
                    }) }, lastGroup.map((item, idx) => (React.createElement(NavListItemRenderer, { key: item.text, isOpen: isOpen, item: item, idx: idx, onClick: onClickHandler }))))))));
};
