import React from 'react';
import { Base, Card, Skeleton } from 'lib/cortiUI';
import { AnswerLoader } from './Answer';
export const QuestionsLoader = () => {
    const cardArr = [0, 1, 2, 3, 4];
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 }, cardArr.map((index) => (React.createElement(Card, { key: index, px: 6, py: 5 },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Skeleton, { variant: "text", height: 24, width: "80%" }),
                React.createElement(Skeleton, { variant: "circular", height: 25, width: 25 })),
            React.createElement(AnswerLoader, null)))))));
};
