import userSessionStorage from '@corti/lib/userSessionStorage';
export const useParametersCache = () => {
    const LOCAL_STORAGE_PARAMETERS_KEY = `missionControl:parameters`;
    function persistParameters(parameters) {
        userSessionStorage.setItem(LOCAL_STORAGE_PARAMETERS_KEY, JSON.stringify(parameters));
    }
    function getPersistedParameters() {
        var _a;
        const persistedParameters = JSON.parse((_a = userSessionStorage.getItem(LOCAL_STORAGE_PARAMETERS_KEY)) !== null && _a !== void 0 ? _a : 'null');
        if (!persistedParameters)
            return null;
        return Object.assign(Object.assign({}, persistedParameters), { dateRange: {
                startDate: new Date(persistedParameters.dateRange.startDate),
                endDate: new Date(persistedParameters.dateRange.endDate),
            } });
    }
    function clearCache() {
        userSessionStorage.removeItem(LOCAL_STORAGE_PARAMETERS_KEY);
    }
    return {
        persistParameters,
        getPersistedParameters,
        clearCache,
    };
};
