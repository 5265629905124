import WS from 'reconnecting-websocket';
import urlParse from 'url-parse';
import { config } from 'core/configuration/browser';
function parseMessage(event) {
    let parsedMessage;
    try {
        parsedMessage = JSON.parse(event.data);
    }
    catch (err) {
        throw new Error('Unexpected data received from websocket');
    }
    if (isValidMessage(parsedMessage)) {
        return parsedMessage;
    }
    else {
        throw new Error(`Websocket message type is in the wrong format: ${event.data}`);
    }
}
function isValidMessage(message) {
    if (typeof message !== 'object' || message == null) {
        return false;
    }
    if (Reflect.has(message, 'err') || Reflect.has(message, 'msg')) {
        return true;
    }
    return false;
}
export class WsService {
    constructor(options) {
        Object.defineProperty(this, "ws", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "registerEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.ws.addEventListener('open', () => {
                    this.sendToWS({
                        operation: 'start',
                        id: this.options.extension,
                        token: this.options.token,
                    });
                });
                this.ws.addEventListener('message', (event) => {
                    const parsed = parseMessage(event);
                    // for now there can only be one type of error (authentication or feature not available)
                    // which is treated as critical and connection is closed
                    if ('err' in parsed) {
                        this.ws.close();
                        return;
                    }
                });
            }
        });
        Object.defineProperty(this, "sendToWS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                this.ws.send(JSON.stringify(data));
            }
        });
        this.options = options;
        const url = urlParse(config.getConfig().apiHost);
        const wsProtocol = url.protocol === 'https:' ? 'wss' : 'ws';
        const host = config.getConfig().apiHost.replace(/https?/, wsProtocol);
        this.ws = new WS(`${host}/subscriptions${options.endpoint}`);
        this.registerEvents();
    }
    close() {
        if (this.ws) {
            this.ws.close();
        }
    }
}
