import { endOfDay, endOfHour, endOfMinute, endOfMonth, endOfSecond, endOfWeek, endOfYear, startOfDay, startOfHour, startOfMinute, startOfMonth, startOfSecond, startOfWeek, startOfYear, subDays, subHours, subMinutes, subMonths, subSeconds, subWeeks, subYears, } from '@corti/date';
import { Formatters } from '@corti/strings';
// This utility has limitations so it should not be used to calculate date ranges of complex duration strings.
// It should be used with relative date ranges defined in RelativeDateRangePicker component only.
export function relativeToAbsolute(dateRange) {
    const duration = Formatters.durationStringToDuration(dateRange.durationString);
    if (!duration)
        return;
    let startDate = undefined;
    let endDate = undefined;
    if (dateRange.isCurrentPeriodIncluded) {
        startDate = new Date();
        endDate = new Date();
        if (duration.years) {
            startDate = subYears(startOfYear(startDate), duration.years - 1);
        }
        if (duration.months) {
            startDate = subMonths(startOfMonth(startDate), duration.months - 1);
        }
        if (duration.weeks) {
            startDate = subWeeks(startOfWeek(startDate), duration.weeks - 1);
        }
        if (duration.days) {
            startDate = subDays(startOfDay(startDate), duration.days - 1);
        }
        if (duration.hours) {
            startDate = subHours(startOfHour(startDate), duration.hours - 1);
        }
        if (duration.minutes) {
            startDate = subMinutes(startOfMinute(startDate), duration.minutes - 1);
        }
        if (duration.seconds) {
            startDate = subSeconds(startOfSecond(startDate), duration.seconds - 1);
        }
        return {
            start: startDate,
            end: endDate,
        };
    }
    if (duration.years) {
        endDate = !endDate ? endOfYear(subYears(new Date(), 1)) : endDate;
        startDate = subYears(startOfYear(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.years - 1);
    }
    if (duration.months) {
        endDate = !endDate ? endOfMonth(subMonths(new Date(), 1)) : endDate;
        startDate = subMonths(startOfMonth(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.months - 1);
    }
    if (duration.weeks) {
        endDate = !endDate ? endOfWeek(subWeeks(new Date(), 1)) : endDate;
        startDate = subWeeks(startOfWeek(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.weeks - 1);
    }
    if (duration.days) {
        endDate = !endDate ? endOfDay(subDays(new Date(), 1)) : endDate;
        startDate = subDays(startOfDay(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.days - 1);
    }
    if (duration.hours) {
        endDate = !endDate ? endOfHour(subHours(new Date(), 1)) : endDate;
        startDate = subHours(startOfHour(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.hours - 1);
    }
    if (duration.minutes) {
        endDate = !endDate ? endOfMinute(subMinutes(new Date(), 1)) : endDate;
        startDate = subMinutes(startOfMinute(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.minutes - 1);
    }
    if (duration.seconds) {
        endDate = !endDate ? endOfSecond(subSeconds(new Date(), 1)) : endDate;
        startDate = subSeconds(startOfSecond(startDate !== null && startDate !== void 0 ? startDate : endDate), duration.seconds - 1);
    }
    if (startDate && endDate) {
        return {
            start: startDate,
            end: endDate,
        };
    }
}
