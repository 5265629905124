import TuneIcon from '@mui/icons-material/Tune';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { Button, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../../CaseReviewRequestsContext';
import { AdvancedFilterPanel } from './AdvancedFilterPanel';
export const AdvancedFilterPicker = observer(function AdvancedFilterPicker(props) {
    const { onChange } = props;
    const { model } = useCaseReviewRequestsContext();
    const buttonRef = React.useRef();
    const [isOpen, setIsOpen] = React.useState(false);
    function handleOpen() {
        setIsOpen(true);
    }
    function handleClose() {
        setIsOpen(false);
    }
    function handleApply() {
        handleClose();
        onChange();
    }
    function getTotalFilterCount() {
        return (model.filter.status.length +
            model.filter.callTakerIDs.length +
            model.filter.callTakerTeams.length +
            model.filter.assigneeIDs.length +
            model.filter.assigneeTeams.length);
    }
    const totalFilterCount = getTotalFilterCount();
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: (r) => (buttonRef.current = r), size: "small", variant: "outlined", color: totalFilterCount > 0 ? 'primary' : undefined, disabled: props.loading, onClick: handleOpen, startIcon: React.createElement(TuneIcon, { fontSize: "small" }) }, `${intl.t('caseReviewRequestsModule:advancedFilters.filterTitle', 'All filters')} ${totalFilterCount > 0 ? `(${totalFilterCount})` : ''}`),
        React.createElement(Modal, { open: isOpen, onClose: handleClose },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: intl.t('caseReviewRequestsModule:advancedFilters.filterTitle', 'All filters') }),
                React.createElement(ModalContent, { "data-cy": "advanced-filters" },
                    React.createElement(AdvancedFilterPanel, { onApply: handleApply }))))));
});
