var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { Base, ButtonBase, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from '../core/view';
export function MainNavigationPanel(_props) {
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    return (React.createElement(Base, { display: "grid", gridGap: 5, p: 6 },
        React.createElement(UnstyledLink, { to: `/graph` }, ({ isActive, navigate }) => (React.createElement(MainNavigationItem, { active: isActive, "data-cy": "graph-button", onClick: navigate }, t('mainNav.graph', 'Graph')))),
        editor.checklistSettings.enabled && (React.createElement(UnstyledLink, { to: "/checklists" }, ({ isActive, navigate }) => (React.createElement(MainNavigationItem, { active: isActive, "data-cy": "checklist-library-button", onClick: navigate }, t('mainNav.checklists', 'Checklists'))))),
        React.createElement(UnstyledLink, { to: "/components" }, ({ isActive, navigate }) => (React.createElement(MainNavigationItem, { active: isActive, "data-cy": "component-library-button", onClick: navigate }, t('mainNav.componentLib', 'Component Library')))),
        React.createElement(UnstyledLink, { to: "/facts" }, ({ isActive, navigate }) => (React.createElement(MainNavigationItem, { active: isActive, "data-cy": "fact-library-button", onClick: navigate }, t('mainNav.factLib', 'Fact Library')))),
        React.createElement(UnstyledLink, { to: "/preview" }, ({ isActive, navigate }) => (React.createElement(MainNavigationItem, { active: isActive, onClick: navigate }, t('mainNav.graphPreview', 'Graph Preview'))))));
}
function MainNavigationItem(props) {
    const { active, children } = props, rest = __rest(props, ["active", "children"]);
    return (React.createElement(ButtonBase, Object.assign({ justifyContent: "start" }, rest),
        React.createElement(Typography, { variant: active ? 'subtitle2' : 'body2', color: active ? 'default' : 'secondaryText' }, children)));
}
function UnstyledLink(props) {
    const { to, activeOnlyWhenExact } = props;
    const history = useHistory();
    const renderFn = 'render' in props ? props.render : props.children;
    return (React.createElement(Route, { path: to, exact: activeOnlyWhenExact, children: ({ match }) => renderFn({ isActive: !!match, navigate: () => history.push(to) }) }));
}
