var _a;
import Mousetrap from 'mousetrap';
import React from 'react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from 'lib/cortiUI';
import { useButtons } from './buttonsContext';
import { useToolbar } from './context';
const isMac = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent.includes('Mac OS');
function formatKeyboardShortcuts(shortcut) {
    if (!shortcut)
        return;
    const modifierKeys = [];
    if (isMac) {
        modifierKeys.push([/shift\+/, '⇧'], [/command|mod\+/, '⌘'], [/ctrl\+/, '⌃'], [/alt\+/, '⌥']);
    }
    if (modifierKeys.length) {
        for (const [regex, symbol] of modifierKeys) {
            if (regex.test(shortcut)) {
                return shortcut.replace(regex, symbol).toLocaleUpperCase();
            }
            continue;
        }
    }
    return shortcut.split('+').join(' + ');
}
export const ToolbarButton = ({ tooltip, onClick, id, icon, keyboardShortcut, }) => {
    const { isMenuItem, handleClose } = useButtons();
    const { openToolID, setOpenToolID } = useToolbar();
    const theme = useTheme();
    const isSelected = openToolID === id;
    const keyBinder = React.useRef(null);
    React.useEffect(() => {
        var _a;
        if (keyboardShortcut) {
            if (keyboardShortcut.disabled) {
                (_a = keyBinder.current) === null || _a === void 0 ? void 0 : _a.reset();
                return;
            }
            keyBinder.current = new Mousetrap();
            keyBinder.current.bind(keyboardShortcut.open, (event) => {
                event.preventDefault();
                toggleToolPanelHandler();
            });
        }
        return () => {
            var _a;
            (_a = keyBinder.current) === null || _a === void 0 ? void 0 : _a.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.open, keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.disabled, isSelected]);
    const toggleToolPanelHandler = () => {
        setOpenToolID(isSelected ? null : id);
    };
    const onClickHandler = (ev) => {
        ev === null || ev === void 0 ? void 0 : ev.stopPropagation();
        toggleToolPanelHandler();
        handleClose();
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    const formattedShortcut = formatKeyboardShortcuts(keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.open);
    return isMenuItem ? (React.createElement(MenuItem, { disableRipple: true, selected: isSelected, onClick: onClickHandler },
        React.createElement(ListItemIcon, null, icon),
        React.createElement(ListItemText, null, tooltip),
        formattedShortcut && (React.createElement(Typography, { variant: "body2", color: "secondaryText" }, formattedShortcut)))) : (React.createElement(Tooltip, { title: formattedShortcut ? `${tooltip} (${formattedShortcut})` : tooltip, disableInteractive: true },
        React.createElement(IconButton, { "data-cy": `toolbar-icon-button:${id}`, onClick: onClickHandler, icon: icon, className: css({
                color: isSelected ? theme.palette.primary.main : theme.palette.action.active,
                '&:hover': {
                    backgroundColor: isSelected
                        ? transparentize(0.96, theme.palette.primary.main)
                        : theme.palette.action.hover,
                },
            }) })));
};
