import { intl } from '@corti/i18n';
import { ICaseReviewRequestStatus } from '@corti/lib/coreApiService';
export function getCaseReviewRequestStatus(request) {
    if (request.submission && !request.submission.dispatcherAcknowledgedAt) {
        return ICaseReviewRequestStatus.PendingAcknowledgement;
    }
    if (request.submission && request.submission.dispatcherAcknowledgedAt) {
        return ICaseReviewRequestStatus.Completed;
    }
    return ICaseReviewRequestStatus.PendingReview;
}
export function getCaseReviewRequestStatusDescriptor(status, theme) {
    switch (status) {
        case ICaseReviewRequestStatus.PendingReview: {
            return {
                status,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.pendingReview', 'To Review'),
                color: theme.palette.primary.dark,
            };
        }
        case ICaseReviewRequestStatus.PendingAcknowledgement: {
            return {
                status,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.pendingAcknowledgement', 'To Acknowledge'),
                color: theme.palette.warning.dark,
            };
        }
        case ICaseReviewRequestStatus.Completed: {
            return {
                status,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.completed', 'Completed'),
                color: theme.palette.success.dark,
            };
        }
    }
}
