import axios from 'axios';
import { ScreenRecorderError } from './ScreenRecorderError';
const REST_API_ENDPOINT = '/screen-recording-service/api/v1';
export function initRestClient(config) {
    const restClient = axios.create({
        baseURL: config.host + REST_API_ENDPOINT,
        headers: {
            Authorization: config.authToken,
        },
    });
    restClient.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        throw createError(error);
    });
    return restClient;
}
function createError(error) {
    if (error.response) {
        return ScreenRecorderError.ServerInternal(error);
    }
    if (error.request) {
        return ScreenRecorderError.Unavailable(error);
    }
    return ScreenRecorderError.Internal(error);
}
