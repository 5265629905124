import React from 'react';
import { getTemplateById } from '@corti/mission-control-api';
import { Base, Card } from 'lib/cortiUI';
import { useSaving, useTemplate } from '../context';
import { Actions } from './Actions';
import { ConfigurationLoader } from './ConfigurationLoader';
import { SavingIndicator } from './SavingIndicator';
import { Scoring } from './Scoring';
import { TitleInput } from './TitleInput';
export const Configuration = () => {
    const { setIsScoringEnabled, templateID } = useTemplate();
    const { isSaving } = useSaving();
    const [thresholds, setThresholds] = React.useState({
        has_partial_threshold: false,
        has_threshold: false,
        threshold: 0,
        partial_threshold: 0,
    });
    const [isTemplateLoading, setIsTemplateLoading] = React.useState(false);
    const initialNameRef = React.useRef();
    React.useEffect(() => {
        void fetchTemplate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateID]);
    async function fetchTemplate() {
        setIsTemplateLoading(true);
        try {
            const { name, has_partial_threshold, has_threshold, threshold, partial_threshold, scoring_enabled, } = await getTemplateById(templateID);
            setIsScoringEnabled(scoring_enabled);
            setThresholds({
                has_partial_threshold,
                has_threshold,
                threshold,
                partial_threshold,
            });
            initialNameRef.current = name;
        }
        catch (error) {
            console.error('failed to fetch template:', error);
        }
        finally {
            setIsTemplateLoading(false);
        }
    }
    if (isTemplateLoading) {
        return React.createElement(ConfigurationLoader, null);
    }
    return (React.createElement(Card, { p: 8, display: "flex", flexDirection: "column", gap: 7 },
        React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(TitleInput, { initialName: initialNameRef.current, disabled: isTemplateLoading }),
            React.createElement(Base, { display: "flex", alignItems: "center", gap: 4 },
                React.createElement(SavingIndicator, { isSaving: isSaving }),
                React.createElement(Actions, { disabled: isTemplateLoading || isSaving, templateID: templateID }))),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
            React.createElement(Scoring, { thresholdsData: thresholds, updateThresholds: setThresholds }))));
};
