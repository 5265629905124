import * as GQL from './grapqhl';
import { dataTransformer } from './transformers';
export class TranscriptsService {
    constructor(fetcher, gqlClient) {
        Object.defineProperty(this, "fetcher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: fetcher
        });
        Object.defineProperty(this, "gqlClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: gqlClient
        });
        Object.defineProperty(this, "createTranscriptQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const url = `/transcript-queries`;
                const data = dataTransformer.fromTranscriptQueryCreateUpdateInput(input);
                const res = await this.fetcher.post(url, data);
                return dataTransformer.toTranscriptQuery(res.data);
            }
        });
        Object.defineProperty(this, "updateTranscriptQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (transcriptQueryID, input) => {
                const url = `/transcript-queries/${transcriptQueryID}`;
                const data = dataTransformer.fromTranscriptQueryCreateUpdateInput(input);
                const res = await this.fetcher.put(url, data);
                return dataTransformer.toTranscriptQuery(res.data);
            }
        });
        Object.defineProperty(this, "getOrgTranscriptQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const url = `/transcript-queries`;
                const res = await this.fetcher.get(url);
                return res.data.data.map(dataTransformer.toTranscriptQuery);
            }
        });
        Object.defineProperty(this, "getTranscriptQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (transcriptQueryID) => {
                const url = `/transcript-queries/${transcriptQueryID}`;
                const res = await this.fetcher.get(url);
                return dataTransformer.toTranscriptQuery(res.data);
            }
        });
        Object.defineProperty(this, "deleteTranscriptQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (transcriptQueryID) => {
                const url = `/transcript-queries/${transcriptQueryID}`;
                await this.fetcher.delete(url);
            }
        });
        Object.defineProperty(this, "exportTranscriptQueryCallsCSV", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const url = `/exports/transcript-query-calls`;
                const data = dataTransformer.fromExportTQCallsInput(input);
                const res = await this.fetcher.post(url, data);
                return res.data;
            }
        });
        Object.defineProperty(this, "getTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (organizationID) => {
                const res = await this.gqlClient.query({
                    query: GQL.getTeamsQuery,
                    variables: {
                        organizationID,
                    },
                });
                return res.data.teams;
            }
        });
        Object.defineProperty(this, "getCoreCallsMetadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (callIDs) => {
                const res = await this.gqlClient.query({
                    query: GQL.getCallsQuery,
                    variables: {
                        id: callIDs,
                    },
                });
                return res.data.calls;
            }
        });
    }
}
