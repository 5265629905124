import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { theme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Box, Button, TextField, Typography } from 'lib/cortiUI';
import * as GQL from '../graphql';
export function CreateTeamView(props) {
    const { onSuccess } = props;
    const auth = useAuth();
    const [createTeam] = useMutation(GQL.createTeamMutation);
    const onSubmit = async (data) => {
        await createTeam({
            variables: {
                team: {
                    name: data.name,
                    description: data.description,
                },
                organizationID: auth.organization.id,
            },
        });
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
    };
    return React.createElement(CommonTeamView, { onSubmit: onSubmit });
}
export function UpdateTeamView(props) {
    const { teamID, onSuccess } = props;
    const [updateTeam] = useMutation(GQL.updateTeamMutation);
    const query = useQuery(GQL.getTeamQuery, {
        variables: {
            id: teamID,
        },
    });
    const onSubmit = async (data) => {
        await updateTeam({
            variables: {
                input: {
                    id: teamID,
                    name: data.name,
                    description: data.description,
                },
            },
        });
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
    };
    return (React.createElement(CommonTeamView, { initialValues: query.data
            ? {
                name: query.data.team.name,
                description: query.data.team.description,
            }
            : undefined, disabled: Boolean(query.loading || query.error), onSubmit: onSubmit }));
}
const MAX_DESCRIPTION_LENGTH = 160;
function CommonTeamView(props) {
    const { t } = useTranslation('teams');
    const { initialValues, disabled, onSubmit } = props;
    const { handleSubmit, formState, reset, control } = useForm({
        defaultValues: initialValues,
    });
    React.useEffect(() => {
        if (initialValues) {
            reset(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);
    return (React.createElement("form", { "data-cy": "create-update-team-dialog", onSubmit: handleSubmit(onSubmit), className: css({ display: 'flex', flexDirection: 'column', gap: theme.space[6] }) },
        React.createElement(Controller, { name: "name", control: control, rules: {
                required: t('teamForm.fieldRequired', 'This field is required'),
            }, defaultValue: "", render: ({ field, fieldState }) => {
                var _a;
                return (React.createElement(TextField, Object.assign({}, field, { inputRef: field.ref, disabled: disabled, autoComplete: "off", label: t('teamForm.name', 'Name'), error: !!fieldState.error, helperText: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, name: "name" })));
            } }),
        React.createElement(Controller, { name: "description", control: control, rules: { maxLength: MAX_DESCRIPTION_LENGTH }, defaultValue: "", render: ({ field, fieldState }) => {
                var _a;
                return (React.createElement(TextField, Object.assign({}, field, { inputRef: field.ref, multiline: true, disabled: disabled, autoComplete: "off", label: t('teamForm.description', 'Description (optional)'), rows: 3, error: field.value.length > MAX_DESCRIPTION_LENGTH, name: "description", helperText: React.createElement(Typography, { color: fieldState.error ? 'error' : 'secondaryText', variant: "caption" }, `${(_a = field.value.length) !== null && _a !== void 0 ? _a : 0} / ${MAX_DESCRIPTION_LENGTH}`) })));
            } }),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
            React.createElement(Button, { "data-cy": "create-update-team-save-button", type: "submit", variant: "text", color: "primary", disabled: disabled || formState.isSubmitting }, t('save', 'Save')))));
}
