import { observable } from 'mobx';
import { wait } from '@corti/timers';
export function createBaseMediaProvider() {
    return {
        mediaAssets: observable([]),
        async refreshMediaAssets() { },
        async uploadFile(_) {
            await wait(1000);
        },
        resolveMediaAsset(_) {
            return undefined;
        },
    };
}
