import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { SearchApp } from './SearchApp';
export * from './SearchApp';
const mod = {
    id: 'searchApp',
    title: () => intl.t('searchApp:appName', 'Search'),
    path: '/search',
    icon: ManageSearchRoundedIcon,
    requiresOrgFeature: 'mission-control-search-view',
    requiresUserPermission: 'app:mission_control_search',
    viewComponent: () => React.createElement(SearchApp, null),
    navGroupe: 1,
};
export default mod;
