import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Typography } from 'lib/cortiUI';
import { EntityFactory } from '../../utils';
import { useTriageSession } from '../TriageSessionContext';
import { CommentsInput } from './CommentsInput';
import { CommentsList } from './CommentsList';
import { ContainerSection } from './ContainerSection';
const ADD_COMMENT_SECTION_HEIGHT = 190;
export const Comments = observer(function Comments() {
    const { controller } = useTriageSession();
    function createComment(value) {
        controller.comments.create(EntityFactory.createComment(value, controller.currentUser));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { className: css({
                height: `calc(100% - ${ADD_COMMENT_SECTION_HEIGHT}px)`,
            }) }, controller.comments.all.length === 0 ? React.createElement(FallbackView, null) : React.createElement(CommentsList, null)),
        React.createElement(ContainerSection, { showTopSeparator: true, maxHeight: ADD_COMMENT_SECTION_HEIGHT, pt: 8, pb: 6 },
            React.createElement(CommentsInput, { onSubmit: createComment }))));
});
function FallbackView() {
    const theme = useTheme();
    return (React.createElement(Box, { "data-cy": "comments-list-fallback", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" },
        React.createElement(Typography, { className: css({
                color: theme.palette.text.secondary,
            }) }, intl.t('realtime:commentsView.noComments', 'Session has no comments'))));
}
