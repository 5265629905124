import ForkRightIcon from '@mui/icons-material/ForkRight';
import { observer } from 'mobx-react';
import React from 'react';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { SmartTarget } from 'lib/graphEditor/ui';
export const BranchSmartTarget = observer(function BranchSmartTarget(props) {
    const { branchID } = props;
    const { editor } = useGraphEditorCtx();
    const branch = editor.model.getBranchByID(branchID);
    if (!branch) {
        return React.createElement(SmartTarget, { label: '[missing ref]', color: "error", disabled: true, tooltipTitle: branchID });
    }
    return (React.createElement(SmartTarget, { label: branch.name, tooltipTitle: `${branch.name}`, startIcon: React.createElement(ForkRightIcon, { style: { color: 'inherit' } }), onClick: () => {
            editor.state.setActiveBranch(branch.id);
        } }));
});
