import axios from 'axios';
import { CoreApiError } from './errors';
function createError(error) {
    var _a, _b, _c, _d, _e, _f;
    const extra = {
        response: error.response,
        request: error.request,
    };
    if (!error.response) {
        return CoreApiError.Unavailable({ extra });
    }
    const errorInput = {
        extra,
        message: (_b = (_a = error.response.data) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message,
        devMessage: JSON.stringify(extra, null, 2),
    };
    const res = error.response;
    if (res.status === 401) {
        return CoreApiError.Unauthorized(errorInput);
    }
    if (res.status === 404) {
        return CoreApiError.SchemaMismatch(errorInput);
    }
    if (((_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.code) && ((_f = (_e = res.data) === null || _e === void 0 ? void 0 : _e.error) === null || _f === void 0 ? void 0 : _f.message)) {
        return CoreApiError.DomainError(Object.assign(Object.assign({}, errorInput), { domainErrors: [
                {
                    code: res.data.error.code,
                    message: res.data.error.message,
                },
            ] }));
    }
    return CoreApiError.Internal(errorInput);
}
async function handleResponse(res) {
    try {
        if (!res.ok) {
            throw new Error('Invalid status');
        }
        return await res.json();
    }
    catch (_a) {
        return {};
    }
}
export class RestClient {
    constructor(config) {
        Object.defineProperty(this, "REST_API_ENDPOINT", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: '/api/v1.0'
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "axiosClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.config = config;
        this.axiosClient = this.initAxiosClient();
    }
    initAxiosClient() {
        const fetcher = axios.create({
            baseURL: this.config.host + this.REST_API_ENDPOINT,
        });
        fetcher.interceptors.request.use((req) => {
            if (this.config.apiKey && req.headers) {
                req.headers['x-api-key'] = this.config.apiKey;
            }
            return req;
        });
        fetcher.interceptors.response.use((response) => response, (error) => {
            throw createError(error);
        });
        return fetcher;
    }
    setHost(host) {
        this.config.host = host;
        this.axiosClient.defaults.baseURL = host + this.REST_API_ENDPOINT;
    }
    /**
     * Hook to listen to whenever we receive error from our api
     */
    onErrorResponse(cb) {
        const id = this.axiosClient.interceptors.response.use(undefined, (error) => {
            cb(error);
            throw error;
        });
        return () => this.axiosClient.interceptors.response.eject(id);
    }
    async fetchApiInfo() {
        const [info, pVersion] = await Promise.all([
            fetch(`${this.config.host}/info`).then(handleResponse),
            fetch(`${this.config.host}/product-version`).then(handleResponse),
        ]);
        return Object.assign(Object.assign(Object.assign({}, info), pVersion), { url: this.config.host });
    }
}
