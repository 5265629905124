var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { stringifyRichText } from '@corti/richtext';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { ValuePublisher } from 'lib/graphEditor/valuePublisher';
import { BaseFormElementModel } from './BaseFormElementModel';
import { SelectOptionElementModel } from './SelectOptionElementModel';
const DEFAULTS = {
    max: 1,
};
export class SelectElementModel extends BaseFormElementModel {
    constructor(props = {}) {
        super(props);
        Object.defineProperty(this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "max", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSearchable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDropdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "valuePublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (options) => {
                options.forEach((o) => o.setParent(this));
                this.options = options;
            }
        });
        makeObservable(this);
        this.type = SelectElementModel.typename;
        this.valuePublisher = new ValuePublisher();
        runInAction(() => {
            this.options = [];
            this.max = DEFAULTS.max;
            this.isSearchable = false;
            this.isDropdown = false;
        });
    }
    setMulti(val) {
        this.max = val ? undefined : 1;
    }
    get isMulti() {
        return this.max !== 1;
    }
    addOption(input) {
        const option = new SelectOptionElementModel({ initialData: input });
        this.addOptionInternal(option);
        return option;
    }
    addOptionInternal(option) {
        option.setParent(this);
        this.options.push(option);
    }
    removeOption(option) {
        const idx = this.options.indexOf(option);
        if (idx !== -1) {
            this.options.splice(idx, 1);
        }
    }
    setIsSearchable(value) {
        this.isSearchable = value;
    }
    setIsDropdown(value) {
        this.isDropdown = value;
    }
    get valuePublisherItems() {
        const r = new Map();
        r.set(this.id, this);
        this.options.forEach((o) => {
            r.set(o.id, o);
        });
        return r;
    }
    get referenceableItems() {
        const items = new Map();
        this.options.forEach((it) => {
            items.set(it.refItemValue, it);
        });
        items.set(this.refItemValue, this);
        return items;
    }
    get refItemText() {
        return stringifyRichText(this.label) || this.attributes.values.name || `No name ${this.type}`;
    }
    get refItemTarget() {
        return this;
    }
    get refItemValue() {
        return `block:${this.id}`;
    }
    deserialize(input) {
        super.deserialize(input);
        this.max = input.max;
        this.isSearchable = input.isSearchable;
        this.isDropdown = input.isDropdown;
        input.options.forEach((c) => {
            const option = new SelectOptionElementModel({});
            option.deserialize(c);
            this.addOptionInternal(option);
        });
        this.valuePublisher.deserialize(input.valuePublisher);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { options: this.options.map((o) => o.getEncodableData()), max: this.max, isSearchable: this.isSearchable, isDropdown: this.isDropdown, valuePublisher: this.valuePublisher.getEncodableData() });
    }
    copy() {
        const i = super.copy();
        i.valuePublisher = this.valuePublisher.copy();
        i.setOptions(this.options.map((it) => it.copy()));
        i.max = this.max;
        i.isSearchable = this.isSearchable;
        i.isDropdown = this.isDropdown;
        return i;
    }
}
Object.defineProperty(SelectElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'select'
});
Object.defineProperty(SelectElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.selectElement.title', 'Select'),
    })
});
Object.defineProperty(SelectElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (value) => {
        const instance = new SelectElementModel();
        instance.deserialize(value);
        return instance;
    }
});
__decorate([
    observable,
    __metadata("design:type", Array)
], SelectElementModel.prototype, "options", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], SelectElementModel.prototype, "max", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SelectElementModel.prototype, "isSearchable", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SelectElementModel.prototype, "isDropdown", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "setMulti", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "isMulti", null);
__decorate([
    action,
    __metadata("design:type", Object)
], SelectElementModel.prototype, "setOptions", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_a = typeof SelectOptionElementModel !== "undefined" && SelectOptionElementModel) === "function" ? _a : Object)
], SelectElementModel.prototype, "addOption", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof SelectOptionElementModel !== "undefined" && SelectOptionElementModel) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "removeOption", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "setIsSearchable", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "setIsDropdown", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_c = typeof Map !== "undefined" && Map) === "function" ? _c : Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "valuePublisherItems", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "referenceableItems", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "refItemText", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "refItemTarget", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectElementModel.prototype, "refItemValue", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectElementModel.prototype, "copy", null);
ElementSerializer.register(SelectElementModel, 'SelectElementModel');
