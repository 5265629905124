import fuzzysort from 'fuzzysort';
import { deburr, orderBy } from 'lodash';
export function mapOption(option) {
    return {
        id: option.id,
        plainText: option.text.plainText,
        richText: option.text.richText,
    };
}
export function mergeOptions(option, options, limit) {
    const exists = Boolean(options.find((it) => it.id === option.id));
    let result = options;
    if (exists) {
        return result.filter((it) => it.id !== option.id);
    }
    if (limit === undefined) {
        return [...result, option];
    }
    if (limit === 0) {
        return [];
    }
    if (limit === 1) {
        return [option];
    }
    if (limit === options.length) {
        return result;
    }
    return [...result, option];
}
export function isOptionDisabled(option, options, limit) {
    const exists = Boolean(options.find((it) => it.id === option.id));
    if (limit === undefined) {
        return false;
    }
    if (limit === 0) {
        return true;
    }
    if (limit === 1) {
        return false;
    }
    if (limit === options.length) {
        if (exists) {
            return false;
        }
        return true;
    }
    return false;
}
export function search(searchValue, options) {
    const SEARCH_KEY = '__search_key__';
    if (searchValue === '') {
        return options;
    }
    const dSearchValue = deburr(searchValue);
    const dOptions = options.map((it) => (Object.assign(Object.assign({}, it), { __search_key__: deburr(it.plainText) })));
    const results = fuzzysort.go(dSearchValue, dOptions, {
        all: true,
        key: SEARCH_KEY,
        threshold: -10000,
    });
    const orderedResults = orderBy(results, (it) => it.obj[SEARCH_KEY]);
    return orderedResults.map((it) => {
        return {
            id: it.obj.id,
            plainText: it.obj.plainText,
            richText: it.obj.richText,
        };
    });
}
