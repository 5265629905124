import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Base, Button, Typography } from 'lib/cortiUI';
import { clearStorage, loadCurrentGraph } from './storage';
export function LoadGraphDebugPage() {
    const history = useHistory();
    const match = useRouteMatch();
    const [graphState, setGraphState] = React.useState({ type: 'initial' });
    React.useEffect(() => {
        loadGraph();
    }, []);
    function loadGraph() {
        setGraphState({ type: 'loading' });
        loadCurrentGraph()
            .then((g) => {
            if (!g) {
                setGraphState({ type: 'ok' });
                return;
            }
            setGraphState({ type: 'ok', data: { graphVersion: g.version } });
        })
            .catch((err) => {
            console.error(err);
            setGraphState({ type: 'error', error: String(err) });
        });
    }
    return (React.createElement(Base, { p: 6 },
        React.createElement(Base, { mb: 6 },
            React.createElement(Typography, null, graphState.type === 'error'
                ? 'error'
                : graphState.type === 'initial'
                    ? null
                    : graphState.type === 'loading'
                        ? 'loading'
                        : !graphState.data
                            ? 'no graph'
                            : JSON.stringify(graphState.data.graphVersion, null, 2))),
        React.createElement(Base, { display: 'flex', gap: 4 },
            React.createElement(Button, { onClick: () => {
                    history.push(`${match.url}/indexdb`);
                } }, `Load from main app's index db`),
            React.createElement(Button, { onClick: () => {
                    history.push(`${match.url}/file`);
                } }, `Load from file`),
            React.createElement(Button, { onClick: async () => {
                    await clearStorage();
                    loadGraph();
                } }, `Clear storage`))));
}
