import { useMutation } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Button, Dialog, MenuItem, TextField, Typography } from 'lib/cortiUI';
import { addTeamMemberMutation, getTeamQuery, } from '../../graphql';
isEqual;
export const AddMembersDialog = ({ teamID, members, onClose, users, }) => {
    const { t } = useTranslation('teams');
    const [selectedUsersIDs, setSelectedUsersIDs] = React.useState([]);
    const [addTeamMember] = useMutation(addTeamMemberMutation);
    const filteredUsers = React.useMemo(() => {
        const teamMembersIds = new Set(members.map((it) => it.user.id));
        return users.filter((user) => user && !teamMembersIds.has(user.id));
    }, [users, members]);
    const selectedUsers = React.useMemo(() => selectedUsersIDs
        .map((id) => filteredUsers.find((user) => user.id === id))
        .filter(Boolean), [selectedUsersIDs, filteredUsers]);
    async function handleSubmit() {
        await Promise.all(selectedUsersIDs.map((id) => addTeamMember({
            variables: {
                userID: id,
                teamID: teamID,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getTeamQuery,
                    variables: {
                        id: teamID,
                    },
                },
            ],
        })));
        onClose();
    }
    function filterOptions(options, { inputValue }) {
        return options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.email.toLowerCase().includes(inputValue.toLowerCase()));
    }
    return (React.createElement(Dialog, { "data-cy": "add-team-members-dialog", open: true, onClose: onClose, title: t('teamView.addMembers.title', 'Add user to team'), helperText: t('teamView.addMembers.helperText', 'Start typing a user name or e-mail to add them to the team. Adding a user to a team does not provide them access rights.'), actionButtons: React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "text", onClick: onClose }, t('teamView.addMembers.cancel', 'Cancel')),
            React.createElement(Button, { "data-cy": "add-team-members-done-btn", variant: "contained", color: "error", onClick: handleSubmit }, t('teamView.addMembers.save', 'Save'))) },
        React.createElement(Autocomplete, { "data-cy": "team-members-multiple-select", multiple: true, disableCloseOnSelect: true, options: filteredUsers, getOptionLabel: (option) => option.name, value: selectedUsers, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_, value) => setSelectedUsersIDs(value.map((v) => v.id)), filterOptions: filterOptions, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('teamView.addMembers.selectPlaceholder', 'User name or email') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }),
                React.createElement(Typography, { noWrap: true }, option.name))), ChipProps: {
                size: 'small',
            } })));
};
