// JSON-RPC 2.0 specifies these standard codes and messages
// https://www.jsonrpc.org/specification#response_object
export const RpcReservedErrors = {
    METHOD_NOT_FOUND: { code: -32601, message: 'Method not found' },
    INVALID_PARAMS: { code: -32602, message: 'Invalid params' },
    INVALID_REQUEST: { code: -32600, message: 'Invalid request' },
    INTERNAL_ERR: { code: -32603, message: 'Internal error' },
    SERVER_ERR: { code: -32000, message: 'Server error' },
};
/**
 * These are the errors that can be used by our application code.
 * When adding to the list of application errors, just continue incrementing error codes by one
 *
 * A change in the message or the code is considered a BREAKING change
 */
export const RpcApplicationErrors = {
    NOT_ENOUGH_PERMISSIONS: { code: 100, message: 'Not enough permissions' },
    TRIAGE_SESSION_NOT_FOUND: { code: 101, message: 'Triage session not found' },
};
export const Errors = {
    METHOD_NOT_FOUND: (methodName) => ({
        code: RpcReservedErrors.METHOD_NOT_FOUND.code,
        message: RpcReservedErrors.METHOD_NOT_FOUND.message,
        data: {
            methodName,
        },
    }),
    INVALID_PARAMS: (input) => ({
        code: RpcReservedErrors.INVALID_PARAMS.code,
        message: RpcReservedErrors.INVALID_PARAMS.message,
        data: {
            readableText: input.readableText,
        },
    }),
    INVALID_REQUEST: (input) => ({
        code: RpcReservedErrors.INVALID_REQUEST.code,
        message: RpcReservedErrors.INVALID_REQUEST.message,
        data: {
            readableText: input.readableText,
        },
    }),
    INTERNAL_ERROR: (err) => ({
        code: RpcReservedErrors.INTERNAL_ERR.code,
        message: RpcReservedErrors.INTERNAL_ERR.message,
        data: {
            internalError: {
                message: err instanceof Error ? err.message : String(err),
            },
        },
    }),
    APPLICATION_ERROR: (type, data) => {
        const errBase = RpcApplicationErrors[type];
        return Object.assign(Object.assign({}, errBase), { data });
    },
    isRpcApiError(err) {
        return typeof err === 'object' && err != null && 'code' in err && 'message' in err;
    },
};
