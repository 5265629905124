import MUIAutocomplete, { createFilterOptions, } from '@mui/material/Autocomplete';
import React from 'react';
import { css } from '@corti/style';
function Autocomplete(props) {
    return (React.createElement(MUIAutocomplete, Object.assign({}, props, { classes: Object.assign(Object.assign({}, props.classes), { inputRoot: css({
                // The reason why we need to have marginTop on start adornment this https://github.com/mui/material-ui/issues/22544
                '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                    marginTop: '0px !important',
                },
            }) }) })));
}
export { Autocomplete, createFilterOptions, };
