import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, RichTextEditor, RichTextEditorToolbar } from 'lib/cortiUI';
import { SelectableElement } from '../SelectableElement';
export const SelectOptionElementWidget = observer((props) => {
    const { model } = props;
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(SelectableElement, { model: model },
        React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", width: 1, pr: 6, pl: 5, pt: 5, pb: 5, borderRadius: theme.shape.borderRadius, border: `1px solid ${theme.palette.action.outlinedBorder}` },
            React.createElement(Base, { flex: 1 },
                React.createElement(RichTextEditor, { style: { maxHeight: 160 }, placeholder: t('contentBuilder.selectOptionElement.placeholder', 'Option text'), value: model.text, onChange: (value) => {
                        model.setText(value);
                    } },
                    React.createElement(RichTextEditorToolbar, null))),
            React.createElement(ClearRoundedIcon, { fontSize: "small", onClick: () => {
                    model.remove();
                } }))));
});
