import { uuid } from '@corti/uuid';
export const EventFactory = {
    createActionBlockTriggered(input) {
        var _a, _b, _c, _d, _e, _f, _g;
        return {
            event: 'action-block-triggered',
            data: {
                id: uuid(),
                datetime: new Date().toISOString(),
                stepID: (_b = (_a = input.sourceData) === null || _a === void 0 ? void 0 : _a.step) === null || _b === void 0 ? void 0 : _b.id,
                node: (_d = (_c = input.sourceData) === null || _c === void 0 ? void 0 : _c.step) === null || _d === void 0 ? void 0 : _d.node,
                branch: (_f = (_e = input.sourceData) === null || _e === void 0 ? void 0 : _e.step) === null || _f === void 0 ? void 0 : _f.branch,
                blockPrototype: input.blockPrototype,
                blockInstance: input.blockInstance,
                source: input.source,
                sourceLocation: (_g = input.sourceData) === null || _g === void 0 ? void 0 : _g.location,
            },
        };
    },
    createActiveStepChanged(input) {
        var _a;
        return {
            event: 'active-step-changed',
            data: {
                id: uuid(),
                datetime: new Date().toISOString(),
                stepID: input.step.id,
                node: input.step.node,
                branch: input.step.branch,
                source: input.source,
                sourceLocation: (_a = input.sourceData) === null || _a === void 0 ? void 0 : _a.location,
            },
        };
    },
    createFactValuesUpdated(input) {
        return {
            event: 'fact-values-updated',
            data: {
                id: uuid(),
                datetime: new Date().toISOString(),
                factValues: input.factValues,
                source: input.source,
            },
        };
    },
    createBlockValuesUpdated(input) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return {
            event: 'block-values-updated',
            data: {
                id: uuid(),
                datetime: new Date().toISOString(),
                node: (_b = (_a = input.sourceData) === null || _a === void 0 ? void 0 : _a.step) === null || _b === void 0 ? void 0 : _b.node,
                blockValues: input.blockValues,
                blockPrototype: (_d = (_c = input.sourceData) === null || _c === void 0 ? void 0 : _c.blockInstance) === null || _d === void 0 ? void 0 : _d.blockPrototype,
                blockInstance: (_e = input.sourceData) === null || _e === void 0 ? void 0 : _e.blockInstance,
                branch: (_g = (_f = input.sourceData) === null || _f === void 0 ? void 0 : _f.step) === null || _g === void 0 ? void 0 : _g.branch,
                source: input.source,
                sourceLocation: (_h = input.sourceData) === null || _h === void 0 ? void 0 : _h.location,
            },
        };
    },
};
