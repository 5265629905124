import { gql } from '@apollo/client';
import { Observer } from '@corti/observer';
export const permissionsFragment = gql `
  fragment Permissions on Permission {
    id
    name
  }
`;
export const roleFragment = gql `
  fragment Role on Role {
    id
    name
    isUsed
    permissions {
      ...Permissions
    }
  }
  ${permissionsFragment}
`;
export const createRoleMutation = gql `
  mutation createRoleMutation($role: RoleInput!, $organizationID: ID!) {
    createRole(role: $role, organizationID: $organizationID) {
      ...Role
    }
  }
  ${roleFragment}
`;
export const getOrganizationRolesQuery = gql `
  query getOrganizationRoles($organizationID: ID!) {
    roles(organizationID: $organizationID) {
      ...Role
    }
  }
  ${roleFragment}
`;
export const updateRoleMutation = gql `
  mutation updateRoleMutation($id: String!, $role: RoleInput!, $organizationID: ID!) {
    updateRole(id: $id, role: $role, organizationID: $organizationID) {
      ...Role
    }
  }
  ${roleFragment}
`;
export const deleteRoleMutation = gql `
  mutation deleteRoleMutation($id: String!, $organizationID: ID!) {
    deleteRole(id: $id, organizationID: $organizationID)
  }
`;
export const getPermissions = gql `
  query getPermissions($organizationID: ID!) {
    permissions(organizationID: $organizationID) {
      ...Permissions
    }
  }
  ${permissionsFragment}
`;
const observer = new Observer();
export const roleQueries = (client) => ({
    async create(role, organizationID) {
        const response = await client.mutate({
            mutation: createRoleMutation,
            variables: {
                role,
                organizationID,
            },
        });
        return response.data.createRole;
    },
    async getAll(organizationID) {
        const response = await client.query({
            query: getOrganizationRolesQuery,
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        return response.data.roles;
    },
    async update(id, role, organizationID) {
        const response = await client.mutate({
            mutation: updateRoleMutation,
            variables: {
                id,
                role,
                organizationID,
            },
        });
        observer.fireEvent('roleUpdated', response.data.updateRole);
        return response.data.updateRole;
    },
    async delete(id, organizationID) {
        await client.mutate({
            mutation: deleteRoleMutation,
            variables: {
                id,
                organizationID,
            },
        });
    },
    async getPermissions(organizationID) {
        const response = await client.query({
            query: getPermissions,
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        return response.data.permissions;
    },
    onRoleUpdated(cb) {
        return observer.on('roleUpdated', cb);
    },
});
