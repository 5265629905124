import React from 'react';
/**
 *
 * Collapsable does not render any UI, but provides a state management for building collapsable menus"
 */
export function Collapsable(props) {
    var _a;
    const [collapsed, setCollapsed] = React.useState((_a = props.initialCollapsed) !== null && _a !== void 0 ? _a : false);
    const getHeaderProps = (args) => {
        return {
            onClick: (e) => {
                var _a, _b;
                const newState = !collapsed;
                (_a = props.onCollapsedChange) === null || _a === void 0 ? void 0 : _a.call(props, { collapsed: newState });
                setCollapsed(newState);
                (_b = args === null || args === void 0 ? void 0 : args.onClick) === null || _b === void 0 ? void 0 : _b.call(args, e);
            },
        };
    };
    const getBodyProps = (args) => {
        const props = {
            style: Object.assign({}, args === null || args === void 0 ? void 0 : args.style),
        };
        if (collapsed) {
            props.style.height = 0;
            props.style.overflow = 'hidden';
        }
        return props;
    };
    return props.children({ getHeaderProps, getBodyProps, collapsed });
}
