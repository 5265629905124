import { addDays as addDaysFns, addMinutes as addMinutesFns, differenceInDays, differenceInHours, differenceInMilliseconds, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInYears, endOfDay as endOfDayFns, endOfHour as endOfHourFns, endOfMinute as endOfMinuteFns, endOfMonth as endOfMonthFns, endOfSecond as endOfSecondFns, endOfWeek as endOfWeekFns, endOfYear as endOfYearFns, formatDistanceToNow as formatDistanceToNowFns, isDate, isValid, parseISO, startOfDay as startOfDayFns, startOfHour as startOfHourFns, startOfMinute as startOfMinuteFns, startOfMonth as startOfMonthFns, startOfSecond as startOfSecondFns, startOfWeek as startOfWeekFns, startOfYear as startOfYearFns, subDays as subDaysFns, subHours as subHoursFns, subMinutes as subMinutesFns, subMonths as subMonthsFns, subSeconds as subSecondsFns, subWeeks as subWeeksFns, subYears as subYearsFns, } from 'date-fns';
import { format as formatFns, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getDateFormat, getLocale, getTimeFormat, getTimeZone } from './dateFormatterConfiguration';
export { isDate, parseISO, differenceInMilliseconds, isValid, differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInYears, };
export function formatTime(date, timeFormat) {
    const timeZonedDate = convertUtcToZonedTime(date);
    return formatFns(timeZonedDate, timeFormat !== null && timeFormat !== void 0 ? timeFormat : getTimeFormat());
}
export function formatDate(date, dateFormat) {
    const timeZonedDate = convertUtcToZonedTime(date);
    return formatFns(timeZonedDate, dateFormat !== null && dateFormat !== void 0 ? dateFormat : getDateFormat(), { locale: getLocale() });
}
export function formatDateTime(date) {
    const formatStr = `${getDateFormat()} ${getTimeFormat()}`;
    const timeZonedDate = convertUtcToZonedTime(date);
    return formatFns(timeZonedDate, formatStr, {
        locale: getLocale(),
        timeZone: getTimeZone(),
    });
}
export function formatDistanceToNow(date, options) {
    try {
        return formatDistanceToNowFns(date, { locale: getLocale(), addSuffix: options.addSuffix });
    }
    catch (e) {
        console.log('Invalid time stamp from', date);
        console.error(e);
        return '';
    }
}
/**
 * Returns a new Date object with a different absolute timestamp. Works similarly as "add..." utility functions.
 */
export function convertUtcToZonedTime(date) {
    return utcToZonedTime(date, getTimeZone(), { locale: getLocale() });
}
/**
 * Returns a new Date object with a different absolute timestamp. Works similarly as "add..." utility functions.
 */
export function convertZonedToUtcTime(date) {
    return zonedTimeToUtc(date, getTimeZone(), { locale: getLocale() });
}
export function startOfYear(date) {
    return convertZonedToUtcTime(startOfYearFns(convertUtcToZonedTime(date)));
}
export function startOfMonth(date) {
    return convertZonedToUtcTime(startOfMonthFns(convertUtcToZonedTime(date)));
}
export function startOfWeek(date) {
    return convertZonedToUtcTime(startOfWeekFns(convertUtcToZonedTime(date), { locale: getLocale() }));
}
export function startOfDay(date) {
    return convertZonedToUtcTime(startOfDayFns(convertUtcToZonedTime(date)));
}
export function startOfHour(date) {
    return convertZonedToUtcTime(startOfHourFns(convertUtcToZonedTime(date)));
}
export function startOfMinute(date) {
    return convertZonedToUtcTime(startOfMinuteFns(convertUtcToZonedTime(date)));
}
export function startOfSecond(date) {
    return convertZonedToUtcTime(startOfSecondFns(convertUtcToZonedTime(date)));
}
export function endOfYear(date) {
    return convertZonedToUtcTime(endOfYearFns(convertUtcToZonedTime(date)));
}
export function endOfMonth(date) {
    return convertZonedToUtcTime(endOfMonthFns(convertUtcToZonedTime(date)));
}
export function endOfWeek(date) {
    return convertZonedToUtcTime(endOfWeekFns(convertUtcToZonedTime(date), { locale: getLocale() }));
}
export function endOfDay(date) {
    return convertZonedToUtcTime(endOfDayFns(convertUtcToZonedTime(date)));
}
export function endOfHour(date) {
    return convertZonedToUtcTime(endOfHourFns(convertUtcToZonedTime(date)));
}
export function endOfMinute(date) {
    return convertZonedToUtcTime(endOfMinuteFns(convertUtcToZonedTime(date)));
}
export function endOfSecond(date) {
    return convertZonedToUtcTime(endOfSecondFns(convertUtcToZonedTime(date)));
}
export function addDays(date, amount) {
    return convertZonedToUtcTime(addDaysFns(convertUtcToZonedTime(date), amount));
}
export function addMinutes(date, amount) {
    return convertZonedToUtcTime(addMinutesFns(convertUtcToZonedTime(date), amount));
}
export function subYears(date, amount) {
    return convertZonedToUtcTime(subYearsFns(convertUtcToZonedTime(date), amount));
}
export function subMonths(date, amount) {
    return convertZonedToUtcTime(subMonthsFns(convertUtcToZonedTime(date), amount));
}
export function subWeeks(date, amount) {
    return convertZonedToUtcTime(subWeeksFns(convertUtcToZonedTime(date), amount));
}
export function subDays(date, amount) {
    return convertZonedToUtcTime(subDaysFns(convertUtcToZonedTime(date), amount));
}
export function subHours(date, amount) {
    return convertZonedToUtcTime(subHoursFns(convertUtcToZonedTime(date), amount));
}
export function subMinutes(date, amount) {
    return convertZonedToUtcTime(subMinutesFns(convertUtcToZonedTime(date), amount));
}
export function subSeconds(date, amount) {
    return convertZonedToUtcTime(subSecondsFns(convertUtcToZonedTime(date), amount));
}
