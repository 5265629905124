import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export const CallsDataGridLoader = () => {
    const rowArr = [0, 1, 2];
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2 }, rowArr.map((num) => (React.createElement(React.Fragment, { key: num },
        React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 1 }),
        React.createElement(Base, { display: "flex", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 0.5 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 3 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } })))))));
};
