import React from 'react';
const DEFAULT_OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
};
export const useIntersectionObserver = (callback, observerOptions = DEFAULT_OBSERVER_OPTIONS) => {
    const observerAnchorRef = React.useRef(null);
    const observerRef = React.useRef(null);
    React.useEffect(() => {
        /**
         * Only disconnect and recreate the observer if dependencies actually change
         * This is to avoid creating a new observer on every re-render
         */
        if (observerRef.current) {
            observerRef.current.disconnect();
        }
        observerRef.current = new IntersectionObserver(callback, observerOptions);
        /**
         * Start observing the anchor element
         */
        if (observerAnchorRef.current) {
            observerRef.current.observe(observerAnchorRef.current);
        }
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observerOptions, callback]);
    React.useEffect(() => {
        const observerInstance = observerRef.current;
        if (observerAnchorRef.current && observerInstance)
            observerInstance.observe(observerAnchorRef.current);
        return () => {
            if (observerInstance) {
                observerInstance.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observerAnchorRef, observerOptions]);
    return {
        observerAnchorRef,
    };
};
