import React, { useContext, useEffect, useRef } from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Popper } from 'lib/cortiUI';
import { FilterBuilderContext } from '../FilterBuilderContext';
import { AttributeRenderer } from './AttributeRenderer';
import { ComparisonRenderer } from './ComparisonRenderer';
import { ComparisonHeader } from './FilterGroupComparisonHeader';
import { useFilterGroup } from './FilterGroupProvider';
import { PredicateValueRenderer } from './PredicateValueRenderer';
function compareText(input, val) {
    return val.toLowerCase().includes(input.toLowerCase());
}
export function FilterGroupPopup() {
    var _a, _b, _c;
    const theme = useTheme();
    const { value, uniqueAttributes, resolvedAttribute, isOpen, setIsInsidePopupOpen, menuView, tempFilterValue, inputValue, inputRef, predicateInputRef, containerRef, downshiftProps, onAttributeChange, onComparisonChange, onPredicateValueChange, onReturnToAttributeMenu, } = useFilterGroup();
    const getMenuProps = ((_a = downshiftProps.current) === null || _a === void 0 ? void 0 : _a.getMenuProps) || (() => ({}));
    const getItemProps = ((_b = downshiftProps.current) === null || _b === void 0 ? void 0 : _b.getItemProps) || (() => ({}));
    const highlightedIndex = (_c = downshiftProps.current) === null || _c === void 0 ? void 0 : _c.highlightedIndex;
    const { getComparisonText } = useContext(FilterBuilderContext);
    const initialValue = useRef(value);
    useEffect(() => {
        if (!initialValue.current.value.length && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);
    useEffect(() => {
        if (menuView === 'comparison' && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef, menuView]);
    const isValueRendererNeeded = Boolean(value.comparison !== 'isset') && Boolean(value.comparison !== 'isnotset');
    return (React.createElement(Popper, { open: isOpen, anchorEl: containerRef.current, placement: "bottom-start", className: css({ zIndex: 2 }) },
        React.createElement(Base, Object.assign({}, (isOpen ? getMenuProps({}, { suppressRefError: true }) : {}), { mt: 5, ml: -5, elevation: 16, className: css({
                zIndex: 1,
                background: theme.palette.background.card,
                borderRadius: theme.shape.borderRadius,
                width: 250,
            }) }), menuView === 'comparison' && resolvedAttribute ? (React.createElement(Box, null,
            React.createElement(ComparisonHeader, { title: resolvedAttribute.text, onReturn: onReturnToAttributeMenu }),
            React.createElement(Base, { display: "flex", flexDirection: "column", alignItems: "center", className: css({
                    backgroundColor: theme.palette.background.default,
                }) },
                React.createElement(ComparisonRenderer, { comparisons: resolvedAttribute.comparisons.filter((c) => compareText(inputValue || '', (getComparisonText === null || getComparisonText === void 0 ? void 0 : getComparisonText(c)) || '')), value: tempFilterValue.comparison, onChange: onComparisonChange }),
                isValueRendererNeeded && (React.createElement(PredicateValueRenderer, { inputRef: (i) => (predicateInputRef.current = i), type: resolvedAttribute.valueType, options: resolvedAttribute.options || [], comparison: value.comparison, values: value.value, onChange: onPredicateValueChange, onPopupClose: () => setIsInsidePopupOpen(false), onPopupOpen: () => setIsInsidePopupOpen(true) }))))) : (React.createElement(Base, { className: css(getScrollerCss({ theme: theme })) },
            React.createElement(AttributeRenderer, { attributes: uniqueAttributes.filter((a) => compareText(inputValue || '', a.id)), value: tempFilterValue.attributeID, onChange: onAttributeChange, requiredProps: getItemProps, highlightedIndex: highlightedIndex !== null ? highlightedIndex : undefined }))))));
}
