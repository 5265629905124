import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { TargetConfigurationPanel } from './TargetConfigurationPanel';
export const TargetConfigurationTrigger = ({ variant = 'primary', goal, onApply, }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'targetConfiguration' });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const isPrimary = variant === 'primary';
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-cy": "performance-add-goal-btn", onClick: () => setIsModalOpen(true), startIcon: isPrimary ? React.createElement(AddRoundedIcon, { color: "action" }) : React.createElement(EditRoundedIcon, { color: "action" }), variant: isPrimary ? 'outlined' : 'text' }, t('setGoalBtn', 'Goal')),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('modalTitle', 'Goal') }),
                React.createElement(ModalContent, null,
                    React.createElement(TargetConfigurationPanel, { value: goal.value, isEnabled: goal.enabled, onSave: (data) => {
                            onApply(data);
                            setIsModalOpen(false);
                        }, onClose: () => setIsModalOpen(false) }))))));
};
