import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect, Route, Switch } from 'react-router-dom';
import { I18nextProvider, intl } from '@corti/i18n';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Dialog } from 'lib/cortiUI';
import { CanvasView } from 'lib/graphEditor/canvas/views';
import { ContentEditorView } from 'lib/graphEditor/contentBuilder/view';
import { GraphEditorCtx } from 'lib/graphEditor/core/view/graphEditorContext';
import { FactLibraryView } from 'lib/graphEditor/facts/view';
import { ComponentEditorView, ComponentLibraryView } from 'lib/graphEditor/library/view';
import { MediaModuleWidget } from 'lib/graphEditor/media/MediaModuleWidget';
import { GraphPreviewView } from 'lib/graphEditor/preview/view';
import { BranchInfoPanel, GraphInfoPanel, MainNavigationPanel, ToolPalette, ValidationIssuesPanel, } from 'lib/graphEditor/views';
import { ChangelogWidget } from './ChangelogWidget';
import { ChecklistsView } from './checklists/ChecklistsView';
// Default intl instance used as a fallback if no intl is passed higher in a tree
// this only ensures that fallback english translations from the source code are loaded
const defaultIntl = intl.createInstance();
void defaultIntl.init({
    react: { useSuspense: false },
});
export function GraphEditorWidget(props) {
    const { children, editor, i18n = defaultIntl, i18nDefaultNS } = props;
    const theme = useTheme();
    const $editorEl = React.useRef(null);
    const [isChangelogDialogOpen, setChangelogDialogOpen] = React.useState(false);
    React.useEffect(() => {
        window.__cortiGraphEditor = editor;
        // for now this is exposed for internal use only until we figure out UI/UX
        window.__cortiGraphEditor__openChangelogDialog = () => setChangelogDialogOpen(true);
        return () => {
            delete window.__cortiGraphEditor;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const ctxValue = React.useMemo(() => {
        return {
            editor,
            get $editorEl() {
                return $editorEl.current;
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$editorEl]);
    return (React.createElement(I18nextProvider, { i18n: i18n, defaultNS: i18nDefaultNS },
        React.createElement(GraphEditorCtx.Provider, { value: ctxValue },
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(Base, { ref: $editorEl, "data-cy": "graph-editor-view", position: "relative", height: "100%", width: "100%", display: "grid", gridTemplateColumns: "260px minmax(0, 1fr)", gridTemplateRows: "auto minmax(400px, 1fr)", overflow: "hidden", onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    } },
                    React.createElement(Base, { background: theme.palette.background.default, className: getScrollerCss({ theme }), borderRight: `1px solid ${theme.palette.background.divider}`, pb: 5, gridRow: "span 2" },
                        React.createElement(Base, { gridGap: 8, display: "grid", gridAutoRows: "max-content" },
                            React.createElement(MainNavigationPanel, null),
                            React.createElement(GraphInfoPanel, null),
                            React.createElement(BranchInfoPanel, null),
                            React.createElement(ValidationIssuesPanel, null))),
                    React.createElement(Base, { background: theme.palette.background.default, borderBottom: `1px solid ${theme.palette.background.divider}`, position: "relative" },
                        React.createElement(ToolPalette, null)),
                    React.createElement(Dialog, { open: isChangelogDialogOpen, onClose: () => setChangelogDialogOpen(false), title: i18n.t('changelogDialog.title', 'Change Log') },
                        React.createElement(ChangelogWidget, { changelog: editor.changelog })),
                    React.createElement(Switch, null,
                        React.createElement(Route, { exact: true, path: "/" },
                            React.createElement(Redirect, { to: "/graph" })),
                        React.createElement(Route, { path: "/graph" },
                            React.createElement(CanvasView, null)),
                        React.createElement(Route, { path: "/checklists" },
                            React.createElement(ChecklistsView, null)),
                        React.createElement(Route, { exact: true, path: "/components" },
                            React.createElement(ComponentLibraryView, null)),
                        React.createElement(Route, { exact: true, path: "/facts" },
                            React.createElement(FactLibraryView, null)),
                        React.createElement(Route, { path: "/content-builder/:elementID" },
                            React.createElement(ContentEditorView, null)),
                        React.createElement(Route, { path: "/components/:componentID" },
                            React.createElement(ComponentEditorView, null)),
                        React.createElement(Route, { path: "/preview" },
                            React.createElement(GraphPreviewView, null)))),
                children,
                React.createElement(MediaModuleWidget, null)))));
}
