import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, TextField } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const DropdownField = ({ title, required, choices }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    const getHelperTextErrorMessage = () => {
        let message = '';
        if (!title) {
            message = message.concat(t('preview.validationErrors.noTitle', 'Title is missing.'));
        }
        if (choices === null || choices === void 0 ? void 0 : choices.some((c) => !c.text)) {
            message = message.concat(` ${t('preview.validationErrors.emptyOptions', 'Some options are empty.')}`);
        }
        return message ? message : undefined;
    };
    const helperText = getHelperTextErrorMessage();
    return (React.createElement(Base, { "data-cy": CY_SELECTORS.preview.dropdownFieldPreview },
        React.createElement(Autocomplete, { size: "small", options: (choices !== null && choices !== void 0 ? choices : []).map((choice) => ({ value: choice.value, label: choice.text })), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { error: Boolean(helperText), required: required, helperText: helperText, placeholder: t('preview.placeholders.choseAnswer', 'Chose an answer'), FormHelperTextProps: {
                    'data-cy': CY_SELECTORS.preview.dropdownPreviewHelperText,
                } }))), clearIcon: null })));
};
