import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import React from 'react';
import { Formatters } from '@corti/strings';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { IconButton, ListItem, ListItemText, Typography } from 'lib/cortiUI';
export const AlertsListItem = React.forwardRef(({ alert, sessionCreatedAt, onActionClick }, ref) => {
    var _a;
    const theme = useTheme();
    return (React.createElement(ListItem, { "data-cy": "alerts-list-item", ref: ref, onClick: () => onActionClick(alert), display: "grid", gridTemplateColumns: "1fr auto", classes: {
            root: css({
                backgroundColor: 'transparent',
                '&:not(:last-child)': { marginBottom: theme.space[6] },
            }, getFocusCss({ theme })),
        } },
        React.createElement(ListItemText, null,
            React.createElement(Typography, { color: "secondaryText", fontSize: 12 }, Formatters.msToDuration(new Date(alert.datetime).getTime() - sessionCreatedAt.getTime(), { showHours: false })),
            React.createElement(Typography, { variant: "subtitle1", className: css({
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.fontSize,
                }) },
                alert.title,
                " - ", (_a = alert.text) !== null && _a !== void 0 ? _a : '')),
        React.createElement(IconButton, { size: "medium", "data-cy": "alerts-list-item-action", onClick: (e) => {
                e.stopPropagation();
                onActionClick(alert);
            }, icon: React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "large", color: "inherit" }) })));
});
