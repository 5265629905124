import { groupBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import { ComplianceCard } from './ComplianceCard';
export const TrackersList = ({ items }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'trackersPanel' });
    function getItemsGroupedByTitle(items) {
        const groupedByTitle = groupBy(items, (i) => { var _a; return (_a = i.source.title) !== null && _a !== void 0 ? _a : ''; });
        const sortBySourceText = (a, b) => a.source.text.localeCompare(b.source.text);
        const entries = Object.entries(groupedByTitle).sort(([a], [b]) => a.localeCompare(b, undefined, { numeric: true }));
        entries.forEach(([, trackers]) => trackers.sort(sortBySourceText));
        if (entries.length > 0 && entries[0][0] === '') {
            const [, trackers] = entries.shift();
            entries.push([t('noTrackersCategory', 'No category'), trackers]);
        }
        return entries;
    }
    const groupedBySourceTextMatrix = getItemsGroupedByTitle(items);
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5, width: "100%", "data-cy": "compliance-cards-grid" }, groupedBySourceTextMatrix.map(([title, items]) => (React.createElement(React.Fragment, { key: title },
        React.createElement(Typography, { variant: "caption", key: title, mb: 2 }, title.toUpperCase()),
        React.createElement(Base, { display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 5, width: "100%", "data-cy": "compliance-cards-grid" }, items.map(({ source, percentage }) => (React.createElement(ComplianceCard, { key: `${source.key.entityID}${source.key.type}`, title: source.text, score: Math.round(percentage * 100) })))))))));
};
