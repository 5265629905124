import { omitBy, round } from 'lodash';
import { logger } from '@corti/logger';
import { Observer } from '@corti/observer';
import { Backoff } from './backoff';
const DEFAULT_CONFIG = {
    pongTimeout: 10000,
    connectionTimeout: 10000,
    healthcheckInterval: 10000,
    reconnectionDelayGrowFactor: 1.5,
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 1000,
    jitter: 0.3,
};
export function createConnection(config = {}) {
    return new Connection(config);
}
export class Connection extends Observer {
    constructor(config = {}) {
        super();
        Object.defineProperty(this, "ws", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "url", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "backoff", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: logger.getLogger('Connection')
        });
        Object.defineProperty(this, "pongReceiveTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pingPongInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "retryTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "connectionTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "backoffResetTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * For not this is only used for testing, but it can eventually be used to dynamically
         * adjust delay timers from more optimistic, to less optimistic
         */
        Object.defineProperty(this, "isConnStable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "send", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (message) => {
                var _a, _b;
                if (((_a = this.ws) === null || _a === void 0 ? void 0 : _a.readyState) !== WebSocket.OPEN) {
                    this.logger.warn('unable to send message, connection is not open. Skipping this message:', JSON.stringify(message));
                    return;
                }
                (_b = this.ws) === null || _b === void 0 ? void 0 : _b.send(JSON.stringify(message));
            }
        });
        // #region internal Websocket event handlers
        Object.defineProperty(this, "handleOpenEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.logger.debug(`ws event: open`);
                this.handleConnectionEstablished();
            }
        });
        Object.defineProperty(this, "handleCloseEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.logger.debug('ws event: close');
                this.handleConnectionClosed({ reason: 'other' });
            }
        });
        Object.defineProperty(this, "handleErrorEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_) => {
                // `close` event is received whenever an error occurs
                // no need to handle, just log the event
                this.logger.error('ws event: error');
            }
        });
        Object.defineProperty(this, "handleMessageEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                this.clearPongTimer();
                if (event.data === 'pong') {
                    return;
                }
                this.parseAndHandleMessage(event);
            }
        });
        // #endregion
        Object.defineProperty(this, "handleConnectionEstablished", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.clearConnectionTimer();
                this.clearRetryTimer();
                this.setState('connected');
                this.logger.info('sucessfully connected');
            }
        });
        Object.defineProperty(this, "handleConnectionClosed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_) => {
                this.clearConnectionTimer();
                if (this.shouldRetry()) {
                    this.logger.info('connection lost');
                    this.setState('unavailable');
                    const retryIn = this.backoff.getNextDelay();
                    this.reconnectIn(retryIn);
                    this.backoff.incRetryCounter();
                    this.isConnStable = false;
                    this.restartBackoffTimer();
                }
            }
        });
        Object.defineProperty(this, "parseAndHandleMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                const data = event.data;
                let message;
                try {
                    message = JSON.parse(data);
                }
                catch (err) {
                    console.error('Only json format message data is allowed');
                }
                if (message) {
                    this.fireEvent('message', message);
                }
            }
        });
        Object.defineProperty(this, "setState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (state) => {
                if (this.state !== state) {
                    const prevState = this.state;
                    this.state = state;
                    this.fireEvent('stateChanged', { previous: prevState, current: this.state });
                }
            }
        });
        // #region Testing
        /**
         * API FOR TESTING
         *
         * Used to simulate a message event coming into the connection
         */
        Object.defineProperty(this, "dispatchMessageEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                var _a;
                (_a = this.ws) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new MessageEvent('message', { data: JSON.stringify(data) }));
            }
        });
        this._config = config;
        this.state = 'initialized';
        this.isConnStable = true;
        this.backoff = new Backoff({
            minDelay: config.minReconnectionDelay,
            maxDelay: config.maxReconnectionDelay,
            growthFactor: config.reconnectionDelayGrowFactor,
        });
        this.on('stateChanged', (state) => {
            this.logger.debug(`connection state change: now ${state.current}, was ${state.previous}`);
            if (state.current === 'connected') {
                this.startPingPong();
            }
            else {
                this.stopPingPong();
            }
        });
    }
    get config() {
        return Object.assign(Object.assign({}, DEFAULT_CONFIG), omitBy(this._config, (v) => v === undefined));
    }
    connect(url) {
        this.logger.debug(`method call: connect`);
        if (this.ws) {
            return;
        }
        if (url) {
            this.url = url;
        }
        if (!this.url) {
            throw new Error('connection has no URL to connect to');
        }
        this.setState('connecting');
        this.ws = new WebSocket(this.url);
        this.addListeners();
        this.connectionTimer = new Timer(this.config.connectionTimeout, () => {
            this.logger.error('unable to connect - timed out');
            this.handleConnectionClosed({ reason: 'timeout' });
        });
    }
    disconnect() {
        this.logger.debug(`method call: disconnect()`);
        this.disconnectInternally();
        this.backoff.reset();
        this.clearBackoffResetTimer();
        this.isConnStable = true;
        this.setState('disconnected');
    }
    reconnect(url) {
        this.logger.debug(`method call: reconnect`);
        if (url) {
            this.url = url;
        }
        this.backoff.reset();
        this.clearBackoffResetTimer();
        this.isConnStable = true;
        this.clearRetryTimer();
        this.reconnectIn(0);
    }
    reconnectIn(delay = 0) {
        if (this.retryTimer) {
            return;
        }
        this.logger.info(`reconnect attempt in ${round(delay / 1000, 2)} seconds`);
        this.retryTimer = new Timer(delay, () => {
            this.logger.debug(`timer cb: retryTimer`);
            this.disconnectInternally();
            this.connect();
        });
    }
    disconnectInternally() {
        this.logger.debug(`method call: disconnectInternally()`);
        this.clearRetryTimer();
        this.clearConnectionTimer();
        if (this.ws) {
            const ws = this.ws;
            this.abandonConnection();
            this.logger.debug(`method call: Websocket.close()`);
            ws.close();
            this.ws = undefined;
        }
    }
    startPingPong() {
        if (this.pingPongInterval) {
            return;
        }
        this.pingPongInterval = window.setInterval(() => this.sendPing(), this.config.healthcheckInterval);
    }
    /**
     * Will try to send ping message right away if it is not currently waiting for the pong.
     * This is mainly useful for testing
     */
    sendPing() {
        var _a;
        if (this.pongReceiveTimer) {
            return;
        }
        (_a = this.ws) === null || _a === void 0 ? void 0 : _a.send('ping');
        this.pongReceiveTimer = window.setTimeout(() => {
            this.logger.debug(`timer cb: pongReceiveTimer`);
            this.handleConnectionClosed({ reason: 'pong-timeout' });
        }, this.config.pongTimeout);
    }
    stopPingPong() {
        clearInterval(this.pingPongInterval);
        this.pingPongInterval = undefined;
        this.clearPongTimer();
    }
    restartBackoffTimer() {
        if (!this.backoffResetTimer) {
            this.logger.info('connection is unstable, retry count will be reset only when this status changes');
        }
        this.clearBackoffResetTimer();
        // 1 min without losing a connection is considered as stable
        this.backoffResetTimer = new Timer(60 * 1000, () => {
            this.backoff.reset();
            this.logger.info('connection is now stable, retry count reset to 0');
            this.isConnStable = true;
            this.clearBackoffResetTimer();
        });
    }
    clearBackoffResetTimer() {
        var _a;
        (_a = this.backoffResetTimer) === null || _a === void 0 ? void 0 : _a.ensureAborted();
        this.backoffResetTimer = undefined;
    }
    clearRetryTimer() {
        if (this.retryTimer) {
            this.logger.debug(`timer clear: retryTimer`);
            this.retryTimer.ensureAborted();
            this.retryTimer = undefined;
        }
    }
    clearConnectionTimer() {
        if (this.connectionTimer) {
            this.logger.debug(`timer clear: connectionTimer`);
            this.connectionTimer.ensureAborted();
            this.connectionTimer = undefined;
        }
    }
    clearPongTimer() {
        if (this.pongReceiveTimer) {
            clearTimeout(this.pongReceiveTimer);
            this.pongReceiveTimer = undefined;
        }
    }
    abandonConnection() {
        this.logger.debug(`method call: abandonConnection()`);
        if (!this.ws) {
            return;
        }
        this.removeListeners();
    }
    addListeners() {
        this.logger.debug(`method call: addListeners()`);
        this.ws.addEventListener('open', this.handleOpenEvent);
        this.ws.addEventListener('message', this.handleMessageEvent);
        this.ws.addEventListener('close', this.handleCloseEvent);
        this.ws.addEventListener('error', this.handleErrorEvent);
    }
    removeListeners() {
        this.logger.debug(`method call: removeListeners()`);
        this.ws.removeEventListener('open', this.handleOpenEvent);
        this.ws.removeEventListener('message', this.handleMessageEvent);
        this.ws.removeEventListener('close', this.handleCloseEvent);
        this.ws.removeEventListener('error', this.handleErrorEvent);
    }
    shouldRetry() {
        return this.state !== 'disconnected';
    }
}
class Timer {
    constructor(time, cb) {
        Object.defineProperty(this, "handle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.handle = window.setTimeout(cb, time);
    }
    ensureAborted() {
        if (this.handle) {
            window.clearTimeout(this.handle);
        }
    }
}
