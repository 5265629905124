var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable, override, runInAction } from 'mobx';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { BaseFormElementModel } from './BaseFormElementModel';
export class FlowValueCollectorElementModel extends BaseFormElementModel {
    constructor(_props = {}) {
        super({
            attributes: {},
        });
        Object.defineProperty(this, "collectFromCriticalPath", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.type = FlowValueCollectorElementModel.typename;
        runInAction(() => {
            this.collectFromCriticalPath = false;
        });
    }
    setCollectFromCriticalPath(value) {
        this.collectFromCriticalPath = value;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { collectFromCriticalPath: this.collectFromCriticalPath });
    }
    deserialize(input) {
        super.deserialize(input);
        this.collectFromCriticalPath = input.collectFromCriticalPath;
    }
    copy() {
        const i = super.copy();
        i.collectFromCriticalPath = this.collectFromCriticalPath;
        return i;
    }
}
Object.defineProperty(FlowValueCollectorElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'flowValueCollector'
});
Object.defineProperty(FlowValueCollectorElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.flowValueCollectorElement.title', 'Flow Value Collector'),
    })
});
Object.defineProperty(FlowValueCollectorElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new FlowValueCollectorElementModel();
        i.deserialize(val);
        return i;
    }
});
__decorate([
    observable,
    __metadata("design:type", Boolean)
], FlowValueCollectorElementModel.prototype, "collectFromCriticalPath", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FlowValueCollectorElementModel.prototype, "setCollectFromCriticalPath", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], FlowValueCollectorElementModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FlowValueCollectorElementModel.prototype, "copy", null);
ElementSerializer.register(FlowValueCollectorElementModel, 'FlowValueCollectorElementModel');
