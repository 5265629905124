import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { useDebouncedEffect } from '@corti/react';
import { css } from '@corti/style';
import { IconButton, InputAdornment, TextField } from 'lib/cortiUI';
function urlToEnv(url) {
    return url.match(/https?.*motocorti\.io/i)
        ? url.replace('https://api.', '').replace('.motocorti.io', '')
        : url;
}
export default function EnvironmentInput(props) {
    const { initialUrl, onChange } = props;
    const inputRef = React.useRef();
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [inputValue, setInputValue] = React.useState(initialUrl ? urlToEnv(initialUrl) : '');
    React.useEffect(() => {
        if (isDisabled === false && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isDisabled]);
    useDebouncedEffect(() => {
        void onChange(inputValue);
    }, 500, [inputValue]);
    return (React.createElement(TextField, { inputRef: inputRef, fullWidth: true, label: intl.t('appSettingsView.envIdInputLabel', 'Environment ID'), name: "env", type: 'text', value: inputValue, onChange: (e) => {
            const val = e.target.value;
            setInputValue(val);
        }, disabled: isDisabled, onBlur: () => {
            setIsDisabled(true);
        }, InputLabelProps: {
            shrink: true,
        }, InputProps: {
            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                React.createElement(IconButton, { "data-cy": "enable-env-input-button", onClick: () => {
                        setIsDisabled(false);
                    }, className: css({ marginLeft: 3 }), icon: React.createElement(CreateRoundedIcon, { fontSize: "small" }), size: "small" }))),
        } }));
}
