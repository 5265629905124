import { gql } from '@apollo/client';
export const getPerformanceViewMetricsQuery = gql `
  query performanceViewMetrics(
    $organizationID: ID!
    $startTime: Time!
    $endTime: Time!
    $filter: PerformanceMetricsFilterInput
    $sources: [PerformanceMetricsSourceKeyInput!]!
  ) {
    performanceViewMetrics(
      organizationID: $organizationID
      startTime: $startTime
      endTime: $endTime
      filter: $filter
      sources: $sources
    ) {
      metricsBreakdown {
        source {
          isEnabled
          text
          title
          key {
            type
            entityID
          }
        }
        percentage
      }
      userMetrics {
        numberOfCalls
        user {
          id
          name
        }
        breakdown {
          percentage
          source {
            isEnabled
            key {
              entityID
              type
            }
            text
            title
          }
        }
      }
      timeBasedMetrics {
        timePoint
        detectionPercentage
        numberOfCalls
      }
    }
  }
`;
export const getPerformanceViewMetricsGroupByCallQuery = gql `
  query performanceViewMetricsGroupByCall(
    $organizationID: ID!
    $sources: [PerformanceMetricsSourceKeyInput!]!
    $startTime: Time!
    $endTime: Time!
    $filter: PerformanceMetricsFilterInput!
    $offset: String
    $limit: Int
  ) {
    performanceViewMetricsGroupByCall(
      organizationID: $organizationID
      sources: $sources
      startTime: $startTime
      endTime: $endTime
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      cursor
      performanceMetrics {
        call {
          id
          protocolName
          start
          externalID
        }
        case {
          id
        }
        metrics {
          source {
            text
            title
            key {
              entityID
              type
            }
            isEnabled
          }
          value
        }
      }
    }
  }
`;
