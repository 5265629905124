var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { ExpressionUtils } from '@corti/lib/graphs/expressionUtils';
import { createRichText, stringifyRichText } from '@corti/richtext';
import { BaseElementModel } from './BaseElementModel';
export class BaseFormElementModel extends BaseElementModel {
    constructor(props) {
        super(Object.assign(Object.assign({}, props), { attributes: props.attributes }));
        Object.defineProperty(this, "_label", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this._label = createRichText();
        });
    }
    setLabel(label) {
        if (typeof label === 'string') {
            this._label = createRichText(label);
        }
        else {
            this._label = label;
        }
    }
    get label() {
        return this._label;
    }
    get expressionVariables() {
        let expressions = [];
        this._label.nodes.forEach((node) => {
            if (node.type !== 'paragraph')
                return;
            node.children.forEach((child) => {
                var _a, _b;
                if (child.type !== 'hasexpression' || !((_a = child.attributes) === null || _a === void 0 ? void 0 : _a.expression))
                    return;
                expressions.push(...ExpressionUtils.getVariables((_b = child.attributes) === null || _b === void 0 ? void 0 : _b.expression));
            });
        });
        return expressions;
    }
    get readableTitle() {
        return stringifyRichText(this.label) || this.name || `[unnamed ${this.type}]`;
    }
    deserialize(value) {
        super.deserialize(value);
        this._label = value.label;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { label: this._label });
    }
    copy() {
        const i = super.copy();
        i.setLabel(cloneDeep(this.label));
        return i;
    }
}
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], BaseFormElementModel.prototype, "_label", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseFormElementModel.prototype, "setLabel", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseFormElementModel.prototype, "label", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseFormElementModel.prototype, "expressionVariables", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BaseFormElementModel.prototype, "readableTitle", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], BaseFormElementModel.prototype, "deserialize", null);
