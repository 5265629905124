// pollyfill global fetch with isomorphic fetch
import 'isomorphic-fetch';
import { graphqlSchemaQueries, } from '@corti/lib/coreApiService/graphqlSchema';
import { organizationConfigQueries, } from '@corti/lib/coreApiService/organizationConfigs';
import { createClient } from './GraphqlClient';
import { RestClient } from './RestClient';
// API namespaces
import { AuthService } from './auth';
import { CaseFeedbackService } from './caseFeedback';
import { caseFiltersService } from './caseFilters';
import { caseService } from './cases';
import { FilesService } from './files';
import { createMediaService } from './media/media';
import { notificationService } from './notifications';
import { OrganizationService } from './organizations';
import { protocolGraphService } from './protocolGraphs';
import { roleQueries } from './roles';
import { timelineEntryDefinitionsQueries, } from './timelineEntryDefinitions';
import { TranscriptsService } from './transcripts';
import { triageQueries } from './triage';
import { userQueries } from './users';
export function createCoreApiService(config) {
    const gqlClient = createClient({
        host: config.baseUrl,
        apiKey: config.apiKey,
    });
    const restClient = new RestClient({
        host: config.baseUrl,
        apiKey: config.apiKey,
    });
    const auth = new AuthService(restClient.axiosClient, gqlClient);
    auth.onSignedOut(() => {
        gqlClient.stop(); // https://github.com/apollographql/apollo-client/issues/3766#issuecomment-578075556
        void gqlClient.clearStore();
    });
    const caseFeedback = new CaseFeedbackService(gqlClient, restClient);
    const caseFilters = caseFiltersService(gqlClient);
    const cases = caseService(gqlClient, restClient);
    const files = new FilesService(restClient.axiosClient, auth);
    const notifications = notificationService(gqlClient);
    const graphqlSchema = graphqlSchemaQueries(gqlClient);
    const orgs = new OrganizationService(gqlClient);
    const orgConfigs = organizationConfigQueries(gqlClient);
    const protocolGraphs = protocolGraphService(gqlClient);
    const roles = roleQueries(gqlClient);
    const timelineEntryDefinitions = timelineEntryDefinitionsQueries(gqlClient);
    const triage = triageQueries(gqlClient, restClient);
    const users = userQueries(gqlClient);
    const media = createMediaService(gqlClient, files);
    const transcripts = new TranscriptsService(restClient.axiosClient, gqlClient);
    return {
        client: gqlClient,
        auth,
        caseFeedback,
        caseFilters,
        cases,
        files,
        media,
        notifications,
        graphqlSchema,
        orgs,
        orgConfigs,
        protocolGraphs,
        roles,
        timelineEntryDefinitions,
        triage,
        users,
        transcripts,
        getServerInfo: () => restClient.fetchApiInfo(),
        setHost: (apiHost) => {
            restClient.setHost(apiHost);
            gqlClient.setHost(apiHost);
        },
        onErrorResponse: (cb) => {
            const u1 = gqlClient.onError(cb);
            const u2 = restClient.onErrorResponse(cb);
            return () => {
                u1();
                u2();
            };
        },
    };
}
