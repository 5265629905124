import React from 'react';
import { Redirect } from 'react-router-dom';
import { moduleRegistry } from 'core/moduleRegistry/browser';
export function ExternalApiRoute({ location }) {
    const exploreModule = moduleRegistry.getModule('explore');
    if (!exploreModule) {
        return null;
    }
    return React.createElement(Redirect, { to: { pathname: exploreModule.path, search: location.search } });
}
