import Keycloak from 'keycloak-js';
export class KeycloakService {
    constructor(envInput) {
        Object.defineProperty(this, "keycloak", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.createKeyloak(envInput);
    }
    createKeyloak(envInput) {
        this.keycloak = new Keycloak({
            url: `https://keycloak.${envInput}.corti.live/`,
            realm: envInput,
            clientId: 'frontend-auth',
        });
        void this.initKeycloak();
    }
    async initKeycloak() {
        await this.keycloak.init({});
    }
    async login() {
        if (!this.isObjectInitialised)
            return;
        await this.keycloak.login();
    }
    async logout() {
        if (!this.isObjectInitialised)
            return;
        await this.keycloak.logout();
    }
    onSignedIn(cb) {
        if (!this.isObjectInitialised)
            return;
        this.keycloak.onAuthSuccess = function () {
            cb();
        };
    }
    get keycloakToken() {
        if (this.isAuthenticated) {
            return this.keycloak.token;
        }
        else {
            throw new Error('Keycloak token only allowed when user is authenticated, call `isAuthenticated` to check');
        }
    }
    get keycloakIDToken() {
        if (this.isAuthenticated) {
            return this.keycloak.idToken;
        }
        else {
            throw new Error('Keycloak token only allowed when user is authenticated, call `isAuthenticated` to check');
        }
    }
    get isAuthenticated() {
        return this.keycloak.authenticated;
    }
    get isObjectInitialised() {
        if (!!this.keycloak) {
            return true;
        }
        else {
            throw new Error('Keycloak only allowed when it is initialised');
        }
    }
}
