import { HelpOutline } from '@mui/icons-material';
import React, { useState } from 'react';
import { Popover } from 'lib/cortiUI';
export function Help({ text }) {
    const [anchor, setAnchor] = useState(null);
    const handlePopoverOpen = (evt) => setAnchor(evt.currentTarget);
    const handlePopoverClose = () => setAnchor(null);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { "aria-haspopup": "true", onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose, onClick: (evt) => evt.preventDefault(), style: { cursor: 'help' } },
            React.createElement(HelpOutline, { fontSize: "small" })),
        React.createElement(Popover, { open: !!anchor, anchorEl: anchor, onClose: handlePopoverClose, anchorOrigin: { vertical: 'top', horizontal: 'right' }, transformOrigin: { vertical: 'bottom', horizontal: 'left' }, disableRestoreFocus: true, sx: { pointerEvents: 'none' }, slotProps: { paper: { sx: { maxWidth: 300, p: 4, fontSize: '90%' } } } }, text)));
}
