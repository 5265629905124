import AddRounded from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Typography } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
import { FieldOption } from './FieldOption';
export const DropdownField = ({ choices, timelineEntryDefinitions, onChoiceInputValueChange, onTimelineEntryDefsHintsValueChange, onAddChoice, onRemoveChoice, }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { mb: 5 },
            React.createElement(Typography, { variant: "body1", color: "default" }, t('fieldWidget.manualOptions', 'Manual options'))),
        React.createElement(Base, null,
            choices &&
                choices.map((c, i) => {
                    var _a;
                    return (React.createElement(FieldOption, { key: c.value, valueID: c.value, textValue: c.text, label: `${t('fieldWidget.option', 'Option')} ${i + 1}`, timelineEntryDefinitions: timelineEntryDefinitions, selectedTimelineEntryDefinitionsForHints: (_a = c.timelineEntryDefinitionSourceHints) !== null && _a !== void 0 ? _a : [], onTimelineEntryDefsHintsValueChange: onTimelineEntryDefsHintsValueChange, onChoiceInputValueChange: onChoiceInputValueChange, onRemoveChoice: onRemoveChoice }));
                }),
            React.createElement(Base, { width: 1, mb: 6 },
                React.createElement(Button, { startIcon: React.createElement(AddRounded, { fontSize: "small", color: "primary" }), variant: "text", color: "primary", size: "small", onClick: onAddChoice, "data-cy": CY_SELECTORS.fieldWidget.addOptionBtn }, t('fieldWidget.addChoiceBtn', 'Add manual option'))))));
};
