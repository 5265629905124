import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { Base, Box, Button, IconButton, TextField, Typography } from 'lib/cortiUI';
export function FeedbackFormFieldActionBar(props) {
    const { isPreviewMode, onResetField, onCommentChange, commentValue, fieldValue } = props;
    const [commentInputValue, setCommentInputValue] = React.useState(commentValue || '');
    const [isCommentExpanded, setIsCommentExpanded] = React.useState(!!commentValue);
    function getPreviewContent() {
        if (commentValue) {
            return (React.createElement(Box, null,
                React.createElement(Typography, { variant: "caption", color: "secondaryText" }, intl.t('feedbackForm.comments.commentInputLabel', 'Comment')),
                React.createElement(Typography, { mt: 1, variant: "body2", color: "secondaryText", "data-cy": "feedback-form-comment-value" }, commentValue)));
        }
        else {
            return null;
        }
    }
    function getCommentContent() {
        if (isCommentExpanded) {
            return (React.createElement(Base, { display: "flex", width: "100%", pt: "2" },
                React.createElement(TextField, { "data-cy": "feedback-form-comment-draft", fullWidth: true, multiline: true, rows: 3, InputLabelProps: { shrink: true }, label: intl.t('feedbackForm.comments.commentInputLabel', 'Comment'), placeholder: intl.t('feedbackForm.comments.commentInputPlaceholder', 'Write your comment'), value: commentInputValue, onChange: (e) => setCommentInputValue(e.target.value), onBlur: () => onCommentChange(commentInputValue) }),
                React.createElement(Base, null,
                    React.createElement(IconButton, { "data-cy": "remove-comment-button", onClick: () => {
                            onCommentChange('');
                            setCommentInputValue('');
                            setIsCommentExpanded(false);
                        }, icon: React.createElement(HighlightOffRoundedIcon, { fontSize: "small" }) }))));
        }
        else {
            return (React.createElement(Base, { display: "flex" },
                React.createElement(Button, { onClick: () => {
                        setIsCommentExpanded(true);
                    }, color: "primary", size: "small", variant: "text", "data-cy": "add-comment-button" }, intl.t('feedbackForm.comments.addComment', 'Add Comment'))));
        }
    }
    function getEditableContent() {
        return (React.createElement(Base, { display: "flex", flexDirection: isCommentExpanded ? 'column' : 'row', justifyContent: "space-between" },
            getCommentContent(),
            React.createElement(Base, { mt: isCommentExpanded ? 1 : 0 }, fieldValue && (React.createElement(Button, { "data-cy": "reset-field-button", size: "small", variant: "text", onClick: () => {
                    onResetField();
                    setCommentInputValue('');
                    setIsCommentExpanded(false);
                } }, intl.t('feedbackForm.clearField', 'Clear answer'))))));
    }
    return isPreviewMode ? getPreviewContent() : getEditableContent();
}
