import { deburr } from 'lodash';
import { runInAction } from 'mobx';
import { ValuePublisher } from 'lib/graphEditor/valuePublisher';
const invalidIDRegex = /[^a-z0-9\._\-]|\.\.|(\.|-)$|^(\.|-)|\-\-|__/;
const regexes = {
    trimBeginning: /^(\W|-|\.)+/,
    trimEnding: /(\W|-|\.)+$/,
    replaceDashes: /(-|\s)+/g,
    replaceDots: /((-+)?\.+(-+)?)/g,
    replaceIllegal: /[^a-z0-9-_\.]/g,
};
export const FactStatic = {
    createFact(input) {
        var _a;
        return Object.assign(Object.assign({}, input), { id: (_a = input.id) !== null && _a !== void 0 ? _a : FactStatic.generateIDFromName(input.name), valuePublisherConfig: input.valuePublisherConfig
                ? new ValuePublisher(input.valuePublisherConfig)
                : new ValuePublisher() });
    },
    serialize(fact) {
        return Object.assign(Object.assign({}, fact), { valuePublisherConfig: fact.valuePublisherConfig.getEncodableData() });
    },
    setName(fact, name) {
        runInAction(() => {
            fact.name = name;
        });
    },
    setType(fact, type) {
        runInAction(() => {
            fact.type = type;
        });
    },
    copy(fact) {
        return Object.assign(Object.assign({}, fact), { valuePublisherConfig: fact.valuePublisherConfig.copy() });
    },
    generateIDFromName(name) {
        return deburr(name)
            .toLowerCase()
            .replace(regexes.trimBeginning, '')
            .replace(regexes.trimEnding, '')
            .replace(regexes.replaceDashes, '-')
            .replace(regexes.replaceDots, '.')
            .replace(regexes.replaceIllegal, '');
    },
    isValidID(id) {
        return !invalidIDRegex.test(id);
    },
};
