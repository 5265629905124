import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { isValidRegExp } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Tooltip, Typography } from 'lib/cortiUI';
export function PredicateValueTextRenderer(props) {
    const theme = useTheme();
    function getValueTextByType() {
        switch (props.type) {
            case 'string':
                return props.values[0];
            case 'int':
                return props.values[0].toString();
            case 'age':
                const firstValue = props.options.find((o) => o.value === props.values[0]);
                if (props.values.length === 1) {
                    return (firstValue === null || firstValue === void 0 ? void 0 : firstValue.text) || (firstValue === null || firstValue === void 0 ? void 0 : firstValue.value);
                }
                else {
                    return firstValue
                        ? `${firstValue.text || firstValue.value}, +${props.values.length - 1}`
                        : `+${props.values.length - 1}`;
                }
            case 'boolean':
                return props.values[0].toString();
            case 'datetime':
                const selectedDate = props.values[0];
                switch (props.comparison) {
                    case 'between':
                        return `${formatDateTime(selectedDate.startDate)} - ${formatDateTime(selectedDate.endDate)}`;
                    case 'after':
                    case 'before':
                        return formatDateTime(selectedDate);
                    default:
                        return undefined;
                }
            case 'multiselect':
                const allSelected = props.options
                    .filter((o) => props.values.includes(o.value))
                    .map((o) => o.text || o.value);
                return allSelected.toString().replace(/,/g, ', ');
            case 'select':
                const option = props.options.find((o) => o.value === props.values[0]);
                return (option === null || option === void 0 ? void 0 : option.text) || (option === null || option === void 0 ? void 0 : option.value);
            default:
                return '[Unsupported Value Type]';
        }
    }
    function isErrorSignNeeded() {
        return ((props.comparison === 'contains' || props.comparison === 'ncontains') &&
            !isValidRegExp(props.values[0].toString()));
    }
    if (props.values.length && props.values[0]) {
        return (React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { variant: "body2", color: "default", noWrap: true, noSelect: true }, getValueTextByType()),
            isErrorSignNeeded() && (React.createElement(Tooltip, { disableInteractive: true, title: intl.t('filterBuilder.invalidRegex', 'The regular expression is not valid') },
                React.createElement(ErrorRoundedIcon, { fontSize: "small", className: css({ color: theme === null || theme === void 0 ? void 0 : theme.palette.error.dark }) })))));
    }
    else {
        return (React.createElement(Typography, { variant: "body2", color: "hint", noSelect: true, noWrap: true }, intl.t('filterBuilder.value', 'Value')));
    }
}
