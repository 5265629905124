var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { makeObservable, observable, runInAction } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { gqlMediaTypeToMediaAsset } from './graphqlAdapter';
export class GqlMediaProvider {
    constructor() {
        Object.defineProperty(this, "mediaAssetsByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refreshMediaAssets", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (!api.auth.user) {
                    return;
                }
                const mediaAssets = await api.media.getMediaAssets(api.auth.user.orgID);
                runInAction(() => {
                    this.mediaAssetsByID = new Map(mediaAssets.map(gqlMediaTypeToMediaAsset).map((it) => [it.id, it]));
                });
            }
        });
        Object.defineProperty(this, "resolveMediaAsset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                return this.mediaAssetsByID.get(id);
            }
        });
        makeObservable(this);
        runInAction(() => {
            this.mediaAssetsByID = new Map();
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], GqlMediaProvider.prototype, "mediaAssetsByID", void 0);
