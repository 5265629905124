import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { createChoice, deleteChoice, getChoices, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Button, Tooltip, Typography } from 'lib/cortiUI';
import { useQuestion, useSaving, useTemplate } from '../../context';
import { Choice } from './Choice';
export const Choices = () => {
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions.choices' });
    const { templateID, isScoringEnabled } = useTemplate();
    const questionID = useQuestion();
    const { setIsSaving } = useSaving();
    const [choices, setChoices] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadData = async () => {
        setLoading(true);
        try {
            const choicesData = await getChoices(templateID, questionID);
            if (choicesData) {
                setChoices(choicesData);
            }
            else {
                const choice = await createNewChoice();
                setChoices([choice]);
            }
        }
        catch (error) {
            console.error(`[Template Builder]: Failed to get questions choices (id: ${questionID})`, error);
        }
        finally {
            setLoading(false);
        }
    };
    const createNewChoice = async (payload) => {
        const defaultValue = Object.assign({ score: 0, text: 'Option', auto_fail: false, not_applicable: false }, payload);
        const idResponse = await createChoice(templateID, questionID, defaultValue);
        return Object.assign({ id: idResponse.id }, defaultValue);
    };
    const addChoice = async (payload) => {
        setLoading(true);
        setIsSaving(true);
        try {
            const choice = await createNewChoice(payload);
            setChoices((choices) => [...choices, choice]);
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.addError', 'Error adding an answer'),
                type: 'error',
            });
            console.error('[Template Builder]: Failed to create choice', error);
        }
        finally {
            setLoading(false);
            setIsSaving(false);
        }
    };
    const removeChoice = async (choiceID) => {
        setLoading(true);
        setIsSaving(true);
        try {
            await deleteChoice(templateID, questionID, choiceID);
            setChoices((choices) => choices.filter((choice) => choice.id !== choiceID));
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.removeError', 'Error removing answer'),
                type: 'error',
            });
            console.error('[Template Builder]: Failed to remove choice', error);
        }
        finally {
            setLoading(false);
            setIsSaving(false);
        }
    };
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
        React.createElement(Typography, null, t('title', 'Answers')),
        choices.map((choice, idx) => (React.createElement(Choice, { key: choice.id, choice: choice, disabled: loading, onRemove: () => removeChoice(choice.id), label: t('choiceLabel', 'Option {{count}}', { count: idx + 1 }) }))),
        React.createElement(Base, { display: "flex", gap: 4, alignItems: "center" },
            React.createElement(Button, { startIcon: React.createElement(AddRoundedIcon, null), variant: "text", size: "small", color: "primary", disabled: loading, onClick: () => addChoice() }, t('actions.add', 'Add answer')),
            !choices.some((c) => c.not_applicable) && isScoringEnabled && (React.createElement(Tooltip, { title: t('actions.notApplicableTooltip', 'A ‘Not Applicable’ answer option allow the reviewer to mark the question as not applicable to the review. Such questions do not count towards the total Assessment score.') },
                React.createElement(Base, null,
                    React.createElement(Button, { startIcon: React.createElement(AddRoundedIcon, null), variant: "text", size: "small", disabled: loading, onClick: () => addChoice({ not_applicable: true, text: 'N/A (Not Applicable)' }) }, t('actions.addNotApplicableChoice', 'Add N/A answer'))))))));
};
