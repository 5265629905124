var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { Observer } from '@corti/observer';
export class TakeoverRequests {
    constructor(input) {
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "map", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.session = input.session;
        this.observer = new Observer();
        runInAction(() => {
            this.map = new Map(input.state.takeoverRequests.map((it) => [it.id, it]));
        });
    }
    reset(state) {
        this.map = state.takeoverRequests
            ? new Map(state.takeoverRequests.map((it) => [it.id, it]))
            : this.map;
    }
    create(request) {
        this.map.set(request.id, request);
        this.observer.fireEvent('takeover-request-created', request);
        return request;
    }
    accept(requestID) {
        var _a;
        const request = this.getByID(requestID);
        if (!request) {
            (_a = this.session.logger) === null || _a === void 0 ? void 0 : _a.warn('takeover request not found');
            return;
        }
        this.map = new Map();
        this.observer.fireEvent('takeover-request-accepted', request);
    }
    decline(requestID) {
        var _a;
        const request = this.getByID(requestID);
        if (!request) {
            (_a = this.session.logger) === null || _a === void 0 ? void 0 : _a.warn('takeover request not found');
            return;
        }
        this.map.delete(requestID);
        this.observer.fireEvent('takeover-request-declined', request);
    }
    cancel(requestID) {
        var _a;
        const request = this.getByID(requestID);
        if (!request) {
            (_a = this.session.logger) === null || _a === void 0 ? void 0 : _a.warn('takeover request not found');
            return;
        }
        this.map.delete(requestID);
        this.observer.fireEvent('takeover-request-canceled', request);
    }
    getByID(requestID) {
        return this.map.get(requestID);
    }
    get all() {
        return Array.from(this.map.values());
    }
    get sent() {
        return this.all.filter((it) => it.createdBy.id === this.session.currentUser.id);
    }
    get received() {
        return this.all.filter((it) => it.createdBy.id !== this.session.currentUser.id);
    }
    serialize() {
        return cloneDeep({
            takeoverRequests: toJS(this.all),
        });
    }
    onCreate(cb) {
        return this.observer.on('takeover-request-created', cb);
    }
    onAccept(cb) {
        return this.observer.on('takeover-request-accepted', cb);
    }
    onDecline(cb) {
        return this.observer.on('takeover-request-declined', cb);
    }
    onCancel(cb) {
        return this.observer.on('takeover-request-canceled', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], TakeoverRequests.prototype, "map", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], TakeoverRequests.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], TakeoverRequests.prototype, "create", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TakeoverRequests.prototype, "accept", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TakeoverRequests.prototype, "decline", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TakeoverRequests.prototype, "cancel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Object)
], TakeoverRequests.prototype, "getByID", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TakeoverRequests.prototype, "all", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TakeoverRequests.prototype, "sent", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TakeoverRequests.prototype, "received", null);
