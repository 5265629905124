import Ajv from 'ajv';
import { Errors, RpcReservedErrors, } from '@corti/lib/publicApiFramework';
import { Observer } from '@corti/observer';
const actionTypeSchema = {
    type: 'object',
    properties: {
        method: {
            type: 'string',
        },
        params: {
            type: 'object',
        },
    },
    required: ['method'],
};
const ajv = new Ajv();
const validateActionType = ajv.compile(actionTypeSchema);
export class PublicApiFramework {
    constructor(...adapters) {
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "methodRegistry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "adapters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "exposeMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (def) => {
                if (this.methodRegistry.has(def.name)) {
                    throw new Error(`Method '${def.name}' already exposed. Call 'unexposeMethod' first before adding a new handler with the same name`);
                }
                let validateFn;
                if ('paramsJSONSchema' in def) {
                    validateFn = new Ajv({ allowUnionTypes: true }).compile(def.paramsJSONSchema);
                }
                this.methodRegistry.set(def.name, { handler: def.handler, validateFn });
            }
        });
        Object.defineProperty(this, "unexposeMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.methodRegistry.delete(name);
            }
        });
        Object.defineProperty(this, "callMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (action) => {
                var _a, _b;
                if (!validateActionType(action)) {
                    return {
                        error: Errors.INVALID_PARAMS({
                            readableText: (_b = "'callMethod' input " +
                                ((_a = validateActionType.errors) === null || _a === void 0 ? void 0 : _a.map((err) => err.message).join('.'))) !== null && _b !== void 0 ? _b : '',
                        }),
                    };
                }
                try {
                    const result = await Promise.any([
                        this.localCallMethod(action),
                        ...this.adapters.map((a) => a.remoteCallMethod(action)),
                    ]);
                    // when a method handler returns an error
                    // we wrap it in a RPC error response type
                    if (Errors.isRpcApiError(result)) {
                        return {
                            error: result,
                        };
                    }
                    // non error response type is considered a OK response
                    // so we wrap it in a standard RPC OK response body
                    return {
                        result,
                    };
                }
                catch (err) {
                    // error originated outside of the Promise.any call
                    // this is clearly the implementation mistake and should throw
                    if (!(err instanceof AggregateError)) {
                        throw err;
                    }
                    // all errors are "Method not found", meaning no adapter has this method exposed
                    if (err.errors.every((err) => Errors.isRpcApiError(err) && err.code === RpcReservedErrors.METHOD_NOT_FOUND.code)) {
                        return {
                            error: Errors.METHOD_NOT_FOUND(action.method),
                        };
                    }
                    const nonMethodNotFoundErrors = err.errors.filter((err) => !(Errors.isRpcApiError(err) && err.code === RpcReservedErrors.METHOD_NOT_FOUND.code));
                    // exceptional situation, there should not be more than one source method exposed
                    if (nonMethodNotFoundErrors.length !== 1) {
                        return {
                            error: Errors.INTERNAL_ERROR('more than two handlers responded to the same method call'),
                        };
                    }
                    const oneErr = nonMethodNotFoundErrors[0];
                    if (Errors.isRpcApiError(oneErr)) {
                        return {
                            error: oneErr,
                        };
                    }
                    // not known error needs to be wrapped in the "internal error" rpc error type
                    return {
                        error: Errors.INTERNAL_ERROR(oneErr),
                    };
                }
            }
        });
        Object.defineProperty(this, "localCallMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (action) => {
                const entry = this.methodRegistry.get(action.method);
                if (!entry) {
                    throw Errors.METHOD_NOT_FOUND(action.method);
                }
                if (entry.validateFn) {
                    if (!entry.validateFn(action.params)) {
                        throw Errors.INVALID_PARAMS({
                            readableText: getParamsValidationMsg(entry.validateFn.errors),
                        });
                    }
                }
                return await entry.handler(action.params);
            }
        });
        Object.defineProperty(this, "getAvailableMethods", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return Array.from(this.methodRegistry.keys());
            }
        });
        this.adapters = adapters.filter((a) => a != null);
        this.adapters.forEach((a) => { var _a; return (_a = a.init) === null || _a === void 0 ? void 0 : _a.call(a, this); });
    }
    fireEvent(event) {
        this.localFireEvent(event);
        this.adapters.forEach((a) => a.remoteFireEvent(event));
    }
    localFireEvent(event) {
        this.observer.fireEvent('all-events', event);
    }
    subscribeAll(cb) {
        return this.observer.on('all-events', cb);
    }
}
function getParamsValidationMsg(errors) {
    return errors
        .map((err) => {
        var _a;
        // `instancePath` is empty string when invalid property belongs to the root object
        // thus we know that the root object is "params" per our API naming
        return `'params${err.instancePath}' ${(_a = err.message) !== null && _a !== void 0 ? _a : ''}`;
    })
        .join('.');
}
