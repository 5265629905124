import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor';
import { SelectElementModel } from '../elements';
import { ElementTreeRenderer } from './ElementTreeRenderer';
function cleanupEmptyCustomProps(contentBuilderContext) {
    contentBuilderContext.getPrototypes().forEach((p) => {
        p.customProperties.removeAllEmpty();
        if (p instanceof SelectElementModel) {
            p.options.forEach((o) => o.customProperties.removeAllEmpty());
        }
    });
    contentBuilderContext.getElements().forEach((el) => {
        el.customProperties.removeAllEmpty();
    });
}
export const ContentBuilderEditor = (props) => {
    const { contentBuilderContext } = props;
    const rootRef = React.useRef(null);
    const theme = useTheme();
    const { editor } = useGraphEditorCtx();
    React.useEffect(() => {
        const keyboardBinder = editor.keyboardBinder.createInstance(rootRef.current);
        keyboardBinder.bind('mod+c', () => {
            handleCopy();
        });
        keyboardBinder.bind('mod+v', () => {
            handlePaste();
        });
        keyboardBinder.bind('mod+z', () => {
            editor.undo();
        });
        keyboardBinder.bind('mod+shift+z', () => {
            editor.redo();
        });
        keyboardBinder.bind('del', () => {
            if (contentBuilderContext.selectedElement) {
                editor.dispatch({
                    type: 'viewBuilder.deleteBlockInstance',
                    data: { instanceID: contentBuilderContext.selectedElement.id },
                });
            }
        });
        return () => {
            editor.keyboardBinder.remove(keyboardBinder);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        return () => {
            cleanupEmptyCustomProps(contentBuilderContext);
        };
    }, [contentBuilderContext]);
    function handleCopy() {
        const selectedEl = contentBuilderContext.selectedElement;
        if (!selectedEl)
            return;
        editor.dispatch({
            type: 'viewBuilder.copyInstances',
            data: {
                instanceIDs: [selectedEl.id],
            },
        });
    }
    function handlePaste() {
        editor.dispatch({
            type: 'viewBuilder.pasteInstances',
            data: { contentBuilderID: contentBuilderContext.id },
        });
    }
    return (React.createElement(Base, { ref: rootRef, background: theme.palette.background.default, py: 8, px: 4, display: "grid", justifyItems: "center", alignItems: "flex-start", tabIndex: 0, width: '100%', height: '100%', className: css(getScrollerCss({ theme }), {
            ':focus-within': {
                boxShadow: `inset 0 0 1px 1px ${theme.palette.background.divider}`,
            },
        }) },
        React.createElement(ElementTreeRenderer, { contentBuilderContext: contentBuilderContext })));
};
