import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { createRichText, stringifyRichText } from '@corti/richtext';
import { useTheme } from '@corti/theme';
import { Box, Button, Input, Label, ModalContent, ModalHeader, RichTextEditor, RichTextEditorToolbar, Typography, getRichTextInputStyles, } from 'lib/cortiUI';
import { CommitsList } from '../../components/commits';
import { getProtocolGraphVersionQuery } from '../graphql';
const updateVersionMutation = gql `
  mutation ensureProtocolGraphVersion($input: ProtocolGraphVersionInput!) {
    ensureProtocolGraphVersion(input: $input)
  }
`;
export function EditVersionDetailsView(props) {
    var _a, _b, _c;
    const theme = useTheme();
    const { t } = useTranslation();
    const query = useQuery(getProtocolGraphVersionQuery(), { variables: { id: props.versionID } });
    const [updateVersion, updateVersionResult] = useMutation(updateVersionMutation);
    const [description, setDescription] = React.useState(createRichText());
    const [versionName, setVersionName] = React.useState('');
    React.useEffect(() => {
        var _a, _b;
        if (query.data) {
            setDescription((_b = (_a = query.data.protocolGraphVersion.description) === null || _a === void 0 ? void 0 : _a.richText) !== null && _b !== void 0 ? _b : createRichText());
            setVersionName(query.data.protocolGraphVersion.name);
        }
    }, [query]);
    const handleSubmit = async () => {
        var _a;
        if (!query.data) {
            return;
        }
        try {
            await updateVersion({
                variables: {
                    input: {
                        graphID: props.graphID,
                        id: props.versionID,
                        name: versionName,
                        description: {
                            plainText: stringifyRichText(description),
                            richText: description,
                        },
                    },
                },
            });
            await query.refetch();
            (_a = props.onSuccess) === null || _a === void 0 ? void 0 : _a.call(props);
        }
        catch (err) {
            console.error(err);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: ((_a = query.data) === null || _a === void 0 ? void 0 : _a.protocolGraphVersion.release)
                ? t('editorApp2:editVersionDetailsView.titleReleased', 'Update release notes')
                : t('editorApp2:editVersionDetailsView.titleDraft', 'Update draft notes') }),
        React.createElement(ModalContent, null, query.error ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body2", color: "default" }, t('editorApp2:editVersionDetailsView.graphRetrievalError', 'Error: unable to retrieve graph version data')))) : (React.createElement(Box, { gap: 8, "data-cy": "update-version-details-dialog" },
            React.createElement(Box, { gap: 3 },
                React.createElement(Label, { htmlFor: 'version-field' }, t('editorApp2:editVersionDetailsView.versionNameLabel', 'Version name')),
                React.createElement(Input, { id: "version-field", value: versionName, onChange: (e) => setVersionName(e.target.value) })),
            React.createElement(Box, { gap: 3 },
                React.createElement(Label, null, t('editorApp2:editVersionDetailsView.descriptionLabel', 'Description')),
                React.createElement(RichTextEditor, { className: getRichTextInputStyles({ theme }), value: description, style: { minHeight: theme.typography.fontSize * 12 }, onChange: (v) => setDescription(v) },
                    React.createElement(RichTextEditorToolbar, null))),
            React.createElement(Box, { gap: 4 },
                React.createElement(CommitsList, { commits: (_c = (_b = query.data) === null || _b === void 0 ? void 0 : _b.protocolGraphVersion.commits) !== null && _c !== void 0 ? _c : [] })),
            React.createElement(Box, { flexDirection: "row", alignItems: "center", gap: 4 },
                React.createElement(Button, { "data-cy": "update-version-details-submit-button", color: "primary", disabled: updateVersionResult.loading, onClick: () => {
                        void handleSubmit();
                    } }, t('editorApp2:editVersionDetailsView.saveBtn', 'Save')),
                React.createElement(Button, { onClick: props.onCancel }, t('editorApp2:editVersionDetailsView.cancelBtn', 'Cancel'))))))));
}
