import { gql } from '@apollo/client';
export const getPerformanceViewsQuery = gql `
  query performanceViews($organizationID: ID!, $userID: ID!) {
    performanceViews(organizationID: $organizationID, userID: $userID) {
      id
      title
      visibility
      locked
      createdBy {
        id
      }
      folder
    }
  }
`;
