import React from 'react';
const initialState = '';
const QuestionContext = React.createContext(initialState);
export const QuestionProvider = ({ questionID, children }) => {
    const value = React.useMemo(() => questionID, [questionID]);
    return React.createElement(QuestionContext.Provider, { value: value }, children);
};
export const useQuestion = () => {
    const context = React.useContext(QuestionContext);
    if (!context) {
        throw new Error('useQuestion must be used within a QuestionProvider');
    }
    return context;
};
