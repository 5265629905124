import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { Sidebar, SidebarActionButton, SidebarSection } from 'lib/cortiUI';
import { SidebarOrganizationItem } from './SidebarOrganizationItem';
export const AdminPanelSidebar = ({ activeOrganizationID, organizations, onOrganizationClick, }) => {
    const renderOrgsList = () => organizations.map((org) => (React.createElement(SidebarOrganizationItem, { selected: org.id === activeOrganizationID, onClick: onOrganizationClick, key: org.id, organization: org })));
    return (React.createElement(Sidebar, null,
        React.createElement(SidebarSection, { title: intl.t('adminPanel:navigationBar.orgConfigManagementTab', 'Organizations'), actions: React.createElement(SidebarActionButton, { icon: React.createElement(AddRoundedIcon, { fontSize: "small" }), tooltipTitle: intl.t('adminPanel:navigationBar.action', 'Create an organization') }) }, renderOrgsList())));
};
