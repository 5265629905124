import React from 'react';
import { intl } from '@corti/i18n';
import { Base, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { CaseMergeView } from './CaseMergeView';
export function TopToolbarMergeModal({ caseEntity, isCaseMergeViewOpen, closeCasesMergeView, handleCasesMerge, }) {
    return (React.createElement(Modal, { open: isCaseMergeViewOpen, onClose: closeCasesMergeView },
        React.createElement(Base, null,
            React.createElement(ModalHeader, { title: intl.t('explore:caseView.caseMerge.mergeCases', 'Merge cases') }),
            React.createElement(ModalContent, null,
                React.createElement(CaseMergeView, { parentCase: Object.assign(Object.assign({}, caseEntity), { startedAt: caseEntity.startedAt.toISOString() }), onClose: closeCasesMergeView, onMerge: handleCasesMerge })))));
}
