import { gql } from '@apollo/client';
export const performanceMetricsSourceFragment = gql `
  fragment PerformanceMetricsSource on PerformanceMetricsSource {
    text
    title
    key {
      entityID
      type
    }
  }
`;
export const getPerformanceMetricsSourcesQuery = gql `
  query performanceMetricsSources($organizationID: ID!) {
    performanceMetricsSources(organizationID: $organizationID) {
      ...PerformanceMetricsSource
    }
  }
  ${performanceMetricsSourceFragment}
`;
