import React from 'react';
import { useRouteMatch } from 'react-router';
import { CaseViewContent } from './CaseViewContent';
import { InteractionProvider } from './context';
export const CaseView = () => {
    const { params: { interactionID }, } = useRouteMatch();
    if (!interactionID) {
        throw Error('`interactionID` must be provided as Route parameters to use `Case View` component ');
    }
    return (React.createElement(InteractionProvider, { id: interactionID },
        React.createElement(CaseViewContent, null)));
};
