import React from 'react';
import { addDays, endOfDay, startOfDay } from '@corti/date';
import { DateRangePicker, DateSinglePicker, } from 'lib/cortiUI';
export function DateSelectContent({ selectType, selectedDate, onChange }) {
    const max = new Date();
    const min = startOfDay(new Date(2000, 0));
    const defaultSelectedDate = selectType === 'range'
        ? {
            startDate: startOfDay(addDays(max, -7)),
            endDate: endOfDay(max),
        }
        : max;
    selectedDate = selectedDate || defaultSelectedDate;
    const handleRelativeDateRangeSelect = (_, absoluteDateRange) => {
        if (absoluteDateRange) {
            handleDateRangeChange({
                startDate: absoluteDateRange.start,
                endDate: absoluteDateRange.end,
            });
        }
    };
    const handleDateRangeChange = (input) => {
        if (selectType === 'range') {
            onChange(input);
        }
    };
    const handleSingleDateChange = (input) => {
        if (selectType === 'single') {
            onChange(input);
        }
    };
    switch (selectType) {
        case 'single':
            return (React.createElement(DateSinglePicker, { selectedDate: selectedDate, onChange: handleSingleDateChange, withTime: true, maxDate: max, minDate: min }));
        case 'range':
            return (React.createElement(DateRangePicker, { maxDate: max, minDate: min, range: selectedDate, onChange: handleDateRangeChange, withTime: true, relativeDateRangePickerProps: {
                    enabled: true,
                    onSelect: handleRelativeDateRangeSelect,
                    dateRanges: (ranges) => {
                        return [
                            ranges.LAST_YEAR,
                            ranges.THIS_YEAR,
                            ranges.LAST_MONTH,
                            ranges.THIS_MONTH,
                            ranges.LAST_WEEK,
                            ranges.LAST_30_DAYS,
                            ranges.YESTERDAY,
                        ];
                    },
                } }));
    }
}
