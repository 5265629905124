import React from 'react';
import { getLoadedLocales, intl } from '@corti/i18n';
import { isValidEmail } from '@corti/strings';
import { css } from '@corti/style';
import { getConfig } from 'browser/services/callSubscriberConfig.service';
import { ThemeUtils } from 'browser/themes';
import { Autocomplete, Box, Button, Separator, TextField } from 'lib/cortiUI';
export function ProfileSettingsForm(props) {
    var _a, _b, _c;
    const [name, setName] = React.useState(props.settings.name);
    const [email, setEmail] = React.useState(props.settings.email);
    const [extension, setExtension] = React.useState(props.settings.extension);
    const [externalID, setExternalID] = React.useState(props.settings.externalID);
    const [themeID, setThemeID] = React.useState(props.settings.themeID);
    const [locale, setLocale] = React.useState(props.settings.locale);
    const translations = {
        en: intl.t('languages.en', 'English'),
        da: intl.t('languages.da', 'Danish'),
        'en-US-sfd': intl.t('languages.en-US-sfd', 'English (US) SFD'),
        fr: intl.t('languages.fr', 'French'),
        it: intl.t('languages.it', 'Italian'),
        sv: intl.t('languages.sv', 'Swedish'),
    };
    const localeItemOptions = getLoadedLocales(intl).map((locale) => {
        var _a;
        return {
            text: (_a = translations[locale]) !== null && _a !== void 0 ? _a : locale,
            value: locale,
        };
    });
    const themeItemOptions = ThemeUtils.getThemes().map((theme) => {
        return {
            text: theme.name,
            value: theme.id,
        };
    });
    const selectedLocaleItem = localeItemOptions.find((i) => i.value === locale);
    const selectedThemeItem = themeItemOptions.find((i) => i.value === themeID);
    function isFormValid() {
        return email && name && isValidEmail(email);
    }
    return (React.createElement("form", { onSubmit: (e) => {
            e.preventDefault();
            props.onSubmit({
                name,
                email,
                extension,
                externalID,
                themeID,
                locale,
            });
        } },
        React.createElement(Box, { gap: 6 },
            React.createElement(TextField, { label: intl.t('settings:settingsForm.inputLabel1', 'Name'), name: "name", value: name, onChange: (e) => setName(e.target.value) }),
            React.createElement(TextField, { label: intl.t('settings:settingsForm.inputLabel2', 'Email'), name: "email", autoComplete: "off", type: "email", value: email, onChange: (e) => setEmail(e.target.value) }),
            React.createElement(TextField, { fullWidth: true, label: intl.t('settings:settingsForm.inputLabel3', 'Extension (Optional)'), name: "extension", value: extension, onChange: (e) => setExtension(e.target.value), disabled: Boolean((_a = getConfig()) === null || _a === void 0 ? void 0 : _a.phoneExtension), helperText: ((_b = getConfig()) === null || _b === void 0 ? void 0 : _b.phoneExtension) && (React.createElement(React.Fragment, null,
                    intl.t('settings:settingsForm.workstationExtensionInUse', 'Using workstation extension'),
                    ": ", (_c = getConfig()) === null || _c === void 0 ? void 0 :
                    _c.phoneExtension)) }),
            React.createElement(TextField, { label: intl.t('settings:settingsForm.inputLabel4', 'External ID (Optional)'), name: "externalID", value: externalID, onChange: (e) => setExternalID(e.target.value) }),
            React.createElement(Separator, { horizontal: true }),
            React.createElement(Autocomplete, { size: "small", options: localeItemOptions, getOptionLabel: (option) => option.text, value: selectedLocaleItem, onChange: (_, option) => {
                    if (option) {
                        setLocale(option.value.toString());
                    }
                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('settings:locale', 'Language') }))), clearIcon: null }),
            React.createElement(Autocomplete, { size: "small", options: themeItemOptions, getOptionLabel: (option) => option.text, value: selectedThemeItem, onChange: (_, option) => {
                    if (option) {
                        setThemeID(option.value.toString());
                    }
                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('settings:settingsForm.inputLabel5', 'App Theme') }))), clearIcon: null }),
            React.createElement(Button, { color: "primary", disabled: !isFormValid(), type: "submit", className: css({ width: 200 }) }, intl.t('settings:settingsForm.submitButtonTitle', 'Save Changes')))));
}
