import { useMachine } from '@xstate/react';
import React, { useEffect, useRef } from 'react';
import { ChecklistCollection } from './components';
import { isCollectionEvent, startCollectionMachine } from './data/collection';
import { useSyncChecklists } from './data/useSyncChecklists';
export function ChecklistPanel({ allChecklists, currentChecklistIds, snapshot, autoCheckedEntryIds, listener, }) {
    const [state, send, service] = useMachine(() => {
        const initialChecklists = allChecklists.filter((checklist) => currentChecklistIds.includes(checklist.id));
        return startCollectionMachine(initialChecklists, snapshot);
    });
    useEffect(() => {
        const callback = (event) => {
            if (!isCollectionEvent(event)) {
                return;
            }
            // Invoke listener on auto collapse and auto expand
            if (event.type === 'AUTOCOLLAPSED' || event.type === 'AUTOEXPANDED') {
                const type = event.type === 'AUTOCOLLAPSED' ? 'collapse' : 'expand';
                listener({ type, checklistId: event.id });
            }
        };
        service.onEvent(callback);
        return () => void service.off(callback);
    }, [service, listener]);
    const originalSnapshotRef = useRef(snapshot);
    // If checklists update from the outside (by visibility changing), sync them with the machine
    useSyncChecklists({
        send,
        allChecklists,
        currentChecklistIds,
        snapshot: originalSnapshotRef,
        autoCheckedEntryIds,
    });
    return React.createElement(ChecklistCollection, { listener: listener, checklists: state.context.checklists });
}
