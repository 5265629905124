import React from 'react';
import { Base, Card, CardContent, Skeleton } from 'lib/cortiUI';
export const ChartLoader = () => {
    return (React.createElement(Card, { m: 0, height: "100%" },
        React.createElement(CardContent, { sx: { width: '100%' } },
            React.createElement(Base, { display: "flex", justifyContent: "space-between", mb: 10, alignItems: "center", gap: 7 },
                React.createElement(Skeleton, { variant: "text", sx: { flex: 3 } }),
                React.createElement(Skeleton, { variant: "rounded", height: 30, sx: { flex: 2 } })),
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" }),
                React.createElement(Skeleton, { variant: "rounded", height: 5, width: "100%" })))));
};
