import React, { useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ExpressionCard } from './ExpressionCard';
import { ExpressionTextResult } from './ExpressionTextResult';
import { RichTextElement } from './RichTextElement';
import * as Commands from './commands';
import { useRichTextEditor } from './context';
export function HasExpression(props) {
    const element = props.element;
    const richTextEditor = useRichTextEditor();
    const slateEditor = useSlate();
    const theme = useTheme();
    const [isEditingExpression, setIsEditingExpression] = useState(false);
    const [tempExpression, setTempExpression] = useState(null);
    const [validationResult, setValidationResult] = useState();
    if (richTextEditor.readOnly) {
        return React.createElement(ExpressionTextResult, Object.assign({}, props));
    }
    const onExpressionRemove = () => {
        const path = ReactEditor.findPath(slateEditor, props.element);
        Transforms.unwrapNodes(slateEditor, { at: path });
    };
    const startEditingExpression = () => {
        setIsEditingExpression(true);
        setTempExpression(element.attributes.expression);
    };
    const onExpressionSave = () => {
        if (!validationResult || validationResult.type === 'error') {
            return;
        }
        const path = ReactEditor.findPath(slateEditor, props.element);
        if (!tempExpression) {
            Commands.removeExpression(slateEditor, { at: path });
            return;
        }
        const expression = tempExpression;
        Transforms.setNodes(slateEditor, { type: 'hasexpression', attributes: { expression }, children: [] }, { at: path });
        setIsEditingExpression(false);
        setTempExpression(null);
    };
    const onExpressionCancel = () => {
        setIsEditingExpression(false);
        setTempExpression(null);
    };
    const expressionCardContent = (React.createElement(ExpressionCard, { isEditable: !richTextEditor.readOnly, isEditing: isEditingExpression, content: isEditingExpression ? tempExpression : element.attributes.expression, onExpressionSave: onExpressionSave, onExpressionRemove: onExpressionRemove, onExpressionEdit: startEditingExpression, onExpressionCancel: onExpressionCancel, onValueChange: (val) => {
            setTempExpression(val);
        }, setValidationResult: setValidationResult, validationResult: validationResult }));
    return (React.createElement(RichTextElement, { renderCard: expressionCardContent, isEditing: isEditingExpression, attributes: props.attributes, children: props.children, className: css({
            backgroundColor: transparentize(0.9, theme.palette.primary.light),
            border: `2px dashed ${theme.palette.primary.light}`,
        }) }));
}
