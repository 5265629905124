import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { Route } from 'browser/containers/Route';
import { GraphEditorRoute } from './grapheditor/GraphEditorRoute';
import { GraphListView } from './graphlist/GraphListView';
export function EditorView() {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: `${match.path}/editor/:versionID`, component: GraphEditorRoute }),
        React.createElement(Route, { path: `${match.path}`, component: GraphListView })));
}
