var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route as RouterRoute, useRouteMatch, } from 'react-router-dom';
import { useBreadcrumbActionsContext } from './context';
export function Route(props) {
    const { path } = props, rest = __rest(props, ["path"]);
    return (React.createElement(RouterRoute, { path: path },
        React.createElement(WrappedRoute, Object.assign({ path: path }, rest))));
}
function WrappedRoute(props) {
    const { breadcrumb } = props, rest = __rest(props, ["breadcrumb"]);
    return (React.createElement(React.Fragment, null,
        breadcrumb && React.createElement(RouteBreadcrumb, { breadcrumb: breadcrumb }),
        React.createElement(RouterRoute, Object.assign({}, rest))));
}
export function RouteBreadcrumb({ breadcrumb }) {
    const breadcrumbCtx = useBreadcrumbActionsContext();
    if (!breadcrumbCtx) {
        throw new Error(`RouteBreadcrumb component must be used inside inside breadcrumb actions context provider`);
    }
    const match = useRouteMatch();
    React.useEffect(() => {
        breadcrumbCtx.registerRoute(match.url, () => typeof breadcrumb === 'string' ? breadcrumb : breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb({ match }));
        return () => {
            if (breadcrumb) {
                breadcrumbCtx.unregisterRoute(match.url);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breadcrumb, match]);
    return null;
}
