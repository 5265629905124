var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { action, computed, makeObservable, observable, override } from 'mobx';
import { ElementSerializer, } from 'lib/graphEditor/ElementSerializer';
import { BaseGraphEditorNode } from '../common/BaseGraphEditorNode';
export class LinkPortalNodeModel extends BaseGraphEditorNode {
    constructor() {
        super({ type: 'linkPortalNode', hasInputPort: true });
        Object.defineProperty(this, "targetNodeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
    }
    setTargetNode(id) {
        this.targetNodeID = id;
    }
    get targetNode() {
        var _a;
        if (this.targetNodeID) {
            return (_a = this.graphEditorModelContext) === null || _a === void 0 ? void 0 : _a.getNodeByID(this.targetNodeID);
        }
    }
    get readableTitle() {
        if (this.name.trim() !== '') {
            return this.name;
        }
        return `Link Portal`;
    }
    getEncodableData() {
        var _a;
        return Object.assign(Object.assign({}, super.getEncodableData()), { targetNodeID: (_a = this.targetNode) === null || _a === void 0 ? void 0 : _a.id });
    }
    deserialize(entity, ctx) {
        super.deserialize(entity, ctx);
        this.setTargetNode(entity.targetNodeID);
    }
    copy() {
        const i = super.copy();
        i.setTargetNode(this.targetNodeID);
        return i;
    }
    toString() {
        return this.id;
    }
}
Object.defineProperty(LinkPortalNodeModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val, ctx) => {
        const i = new LinkPortalNodeModel();
        i.deserialize(val, ctx);
        return i;
    }
});
__decorate([
    observable,
    __metadata("design:type", String)
], LinkPortalNodeModel.prototype, "targetNodeID", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], LinkPortalNodeModel.prototype, "setTargetNode", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LinkPortalNodeModel.prototype, "targetNode", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], LinkPortalNodeModel.prototype, "readableTitle", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _b : Object, Object]),
    __metadata("design:returntype", void 0)
], LinkPortalNodeModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LinkPortalNodeModel.prototype, "copy", null);
ElementSerializer.register(LinkPortalNodeModel, 'LinkPortalNodeModel');
