import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { repositories } from 'core/repositories';
import { CaseReviewRequestsView } from './CaseReviewRequestsView';
const mod = {
    id: 'case-review-requests-module',
    title: () => intl.t('caseReviewRequestsModule:appName', 'Review Queue'),
    path: '/case-review-requests',
    icon: ReviewsRoundedIcon,
    requiresUserPermission: 'app:case_review_request',
    requiresOrgFeature: 'case-review-request',
    viewComponent: (props) => (React.createElement(CaseReviewRequestsView, Object.assign({}, props, { dependencies: {
            repositories: {
                users: repositories.users,
            },
            api: {
                caseFeedback: api.caseFeedback,
            },
        } }))),
    navGroupe: 1,
};
export default mod;
