import React from 'react';
import { Typography } from 'lib/cortiUI';
export const TrendWidget = ({ value }) => {
    if (!value)
        return (React.createElement(Typography, { noWrap: true, variant: "body2", color: "default" }, value !== null && value !== void 0 ? value : '-'));
    if (value > 0)
        return (React.createElement(Typography, { noWrap: true, variant: "body2", color: "success" }, `${(value * 100).toFixed(0)}%`));
    if (value < 0)
        return (React.createElement(Typography, { noWrap: true, variant: "body2", color: "error" }, `${(value * 100).toFixed(0)}%`));
    return null;
};
