var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Button, Typography } from 'lib/cortiUI';
export const FilterButton = (props) => {
    const { icon, title } = props, rest = __rest(props, ["icon", "title"]);
    const theme = useTheme();
    return (React.createElement(Button, Object.assign({ variant: "text", className: css({
            padding: 0,
            paddingLeft: theme.space[4],
            paddingRight: theme.space[4],
            minWidth: 40,
            height: 30,
            textTransform: 'lowercase',
        }) }, rest), icon !== null && icon !== void 0 ? icon : null,
        title && (React.createElement(Typography, { ml: 3, variant: "body2" }, title))));
};
