import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { orgFragment, toOrgConfig } from '../organizations';
import { roleFragment } from '../roles';
export const userFragment = gql `
  fragment User on User {
    id
    name
    email
    extension
    externalID
    organization {
      id
    }
    role {
      id
      name
    }
    createdAt
  }
`;
const getCurrentUserAndOrganization = gql `
  query getCurrentUserAndOrganization($id: ID!) {
    user(id: $id) {
      ...User
      role {
        ...Role
      }
      settings {
        locale
      }
      clientSettings
      organization {
        ...Organization
      }
    }
  }
  ${orgFragment}
  ${roleFragment}
  ${userFragment}
`;
export const getAllUsersQuery = gql `
  query getAllUsers($organizationID: ID!) {
    users(organizationID: $organizationID) {
      ...User
    }
  }
  ${userFragment}
`;
export const createUserMutation = gql `
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      ...User
    }
  }
  ${userFragment}
`;
export const updateUserMutation = gql `
  mutation updateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      ...User
    }
  }
  ${userFragment}
`;
export const deleteUserMutation = gql `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
function toServerUserSettings(settings) {
    const { locale, themeID, disabledTimelineFilterIDs, casesTableColumns, caseFilterSearchTypeId } = settings;
    const serverSettings = {
        settings: {},
        clientSettings: {},
    };
    if (locale) {
        serverSettings.settings.locale = locale;
    }
    if (themeID) {
        serverSettings.clientSettings.themeID = themeID;
    }
    if (caseFilterSearchTypeId) {
        serverSettings.clientSettings.caseFilterSearchTypeId = caseFilterSearchTypeId;
    }
    if (disabledTimelineFilterIDs) {
        serverSettings.clientSettings.disabledTimelineFilterIDs = disabledTimelineFilterIDs;
    }
    if (casesTableColumns) {
        serverSettings.clientSettings.casesTableColumns = casesTableColumns;
    }
    return serverSettings;
}
function toUserSettings({ settings, clientSettings, }) {
    return Object.assign(Object.assign({}, settings), parseServerUserClientSettings(clientSettings));
}
function parseServerUserClientSettings(clientSettings) {
    const parsed = {};
    if (clientSettings) {
        if (typeof clientSettings.themeID === 'string') {
            parsed.themeID = clientSettings.themeID;
        }
        if (typeof clientSettings.isAlertsDisabled === 'boolean') {
            parsed.isAlertsDisabled = clientSettings.isAlertsDisabled;
        }
        if (typeof clientSettings.caseFilterSearchTypeId === 'string') {
            parsed.caseFilterSearchTypeId = clientSettings.caseFilterSearchTypeId;
        }
        if (Array.isArray(clientSettings.disabledTimelineFilterIDs)) {
            parsed.disabledTimelineFilterIDs = clientSettings.disabledTimelineFilterIDs.filter((id) => {
                return typeof id === 'string';
            });
        }
        if (Array.isArray(clientSettings.casesTableColumns)) {
            parsed.casesTableColumns = clientSettings.casesTableColumns;
        }
    }
    return parsed;
}
export const userQueries = (client) => ({
    async create(input) {
        const serverInput = input.settings
            ? Object.assign(Object.assign({}, omit(input, ['settings'])), toServerUserSettings(input.settings)) : input;
        const response = await client.mutate({
            mutation: createUserMutation,
            variables: {
                user: serverInput,
            },
        });
        const user = Object.assign(Object.assign({}, omit(response.data.createUser, ['settings', 'clientSettings'])), { settings: toUserSettings({
                settings: response.data.createUser.settings,
                clientSettings: response.data.createUser.clientSettings,
            }) });
        return user;
    },
    async getCurrentUserAndOrganization(userId) {
        const response = await client.query({
            query: getCurrentUserAndOrganization,
            variables: { id: userId },
            fetchPolicy: 'no-cache',
        });
        const userAndOrg = Object.assign(Object.assign({}, omit(response.data.user, ['settings', 'clientSettings'])), { settings: toUserSettings({
                settings: response.data.user.settings,
                clientSettings: response.data.user.clientSettings,
            }), organization: Object.assign(Object.assign({}, omit(response.data.user.organization, [
                'clientSettings',
                'organizationSecuritySettings',
                'config',
            ])), { clientSettings: response.data.user.organization.clientSettings, organizationSecuritySettings: response.data.user.organization.organizationSecuritySettings, config: toOrgConfig(response.data.user.organization.config) }) });
        return userAndOrg;
    },
    async getAll(organizationID) {
        const response = await client.query({
            query: getAllUsersQuery,
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        const users = response.data.users.map((resUser) => {
            const user = Object.assign(Object.assign({}, omit(resUser, ['settings', 'clientSettings'])), { settings: toUserSettings({
                    settings: resUser.settings,
                    clientSettings: resUser.clientSettings,
                }) });
            return user;
        });
        return users;
    },
    async update(userId, input) {
        const serverInput = input.settings
            ? Object.assign(Object.assign({}, omit(input, ['settings'])), toServerUserSettings(input.settings)) : input;
        const response = await client.mutate({
            mutation: updateUserMutation,
            variables: {
                id: userId,
                user: serverInput,
            },
        });
        const user = Object.assign(Object.assign({}, omit(response.data.updateUser, ['settings', 'clientSettings'])), { settings: toUserSettings({
                settings: response.data.updateUser.settings,
                clientSettings: response.data.updateUser.clientSettings,
            }) });
        return user;
    },
    async delete(id) {
        const response = await client.mutate({
            mutation: deleteUserMutation,
            variables: { id },
        });
        return response.data.deleteUser;
    },
});
