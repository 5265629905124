export const createSelectionSlice = (set, get) => ({
    selected: [],
    isDeleting: false,
    toggleChecklistSelection: (id) => {
        const { selected } = get();
        if (selected.includes(id)) {
            set({ selected: selected.filter((selectedId) => selectedId !== id) });
        }
        else {
            set({ selected: [...selected, id] });
        }
    },
    toggleAllChecklistSelection: () => {
        const { selected, checklistRows } = get();
        if (selected.length === checklistRows.length) {
            set({ selected: [] });
        }
        else {
            set({ selected: checklistRows.map((row) => row.id) });
        }
    },
    clearSelection: () => set({ selected: [] }),
    startDelete: () => {
        set({ isDeleting: get().selected.length > 0 });
    },
    abortDelete: () => {
        set({ isDeleting: false });
    },
    confirmDelete: () => {
        set({ isDeleting: false, selected: [] });
    },
});
