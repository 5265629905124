import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base } from 'lib/cortiUI';
import { CHART_COLOR } from '../utils';
import { LegendItem } from './LegendItem';
export function ChartHeader({ renderRightComponent }) {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'chart' });
    return (React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "start" },
        React.createElement(Base, { display: "flex", gap: 4, pl: 4 },
            React.createElement(LegendItem, { color: CHART_COLOR, label: t('legendItem.selectedPeriod', 'Selected Period') })),
        renderRightComponent && (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" }, renderRightComponent()))));
}
