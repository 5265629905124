import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import React from 'react';
import { formatDate } from '@corti/date';
import { useTheme } from '@corti/theme';
import { Button, Popover } from 'lib/cortiUI';
import { DateRangePickerPanel } from './DateRangePickerPanel';
export function DateRangePicker(props) {
    const { dateRange, onChange } = props;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    function open(e) {
        setAnchorEl(e.currentTarget);
    }
    function close() {
        setAnchorEl(null);
    }
    function getTitle() {
        if (dateRange.type === 'absolute') {
            return `${formatDate(dateRange.absoluteRange.start)}-${formatDate(dateRange.absoluteRange.end)}`;
        }
        //FIXME: replace this with human readable (translated) relative date range
        return 'Relative range';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: open, variant: "text", sx: { color: theme.palette.text.primary }, startIcon: React.createElement(CalendarTodayRoundedIcon, { fontSize: "small" }) }, getTitle()),
        React.createElement(Popover, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: close },
            React.createElement(DateRangePickerPanel, { dateRange: dateRange, onChange: onChange }))));
}
