import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Button, Container, Page, Typography } from 'lib/cortiUI';
export const NotFound = (props) => {
    const { t } = useTranslation('performanceApp');
    const history = useHistory();
    const { title = t('notFound', 'Not found') } = props;
    const errorCode = '404';
    const navigateToOrgPerformance = () => {
        history.push('/performance');
    };
    return (React.createElement(Page, { p: 7, alignItems: "center" },
        React.createElement(Container, { className: css({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
            }) },
            React.createElement(Typography, { variant: "h2", fontWeight: 900 }, errorCode),
            React.createElement(Typography, null, title),
            React.createElement(Button, { variant: "text", color: "primary", onClick: navigateToOrgPerformance }, t('navigateToOrgPerformanceBtn', 'Back to organization performance')))));
};
