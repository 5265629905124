import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Tooltip } from 'lib/cortiUI';
import { useChecklistRouting } from '../useChecklistRouting';
export function ChecklistInspectorButtons({ formId, isAdding, canSave, }) {
    const { t } = useTranslation('libGraphEditor');
    const { closeDialog } = useChecklistRouting();
    const saveButtonLabel = isAdding
        ? t('checklist.checklistInfo.addBtn', 'Add')
        : t('checklist.checklistInfo.updateBtn', 'Save');
    const saveButtonTooltip = canSave
        ? undefined
        : t('checklist.checklistInfo.saveBtnTooltipDisabled', 'Please fix all errors before saving');
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { type: "button", color: "primary", variant: "outlined", onClick: closeDialog }, t('checklist.checklistInfo.cancelBtn', 'Cancel')),
        React.createElement(Tooltip, { title: saveButtonTooltip },
            React.createElement(Base, null,
                React.createElement(Button, { type: "submit", form: formId, color: "primary", disabled: !canSave, sx: { pointerEvents: 'auto' } }, saveButtonLabel)))));
}
