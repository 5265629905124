import { Dexie } from '@corti/lib/indexedDB/dexie';
const IDB_NAME = 'EmergencyTriageDB';
const IDB_VERSION = 1;
const GRAPHS_TABLE_NAME = 'graphs';
const IDB_DEFAULT_GRAPH_ID = 'current-graph';
function createIDB() {
    const dexie = new Dexie(IDB_NAME);
    dexie.version(IDB_VERSION).stores({ graphs: 'id' });
    return dexie;
}
export async function clearStorage() {
    const idb = createIDB();
    try {
        await idb.open();
        await idb.table(GRAPHS_TABLE_NAME).clear();
    }
    catch (err) {
        console.error(err);
        throw new Error(`Cannot clear index db graphs storage`);
    }
}
export async function saveCurrentGraph(graph) {
    const idb = createIDB();
    try {
        await idb.open();
    }
    catch (err) {
        console.error(err);
        throw new Error('Cannot save graph: unable to open database');
    }
    try {
        await idb.table(GRAPHS_TABLE_NAME).put({
            id: IDB_DEFAULT_GRAPH_ID,
            graph,
        });
    }
    catch (err) {
        console.error(err);
        throw new Error(`Cannot save current graph to storage`);
    }
}
export async function loadCurrentGraph() {
    const idb = createIDB();
    try {
        await idb.open();
        const currentGraph = await idb
            .table(GRAPHS_TABLE_NAME)
            .get(IDB_DEFAULT_GRAPH_ID);
        if (!currentGraph) {
            return;
        }
        return currentGraph.graph;
    }
    catch (err) {
        console.error(err);
        throw new Error('Error when loading current graph from storage');
    }
}
