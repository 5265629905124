import React from 'react';
import { FixedSizeList } from 'react-window';
import { intl } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Checkbox, FormControlLabel, ListItemButton, ListItemText, Typography, } from 'lib/cortiUI';
import { GroupOuterShell } from './GroupOuterShell';
import { Input } from './Input';
export function MultipleSelectableList(props) {
    const { items, selectedItemsIds = [], onChange, withSearch = false } = props;
    const theme = useTheme();
    const [searchValue, setFilterValue] = React.useState('');
    const listItems = getFilteredItems();
    function handleSelectAll() {
        const newItems = selectedItemsIds.length < items.length ? items.map((i) => i.value) : [];
        onChange(newItems);
    }
    function handleSelect(item) {
        let newItems = selectedItemsIds;
        if (newItems.includes(item.value)) {
            newItems = newItems.filter((i) => i !== item.value);
        }
        else {
            newItems = [...newItems, item.value];
        }
        onChange(newItems);
    }
    function getFilteredItems() {
        return items.filter((i) => !searchValue || stringIncludes(i.text, searchValue));
    }
    const Row = ({ index, style, data }) => {
        const rowData = data[index];
        return (React.createElement(ListItemButton, { key: rowData.value, style: style, onClick: () => handleSelect(rowData), classes: {
                root: css({
                    backgroundColor: theme.palette.background.default,
                }),
            } },
            React.createElement(Base, { display: "flex", alignItems: "center" },
                React.createElement(Base, { mr: 3 },
                    React.createElement(Checkbox, { checked: selectedItemsIds.includes(rowData.value), disableRipple: true })),
                React.createElement(ListItemText, null,
                    React.createElement(Typography, { variant: "caption", color: "default", noWrap: true }, rowData.text)))));
    };
    return (React.createElement(React.Fragment, null,
        withSearch && (React.createElement(GroupOuterShell, { className: css({
                margin: 'auto',
                width: 200,
                height: 30,
            }) },
            React.createElement(Input, { value: searchValue, placeholder: intl.t('search', 'Search'), onChange: (e) => setFilterValue(e.target.value) }))),
        React.createElement(Base, { overflow: "hidden", my: 3, mx: 2 },
            React.createElement(Base, { mr: 3 },
                React.createElement(FormControlLabel, { label: intl.t('filterBuilder.selectAll', 'Select All'), control: React.createElement(Checkbox, { checked: items.length === (selectedItemsIds === null || selectedItemsIds === void 0 ? void 0 : selectedItemsIds.length), color: "primary", onChange: handleSelectAll, indeterminate: items.length !== (selectedItemsIds === null || selectedItemsIds === void 0 ? void 0 : selectedItemsIds.length) }) })),
            React.createElement(FixedSizeList, { className: css(getScrollerCss({ theme: theme })), height: 150, width: 200, itemCount: listItems.length, itemSize: 36, itemData: listItems }, Row))));
}
