import React from 'react';
import { isElectron } from '@corti/electron-utils';
import { rbacService, trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { moduleRegistry } from 'core/moduleRegistry/browser';
import { module as realTime } from 'modules/RealTimeApp';
import { RealTimeAlertWidget } from 'modules/RealTimeApp/RealTimeAlertWidget';
export function RealTimeAlerts() {
    const mod = moduleRegistry.getModule('real-time');
    const isAlertWidgetEnabled = isElectron() &&
        mod &&
        (!mod.requiresUserPermission || rbacService.hasPermission(mod.requiresUserPermission)) &&
        authStore.organization.config.triage.isAlertWidgetEnabled &&
        !authStore.currentUser.settings.isAlertsDisabled;
    if (!isAlertWidgetEnabled || realTime.module.status !== 'ok')
        return null;
    return (React.createElement(RealTimeAlertWidget, { realTimeController: realTime.module.controller, onAlertActionClick: (session, alert) => {
            trackerService.track('[Triage] alert clicked', {
                alertID: alert.id,
                alertTitle: alert.title,
                alertText: alert.text,
                sessionID: session.id,
                caseID: session.caseID,
                externalID: session.externalID,
                contextSource: 'widget',
            });
        } }));
}
