import OpenWithRoundedIcon from '@mui/icons-material/OpenWithRounded';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import React, { useState } from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
export function ImageRenderer({ url }) {
    const DEFAULT_ZOOM = 1;
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [orientation, setOrientation] = useState('landscape');
    const theme = useTheme();
    const resetZoomToDefault = () => setZoom(DEFAULT_ZOOM);
    const handleZoomIn = () => setZoom((oldZoom) => Math.min(oldZoom + 0.1, 2));
    const handleZoomOut = () => setZoom((oldZoom) => Math.max(oldZoom - 0.1, 0.5));
    const size = orientation === 'landscape' ? { width: '100%' } : { height: '100%' };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { style: {
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'auto',
            } },
            React.createElement("img", Object.assign({ src: url, style: {
                    margin: 'auto',
                    transform: `scale(${zoom})`,
                }, onLoad: (e) => {
                    if (e.currentTarget.naturalWidth >= e.currentTarget.naturalHeight) {
                        setOrientation('landscape');
                    }
                    else {
                        setOrientation('portrait');
                    }
                } }, size))),
        React.createElement(Box, { width: '100%', flexDirection: "row", justifyContent: 'center' },
            React.createElement(Card, { p: 2, elevation: 8, style: {
                    backgroundColor: theme.palette.background.card,
                    position: 'absolute',
                    bottom: 16,
                    zIndex: 1,
                } },
                React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                    React.createElement(Button, { variant: "text", size: "small", onClick: resetZoomToDefault },
                        React.createElement(OpenWithRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.secondary }) })),
                    React.createElement(Button, { variant: "text", size: "small", onClick: handleZoomOut },
                        React.createElement(ZoomOutRoundedIcon, { className: css({ color: theme.palette.text.secondary }) })),
                    React.createElement(Button, { variant: "text", size: "small", onClick: handleZoomIn },
                        React.createElement(ZoomInRoundedIcon, { className: css({ color: theme.palette.text.secondary }) })))))));
}
