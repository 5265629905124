var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { createRichText } from '@corti/richtext';
import { css } from '@corti/style';
import { RichTextEditor } from './RichTextEditor';
export function RichTextRenderer(_a) {
    var { value, hideMetaText = true, className } = _a, rest = __rest(_a, ["value", "hideMetaText", "className"]);
    const transformedValue = typeof value === 'string' ? createRichText(value) : value;
    return (React.createElement(RichTextEditor, Object.assign({ "data-cy": 'rich-text-renderer', hideMetaText: hideMetaText, disabled: true, readOnly: true, value: transformedValue, className: css({
            cursor: 'inherit',
            padding: 2,
            background: 'transparent',
        }, className) }, rest)));
}
