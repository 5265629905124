import { random, sample } from 'lodash';
import { GraphEditor, GraphEditorModel } from 'lib/graphEditor';
import { ContentBuilderContext, ElementFactory, } from 'lib/graphEditor/contentBuilder';
export const graphTemplates = {
    minimalValidGraph: () => {
        const graphEditor = new GraphEditor();
        const graph = new GraphEditorModel();
        const vn = graphEditor.nodeFactory.viewNode();
        const select = ElementFactory.select();
        select.setLabel('Root View Title');
        select.addOption({ text: 'option 1' });
        select.addOption({ text: 'option 2' });
        vn.contentBuilderContext.addElement(ContentBuilderContext.createBlockInstance(select));
        graph.addNode(vn);
        graph.setStartNodeID(vn.id);
        return graph;
    },
    performanceTest: (options = {
        branches: 2,
        nodesPerBranch: 2,
        elementsPerNode: 1,
        optionsPerSelectElement: 3,
        gatesPerNode: 3,
        components: 5,
        componentInstancesPerNode: 1,
        facts: 2,
    }) => {
        const graphEditor = new GraphEditor();
        const graph = new GraphEditorModel();
        let libItemIterator = graph.library.items.values();
        function createFacts() {
            Array(options.facts)
                .fill('')
                .forEach((_, factIdx) => {
                const factType = sample(['boolean', 'string']);
                graph.facts.addNewFact({
                    name: `Fact ${factIdx}`,
                    type: factType,
                });
            });
        }
        function createComponents() {
            Array(options.components)
                .fill('')
                .forEach((_, componentIdx) => {
                const select = createSelectElement(`Select component element ${componentIdx}`);
                graph.library.addElementComponent(select, `Select component ${componentIdx}`);
            });
        }
        function createSelectElement(labelText) {
            const select = ElementFactory.select();
            select.setLabel(labelText);
            Array(options.optionsPerSelectElement)
                .fill('')
                .forEach((_, idx) => {
                select.addOption({
                    text: `${labelText} Option ${idx}`,
                });
            });
            return select;
        }
        function getNextComponent() {
            let i = libItemIterator.next();
            if (i.done) {
                libItemIterator = graph.library.items.values();
                i = libItemIterator.next();
            }
            return i.value;
        }
        function populateGraph() {
            Array(options.branches)
                .fill('')
                .forEach((_, branchIdx) => {
                const b = graph.addBranch({ name: `Branch ${branchIdx}` });
                let prevNode;
                // create nodes
                Array(options.nodesPerBranch)
                    .fill('')
                    .forEach((_, nodeIdx) => {
                    const vn = graphEditor.nodeFactory.viewNode();
                    // create elements
                    Array(options.elementsPerNode)
                        .fill('')
                        .forEach((_, elementIdx) => {
                        const select = createSelectElement(`B${branchIdx} VN${nodeIdx} Single Select ${elementIdx}`);
                        vn.contentBuilderContext.addElement(ContentBuilderContext.createBlockInstance(select));
                    });
                    // create component instances
                    if (graph.library.items.size) {
                        Array(options.componentInstancesPerNode)
                            .fill('')
                            .forEach((_) => {
                            vn.contentBuilderContext.addElement(getNextComponent().createInstance());
                        });
                    }
                    // create logic gates
                    const refItems = vn.contentBuilderContext.getReferenceableItems();
                    Array(options.gatesPerNode)
                        .fill('')
                        .forEach((_) => {
                        const gate = vn.addNewLogicGate();
                        const refItem = refItems[random(0, refItems.length - 1, false)];
                        if (refItem) {
                            gate.setExpression(`\$\{${refItem.refItemValue}\}`);
                        }
                    });
                    // create links
                    if (prevNode) {
                        prevNode.logicGates.forEach((g) => {
                            g.port.link(vn.portIn);
                        });
                    }
                    b.addNode(vn);
                    vn.setPosition(290 * nodeIdx + 64, 64);
                    prevNode = vn;
                });
            });
        }
        createFacts();
        createComponents();
        populateGraph();
        graph.setStartNodeID([...graph.nodes.values()][0].id);
        return graph;
    },
    withAllTypes: ({ editor } = { editor: new GraphEditor() }) => {
        const graph = new GraphEditorModel();
        const vn = editor.nodeFactory.viewNode();
        const singleSelect = ElementFactory.select();
        singleSelect.setLabel('Reusable single select');
        singleSelect.addOption({ text: 'option 1' });
        singleSelect.addOption({ text: 'option 2' });
        const component1 = graph.library.addElementComponent(singleSelect, 'Single select with 2 options');
        graph.library.addElementComponent(ElementFactory.text(), 'My simple paragraph');
        const instance1 = component1.createInstance();
        const instance2 = component1.createInstance();
        const instance3 = instance1.clone();
        vn.contentBuilderContext.addElement(instance1);
        vn.contentBuilderContext.addElement(instance2);
        vn.contentBuilderContext.addElement(instance3);
        if (editor.media.provider.mediaAssets.length) {
            const media1 = editor.media.provider.mediaAssets[0];
            const mediaElement1 = ElementFactory.image();
            mediaElement1.setMediaAssetID(media1.id);
            vn.contentBuilderContext.addElement(ContentBuilderContext.createBlockInstance(mediaElement1));
            const media2 = editor.media.provider.mediaAssets[1];
            if (media2) {
                const mediaElement2 = ElementFactory.image();
                mediaElement2.setMediaAssetID(media2.id);
                vn.contentBuilderContext.addElement(ContentBuilderContext.createBlockInstance(mediaElement2));
            }
            const mediaElement3 = ElementFactory.image();
            mediaElement3.setMediaAssetID('some-missing-id');
            vn.contentBuilderContext.addElement(ContentBuilderContext.createBlockInstance(mediaElement3));
        }
        vn.addNewLogicGate({ expression: 'true' });
        const n = editor.nodeFactory.linkPortalNode();
        n.setPosition(50, 200);
        graph.addNode(n);
        const d = editor.nodeFactory.timelineEntryAlertNode();
        d.setPosition(50, 400);
        graph.addNode(d);
        graph.addNode(vn);
        graph.setStartNodeID(vn.id);
        return graph;
    },
};
