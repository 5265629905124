var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { DefaultColumnRenderer } from './DefaultColumnRenderer';
export const DefaultHeaderRowRenderer = React.forwardRef(function DefaultHeaderRowRenderer(props, ref) {
    const { requiredProps, columnClassName, className } = props, rest = __rest(props, ["requiredProps", "columnClassName", "className"]);
    const { className: rowClassName, style, columns } = requiredProps;
    const theme = useTheme();
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, style: style, className: `${rowClassName} ${css({
            borderBottom: `1px solid ${theme.palette.background.divider}`,
        }, className)}`, "data-cy": "header-row" }), columns.map((col, idx) => {
        return (React.createElement(DefaultColumnRenderer, { key: idx, requiredProps: col, className: css({
                position: 'relative',
                paddingLeft: 0,
                paddingRight: 0,
            }, columnClassName) }));
    })));
});
