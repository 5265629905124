import React from 'react';
import { Transforms } from 'slate';
import { Popover } from 'lib/cortiUI/components/Popover';
import { ExpressionCreator } from './ExpressionCreator';
import { LinkCreator } from './LinkCreator';
import { NoteCreator } from './NoteCreator';
import * as Commands from './commands';
export function RichTextEditorPopover({ editor, richTextEditor, selectionSnapshot, secondaryPopover, setSecondaryPopover, }) {
    return (React.createElement(Popover, { anchorPosition: secondaryPopover === null || secondaryPopover === void 0 ? void 0 : secondaryPopover.anchorPosition, anchorReference: "anchorPosition", open: Boolean(secondaryPopover), transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        }, onClose: () => {
            setSecondaryPopover(null);
        } }, secondaryPopover ? (secondaryPopover.name === 'note' ? (React.createElement(NoteCreator, { onSave: (value) => {
            var _a;
            if (!selectionSnapshot.current) {
                return;
            }
            Transforms.select(editor, selectionSnapshot.current);
            Commands.insertNote(editor, Object.assign({ content: value }, (_a = richTextEditor.resolveNoteDetails) === null || _a === void 0 ? void 0 : _a.call(richTextEditor)));
            setSecondaryPopover(null);
        }, onCancel: () => {
            setSecondaryPopover(null);
        } })) : secondaryPopover.name === 'link' ? (React.createElement(LinkCreator, { onSave: (value) => {
            if (!selectionSnapshot.current) {
                return;
            }
            Transforms.select(editor, selectionSnapshot.current);
            Commands.insertLink(editor, value);
            setSecondaryPopover(null);
        }, onCancel: () => {
            setSecondaryPopover(null);
        } })) : secondaryPopover.name === 'expression' ? (React.createElement(ExpressionCreator, { onSave: (value) => {
            if (!selectionSnapshot.current) {
                return;
            }
            Transforms.select(editor, selectionSnapshot.current);
            Commands.insertExpression(editor, value);
            setSecondaryPopover(null);
        }, onCancel: () => {
            setSecondaryPopover(null);
        } })) : null) : null));
}
