import { findIndex } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Checkbox, List, ListItem, ListItemButton, ListItemText, Typography, } from 'lib/cortiUI';
export const AttributeRenderer = (props) => {
    const theme = useTheme();
    const { value, attributes, onChange, requiredProps, highlightedIndex } = props;
    return (React.createElement(Base, { maxHeight: 270 },
        React.createElement(List, { "data-cy": "filter-group-attribute-menu" }, !!attributes.length ? (attributes.map((attribute, index) => {
            const selectedItemIndex = findIndex(attributes, (a) => a.id === value);
            const isHighlighted = highlightedIndex !== undefined ? highlightedIndex === index : undefined;
            return (React.createElement(ListItemButton, Object.assign({ key: index }, requiredProps({
                key: attribute.id,
                index,
                item: attribute,
            }), { selected: selectedItemIndex === index, onClick: () => onChange(attribute), classes: {
                    root: css({
                        backgroundColor: isHighlighted
                            ? theme.palette.action.hover
                            : theme.palette.background.card,
                        borderRadius: 0,
                        '&:not(:last-child)': {
                            marginBottom: 0,
                        },
                    }),
                } }),
                React.createElement(Base, { display: "flex", gap: 3 },
                    React.createElement(Checkbox, { checked: value === attribute.id, color: "primary" }),
                    React.createElement(ListItemText, null,
                        React.createElement(Base, { display: "flex", alignContent: "center" },
                            React.createElement(Typography, { variant: "body2", color: "secondaryText" }, attribute.text))))));
        })) : (React.createElement(ListItem, null,
            React.createElement(ListItemText, null,
                React.createElement(Typography, { variant: "body2", color: "hint" }, intl.t('searchbar.noSearchResultsFound', 'No results found'))))))));
};
