import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useMemo } from 'react';
import { intl } from '@corti/i18n';
import { zendeskWidgetService } from 'browser/services/init';
import { authStore } from 'core/auth';
export function useNavItems(modules) {
    const navItems = useMemo(() => {
        const orgItems = [
            {
                icon: SettingsRoundedIcon,
                path: '/organisation-settings',
                text: intl.t('settings', 'Org. Settings'),
            },
            {
                icon: LiveHelpRoundedIcon,
                text: intl.t('support', 'Support'),
                disabled: !zendeskWidgetService.isReady(),
                onClick: () => {
                    var _a, _b, _c;
                    if (zendeskWidgetService.isReady()) {
                        const currentUser = authStore.currentUser;
                        zendeskWidgetService.show({
                            username: currentUser.name,
                            email: currentUser.email,
                            rumSessionId: (_c = (_b = (_a = window.DD_RUM) === null || _a === void 0 ? void 0 : _a.getInternalContext()) === null || _b === void 0 ? void 0 : _b.session_id) !== null && _c !== void 0 ? _c : '',
                        });
                    }
                },
            },
        ];
        const userItems = [
            {
                useAvatarAsIcon: true,
                path: '/profile',
                text: authStore.currentUser.name,
            },
            {
                icon: LogoutRoundedIcon,
                path: '/login',
                text: intl.t('logout', 'Sign out'),
                onClick: () => authStore.logout(),
            },
        ];
        return modules
            .reduce((acc, module) => {
            var _a, _b;
            const navItem = { icon: module.icon, path: module.path, text: (_b = (_a = module.title) === null || _a === void 0 ? void 0 : _a.call(module)) !== null && _b !== void 0 ? _b : '' };
            const groupe = module.navGroupe;
            const existingGroupe = acc[groupe];
            if (existingGroupe) {
                existingGroupe.push(navItem);
                return acc;
            }
            acc[groupe] = [navItem];
            return acc;
        }, [])
            .filter(Boolean)
            .concat([orgItems, userItems]);
    }, [modules]);
    return navItems;
}
