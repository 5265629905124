import StarRounded from '@mui/icons-material/StarRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { DefaultPortWidget } from './DefaultPortWidget';
export const BaseGraphEditorNodeWidget = observer(function BaseGraphEditorNodeWidget(props) {
    const { model, title = model.type, isFavoriteNode = false, children } = props;
    const theme = useTheme();
    return (
    // width and height has to be fixed, because of limitations and complexity
    // when calculating port positions
    // This could be improved by using Resize Observer api and reporting new port positions
    // whenever elements size is changing
    // https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
    React.createElement("div", { "data-cy": "node", className: css({
            width: 240,
            borderRadius: 8,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: model.isSelected
                ? theme.palette.primary.main
                : model.isHovered
                    ? theme.palette.action.outlinedHoverBorder
                    : theme.palette.action.outlinedBorder,
            cursor: 'grab',
            '&:active': {
                cursor: 'grabbing',
            },
            '&:focus,&:hover': {
                boxShadow: theme.shadows[2],
            },
        }) },
        React.createElement("div", { className: css({
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                background: theme.palette.background.secondary,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: theme.space[8],
                position: 'relative',
                paddingLeft: 30,
                padding: '8px 12px 8px 12px',
            }) },
            React.createElement("div", { className: css({
                    height: '100%',
                    top: 0,
                    left: 6,
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }) }, props.model.portIn && (React.createElement(DefaultPortWidget, { key: props.model.portIn.id, model: props.model.portIn }))),
            React.createElement("span", { className: css({
                    fontSize: '0.8em',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    overflow: 'hidden',
                    color: theme.palette.text.primary,
                }) }, title),
            React.createElement("div", { className: css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }) },
                React.createElement("span", { className: css({
                        fontSize: 'x-small',
                        color: theme.palette.text.hint,
                    }) },
                    "#",
                    model.shortid),
                isFavoriteNode && React.createElement(StarRounded, { fontSize: "small" }))),
        React.createElement("div", null, children)));
});
export function NodeMainContent(props) {
    const { children } = props;
    const theme = useTheme();
    return (React.createElement(Base, { background: theme.palette.background.card, p: 4, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }, children));
}
