import { observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Loading } from 'lib/cortiUI';
import { useTranscriptSearchViewContext } from './context';
export const RedirectView = observer(function RedirectView() {
    const { viewModel, router } = useTranscriptSearchViewContext();
    if (viewModel.state.isLoadingAllQueries) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }));
    }
    if (!viewModel.state.allQueries) {
        return null;
    }
    return React.createElement(Redirect, { to: router.getNextRoute(viewModel.state.allQueries) });
});
