var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { cloneDeep, isEqual } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { BlockValueUtils } from '../../stores/BlockValueStore';
export class CollectorModel {
    constructor(blockInstance, flowStore, initialData = {}) {
        Object.defineProperty(this, "blockInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: blockInstance
        });
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "muted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "itemsBeforeMute", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "collectBlockValues", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockID, blockValues) => {
                const nextValues = blockValues.filter((it) => BlockValueUtils.isTruthy(it));
                const target = this.getItemsByValueType('block').find((i) => {
                    return i.values.some((v) => v.identifier.blockPrototypeID === blockID);
                });
                if (target) {
                    if (nextValues.length !== 0) {
                        this.replace(target.id, Object.assign(Object.assign({}, target), { values: nextValues }));
                    }
                    else {
                        this.remove(target.id);
                    }
                }
                else {
                    if (nextValues.length !== 0) {
                        this.add(CollectorItemFactory.createCollectorBlockValueItem(nextValues));
                    }
                }
            }
        });
        Object.defineProperty(this, "collectBlockOptionValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (optionID, blockValue) => {
                const nextValue = BlockValueUtils.isTruthy(blockValue) ? blockValue : undefined;
                const target = this.getItemsByValueType('block-option').find((i) => {
                    return i.values.some((v) => v.identifier.optionID === optionID);
                });
                if (target) {
                    if (nextValue) {
                        this.replace(target.id, Object.assign(Object.assign({}, target), { values: [nextValue] }));
                    }
                    else {
                        this.remove(target.id);
                    }
                }
                else {
                    if (nextValue) {
                        this.add(CollectorItemFactory.createCollectorBlockOptionValueItem([nextValue]));
                    }
                }
            }
        });
        Object.defineProperty(this, "collectFactValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (factValue) => {
                const target = this.getItemsByValueType('fact').find((i) => {
                    return i.values.some((v) => v.factID === factValue.factID);
                });
                if (target) {
                    this.replace(target.id, Object.assign(Object.assign({}, target), { values: [factValue] }));
                }
                else {
                    this.add(CollectorItemFactory.createCollectorFactValueItem([factValue]));
                }
            }
        });
        Object.defineProperty(this, "collectCustomValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (customValue) => {
                this.add(CollectorItemFactory.createCollectorCustomValueItem([{ value: customValue }]));
            }
        });
        Object.defineProperty(this, "updateCustomValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (item, customValue) => {
                const target = this.getItemsByValueType('custom').find((it) => it.id === item.id);
                if (!target)
                    return;
                this.replace(target.id, Object.assign(Object.assign({}, target), { values: [{ value: customValue }] }));
            }
        });
        Object.defineProperty(this, "resetCollectedItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (items) => {
                this.items = [];
                items.forEach((it) => this.addInOrder(it));
            }
        });
        Object.defineProperty(this, "removeCollectedItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (item) => {
                this.remove(item.id);
            }
        });
        Object.defineProperty(this, "add", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (item) => {
                this.addInOrder(item);
                this.fireCollectorBlockValuesUpdatedEvent();
            }
        });
        Object.defineProperty(this, "addInOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (item) => {
                if (item.valueType === 'fact') {
                    // Business logic: add new fact to the beggining of the list
                    this.items.unshift(item);
                }
                else {
                    this.items.push(item);
                }
            }
        });
        Object.defineProperty(this, "replace", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (itemID, replacement) => {
                const index = this.items.findIndex((it) => it.id === itemID);
                if (index >= 0) {
                    this.items.splice(index, 1, replacement);
                    this.fireCollectorBlockValuesUpdatedEvent();
                    return replacement;
                }
            }
        });
        Object.defineProperty(this, "remove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (itemID) => {
                const index = this.items.findIndex((it) => it.id === itemID);
                if (index >= 0) {
                    this.items.splice(index, 1);
                    this.fireCollectorBlockValuesUpdatedEvent();
                }
            }
        });
        Object.defineProperty(this, "serialize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return {
                    blockInstance: this.blockInstance,
                    items: cloneDeep(this.items),
                };
            }
        });
        Object.defineProperty(this, "fireCollectorBlockValuesUpdatedEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b, _c;
                if (this.muted)
                    return;
                // TODO: we need to rethink this solution and ensure custom values sync across all
                const { viewProtoID } = this.blockInstance;
                if (!viewProtoID)
                    return;
                const isFavorite = ((_a = this.flowStore.stepStore.favoriteStep) === null || _a === void 0 ? void 0 : _a.node.view.id) === viewProtoID;
                const isPinned = (_b = this.flowStore.stepStore.pinnedSteps) === null || _b === void 0 ? void 0 : _b.some((s) => s.node.view.id === viewProtoID);
                const isActiveStep = ((_c = this.flowStore.stepStore.activeStep) === null || _c === void 0 ? void 0 : _c.node.view.id) === viewProtoID;
                if (!isActiveStep && !isFavorite && !isPinned)
                    return;
                this.observer.fireEvent('collector-values-updated', this.serialize());
            }
        });
        Object.defineProperty(this, "mute", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.muted = true;
                this.itemsBeforeMute = this.items.slice();
            }
        });
        Object.defineProperty(this, "unmute", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.muted = false;
                if (this.hasChangedSince(this.itemsBeforeMute)) {
                    this.fireCollectorBlockValuesUpdatedEvent();
                }
                this.itemsBeforeMute = [];
            }
        });
        Object.defineProperty(this, "onCollectorValuesUpdated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('collector-values-updated', cb);
            }
        });
        makeObservable(this);
        this.observer = new Observer();
        runInAction(() => {
            this.items = [];
        });
        if (initialData.items) {
            initialData.items.forEach((it) => this.addInOrder(it));
        }
    }
    hasChangedSince(beforeItems) {
        const extract = (it) => [it.valueType, it.values];
        const before = beforeItems.map(extract);
        const after = this.items.map(extract);
        return !isEqual(before, after);
    }
    getItemsByValueType(valueType) {
        return this.items.filter((it) => it.valueType === valueType);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], CollectorModel.prototype, "items", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CollectorModel.prototype, "resetCollectedItems", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CollectorModel.prototype, "removeCollectedItem", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CollectorModel.prototype, "addInOrder", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CollectorModel.prototype, "replace", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CollectorModel.prototype, "remove", void 0);
export const CollectorItemFactory = {
    createCollectorBlockOptionValueItem: (values) => {
        return {
            id: uuid(),
            valueType: 'block-option',
            values: values,
        };
    },
    createCollectorBlockValueItem: (values) => {
        return {
            id: uuid(),
            valueType: 'block',
            values: values,
        };
    },
    createCollectorCustomValueItem: (values) => {
        return {
            id: uuid(),
            valueType: 'custom',
            values: values,
        };
    },
    createCollectorFactValueItem: (values) => {
        return {
            id: uuid(),
            valueType: 'fact',
            values: values,
        };
    },
};
