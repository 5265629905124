var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { getFocusWithinCss } from '../utils';
import { Checkbox, Radio } from './components';
export function Option(props) {
    const { innerRef, controlType, isSelected = false, isReadonly = false, isDisabled, onSelect, onEnter, onLeave, children } = props, rest = __rest(props, ["innerRef", "controlType", "isSelected", "isReadonly", "isDisabled", "onSelect", "onEnter", "onLeave", "children"]);
    const theme = useTheme();
    const elementRef = React.useRef(null);
    React.useEffect(() => {
        if (elementRef.current && innerRef) {
            innerRef(elementRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleClick() {
        if (onSelect && isInteractible()) {
            onSelect();
        }
    }
    function handleEnter() {
        if (onEnter && isInteractible()) {
            onEnter();
        }
    }
    function handleLeave() {
        if (onLeave && isInteractible()) {
            onLeave();
        }
    }
    function isInteractible() {
        return !isReadonly && !isDisabled;
    }
    function getHoverCss() {
        return css({
            '&:hover': {
                backgroundColor: theme.palette.background.secondary,
            },
        });
    }
    return (React.createElement(Base, Object.assign({ ref: elementRef, onClick: handleClick, onMouseEnter: handleEnter, onMouseLeave: handleLeave, onFocus: handleEnter, onBlur: handleLeave, className: css({
            padding: '8px 16px',
            opacity: isDisabled ? 0.6 : 1,
            borderRadius: Number(theme.shape.borderRadius),
            border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.background.divider}`,
            backgroundColor: isSelected
                ? theme.palette.primary.outlinedHoverBackground
                : theme.palette.background.default,
            cursor: isInteractible() ? 'pointer' : 'default',
        }, isInteractible() ? getHoverCss() : undefined, isInteractible() ? getFocusWithinCss({ theme }) : undefined) }, rest),
        React.createElement(Base, { display: "grid", gridTemplateColumns: "auto 1fr", width: "100%", gridAutoFlow: "column", gridGap: theme.space[5], alignItems: "center" },
            controlType === 'radio' ? (React.createElement(Radio, { tabIndex: -1, checked: isSelected, inputProps: { disabled: true }, style: { pointerEvents: 'none' } })) : (React.createElement(Checkbox, { tabIndex: -1, checked: isSelected, inputProps: { disabled: true }, style: { pointerEvents: 'none' } })),
            children)));
}
