import { useEffect, useState } from 'react';
import useUpdateWhileUnfocused from './useUpdateWhileUnfocused';
function useBlockFocus(containerRef, value, setInternalValue) {
    const [hasFocus, setHasFocus] = useState(false);
    // Handle focus
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const node = containerRef.current;
        const onFocus = () => setHasFocus(true);
        const onBlur = () => setHasFocus(false);
        node.addEventListener('focusin', onFocus);
        node.addEventListener('focusout', onBlur);
        return () => {
            node.removeEventListener('focusin', onFocus);
            node.removeEventListener('focusout', onBlur);
        };
    }, [containerRef]);
    useUpdateWhileUnfocused(hasFocus, value, setInternalValue);
    return hasFocus;
}
export default useBlockFocus;
