import { InfoRounded } from '@mui/icons-material';
import React from 'react';
import { useTheme } from '@corti/theme';
import { Base, Switch, Tooltip, Typography } from 'lib/cortiUI';
export const ToggleField = ({ onChange, value, label, tooltip }) => {
    const { palette } = useTheme();
    return (React.createElement(Base, { display: "flex", alignItems: "center", borderRadius: 4, px: 7, py: 7, background: palette.grey[200], gap: 4 },
        React.createElement(Tooltip, { title: tooltip, placement: "bottom-start" },
            React.createElement(InfoRounded, { sx: { cursor: 'help' } })),
        React.createElement(Typography, { flex: 1, color: "default" }, label),
        React.createElement(Switch, { checked: value, onChange: (_e, checked) => onChange(checked) })));
};
