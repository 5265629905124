import Highcharts from 'highcharts';
import { chunk, groupBy } from 'lodash';
export function getDataPoints(timeBasedMetrics, currentPeriodType) {
    switch (currentPeriodType) {
        case 'daily':
            return aggregateDataPointsByPeriod(timeBasedMetrics, 1);
        case '7-days':
            return aggregateDataPointsByPeriod(timeBasedMetrics, 7);
        case 'monthly':
            return aggregateDataPointsByCalendarMonths(timeBasedMetrics);
        default:
            return [];
    }
}
function aggregateDataPointsByPeriod(data, period) {
    const periods = chunk(data, period);
    return periods.map(getMean).filter((it) => !!it);
}
function getMean(data) {
    const filterByScore = data.filter((it) => it.numberOfReviews > 0);
    if (filterByScore.length === 0)
        return;
    const totalNumberOfReviews = filterByScore.reduce((total, it) => total + it.numberOfReviews, 0);
    return {
        detectionPercentage: filterByScore.reduce((total, it) => { var _a; return total + ((_a = it.detectionPercentage) !== null && _a !== void 0 ? _a : 0) * it.numberOfReviews; }, 0) / totalNumberOfReviews,
        numberOfReviews: totalNumberOfReviews,
        timePoint: data[0].timePoint,
    };
}
function aggregateDataPointsByCalendarMonths(data) {
    const groupedData = groupBy(data, (it) => {
        return `${new Date(it.timePoint).getFullYear()}-${new Date(it.timePoint).getMonth()}`;
    });
    return calculateMeanForGroupedDataPoints(groupedData);
}
function calculateMeanForGroupedDataPoints(groupedByMonth) {
    return Object.values(groupedByMonth)
        .map((monthDataPoints) => {
        const aggregatedDataPoint = getMean(monthDataPoints);
        return Object.assign({}, aggregatedDataPoint);
    })
        .filter((it) => !!it.numberOfReviews);
}
export function tooltipLabelFormatter(currentPeriodType, dateRange, context) {
    if (typeof context.x !== 'number')
        return;
    const date = new Date(context.x);
    const formattedInitialDate = Highcharts.dateFormat('%d %b, %Y', date.getTime());
    const tooltipFooter = '<br/>' + `${context.series.name}: <b>${context.y}%</b>`;
    switch (currentPeriodType) {
        case 'monthly':
            return Highcharts.dateFormat('%b, %Y', date.getTime()) + tooltipFooter;
        case '7-days':
            // Adding six days in miliseconds to initial date to get seven days in total
            const currentPeriodEndDate = context.x + 86400000 * 6;
            const formattedEndDate = Highcharts.dateFormat('%d %b, %Y', Math.min(currentPeriodEndDate, dateRange.endDate.getTime()));
            return `${formattedInitialDate} - ${formattedEndDate}` + tooltipFooter;
        default:
            return formattedInitialDate + tooltipFooter;
    }
}
