import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import { useTheme } from '@corti/theme';
import { Base, Box, IconButton, Typography } from 'lib/cortiUI';
export function SelectedColumnsListItem({ column, onRemove, handleProps, itemProps, }) {
    const theme = useTheme();
    return (React.createElement(Base, Object.assign({ pb: 3 }, itemProps),
        React.createElement(Base, Object.assign({ pl: 6, pr: 3, py: 3, width: 1, elevation: 15, borderRadius: 2, "data-cy": "selected-columns-list-item", background: theme.palette.background.paper }, handleProps),
            React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { color: "default", variant: "caption" }, column.label),
                React.createElement(IconButton, { icon: React.createElement(ClearRoundedIcon, { fontSize: "small" }), size: "small", onClick: () => onRemove(column.id), "data-cy": "selected-columns-list-item-remove-btn" })))));
}
