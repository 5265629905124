import React from 'react';
import { intl } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Box, Typography } from 'lib/cortiUI';
export const ChartLegend = ({ chartSeries, onToggleSeriesVisibility }) => {
    const DEFAULT_COLOR = '#b5b5b5';
    const { palette } = useTheme();
    const handleMouseOver = (currentSeries, series) => {
        if (!currentSeries.visible)
            return;
        currentSeries.setState('hover');
        series.forEach((s) => {
            if (Object.values(s).length && (s === null || s === void 0 ? void 0 : s.index) !== currentSeries.index) {
                s === null || s === void 0 ? void 0 : s.setState('inactive');
            }
        });
    };
    const handleMouseOut = () => {
        chartSeries.forEach((i) => {
            if (Object.values(i).length && i.setState)
                i.setState();
        });
    };
    return (React.createElement(Box, { mt: 8, display: "flex", flexDirection: "row", flexWrap: "wrap" }, chartSeries.map((series, _i, arr) => {
        var _a;
        return ((_a = series === null || series === void 0 ? void 0 : series.userOptions) === null || _a === void 0 ? void 0 : _a.showInLegend) && (React.createElement(Box, { key: series.name, display: "flex", flexDirection: "row", style: { cursor: 'pointer' }, mr: 9, mb: 5, maxWidth: 200, onMouseOver: () => handleMouseOver(series, arr), onMouseOut: handleMouseOut, onClick: () => onToggleSeriesVisibility(series) },
            React.createElement(Box, { background: series.visible
                    ? series.userOptions.color || DEFAULT_COLOR
                    : palette.text.disabled, width: 12, height: 12, borderRadius: 6, mr: 4, mt: 3 }),
            React.createElement(Box, { display: "flex", overflow: "hidden", flex: 1 },
                React.createElement(Typography, { variant: "body1", color: series.visible ? 'default' : 'disabled', textTransform: "capitalize", width: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }, series.name),
                React.createElement(Typography, { variant: "body2", color: series.visible ? 'secondaryText' : 'disabled', "data-cy": "transcript-search-time-series-call-count" },
                    (series.yData || []).reduce((acc, n) => {
                        acc += n;
                        return acc;
                    }, 0),
                    ' ',
                    intl.t('transcriptSearchApp:timeSeriesChart.calls', 'Calls').toLowerCase()))));
    })));
};
