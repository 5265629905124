import MUIFormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
export function FormControlLabel(props) {
    const theme = useTheme();
    return (React.createElement(MUIFormControlLabel, Object.assign({ classes: {
            root: css({
                marginLeft: 0,
            }),
            label: css({
                marginLeft: theme.space[3],
                color: theme.palette.text.secondary,
                fontSize: theme.typography.fontSize,
            }),
        } }, props)));
}
