var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { assign, createMachine, spawn } from 'xstate';
import { pure, sendParent, sendTo } from 'xstate/lib/actions';
import { startEntryMachine } from './entry';
const INITIAL_CHECKLIST_CONTEXT = {
    isFirst: false,
    isExpanded: false,
    completedItems: 0,
    totalItems: 0,
    entries: [],
    id: '',
    name: '',
    description: '',
    key: '',
};
const checklistMachine = createMachine({
    tsTypes: {},
    schema: {
        context: {},
        events: {},
    },
    predictableActionArguments: true,
    id: 'checklist',
    initial: 'initializing',
    context: INITIAL_CHECKLIST_CONTEXT,
    states: {
        initializing: {
            entry: 'initialize',
            always: [
                { target: 'completed', cond: 'isCompleted' },
                { target: 'idle', cond: 'isIdle' },
                { target: 'active' },
            ],
        },
        idle: {
            always: [
                { target: 'active', cond: 'isActive' },
                { target: 'completed', cond: 'isCompleted', actions: ['collapse', 'dispatchCollapsed'] },
            ],
        },
        active: {
            always: [
                { target: 'completed', cond: 'isCompleted', actions: ['collapse', 'dispatchCollapsed'] },
                { target: 'idle', cond: 'isIdle' },
            ],
        },
        completed: {
            entry: 'finishParent',
            exit: 'unfinishParent',
            always: [
                { target: 'active', cond: 'isActive' },
                { target: 'idle', cond: 'isIdle' },
            ],
        },
    },
    on: {
        FIRST: { actions: ['setFirst', 'expand', 'dispatchExpanded'] },
        NOTFIRST: { actions: 'setNotFirst' },
        EXPAND: { actions: 'expand' },
        COLLAPSE: { actions: 'collapse' },
        CHECK: { actions: 'check' },
        UNCHECK: { actions: 'uncheck' },
        AUTOCHECK: { actions: 'autocheck' },
    },
}, {
    actions: {
        setFirst: assign(() => ({ isFirst: true })),
        setNotFirst: assign(() => ({ isFirst: false })),
        collapse: assign(() => ({ isExpanded: false })),
        expand: assign(() => ({ isExpanded: true })),
        check: assign(({ completedItems }) => ({ completedItems: completedItems + 1 })),
        uncheck: assign(({ completedItems }) => ({ completedItems: completedItems - 1 })),
        finishParent: sendParent(({ id }) => ({ type: 'FINISHED', id })),
        unfinishParent: sendParent(({ id }) => ({ type: 'UNFINISHED', id })),
        // Send autocheck to correct child entry
        autocheck: pure((context, { id, checked }) => {
            const entry = context.entries.find((entry) => entry.id === id);
            if (entry) {
                return sendTo(entry.ref, { type: checked ? 'AUTOCHECK' : 'AUTOUNCHECK' });
            }
        }),
        dispatchCollapsed: sendParent(({ id }) => ({ type: 'AUTOCOLLAPSED', id })),
        dispatchExpanded: sendParent(({ id }) => ({ type: 'AUTOEXPANDED', id })),
    },
    guards: {
        isActive: (context) => (context.isFirst || context.completedItems > 0) &&
            context.completedItems < context.totalItems,
        isIdle: (context) => !context.isFirst && context.completedItems === 0,
        isCompleted: (context) => context.completedItems === context.totalItems,
    },
});
export function startChecklistMachine(_a, couldBeFirst, isExpanded, snapshot) {
    var { entries } = _a, checklist = __rest(_a, ["entries"]);
    const completedItems = entries.filter((entry) => Boolean(snapshot.manualChecksById[entry.id])).length;
    const isCompleted = completedItems === entries.length;
    const isFirst = couldBeFirst && !isCompleted;
    const machine = checklistMachine.withConfig({
        actions: {
            initialize: assign(() => (Object.assign(Object.assign({}, checklist), { isExpanded,
                isFirst, totalItems: entries.length, completedItems: completedItems, entries: entries.map((entry) => ({
                    id: entry.id,
                    ref: spawn(startEntryMachine(entry, snapshot), entry.key),
                })) }))),
        },
    });
    return [isCompleted, machine];
}
