import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Avatar, Base, Chip, Typography, } from 'lib/cortiUI';
import { Actions } from './Actions/Actions';
export function getColumns(params) {
    return [
        {
            field: 'title',
            headerName: intl.t('templateList:dataGrid.columns.title', 'Title'),
            editable: false,
            flex: 3,
        },
        {
            field: 'createdBy',
            headerName: intl.t('templateList:dataGrid.columns.createdBy', 'Created By'),
            editable: false,
            flex: 2,
            renderCell: (props) => (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 5 },
                React.createElement(Avatar, { size: "small" }, props.value),
                React.createElement(Typography, { color: "default", variant: "body2", noWrap: true }, props.value))),
        },
        {
            field: 'createdAt',
            headerName: intl.t('templateList:dataGrid.columns.createdAt', 'Created At'),
            editable: false,
            flex: 2,
        },
        {
            field: 'status',
            headerName: intl.t('templateList:dataGrid.columns.status', 'Status'),
            editable: false,
            flex: 2,
            renderCell: ({ value }) => value ? (React.createElement(Chip, { borderColor: value.color, label: value.text, variant: "outlined", className: css({
                    color: value.color,
                }) })) : null,
        },
        {
            field: 'actionToolbar',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 2,
            align: 'right',
            renderCell: ({ value }) => {
                return value ? (React.createElement(Actions, { templateID: value.id, published: value.published, onTemplateDelete: params.removeRow })) : null;
            },
        },
    ];
}
