import React from 'react';
import { FocusElement, FocusElementGroup, List } from 'lib/cortiUI';
import { PinnedStepListItem } from './PinnedStepListItem';
export const PinnedStepList = ({ pinnedSteps, activePinnedStepID, onPinnedStepClick, }) => {
    return (React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(List, null, pinnedSteps
        .sort((a, b) => {
        return a.title.localeCompare(b.title, undefined, { numeric: true });
    })
        .map((item) => (React.createElement(FocusElement, { key: item.id, defaultGroupID: groupID }, ({ registerElementRef }) => (React.createElement(PinnedStepListItem, { ref: registerElementRef, onClick: () => onPinnedStepClick(item), text: item.title, selected: item.id === activePinnedStepID })))))))));
};
