import { Errors } from '@corti/lib/publicApiFramework';
import { createSessionObject } from './utils';
export class TriageSessionPublicApi {
    constructor(sessionController, apiContributor) {
        Object.defineProperty(this, "sessionController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: sessionController
        });
        Object.defineProperty(this, "apiContributor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: apiContributor
        });
        Object.defineProperty(this, "subsriptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.subsriptions = [];
    }
    init() {
        this.apiContributor.exposeMethod({
            name: '/realtime/session/setFactValues',
            paramsJSONSchema: {
                type: 'object',
                required: ['sessionID', 'facts'],
                properties: {
                    sessionID: { type: 'string' },
                    facts: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: ['id', 'value'],
                            properties: {
                                id: { type: 'string' },
                                value: { type: ['boolean', 'string'] },
                            },
                        },
                    },
                },
            },
            handler: (input) => {
                if (input.sessionID !== this.sessionController.id) {
                    return Errors.APPLICATION_ERROR('TRIAGE_SESSION_NOT_FOUND');
                }
                this.sessionController.flow.factValueStore.put({
                    factValues: input.facts.map((it) => ({ factID: it.id, value: it.value })),
                    source: 'integration',
                });
            },
        });
        this.apiContributor.exposeMethod({
            name: '/realtime/session/getFactValues',
            paramsJSONSchema: {
                type: 'object',
                required: ['sessionID'],
                properties: {
                    sessionID: { type: 'string' },
                },
            },
            handler: (input) => {
                if (input.sessionID !== this.sessionController.id) {
                    return Errors.APPLICATION_ERROR('TRIAGE_SESSION_NOT_FOUND');
                }
                return this.sessionController.flow.factValueStore.all.map((f) => ({
                    id: f.factID,
                    value: f.value,
                }));
            },
        });
        this.subsriptions.push(this.sessionController.flow.onIntegrationEvent((event) => {
            var _a, _b, _c;
            if (event.event === 'action-block-triggered') {
                if (!this.sessionController.isCurrentUserOwner) {
                    return;
                }
                this.apiContributor.fireEvent({
                    name: 'realtime.session.triage-flow.action-block-triggered',
                    data: {
                        session: createSessionObject(this.sessionController.serializeSession()),
                        nodeID: (_a = event.data.node) === null || _a === void 0 ? void 0 : _a.id,
                        blockPrototype: {
                            id: event.data.blockPrototype.id,
                            content: event.data.blockPrototype.text.plainText,
                            name: event.data.blockPrototype.name,
                            customProperties: event.data.blockPrototype.customProperties,
                        },
                        blockInstance: event.data.blockInstance
                            ? {
                                id: event.data.blockInstance.id,
                                customProperties: (_c = (_b = event.data.blockInstance.customProperties) === null || _b === void 0 ? void 0 : _b.map((it) => ({
                                    key: it.key,
                                    value: it.value,
                                }))) !== null && _c !== void 0 ? _c : [],
                            }
                            : undefined,
                    },
                });
            }
        }));
        this.subsriptions.push(this.sessionController.flow.onCustomEvent((event) => {
            if (event.event === 'grouped-flow-value-collector-blocks-updated') {
                if (!this.sessionController.isCurrentUserOwner) {
                    return;
                }
                this.apiContributor.fireEvent({
                    name: 'realtime.session.triage-flow.grouped-flow-value-collector-blocks-updated',
                    data: {
                        session: createSessionObject(this.sessionController.serializeSession()),
                        group: event.data.collectors.map((col) => {
                            return Object.assign(Object.assign({}, col), { collectedFactValues: col.collectedFactValues.map((it) => ({
                                    id: it.factID,
                                    value: it.value,
                                })) });
                        }),
                    },
                });
            }
        }));
        this.subsriptions.push(this.sessionController.onStateReset((data) => {
            if (!this.sessionController.isCurrentUserOwner) {
                return;
            }
            if (data.currentState.session.caseID != null &&
                data.currentState.session.caseID !== data.prevState.session.caseID) {
                this.apiContributor.fireEvent({
                    name: 'realtime.session.case-id-changed',
                    data: {
                        session: createSessionObject(data.currentState.session),
                    },
                });
            }
        }));
    }
    destroy() {
        this.subsriptions.forEach((it) => it());
        this.apiContributor.unexposeMethod('/realtime/session/setFactValues');
        this.apiContributor.unexposeMethod('/realtime/session/getFactValues');
    }
}
