var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, runInAction } from 'mobx';
import { uuid } from '@corti/uuid';
export class FileUploader {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "currentRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.currentRequests = [];
        });
    }
    async uploadFile(file) {
        const request = observable({
            id: uuid(),
            progress: 0,
            total: file.size,
            file,
        });
        runInAction(() => {
            this.currentRequests.push(request);
        });
        try {
            await this.input.uploadFile(file, (e) => {
                runInAction(() => {
                    request.progress = e.loaded;
                    request.total = e.total;
                });
            });
            return request;
        }
        finally {
            runInAction(() => {
                this.currentRequests.splice(this.currentRequests.indexOf(request), 1);
            });
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], FileUploader.prototype, "currentRequests", void 0);
