export default function formatNumber({ value, min, max, precision }) {
    if (value === '') {
        return '';
    }
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
        return '';
    }
    const actualMax = max === null ? Number.POSITIVE_INFINITY : max;
    const actualMin = min === null ? Number.NEGATIVE_INFINITY : min;
    const clampedValue = Math.min(actualMax, Math.max(actualMin, numberValue));
    if (precision === null) {
        return clampedValue.toString();
    }
    return clampedValue.toFixed(precision);
}
