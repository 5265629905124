import { gql } from '@apollo/client';
export const getPerformanceViewsQuery = gql `
  query performanceViews($organizationID: ID!, $userID: ID!) {
    performanceViews(organizationID: $organizationID, userID: $userID) {
      id
      title
    }
  }
`;
export const getPerformanceViewSourcesQuery = gql `
  query performanceView($id: String!) {
    performanceView(id: $id) {
      id
      trackers {
        key {
          entityID
          type
        }
      }
    }
  }
`;
export const getPerformanceViewMetricsByCallQuery = gql `
  query performanceViewMetricsByCall(
    $organizationID: ID!
    $callID: String!
    $sources: [PerformanceMetricsSourceKeyInput!]!
  ) {
    performanceViewMetricsByCall(
      organizationID: $organizationID
      callID: $callID
      sources: $sources
    ) {
      source {
        key {
          entityID
          type
        }
        text
        title
      }
      value
      text
    }
  }
`;
