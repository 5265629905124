import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { Base, CopyButton, Typography } from 'lib/cortiUI';
export function CaseMergeItemWidget(props) {
    const { entity, type } = props;
    function getTextByType() {
        switch (type) {
            case 'parent':
                return intl.t('explore:caseView.caseMerge.parentCase', 'Parent case');
            case 'child':
                return intl.t('explore:caseView.caseMerge.childCase', 'Child case');
        }
    }
    return (React.createElement(Base, null,
        React.createElement(Base, { display: "flex", alignItems: "center" },
            React.createElement(Typography, { color: "default", variant: "subtitle2", my: 2, "data-cy": "case-merge-item-caseID" }, `${getTextByType()}: ${entity.readableCaseID}`),
            React.createElement(CopyButton, { getValue: entity.id, tooltipTitle: intl.t('explore:caseView.caseIdCopied', 'Case ID Copied') })),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 1 },
            entity.startedAt && (React.createElement(Typography, { color: "default", variant: "body2" }, `${intl.t('explore:caseView.caseMerge.startedAt', 'Started At')}: ${formatDateTime(entity.startedAt)}`)),
            entity.createdBy && (React.createElement(Typography, { color: "default", variant: "body2" }, `${intl.t('explore:caseView.caseMerge.createdBy', 'Created By')}: ${entity.createdBy.name}`)))));
}
