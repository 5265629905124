var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { TextField } from 'lib/cortiUI';
export const TitleInput = (_a) => {
    var { onSaveValue } = _a, props = __rest(_a, ["onSaveValue"]);
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions' });
    const { field: { onChange, onBlur, name, value, ref }, fieldState: { error }, formState, } = useController(Object.assign(Object.assign({}, props), { name: 'text', rules: {
            required: {
                value: true,
                message: t('required', 'Required'),
            },
        } }));
    return (React.createElement(TextField, { name: name, required: true, error: Boolean(error), label: t('title', 'Question title'), value: value, onChange: ({ target }) => onChange(target.value), multiline: true, sx: { flex: 1, mr: 4 }, helperText: error === null || error === void 0 ? void 0 : error.message, onBlur: () => {
            onBlur();
            onSaveValue();
        }, inputRef: ref, disabled: formState.isSubmitting, FormHelperTextProps: {
            sx: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                mx: 0,
            },
        } }));
};
