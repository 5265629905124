import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Base, Checkbox, Typography, } from 'lib/cortiUI';
export const TrackersAccordion = ({ title, isSelected, indeterminate, onCheckboxClick, children, }) => {
    const [expanded, setExpanded] = React.useState(false);
    return (React.createElement(Accordion, { slotProps: { transition: { unmountOnExit: true } }, expanded: expanded, onChange: (_, expanded) => setExpanded(expanded), "data-cy": "tracker-accordion" },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "aria-controls": `${title}-content`, id: `${title}-header` },
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" },
                React.createElement(Base, { p: 4 },
                    React.createElement(Checkbox, { indeterminate: indeterminate, checked: isSelected, onChange: (_, checked) => onCheckboxClick(checked), onClick: (e) => e.stopPropagation(), "data-cy": `${title}-trackers-group-checkbox` })),
                React.createElement(Typography, { "data-cy": "tracker-accordion-title" }, title))),
        React.createElement(AccordionDetails, { sx: { px: 11, display: 'flex', flexDirection: 'column', gap: 6 } }, children)));
};
