import React from 'react';
import { isValuePublisher } from 'lib/graphEditor/valuePublisher';
import { ActionElementModel, DatePickerElementModel, DocumentElementModel, FlowValueCollectorElementModel, ImageElementModel, SelectElementModel, TextAreaElementModel, TextElementModel, TextInputElementModel, } from '../elements';
import { NumberInputElementModel } from '../elements/NumberInputElementModel';
import { ValuePublisherWidget } from './ValuePublisherWidget';
import { ActionElementWidget } from './elements/ActionElementWidget';
import { DatePickerWidget } from './elements/DatepickerElementWidget';
import { DocumentElementWidget } from './elements/DocumentElementWidget';
import { FlowValueCollectorElementWidget } from './elements/FlowValueCollectorElementWidget';
import { ImageElementWidget } from './elements/ImageElementWidget';
import { NumberInputElementWidget } from './elements/NumberInputElementWidget';
import { SelectElementWidget } from './elements/SelectElementWidget';
import { TextAreaElementWidget } from './elements/TextAreaElementWidget';
import { TextElementWidget } from './elements/TextElementWidget';
import { TextInputElementWidget } from './elements/TextInputElementWidget';
export function ElementRenderer(props) {
    const { model } = props;
    function getSpecificWidget() {
        if (model instanceof ActionElementModel) {
            return React.createElement(ActionElementWidget, { model: model });
        }
        if (model instanceof TextElementModel) {
            return React.createElement(TextElementWidget, { model: model });
        }
        if (model instanceof TextInputElementModel) {
            return React.createElement(TextInputElementWidget, { model: model });
        }
        if (model instanceof NumberInputElementModel) {
            return React.createElement(NumberInputElementWidget, { model: model });
        }
        if (model instanceof TextAreaElementModel) {
            return React.createElement(TextAreaElementWidget, { model: model });
        }
        if (model instanceof DocumentElementModel) {
            return React.createElement(DocumentElementWidget, { model: model });
        }
        if (model instanceof SelectElementModel) {
            return React.createElement(SelectElementWidget, { model: model });
        }
        if (model instanceof FlowValueCollectorElementModel) {
            return React.createElement(FlowValueCollectorElementWidget, { model: model });
        }
        if (model instanceof ImageElementModel) {
            return React.createElement(ImageElementWidget, { model: model });
        }
        if (model instanceof DatePickerElementModel) {
            return React.createElement(DatePickerWidget, { model: model });
        }
        return null;
    }
    const specificWidget = getSpecificWidget();
    if (!specificWidget) {
        return React.createElement("div", null, 'MISSING RENDERER');
    }
    return React.createElement(ElementWrapper, { model: model }, specificWidget);
}
function ElementWrapper({ model, children, }) {
    return (React.createElement(React.Fragment, null,
        children,
        isValuePublisher(model) && (React.createElement(ValuePublisherWidget, { mt: 4, valuePublisherConfig: model.valuePublisher }))));
}
