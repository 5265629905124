import { useLazyQuery, useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { CallMetricsBreakdownItem, DetectionsWidget } from 'lib/cortiAnalyticsUI';
import { Base, FallbackView, Typography } from 'lib/cortiUI';
import { PerformanceMetricsWidgetLoader } from './PerformanceMetricsWidgetLoader';
import { getPerformanceViewMetricsByCallQuery, getPerformanceViewSourcesQuery, } from './graphql';
export const PerformanceMetricsWidget = ({ callID, performanceViewID, }) => {
    const { organization } = useAuth();
    const { t } = useTranslation('explore', { keyPrefix: 'callPerformanceView' });
    const SCORE_WIDGET_SIZE = 180;
    const { data: getPerformanceViewSources, loading: isPerformanceViewSourcesLoading } = useQuery(getPerformanceViewSourcesQuery, {
        variables: {
            id: performanceViewID,
        },
    });
    const [fetchPerformanceViewMetricsByCall, { data: getPerformanceViewMetricsByCall, loading: isPerformarmanceViewMetricsByCallLoading },] = useLazyQuery(getPerformanceViewMetricsByCallQuery);
    React.useEffect(() => {
        var _a;
        if (getPerformanceViewSources === null || getPerformanceViewSources === void 0 ? void 0 : getPerformanceViewSources.performanceView.trackers) {
            fetchPerformanceViewMetricsByCall({
                variables: {
                    organizationID: organization.id,
                    sources: (_a = getPerformanceViewSources.performanceView.trackers.map((it) => it.key)) !== null && _a !== void 0 ? _a : [],
                    callID,
                },
            });
        }
    }, [
        getPerformanceViewSources === null || getPerformanceViewSources === void 0 ? void 0 : getPerformanceViewSources.performanceView.trackers,
        callID,
        fetchPerformanceViewMetricsByCall,
        organization.id,
    ]);
    if (isPerformanceViewSourcesLoading || isPerformarmanceViewMetricsByCallLoading) {
        return React.createElement(PerformanceMetricsWidgetLoader, null);
    }
    if (!getPerformanceViewMetricsByCall) {
        return React.createElement(FallbackView, { title: t('noDataGenericFallback.title', 'No data found') });
    }
    const { performanceViewMetricsByCall } = getPerformanceViewMetricsByCall;
    const detectedQuestions = performanceViewMetricsByCall.filter((it) => it.value !== 'not_detected');
    return (React.createElement(Base, { "data-cy": "call-detections-preview", display: "flex", flexDirection: "column", gap: 8 },
        React.createElement(Base, { width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" },
            React.createElement(DetectionsWidget, { value: detectedQuestions.length / performanceViewMetricsByCall.length, size: SCORE_WIDGET_SIZE, label: `${detectedQuestions.length}/${performanceViewMetricsByCall.length}` })),
        performanceViewMetricsByCall.length ? (React.createElement(React.Fragment, null, performanceViewMetricsByCall
            .slice()
            .sort((a, b) => a.source.text.localeCompare(b.source.text))
            .map((it) => (React.createElement(CallMetricsBreakdownItem, { title: it.source.text, value: it.value, key: it.source.key.entityID + it.source.key.type }))))) : (React.createElement(Typography, null, t('noFeatureFallback.text', 'No question had a major impact on the average')))));
};
