import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { WindowPopup } from '@corti/corti-ui';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ButtonBase, FilePreview, Typography } from 'lib/cortiUI';
import { getFocusWithinCss } from '../utils';
export const DocumentBlock = observer(function DocumentBlock(props) {
    const { text, mediaAsset, onClick, isReadonly = false, innerRef, newWindowPositionProps } = props;
    const theme = useTheme();
    const windowRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    function openFullscreen() {
        setIsOpen(true);
        if (windowRef.current) {
            windowRef.current.focus();
        }
    }
    function closeFullscreen() {
        setIsOpen(false);
    }
    function handleClick() {
        openFullscreen();
        onClick && onClick();
    }
    function getHoverCss() {
        return css({
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonBase, { ref: (r) => innerRef === null || innerRef === void 0 ? void 0 : innerRef(r), onClick: handleClick, tabIndex: isReadonly ? -1 : 0, className: css({
                minHeight: 50,
                borderRadius: Number(theme.shape.borderRadius) * 2,
                paddingLeft: theme.space[6],
                paddingRight: theme.space[6],
                paddingTop: theme.space[5],
                paddingBottom: theme.space[5],
                fontSize: theme.typography.fontSize,
                border: `1px solid ${theme.palette.background.divider}`,
                backgroundColor: 'transparent',
            }, isReadonly ? undefined : getHoverCss(), isReadonly ? undefined : getFocusWithinCss({ theme })) },
            React.createElement(Base, { width: "100%", display: "grid", gridTemplateColumns: "auto 1fr", gridGap: theme.space[5], alignItems: "center" },
                React.createElement(AttachFileRoundedIcon, { className: css({ color: theme.palette.text.hint }) }),
                React.createElement(Typography, { variant: "body2", sx: { overflowWrap: 'anywhere' } }, text))),
        React.createElement(WindowPopup, { isOpen: isOpen, windowRef: windowRef, title: mediaAsset.name, onClose: closeFullscreen, size: { width: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.width, height: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.height }, position: { top: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.top, left: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.left } },
            React.createElement(FilePreview, { url: mediaAsset.url }))));
});
