import React from 'react';
import { useTranslation } from '@corti/i18n';
import { ContextMenuItem } from 'lib/cortiUI';
export function ContextMenu({ editor, point }) {
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(React.Fragment, null,
        React.createElement(ContextMenuItem, { title: t('graphEditorCanvas.ctxMenu.addViewNode', 'Add View Node'), onClick: () => {
                editor.dispatch({
                    type: 'canvas.addNode',
                    data: {
                        type: 'viewnode',
                        position: point,
                    },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('graphEditorCanvas.ctxMenu.addLinkPortalNode', 'Add Link Portal Node'), onClick: () => {
                editor.dispatch({
                    type: 'canvas.addNode',
                    data: {
                        type: 'linkPortalNode',
                        position: point,
                    },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('graphEditorCanvas.ctxMenu.addDetectionTriggerNode', 'Add Detection Trigger Node'), onClick: () => {
                editor.dispatch({
                    type: 'canvas.addNode',
                    data: {
                        type: 'timelineEntryAlertNode',
                        position: point,
                    },
                });
            } })));
}
