import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const RadioField = ({ title, required, choices }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    const { palette, space, typography } = useTheme();
    return (React.createElement(FormControl, { required: required, sx: { width: 1 }, "data-cy": CY_SELECTORS.preview.radioFieldPreview },
        React.createElement(RadioGroup, { name: title, "aria-label": title },
            React.createElement(Base, { display: "grid", gridGap: 4 }, (choices !== null && choices !== void 0 ? choices : []).map((choice, i) => (React.createElement(React.Fragment, { key: choice.value },
                React.createElement(Box, { overflow: "hidden", border: `1px solid ${choice.text ? palette.background.divider : palette.error.main}`, p: 5, borderRadius: 4, className: css({
                        transition: 'background ease-in 150ms',
                        '&:hover': {
                            background: palette.action.hover,
                            transition: 'background ease-in 150ms',
                        },
                    }) },
                    React.createElement(FormControlLabel, { value: choice.value, label: choice.text ? choice.text : `${t('preview.option', 'Option')} ${i + 1}`, classes: {
                            root: css({
                                marginLeft: 0,
                            }),
                            label: css({
                                marginLeft: space[3],
                                color: choice.text ? palette.text.secondary : palette.error.main,
                                fontSize: typography.fontSize,
                            }),
                        }, control: React.createElement(Radio, null) })),
                !choice.text && (React.createElement(FormHelperText, { error: true, sx: { m: 0 }, "data-cy": CY_SELECTORS.preview.radioPreviewHelperText }, t('preview.validationErrors.noOptionTitle', ' Empty option.'))))))))));
};
