import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box, Typography } from 'lib/cortiUI';
export function CommentsCounter(props) {
    const { count, className } = props;
    const theme = useTheme();
    return (React.createElement(Box, { px: 2, flexDirection: "row", alignItems: "center", justifyContent: "center", className: css({ backgroundColor: theme.palette.primary.dark }, className), height: 16, minWidth: 20, maxWidth: 100, borderRadius: "8px" },
        React.createElement(Typography, { variant: "footnote", sx: { color: theme.palette.primary.contrastText }, noWrap: true }, count > 99 ? '99+' : count)));
}
