import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Container, FallbackView, Page } from 'lib/cortiUI';
import { RouteBreadcrumb } from 'lib/router';
import { Header } from './Header';
import { MembersDataGrid } from './MembersDataGrid';
import { getTeamQuery } from './graphql';
export function TeamMembersView(props) {
    const { teamID } = props;
    const { t } = useTranslation('teams');
    const { data, error } = useQuery(getTeamQuery, {
        variables: {
            id: teamID,
        },
    });
    if (error) {
        return React.createElement(FallbackView, { title: "Error", text: error.message });
    }
    const team = data === null || data === void 0 ? void 0 : data.team;
    if (!team) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(RouteBreadcrumb, { breadcrumb: team.name }),
        React.createElement(Page, { "data-cy": "team-view" },
            React.createElement(Container, { className: css({
                    height: '100%',
                }) },
                React.createElement(Base, { height: "100%", display: "grid", gridTemplateRows: "auto 1fr", gap: 4 },
                    React.createElement(Header, { team: team }),
                    team.members.length === 0 ? (React.createElement(FallbackView, { title: t('teamView.noTeamMembersTitle', 'No team members'), text: t('teamView.noTeamMembersText', 'Please add team members to more details about the team') })) : (React.createElement(MembersDataGrid, { members: team.members })))))));
}
