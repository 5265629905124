import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton } from 'lib/cortiUI';
import { AddPartialThresholdAction } from './Actions';
import { ThresholdInput } from './ThresholdInput';
import { getParsedValue } from './utils';
export const ThresholdsForm = ({ compliantValue, compliantError, partiallyCompliantValue, onCompliantChange, onPartiallyCompliantChange, onAddThreshold, onRemoveThreshold, }) => {
    const { t } = useTranslation('template', { keyPrefix: 'builder.configuration.thresholdsForm' });
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, width: "100%" },
        React.createElement(ThresholdInput, { label: t('compliant', 'Compliant'), value: compliantValue, color: "success", onChange: function ({ target }) {
                onCompliantChange(getParsedValue(target.value));
            }, error: !!compliantError, helperText: compliantError, sx: partiallyCompliantValue !== undefined ? { paddingRight: 10 } : undefined }),
        partiallyCompliantValue !== undefined ? (React.createElement(Base, { display: "flex", alignItems: "center" },
            React.createElement(ThresholdInput, { label: t('partiallyCompliant', 'Partially Compliant'), value: partiallyCompliantValue, color: "warning", onChange: function ({ target }) {
                    onPartiallyCompliantChange(getParsedValue(target.value));
                } }),
            React.createElement(IconButton, { icon: React.createElement(RemoveRoundedIcon, { color: "action" }), onClick: onRemoveThreshold }))) : (React.createElement(Base, { minHeight: 48, display: "flex", alignItems: "center" },
            React.createElement(AddPartialThresholdAction, { onClick: onAddThreshold }))),
        React.createElement(ThresholdInput, { disabled: true, label: t('nonCompliant', 'Non Compliant'), value: partiallyCompliantValue !== undefined ? partiallyCompliantValue : compliantValue, color: "error", sx: partiallyCompliantValue !== undefined ? { paddingRight: 10 } : undefined })));
};
