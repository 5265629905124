import { useQuery } from '@apollo/client';
import InfoRounded from '@mui/icons-material/InfoRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useAuth } from 'core/auth/browser';
import { Avatar, Base, DataGrid, GridColumnHeaderTitle, IconButton, Tooltip, Typography, } from 'lib/cortiUI';
import { getFeedbackFormTemplatesQuery, } from '../graphql';
import { Actions, AnonymizeToggle } from './actions';
export const TemplatesDataGrid = () => {
    var _a;
    const authStore = useAuth();
    const { t } = useTranslation('templatesManagement');
    const organizationID = authStore.organization.id;
    const { data: getTemplatesData, loading: isGetTemplatesLoading } = useQuery(getFeedbackFormTemplatesQuery, {
        variables: {
            organizationID,
        },
    });
    function getColumns() {
        return [
            {
                field: 'title',
                headerName: t('title', 'Title'),
                editable: false,
                flex: 3,
            },
            {
                field: 'createdBy',
                headerName: t('createdBy', 'Created By'),
                editable: false,
                flex: 2,
                renderCell: (props) => (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 5 },
                    React.createElement(Avatar, { size: "small" }, props.value),
                    React.createElement(Typography, { color: "default", variant: "body2", noWrap: true }, props.value))),
            },
            {
                field: 'createdAt',
                headerName: t('createdAt', 'Created At'),
                editable: false,
                flex: 2,
            },
            {
                field: 'anonymous',
                headerName: t('anonymous', 'Anonymous'),
                editable: false,
                sortable: false,
                flex: 2,
                renderHeader: (params) => {
                    return (React.createElement(Base, { display: "flex", gap: 2, alignItems: "center" },
                        React.createElement(GridColumnHeaderTitle, Object.assign({}, params, { columnWidth: params.colDef.computedWidth, label: t('anonymous', 'Anonymous') })),
                        React.createElement(Tooltip, { title: t('actionsHints.anonymize', 'Marking a template as “Anonymous” will hide the identity of all users who submit feedback using the selected template') },
                            React.createElement(IconButton, { className: css({ margin: 3 }), icon: React.createElement(InfoRounded, { fontSize: "small" }) }))));
                },
                renderCell: ({ value }) => {
                    return value ? (React.createElement(AnonymizeToggle, { feedbackFormTemplateID: value.id, isAnonymous: value.anonymizeReviewers })) : null;
                },
            },
            {
                field: 'actionToolbar',
                headerName: '',
                editable: false,
                sortable: false,
                flex: 1,
                align: 'right',
                renderCell: ({ value }) => {
                    return value ? React.createElement(Actions, { feedbackFormTemplateID: value }) : null;
                },
            },
        ];
    }
    function getRows() {
        var _a;
        return (_a = getTemplatesData === null || getTemplatesData === void 0 ? void 0 : getTemplatesData.feedbackFormTemplates) === null || _a === void 0 ? void 0 : _a.map((it) => {
            return {
                id: it.id,
                title: it.title,
                createdBy: it.createdBy.name,
                createdAt: formatDateTime(it.createdAt),
                actionToolbar: it.id,
                anonymous: it,
            };
        });
    }
    const rows = (_a = getRows()) !== null && _a !== void 0 ? _a : [];
    const columns = getColumns();
    return (React.createElement(DataGrid, { autoHeight: true, rows: rows, columns: columns, loading: isGetTemplatesLoading, disableColumnFilter: true, disableColumnMenu: true, disableSelectionOnClick: true, components: {
            NoRowsOverlay: () => (React.createElement(Typography, { "data-cy": "no-results-found", className: css({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }) }, t('tableNoDataFallback', 'No results found'))),
        }, sx: {
            '& .MuiDataGrid-row': {
                cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },
        } }));
};
