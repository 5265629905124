import React from 'react';
import { useAutorunEffect } from '@corti/react';
import { getConfig as getCallSubscriberConfig } from 'browser/services/callSubscriberConfig.service';
import { coreRealtimeClient } from 'browser/services/init';
import { phoneExtensionServiceStatic } from 'browser/services/phoneExtension.service';
import { authStore } from 'core/auth';
export function usePhoneExtension() {
    useAutorunEffect(() => {
        var _a;
        if (authStore.isLoaded) {
            coreRealtimeClient.send('set-session-settings', {
                phoneExtension: ((_a = getCallSubscriberConfig()) === null || _a === void 0 ? void 0 : _a.phoneExtension) || authStore.currentUser.extension || '',
            });
        }
    });
    React.useEffect(() => {
        var _a;
        const ext = ((_a = getCallSubscriberConfig()) === null || _a === void 0 ? void 0 : _a.phoneExtension) || authStore.currentUser.extension || '';
        if (!ext) {
            return;
        }
        phoneExtensionServiceStatic.connect({
            authToken: authStore.authToken,
            phoneExtension: ext,
        });
        return () => {
            phoneExtensionServiceStatic.disconnect();
        };
    }, []);
}
