import { createCache, createResolvers } from './cache';
export class GraphTraverser {
    constructor(newcache) {
        Object.defineProperty(this, "cache", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resolvers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.cache = newcache;
        this.resolvers = createResolvers(newcache);
    }
    static create(input) {
        const cache = createCache(input);
        return new GraphTraverser(cache);
    }
    getStartNode() {
        if (!this.cache.startNode)
            return;
        return this.cache.startNode.nodeID
            ? this.getNodeByID(this.cache.startNode.nodeID)
            : this.getBranchStartNode(this.cache.startNode.branchID);
    }
    getBranchStartNode(branchID) {
        const branch = this.getBranchByID(branchID);
        if (branch === null || branch === void 0 ? void 0 : branch.startNodeID) {
            return this.getNodeByID(branch.startNodeID);
        }
    }
    getBranchByID(id) {
        return this.resolvers.branch(id);
    }
    getBranchByNodeID(nodeID) {
        const branchID = this.cache.nodeIDToBranchID.get(nodeID);
        if (branchID) {
            return this.getBranchByID(branchID);
        }
    }
    getBranches() {
        return [...this.cache.branches.keys()].map((b) => this.resolvers.branch(b));
    }
    getNodeByID(id) {
        return this.resolvers.node(id);
    }
    getNodeByTarget(target) {
        return target.nodeID
            ? this.getNodeByID(target.nodeID)
            : this.getBranchStartNode(target.branchID);
    }
    getNodes() {
        return [...this.cache.nodes.keys()].map((id) => this.resolvers.node(id));
    }
    getExtraNodesByType(type) {
        return [...this.cache.extraNodes.values()].filter(
        // this is quick cast now, but typing should be improved with generics
        (n) => n.type === type);
    }
    getViewByID(id) {
        return this.resolvers.viewInstance(id);
    }
    getViewProtoByID(id) {
        return this.resolvers.viewProto(id);
    }
    getBlockByID(id) {
        return this.resolvers.blockInstance(id);
    }
    getBlocksByType(type) {
        return [...this.cache.blockInstances.values()]
            .map((i) => this.resolvers.blockInstance(i.id))
            .filter((b) => b.blockPrototype.type === type);
    }
    getBlockProtoByID(id) {
        return this.resolvers.blockProto(id);
    }
    /**
     * Search filters are combined with AND
     */
    getBlockProtos(queryOptions) {
        var _a, _b, _c, _d;
        // after some experimenting with string array intersections
        // this turned out to be the fastest way that I could find to apply AND operation for filtering
        let finalIDs = new Set(this.cache.blockProtos.keys());
        if ((_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.location) === null || _a === void 0 ? void 0 : _a.branchID) {
            const ids = (_b = this.cache.branchIDToBlockProtoIDs.get(queryOptions.location.branchID)) !== null && _b !== void 0 ? _b : new Set();
            finalIDs = takeFromSet(finalIDs, [...ids.values()]);
        }
        if ((_c = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.properties) === null || _c === void 0 ? void 0 : _c.type) {
            const ids = (_d = this.cache.blockTypeToBlockProtoIDs.get(queryOptions.properties.type)) !== null && _d !== void 0 ? _d : new Set();
            finalIDs = takeFromSet(finalIDs, [...ids.values()]);
        }
        return [...finalIDs.values()]
            .filter((id) => {
            const instances = this.getInstancesByBlockProtoID(id);
            return instances ? instances.size >= 1 : true;
        })
            .map((id) => this.resolvers.blockProto(id));
    }
    getInstancesByBlockProtoID(blockProtoID) {
        var _a;
        const component = this.cache.libraryComponents.get(blockProtoID);
        if (component) {
            return (_a = this.cache.componentIDToBlockProtoIDs.get(component.elementID)) !== null && _a !== void 0 ? _a : new Set();
        }
    }
    getOptionByID(id) {
        return this.resolvers.optionInstance(id);
    }
    getFactByID(id) {
        return this.resolvers.fact(id);
    }
    getChecklists() {
        return [...this.cache.checklists.values()];
    }
}
function takeFromSet(original, keys) {
    const r = new Set();
    keys.forEach((k) => {
        if (original.has(k)) {
            r.add(k);
        }
    });
    return r;
}
