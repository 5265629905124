import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Base, List, ListItem, Typography } from 'lib/cortiUI';
import { useCaseViewContext } from '../CaseViewContext';
import { CaseTimelineEntry } from './CaseTimelineEntry';
export const CaseTimeline = observer(function CaseTimeline() {
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    if (!caseViewState.caseEntity) {
        return null;
    }
    const { caseEntity } = caseViewState;
    const renderListItem = (entry) => {
        var _a, _b;
        const handleTimelineEntryClick = () => {
            if (caseEntity.timelineEditor.activeTimelineEntryID === entry.id) {
                caseEntity.timelineEditor.setActiveTimelineEntry();
            }
            else {
                caseEntity.timelineEditor.setActiveTimelineEntry(entry.id);
                trackerService.track('[Single case] timeline-entry focused in Timeline sidebar', {
                    timelineEntry: {
                        id: entry.id,
                        callID: entry.callID,
                        definition: entry.definition,
                        createdBy: entry.createdBy,
                        createdAt: entry.createdAt,
                        title: entry.definition.title,
                        text: entry.definition.text,
                        color: entry.definition.style.color,
                    },
                });
                if (caseEntity.activeCall && caseEntity.activeCallPlayerState) {
                    const entryOffset = entry.getOffsetPosition(caseEntity.activeCall.start).start / 1000;
                    caseEntity.activeCallPlayerState.setCurrentTime(entryOffset);
                }
            }
        };
        return (React.createElement(ListItem, { key: entry.id, classes: {
                root: css({
                    padding: 0,
                    '&:not(:last-child)': { marginBottom: theme.space[6] },
                }),
            } },
            React.createElement(CaseTimelineEntry, { "data-cy": "timeline-entry-card", "data-cy-state": `timeline-entry-card-active-${entry.isActive}`, baseDatetime: (_b = (_a = caseEntity.activeCall) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : caseEntity.startedAt, entry: entry, onClick: handleTimelineEntryClick })));
    };
    const sortedTimelineEntries = orderBy(caseViewState.filteredTimelineEntries, (entry) => entry.startDatetime, 'asc');
    return (React.createElement(Base, { "data-cy": "case-timeline-view", display: "grid", gap: 7, overflow: "hidden" },
        React.createElement(Typography, { mx: 8, variant: "h6" }, intl.t('explore:caseView.timeline', 'Timeline')),
        React.createElement(Base, { className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
            React.createElement(Base, { mx: 8 },
                React.createElement(List, { style: { padding: 0, position: 'relative' } }, sortedTimelineEntries.map(renderListItem))))));
});
