const MAX_RECENT_SESSIONS = 20;
export class RecentTriageSessionRepository {
    constructor(dbTable) {
        Object.defineProperty(this, "dbTable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: dbTable
        });
    }
    async getSessions() {
        const items = await this.dbTable.orderBy('createdAt').reverse().toArray();
        return items;
    }
    async addSession(session) {
        await this.dbTable.put(Object.assign({}, session));
        const currentCount = await this.dbTable.count();
        const diff = currentCount - MAX_RECENT_SESSIONS;
        if (diff > 0) {
            await this.dbTable.orderBy('createdAt').limit(diff).delete();
        }
    }
}
