import BackIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ForwardIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTheme } from '@corti/theme';
import { Base, Button, Loading } from 'lib/cortiUI';
const BUTTON_WIDTH = '40px';
export const NavigatorPanel = observer(function NavigatorPanel(props) {
    const { navigator, flowStore } = props;
    const backButton = props.isActiveStep && navigator.hasBackwardSteps() ? (React.createElement(NavigationButton, { type: "back", onClick: navigator.navigateToPrevStep, disabled: flowStore.stepStore.activeStep
            ? navigator.renderPath.findIndex((s) => s.id === flowStore.stepStore.activeStep.id) ===
                0
            : true })) : (React.createElement(Base, { width: BUTTON_WIDTH }));
    const forwardButton = props.isActiveStep && navigator.hasForwardSteps() ? (React.createElement(NavigationButton, { loading: false, screenSize: props.screenSize, type: "forward", onClick: navigator.navigateToNextStep, disabled: !navigator.canNavigateForward })) : ('');
    if (props.screenSize === 'small') {
        return (React.createElement(Base, { display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr min-content', gap: 3, minHeight: '100%' },
            React.createElement(Base, { gridColumn: '1/3', minHeight: '100%', display: 'flex', alignItems: 'flex-start' }, props.children),
            backButton,
            forwardButton));
    }
    return (React.createElement(Base, { display: 'grid', gridTemplateColumns: props.screenSize === 'medium'
            ? `${BUTTON_WIDTH} 1fr ${BUTTON_WIDTH}`
            : `${BUTTON_WIDTH} 1fr`, gap: 2, marginLeft: 2, marginRight: [0, 2, 0], maxHeight: '100%', position: 'relative' },
        backButton,
        props.children,
        forwardButton));
});
const NavigationButton = (props) => {
    const theme = useTheme();
    return (React.createElement(Button, { "data-cy": `flow:${props.type}-button`, variant: "text", sx: props.type === 'forward' &&
            (props.screenSize === 'large' || props.screenSize === 'extra-large')
            ? {
                width: BUTTON_WIDTH,
                minWidth: BUTTON_WIDTH,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                transform: 'translateX(calc(100% + 4px))',
                zIndex: 999,
            }
            : props.screenSize === 'large' || props.screenSize === 'extra-large'
                ? {
                    width: BUTTON_WIDTH,
                }
                : {
                    minWidth: BUTTON_WIDTH,
                }, onClick: props.onClick, color: props.type === 'forward' ? 'primary' : undefined, disabled: props.disabled }, props.loading ? (React.createElement(Loading, { size: "small" })) : props.type === 'forward' ? (React.createElement(ForwardIcon, { fontSize: "large" })) : (React.createElement(BackIcon, { style: { color: theme.palette.action.active }, fontSize: "large" }))));
};
