import { ScopedCssBaseline } from '@mui/material';
import React from 'react';
import { css } from '@corti/style';
import { ThemeProvider } from '@corti/theme';
import { ThemeUtils } from 'browser/themes';
import { Box, Dialog } from 'lib/cortiUI';
import { ChecklistInspector } from './ChecklistInspector';
import { ChecklistTabs } from './ChecklistTabs';
import { ChecklistEditProvider } from './useChecklistEdit';
export function ChecklistForm({ isAdding, originalChecklist, hasValidation, onSave, }) {
    const theme = ThemeUtils.getDefaultTheme().config;
    return (React.createElement(Dialog, { size: "auto", open: true, hideCloseButton: true, titleProps: { display: 'none !important' }, contentProps: { style: { padding: 0, borderRadius: 0 } } },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(ScopedCssBaseline, { classes: { root: css({ width: 'fit-content' }) } },
                React.createElement(ChecklistEditProvider, { checklist: originalChecklist },
                    React.createElement(Box, { display: "flex", flexDirection: "row", width: 1000, height: 600, alignItems: "stretch" },
                        React.createElement(Box, { width: 500, display: "flex", background: "#eee", alignItems: "stretch" },
                            React.createElement(ChecklistTabs, null)),
                        React.createElement(Box, { width: 500, display: "flex", px: 32, pt: 48, pb: 16, gap: 16, justifyContent: "space-between" },
                            React.createElement(ChecklistInspector, { isAdding: isAdding, hasValidation: hasValidation, onSave: onSave }))))))));
}
