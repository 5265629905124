import { useQuery, } from '@apollo/client';
import React from 'react';
/**
 * Exactly the same as apollo's `useQuery` with an addition of `delayedLoading` value
 * which gets a `true` value only after the certain time threshold passes.
 *
 * This is useful to avoid UI jumps and flashing loading spinners for queries that are quick
 */
export function useDelayedQuery(query, options) {
    const q = useQuery(query, options);
    const timer = React.useRef(null);
    const [delayedLoading, setDelayedLoading] = React.useState(false);
    React.useEffect(() => {
        if (q.loading) {
            timer.current = window.setTimeout(() => {
                setDelayedLoading(true);
            }, 800);
        }
        else {
            setDelayedLoading(false);
        }
        return () => {
            if (timer.current) {
                window.clearTimeout(timer.current);
            }
        };
    }, [q.loading]);
    return Object.assign(Object.assign({}, q), { delayedLoading });
}
