var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { createRichText, stringifyRichText } from '@corti/richtext';
import { BaseEntity } from 'lib/graphEditor/core';
import { ValuePublisher } from 'lib/graphEditor/valuePublisher';
import { CustomProperties } from '../../common/customProperties';
export class SelectOptionElementModel extends BaseEntity {
    constructor(params = {}) {
        var _a;
        super();
        Object.defineProperty(this, "_text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "valuePublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        const { initialData } = params;
        this.type = 'select-option';
        if ((_a = params === null || params === void 0 ? void 0 : params.initialData) === null || _a === void 0 ? void 0 : _a.id) {
            this.id = params.initialData.id;
        }
        runInAction(() => {
            this._text = createRichText(initialData === null || initialData === void 0 ? void 0 : initialData.text);
        });
        this.customProperties = new CustomProperties();
        this.valuePublisher = new ValuePublisher();
    }
    get context() {
        var _a;
        return (_a = this.parent) === null || _a === void 0 ? void 0 : _a.context;
    }
    setParent(parent) {
        super.setParent(parent);
        this.valuePublisher.setInheritanceContext(this.parent.valuePublisher);
    }
    setText(value) {
        if (typeof value === 'string') {
            this._text = createRichText(value);
        }
        else {
            this._text = value;
        }
    }
    get text() {
        return this._text;
    }
    get refItemText() {
        return stringifyRichText(this.text);
    }
    get refItemTarget() {
        return this.parent;
    }
    get refItemValue() {
        return `option:${this.id}`;
    }
    get refItemContextLabel() {
        const parent = this.parent;
        return stringifyRichText(parent === null || parent === void 0 ? void 0 : parent.label);
    }
    get readableTitle() {
        return stringifyRichText(this.text);
    }
    remove() {
        var _a;
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.removeOption(this);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { id: this.id, text: this._text, customProperties: this.customProperties.getEncodableData(), valuePublisher: this.valuePublisher.getEncodableData() });
    }
    deserialize(input) {
        super.deserialize(input);
        this.id = input.id;
        this._text = input.text;
        this.customProperties.deserialize(input.customProperties);
        this.valuePublisher.deserialize(input.valuePublisher);
    }
    copy() {
        const i = super.copy();
        i.setText(cloneDeep(this.text));
        i.customProperties = this.customProperties.copy();
        i.valuePublisher = this.valuePublisher.copy();
        return i;
    }
}
Object.defineProperty(SelectOptionElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'select-option'
});
Object.defineProperty(SelectOptionElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.selectOptionElement.title', 'Select Option'),
    })
});
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], SelectOptionElementModel.prototype, "_text", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectOptionElementModel.prototype, "setText", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "text", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "refItemText", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "refItemTarget", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "refItemValue", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "refItemContextLabel", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], SelectOptionElementModel.prototype, "readableTitle", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SelectOptionElementModel.prototype, "deserialize", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectOptionElementModel.prototype, "copy", null);
