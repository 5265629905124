import { orderBy } from 'lodash';
import { TranscriptSearchViewState } from './TranscriptSearchViewState';
export class TranscriptSearchViewModel {
    constructor(api) {
        Object.defineProperty(this, "api", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: api
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadAllQueries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                try {
                    this.state.setIsLoadingAllQueries(true);
                    const response = await this.api.getOrgTranscriptQueries();
                    const orderedQueries = orderBy(response, (it) => it.createdAt, 'desc');
                    this.state.setQueries(orderedQueries);
                }
                finally {
                    this.state.setIsLoadingAllQueries(false);
                }
            }
        });
        Object.defineProperty(this, "deleteQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (queryID) => {
                await this.api.deleteTranscriptQuery(queryID);
                this.state.removeQuery(queryID);
            }
        });
        Object.defineProperty(this, "loadTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (organizationID) => {
                try {
                    this.state.setIsLoadingTeams(true);
                    const teams = await this.api.getTeams(organizationID);
                    this.state.setTeams(teams);
                }
                finally {
                    this.state.setIsLoadingTeams(false);
                }
            }
        });
        this.state = new TranscriptSearchViewState();
    }
}
