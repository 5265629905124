var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { cloneDeep, union } from 'lodash';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { convertZonedToUtcTime, endOfDay, startOfDay, subDays } from '@corti/date';
export class CaseReviewRequestsFilterModel {
    constructor(filter) {
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "callTakerIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "callTakerTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "assigneeIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "assigneeTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (filter) => {
                var _a, _b, _c, _d, _e, _f;
                this.createdAt = (_a = filter === null || filter === void 0 ? void 0 : filter.createdAt) !== null && _a !== void 0 ? _a : null;
                this.status = (_b = filter === null || filter === void 0 ? void 0 : filter.status) !== null && _b !== void 0 ? _b : [];
                this.callTakerIDs = (_c = filter === null || filter === void 0 ? void 0 : filter.callTakerIDs) !== null && _c !== void 0 ? _c : [];
                this.callTakerTeams = (_d = filter === null || filter === void 0 ? void 0 : filter.callTakerTeams) !== null && _d !== void 0 ? _d : [];
                this.assigneeIDs = (_e = filter === null || filter === void 0 ? void 0 : filter.assigneeIDs) !== null && _e !== void 0 ? _e : [];
                this.assigneeTeams = (_f = filter === null || filter === void 0 ? void 0 : filter.assigneeTeams) !== null && _f !== void 0 ? _f : [];
            }
        });
        Object.defineProperty(this, "setCreatedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.createdAt = value;
            }
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.status = value;
            }
        });
        Object.defineProperty(this, "setCallTakerIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.callTakerIDs = value;
            }
        });
        Object.defineProperty(this, "setCallTakerTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.callTakerTeams = value;
            }
        });
        Object.defineProperty(this, "setAssigneeIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.assigneeIDs = value;
            }
        });
        Object.defineProperty(this, "setAssigneeTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.assigneeTeams = value;
            }
        });
        Object.defineProperty(this, "persistFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (filters) => {
                sessionStorage.setItem('filters', filters);
            }
        });
        makeObservable(this);
        reaction(() => JSON.stringify(this.serialized), (json) => this.persistFilters(json));
        this.reset(filter || this.persistedFilters || this.defaultData);
    }
    get empty() {
        return {
            createdAt: null,
            status: [],
            callTakerIDs: [],
            callTakerTeams: [],
            assigneeIDs: [],
            assigneeTeams: [],
        };
    }
    get serialized() {
        return cloneDeep({
            createdAt: this.createdAt,
            status: this.status,
            callTakerIDs: this.callTakerIDs,
            callTakerTeams: this.callTakerTeams,
            assigneeIDs: this.assigneeIDs,
            assigneeTeams: this.assigneeTeams,
        });
    }
    get hasFiltersSet() {
        return Boolean(this.status.length ||
            this.callTakerIDs.length ||
            this.callTakerTeams.length ||
            this.assigneeIDs.length ||
            this.assigneeTeams.length ||
            this.createdAt);
    }
    get caseReviewRequestFilterInput() {
        let startCopy = null;
        let endCopy = null;
        if (this.createdAt) {
            startCopy = new Date(this.createdAt.start);
            endCopy = new Date(this.createdAt.end);
        }
        return {
            status: this.status,
            assigneeUserIDs: union(this.assigneeIDs, this.assigneeTeams.flatMap((it) => it.members.map((it) => it.user.id))),
            callTakerUserIDs: union(this.callTakerIDs, this.callTakerTeams.flatMap((it) => it.members.map((it) => it.user.id))),
            createdAt: startCopy && endCopy
                ? { start: startCopy.toISOString(), end: endCopy.toISOString() }
                : undefined,
        };
    }
    get persistedFilters() {
        const serializedFilters = sessionStorage.getItem('filters');
        return serializedFilters ? JSON.parse(serializedFilters) : undefined;
    }
    get defaultData() {
        const DEFAULT_FILTER_DATE_RANGE = 30;
        const currentDay = convertZonedToUtcTime(new Date());
        return {
            createdAt: {
                start: startOfDay(subDays(currentDay, DEFAULT_FILTER_DATE_RANGE - 1)),
                end: endOfDay(currentDay),
            },
        };
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "createdAt", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "status", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "callTakerIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "callTakerTeams", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "assigneeIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "assigneeTeams", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "reset", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setCreatedAt", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setStatus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setCallTakerIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setCallTakerTeams", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setAssigneeIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsFilterModel.prototype, "setAssigneeTeams", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseReviewRequestsFilterModel.prototype, "serialized", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CaseReviewRequestsFilterModel.prototype, "hasFiltersSet", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseReviewRequestsFilterModel.prototype, "caseReviewRequestFilterInput", null);
