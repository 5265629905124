import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import img from './assets/image@1x.png';
import img2x from './assets/image@2x.png';
import img3x from './assets/image@3x.png';
export const NoToDoTasksView = () => {
    const { t } = useTranslation('tasks', { keyPrefix: 'noToDoTasksView' });
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, justifyContent: "center", alignItems: "center" },
        React.createElement(Typography, { color: "default", variant: "h6" }, t('title', 'No tasks to show')),
        React.createElement(Typography, { color: "default" }, t('description', 'Tasks assigned to you will appear here.')),
        React.createElement("img", { alt: "empty list", style: { maxInlineSize: '100%', blockSize: 'auto' }, srcSet: `${img} 1x, ${img2x} 2x, ${img3x} 3x`, src: img })));
};
