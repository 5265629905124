var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseGraphEditorNode, ViewNodeModel } from 'lib/graphEditor/canvas/nodes';
import { BaseEntity } from './core';
// eslint-disable-next-line import/no-unused-modules -- It is actually used, but ESLint doesn't see it
export class BranchModel extends BaseEntity {
    constructor(options) {
        var _a, _b, _c, _d;
        super();
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startNodeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keywords", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "viewport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pointerPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "$canvasElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.parent = options.parent;
        this.name = (_b = (_a = options.data) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Default Branch';
        this.nodes = new Map();
        this.keywords = (_d = (_c = options.data) === null || _c === void 0 ? void 0 : _c.keywords) !== null && _d !== void 0 ? _d : [];
        this.setViewport({ x: 0, y: 0, zoom: 1 });
        this.pointerPosition = { x: 0, y: 0 };
    }
    getCanvasBounds() {
        return this.$canvasElement ? this.$canvasElement.getBoundingClientRect() : undefined;
    }
    setViewport(viewport) {
        this.viewport = viewport;
    }
    setPointerPosition(point) {
        this.pointerPosition = point;
    }
    addNode(node) {
        var _a;
        node.setParent(this);
        this.nodes.set(node.id, node);
        if (!this.startNode && node instanceof ViewNodeModel) {
            this.setStartNode(node.id);
        }
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.fireEvent('nodeAdded', { node });
    }
    removeNode(node) {
        var _a;
        node.dispose();
        this.nodes.delete(node.id);
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.fireEvent('nodeRemoved', { node });
    }
    getNodeByID(id) {
        return this.nodes.get(id);
    }
    get startNode() {
        if (!this.startNodeID) {
            return undefined;
        }
        return this.getNodeByID(this.startNodeID);
    }
    get links() {
        const duppedLinks = [...this.nodes.values()].flatMap((node) => {
            return node.ports.flatMap((port) => port.links);
        });
        return Array.from(new Set(duppedLinks));
    }
    getPortByID(id) {
        let found;
        for (let node of this.nodes.values()) {
            found = node.ports.find((p) => p.id === id);
            if (found) {
                break;
            }
        }
        return found;
    }
    get logicGates() {
        const result = new Map();
        this.nodes.forEach((n) => {
            if (n instanceof ViewNodeModel) {
                n.logicGates.forEach((l) => result.set(l.id, l));
            }
        });
        return result;
    }
    getLogicGateByID(id) {
        return this.logicGates.get(id);
    }
    get entities() {
        return [...this.nodes.values(), ...this.links];
    }
    get selectedEntities() {
        return [...this.selectedNodes, ...this.selectedLinks];
    }
    get selectedNodes() {
        return [...this.nodes.values()].filter((n) => n.isSelected);
    }
    get selectedLinks() {
        return this.links.filter((l) => l.isSelected);
    }
    setName(value) {
        this.name = value;
    }
    setStartNode(nodeID) {
        this.startNodeID = nodeID;
    }
    remove() {
        var _a;
        (_a = this.parent) === null || _a === void 0 ? void 0 : _a.removeBranch(this);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { name: this.name, startNodeID: this.startNodeID, keywords: [...this.keywords] });
    }
    getNodeFactory() {
        var _a;
        return (_a = this.parent) === null || _a === void 0 ? void 0 : _a.ctx.nodeFactory;
    }
    deserialize(entity, ctx) {
        super.deserialize(entity, ctx);
        this.name = entity.name;
        if (entity.startNodeID) {
            const node = this.getNodeByID(entity.startNodeID);
            if (node instanceof ViewNodeModel) {
                this.setStartNode(node.id);
            }
        }
        this.keywords = entity.keywords;
    }
    merge(branch) {
        if (branch.name) {
            this.setName(branch.name);
        }
        branch.nodes.forEach((n) => {
            const existingNode = this.getNodeByID(n.id);
            if (existingNode) {
                existingNode.merge(n);
            }
            else {
                this.addNode(n);
            }
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], BranchModel.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], BranchModel.prototype, "startNodeID", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], BranchModel.prototype, "nodes", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], BranchModel.prototype, "keywords", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], BranchModel.prototype, "viewport", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "setViewport", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof BaseGraphEditorNode !== "undefined" && BaseGraphEditorNode) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "addNode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof BaseGraphEditorNode !== "undefined" && BaseGraphEditorNode) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "removeNode", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "startNode", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "links", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_e = typeof Map !== "undefined" && Map) === "function" ? _e : Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "logicGates", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_f = typeof Array !== "undefined" && Array) === "function" ? _f : Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "entities", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_g = typeof Array !== "undefined" && Array) === "function" ? _g : Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "selectedEntities", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_h = typeof Array !== "undefined" && Array) === "function" ? _h : Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "selectedNodes", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_j = typeof Array !== "undefined" && Array) === "function" ? _j : Object),
    __metadata("design:paramtypes", [])
], BranchModel.prototype, "selectedLinks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "setName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "setStartNode", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BranchModel.prototype, "remove", null);
