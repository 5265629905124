import React from 'react';
import { useTranslation } from '@corti/i18n';
import { simpleIpc } from '@corti/ipc-renderer';
import { Box, Button, Typography } from 'lib/cortiUI';
export function SettingsControlsPanel() {
    const { t } = useTranslation('screenRecorder');
    const handleViewRecordings = () => {
        void simpleIpc.callMain('app:openFileLocation', 'recordings');
    };
    return (React.createElement(Box, { p: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Typography, { textAlign: "start", variant: "h4", color: "default" }, t('viewTitle', 'Screen Recording')),
        React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center", gap: 4 },
            React.createElement(Button, { variant: "outlined", color: "primary", onClick: handleViewRecordings }, t('viewRecordingsBtn', 'View recordings')))));
}
