import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Element, Node, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { ExpressionEvaluator } from 'lib/triageFlow/utils/expressionEvaluator';
import { useTriageSession } from 'lib/triageSession/TriageSessionView/TriageSessionContext';
export const ExpressionTextResult = observer(function ExpressionTextResult(props) {
    const element = props.element;
    const { controller } = useTriageSession();
    const editor = useSlate();
    const activeValue = controller.flow.tempBlockValueStore.activeValue;
    const getBlockValue = (blockValue) => {
        const blockProto = controller.flow.graphTraverser.getBlockProtoByID(blockValue.identifier.blockPrototypeID);
        if ((blockProto === null || blockProto === void 0 ? void 0 : blockProto.type) === 'SELECT') {
            if (activeValue &&
                blockProto.max &&
                blockProto.options.some((o) => o.id === activeValue.identifier.optionID)) {
                return (activeValue === null || activeValue === void 0 ? void 0 : activeValue.identifier.optionID) &&
                    blockValue.identifier.optionID === activeValue.identifier.optionID
                    ? activeValue
                    : ({
                        identifier: blockValue.identifier,
                        value: 'false',
                    });
            }
            return (activeValue === null || activeValue === void 0 ? void 0 : activeValue.identifier.optionID) &&
                blockValue.identifier.optionID === activeValue.identifier.optionID
                ? activeValue
                : blockValue;
        }
        return blockValue;
    };
    const blockValues = controller.flow.blockValueStore.all.map(getBlockValue);
    const env = ExpressionEvaluator.createEnvFromFlowValues({
        blockValues,
        factValues: controller.flow.factValueStore.all,
        graph: controller.flow.graphTraverser,
        evaluateToText: true,
    });
    const value = ExpressionEvaluator.evaluateText({
        expression: element.attributes.expression,
        environment: env,
    });
    const path = ReactEditor.findPath(editor, element);
    useEffect(() => {
        const node = Node.get(editor, path);
        if (Element.isElement(node) && node.children) {
            const firstChild = node.children[0];
            if (value !== undefined && 'text' in firstChild && firstChild.text !== value) {
                Transforms.insertText(editor, value, { at: path.concat([0]) });
            }
        }
    }, [editor, path, value]);
    return React.createElement("span", Object.assign({}, props.attributes), props.children);
});
