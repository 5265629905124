var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { reorder } from '@corti/collections';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Box, Button, SortableContainer, SortableElement } from 'lib/cortiUI';
import { ValuePublisherWidget } from '../ValuePublisherWidget';
import { FormElementWidget } from './FormElementWidget';
import { SelectOptionElementWidget } from './SelectOptionElementWidget';
export const SelectElementWidget = observer(function SelectElementWidget(props) {
    const { model } = props, rest = __rest(props, ["model"]);
    const { t } = useTranslation('libGraphEditor');
    const theme = useTheme();
    return (React.createElement(FormElementWidget, { model: model },
        React.createElement(Base, null,
            React.createElement(SortableContainer, { handleDragEnd: (sourceIdx, targetIdx) => {
                    model.setOptions(reorder(model.options, sourceIdx, targetIdx));
                } }, ({ containerProps, placeholder }) => (React.createElement(React.Fragment, null,
                React.createElement(Observer, null, () => (React.createElement(Base, Object.assign({}, containerProps, rest, { "data-cy": "node-select-options-container" }), model.options.map((o, idx) => {
                    return (React.createElement(SortableElement, { key: o.id, index: idx }, ({ itemProps, handleProps }) => (React.createElement(Box, Object.assign({ pt: 6 }, itemProps),
                        React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                            React.createElement(Base, Object.assign({}, handleProps, { width: 10, alignSelf: "stretch", background: theme.palette.background.divider, mr: 4, style: { cursor: 'grab' } })),
                            React.createElement(Base, { flex: 1 },
                                React.createElement(SelectOptionElementWidget, { model: o }),
                                React.createElement(ValuePublisherWidget, { valuePublisherConfig: o.valuePublisher })))))));
                })))), placeholder !== null && placeholder !== void 0 ? placeholder : React.createElement("span", null)))),
            React.createElement(Base, { mt: 6 },
                React.createElement(Button, { "data-cy": "add-select-option-button", size: "small", variant: "outlined", fullWidth: true, color: "primary", onClick: () => {
                        model.addOption({ text: '' });
                    } }, t('contentBuilder.selectElement.addOptionBtn', 'Add Option'))))));
});
