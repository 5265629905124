var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from '../baseHoc';
export const elements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'];
/**
 * This is the lowest level Text component. It should be used then text styles need to be independant from theme typography.
 */
export const Text = React.forwardRef(function TextNew(props, ref) {
    const { element: Element = 'span', children, display = 'inline-block', color, uppercase, textAlign = 'inherit', gutterBottom, noWrap, noSelect } = props, rest = __rest(props, ["element", "children", "display", "color", "uppercase", "textAlign", "gutterBottom", "noWrap", "noSelect"]);
    const theme = useTheme();
    const baseCss = useBaseCss(rest);
    const filteredProps = filterBaseProps(rest);
    return (React.createElement(Element, Object.assign({}, filteredProps, { className: css({
            display: display,
            color: color,
            textAlign: textAlign,
            textTransform: uppercase ? 'uppercase' : undefined,
            marginBottom: gutterBottom ? theme.space[4] : undefined,
            whiteSpace: noWrap ? 'nowrap' : undefined,
            overflow: noWrap ? 'hidden' : undefined,
            textOverflow: noWrap ? 'ellipsis' : undefined,
            userSelect: noSelect ? 'none' : undefined,
        }, baseCss, props.className), ref: ref }), children));
});
