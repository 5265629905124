var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _IntegrationObserver_instances, _IntegrationObserver_observer, _IntegrationObserver_handleEvent;
import { Observer } from '@corti/observer';
import { EventFactory } from './utils';
export class IntegrationObserver {
    constructor(flowStore) {
        _IntegrationObserver_instances.add(this);
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        _IntegrationObserver_observer.set(this, void 0);
        __classPrivateFieldSet(this, _IntegrationObserver_observer, new Observer(), "f");
        this.flowStore.actionStore.onActionBlockTriggered(__classPrivateFieldGet(this, _IntegrationObserver_instances, "m", _IntegrationObserver_handleEvent).call(this, EventFactory.createActionBlockTriggered));
        this.flowStore.stepStore.onActiveStepChanged(__classPrivateFieldGet(this, _IntegrationObserver_instances, "m", _IntegrationObserver_handleEvent).call(this, EventFactory.createActiveStepChanged));
        this.flowStore.factValueStore.onUpdate(__classPrivateFieldGet(this, _IntegrationObserver_instances, "m", _IntegrationObserver_handleEvent).call(this, EventFactory.createFactValuesUpdated));
        this.flowStore.blockValueStore.onUpdate(__classPrivateFieldGet(this, _IntegrationObserver_instances, "m", _IntegrationObserver_handleEvent).call(this, EventFactory.createBlockValuesUpdated));
    }
    onEvent(cb) {
        return __classPrivateFieldGet(this, _IntegrationObserver_observer, "f").on('event', cb);
    }
}
_IntegrationObserver_observer = new WeakMap(), _IntegrationObserver_instances = new WeakSet(), _IntegrationObserver_handleEvent = function _IntegrationObserver_handleEvent(eventConverter) {
    return (input) => {
        if (input.source === 'state-synchronization') {
            return;
        }
        __classPrivateFieldGet(this, _IntegrationObserver_observer, "f").fireEvent('event', eventConverter(input));
    };
};
