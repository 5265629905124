import { useCallback, useEffect, useRef } from 'react';
import formatNumber from './formatNumber';
function useNumberInputStepper({ setInternalValue, handleTemporaryValue, min, max, precision, step, }) {
    const inputRef = useRef(null);
    const onDelta = useCallback((direction) => {
        var _a;
        setInternalValue((prev) => {
            var _a, _b;
            const rawOldValue = parseFloat(prev);
            const oldValue = isNaN(rawOldValue) ? 0 : rawOldValue;
            const newValue = oldValue + direction * step;
            // Due to floating point precision, we need to round the value to the precision of step
            const stepPrecision = (_b = (_a = step.toString().split('.')[1]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            const stepFactor = Math.pow(10, stepPrecision);
            const roundedNewValue = Math.round(newValue * stepFactor) / stepFactor;
            const formattedValue = formatNumber({
                value: roundedNewValue.toString(),
                min,
                max,
                precision,
            });
            handleTemporaryValue(formattedValue);
            return formattedValue;
        });
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [setInternalValue, step, min, max, precision, handleTemporaryValue]);
    // Handle keyboard stepping
    useEffect(() => {
        if (!inputRef.current) {
            return;
        }
        const checkStepping = (e) => {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                e.stopPropagation();
                onDelta(1);
            }
            else if (e.key === 'ArrowDown') {
                e.preventDefault();
                e.stopPropagation();
                onDelta(-1);
            }
        };
        const node = inputRef.current;
        node.addEventListener('keydown', checkStepping);
        return () => {
            node.removeEventListener('keydown', checkStepping);
        };
    }, [onDelta]);
    // Handle stepper buttons
    const onIncrement = () => onDelta(1);
    const onDecrement = () => onDelta(-1);
    return { inputRef, onIncrement, onDecrement };
}
export default useNumberInputStepper;
