var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { DEFAULT_DEFINITION_COLOR } from '@corti/lib/coreApiService';
import { css, readableColor, transparentize } from '@corti/style';
import { withTheme } from '@corti/theme';
import { Keybinding } from 'browser/components';
import { Region, RegionsPlugin } from 'browser/components/WaveSurfer/plugins/RegionsPlugin';
import { trackerService } from 'browser/services/init';
import { Base, Box, ContextMenuCtx, ContextMenuItem, Typography, } from 'lib/cortiUI';
import { CreateEditTimelineEntryDropdown } from './CreateEditTimelineEntryDropdown';
const MIN_ENTRY_SIZE = 0.2;
//@ts-ignore
let TimelineEntriesPlugin = class TimelineEntriesPlugin extends React.Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "shouldContinuePlaying", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "entryRefs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "tempRegionRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "keyboardTempRegionStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isHighlighting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "dropdownAnchor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "timelineEntryDropdownState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "showCreateNewAnnotationDropdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.timelineEntryDropdownState = {
                    type: 'create',
                };
                this.dropdownAnchor = this.tempRegionRef;
            }
        });
        Object.defineProperty(this, "showEditAnnotationDropdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (timelineEntry) => {
                var _a;
                this.timelineEntryDropdownState = {
                    type: 'edit',
                    timelineEntry,
                };
                this.dropdownAnchor = (_a = this.entryRefs.get(timelineEntry.id)) !== null && _a !== void 0 ? _a : null;
            }
        });
        Object.defineProperty(this, "hideAnnotationDropdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.timelineEntryDropdownState = undefined;
                this.dropdownAnchor = null;
            }
        });
        Object.defineProperty(this, "setKeyboardTempRegionStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (n) => {
                this.keyboardTempRegionStart = n;
            }
        });
        Object.defineProperty(this, "setIsHighlighting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isHighlighting = value;
            }
        });
        Object.defineProperty(this, "seekToClosestEntry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (seekDirection) => {
                const { callPlayerState, caseViewState } = this.props;
                callPlayerState.setCurrentTimeToClosestEntryOffset(caseViewState.audioRelativeTimelineEntries.map((a) => a.getOffsetPosition(callPlayerState.call.start).start / 1000), seekDirection);
            }
        });
        makeObservable(this);
        // Keyboard temp region creation
        reaction(() => this.props.callPlayerState.currentTime, (currentTime) => {
            if (this.keyboardTempRegionStart) {
                this.props.timelineEditor.updateTempRegion(this.keyboardTempRegionStart <= currentTime
                    ? {
                        startOffsetS: this.keyboardTempRegionStart,
                        endOffsetS: currentTime,
                    }
                    : {
                        startOffsetS: currentTime,
                        endOffsetS: this.keyboardTempRegionStart,
                    });
            }
        });
        // Pause/play timeline with annotation creation and mouse drag
        reaction(() => this.isCreatingNewRegion, (isCreatingNewRegion) => {
            const isPlaying = props.callPlayerState.isPlaying;
            if (isCreatingNewRegion && isPlaying) {
                props.callPlayerState.setIsPlaying(false);
                this.shouldContinuePlaying = true;
            }
            else if (!isCreatingNewRegion && this.shouldContinuePlaying) {
                props.callPlayerState.setIsPlaying(true);
                this.shouldContinuePlaying = false;
            }
        });
    }
    get isCreatingNewRegion() {
        return this.isHighlighting || !!this.timelineEntryDropdownState;
    }
    offsetToAbsolute(offsetInSeconds) {
        return new Date(offsetInSeconds * 1000 + this.props.callPlayerState.call.start.getTime());
    }
    render() {
        var _a, _b, _c;
        const { timelineEditor, caseViewState, callPlayerState, theme } = this.props;
        function getOffsetFromAudioEndInPx(entry) {
            const audioDuration = callPlayerState.duration * 1000;
            const entryStartOffset = entry.getOffsetPosition(callPlayerState.call.start).start;
            return ((audioDuration - entryStartOffset) / 1000) * callPlayerState.zoom;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Keybinding, { keycode: "right", modifier: "shift", text: "Seek forward to closest entry", handler: () => {
                    this.seekToClosestEntry('forward');
                } }),
            React.createElement(Keybinding, { keycode: "left", modifier: "shift", text: "Seek backward to closest entry", handler: () => {
                    this.seekToClosestEntry('backward');
                } }),
            React.createElement(Keybinding, { keycode: "enter", text: "Toggle highlight creation", handler: () => {
                    if (!this.keyboardTempRegionStart) {
                        this.setKeyboardTempRegionStart(callPlayerState.currentTime);
                        timelineEditor.createTempRegion({
                            startOffsetS: this.keyboardTempRegionStart,
                            endOffsetS: callPlayerState.currentTime,
                        });
                    }
                    else if (Math.abs(this.keyboardTempRegionStart - callPlayerState.currentTime) > MIN_ENTRY_SIZE) {
                        this.setKeyboardTempRegionStart();
                        this.showCreateNewAnnotationDropdown();
                    }
                } }),
            React.createElement(Keybinding, { keycode: "esc", text: "Cancel", handler: () => {
                    timelineEditor.handleCancel();
                    this.setKeyboardTempRegionStart();
                } }),
            React.createElement(Keybinding, { keycode: "z", modifier: "mod", text: "Undo", canExecute: () => timelineEditor.canUndo, handler: () => {
                    timelineEditor.undo();
                } }),
            React.createElement(Keybinding, { keycode: "z", modifier: ['mod', 'shift'], text: "Redo", canExecute: () => timelineEditor.canRedo, handler: () => {
                    timelineEditor.redo();
                } }),
            React.createElement(Keybinding, { keycode: "del", text: "Remove", canExecute: () => { var _a, _b; return (_b = (_a = timelineEditor.activeTimelineEntry) === null || _a === void 0 ? void 0 : _a.isEditable) !== null && _b !== void 0 ? _b : false; }, handler: () => {
                    if (timelineEditor.activeTimelineEntryID) {
                        timelineEditor.removeTimelineEntry(timelineEditor.activeTimelineEntryID);
                    }
                } }),
            React.createElement(RegionsPlugin, { enableCreation: !timelineEditor.isTimelineLocked, onEmptySpaceClick: () => {
                    this.setKeyboardTempRegionStart();
                    timelineEditor.removeTempRegion();
                    this.hideAnnotationDropdown();
                    timelineEditor.setActiveTimelineEntry();
                }, onRegionCreateStart: () => {
                    this.setKeyboardTempRegionStart();
                    this.setIsHighlighting(true);
                    timelineEditor.removeTempRegion();
                    this.hideAnnotationDropdown();
                }, onRegionCreateEnd: (e) => {
                    this.setIsHighlighting(false);
                    if (!e.end || e.end - e.start < MIN_ENTRY_SIZE) {
                        timelineEditor.removeTempRegion();
                        return;
                    }
                    timelineEditor.createTempRegion({
                        startOffsetS: e.start,
                        endOffsetS: e.end,
                    });
                    this.showCreateNewAnnotationDropdown();
                }, newRegionBackgroundColor: transparentize(0.95, theme.palette.background.tooltip) },
                timelineEditor.tempRegion && !timelineEditor.isTimelineLocked && (React.createElement(Region, { refCallback: (el) => {
                        this.tempRegionRef = el;
                    }, id: 'temp-region', start: timelineEditor.tempRegion.startOffsetS, end: timelineEditor.tempRegion.endOffsetS, backgroundColor: transparentize(0.95, theme.palette.background.tooltip), onClick: (e) => {
                        if (timelineEditor.activeTimelineEntryID !== e.id) {
                            timelineEditor.setActiveTimelineEntry(e.id);
                        }
                    }, onUpdateEnd: (e) => {
                        timelineEditor.updateTempRegion({
                            startOffsetS: e.start,
                            endOffsetS: e.end,
                        });
                    } }, ({ getDragHandleProps, getResizeStartHandleProps, getResizeEndHandleProps }) => {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Base, Object.assign({ "data-cy": "temp-region" }, getDragHandleProps(), { className: css({
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                cursor: 'move',
                            }), onContextMenu: (e) => {
                                e.preventDefault();
                            } })),
                        React.createElement(Base, Object.assign({ "data-cy": "temp-region-left-border" }, getResizeStartHandleProps(), { className: css({
                                position: 'absolute',
                                left: 0,
                                height: '100%',
                                width: 2,
                                cursor: 'col-resize',
                            }) })),
                        React.createElement(Base, Object.assign({ "data-cy": "temp-region-right-border" }, getResizeEndHandleProps(), { className: css({
                                position: 'absolute',
                                right: 0,
                                height: '100%',
                                width: 2,
                                cursor: 'col-resize',
                            }) }))));
                })),
                caseViewState.audioRelativeTimelineEntries.map((timelineEntry, idx) => {
                    const isActive = timelineEditor.activeTimelineEntryID === timelineEntry.id;
                    const offsetPosition = timelineEntry.getOffsetPosition(callPlayerState.call.start);
                    const endOffset = getOffsetFromAudioEndInPx(timelineEntry);
                    const topHandleMinWidth = 100;
                    const color = timelineEntry.definition.style.color;
                    const backgroundColor = transparentize(isActive ? 0.5 : 0.7, color !== null && color !== void 0 ? color : DEFAULT_DEFINITION_COLOR);
                    const borderColor = isActive
                        ? color
                        : transparentize(0.7, color !== null && color !== void 0 ? color : DEFAULT_DEFINITION_COLOR);
                    const borderWidth = 1;
                    return (React.createElement(Region, { refCallback: (el) => {
                            this.entryRefs.set(timelineEntry.id, el);
                        }, key: timelineEntry.id, id: timelineEntry.id, start: offsetPosition.start / 1000, end: offsetPosition.end ? offsetPosition.end / 1000 : undefined, disableDrag: timelineEditor.isTimelineLocked || !timelineEntry.isEditable || !isActive, disableResize: timelineEditor.isTimelineLocked || !timelineEntry.isEditable || !isActive, orderPosition: idx, backgroundColor: backgroundColor, borderColor: borderColor, borderWidth: borderWidth, onClick: (e) => {
                            if (timelineEditor.activeTimelineEntryID !== e.id) {
                                timelineEditor.setActiveTimelineEntry(e.id);
                            }
                        }, onUpdateEnd: (e) => {
                            const entry = timelineEditor.getTimelineEntry(e.id);
                            entry.updatePosition({
                                startDatetime: this.offsetToAbsolute(e.start),
                                endDatetime: e.end != null ? this.offsetToAbsolute(e.end) : undefined,
                            });
                        } }, ({ getDragHandleProps, getResizeStartHandleProps, getResizeEndHandleProps, disableDrag, disableResize, }) => {
                        return (React.createElement(Observer, null, () => {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(Base, Object.assign({ "data-cy": "timeline-annotation", "data-cy-state": `timeline-annotation-active-${isActive}` }, getDragHandleProps(), { className: css({
                                        width: '100%',
                                        backgroundColor: backgroundColor,
                                        opacity: isActive ? 1 : 0.75,
                                        cursor: !disableDrag ? 'move' : 'pointer',
                                        minWidth: topHandleMinWidth,
                                        position: 'absolute',
                                        right: endOffset > topHandleMinWidth ? 'unset' : 0,
                                        boxShadow: isActive ? '0 0 15px rgba(0, 0, 0, 0.5)' : 'unset',
                                        transform: isActive ? 'scale(1.05)' : 'unset',
                                        transformOrigin: '0 50%',
                                    }), title: `${timelineEntry.definition.title}\n${timelineEntry.text}`, onContextMenu: (e) => {
                                        this.context.openContextMenu(e, React.createElement(React.Fragment, null,
                                            timelineEntry.isEditable && (React.createElement(React.Fragment, null,
                                                React.createElement(ContextMenuItem, { title: intl.t('edit', 'Edit'), disabled: timelineEditor.isTimelineLocked, onClick: () => {
                                                        this.showEditAnnotationDropdown(timelineEntry);
                                                    } }),
                                                React.createElement(ContextMenuItem, { title: intl.t('explore:caseView.revertSingleAnnotation', 'Revert annotation'), disabled: !timelineEntry.isDirty, onClick: () => {
                                                        timelineEditor.resetTimelineEntry(timelineEntry);
                                                    } }),
                                                React.createElement(ContextMenuItem, { title: intl.t('remove', 'Remove'), disabled: timelineEditor.isTimelineLocked, onClick: () => {
                                                        timelineEntry.remove();
                                                    } }))),
                                            React.createElement(ContextMenuItem, { title: intl.t('explore:caseView.bringToFront', 'Bring to Front'), onClick: () => {
                                                    timelineEditor.bringToFront(timelineEntry.id);
                                                } }),
                                            React.createElement(ContextMenuItem, { title: intl.t('explore:caseView.sendToBack', 'Send to Back'), onClick: () => {
                                                    timelineEditor.sendToBack(timelineEntry.id);
                                                } }),
                                            React.createElement(ContextMenuItem, { title: intl.t('explore:caseView.reportAnnotation', 'Report Annotation'), onClick: () => {
                                                    trackerService.track(`[Single case] Annotation reported`, {
                                                        annotationId: timelineEntry.id,
                                                        timelineEntry: {
                                                            id: timelineEntry.id,
                                                            callID: timelineEntry.callID,
                                                            definition: timelineEntry.definition,
                                                            createdBy: timelineEntry.createdBy,
                                                            createdAt: timelineEntry.createdAt,
                                                            title: timelineEntry.definition.title,
                                                            text: timelineEntry.definition.text,
                                                            color: timelineEntry.definition.style.color,
                                                        },
                                                    });
                                                } })));
                                    } }),
                                    React.createElement(Box, { "data-cy": "timeline-annotation-drag-bar", height: 30, py: 2, px: 4, justifyContent: "space-between" },
                                        React.createElement(Typography, { noWrap: true, variant: "footnote", style: {
                                                opacity: 0.8,
                                                color: readableColor(color !== null && color !== void 0 ? color : DEFAULT_DEFINITION_COLOR),
                                            }, uppercase: true },
                                            timelineEntry.isDirty ? '* ' : '',
                                            timelineEntry.definition.title),
                                        timelineEntry.text && (React.createElement(Typography, { variant: "subtitle2", noWrap: true, style: {
                                                color: readableColor(color !== null && color !== void 0 ? color : DEFAULT_DEFINITION_COLOR),
                                            } }, timelineEntry.text)))),
                                !disableResize && (React.createElement(Base, Object.assign({ "data-cy": "timeline-annotation-left-border" }, getResizeStartHandleProps(), { className: css({
                                        backgroundColor: borderColor,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        position: 'absolute',
                                        top: '50%',
                                        left: -2,
                                        height: '30%',
                                        width: 8,
                                        transform: 'translate(-100%, -50%)',
                                        cursor: 'col-resize',
                                    }) }))),
                                !disableResize && (React.createElement(Base, Object.assign({ "data-cy": "timeline-annotation-right-border" }, getResizeEndHandleProps(), { className: css({
                                        backgroundColor: borderColor,
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        position: 'absolute',
                                        top: '50%',
                                        right: -2,
                                        height: '30%',
                                        width: 8,
                                        transform: 'translate(100%, -50%)',
                                        cursor: 'col-resize',
                                    }) })))));
                        }));
                    }));
                })),
            this.timelineEntryDropdownState && (React.createElement(CreateEditTimelineEntryDropdown, { anchorEl: this.dropdownAnchor, type: this.timelineEntryDropdownState.type, initialData: this.timelineEntryDropdownState.type === 'create'
                    ? {
                        definitionSource: (_b = (_a = timelineEditor.newTimelineEntryInitialInput) === null || _a === void 0 ? void 0 : _a.definition) === null || _b === void 0 ? void 0 : _b.source,
                        timelineEntryDefinitions: caseViewState.timelineEntryDefinitions,
                    }
                    : {
                        definitionSource: (_c = this.timelineEntryDropdownState.timelineEntry) === null || _c === void 0 ? void 0 : _c.definition.source,
                        timelineEntryDefinitions: caseViewState.timelineEntryDefinitions,
                    }, onClose: () => {
                    this.hideAnnotationDropdown();
                    timelineEditor.handleCancel();
                }, onSubmit: ({ definition }) => {
                    if (this.timelineEntryDropdownState.type === 'edit') {
                        this.timelineEntryDropdownState.timelineEntry.setDefinition(definition);
                    }
                    else {
                        if (timelineEditor.tempRegion && timelineEditor.currentCall) {
                            timelineEditor.createTimelineEntry({
                                callID: timelineEditor.currentCall.id,
                                startDatetime: this.offsetToAbsolute(timelineEditor.tempRegion.startOffsetS),
                                endDatetime: timelineEditor.tempRegion.endOffsetS
                                    ? this.offsetToAbsolute(timelineEditor.tempRegion.endOffsetS)
                                    : undefined,
                                definition,
                            });
                            timelineEditor.removeTempRegion();
                        }
                    }
                    this.hideAnnotationDropdown();
                } }))));
    }
};
Object.defineProperty(TimelineEntriesPlugin, "contextType", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ContextMenuCtx
});
__decorate([
    observable,
    __metadata("design:type", Number)
], TimelineEntriesPlugin.prototype, "keyboardTempRegionStart", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], TimelineEntriesPlugin.prototype, "isHighlighting", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "dropdownAnchor", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "timelineEntryDropdownState", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "showCreateNewAnnotationDropdown", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "showEditAnnotationDropdown", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "hideAnnotationDropdown", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "setKeyboardTempRegionStart", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEntriesPlugin.prototype, "setIsHighlighting", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntriesPlugin.prototype, "isCreatingNewRegion", null);
TimelineEntriesPlugin = __decorate([
    withTheme,
    observer,
    __metadata("design:paramtypes", [Object])
], TimelineEntriesPlugin);
export { TimelineEntriesPlugin };
