import React from 'react';
import { differenceInDays, formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useAuth } from 'core/auth/browser';
import { repositories } from 'core/repositories';
import { Base, Card, ChipV2, Tooltip, Typography, useContextMenu } from 'lib/cortiUI';
import { Assignees } from './Assignees';
import { Score } from './Score';
import { UnassignUser } from './UnassignUser';
export const Task = ({ assignees, createdAt, id, interactionOwner, owner, reviews, status, templateID, type, onClick, onUnassignUser, }) => {
    const { t } = useTranslation('tasks', { keyPrefix: 'task' });
    const { currentUserID } = useAuth();
    const { openContextMenu } = useContextMenu();
    const filteredAssignees = assignees.filter((a) => a !== currentUserID);
    function getTaskDescription() {
        var _a, _b;
        const taskOwnerName = (_a = repositories.users.getUser(owner)) === null || _a === void 0 ? void 0 : _a.name;
        const interactionOwnerName = (_b = repositories.users.getUser(interactionOwner !== null && interactionOwner !== void 0 ? interactionOwner : '')) === null || _b === void 0 ? void 0 : _b.name;
        switch (type) {
            case 'ACKNOWLEDGE_INTERACTION':
                return status === 'PENDING'
                    ? t('type.acknowledge', "Acknowledge {{owner}}'s Review", { owner: taskOwnerName })
                    : t('type.acknowledged', "Acknowledged {{owner}}'s Review", { owner: taskOwnerName });
            case 'REVIEW_INTERACTION':
                return status === 'PENDING'
                    ? t('type.review', "Review {{owner}}'s Interaction", {
                        owner: interactionOwnerName !== null && interactionOwnerName !== void 0 ? interactionOwnerName : taskOwnerName,
                    })
                    : t('type.reviewed', "Reviewed {{owner}}'s Interaction", {
                        owner: interactionOwnerName !== null && interactionOwnerName !== void 0 ? interactionOwnerName : taskOwnerName,
                    });
                return;
            default:
                return '';
        }
    }
    function onContextMenu(event) {
        if (status !== 'PENDING' || type !== 'REVIEW_INTERACTION')
            return;
        openContextMenu(event, React.createElement(React.Fragment, null,
            React.createElement(UnassignUser, { taskID: id, onUnassignUser: onUnassignUser })));
    }
    if (['OBSOLETE', 'NONE'].includes(status)) {
        return null;
    }
    return (React.createElement(Card, { className: css({ cursor: 'pointer' }), onClick: onClick, role: "presentation", onContextMenu: onContextMenu },
        React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "space-between", py: 3, px: 5 },
            React.createElement(Base, { display: "flex", alignItems: "center", gap: 6 },
                React.createElement(Base, { minWidth: 60, display: "flex", alignItems: "center" },
                    React.createElement(Score, { size: "medium", templateID: templateID, reviews: reviews })),
                React.createElement(Typography, { variant: "body2", color: "default" }, getTaskDescription())),
            React.createElement(Base, { display: "flex", alignItems: "center", gap: 6 },
                filteredAssignees.length > 0 && React.createElement(Assignees, { assignees: filteredAssignees }),
                status === 'PENDING' && (React.createElement(Tooltip, { title: formatDateTime(new Date(createdAt)) },
                    React.createElement(ChipV2, { label: t('taskAge', '{{count}} d', {
                            count: differenceInDays(new Date(), new Date(createdAt)),
                        }), size: "medium" })))))));
};
