import { gql } from '@apollo/client';
const mediaAssetFragment = gql `
  fragment MediaAsset on MediaAsset {
    id
    title
    file {
      id
      name
      mimeType
      size
      imageInfo {
        width
        height
      }
    }
    createdAt
  }
`;
export const getMediaAssetsQuery = gql `
  query MediaAssets($organizationID: String!) {
    mediaAssets(organizationID: $organizationID) {
      ...MediaAsset
    }
  }
  ${mediaAssetFragment}
`;
export const createMediaAssetMutation = gql `
  mutation CreateMediaAsset($input: MediaAssetInput!) {
    createMediaAsset(input: $input) {
      ...MediaAsset
    }
  }
  ${mediaAssetFragment}
`;
export const deleteMediaAssetMutation = gql `
  mutation deleteMediaAsset($id: String!) {
    deleteMediaAsset(id: $id)
  }
`;
//#endregion
export const createMediaService = (client, files) => ({
    async createMediaAsset(file, orgID, onProgress) {
        const f = await files.uploadFile({ file, onUploadProgress: onProgress });
        await client.mutate({
            mutation: createMediaAssetMutation,
            variables: {
                input: {
                    title: file.name,
                    fileID: f.id,
                    organizationID: orgID,
                },
            },
        });
    },
    async getMediaAssets(orgID) {
        const res = await client.query({
            query: getMediaAssetsQuery,
            variables: {
                organizationID: orgID,
            },
        });
        return res.data.mediaAssets;
    },
});
