import { useCombobox } from 'downshift';
import fuzzysort from 'fuzzysort';
import { deburr, differenceBy, orderBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTriageSession } from '../TriageSessionContext';
export function useActionSelector({ isMenuOpen, searchQuery, }) {
    const { controller } = useTriageSession();
    const { t } = useTranslation();
    const suggestedItemSuffixText = t('realtime:selectActionMenu.suggestedItemSuffix', 'suggested');
    const allItems = React.useMemo(() => {
        return (controller.flow.graphTraverser
            .getBlockProtos({ properties: { type: 'ACTION' } })
            // if custom properties are defined, check if the action is explictly excluded from the manual action selector list
            .filter((it) => { var _a; return (_a = it.customProperties) === null || _a === void 0 ? void 0 : _a.every(({ key }) => key !== 'excludeFromActionSelector'); })
            .map(prepAction));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const allRelItems = React.useMemo(() => {
        var _a, _b, _c;
        if (!isMenuOpen) {
            return [];
        }
        const scopeToBranchID = (_c = controller.flow.graphTraverser.getBranchByNodeID((_b = (_a = controller.flow.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.node.id) !== null && _b !== void 0 ? _b : '')) === null || _c === void 0 ? void 0 : _c.id;
        const relBlockProtos = controller.flow.graphTraverser.getBlockProtos({
            location: {
                branchID: scopeToBranchID,
            },
            properties: {
                type: 'ACTION',
            },
        });
        return relBlockProtos.map((it) => {
            const p = prepAction(it);
            p.hintText = suggestedItemSuffixText;
            return p;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuOpen, controller.flow.stepStore.activeStep]);
    const allButRelItems = React.useMemo(() => {
        if (!isMenuOpen) {
            return [];
        }
        return differenceBy(allItems, allRelItems, (v) => v.value);
    }, [isMenuOpen, allItems, allRelItems]);
    const result = React.useMemo(() => {
        // optimized only to recalc results when the menu is open
        if (!isMenuOpen) {
            return [];
        }
        const queryDeburred = deburr(searchQuery);
        const results = fuzzysort.go(queryDeburred, allButRelItems, {
            all: true,
            key: '_text',
            threshold: -10000,
        });
        const relresults = fuzzysort.go(queryDeburred, allRelItems, {
            all: true,
            key: '_text',
            threshold: -10000,
        });
        return [...orderItems(relresults.map((r) => r.obj)), ...orderItems(results.map((r) => r.obj))];
    }, [isMenuOpen, allButRelItems, allRelItems, searchQuery]);
    return {
        items: result,
    };
}
function orderItems(items) {
    return orderBy(items, 'text', ['asc']);
}
function prepAction(actionBlockProto) {
    var _a;
    const text = ((_a = actionBlockProto.text) === null || _a === void 0 ? void 0 : _a.plainText) ? actionBlockProto.text.plainText : '-';
    return {
        text,
        value: actionBlockProto.id,
        _text: fuzzysort.prepare(deburr(text)),
    };
}
export function useSelector({ items, onOpen, onClose, onSelectItem, onSearchQueryChange, inputRef, virtualListRef, }) {
    const selector = useCombobox({
        items,
        selectedItem: null,
        defaultHighlightedIndex: 0,
        itemToString: () => '',
        onIsOpenChange: (c) => {
            if (c.isOpen) {
                onOpen === null || onOpen === void 0 ? void 0 : onOpen();
                setTimeout(() => inputRef === null || inputRef === void 0 ? void 0 : inputRef.focus(), 0);
                selector.setInputValue('');
                return;
            }
            onClose === null || onClose === void 0 ? void 0 : onClose();
        },
        onSelectedItemChange: (c) => {
            // If the window loses focus while the dropdown is open and the user has navigated to highlight
            // an option using arrow keys, useCombobox will invoke this callback with selectedItem
            // as the currently highlighted item, and the type will be InputBlur.
            // In this case, we don't want to do anything because the item is not actually selected, it's
            // just highlighted while the app loses focus.
            // Also note, this enum doesn't exist in prod build and it's even converted to numbers,
            // so we have to go through this other path. Yes, it's a pretty terrible design.
            // See also: https://github.com/downshift-js/downshift/issues/918
            if (!c.selectedItem || c.type === useCombobox.stateChangeTypes.InputBlur) {
                return;
            }
            onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(c.selectedItem);
        },
        onInputValueChange: (c) => {
            var _a;
            onSearchQueryChange === null || onSearchQueryChange === void 0 ? void 0 : onSearchQueryChange((_a = c.inputValue) !== null && _a !== void 0 ? _a : '');
        },
        onHighlightedIndexChange: (c) => {
            if (c.highlightedIndex === -1) {
                return;
            }
            virtualListRef === null || virtualListRef === void 0 ? void 0 : virtualListRef.scrollToRow(c.highlightedIndex);
        },
    });
    return selector;
}
