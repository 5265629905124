import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, FocusElement, FocusElementGroup, useGroupedFocusContext } from 'lib/cortiUI';
import { getFocusWithinCss } from '../../utils';
import { useFlow } from '../Flow/FlowProvider';
import { Label } from '../components';
import { MultiDropdown, SingleDropdown } from './Dropdown';
import { mergeOptions, search } from './utils';
function getHoverCss(props) {
    return css({
        '&:hover': {
            border: `1px solid ${props.theme.palette.action.outlinedHoverBorder}`,
        },
    });
}
export function SelectDropdownBlock(props) {
    const { id, label, limit, isReadonly = false, options, selectedOptions, onChange, onOptionEnter, onOptionLeave, } = props;
    const theme = useTheme();
    const isMulti = limit === undefined || limit > 1;
    const flow = useFlow();
    const groupedFocusContext = useGroupedFocusContext();
    const containerRef = React.useRef(null);
    const dropdownMenuToggleRef = React.useRef(null);
    return (React.createElement(Box, null,
        label && (React.createElement(Base, { mb: 3 },
            React.createElement(Label, { label: label }))),
        React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(FocusElement, { defaultElementID: id, defaultGroupID: groupID, isFocusable: !isReadonly }, ({ registerElementRef }) => (React.createElement(Base, { tabIndex: -1, ref: (el) => {
                containerRef.current = el;
                registerElementRef(el);
            }, onClick: (evt) => {
                if (!dropdownMenuToggleRef.current)
                    return;
                // If event doesn't originate inside the toggle, then act as if the toggle was clicked
                if (!dropdownMenuToggleRef.current.contains(evt.target)) {
                    dropdownMenuToggleRef.current.click();
                }
            }, className: css({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: Number(theme.shape.borderRadius),
                border: `1px solid ${theme.palette.action.outlinedBorder}`,
                backgroundColor: theme.palette.background.paper,
                paddingLeft: theme.space[6],
                paddingRight: theme.space[6],
                paddingTop: theme.space[5],
                paddingBottom: theme.space[5],
                minHeight: 55,
            }, isReadonly ? undefined : getHoverCss({ theme }), isReadonly ? undefined : getFocusWithinCss({ theme })) }, isMulti ? (React.createElement(MultiDropdown, { options: options, limit: limit, selectedOptions: selectedOptions, onOptionSelect: (option) => {
                onChange(mergeOptions(option, selectedOptions, limit));
            }, onOptionEmphasize: (option) => {
                onOptionEnter(option);
            }, onOptionDeemphasize: (option) => {
                onOptionLeave(option);
            }, onFocus: () => {
                flow.flowViewStore.disableKeyboardShortcuts();
                groupedFocusContext === null || groupedFocusContext === void 0 ? void 0 : groupedFocusContext.pauseAllKeyboardShortcuts();
            }, onBlur: () => {
                flow.flowViewStore.enableKeyboardShortcuts();
                groupedFocusContext === null || groupedFocusContext === void 0 ? void 0 : groupedFocusContext.unpauseAllKeyboardShortcuts();
            }, onEscapeDown: (e) => {
                var _a;
                e.stopPropagation();
                (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, onClearButtonClick: () => {
                onChange([]);
            }, menuToggleRef: (ref) => (dropdownMenuToggleRef.current = ref), filterOptions: search })) : (React.createElement(SingleDropdown, { options: options, selectedOption: selectedOptions[0], onOptionSelect: (option) => {
                onChange(mergeOptions(option, selectedOptions, limit));
            }, onOptionEmphasize: (option) => {
                onOptionEnter(option);
            }, onOptionDeemphasize: (option) => {
                onOptionLeave(option);
            }, onInputFocus: () => {
                flow.flowViewStore.disableKeyboardShortcuts();
                groupedFocusContext === null || groupedFocusContext === void 0 ? void 0 : groupedFocusContext.pauseAllKeyboardShortcuts();
            }, onInputBlur: () => {
                flow.flowViewStore.enableKeyboardShortcuts();
                groupedFocusContext === null || groupedFocusContext === void 0 ? void 0 : groupedFocusContext.unpauseAllKeyboardShortcuts();
            }, onEscapeDown: (e) => {
                var _a;
                e.stopPropagation();
                (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, onClearButtonClick: () => {
                onChange([]);
            }, menuToggleRef: (ref) => (dropdownMenuToggleRef.current = ref), filterOptions: search })))))))));
}
