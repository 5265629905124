import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
const DndItemTypes = {
    ELEMENT: 'graph-editor/element',
};
export const ElementDropTarget = (props) => {
    const { onDrop } = props;
    const theme = useTheme();
    const [{ isOver, canDrop, item }, connector] = useDrop({
        accept: DndItemTypes.ELEMENT,
        drop: (item) => {
            if (onDrop) {
                return onDrop(item.elementDescriptor);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    });
    let canAcceptElement = item && canDrop;
    return (React.createElement("div", { ref: connector, style: {
            zIndex: 1,
            position: 'relative',
            height: 0,
            width: '100%',
        } },
        React.createElement("div", { style: {
                transform: 'translateY(-50%)',
                height: canAcceptElement ? 24 : 0,
                visibility: canAcceptElement ? 'visible' : 'hidden',
            } },
            React.createElement("div", { "data-cy": "element-drop-target", style: {
                    position: 'relative',
                    backgroundColor: isOver
                        ? transparentize(0.8, theme.palette.primary.main)
                        : theme.palette.action.hover,
                    height: '100%',
                    borderRadius: 4,
                    border: `1px dashed ${isOver ? theme.palette.primary.main : theme.palette.action.outlinedBorder}`,
                } }))));
};
/**
 * React hook for dragging graph node elements
 */
function useElementDragSource(spec) {
    const [, connector] = useDrag({
        item: {
            type: DndItemTypes.ELEMENT,
            elementDescriptor: { type: spec.elementType },
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && monitor.didDrop() && (dropResult === null || dropResult === void 0 ? void 0 : dropResult.dropTarget)) {
                spec.onCreate(dropResult.dropTarget.element, dropResult.dropTarget.positionIndex);
            }
        },
    });
    return connector;
}
/**
 * Component implemented using render prop pattern. A replacement for react hook
 */
export const ElementDragSource = (props) => {
    const { elementType, onCreate, children } = props;
    const connector = useElementDragSource({
        elementType,
        onCreate,
    });
    return children({ connector });
};
