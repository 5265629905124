import { observer } from 'mobx-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getTimeZone } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { logger } from '@corti/logger';
import { useLazyRef } from '@corti/react';
import { Base, Container, Page } from 'lib/cortiUI';
import { api } from '../../services';
import { useTranscriptSearchViewContext } from '../TranscriptSearchView/context';
import { TranscriptQueryModel } from '../entities';
import { CallsPanel } from './CallsPanel';
import { ChartsPanel } from './ChartPanel';
import { Header } from './Header';
import { ParametersPanel } from './ParametersPanel';
import { TranscriptQueryViewModel } from './TranscriptQueryViewModel';
import { TranscriptQueryViewContext } from './context';
const transcriptQueryViewLogger = logger.getLogger('TranscriptQueryView');
export const TranscriptQueryView = observer(function TranscriptQueryView(props) {
    const { transcriptQueryID } = props;
    const { viewModel: searchViewModel, utils } = useTranscriptSearchViewContext();
    const { t } = useTranslation('transcriptSearchApp');
    const { state: locationState, pathname: locationPathname, } = useLocation();
    const history = useHistory();
    const [viewModel, setViewModel] = React.useState(null);
    const queryModelRef = useLazyRef(() => null);
    React.useEffect(() => {
        if (searchViewModel.state.isLoadingTeams)
            return;
        reset(transcriptQueryID).catch((err) => {
            transcriptQueryViewLogger.error(`Reset ${transcriptQueryID} failed with: `, err.message);
            // reset view model on error
            setViewModel(null);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptQueryID, searchViewModel.state.isLoadingTeams]);
    React.useEffect(() => {
        var _a;
        if ((locationState === null || locationState === void 0 ? void 0 : locationState.resetDraftQueryParams) && transcriptQueryID === 'draft') {
            (_a = queryModelRef.current) === null || _a === void 0 ? void 0 : _a.parameters.clearPersistedTempParameters();
            history.replace(locationPathname);
            reset(transcriptQueryID).catch((err) => {
                transcriptQueryViewLogger.error(`Reset ${transcriptQueryID} failed with: `, err.message);
                // reset view model on error
                setViewModel(null);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState, transcriptQueryID]);
    async function reset(queryID) {
        const viewModel = new TranscriptQueryViewModel(searchViewModel.state, api.transcripts);
        setViewModel(viewModel);
        if (transcriptQueryID === 'draft') {
            const queryModel = new TranscriptQueryModel({
                id: transcriptQueryID,
                name: t('untitledSearch', 'Untitled Search'),
                parameters: {
                    timeZone: getTimeZone(),
                    termGroups: [],
                    dateRange: {
                        type: 'relative',
                        relativeRange: {
                            duration: 'P0Y0M0W30DT0H0M0S',
                            isCurrentPeriodIncluded: true,
                        },
                    },
                    userIDs: [],
                    teamIDs: [],
                    protocolNames: [],
                },
            });
            queryModelRef.current = queryModel;
            viewModel.state.setQuery(queryModel);
        }
        else {
            await viewModel.loadQuery(queryID);
        }
        const { query } = viewModel.state;
        if (!query)
            return;
        if (query.parameters.hasNoTerms)
            return;
        const dateRange = utils.toAbsoluteDateRange(query.parameters.dateRange);
        if (!dateRange)
            return;
        const combinedUsers = utils.combineUserIDsAndTeamMemberIDs(query.parameters.tempUserIDs, query.parameters.tempTeamIDs);
        await Promise.all([
            viewModel.loadTimeSeries({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
            viewModel.loadCalls({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
        ]);
    }
    if (!viewModel)
        return null;
    return (React.createElement(TranscriptQueryViewContext.Provider, { value: { viewModel } },
        React.createElement(Page, null,
            React.createElement(Container, null,
                React.createElement(Base, { display: "grid", gridTemplateRows: "auto", gap: 8, "data-cy": "transcript-query-view" },
                    React.createElement(Header, null),
                    React.createElement(ParametersPanel, null),
                    React.createElement(ChartsPanel, null),
                    React.createElement(CallsPanel, null))))));
});
