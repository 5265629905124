import CalendarIcon from '@mui/icons-material/Event';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { InputAdornment, TextField } from 'lib/cortiUI';
import { FormElementWidget } from './FormElementWidget';
export const DatePickerWidget = observer(function DatePickerWidget({ model, }) {
    const label = model.placeholder || model.isDateTime
        ? intl.t('', 'Select a date and time')
        : intl.t('', 'Select a date');
    return (React.createElement(FormElementWidget, { model: model },
        React.createElement(TextField, { label: label, disabled: true, fullWidth: true, value: " ", InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(CalendarIcon, { fontSize: "medium" }))),
            } })));
});
