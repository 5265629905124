import DeleteRounded from '@mui/icons-material/DeleteRounded';
import { sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton, TextField, Tooltip } from 'lib/cortiUI';
import { CY_SELECTORS, getTimelineEntryDefinitionsTitles } from '../../utils';
import { ConfirmableAction } from '../ConfirmableAction';
import { TimelineEntryDefinitionPicker } from '../TimelineEntryDefinitionPicker';
export const FieldOption = ({ valueID, textValue, label, selectedTimelineEntryDefinitionsForHints, timelineEntryDefinitions, onChoiceInputValueChange, onTimelineEntryDefsHintsValueChange, onRemoveChoice, }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    const [timelineEntryDefinitionsTitles, setTimelineEntryDefinitionsTitles] = React.useState([]);
    React.useEffect(() => {
        setTimelineEntryDefinitionsTitles(getTimelineEntryDefinitionsTitles(selectedTimelineEntryDefinitionsForHints, timelineEntryDefinitions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const sortedTimelineEntryDefinitions = sortBy(timelineEntryDefinitions, (it) => it.title);
    const optionHelperText = !textValue
        ? t('fieldWidget.requiredOptionTitle', 'Option title is required')
        : timelineEntryDefinitionsTitles.length
            ? `${t('annotations', 'Annotations')}: ${timelineEntryDefinitionsTitles.join(', ')}`
            : '';
    const handleSelectTimelineEntriesDefinitions = (sources) => {
        onTimelineEntryDefsHintsValueChange(valueID, sources);
        const titles = getTimelineEntryDefinitionsTitles(sources, timelineEntryDefinitions);
        setTimelineEntryDefinitionsTitles(titles);
    };
    return (React.createElement(Base, { width: 1, display: "flex", flexDirection: "column", "data-cy": CY_SELECTORS.fieldWidget.optionWrapper },
        React.createElement(Base, { display: "flex" },
            React.createElement(TextField, { required: true, error: !textValue, helperText: optionHelperText, FormHelperTextProps: {
                    'data-cy': CY_SELECTORS.fieldWidget.optionHelperText,
                    sx: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        mx: 0,
                    },
                }, label: label, value: textValue, sx: {
                    flex: 1,
                    mr: 3,
                    mb: 4,
                    overflow: 'hidden',
                }, onChange: ({ target }) => {
                    onChoiceInputValueChange({ value: valueID, text: target.value });
                }, inputProps: { 'data-cy': CY_SELECTORS.fieldWidget.optionField } }),
            React.createElement(Base, { display: "flex", mt: 3 },
                React.createElement(TimelineEntryDefinitionPicker, { timelineEntryDefinitions: sortedTimelineEntryDefinitions, selectedValues: selectedTimelineEntryDefinitionsForHints, onChange: handleSelectTimelineEntriesDefinitions, tooltipTitle: t('actionsHints.selectTimelineEntryDefinitionHints', 'Select timeline entry definitions for hints') }),
                React.createElement(ConfirmableAction, { condition: Boolean(selectedTimelineEntryDefinitionsForHints.length) || Boolean(textValue), message: t('actionsWarnings.delete', 'Are you sure you would like to delete this item?'), onConfirm: () => onRemoveChoice(valueID), renderClickableComponent: (onClick) => (React.createElement(Tooltip, { title: t('actionsHints.remove', 'Remove') },
                        React.createElement(IconButton, { sx: { ml: 3 }, onClick: onClick, size: "small", icon: React.createElement(DeleteRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.fieldWidget.removeOptionBtn }))) })))));
};
