var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseCanvasEntity } from '../../BaseCanvasEntity';
import { PortModel } from '../../PortModel';
import { Point } from '../../geometry';
export class BaseGraphEditorNode extends BaseCanvasEntity {
    constructor(input) {
        var _a;
        super();
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "position", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // these should not be persisted but are only added when a node is first rendered in a canvas
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_ports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.setName((_a = input.name) !== null && _a !== void 0 ? _a : '');
        this.type = input.type;
        runInAction(() => {
            this.position = new Point({ x: 0, y: 0 });
            this._ports = {};
        });
        if (input.hasInputPort) {
            this.addPortIn();
        }
    }
    setName(name) {
        this.name = name;
    }
    get branchContext() {
        return this.parent;
    }
    get portIn() {
        return this.ports.filter((p) => p.type === 'in')[0];
    }
    setPorts(ports) {
        this._ports = {};
        ports.forEach((p) => this.addPort(p));
    }
    addPort(port) {
        port.setParent(this);
        this._ports[port.id] = port;
    }
    removePort(port) {
        if (this._ports[port.id]) {
            this._ports[port.id].dispose();
            delete this._ports[port.id];
        }
    }
    addPortIn() {
        if (this.portIn) {
            console.warn(`Node is only allowed to contain 1 port of type "in"`);
            return this.portIn;
        }
        const port = new PortModel({ type: 'in', position: { x: 0, y: 0 } });
        this.addPort(port);
        return port;
    }
    addPortOut(input = {}) {
        const port = new PortModel(Object.assign(Object.assign({ position: { x: 0, y: 0 } }, input), { type: 'out' }));
        this.addPort(port);
        return port;
    }
    moveNodeTo(x, y) {
        this.setPosition(x, y);
    }
    moveNodeBy(dx, dy) {
        const newPos = new Point({ x: this.position.x + dx, y: this.position.y + dy });
        this.moveNodeTo(newPos.x, newPos.y);
    }
    setPosition(x, y) {
        if (this.position.x === x && this.position.y === y) {
            return;
        }
        this.position = new Point({ x, y });
    }
    get ports() {
        return Object.values(this._ports);
    }
    get outputPorts() {
        return this.ports.filter((p) => p.type === 'out');
    }
    get inputPorts() {
        return this.ports.filter((p) => p.type === 'in');
    }
    get outputLinks() {
        return this.outputPorts.flatMap((p) => p.links);
    }
    get inputLinks() {
        return this.inputPorts.flatMap((p) => p.links);
    }
    get links() {
        return this.ports.flatMap((p) => p.links);
    }
    get outputTargets() {
        return this.outputPorts
            .flatMap((p) => p.links.flatMap((l) => { var _a; return (_a = l.inPort) === null || _a === void 0 ? void 0 : _a.parent; }))
            .filter((it) => it != null);
    }
    get inputTargets() {
        return this.inputPorts
            .flatMap((p) => p.links.flatMap((l) => { var _a; return (_a = l.outPort) === null || _a === void 0 ? void 0 : _a.parent; }))
            .filter((it) => it != null);
    }
    get readableTitle() {
        if (this.name.trim() !== '') {
            return this.name;
        }
        return this.type;
    }
    remove() {
        if (this.parent) {
            this.parent.removeNode(this);
        }
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { name: this.name, type: this.type, position: this.position.serialize(), ports: this.ports.map((port) => port.getEncodableData()), width: this.width, height: this.height });
    }
    deserialize(entity, ctx) {
        super.deserialize(entity, ctx);
        this.setPosition(entity.position.x, entity.position.y);
        this._ports = {};
        this.name = entity.name;
        entity.ports.forEach((p) => {
            const port = new PortModel({ type: p.type });
            port.deserialize(p);
            this.addPort(port);
        });
        this.width = entity.width;
        this.height = entity.height;
    }
    dispose() {
        this.links.forEach((l) => l.remove());
    }
    copy() {
        const i = super.copy();
        i.position = this.position.copy();
        i.setName(this.name);
        this.setPorts(this.ports.map((p) => p.copy()));
        return i;
    }
    merge(node) {
        node.ports.forEach((p) => this.addPort(p));
        this.setPosition(node.position.x, node.position.y);
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], BaseGraphEditorNode.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Point !== "undefined" && Point) === "function" ? _a : Object)
], BaseGraphEditorNode.prototype, "position", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Record !== "undefined" && Record) === "function" ? _b : Object)
], BaseGraphEditorNode.prototype, "_ports", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "setName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "setPorts", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof PortModel !== "undefined" && PortModel) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "addPort", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof PortModel !== "undefined" && PortModel) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "removePort", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Number]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "moveNodeTo", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Number]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "moveNodeBy", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Number]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "setPosition", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "ports", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "outputPorts", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "inputPorts", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "outputLinks", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "inputLinks", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "links", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "outputTargets", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], BaseGraphEditorNode.prototype, "inputTargets", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _e : Object, Object]),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "deserialize", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "dispose", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseGraphEditorNode.prototype, "copy", null);
