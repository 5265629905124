import { DragIndicator } from '@mui/icons-material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import React, { useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Box, Button, IconButton, SortableContainer, SortableElement } from 'lib/cortiUI';
import { useGraphEditorCtx } from '../../core/view';
import { ExpressionInputConnected, ExpressionInputStyledWrapper } from '../../expressions';
import { AttributeList } from '../../ui';
export function NodeLogicGates({ node, gates }) {
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const handleDragEnd = (source, destination) => editor.dispatch({
        type: 'node.changeLogicGatePosition',
        data: { logicGateID: gates[source].id, newPosIdx: destination },
    });
    return (React.createElement(AttributeList, { headerTitle: t('viewNodeInfoPanel.logicGatesSectionTitle', 'Logic Gates') },
        React.createElement(SortableContainer, { handleDragEnd: handleDragEnd }, ({ containerProps, placeholder }) => (React.createElement(Base, Object.assign({}, containerProps),
            gates.map((gate, idx) => (React.createElement(SortableElement, { key: gate.id, index: idx }, ({ handleProps, itemProps }) => (React.createElement(Box, Object.assign({ "data-cy": "logic-gate-input-container", flexDirection: "row", alignItems: "center", p: 1 }, itemProps),
                React.createElement(Box, { mr: 1, flexShrink: 0 },
                    React.createElement(Base, Object.assign({ display: "flex" }, handleProps),
                        React.createElement(DragIndicator, { fontSize: "small" }))),
                React.createElement(LogicGateExpressionInput, { key: gate.id, gate: gate }),
                React.createElement(IconButton, { className: css({ flexShrink: 0 }), icon: React.createElement(HighlightOffRoundedIcon, { fontSize: "small" }), onClick: () => {
                        editor.dispatch({
                            type: 'node.removeLogicGate',
                            data: { logicGateID: gate.id },
                        });
                    } })))))),
            placeholder))),
        React.createElement(Button, { "data-cy": "add-logic-expression-button", color: "primary", onClick: () => {
                editor.dispatch({ type: 'node.addLogicGate', data: { nodeID: node.id } });
            } }, t('viewNodeInfoPanel.addLogicGateBtn', 'Add Logic Expression'))));
}
function LogicGateExpressionInput({ gate }) {
    const [validationResult, setValidationResult] = useState();
    return (React.createElement(ExpressionInputStyledWrapper, { validationResult: validationResult },
        React.createElement(ExpressionInputConnected, { validate: true, includeBlockSuggestions: true, includeFactSuggestions: true, value: gate.expression, onChange: (val) => gate.setExpression(val), context: { node: gate.parent }, onValidate: (result) => {
                setValidationResult(result);
            } })));
}
