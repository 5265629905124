import { useQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Button } from 'lib/cortiUI';
import { getUsersQuery, } from '../../graphql';
import { AddMembersDialog } from './AddMembersDialog';
export const AddMembers = ({ team }) => {
    const theme = useTheme();
    const { t } = useTranslation('teams');
    const auth = useAuth();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const { data: usersData } = useQuery(getUsersQuery, {
        variables: { orgID: auth.organization.id },
    });
    const users = React.useMemo(() => { var _a; return (_a = usersData === null || usersData === void 0 ? void 0 : usersData.users) !== null && _a !== void 0 ? _a : []; }, [usersData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { sx: { color: theme.palette.text.primary }, "data-cy": "add-team-member-btn", disabled: !rbacService.teamsCRUD(), variant: "text", onClick: () => {
                setIsDialogOpen(true);
            }, startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, t('teamView.addMemberBtn', 'Add Call-Taker')),
        isDialogOpen && (React.createElement(AddMembersDialog, { teamID: team.id, members: team.members, onClose: () => setIsDialogOpen(false), users: users }))));
};
