import { observer } from 'mobx-react';
import React from 'react';
import { css } from '@corti/style';
import { authStore } from 'core/auth';
import { moduleRegistry } from 'core/moduleRegistry/browser';
import { Base } from 'lib/cortiUI';
import { GlobalNavigation } from './GlobalNavigation';
import { RealTimeAlerts } from './RealTimeAlerts';
import { AppRoutes } from './routes/AppRoutes';
import { useDefaultModule } from './useDefaultModule';
import { useFocusMode } from './useFocusMode';
import { useNavItems } from './useNavItems';
import { usePhoneExtension } from './usePhoneExtension';
import { useRealtimeConnection } from './useRealtimeConnection';
export const AppShell = observer(function AppShell() {
    const modules = moduleRegistry.modules;
    const defaultModule = useDefaultModule();
    useRealtimeConnection();
    usePhoneExtension();
    const isFocusMode = useFocusMode();
    const navItems = useNavItems(modules);
    return (React.createElement(React.Fragment, null,
        React.createElement(RealTimeAlerts, null),
        React.createElement(GlobalNavigation, { "data-cy": "app-shell", isFocusMode: isFocusMode, currentUser: authStore.currentUser, navItems: navItems },
            React.createElement(Base, { "data-cy": "app-shell-container", className: css({
                    gridArea: 'appView',
                    display: 'grid',
                    overflow: 'hidden',
                    position: 'relative',
                }) },
                React.createElement(AppRoutes, { defaultModule: defaultModule, modules: modules })))));
});
