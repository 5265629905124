import { useCombobox, useMultipleSelection } from 'downshift';
import React from 'react';
import { useMousetrap } from '@corti/react';
import { isOptionDisabled } from '../utils';
import { DropdownContainer, DropdownInput, DropdownMenu, DropdownMenuFallback, DropdownMenuOption, DropdownMultiValue, DropdownToggleButton, } from './components';
export function MultiDropdown(props) {
    var _a;
    const inputRef = React.useRef(null);
    const toggleRef = React.useRef(null);
    const [filteredOptions, setFilteredOptions] = React.useState(props.options);
    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
        selectedItems: props.selectedOptions,
        onStateChange({ selectedItems: newSelectedOptions, type }) {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    if (newSelectedOptions) {
                        const removedOption = props.selectedOptions.filter((o) => !newSelectedOptions.includes(o))[0];
                        if (removedOption)
                            props.onOptionSelect(removedOption);
                    }
                    break;
                default:
                    break;
            }
        },
    });
    const d = useCombobox({
        items: filteredOptions,
        selectedItem: null,
        defaultHighlightedIndex: undefined,
        itemToString: (it) => { var _a; return (_a = it === null || it === void 0 ? void 0 : it.plainText) !== null && _a !== void 0 ? _a : ''; },
        onHighlightedIndexChange: (changes) => {
            var _a, _b;
            if (changes.highlightedIndex == null) {
                return;
            }
            const deemphasized = filteredOptions[d.highlightedIndex];
            if (deemphasized) {
                (_a = props.onOptionDeemphasize) === null || _a === void 0 ? void 0 : _a.call(props, deemphasized);
            }
            const emphasized = filteredOptions[changes.highlightedIndex];
            if (emphasized) {
                (_b = props.onOptionEmphasize) === null || _b === void 0 ? void 0 : _b.call(props, emphasized);
            }
        },
        onStateChange: (state) => {
            var _a;
            switch (state.type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur: {
                    setFilteredOptions(props.options);
                    if (state.selectedItem != null) {
                        props.onOptionSelect(state.selectedItem);
                    }
                    break;
                }
                case useCombobox.stateChangeTypes.InputChange: {
                    setFilteredOptions(props.filterOptions((_a = state.inputValue) !== null && _a !== void 0 ? _a : '', props.options));
                    break;
                }
                default: {
                    break;
                }
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick: {
                    return Object.assign(Object.assign({}, changes), { isOpen: true, highlightedIndex: state.highlightedIndex, inputValue: '' });
                }
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                case useCombobox.stateChangeTypes.InputBlur: {
                    return Object.assign(Object.assign({}, changes), { selectedItem: null, inputValue: '' });
                }
                case useCombobox.stateChangeTypes.ToggleButtonClick: {
                    return Object.assign(Object.assign({}, changes), { highlightedIndex: state.highlightedIndex });
                }
                default: {
                    return changes;
                }
            }
        },
    });
    useMousetrap({
        keys: 'esc',
        handle: (e) => {
            var _a;
            if (d.isOpen === false) {
                (_a = props.onEscapeDown) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }
        },
        element: (_a = inputRef.current) !== null && _a !== void 0 ? _a : undefined,
    }, [inputRef.current, d]);
    return (React.createElement(DropdownContainer, { onClearButtonClick: (e) => {
            var _a, _b;
            e.stopPropagation();
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = props.onClearButtonClick) === null || _b === void 0 ? void 0 : _b.call(props);
        }, hasValue: props.selectedOptions.length > 0, downshiftProps: Object.assign({}, d.getComboboxProps()), isOpen: d.isOpen },
        React.createElement(DropdownToggleButton, { downshiftProps: Object.assign({}, d.getToggleButtonProps({
                onFocus: props.onFocus,
                onBlur: props.onBlur,
                onKeyDown: (e) => {
                    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                        e.stopPropagation();
                    }
                },
                ref: (el) => {
                    var _a;
                    (_a = props.menuToggleRef) === null || _a === void 0 ? void 0 : _a.call(props, el);
                    toggleRef.current = el;
                },
                onClick: () => {
                    var _a;
                    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                },
            })) },
            props.selectedOptions.map((it, i) => {
                return (React.createElement(DropdownMultiValue, Object.assign({}, getSelectedItemProps({
                    onKeyDown: (e) => {
                        var _a;
                        if (e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight') {
                            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                    },
                    selectedItem: it,
                    index: i,
                }), { key: it.id, id: it.id, label: it.plainText, onDelete: (e) => {
                        var _a;
                        if (e.type === 'click') {
                            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                            removeSelectedItem(it);
                        }
                    } })));
            }),
            React.createElement(DropdownInput, { downshiftProps: Object.assign({}, d.getInputProps(getDropdownProps({
                    ref: inputRef,
                }))) })),
        React.createElement(DropdownMenu, { isOpen: d.isOpen, anchorEl: toggleRef.current, downshiftProps: Object.assign({}, d.getMenuProps({}, { suppressRefError: true })) }, filteredOptions.length === 0 ? (React.createElement(DropdownMenuFallback, null)) : (filteredOptions.map((it, idx) => {
            const isSelected = Boolean(props.selectedOptions.find((option) => option.id === it.id));
            const isHighlighted = d.highlightedIndex === idx;
            const isDisabled = isOptionDisabled(it, props.selectedOptions, props.limit);
            return (React.createElement(DropdownMenuOption, { key: it.id, option: it, isHighlighted: isHighlighted, isSelected: isSelected, isDisabled: isDisabled, downshiftProps: Object.assign({}, d.getItemProps({
                    item: it,
                    index: idx,
                    'aria-selected': isSelected,
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                })) }));
        })))));
}
