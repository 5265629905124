var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI';
export const Input = React.forwardRef(function FilterInput(props, ref) {
    const { requiredInputProps, className } = props, rest = __rest(props, ["requiredInputProps", "className"]);
    const theme = useTheme();
    const baseCss = useBaseCss(props);
    const filteredProps = filterBaseProps(rest);
    return (React.createElement("input", Object.assign({ ref: ref }, requiredInputProps, filteredProps, { className: css({
            border: 'none',
            outline: 'none',
            height: '100%',
            width: '100%',
            fontSize: theme.typography.fontSize,
            padding: 0,
            cursor: 'text',
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            '&::placeholder': {
                color: theme.palette.text.hint,
            },
        }, baseCss, className) })));
});
