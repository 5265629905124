import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Modal } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { useParametersContext } from '../../context/ParametersContext';
import { ViewSettingsModalContent } from './ViewSettingsModalContent/ViewSettingsModalContent';
export const SaveButton = () => {
    const { t } = useTranslation('performanceApp');
    const { isDraft } = useGlobalContext();
    const { title, visibility, locked, appliedFilters: { trackers }, updatePerformanceView, isCreateOrUpdateViewLoading, isChanged, createPerformanceView, } = useParametersContext();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleTriggerButtonClick = () => {
        if (isDraft) {
            setIsModalOpen(true);
        }
        else {
            updatePerformanceView();
        }
    };
    const handleSaveNewView = (view) => {
        createPerformanceView(view.title, view.visibility, view.locked);
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-cy": "performance-save-draft-view-btn", variant: "contained", color: "primary", size: "small", disabled: isCreateOrUpdateViewLoading || !trackers.length || !isChanged, onClick: handleTriggerButtonClick }, isDraft ? t('saveView', 'Save view') : t('saveEdits', 'Save Edits')),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false), size: "small" },
            React.createElement(ViewSettingsModalContent, { isDraft: isDraft, view: { title, visibility, locked }, onSave: handleSaveNewView, isAdvancedSettingsEnabled: true }))));
};
