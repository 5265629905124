import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, MenuItem, MultipleSelect, Typography } from 'lib/cortiUI';
import { useParameters, useTemplate } from '../../context';
import { getReviewComplianceStatusData } from '../../utils';
import { DateRangePicker } from './components/DateRangePicker';
import { getFiltersQuery } from './graphql';
export const Parameters = () => {
    var _a;
    const hasPermissionToReadAllTeamsAndAgents = rbacService.hasPermission('cases:other_users:read') &&
        rbacService.missionControlOtherAssessmentsRead();
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'parameters' });
    const { organization } = useAuth();
    const { dateRange, agents, teams, complianceStatus, acknowledgementStatus, updateParameters } = useParameters();
    const { template } = useTemplate();
    const { data, loading } = useQuery(getFiltersQuery, {
        variables: { organizationID: organization.id },
    });
    const { teams: teamsOptions, users: agentsOptions } = (_a = data === null || data === void 0 ? void 0 : data.performanceMetricsFilter) !== null && _a !== void 0 ? _a : {
        teams: [],
        users: [],
    };
    const agentsOptionsFiltered = teams.length
        ? teams.flatMap((team) => team.members.map((m) => m.user))
        : agentsOptions;
    const handleTeamsChange = (_e, value) => {
        updateParameters({
            teams: value,
            agents: [],
        });
    };
    const handleAgentsChange = (_e, value) => {
        updateParameters({ agents: value });
    };
    const getComplianceStatusOptions = () => {
        if (!(template === null || template === void 0 ? void 0 : template.has_threshold)) {
            return [];
        }
        const options = ['COMPLIANT'];
        if (template === null || template === void 0 ? void 0 : template.has_partial_threshold) {
            options.push('PARTIALLY_COMPLIANT');
        }
        options.push('NON_COMPLIANT', 'AUTO_FAIL');
        return options;
    };
    const getAcknowledgementStatusValues = () => {
        return ['NO_AGENT_INPUT', 'REVIEW_REQUESTED', 'ACKNOWLEDGED', 'ACKNOWLEDGEMENT_REQUESTED'];
    };
    const getStatusLabel = (status) => {
        switch (status) {
            case 'NO_AGENT_INPUT':
                return t('ackStatusNoAgentInput', 'No Agent Input');
            case 'REVIEW_REQUESTED':
                return t('ackStatusReviewRequested', 'Review Requested');
            case 'ACKNOWLEDGED':
                return t('ackStatusAcknowledged', 'Acknowledged');
            case 'ACKNOWLEDGEMENT_REQUESTED':
                return t('ackStatusAcknowledgementRequested', 'Acknowledgement Requested');
            default:
                return '';
        }
    };
    return (React.createElement(Base, { width: "100%", display: "flex", alignItems: "center", gap: 5 },
        React.createElement(Base, { display: "flex", flex: 1, gap: 5 },
            React.createElement(MultipleSelect, { options: teamsOptions, value: teams, inputLabel: t('teamsInputLabel', 'Teams'), getOptionLabel: (option) => option.name, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: handleTeamsChange, disabled: !hasPermissionToReadAllTeamsAndAgents || loading }),
            React.createElement(MultipleSelect, { options: agentsOptionsFiltered, value: agents, inputLabel: t('agentsInputLabel', 'Agents'), getOptionLabel: (option) => option.name, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: handleAgentsChange, disabled: !hasPermissionToReadAllTeamsAndAgents || loading }),
            React.createElement(MultipleSelect, { options: getAcknowledgementStatusValues(), value: acknowledgementStatus, inputLabel: t('ackStatus', 'Acknowledgement Status'), getOptionLabel: (option) => getStatusLabel(option), isOptionEqualToValue: (option, value) => option === value, onChange: (_e, value) => updateParameters({ acknowledgementStatus: value }) }),
            React.createElement(MultipleSelect, { options: getComplianceStatusOptions(), value: complianceStatus, inputLabel: t('scoreStatusInputLabel', 'Score Status'), getOptionLabel: (option) => getReviewComplianceStatusData(option).label, isOptionEqualToValue: isEqual, onChange: (_e, value) => updateParameters({ complianceStatus: value }), renderOption: (props, value, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected }),
                    React.createElement(Typography, { variant: "body2", noWrap: true, color: getReviewComplianceStatusData(value).color }, getReviewComplianceStatusData(value).label))) })),
        React.createElement(Base, { display: "flex", justifyContent: "flex-end", alignItems: "center" },
            React.createElement(DateRangePicker, { value: dateRange, onChange: (value) => updateParameters({ dateRange: value }), readOnly: false }))));
};
