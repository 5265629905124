import EditRoundedIcon from '@mui/icons-material/EditRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button } from 'lib/cortiUI';
export const OpenModalAction = ({ mode, disabled, hide, onClick, }) => {
    const { t } = useTranslation('template', {
        keyPrefix: 'builder.configuration.thresholds.actions',
    });
    return (React.createElement(Button, { variant: "outlined", color: mode === 'set' ? 'primary' : undefined, size: "small", disabled: disabled, onClick: onClick, sx: { visibility: hide ? 'hidden' : 'visible' }, startIcon: mode === 'edit' ? React.createElement(EditRoundedIcon, { fontSize: "small" }) : null }, mode === 'set'
        ? t('setComplianceCriteriaBtn', 'Set compliance criteria')
        : t('complianceCriteriaBtn', 'Compliance criteria')));
};
