var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { DialogContentText } from '@mui/material';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { config } from 'core/configuration/browser';
import { Box, Button, Text, TextField } from 'lib/cortiUI';
// @ts-ignore
let PasswordResetVerifyForm = class PasswordResetVerifyForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                verificationCode: '',
                errorMesssage: '',
            }
        });
        Object.defineProperty(this, "handleInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ verificationCode: e.target.value });
            }
        });
        Object.defineProperty(this, "isFormValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.state.verificationCode.length == 8;
            }
        });
        Object.defineProperty(this, "handleFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.preventDefault();
                this.setState({ errorMesssage: '' });
                this.postVerificationCode(this.props.email, this.state.verificationCode)
                    .then(async (res) => {
                    if (res.ok) {
                        const jsonResponse = await res.json();
                        this.props.onSuccess(jsonResponse.userID, jsonResponse.token);
                    }
                    else {
                        this.setState({
                            errorMesssage: intl.t('team:passwordReset.invalidResetCodeErrorMsg', 'Password reset verification code is invalid or expired'),
                        });
                    }
                })
                    .catch(() => {
                    this.setState({ errorMesssage: intl.t('remoteServerErrorMsg', 'Something went wrong') });
                });
            }
        });
    }
    postVerificationCode(email, resetCode) {
        const url = `${config.getConfig().apiHost}/api/v1.0/users/password/reset/email/${email}/reset-code/${resetCode}`;
        return fetch(url, {
            method: 'post',
        });
    }
    render() {
        var _a;
        const { verificationCode, errorMesssage } = this.state;
        return (React.createElement(Box, { "data-cy": "password-reset-form" },
            React.createElement("form", { onSubmit: this.handleFormSubmit, className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: (_a = this.props.theme) === null || _a === void 0 ? void 0 : _a.space[6],
                }) },
                React.createElement(DialogContentText, { color: "secondaryText" }, intl.t('team:passwordReset.enterVerificationCodeNotice', 'Please enter verification code that you have received in e-mail')),
                React.createElement(TextField, { fullWidth: true, label: intl.t('team:passwordReset.verificationCode', 'Verification code'), value: verificationCode, onChange: this.handleInputChange, inputProps: {
                        'data-cy': 'password-reset-verification-code-input',
                    } }),
                React.createElement(Button, { type: "submit", variant: "text", "data-cy": "password-reset-btn", color: "primary", disabled: !this.isFormValid(), className: css({ alignSelf: 'flex-end' }) }, intl.t('confirm', 'Confirm'))),
            errorMesssage && (React.createElement(Text, { textAlign: "center", size: "small", color: "error" }, errorMesssage))));
    }
};
PasswordResetVerifyForm = __decorate([
    withTheme
], PasswordResetVerifyForm);
export { PasswordResetVerifyForm };
