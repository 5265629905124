var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, Chip, MenuItem, TextField, Typography } from 'lib/cortiUI';
import { useTemplate } from '../../context';
export const AnswerTypeInput = (_a) => {
    var { onSaveValue } = _a, props = __rest(_a, ["onSaveValue"]);
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions' });
    const { isScoringEnabled } = useTemplate();
    const { field: { onChange, onBlur, value, ref }, } = useController(Object.assign(Object.assign({}, props), { name: 'answer_type' }));
    const answerTypeOptions = [
        'SINGLE_LINE',
        'MULTI_LINE',
        'DROPDOWN',
        'MULTIPLE_CHOICE',
    ];
    function getOptionLabel(value) {
        switch (value) {
            case 'SINGLE_LINE':
                return t('answerType.singleLine', 'Single line answer');
            case 'MULTI_LINE':
                return t('answerType.multiLine', 'Multi line answer');
            case 'DROPDOWN':
                return t('answerType.dropdown', 'Dropdown');
            case 'MULTIPLE_CHOICE':
                return t('answerType.multipleChoice', 'Multiple choice');
            default:
                return '';
        }
    }
    return (React.createElement(Autocomplete, { disabled: true, size: "small", options: answerTypeOptions, getOptionLabel: (option) => getOptionLabel(option), isOptionEqualToValue: (option, value) => option === value, value: value, onChange: (_, option) => {
            if (option) {
                onChange(option);
            }
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({ name: "answerType" }, params, { label: t('fieldWidget.fieldType', 'Answer Type'), inputProps: Object.assign({}, params.inputProps), inputRef: ref }))), onBlur: () => {
            onBlur();
            onSaveValue();
        }, clearIcon: null, sx: { flex: 1 }, renderOption: (props, option) => (React.createElement(MenuItem, Object.assign({}, props),
            React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
                React.createElement(Typography, null, getOptionLabel(option)),
                isScoringEnabled && (option === 'MULTIPLE_CHOICE' || option === 'DROPDOWN') && (React.createElement(Chip, { label: t('scoringChip', 'Scoring Available') }))))) }));
};
