var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { Observer } from '@corti/observer';
import { authStore } from 'core/auth';
import { getCommon } from './commonColumnsDictionary';
export class CasesTableEditorState {
    constructor() {
        Object.defineProperty(this, "APP_DEFAULT_TABLE_COLUMNS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                { id: 'caseID' },
                { id: 'caseCreatedBy' },
                { id: 'caseStartedAt' },
                { id: 'caseDuration' },
            ]
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "allColumnsDictionary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadContent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                await this.loadAllColumnsDictionary();
                this.loadTableColumns();
                this.observer.fireEvent('tableColumnsLoaded');
            }
        });
        Object.defineProperty(this, "loadAllColumnsDictionary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const orgID = api.auth.user.orgID;
                const customPropertiesKeys = await api.cases.getCustomPropertyKeys(orgID);
                const customPropertiesColumnsDictionary = {};
                customPropertiesKeys.forEach((key) => {
                    customPropertiesColumnsDictionary[key] = {
                        id: key,
                        entityType: 'customProperty',
                        label: key,
                    };
                });
                this.allColumnsDictionary = Object.assign(Object.assign({}, getCommon()), customPropertiesColumnsDictionary);
            }
        });
        Object.defineProperty(this, "loadTableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const columns = this.userTableColumns || this.orgTableColumns || this.APP_DEFAULT_TABLE_COLUMNS;
                this.tableColumns = columns.filter((c) => { var _a; return ((_a = this.allColumnsDictionary[c.id]) === null || _a === void 0 ? void 0 : _a.id) === c.id; });
            }
        });
        Object.defineProperty(this, "updateUserTableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (columns) => {
                await authStore.updateUserProfile({
                    settings: {
                        casesTableColumns: columns,
                    },
                });
            }
        });
        Object.defineProperty(this, "updateOrgTableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (columns) => {
                await authStore.updateOrganizationClientSettings({
                    casesTableColumns: columns,
                });
            }
        });
        Object.defineProperty(this, "updateTableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (columns) => {
                runInAction(() => {
                    this.tableColumns = columns;
                });
                await this.updateUserTableColumns(columns);
            }
        });
        Object.defineProperty(this, "updateDefaultTableColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (columns) => {
                await this.updateOrgTableColumns(columns);
            }
        });
        Object.defineProperty(this, "resetTableColumnsToDefault", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                await this.updateTableColumns(this.orgTableColumns || this.APP_DEFAULT_TABLE_COLUMNS);
            }
        });
        Object.defineProperty(this, "getColumnDescriptorById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                return this.allColumnsDictionary[id];
            }
        });
        makeObservable(this);
        this.allColumnsDictionary = {};
        runInAction(() => {
            this.tableColumns = [];
        });
        void this.loadContent();
    }
    get userTableColumns() {
        if (authStore.isLoaded) {
            return authStore.currentUser.settings.casesTableColumns;
        }
    }
    get orgTableColumns() {
        if (authStore.isLoaded) {
            return authStore.organization.clientSettings.casesTableColumns;
        }
    }
    onTableColumnsLoaded(cb) {
        return this.observer.on('tableColumnsLoaded', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], CasesTableEditorState.prototype, "tableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "loadContent", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "loadTableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "updateUserTableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "updateOrgTableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "updateTableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "updateDefaultTableColumns", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesTableEditorState.prototype, "resetTableColumnsToDefault", void 0);
