import { observer } from 'mobx-react';
import React from 'react';
import { uuid } from '@corti/uuid';
import { useFocusContext } from './FocusContext';
export const FocusElement = observer(function FocusElement(props) {
    const { defaultElementID, defaultGroupID, children, isFocusable = true, autofocus, position, } = props;
    const { registry, controller } = useFocusContext();
    const id = React.useRef(defaultElementID !== null && defaultElementID !== void 0 ? defaultElementID : uuid()).current;
    const groupID = React.useRef(defaultGroupID !== null && defaultGroupID !== void 0 ? defaultGroupID : uuid()).current;
    const [elementRef, setElementRef] = React.useState(null);
    const [hasAutofocused, setHasAutofocused] = React.useState(false);
    const isFocusedNatively = document.activeElement === elementRef;
    React.useEffect(() => {
        if (!elementRef)
            return;
        function handleFocusNatively() {
            if (!isFocused()) {
                controller.focusElementByID(id);
            }
        }
        function handleBlurNatively() {
            if (isFocused()) {
                controller.blur();
            }
        }
        const unsubscriber = controller.observer.on('focused-element-triggered', (data) => {
            if (elementRef && data.id === id) {
                elementRef.click();
            }
        });
        elementRef.addEventListener('focus', handleFocusNatively);
        elementRef.addEventListener('blur', handleBlurNatively);
        return () => {
            unsubscriber();
            elementRef.removeEventListener('focus', handleFocusNatively);
            elementRef.removeEventListener('blur', handleBlurNatively);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef]);
    React.useEffect(() => {
        registry.registerElement({
            id: id,
            groupID: groupID,
            isFocusable: isFocusable,
        }, position);
        return () => {
            registry.unregisterElement(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);
    React.useEffect(() => {
        registry.updateElement(id, { isFocusable });
        if (elementRef) {
            elementRef.tabIndex = isFocusable ? 0 : -1;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusable, elementRef]);
    React.useEffect(() => {
        if (!isFocusable && isFocused()) {
            controller.blur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusable, elementRef]);
    React.useEffect(() => {
        if (autofocus && isFocusable && elementRef && !hasAutofocused) {
            setHasAutofocused(true);
            elementRef.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusable, elementRef, hasAutofocused]);
    React.useEffect(() => {
        var _a;
        const isFocused = ((_a = controller.focusedElement) === null || _a === void 0 ? void 0 : _a.id) === id;
        if (isFocused && !isFocusedNatively) {
            elementRef === null || elementRef === void 0 ? void 0 : elementRef.focus();
            return;
        }
        if (!isFocused && isFocusedNatively) {
            elementRef === null || elementRef === void 0 ? void 0 : elementRef.blur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.focusedElement, elementRef]);
    function isFocused() {
        var _a;
        return ((_a = controller.focusedElement) === null || _a === void 0 ? void 0 : _a.id) === id;
    }
    function registerElementRef(ref) {
        setElementRef(ref);
    }
    return React.createElement(React.Fragment, null, children({ registerElementRef }));
});
