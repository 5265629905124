var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box';
import { DefaultMultiValueController } from './DefaultMultiValueController';
import { DefaultValueRenderer } from './DefaultValueRenderer';
export function DefaultCellRenderer(props) {
    const { requiredProps, valueRenderer, multiValueRenderer, className } = props, rest = __rest(props, ["requiredProps", "valueRenderer", "multiValueRenderer", "className"]);
    const { cellData } = requiredProps;
    const getValueRenderer = () => {
        if (Array.isArray(cellData)) {
            return React.createElement(DefaultMultiValueController, { value: cellData, valueRenderer: multiValueRenderer });
        }
        if (valueRenderer) {
            return valueRenderer(cellData);
        }
        else {
            return cellData == null ? null : React.createElement(DefaultValueRenderer, { value: cellData });
        }
    };
    return (React.createElement(Box, Object.assign({ flexDirection: "row", alignItems: "center", className: css({
            height: '100%',
        }, className) }, rest), getValueRenderer()));
}
