import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor';
import { CustomPropertiesRenderer } from 'lib/graphEditor/common/CustomPropertiesRenderer';
import { ExpressionInputConnected, ExpressionInputStyledWrapper, } from 'lib/graphEditor/expressions';
import { AttributeDescriptorRenderer, AttributeRendererBoolean, AttributeRendererCopyableString, AttributeRendererStatic, CustomAttribute, GenericPanel, GenericPanelContent, GenericPanelHeader, } from 'lib/graphEditor/ui';
import { isValuePublisher } from 'lib/graphEditor/valuePublisher';
import { ValuePublisherConfig } from 'lib/graphEditor/valuePublisher/view';
import { BaseInstance } from '../BaseInstance';
import { ActionElementModel, DatePickerElementModel, FlowValueCollectorElementModel, SelectElementModel, } from '../elements';
export const ElementInfoPanel = (props) => {
    const { element } = props;
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(GenericPanel, { key: element === null || element === void 0 ? void 0 : element.id },
        React.createElement(GenericPanelHeader, { header: t('contentBuilder.elementInfo.selectedElementSectionTitle', 'Selected Element') }),
        React.createElement(GenericPanelContent, { p: 4, className: css({ minWidth: 120, width: '100%' }) }, element && React.createElement(ElementContextualPanel, { element: element }))));
};
const ElementContextualPanel = observer((props) => {
    const { element } = props;
    if (element instanceof BaseInstance) {
        return React.createElement(BaseInstancePanel, { instance: element });
    }
    return React.createElement(OtherElementPanel, { element: element });
});
const BaseInstancePanel = observer(function BaseInstancePanel({ instance, }) {
    const blockProto = instance.wrappedElement;
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(Base, { key: instance.id, display: "grid", gridGap: 6 },
        React.createElement(Base, null,
            React.createElement(Separator, { title: t('contentBuilder.elementInfo.instanceSectionTitle', 'Instance') }),
            React.createElement(Base, { gridGap: 5 },
                React.createElement(BasicInfoRenderer, { element: instance }),
                React.createElement(AttributesRenderer, { element: instance }),
                React.createElement(CustomPropertiesRenderer, { customProperties: instance.customProperties }),
                blockProto instanceof ActionElementModel && (React.createElement(Base, null,
                    React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.triggerOnMount', 'Trigger on mount'), value: instance.triggerOnMount, onValueChange: (v) => {
                            instance.setTriggerOnMount(v);
                        } }))))),
        React.createElement(Base, { "data-cy": "prototype-attributes-section" },
            React.createElement(Separator, { title: t('contentBuilder.elementInfo.prototype', 'Prototype') }),
            React.createElement(BlockPrototypeRenderer, { blockProto: blockProto }))));
});
const OtherElementPanel = observer(function OtherElementPanel({ element, }) {
    return (React.createElement(Base, null,
        React.createElement(BasicInfoRenderer, { element: element }),
        React.createElement(AttributesRenderer, { element: element }),
        element.customProperties && (React.createElement(CustomPropertiesRenderer, { customProperties: element.customProperties })),
        isValuePublisher(element) && (React.createElement(ValuePublisherConfig, { valuePublisher: element.valuePublisher }))));
});
const BlockPrototypeRenderer = observer(function BlockPrototypeRenderer({ blockProto, }) {
    const { t } = useTranslation('libGraphEditor');
    const [validationResult, setValidationResult] = React.useState();
    return (React.createElement(Base, { display: "grid", gridGap: 5 },
        React.createElement(AttributeRendererCopyableString, { name: "ID", value: blockProto.id, getClipboardValue: () => blockProto.id }),
        React.createElement(AttributeRendererStatic, { name: t('contentBuilder.elementInfo.prototypeTypeAttr', 'Type'), value: blockProto.type }),
        React.createElement(AttributesRenderer, { element: blockProto }),
        isValuePublisher(blockProto) && (React.createElement(ValuePublisherConfig, { valuePublisher: blockProto.valuePublisher })),
        blockProto instanceof ActionElementModel && (React.createElement(React.Fragment, null,
            React.createElement(CustomAttribute, { name: t('contentBuilder.elementInfo.triggerStateExpr', 'Trigger state expression') },
                React.createElement(ExpressionInputStyledWrapper, { validationResult: validationResult },
                    React.createElement(ExpressionInputConnected, { validate: true, includeFactSuggestions: true, includeBlockSuggestions: true, onChange: (val) => blockProto.setTriggeredStateExpression(val), value: blockProto.triggeredStateExpression, onValidate: (result) => {
                            setValidationResult(result);
                        } }))))),
        blockProto instanceof SelectElementModel && (React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.multiselectAttr', 'Multiselect'), value: blockProto.isMulti, onValueChange: (v) => {
                blockProto.setMulti(v);
            } })),
        blockProto instanceof SelectElementModel && (React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.searchableAttr', 'Add search by options'), value: blockProto.isSearchable, onValueChange: (v) => {
                blockProto.setIsSearchable(v);
            } })),
        blockProto instanceof SelectElementModel && (React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.isDropdownAttr', 'Dropdown'), value: blockProto.isDropdown, onValueChange: (v) => {
                blockProto.setIsDropdown(v);
            } })),
        blockProto instanceof FlowValueCollectorElementModel && (React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.collectFromCriticalPathAttr', 'Collect from critical path'), value: blockProto.collectFromCriticalPath, onValueChange: (v) => {
                blockProto.setCollectFromCriticalPath(v);
            } })),
        blockProto instanceof DatePickerElementModel && (React.createElement(AttributeRendererBoolean, { name: t('contentBuilder.elementInfo.isDateTime', 'Include time selector'), value: blockProto.isDateTime, onValueChange: (v) => {
                blockProto.setIsDateTime(v);
            } })),
        React.createElement(CustomPropertiesRenderer, { customProperties: blockProto.customProperties })));
});
function BasicInfoRenderer({ element }) {
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(React.Fragment, null,
        React.createElement(AttributeRendererCopyableString, { name: "ID", value: element.id, getClipboardValue: () => element.id }),
        React.createElement(AttributeRendererStatic, { name: t('contentBuilder.elementInfo.nameAttr', 'Name'), value: element.type })));
}
const AttributesRenderer = observer(function AttributesRenderer({ element, }) {
    var _a, _b;
    const { editor } = useGraphEditorCtx();
    return (React.createElement(React.Fragment, null, Object.entries((_b = (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.attributeDescriptors) !== null && _b !== void 0 ? _b : {}).map(([identifier, descriptor]) => {
        return (React.createElement(AttributeDescriptorRenderer, { key: identifier + element.id, identifier: identifier + element.id, attributeDescriptor: descriptor, onValueChange: (newValue) => {
                editor.dispatch({
                    type: 'viewBuilder.setBlockProtoProperty',
                    data: {
                        protoID: element.id,
                        propName: identifier,
                        propValue: newValue,
                    },
                });
            } }));
    })));
});
function Separator({ title }) {
    const theme = useTheme();
    return (React.createElement(Base, { my: 4, borderBottom: `1px solid ${theme.palette.background.divider}`, textAlign: "center" },
        React.createElement(Typography, { noSelect: true, variant: "caption" }, title)));
}
