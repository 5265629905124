import React from 'react';
import { PageHeader, PageHeaderActionsSection, PageHeaderDescription, PageHeaderTitle, } from 'lib/cortiUI';
import { Actions } from './Actions';
export const Header = ({ team }) => {
    return (React.createElement(PageHeader, null,
        React.createElement(PageHeaderActionsSection, null,
            React.createElement(Actions, { team: team })),
        React.createElement(PageHeaderDescription, null, team ? team.description : '...'),
        React.createElement(PageHeaderTitle, null, team ? team.name : '...')));
};
