import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { formatDate } from '@corti/date';
import { intl } from '@corti/i18n';
import { trackerService } from 'browser/services/init';
import { Button, DateRangePicker, Popover, } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../CaseReviewRequestsContext';
export const DateRangeFilterPicker = observer(function DateRangeFilterPicker(props) {
    const { onChange } = props;
    const ctx = useCaseReviewRequestsContext();
    const buttonRef = React.useRef();
    const [isOpen, setIsOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState(() => {
        return ctx.model.filter.createdAt;
    });
    React.useEffect(() => {
        setDateRange(ctx.model.filter.createdAt);
    }, [ctx.model.filter.createdAt]);
    function handleOpen() {
        setIsOpen(true);
    }
    function handleClose() {
        if (hasChanges()) {
            ctx.model.filter.setCreatedAt(dateRange);
            onChange();
            trackerService.track('[Review Queue] date-range filter applied', {
                startDate: dateRange === null || dateRange === void 0 ? void 0 : dateRange.start,
                endDate: dateRange === null || dateRange === void 0 ? void 0 : dateRange.end,
            });
        }
        setIsOpen(false);
    }
    function handleChange(value) {
        setDateRange({
            start: value.startDate,
            end: value.endDate,
        });
    }
    function handleRelativeDateRangeSelect(_, absoluteDateRange) {
        if (absoluteDateRange) {
            setDateRange({
                start: absoluteDateRange.start,
                end: absoluteDateRange.end,
            });
        }
    }
    function hasChanges() {
        return !isEqual(dateRange, ctx.model.filter.createdAt);
    }
    function getTitle() {
        return ctx.model.filter.createdAt
            ? `${formatDate(ctx.model.filter.createdAt.start)}-${formatDate(ctx.model.filter.createdAt.end)}`
            : intl.t('caseReviewRequestsModule:dateRangeFilters.filterTitle', 'Review requested');
    }
    function convert(value) {
        return {
            startDate: value.start,
            endDate: value.end,
        };
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: (r) => (buttonRef.current = r), size: "small", variant: "outlined", color: !!dateRange ? 'primary' : undefined, disabled: props.loading, onClick: handleOpen, noWrap: true, endIcon: React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }) }, getTitle()),
        React.createElement(Popover, { transitionDuration: 0, open: isOpen, anchorEl: buttonRef.current, onClose: handleClose },
            React.createElement(DateRangePicker, { range: dateRange ? convert(dateRange) : null, onChange: handleChange, relativeDateRangePickerProps: {
                    enabled: true,
                    onSelect: handleRelativeDateRangeSelect,
                    dateRanges: (ranges) => {
                        return [
                            ranges.LAST_YEAR,
                            ranges.THIS_YEAR,
                            ranges.LAST_MONTH,
                            ranges.THIS_MONTH,
                            ranges.LAST_WEEK,
                            ranges.LAST_30_DAYS,
                            ranges.YESTERDAY,
                        ];
                    },
                }, withTime: true }))));
});
