import React from 'react';
import { useRouteMatch } from 'react-router';
import { useChecklistStorage } from '../useChecklistStorage';
import { useChecklistView } from '../useChecklistView';
import { ChecklistForm } from './ChecklistForm';
export function ChecklistEdit() {
    const { params: { checklistID }, } = useRouteMatch();
    const originalChecklist = useChecklistView(({ checklists }) => checklists.find((c) => c.id === checklistID));
    const { updateChecklist } = useChecklistStorage();
    const handleSave = (checklist) => {
        updateChecklist(checklist);
        return true;
    };
    if (!originalChecklist) {
        return null;
    }
    return (React.createElement(ChecklistForm, { isAdding: false, originalChecklist: originalChecklist, hasValidation: true, onSave: handleSave }));
}
