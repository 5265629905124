var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { authStore } from 'core/auth';
export class NotificationsViewState {
    constructor() {
        Object.defineProperty(this, "NOTIFICATIONS_PAGE_SIZE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 25
        });
        Object.defineProperty(this, "isLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextPageCursor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.error = undefined;
                this.isLoading = false;
                this.hasNextPage = false;
                this.nextPageCursor = undefined;
                this.notifications = [];
            }
        });
        Object.defineProperty(this, "loadNotifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                try {
                    runInAction(() => {
                        this.error = undefined;
                        this.isLoading = true;
                        this.hasNextPage = false;
                        this.nextPageCursor = undefined;
                    });
                    const res = await api.notifications.getNotifications(this.notificationsInput);
                    runInAction(() => {
                        this.notifications = res.items;
                        this.nextPageCursor = res.cursor;
                        this.hasNextPage = res.hasNextPage;
                    });
                }
                catch (err) {
                    runInAction(() => {
                        if (err instanceof Error) {
                            this.error = err;
                        }
                    });
                }
                finally {
                    runInAction(() => {
                        this.isLoading = false;
                    });
                }
            }
        });
        Object.defineProperty(this, "loadNextNotificationsPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const res = await api.notifications.getNotifications(this.notificationsInput);
                runInAction(() => {
                    this.notifications = [...this.notifications, ...res.items];
                    this.nextPageCursor = res.cursor;
                    this.hasNextPage = res.hasNextPage;
                });
            }
        });
        Object.defineProperty(this, "archiveNotification", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (id) => {
                const idx = this.notifications.findIndex((n) => n.id === id);
                const notification = this.notifications[idx];
                if (idx > -1) {
                    try {
                        runInAction(() => {
                            this.notifications.splice(idx, 1);
                        });
                        await api.notifications.markNotificationDone(id);
                        void this.loadNotifications();
                    }
                    catch (err) {
                        runInAction(() => {
                            this.notifications.splice(idx, 0, notification);
                        });
                        throw err;
                    }
                }
            }
        });
        makeObservable(this);
        this.resetState();
    }
    get notificationsInput() {
        return {
            userID: authStore.currentUserID,
            first: this.NOTIFICATIONS_PAGE_SIZE,
            cursor: this.nextPageCursor,
        };
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NotificationsViewState.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Error !== "undefined" && Error) === "function" ? _a : Object)
], NotificationsViewState.prototype, "error", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], NotificationsViewState.prototype, "hasNextPage", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], NotificationsViewState.prototype, "nextPageCursor", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], NotificationsViewState.prototype, "notifications", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationsViewState.prototype, "resetState", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationsViewState.prototype, "loadNotifications", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationsViewState.prototype, "loadNextNotificationsPage", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], NotificationsViewState.prototype, "archiveNotification", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], NotificationsViewState.prototype, "notificationsInput", null);
