import { isEqual, keyBy, mapValues } from 'lodash';
import { DEFAULT_DEFINITION, DEFAULT_DEFINITION_COLOR, } from '@corti/lib/coreApiService';
import { Formatters } from '@corti/strings';
export function submissionToFormValue(submission, caseModel) {
    return {
        fields: mapValues(keyBy(submission.fields, 'fieldID'), (v) => {
            const firstVal = v.values[0];
            const comment = v.comments[0];
            switch (firstVal.type) {
                case 'text': {
                    return {
                        value: firstVal.value,
                        comment: comment ? comment.text : undefined,
                    };
                }
                case 'timeline-entry': {
                    const t = caseModel.timelineEditor.getTimelineEntryBySourceID(firstVal.timelineEntry.sourceID);
                    return {
                        value: t === null || t === void 0 ? void 0 : t.id,
                        comment: comment ? comment.text : undefined,
                    };
                }
            }
        }),
    };
}
// eslint-disable-next-line import/no-unused-modules -- used in tests
export function formatDetection(entry, baseDateTime) {
    const offsetPosition = entry.getOffsetPosition(baseDateTime);
    const label = [offsetPosition.start, offsetPosition.end]
        .filter((p) => p != null)
        .map((p) => Formatters.msToDuration(p))
        .join(' - ');
    return { timelineEntry: entry, label };
}
// eslint-disable-next-line import/no-unused-modules -- used in tests
export function getMatchedTimelineEntriesForChoiceHint(timelineEntries, choice) {
    var _a;
    return ((_a = choice.timelineEntryDefinitionSourceHints) !== null && _a !== void 0 ? _a : []).flatMap((hintTED) => timelineEntries.filter((entry) => isEqual(entry.definition.source, hintTED)));
}
function timelineEntryToTimelineEntryRadioChoiceField(timelineEntry, baseDateTime) {
    var _a;
    const offsetPosition = timelineEntry.getOffsetPosition(baseDateTime);
    return {
        type: 'timeline-entry',
        color: (_a = timelineEntry.definition.style.color) !== null && _a !== void 0 ? _a : DEFAULT_DEFINITION_COLOR,
        text: timelineEntry.text || '',
        headerText: timelineEntry.definition.title,
        headerInfoText: [offsetPosition.start, offsetPosition.end]
            .filter((p) => p != null)
            .map((p) => Formatters.msToDuration(p))
            .join(' - '),
        value: timelineEntry.id,
    };
}
const getAcceptedTimelineEntryDefinitionsFormFieldProperty = (definitionSources, timelineEntryDefinitions) => definitionSources.map((s) => {
    var _a;
    const resolvedDefinition = timelineEntryDefinitions.find(({ source }) => isEqual(source, s)) || DEFAULT_DEFINITION;
    return {
        source: resolvedDefinition.source,
        title: resolvedDefinition.text || resolvedDefinition.title,
        color: (_a = resolvedDefinition.style.color) !== null && _a !== void 0 ? _a : DEFAULT_DEFINITION_COLOR,
        isEntryCreatable: resolvedDefinition.isEntryCreatable || false,
    };
});
const composeTextField = (field) => ({
    id: field.id,
    title: field.title,
    type: field.type,
    isRequired: field.required,
    visibleIf: field.visibleIf,
});
const composeRadioField = (field, timelineEntryDefinitions, timelineEntries, baseDateTime) => {
    const filteredTimelineEntries = timelineEntries.filter((t) => {
        return field.acceptedTimelineEntryDefinitionSources.some((s) => {
            return isEqual(s, t.definition.source);
        });
    });
    const choicesOfAcceptedTimelineEntriesType = filteredTimelineEntries.map((t) => timelineEntryToTimelineEntryRadioChoiceField(t, baseDateTime));
    const choicesOfTextType = (field.choices || []).map((choice) => {
        return {
            type: 'text',
            text: choice.text,
            value: choice.value,
            detectedTimelineEntries: getMatchedTimelineEntriesForChoiceHint(timelineEntries, choice).map((it) => formatDetection(it, baseDateTime)),
        };
    });
    return {
        id: field.id,
        title: field.title,
        type: field.type,
        isRequired: field.required,
        choices: [...choicesOfAcceptedTimelineEntriesType, ...choicesOfTextType],
        acceptedTimelineEntryDefinitions: getAcceptedTimelineEntryDefinitionsFormFieldProperty(field.acceptedTimelineEntryDefinitionSources, timelineEntryDefinitions),
        visibleIf: field.visibleIf,
    };
};
const composeDropdownField = (field, timelineEntries, baseDateTime) => {
    return {
        id: field.id,
        title: field.title,
        type: 'dropdown',
        isRequired: field.required,
        choices: (field.choices || []).map((choice) => {
            return {
                text: choice.text,
                value: choice.value,
                detectedTimelineEntries: getMatchedTimelineEntriesForChoiceHint(timelineEntries, choice).map((it) => formatDetection(it, baseDateTime)),
            };
        }),
        visibleIf: field.visibleIf,
    };
};
const getComposedField = (field, caseModel) => {
    switch (field.type) {
        case 'radio':
            return composeRadioField(field, caseModel.timelineEntryDefinitions, caseModel.timelineEditor.timelineEntries, caseModel.startedAt);
        case 'dropdown':
        case 'action-select':
        case 'protocol-select':
            return composeDropdownField(field, caseModel.timelineEditor.timelineEntries, caseModel.startedAt);
        default:
            return composeTextField(field);
    }
};
export function transformTemplate(template, caseModel) {
    return {
        fields: template.fields.map((field) => getComposedField(field, caseModel)),
    };
}
