var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTheme } from '@corti/theme';
import { Base, Slider, Typography } from 'lib/cortiUI';
export const ThresholdsSlider = (_a) => {
    var { value, style, disabled } = _a, props = __rest(_a, ["value", "style", "disabled"]);
    const MIN_MARK = 0;
    const MAX_MARK = 100;
    const { palette } = useTheme();
    const hasMultipleValues = value.length > 1;
    return (React.createElement(Base, { width: "100%" },
        React.createElement(Slider, Object.assign({}, props, { disabled: disabled, value: value, disableSwap: true, sx: Object.assign({ '& .MuiSlider-track': Object.assign({ background: disabled
                        ? palette.grey[300]
                        : hasMultipleValues
                            ? palette.warning.light
                            : palette.error.light, borderColor: disabled
                        ? palette.grey[300]
                        : hasMultipleValues
                            ? palette.warning.light
                            : 'transparent', borderWidth: 2 }, (!hasMultipleValues && {
                    width: `${value[0]}% !important`,
                    left: '0 !important',
                })), '& .MuiSlider-thumb': {
                    '&:nth-of-type(1n)': {
                        background: disabled
                            ? palette.grey[300]
                            : hasMultipleValues
                                ? palette.warning.light
                                : palette.success.light,
                        '& span': {
                            background: disabled
                                ? palette.grey[300]
                                : hasMultipleValues
                                    ? palette.warning.light
                                    : palette.success.light,
                        },
                    },
                    '&:nth-of-type(2n)': {
                        background: disabled ? palette.grey[300] : palette.success.light,
                        '& span': {
                            background: disabled ? palette.grey[300] : palette.success.light,
                        },
                    },
                }, '& .MuiSlider-rail': {
                    background: disabled
                        ? palette.grey[300]
                        : hasMultipleValues
                            ? `linear-gradient(to right, ${palette.error.light} 0% ${value[0]}%, ${palette.warning.light} ${value[0]}% ${value[1]}%, ${palette.success.light} ${value[1]}% 100%)`
                            : palette.success.light,
                    height: 8,
                    opacity: 1,
                } }, style) })),
        React.createElement(Base, { display: "flex", justifyContent: "space-between" },
            React.createElement(Typography, { variant: "body2", color: "disabled" },
                MIN_MARK,
                "%"),
            React.createElement(Typography, { variant: "body2", color: "disabled" },
                MAX_MARK,
                "%"))));
};
