import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton, Modal, ModalContent, Tooltip } from 'lib/cortiUI';
import { ScreenRecorderSettings } from './ScreenRecorderSettings';
import { useScreenRecordingContext } from './context';
export const ScreenRecorderSettingsTriggerButton = () => {
    const { t } = useTranslation('screenRecorder');
    const { isRecording } = useScreenRecordingContext();
    const [isScreenRecordingSettingsOpen, setIsScreenRecordingSettingsOpen] = React.useState(false);
    const windowRef = React.useRef(null);
    function openScreenRecordingSettings() {
        setIsScreenRecordingSettingsOpen(true);
        if (windowRef.current) {
            windowRef.current.focus();
        }
    }
    function closeScreenRecordingSettings() {
        setIsScreenRecordingSettingsOpen(false);
    }
    return (React.createElement(Base, { display: "flex", justifyContent: "flex-end" },
        React.createElement(Tooltip, { title: t('screenRecordingBtn', 'Open screen recording settings') },
            React.createElement(IconButton, { size: "small", disabled: isRecording, color: isScreenRecordingSettingsOpen ? 'primary' : 'default', onClick: openScreenRecordingSettings, icon: React.createElement(SettingsIcon, null) })),
        React.createElement(Modal, { keepMounted: false, open: isScreenRecordingSettingsOpen, onClose: closeScreenRecordingSettings },
            React.createElement(ModalContent, null,
                React.createElement(ScreenRecorderSettings, null)))));
};
