import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { css } from '@corti/style';
import { Autocomplete, Box, MenuItem, Text, TextField } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { sourceToID } from 'lib/graphEditor/detections';
import { BaseGraphEditorNodeWidget, NodeMainContent } from '../common/BaseGraphEditorNodeWidget';
import { DefaultPortWidget } from '../common/DefaultPortWidget';
export const TimelineEntryAlertNodeWidget = observer(function TimelineEntryAlertNodeWidget(props) {
    const { model } = props;
    const { editor } = useGraphEditorCtx();
    const issues = [];
    const detectionDefinition = model.source
        ? editor.detections.getDefinitionBySource(model.source)
        : null;
    let selectedValue;
    const suggestionItems = editor.detections.detectionDefinitions.map((it) => {
        var _a;
        const item = {
            value: it.id,
            sectionId: it.title,
            text: (_a = it.text) !== null && _a !== void 0 ? _a : '-',
            definition: it,
        };
        if (it === detectionDefinition) {
            selectedValue = item;
        }
        return item;
    });
    if (model.source && !detectionDefinition) {
        selectedValue = { value: sourceToID(model.source), text: sourceToID(model.source) };
        issues.push({ text: 'Missing detection definition' });
    }
    return (React.createElement(BaseGraphEditorNodeWidget, { model: props.model, title: "Detection trigger" },
        React.createElement(NodeMainContent, null,
            React.createElement("div", { className: css({ display: 'flex', alignItems: 'center' }) },
                React.createElement(Box, { mr: 4 },
                    React.createElement(Autocomplete, { "data-cy": "timeline-entry-alert-selector", size: "small", options: orderBy(suggestionItems, [(item) => item.sectionId.toLowerCase(), 'text'], ['asc', 'asc']), getOptionLabel: (option) => option.text, groupBy: (option) => { var _a; return (_a = option.sectionId) !== null && _a !== void 0 ? _a : ''; }, value: selectedValue !== null && selectedValue !== void 0 ? selectedValue : null, onChange: (_, option) => {
                            if (option === null || option === void 0 ? void 0 : option.definition) {
                                model.setSource(option.definition.source);
                            }
                        }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { placeholder: "Select detection" })), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "timeline-entry-menu-item", selected: state.selected, key: option.value }),
                            React.createElement(Text, { noWrap: true }, option.text))), clearIcon: null, sx: { minWidth: 200 } })),
                React.createElement(DefaultPortWidget, { model: props.model.portOut })),
            issues.length !== 0 && (React.createElement("div", null, issues.map((it, idx) => (React.createElement(Text, { key: idx, mt: 4, color: "warning" }, it.text))))))));
});
