import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, CardActions, Checkbox, FormControlLabel, IconButton, Tooltip, } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
import { ConfirmableAction } from '../ConfirmableAction';
export function CardFooter({ isRequired, onRequiredChange, onCopy, onRemove, renderConditionsWidgetTrigger, conditionsCount, onConditionsWidgetTriggerClick, shouldConfirmDeletion, }) {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(CardActions, { sx: { py: 6, px: 0 } },
        React.createElement(Base, { px: 6, display: "flex", justifyContent: "space-between", width: 1 },
            React.createElement(Base, { display: "flex", justifyContent: "flex-start" },
                React.createElement(FormControlLabel, { label: t('fieldWidget.required', 'Required'), control: React.createElement(Checkbox, { checked: isRequired, color: "primary", onChange: (_e, checked) => onRequiredChange(checked), "data-cy": CY_SELECTORS.fieldWidget.requiredField }) }),
                renderConditionsWidgetTrigger && (React.createElement(Button, { color: conditionsCount && conditionsCount > 0 ? 'primary' : undefined, onClick: onConditionsWidgetTriggerClick, variant: "outlined", size: "small", "data-cy": CY_SELECTORS.fieldWidget.openVisibleIfWidgetBtn },
                    t('fieldWidget.setVisibilityConditionBtn', 'Set visibility condition'),
                    ' ',
                    Boolean(conditionsCount && conditionsCount > 0) && `(${conditionsCount})`))),
            React.createElement(Base, { display: "flex", gap: 2 },
                React.createElement(Tooltip, { title: t('actionsHints.duplicateField', 'Duplicate field') },
                    React.createElement(IconButton, { onClick: onCopy, size: "small", icon: React.createElement(ContentCopyRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.fieldWidget.copyFieldBtn })),
                React.createElement(ConfirmableAction, { condition: Boolean(shouldConfirmDeletion), message: t('actionsWarnings.delete', 'Are you sure you would like to delete this item?'), onConfirm: onRemove, renderClickableComponent: (onClick) => (React.createElement(Tooltip, { title: t('actionsHints.deleteField', 'Delete field') },
                        React.createElement(IconButton, { onClick: onClick, size: "small", icon: React.createElement(DeleteRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.fieldWidget.removeFieldBtn }))) })))));
}
