import { observer } from 'mobx-react';
import React from 'react';
import { css, darken, readableColor, shade, tint } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ButtonBase, Typography } from 'lib/cortiUI';
export const ActionElementWidget = observer(function ActionElementWidget(props) {
    const { model } = props;
    const theme = useTheme();
    const color = model.attributes.values.backgroundColor || theme.palette.primary.main;
    return (React.createElement("div", { "data-cy": "action-element-widget" },
        React.createElement(ButtonBase, { className: css({
                width: '100%',
                minHeight: 48,
                border: 'none',
                borderRadius: Number(theme.shape.borderRadius) * 2,
                paddingLeft: theme.space[6],
                paddingRight: theme.space[6],
                paddingTop: theme.space[4],
                paddingBottom: theme.space[4],
                fontSize: theme.typography.fontSize,
                color: readableColor(darken(0.2, color)),
                boxShadow: `inset 0 0 0 1px ${readableColor(darken(0.51, color), theme.palette.background.divider, 'none')}`,
                backgroundColor: color,
                '&:hover': {
                    backgroundColor: readableColor(darken(0.415, color), shade(0.03, color), tint(0.2, color)),
                },
                '&:focus-within': {
                    backgroundColor: readableColor(darken(0.415, color), shade(0.03, color), tint(0.2, color)),
                },
            }) },
            React.createElement(Base, { display: "grid", alignItems: "center", gridTemplateColumns: '50px auto 50px', gridTemplateAreas: '"icon text empty"' },
                React.createElement(Typography, { variant: "body2", gridArea: "text", color: "inherit" }, model.content)))));
});
