import LinkRounded from '@mui/icons-material/LinkRounded';
import React from 'react';
import { Badge, Base, IconButton, Modal, ModalHeader, Tooltip } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
import { TimelineEntryDefinitionSelect } from './TimelineEntryDefinitionSelect';
export const TimelineEntryDefinitionPicker = ({ tooltipTitle, onChange, selectedValues, timelineEntryDefinitions, renderDefaultTrigger, }) => {
    const [isTimelineEntryDefinitionSelectModalOpen, setIsTimelineEntryDefinitionSelectModalOpen] = React.useState(false);
    function handleTimelineEntryDefinitionChange(sources) {
        onChange(sources);
        setIsTimelineEntryDefinitionSelectModalOpen(false);
    }
    function handleToggleModal() {
        setIsTimelineEntryDefinitionSelectModalOpen(!isTimelineEntryDefinitionSelectModalOpen);
    }
    function renderTrigger() {
        return selectedValues.length > 0 ? (React.createElement(Badge, { color: "primary", badgeContent: selectedValues.length, "data-cy": CY_SELECTORS.fieldWidget.definitionsSelectorBadge },
            React.createElement(IconButton, { onClick: () => setIsTimelineEntryDefinitionSelectModalOpen(true), size: "small", color: "primary", icon: React.createElement(LinkRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.fieldWidget.selectTimelineEntryDefsForHintsBtn }))) : (React.createElement(IconButton, { onClick: handleToggleModal, size: "small", icon: React.createElement(LinkRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.fieldWidget.selectTimelineEntryDefsForHintsBtn }));
    }
    return (React.createElement(Base, null,
        React.createElement(Tooltip, { title: tooltipTitle }, renderDefaultTrigger ? renderDefaultTrigger(handleToggleModal) : renderTrigger()),
        React.createElement(Modal, { open: isTimelineEntryDefinitionSelectModalOpen, onClose: handleToggleModal, size: "large", "data-cy": CY_SELECTORS.selectTimelineEntryDefsModal },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: tooltipTitle }),
                React.createElement(TimelineEntryDefinitionSelect, { allTimelineEntryDefinitions: timelineEntryDefinitions, onApply: handleTimelineEntryDefinitionChange, selectedTimelineEntryDefinitionSources: selectedValues })))));
};
