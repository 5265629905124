/** Returns mouse offset in seconds from audio start */
export function getMouseOffset(ws, e) {
    // WS method that returns playback position from 0 to 1
    const playbackPosition = ws.drawer.handleEvent(e);
    const audioDuration = ws.getDuration();
    return playbackPosition * audioDuration;
}
export function convertSecondsToPx(ws, seconds) {
    const width = ws.drawer.width / ws.params.pixelRatio;
    const audioDuration = ws.getDuration();
    return (seconds / audioDuration) * width;
}
export function convertPxToSeconds(ws, px) {
    const width = ws.drawer.width / ws.params.pixelRatio;
    const audioDuration = ws.getDuration();
    return (px * audioDuration) / width;
}
