import { isValid } from '@corti/date';
import { isValuePublisher, } from '@corti/lib/graphs';
export function getReadableCollectorItems(collector, flowStore) {
    const result = [];
    const formatDate = flowStore.formatDate;
    collector.items.forEach((it) => {
        var _a;
        const item = Object.assign(Object.assign({}, it), { label: '', text: '' });
        switch (it.valueType) {
            case 'block':
            case 'block-option': {
                const blockProto = flowStore.graphTraverser.getBlockProtoByID(it.values[0].identifier.blockPrototypeID);
                if (!blockProto) {
                    return result;
                }
                const { label, text } = getReadableBlockValue(collector.blockInstance.blockPrototype, blockProto, it.values);
                item.label = label;
                const dateFromText = (_a = text.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/)) === null || _a === void 0 ? void 0 : _a[0];
                const isDate = dateFromText && isValid(new Date(dateFromText));
                if (isDate) {
                    item.text = text.replace(dateFromText, formatDate(new Date(dateFromText)));
                }
                else {
                    item.text = text;
                }
                break;
            }
            case 'fact': {
                const factDef = flowStore.graphTraverser.getFactByID(it.values[0].factID);
                if (!factDef) {
                    return result;
                }
                const { label, text } = getReadableFactValue(collector.blockInstance.blockPrototype, factDef, it.values[0]);
                item.label = label;
                item.text = text;
                break;
            }
            case 'custom': {
                item.text = it.values.map((it) => it.value).join(', ');
                break;
            }
        }
        result.push(item);
    });
    return result;
}
const CUST_PROP_ALT_TEXT_KEY = 'altText';
const ALT_TEXT_VARIABLE_KEY = '{value}';
function evaluateAltTextExpression(altText, variableValue) {
    return altText.replace(ALT_TEXT_VARIABLE_KEY, String(variableValue));
}
function getAltTextForCollector(collector, publisher) {
    var _a, _b;
    if (!isValuePublisher(publisher)) {
        return undefined;
    }
    // old way compatibility - using custom properties
    if (publisher.valuePublisherConfig.collectors.length === 0) {
        if ('customProperties' in publisher) {
            return (_a = publisher.customProperties.find((it) => it.key === CUST_PROP_ALT_TEXT_KEY)) === null || _a === void 0 ? void 0 : _a.value;
        }
    }
    const configForCollector = publisher.valuePublisherConfig.collectors.find((c) => c.prototypeID === collector.id);
    if (!configForCollector) {
        return undefined;
    }
    return (_b = configForCollector.customValueFormat) !== null && _b !== void 0 ? _b : undefined;
}
export function getReadableBlockValue(collectorProto, block, values) {
    const readableValues = [];
    const res = {
        label: '',
        text: '',
    };
    if ('label' in block) {
        res.label = block.label.plainText;
    }
    values.forEach((value) => {
        const { optionID } = value.identifier;
        if (optionID == undefined) {
            readableValues.push(value.value);
        }
        else {
            if (block.type !== 'SELECT') {
                throw new Error(`SELECT block type value used with ${block.type} block type`);
            }
            const option = block.options.find((o) => o.id === optionID);
            if (option) {
                const optionText = option.text.plainText;
                const optionAltText = getAltTextForCollector(collectorProto, option);
                const text = optionAltText
                    ? optionAltText.replace(ALT_TEXT_VARIABLE_KEY, optionText)
                    : optionText;
                readableValues.push(text);
            }
        }
    });
    res.text = readableValues.join(', ');
    const blockAltText = getAltTextForCollector(collectorProto, block);
    if (blockAltText) {
        res.text = evaluateAltTextExpression(blockAltText, res.text);
    }
    return res;
}
function getReadableFactValue(collectorProto, fact, value) {
    const res = {
        label: fact.name,
        text: String(value.value),
    };
    const altText = getAltTextForCollector(collectorProto, fact);
    if (altText) {
        res.text = evaluateAltTextExpression(altText, value.value);
    }
    return res;
}
export function collectedItemsToClipboardContent(model, flowStore) {
    const readableItems = getReadableCollectorItems(model, flowStore);
    function collectorItemToHtml(item) {
        return item.text.trim();
    }
    function collectorItemToPlain(item) {
        return item.text.trim();
    }
    return [
        {
            type: 'text/html',
            content: readableItems.map(collectorItemToHtml).join('</br>'),
        },
        {
            type: 'text/plain',
            content: readableItems.map(collectorItemToPlain).join('\r\n'),
        },
    ];
}
