import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { Base, Button, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../context';
import { useNavigation } from '../context';
export const Header = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment' });
    const { setView } = useNavigation();
    const { interaction } = useInteraction();
    return (React.createElement(Base, { display: "flex", alignItems: "centre", justifyContent: "space-between" },
        React.createElement(Typography, { variant: "h6", color: "default" }, t('title', 'Assessment')),
        rbacService.feedbackSubmissionCRUD({
            caseOwnerID: interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner,
        }) && (React.createElement(Button, { variant: "outlined", size: "small", startIcon: React.createElement(AddRoundedIcon, null), onClick: () => setView('manual-assessment') }, t('newReviewBtn', 'New')))));
};
