import { gql } from '@apollo/client';
import { timelineEntryFragment } from '../cases';
import { timelineEntryDefinitionSourceFragment, } from '../timelineEntryDefinitions';
const feedbackFormTemplateFragment = gql `
  fragment FeedbackFormTemplate on FeedbackFormTemplate {
    id
    title
    anonymizeReviewers
    createdBy {
      id
      name
    }
    createdAt
    fields {
      id
      title
      type
      required
      acceptedTimelineEntryDefinitionSources {
        ...TimelineEntryDefinitionSource
      }
      choices {
        value
        text
        timelineEntryDefinitionSourceHints {
          ...TimelineEntryDefinitionSource
        }
      }
      visibleIf {
        junction
        conditions {
          fieldID
          comparison
          values
        }
      }
    }
  }
  ${timelineEntryDefinitionSourceFragment}
`;
const feedbackFormSubmissionFragment = gql `
  fragment FeedbackFormSubmissionSimple on FeedbackFormSubmission {
    id
    createdAt
    createdBy {
      id
      name
    }
    dispatcherAcknowledgedAt
    template {
      title
    }
  }
`;
const caseReviewRequestFragment = gql `
  fragment CaseReviewRequest on CaseReviewRequest {
    id
    createdAt
    createdBy {
      id
      name
    }
    assignee {
      isAnonymised
      user {
        id
        name
      }
    }
    case {
      id
      createdAt
      calls {
        user {
          id
          name
        }
        protocolName
        externalID
      }
    }
    suggestedTemplate {
      id
      title
    }
    submission {
      id
      dispatcherAcknowledgedAt
      template {
        id
        title
      }
    }
  }
`;
export class CaseFeedbackService {
    constructor(client, restClient) {
        Object.defineProperty(this, "client", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: client
        });
        Object.defineProperty(this, "restClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: restClient
        });
    }
    async createFeedbackFormTemplate(template) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation createFeedbackFormTemplate($template: FeedbackFormTemplateInput!) {
          createFeedbackFormTemplate(template: $template) {
            ...FeedbackFormTemplate
          }
        }
        ${feedbackFormTemplateFragment}
      `,
            variables: { template: template },
        });
        return res.data.createFeedbackFormTemplate;
    }
    async getFeedbackFormTemplates(orgID) {
        const res = await this.client.query({
            query: gql `
        query feedbackFormTemplates($orgID: ID!) {
          feedbackFormTemplates(organizationID: $orgID) {
            ...FeedbackFormTemplate
          }
        }
        ${feedbackFormTemplateFragment}
      `,
            variables: {
                orgID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.feedbackFormTemplates;
    }
    async getFeedbackFormTemplatesIncludeDeleted(orgID) {
        const res = await this.client.query({
            query: gql `
        query feedbackFormTemplatesIncludeDeleted($orgID: ID!) {
          feedbackFormTemplatesIncludeDeleted(organizationID: $orgID) {
            id
            title
            createdAt
            deletedAt
          }
        }
      `,
            variables: {
                orgID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.feedbackFormTemplatesIncludeDeleted;
    }
    async createReviewRequest(input) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation createCaseReviewRequest($input: CaseReviewRequestInput!) {
          createCaseReviewRequest(input: $input) {
            ...CaseReviewRequest
          }
        }
        ${caseReviewRequestFragment}
      `,
            variables: {
                input: input,
            },
        });
        return res.data.createCaseReviewRequest;
    }
    async deleteCaseReviewRequest(requestID) {
        await this.client.mutate({
            mutation: gql `
        mutation deleteCaseReviewRequest($id: String!) {
          deleteCaseReviewRequest(id: $id)
        }
      `,
            variables: { id: requestID },
        });
    }
    async removeUserFromReviewingCase(caseID, userID) {
        await this.client.mutate({
            mutation: gql `
        mutation removeUserFromReviewingCase($caseID: String!, $userID: ID!) {
          removeUserFromReviewingCase(caseID: $caseID, userID: $userID)
        }
      `,
            variables: { caseID, userID },
        });
    }
    async getReviewRequests(input) {
        const res = await this.client.query({
            query: gql `
        query caseReviewRequests(
          $organizationID: ID!
          $filter: CaseReviewRequestFilterInput
          $sort: CaseReviewRequestSortInput
          $cursor: String
          $first: Int
        ) {
          caseReviewRequests(
            organizationID: $organizationID
            filter: $filter
            sort: $sort
            cursor: $cursor
            first: $first
          ) {
            totalCount
            items {
              ...CaseReviewRequest
            }
            cursor
            hasNextPage
          }
        }
        ${caseReviewRequestFragment}
      `,
            variables: {
                organizationID: input.organizationID,
                filter: input.filter,
                sort: input.sort,
                cursor: input.cursor,
                first: input.first,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.caseReviewRequests;
    }
    async getFeedbackFormSubmission(id) {
        const res = await this.client.query({
            query: gql `
        query feedbackFormSubmission($id: String!) {
          feedbackFormSubmission(id: $id) {
            ...FeedbackFormSubmissionSimple
            template {
              ...FeedbackFormTemplate
            }
            fields {
              fieldID
              values {
                type
                value
                timelineEntry {
                  ...TimelineEntryFragment
                }
              }
              comments {
                id
                text
                createdAt
                createdBy {
                  id
                  name
                }
              }
            }
          }
        }
        ${feedbackFormTemplateFragment}
        ${timelineEntryFragment}
        ${feedbackFormSubmissionFragment}
      `,
            variables: { id },
            fetchPolicy: 'no-cache',
        });
        return res.data.feedbackFormSubmission;
    }
    async submitFeedbackForm(input) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation submitFeedbackForm($input: FeedbackFormSubmissionInput!) {
          submitFeedbackForm(formSubmission: $input) {
            ...FeedbackFormSubmissionSimple
          }
        }
        ${feedbackFormSubmissionFragment}
      `,
            variables: {
                input,
            },
        });
        return res.data.submitFeedbackForm;
    }
    async acknowledgeFeedbackFormSubmission(submissionID) {
        const response = await this.client.mutate({
            mutation: gql `
        mutation acknowledgeFeedbackFormSubmission($id: String!) {
          acknowledgeFeedbackFormSubmission(id: $id)
        }
      `,
            variables: { id: submissionID },
        });
        return response.data.acknowledgeFeedbackFormSubmission;
    }
    async exportFeedbackFormSubmissionCsvFile(input) {
        const url = '/exports/feedback-form-submissions';
        const res = await this.restClient.axiosClient({
            method: 'post',
            url,
            data: {
                organization_id: input.organizationId,
                template_id: input.templateId,
                filter: input.filter,
            },
        });
        return res.data;
    }
    async getFeedbackFormTemplateByID(templateID) {
        const res = await this.client.query({
            query: gql `
        query getTemplate($id: String!) {
          feedbackFormTemplate(id: $id) {
            ...FeedbackFormTemplate
          }
        }
        ${feedbackFormTemplateFragment}
      `,
            variables: {
                id: templateID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.feedbackFormTemplate;
    }
}
