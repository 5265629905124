/**
 * HOC that adds some base layout props to a component
 */
import { omit } from 'lodash';
import { css } from '@corti/style';
import { background, border, elevation, flexGap, flexbox, grid, layout, opacity, position, shadow, space, typography, useTheme, } from '@corti/theme';
/**
 * Convenience utility to get classname from styling props for react hooks
 */
export function useBaseCss(styleProps) {
    const theme = useTheme();
    return getBaseCss(styleProps, theme);
}
/**
 * Accepts styling props and returns a classname
 */
export function getBaseCss(styleProps, theme) {
    const props = Object.assign(Object.assign({}, styleProps), { theme });
    return css(background(props), border(props), elevation(props), flexbox(props), flexGap(props), grid(props), layout(props), opacity(props), position(props), shadow(props), space(props), typography(props));
}
const allBasePropNames = [
    background,
    border,
    elevation,
    flexGap,
    flexbox,
    grid,
    layout,
    layout,
    opacity,
    position,
    shadow,
    space,
    space,
    typography,
].flatMap((it) => it.propNames);
/**
 * Utility function. Accepts props object and removes base layout and styling props from it
 * Doesn't modify props object but returns a new one
 */
export function filterBaseProps(props) {
    return omit(props, allBasePropNames);
}
