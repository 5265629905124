import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { Base, Button, ModalContent, ModalHeader, Separator, Typography } from 'lib/cortiUI';
import { GroupTracker, TrackersGroupsList } from './components';
import { getPerformanceMetricsSourcesQuery, } from './graphql';
import { getMetricsSourceInput, groupMetricSourcesByTitle, } from './utils';
export const TrackersConfigModalContent = ({ onApply, selectedMetricSourcesKeys = [], }) => {
    const { t } = useTranslation('performanceApp');
    const { organization } = useAuth();
    const [configurationSources, setConfigurationSources] = React.useState(null);
    const { loading: getMetricsSourcesLoading } = useQuery(getPerformanceMetricsSourcesQuery, {
        variables: {
            organizationID: organization.id,
        },
        onCompleted: handleFetchMetricSourcesComplete,
    });
    const hasEnabledSources = Object.values(configurationSources !== null && configurationSources !== void 0 ? configurationSources : {}).some((sources) => sources.some((s) => s.isEnabled));
    function handleFetchMetricSourcesComplete(data) {
        if (!(data === null || data === void 0 ? void 0 : data.performanceMetricsSources))
            return;
        const metricSourcesList = data.performanceMetricsSources.map((it) => {
            var _a;
            return (Object.assign(Object.assign({}, it), { isEnabled: (_a = selectedMetricSourcesKeys === null || selectedMetricSourcesKeys === void 0 ? void 0 : selectedMetricSourcesKeys.some((key) => isEqual(key, it.key))) !== null && _a !== void 0 ? _a : false }));
        });
        setConfigurationSources(groupMetricSourcesByTitle(metricSourcesList));
    }
    function handleGroupCheckboxClick(title) {
        return (checked) => {
            setConfigurationSources((currentSources) => {
                if (currentSources === null) {
                    return currentSources;
                }
                return Object.assign(Object.assign({}, currentSources), { [title]: currentSources[title].map((source) => (Object.assign(Object.assign({}, source), { isEnabled: checked }))) });
            });
        };
    }
    function handleTrackerClick(title, key, isEnabled) {
        setConfigurationSources((currentSources) => {
            if (currentSources === null) {
                return currentSources;
            }
            return Object.assign(Object.assign({}, currentSources), { [title]: currentSources[title].map((source) => isEqual(key, source.key) ? Object.assign(Object.assign({}, source), { isEnabled }) : source) });
        });
    }
    async function handleApply() {
        onApply(getMetricsSourceInput(configurationSources));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: t('trackersConfiguration.modalTitle', 'Select Trackers') }),
        React.createElement(Typography, { variant: "body1", px: 7, mb: 7 }, t('trackersConfiguration.modalDescription', 'Select the trackers you would like to see Compliance data for. You can always edit these trackers in the Annotations page (Org. Settings > Annotations).')),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(ModalContent, { py: 6 },
            React.createElement(TrackersGroupsList, { isLoading: getMetricsSourcesLoading, metricSourcesList: configurationSources, onGroupCheckboxClick: handleGroupCheckboxClick }, (groupTrackers) => groupTrackers.map((tracker) => (React.createElement(GroupTracker, { key: tracker.text, item: tracker, onTrackerClick: handleTrackerClick }))))),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(Base, { display: "flex", justifyContent: "flex-end", px: 7, pb: 5, pt: 5 },
            React.createElement(Button, { variant: "text", disabled: getMetricsSourcesLoading || !hasEnabledSources, onClick: handleApply, "data-cy": "save-trackers-btn" }, t('trackersConfiguration.apply', 'Apply')))));
};
