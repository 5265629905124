import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import fuzzysort from 'fuzzysort';
import { observer } from 'mobx-react';
import React from 'react';
import { intl, useTranslation } from '@corti/i18n';
import { useLazyRef, useMousetrap } from '@corti/react';
import { Autocomplete, Box, InputAdornment, TextField, Typography } from 'lib/cortiUI';
import { useTriageSession } from '../TriageSessionContext';
import { prepare } from './search';
export const SearchBar = observer(function SearchBar({ keyboardShortcut }) {
    var _a, _b, _c;
    const kbShortcutOpen = (_a = keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.open) !== null && _a !== void 0 ? _a : '/';
    const kbShortcutClose = (_b = keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.close) !== null && _b !== void 0 ? _b : 'esc';
    const ctx = useTriageSession();
    const { t } = useTranslation();
    const [inputRef, setInputRef] = React.useState(null);
    const search = useLazyRef(() => prepare(ctx.controller.flow.graphTraverser));
    const allItems = search.current.find('');
    useMousetrap({
        keys: kbShortcutOpen,
        handle: (e) => {
            if (!(keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.disabled)) {
                e.preventDefault();
                inputRef === null || inputRef === void 0 ? void 0 : inputRef.focus();
            }
        },
    }, [kbShortcutOpen, keyboardShortcut, inputRef]);
    useMousetrap({
        keys: (_c = keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.close) !== null && _c !== void 0 ? _c : 'esc',
        handle: (event) => {
            if (!(keyboardShortcut === null || keyboardShortcut === void 0 ? void 0 : keyboardShortcut.disabled)) {
                event.preventDefault();
                inputRef === null || inputRef === void 0 ? void 0 : inputRef.blur();
                ctx.controller.flow.observer.fireEvent('toolbar-element-blurred', {
                    event: 'toolbar-element-blurred',
                    data: {
                        datetime: new Date().toUTCString(),
                        event,
                    },
                });
            }
        },
        element: inputRef !== null && inputRef !== void 0 ? inputRef : undefined,
    }, [kbShortcutClose, keyboardShortcut, inputRef]);
    function handleSelectItem(item) {
        const node = ctx.controller.flow.graphTraverser.getBranchStartNode(item.target.id);
        if (!node) {
            return;
        }
        let step = ctx.controller.flow.stepStore.getLastStepByNodeID(node.id);
        if (!step) {
            step = ctx.controller.flow.stepStore.createStep(node);
            ctx.controller.flow.addStep(step);
        }
        ctx.controller.flow.changeActiveStep({
            step,
            source: 'user-action',
            sourceData: {
                location: 'search-bar',
            },
        });
    }
    return (React.createElement(Autocomplete, { size: "small", clearOnEscape: true, blurOnSelect: true, openOnFocus: true, autoHighlight: true, options: allItems, onChange: (_, value) => {
            if (value) {
                handleSelectItem(value);
            }
        }, value: null, getOptionLabel: (option) => option.text, componentsProps: {
            paper: {
                sx: {
                    maxWidth: 460,
                    width: '90vw',
                },
            },
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('realtime:searchbar.label', 'Go to'), placeholder: t('realtime:searchbar.placeholder', 'Use "{{shortcut}}" to open', {
                shortcut: kbShortcutOpen,
                interpolation: { escapeValue: false },
            }), InputProps: Object.assign(Object.assign({}, params.InputProps), { inputRef: (r) => setInputRef(r), startAdornment: (React.createElement(InputAdornment, { position: "start" },
                    React.createElement(SubdirectoryArrowRightIcon, { fontSize: "small" }))) }) }))), renderOption: (props, option) => {
            var _a, _b, _c;
            return (React.createElement("li", Object.assign({}, props),
                React.createElement(Box, { display: "flex", flexDirection: 'column', alignItems: "start" },
                    React.createElement(Typography, null, ((_a = option.searchResult) === null || _a === void 0 ? void 0 : _a.text) ? (React.createElement(HighlightedText, { result: option.searchResult.text })) : (option.text)),
                    ((_b = option.searchResult) === null || _b === void 0 ? void 0 : _b.dynamicKeywords) ? (React.createElement(Box, { style: { display: 'flex', gap: 2, flexDirection: 'row' } }, (_c = option.searchResult) === null || _c === void 0 ? void 0 : _c.dynamicKeywords.map((keyword) => {
                        return (React.createElement(Typography, null,
                            React.createElement(HighlightedText, { result: keyword })));
                    }))) : null)));
        }, filterOptions: (_, state) => {
            return search.current.find(state.inputValue);
        }, clearIcon: null, popupIcon: null }));
});
function HighlightedText(props) {
    let components = fuzzysort.highlight(props.result, (m, i) => (React.createElement("strong", { style: { fontWeight: 700 }, key: i }, m)));
    return React.createElement(React.Fragment, null, components);
}
