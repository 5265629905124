import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Mousetrap from 'mousetrap';
import React from 'react';
import { useDebouncedEffect } from '@corti/react';
import { css, getFocusCss, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from '../Base';
import { Box } from '../Box';
import { ButtonBase } from '../Button';
import { FocusContext } from '../FocusContext';
const SIDEBAR_WIDTH_OPENED = 260;
const SIDEBAR_WIDTH_CLOSED = 16;
const SIDEBAR_OPEN_TEMP_TIMEOUT = 200;
const SIDEBAR_CLOSE_TEMP_TIMEOUT = 400;
/**
 * Component expands with hovering it or pressing button. It has navigation between sub-apps and usally contains information regarding them
 */
export const Sidebar = React.forwardRef(function Sidebar(props, ref) {
    const { defaultMode = 'opened-temp', openingDirection = 'right', keepMounted = false, onOpenTemporary, onOpenPermanently, onClose, children, } = props;
    const theme = useTheme();
    const $outerContainer = React.useRef(null);
    const $innerContainer = React.useRef(null);
    const focusController = React.useRef(null);
    const mousetrap = React.useRef(new Mousetrap());
    const keyboardShortcuts = React.useRef(Object.assign({ openTemporarSidebar: 'q', closeTemporarSidebar: 'esc', togglePermanentSidebar: 'shift+q' }, props.keyboardShortcuts));
    const [isHovered, setIsHovered] = React.useState(false);
    const [isFocusedWithin, setIsFocusedWithin] = React.useState(false);
    const [mode, setMode] = React.useState(defaultMode);
    React.useEffect(() => {
        return () => {
            var _a, _b;
            (_a = $outerContainer.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseleave', handleMouseLeave);
            (_b = $innerContainer.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('mouseenter', handleMouseEnter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useImperativeHandle(ref, () => {
        return $innerContainer.current;
    });
    React.useEffect(() => {
        if (keyboardShortcuts.current.openTemporarSidebar) {
            mousetrap.current.bind(keyboardShortcuts.current.openTemporarSidebar, () => {
                var _a;
                if (mode === 'closed') {
                    setMode('opened-temp');
                }
                (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
            });
        }
        if (keyboardShortcuts.current.closeTemporarSidebar) {
            mousetrap.current.bind(keyboardShortcuts.current.closeTemporarSidebar, () => {
                if (mode === 'opened-temp') {
                    setMode('closed');
                }
            });
        }
        if (keyboardShortcuts.current.togglePermanentSidebar) {
            mousetrap.current.bind(keyboardShortcuts.current.togglePermanentSidebar, () => {
                var _a, _b;
                if (mode !== 'opened-perm') {
                    setMode('opened-perm');
                    (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
                }
                else {
                    setMode('closed');
                    (_b = focusController.current) === null || _b === void 0 ? void 0 : _b.blur();
                }
            });
        }
        return () => {
            var _a;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (_a = mousetrap.current) === null || _a === void 0 ? void 0 : _a.reset();
        };
    }, [mode]);
    React.useEffect(() => {
        switch (mode) {
            case 'opened-temp': {
                onOpenTemporary && onOpenTemporary();
                document.addEventListener('focusin', handleDocumentFocusWithin);
                document.addEventListener('click', handleDocumentClick);
                break;
            }
            case 'opened-perm': {
                onOpenPermanently && onOpenPermanently();
                setIsFocusedWithin(false);
                break;
            }
            case 'closed': {
                onClose && onClose();
                setIsFocusedWithin(false);
                setIsHovered(false);
                break;
            }
        }
        return () => {
            document.removeEventListener('focusin', handleDocumentFocusWithin);
            document.removeEventListener('click', handleDocumentClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);
    React.useEffect(() => {
        if (!isFocusedWithin) {
            if (isHovered) {
                return;
            }
            if (mode === 'opened-temp') {
                setMode('closed');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusedWithin]);
    useDebouncedEffect(() => {
        if (isFocusedWithin) {
            return;
        }
        if (isHovered) {
            if (mode === 'closed') {
                setMode('opened-temp');
            }
        }
    }, SIDEBAR_OPEN_TEMP_TIMEOUT, [isHovered]);
    useDebouncedEffect(() => {
        if (isFocusedWithin) {
            return;
        }
        if (!isHovered) {
            if (mode === 'opened-temp') {
                setMode('closed');
            }
        }
    }, SIDEBAR_CLOSE_TEMP_TIMEOUT, [isHovered]);
    const handleDocumentClick = React.useCallback(() => {
        var _a;
        if (((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'BODY') {
            setIsFocusedWithin(false);
        }
    }, []);
    const handleDocumentFocusWithin = React.useCallback((e) => {
        const node = e.target;
        if (node && $innerContainer.current) {
            if (!$innerContainer.current.contains(node)) {
                setIsFocusedWithin(false);
            }
            else {
                setIsFocusedWithin(true);
            }
        }
    }, []);
    function handleControlButtonClick() {
        if (mode === 'opened-perm') {
            setMode('closed');
        }
        else {
            setMode('opened-perm');
        }
    }
    function isOpen() {
        return mode !== 'closed';
    }
    function getOuterContainerWidth() {
        if (mode === 'opened-perm') {
            return SIDEBAR_WIDTH_OPENED;
        }
        else {
            return SIDEBAR_WIDTH_CLOSED;
        }
    }
    function getInnerContainerWidth() {
        if (mode === 'opened-perm' || mode === 'opened-temp') {
            return SIDEBAR_WIDTH_OPENED;
        }
        else {
            return SIDEBAR_WIDTH_CLOSED;
        }
    }
    function getControllButtonPosition() {
        if (mode === 'opened-perm' || mode === 'opened-temp') {
            return SIDEBAR_WIDTH_OPENED;
        }
        else {
            return SIDEBAR_WIDTH_CLOSED;
        }
    }
    function renderContent() {
        if (typeof children === 'function') {
            return children({ isOpen: isOpen() });
        }
        else {
            return children;
        }
    }
    function registerOuterContainerRef(ref) {
        var _a, _b;
        (_a = $outerContainer.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseleave', handleMouseLeave);
        $outerContainer.current = ref;
        (_b = $outerContainer.current) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseleave', handleMouseLeave);
    }
    function registerInnerContainerRef(ref) {
        var _a, _b;
        (_a = $innerContainer.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseenter', handleMouseEnter);
        $innerContainer.current = ref;
        (_b = $innerContainer.current) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseenter', handleMouseEnter);
    }
    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, []);
    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, []);
    return (React.createElement(Base, { ref: registerOuterContainerRef, position: "relative", height: "100%", width: getOuterContainerWidth(), className: css({
            backgroundColor: theme.palette.background.default,
        }), flexShrink: 0 },
        React.createElement(Box, { ref: registerInnerContainerRef, zIndex: theme.zIndex.drawer, position: "absolute", top: 0, left: 0, width: getInnerContainerWidth(), height: "100%", py: isOpen() ? 8 : 0, px: isOpen() ? 7 : 0, className: css({
                backgroundColor: theme.palette.background.default,
                borderRight: `1px solid ${theme.palette.background.divider}`,
            }, getScrollerCss({ theme })) },
            React.createElement(FocusContext, { controllerRef: focusController, disableKeyboardShortcuts: !isOpen(), keyboardEventBindingElement: $innerContainer.current },
                React.createElement(Base, { display: isOpen() ? 'flex' : 'none', flexDirection: "column", gap: 7 }, (keepMounted || isOpen()) && renderContent()))),
        React.createElement(Box, { zIndex: theme.zIndex.drawer, position: "absolute", top: "10px", left: openingDirection === 'right' ? getControllButtonPosition() : undefined, right: openingDirection === 'left' ? getControllButtonPosition() : undefined, width: 50, height: 50, justifyContent: "center", alignItems: "center", className: css({
                transform: openingDirection === 'right' ? 'translateX(-50%)' : 'translateX(50%)',
            }) },
            React.createElement(ButtonBase, { width: 22, height: 22, onClick: handleControlButtonClick, "data-cy": "sidebar-control-button", className: css({
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.background.divider,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.text.inverted,
                    },
                }, getFocusCss({ theme })) }, openingDirection === 'right' ? (mode === 'opened-perm' ? (React.createElement(KeyboardArrowLeftRoundedIcon, { fontSize: "small", className: css({ fontSize: 13 }), color: "inherit" })) : (React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "small", className: css({ fontSize: 13 }), color: "inherit" }))) : mode === 'opened-perm' ? (React.createElement(KeyboardArrowRightRoundedIcon, { fontSize: "small", className: css({ fontSize: 13 }), color: "inherit" })) : (React.createElement(KeyboardArrowLeftRoundedIcon, { fontSize: "small", className: css({ fontSize: 13 }), color: "inherit" }))))));
});
