import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, Button, MenuItem } from 'lib/cortiUI';
import { TextField } from 'lib/cortiUI/components/TextField';
import { useTranscriptQueryViewContext } from '../../TranscriptQueryView/context';
export function FilterPanel(props) {
    var _a, _b, _c;
    const { onFilterSave, allFilters } = props;
    const { t } = useTranslation();
    const { viewModel } = useTranscriptQueryViewContext();
    const [selectedUsers, setSelectedUsers] = React.useState((_a = allFilters.users.filter((it) => { var _a; return (_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.tempUserIDs.includes(it.id); })) !== null && _a !== void 0 ? _a : []);
    const [selectedTeams, setSelectedTeams] = React.useState((_b = allFilters.teams.filter((it) => { var _a; return (_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.tempTeamIDs.includes(it.id); })) !== null && _b !== void 0 ? _b : []);
    const [selectedProtocolNames, setSelectedProtocolNames] = React.useState((_c = allFilters.protocolNames.filter((it) => { var _a; return (_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.tempProtocolNames.includes(it); })) !== null && _c !== void 0 ? _c : []);
    function handleSavingFilters() {
        var _a, _b, _c;
        (_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.changeTempUserIDs(selectedUsers.map((it) => it.id));
        (_b = viewModel.state.query) === null || _b === void 0 ? void 0 : _b.parameters.changeTempTeamIDs(selectedTeams.map((it) => it.id));
        (_c = viewModel.state.query) === null || _c === void 0 ? void 0 : _c.parameters.changeTempProtocolNames(selectedProtocolNames);
        onFilterSave();
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        React.createElement(Autocomplete, { multiple: true, size: "small", options: allFilters.users, getOptionLabel: (option) => option.name, disableCloseOnSelect: true, value: selectedUsers, onChange: (_, value) => setSelectedUsers(value), renderInput: (props) => (React.createElement(TextField, Object.assign({}, props, { label: t('transcriptSearchApp:user', 'User') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.name)) }),
        React.createElement(Autocomplete, { multiple: true, size: "small", options: allFilters.teams, getOptionLabel: (option) => option.name, disableCloseOnSelect: true, value: selectedTeams, onChange: (_, value) => setSelectedTeams(value), renderInput: (props) => (React.createElement(TextField, Object.assign({}, props, { label: t('transcriptSearchApp:team', 'Team') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.name)) }),
        React.createElement(Autocomplete, { multiple: true, size: "small", options: allFilters.protocolNames, disableCloseOnSelect: true, value: selectedProtocolNames, onChange: (_, value) => setSelectedProtocolNames(value), renderInput: (props) => (React.createElement(TextField, Object.assign({}, props, { label: t('transcriptSearchApp:protocolName', 'Protocol Name') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option }), option)) }),
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
            React.createElement(Button, { color: "primary", onClick: handleSavingFilters }, t('done', 'Done')))));
}
