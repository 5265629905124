import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
const initialState = {
    isEditMode: false,
    isDraft: false,
    toggleEditMode: () => undefined,
};
const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
    const { pathname } = useLocation();
    const isDraft = pathname.includes('/draft');
    const [isEditMode, setIsEditMode] = useState(initialState.isEditMode);
    const toggleEditMode = () => setIsEditMode((current) => !current);
    const value = React.useMemo(() => ({
        isEditMode,
        isDraft,
        toggleEditMode,
    }), [isEditMode, isDraft]);
    return React.createElement(GlobalContext.Provider, { value: value }, children);
};
export const useGlobalContext = () => {
    const context = React.useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalContextProvider');
    }
    return context;
};
