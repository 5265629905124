import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
export function SplitButton({ options, defaultIndex = 0, groupLabel = 'Button group', dropdownLabel = 'Select an option', color = 'primary', variant = 'contained', disabled = false, dropdownClickHandler, }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
    const handleClick = (e) => options[selectedIndex].onClick(e);
    const handleMenuItemClick = (index) => {
        setSelectedIndex(index);
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const menuId = useMemo(() => `split-button-menu-${Math.random().toString(36).slice(2)}`, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonGroup, { color: color, variant: variant, ref: anchorRef, "aria-label": groupLabel },
            React.createElement(Button, { color: color, variant: variant, onClick: handleClick, disabled: disabled }, options[selectedIndex].label),
            React.createElement(Button, { color: color, size: "small", "aria-controls": open ? menuId : undefined, "aria-expanded": open ? 'true' : undefined, "aria-label": dropdownLabel, "aria-haspopup": "menu", onClick: dropdownClickHandler !== null && dropdownClickHandler !== void 0 ? dropdownClickHandler : handleToggle },
                React.createElement(ArrowDropDown, null))),
        React.createElement(Popper, { sx: {
                zIndex: 1,
            }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true }, ({ TransitionProps, placement }) => (React.createElement(Grow, Object.assign({}, TransitionProps, { style: {
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            } }),
            React.createElement(Paper, null,
                React.createElement(ClickAwayListener, { onClickAway: handleClose },
                    React.createElement(MenuList, { id: menuId, autoFocusItem: true }, options.map((option, index) => (React.createElement(MenuItem, { key: option.label, selected: index === selectedIndex, onClick: () => handleMenuItemClick(index) }, option.label)))))))))));
}
