var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Base } from 'lib/cortiUI/components/Base';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { Tooltip } from 'lib/cortiUI/components/Tooltip';
export function SidebarActionButton(props) {
    const { icon, tooltipTitle, disabled, onClick } = props, rest = __rest(props, ["icon", "tooltipTitle", "disabled", "onClick"]);
    return (React.createElement(Tooltip, { title: tooltipTitle, disableInteractive: true },
        React.createElement(Base, null,
            React.createElement(IconButton, Object.assign({ size: "small", icon: icon, onClick: onClick, disabled: disabled }, rest)))));
}
