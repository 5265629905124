import { Dexie } from '@corti/lib/indexedDB/dexie';
export let appDB;
export async function init() {
    if (appDB) {
        return;
    }
    appDB = new AppDB();
    await appDB.open();
}
export class AppDB {
    constructor() {
        Object.defineProperty(this, "db", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "organization", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "users", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timelineEntryDefinitions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.db = new Dexie('AppDB');
        this.db.version(1).stores({
            organization: 'id',
            currentUser: 'id',
            users: 'id',
            timelineEntryDefinitions: 'id',
        });
        this.organization = this.db.table('organization');
        this.currentUser = this.db.table('currentUser');
        this.users = this.db.table('users');
        this.timelineEntryDefinitions = this.db.table('timelineEntryDefinitions');
    }
    async open() {
        await this.db.open();
        return;
    }
    hasFailed() {
        return this.db.hasFailed();
    }
}
