import UploadRounded from '@mui/icons-material/UploadRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { coreStore } from 'browser/stores';
import { Loading, MenuItem, Typography } from 'lib/cortiUI';
export const ExportAction = ({ feedbackFormTemplateID, onClose }) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'templatesDataGrid' });
    const [isLoading, setIsLoading] = React.useState(false);
    const handleExport = async () => {
        try {
            setIsLoading(true);
            const { title, anonymizeReviewers, fields } = await api.caseFeedback.getFeedbackFormTemplateByID(feedbackFormTemplateID);
            setIsLoading(false);
            const data = {
                title,
                anonymizeReviewers,
                fields: Object.values(fields).map((field) => {
                    var _a;
                    const data = {
                        id: field.id,
                        title: field.title,
                        type: field.type === 'action-select' || field.type === 'protocol-select'
                            ? 'short-text'
                            : field.type,
                        required: field.required,
                        visibleIf: field.visibleIf,
                    };
                    if (field.visibleIf) {
                        const { junction, conditions } = field.visibleIf;
                        const visibleIfObject = {
                            junction,
                            conditions: conditions.map((i) => ({
                                fieldID: i.fieldID,
                                comparison: i.comparison,
                                values: i.values,
                            })),
                        };
                        data.visibleIf = visibleIfObject;
                    }
                    if (field.type === 'dropdown' || field.type === 'radio') {
                        data.choices = ((_a = field === null || field === void 0 ? void 0 : field.choices) !== null && _a !== void 0 ? _a : []).map(({ value, text }) => ({ value, text }));
                    }
                    return data;
                }),
            };
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
            const link = document.createElement('a');
            link.href = jsonString;
            link.download = `${title}.json`;
            link.click();
        }
        catch (error) {
            setIsLoading(false);
            coreStore.notifications.showNotification({
                message: "Couldn't export the template",
                type: 'error',
            });
        }
        onClose();
    };
    return (React.createElement(MenuItem, { onClick: () => handleExport(), gap: 3, "data-cy": "export-template-btn" }, isLoading ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
        React.createElement(UploadRounded, { color: "action" }),
        React.createElement(Typography, { variant: "body1" }, t('showMore.export', 'Export'))))));
};
