import { Dexie } from '@corti/lib/indexedDB/dexie';
const LAST_USED_TRIAGE_SETTINGS_ID = 'last-used-triage-settings';
export let protocolGraphDB;
export async function init() {
    protocolGraphDB = new ProtocolGraphDB();
    await protocolGraphDB.open();
}
export class ProtocolGraphDB {
    constructor() {
        Object.defineProperty(this, "dexie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "triageSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.dexie = new Dexie('ProtocolGraphDB');
        // https://dexie.org/docs/Tutorial/Design#database-versioning
        this.dexie.version(1).stores({ graphs: 'id', triageSettings: 'id' });
        this.graphs = this.dexie.table('graphs');
        this.triageSettings = this.dexie.table('triageSettings');
    }
    async open() {
        await this.dexie.open();
        return;
    }
    hasFailed() {
        return this.dexie.hasFailed();
    }
    async putGraph(id, data) {
        await this.graphs.put({
            id,
            data,
        });
    }
    async getGraphByID(id) {
        const res = await this.graphs.get(id);
        return res === null || res === void 0 ? void 0 : res.data;
    }
    async getAllGraphs() {
        const res = await this.graphs.toArray();
        return res.map((it) => it.data);
    }
    async deleteAllGraphs() {
        await this.graphs.clear();
    }
    async putLatestTriageSettings(settings) {
        await this.triageSettings.put({ id: LAST_USED_TRIAGE_SETTINGS_ID, settings });
    }
    async getLatestTriageSettings() {
        const r = await this.triageSettings.get(LAST_USED_TRIAGE_SETTINGS_ID);
        return r === null || r === void 0 ? void 0 : r.settings;
    }
}
