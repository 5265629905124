import React from 'react';
import { useWavesurferContext } from 'browser/components/WaveSurfer/WaveSurferContext';
// @ts-ignore - keeping `minimap.js` in our source code until a patch is released to a minimap plugin's code
import MinimapPluginInternal from './minimap';
export function MinimapPlugin(props) {
    const { container, waveColor = '#777', progressColor = '#222', height = 50, overviewBackgroundColor = 'rgba(255,255,255,0.15)', overviewBorderSize = 1, overviewBorderColor = 'rgba(255,255,255,0.25)', } = props;
    const { wavesurfer } = useWavesurferContext();
    let pluginInstance = wavesurfer.minimap;
    React.useEffect(() => {
        wavesurfer.addPlugin(MinimapPluginInternal.create({
            container,
            waveColor,
            progressColor,
            height,
            overviewBorderSize,
            overviewBorderColor,
            showOverview: true,
        }));
        wavesurfer.initPlugin('minimap');
        // eslint-disable-next-line react-hooks/exhaustive-deps
        pluginInstance = wavesurfer.minimap;
        updateStyles();
        return () => {
            try {
                wavesurfer.destroyPlugin('minimap');
            }
            catch (_a) { }
        };
    }, []);
    React.useEffect(() => {
        if (pluginInstance) {
            // Wafesurfer doesnt have ref to our React RegionPlugin instance so
            // ws Minimap doesnt know that regions exist. In order to render our React Regions
            // in ws Minimap we have to manually set regions to Minimap instance and force rerendering using its API.
            pluginInstance.regions = {};
            const transformRegionEvent = (e) => {
                return {
                    id: e.id,
                    start: e.start,
                    end: e.end || e.start,
                    color: e.backgroundColor,
                };
            };
            const onRegionLoaded = (e) => {
                pluginInstance.regions[e.id] = transformRegionEvent(e);
                pluginInstance.renderRegions();
            };
            const onRegionUpdate = (e) => {
                pluginInstance.regions[e.id] = Object.assign(Object.assign({}, transformRegionEvent(e)), { color: pluginInstance.regions[e.id].color });
                pluginInstance.renderRegions();
            };
            const onRegionRemoved = (e) => {
                delete pluginInstance.regions[e.id];
                pluginInstance.renderRegions();
            };
            wavesurfer.on('region-added', onRegionLoaded);
            wavesurfer.on('region-update', onRegionUpdate);
            wavesurfer.on('region-removed', onRegionRemoved);
            return () => {
                wavesurfer.un('region-added', onRegionLoaded);
                wavesurfer.un('region-update', onRegionUpdate);
                wavesurfer.un('region-removed', onRegionRemoved);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        updateStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overviewBackgroundColor, overviewBorderSize, overviewBorderColor]);
    const updateStyles = () => {
        if (pluginInstance && pluginInstance.overviewRegion) {
            const $overviewRegion = pluginInstance.overviewRegion;
            $overviewRegion.style.backgroundColor = overviewBackgroundColor;
            $overviewRegion.style.borderWidth = `${overviewBorderSize}px`;
            $overviewRegion.style.borderColor = overviewBorderColor;
        }
    };
    return null;
}
