var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from '@apollo/client';
import { DoDisturbRounded } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Base, Button, ConfirmableTarget, Skeleton, Tooltip, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { useDataContext } from '../../../activeView/context/dataContext';
import { getPerformanceViewMetricsGroupByCallQuery, getPerformanceViewMetricsQuery, } from '../../../activeView/context/dataContext/graphql';
import { ViewTitle } from '../../components/ViewTitle';
import { setCallExcludedFromPerformanceMetricsMutation, } from './graphql';
export function Header(props) {
    const { selectedCalls, onSelectedCallsClear } = props;
    const { t } = useTranslation('performanceApp', { keyPrefix: 'callsTable' });
    const { organization } = useAuth();
    const _a = useParametersContext(), { dateRange } = _a, _b = _a.appliedFilters, { trackers } = _b, filter = __rest(_b, ["trackers"]);
    const { isPerformanceViewMetricsLoading, totalNumberOfUsersCalls } = useDataContext();
    const [excludeCallFromPerformanceMetrics, { loading: isExcludeCallFromPerformanceMetricsLoading },] = useMutation(setCallExcludedFromPerformanceMetricsMutation, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: getPerformanceViewMetricsQuery,
                variables: {
                    organizationID: organization.id,
                    startTime: dateRange.startDate,
                    endTime: dateRange.endDate,
                    filter,
                    sources: trackers,
                },
            },
            {
                query: getPerformanceViewMetricsGroupByCallQuery,
                variables: {
                    organizationID: organization.id,
                    startTime: dateRange.startDate,
                    endTime: dateRange.endDate,
                    filter,
                    sources: trackers,
                },
            },
        ],
    });
    const handleExcludeCalls = async () => {
        if (!selectedCalls.length)
            return;
        await Promise.all(selectedCalls.map((callID) => excludeCallFromPerformanceMetrics({
            variables: { callID: callID, excluded: true },
        })));
        coreStore.notifications.showNotification({
            message: t('successMessages.excluded', {
                count: selectedCalls.length,
                defaultValue_one: `{{count}} call excluded from performance tracking`,
                defaultValue_other: `{{count}} calls excluded from performance tracking`,
            }),
            type: 'success',
        });
    };
    return (React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Base, { margin: 1, className: css({ fontVariantNumeric: 'tabular-nums' }) },
            React.createElement(ViewTitle, { title: selectedCalls.length > 0
                    ? t('selectedCallsCount', {
                        count: selectedCalls.length,
                        defaultValue: `{{count}} call`,
                        defaultValue_other: `{{count}} calls`,
                    })
                    : t('calls', 'Calls') })),
        selectedCalls.length > 0 ? (React.createElement(Base, { display: "flex", gap: 4 },
            React.createElement(Button, { onClick: onSelectedCallsClear, color: "primary", size: "small", disabled: isExcludeCallFromPerformanceMetricsLoading, variant: "outlined" }, t('clearSelection', 'Clear selection')),
            React.createElement(ConfirmableTarget, { message: t('warningOnExclude', 'This action will exclude the selected calls from performance tracking. You can still find all calls in the Explore page.'), onConfirm: handleExcludeCalls, color: "error" }, ({ onClick }) => (React.createElement(Tooltip, { placement: "top", title: t('warningOnExclude', 'This action will exclude the selected calls from performance tracking. You can still find all calls in the Explore page.') },
                React.createElement(Base, null,
                    React.createElement(Button, { onClick: onClick, color: "primary", size: "small", disabled: isExcludeCallFromPerformanceMetricsLoading, startIcon: React.createElement(DoDisturbRounded, null) }, t('excludeCall', 'Exclude from performance tracking')))))))) : isPerformanceViewMetricsLoading ? (React.createElement(Skeleton, { variant: "text", width: "10%" })) : (React.createElement(Typography, { ml: "auto", variant: "caption", className: css({ fontVariantNumeric: 'tabular-nums' }) }, t('callsCount', {
            count: totalNumberOfUsersCalls,
            defaultValue_one: `{{count}} result found`,
            defaultValue_other: `{{count}} results found`,
        })))));
}
