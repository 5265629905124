var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { Observer } from '@corti/observer';
export class Comments {
    constructor(input) {
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.logger = input.logger;
        this.observer = new Observer();
        runInAction(() => {
            this.all = input.state.comments;
            this.totalCount = input.state.totalCommentsCount;
        });
    }
    reset(state) {
        var _a, _b;
        this.all = (_a = state.comments) !== null && _a !== void 0 ? _a : this.all;
        this.totalCount = (_b = state.totalCommentsCount) !== null && _b !== void 0 ? _b : this.totalCount;
    }
    create(comment) {
        var _a;
        if (this.getByID(comment.id)) {
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.warn('comment already exists');
            return;
        }
        this.all.push(comment);
        this.totalCount = this.totalCount + 1;
        this.observer.fireEvent('comment-created', comment);
    }
    getByID(commentID) {
        return this.all.find((it) => it.id === commentID);
    }
    // TODO: delete
    setComments(comments) {
        this.all = comments;
    }
    // TODO: delete
    setTotalCommentsCount(count) {
        this.totalCount = count;
    }
    serialize() {
        return cloneDeep({
            comments: toJS(this.all),
            totalCommentsCount: toJS(this.totalCount),
        });
    }
    onCreate(cb) {
        return this.observer.on('comment-created', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Comments.prototype, "all", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Comments.prototype, "totalCount", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], Comments.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Comments.prototype, "create", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Comments.prototype, "setComments", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], Comments.prototype, "setTotalCommentsCount", null);
