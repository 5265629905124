var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Link, Route } from 'react-router-dom';
import { css } from '@corti/style';
export const UnstyledLink = (props) => {
    var _a;
    const { to, activeOnlyWhenExact = false, disabled = false, className, children, render } = props, anchorElProps = __rest(props, ["to", "activeOnlyWhenExact", "disabled", "className", "children", "render"]);
    const renderFn = (_a = props.render) !== null && _a !== void 0 ? _a : props.children;
    if (render != null && children != null) {
        throw new Error('Provide either render or children prop, not both');
    }
    if (!renderFn) {
        throw new Error('Provide either render or children prop.');
    }
    return (React.createElement(Route, { path: typeof to === 'string' ? to : to.pathname, exact: activeOnlyWhenExact, children: ({ match }) => (React.createElement(Link, Object.assign({}, anchorElProps, { className: css({ pointerEvents: disabled ? 'none' : undefined }, className), to: to }), renderFn({ isActive: !!match }))) }));
};
