import { orderBy } from 'lodash';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { useDidMount } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, FocusContext, Typography } from 'lib/cortiUI';
import { EntityFactory } from '../../utils';
import { useTriageSession } from '../TriageSessionContext';
import { ReceivedTakeoverRequestControls } from './ReceivedTakeoverRequestControls';
import { SentTakeoverRequestControls } from './SentTakeoverRequestControls';
import { UserItem } from './UserItem';
export const ConnectedUsers = observer(function ConnectedUsers() {
    const didMount = useDidMount();
    const theme = useTheme();
    const ctx = useTriageSession();
    const focusController = React.useRef(null);
    const spectators = ctx.controller.connectedUsers.all.filter((user) => {
        return user.id !== ctx.controller.ownerUser.id;
    });
    React.useEffect(() => {
        var _a;
        if (!didMount) {
            (_a = focusController.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusController.current]);
    return (React.createElement(FocusContext, { controllerRef: focusController }, ({ registerKeyboardEventBindingElement }) => (React.createElement(Observer, null, () => (React.createElement(Base, { "data-cy": "users-menu", ref: registerKeyboardEventBindingElement, width: 360, maxHeight: 400, className: css(getScrollerCss({ theme }), {
            display: 'grid',
            padding: '16px 24px',
            gap: theme.space[6],
        }) },
        React.createElement(Section, { title: intl.t('realtime:sessionOwner', 'Owner') },
            React.createElement(UserItem, { "data-cy": "users:owner-item", user: ctx.controller.ownerUser, action: ctx.controller.settings.isTakeoverEnabled ? (React.createElement(SentTakeoverRequestControls, { title: ctx.controller.takeoverRequests.sent.length > 0
                        ? intl.t('realtime:requested', 'Requested')
                        : intl.t('realtime:takeover', 'Takeover'), isHidden: ctx.controller.isCurrentUserOwner, isDisabled: ctx.controller.takeoverRequests.sent.length > 0, onClick: () => {
                        ctx.controller.takeoverRequests.create(EntityFactory.createTakeoverRequest(ctx.controller.currentUser.id, ctx.controller.currentUser));
                    } })) : undefined })),
        ctx.controller.takeoverRequests.received.length > 0 && (React.createElement(Section, { title: intl.t('realtime:sessionOwnershipRequests', 'Takeover Requests') },
            React.createElement(Base, { display: "grid", gap: 6 }, orderBy(ctx.controller.takeoverRequests.received).map((r) => (React.createElement(Observer, { key: r.id }, () => (React.createElement(UserItem, { "data-cy": "users:received-request-item", key: r.id, user: ctx.controller.connectedUsers.all.find((u) => u.id === r.createdBy.id), action: ctx.controller.settings.isTakeoverEnabled ? (React.createElement(ReceivedTakeoverRequestControls, { acceptTitle: intl.t('realtime:accept', 'Accept'), declineTitle: intl.t('realtime:decline', 'Decline'), onAccept: () => ctx.controller.takeoverRequests.accept(r.id), onDecline: () => ctx.controller.takeoverRequests.decline(r.id) })) : undefined })))))))),
        spectators.length > 0 && (React.createElement(Section, { title: intl.t('realtime:sessionSpectators', 'Spectators') },
            React.createElement(Base, { display: "grid", gap: 6 }, orderBy(spectators).map((s) => (React.createElement(Observer, { key: s.id }, () => (React.createElement(UserItem, { "data-cy": "users:spectator-item", key: s.id, user: s, action: ctx.controller.settings.isTakeoverEnabled ? (React.createElement(SentTakeoverRequestControls, { title: ctx.controller.takeoverRequests.sent.find((it) => it.newOwnerUserID === s.id)
                        ? intl.t('realtime:requested', 'Requested')
                        : intl.t('realtime:handover', 'Handover'), isHidden: !ctx.controller.isCurrentUserOwner, isDisabled: ctx.controller.takeoverRequests.sent.length > 0, onClick: () => {
                        ctx.controller.takeoverRequests.create(EntityFactory.createTakeoverRequest(s.id, ctx.controller.currentUser));
                    } })) : undefined }))))))))))))));
});
export const Section = ({ title, children }) => {
    return (React.createElement(Base, { display: "flex", flexDirection: "column" },
        React.createElement(Typography, { mb: 3, color: 'secondaryText', fontSize: 13 }, title),
        children));
};
