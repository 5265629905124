import React from 'react';
import { useLazyRef } from '@corti/react';
import { FocusContextController } from './FocusContextController';
import { FocusContextRegistry } from './FocusContextRegistry';
import { useGroupedFocusContext } from './GroupedFocusContext';
const Context = React.createContext({});
export function useFocusContext() {
    return React.useContext(Context);
}
/**
 * Wrappers that enable focus navigation between UI components using keyboard shortcuts
 */
export function FocusContext(props) {
    const { disableKeyboardShortcuts, keyboardEventBindingElement, keyboardShortcuts, controllerRef, onElementBlur, onElementFocus, onElementTrigger, children, } = props;
    const globalCtx = useGroupedFocusContext();
    const registry = useLazyRef(() => new FocusContextRegistry());
    const ctx = useLazyRef(() => {
        return {
            registry: registry.current,
            controller: new FocusContextController(registry.current, keyboardShortcuts),
        };
    });
    React.useEffect(() => {
        const controllerRef = ctx.current.controller;
        return () => {
            controllerRef.destroy();
        };
    }, [ctx]);
    React.useEffect(() => {
        const controllerRef = ctx.current.controller;
        const globalCtxRef = globalCtx;
        globalCtxRef === null || globalCtxRef === void 0 ? void 0 : globalCtxRef.registerController(controllerRef);
        return () => {
            globalCtxRef === null || globalCtxRef === void 0 ? void 0 : globalCtxRef.unregisterController(controllerRef.id);
        };
    }, [ctx, globalCtx]);
    React.useEffect(() => {
        const ref = ctx.current.controller;
        if (controllerRef) {
            if (typeof controllerRef === 'function') {
                controllerRef(ref);
            }
            else {
                controllerRef.current = ref;
            }
        }
        const subs = [
            ref.observer.on('element-focused', (data) => {
                onElementFocus === null || onElementFocus === void 0 ? void 0 : onElementFocus(data);
            }),
            ref.observer.on('element-blurred', (data) => {
                onElementBlur === null || onElementBlur === void 0 ? void 0 : onElementBlur(data);
            }),
            ref.observer.on('focused-element-triggered', (data) => {
                onElementTrigger === null || onElementTrigger === void 0 ? void 0 : onElementTrigger(data);
            }),
        ];
        return () => {
            subs.forEach((it) => it());
        };
    }, [controllerRef, onElementBlur, onElementFocus, onElementTrigger, ctx]);
    React.useEffect(() => {
        if (keyboardEventBindingElement !== undefined) {
            ctx.current.controller.setKeyboardEventBindingElement(keyboardEventBindingElement);
        }
    }, [keyboardEventBindingElement, ctx]);
    React.useEffect(() => {
        const controllerRef = ctx.current.controller;
        if (!disableKeyboardShortcuts) {
            controllerRef.enableKeyboardShortcuts();
        }
        return () => {
            controllerRef.disableKeyboardShortcuts();
        };
    }, [disableKeyboardShortcuts, ctx]);
    function renderChildren() {
        if (typeof children === 'function') {
            return children({
                registerKeyboardEventBindingElement(el) {
                    ctx.current.controller.setKeyboardEventBindingElement(el);
                },
            });
        }
        return children;
    }
    return React.createElement(Context.Provider, { value: ctx.current }, renderChildren());
}
