import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { Base, IconButton, Menu } from 'lib/cortiUI';
import { CopyAction } from './CopyAction';
import { DeleteAction } from './DeleteAction';
import { ExportAction } from './ExportAction';
export const ShowMoreAction = ({ feedbackFormTemplateID }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const handleOpenMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    return (React.createElement(Base, null,
        React.createElement(IconButton, { "data-cy": "open-menu-button", onClick: handleOpenMenu, icon: React.createElement(MoreHorizIcon, null) }),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: handleCloseMenu, anchorEl: menuAnchorEl },
            React.createElement(ExportAction, { feedbackFormTemplateID: feedbackFormTemplateID, onClose: handleCloseMenu }),
            React.createElement(CopyAction, { feedbackFormTemplateID: feedbackFormTemplateID }),
            React.createElement(DeleteAction, { feedbackFormTemplateID: feedbackFormTemplateID, onClose: handleCloseMenu }))));
};
