import styled from '@emotion/styled';
import React from 'react';
import { Progress } from '../checklist/Checklist';
import { Subsection } from '../general';
const TitleInput = styled.input `
  font: inherit;
  background-image: inherit;
  background-clip: inherit;
  text-fill-color: inherit;

  border: 0;
  background: transparent;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
`;
export function EditableChecklist({ name, namePlaceholder, entries, isSelected, handleSelect, handleChange, children, }) {
    return (React.createElement(Subsection, { color: isSelected ? 'primary' : 'inactive', title: React.createElement(TitleInput, { value: name, name: "name", placeholder: namePlaceholder, onChange: handleChange, autoComplete: "off", "data-1p-ignore": true }), postfix: React.createElement(Progress, null, `0 / ${entries}`), onClick: handleSelect, cardSx: { height: '100%' } }, children));
}
