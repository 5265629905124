import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Base } from 'lib/cortiUI';
import { App } from './App';
const SKIP_UPDATE_CHECK_PARAM = 'skipupdatecheck';
export function AppRoot({ swWrapper }) {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [skipUpdateCheck] = React.useState(() => {
        const _skipUpdateCheckUrlParam = searchParams.get(SKIP_UPDATE_CHECK_PARAM);
        return _skipUpdateCheckUrlParam === 'true';
    });
    const [hasRemovedSearchParam, setHasRemovedSearchParam] = React.useState(false);
    const [noUpdateFound, setNoUpdateFound] = React.useState(null);
    const [isRestarting, setIsRestarting] = React.useState(false);
    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has(SKIP_UPDATE_CHECK_PARAM)) {
            params.delete(SKIP_UPDATE_CHECK_PARAM);
            history.replace({ search: params.toString() });
        }
        else {
            setHasRemovedSearchParam(true);
        }
    }, [history, location]);
    // whatch for SW controller changes and refresh the app when new one activates
    // this is here instead of refreshing after the update check
    // because sw can be updated from different places, including the dev tools
    React.useEffect(() => {
        if (!swWrapper) {
            return;
        }
        const u = swWrapper.onNewController(() => {
            const url = new URL(window.location.href);
            // no need to check for updates here when reloading
            // because the reason of reload is that new SW is already active
            url.searchParams.append(SKIP_UPDATE_CHECK_PARAM, 'true');
            // prevents a flash of an old version, since changing location is async
            setIsRestarting(true);
            window.location.href = url.href;
        });
        return () => {
            u();
        };
    }, [swWrapper]);
    function renderContent() {
        // cannot render app until hasn't removed the param
        // otherwise it clashes with the auth redirect params and one starts overriding another
        if (!hasRemovedSearchParam) {
            return null;
        }
        if (!swWrapper || skipUpdateCheck || !navigator.serviceWorker.controller || noUpdateFound) {
            // refreshing the browser is async operation
            // so we want to show a different screen whilst the app is being restarted
            if (isRestarting) {
                return React.createElement(CortiLaunchScreen, null);
            }
            return React.createElement(App, { swWrapper: swWrapper });
        }
        return (React.createElement(UpdateCheckScreen, { swWrapper: swWrapper, onNoUpdateFound: () => {
                setNoUpdateFound(true);
            } }));
    }
    return (React.createElement(Base, { "data-cy": "app-root", height: '100vh' }, renderContent()));
}
function UpdateCheckScreen({ swWrapper, onNoUpdateFound, }) {
    const [state, setState] = React.useState(swWrapper.currentState());
    React.useEffect(() => {
        void checkForUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        const u = swWrapper.onStateChange((e) => {
            setState(e.type);
        });
        return () => {
            u();
        };
    }, [swWrapper]);
    React.useEffect(() => {
        if (state === 'waiting') {
            swWrapper.skipWaiting();
        }
        if (state === 'noupdate' || state === 'update-check-failed') {
            onNoUpdateFound();
        }
    }, [state, swWrapper, onNoUpdateFound]);
    async function checkForUpdate() {
        await swWrapper.checkForUpdate();
    }
    function getStatusText() {
        switch (state) {
            // in case of `active` we are still checking for update
            case 'active':
            case 'checking-for-update':
                return 'checking for updates';
            case 'noupdate':
                return 'up to date';
            case 'update-check-failed':
                return 'update check failed';
            case 'installing':
                return 'installing';
            case 'waiting':
                return 'update installed, restarting';
            default:
                return '';
        }
    }
    return React.createElement(CortiLaunchScreen, { statusText: getStatusText() });
}
function CortiLaunchScreen({ statusText }) {
    return (React.createElement("div", { style: {
            position: 'relative',
            maxWidth: '460px',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '50vh',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            padding: '16px',
            color: 'black',
        } },
        React.createElement("span", { style: { fontSize: 32 } }, 'Corti'),
        React.createElement("div", { style: { height: 30 } }, statusText)));
}
