import React from 'react';
import { api } from '@corti/lib/coreApiService';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { buildSortedSessionsMap } from '../utils';
export function useFetchSessionsWithCalls(realtime) {
    const auth = useAuth();
    const cachedSessions = React.useRef([]);
    const [sortedSessions, setSortedSessions] = React.useState(new Map());
    const handleSessions = React.useCallback((items) => {
        const sessionsWithCall = items.map((session) => {
            var _a;
            return Object.assign(Object.assign({}, session), { call: (_a = session.call) !== null && _a !== void 0 ? _a : { number: '', active: false } });
        });
        const newSortedSessions = buildSortedSessionsMap(sessionsWithCall, !rbacService.hasPermission('app:protocol_graph_editor'));
        setSortedSessions(newSortedSessions);
    }, []);
    const fetchSessions = React.useCallback(async (cachedItems, offset) => {
        const { items } = await api.triage
            .getUsersRecentSessionsWithCalls(auth.organization.id, auth.currentUserID, offset, 20)
            .catch(() => {
            console.error('Error fetching sessions');
            return { items: [] };
        });
        const filteredCachedSessions = items.length > 0
            ? cachedItems.filter((item) => !items.map((s) => s.id).includes(item.id))
            : cachedItems;
        const mergedSessions = [...items, ...filteredCachedSessions]
            .sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
            .slice(0, 20);
        if (mergedSessions.length > 0) {
            handleSessions(mergedSessions);
        }
        return;
    }, [auth, handleSessions]);
    React.useEffect(() => {
        realtime.recentTriageSessionRepository
            .getSessions()
            .then((items) => {
            cachedSessions.current = items;
            return fetchSessions(items);
        })
            .catch((err) => {
            var _a;
            (_a = realtime.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Failed to get recent sessions`, String(err));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realtime]);
    // Handle updating the session list when a new session is created in realtime
    realtime.onTriageSessionCreatedListener = (session) => {
        const renderedSessions = Array.from(sortedSessions.values()).flat();
        handleSessions([session, ...renderedSessions]);
    };
    return sortedSessions;
}
