import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { DataGrid } from 'lib/cortiUI';
import { getColumns } from './columns';
export const MembersDataGrid = ({ members }) => {
    var _a;
    const { t } = useTranslation('teams');
    const { isFeatureEnabled } = useAuth();
    function getRows() {
        return members.map((it) => ({
            id: it.id,
            user: it.user.name,
            archived: Boolean(it.user.deletedAt)
                ? t('teamView.table.statusDeleted', 'Archived')
                : undefined,
            role: it.teamRole === 'LEAD' ? t('teamView.table.role.teamLead', 'Team Lead') : undefined,
            actionToolbar: it,
        }));
    }
    return (React.createElement(DataGrid, { disableColumnFilter: true, disableColumnMenu: true, disableSelectionOnClick: true, columns: getColumns(), rows: (_a = getRows()) !== null && _a !== void 0 ? _a : [], columnVisibilityModel: {
            role: isFeatureEnabled('mission-control-team-role'),
            actionToolbar: rbacService.teamsCRUD(),
        }, initialState: {
            sorting: {
                sortModel: [{ field: 'user', sort: 'asc' }],
            },
        }, sx: {
            border: 'hidden',
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },
        } }));
};
