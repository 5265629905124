import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Base } from 'lib/cortiUI';
export const ResizeObserverContainer = ({ children }) => {
    const containerSizeObserver = React.useRef();
    const containerRef = React.useRef(null);
    React.useEffect(() => {
        return () => {
            var _a;
            (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
            containerSizeObserver.current = undefined;
        };
    }, []);
    function initContainerSizeObserver(chart) {
        var _a, _b;
        (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        containerSizeObserver.current = undefined;
        containerSizeObserver.current = new ResizeObserver(() => chart.reflow());
        if (containerRef.current) {
            (_b = containerSizeObserver.current) === null || _b === void 0 ? void 0 : _b.observe(containerRef.current);
        }
    }
    function setContainerRef(ref) {
        var _a, _b;
        if (containerRef.current) {
            (_a = containerSizeObserver.current) === null || _a === void 0 ? void 0 : _a.unobserve(containerRef.current);
        }
        containerRef.current = ref;
        if (containerRef.current) {
            (_b = containerSizeObserver.current) === null || _b === void 0 ? void 0 : _b.observe(containerRef.current);
        }
    }
    return (React.createElement(Base, { ref: setContainerRef, height: "100%", width: "100%", display: "grid", gridTemplateRows: "auto 1fr", gridGap: 6, "data-cy": "chart-widget" }, children(initContainerSizeObserver)));
};
