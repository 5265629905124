import parse from 'semver/functions/parse';
export function isCompatible(input) {
    const { expected, actual } = input;
    if (expected === actual) {
        return true;
    }
    const required = parse(expected);
    const real = parse(actual);
    if (!required || !real) {
        return false;
    }
    if (required.major !== real.major) {
        return false;
    }
    return real.minor >= required.minor;
}
