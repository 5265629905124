import React from 'react';
/**
 * OuterElementContext is used to pass additional props from the parent component
 * to the outer element of the VariableSizeList. This is helpful when you need
 * to customize or style the outer element based on props that are not directly
 * used by the VariableSizeList itself.
 */
const OuterElementContext = React.createContext({});
export const OuterElementProvider = OuterElementContext.Provider;
export const useOuterElementContext = () => {
    if (OuterElementContext === undefined) {
        throw new Error('useOuterElementContext must be used within a OuterElementContext.Provider');
    }
    return React.useContext(OuterElementContext);
};
