import React from 'react';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { Autocomplete, MenuItem, TextField } from 'lib/cortiUI';
export function CallSwitcher(props) {
    const { caseModel } = props;
    const generateItemText = (call) => {
        const dispatcher = call.user && call.user.name
            ? call.user.name
            : intl.t('unknownDispatcher', 'Unknown dispatcher');
        const callDate = formatDateTime(call.start);
        return `${dispatcher} / ${callDate}`;
    };
    const items = caseModel.allCalls.map((call) => {
        return {
            text: generateItemText(call),
            value: call.id,
        };
    });
    const getSelectedValue = () => {
        const { activeCall } = caseModel;
        if (activeCall) {
            return items.find((i) => i.value === activeCall.id);
        }
    };
    const onValueChange = (val) => {
        caseModel.setActiveCall(String(val.value));
    };
    return (React.createElement(Autocomplete, { "data-cy": "call-switcher", size: "small", options: items, getOptionLabel: (option) => option.text, value: getSelectedValue(), onChange: (_, option) => {
            if (option) {
                onValueChange(option);
            }
        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: intl.t('explore:caseView.selectCall', 'Select call...') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }), option.text)), clearIcon: null }));
}
