import { intl } from '@corti/i18n';
export function getCommon() {
    const commonColumnsDictionary = {
        caseID: {
            id: 'caseID',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseID', 'Case ID'),
        },
        caseCreatedBy: {
            id: 'caseCreatedBy',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseCreatedBy', 'Created By'),
        },
        caseStartedAt: {
            id: 'caseStartedAt',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseStartedAt', 'Started At'),
        },
        caseEndedAt: {
            id: 'caseEndedAt',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseEndedAt', 'Ended At'),
        },
        caseUpdatedAt: {
            id: 'caseUpdatedAt',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseUpdatedAt', 'Last Updated At'),
        },
        caseTags: {
            id: 'caseTags',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseTags', 'Tags'),
        },
        caseReviewedBy: {
            id: 'caseReviewedBy',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseReviewedBy', 'Case Reviewed By'),
        },
        sessionCreatedBy: {
            id: 'sessionCreatedBy',
            entityType: 'session',
            label: intl.t('explore:casesTableEditorView.columns.sessionCreatedBy', 'Users'),
        },
        sessionExternalID: {
            id: 'sessionExternalID',
            entityType: 'session',
            label: intl.t('explore:casesTableEditorView.columns.sessionExternalID', 'External ID'),
        },
        callUserExtension: {
            id: 'callUserExtension',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callUserExtension', 'Call Extension'),
        },
        callExternalID: {
            id: 'callExternalID',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callExternalID', 'Call External ID'),
        },
        profilePhone: {
            id: 'profilePhone',
            entityType: 'profile',
            label: intl.t('explore:casesTableEditorView.columns.profilePhone', 'Caller Number'),
        },
        profileAddress: {
            id: 'profileAddress',
            entityType: 'profile',
            label: intl.t('explore:casesTableEditorView.columns.profileAddress', 'Address'),
        },
        caseReviewType: {
            id: 'caseReviewType',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseReviewType', 'Case Review Type'),
        },
        caseHasReviewAcknowledged: {
            id: 'caseHasReviewAcknowledged',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseHasReviewAcknowledged', 'Case Has Review Acknowledged'),
        },
        caseAssignedTo: {
            id: 'caseAssignedTo',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseAssignedTo', 'Case Assigned To'),
        },
        calledParty: {
            id: 'calledParty',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.calledParty', 'Called Party'),
        },
        callingParty: {
            id: 'callingParty',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callingParty', 'Calling Party'),
        },
        protocolName: {
            id: 'protocolName',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.protocolName', 'Protocol Name'),
        },
        caseDuration: {
            id: 'caseDuration',
            entityType: 'case',
            label: intl.t('explore:casesTableEditorView.columns.caseDuration', 'Total Duration'),
        },
        performanceMetricsExcluded: {
            id: 'performanceMetricsExcluded',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.performanceMetricsExcluded', 'Excluded From Performance Tracking'),
        },
        callParticipants: {
            id: 'callParticipants',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callParticipants', 'Participants'),
        },
        callProperties: {
            id: 'callProperties',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callProperties', 'Call Properties'),
        },
        callSource: {
            id: 'callSource',
            entityType: 'call',
            label: intl.t('explore:casesTableEditorView.columns.callSource', 'Call Source'),
        },
    };
    return commonColumnsDictionary;
}
