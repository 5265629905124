import { gql } from '@apollo/client';
export const getTeamsQuery = gql `
  query Teams($organizationID: String!) {
    teams(organizationID: $organizationID) {
      id
      name
      members {
        user {
          id
        }
      }
    }
  }
`;
export const getCallsQuery = gql `
  query Calls($id: [String!]!) {
    calls(id: $id) {
      id
      callServiceCallId
      start
      protocolName
      case {
        id
      }
      user {
        name
      }
    }
  }
`;
