import { intl } from '@corti/i18n';
import { coreStore } from 'browser/stores';
export function showCoreApiErrorNotification(err) {
    switch (err.code) {
        case 'schema-mismatch': {
            coreStore.notifications.showNotification({
                type: 'error',
                message: intl.t('remoteServerSchemaMismatchErrorMsg', 'Client is incompatible with the server'),
                detailText: err.devMessage,
                closable: true,
            });
            return;
        }
        case 'internal': {
            coreStore.notifications.showNotification({
                type: 'error',
                message: err.message || intl.t('remoteServerErrorMsg', 'Something went wrong'),
                detailText: err.devMessage,
                closable: true,
            });
            return;
        }
        case 'unauthorized':
        case 'unavailable': {
            return;
        }
    }
}
