var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/**
 * Utility component which allows to render a custom component instead of a default `<input />` field
 */
export function FileInput(props) {
    const { onSelectFiles, children } = props, restInputProps = __rest(props, ["onSelectFiles", "children"]);
    const $input = React.useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", Object.assign({ ref: $input, style: { display: 'none' }, type: "file", onChange: (e) => {
                var _a, _b;
                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
                if ((_b = e.target.files) === null || _b === void 0 ? void 0 : _b.length) {
                    onSelectFiles === null || onSelectFiles === void 0 ? void 0 : onSelectFiles(Array.from(e.target.files));
                    // clear files array right after they have been given to the receiver
                    e.target.value = '';
                }
            } }, restInputProps)),
        props.children({
            selectFiles: () => {
                $input.current.click();
            },
        })));
}
