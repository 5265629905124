import { gql } from '@apollo/client';
export const QUERIES = {
    GET_ORGANIZATION_NAME: gql `
    query getOrganization($orgID: ID!) {
      organization: organization(id: $orgID) {
        name
        id
      }
    }
  `,
    GET_ORGANIZATION: gql `
    query getOrganization($orgID: ID!) {
      organization: organization(id: $orgID) {
        name
        id
        clientSettings
        ssoEnabled
        features {
          featureID
          translation
        }
        organizationSecuritySettings {
          oldPasswordRequiredForPasswordReset
          passwordRequirements {
            minLength
            numberOfLowercase
            numberOfCapitals
            numberOfNumbers
            numberOfSymbols
          }
        }
        triageSettings {
          automaticProgressToNextStep
        }
      }
    }
  `,
};
export const MUTATIONS = {
    DELETE_ORG_FEATURES: gql `
    mutation deleteOrgFeatures($orgID: ID!, $features: [FeatureID!]!) {
      deleteOrganizationFeatures(organizationID: $orgID, featureIDs: $features)
    }
  `,
    ENSURE_ORGANIZATION_FEATURES: gql `
    mutation ensureOrganizationFeatures($orgID: ID!, $featureIDs: [FeatureID!]!) {
      ensureOrganizationFeatures(organizationID: $orgID, featureIDs: $featureIDs) {
        featureID
        translation
      }
    }
  `,
    TOGGLE_SSO_ENABLED: gql `
    mutation updateOrganization($organizationID: ID!, $ssoEnabled: Boolean!) {
      updateOrganization(organizationID: $organizationID, ssoEnabled: $ssoEnabled) {
        id
        ssoEnabled
      }
    }
  `,
};
