import { IPC_LOGGER_CHANNEL } from '@corti/logger';
import { callMain } from './simpleIpc';
/**
 * Represents a transport handler for sending structured messages using IPC in the renderer process.
 */
export class RendererIpcLoggerTransport {
    send(msg) {
        void callMain(IPC_LOGGER_CHANNEL, msg);
    }
}
