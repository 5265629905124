import { gql } from '@apollo/client';
const caseFragmentSimple = gql `
  fragment CaseFragmentSimple on Case {
    id
    startedAt
    endedAt
    createdBy {
      id
      name
    }
    customProperties
    tags {
      type
      value
      style {
        color
      }
    }
  }
`;
export const timelineEntryFragment = gql `
  fragment TimelineEntryFragment on TimelineEntry {
    sourceID
    definition {
      source {
        type
        entityType
        entityID
      }
      title
      text
      isEntryCreatable
      style {
        color
      }
    }
    startDateTime
    endDateTime
    callID
    text
    mods
    createdAt
    createdBy {
      name
    }
    root {
      createdAt
    }
  }
`;
const feedbackFormSubmissionSimpleFragment = gql `
  fragment FeedbackFormSubmissionSimple on FeedbackFormSubmission {
    id
    createdAt
    createdBy {
      id
      name
    }
    dispatcherAcknowledgedAt
    template {
      title
    }
  }
`;
const CasesFilterAttributeDescriptorFieldFragment = gql `
  fragment CasesFilterAttributeDescriptorField on CasesFilterAttributeDescriptor {
    id
    text
    valueType
    comparisons
  }
`;
const CasesFilterOptionDescriptorFieldFragment = gql `
  fragment CasesFilterOptionDescriptorField on CasesFilterOptionDescriptor {
    value
    text
  }
`;
const getCasesFilterDescriptor = gql `
  query casesFilterDescriptor($organizationID: ID!) {
    casesFilterDescriptor(organizationID: $organizationID) {
      entities {
        id
        text
        attributes {
          ...CasesFilterAttributeDescriptorField
          options {
            ...CasesFilterOptionDescriptorField
            attributes {
              ...CasesFilterAttributeDescriptorField
              options {
                ...CasesFilterOptionDescriptorField
              }
            }
          }
        }
      }
    }
  }
  ${CasesFilterAttributeDescriptorFieldFragment}
  ${CasesFilterOptionDescriptorFieldFragment}
`;
export const caseService = (client, restClient) => ({
    async getCasesForTable(input, config) {
        const res = await client.query({
            query: gql `
        query getCases(
          $organizationID: ID!
          $cursor: String
          $first: Int
          $filter: CasesFilterInput
        ) {
          cases(organizationID: $organizationID, cursor: $cursor, first: $first, filter: $filter) {
            cursor
            hasNextPage
            items {
              id
              startedAt @include(if:${config.caseStartedAt})
              endedAt @include(if:${config.caseEndedAt})
              updatedAt @include(if:${config.caseUpdatedAt})
              createdBy @include(if:${config.caseCreatedBy}) {
                id
                name
              }
              tags @include(if:${config.caseTags}) {
                type
                value
                style {
                  color
                }
              }
              calls @include(if:${config.callUserExtension ||
                config.calledParty ||
                config.callingParty ||
                config.protocolName ||
                config.callExternalID ||
                config.caseDuration ||
                config.callTags ||
                config.callParticipants ||
                config.callProperties ||
                config.callSource}) {
                user @include(if:${config.callUserExtension}) {
                  extension
                }
                calledParty @include(if:${config.calledParty})
                callingParty @include(if:${config.callingParty})
                protocolName @include(if:${config.protocolName})
                externalID @include(if:${config.callExternalID})
                start @include(if:${config.caseDuration})
                stop @include(if:${config.caseDuration})
                tags @include(if:${config.callTags})
                participants @include(if:${config.callParticipants}) {
                  id
                  name
                }
                properties @include(if:${config.callProperties}) {
                  name
                  value
                }
                callSource @include(if:${config.callSource})
              }
              triageSessions @include(if:${config.sessionCreatedBy || config.sessionExternalID}) {
                externalID @include(if:${config.sessionExternalID})
                createdBy @include(if:${config.sessionCreatedBy}) {
                  id
                  name
                }
              }
              profiles @include(if:${config.profileAddress || config.profilePhone}) {
                address @include(if:${config.profileAddress})
                phone @include(if:${config.profilePhone})
              }
              feedbackFormSubmissions @include(if:${config.caseReviewedBy || config.caseReviewType || config.caseHasReviewAcknowledged}) {
                createdBy @include(if:${config.caseReviewedBy}) {
                  id
                  name
                }
                template @include(if:${config.caseReviewType}) {
                  id
                  title
                }
                dispatcherAcknowledgedAt @include(if:${config.caseHasReviewAcknowledged})
              }
              reviewRequests @include(if:${config.caseAssignedTo}){
                assignee {
                  isAnonymised
                  user {
                    id
                    name
                  }
                }
              }
              customProperties @include(if:${config.customProperties})
            }
          }
        }
      `,
            variables: Object.assign({}, input),
            fetchPolicy: 'no-cache',
        });
        return res.data.cases;
    },
    async getCaseForPreview(id, config) {
        const res = await client.query({
            query: gql `
        query getCase($id: String!) {
          case(id: $id) {
            ...CaseFragmentSimple
            calls {
              id
              start
              stop
              user {
                name
              }
              tags
              protocolName
              externalID
              transcript @include(if:${config.transcript})
            }
            reviewRequests {
              id
              assignee {
                isAnonymised
                user {
                  id
                  name
                }
              }
              status
              suggestedTemplate {
                id
              }
            }
            feedbackFormSubmissions {
              ...FeedbackFormSubmissionSimple
            }
            profiles {
              address
              ageIsExact
              ageUnit
              ageValue
              gender
              phone
            }
          }
        }
        ${caseFragmentSimple}
        ${feedbackFormSubmissionSimpleFragment}
      `,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.case;
    },
    async getCaseForMerge(id) {
        const res = await client.query({
            query: gql `
        query getCase($id: String!) {
          case(id: $id) {
            id
            startedAt
            createdBy {
              id
              name
            }
            customProperties
          }
        }
      `,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.case;
    },
    async getCasesFilterDescriptor(organizationID) {
        const response = await client.query({
            query: getCasesFilterDescriptor,
            variables: { organizationID },
            fetchPolicy: 'no-cache',
        });
        return response.data.casesFilterDescriptor;
    },
    async getTimelineEntries(caseID) {
        const res = await client.query({
            query: gql `
        query getTimelineEntries($caseID: String!) {
          case(id: $caseID) {
            timelineEntries {
              ...TimelineEntryFragment
            }
          }
        }
        ${timelineEntryFragment}
      `,
            variables: { caseID },
            fetchPolicy: 'no-cache',
        });
        return res.data.case.timelineEntries;
    },
    async createTimelineEntry(organizationID, entry, parentSourceID) {
        const res = await client.mutate({
            mutation: gql `
        mutation createTimelineEntry(
          $organizationID: ID!
          $parentSourceID: String
          $entry: TimelineEntryInput!
        ) {
          createTimelineEntry(
            organizationID: $organizationID
            parentSourceID: $parentSourceID
            entry: $entry
          ) {
            ...TimelineEntryFragment
          }
        }
        ${timelineEntryFragment}
      `,
            variables: {
                organizationID,
                parentSourceID,
                entry,
            },
        });
        return res.data.createTimelineEntry;
    },
    async deleteTimelineEntry(input) {
        await client.mutate({
            mutation: gql `
        mutation deleteTimelineEntry(
          $organizationID: ID!
          $sourceID: String!
          $definitionSource: TimelineEntryDefinitionSourceInput!
        ) {
          deleteTimelineEntry(
            organizationID: $organizationID
            sourceID: $sourceID
            definitionSource: $definitionSource
          )
        }
      `,
            variables: {
                organizationID: input.organizationID,
                sourceID: input.sourceID,
                definitionSource: input.definitionSource,
            },
        });
    },
    async exportCasesCsvFile(input) {
        const url = '/exports/cases';
        const res = await restClient.axiosClient({
            method: 'post',
            url,
            data: {
                organization_id: input.organizationId,
                filter: input.filter,
                time_zone: input.timeZone,
            },
        });
        return res.data;
    },
    async exportEventsCsvFile(input) {
        const url = '/exports/events';
        const res = await restClient.axiosClient({
            method: 'post',
            url,
            data: {
                organization_id: input.organizationId,
                filter: input.filter,
            },
        });
        return res.data;
    },
    async ensureCustomProperties(caseID, customProperties) {
        const res = await client.mutate({
            mutation: gql `
        mutation ensureCaseCustomProperties($caseID: String!, $customProperties: StringMap!) {
          ensureCaseCustomProperties(caseID: $caseID, customProperties: $customProperties)
        }
      `,
            variables: {
                caseID,
                customProperties,
            },
        });
        return res.data.ensureCaseCustomProperties;
    },
    async getCustomPropertyKeys(organizationID) {
        const res = await client.query({
            query: gql `
        query caseCustomPropertyKeys($organizationID: ID!) {
          caseCustomPropertyKeys(organizationID: $organizationID)
        }
      `,
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.caseCustomPropertyKeys;
    },
    async mergeCases(input) {
        await client.mutate({
            mutation: gql `
        mutation mergeCases($parentCaseID: String!, $childCaseID: String!) {
          mergeCases(parentCaseID: $parentCaseID, childCaseID: $childCaseID)
        }
      `,
            variables: {
                parentCaseID: input.parentCaseId,
                childCaseID: input.childCaseId,
            },
        });
    },
    async setCallExcludedFromPerformanceMetrics(input) {
        await client.mutate({
            mutation: gql `
        mutation setCallExcludedFromPerformanceMetrics($callID: String!, $excluded: Boolean!) {
          setCallExcludedFromPerformanceMetrics(callID: $callID, excluded: $excluded)
        }
      `,
            variables: input,
        });
    },
});
