import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import React from 'react';
import { css, darken, readableColor } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card, Typography } from 'lib/cortiUI';
function getReadableTextColor(backgroundColor) {
    return readableColor(darken(0.3, backgroundColor), 'rgba(0, 0, 0, 1)', 'rgba(255, 255, 255, 1)');
}
// IMPORTANT!
// All dynamically changing styles should be defined inline.
// Component is rendered in a separate window so dynamically
// generated styles from a classname wont be applied to the element.
export function AlertView(props) {
    const theme = useTheme();
    const { thumbnail, title, text, color, backgroundColor = theme.palette.background.card, onActionClick, } = props;
    return (React.createElement(Card, { p: 0, gap: 5, borderRadius: 0, width: "100%", height: "100%", display: "grid", border: "none", gridTemplateColumns: "1fr auto", style: {
            backgroundColor: backgroundColor,
        } },
        React.createElement(Base, { gap: 5, display: "grid", gridTemplateColumns: "auto 1fr", className: css({ WebkitAppRegion: 'drag' }) },
            React.createElement(Base, { py: 4, pl: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }, thumbnail),
            React.createElement(Base, { width: "100%", py: 2, flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center", style: {
                    color: color !== null && color !== void 0 ? color : getReadableTextColor(backgroundColor),
                } },
                React.createElement(Typography, { variant: "body1", noSelect: true, fontWeight: "500" }, title),
                text && (React.createElement(Typography, { variant: "body2", noSelect: true, sx: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                    } }, text)))),
        React.createElement(Base, { "data-cy": "alert-widget-action", px: 5, display: "flex", alignItems: "center", justifyContent: "center", style: {
                color: getReadableTextColor(backgroundColor),
                cursor: 'pointer',
            }, className: css({
                cursor: 'pointer',
                ':hover': {
                    backgroundColor: theme.palette.action.hover,
                },
            }), onClick: onActionClick },
            React.createElement(LinkRoundedIcon, { fontSize: "small", color: "inherit", className: css({ cursor: 'inherit' }) }))));
}
