import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Dialog, Loading, MenuItem, Typography } from 'lib/cortiUI';
import { deleteTeamMemberMutation, getTeamQuery, } from '../../graphql';
export const RemoveMember = ({ teamID, memberID, onRemove }) => {
    const { t } = useTranslation('teams');
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteTeamMember, deleteTeamMemberResult] = useMutation(deleteTeamMemberMutation, {
        variables: { id: memberID },
    });
    async function handleDelete() {
        await deleteTeamMember({
            refetchQueries: [
                {
                    query: getTeamQuery,
                    variables: {
                        id: teamID,
                    },
                },
            ],
        });
        onRemove();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: () => setIsDialogOpen(true), gap: 3, disabled: deleteTeamMemberResult.loading }, deleteTeamMemberResult.loading ? (React.createElement(Loading, null)) : (React.createElement(Typography, { "data-cy": "remove-team-member-btn", variant: "body1" }, t('teamView.label', 'Remove from team')))),
        React.createElement(Dialog, { "data-cy": "remove-team-member-dialog", title: t('teamView.removeUserView.title', 'Remove user?'), helperText: t('removeUserView.message', 'You are about to remove a user from the team. This will affect all related places where the team is being used. The action cannot be undone.'), open: isDialogOpen, onClose: () => setIsDialogOpen(false), actionButtons: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", onClick: () => setIsDialogOpen(false) }, t('teamView.removeUserView.cancelBtn', 'Cancel')),
                React.createElement(Button, { variant: "contained", color: "error", onClick: handleDelete }, t('teamView.removeUserView.removeBtn', 'Remove'))) })));
};
