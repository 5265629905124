export const theme = {
    palette: {
        primary: {
            light: '#6A9FFF',
            main: '#548AFF',
            dark: '#206cc6',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FF4081',
            main: '#F50057',
            dark: '#C51162',
            contrastText: '#fff',
        },
        success: {
            light: '#4caf50',
            main: '#2e7d32',
            dark: '#1b5e20',
            contrastText: '#fff',
        },
        error: {
            light: '#ef5350',
            main: '#d32f2f',
            dark: '#c62828',
            contrastText: '#fff',
        },
        warning: {
            light: '#FF9800',
            main: '#ED6C02',
            dark: '#E65100',
            contrastText: '#fff',
        },
        info: {
            light: '#03a9f4',
            main: '#0288d1',
            dark: '#01579b',
            contrastText: '#fff',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
        },
        background: {
            default: '#fafafa',
            secondary: '#FDFDFD',
            grid: '#FFE5E5',
            card: '#fff',
            tooltip: '#000',
            snackbar: '#323232',
            divider: 'rgba(0, 0, 0, 0.12)',
            paper: '#fff',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            hover: 'rgba(0, 0, 0, 0.04)',
            selected: 'rgba(0, 0, 0, 0.08)',
            focus: 'rgba(0, 0, 0, 0.12)',
            outlinedBorder: 'rgba(0, 0, 0, 0.23)',
            outlinedHoverBorder: 'rgba(0, 0, 0, 0.87)',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            hint: 'rgba(0, 0, 0, 0.5)',
            inverted: 'rgba(255, 255, 255, 1)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            success: '#2e7d32',
            error: '#d32f2f',
            warning: '#ed6c02',
        },
        mode: 'light',
    },
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    space: [0, 2, 4, 6, 8, 12, 16, 24, 32, 48, 64],
    shadows: [
        'none',
        '0px 1px 6px rgba(24, 24, 43, 0.02),0px 2px 16px rgba(0, 0, 0, 0.02)',
        '0px 1.1739px 6px rgba(24, 24, 43, 0.0265),0px 2.0109px 16.3478px rgba(0, 0, 0, 0.0265)',
        '0px 1.3478px 6px rgba(24, 24, 43, 0.033),0px 2.0217px 16.6957px rgba(0, 0, 0, 0.033)',
        '0px 1.5217px 5px rgba(24, 24, 43, 0.0396),0px 2.0326px 17.0435px rgba(0, 0, 0, 0.0396)',
        '0px 1.6957px 5px rgba(24, 24, 43, 0.0461),0px 2.0435px 17.3913px rgba(0, 0, 0, 0.0461)',
        '0px 1.8696px 5px rgba(24, 24, 43, 0.0526),0px 2.0543px 17.7391px rgba(0, 0, 0, 0.0526)',
        '0px 2.0435px 5px rgba(24, 24, 43, 0.0591),0px 2.0652px 18.087px rgba(0, 0, 0, 0.0591)',
        '0px 2.2174px 4px rgba(24, 24, 43, 0.0657),0px 2.0761px 18.4348px rgba(0, 0, 0, 0.0657)',
        '0px 2.3913px 4px rgba(24, 24, 43, 0.0722),0px 2.087px 18.7826px rgba(0, 0, 0, 0.0722)',
        '0px 2.5652px 4px rgba(24, 24, 43, 0.0787),0px 2.0978px 19.1304px rgba(0, 0, 0, 0.0787)',
        '0px 2.7391px 4px rgba(24, 24, 43, 0.0852),0px 2.1087px 19.4783px rgba(0, 0, 0, 0.0852)',
        '0px 2.913px 4px rgba(24, 24, 43, 0.0917),0px 2.1196px 19.8261px rgba(0, 0, 0, 0.0917)',
        '0px 3.087px 3px rgba(24, 24, 43, 0.0983),0px 2.1304px 20.1739px rgba(0, 0, 0, 0.0983)',
        '0px 3.2609px 3px rgba(24, 24, 43, 0.1048),0px 2.1413px 20.5217px rgba(0, 0, 0, 0.1048)',
        '0px 3.4348px 3px rgba(24, 24, 43, 0.1113),0px 2.1522px 20.8696px rgba(0, 0, 0, 0.1113)',
        '0px 3.6087px 3px rgba(24, 24, 43, 0.1178),0px 2.163px 21.2174px rgba(0, 0, 0, 0.1178)',
        '0px 3.7826px 3px rgba(24, 24, 43, 0.1243),0px 2.1739px 21.5652px rgba(0, 0, 0, 0.1243)',
        '0px 3.9565px 2px rgba(24, 24, 43, 0.1309),0px 2.1848px 21.913px rgba(0, 0, 0, 0.1309)',
        '0px 4.1304px 2px rgba(24, 24, 43, 0.1374),0px 2.1957px 22.2609px rgba(0, 0, 0, 0.1374)',
        '0px 4.3043px 2px rgba(24, 24, 43, 0.1439),0px 2.2065px 22.6087px rgba(0, 0, 0, 0.1439)',
        '0px 4.4783px 2px rgba(24, 24, 43, 0.1504),0px 2.2174px 22.9565px rgba(0, 0, 0, 0.1504)',
        '0px 4.6522px 1px rgba(24, 24, 43, 0.157),0px 2.2283px 23.3043px rgba(0, 0, 0, 0.157)',
        '0px 4.8261px 1px rgba(24, 24, 43, 0.1635),0px 2.2391px 23.6522px rgba(0, 0, 0, 0.1635)',
        '0px 5px 1px rgba(24, 24, 43, 0.17),0px 2.25px 24px rgba(0, 0, 0, 0.17)',
    ],
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        popover: 1350,
        snackbar: 1400,
        tooltip: 1500,
    },
};
