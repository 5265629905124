export const DEFAULT_AVATAR_COLORS = [
    '#2A5BF4',
    '#009d77',
    '#00a4a1',
    '#4a2bc2',
    '#d3543f',
    '#c13578',
    '#4a8d2f',
    '#2c2fa0',
    '#187c8e',
];
export const DEFAULT_AVATAR_BG_COLORS = [
    '#C5D2FC',
    '#B7E9D8',
    '#B8EBEA',
    '#CEC1ED',
    '#F4D3CF',
    '#F2C7D3',
    '#CDE5C4',
    '#C4C7ED',
    '#C2DDE1',
];
export function getInitials(title) {
    title = title.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    const names = title.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}
export function getColor(str) {
    let index = 0;
    if (str) {
        index = str.length % DEFAULT_AVATAR_COLORS.length;
    }
    return { color: DEFAULT_AVATAR_COLORS[index], bgColor: DEFAULT_AVATAR_BG_COLORS[index] };
}
export function getSize(size) {
    switch (size) {
        case 'tiny': {
            return 24;
        }
        case 'small': {
            return 32;
        }
        case 'regular': {
            return 48;
        }
        case 'large': {
            return 64;
        }
        default: {
            throw new Error(`Invalid size: ${size}`);
        }
    }
}
