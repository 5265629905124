import { gql } from '@apollo/client';
const timelineEntryDefinitionSourceFragment = gql `
  fragment TimelineEntryDefinitionSource on TimelineEntryDefinitionSource {
    type
    entityType
    entityID
    isDefinitionCreatable
  }
`;
const timelineEntryDefinitionFragment = gql `
  fragment TimelineEntryDefinitionFragment on TimelineEntryDefinition {
    source {
      ...TimelineEntryDefinitionSource
    }
    title
    text
    isEntryCreatable
    isObvious
    mods
    keywords
    style {
      color
    }
  }
  ${timelineEntryDefinitionSourceFragment}
`;
export const QUERIES = {
    GET_ALL_DEFINITIONS: gql `
    query getTimelineEntryDefinitions($organizationID: ID!) {
      timelineEntryDefinitions(organizationID: $organizationID) {
        ...TimelineEntryDefinitionFragment
      }
    }
    ${timelineEntryDefinitionFragment}
  `,
    GET_SOURCES: gql `
    query getTimelineEntryDefinitionsSources($organizationID: ID!) {
      timelineEntryDefinitionSources(organizationID: $organizationID) {
        ...TimelineEntryDefinitionSource
      }
    }
    ${timelineEntryDefinitionSourceFragment}
  `,
};
export const MUTATIONS = {
    CREATE: gql `
    mutation createTimelineEntryDefinition(
      $definition: TimelineEntryDefinitionInput!
      $organizationID: ID!
    ) {
      createTimelineEntryDefinition(definition: $definition, organizationID: $organizationID) {
        ...TimelineEntryDefinitionFragment
      }
    }
    ${timelineEntryDefinitionFragment}
  `,
    UPDATE: gql `
    mutation updateTimelineEntryDefinition(
      $definition: TimelineEntryDefinitionInput!
      $organizationID: ID!
    ) {
      updateTimelineEntryDefinition(definition: $definition, organizationID: $organizationID) {
        ...TimelineEntryDefinitionFragment
      }
    }
    ${timelineEntryDefinitionFragment}
  `,
    DELETE: gql `
    mutation deleteTimelineEntryDefinition(
      $definition: TimelineEntryDefinitionSourceInput!
      $organizationID: ID!
    ) {
      deleteTimelineEntryDefinition(definition: $definition, organizationID: $organizationID)
    }
  `,
};
