import { getReadableCollectorItems } from '../../models/CollectorModel/utils';
export const FactValueTransformer = {
    toExternal(value) {
        return String(value);
    },
    toInternal(value) {
        return ['true', 'false'].includes(value) ? value === 'true' : value;
    },
};
export const StateSerializer = {
    serializeActionBlockTriggered(state) {
        return {
            event: 'action-block-triggered',
            data: {
                datetime: new Date().toISOString(),
                triggeredBy: state.triggeredBy,
                block: {
                    id: state.blockPrototype.id,
                    customProperties: state.blockPrototype.customProperties,
                },
            },
        };
    },
    serializeStepAdded(state) {
        return {
            event: 'step-added',
            data: {
                datetime: new Date().toISOString(),
                step: state.step.serialize(),
            },
        };
    },
    serializeActiveStepChanged(state) {
        return {
            event: 'active-step-changed',
            data: {
                datetime: new Date().toISOString(),
                stepID: state.step.id,
            },
        };
    },
    serializeFactValuesUpdated(state) {
        return {
            event: 'fact-values-updated',
            data: {
                datetime: new Date().toISOString(),
                factValues: state.factValues.map((it) => (Object.assign(Object.assign({}, it), { value: FactValueTransformer.toExternal(it.value) }))),
            },
        };
    },
    serializeBlockValuesUpdated(state) {
        return {
            event: 'block-values-updated',
            data: {
                datetime: new Date().toISOString(),
                blockValues: state.blockValues.map((it) => ({
                    identifier: {
                        blockID: it.identifier.blockPrototypeID,
                        optionID: it.identifier.optionID,
                    },
                    value: it.value,
                })),
            },
        };
    },
    serializeValueCollectorBlockValuesUpdated(state, flowStore) {
        return {
            event: 'value-collector-block-values-updated',
            data: {
                datetime: new Date().toISOString(),
                blockID: state.collector.blockInstance.id,
                blockPrototypeID: state.collector.blockInstance.blockPrototype.id,
                displayValues: getReadableCollectorItems(state.collector, flowStore).map((it) => ({
                    text: it.text,
                })),
                items: state.collector.items.map((it) => {
                    switch (it.valueType) {
                        case 'block': {
                            return {
                                id: it.id,
                                valueType: 'block',
                                values: it.values.map((v) => ({
                                    blockID: v.identifier.blockPrototypeID,
                                    optionID: v.identifier.optionID,
                                    value: v.value,
                                })),
                            };
                        }
                        case 'block-option': {
                            return {
                                id: it.id,
                                valueType: 'block-option',
                                values: it.values.map((v) => ({
                                    blockID: v.identifier.blockPrototypeID,
                                    optionID: v.identifier.optionID,
                                    value: v.value,
                                })),
                            };
                        }
                        case 'fact': {
                            return {
                                id: it.id,
                                valueType: 'fact',
                                values: it.values.map((it) => (Object.assign(Object.assign({}, it), { value: FactValueTransformer.toExternal(it.value) }))),
                            };
                        }
                        case 'custom': {
                            return {
                                id: it.id,
                                valueType: 'custom',
                                values: it.values.map((v) => ({
                                    value: v.value,
                                })),
                            };
                        }
                    }
                }),
            },
        };
    },
    serializeChecklistUpdated(state) {
        return {
            event: 'checklist-updated',
            data: {
                datetime: new Date().toISOString(),
                checklistId: state.checklistId,
                expanded: state.expanded,
            },
        };
    },
    serializeChecklistEntryUpdated(state) {
        return {
            event: 'checklist-entry-updated',
            data: {
                datetime: new Date().toISOString(),
                checklistId: state.checklistId,
                entryId: state.entryId,
                checked: state.checked,
                source: state.source,
            },
        };
    },
};
