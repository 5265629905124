import DeleteRounded from '@mui/icons-material/DeleteRounded';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from 'lib/cortiUI';
import { CY_SELECTORS, getConditionChoiceValueTitle, getConditionQuestionTitle } from '../utils';
import { ConfirmableAction } from './ConfirmableAction';
export const Condition = ({ condition, title, conditionField, questionsOptions, onChange, onRemove }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    const CONDITION_COMPARISON_ITEMS = [
        {
            value: 'eq',
            label: '=',
        },
        { value: 'neq', label: '!=' },
    ];
    const getDefaultTitle = (field) => {
        const idx = questionsOptions.reduce((idx, { value }, i) => {
            if (field.id === value) {
                idx = i;
            }
            return idx;
        }, -1);
        return idx > -1 ? getConditionQuestionTitle(idx) : 'Question with no title';
    };
    const conditionQuestionOption = conditionField
        ? {
            label: conditionField.title ? conditionField.title : getDefaultTitle(conditionField),
            value: conditionField.id,
        }
        : { label: condition.fieldID, value: condition.fieldID };
    const handleQuestionChange = (_, option) => {
        if (!option)
            return;
        onChange(Object.assign(Object.assign({}, condition), { fieldID: option.value, values: [] }));
    };
    const handleComparisonChange = (_, value) => {
        onChange(Object.assign(Object.assign({}, condition), { comparison: value }));
    };
    const handleTextValueChange = ({ target }) => {
        onChange(Object.assign(Object.assign({}, condition), { values: [target.value] }));
    };
    const handleOptionValueChange = (_, option) => {
        if (!option)
            return;
        onChange(Object.assign(Object.assign({}, condition), { values: [option] }));
    };
    const handleRemoveCondition = () => onRemove(condition.id);
    const renderValueField = () => {
        var _a, _b;
        if ((conditionField === null || conditionField === void 0 ? void 0 : conditionField.type) === 'radio' || (conditionField === null || conditionField === void 0 ? void 0 : conditionField.type) === 'dropdown') {
            const options = (_b = (_a = conditionField.choices) === null || _a === void 0 ? void 0 : _a.map((choice) => ({
                label: choice.text,
                value: choice.value,
            }))) !== null && _b !== void 0 ? _b : [];
            const isDisabled = options.some((option) => !option.label);
            const getOptionValue = () => {
                var _a;
                const conditionValue = condition.values[0];
                if (conditionValue) {
                    const optionIndex = (_a = conditionField.choices) === null || _a === void 0 ? void 0 : _a.findIndex((choice) => choice.value === conditionValue.value);
                    if (conditionValue.label) {
                        return conditionValue;
                    }
                    else {
                        return Object.assign(Object.assign({}, conditionValue), { label: getConditionChoiceValueTitle(optionIndex !== null && optionIndex !== void 0 ? optionIndex : 0) });
                    }
                }
                return { label: '', value: '' };
            };
            return (React.createElement(Autocomplete, { size: "small", sx: { flex: 1, mr: 5 }, disabled: isDisabled, options: options, value: getOptionValue(), getOptionLabel: (option) => option.label, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: handleOptionValueChange, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('visibleIfWidget.fieldValue', 'Value'), required: true, helperText: isDisabled
                        ? t('visibleIfWidget.noOptionLabel', "Some options' inputs don't have value. Please, fill them in before setting a condition.")
                        : undefined }))), clearIcon: null, "data-cy": CY_SELECTORS.visibleIfWidget.conditionValueSelect }));
        }
        return (React.createElement(TextField, { required: true, sx: { flex: 1, mr: 3 }, error: !condition.values[0], label: t('visibleIfWidget.fieldValue', 'Value'), value: condition.values[0] ? condition.values[0] : '', onChange: handleTextValueChange, size: "small", inputProps: { 'data-cy': CY_SELECTORS.visibleIfWidget.conditionValueTextInput } }));
    };
    return (React.createElement(Base, { m: 4, p: 2, "data-cy": CY_SELECTORS.visibleIfWidget.conditionWrapper },
        React.createElement(Typography, { variant: "subtitle2", color: "default", mb: 7 }, title),
        React.createElement(Base, { display: "flex", alignItems: "flex-start" },
            React.createElement(Autocomplete, { size: "small", sx: { flex: 1, mr: 5 }, options: questionsOptions, getOptionLabel: (option) => option.label, isOptionEqualToValue: (option, value) => isEqual(option, value), value: conditionQuestionOption, onChange: handleQuestionChange, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('visibleIfWidget.fieldID', 'Question'), inputProps: Object.assign(Object.assign({}, params.inputProps), { 'data-cy': CY_SELECTORS.visibleIfWidget.conditionQuestionTextField }) }))), clearIcon: null, "data-cy": CY_SELECTORS.visibleIfWidget.conditionQuestionSelect }),
            React.createElement(ToggleButtonGroup, { "aria-label": "Radio group", value: condition.comparison, exclusive: true, sx: { mr: 5 }, size: "medium", onChange: handleComparisonChange, "data-cy": CY_SELECTORS.visibleIfWidget.conditionEqualityWrapper }, CONDITION_COMPARISON_ITEMS.map((i) => (React.createElement(ToggleButton, { key: i.value, value: i.value, "data-cy": i.value === 'eq'
                    ? CY_SELECTORS.visibleIfWidget.conditionEqualsBtn
                    : CY_SELECTORS.visibleIfWidget.conditionNotEqualBtn }, i.label)))),
            renderValueField(),
            React.createElement(ConfirmableAction, { condition: Boolean(condition.values[0]) || condition.fieldID !== questionsOptions[0].value, message: t('actionsWarnings.delete', 'Are you sure you would like to delete this item?'), onConfirm: handleRemoveCondition, renderClickableComponent: (onClick) => (React.createElement(Tooltip, { title: t('actionsHints.remove', 'Remove') },
                    React.createElement(IconButton, { onClick: onClick, size: "small", icon: React.createElement(DeleteRounded, { fontSize: "small" }), "data-cy": CY_SELECTORS.visibleIfWidget.removeConditionBtn }))) }))));
};
