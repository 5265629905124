import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, DefaultCellRenderer, DefaultRowRenderer, Input, InputAdornment, Table, TableColumn, Typography, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { FactStatic } from 'lib/graphEditor/facts';
import { AttributeList, AttributeRendererEnum, AttributeRendererStatic, AttributeRendererString, GenericPanel, GenericPanelContent, GenericPanelHeader, Sidepanel, TwoColGridLayout, getUIStateStorage, setUIStateStorage, useGraphEditorStorage, } from 'lib/graphEditor/ui';
import { ValuePublisherConfig } from 'lib/graphEditor/valuePublisher/view';
export const FactLibraryView = observer(() => {
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    const tableRef = React.useRef(null);
    const scrollTopRef = React.useRef(0);
    const [searchInputValue, setSearchInputValue] = useGraphEditorStorage('', 'FactLibraryView-searchInputValue');
    const [selectedFactID, setSelectedFactID] = useGraphEditorStorage(null, 'FactLibraryView-selectedItem');
    const localStore = useLocalObservable(() => ({}));
    React.useEffect(() => {
        var _a;
        (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.scrollToPosition(getUIStateStorage(editor, 'FactLibraryView-scrollTop'));
        return () => {
            setUIStateStorage(editor, 'FactLibraryView-scrollTop', scrollTopRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function cancelTempFact() {
        runInAction(() => {
            localStore.tempFact = undefined;
        });
    }
    function getOrderedRows() {
        const allRows = [];
        Array.from(editor.model.facts.items.values()).forEach((it) => {
            if (stringIncludes(it.name + it.id, searchInputValue)) {
                allRows.push({
                    id: it.id,
                    name: it.name,
                    type: it.type,
                    itemModel: it,
                });
            }
        });
        if (localStore.tempFact) {
            const t = localStore.tempFact;
            allRows.unshift({
                id: t.id,
                name: t.id,
                type: t.type,
                itemModel: localStore.tempFact,
            });
        }
        return allRows;
    }
    const selectedFact = localStore.tempFact
        ? localStore.tempFact
        : selectedFactID
            ? editor.model.facts.getFactByID(selectedFactID)
            : undefined;
    return (React.createElement(TwoColGridLayout, null,
        React.createElement(Base, { background: theme.palette.background.secondary, overflow: "hidden" },
            React.createElement(Base, { p: 5, display: "grid", gridTemplateColumns: "1fr max-content", gridGap: 3 },
                React.createElement(Input, { fullWidth: true, type: "search", placeholder: t('factLibraryView.searchInputPlaceholder', 'Search facts by ID or name'), value: searchInputValue, startAdornment: React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.hint }) })), onChange: (e) => {
                        setSearchInputValue(e.target.value);
                    } }),
                React.createElement(Button, { "data-cy": "add-fact-button", color: "primary", onClick: () => {
                        if (localStore.tempFact)
                            return;
                        runInAction(() => {
                            localStore.tempFact = FactStatic.createFact({ id: '', name: '', type: 'boolean' });
                        });
                    } }, t('factLibraryView.addFactBtn', 'Add Fact'))),
            React.createElement(Table, { innerRef: tableRef, onScroll: ({ scrollTop }) => {
                    scrollTopRef.current = scrollTop;
                }, columns: [
                    {
                        id: 'id',
                        label: t('factLibraryView.tableColID', 'Fact ID'),
                    },
                    {
                        id: 'name',
                        label: t('factLibraryView.tableColName', 'Fact Name'),
                    },
                    {
                        id: 'type',
                        label: t('factLibraryView.tableColType', 'Type'),
                    },
                ], rows: getOrderedRows(), rowHeight: 48, rowRenderer: (props) => {
                    const item = props.rowData.itemModel;
                    return (React.createElement(DefaultRowRenderer, { key: props.index, requiredProps: props, onClick: () => {
                            cancelTempFact();
                            setSelectedFactID(item.id);
                        }, className: css({
                            backgroundColor: (selectedFact === null || selectedFact === void 0 ? void 0 : selectedFact.id) === item.id ? theme.palette.background.default : undefined,
                        }) }));
                } }, ({ columns }) => {
                return columns.map((col, idx) => {
                    return (React.createElement(TableColumn, { key: idx, label: col.label, dataKey: col.id, width: col.width, cellRenderer: (props) => {
                            return (React.createElement(DefaultCellRenderer, { requiredProps: props, valueRenderer: (value) => {
                                    if (col.id === 'createdAt') {
                                        return (React.createElement(Typography, { variant: "body2", color: "default", noSelect: true }, value ? formatDateTime(new Date(value)) : '-'));
                                    }
                                    return (React.createElement(Typography, { variant: "body2", color: "default", noSelect: true }, value));
                                } }));
                        } }));
                });
            })),
        selectedFact && (React.createElement(Sidepanel, null,
            React.createElement(FactInfo, { fact: selectedFact === localStore.tempFact
                    ? { isTemp: true, fact: localStore.tempFact }
                    : { isTemp: false, fact: selectedFact }, onCancelTempFact: cancelTempFact, onSaveTempFact: () => {
                    if (localStore.tempFact) {
                        const result = editor.model.facts.addNewFact(Object.assign({}, localStore.tempFact));
                        if (result.err) {
                            return;
                        }
                        setSelectedFactID(result.data.id);
                        runInAction(() => {
                            localStore.tempFact = undefined;
                        });
                    }
                } })))));
});
const FactInfo = observer((props) => {
    const { fact } = props;
    const { t } = useTranslation('libGraphEditor');
    const { editor: { model: graphmodel }, } = useGraphEditorCtx();
    function getValidationErrorMsg() {
        const validationError = fact.isTemp && fact.fact.id.trim() != '' && graphmodel.facts.validateFactAdd(fact.fact);
        if (validationError) {
            switch (validationError.code) {
                case 'fact-with-id-already-exists':
                    return t('facts.validationMsgFactAlreadyExists', 'Fact with ID already exists');
                case 'invalid-id':
                    return t('facts.validationMsgInvalidID', 'Invalid fact ID');
            }
        }
    }
    const validationErrorMsg = getValidationErrorMsg();
    return (React.createElement(React.Fragment, null,
        React.createElement(GenericPanel, { "data-cy": "fact-info-panel" },
            React.createElement(GenericPanelHeader, { header: t('facts.factInfo.sectionTitle', 'Fact Info') }),
            React.createElement(GenericPanelContent, { p: 4 },
                React.createElement(AttributeList, null,
                    React.createElement(AttributeRendererStatic, { name: 'ID', value: fact.fact.id }),
                    React.createElement(AttributeRendererString, { "data-cy": "fact-name-input", name: t('facts.factInfo.name', 'Name'), value: fact.fact.name, onValueChange: (v) => {
                            if (fact.isTemp) {
                                runInAction(() => {
                                    fact.fact.name = v;
                                    fact.fact.id = FactStatic.generateIDFromName(v);
                                });
                            }
                            else {
                                FactStatic.setName(fact.fact, v);
                            }
                        } }),
                    React.createElement(AttributeRendererEnum, { identifier: "factType", "data-cy": "fact-type-selector", name: t('facts.factInfo.type', 'Type'), items: [
                            { text: 'Boolean', value: 'boolean' },
                            { text: 'String', value: 'string' },
                        ], value: fact.fact.type, onValueChange: (v) => {
                            if (v) {
                                FactStatic.setType(fact.fact, v);
                            }
                        } }),
                    React.createElement(ValuePublisherConfig, { valuePublisher: fact.fact.valuePublisherConfig })),
                React.createElement(Typography, { variant: "caption", color: "hint" }, validationErrorMsg !== null && validationErrorMsg !== void 0 ? validationErrorMsg : '\u200B'),
                React.createElement(Base, { mt: 6, display: "grid", gridGap: 4 }, fact.isTemp ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { "data-cy": "save-fact-button", color: "primary", disabled: !fact.fact.id || !!validationErrorMsg, onClick: props.onSaveTempFact }, t('facts.factInfo.saveBtn', 'Save')),
                    React.createElement(Button, { onClick: props.onCancelTempFact }, t('facts.factInfo.cancelBtn', 'Cancel')))) : (React.createElement(Button, { color: "error", variant: "text", onClick: () => {
                        graphmodel.facts.removeFact(fact.fact.id);
                    } }, t('facts.factInfo.deleteBtn', 'Delete from library'))))))));
});
