import { Observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Typography } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor';
import { ContentBuilderContext, ContentBuilderLibraryComponent, } from 'lib/graphEditor/contentBuilder';
import { ElementInfoPanel, ElementTreeRenderer } from 'lib/graphEditor/contentBuilder/view';
import { Sidepanel, TwoColGridLayout } from 'lib/graphEditor/ui';
export function ComponentEditorView() {
    const match = useRouteMatch();
    const { editor } = useGraphEditorCtx();
    const componentID = match.params.componentID;
    const component = editor.model.library.getComponentByID(componentID);
    if (!component) {
        return (React.createElement(Typography, { color: "default", variant: "body2" }, `Missing component with ID ${componentID}`));
    }
    if (!(component instanceof ContentBuilderLibraryComponent)) {
        return React.createElement(Typography, { color: "default", variant: "body2" }, `unknown component type`);
    }
    return React.createElement(ElementComponentEditor, { component: component });
}
function ElementComponentEditor(props) {
    const theme = useTheme();
    const { component } = props;
    const contentBuilderContext = React.useRef(new ContentBuilderContext()).current;
    React.useEffect(() => {
        contentBuilderContext.elements.forEach((el) => el.remove());
        contentBuilderContext.addElement(component.createInstance());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component]);
    return (React.createElement(Observer, null, () => (React.createElement(TwoColGridLayout, null,
        React.createElement(Base, { background: theme.palette.background.default, py: 8, px: 4, display: "grid", justifyItems: "center", alignItems: "flex-start", tabIndex: 0, width: '100%', height: '100%', className: css(getScrollerCss({ theme }), {
                ':focus-within': {
                    outline: `1px solid ${theme.palette.primary.main}`,
                },
            }) },
            React.createElement(ElementTreeRenderer, { contentBuilderContext: contentBuilderContext })),
        React.createElement(Sidepanel, null,
            React.createElement(ElementInfoPanel, { element: contentBuilderContext.selectedElement }))))));
}
