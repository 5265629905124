import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export const PerformanceSidebarLoader = () => {
    return (React.createElement(Base, { display: "grid", gap: 8 },
        React.createElement(Base, { display: "grid", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", width: "100%", height: 40 })),
        React.createElement(Base, { display: "grid", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", width: "50%" }),
            React.createElement(Skeleton, { variant: "rectangular", height: 50 })),
        React.createElement(Base, { display: "grid", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", width: "50%" }),
            React.createElement(Skeleton, { variant: "rectangular", height: 50 }))));
};
