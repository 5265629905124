import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { intl } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { Base, Container, Page } from 'lib/cortiUI';
import { ClientSettings } from './components/ClientSettings';
import { GeneralSettings } from './components/GeneralSettings';
import { OrgConfiguration } from './components/OrgConfiguration';
import { OrgDataManagement } from './components/OrgDataManagement';
import { MUTATIONS, QUERIES } from './graphql';
export const OrganizationSettingsView = ({ organizationID }) => {
    const { data: organizationData } = useQuery(QUERIES.GET_ORGANIZATION, {
        variables: {
            orgID: organizationID,
        },
    });
    const [deleteFeatureHandler] = useMutation(MUTATIONS.DELETE_ORG_FEATURES);
    const [setFeaturesHandler] = useMutation(MUTATIONS.ENSURE_ORGANIZATION_FEATURES);
    const [toggleSsoEnabled] = useMutation(MUTATIONS.TOGGLE_SSO_ENABLED);
    const handleUpdateOrganizationName = async (value) => {
        await authStore.updateOrganizationName(value.trim());
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    const handleSetOrganizationFeatures = async (featureIDs) => {
        await setFeaturesHandler({
            variables: {
                orgID: organizationID,
                featureIDs: [...featureIDs],
            },
        });
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    const handleDeleteOrganizationFeatures = async (removedFeatureIDs) => {
        await deleteFeatureHandler({
            variables: { features: removedFeatureIDs, orgID: organizationID },
        });
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    const handleUpdateClientSettings = async (settingsKeyToUpdate, newValue) => {
        await authStore.updateOrganizationClientSettings({ [settingsKeyToUpdate]: newValue });
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    const handleUpdateTriageSettings = async (input) => {
        await authStore.updateOrganizationTriageSettings(input);
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    const handleToggleSSO = async (value) => {
        await toggleSsoEnabled({
            variables: {
                organizationID,
                ssoEnabled: value,
            },
        });
        coreStore.notifications.showNotification({
            type: 'success',
            message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
        });
    };
    return (React.createElement(Page, null, organizationData && (React.createElement(Container, { maxWidth: "sm" },
        React.createElement(Base, { display: "grid", gap: 9 },
            React.createElement(GeneralSettings, { organizationName: organizationData.organization.name, organizationFeatures: organizationData.organization.features, ssoEnabled: organizationData.organization.ssoEnabled, onUpdateOrganizationName: handleUpdateOrganizationName, onSetOrganizationFeatures: handleSetOrganizationFeatures, onDeleteOrganizationFeatures: handleDeleteOrganizationFeatures, onToggleSsoEnabled: handleToggleSSO }),
            React.createElement(ClientSettings, { organizationFeatures: organizationData.organization.features, clientSettings: organizationData.organization.clientSettings, onUpdateClientSettings: handleUpdateClientSettings }),
            React.createElement(OrgConfiguration, { organizationID: organizationID, triageSettings: organizationData.organization.triageSettings, onUpdateTriageSettings: handleUpdateTriageSettings }),
            React.createElement(OrgDataManagement, null))))));
};
