import { isEqual, orderBy } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { combineTimelineEntryDefinitionSourceValues, } from '@corti/lib/coreApiService';
import { Autocomplete, Paper, Popper, TextField } from 'lib/cortiUI';
export const CreateEditTimelineEntryDropdown = (props) => {
    const { type, initialData, onClose, onSubmit, anchorEl } = props;
    const initialTimelineEntryDefinition = initialData.definitionSource
        ? initialData.timelineEntryDefinitions.find(({ source }) => isEqual(source, initialData.definitionSource))
        : null;
    const [selectedDefinition, setSelectedDefinition] = React.useState(initialTimelineEntryDefinition);
    const definitionItems = initialData.timelineEntryDefinitions
        .filter((d) => {
        if (type === 'create') {
            return d.isEntryCreatable;
        }
        else {
            return initialTimelineEntryDefinition
                ? d.source.type === initialTimelineEntryDefinition.source.type
                : false;
        }
    })
        .map((d) => {
        return {
            text: d.text || d.title,
            value: combineTimelineEntryDefinitionSourceValues(d.source),
            sectionId: d.title,
            definition: d,
        };
    });
    const handleSubmit = () => {
        if (selectedDefinition) {
            onSubmit({ definition: selectedDefinition });
        }
    };
    return (React.createElement(Popper, { open: !!anchorEl, style: { paddingTop: 50, paddingInline: 20 }, anchorEl: anchorEl, placement: "left-start" },
        React.createElement(Paper, { elevation: 3 },
            React.createElement(Autocomplete, { "data-cy": "timelineentry-type-select", id: "annotation-input", sx: { width: 300 }, value: definitionItems.find((i) => {
                    selectedDefinition
                        ? combineTimelineEntryDefinitionSourceValues(selectedDefinition === null || selectedDefinition === void 0 ? void 0 : selectedDefinition.source) === i.value
                        : false;
                }), options: orderBy(definitionItems, [(item) => item.sectionId.toLowerCase(), 'text'], ['asc', 'asc']), getOptionLabel: (option) => option.text, groupBy: (option) => option.sectionId, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('explore:caseView.createTimelineEntry', 'Create timeline entry'), autoFocus: true }))), onChange: handleSubmit, onHighlightChange: (_, option) => {
                    setSelectedDefinition(option === null || option === void 0 ? void 0 : option.definition);
                }, onKeyDown: ({ key }) => {
                    if (key === 'Escape') {
                        onClose();
                    }
                }, onClose: onClose, autoHighlight: true, selectOnFocus: true, openOnFocus: true, autoComplete: true }))));
};
