import React from 'react';
import { FixedSizeList } from 'react-window';
import { intl } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ListItemButton, Typography } from 'lib/cortiUI';
import { GroupOuterShell } from './GroupOuterShell';
import { Input } from './Input';
export function SingleSelectableList(props) {
    const { items, selectedItemId, onChange, withSearch = false } = props;
    const theme = useTheme();
    const [searchValue, setFilterValue] = React.useState('');
    const listItems = getFilteredItems();
    function getFilteredItems() {
        return items.filter((i) => !searchValue || stringIncludes(i.text, searchValue));
    }
    const Row = ({ index, style, data }) => {
        const rowData = data[index];
        return (React.createElement(ListItemButton, { key: rowData.value, style: style, selected: selectedItemId === rowData.value, onClick: () => onChange(rowData.value), classes: {
                root: css({
                    backgroundColor: theme.palette.background.default,
                }),
            } },
            React.createElement(Typography, { color: "default", variant: "caption" }, rowData.text)));
    };
    return (React.createElement(React.Fragment, null,
        withSearch && (React.createElement(GroupOuterShell, { className: css({
                margin: 'auto',
                width: 200,
                height: 30,
            }) },
            React.createElement(Input, { value: searchValue, placeholder: intl.t('search', 'Search'), onChange: (e) => setFilterValue(e.target.value) }))),
        React.createElement(Base, { className: css(getScrollerCss({ theme: theme })), maxHeight: 150, overflow: "hidden", mt: 3 },
            React.createElement(FixedSizeList, { className: css(getScrollerCss({ theme: theme })), height: 150, width: 200, itemCount: listItems.length, itemSize: 36, itemData: listItems }, Row))));
}
