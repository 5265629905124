var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from '@emotion/styled';
import React from 'react';
import { Box, Switch, Typography } from 'lib/cortiUI';
import { Help } from './Help';
const SwitchLabel = styled.label `
  display: flex;
  align-items: center;
`;
const InputLabel = styled.label `
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;
const SWITCH_SX = {
    '&.MuiSwitch-root .MuiSwitch-switchBase': { color: '#ccc' },
    '&.MuiSwitch-root .Mui-checked': { color: 'primary.main' },
};
export function ChecklistInspectorAttribute(_a) {
    var { label, help, children } = _a, rest = __rest(_a, ["label", "help", "children"]);
    const { hasSwitch } = rest;
    if (hasSwitch) {
        return (React.createElement(Box, { display: "flex", gap: 4 },
            React.createElement(SwitchLabel, null,
                React.createElement(Box, { flex: "0 0 72px" },
                    React.createElement(Switch, { checked: rest.switchValue, onChange: (evt) => rest.handleSwitchChange(evt.target.checked), sx: SWITCH_SX })),
                React.createElement(Typography, { variant: "h6", fontWeight: 600 },
                    label,
                    " ",
                    help && React.createElement(Help, { text: help }))),
            React.createElement(Box, { display: "flex", flexDirection: "row" },
                React.createElement(Box, { flex: "0 0 72px" }),
                children)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "row" },
            React.createElement(Box, { flex: "0 0 72px" }),
            React.createElement(InputLabel, null,
                React.createElement(Typography, { variant: "h6", fontWeight: 600 },
                    label,
                    " ",
                    help && React.createElement(Help, { text: help })),
                children))));
}
