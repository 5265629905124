var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { createBaseMediaProvider } from './media';
export class MediaModule {
    constructor(_, provider) {
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "provider", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isMediaPickerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mediaPickerOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.provider = provider !== null && provider !== void 0 ? provider : createBaseMediaProvider();
        runInAction(() => {
            this.isMediaPickerOpen = false;
        });
    }
    mediaAssetPicked(mediaAsset) {
        this.observer.fireEvent('mediaPicked', mediaAsset);
    }
    openMediaPicker(options = {}) {
        this.isMediaPickerOpen = true;
        this.mediaPickerOptions = options;
    }
    closeMediaPicker() {
        this.isMediaPickerOpen = false;
        this.mediaPickerOptions = undefined;
        this.observer.fireEvent('mediaPickerClosed');
    }
    /**
     * Opens the media picker view and waits until the media item is selected
     */
    async pickMedia(options = {}) {
        if (this.isMediaPickerOpen) {
            return;
        }
        return new Promise((res) => {
            this.openMediaPicker(options);
            const unsub1 = this.observer.once('mediaPicked', (m) => {
                unsub2();
                this.closeMediaPicker();
                res(m);
            });
            const unsub2 = this.observer.once('mediaPickerClosed', () => {
                unsub1();
                res(undefined);
            });
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], MediaModule.prototype, "isMediaPickerOpen", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], MediaModule.prototype, "mediaPickerOptions", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MediaModule.prototype, "openMediaPicker", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MediaModule.prototype, "closeMediaPicker", null);
