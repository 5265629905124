import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Button, Modal, ModalContent, ModalHeader } from 'lib/cortiUI';
import { useTranscriptQueryViewContext } from '../../TranscriptQueryView/context';
import { useTranscriptSearchViewContext } from '../../TranscriptSearchView/context';
import { FilterPanel } from './FilterPanel';
export const FilterPicker = observer(function FilterPicker() {
    var _a, _b, _c;
    const theme = useTheme();
    const { t } = useTranslation('transcriptSearchApp');
    const { viewModel } = useTranscriptQueryViewContext();
    const { viewModel: searchViewModel } = useTranscriptSearchViewContext();
    const [isFilterModalOpen, setIsFilterModalOpen] = React.useState(false);
    const allFilters = {
        teams: searchViewModel.state.teams,
        users: repositories.users.users,
        protocolNames: searchViewModel.state.protocolNames || [],
    };
    const totalFilterCount = (((_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.tempUserIDs.length) || 0) +
        (((_b = viewModel.state.query) === null || _b === void 0 ? void 0 : _b.parameters.tempTeamIDs.length) || 0) +
        (((_c = viewModel.state.query) === null || _c === void 0 ? void 0 : _c.parameters.tempProtocolNames.length) || 0);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { sx: { color: theme.palette.text.primary }, onClick: () => setIsFilterModalOpen(true), variant: "text", disabled: searchViewModel.state.isLoadingProtocolNames || searchViewModel.state.isLoadingTeams, startIcon: React.createElement(FilterAltRoundedIcon, { fontSize: "small" }) },
            t('filters', 'Filters'),
            !!totalFilterCount && `(${totalFilterCount})`),
        React.createElement(Modal, { open: isFilterModalOpen, onClose: () => setIsFilterModalOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('filters', 'Filters') }),
                React.createElement(ModalContent, null,
                    React.createElement(FilterPanel, { allFilters: allFilters, onFilterSave: () => setIsFilterModalOpen(false) }))))));
});
