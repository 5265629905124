import React from 'react';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view/graphEditorContext';
export function getUIStateStorage(editor, scope) {
    if (!editor.__UIcomponentState) {
        editor.__UIcomponentState = {};
    }
    return editor.__UIcomponentState[scope];
}
export function setUIStateStorage(editor, scope, value) {
    if (!editor.__UIcomponentState) {
        editor.__UIcomponentState = {};
    }
    editor.__UIcomponentState[scope] = value;
}
/**
 * Similar to React.useState except that it will also store this state on the editor instance
 * thus persisting it after UI components remount
 */
export function useGraphEditorStorage(initialValue, scope) {
    var _a;
    const { editor } = useGraphEditorCtx();
    const [state, setState] = React.useState((_a = getUIStateStorage(editor, scope)) !== null && _a !== void 0 ? _a : initialValue);
    React.useEffect(() => {
        return () => {
            setUIStateStorage(editor, scope, state);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    return [state, setState];
}
