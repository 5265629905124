var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useMediaQuery } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { NavSidebar, COLLAPSED_SIZE as SIDEBAR_COLLAPSED_SIZE, EXPANDED_SIZE as SIDEBAR_EXPANDED_SIZE, Topbar, } from 'lib/webappUI';
const USER_PREFERENCE_SIDEBAR_STORAGE_KEY = 'expandedSidebar';
export const GlobalNavigation = (_a) => {
    var _b, _c;
    var { isFocusMode, navItems, children, currentUser } = _a, rest = __rest(_a, ["isFocusMode", "navItems", "children", "currentUser"]);
    const [isNavSidebarOpen, setIsNavSidebarOpen] = React.useState(() => localStorage.getItem(USER_PREFERENCE_SIDEBAR_STORAGE_KEY) === 'true');
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(`(max-width: ${(_c = (_b = theme === null || theme === void 0 ? void 0 : theme.breakpoints) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.sm}px)`);
    const expandSidebar = () => {
        setIsNavSidebarOpen(true);
    };
    const collapseSidebar = () => {
        setIsNavSidebarOpen(false);
    };
    React.useEffect(() => {
        const previousState = localStorage.getItem(USER_PREFERENCE_SIDEBAR_STORAGE_KEY);
        if (!previousState) {
            expandSidebar();
        }
    }, []);
    React.useEffect(() => {
        localStorage.setItem(USER_PREFERENCE_SIDEBAR_STORAGE_KEY, isNavSidebarOpen.toString());
    }, [isNavSidebarOpen]);
    return (React.createElement(Base, Object.assign({ className: css(Object.assign({ display: 'grid', height: '100%', transition: `all 200ms ease-in-out` }, (isFocusMode
            ? {
                gridTemplateAreas: `"topbar"
                              "appView"`,
                gridTemplateRows: '64px 1fr',
            }
            : isSmallScreen
                ? {
                    gridTemplateAreas: `"navSidebar topbar"
                              "navSidebar appView"`,
                    gridTemplateColumns: '0px 1fr',
                    gridTemplateRows: '64px 1fr',
                }
                : {
                    gridTemplateAreas: `"navSidebar topbar"
                              "navSidebar appView"`,
                    gridTemplateColumns: isNavSidebarOpen
                        ? `${SIDEBAR_EXPANDED_SIZE} 1fr`
                        : `${SIDEBAR_COLLAPSED_SIZE} 1fr`,
                    gridTemplateRows: '64px 1fr',
                }))) }, rest),
        !isFocusMode && (React.createElement(NavSidebar, { isSmallScreen: isSmallScreen, isOpen: isNavSidebarOpen, onOpen: expandSidebar, onClose: collapseSidebar, navItems: navItems, currentUser: currentUser })),
        React.createElement(Topbar, Object.assign({ isFocusMode: isFocusMode }, (isSmallScreen
            ? {
                showBurgerMenu: isSmallScreen,
                onBurgerMenuClick: expandSidebar,
            }
            : {}))),
        children));
};
