import React from 'react';
import { useTranslation } from '@corti/i18n';
import { logger } from '@corti/logger';
import { coreRealtimeClient } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { FileSystemStorage, Recorder } from '../utils';
import { useSettingsContext } from './SettingsContext';
const screenRecordingInitialState = {
    isRecording: false,
    toggleRecording: () => { },
};
const ScreenRecordingContext = React.createContext(screenRecordingInitialState);
const VIDEO_OPTIONS = {
    mimeType: 'video/webm;codecs=vp8',
};
export const ScreenRecordingProvider = ({ children }) => {
    const { t } = useTranslation('screenRecorder');
    const { authToken, currentUserID, organization } = useAuth();
    const { capturingSettings, availableCaptureSources } = useSettingsContext();
    const [recorders, setRecorders] = React.useState([]);
    const [isRecording, setIsRecording] = React.useState(screenRecordingInitialState.isRecording);
    const previousIsRecording = React.useRef(isRecording);
    const prevSystemStorage = React.useRef();
    // #region Recording handling
    React.useEffect(() => {
        if (!previousIsRecording.current && isRecording) {
            void startRecording();
        }
        else if (previousIsRecording.current && !isRecording) {
            void stopRecording();
        }
        previousIsRecording.current = isRecording;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecording]);
    function getMediaConstrainsFromSettings() {
        const { resolution, frameRate } = capturingSettings;
        const [width, height] = resolution.split('x');
        return {
            width,
            height,
            maxFrameRate: frameRate,
        };
    }
    function createStorageForRecordingSession() {
        const now = Date.now();
        const storage = new FileSystemStorage({
            path: `recordings/${now}`,
            authToken: authToken,
        });
        prevSystemStorage.current = storage;
        return storage;
    }
    async function createRecorderForScreen(storage, screen) {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                mandatory: Object.assign({ chromeMediaSource: 'desktop', chromeMediaSourceId: screen.id }, getMediaConstrainsFromSettings()),
            },
        });
        const recorder = new Recorder(storage, screen.id);
        recorder.startRecording(stream, VIDEO_OPTIONS);
        return recorder;
    }
    async function startRecording() {
        const storage = createStorageForRecordingSession();
        try {
            const recorders = await Promise.all(availableCaptureSources
                .filter((s) => s.selected)
                .map((s) => createRecorderForScreen(storage, s)));
            setRecorders(recorders);
            coreStore.notifications.showNotification({
                type: 'info',
                message: t('screenRecordingStartedMessage', 'Screen recording started'),
            });
            logger.info('[ScreenRecorder] Recording started');
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('screenRecordingFailedErrorMessage', "Couldn't start the recording."),
            });
            logger.info("[ScreenRecorder] Recording didn't start due to an unexpected error.", error instanceof Error ? error.message : JSON.stringify(error));
            storage.cleanUp();
            prevSystemStorage.current = null;
            setIsRecording(false);
        }
    }
    async function stopRecording() {
        if (!prevSystemStorage.current)
            return;
        await Promise.all(recorders.map((recorder) => recorder.stopRecording()));
        coreStore.notifications.showNotification({
            type: 'info',
            message: t('screenRecordingStoppedMessage', 'Screen recording stopped'),
        });
        logger.info('[ScreenRecorder] Recording stopped');
    }
    // #endregion
    // #region Events handling
    React.useEffect(() => {
        coreRealtimeClient.bind('triage-session-call-started', handleCallStartEvent);
        coreRealtimeClient.bind('triage-session-call-ended', handleStopCallEvent);
        return () => {
            coreRealtimeClient.unbind('triage-session-call-started', handleCallStartEvent);
            coreRealtimeClient.unbind('triage-session-call-ended', handleStopCallEvent);
            handleStopCallEvent();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableCaptureSources]);
    React.useEffect(() => {
        //@ts-expect-error
        window.Bridge.ipcRenderer.on('screen-recording-upload-response', handleVideoUploadStatusEvent);
        return () => 
        //@ts-expect-error
        window.Bridge.ipcRenderer.removeListener('screen-recording-upload-response', handleVideoUploadStatusEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleCallStartEvent(_) {
        if (!availableCaptureSources.some((s) => s.selected)) {
            coreStore.notifications.showNotification({
                type: 'warning',
                message: t('sourcesNotSelectedWarning', "Screen recording hasn't started because sources are not selected. Please select sources"),
            });
            return;
        }
        setIsRecording(true);
    }
    function handleStopCallEvent(data) {
        var _a;
        if (!prevSystemStorage.current)
            return;
        setIsRecording(false);
        if (!data)
            return;
        const metadata = {
            call_id: data.call_id,
            ended_at: data.end,
            triage_session_id: null,
            started_at: data.start,
            user_id: currentUserID,
            case_id: data.case_id,
            organization_id: organization.id,
            monitor_no: 1,
        };
        (_a = prevSystemStorage.current) === null || _a === void 0 ? void 0 : _a.uploadToServer(metadata);
    }
    function handleVideoUploadStatusEvent(_event, arg) {
        coreStore.notifications.showNotification(arg.userData === 'upload-success'
            ? {
                type: 'success',
                message: t('screenRecordingUploadSuccess', 'The screen recording is sent to the server'),
            }
            : {
                type: 'error',
                message: t('screenRecordingUploadFailure', "En error ocurred, screen recording has not been sent to the server but you can view it in the 'recordings' folder."),
            });
    }
    // #endregion
    /**
     * For manual triggering (e.g. onClick)
     */
    function toggleRecording() {
        setIsRecording((prev) => !prev);
    }
    const value = React.useMemo(() => ({
        isRecording,
        toggleRecording,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRecording]);
    return (React.createElement(ScreenRecordingContext.Provider, { value: value }, children));
};
export function useScreenRecordingContext() {
    return React.useContext(ScreenRecordingContext);
}
