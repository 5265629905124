import { validateChecklist } from '../validation';
export const createValidationSlice = (set, get) => ({
    validationResults: {},
    validate: (checklist) => {
        const [prevHash, prevValid] = get().validationResults[checklist.id] || ['', 'valid'];
        const hash = JSON.stringify(checklist);
        if (prevHash === hash) {
            return prevValid;
        }
        const checklists = get().checklists;
        const validation = validateChecklist(checklist, { checklists });
        set((state) => ({
            validationResults: Object.assign(Object.assign({}, state.validationResults), { [checklist.id]: [hash, validation] }),
        }));
        return validation;
    },
});
