var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { TimelineEntryDefinitionMods, TimelineEntryDefinitionSourceType, } from '@corti/lib/coreApiService';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Box, Button, ConfirmableTarget, Container, IconButton, Modal, ModalContent, ModalHeader, Page, PageHeader, PageHeaderActionsSection, Tooltip, Typography, } from 'lib/cortiUI';
import { AnnotationForm, DEFAULT_COLOR, TEST_IDS, getFieldOption, getFormDataBySourceType, } from './AnnotationForm';
import { AnnotationsDataGrid, getRowData } from './AnnotationsDataGrid';
import { VisibilityConfigurationTrigger } from './VisibilityConfiguration';
import { DefinitionContextProvider, useDefinitionContext } from './context';
import { mapFormValuesToInputKeys } from './utils';
const AnnotationManagementViewTemp = () => {
    const theme = useTheme();
    const { definitions, definitionSources, createDefinition, updateDefinition, deleteDefinition } = useDefinitionContext();
    const { t } = useTranslation('annotationManagement');
    const [selectedDefinitions, setSelectedDefinitions] = React.useState([]);
    const [isAnnotationModalOpen, setIsAnnotationModalOpen] = React.useState(false);
    const [annotationFormData, setAnnotationFormData] = React.useState([]);
    const { control, handleSubmit, reset, getValues } = useForm({
        mode: 'onBlur',
    });
    const [editingDefinitionSource, setEditingDefinitionSource] = React.useState(null);
    const { editableRows, categories } = definitions.reduce((data, definition) => {
        const { mods, title } = definition;
        if (mods.includes(TimelineEntryDefinitionMods.EDITABLE)) {
            data.editableRows.push(getRowData(definition));
            if (title && !data.categories.includes(title)) {
                data.categories.push(title);
            }
        }
        return data;
    }, { editableRows: [], categories: [] });
    /**
     * Currently there's only one creatable type in this array.
     * In the future there will be logic for choosing the type of created annotation.
     */
    const creatableSources = definitionSources.reduce((filtered, s) => {
        if (s.isDefinitionCreatable)
            filtered.push(s.type);
        return filtered;
    }, []);
    const handleCloseModal = () => {
        setIsAnnotationModalOpen(false);
        setAnnotationFormData([]);
        reset();
        setEditingDefinitionSource(null);
    };
    const handleCreateAnnotationButtonClick = () => {
        if (!creatableSources.length)
            return;
        const [searchTermsSourceType] = creatableSources;
        const fieldData = getFormDataBySourceType(searchTermsSourceType, categories);
        setAnnotationFormData(fieldData);
        setIsAnnotationModalOpen(true);
    };
    const handleRowSelection = (rowIDs) => {
        const selectedIDs = new Set(rowIDs);
        const selectedRowsData = [];
        editableRows.forEach((row) => {
            if (selectedIDs.has(row.id.toString())) {
                const { entityType, type, entityID } = row._definition.source;
                selectedRowsData.push({
                    source: { entityID: entityID, entityType, type },
                    title: row._definition.title,
                });
            }
        }, []);
        setSelectedDefinitions(selectedRowsData);
    };
    const handleRowClick = (row) => {
        var _a;
        if (!row.mods.includes(TimelineEntryDefinitionMods.EDITABLE))
            return;
        const fieldsData = getFormDataBySourceType(row.sourceType, categories, {
            category: getFieldOption(row.category),
            name: row.name,
            searchTerms: row.searchTerms.length ? row.searchTerms.split(', ').map(getFieldOption) : [],
            isCreatable: row.isEntryCreatable,
            color: (_a = row.color) !== null && _a !== void 0 ? _a : DEFAULT_COLOR,
            isObvious: row.isObvious,
        });
        setEditingDefinitionSource(row._definition.source);
        setAnnotationFormData(fieldsData);
        setIsAnnotationModalOpen(true);
    };
    const handleCreateAnnotation = async () => {
        if (!annotationFormData.length)
            return;
        const values = getValues();
        const _a = mapFormValuesToInputKeys(values), { isEntryCreationDisabled: _ } = _a, inputKeys = __rest(_a, ["isEntryCreationDisabled"]);
        // Type is hardcoded because UI form only support "serch-term" definition creation
        const input = Object.assign({ source: {
                type: TimelineEntryDefinitionSourceType.searchTerms,
                entityType: inputKeys.title,
            } }, inputKeys);
        createDefinition(input);
        handleCloseModal();
    };
    const handleUpdateAnnotation = async () => {
        if (!annotationFormData.length || !editingDefinitionSource)
            return;
        const values = getValues();
        const inputKeys = mapFormValuesToInputKeys(values);
        const input = Object.assign({ source: {
                entityID: editingDefinitionSource.entityID,
                entityType: editingDefinitionSource.entityType,
                type: editingDefinitionSource.type,
            } }, inputKeys);
        updateDefinition(input);
        handleCloseModal();
    };
    const handleDeleteAnnotations = async () => {
        if (!selectedDefinitions.length)
            return;
        await Promise.all(selectedDefinitions.map(async (defToDelete) => {
            return await deleteDefinition(defToDelete.source);
        }));
        coreStore.notifications.showNotification({
            message: t('successMessages.deleted', {
                count: selectedDefinitions.length,
                defaultValue_one: `{{count}} annotation removed`,
                defaultValue_other: `{{count}} annotations removed`,
            }),
        });
    };
    return (React.createElement(Page, { "data-cy": "call-annotation-management-view" },
        React.createElement(Container, null,
            React.createElement(PageHeader, null,
                React.createElement(Typography, { variant: "h4", color: "default" }, t('pageTitle', 'Annotations')),
                React.createElement(PageHeaderActionsSection, null,
                    React.createElement(VisibilityConfigurationTrigger, null),
                    React.createElement(Button, { "data-cy": TEST_IDS.createTimelineEntryDefBtn, color: "primary", onClick: handleCreateAnnotationButtonClick }, t('createTimelineEntryDefinition', 'Create annotation')))),
            React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center", minHeight: 50, justifyContent: selectedDefinitions.length > 0 ? 'space-between' : 'flex-end', pl: 3 },
                selectedDefinitions.length > 0 && (React.createElement(ConfirmableTarget, { message: t('warningOnDelete', 'Are you sure you want to permanently remove selected items? This action will affect other parts of the app.'), onConfirm: handleDeleteAnnotations }, ({ onClick }) => (React.createElement(Tooltip, { disableInteractive: true, title: t('tooltip.remove', 'Remove') },
                    React.createElement(IconButton, { "data-cy": TEST_IDS.deleteTimelineEntryDefBtn, icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), onClick: onClick }))))),
                React.createElement(Typography, { mb: 6, variant: "caption", "data-cy": TEST_IDS.gridResultsCount }, t('resultsFound', {
                    count: editableRows.length,
                    defaultValue_one: `{{count}} result found`,
                    defaultValue_other: `{{count}} results found`,
                }))),
            React.createElement(AnnotationsDataGrid, { rows: editableRows, onRowClick: handleRowClick, onSelectionModelChange: handleRowSelection }),
            React.createElement(Modal, { open: isAnnotationModalOpen, onClose: handleCloseModal },
                React.createElement(Box, { className: css(getScrollerCss({ theme })) },
                    React.createElement(ModalHeader, { title: editingDefinitionSource
                            ? t('modal.updateTimelineEntryDefinitionHeader', 'Update an annotation')
                            : t('modal.createTimelineEntryDefinitionHeader', 'Create an annotation') }),
                    React.createElement(ModalContent, null,
                        React.createElement(AnnotationForm, { control: control, data: annotationFormData, onCancel: handleCloseModal, onSubmit: editingDefinitionSource
                                ? handleSubmit(handleUpdateAnnotation)
                                : handleSubmit(handleCreateAnnotation) })))))));
};
//TODO: Get rid of this by using context inside other components
export const AnnotationManagementView = () => {
    return (React.createElement(DefinitionContextProvider, null,
        React.createElement(AnnotationManagementViewTemp, null)));
};
