import SwipeRoundedIcon from '@mui/icons-material/SwipeRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Label, Separator, Slider, Switch, Typography } from 'lib/cortiUI';
export function TargetConfigurationPanel({ value, isEnabled, onSave, onClose, }) {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'targetConfiguration' });
    const [isTargetEnabled, setIsTargetEnabled] = React.useState(isEnabled);
    const [target, setTarget] = React.useState(value || 1);
    function handleTargetChange(_, value) {
        setTarget(typeof value !== 'number' ? 0 : value);
    }
    function handleOnSave() {
        if (value !== target || isEnabled !== isTargetEnabled) {
            if (isTargetEnabled === false) {
                onSave({ value: 0, enabled: false });
                return;
            }
            onSave({ value: target, enabled: isTargetEnabled });
        }
        else {
            onClose();
        }
    }
    return (React.createElement(Base, { display: "grid" },
        React.createElement(Base, { pb: 6 },
            React.createElement(Typography, null, t('modalDescription', 'Set a compliance goal for the selected trackers.'))),
        React.createElement(Separator, { horizontal: true }),
        isTargetEnabled && (React.createElement(React.Fragment, null,
            React.createElement(Base, { my: 8 },
                React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center" },
                    React.createElement(Slider, { components: { Mark: 'span' }, value: target, step: 1, min: 1, onChange: handleTargetChange, valueLabelDisplay: "auto", marks: [
                            { value: 1, label: '1%' },
                            { value: 100, label: '100%' },
                        ], sx: { width: 0.6 } })),
                React.createElement(Base, { display: "flex", justifyContent: "center", alignItems: "center", gap: 4 },
                    React.createElement(SwipeRoundedIcon, { color: "action" }),
                    React.createElement(Typography, { variant: "body2", sx: { ml: 1 } }, t('sliderLabel', 'Drag the slider to set a goal')))),
            React.createElement(Separator, { horizontal: true }))),
        React.createElement(Base, { display: "flex", justifyContent: "space-between", pt: 5 },
            React.createElement(Label, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Switch, { checked: isTargetEnabled, onChange: () => setIsTargetEnabled((currentValue) => !currentValue) }),
                isTargetEnabled
                    ? t('targetSwitchTitleEnabled', 'Enabled')
                    : t('targetSwitchTitleDisabled', 'Disabled')),
            React.createElement(Button, { variant: "text", onClick: handleOnSave }, t('apply', 'Apply')))));
}
