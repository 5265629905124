import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import { BackButton } from './BackButton';
import { Templates } from './Templates';
export function Header() {
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.header' });
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        React.createElement(Base, { display: "flex", gap: 2, alignItems: "center" },
            React.createElement(BackButton, null),
            React.createElement(Typography, { variant: "h6", color: "default" }, t('manualAssessment', 'Manual Assessment'))),
        React.createElement(Templates, null)));
}
