import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { AnalyticsApp } from './AnalyticsApp';
const mod = {
    id: 'analytics',
    title: () => intl.t('analytics:appName', 'Analytics'),
    path: '/analytics',
    icon: TableChartRoundedIcon,
    requiresOrgFeature: 'custom-analytics',
    requiresUserPermission: 'custom_analytics:read',
    viewComponent: () => React.createElement(AnalyticsApp, null),
    navGroupe: 1,
};
export default mod;
