import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { Button, Dialog, Switch } from 'lib/cortiUI';
import { getFeedbackFormTemplatesQuery, setFeedbackFormTemplateAnonymizeReviewersMutation, } from '../../graphql';
export const AnonymizeToggle = ({ feedbackFormTemplateID, isAnonymous, }) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'templatesDataGrid' });
    const authStore = useAuth();
    const organizationID = authStore.organization.id;
    const [updateTemplate, { loading }] = useMutation(setFeedbackFormTemplateAnonymizeReviewersMutation, {
        refetchQueries: [
            {
                query: getFeedbackFormTemplatesQuery,
                variables: {
                    organizationID,
                },
            },
        ],
    });
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const handleConfirmation = async () => {
        setModalOpen(false);
        await updateTemplate({ variables: { id: feedbackFormTemplateID, isAnonymous: !isAnonymous } });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Switch, { disabled: loading, checked: isAnonymous, onClick: handleOpen, "data-cy": "set-isAnonymous-toggle-btn", "data-cy-state": `template-anonymous-${isAnonymous}` }),
        !isAnonymous ? (React.createElement(Dialog, { open: modalOpen, onClose: handleClose, title: t('actionsWarnings.setAnonymousTitle', 'Confirm making this template anonymous?'), helperText: t('actionsWarnings.setAnonymousMessage', 'Making this template anonymous will hide the identity of all users who submit feedback using this template.'), actionButtons: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", color: "primary", onClick: handleClose }, t('actionsWarnings.setAnonymousCancel', 'Keep public')),
                React.createElement(Button, { variant: "contained", color: "primary", onClick: handleConfirmation, "data-cy": "make-anonymous-btn" }, t('actionsWarnings.confirmAnonymous', 'Make anonymous'))) })) : (React.createElement(Dialog, { open: modalOpen, onClose: handleClose, title: t('actionsWarnings.setPublicTitle', 'Confirm making this template public?'), helperText: t('actionsWarnings.setPublicMessage', 'Making this template public will reveal the identity of all users who submit feedback using this template.'), actionButtons: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", color: "primary", onClick: handleClose }, t('actionsWarnings.setPublicCancel', 'Keep anonymous')),
                React.createElement(Button, { variant: "contained", color: "primary", onClick: handleConfirmation, "data-cy": "make-public-btn" }, t('actionsWarnings.confirmPublic', 'Make public'))) }))));
};
