import { Dexie } from '@corti/lib/indexedDB/dexie';
export class RealTimeUserDB {
    constructor(dbName) {
        Object.defineProperty(this, "dexie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "comments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flowStateChangeEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flowAnalyticEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recentSessions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onQuotaExceeded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.dexie.onQuotaExceeded(cb);
            }
        });
        this.dexie = new Dexie(dbName);
        this.dexie.version(1).stores({
            sessions: 'id',
            comments: 'id, sessionID',
            flowStateChangeEvents: 'id, sessionID',
            recentSessions: 'id, createdAt',
        });
        this.dexie
            .version(2)
            .stores({
            flowAnalyticEvents: 'id, session_id',
        })
            .upgrade((tx) => {
            return tx
                .table('sessions')
                .toCollection()
                .modify((session) => {
                session.startedAt = session.createdAt;
                session.createdBy = Object.assign({}, session.ownerUser);
            });
        });
        this.sessions = this.dexie.table('sessions');
        this.comments = this.dexie.table('comments');
        this.flowStateChangeEvents = this.dexie.table('flowStateChangeEvents');
        this.flowAnalyticEvents = this.dexie.table('flowAnalyticEvents');
        this.recentSessions = this.dexie.table('recentSessions');
    }
    async open() {
        await this.dexie.open();
    }
    close() {
        this.dexie.close();
    }
}
