import React from 'react';
import { Base } from 'lib/cortiUI';
import { ScreenRecorderSettingsTriggerButton } from './ScreenRecorderSettingsTriggerButton';
import { ScreenRecorderTriggerButton } from './ScreenRecordingTriggerButton';
import { ScreenRecorderProvider } from './context';
/**
 * Use with caution. It's Electron only functionality
 */
export const ScreenRecorderControls = () => {
    return (React.createElement(ScreenRecorderProvider, null,
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 3 },
            React.createElement(ScreenRecorderSettingsTriggerButton, null),
            React.createElement(ScreenRecorderTriggerButton, null))));
};
