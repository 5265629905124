import { upperFirst } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { api, } from '@corti/lib/coreApiService';
import { coreStore } from 'browser/stores/storeLocator';
import { SettingsField } from './SettingsField';
import { SettingsSection } from './SettingsSection';
import { TriageSettings } from './TriageSettings';
export const OrgConfiguration = ({ organizationID, triageSettings, onUpdateTriageSettings, }) => {
    const [orgConfig, setOrgConfig] = React.useState([]);
    React.useEffect(() => {
        void loadOrgConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadOrgConfig = async () => {
        const resp = await api.orgConfigs.getGenericValues(organizationID);
        setOrgConfig(resp);
    };
    const handleInputChange = async (value, configNameToUpdate, type, name) => {
        const valuesToUpdate = [
            {
                groupName: configNameToUpdate,
                values: [
                    {
                        config: {
                            type,
                            name,
                        },
                        value,
                    },
                ],
                children: [],
            },
        ];
        try {
            await api.orgConfigs.patchGenericValues(organizationID, valuesToUpdate);
            coreStore.notifications.showNotification({
                type: 'success',
                message: intl.t('adminPanel:status.successfullyUpdated', 'Successfully updated'),
            });
        }
        finally {
            await loadOrgConfig();
        }
    };
    return (React.createElement(React.Fragment, null, orgConfig.map((config) => {
        var _a;
        return (React.createElement(SettingsSection, { key: config.groupName, title: upperFirst(config.groupName) }, (_a = config.values) === null || _a === void 0 ? void 0 :
            _a.map((configValue) => (React.createElement(SettingsField, { key: configValue.config.name, onChangeValue: handleInputChange, fieldInfo: configValue, configNameToUpdate: config.groupName }))),
            config.groupName === 'triage' && (React.createElement(TriageSettings, { triageSettings: triageSettings, onUpdateTriageSettings: onUpdateTriageSettings }))));
    })));
};
