var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ExpressionEvaluator } from '@corti/lib/graphs';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { ExpressionInput } from './ExpressionInput';
export function ExpressionInputConnected(props) {
    const { context, placeholder = 'E.g. $abc && $def || true', includeBlockSuggestions, includeFactSuggestions, validate, onValidate, value } = props, rest = __rest(props, ["context", "placeholder", "includeBlockSuggestions", "includeFactSuggestions", "validate", "onValidate", "value"]);
    const { editor } = useGraphEditorCtx();
    const [suggestions, setSuggestions] = React.useState([]);
    React.useEffect(() => {
        doValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validate]);
    function doValidation() {
        if (validate && value != null) {
            onValidate === null || onValidate === void 0 ? void 0 : onValidate(ExpressionEvaluator.validate(value));
        }
    }
    return (React.createElement(ExpressionInput, Object.assign({}, rest, { value: value, placeholder: placeholder, suggestions: suggestions, resolveItemReference: ({ id }) => {
            const item = editor.logicContext.getRefItemByID(id);
            const node = context === null || context === void 0 ? void 0 : context.node;
            if (item) {
                return Object.assign(Object.assign({}, item), { external: node && item.type !== 'fact'
                        ? !node.contentBuilderContext.hasBlock(item.backingModel)
                        : false });
            }
        }, onBlockSearchRequest: async (query) => {
            const items = await editor.logicContext.queryItems(query, {
                includeBlocks: includeBlockSuggestions,
                includeFacts: includeFactSuggestions,
                context: context,
            });
            setSuggestions(items);
        }, onBlur: doValidation })));
}
