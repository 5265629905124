import { groupBy } from 'lodash';
export const groupMetricSourcesByTitle = (sources) => {
    if (sources.length === 0) {
        return null;
    }
    return groupBy(sources, (it) => it.title);
};
export const getMetricsSourceInput = (groupedSources) => {
    if (!groupedSources) {
        return [];
    }
    return Object.values(groupedSources).flatMap((sources) => {
        return sources.filter((s) => s.isEnabled).map(({ key }) => key);
    }, []);
};
