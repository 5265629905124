import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Loading, MenuItem, Typography } from 'lib/cortiUI';
import { getTeamQuery, updateTeamMemberMutation, } from '../../graphql';
export const SetTeamRole = ({ member, onClose }) => {
    const { t } = useTranslation('teams');
    const [updateTeamMember, updateTeamMemberResult] = useMutation(updateTeamMemberMutation);
    async function handleSetTeamRole() {
        await updateTeamMember({
            refetchQueries: [
                {
                    query: getTeamQuery,
                    variables: {
                        id: member.team.id,
                    },
                },
            ],
            variables: {
                input: {
                    id: member.id,
                    teamRole: member.teamRole === 'MEMBER' ? 'LEAD' : 'MEMBER',
                },
            },
        });
        onClose();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: handleSetTeamRole, gap: 3, disabled: updateTeamMemberResult.loading }, updateTeamMemberResult.loading ? (React.createElement(Loading, null)) : (React.createElement(Typography, { "data-cy": "team-lead-action", variant: "body1" }, member.teamRole === 'MEMBER'
            ? t('teamView.setTeamRole.member', 'Set as Team Lead')
            : t('teamView.setTeamRole.lead', 'Remove as Team Lead'))))));
};
