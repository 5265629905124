import { sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Base, Button, Chip, ModalContent, Separator, Typography } from 'lib/cortiUI';
import { useExploreViewContext } from '../../ExploreViewContext';
export const ScreenRecordingsView = ({ caseID }) => {
    const { t } = useTranslation('explore', { keyPrefix: 'caseView.screenRecorder' });
    const { authToken } = useAuth();
    const { screenRecorderAPI } = useExploreViewContext();
    const [recordings, setRecordings] = React.useState([]);
    const [activeRecording, setActiveRecording] = React.useState();
    React.useEffect(() => {
        getRecordings()
            .then((recs) => setActiveRecording(recs[0]))
            .catch(() => coreStore.notifications.showNotification({
            type: 'error',
            message: t('fetchRecordingsError', 'Failed to fetch recordings.'),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function getRecordings() {
        const res = await screenRecorderAPI.getRecordings({ case_id: caseID });
        const sortedRecordings = sortBy(res, (r) => r.monitorNo);
        setRecordings(sortedRecordings);
        return sortedRecordings;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator, { horizontal: true }),
        React.createElement(ModalContent, null,
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 5 },
                React.createElement(Base, { flex: 1 }, recordings.map((recording) => (React.createElement(Button, { variant: "text", color: recording.id === (activeRecording === null || activeRecording === void 0 ? void 0 : activeRecording.id) ? 'primary' : undefined, key: recording.id, onClick: () => setActiveRecording(recording), fullWidth: true, sx: { textAlign: 'start' } }, `${t('recordingMonitorNumber', 'Monitor number')}: ${recording.monitorNo}`)))),
                React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5, flex: 2 },
                    React.createElement(Base, null,
                        React.createElement(Typography, { variant: "subtitle1", color: "default" }, t('selectedRecording', 'Selected recording ID:')),
                        React.createElement(Chip, { variant: "outlined", color: "primary", label: (activeRecording === null || activeRecording === void 0 ? void 0 : activeRecording.id)
                                ? activeRecording.id
                                : t('noSelectedRecording', 'Not selected') })),
                    activeRecording && (React.createElement("video", { controls: true, width: 450, src: `${screenRecorderAPI.host}/screen-recording-service/api/v1/screen-recordings/${activeRecording.id}/file?token=${authToken}` })))))));
};
