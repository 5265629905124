import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { MenuItem, Typography, UnstyledLink } from 'lib/cortiUI';
export const CopyAction = ({ feedbackFormTemplateID }) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'templatesDataGrid' });
    const history = useHistory();
    return (React.createElement(UnstyledLink, { to: {
            pathname: `${history.location.pathname}/builder`,
            state: { templateID: feedbackFormTemplateID },
        }, className: css({ display: 'flex', alignItems: 'center', justifyContent: 'center' }), render: () => (React.createElement(MenuItem, { "data-cy": "copy-template-btn", gap: 3 },
            React.createElement(ContentCopyRoundedIcon, { color: "action" }),
            React.createElement(Typography, { variant: "body1" }, t('showMore.duplicate', 'Duplicate')))) }));
};
