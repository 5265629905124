import React from 'react';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base } from 'lib/cortiUI';
import { SectionWrapper } from '../components';
import { useInteraction } from '../context';
import { AcknowledgementStatus } from './AcknowledgementStatus';
import { Assessment } from './Assessment';
import { ManualAssessment } from './ManualAssessment';
import { NavigationProvider, useNavigation } from './context';
export const RightSidePanelContent = () => {
    const { currentUserID } = useAuth();
    const { interaction } = useInteraction();
    const { view } = useNavigation();
    return (React.createElement(Base, { display: "grid", gridTemplateRows: ((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) === currentUserID ||
            rbacService.missionControlOtherAssessmentsRead()) &&
            view === 'assessment'
            ? 'auto 1fr'
            : '1fr', flexDirection: "column", overflow: "hidden" },
        ((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) === currentUserID ||
            rbacService.missionControlOtherAssessmentsRead()) &&
            view === 'assessment' && (React.createElement(SectionWrapper, { paddingBottom: 0 },
            React.createElement(AcknowledgementStatus, null))),
        view === 'assessment' && React.createElement(Assessment, null),
        view === 'manual-assessment' && React.createElement(ManualAssessment, null)));
};
export const RightSidePanel = () => {
    return (React.createElement(NavigationProvider, null,
        React.createElement(RightSidePanelContent, null)));
};
