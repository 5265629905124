var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { wait } from '@corti/timers';
import { validateGraph } from './validation';
export class ValidationModule {
    constructor(editor) {
        Object.defineProperty(this, "editor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: editor
        });
        Object.defineProperty(this, "isValidating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "result", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.reset();
    }
    reset() {
        this.isValidating = false;
        this.result = undefined;
    }
    async validate() {
        runInAction(() => {
            this.isValidating = true;
        });
        // we need to flash the screen to give user some feedback that validation is complete
        // when validation is too quick, we give it at least 500ms to complete
        const [result] = await Promise.all([validateGraph(this.editor.model), wait(500)]);
        runInAction(() => {
            this.isValidating = false;
            this.result = result;
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], ValidationModule.prototype, "isValidating", void 0);
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], ValidationModule.prototype, "result", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ValidationModule.prototype, "reset", null);
