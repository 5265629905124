export const dataTransformer = {
    toTranscriptQuery(input) {
        var _a, _b, _c;
        const { time_zone, date_range, term_groups } = input.parameters;
        return {
            id: input.id,
            name: input.name,
            createdAt: new Date(input.created_at),
            createdByUser: {
                id: input.created_by_user.id,
                name: input.created_by_user.name,
            },
            updatedAt: new Date(input.updated_at),
            parameters: {
                timeZone: time_zone,
                termGroups: term_groups.map((it) => ({
                    id: it.id,
                    terms: it.terms,
                    style: { color: it.style.color },
                })),
                userIDs: (_a = input.parameters.user_ids) !== null && _a !== void 0 ? _a : [],
                teamIDs: (_b = input.parameters.team_ids) !== null && _b !== void 0 ? _b : [],
                protocolNames: (_c = input.parameters.protocol_name_ids) !== null && _c !== void 0 ? _c : [],
                dateRange: date_range.type === 'absolute'
                    ? {
                        type: date_range.type,
                        absoluteRange: {
                            start: new Date(date_range.absolute_range.start),
                            end: new Date(date_range.absolute_range.end),
                        },
                    }
                    : {
                        type: date_range.type,
                        relativeRange: {
                            duration: date_range.relative_range.duration,
                            isCurrentPeriodIncluded: date_range.relative_range.is_current_period_included,
                        },
                    },
            },
        };
    },
    fromTranscriptQueryCreateUpdateInput(input) {
        const { termGroups, dateRange } = input;
        return {
            name: input.name,
            parameters: {
                time_zone: input.timeZone,
                term_groups: termGroups.map((it) => ({
                    id: it.id,
                    terms: it.terms,
                    style: { color: it.style.color },
                })),
                date_range: dateRange.type === 'absolute'
                    ? {
                        type: dateRange.type,
                        absolute_range: {
                            start: dateRange.absoluteRange.start.toISOString(),
                            end: dateRange.absoluteRange.end.toISOString(),
                        },
                    }
                    : {
                        type: dateRange.type,
                        relative_range: {
                            duration: dateRange.relativeRange.duration,
                            is_current_period_included: dateRange.relativeRange.isCurrentPeriodIncluded,
                        },
                    },
                user_ids: input.userIDs,
                team_ids: input.teamIDs,
                protocol_name_ids: input.protocolNames,
            },
        };
    },
    fromExportTQCallsInput(input) {
        return {
            time_zone: input.timeZone,
            date_range: {
                start: input.dateRange.start.toISOString(),
                end: input.dateRange.end.toISOString(),
            },
            user_ids: input.userIDs,
            protocol_name_ids: input.protocolNameIDs,
            term_groups: input.termGroups,
        };
    },
};
