var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { ICaseReviewRequestSortDirection, ICaseReviewRequestSortPropertyName, } from '@corti/lib/coreApiService';
export class CaseReviewRequestsSortModel {
    constructor(sort) {
        Object.defineProperty(this, "propertyName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "direction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.reset(sort);
    }
    reset(sort) {
        var _a, _b;
        this.propertyName = (_a = sort === null || sort === void 0 ? void 0 : sort.propertyName) !== null && _a !== void 0 ? _a : ICaseReviewRequestSortPropertyName.RequestCreatedAt;
        this.direction = (_b = sort === null || sort === void 0 ? void 0 : sort.direction) !== null && _b !== void 0 ? _b : ICaseReviewRequestSortDirection.Descending;
    }
    setPropertyName(propertyName) {
        this.propertyName = propertyName;
    }
    setDirection(direction) {
        this.direction = direction;
    }
    get caseReviewRequestSortInput() {
        return {
            propertyName: this.propertyName,
            direction: this.direction,
        };
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsSortModel.prototype, "propertyName", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseReviewRequestsSortModel.prototype, "direction", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CaseReviewRequestsSortModel.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CaseReviewRequestsSortModel.prototype, "setPropertyName", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CaseReviewRequestsSortModel.prototype, "setDirection", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseReviewRequestsSortModel.prototype, "caseReviewRequestSortInput", null);
