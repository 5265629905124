var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { ExpressionEvaluator } from '../utils';
export class ChecklistStore {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "evergreenChecklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "flexibleChecklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allChecklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_checklistProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_autoCheckedEntryIds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.observer = new Observer();
        this.allChecklists = this.flowStore.graphTraverser.getChecklists();
        this.evergreenChecklists = this.allChecklists
            .filter((c) => !c.visibilityConditionEnabled)
            .map((c) => c.id);
        this.flexibleChecklists = this.allChecklists
            .filter((c) => c.visibilityConditionEnabled)
            .map((c) => c.id);
        runInAction(() => {
            this._checklistProgress = {
                expandedChecklistsById: {},
                manualChecksById: {},
            };
            this._autoCheckedEntryIds = [];
        });
        autorun(() => this.updateAutomaticChecks());
    }
    get currentChecklists() {
        // Short-circuit if no flexible checklists
        if (this.flexibleChecklists.length === 0) {
            return this.evergreenChecklists;
        }
        // Set up the expression environment
        const environment = ExpressionEvaluator.createEnvFromFlowValues({
            blockValues: this.flowStore.blockValueStore.all,
            factValues: this.flowStore.factValueStore.all,
            graph: this.flowStore.graphTraverser,
        });
        // Loop through all flexible checklists, to see if any of them should be visible
        const visibleChecklists = this.allChecklists
            .filter((c) => this.flexibleChecklists.includes(c.id))
            .filter(({ visibilityConditionExpression: expression }) => ExpressionEvaluator.evaluate({ expression, environment }))
            .map((c) => c.id);
        // Current checklists are the evergreen ones, plus the visible flexible ones
        return this.evergreenChecklists.concat(visibleChecklists);
    }
    get checklistProgress() {
        return this._checklistProgress;
    }
    get autoCheckedEntryIds() {
        return this._autoCheckedEntryIds;
    }
    updateAutomaticChecks() {
        // Create a map of all visible automatic entries
        const automaticEntries = this.allChecklists
            .filter((c) => this.currentChecklists.includes(c.id))
            .map((c) => c.entries)
            .flat()
            .filter((e) => e.automaticCompletionEnabled);
        // Short-circuit if no automatic entries
        if (automaticEntries.length === 0) {
            runInAction(() => {
                this._autoCheckedEntryIds = [];
            });
            return;
        }
        // Set up the environment
        const environment = ExpressionEvaluator.createEnvFromFlowValues({
            blockValues: this.flowStore.blockValueStore.all,
            factValues: this.flowStore.factValueStore.all,
            graph: this.flowStore.graphTraverser,
        });
        // Evaluate all automatic entries to get a list of ids that should be checked
        const autoCheckedEntryIds = automaticEntries
            .filter(({ automaticCompletionExpression: expression }) => ExpressionEvaluator.evaluate({ expression, environment }))
            .map((e) => e.id);
        // Then update the snapshot
        runInAction(() => {
            this._autoCheckedEntryIds = autoCheckedEntryIds;
        });
    }
    get updateChecklistProgress() {
        return action((event) => {
            switch (event.type) {
                case 'expand':
                case 'collapse': {
                    const expanded = event.type === 'expand';
                    const { checklistId } = event;
                    this._checklistProgress.expandedChecklistsById[checklistId] = event.type === 'expand';
                    this.observer.fireEvent('checklist-updated', { checklistId, expanded });
                    break;
                }
                case 'check':
                case 'uncheck': {
                    const checked = event.type === 'check';
                    const { checklistId, entryId } = event;
                    this._checklistProgress.manualChecksById[entryId] = checked;
                    this.observer.fireEvent('checklist-entry-updated', {
                        checklistId,
                        entryId,
                        checked,
                        source: 'manual',
                    });
                    break;
                }
                case 'launch': {
                    const { nodeId } = event;
                    let step = this.flowStore.stepStore.getLastStepByNodeID(nodeId);
                    if (!step)
                        return;
                    this.flowStore.changeActiveStep({
                        step,
                        source: 'user-action',
                        sourceData: { location: 'checklist-entry' },
                    });
                }
            }
        });
    }
    onChecklistUpdate(cb) {
        return this.observer.on('checklist-updated', cb);
    }
    onChecklistEntryUpdate(cb) {
        return this.observer.on('checklist-entry-updated', cb);
    }
    updateChecklist(event) {
        // Set flag for given checklist inside MobX action
        runInAction(() => {
            this._checklistProgress.expandedChecklistsById[event.data.checklistId] = event.data.expanded;
        });
    }
    updateChecklistEntry(event) {
        // Set check status for given checklist entry inside MobX action
        runInAction(() => {
            if (event.data.source === 'manual') {
                this._checklistProgress.manualChecksById[event.data.entryId] = event.data.checked;
            }
            else {
                // This doesn't happen - we don't send events for automatic checks.
                // They're resolved locally by every participant from the regular flow events
            }
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ChecklistStore.prototype, "_checklistProgress", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], ChecklistStore.prototype, "_autoCheckedEntryIds", void 0);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ChecklistStore.prototype, "currentChecklists", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ChecklistStore.prototype, "checklistProgress", null);
__decorate([
    computed({
        // Two lists of automatic checks are equal, if they have the same members, reagardless of order
        // If they are equal, don't trigger observers
        equals: (a, b) => a.slice().sort().join(',') === b.slice().sort().join(','),
    }),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ChecklistStore.prototype, "autoCheckedEntryIds", null);
