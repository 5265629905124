import React from 'react';
import { intl } from '@corti/i18n';
import { Base, Box, ButtonBase, Typography } from 'lib/cortiUI';
import { Item } from './Item';
export function AcceptedTimelineEntriesList(props) {
    return (React.createElement(Base, { display: "grid", gap: 4, "data-cy": "feedback-form-accepted-timeline-entry" }, props.definitions.map((definition) => {
        const { isEntryCreatable, color, title, source: { entityType, type, entityID }, } = definition;
        const handleClick = () => props.onEntryClick(definition);
        if (isEntryCreatable) {
            return (React.createElement(ButtonBase, { key: `${entityType}${entityID !== null && entityID !== void 0 ? entityID : ''}${type}`, onClick: handleClick },
                React.createElement(Item, { clickable: true, borderColor: color },
                    React.createElement(Box, { justifyItems: "center", width: 1 },
                        React.createElement(Typography, { mb: 2, variant: "caption", color: "primary" }, intl.t('feedbackForm.missingAnnotationsLabel', 'Missing Annotation')),
                        React.createElement(Typography, { fontWeight: 600 }, title)))));
        }
    })));
}
