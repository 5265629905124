import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { Base, ConfirmableTarget, IconButton, Typography } from 'lib/cortiUI';
export function Toolbar(props) {
    const { showTools = false, totalRowCount, onDelete } = props;
    return (React.createElement(Base, { px: 3, minHeight: 45, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
        showTools && (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" },
            React.createElement(ConfirmableTarget, { message: intl.t('caseReviewRequestsModule:caseReviewRequestDeleteConfirmation.text', 'The data will permanently be removed from your organization. This action cannot be reverted'), onConfirm: onDelete, color: "error" }, ({ onClick }) => (React.createElement(IconButton, { icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), onClick: onClick }))))),
        React.createElement(Typography, { ml: "auto", variant: "caption", "data-cy": "review-requests-count" }, intl.t('caseReviewRequestsModule:caseReviewRequestsCount', {
            count: totalRowCount,
            defaultValue_one: `{{count}} result found`,
            defaultValue_other: `{{count}} results found`,
        }))));
}
