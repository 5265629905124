var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import Mousetrap from 'mousetrap';
import { Navigator } from './Navigator';
const NAVIGATE_PREV = 'left';
const NAVIGATE_NEXT = 'right';
export class FlowViewStore {
    constructor(flowStore) {
        Object.defineProperty(this, "keybinder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardBindingElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardShortcutsEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "screenSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFlowAnimating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setScreenSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (size) => {
                this.screenSize = size;
            }
        });
        Object.defineProperty(this, "setIsFlowAnimating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.isFlowAnimating = value;
            }
        });
        Object.defineProperty(this, "setKeyboardBindingElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bindingElement) => {
                this.keyboardBindingElement = bindingElement;
            }
        });
        Object.defineProperty(this, "enableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.keyboardShortcutsEnabled) {
                    return;
                }
                this.keyboardShortcutsEnabled = true;
                this.keybinder = new Mousetrap(this.keyboardBindingElement);
                this.keybinder.bind(NAVIGATE_PREV, this.handleFlowStepBackwards);
                this.keybinder.bind(NAVIGATE_NEXT, this.handleFlowStepForward);
            }
        });
        Object.defineProperty(this, "disableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (!this.keyboardShortcutsEnabled) {
                    return;
                }
                this.keyboardShortcutsEnabled = false;
                (_a = this.keybinder) === null || _a === void 0 ? void 0 : _a.reset();
                this.keybinder = undefined;
            }
        });
        Object.defineProperty(this, "handleFlowStepForward", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.isFlowAnimating) {
                    this.navigator.navigateToNextStep();
                }
            }
        });
        Object.defineProperty(this, "handleFlowStepBackwards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.isFlowAnimating) {
                    this.navigator.navigateToPrevStep();
                }
            }
        });
        makeObservable(this);
        this.keybinder = undefined;
        this.keyboardBindingElement = undefined;
        this.keyboardShortcutsEnabled = false;
        this.navigator = new Navigator(flowStore);
        runInAction(() => {
            this.screenSize = 'extra-large';
            this.isFlowAnimating = false;
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], FlowViewStore.prototype, "screenSize", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], FlowViewStore.prototype, "isFlowAnimating", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], FlowViewStore.prototype, "setScreenSize", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], FlowViewStore.prototype, "setIsFlowAnimating", void 0);
