export function getCortiLiveSubdomain(host) {
    const domain = /(.+)\.corti\.(app|live)/;
    const match = host.match(domain);
    if (!match) {
        return false;
    }
    const [_, firstCapture] = match;
    return firstCapture;
}
export function shouldUseSubdomainAsEnv(host) {
    const excludedSubdomains = ['master'];
    const subdomain = getCortiLiveSubdomain(host);
    return !!subdomain && !excludedSubdomains.includes(subdomain);
}
