var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { intl } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { isValidEmail } from '@corti/strings';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { Box, Button, TextField, Typography } from 'lib/cortiUI';
// @ts-ignore
let PasswordResetEmailForm = class PasswordResetEmailForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                email: (this.props.initialEmail || '').toLowerCase(),
                errorMesssage: '',
            }
        });
        Object.defineProperty(this, "handleInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({ email: e.target.value });
            }
        });
        Object.defineProperty(this, "isFormValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return isValidEmail(this.state.email);
            }
        });
        Object.defineProperty(this, "handleFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (e) => {
                e.preventDefault();
                this.setState({ errorMesssage: '' });
                try {
                    await api.auth.sendPasswordResetLink(this.state.email);
                    this.props.onSuccess(this.state.email);
                }
                catch (e) {
                    this.setState({ errorMesssage: intl.t('remoteServerErrorMsg', 'Something went wrong') });
                }
            }
        });
    }
    render() {
        var _a;
        const { email, errorMesssage } = this.state;
        return (React.createElement(Box, { "data-cy": "password-reset-form" },
            React.createElement("form", { className: css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: (_a = this.props.theme) === null || _a === void 0 ? void 0 : _a.space[6],
                }), onSubmit: this.handleFormSubmit },
                React.createElement(DialogContentText, null, intl.t('team:passwordReset.resetCodeSendNotice', 'Enter your registrated email below and recieve a verification code to reset your password.')),
                React.createElement(TextField, { fullWidth: true, label: intl.t('email', 'Email'), type: "email", value: email, onChange: this.handleInputChange, name: "password-reset-email", inputProps: {
                        'data-cy': 'password-reset-email-input',
                    } }),
                React.createElement(Button, { className: css({ alignSelf: 'flex-end' }), variant: "text", type: "submit", "data-cy": "password-reset-btn", color: "primary", disabled: !this.isFormValid() }, intl.t('team:send', 'Send'))),
            errorMesssage && (React.createElement(Typography, { variant: "caption", textAlign: "center", color: "error", pb: 3 }, errorMesssage))));
    }
};
PasswordResetEmailForm = __decorate([
    withTheme
], PasswordResetEmailForm);
export { PasswordResetEmailForm };
