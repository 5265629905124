import { groupBy, isEmpty } from 'lodash';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { getReviewsByCall, getTemplatesList, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Typography } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { useInteraction } from '../../context';
import { TemplateSelector } from '../components';
import { useNavigation } from '../context';
import { ActiveReview } from './ActiveReview';
import { AssessmentLoading } from './AssessmentLoading';
import { Header } from './Header';
import { NoAssessment } from './NoAssessment';
import { Reviews } from './Reviews';
export const Assessment = () => {
    const { interaction } = useInteraction();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.reviews' });
    const { initialReviewId } = useNavigation();
    const [selectedReview, setSelectedReview] = React.useState();
    const [selectedTemplate, setSelectedTemplate] = React.useState();
    const [reviews, setReviews] = React.useState([]);
    const [templates, setTemplates] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const groupedReviewsRef = React.useRef({});
    const filteredTemplatesRef = React.useRef([]);
    React.useEffect(() => {
        void fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction === null || interaction === void 0 ? void 0 : interaction.call_id]);
    // Set initial values after data is fetched
    React.useEffect(() => {
        var _a;
        if (!templates.length || !reviews.length)
            return;
        const groupedReviews = groupBy(reviews, 'template_id');
        const filteredTemplates = templates
            .filter((it) => it.id in groupedReviews)
            .sort((a, b) => {
            if (a.is_default !== b.is_default)
                return a.is_default ? -1 : 1;
            return a.name.localeCompare(b.name, undefined, { numeric: true });
        });
        if (!filteredTemplates.length || isEmpty(groupedReviews))
            return;
        filteredTemplatesRef.current = filteredTemplates;
        groupedReviewsRef.current = groupedReviews;
        if (initialReviewId)
            return setInitialData(initialReviewId, filteredTemplates, reviews);
        const initialTemplate = (_a = filteredTemplates.find((it) => it.is_default)) !== null && _a !== void 0 ? _a : filteredTemplates[0];
        setSelectedTemplate(initialTemplate);
        const initialReview = groupedReviews[initialTemplate.id][0];
        setSelectedReview(initialReview);
    }, [reviews, templates, initialReviewId]);
    function setInitialData(initialReviewId, templates, reviews) {
        const initialReview = reviews.find((it) => it.id === initialReviewId);
        if (initialReview) {
            setSelectedReview(initialReview);
            setSelectedTemplate(templates.find((it) => it.id === initialReview.template_id));
            return;
        }
    }
    async function fetchData() {
        setIsLoading(true);
        await Promise.all([fetchReviews(), fetchTemplates()]);
        setIsLoading(false);
    }
    async function fetchReviews() {
        try {
            if (!interaction)
                return;
            const res = await getReviewsByCall(interaction.call_id);
            setReviews(res);
        }
        catch (e) {
            console.error('Could not fetch reviews', e);
            coreStore.notifications.showNotification({
                message: t('reviewsError', 'Failed to get Assessments'),
                type: 'error',
            });
        }
    }
    async function fetchTemplates() {
        try {
            if (!interaction)
                return;
            const res = await getTemplatesList({ published: true });
            setTemplates(res);
        }
        catch (error) {
            console.error('failed to fetch template:', error);
            coreStore.notifications.showNotification({
                message: t('templatesError', 'Failed to get Templates'),
                type: 'error',
            });
        }
    }
    if (isLoading)
        return React.createElement(AssessmentLoading, null);
    if (!reviews.length || !templates.length || !selectedReview || !selectedTemplate)
        return React.createElement(NoAssessment, null);
    return (React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", gap: 6, overflow: "hidden" },
        React.createElement(Header, null),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, pt: 2, pr: 2 },
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 3 },
                React.createElement(Typography, { variant: "body2" }, t('template', 'Template')),
                React.createElement(TemplateSelector, { selectedTemplateID: selectedTemplate.id, onChange: (id) => {
                        setSelectedTemplate(filteredTemplatesRef.current.find((it) => it.id === id));
                        setSelectedReview(groupedReviewsRef.current[id][0]);
                    }, options: filteredTemplatesRef.current.map((it) => ({
                        id: it.id,
                        default: it.is_default,
                        primaryText: it.name,
                        secondaryText: `${t('lastAssessment', 'Last Assessment')}: ${formatDateTime(new Date(groupedReviewsRef.current[it.id][0].created_at))}`,
                        rightText: groupedReviewsRef.current[it.id].length.toString(),
                        rightTextTooltip: t('reviewsCountDetails', 'Number of submitted assessments with this template'),
                    })) })),
            React.createElement(Reviews, { reviews: groupedReviewsRef.current[selectedTemplate.id], selectedReview: selectedReview, onSelectReview: (review) => {
                    setSelectedReview(review);
                }, selectedTemplate: selectedTemplate })),
        React.createElement(ActiveReview, { selectedReview: selectedReview, selectedTemplate: selectedTemplate })));
};
