var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ButtonBase } from 'lib/cortiUI/components/Button';
import { Typography } from 'lib/cortiUI/components/Typography';
export function ToolbarTextButton(_a) {
    var { size, children, isActive } = _a, buttonBaseProps = __rest(_a, ["size", "children", "isActive"]);
    const theme = useTheme();
    return (React.createElement(CommonButtonBase, Object.assign({ style: {
            color: isActive ? theme.palette.primary.main : undefined,
        } }, buttonBaseProps),
        React.createElement(Typography, { variant: "button", color: "inherit", style: {
                margin: 0,
            } }, children)));
}
export function ToolbarIconButton(_a) {
    var { icon, isActive } = _a, buttonBaseProps = __rest(_a, ["icon", "isActive"]);
    const theme = useTheme();
    return (React.createElement(CommonButtonBase, Object.assign({ style: { color: isActive ? theme.palette.primary.main : undefined } }, buttonBaseProps), icon));
}
export function ToolbarIconButtonColorSetter(_a) {
    var { icon, color } = _a, buttonBaseProps = __rest(_a, ["icon", "color"]);
    const theme = useTheme();
    return (React.createElement(CommonButtonBase, Object.assign({ style: { flexDirection: 'column', color: theme.palette.grey[500] } }, buttonBaseProps), icon));
}
function CommonButtonBase(props) {
    const theme = useTheme();
    return (React.createElement(ButtonBase, Object.assign({ width: 34, height: 34, className: css({ color: theme.palette.grey[500] }), onMouseDown: (e) => {
            // so that editor doesn't lose focus
            e.preventDefault();
        } }, props)));
}
