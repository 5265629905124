import EditIcon from '@mui/icons-material/Edit';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { Base, Button, Tooltip } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { useParametersContext } from '../../context/ParametersContext';
export const EditModeButton = () => {
    const { t } = useTranslation('performanceApp');
    const { currentUserID } = useAuth();
    const { toggleEditMode } = useGlobalContext();
    const { locked, createdBy, resetView } = useParametersContext();
    const handleClick = () => {
        resetView();
        toggleEditMode();
    };
    return (React.createElement(Base, { display: "flex", gap: 4, alignItems: "center" },
        locked && (createdBy === null || createdBy === void 0 ? void 0 : createdBy.id) === currentUserID && (React.createElement(Tooltip, { title: t('editBtnTooltip', 'Only you can edit this View. You can always change this setting from Edit > Settings > Lock for edits.'), placement: "top" },
            React.createElement(LockOpenRoundedIcon, { fontSize: "small", color: "action" }))),
        React.createElement(Button, { "data-cy": "performance-view-edit-btn", variant: "outlined", size: "small", onClick: handleClick, startIcon: React.createElement(EditIcon, { fontSize: "small", color: "action" }) }, t('editBtn', 'Edit'))));
};
