import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { BaseGraphEditorNode, ViewNodeModel } from 'lib/graphEditor/canvas/nodes';
import { Clipboard } from '../clipboard';
import { LinkModel } from './LinkModel';
import { getRectForNodes, getViewportToFitRect, rectMidPoint } from './geometry';
const selectEntities = (data) => {
    return {
        run: ({ editor }) => {
            if (data.type === 'replace') {
                editor.state.setSelectedEntityIDs(new Set(data.ids));
                return;
            }
            if (data.type === 'add') {
                editor.state.addSelectedEntities(data.ids);
                return;
            }
        },
    };
};
const unselectEntities = (data) => {
    return {
        run: ({ editor }) => {
            editor.state.removeSelectedEntities(data.ids);
        },
    };
};
const fitNodesIntoView = (data) => {
    return {
        run: ({ editor }) => {
            const nodes = data.nodeIDs
                .map((id) => editor.model.getNodeByID(id))
                .filter((n) => n !== undefined);
            if (!nodes.length) {
                return;
            }
            // this could be handled better, but it is considered a invalid input
            // if passed nodes from different branches
            const branch = nodes[0].branchContext;
            editor.state.setActiveBranch(branch.id);
            const canvasBounds = editor.getCanvasBounds();
            if (!canvasBounds) {
                return;
            }
            const viewport = getViewportToFitRect(getRectForNodes(nodes), canvasBounds);
            branch.setViewport(viewport);
        },
    };
};
const connectNodes = (data) => {
    let link;
    return {
        run: ({ editor }) => {
            const sourceNode = editor.model.getNodeByID(data.source.nodeID);
            if (!sourceNode) {
                return;
            }
            const port = sourceNode.ports.find((p) => p.id === data.source.portID);
            if (!port) {
                return;
            }
            const targetNode = editor.model.getNodeByID(data.target.nodeID);
            if (!targetNode || !targetNode.portIn) {
                return;
            }
            link = port.link(targetNode.portIn);
        },
        revert: () => {
            if (link) {
                link.remove();
            }
        },
    };
};
const moveNodes = (data) => {
    let nodes = [];
    return {
        run: ({ editor }) => {
            data.nodes.forEach((n) => {
                var _a;
                let node = editor.model.getNodeByID(n.id);
                if (!node) {
                    return;
                }
                let prevPos = (_a = n.initialPosition) !== null && _a !== void 0 ? _a : node.position;
                nodes.push({ node, prevPos });
                node.moveNodeTo(n.position.x, n.position.y);
            });
        },
        revert: () => {
            nodes.forEach((n) => {
                n.node.moveNodeTo(n.prevPos.x, n.prevPos.y);
            });
        },
    };
};
const addNode = (data) => {
    let node;
    return {
        run: ({ editor }) => {
            var _a;
            node = editor.nodeFactory.createNodeForType(data.type);
            if (!node) {
                return;
            }
            if (data.id) {
                node.id = data.id;
            }
            const position = (_a = data.position) !== null && _a !== void 0 ? _a : editor.state.activeBranch.pointerPosition;
            node.moveNodeTo(position.x, position.y);
            editor.state.activeBranch.addNode(node);
            editor.state.setSelectedEntityIDs(new Set([node.id]));
        },
        revert: () => {
            if (node) {
                node.remove();
            }
        },
    };
};
const deleteEntities = (data) => {
    let nodes = new Set();
    let links = new Set();
    return {
        run: ({ editor }) => {
            data.ids.forEach((id) => {
                const entity = editor.model.getEntitityByID(id);
                if (entity instanceof LinkModel) {
                    links.add(entity);
                    entity.remove();
                    return;
                }
                if (entity instanceof BaseGraphEditorNode) {
                    nodes.add(entity);
                    links = new Set([...links, ...entity.links]);
                    entity.remove();
                }
            });
        },
        revert: () => {
            nodes.forEach((node) => {
                node.parent.addNode(node);
            });
            links.forEach((l) => {
                l.inPort.addLink(l);
                l.outPort.addLink(l);
            });
        },
    };
};
const changeGraphStartNode = (data) => {
    let prevStartNodeID;
    let nodeID;
    return {
        run: ({ editor }) => {
            prevStartNodeID = editor.model.startNodeID;
            let n = editor.model.getNodeByID(data.nodeID);
            if (n instanceof ViewNodeModel) {
                nodeID = n.id;
                editor.model.setStartNodeID(nodeID);
            }
        },
        revert: ({ editor }) => {
            editor.model.setStartNodeID(prevStartNodeID);
        },
    };
};
const changeBranchStartNode = (data) => {
    let node;
    let branch;
    let prevStartNodeID;
    return {
        run: ({ editor }) => {
            node = editor.model.getNodeByID(data.nodeID);
            branch = editor.model.getBranchByID(data.branchID);
            if (branch && node instanceof ViewNodeModel) {
                branch.setStartNode(node.id);
                prevStartNodeID = node.id;
            }
        },
        revert: () => {
            branch === null || branch === void 0 ? void 0 : branch.setStartNode(prevStartNodeID);
        },
    };
};
const addBranch = (data) => {
    let branchID;
    let currentActiveBranch;
    return {
        run: ({ editor }) => {
            currentActiveBranch = editor.state.activeBranch;
            const b = editor.model.addBranch(data);
            branchID = b.id;
            editor.state.setActiveBranch(branchID);
        },
        revert: ({ editor }) => {
            editor.model.getBranchByID(branchID).remove();
            editor.state.setActiveBranch(currentActiveBranch.id);
        },
    };
};
const deleteBranch = (data) => {
    let branch;
    return {
        run: ({ editor }) => {
            branch = editor.model.getBranchByID(data.branchID);
            if (branch && editor.model.branches.length > 1) {
                branch.remove();
                editor.state.setActiveBranch(editor.model.branches[0].id);
            }
        },
        revert: ({ editor }) => {
            if (branch) {
                editor.model.addPreparedBranch(branch);
                editor.state.setActiveBranch(branch.id);
            }
        },
    };
};
const copyNodes = (data) => {
    return {
        run: async ({ editor }) => {
            const nodes = data.nodeIDs
                .map((id) => editor.model.getNodeByID(id))
                .filter((it) => it != null);
            await Clipboard.write('canvas-nodes', ElementSerializer.serialize(nodes));
        },
    };
};
const pasteNodes = () => {
    return {
        run: async ({ editor }) => {
            const s = await Clipboard.read('canvas-nodes');
            if (!s)
                return;
            const d = await ElementSerializer.deserialize(s);
            const pointerPos = editor.state.activeBranch.pointerPosition;
            const nodes = [];
            d.entities.forEach((entity) => {
                if (entity instanceof BaseGraphEditorNode) {
                    nodes.push(entity);
                }
            });
            let clonedNodes = [];
            const boundingRect = getRectForNodes(nodes);
            if (nodes.length) {
                const basePoint = rectMidPoint(boundingRect);
                nodes.forEach((node) => {
                    const cloned = node.copy();
                    clonedNodes.push(cloned);
                    const offsetFromBasePoint = {
                        x: cloned.position.x - basePoint.x,
                        y: cloned.position.y - basePoint.y,
                    };
                    cloned.moveNodeTo(offsetFromBasePoint.x + pointerPos.x, offsetFromBasePoint.y + pointerPos.y);
                    editor.state.activeBranch.addNode(cloned);
                });
            }
            editor.dispatch({
                type: 'canvas.selectEntities',
                data: { ids: clonedNodes.map((n) => n.id), type: 'replace' },
            });
        },
    };
};
const addBranchKeyword = (data) => {
    let kwdIdx;
    let branch;
    return {
        run({ editor }) {
            branch = editor.model.getBranchByID(data.branchID);
            if (!branch) {
                return;
            }
            let normalisedKwd = data.keyword.trim();
            kwdIdx = branch.keywords.push(normalisedKwd) - 1;
        },
        revert() {
            if (kwdIdx !== undefined && branch !== undefined) {
                branch.keywords.splice(kwdIdx, 1);
            }
        },
    };
};
const deleteBranchKeyword = (data) => {
    let branch;
    let keyword;
    return {
        run({ editor }) {
            branch = editor.model.getBranchByID(data.branchID);
            if (!branch) {
                return;
            }
            keyword = branch.keywords[data.idx];
            if (keyword === undefined) {
                return;
            }
            branch.keywords.splice(data.idx, 1);
        },
        revert() {
            if (branch !== undefined && keyword !== undefined) {
                branch.keywords.splice(data.idx, 0, keyword);
            }
        },
    };
};
export function registerCommands(editor) {
    editor.registerCommandDescriptor({
        type: 'canvas.selectEntities',
        createHandler: selectEntities,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.unselectEntities',
        createHandler: unselectEntities,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.fitNodesIntoView',
        createHandler: fitNodesIntoView,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.connectNodes',
        createHandler: connectNodes,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.moveNodes',
        createHandler: moveNodes,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.addNode',
        createHandler: addNode,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.deleteEntities',
        createHandler: deleteEntities,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.changeGraphStartNode',
        createHandler: changeGraphStartNode,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.changeBranchStartNode',
        createHandler: changeBranchStartNode,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.addBranch',
        createHandler: addBranch,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.deleteBranch',
        createHandler: deleteBranch,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.copyNodes',
        createHandler: copyNodes,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.pasteNodes',
        createHandler: pasteNodes,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.addBranchKeyword',
        createHandler: addBranchKeyword,
    });
    editor.registerCommandDescriptor({
        type: 'canvas.deleteBranchKeyword',
        createHandler: deleteBranchKeyword,
    });
}
