import { useApolloClient } from '@apollo/client';
import { observer } from 'mobx-react';
import React from 'react';
import { api, deleteMediaAssetMutation, getMediaAssetsQuery, } from '@corti/lib/coreApiService';
import { gqlMediaTypeToMediaAsset } from '@corti/lib/graphs/media/graphqlAdapter';
import { useDelayedQuery } from '@corti/react';
import { useAuth } from 'core/auth/browser';
import { MediaLibraryView } from 'lib/graphEditor/media/mediaLibrary/MediaLibraryView';
export const MediaLibraryPage = observer(function MediaLibraryPage() {
    const auth = useAuth();
    const apollo = useApolloClient();
    const getMediaAssets = useDelayedQuery(getMediaAssetsQuery, {
        variables: {
            organizationID: auth.organization.id,
        },
    });
    async function handleArchiveSelected(mediaAssets) {
        mediaAssets.forEach((it) => apollo.mutate({
            mutation: deleteMediaAssetMutation,
            variables: { id: it.id },
            optimisticResponse: () => {
                return { deleteMediaAsset: true };
            },
            update: (store, { data }) => {
                const didDelete = data === null || data === void 0 ? void 0 : data.deleteMediaAsset;
                if (!didDelete) {
                    return;
                }
                const q = store.readQuery({
                    query: getMediaAssetsQuery,
                    variables: {
                        organizationID: auth.organization.id,
                    },
                });
                if (!q) {
                    return;
                }
                store.writeQuery({
                    query: getMediaAssetsQuery,
                    variables: {
                        organizationID: auth.organization.id,
                    },
                    data: Object.assign(Object.assign({}, q), { mediaAssets: q.mediaAssets.filter((m) => m.id !== it.id) }),
                });
            },
        }));
    }
    const mediaItems = React.useMemo(() => {
        if (!getMediaAssets.data) {
            return [];
        }
        return getMediaAssets.data.mediaAssets.map(gqlMediaTypeToMediaAsset);
    }, [getMediaAssets]);
    return (React.createElement(MediaLibraryView, { mediaAssets: mediaItems, fileUploadImplementation: async (file, onProgress) => {
            await api.media.createMediaAsset(file, auth.organization.id, onProgress);
            await getMediaAssets.refetch();
        }, onArchiveSelected: handleArchiveSelected }));
});
