import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Dialog } from 'lib/cortiUI';
import { useChecklistRouting } from '../useChecklistRouting';
import { useChecklistStorage } from '../useChecklistStorage';
import { ImportContext } from './ImportContext';
import { ImportResultTable } from './ImportResultTable';
import { useJSONImport } from './useJSONImport';
export function Import({ children }) {
    var _a;
    const { t } = useTranslation('libGraphEditor');
    const formId = 'checklist-import-form';
    const { closeDialog } = useChecklistRouting();
    const { importChecklists } = useChecklistStorage();
    const { setRawJSON, result } = useJSONImport();
    const contextValue = {
        hasError: (_a = result === null || result === void 0 ? void 0 : result.hasError) !== null && _a !== void 0 ? _a : false,
        errorMessage: (result === null || result === void 0 ? void 0 : result.hasError) ? result.errorMessage : null,
        setRawJSON,
    };
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (result && !result.hasError) {
            const importableChecklists = result.checklists
                .filter((importChecklist) => !importChecklist.hasError)
                .map((importChecklist) => importChecklist.list);
            if (importableChecklists.length > 0) {
                importChecklists(importableChecklists);
                closeDialog();
            }
        }
    };
    const canImport = result && !result.hasError && result.checklists.some((checklist) => !checklist.hasError);
    return (React.createElement(Dialog, { size: "large", open: true, title: t('checklist.import.dialogTitle', 'Import checklists'), actionButtons: React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", onClick: closeDialog }, t('checklist.import.cancelBtn', 'Cancel')),
            React.createElement(Button, { type: "submit", form: formId, disabled: !canImport, color: "primary" }, t('checklist.import.submitBtn', 'Import'))), onClose: closeDialog },
        React.createElement("form", { id: formId, onSubmit: onSubmit },
            React.createElement(ImportContext.Provider, { value: contextValue }, children),
            result && !result.hasError && React.createElement(ImportResultTable, { result: result }))));
}
