import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export function AssessmentLoading() {
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 },
        React.createElement(Skeleton, { variant: "rounded", height: 70 }),
        React.createElement(Base, null,
            React.createElement(Skeleton, { variant: "text" }),
            React.createElement(Skeleton, { variant: "rounded", height: 150 })),
        React.createElement(Skeleton, { variant: "rounded", height: 180 }),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
            React.createElement(Skeleton, { variant: "rounded", height: 90 }),
            React.createElement(Skeleton, { variant: "rounded", height: 90 }),
            React.createElement(Skeleton, { variant: "rounded", height: 90 }))));
}
