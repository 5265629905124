import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { shortenID } from './shortid';
/**
 * Base class for all `GraphEditor` entities
 *
 * Entities is anything that can be contained in a `GraphEditorModel`
 */
export class BaseEntity extends Observer {
    constructor(parent) {
        super();
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "parent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = uuid();
        this.parent = parent;
    }
    get shortid() {
        return shortenID(this.id);
    }
    setParent(parent) {
        this.parent = parent;
    }
    get graphEditor() {
        var _a;
        return (_a = this.graphEditorModelContext) === null || _a === void 0 ? void 0 : _a.ctx;
    }
    get graphEditorModelContext() {
        if (this.parent instanceof BaseEntity) {
            return this.parent.graphEditorModelContext;
        }
        return this.parent;
    }
    getEncodableData() {
        return {
            id: this.id,
        };
    }
    deserialize(entity, _ctx) {
        this.id = entity.id;
    }
    copy() {
        const i = new this.constructor();
        i.id = uuid();
        return i;
    }
}
