import jexl from 'jexl';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInYears, } from '@corti/date';
import { ExpressionUtils } from './expressionUtils';
// Setup jexl custom transforms
jexl.addTransform('contains', (value, input) => {
    if (typeof value === 'string' && typeof input === 'string') {
        return value.includes(input);
    }
    if (Array.isArray(value)) {
        return value.includes(input);
    }
    return false;
});
// Returns current time
jexl.addFunction('now', () => {
    return new Date().toISOString();
});
// Calculates the difference between two dates in the specified units
jexl.addFunction('dateDiff', (date1, date2, units) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    switch (units) {
        case 'year':
            return differenceInYears(d1, d2);
        case 'month':
            return differenceInMonths(d1, d2);
        case 'day':
            return differenceInDays(d1, d2);
        case 'hour':
            return differenceInHours(d1, d2);
        case 'minute':
            return differenceInMinutes(d1, d2);
        case 'second':
            return differenceInSeconds(d1, d2);
        default:
            return 0;
    }
});
// Count values
jexl.addFunction('countValues', (...args) => {
    return args.filter(Boolean).length;
});
// Flattens array by depth 1, then count values
jexl.addFunction('countNestedValues', (...args) => {
    return args.flat().filter(Boolean).length;
});
// Need to align with the three valued logic truth tables
jexl.addBinaryOp('||', 10, (left, right) => {
    if (left == null && right === false) {
        return null;
    }
    return left || right;
});
jexl.addBinaryOp('&&', 11, (left, right) => {
    if (left === false || right === false) {
        return false;
    }
    return left && right;
});
jexl.addUnaryOp('!', (right) => {
    if (right == null)
        return null;
    return !right;
});
jexl.addUnaryOp('?', (right) => {
    return right == null;
});
function encodeVariableName(name) {
    return '$'.concat(name).replace(/(\.|-|:)/g, '_');
}
function parseExpression(expr) {
    const escaped = ExpressionUtils.replaceVariable(expr, (variableName) => {
        return encodeVariableName(variableName);
    });
    return jexl.compile(escaped);
}
function convertValue(value) {
    // Empty arrays are implicitly converted to `false` values
    // to make checking for "hasValue" consistent with other block types
    if (Array.isArray(value) && value.length === 0) {
        return false;
    }
    return value;
}
function parseEnvironment(variables) {
    const env = {};
    Object.entries(variables).forEach(([key, value]) => {
        env[encodeVariableName(key)] = convertValue(value);
    });
    return env;
}
export class ExpressionEvaluator {
    constructor(expression) {
        Object.defineProperty(this, "compiledExpr", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.compiledExpr = parseExpression(expression);
    }
    static compile(expression) {
        return new ExpressionEvaluator(expression);
    }
    static validate(expression) {
        if (expression.trim() === '') {
            return {
                type: 'ok',
            };
        }
        try {
            const e = new ExpressionEvaluator(expression);
            // calling `eval` is needed to also catch more error types, e.g. missing custom functions
            e.eval({});
            return { type: 'ok' };
        }
        catch (err) {
            if (err instanceof Error) {
                return {
                    type: 'error',
                    message: err.message,
                };
            }
            throw err;
        }
    }
    eval(variables = {}) {
        const result = this.compiledExpr.evalSync(parseEnvironment(variables));
        if (result == null) {
            return null;
        }
        return Boolean(result);
    }
    evalText(variables = {}) {
        const result = this.compiledExpr.evalSync(parseEnvironment(variables));
        if (result == null) {
            return undefined;
        }
        if (Number.isNaN(result)) {
            return '';
        }
        if (typeof result === 'string') {
            return result;
        }
        if (typeof result === 'number') {
            return result.toString();
        }
        if (typeof result === 'boolean') {
            return result.toString();
        }
        if (Array.isArray(result)) {
            return result.join(', ');
        }
        return result;
    }
}
