import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { MenuItem } from '@mui/material';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { formatDateTime, getTimeZone } from '@corti/date';
import { intl } from '@corti/i18n';
import { api, } from '@corti/lib/coreApiService';
import { logger } from '@corti/logger';
import { Formatters } from '@corti/strings';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { Autocomplete, Base, Box, Button, Loading, TextField, Typography } from 'lib/cortiUI';
export function CasesExportView(props) {
    const { filter } = props;
    const theme = useTheme();
    const [exportFileData, setExportFileData] = React.useState({
        dataType: null,
        isLoading: false,
    });
    const [templates, setTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    React.useEffect(() => {
        void api.caseFeedback
            .getFeedbackFormTemplatesIncludeDeleted(authStore.organization.id)
            .then((list) => list.slice().sort((a, b) => a.title.localeCompare(b.title)))
            .then(setTemplates);
    }, []);
    const translations = {
        cases: intl.t(`explore:callsExportView.cases`, 'cases'),
        events: intl.t(`explore:callsExportView.events`, 'events'),
        reviews: intl.t(`explore:callsExportView.reviews`, 'reviews'),
    };
    const exportLogger = logger.getLogger('CasesExportView');
    function generateExportName(dataType) {
        const ms = new Date().getTime();
        const date = Formatters.msToFullYears(ms, { separator: '' });
        const time = Formatters.msToDayTime(ms, { separator: '' });
        const ext = '.csv';
        return 'Corti-' + translations[dataType] + '-' + date + '-' + time + ext;
    }
    async function callExportService(options) {
        const input = {
            filter: filter || null,
            organizationId: authStore.organization.id,
            templateId: options.templateId,
            timeZone: getTimeZone(),
        };
        switch (options.dataType) {
            case 'cases':
                return await api.cases.exportCasesCsvFile(input);
            case 'events':
                return await api.cases.exportEventsCsvFile(input);
            case 'reviews':
                return await api.caseFeedback.exportFeedbackFormSubmissionCsvFile(input);
        }
    }
    async function onSelectExportFile(options) {
        var _a;
        try {
            setExportFileData({
                isLoading: true,
                dataType: options.dataType,
            });
            const response = await callExportService(options);
            trackerService.track(`Exported ${options.dataType} to CSV`);
            saveFileToDisk(new Blob([response], { type: 'text/csv' }), generateExportName(options.dataType));
        }
        catch (e) {
            if (e instanceof Error) {
                exportLogger.error(`Failed to export ${options.dataType} to CSV`, e === null || e === void 0 ? void 0 : e.message, (_a = e === null || e === void 0 ? void 0 : e.stack) !== null && _a !== void 0 ? _a : '');
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: intl.t('explore:callsExportView.exportError', 'Failed to export {{ dataType }}', {
                        dataType: options.dataType,
                    }),
                });
            }
        }
        finally {
            setExportFileData({
                isLoading: false,
                dataType: null,
            });
        }
    }
    return (React.createElement(Base, { "data-cy": "export-csv-view", style: {
            display: 'grid',
            gridRowGap: theme.space[7],
            gridTemplateColumns: '100%',
        } },
        React.createElement(Typography, null, intl.t('explore:callsExportView.text', 'Select data you want to export')),
        React.createElement(Box, { flexDirection: "column", alignItems: "flex-start", gap: 3 },
            React.createElement(Button, { "data-cy": "export-cases-csv-button", variant: "text", size: "medium", onClick: () => onSelectExportFile({ dataType: 'cases' }), disabled: exportFileData.isLoading, startIcon: React.createElement(FileDownloadRoundedIcon, null) }, intl.t('explore:callsExportView.exportCases', 'Export cases')),
            React.createElement(Button, { "data-cy": "export-events-csv-button", variant: "text", size: "medium", onClick: () => onSelectExportFile({ dataType: 'events' }), disabled: exportFileData.isLoading, startIcon: React.createElement(FileDownloadRoundedIcon, null) }, intl.t('explore:callsExportView.exportEvents', 'Export events')),
            rbacService.feedbackSubmissionRead() && (React.createElement(Base, { display: "flex", width: "100%", justifyContent: "flex-start", gap: 3 },
                React.createElement(Autocomplete, { size: "small", options: templates, disabled: exportFileData.isLoading, getOptionLabel: (option) => option.title, value: selectedTemplate, onChange: (_, values) => {
                        setSelectedTemplate(values);
                    }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('explore:callsExportView.feedbackTemplates', 'Feedback templates') }))), isOptionEqualToValue: (option, value) => option.id === value.id, sx: { flex: 1 }, renderOption: (props, option) => {
                        return (React.createElement(MenuItem, Object.assign({}, props, { key: option.id }),
                            React.createElement(Base, { display: "flex", flexDirection: "column", justifyContent: "flex-start" },
                                React.createElement(Typography, { variant: "body1" }, option.title),
                                React.createElement(Typography, { variant: "caption" }, `${intl.t('explore:callsExportView.createdAt', 'Created at')}: ${formatDateTime(new Date(option.createdAt))}`),
                                option.deletedAt && (React.createElement(Typography, { variant: "caption" }, `${intl.t('explore:callsExportView.deletedAt', 'Deleted at')}: ${formatDateTime(new Date(option.deletedAt))}`)))));
                    } }),
                React.createElement(Button, { variant: "text", size: "medium", onClick: () => void onSelectExportFile({ dataType: 'reviews', templateId: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id }), disabled: exportFileData.isLoading || !templates.length || !selectedTemplate, startIcon: React.createElement(FileDownloadRoundedIcon, null) }, intl.t('explore:callsExportView.exportReviews', 'Export reviews'))))),
        exportFileData.isLoading && (React.createElement(Loading, { "data-cy": "export-loading", mb: 4, text: intl.t('explore:callsExportView.loadingMessage', 'Preparing data for {{ dataType }} export...', {
                dataType: translations[exportFileData.dataType],
            }) }))));
}
