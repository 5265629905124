import { gql } from '@apollo/client';
const richTextNodeFragment = gql `
  fragment RichTextNode on RichTextNode {
    type
    text
    attributes
  }
`;
export const richTextFragment = gql `
  fragment RichText on RichText {
    nodes {
      ...RichTextNode
      children {
        ...RichTextNode
        children {
          ...RichTextNode
          children {
            ...RichTextNode
            children {
              ...RichTextNode
            }
          }
        }
      }
    }
  }
  ${richTextNodeFragment}
`;
