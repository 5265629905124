var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _CustomObserver_observer, _CustomObserver_handleValueCollectorBlockValuesUpdated;
import { Observer } from '@corti/observer';
import { EventFactory } from './utils';
export class CustomObserver {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        _CustomObserver_observer.set(this, void 0);
        _CustomObserver_handleValueCollectorBlockValuesUpdated.set(this, (e) => {
            const event = EventFactory.createGroupedFlowValueCollectorBlocksUpdated(e.data.blockID, this.flowStore);
            if (!event) {
                this.flowStore.logger.warn(`failed to create "grouped-flow-value-collector-blocks-updated" event`);
                return;
            }
            __classPrivateFieldGet(this, _CustomObserver_observer, "f").fireEvent('custom-event', event);
        });
        __classPrivateFieldSet(this, _CustomObserver_observer, new Observer(), "f");
        this.flowStore.onStateEvent((e) => {
            if (e.event === 'value-collector-block-values-updated') {
                __classPrivateFieldGet(this, _CustomObserver_handleValueCollectorBlockValuesUpdated, "f").call(this, e);
            }
        });
    }
    onEvent(cb) {
        return __classPrivateFieldGet(this, _CustomObserver_observer, "f").on('custom-event', cb);
    }
}
_CustomObserver_observer = new WeakMap(), _CustomObserver_handleValueCollectorBlockValuesUpdated = new WeakMap();
