import { Observer } from '@corti/observer';
import { RefCountedPool } from './RefCountedPool';
import { flowEventUtils } from './utils';
/**
 * An abstraction that is responsible for binding subscribers to the triage session stream events.
 * Event subscribers dont need to worry if a session exists in a DB before subscribing,
 * abstraction simply forwards events whenever it successfully connects to streams.
 */
export class StreamEventSubscriber {
    constructor(input) {
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "streamPool", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "subscriberPool", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alreadyBoundSubscribers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bindingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.logger = input.logger;
        this.streamPool = input.streamPool;
        this.observer = new Observer();
        this.alreadyBoundSubscribers = new Map();
        this.subscriberPool = new RefCountedPool({
            factory: (sessionID) => sessionID,
            cleanup: (sessionID) => {
                var _a;
                (_a = this.alreadyBoundSubscribers.get(sessionID)) === null || _a === void 0 ? void 0 : _a();
                this.alreadyBoundSubscribers.delete(sessionID);
            },
        });
    }
    destroy() {
        clearInterval(this.bindingInterval);
    }
    subscribe(sessionID, subscription) {
        this.subscriberPool.acquire(sessionID);
        this.bindSubscriberToStream(sessionID);
        // I did not know how to properly type the callback argument here (vl).
        // @ts-ignore
        const u = this.observer.on(subscription.event, (data) => {
            if (data.sessionID === sessionID) {
                subscription.cb(data.data);
            }
        });
        return () => {
            u();
            this.subscriberPool.release(sessionID);
        };
    }
    bindSubscriberToStream(sessionID) {
        if (this.alreadyBoundSubscribers.has(sessionID)) {
            return;
        }
        const stream = this.streamPool.acquire(sessionID);
        const unsubscribers = [];
        unsubscribers.push(stream.bindUsersChannel((e) => {
            if (e.event === 'user-connected') {
                this.observer.fireEvent('session-user-connected', {
                    sessionID,
                    data: e,
                });
                return;
            }
            if (e.event === 'user-disconnected') {
                this.observer.fireEvent('session-user-disconnected', {
                    sessionID,
                    data: e,
                });
                return;
            }
            if (e.event === 'user-presence') {
                this.observer.fireEvent('session-user-presence', {
                    sessionID,
                    data: e,
                });
                return;
            }
        }));
        unsubscribers.push(stream.bindAll(async (e) => {
            var _a;
            (_a = this.logger) === null || _a === void 0 ? void 0 : _a.info(`[stream message]: ${e.event}, sessionID: ${sessionID}`);
            if (flowEventUtils.isFlowCommand(e)) {
                this.observer.fireEvent('session-flow-command', {
                    sessionID,
                    data: e,
                });
                return;
            }
            if (flowEventUtils.isFlowStateChangeEvent(e)) {
                this.observer.fireEvent('session-flow-state-change-event-added', {
                    sessionID,
                    data: e,
                });
                return;
            }
            if (e.event === 'triage-session-comment-created') {
                this.observer.fireEvent('session-comment-added', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-owner-changed') {
                this.observer.fireEvent('session-owner-changed', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-ownership-requested') {
                this.observer.fireEvent('session-ownership-requested', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-ownership-request-declined') {
                this.observer.fireEvent('session-ownership-request-declined', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-ownership-request-accepted') {
                this.observer.fireEvent('session-ownership-request-accepted', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-ownership-request-cancelled') {
                this.observer.fireEvent('session-ownership-request-cancelled', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-timeline-entry-created') {
                this.observer.fireEvent('session-timeline-entry-created', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
            if (e.event === 'triage-session-live-call-status-changed') {
                this.observer.fireEvent('session-live-call-status-changed', {
                    sessionID,
                    data: e.data,
                });
                return;
            }
        }));
        this.alreadyBoundSubscribers.set(sessionID, () => {
            unsubscribers.forEach((it) => it());
            this.streamPool.release(sessionID);
        });
    }
}
