import { simpleIpc } from '@corti/ipc-renderer';
import { Channels } from '../shared';
let instance;
export class IPCAdapter {
    init(contributor) {
        this.contributor = contributor;
    }
    constructor() {
        Object.defineProperty(this, "contributor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (instance) {
            throw new Error('IPC adapter is global and should have only one instance');
        }
        simpleIpc.answerMain(Channels.METHOD_CALL, async (msg) => {
            return this.contributor.localCallMethod(msg);
        });
        instance = this;
    }
    remoteCallMethod(action) {
        return simpleIpc.callMain(Channels.METHOD_CALL, action);
    }
    remoteFireEvent(event) {
        void simpleIpc.callMain(Channels.PUSH_EVENT, event);
    }
}
