var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { SettingsCheckInput, Switch } from 'lib/cortiUI';
export const RequiredInput = (_a) => {
    var { onSaveValue } = _a, props = __rest(_a, ["onSaveValue"]);
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions' });
    const _b = useController(Object.assign(Object.assign({}, props), { name: 'required' })).field, { ref } = _b, fieldData = __rest(_b, ["ref"]);
    //TODO: disable when request to the server
    return (React.createElement(SettingsCheckInput, { label: t('required', 'Required'), inputComponent: React.createElement(Switch, Object.assign({}, fieldData, { checked: fieldData.value, onChange: (_, checked) => {
                fieldData.onChange(checked);
                onSaveValue();
            }, inputRef: ref })) }));
};
