import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import React from 'react';
import { EditorView } from './EditorView';
const mod = {
    id: 'editorv2',
    title: () => 'Protocol Builder',
    path: '/editor-v2',
    icon: HandymanRoundedIcon,
    requiresOrgFeature: 'protocol-graph-editor',
    requiresUserPermission: 'app:protocol_graph_editor',
    viewComponent: () => React.createElement(EditorView, null),
    navGroupe: 0,
};
export default mod;
