var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI';
export const GroupOuterShell = (props) => {
    const { className } = props, rest = __rest(props, ["className"]);
    const theme = useTheme();
    return (React.createElement(Box, Object.assign({ px: 5, py: 3, alignItems: "flex-start", justifyContent: "center", className: css({
            backgroundColor: theme.palette.background.card,
            borderRadius: theme.shape.borderRadius,
            height: 58,
            width: 250,
        }, props.className) }, rest)));
};
