import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useCoreStores } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { Base, Box, Button, ConfirmableTarget, Input, Label, Tooltip, Typography, } from 'lib/cortiUI';
import { deleteGraphMutation, ensureGraph, getGraphQuery, } from './graphql';
export function GraphSettingsView({ graphID }) {
    var _a;
    const [nameInputVal, setNameInputVal] = React.useState('');
    const authStore = useAuth();
    const { notifications } = useCoreStores();
    const history = useHistory();
    const { t } = useTranslation();
    const graphQuery = useQuery(getGraphQuery, {
        variables: {
            id: graphID,
            activeProtocolGraphVersionID: authStore.organization.triageSettings.activeProtocolGraphVersionID || '',
        },
    });
    const [updateGraph, updateGraphResult] = useMutation(ensureGraph);
    const [deleteGraph, deleteGraphResult] = useMutation(deleteGraphMutation);
    const deleteGraphNotAllowed = !graphQuery.data || ((_a = graphQuery.data.protocolGraphVersion) === null || _a === void 0 ? void 0 : _a.graph.id) === graphID;
    React.useEffect(() => {
        if (graphQuery.data && nameInputVal === '') {
            setNameInputVal(graphQuery.data.protocolGraph.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphQuery]);
    async function handleSubmit() {
        if (updateGraphResult.loading) {
            return;
        }
        await updateGraph({
            variables: {
                input: {
                    id: graphID,
                    name: nameInputVal,
                    organizationID: authStore.organization.id,
                },
            },
        });
        notifications.showNotification({
            type: 'success',
            message: t('editorApp2:graphSettings.savedNotificationMsg', 'Saved!'),
        });
        void graphQuery.refetch();
    }
    async function handleDelete() {
        await deleteGraph({
            variables: { id: graphID },
        });
        history.replace('/editor-v2', { refresh: true });
    }
    return (React.createElement(Base, { px: 9, pt: 8 },
        React.createElement(Typography, { mb: 8, variant: "h6", color: "default" }, t('editorApp2:graphSettings.title', 'Graph Settings')),
        React.createElement("form", { onSubmit: (e) => {
                e.preventDefault();
                void handleSubmit();
            } },
            React.createElement(Box, { my: 4, gap: 3, alignItems: "flex-start" },
                React.createElement(Label, { htmlFor: "rename-graph-input" }, t('editorApp2:graphSettings.nameInputLabel', 'Name')),
                React.createElement(Input, { id: "rename-graph-input", "data-cy": "update-graph-name-input", placeholder: "", required: true, value: nameInputVal, disabled: graphQuery.loading && !graphQuery.data, onChange: (e) => {
                        setNameInputVal(e.target.value);
                    } })),
            React.createElement(Button, { className: css({ marginTop: 5 }), type: "submit", "data-cy": "update-graph-submit-button" }, t('editorApp2:graphSettings.submitBtn', 'Submit'))),
        React.createElement(ConfirmableTarget, { message: React.createElement(Typography, { variant: "body2", color: "default" }, t('editorApp2:graphSettings.deleteConfirmationMsg', 'The graph will permanently be removed from your organization. It will no longer be available in the Triage application. This action cannot be reverted.')), onConfirm: () => {
                void handleDelete();
            } }, ({ onClick }) => (React.createElement(Tooltip, { componentsProps: {
                tooltip: {
                    sx: {
                        display: deleteGraphNotAllowed ? undefined : 'none',
                    },
                },
            }, title: t('editorApp2:graphSettings.graphDeletionDisabledHint', 'Protocol graph that is currently set as active graph for the organisation cannot be deleted') },
            React.createElement("span", { style: { display: 'inline-flex' } },
                React.createElement(Button, { "data-cy": "delete-graph-submit-button", className: css({ marginTop: 8 }), color: "error", onClick: onClick, disabled: deleteGraphResult.loading || deleteGraphNotAllowed }, t('editorApp2:graphSettings.deleteBtn', 'Delete graph'))))))));
}
