import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import React from 'react';
import { Box } from 'lib/cortiUI';
import { useGetIssueMessage } from 'lib/graphEditor/validation';
import { useChecklistRouting } from '../useChecklistRouting';
import { useChecklistView } from '../useChecklistView';
import { ChecklistInspectorBreadcrumbs } from './ChecklistInspectorBreadcrumb';
import { ChecklistInspectorButtons } from './ChecklistInspectorButtons';
import { ChecklistInspectorEntry } from './ChecklistInspectorEntry';
import { ChecklistInspectorGeneral } from './ChecklistInspectorGeneral';
import { useChecklistEdit } from './useChecklistEdit';
const Form = styled.form `
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 0 1 auto;
  overflow: auto;
  padding: 0 0.1rem 0.25rem 0;
`;
export function ChecklistInspector({ isAdding, hasValidation, onSave }) {
    var _a, _b;
    const checklist = useChecklistEdit((state) => state.checklist);
    const hasSelection = useChecklistEdit((state) => state.selection !== null);
    const validate = useChecklistView((state) => state.validate);
    const validation = hasValidation ? validate(checklist) : null;
    const errors = (_a = validation === null || validation === void 0 ? void 0 : validation.getIssuesBySeverity('error')) !== null && _a !== void 0 ? _a : [];
    const warnings = (_b = validation === null || validation === void 0 ? void 0 : validation.getIssuesBySeverity('warning')) !== null && _b !== void 0 ? _b : [];
    const getIssueMessage = useGetIssueMessage();
    const { closeDialog } = useChecklistRouting();
    const onSubmit = (evt) => {
        evt.preventDefault();
        const shouldClose = onSave(checklist);
        if (shouldClose) {
            closeDialog();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", gap: 64, height: "100%", flex: "1 1", justifyContent: "stretch", overflow: "hidden" },
            React.createElement(ChecklistInspectorBreadcrumbs, null),
            React.createElement(Form, { id: "checklist-edit", onSubmit: onSubmit },
                !hasSelection ? React.createElement(ChecklistInspectorGeneral, null) : React.createElement(ChecklistInspectorEntry, null),
                errors.length > 0 && (React.createElement(Alert, { severity: "error" },
                    React.createElement("ul", null, errors
                        .map((error) => error.type)
                        .map(getIssueMessage)
                        .map((message) => (React.createElement("li", { key: message }, message)))))),
                warnings.length > 0 && (React.createElement(Alert, { severity: "warning" },
                    React.createElement("ul", null, warnings
                        .map((error) => error.type)
                        .map(getIssueMessage)
                        .map((message) => (React.createElement("li", { key: message }, message)))))))),
        React.createElement(Box, { display: "flex", gap: 4, alignItems: "stretch" },
            React.createElement(Box, { display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-end" },
                React.createElement(ChecklistInspectorButtons, { formId: "checklist-edit", isAdding: isAdding, canSave: errors.length === 0 })))));
}
