import { getReadableCaseID } from '@corti/lib/coreApiService';
export class CaseTableModel {
    constructor(input) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "updatedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "triageSessions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feedbackFormSubmissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = input.id;
        this.startedAt = input.startedAt ? new Date(input.startedAt) : undefined;
        this.updatedAt = input.updatedAt ? new Date(input.updatedAt) : undefined;
        this.endedAt = input.endedAt ? new Date(input.endedAt) : undefined;
        this.createdBy = input.createdBy;
        this.tags = input.tags;
        this.calls = input.calls;
        this.triageSessions = input.triageSessions;
        this.profiles = input.profiles;
        this.customProperties = input.customProperties;
        this.feedbackFormSubmissions = input.feedbackFormSubmissions;
        this.reviewRequests = input.reviewRequests;
    }
    get readableCaseID() {
        return getReadableCaseID(this.id);
    }
}
