export const inputFieldsToConfigGroup = (inputFields) => {
    return addInputFieldsToConfigGroup({
        name: 'config',
        children: [],
        configs: [],
    }, inputFields);
};
export const configGroupToQueryString = (rootConfigGroup) => {
    return cfgGroupsQueryReducer('', rootConfigGroup);
};
export const configGroupValuesToInput = (groupValues) => {
    let entity = {};
    groupValues.forEach((groupValue) => {
        var _a;
        entity[groupValue.groupName] = {};
        (_a = groupValue.values) === null || _a === void 0 ? void 0 : _a.forEach((val) => {
            entity[groupValue.groupName][val.config.name] = val.value;
        });
        groupValue.children.forEach((child) => {
            entity[groupValue.groupName][child.groupName] = configGroupValuesToInput([child]);
        });
    });
    return entity;
};
export const populateConfigValuesWithResult = (parent, result) => {
    let groupValues = {
        groupName: parent.name,
        values: [],
        children: [],
    };
    parent.configs.forEach((cfg) => {
        var _a;
        (_a = groupValues.values) === null || _a === void 0 ? void 0 : _a.push({
            config: cfg,
            value: result[cfg.name],
        });
    });
    parent.children.forEach((child) => {
        groupValues.children.push(populateConfigValuesWithResult(child, result[child.name]));
    });
    return groupValues;
};
const cfgQueryReducer = (accumulator, currentValue) => accumulator + `${currentValue.name}\n`;
const cfgGroupsQueryReducer = (accumulator, currentValue) => `${currentValue.name} {\n
  ${currentValue.children.reduce(cfgGroupsQueryReducer, currentValue.configs.reduce(cfgQueryReducer, ''))}}\n
  ${accumulator}\n`;
const addInputFieldsToConfigGroup = (parent, inputFields = []) => {
    inputFields.forEach((input) => {
        switch (input.type.kind) {
            case 'INPUT_OBJECT':
                let child = {
                    name: input.name,
                    children: [],
                    configs: [],
                };
                child = addInputFieldsToConfigGroup(child, input.type.inputFields);
                if (child.children.length === 0 && child.configs.length === 0) {
                    break;
                }
                parent.children.push(child);
                break;
            case 'SCALAR':
            case 'ENUM':
                parent.configs.push({
                    type: input.type.name,
                    name: input.name,
                });
                break;
            case 'NON_NULL':
                input.type.ofType &&
                    parent.configs.push({
                        type: input.type.ofType.name,
                        name: input.name,
                    });
                break;
        }
    });
    return parent;
};
