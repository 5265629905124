var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Dexie_observer, _Dexie_interceptResourceCreation, _Dexie_interceptResourceUpdate, _Dexie_interceptTransaction;
import DexieRaw from 'dexie';
import { Observer } from '@corti/observer';
/**
 * Dexie.js wrapper for indexedDB, extended to accompany specific needs of Corti app.
 */
export class Dexie extends DexieRaw {
    constructor(name, options) {
        super(name, options);
        _Dexie_observer.set(this, void 0);
        Object.defineProperty(this, "onQuotaExceeded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return __classPrivateFieldGet(this, _Dexie_observer, "f").on('quota-exceeded', cb);
            }
        });
        _Dexie_interceptResourceCreation.set(this, (_key, _obj, transaction) => {
            __classPrivateFieldGet(this, _Dexie_interceptTransaction, "f").call(this, transaction);
        });
        _Dexie_interceptResourceUpdate.set(this, (_mod, _key, _obj, transaction) => {
            __classPrivateFieldGet(this, _Dexie_interceptTransaction, "f").call(this, transaction);
        });
        _Dexie_interceptTransaction.set(this, (transaction) => {
            transaction.on('error', (err) => {
                if (err instanceof Dexie.DexieError) {
                    if (err.name === Dexie.errnames.QuotaExceeded ||
                        (err.inner && err.inner.name === Dexie.errnames.QuotaExceeded)) {
                        __classPrivateFieldGet(this, _Dexie_observer, "f").fireEvent('quota-exceeded');
                    }
                }
            });
        });
        __classPrivateFieldSet(this, _Dexie_observer, new Observer(), "f");
    }
    open() {
        this.tables.forEach((t) => {
            t.hook('creating', __classPrivateFieldGet(this, _Dexie_interceptResourceCreation, "f"));
            t.hook('updating', __classPrivateFieldGet(this, _Dexie_interceptResourceUpdate, "f"));
        });
        return super.open();
    }
    close() {
        __classPrivateFieldGet(this, _Dexie_observer, "f").unAll();
        this.tables.forEach((t) => {
            t.hook('creating').unsubscribe(__classPrivateFieldGet(this, _Dexie_interceptResourceCreation, "f"));
            t.hook('updating').unsubscribe(__classPrivateFieldGet(this, _Dexie_interceptResourceUpdate, "f"));
        });
        return super.close();
    }
}
_Dexie_observer = new WeakMap(), _Dexie_interceptResourceCreation = new WeakMap(), _Dexie_interceptResourceUpdate = new WeakMap(), _Dexie_interceptTransaction = new WeakMap();
