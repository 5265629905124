var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { Tooltip } from 'lib/cortiUI/components/Tooltip';
export function ExpressionInputStyledWrapper(props) {
    const theme = useTheme();
    const { className, validationResult } = props, rest = __rest(props, ["className", "validationResult"]);
    function renderContent() {
        return (React.createElement(Base, Object.assign({}, rest, { className: css({
                width: '100%',
                flexGrow: 1,
                borderRadius: 4,
                border: `1px solid ${(validationResult === null || validationResult === void 0 ? void 0 : validationResult.type) === 'error'
                    ? theme.palette.error.main
                    : theme.palette.background.divider}`,
                background: (validationResult === null || validationResult === void 0 ? void 0 : validationResult.type) === 'error'
                    ? theme.palette.error.outlinedHoverBackground
                    : theme.palette.background.card,
                padding: 4,
                color: theme.palette.text.secondary,
            }, className) })));
    }
    if ((validationResult === null || validationResult === void 0 ? void 0 : validationResult.type) === 'error') {
        return React.createElement(Tooltip, { title: validationResult.message }, renderContent());
    }
    return renderContent();
}
