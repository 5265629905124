import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Base, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography, } from 'lib/cortiUI';
export const SaveFilterForm = (props) => {
    const theme = useTheme();
    const [name, setName] = React.useState('');
    const [isSubscribed, setIsSubscribed] = React.useState(false);
    const [visibility, setVisibility] = React.useState('PRIVATE');
    const isFormValid = () => {
        return !!name;
    };
    const onFormSave = (e) => {
        e.preventDefault();
        props.onSave({
            name,
            isSubscribed,
            visibility,
        });
    };
    return (React.createElement(Base, { "data-cy": "save-new-filter-view" },
        React.createElement("form", { onSubmit: onFormSave, className: css({
                display: 'grid',
                gridGap: theme.space[6],
            }) },
            React.createElement(TextField, { inputProps: {
                    'data-cy': 'name-input',
                }, label: intl.t('explore:filters.filterName', 'Filter name'), value: name, autoFocus: true, onChange: (e) => setName(e.currentTarget.value) }),
            authStore.isFeatureEnabled('case-filter-subscription') && (React.createElement(Base, null,
                React.createElement(Typography, { color: "default", mb: 2, variant: "body2" }, intl.t('explore:filters.subscribe', 'Subscribe')),
                React.createElement(FormControlLabel, { label: intl.t('explore:filters.subscriptionLabel', 'Get messages when a new case enters the filter'), control: React.createElement(Checkbox, { "data-cy": "subscribe-input", checked: isSubscribed, onChange: (_e, val) => setIsSubscribed(val) }) }))),
            React.createElement(Base, null,
                React.createElement(Typography, { color: "default", mb: 2, variant: "body2" }, intl.t('explore:filters.privacy', 'Privacy')),
                React.createElement(RadioGroup, { name: intl.t('explore:filters.privacy', 'Privacy'), "aria-label": intl.t('explore:filters.privacy', 'Privacy'), value: visibility, onChange: (_e, val) => setVisibility(val) },
                    React.createElement(FormControlLabel, { value: "PRIVATE", label: intl.t('explore:filters.private', 'Private'), control: React.createElement(Radio, null), className: css({ marginBottom: theme.space[3] }) }),
                    rbacService.casesPublicFiltersCRUD() && (React.createElement(FormControlLabel, { "data-cy": "public-input", value: "PUBLIC", label: intl.t('explore:filters.public', 'Public'), control: React.createElement(Radio, null) })))),
            React.createElement(Base, null,
                React.createElement(Button, { "data-cy": "save-button", color: "primary", type: "submit", disabled: !isFormValid(), className: css({ float: 'right' }) }, intl.t('save', 'Save'))))));
};
