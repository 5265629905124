import React from 'react';
import { Base, Card, CardContent, Skeleton } from 'lib/cortiUI';
export const GeneralPerformanceLoader = () => {
    return (React.createElement(Card, { height: "100%" },
        React.createElement(CardContent, { sx: {
                height: '100%',
            } },
            React.createElement(Base, { display: "flex", justifyContent: "space-between", mb: 10, alignItems: "center", flex: 1 },
                React.createElement(Skeleton, { variant: "text", width: "60%" }),
                React.createElement(Skeleton, { variant: "circular", height: 25, width: 25 })),
            React.createElement(Base, { display: "flex", flexDirection: "column", justifyContent: "space-between" },
                React.createElement(Base, { display: "flex", alignItems: "center", mb: 8 },
                    React.createElement(Skeleton, { variant: "text", height: 25, width: "40%", sx: { mr: 4 } }),
                    React.createElement(Skeleton, { variant: "rounded", height: 25, width: 50 })),
                React.createElement(Skeleton, { variant: "rounded", height: 20, width: "100%" }),
                React.createElement(Base, { display: "flex", alignItems: "center", gap: 3, "justify-content": "space-between" },
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 1 } }),
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 2 } }),
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 3 } }))))));
};
