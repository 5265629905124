var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable } from 'mobx';
import { stringifyRichText } from '@corti/richtext';
import { ValuePublisher } from 'lib/graphEditor/valuePublisher';
import { BaseFormElementModel } from './BaseFormElementModel';
export class AbstractInputElementModel extends BaseFormElementModel {
    constructor(props = {}, additionalAttributes) {
        super(Object.assign(Object.assign({}, props), { attributes: Object.assign({ placeholder: {
                    type: 'string',
                    name: 'Placeholder',
                    defaultValue: '',
                } }, additionalAttributes) }));
        Object.defineProperty(this, "valuePublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.valuePublisher = new ValuePublisher();
    }
    get placeholder() {
        return this.attributes.values.placeholder;
    }
    setPlaceholder(value) {
        this.attributes.setAttributeValue('placeholder', value);
    }
    get referenceableItems() {
        const items = new Map();
        items.set(this.refItemValue, this);
        return items;
    }
    get refItemText() {
        return stringifyRichText(this.label) || this.attributes.values.name || `No name ${this.type}`;
    }
    get refItemTarget() {
        return this;
    }
    get refItemValue() {
        return `block:${this.id}`;
    }
    deserialize(value) {
        super.deserialize(value);
        this.valuePublisher.deserialize(value.valuePublisher);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { valuePublisher: this.valuePublisher.getEncodableData() });
    }
    copy() {
        const i = super.copy();
        i.valuePublisher = this.valuePublisher.copy();
        return i;
    }
}
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AbstractInputElementModel.prototype, "placeholder", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AbstractInputElementModel.prototype, "setPlaceholder", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AbstractInputElementModel.prototype, "referenceableItems", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AbstractInputElementModel.prototype, "refItemText", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AbstractInputElementModel.prototype, "refItemTarget", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AbstractInputElementModel.prototype, "refItemValue", null);
