import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Base, Typography } from 'lib/cortiUI';
export const AnnotationsAccordion = ({ title, children }) => {
    return (React.createElement(Base, null,
        React.createElement(Accordion, { sx: { boxShadow: 'none' } },
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, { fontSize: "small" }), "aria-controls": `${title}-content`, id: `${title}-header`, sx: { p: 0 } },
                React.createElement(Typography, { variant: "body2" }, title)),
            React.createElement(AccordionDetails, { sx: { px: 5, display: 'flex', flexDirection: 'column', gap: 6 } }, children))));
};
