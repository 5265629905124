import CheckIcon from '@mui/icons-material/Check';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SpeedIcon from '@mui/icons-material/Speed';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import { Observer } from 'mobx-react';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { intl, useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { Formatters } from '@corti/strings';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { Base, Box, ButtonBase, Chip, Grow, IconButton, Menu, MenuItem, Slider, Tag, Tooltip, Typography, } from 'lib/cortiUI';
import { CallPlayerState } from './CallPlayerState';
import { useCaseViewContext } from './CaseViewContext';
function TagsList(props) {
    const { tags, maxLengthToShow = tags.length } = props;
    const theme = useTheme();
    if (tags.length > 0) {
        return (React.createElement(Box, { "data-cy": "tags-list", flexDirection: "row", alignItems: "center", flexWrap: "wrap", gap: 3 },
            tags.map((tag, idx) => {
                var _a;
                if (idx < maxLengthToShow) {
                    return React.createElement(Tag, { key: idx, text: tag.value, color: (_a = tag.style) === null || _a === void 0 ? void 0 : _a.color });
                }
            }),
            tags.length > maxLengthToShow && (React.createElement(Tag, { text: `+${tags.length - maxLengthToShow}`, color: theme.palette.primary.light }))));
    }
    return null;
}
function ProtocolName(props) {
    const { protocolName } = props;
    const { t } = useTranslation('explore');
    if (!protocolName)
        return null;
    return (React.createElement(Base, { display: "grid" },
        React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('caseView.protocolName', 'Protocol Name')),
        React.createElement(Typography, { variant: "body2", noWrap: true }, protocolName)));
}
function ExternalIdInfo({ value }) {
    const { t } = useTranslation('explore');
    const [showTooltip, setShowTooltip] = React.useState(false);
    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(value);
        setShowTooltip(true);
    };
    return (React.createElement(Base, { "data-cy": "external-id", display: "flex", gap: 4, flexWrap: "nowrap", alignItems: "center" },
        React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('caseView.callExternalID', 'External ID')),
        React.createElement(Tooltip, { title: intl.t('explore:caseView.externalIDCopied', 'External ID Copied'), disableInteractive: true, placement: "top", leaveDelay: 500, open: showTooltip, onClose: () => setShowTooltip(false) },
            React.createElement(Base, null,
                React.createElement(Chip, { size: "small", maxWidth: 130, label: React.createElement(ButtonBase, { width: "100%", onClick: handleCopyToClipboard, display: "flex" },
                        React.createElement(Typography, { noWrap: true, textOverflow: "ellipsis", overflow: "hidden", variant: "caption", flex: 1 }, value),
                        ' ',
                        React.createElement(Base, { height: 21, width: 23, position: "relative" },
                            React.createElement(Base, { position: "absolute" },
                                React.createElement(Grow, { in: showTooltip, unmountOnExit: true, mountOnEnter: true }, React.createElement(CheckIcon, { color: "success", fontSize: "small" }))),
                            React.createElement(Base, { position: "absolute" },
                                React.createElement(Grow, { in: !showTooltip, mountOnEnter: true },
                                    React.createElement(ContentCopyRoundedIcon, { color: "action", fontSize: "small" }))))) })))));
}
export function BottomToolbar(props) {
    const theme = useTheme();
    const { caseViewState } = useCaseViewContext();
    const [rateAnchorEl, setRateAnchorEl] = React.useState(null);
    const downloadAudioFile = async () => {
        const { activeCall } = props.caseModel;
        if (activeCall) {
            const audioFileUrl = activeCall.getCallAudioUrl();
            const blob = await api.files.downloadFile(audioFileUrl);
            const ext = '.wav';
            const name = activeCall.id;
            saveFileToDisk(blob, name + ext);
            trackerService.track('[Single case] audio file exported');
        }
    };
    const seekToClosestEntry = (seekDirection) => {
        const { activeCallPlayerState } = props.caseModel;
        activeCallPlayerState === null || activeCallPlayerState === void 0 ? void 0 : activeCallPlayerState.setCurrentTimeToClosestEntryOffset(caseViewState.audioRelativeTimelineEntries.map((a) => a.getOffsetPosition(activeCallPlayerState.call.start).start / 1000), seekDirection);
    };
    const getPlaybackRateMenuItem = (rate) => {
        return (React.createElement(MenuItem, { key: rate, onClick: () => {
                var _a;
                (_a = props.caseModel.activeCallPlayerState) === null || _a === void 0 ? void 0 : _a.setPlaybackRate(rate);
                setRateAnchorEl(null);
            } }, `${rate}x`));
    };
    return (React.createElement(Observer, null, () => {
        var _a, _b;
        const { caseModel, className } = props;
        const renderPlayerToolbar = () => {
            if (caseModel.activeCallPlayerState) {
                const { isPlaying, togglePlay, currentTime, playbackRate, duration, isControlsDisabled, zoom, setZoom, } = caseModel.activeCallPlayerState;
                const { ZOOM_STEP, ZOOM_MIN, ZOOM_MAX, PLAYBACK_RATE_MULTIPLIERS } = CallPlayerState;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Base, { "data-cy": "audio-player-toolbar", className: css({
                            display: 'grid',
                            gridTemplateAreas: `"audio-download audio-toggler audio-timer"`,
                            gridTemplateColumns: '1fr auto 1fr',
                            gridGap: theme.space[6],
                            alignItems: 'center',
                            gridArea: 'case-player-toolbar-1',
                        }) },
                        rbacService.casesCallAudioDownload() && (React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end", className: css({ gridArea: 'audio-download' }) },
                            React.createElement(IconButton, { "data-cy": "audio-file-download", onClick: downloadAudioFile, disabled: isControlsDisabled, size: "small", icon: React.createElement(FileDownloadRoundedIcon, { fontSize: "small" }) }))),
                        React.createElement(Base, { className: css({
                                display: 'grid',
                                gridArea: 'audio-toggler',
                                gridAutoFlow: 'column',
                                gridGap: theme.space[3],
                                alignItems: 'center',
                            }) },
                            React.createElement(IconButton, { "data-cy": "prev-annotation-btn", onClick: () => {
                                    trackerService.track('[Single case] audio skipped to previous timeline-entry');
                                    seekToClosestEntry('backward');
                                }, disabled: isControlsDisabled, icon: React.createElement(SkipPreviousRoundedIcon, { fontSize: "small" }), size: "small" }),
                            React.createElement(IconButton, { "data-cy": isPlaying ? 'pause-btn' : 'play-btn', onClick: () => {
                                    trackerService.track(`[Single case] audio ${isPlaying ? 'stopped playing' : 'started playing'}`);
                                    togglePlay();
                                }, disabled: isControlsDisabled, icon: isPlaying ? React.createElement(PauseRoundedIcon, null) : React.createElement(PlayArrowRoundedIcon, null), size: "large", sx: { border: 1 }, className: css({
                                    borderColor: transparentize(0.5, theme.palette.text.primary),
                                }) }),
                            React.createElement(IconButton, { "data-cy": "next-annotation-btn", onClick: () => {
                                    trackerService.track('[Single case] audio skipped to next timeline-entry');
                                    seekToClosestEntry('forward');
                                }, disabled: isControlsDisabled, icon: React.createElement(SkipNextRoundedIcon, { fontSize: "small" }), size: "small" }),
                            React.createElement(IconButton, { icon: React.createElement(SpeedIcon, { fontSize: "small" }), onClick: (e) => setRateAnchorEl(e.currentTarget), size: "small", color: playbackRate === 1 ? 'default' : 'primary' }),
                            React.createElement(Menu, { open: !!rateAnchorEl, anchorEl: rateAnchorEl, onClose: () => setRateAnchorEl(null) }, PLAYBACK_RATE_MULTIPLIERS.sort((a, b) => b - a).map((rate) => getPlaybackRateMenuItem(rate))),
                            React.createElement(Typography, { variant: "overline", color: "disabled" }, `${playbackRate}x`)),
                        React.createElement(Box, { flexDirection: "row", alignItems: "center", className: css({ gridArea: 'audio-timer' }) },
                            React.createElement(Typography, { variant: "body2", "data-cy": "current-time", color: "secondaryText", mr: 4 }, Formatters.msToDuration(currentTime * 1000, { showHours: false })),
                            React.createElement(Typography, { variant: "body2", "data-cy": "total-time", color: "secondaryText" }, Formatters.msToDuration(duration * 1000, { showHours: false })))),
                    React.createElement(Base, { ml: "auto", className: css({
                            display: 'grid',
                            gridGap: theme.space[3],
                            gridTemplateColumns: 'auto 1fr auto',
                            gridArea: 'case-player-toolbar-2',
                            alignItems: 'center',
                            width: '50%',
                            minWidth: 250,
                            zIndex: 0,
                        }) },
                        React.createElement(IconButton, { "data-cy": "zoom-out-btn", onClick: () => setZoom(zoom - ZOOM_STEP), disabled: isControlsDisabled, icon: React.createElement(ZoomOutRoundedIcon, { fontSize: "small" }) }),
                        React.createElement(Slider, { "data-cy": "zoom-slider", "data-cy-value": zoom, value: zoom, min: ZOOM_MIN, max: ZOOM_MAX, step: ZOOM_STEP, 
                            //@ts-ignore
                            onChange: (_e, val) => setZoom(val), disabled: isControlsDisabled, onMouseUp: () => {
                                document.activeElement.blur();
                            } }),
                        React.createElement(IconButton, { "data-cy": "zoom-in-btn", onClick: () => setZoom(zoom + ZOOM_STEP), disabled: isControlsDisabled, icon: React.createElement(ZoomInRoundedIcon, { fontSize: "small" }) }))));
            }
        };
        return (React.createElement(Base, { mx: 8, my: 7, className: css({
                minHeight: 50,
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                gridTemplateAreas: `"case-details-card case-player-toolbar-1 case-player-toolbar-2"`,
                gridGap: theme.space[6],
                alignItems: 'center',
            }, className) },
            React.createElement(Base, { display: "grid", gridTemplateColumns: "auto 1fr", overflow: "hidden", gap: 6, gridArea: "case-details-card", className: css({
                    minWidth: 200,
                }) },
                React.createElement(Base, { display: "grid", gap: 3 },
                    ((_a = caseModel.activeCall) === null || _a === void 0 ? void 0 : _a.externalID) && (React.createElement(ExternalIdInfo, { value: caseModel.activeCall.externalID })),
                    React.createElement(TagsList, { tags: caseModel.tags, maxLengthToShow: 2 })),
                React.createElement(ProtocolName, { protocolName: (_b = caseModel.activeCall) === null || _b === void 0 ? void 0 : _b.protocolName })),
            renderPlayerToolbar()));
    }));
}
