import React from 'react';
/**
 * Hook that provides previous value
 */
export function usePrevious(value) {
    const ref = React.useRef(value);
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
