import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { useMousetrap } from '@corti/react';
import { Dialog } from 'lib/cortiUI';
import { useTriageSession } from '../TriageSessionContext';
import { QuickComment } from './QuickComment';
const OPEN_QUICK_COMMENT_VIEW_KEY = 'shift+c';
export const QuickCommentDialog = observer(function Component() {
    const { controller } = useTriageSession();
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        const unsubscribe = controller.onOwnerUserChange(closeDialog);
        return () => unsubscribe();
    }, [controller]);
    useMousetrap({
        keys: OPEN_QUICK_COMMENT_VIEW_KEY,
        handle: (e) => {
            e.preventDefault();
            setIsOpen((isOpen) => !isOpen);
        },
    }, []);
    function closeDialog() {
        setIsOpen(false);
    }
    return (React.createElement(Dialog, { open: isOpen, onClose: () => setIsOpen(false), title: intl.t('realtime:quickCommentView.title', 'Add comment') },
        React.createElement(QuickComment, { onSubmit: closeDialog, onCancel: closeDialog })));
});
