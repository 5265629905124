import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Box, FocusContext, FocusElement, FocusElementGroup, List, Typography, } from 'lib/cortiUI';
import { useToolbar } from 'lib/webappUI';
import { useTriageSession } from '../TriageSessionContext';
import { useUIEventObserver } from '../UIEventProvider';
import { AlertsListItem } from './AlertsListItem';
export const Alerts = observer(function Alerts() {
    const toolbar = useToolbar();
    const observer = useUIEventObserver();
    const { controller } = useTriageSession();
    const { t } = useTranslation('realtimeAlerts');
    const [containerRef, setContainerRef] = React.useState(null);
    const focusControllerRef = React.useRef(null);
    const onActionClick = (alert) => {
        controller.alerts.triggerAction(alert);
        observer.fireEvent({
            type: 'alert-action-button-clicked',
            data: alert,
        });
    };
    React.useEffect(() => {
        var _a;
        if (toolbar.isToolPanelOpened) {
            (_a = focusControllerRef.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
        }
    }, [toolbar.isToolPanelOpened]);
    if (controller.alerts.all.length === 0) {
        return (React.createElement(Box, { "data-cy": "alerts-list-fallback-view", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" },
            React.createElement(Typography, { color: "secondaryText" }, t('alertsView.noAlerts', 'Session has no detections'))));
    }
    return (React.createElement(Base, { "data-cy": "alerts-list-view", ref: (ref) => setContainerRef(ref), px: 6, py: 4 },
        React.createElement(FocusContext, { controllerRef: (ref) => (focusControllerRef.current = ref), keyboardEventBindingElement: containerRef, disableKeyboardShortcuts: !toolbar.openToolID, keyboardShortcuts: {
                triggerFocusedElement: 'enter',
            } },
            React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(List, null, controller.alerts.all.map((alert) => (React.createElement(FocusElement, { key: alert.id, defaultGroupID: groupID }, ({ registerElementRef }) => (React.createElement(AlertsListItem, { ref: registerElementRef, alert: alert, sessionCreatedAt: controller.createdAt, onActionClick: onActionClick })))))))))));
});
