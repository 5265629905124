import { useEffect, useRef } from 'react';
/**
 * useResetCache is a custom hook that resets the internal cache of the
 * VariableSizeList component whenever the data changes. This ensures
 * that the list recalculates item sizes based on the updated data.
 *
 * @param data - The data that the list is rendering.
 * @returns A ref to the VariableSizeList component.
 */
export const useResetCache = (data) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};
