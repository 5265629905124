import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import React, { forwardRef } from 'react';
import { createRichTextFromNodes } from '@corti/richtext';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { RichTextRenderer } from 'lib/cortiUI';
import { getFocusWithinCss } from '../utils';
import { Accordion, AccordionDetails, AccordionSummary } from './components';
function ParagraphRenderer({ text }) {
    return React.createElement(RichTextRenderer, { value: text, className: css({ padding: 0 }) });
}
function splitText(text) {
    if (typeof text === 'string') {
        return [text, ''];
    }
    const [firstNode, ...restNodes] = text.nodes;
    return [createRichTextFromNodes([firstNode]), createRichTextFromNodes(restNodes)];
}
export const ParagraphBlock = forwardRef(function ParagraphBlock({ text, isReadonly = false, isCollapsable = false }, ref) {
    const theme = useTheme();
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    if (!isCollapsable) {
        return React.createElement(ParagraphRenderer, { text: text });
    }
    const [summary, details] = splitText(text);
    const hasDetails = typeof details === 'string' || details.nodes.length > 0;
    const padding = `${theme.space[4]}px ${theme.space[5]}px`;
    return (React.createElement(Accordion, { ref: ref, expanded: !isCollapsed, onClick: () => setIsCollapsed((val) => !val), className: css({ borderRadius: Number(theme.shape.borderRadius) * 2 }, isReadonly ? undefined : getFocusWithinCss({ theme })), TransitionProps: { timeout: 0 } },
        React.createElement(AccordionSummary, { tabIndex: -1, classes: { root: css({ padding }) }, expandIcon: React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }) },
            React.createElement(ParagraphRenderer, { text: summary })),
        hasDetails && (React.createElement(AccordionDetails, { classes: { root: css({ padding }) }, onClick: (e) => e.stopPropagation() },
            React.createElement(ParagraphRenderer, { text: details })))));
});
