var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Paper } from '@mui/material';
import React from 'react';
export function Copilot(_a) {
    var { sx, children } = _a, rest = __rest(_a, ["sx", "children"]);
    return (React.createElement(Paper, Object.assign({}, rest, { elevation: 0, sx: Object.assign({ p: 0, m: 0, bgcolor: 'transparent', color: 'rgba(0 0 0 / 0.87) !important', '--default-color': '#404058', '--primary-color': '#608ec6', '--success-color': '#11a896', '--highlight-color': '#7373e8', '--inactive-color': 'rgba(64, 64, 88, 0.5)', '--default-gradient': 'linear-gradient(180deg, #404058 0%, #2c2c4a 100%)', '--primary-gradient': 'linear-gradient(180deg, #7373e8 0%, #608ec6 100%)', '--success-gradient': 'linear-gradient(180deg, #11a896 0%, #20c997 100%)', '--highlight-gradient': 'linear-gradient(180deg, #7373e8 0%, #5e5e99 100%)', '--inactive-gradient': 'linear-gradient(180deg, rgba(64, 64, 88, 0.5) 0%, rgba(44, 44, 74, 0.5) 100%)' }, sx) }),
        React.createElement("svg", { width: 0, height: 0, style: { position: 'absolute' } },
            React.createElement("linearGradient", { id: "successGradient", x1: 1, y1: 0, x2: 1, y2: 1 },
                React.createElement("stop", { offset: 0, stopColor: "#11a896" }),
                React.createElement("stop", { offset: 1, stopColor: "#20c997" }))),
        React.createElement("svg", { width: 0, height: 0, style: { position: 'absolute' } },
            React.createElement("linearGradient", { id: "primaryGradient", x1: 1, y1: 0, x2: 1, y2: 1 },
                React.createElement("stop", { offset: 0, stopColor: "#608ec6" }),
                React.createElement("stop", { offset: 1, stopColor: "#7373e8" }))),
        children));
}
