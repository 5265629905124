var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { produce } from 'immer';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { uuid } from '@corti/uuid';
import { Base, Box, Button } from 'lib/cortiUI';
import { EntitySelector } from './EntitySelector';
import { FilterGroup } from './FilterGroup';
import { FilterButton } from './components/Button';
import { JunctionController } from './components/JunctionController';
export function FilterRow(props) {
    const { onChange, entities, value, removable, onRemove } = props, rest = __rest(props, ["onChange", "entities", "value", "removable", "onRemove"]);
    const resolvedEntity = value && value.id ? entities.find((e) => e.id === value.id) : undefined;
    function onAddGroup() {
        if (value) {
            onChange(produce(value, (draft) => {
                draft.predicates.push({ id: uuid(), value: [] });
            }));
        }
    }
    const onChangeJunctionType = (newJuction) => {
        if (value) {
            onChange(produce(value, (draft) => {
                draft.junction = newJuction;
            }));
        }
    };
    const renderAddFilterButton = () => {
        const lastPredicate = props.value &&
            props.value.predicates &&
            props.value.predicates[props.value.predicates.length - 1];
        const isLastPredicateValidWithoutValue = (lastPredicate === null || lastPredicate === void 0 ? void 0 : lastPredicate.comparison) === 'isset' || (lastPredicate === null || lastPredicate === void 0 ? void 0 : lastPredicate.comparison) === 'isnotset';
        const isLastPredicateValueSet = lastPredicate &&
            ((lastPredicate.value && !!lastPredicate.value.length) || isLastPredicateValidWithoutValue);
        return (React.createElement(Button, { "data-cy": "filter-group-add-button", className: css({ marginLeft: 4 }), variant: "outlined", color: "primary", onClick: onAddGroup, disabled: !isLastPredicateValueSet }, intl.t('filterBuilder.addFilter', 'Add Filter')));
    };
    const getAvailableAttributeList = (predicates, filterGroupIdx) => {
        if (resolvedEntity) {
            const getRelatedAttributesByOption = (optionValue) => {
                let relatedAttributes = [];
                const findRelatedAttributes = (allAttributes) => {
                    allAttributes.forEach((a) => {
                        a.options.forEach((o) => {
                            if (o.attributes && o.attributes.length) {
                                if (o.value === optionValue) {
                                    o.attributes.forEach((a) => relatedAttributes.push(a));
                                }
                                else {
                                    findRelatedAttributes(o.attributes);
                                }
                            }
                        });
                    });
                };
                findRelatedAttributes(resolvedEntity.attributes);
                if (relatedAttributes.length) {
                    return relatedAttributes;
                }
            };
            let availableAttributeList = [
                ...resolvedEntity.attributes,
            ];
            predicates
                .filter((_predicate, idx) => idx < filterGroupIdx)
                .map((predicate) => getRelatedAttributesByOption(String(predicate.value[0])))
                .filter((attributeList) => attributeList != null)
                .forEach((attributeList) => attributeList.forEach((attribute) => availableAttributeList.push(attribute)));
            return availableAttributeList;
        }
    };
    return (React.createElement(Box, Object.assign({}, rest, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: '100%' }),
        React.createElement(Box, { flexDirection: "row", alignItems: "flex-start" },
            React.createElement(Base, { mr: 4, my: 2, style: { flexShrink: 0 } },
                React.createElement(EntitySelector, { entities: entities, selectedEntityID: value && value.id, onChange: (entityID) => {
                        onChange(Object.assign(Object.assign({}, value), { id: entityID, junction: 'and', predicates: [{ id: uuid(), value: [] }] }));
                    } })),
            value && resolvedEntity && !!value.predicates.length && (React.createElement(Box, { flexDirection: "row", alignItems: "center", flexWrap: "wrap" }, value.predicates.map((predicate, idx) => {
                return (React.createElement(Box, { key: idx, flexDirection: "row", alignItems: "center", mr: 4, my: 2 },
                    idx !== 0 && (React.createElement(Base, { mr: 4 },
                        React.createElement(JunctionController, { activeJunction: value.junction, onChange: onChangeJunctionType }))),
                    React.createElement(FilterGroup, { key: predicate.id, attributes: getAvailableAttributeList(value.predicates, idx), value: predicate, onChange: (newPredicate) => {
                            onChange(produce(value, (draft) => {
                                draft.predicates.splice(idx, draft.predicates.length);
                                draft.predicates[idx] = newPredicate;
                            }));
                        }, removable: true, onRemove: () => {
                            onChange(produce(value, (draft) => {
                                draft.predicates.splice(idx, draft.predicates.length);
                                if (idx === 0) {
                                    draft.predicates = [{ id: uuid(), value: [] }];
                                }
                            }));
                        } }),
                    idx === value.predicates.length - 1 && renderAddFilterButton()));
            })))),
        removable && (React.createElement(Base, { style: { flexShrink: 0 } },
            React.createElement(FilterButton, { "data-cy": "filter-row-remove-button", icon: React.createElement(DeleteRoundedIcon, null), variant: "contained", size: "small", onClick: onRemove })))));
}
