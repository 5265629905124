var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Chip, IconButton, InputBase, MenuItem, Popper, RichTextRenderer, Typography, } from 'lib/cortiUI';
export function DropdownContainer({ downshiftProps, children, onClearButtonClick, hasValue, isOpen, }) {
    return (React.createElement(Base, Object.assign({ position: "relative" }, downshiftProps),
        React.createElement(Base, { gap: 3, display: "grid", gridTemplateColumns: "minmax(0, 1fr) auto", alignItems: "center", minHeight: 34 },
            children,
            React.createElement(Base, { alignItems: "center", display: "flex", position: "absolute", right: 0, top: 0, height: "100%" },
                hasValue && (React.createElement(IconButton, { size: "small", icon: React.createElement(ClearIcon, { fontSize: "small" }), onClick: onClearButtonClick })),
                React.createElement(Base, { alignItems: "center", display: "flex", justifyContent: "center" }, isOpen ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null))))));
}
export function DropdownToggleButton(props) {
    return (React.createElement(Base, Object.assign({ gap: 3, display: "flex", flexWrap: "wrap" }, props.downshiftProps), props.children));
}
export function DropdownInput(props) {
    return (React.createElement(InputBase, { classes: { input: css({ padding: 0 }), root: css({ minWidth: 150, flexGrow: 1 }) }, inputProps: Object.assign({}, props.downshiftProps) }));
}
export const DropdownMultiValue = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { id, label, onDelete } = props, rest = __rest(props, ["id", "label", "onDelete"]);
    return (React.createElement(Chip, Object.assign({}, rest, { ref: ref, key: id, size: "small", label: label, onDelete: (e) => {
            onDelete(e, id);
        }, className: css({
            backgroundColor: transparentize(0.7, theme.palette.text.primary),
            '&:focus': {
                backgroundColor: transparentize(0.8, theme.palette.text.primary),
            },
            '&:hover': {
                backgroundColor: transparentize(0.7, theme.palette.text.primary),
            },
        }) })));
});
export function DropdownMenu(props) {
    var _a;
    const theme = useTheme();
    return (React.createElement(Popper, { open: props.isOpen, anchorEl: props.anchorEl, placement: "bottom-start", className: css({ width: (_a = props.anchorEl) === null || _a === void 0 ? void 0 : _a.clientWidth }), keepMounted: true },
        React.createElement(Base, Object.assign({ display: "flex", flexDirection: "column", alignItems: "flex-start", width: '100%', maxHeight: 300, py: 3, className: css({
                overflowY: 'hidden',
                overflowX: 'scroll',
                backgroundColor: theme.palette.background.card,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[2],
            }, getScrollerCss({ theme })) }, props.downshiftProps), props.isOpen && props.children)));
}
export function DropdownMenuOption(props) {
    const theme = useTheme();
    return (React.createElement(MenuItem, Object.assign({ disabled: props.isDisabled, width: 1, className: css({
            backgroundColor: props.isHighlighted
                ? theme.palette.action.focus
                : props.isSelected
                    ? theme.palette.action.selected
                    : undefined,
        }) }, props.downshiftProps),
        React.createElement(RichTextRenderer, { value: props.option.richText || props.option.plainText })));
}
export function DropdownMenuFallback() {
    const { t } = useTranslation('common');
    return (React.createElement(Base, { px: 6, py: 4 },
        React.createElement(Typography, { color: "secondaryText", variant: "body2" }, t('searchbar.noSearchResultsFound', 'No results found'))));
}
