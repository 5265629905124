import { browserApi } from 'core/api/browser/browserApi';
import { createSessionObject } from './utils';
const REALTIME_PUBLIC_API_NAME = 'realtime';
export class RealTimePublicApi {
    constructor(realTimeController, apiContributor) {
        Object.defineProperty(this, "realTimeController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: realTimeController
        });
        Object.defineProperty(this, "apiContributor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: apiContributor
        });
        Object.defineProperty(this, "subsriptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.subsriptions = [];
    }
    init() {
        this.apiContributor.exposeMethod({
            name: '/realtime/activeSessions',
            handler: () => {
                var _a;
                const activeSessions = [];
                if (((_a = this.realTimeController.activeTriageSession) === null || _a === void 0 ? void 0 : _a.status) === 'ok') {
                    activeSessions.push(createSessionObject(this.realTimeController.activeTriageSession.controller.serializeSession()));
                }
                return {
                    activeSessions,
                };
            },
        });
        this.apiContributor.exposeMethod({
            name: '/realtime/enterSession',
            paramsJSONSchema: {
                type: 'object',
                required: ['sessionID'],
                properties: { sessionID: { type: 'string' } },
            },
            handler: (input) => {
                this.realTimeController.openTriageSessionView({ id: input.sessionID });
            },
        });
        this.apiContributor.exposeMethod({
            name: '/realtime/startSession',
            paramsJSONSchema: {
                type: 'object',
                properties: {
                    caseID: { type: 'string', nullable: true },
                    externalID: { type: 'string', nullable: true },
                    startTime: { type: 'string', nullable: true },
                },
            },
            handler: async (input) => {
                const session = await this.realTimeController.createTriageSession({
                    caseID: input.caseID,
                    externalID: input.externalID,
                    createdAt: input.startTime ? new Date(input.startTime) : undefined,
                });
                this.realTimeController.openTriageSessionView({ id: session.id });
                return {
                    session: {
                        id: session.id,
                        externalID: session.externalID,
                    },
                };
            },
        });
        this.apiContributor.exposeMethod({
            name: '/realtime/leaveSession',
            paramsJSONSchema: {
                type: 'object',
                required: ['sessionID'],
                properties: {
                    sessionID: { type: 'string' },
                },
            },
            handler: () => {
                this.realTimeController.closeTriageSessionView();
            },
        });
        this.subsriptions.push(this.realTimeController.onTriageSessionViewOpenedEvent((sessionController) => {
            if (!sessionController.isCurrentUserOwner) {
                return;
            }
            this.apiContributor.fireEvent({
                name: 'realtime.session-opened',
                data: {
                    session: createSessionObject(sessionController.serializeSession()),
                },
            });
        }));
        this.subsriptions.push(this.realTimeController.onTriageSessionViewClosedEvent((sessionController) => {
            if (!sessionController.isCurrentUserOwner) {
                return;
            }
            this.apiContributor.fireEvent({
                name: 'realtime.session-closed',
                data: {
                    session: createSessionObject(sessionController.serializeSession()),
                },
            });
        }));
        initRealTimePublicApi(this.realTimeController);
    }
    destroy() {
        this.apiContributor.unexposeMethod('/realtime/activeSessions');
        this.apiContributor.unexposeMethod('/realtime/enterSession');
        this.apiContributor.unexposeMethod('/realtime/startSession');
        this.apiContributor.unexposeMethod('/realtime/leaveSession');
        this.subsriptions.forEach((it) => it());
        destroyRealTimePublicApi();
    }
}
/**
 * @deprecated
 * Some of the clients still use old realtime api.
 * This should be removed when all of the clients are migrated to the new frontend public api.
 */
function initRealTimePublicApi(realTimeController) {
    browserApi.setApi(REALTIME_PUBLIC_API_NAME, {
        /**
         * Enters session
         */
        enterSession(input) {
            realTimeController.openTriageSessionView(input);
        },
        /**
         * Leaves session
         */
        leaveSession() {
            realTimeController.closeTriageSessionView();
        },
    });
}
/**
 * @deprecated
 */
function destroyRealTimePublicApi() {
    browserApi.removeApi(REALTIME_PUBLIC_API_NAME);
}
