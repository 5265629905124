var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable } from 'mobx';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { BaseInstance } from './BaseInstance';
export class ComponentInstance extends BaseInstance {
    constructor(props = {}) {
        super();
        Object.defineProperty(this, "_definition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this._definition = props.definition;
    }
    get type() {
        return ComponentInstance.typename;
    }
    setDefinition(definition) {
        this._definition = definition;
    }
    get definition() {
        return this._definition;
    }
    get wrappedElement() {
        return this.definition.wrappedItem;
    }
    explode() {
        this.remove();
        return this.wrappedElement.copy();
    }
    clone() {
        return this.definition.createInstance();
    }
    copy() {
        const i = super.copy();
        i._definition = this.definition;
        return i;
    }
    deserialize(input) {
        super.deserialize(input);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { definitionID: this.definition.id, definition: this.definition });
    }
    get referenceableItems() {
        return this.wrappedElement.referenceableItems;
    }
}
Object.defineProperty(ComponentInstance, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'component-instance'
});
Object.defineProperty(ComponentInstance, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('contentBuilder.componentInstance.title', 'Component instance'),
    })
});
Object.defineProperty(ComponentInstance, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (value, ctx) => {
        const entity = new ComponentInstance();
        ctx.getElement(value.definitionID).then((el) => {
            entity._definition = el;
        });
        entity.deserialize(value);
        return entity;
    }
});
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ComponentInstance.prototype, "copy", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ComponentInstance.prototype, "referenceableItems", null);
ElementSerializer.register(ComponentInstance, 'ComponentInstance');
