import React from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Button, FocusElement, FocusElementGroup, Typography } from 'lib/cortiUI';
export function SentTakeoverRequestControls({ title, isDisabled, isHidden, onClick, }) {
    const theme = useTheme();
    return (React.createElement(FocusElementGroup, null, ({ groupID }) => {
        if (!isHidden) {
            return (React.createElement(FocusElement, { defaultGroupID: groupID }, ({ registerElementRef }) => (React.createElement(Button, { "data-cy": "users:sent-request-controls", ref: registerElementRef, size: "small", variant: "text", disabled: isDisabled, onClick: onClick, className: css(getFocusCss({ theme }), { height: 22 }) },
                React.createElement(Typography, { fontSize: 13, variant: "button", color: isDisabled ? 'disabled' : 'secondaryText', uppercase: true, noWrap: true }, title)))));
        }
    }));
}
