var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
export function sourceToID(source) {
    var _a;
    return (_a = source.type + source.entityType + source.entityID) !== null && _a !== void 0 ? _a : '';
}
export class DetectionsModule {
    constructor(_editor) {
        Object.defineProperty(this, "_definitions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this._definitions = new Map();
        });
    }
    setDetectionDefinitions(definitions) {
        this._definitions = new Map(definitions.map((it) => {
            const id = sourceToID(it.source);
            return [id, Object.assign(Object.assign({}, it), { id })];
        }));
    }
    get detectionDefinitions() {
        return [...this._definitions.values()];
    }
    getDefinitionBySource(source) {
        return this.getDefinitionByID(sourceToID(source));
    }
    getDefinitionByID(id) {
        return this._definitions.get(id);
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], DetectionsModule.prototype, "_definitions", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], DetectionsModule.prototype, "setDetectionDefinitions", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], DetectionsModule.prototype, "detectionDefinitions", null);
