import React from 'react';
import { useTheme } from '@corti/theme';
export const CortiLogo = ({ width = 24, height = 24, fill }) => {
    const theme = useTheme();
    return (React.createElement("svg", { width: width, height: height, xmlns: "http://www.w3.org/2000/svg", x: "0px", y: "0px", viewBox: "0 0 366 366", fill: (fill !== null && fill !== void 0 ? fill : theme.palette.mode === 'light')
            ? theme.palette.common.black
            : theme.palette.common.white },
        React.createElement("path", { d: "M175,0.2C77.8,4.3,0,84.7,0,183s77.8,178.7,175,182.8c4.3,0.2,9.9-2.9,9.9-9.2c0-6.2-5.3-9.9-9.3-10.1\n\t\tC86.4,342.8,19.2,273.1,19.2,183c0-90.2,67.2-159.8,156.4-163.6c4-0.2,8.9-2.8,8.9-10C184.4,3.1,180.3-0.1,175,0.2z" }),
        React.createElement("path", { d: "M239.3,53.3c-5-29.7-28.6-44.7-36.1-52.8c-1-1.1-2.7,0.1-2,1.4c4.6,8.9,22.3,31.6,22.3,59.1c0,43.9-11.8,79.5-11.8,117.6\n\t\tc0,38.1,11.8,82.7,11.8,126.6c0,27.5-17.7,50.2-22.3,59.1c-0.7,1.3,1,2.5,2,1.4c7.5-8.2,31-23.1,36.1-52.8\n\t\tc6.1-36.2-8.5-94-8.5-132.1C230.8,142.5,245.4,89.5,239.3,53.3L239.3,53.3z" }),
        React.createElement("path", { d: "M285.6,66.6c-4.8-29-23.5-43.6-30.7-51.6c-0.9-1-2.6,0.1-1.9,1.4c4.4,8.6,19.4,30.8,19.4,57.7c0,42.8-6.1,68.8-6.1,106\n\t\tc0,37.2,6.1,69.1,6.1,112c0,26.8-15.1,49-19.4,57.7c-0.6,1.3,1,2.4,1.9,1.4c7.2-8,25.9-22.6,30.7-51.6c5.9-35.3-0.4-82.2-0.4-119.4\n\t\tC285.2,142.8,291.4,101.9,285.6,66.6z" }),
        React.createElement("path", { d: "M323.6,88.8c-4.9-25.1-21.9-44.1-29.3-51c-1-0.9-2.6,0.1-2,1.2c4.5,7.5,17.8,20.8,21.9,58.3c4.1,37.5,1,51.7,1,83.9\n\t\tc0,32.2,3.3,56.4-1,91.8c-4.2,35.3-17.5,46.7-21.9,54.1c-0.6,1.1,1,2.1,2,1.2c7.3-6.9,24.4-25.9,29.3-51c6-30.5,5.9-55.7,5.9-96\n\t\tS329.6,119.3,323.6,88.8z" }),
        React.createElement("path", { d: "M360.3,136.9c-2.8-9-9.3-13.3-8-6c1.3,7.3,3,30.9,3.2,51.4c0.2,20.5-1.1,43.1-2.1,50.6c-1,7.5,4.1,4.2,6.9-5.2\n\t\ts5.7-29.7,5.7-45.4S364,151.5,360.3,136.9z" })));
};
