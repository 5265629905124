import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React from 'react';
import { SplitButton } from '@corti/corti-ui';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Input, InputAdornment } from 'lib/cortiUI';
import { useChecklistRouting } from './useChecklistRouting';
import { useChecklistView } from './useChecklistView';
export function ChecklistHeader() {
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    const searchValue = useChecklistView(({ searchValue }) => searchValue);
    const setSearchValue = useChecklistView(({ setSearchValue }) => setSearchValue);
    const { startAdding } = useChecklistRouting();
    const { startImport } = useChecklistRouting();
    const createOptions = [
        { label: t('checklistsView.createChecklist', 'Create'), onClick: startAdding },
        { label: t('checklistsView.importChecklistFile', 'Import file'), onClick: startImport('file') },
        { label: t('checklistsView.importChecklistJSON', 'Import JSON'), onClick: startImport('json') },
    ];
    return (React.createElement(Base, { p: 5, display: "flex", gap: 3, height: 64 },
        React.createElement(Input, { sx: { flexGrow: 1 }, type: "search", placeholder: t('checklistsView.searchInputPlaceholder', 'Search checklists by name or entry'), value: searchValue, startAdornment: React.createElement(InputAdornment, { position: "start" },
                React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.hint }) })), onChange: (e) => setSearchValue(e.target.value) }),
        React.createElement(SplitButton, { variant: "outlined", options: createOptions, groupLabel: t('checklistsView.createGroupLabel', 'Create button group'), dropdownLabel: t('checklistsView.dropdownLabel', 'Switch create method') })));
}
