import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, ConfirmableTarget, IconButton, Tooltip } from 'lib/cortiUI';
export const Actions = ({ onCopy, onRemove, loading }) => {
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions.actions' });
    return (React.createElement(Base, { display: "flex", gap: 2 },
        React.createElement(Tooltip, { title: t('hints.duplicateField', 'Duplicate field') },
            React.createElement(IconButton, { onClick: onCopy, size: "small", icon: React.createElement(ContentCopyRoundedIcon, { fontSize: "small" }), disabled: loading })),
        React.createElement(ConfirmableTarget, { message: t('warnings.delete', 'Are you sure you would like to delete this item?'), onConfirm: onRemove }, ({ onClick }) => (React.createElement(Tooltip, { title: t('hints.deleteField', 'Delete field') },
            React.createElement(IconButton, { onClick: onClick, size: "small", icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), disabled: loading }))))));
};
