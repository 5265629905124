import React from 'react';
import { useTranslation } from '@corti/i18n';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { Base } from 'lib/cortiUI';
import { ToggleSwitch } from '../ToggleSwitch';
const USER_SESSION_STORAGE_TOGGLE_TREND_KEY = 'adminPanel:toggleTrendValue';
export const PerformanceTrendToggle = () => {
    var _a, _b;
    const { t } = useTranslation();
    const persistedToggleTrendValue = (_b = JSON.parse((_a = userSessionStorage.getItem(USER_SESSION_STORAGE_TOGGLE_TREND_KEY)) !== null && _a !== void 0 ? _a : 'null')) !== null && _b !== void 0 ? _b : false;
    const [isTrendToggleEnabled, setIsTrendToggleEnabled] = React.useState(persistedToggleTrendValue);
    function persistToggleTrendValue(value) {
        userSessionStorage.setItem(USER_SESSION_STORAGE_TOGGLE_TREND_KEY, JSON.stringify(value));
    }
    return (React.createElement(Base, null,
        React.createElement(ToggleSwitch, { label: t('adminPanel:orgDataManagement.performanceTrendToggle', 'Enable User Trend in call-taker table (dashboard)'), checked: isTrendToggleEnabled, onChange: (_, checked) => {
                persistToggleTrendValue(checked);
                setIsTrendToggleEnabled(checked);
            } })));
};
