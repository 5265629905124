import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { Button } from 'lib/cortiUI';
export const Edit = ({ templateID }) => {
    const { t } = useTranslation('templateList', { keyPrefix: 'dataGrid.actions.edit' });
    const history = useHistory();
    function handleClick() {
        history.push(`${history.location.pathname}/${templateID}`);
    }
    return (React.createElement(Button, { size: "small", color: "primary", variant: "text", onClick: handleClick }, t('label', 'Edit')));
};
