import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { Button, PageHeader, PageHeaderActionsSection, Typography } from 'lib/cortiUI';
import { ImportModalTrigger } from './TemplateUploader';
export const Header = () => {
    const { t } = useTranslation('templatesManagement');
    const history = useHistory();
    function handleCreateNewTemplate() {
        history.push(`${history.location.pathname}/builder`);
    }
    return (React.createElement(PageHeader, null,
        React.createElement(Typography, { variant: "h4", color: "default" }, t('feedbackFormTemplates', 'Feedback Form Templates')),
        React.createElement(PageHeaderActionsSection, null,
            React.createElement(ImportModalTrigger, null),
            React.createElement(Button, { "data-cy": "create-new-template-btn", color: "primary", onClick: handleCreateNewTemplate }, t('createFeedbackFormTemplate', 'Create New Template')))));
};
