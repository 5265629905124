import React from 'react';
import { useTranslation } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, ChipV2, Text } from 'lib/cortiUI';
import { SearchBar } from 'lib/cortiUI/components/SearchBar/SearchBar';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { GenericPanel, GenericPanelContent, GenericPanelHeader } from 'lib/graphEditor/ui';
import { ContentBuilderLibraryComponent } from '../ContentBuilderLibraryComponent';
import * as ElementModels from '../elements';
import { ElementDragSource } from './dnd';
const elementCollection = [
    ElementModels.ActionElementModel,
    ElementModels.TextElementModel,
    ElementModels.TextInputElementModel,
    ElementModels.NumberInputElementModel,
    ElementModels.TextAreaElementModel,
    ElementModels.SelectElementModel,
    ElementModels.FlowValueCollectorElementModel,
    ElementModels.ImageElementModel,
    ElementModels.DocumentElementModel,
    ElementModels.DatePickerElementModel,
];
export function ElementCreationToolbar() {
    const { editor } = useGraphEditorCtx();
    const theme = useTheme();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(GenericPanel, null,
        React.createElement(GenericPanelHeader, { header: t('contentBuilder.elementCreation.blockPickerSectionTitle', 'Block Picker') }),
        React.createElement(GenericPanelContent, { p: 5 },
            React.createElement(Base, { display: "flex", flexWrap: "wrap", mb: 4 }, elementCollection.map((elementDescriptor) => {
                const descriptor = elementDescriptor.descriptor({ t });
                return (React.createElement(ElementDragSource, { key: elementDescriptor.typename, elementType: elementDescriptor.typename, onCreate: (target, position) => {
                        editor.dispatch({
                            type: 'viewBuilder.createBlockInstance',
                            data: {
                                elementType: elementDescriptor.typename,
                                contentBuilderID: target.id,
                                position,
                            },
                        });
                    } }, ({ connector }) => (React.createElement(ChipV2, { className: css({
                        cursor: 'move',
                        marginRight: theme.space[4],
                        marginBottom: theme.space[4],
                    }), "data-cy": "draggable-node-section", label: descriptor.title, ref: connector, size: "medium" }))));
            })),
            React.createElement(Base, null,
                React.createElement(Text, { noSelect: true, my: 4 }, t('contentBuilder.elementCreation.addFromLibraryBtn', 'Add from the library')),
                React.createElement(ComponentFinderPanel, null)))));
}
function ComponentFinderPanel() {
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const [searchInputValue, setSearchInputValue] = React.useState('');
    const library = editor.model.library;
    const filteredComponents = [...library.items.values()]
        .filter((it) => it instanceof ContentBuilderLibraryComponent && stringIncludes(it.name, searchInputValue))
        .slice(0, 10);
    return (React.createElement(Base, null,
        React.createElement(SearchBar, { className: css({ marginBottom: 12 }), placeholder: t('contentBuilder.componentFinder.searchInputLabel', 'Search by name'), value: searchInputValue, onChange: (e) => setSearchInputValue(e.target.value) }),
        React.createElement(Base, { display: "grid", gridGap: 2 }, filteredComponents.map((it) => (React.createElement(ElementDragSource, { key: it.id, elementType: it.wrappedItem.type, onCreate: (target, position) => {
                editor.dispatch({
                    type: 'viewBuilder.createComponentInstance',
                    data: {
                        componentID: it.id,
                        contentBuilderID: target.id,
                        position,
                    },
                });
            } }, ({ connector }) => (React.createElement(Base, { ref: connector, key: it.id, p: 2, "data-cy": "draggable-component-section" },
            React.createElement(Text, { noSelect: true }, it.name)))))))));
}
