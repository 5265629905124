import { intl } from '@corti/i18n';
export function transformRequestToTimeStampCellDescriptor(request) {
    var _a, _b;
    let descriptor;
    if ((_b = (_a = request.error) === null || _a === void 0 ? void 0 : _a.domainErrors) === null || _b === void 0 ? void 0 : _b.some((e) => e.code === 'MALFORMED_TIMESTAMP')) {
        descriptor = { text: '', color: 'error', error: request.error.message };
    }
    else {
        descriptor = { text: '', color: 'primary' };
    }
    if (request.metadata.timeStamp) {
        descriptor.text = request.metadata.timeStamp;
    }
    return descriptor;
}
export function transformRequestToProtocolNameCellDescriptor(request) {
    var _a;
    return {
        text: (_a = request.metadata.protocolName) !== null && _a !== void 0 ? _a : '',
        color: 'primary',
    };
}
export function transformRequestToCaseIDCellDescriptor(request) {
    var _a, _b, _c, _d;
    let descriptor;
    if (((_b = (_a = request.error) === null || _a === void 0 ? void 0 : _a.domainErrors) === null || _b === void 0 ? void 0 : _b.some((e) => e.code === 'MALFORMED_CASE_ID')) ||
        ((_d = (_c = request.error) === null || _c === void 0 ? void 0 : _c.domainErrors) === null || _d === void 0 ? void 0 : _d.some((e) => e.code === 'CASE_NOT_FOUND'))) {
        descriptor = { text: '', color: 'error', error: request.error.message };
    }
    else {
        descriptor = { text: '', color: 'primary' };
    }
    if (request.metadata.caseID) {
        descriptor.text = request.metadata.caseID;
    }
    if (request.response) {
        descriptor.text = request.response.caseID;
    }
    return descriptor;
}
export function transformRequestToStatusCellDescriptor(request) {
    var _a, _b;
    let descriptor = { text: '', color: 'primary' };
    if (request.error) {
        if ((_b = (_a = request.error) === null || _a === void 0 ? void 0 : _a.domainErrors) === null || _b === void 0 ? void 0 : _b.some((e) => e.code === 'FILE_ALREADY_EXISTS')) {
            descriptor = {
                color: 'warning',
                text: intl.t('explore:callsUploadView.statusMessages.duplicate', 'Already exists'),
            };
        }
        else if (request.error.code === 'unavailable') {
            descriptor = {
                color: 'error',
                text: intl.t('explore:callsUploadView.statusMessages.networkError', 'Network error'),
            };
        }
        else {
            descriptor = {
                color: 'error',
                text: intl.t('explore:callsUploadView.statusMessages.metadataError', 'Metadata error'),
            };
        }
    }
    else if (request.response) {
        descriptor = {
            color: 'success',
            text: intl.t('explore:callsUploadView.statusMessages.fulfilled', 'Success'),
        };
    }
    else if (request.isUploading) {
        descriptor = {
            color: 'secondary',
            text: intl.t('explore:callsUploadView.statusMessages.uploading', 'Uploading...'),
        };
    }
    else {
        descriptor = {
            color: 'secondary',
            text: intl.t('explore:callsUploadView.statusMessages.waiting', 'Waiting'),
        };
    }
    return descriptor;
}
