import { sortBy } from 'lodash';
import React from 'react';
import { Base, Box, ButtonBase, Radio, Typography } from 'lib/cortiUI';
import { ChoiceHintsList } from './ChoiceHintsList';
import { Item } from './Item';
export function RadioField({ choices: propsChoices, value, isPreviewMode, onChoiceClick, onChange, onChoiceHintClick, }) {
    const selectedItem = propsChoices.find((c) => c.value === value);
    const groupedChoices = propsChoices.reduce((acc, choice) => {
        if (choice.type === 'timeline-entry') {
            acc.timelineEntry.push(choice);
        }
        else {
            acc.text.push(choice);
        }
        return acc;
    }, {
        timelineEntry: [],
        text: [],
    });
    const choices = isPreviewMode
        ? selectedItem
            ? [selectedItem]
            : []
        : [...sortBy(groupedChoices.timelineEntry, 'headerInfoText'), ...groupedChoices.text];
    if (isPreviewMode && !selectedItem) {
        return (React.createElement(Item, null,
            React.createElement(Typography, null, '-')));
    }
    function renderTextItem(choice) {
        return (React.createElement(Item, { clickable: !isPreviewMode, overflow: "hidden" },
            React.createElement(Box, { flexDirection: "row", alignItems: "center", width: 1 },
                !isPreviewMode && React.createElement(Radio, { sx: { pr: 3 }, checked: value === choice.value }),
                React.createElement(Box, { overflow: "hidden", flex: 1 },
                    React.createElement(Typography, { alignSelf: "flex-start", textAlign: "start" }, choice.text)))));
    }
    function renderTimelineEntryItem(choice) {
        return (React.createElement(Item, { "data-cy": "feedback-timeline-entry-item", "data-cy-state": value === choice.value
                ? 'feedback-timeline-entry-item-checked'
                : 'feedback-timeline-entry-item-unchecked', clickable: true, overflow: "hidden", borderColor: choice.color },
            React.createElement(Box, { flexDirection: "row", alignItems: "center", width: 1 },
                !isPreviewMode && React.createElement(Radio, { checked: value === choice.value }),
                React.createElement(Box, { overflow: "hidden", px: 5, flex: 1 },
                    React.createElement(Box, { flexDirection: "row", overflow: "hidden", justifyContent: "space-between", mb: 2, flex: 1 },
                        React.createElement(Typography, { noWrap: true, uppercase: true, color: "secondaryText", variant: "caption", mr: 2 }, choice.headerText),
                        React.createElement(Typography, { noWrap: true, uppercase: true, color: "secondaryText", variant: "caption" }, choice.headerInfoText)),
                    React.createElement(Typography, { alignSelf: "flex-start", variant: "subtitle2", color: "default" }, choice.text)))));
    }
    return (React.createElement(Base, { display: "grid", gridGap: 4 }, choices.map((choice) => {
        var _a;
        return (React.createElement(React.Fragment, { key: choice.value },
            React.createElement(ButtonBase, { onClick: () => {
                    onChoiceClick(choice.value);
                    onChange(choice.value);
                } }, choice.type === 'text'
                ? renderTextItem(choice)
                : renderTimelineEntryItem(choice)),
            choice.type === 'text' && Boolean((_a = choice === null || choice === void 0 ? void 0 : choice.detectedTimelineEntries) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(ChoiceHintsList, { items: choice.detectedTimelineEntries || [], onItemClick: onChoiceHintClick }))));
    })));
}
