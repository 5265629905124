var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
export class FactValueStore {
    constructor(flowStore) {
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._all = new Map();
            }
        });
        /**
         * Adds new or updates existing fact values.
         */
        Object.defineProperty(this, "put", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                const values = this.filterValid(input.factValues);
                if (!values.length) {
                    return;
                }
                values.forEach((it) => this._all.set(it.factID, it));
                this.observer.fireEvent('values-updated', Object.assign(Object.assign({}, input), { factValues: values }));
            }
        });
        Object.defineProperty(this, "getByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (factID) => {
                return this._all.get(factID);
            }
        });
        Object.defineProperty(this, "onUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('values-updated', cb);
            }
        });
        Object.defineProperty(this, "filterValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (values) => {
                return values.filter(this.validate);
            }
        });
        Object.defineProperty(this, "validate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                const fact = this.flowStore.graphTraverser.getFactByID(value.factID);
                if (!fact) {
                    return false;
                }
                if (value.value === null ||
                    (typeof value.value === 'boolean' && fact.type === 'BOOLEAN') ||
                    (typeof value.value === 'string' && fact.type === 'TEXT')) {
                    return true;
                }
                return false;
            }
        });
        makeObservable(this);
        this.observer = new Observer();
        runInAction(() => {
            this._all = new Map();
        });
    }
    get all() {
        return [...this._all.values()];
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], FactValueStore.prototype, "_all", void 0);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], FactValueStore.prototype, "all", null);
__decorate([
    action,
    __metadata("design:type", Object)
], FactValueStore.prototype, "reset", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], FactValueStore.prototype, "put", void 0);
