import { intl } from '@corti/i18n';
export class TriageSessionNotifications {
    constructor(notificationStore) {
        Object.defineProperty(this, "notificationStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: notificationStore
        });
        Object.defineProperty(this, "notificationIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "takeoverSuggestionNotificationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "takeoverRequestIDToNotificationIDMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.clearAllNotifications();
            }
        });
        this.notificationIDs = [];
        this.takeoverSuggestionNotificationID = null;
        this.takeoverRequestIDToNotificationIDMap = new Map();
    }
    showOwnerConnectedNotification() {
        this.showNotification({
            type: 'info',
            message: intl.t('realtime:sessionOwnershipNotifications.ownerReturned', 'Owner returned to the session'),
        });
    }
    showOwnerDisconnectedNotification() {
        this.showNotification({
            type: 'info',
            message: intl.t('realtime:sessionOwnershipNotifications.ownerLeft', 'Owner left the session'),
        });
    }
    showTakeoverSuggestedNotification(input) {
        const id = this.showNotification({
            type: 'info',
            message: intl.t('realtime:sessionOwnershipNotifications.takeoverSuggestion', 'Would you like to take over the session?'),
            permanent: true,
            actions: [
                {
                    text: intl.t('realtime:takeover', 'Takeover'),
                    onClick: input.onAccept,
                },
            ],
        });
        this.takeoverSuggestionNotificationID = id;
    }
    showOwnershipGrantedNotification() {
        this.showNotification({
            type: 'success',
            message: intl.t('realtime:sessionOwnershipNotifications.youTookover', 'You are now the owner'),
        });
    }
    showOwnerChangedNotification(owner) {
        this.showNotification({
            type: 'success',
            message: intl.t('realtime:sessionOwnershipNotifications.userTookover', '{{ userName }} is now the owner', {
                userName: owner ? owner.name : `[${intl.t('realtime:unknownUser', 'Unknown user')}]`,
            }),
        });
    }
    showHandoverRequestedNotification(input) {
        const id = this.showNotification({
            type: 'info',
            message: intl.t('realtime:sessionOwnershipNotifications.ownerRequested', 'Session handover request'),
            permanent: true,
            actions: [
                {
                    text: intl.t('realtime:accept', 'Accept'),
                    onClick: input.onAccept,
                },
                {
                    text: intl.t('realtime:decline', 'Decline'),
                    onClick: input.onDecline,
                },
            ],
        });
        this.takeoverRequestIDToNotificationIDMap.set(input.request.id, id);
    }
    showTakeoverRequestedNotification(input) {
        let notification = {
            type: 'info',
            message: intl.t('realtime:sessionOwnershipNotifications.userRequested', '{{ userName }} wants to take over the session', {
                userName: input.user
                    ? input.user.name
                    : `[${intl.t('realtime:unknownUser', 'Unknown user')}]`,
            }),
            permanent: true,
            actions: [
                {
                    text: intl.t('realtime:accept', 'Accept'),
                    onClick: input.onAccept,
                },
                {
                    text: intl.t('realtime:decline', 'Decline'),
                    onClick: input.onDecline,
                },
            ],
        };
        if (input.autoAcceptTimeout !== undefined) {
            notification = Object.assign(Object.assign({}, notification), { permanent: false, duration: input.autoAcceptTimeout, showDurationTimer: true });
        }
        const id = this.showNotification(notification);
        this.takeoverRequestIDToNotificationIDMap.set(input.request.id, id);
    }
    showUserDeclinedRequestNotification(user) {
        this.showNotification({
            type: 'error',
            message: intl.t('realtime:sessionOwnershipNotifications.userDeclined', '{{ userName }} declined your request', {
                userName: user ? user.name : `[${intl.t('realtime:unknownUser', 'Unknown user')}]`,
            }),
        });
    }
    clearTakeoverSuggestionNotification() {
        if (this.takeoverSuggestionNotificationID !== null) {
            this.notificationStore.closeNotification(this.takeoverSuggestionNotificationID);
        }
    }
    clearAllNotifications() {
        this.notificationIDs.forEach((id) => this.notificationStore.closeNotification(id));
        this.notificationIDs = [];
        this.takeoverRequestIDToNotificationIDMap = new Map();
        this.takeoverSuggestionNotificationID = null;
    }
    clearNotificationByRequestID(requestID) {
        const notificationID = this.takeoverRequestIDToNotificationIDMap.get(requestID);
        if (!notificationID) {
            return;
        }
        const index = this.notificationIDs.findIndex((id) => id === notificationID);
        if (index === -1) {
            return;
        }
        this.notificationStore.closeNotification(notificationID);
        this.notificationIDs.splice(index, 1);
        this.takeoverRequestIDToNotificationIDMap.delete(requestID);
    }
    showNotification(notification) {
        const id = this.notificationStore.showNotification(Object.assign(Object.assign({}, notification), { closable: true }));
        this.notificationIDs.push(id);
        return id;
    }
}
