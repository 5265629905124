import { observer } from 'mobx-react';
import React from 'react';
import DateRange from 'react-date-range/dist/components/DateRange';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { convertUtcToZonedTime, convertZonedToUtcTime, endOfDay, getLocale, startOfDay, } from '@corti/date';
import { intl } from '@corti/i18n';
import { useLazyRef } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { RelativeDateRangePicker, } from './RelativeDateRangePicker';
import { TimePicker } from './TimePicker';
/**
 * Date range picker component
 */
export const DateRangePicker = observer(function DateRangePicker(props) {
    const theme = useTheme();
    const currentDate = useLazyRef(() => convertZonedToUtcTime(new Date()));
    const { maxDate = currentDate.current, minDate, months, onChange, withTime = false, onTimePickerOpen, onTimePickerClose, relativeDateRangePickerProps, range, } = props;
    function handleTimeSelect(dateType, value) {
        if (!range)
            return;
        onChange({
            startDate: dateType === 'start' ? convertZonedToUtcTime(value) : range.startDate,
            endDate: dateType === 'end' ? convertZonedToUtcTime(value) : range.endDate,
        });
    }
    function handleDateRangeSelect(value) {
        onChange({
            startDate: startOfDay(convertZonedToUtcTime(value.startDate)),
            endDate: endOfDay(convertZonedToUtcTime(value.endDate)),
        });
    }
    function handleRelativeDateRangeSelect(relativeDateRange, absoluteDateRange) {
        var _a;
        (_a = relativeDateRangePickerProps === null || relativeDateRangePickerProps === void 0 ? void 0 : relativeDateRangePickerProps.onSelect) === null || _a === void 0 ? void 0 : _a.call(relativeDateRangePickerProps, relativeDateRange, absoluteDateRange);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "flex-start", width: "max-content", className: css({
            backgroundColor: theme.palette.background.card,
        }) },
        (relativeDateRangePickerProps === null || relativeDateRangePickerProps === void 0 ? void 0 : relativeDateRangePickerProps.enabled) && (React.createElement(Base, { width: "max-content" },
            React.createElement(RelativeDateRangePicker, { selectedDateRange: relativeDateRangePickerProps.selectedDateRange, dateRanges: relativeDateRangePickerProps.dateRanges, onSelect: handleRelativeDateRangeSelect }))),
        React.createElement(Base, { width: 330, display: "flex", flexDirection: "column", justifyContent: "center" },
            withTime && (React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr 1fr", p: 4, pt: 5, gap: 4 },
                React.createElement(Base, null,
                    React.createElement(TimePicker, { disabled: !range, onOpen: onTimePickerOpen, onClose: onTimePickerClose, value: range ? convertUtcToZonedTime(range.startDate) : null, onChange: (date) => handleTimeSelect('start', date), label: intl.t('datePicker.timePicker.from', 'Time from') })),
                React.createElement(Base, null,
                    React.createElement(TimePicker, { disabled: !range, onOpen: onTimePickerOpen, onClose: onTimePickerClose, value: range ? convertUtcToZonedTime(range.endDate) : null, onChange: (date) => handleTimeSelect('end', date), label: intl.t('datePicker.timePicker.to', 'Time to') })))),
            React.createElement(DateRange, { showDateDisplay: false, months: months, maxDate: maxDate ? convertUtcToZonedTime(maxDate) : undefined, minDate: minDate ? convertUtcToZonedTime(minDate) : undefined, direction: "horizontal", onChange: (e) => handleDateRangeSelect(e.range1), ranges: range
                    ? [
                        {
                            startDate: convertUtcToZonedTime(range.startDate),
                            endDate: convertUtcToZonedTime(range.endDate),
                        },
                    ]
                    : [
                        // This is a workaround. Currently React-date-range doesn`t provide
                        // any other way to apply an empty selection.
                        {
                            startDate: null,
                            endDate: new Date(''),
                        },
                    ], locale: getLocale(), classNames: {
                    calendarWrapper: css({ backgroundColor: theme.palette.background.secondary }),
                    nextButton: css({ backgroundColor: theme.palette.background.secondary }),
                    prevButton: css({ backgroundColor: theme.palette.background.secondary }),
                    dayNumber: css({ '& span': { color: theme.palette.text.primary } }),
                    dayDisabled: css({
                        backgroundColor: theme.palette.action.disabledBackground,
                        color: theme.palette.text.disabled,
                    }),
                } }))));
});
