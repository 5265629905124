import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { logger } from '@corti/logger';
import { protocolGraphDB } from 'core/protocolGraphDB';
import { RealTimeModule } from './RealTimeModule';
import { RealTimeView } from './RealTimeView';
export let module;
const mod = {
    id: 'real-time',
    title: () => intl.t('realtime:appName', 'Triage'),
    path: '/real-time',
    icon: SupportAgentRoundedIcon,
    requiresOrgFeature: 'real-time',
    requiresUserPermission: 'app:real_time',
    viewComponent: (props) => React.createElement(RealTimeView, Object.assign({ module: module }, props)),
    onInit: (ctx) => {
        if (!module) {
            module = new RealTimeModule({
                apiContributor: ctx.publicApi,
                protocolGraphDB: protocolGraphDB,
                logger: logger.getLogger('Triage'),
            });
        }
    },
    onDestroy: () => {
        if (module.module.status === 'ok') {
            module.module.controller.destroy();
        }
        module = null;
    },
    navGroupe: 0,
};
export default mod;
