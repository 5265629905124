var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { ElementSerializer, } from 'lib/graphEditor/ElementSerializer';
import { CustomProperties } from 'lib/graphEditor/common/customProperties';
import { sourceToID } from 'lib/graphEditor/detections';
import { BaseGraphEditorNode } from '../common/BaseGraphEditorNode';
export class TimelineEntryAlertNode extends BaseGraphEditorNode {
    constructor() {
        super({ type: 'timelineEntryAlertNode' });
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.addPortOut();
        this.customProperties = new CustomProperties();
    }
    setSource(source) {
        this.source = source;
    }
    get sourceID() {
        return this.source ? sourceToID(this.source) : undefined;
    }
    addPortOut() {
        if (this.portOut) {
            throw new Error('Detection trigger node only allowed to have one output port');
        }
        return super.addPortOut({ maxLinks: 1 });
    }
    get portOut() {
        return this.outputPorts[0];
    }
    get readableTitle() {
        if (this.name.trim() !== '') {
            return this.name;
        }
        return `Detection Trigger`;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { source: this.source ? Object.assign({}, this.source) : undefined });
    }
    copy() {
        const i = super.copy();
        i.setSource(this.source);
        return i;
    }
    deserialize(data, ctx) {
        super.deserialize(data, ctx);
        this.setSource(data.source);
    }
}
Object.defineProperty(TimelineEntryAlertNode, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (data, ctx) => {
        const i = new TimelineEntryAlertNode();
        i.deserialize(data, ctx);
        return i;
    }
});
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEntryAlertNode.prototype, "source", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEntryAlertNode.prototype, "setSource", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEntryAlertNode.prototype, "sourceID", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TimelineEntryAlertNode.prototype, "readableTitle", null);
ElementSerializer.register(TimelineEntryAlertNode, 'TimelineEntryAlertNode');
