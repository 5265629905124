import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Typography } from 'lib/cortiUI';
export const NoDataGrid = () => {
    const { t } = useTranslation('templateList', { keyPrefix: 'dataGrid' });
    return (React.createElement(Typography, { className: css({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }) }, t('noResultsFound', 'No results found. Please create a new template')));
};
