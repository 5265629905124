import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export function ChartHeader({ renderRightComponent }) {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'chartPanel' });
    return (React.createElement(Base, { display: "flex", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Base, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { variant: "body1", color: "default" }, t('title', 'History'))),
        renderRightComponent && (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center" }, renderRightComponent()))));
}
