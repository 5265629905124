import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, IconButton, Modal, Typography } from 'lib/cortiUI';
import { ToolPalettePlaceholder } from 'lib/graphEditor/views';
import { GraphSaveDialog } from './GraphSaveDialog';
import { useGraphStatus } from './useGraphStatus';
import { useSaveGraph } from './useSaveGraph';
export const GraphToolbox = observer(function GraphToolbox({ editor, graphVersion, isReleased, }) {
    const { t } = useTranslation();
    const [hasReadonlyAlert, setReadonlyAlert] = useState(isReleased);
    const [hasSavingModal, setSavingModal] = useState(false);
    const lastChangeDate = graphVersion.updatedAt ? new Date(graphVersion.updatedAt) : new Date();
    const [savingState, setSavingState] = useState({ type: 'initial', lastChangeDate });
    const persistGraph = useSaveGraph({ editor, setSavingState });
    const graphStatus = useGraphStatus({ editor, isReleased, savingState });
    // You can save if not already saving and graph is not released (which equals readonly)
    const canSave = savingState.type !== 'saving' && !isReleased;
    // In the future, when all graph changes are being recorded to the undo stack,
    // we can replace with this line to disable saving when there are no changes to save
    // const canSave = savingState.type !== 'saving' && !isReleased && statusAndWording.canSave
    return (React.createElement(ToolPalettePlaceholder, null,
        React.createElement(Base, { display: 'flex', alignItems: "center", gap: 4 },
            React.createElement(Typography, { variant: "caption" }, graphStatus.text),
            savingState.type === 'error' && (React.createElement(IconButton, { color: "error", onClick: savingState.showError, icon: React.createElement(ErrorOutlineOutlined, null) })),
            React.createElement(Button, { "data-cy": "save-graph-button", variant: "contained", color: "primary", onClick: () => setSavingModal(true), disabled: !canSave }, t('editorApp2:graphEditorView.saveToBackendBtn', 'Save Graph')),
            React.createElement(Modal, { open: hasSavingModal, onClose: () => setSavingModal(false) },
                React.createElement(GraphSaveDialog, { editor: editor, onCancel: () => setSavingModal(false), onSuccess: ({ message }) => {
                        setSavingModal(false);
                        void persistGraph(message);
                    } })),
            hasReadonlyAlert && (React.createElement(Base, { position: "absolute", top: "60px", right: 0, left: 0, p: 16, zIndex: 2, display: "flex", justifyContent: "center" },
                React.createElement(Alert, { onClose: () => setReadonlyAlert(false), severity: "warning" },
                    React.createElement(AlertTitle, null, t('editorApp2:graphEditorView.releasedGraphReadonlyAlertTitle', 'This graph cannot be edited')),
                    t('editorApp2:graphEditorView.releasedGraphReadonlyAlertBody', "This graph is released and changes can't be saved. To make changes, please go back and make a new draft from the Overview page.")))))));
});
