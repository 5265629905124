import { debounce } from 'lodash';
import Mousetrap from 'mousetrap';
import React, { useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useDidMount } from '@corti/react';
import { Box } from 'lib/cortiUI/components/Box';
import { CarouselItem } from './CarouselItem';
import { CarouselProvider } from './CarouselProvider';
import { WindowedList } from './WindowedList';
const DEFAULT_ANIMATION_DURATION = 500;
const CONTAINER_RESIZE_DEBOUNCE = 150;
/**
 *
 * Component displays progress through a sequence of logical and numbered steps.(Also called Stepper)
 */
export function Carousel(props) {
    const { steps, activeStepIdx, onChange, onAnimationStart, onAnimationEnd, onContainerResize, renderStep, visibleSteps = 2, animationDuration = DEFAULT_ANIMATION_DURATION, disableKeyboardShortcuts = false, disableWindowing = false, } = props;
    let animationTimer = undefined;
    const containerRef = React.useRef(null);
    const didMount = useDidMount();
    const containerResizeCallback = useRef(onContainerResize);
    const [containerWidth, setContainerWidth] = React.useState(0);
    React.useEffect(() => {
        if (containerRef.current === null)
            return;
        // When component unmounts the reference of the element we want to observe is no longer stored.
        // To avoid a possible memory leak and unobserve the element
        // we have to keep a reference of it in the scope of this function.
        const element = containerRef.current;
        const handleContainerResize = (width) => {
            var _a;
            (_a = containerResizeCallback.current) === null || _a === void 0 ? void 0 : _a.call(containerResizeCallback, { width });
            setContainerWidth(width);
        };
        const handleContainerResizeDebounced = debounce(handleContainerResize, CONTAINER_RESIZE_DEBOUNCE, { leading: true });
        const observer = new ResizeObserver((e) => {
            handleContainerResizeDebounced(e[0].contentRect.width);
        });
        observer.observe(element);
        return () => {
            handleContainerResizeDebounced.cancel();
            observer.unobserve(element);
        };
    }, []);
    React.useEffect(() => {
        if (!didMount) {
            return;
        }
        if (animationDuration >= 0) {
            if (onAnimationStart) {
                onAnimationStart(activeStepIdx);
            }
            if (onAnimationEnd) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                animationTimer = window.setTimeout(() => {
                    onAnimationEnd(activeStepIdx);
                    animationTimer = undefined;
                }, animationDuration);
            }
        }
        return () => {
            window.clearTimeout(animationTimer);
            animationTimer = undefined;
        };
    }, [activeStepIdx]);
    React.useEffect(() => {
        if (disableKeyboardShortcuts) {
            return;
        }
        const keybinder = new Mousetrap();
        keybinder.bind('left', () => {
            if (activeStepIdx !== 0) {
                onChange(activeStepIdx - 1);
            }
        });
        keybinder.bind('right', () => {
            if (activeStepIdx < steps.length - 1) {
                onChange(activeStepIdx + 1);
            }
        });
        return () => {
            keybinder.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableKeyboardShortcuts, activeStepIdx, steps.length]);
    const slideWidth = containerWidth / visibleSteps;
    return (React.createElement(Box, { ref: containerRef, position: "relative", pr: [4, 0, 0, 0], pl: [4, 0], width: "100%", height: "100%", justifyContent: "center", alignItems: "center", overflow: "hidden", "data-cy": "carousel" }, containerWidth != null && (React.createElement(CarouselProvider, { onChange: onChange, activeStepIdx: activeStepIdx, renderStep: renderStep },
        React.createElement(WindowedList, { items: steps, itemSize: slideWidth, scrollPosition: slideWidth * activeStepIdx, animationDuration: animationDuration, disableWindowing: disableWindowing, ItemRenderer: CarouselItem })))));
}
