import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { TasksApp } from './TasksApp';
const mod = {
    id: 'tasks',
    title: () => intl.t('tasksApp:appName', 'Tasks'),
    path: '/tasks',
    icon: TaskAltRoundedIcon,
    requiresOrgFeature: 'mission-control-task-list',
    requiresUserPermission: 'mission_control_tasks:read',
    viewComponent: () => React.createElement(TasksApp, null),
    navGroupe: 1,
};
export default mod;
