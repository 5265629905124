import { reaction } from 'mobx';
import { trackerService } from 'browser/services/init';
import { repositories } from 'core/repositories';
import { EntityFactory } from 'lib/triageSession/utils';
export class AlertsAdapter {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "unsubscribers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "init", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.unsubscribers = [
                    this.input.sessionController.alerts.onCreate(this.handleAlertCreate),
                    this.input.sessionRepository.onSessionTimelineEntryCreated(this.input.sessionController.id, this.handleTimelineEntryCreate),
                ];
            }
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.unsubscribers.forEach((it) => it());
                this.unsubscribers = [];
            }
        });
        Object.defineProperty(this, "handleAlertCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (alert) => {
                trackerService.track('[Triage] alert shown', {
                    alertID: alert.id,
                    alertTitle: alert.title,
                    alertText: alert.text,
                    sessionID: this.input.sessionController.id,
                    caseID: this.input.sessionController.caseID,
                    externalID: this.input.sessionController.externalID,
                });
                if (!alert.alertNode) {
                    return;
                }
                if (!this.input.sessionController.isCurrentUserOwner) {
                    return;
                }
                await this.input.sessionRepository.addFlowStateChangeEvent(this.input.sessionController.id, {
                    event: 'triage-flow-alert-node-opened',
                    data: {
                        datetime: alert.datetime,
                        node: {
                            id: alert.alertNode.id,
                            customProperties: alert.alertNode.customProperties.map((it) => ({
                                key: it.key,
                                value: it.value,
                            })),
                        },
                    },
                });
            }
        });
        Object.defineProperty(this, "handleTimelineEntryCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                var _a, _b, _c;
                const definition = repositories.timelineEntryDefinitions.getDefinitionBySource(data.definition.source);
                if (!definition) {
                    (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.warn('timeline entry definition not found');
                    return;
                }
                // Graph might contain multiple alert nodes with the same timeline entry definition source.
                // Currently we dont know how to determine which alert node is the most relevant to this use case.
                // So we take the first one.
                const node = this.input.sessionController.flow.graphTraverser
                    .getExtraNodesByType('timelineEntryAlertNode')
                    .find((it) => {
                    if (it.timelineEntryDefinitionSource) {
                        return (it.timelineEntryDefinitionSource.type === definition.source.type &&
                            it.timelineEntryDefinitionSource.entityType === definition.source.entityType &&
                            it.timelineEntryDefinitionSource.entityID === definition.source.entityID);
                    }
                });
                // If no node is found, don't show the alert
                if (!node) {
                    return;
                }
                this.input.sessionController.alerts.create(EntityFactory.createAlert({
                    title: definition.title,
                    text: definition.text,
                    color: definition.style.color,
                    datetime: data.startDatetime,
                    alertNode: {
                        id: node.id,
                        targetNodeID: (_b = node.targetNodeID) !== null && _b !== void 0 ? _b : null,
                        customProperties: (_c = node.customProperties) !== null && _c !== void 0 ? _c : [],
                    },
                }));
            }
        });
        reaction(() => this.input.sessionController.settings.isAlertsEnabled, (isEnabled) => (isEnabled ? this.init() : this.destroy()), { fireImmediately: true });
    }
}
