var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed } from 'mobx';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { AbstractInputElementModel } from './AbstractInputElementModel';
export class NumberInputElementModel extends AbstractInputElementModel {
    constructor(props = {}) {
        super(props, {
            min: {
                type: 'number',
                name: 'Minimum value',
                defaultValue: null,
            },
            max: {
                type: 'number',
                name: 'Maximum value',
                defaultValue: null,
            },
            step: {
                type: 'number',
                name: 'Increment step',
                defaultValue: 1,
            },
            precision: {
                type: 'number',
                name: 'Number of decimals (0=integers only)',
                defaultValue: null,
            },
        });
        this.type = NumberInputElementModel.typename;
    }
    get min() {
        return this.attributes.values.min;
    }
    setMin(value) {
        this.attributes.setAttributeValue('min', value);
    }
    get max() {
        return this.attributes.values.max;
    }
    setMax(value) {
        this.attributes.setAttributeValue('max', value);
    }
    get step() {
        return this.attributes.values.step;
    }
    setStep(value) {
        this.attributes.setAttributeValue('step', value);
    }
    get precision() {
        return this.attributes.values.precision;
    }
    setPrecision(value) {
        this.attributes.setAttributeValue('precision', value);
    }
}
Object.defineProperty(NumberInputElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'number-input'
});
Object.defineProperty(NumberInputElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.numberInputElement.title', 'Number Input'),
    })
});
Object.defineProperty(NumberInputElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new NumberInputElementModel();
        i.deserialize(val);
        return i;
    }
});
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], NumberInputElementModel.prototype, "min", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NumberInputElementModel.prototype, "setMin", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], NumberInputElementModel.prototype, "max", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NumberInputElementModel.prototype, "setMax", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], NumberInputElementModel.prototype, "step", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], NumberInputElementModel.prototype, "setStep", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], NumberInputElementModel.prototype, "precision", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], NumberInputElementModel.prototype, "setPrecision", null);
ElementSerializer.register(NumberInputElementModel, 'NumberInputElementModel');
