import { isEqual } from 'lodash';
import React from 'react';
import { intl, useTranslation } from '@corti/i18n';
import { Autocomplete, Base, Box, MenuItem, TextField, Typography } from 'lib/cortiUI';
export function ChartPanelHeader(props) {
    const { activeChartType, activePeriod, periodOptions, onPeriodChange } = props;
    const { t } = useTranslation('transcriptSearchApp');
    const getOptionLabel = (option) => option.text;
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
        React.createElement(Typography, { variant: "h5", color: "default" }, t('timeSeries', 'Time Series')),
        React.createElement(Box, { display: "flex", flexDirection: "row" }, activeChartType === 'time-series' && (React.createElement(Autocomplete, { autoComplete: false, blurOnSelect: true, disableClearable: true, getOptionLabel: getOptionLabel, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_e, value) => onPeriodChange(value), options: periodOptions, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('transcriptSearchApp:timeSeriesChart.periodType', 'Period type') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.text)), size: "small", sx: { pr: 3, minWidth: 200 }, value: activePeriod })))));
}
