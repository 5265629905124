const defaultOptions = {
    maxStack: Infinity,
};
export class UndoManager {
    constructor(options = {}) {
        var _a;
        Object.defineProperty(this, "undoStack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "redoStack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.options = {
            maxStack: (_a = options.maxStack) !== null && _a !== void 0 ? _a : defaultOptions.maxStack,
        };
    }
    register(action) {
        if (this.undoStack.length >= this.options.maxStack) {
            this.undoStack.shift();
        }
        this.undoStack.push(action);
        this.redoStack.splice(0, this.redoStack.length);
    }
    undo() {
        if (this.undoStack.length === 0) {
            return;
        }
        const action = this.undoStack.pop();
        this.redoStack.push(action);
        return action;
    }
    redo() {
        if (this.redoStack.length === 0) {
            return;
        }
        const action = this.redoStack.pop();
        this.undoStack.push(action);
        return action;
    }
    reset() {
        this.undoStack.splice(0, this.undoStack.length);
        this.redoStack.splice(0, this.redoStack.length);
    }
    canUndo() {
        return this.undoStack.length !== 0;
    }
    canRedo() {
        return this.redoStack.length !== 0;
    }
    getUndoStack() {
        return [...this.undoStack];
    }
}
