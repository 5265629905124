import { useEffect, useState } from 'react';
export default function useHover(containerRef) {
    const [isHovered, setIsHovered] = useState(false);
    // Handle hover
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const node = containerRef.current;
        const onMouseEnter = () => setIsHovered(true);
        const onMouseLeave = () => setIsHovered(false);
        node.addEventListener('mouseenter', onMouseEnter);
        node.addEventListener('mouseleave', onMouseLeave);
        return () => {
            node.removeEventListener('mouseenter', onMouseEnter);
            node.removeEventListener('mouseleave', onMouseLeave);
        };
    }, [containerRef]);
    return isHovered;
}
