import { TimelineEntryDefinitionSourceType } from './types';
export const DEFAULT_DEFINITION_COLOR = '#787878';
export const DEFAULT_DEFINITION = {
    source: {
        type: TimelineEntryDefinitionSourceType.default,
        entityType: 'fe-client-definition',
        entityID: undefined,
    },
    title: '[Default Definition]',
    text: '',
    style: {
        color: DEFAULT_DEFINITION_COLOR,
    },
    isEntryCreatable: false,
    keywords: [],
    mods: [],
};
export function combineTimelineEntryDefinitionSourceValues(source) {
    return `${source.type}:${source.entityType}:${source.entityID || ''}`;
}
