import React from 'react';
import { Base } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { DateRangePicker } from '../../components/DateRangePicker';
import { useParametersContext } from '../../context/ParametersContext';
export const GlobalFilters = () => {
    const { dateRange, changeDateRange } = useParametersContext();
    const { isEditMode, isDraft } = useGlobalContext();
    return (React.createElement(Base, { "data-cy": "performance-filters-date-picker" },
        React.createElement(DateRangePicker, { value: dateRange, onChange: changeDateRange, readOnly: isEditMode || isDraft })));
};
