import React, { forwardRef, useState } from 'react';
import { intl } from '@corti/i18n';
import { Base, DatePickerWithTime } from 'lib/cortiUI';
import { Label } from './components';
export const DatePickerBlock = forwardRef(({ value, label, placeholder, isReadonly, isDateTime, onChange }, ref) => {
    const [selectedDate, setSelectedDate] = useState(() => value ? new Date(value) : null);
    const datePickerLabel = placeholder || isDateTime
        ? intl.t('contentBuilder.dateTimePickerElement.label', 'Select a date and time')
        : intl.t('contentBuilder.datePickerElement.label', 'Select a date');
    return (React.createElement(Base, null,
        label && (React.createElement(Base, { mb: 3 },
            React.createElement(Label, { label: label }))),
        React.createElement(DatePickerWithTime, { ref: ref, isReadonly: isReadonly, isDateTime: isDateTime, label: datePickerLabel, selectedDate: selectedDate, onChange: (date) => {
                setSelectedDate(date);
                onChange((date === null || date === void 0 ? void 0 : date.toISOString()) || '');
            } })));
});
