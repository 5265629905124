import Ajv from 'ajv';
import { cloneDeep, omit } from 'lodash';
import { logger } from '@corti/logger';
import { Observer } from '@corti/observer';
import { userConfigSchema } from './userConfigSchema';
const _validateAndFill = new Ajv({
    removeAdditional: 'failing',
    useDefaults: true,
    coerceTypes: true,
}).compile(userConfigSchema);
export const _validate = new Ajv({
    removeAdditional: 'failing',
    coerceTypes: true,
}).compile(userConfigSchema);
function _removeInvalidValues(config, errors) {
    return omit(config, 
    // instancePath starts with "." e.g ".buildNumber"
    errors.map((err) => err.instancePath.substring(1, err.instancePath.length)));
}
/**
 * Given an unknown object to this function it will correct it
 * and return a minimal valid config object
 * @return Minimal valid config object
 */
export function validateAndCorrect(config) {
    var _a, _b;
    let clone = cloneDeep(config);
    _validate(clone);
    let valid = _removeInvalidValues(clone, (_a = _validate.errors) !== null && _a !== void 0 ? _a : []);
    return [valid, (_b = _validate.errors) !== null && _b !== void 0 ? _b : []];
}
export class ConfigManager {
    constructor(input = {}) {
        Object.defineProperty(this, "defaultConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initialConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isInit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: logger.getLogger('configManager')
        });
        Object.defineProperty(this, "setUserConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newConfig, opts = { emitEvent: true }) => {
                const [validConfig, errors] = validateAndCorrect(newConfig);
                if (errors.length) {
                    this.logger.error(`user config errors\n${formatErrors(errors)}`);
                }
                this.userConfig = validConfig;
                if (opts.emitEvent) {
                    this.observer.fireEvent('didChangeUserConfig', this.userConfig);
                }
            }
        });
        Object.defineProperty(this, "setUserConfigValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key, value) => {
                this.userConfig[key] = value;
                this.observer.fireEvent('didChangeUserConfig', this.userConfig);
            }
        });
        this.isInit = false;
        this.observer = new Observer();
        // init default config from the default values defined in the schema
        this.defaultConfig = {};
        _validateAndFill(this.defaultConfig);
        if (input.initialConfig) {
            const [c, errors] = validateAndCorrect(input.initialConfig);
            if (errors.length) {
                this.logger.error(`build time config errors\n${formatErrors(errors)}`);
            }
            if (errors.length) {
            }
            this.initialConfig = c;
        }
    }
    // This module is used as a singleton, so instead of using constructor
    // we initialise it by using init
    init(data) {
        this.setUserConfig(data.userConfig);
        if (data.inlineConfig) {
            const [conf, errors] = validateAndCorrect(data.inlineConfig);
            if (errors.length) {
                this.logger.error(`inline config errors\n${formatErrors(errors)}`);
            }
            this.userConfig = Object.assign(Object.assign({}, this.userConfig), conf);
        }
        this.isInit = true;
    }
    /**
     * Returns assembled full config.
     * If there's a config value defined for the same key, that already existed, it will be overriden
     * according to the rules of order of presedence:
     *
     * 1. Default config values defined in the source code
     * 2. Config values passed in the constructor
     * 3. User config (electron config file or browser local storage)
     * 4. Inline config values (cli runtime arguments or browser query params)
     */
    getConfig() {
        if (!this.isInit) {
            throw new Error('ConfigManager not initialised');
        }
        return Object.assign(Object.assign(Object.assign({}, this.defaultConfig), this.initialConfig), this.userConfig);
    }
    validateAndCorrect(config) {
        return validateAndCorrect(config);
    }
    onDidChangeUserConfig(listener) {
        return this.observer.on('didChangeUserConfig', listener);
    }
}
function formatErrors(errors) {
    return errors.map((err) => err.instancePath + ' ' + err.message).join('\n');
}
