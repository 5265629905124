import React from 'react';
/**
 * A functional utility to `useState` as render props component
 *
 * Convenient in places where you need a quick and localised state to not sacriface performance
 * of the entire parent component re-rendering
 *
 * @param param0
 */
export function State({ initialState, children }) {
    const [state, setState] = React.useState(initialState);
    return children([state, setState]);
}
