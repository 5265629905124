import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base } from 'lib/cortiUI';
import { SettingsSection } from '../SettingsSection';
import { HideAnnotationType } from './HideAnnotationType';
import { HideDetectionType } from './HideDetectionType';
import { PerformanceTrendToggle } from './PerformanceTrendToggle';
import { TimelineEntryDefinitionImport } from './TimelineEntryDefinitionImport';
export const OrgDataManagement = () => {
    const { t } = useTranslation();
    return (React.createElement(Base, null,
        React.createElement(SettingsSection, { title: t('adminPanel:orgDataManagement.sectionTitle', 'Clear') },
            React.createElement(HideAnnotationType, null),
            React.createElement(HideDetectionType, null),
            React.createElement(TimelineEntryDefinitionImport, null),
            React.createElement(PerformanceTrendToggle, null))));
};
