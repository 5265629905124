import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { DetectionsCircularProgressBar } from 'lib/cortiAnalyticsUI';
import { Base, Card, CardContent, Tooltip, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { calculatePerformanceScore, } from '../../../activeView/context/dataContext';
import { CallTakersMetricsBreakdownItem } from './CallTakersMetricsBreakdownItem';
export const CallTakersMetricsWidget = ({ breakdown }) => {
    const { goal } = useParametersContext();
    const performance = calculatePerformanceScore(breakdown);
    const theme = useTheme();
    return (React.createElement(Tooltip, { className: css({
            backgroundColor: 'transparent',
            padding: 0,
            maxWidth: 'unset',
        }), title: React.createElement(Card, { maxHeight: "50vh", overflow: "hidden", display: "flex", flexDirection: "column" },
            React.createElement(Typography, { p: 4, variant: "h6", width: "100%", color: "default" }, intl.t('performanceApp:questionBreakdown', 'Breakdown')),
            React.createElement(CardContent, { sx: { pt: 0, display: 'flex', flexDirection: 'column', gap: 3 }, className: css(getScrollerCss({ theme })) }, breakdown.map((it) => (React.createElement(CallTakersMetricsBreakdownItem, { title: it.source.text, value: it.percentage, target: goal.enabled ? goal.value / 100 : undefined, key: it.source.key.entityID + it.source.key.type }))))) },
        React.createElement(Base, null,
            React.createElement(DetectionsCircularProgressBar, { value: performance / 100, target: goal.enabled ? goal.value / 100 : undefined, label: `${performance}%`, size: 24, strokeWidth: 4 }))));
};
