import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getTemplateById, isAutomaticReview, } from '@corti/mission-control-api';
import { transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, Card, ChipV2, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
import { GradientStarIcon } from '../components';
import { getReviewComplianceStatusData, getReviewComplianceStatusPaletteColor } from '../utils';
import { AvgComparison } from './AvgComparison';
import { Bar } from './Bar';
export const Score = ({ review, template }) => {
    var _a;
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.score' });
    const { palette } = useTheme();
    const { interaction } = useInteraction();
    const { currentUserID } = useAuth();
    const [detailedTemplate, setDetailedTemplate] = React.useState();
    React.useEffect(() => {
        void fetchDetailedTemplate(template.id);
    }, [template.id]);
    const scorePercentage = isNaN(review.score_percentage)
        ? undefined
        : Math.floor(review.score_percentage);
    const complianceStatusData = getReviewComplianceStatusData(review.compliance_status);
    const gradientColor = transparentize(0.93, (_a = getReviewComplianceStatusPaletteColor(review.compliance_status, palette)) !== null && _a !== void 0 ? _a : palette.background.card);
    const isAiReview = isAutomaticReview(review.created_by);
    async function fetchDetailedTemplate(templateID) {
        try {
            const response = await getTemplateById(templateID);
            setDetailedTemplate(response);
        }
        catch (error) {
            console.error('failed to fetch template:', error);
        }
    }
    if (scorePercentage == undefined)
        return null;
    return (React.createElement(Card, { p: 6, background: `linear-gradient(57deg, ${gradientColor} 0%, ${palette.background.card} 48%, ${palette.background.card} 100%)` },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 2, alignItems: "center" },
                isAiReview && React.createElement(GradientStarIcon, { fontSize: "small" }),
                React.createElement(Typography, { color: "default", variant: "subtitle2" }, `${isAiReview ? `${t('ai', 'AI')} ` : ''}${t('title', 'Score')}`)),
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 6, justifyContent: "space-between" },
                React.createElement(Base, { flex: 1 },
                    React.createElement(Base, { display: "flex", gap: 5, alignItems: "baseline", justifyContent: "space-between" },
                        React.createElement(Typography, { variant: "h4", color: "default" },
                            scorePercentage,
                            "%"),
                        React.createElement(ChipV2, Object.assign({ variant: "outlined" }, complianceStatusData))),
                    React.createElement(Bar, { value: scorePercentage, threshold: (detailedTemplate === null || detailedTemplate === void 0 ? void 0 : detailedTemplate.has_threshold) ? detailedTemplate.threshold : undefined, partialThreshold: (detailedTemplate === null || detailedTemplate === void 0 ? void 0 : detailedTemplate.has_partial_threshold)
                            ? detailedTemplate.partial_threshold
                            : undefined, complianceStatus: review.compliance_status })),
                ((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) === currentUserID ||
                    rbacService.missionControlOtherAssessmentsRead()) && (React.createElement(Base, { flex: 1, display: "flex", justifyContent: "flex-end" },
                    React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5, justifyContent: "flex-end" },
                        (interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) && (React.createElement(AvgComparison, { score: scorePercentage, templateID: review.template_id, label: t('agent', 'Agent Average'), userIDs: [interaction.interaction_owner] })),
                        rbacService.missionControlOtherAssessmentsRead() && (React.createElement(AvgComparison, { score: scorePercentage, templateID: review.template_id, label: t('organization', 'Org Average'), userIDs: [] })))))))));
};
