import { useMutation } from '@apollo/client';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { theme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Button, Dialog, Loading, MenuItem, Typography } from 'lib/cortiUI';
import { deleteFeedbackFormTemplateMutation, getFeedbackFormTemplatesQuery, } from '../../graphql';
export const DeleteAction = ({ feedbackFormTemplateID, onClose }) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'templatesDataGrid' });
    const authStore = useAuth();
    const organizationID = authStore.organization.id;
    const [deleteTemplate, { loading: isDeleteTemplateLoading }] = useMutation(deleteFeedbackFormTemplateMutation, {
        refetchQueries: [
            {
                query: getFeedbackFormTemplatesQuery,
                variables: {
                    organizationID,
                },
            },
        ],
    });
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setModalOpen(false);
        onClose();
    };
    async function handleTemplateDelete() {
        setModalOpen(false);
        await deleteTemplate({ variables: { id: feedbackFormTemplateID } });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { "data-cy": "delete-template-btn", onClick: handleOpen, gap: 3, className: css({
                '&:hover': {
                    backgroundColor: theme.palette.error.outlinedHoverBackground,
                    color: theme.palette.text.error,
                },
            }) }, isDeleteTemplateLoading ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
            React.createElement(DeleteRoundedIcon, { color: "error" }),
            React.createElement(Typography, { variant: "body1" }, t('showMore.delete', 'Delete'))))),
        React.createElement(Dialog, { open: modalOpen, onClose: handleClose, title: t('actionsWarnings.deleteTitle', 'Permanently delete this template?'), helperText: t('actionsWarnings.deleteMessage', 'All feedback submissions where this template has been used will remain visible.'), actionButtons: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", onClick: handleClose }, t('actionsWarnings.deleteCancel', 'Keep')),
                React.createElement(Button, { variant: "contained", color: "error", onClick: () => handleTemplateDelete(), "data-cy": "delete-template-confirm-btn" }, t('actionsWarnings.deleteConfirm', 'Delete forever'))) })));
};
