import React from 'react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Avatar } from 'lib/cortiUI/components/Avatar';
import { Box } from 'lib/cortiUI/components/Box';
import { Typography } from 'lib/cortiUI/components/Typography';
/**
 * Component that displays initials of provided title and applies random colors. Also it shows title as text next to avatar
 */
export function AvatarWithText(props) {
    const { title } = props;
    const theme = useTheme();
    return (React.createElement(Box, { flexDirection: "row", alignItems: "center", className: css({
            borderRadius: '50vh',
            backgroundColor: transparentize(0.8, theme.palette.grey[500]),
            width: '100%',
            maxWidth: 'max-content',
        }) },
        React.createElement(Avatar, { size: "small" }, title),
        title && (React.createElement(Typography, { variant: "body2", color: "default", noWrap: true, noSelect: true, ml: 4, mr: 5 }, title))));
}
