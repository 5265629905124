import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { BarContainer, BarSection } from './Bar';
import { LegendItem } from './LegendItem';
export const FeedbackChart = ({ data }) => {
    const { palette, space } = useTheme();
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'assessmentFeedbackPanel' });
    const total = data.reduce((acc, it) => acc + it.percentage, 0);
    const feedbackChartItems = getFeedbackChartItems();
    function getFeedbackChartItems() {
        const chartItems = [
            {
                status: 'NO_AGENT_INPUT',
                label: t('pending', 'No Agent Input'),
                color: palette.grey[palette.mode === 'light' ? 300 : 600],
                order: 1,
            },
            {
                status: 'REVIEW_REQUESTED',
                label: t('flagged', 'Review Requested'),
                color: palette.warning.light,
                order: 2,
            },
            {
                status: 'ACKNOWLEDGEMENT_REQUESTED',
                label: t('ackRequested', 'Acknowledgement Requested'),
                color: palette.primary.light,
                order: 3,
            },
            {
                status: 'ACKNOWLEDGED',
                label: t('acknowledged', 'Acknowledged'),
                color: palette.success.light,
                order: 4,
            },
        ];
        return chartItems.map((item) => {
            var _a, _b;
            const dataItem = data.find((it) => it.value === item.status);
            return Object.assign(Object.assign({}, item), { status: item.status, percentage: (_a = dataItem === null || dataItem === void 0 ? void 0 : dataItem.percentage) !== null && _a !== void 0 ? _a : 0, barWidth: getSectionWidth((_b = dataItem === null || dataItem === void 0 ? void 0 : dataItem.percentage) !== null && _b !== void 0 ? _b : 0) });
        });
    }
    function getSectionWidth(percentage) {
        return (percentage / total) * 100;
    }
    return (React.createElement(Base, { width: "100%", display: "flex", flexDirection: "column", gap: 7 },
        React.createElement(BarContainer, null, feedbackChartItems
            .filter((it) => it.barWidth > 0)
            .map((it, idx) => {
            var _a, _b;
            const hasMarginR = ((_a = feedbackChartItems[idx + 1]) === null || _a === void 0 ? void 0 : _a.barWidth) > 0 && it.barWidth > 0;
            const hasMarginL = ((_b = feedbackChartItems[idx - 1]) === null || _b === void 0 ? void 0 : _b.barWidth) > 0 && it.barWidth > 0;
            return (React.createElement(BarSection, { key: it.label, width: it.barWidth, bgcolor: it.color, mr: hasMarginR ? 1 : 0, ml: hasMarginL ? 1 : 0 }));
        })),
        React.createElement(Base, { display: "flex", alignItems: "center", flexWrap: "wrap", className: css({ columnGap: space[7] }) }, feedbackChartItems.map(({ label, color, percentage }) => {
            return React.createElement(LegendItem, { key: label, label: label, color: color, value: percentage });
        }))));
};
