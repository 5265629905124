import React from 'react';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, Separator, Typography } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { useParametersContext } from '../../context/ParametersContext';
import { DiscardButton } from './DiscardButton';
import { EditModeButton } from './EditModeButton';
import { LockedViewChip } from './LockedViewChip';
import { ResetButton } from './ResetButton';
import { SaveButton } from './SaveButton';
import { SettingsButton } from './SettingsButton';
export const Header = () => {
    var _a;
    const { currentUserID } = useAuth();
    const { isEditMode, isDraft } = useGlobalContext();
    const { title, locked, createdBy, isChanged } = useParametersContext();
    return (React.createElement(Base, { "data-cy": "performance-view-header", display: "flex", alignItems: "center", justifyContent: "space-between" },
        React.createElement(Base, { display: "flex", gap: 3, alignItems: "center" },
            React.createElement(Typography, { color: "default", variant: "h6", fontStyle: isDraft ? 'italic' : 'normal' }, title),
            !isDraft && isEditMode && React.createElement(SettingsButton, null)),
        React.createElement(Base, { display: "flex", gap: 4, alignItems: "center" },
            isChanged && !isDraft && React.createElement(ResetButton, null),
            (isEditMode || isDraft) && (React.createElement(React.Fragment, null,
                React.createElement(DiscardButton, null),
                React.createElement(SaveButton, null))),
            rbacService.performanceViewsCRUD() &&
                !isDraft &&
                !isEditMode &&
                (locked && (createdBy === null || createdBy === void 0 ? void 0 : createdBy.id) !== currentUserID ? (React.createElement(LockedViewChip, { creatorFullName: (_a = createdBy === null || createdBy === void 0 ? void 0 : createdBy.name) !== null && _a !== void 0 ? _a : '' })) : (React.createElement(React.Fragment, null,
                    isChanged && (createdBy === null || createdBy === void 0 ? void 0 : createdBy.id) === currentUserID && React.createElement(Separator, null),
                    React.createElement(EditModeButton, null)))))));
};
