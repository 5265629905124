var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ElementSerializer, } from 'lib/graphEditor/ElementSerializer';
import { BaseEntity } from 'lib/graphEditor/core';
import { BaseInstance } from './BaseInstance';
import { BlockInstance } from './BlockInstance';
export class ContentBuilderContext extends BaseEntity {
    static createBlockInstance(element) {
        return new BlockInstance({ wrappedElement: element });
    }
    constructor(opt = {}) {
        super(opt.parent);
        Object.defineProperty(this, "selectedElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "preselectedElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "elements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "removeElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (element) => {
                var _a;
                const idx = this.elements.indexOf(element);
                if (idx !== -1) {
                    this.elements.splice(idx, 1);
                    (_a = this.globalContext) === null || _a === void 0 ? void 0 : _a.dropElementIndex(element);
                }
            }
        });
        makeObservable(this);
        runInAction(() => {
            this.elements = [];
        });
    }
    get globalContext() {
        var _a;
        return (_a = this.graphEditorModelContext) === null || _a === void 0 ? void 0 : _a.elementContext;
    }
    get blocks() {
        const result = new Map();
        this.elements.forEach((it) => {
            const b = it.wrappedElement;
            result.set(b.id, b);
        });
        return result;
    }
    hasBlock(block) {
        return this.blocks.has(block.id);
    }
    setElements(elements) {
        this.elements.splice(0, this.elements.length);
        elements.forEach((c) => this.addElement(c));
        return this;
    }
    addElement(element, position = this.elements.length) {
        var _a;
        if (element.parent) {
            element.remove();
        }
        element.setParent(this);
        this.elements.splice(position, 0, element);
        (_a = this.globalContext) === null || _a === void 0 ? void 0 : _a.indexElement(element);
        return this;
    }
    setSelectedElement(element) {
        this.selectedElement = element;
    }
    setPreselectedElement(element) {
        this.preselectedElement = element;
    }
    getElements() {
        return this.elements;
    }
    getPrototypes() {
        return [...new Set(this.elements.map((el) => el.wrappedElement))];
    }
    getElementByID(id) {
        return this.getElements().find((el) => el.id === id);
    }
    get valuePublisherItems() {
        const result = new Map();
        this.blocks.forEach((it) => {
            it.valuePublisherItems.forEach((v) => {
                result.set(v.id, v);
            });
        });
        return result;
    }
    get referenceableItems() {
        return new Map(this.elements.flatMap((el) => {
            return [...el.wrappedElement.referenceableItems];
        }));
    }
    getReferenceableItems() {
        return [...this.referenceableItems.values()];
    }
    getReferenceableItemByID(id) {
        return this.referenceableItems.get(id);
    }
    deserialize(input, ctx) {
        super.deserialize(input, ctx);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { elements: [...this.elements], elementIDs: this.elements.map((it) => it.id) });
    }
    copy() {
        const i = super.copy();
        this.elements.forEach((it) => {
            i.addElement(it.copy());
        });
        return i;
    }
}
Object.defineProperty(ContentBuilderContext, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val, ctx) => {
        const c = new ContentBuilderContext();
        c.deserialize(val, ctx);
        val.elementIDs.forEach((id) => {
            ctx.getElement(id).then((it) => {
                c.addElement(it);
            });
        });
        return c;
    }
});
__decorate([
    observable,
    __metadata("design:type", Object)
], ContentBuilderContext.prototype, "selectedElement", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ContentBuilderContext.prototype, "preselectedElement", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], ContentBuilderContext.prototype, "elements", void 0);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_b = typeof Map !== "undefined" && Map) === "function" ? _b : Object),
    __metadata("design:paramtypes", [])
], ContentBuilderContext.prototype, "blocks", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", Object)
], ContentBuilderContext.prototype, "setElements", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof BaseInstance !== "undefined" && BaseInstance) === "function" ? _c : Object, Number]),
    __metadata("design:returntype", void 0)
], ContentBuilderContext.prototype, "addElement", null);
__decorate([
    action,
    __metadata("design:type", Object)
], ContentBuilderContext.prototype, "removeElement", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ContentBuilderContext.prototype, "setSelectedElement", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ContentBuilderContext.prototype, "setPreselectedElement", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_d = typeof Map !== "undefined" && Map) === "function" ? _d : Object),
    __metadata("design:paramtypes", [])
], ContentBuilderContext.prototype, "valuePublisherItems", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_e = typeof Map !== "undefined" && Map) === "function" ? _e : Object),
    __metadata("design:paramtypes", [])
], ContentBuilderContext.prototype, "referenceableItems", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ContentBuilderContext.prototype, "copy", null);
ElementSerializer.register(ContentBuilderContext, 'ContentBuilderContext');
