export class GroupedFocusContextController {
    constructor() {
        Object.defineProperty(this, "controllers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "controllerSnapshot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.controllers = new Map();
        this.controllerSnapshot = new Map();
    }
    registerController(controller) {
        if (this.controllers.get(controller.id)) {
            return;
        }
        this.controllers.set(controller.id, controller);
    }
    unregisterController(controllerID) {
        this.controllers.delete(controllerID);
    }
    pauseAllKeyboardShortcuts() {
        this.controllers.forEach((it) => {
            this.controllerSnapshot.set(it.id, {
                id: it.id,
                keyboardShortcutsEnabled: it.keyboardShortcutsEnabled,
            });
            it.disableKeyboardShortcuts();
        });
    }
    unpauseAllKeyboardShortcuts() {
        this.controllers.forEach((it) => {
            const c = this.controllerSnapshot.get(it.id);
            if (!c) {
                return;
            }
            if (!c.keyboardShortcutsEnabled) {
                return;
            }
            it.enableKeyboardShortcuts();
        });
    }
}
