import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Typography } from 'lib/cortiUI';
export function NoRowsOverlay() {
    return (React.createElement(Typography, { className: css({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }) }, intl.t('caseReviewRequestsModule:tableNoDataFallback.text', 'No results found')));
}
