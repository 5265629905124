var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { TextField } from 'lib/cortiUI';
import { CheckboxField } from './CheckboxField';
import { ColorPickerField } from './ColorPickerField';
import { MultipleAutocompleteField } from './MultipleAutocompleteField';
import { RadioGroupField } from './RadioGroupField';
import { SingleAutocompleteField } from './SingleAutocompleteField';
import { ToggleField } from './ToggleField';
import { FormFieldVariant } from './types';
import { TEST_IDS } from './utils';
export const GenericField = ({ fieldData, onChange, error, invalid }) => {
    const { t } = useTranslation('annotationManagement');
    switch (fieldData.variant) {
        case FormFieldVariant.autocompleteSingle:
            return (React.createElement(SingleAutocompleteField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue, error: invalid, errorMessage: error === null || error === void 0 ? void 0 : error.message })));
        case FormFieldVariant.autocompleteMultiple:
            return (React.createElement(MultipleAutocompleteField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue, error: invalid, errorMessage: error === null || error === void 0 ? void 0 : error.message })));
        case FormFieldVariant.textfield: {
            const { variant, name, rules, initialValue } = fieldData, props = __rest(fieldData, ["variant", "name", "rules", "initialValue"]);
            return (React.createElement(TextField, Object.assign({ size: "medium", name: name, "data-cy": TEST_IDS.textfield }, props, { value: initialValue, error: invalid, helperText: invalid && ((error === null || error === void 0 ? void 0 : error.message) || t('errorMessages.invalid', 'Invalid')), onChange: ({ target }) => onChange(target.value), onBlur: ({ target }) => {
                    onChange(target.value.trim() || null);
                }, required: Boolean(rules === null || rules === void 0 ? void 0 : rules.required) })));
        }
        case FormFieldVariant.colorPicker:
            return React.createElement(ColorPickerField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue }));
        case FormFieldVariant.checkbox:
            return React.createElement(CheckboxField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue }));
        case FormFieldVariant.toggle:
            return React.createElement(ToggleField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue }));
        default:
            return React.createElement(RadioGroupField, Object.assign({}, fieldData, { onChange: onChange, value: fieldData.initialValue }));
    }
};
