import { reaction } from 'mobx';
import { createSessionObject } from '../publicapi/utils';
import { commentTransformer } from '../transformers/comment';
export class CommentsAdapter {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "unsubscribers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "init", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.unsubscribers = [
                    this.input.sessionController.comments.onCreate(this.handleCommentCreate),
                    this.input.sessionController.comments.onCreate(this.handlePublicApiCommentCreate),
                    this.input.sessionRepository.onCommentAdded(this.input.sessionController.id, this.handleCommentAdd),
                ];
            }
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.unsubscribers.forEach((it) => it());
                this.unsubscribers = [];
            }
        });
        Object.defineProperty(this, "handleCommentCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (comment) => {
                if (!this.input.sessionController.isCurrentUser(comment.createdBy.id)) {
                    return;
                }
                await this.input.sessionRepository.addComment(this.input.sessionController.id, commentTransformer.toRepo(comment));
            }
        });
        Object.defineProperty(this, "handleCommentAdd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (comment) => {
                if (!this.input.sessionController.isCurrentUser(comment.createdBy.id)) {
                    this.input.sessionController.comments.create(commentTransformer.fromRepo(comment));
                }
            }
        });
        Object.defineProperty(this, "handlePublicApiCommentCreate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (comment) => {
                if (!this.input.sessionController.isCurrentUser(comment.createdBy.id)) {
                    return;
                }
                this.input.apiContributor.fireEvent({
                    name: 'realtime.session.comments.comment-created',
                    data: {
                        session: createSessionObject(this.input.sessionController.serializeSession()),
                        comment: {
                            text: comment.text,
                            createdBy: {
                                id: comment.createdBy.id,
                            },
                        },
                    },
                });
            }
        });
        reaction(() => this.input.sessionController.settings.isCommentsEnabled, (isEnabled) => (isEnabled ? this.init() : this.destroy()), { fireImmediately: true });
    }
}
