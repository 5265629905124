export const casesOutputConfigurationOptions = [
    'caseCreatedBy',
    'caseStartedAt',
    'caseEndedAt',
    'caseUpdatedAt',
    'caseReviewedBy',
    'caseTags',
    'sessionCreatedBy',
    'sessionExternalID',
    'callUserExtension',
    'callExternalID',
    'profilePhone',
    'profileAddress',
    'caseReviewType',
    'caseHasReviewAcknowledged',
    'caseAssignedTo',
    'calledParty',
    'callingParty',
    'customProperties',
    'protocolName',
    'caseDuration',
    'callTags',
    'callParticipants',
    'callProperties',
    'callSource',
];
export var CallTagsValue;
(function (CallTagsValue) {
    CallTagsValue["performanceMetricsExcluded"] = "performanceMetricsExcluded";
})(CallTagsValue || (CallTagsValue = {}));
export var CaseReviewRequestStatus;
(function (CaseReviewRequestStatus) {
    CaseReviewRequestStatus["pendingReview"] = "PENDING_REVIEW";
    CaseReviewRequestStatus["pendingAck"] = "PENDING_ACK";
    CaseReviewRequestStatus["completed"] = "COMPLETED";
})(CaseReviewRequestStatus || (CaseReviewRequestStatus = {}));
// #endregion
