import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { duplicateTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Loading, MenuItem, Typography } from 'lib/cortiUI';
export const Duplicate = ({ templateID }) => {
    const { t } = useTranslation('templateList', { keyPrefix: 'dataGrid.actions.duplicate' });
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleDuplicate() {
        try {
            setIsLoading(true);
            const res = await duplicateTemplate(templateID);
            history.push(`${history.location.pathname}/${res.id}`);
            coreStore.notifications.showNotification({
                message: t('success', 'Template duplicated'),
            });
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                message: t('error', 'Failed to duplicate'),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: handleDuplicate, disabled: isLoading, gap: 3 }, isLoading ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
            React.createElement(ContentCopyRoundedIcon, { color: "action" }),
            React.createElement(Typography, { variant: "body1" }, t('label', 'Duplicate')))))));
};
