import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import { Tabs } from './Tabs';
export const Header = ({ tab, onTabChange }) => {
    const { t } = useTranslation('tasks', { keyPrefix: 'header' });
    return (React.createElement(Base, { size: "small", display: "flex", alignItems: "center", justifyContent: "space-between" },
        React.createElement(Typography, { variant: "h6", color: "default" }, t('title', 'Your tasks')),
        React.createElement(Tabs, { value: tab, onChange: onTabChange })));
};
