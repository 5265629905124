import * as flatted from 'flatted';
function replaceMap(_key, value) {
    if (value instanceof Map) {
        return {
            dataType: '__internal__Map',
            value: [...value],
        };
    }
    return value;
}
function reviveMap(_key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === '__internal__Map') {
            return new Map(value.value);
        }
    }
    return value;
}
/**
 * Like JSON.stringify but can deal with Map types
 */
function toJSON(value, replacer, space) {
    function r(key, value) {
        let replaced = value;
        if (replacer) {
            // @ts-expect-error `this` is the context in which the key/value was found
            replaced = replacer.call(this, key, value);
        }
        replaced = replaceMap(key, replaced);
        return replaced;
    }
    return flatted.stringify(value, r, space);
}
function fromJSON(value, reviver) {
    function r(key, value) {
        let newvalue = value;
        if (reviver) {
            // @ts-expect-error `this` is the context in which the key/value was found
            newvalue = reviver.call(this, key, value);
        }
        newvalue = reviveMap(key, newvalue);
        return newvalue;
    }
    return flatted.parse(value, r);
}
export { toJSON, fromJSON };
