import React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { protocolGraphDB } from 'core/protocolGraphDB';
import { Base } from 'lib/cortiUI';
import { TriageStandalone } from './TriageStandalone';
import { loadCurrentGraph } from './storage';
export function TriageStandalonePage() {
    const match = useRouteMatch();
    const [graphLoadState, setGraphLoadState] = React.useState({
        type: 'initial',
    });
    React.useEffect(() => {
        void loadGraphAndSettings();
    }, []);
    async function loadGraphAndSettings() {
        const cachedSettings = await protocolGraphDB.getLatestTriageSettings();
        try {
            const g = await loadCurrentGraph();
            if (!g) {
                setGraphLoadState({ type: 'missing-graph' });
                return;
            }
            setGraphLoadState({ type: 'ok', data: { graph: g, settings: cachedSettings } });
        }
        catch (error) {
            console.error(error);
            if (error instanceof Error) {
                setGraphLoadState({ type: 'error', error });
            }
        }
    }
    switch (graphLoadState.type) {
        case 'initial': {
            return null;
        }
        case 'missing-graph': {
            return (React.createElement(Base, { p: 6 },
                React.createElement(Base, { mb: 4 }, 'No graph found in storage'),
                React.createElement(Link, { to: `${match.url}/loadgraph` }, 'Go to preload page')));
        }
        case 'error': {
            return React.createElement(Base, { p: 6 }, graphLoadState.error.message);
        }
        case 'ok': {
            return (React.createElement(Base, { height: '100vh' },
                React.createElement(TriageStandalone, { graph: graphLoadState.data.graph, triageSessionSettings: graphLoadState.data.settings })));
        }
    }
}
