import React from 'react';
import { Base } from '../Base';
/**
 * Component to render a single row in the VirtualizedList options list
 */
export function ListRow({ data, index, style, listboxPadding = 0 }) {
    const dataSet = data[index];
    const inlineStyle = Object.assign(Object.assign({}, style), { top: style.top + listboxPadding });
    return React.createElement(Base, { style: inlineStyle }, dataSet);
}
