var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { stringifyRichText } from '@corti/richtext';
import { queueMacrotask } from '@corti/timers';
import { LinkPortalNodeModel, TimelineEntryAlertNode, ViewNodeModel, } from 'lib/graphEditor/canvas/nodes';
import { ActionElementModel, DatePickerElementModel, DocumentElementModel, FlowValueCollectorElementModel, ImageElementModel, NumberInputElementModel, SelectElementModel, TextAreaElementModel, TextElementModel, TextInputElementModel, } from 'lib/graphEditor/contentBuilder/elements';
import { FactStatic } from 'lib/graphEditor/facts';
import { ContentBuilderLibraryComponent } from '../contentBuilder';
function transformBaseElement(element) {
    const r = {
        id: element.id,
        name: element.name,
        customProperties: element.customProperties.getEncodableData(),
    };
    return r;
}
function transformTextToGraphText(text) {
    return {
        plainText: typeof text === 'string' ? text : stringifyRichText(text),
        richText: typeof text === 'string' ? undefined : text,
    };
}
function transformFactType(input) {
    switch (input) {
        case 'boolean':
            return 'BOOLEAN';
        case 'string':
            return 'TEXT';
    }
}
class Serializer {
    constructor(graphModel, options) {
        Object.defineProperty(this, "graphModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: graphModel
        });
        Object.defineProperty(this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: options
        });
        Object.defineProperty(this, "result", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                version: {
                    id: '',
                },
                data: {
                    branches: [],
                    startBranchNode: undefined,
                    prototypes: {
                        views: [],
                        blocks: [],
                        blockOptions: [],
                    },
                    facts: [],
                },
                metadata: {
                    editorApp: {
                        connectorGates: [],
                        extraNodes: [],
                        nodes: [],
                        library: {
                            components: [],
                        },
                        prototypes: {
                            blocks: [],
                        },
                    },
                },
            }
        });
        Object.defineProperty(this, "transformLogicGate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (l, node) => {
                let targetBranchNode;
                if (l.finalTargetNode) {
                    targetBranchNode = {
                        branchID: l.finalTargetNode.branchContext.id,
                        nodeID: l.finalTargetNode.id,
                    };
                }
                if (l.targetNode instanceof LinkPortalNodeModel) {
                    this.editorMeta.connectorGates.push({
                        id: l.id,
                        linkPortalNodeID: l.targetNode.id,
                    });
                }
                node.gates.push({
                    id: l.id,
                    expression: l.expression,
                    targetBranchNode,
                });
            }
        });
        Object.defineProperty(this, "serializeInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (instance) => {
                this.serializeElement(instance.wrappedElement);
                const serialized = {
                    id: instance.id,
                    blockID: instance.wrappedElement.id,
                    customProperties: instance.customProperties.getEncodableData(),
                };
                if (instance.triggerOnMount != null) {
                    serialized.triggerOnMount = instance.triggerOnMount;
                }
                return serialized;
            }
        });
        Object.defineProperty(this, "serializedElementsCache", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
        Object.defineProperty(this, "serializeElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (element) => {
                var _a, _b, _c;
                if (this.serializedElementsCache.has(element.id)) {
                    return;
                }
                if (element instanceof DatePickerElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'DATE_PICKER', isDateTime: element.isDateTime, placeholder: element.attributes.values.placeholder, label: transformTextToGraphText(element.label), valuePublisherConfig: element.valuePublisher.getEncodableData() }));
                }
                if (element instanceof ActionElementModel) {
                    this.editorMeta.prototypes.blocks.push({
                        id: element.id,
                        backgroundColor: element.attributes.values.backgroundColor,
                    });
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'ACTION', text: transformTextToGraphText(element.content), invisible: element.invisible, actionBlock: {
                            triggeredStateExpression: element.triggeredStateExpression,
                        } }));
                }
                if (element instanceof DocumentElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'DOCUMENT', mediaAsset: element.mediaAssetID
                            ? {
                                id: element.mediaAssetID,
                            }
                            : undefined }));
                }
                if (element instanceof ImageElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'IMAGE', mediaAsset: element.mediaAssetID
                            ? {
                                id: element.mediaAssetID,
                            }
                            : undefined }));
                }
                if (element instanceof TextAreaElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'TEXTAREA_INPUT', placeholder: element.attributes.values.placeholder, label: transformTextToGraphText(element.label), valuePublisherConfig: element.valuePublisher.getEncodableData() }));
                }
                if (element instanceof TextElementModel) {
                    this.editorMeta.prototypes.blocks.push({
                        id: element.id,
                        collapsable: element.attributes.values.collapsable,
                    });
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'PARAGRAPH', text: transformTextToGraphText(element.content) }));
                }
                if (element instanceof TextInputElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'TEXT_INPUT', label: transformTextToGraphText(element.label), valuePublisherConfig: element.valuePublisher.getEncodableData() }), element.attributes.values));
                }
                if (element instanceof NumberInputElementModel) {
                    const _d = element.attributes.values, { min, max, step, precision } = _d, values = __rest(_d, ["min", "max", "step", "precision"]);
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'NUMBER_INPUT', label: transformTextToGraphText(element.label), valuePublisherConfig: element.valuePublisher.getEncodableData(), numberBlock: { min, max, step, precision } }), values));
                }
                if (element instanceof SelectElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'SELECT', max: (_a = element.max) !== null && _a !== void 0 ? _a : null, label: transformTextToGraphText(element.label), isSearchable: (_b = element.isSearchable) !== null && _b !== void 0 ? _b : false, selectBlock: {
                            viewType: element.isDropdown ? 'DROPDOWN' : 'LIST',
                        }, valuePublisherConfig: element.valuePublisher.getEncodableData(), optionInstances: element.options.map((opt) => {
                            this.result.data.prototypes.blockOptions.push({
                                id: opt.id,
                                customProperties: opt.customProperties.getEncodableData(),
                                text: transformTextToGraphText(opt.text),
                                valuePublisherConfig: opt.valuePublisher.getEncodableData(),
                            });
                            return { id: opt.id, optionID: opt.id };
                        }) }));
                }
                if (element instanceof FlowValueCollectorElementModel) {
                    this.result.data.prototypes.blocks.push(Object.assign(Object.assign({}, transformBaseElement(element)), { type: 'FLOW_VALUE_COLLECTOR', label: transformTextToGraphText(element.label), collectFromCriticalPath: (_c = element.collectFromCriticalPath) !== null && _c !== void 0 ? _c : false }));
                }
                this.serializedElementsCache.add(element.id);
            }
        });
        Object.defineProperty(this, "serializeLibrary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.graphModel.library.items.forEach((it) => {
                    if (it instanceof ContentBuilderLibraryComponent) {
                        this.editorMeta.library.components.push({
                            elementID: it.wrappedItem.id,
                            name: it.name,
                        });
                        this.serializeElement(it.wrappedItem);
                    }
                });
            }
        });
        Object.defineProperty(this, "serializeFacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.graphModel.facts.items.forEach((it) => {
                    this.result.data.facts.push(Object.assign(Object.assign({}, FactStatic.serialize(it)), { type: transformFactType(it.type) }));
                });
            }
        });
        Object.defineProperty(this, "serializeChecklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.checklistsEnabled)) {
                    return;
                }
                this.result.data.checklists = this.graphModel.checklists.items.map((list) => (Object.assign(Object.assign({}, list), { entries: list.entries.map((entry) => (Object.assign({}, entry))) })));
            }
        });
        Object.defineProperty(this, "serializeGraphData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.result.version.id = this.graphModel.id;
                if (this.graphModel.startNode) {
                    this.result.data.startBranchNode = {
                        branchID: this.graphModel.startNode.branchContext.id,
                        nodeID: this.graphModel.startNode.id,
                    };
                }
            }
        });
        Object.defineProperty(this, "serializeNodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                for (const b of this.graphModel.branches) {
                    await queueMacrotask();
                    await this.serializeBranch(b);
                }
            }
        });
    }
    transformViewNode(node, branch) {
        this.editorMeta.nodes.push({
            id: node.id,
            position: node.position.serialize(),
            pinnedInTriage: node.pinnedInTriage,
            favoriteNode: node.favoriteNode,
        });
        const view = node.contentBuilderContext;
        const viewLibItem = {
            id: view.id,
            name: node.name,
            blockInstances: [],
        };
        this.result.data.prototypes.views.push(viewLibItem);
        viewLibItem.blockInstances = view.elements.map((child) => {
            return this.serializeInstance(child);
        });
        const n = {
            id: node.id,
            viewInstance: {
                id: node.contentBuilderContext.id,
                viewID: node.contentBuilderContext.id,
            },
            gates: [],
        };
        branch.nodes.push(n);
        node.logicGates.forEach((it) => this.transformLogicGate(it, n));
    }
    /**
     * Transform any other type of node that is not part of the backend supported graph nodes
     */
    transformOtherNode(node, branch) {
        var _a, _b;
        if (node instanceof LinkPortalNodeModel) {
            const result = {
                id: node.id,
                branchID: branch.id,
                type: 'linkPortalNode',
                target: node.targetNode
                    ? {
                        viewNodeID: (_a = node.targetNode) === null || _a === void 0 ? void 0 : _a.id,
                    }
                    : undefined,
                position: node.position.serialize(),
            };
            this.editorMeta.extraNodes.push(result);
            return;
        }
        if (node instanceof TimelineEntryAlertNode) {
            const result = {
                id: node.id,
                branchID: branch.id,
                type: 'timelineEntryAlertNode',
                position: node.position.serialize(),
                timelineEntryDefinitionSource: node.source,
                targetNodeID: (_b = node.outputTargets[0]) === null || _b === void 0 ? void 0 : _b.id,
                customProperties: node.customProperties.getEncodableData(),
            };
            this.editorMeta.extraNodes.push(result);
            return;
        }
    }
    async serializeBranch(branch) {
        var _a;
        const result = {
            id: branch.id,
            name: branch.name,
            nodes: [],
            startNodeID: (_a = branch.startNode) === null || _a === void 0 ? void 0 : _a.id,
            keywords: branch.keywords.slice(),
        };
        branch.nodes.forEach((node) => {
            if (node instanceof ViewNodeModel) {
                this.transformViewNode(node, result);
                return;
            }
            this.transformOtherNode(node, result);
        });
        this.result.data.branches.push(result);
    }
    get editorMeta() {
        return this.result.metadata.editorApp;
    }
    async process() {
        this.serializeGraphData();
        this.serializeLibrary();
        this.serializeFacts();
        this.serializeChecklists();
        await this.serializeNodes();
        return this.result;
    }
}
async function transform({ graphData, options, }) {
    const serializer = new Serializer(graphData, options);
    return await serializer.process();
}
export const CoreBackendSerializer = {
    transform,
};
