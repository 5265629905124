import { Card } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
const MotionCard = motion(Card);
export function Section({ children }) {
    return (React.createElement(MotionCard, { sx: {
            borderRadius: 6,
            background: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(8px)',
            width: '100%',
            display: 'flex',
            padding: 6,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 4,
            alignSelf: 'stretch',
        } }, children));
}
