var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cloneDeep, isEqual, pick } from 'lodash';
import { intl } from '@corti/i18n';
import { uuid } from '@corti/uuid';
export function transformStateToJSON(title, anonymizeReviewers, organizationID, formFields) {
    const fields = formFields.map((f) => {
        return {
            id: f.id,
            title: f.title,
            type: f.type,
            visibleIf: transformVisibleIfToJSON(f),
            choices: transformChoicesToJSON(f),
            acceptedTimelineEntryDefinitionSources: transformTimlineEntryToJSON(f),
            required: f.required || false,
        };
    });
    return {
        template: {
            title,
            anonymizeReviewers,
            organizationID: organizationID,
            fields: fields,
        },
    };
}
function transformVisibleIfToJSON(f) {
    return f.visibleIf
        ? {
            junction: f.visibleIf.junction,
            conditions: f.visibleIf.conditions.map((c) => ({
                fieldID: c.fieldID,
                comparison: c.comparison,
                values: c.values.map((value) => (typeof value === 'string' ? value : value.value)),
            })),
        }
        : undefined;
}
function transformChoicesToJSON(f) {
    if (f.type === 'dropdown' || f.type === 'radio') {
        const choices = f.choices;
        return choices === null || choices === void 0 ? void 0 : choices.map((c) => (Object.assign(Object.assign({}, c), { timelineEntryDefinitionSourceHints: transformTimlineEntryDefinitionsToJSON(c.timelineEntryDefinitionSourceHints) })));
    }
}
function transformTimlineEntryToJSON(f) {
    if (f.type === 'radio') {
        if (f.acceptedTimelineEntryDefinitionSources) {
            return transformTimlineEntryDefinitionsToJSON(f.acceptedTimelineEntryDefinitionSources);
        }
    }
    return [];
}
function transformTimlineEntryDefinitionsToJSON(t) {
    if (!t)
        return [];
    return t.map((it) => ({
        type: it.type,
        entityType: it.entityType,
        entityID: it.entityID,
    }));
}
export function getConditionQuestionTitle(n) {
    return intl.t('libFeedbackFormManager:noTitleQuestion', 'Question {{number}}: No title', {
        number: n + 1,
    });
}
export function getConditionChoiceValueTitle(n) {
    return intl.t('libFeedbackFormManager:noTitleChoiceValue', 'Option {{number}}: No title', {
        number: n + 1,
    });
}
export const getDefaultField = () => ({
    id: uuid(),
    title: '',
    type: 'short-text',
    required: false,
});
export function getTimelineEntryDefinitionsTitles(defSources, timelineEntryDefinitions) {
    return timelineEntryDefinitions.reduce((titles, it) => {
        const { source: timelineEntryDefinitionSource, text } = it;
        const comparedProperties = ['entityType', 'entityID', 'type'];
        const isSelected = defSources.find((source) => isEqual(pick(timelineEntryDefinitionSource, comparedProperties), pick(source, comparedProperties)));
        if (isSelected && text)
            titles.push(text);
        return titles;
    }, []);
}
function getConditionValueFromTemplate({ values }, conditionField) {
    var _a, _b;
    if (conditionField.type === 'radio' || conditionField.type === 'dropdown') {
        const [optionValue] = values;
        const conditionSelectedChoice = (_a = conditionField.choices) === null || _a === void 0 ? void 0 : _a.find((choice) => choice.value === optionValue);
        if (conditionSelectedChoice) {
            const value = Object.assign(Object.assign({}, conditionSelectedChoice), { label: (_b = conditionSelectedChoice === null || conditionSelectedChoice === void 0 ? void 0 : conditionSelectedChoice.text) !== null && _b !== void 0 ? _b : '' });
            return [value];
        }
        return values;
    }
    return values;
}
export function copyTemplateFields(template) {
    const fieldIds = template.fields.map((f) => ({ formerID: f.id, newID: uuid() }));
    const transformedFields = template.fields.map((_a, idx) => {
        var { visibleIf, type } = _a, field = __rest(_a, ["visibleIf", "type"]);
        const isOutdatedType = type === 'action-select' || type === 'protocol-select';
        const updatedField = Object.assign(Object.assign({}, field), { id: fieldIds[idx].newID, type: isOutdatedType ? 'short-text' : type, visibleIf: undefined });
        if (visibleIf) {
            const visibleIfCopy = cloneDeep(visibleIf);
            const conditions = visibleIfCopy.conditions.map((c) => {
                const conditionField = template.fields.find(({ id }) => id === c.fieldID);
                const updatedValue = conditionField
                    ? getConditionValueFromTemplate(c, conditionField)
                    : c.values;
                return Object.assign(Object.assign({}, c), { id: uuid(), fieldID: fieldIds.find((ids) => ids.formerID === c.fieldID).newID, values: updatedValue });
            });
            updatedField.visibleIf = Object.assign(Object.assign({}, visibleIfCopy), { conditions });
        }
        return updatedField;
    });
    return transformedFields;
}
export const CY_SELECTORS = {
    view: 'template-builder-view',
    viewTitle: 'view-title',
    templateTitleField: 'title-field',
    addFieldBtn: 'add-field-btn',
    questionsTitle: 'questions-title',
    clearTemplateBtn: 'clear-template-btn',
    submitTemplateBtn: 'submit-template-btn',
    notification: 'app-notification',
    fieldWidget: {
        fieldBuilder: 'field-builder',
        questionField: 'question-field',
        questionTypeField: 'question-type-field',
        questionTypeTextField: 'question-type-text-field',
        requiredField: 'required-field',
        copyFieldBtn: 'copy-btn',
        removeFieldBtn: 'remove-field-btn',
        selectAcceptedTimelineEntryDefsBtn: 'select-accepted-timeline-entries-btn',
        selectTimelineEntryDefsForHintsBtn: 'select-timeline-entries-for-hints-btn',
        optionField: 'option',
        optionWrapper: 'option-wrapper',
        removeOptionBtn: 'remove-option-btn',
        openVisibleIfWidgetBtn: 'open-visible-if-btn',
        selectedDefinitionChip: 'definition-chip',
        addOptionBtn: 'add-option-btn',
        optionHelperText: 'option-helper-text',
        definitionsSelectorBadge: 'definitions-selector-badge',
        questionTitleHelperText: 'question-title-helper-text',
    },
    preview: {
        previewCard: 'preview-card',
        shortTextFieldPreview: 'short-text-field-preview',
        longTextFieldPreview: 'long-text-field-preview',
        radioFieldPreview: 'radio-field-preview',
        dropdownFieldPreview: 'dropdown-field-preview',
        previewOptionField: 'preview-option-field',
        shortTextPreviewHelperText: 'short-text-preview-helper-text',
        longTextPreviewHelperText: 'long-text-preview-helper-text',
        dropdownPreviewHelperText: 'dropdown-preview-helper-text',
        radioPreviewHelperText: 'radio-preview-helper-text',
    },
    visibleIfWidget: {
        visibleIfWidgetModal: 'visible-if-modal',
        closeVisibleIfModalBtn: 'close-visible-if-modal-btn',
        applyVisibilityConditionsBtn: 'apply-visibility-conditions-btn',
        conditionWrapper: 'condition-wrapper',
        conditionQuestionSelect: 'condition-question-select',
        conditionEqualityWrapper: 'condition-equality-wrapper',
        conditionEqualsBtn: 'condition-equals-btn',
        conditionNotEqualBtn: 'condition-not-equal-btn',
        conditionValueTextInput: 'condition-value-text-input',
        conditionValueSelect: 'condition-value-select',
        visibilityJunctionWrapper: 'visibility-junction-wrapper',
        visibilityAndJunctionBtn: 'visibility-and-junction-btn',
        visibilityOrJunctionBtn: 'visibility-or-junction-btn',
        removeConditionBtn: 'remove-condition-btn',
        addConditionBtn: 'add-condition-btn',
        conditionQuestionTextField: 'condition-question-text-field',
    },
    selectTimelineEntryDefsModal: 'select-timeline-entry-defs-modal',
    applyTimelineEntryDefsModalBtn: 'apply-timeline-entry-defs-modal',
    copyTemplateBtn: 'copy-template-btn',
    openMenuBtn: 'open-menu-button',
};
