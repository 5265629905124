import React from 'react';
import { Base, Card, CardContent, Skeleton } from 'lib/cortiUI';
export const GeneralPerformancePanelLoader = () => {
    return (React.createElement(Card, { height: "100%" },
        React.createElement(CardContent, { sx: {
                height: '100%',
            } },
            React.createElement(Base, { display: "flex", justifyContent: "space-between", mb: 10, alignItems: "center", flex: 1 },
                React.createElement(Skeleton, { variant: "text", width: "60%" }),
                React.createElement(Skeleton, { variant: "circular", height: 25, width: 25 })),
            React.createElement(Base, { display: "flex", flexDirection: "column", justifyContent: "space-between" },
                React.createElement(Base, { display: "flex", alignItems: "center", mb: 8 },
                    React.createElement(Skeleton, { variant: "text", height: 25, width: "40%", sx: { mr: 4 } }),
                    React.createElement(Skeleton, { variant: "rectangular", height: 25, width: 50, sx: { borderRadius: 4 } })),
                React.createElement(Skeleton, { variant: "rectangular", height: 20, width: "100%", sx: { borderRadius: '4px' } }),
                React.createElement(Base, { display: "flex", alignItems: "center", gap: 3, "justify-content": "space-between" },
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 1 } }),
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 2 } }),
                    React.createElement(Skeleton, { variant: "text", sx: { flex: 3 } }))))));
};
