import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router';
import { useRealTimeCtx } from '../RealTimeView/RealTimeContext';
export const TriageSessionLoader = observer(function TriageSessionLoader() {
    const { sessionID } = useParams();
    const realTimeController = useRealTimeCtx();
    React.useEffect(() => {
        void realTimeController.loadTriageSession(sessionID);
        return () => realTimeController.destroyTriageSession();
    }, [realTimeController, sessionID]);
    return null;
});
