export const flowStateChangeEventTransformer = {
    toRepo(event) {
        switch (event.event) {
            case 'action-block-triggered': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-action-block-triggered' });
            }
            case 'active-step-changed': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-active-step-changed' });
            }
            case 'fact-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-fact-values-updated' });
            }
            case 'block-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-block-values-updated' });
            }
            case 'step-added': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-step-added' });
            }
            case 'value-collector-block-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'triage-flow-value-collector-block-values-updated' });
            }
            case 'checklist-updated': {
                return {
                    event: 'checklist-updated',
                    data: {
                        datetime: event.data.datetime,
                        checklistID: event.data.checklistId,
                        expanded: event.data.expanded,
                    },
                };
            }
            case 'checklist-entry-updated': {
                return {
                    event: 'checklist-entry-updated',
                    data: {
                        datetime: event.data.datetime,
                        checklistID: event.data.checklistId,
                        checklistEntryID: event.data.entryId,
                        completed: event.data.checked,
                        checklistEntryCompletionSource: event.data.source,
                    },
                };
            }
            default: {
                return;
            }
        }
    },
    fromExternal(event) {
        switch (event.event) {
            case 'triage-flow-active-step-changed': {
                return Object.assign(Object.assign({}, event), { event: 'active-step-changed' });
            }
            case 'triage-flow-fact-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'fact-values-updated' });
            }
            case 'triage-flow-block-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'block-values-updated' });
            }
            case 'triage-flow-step-added': {
                return Object.assign(Object.assign({}, event), { event: 'step-added' });
            }
            case 'triage-flow-value-collector-block-values-updated': {
                return Object.assign(Object.assign({}, event), { event: 'value-collector-block-values-updated' });
            }
            case 'triage-flow-action-block-triggered': {
                return Object.assign(Object.assign({}, event), { event: 'action-block-triggered' });
            }
            case 'triage-flow-alert-node-opened': {
                return;
            }
            case 'checklist-updated': {
                return {
                    event: 'checklist-updated',
                    data: {
                        datetime: event.data.datetime,
                        checklistId: event.data.checklistID,
                        expanded: event.data.expanded,
                    },
                };
            }
            case 'checklist-entry-updated': {
                return {
                    event: 'checklist-entry-updated',
                    data: {
                        datetime: event.data.datetime,
                        checklistId: event.data.checklistID,
                        entryId: event.data.checklistEntryID,
                        checked: event.data.completed,
                        source: event.data.checklistEntryCompletionSource,
                    },
                };
            }
            default: {
                return;
            }
        }
    },
};
