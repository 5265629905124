import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { Base, Button, Typography } from 'lib/cortiUI';
import { NewViewItem } from './NewViewItem';
export const SidebarHeader = ({ onNewViewClick, isDraftView, disabled = false, }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'sidebar' });
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { "data-cy": "performance-sidebar-header", display: "flex", alignItems: "center", width: "100%" },
            React.createElement(Base, { display: "flex", gap: 4, flex: 1 },
                React.createElement(Typography, { variant: "subtitle1", color: "default" }, t('views', 'Views'))),
            rbacService.performanceViewsCRUD() && (React.createElement(Button, { "data-cy": "performance-add-new-view-btn", startIcon: React.createElement(AddRoundedIcon, { fontSize: "small", color: "action" }), onClick: onNewViewClick, disabled: disabled, variant: "outlined", size: "small" }, t('newViewBtn', 'New')))),
        isDraftView && React.createElement(NewViewItem, null)));
};
