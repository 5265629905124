import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Button } from 'lib/cortiUI';
export const SelectFilesTrigger = React.forwardRef(({ onResetInputValue, onInputValueChange, onUploadTriggerClick, disableUploadTrigger }, ref) => {
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'dragAndDrop' });
    return (React.createElement(Button, { color: "primary", onClick: onUploadTriggerClick, disabled: disableUploadTrigger, "data-cy": "browse-files-btn" },
        t('browseFilesBtn', 'Browse files'),
        React.createElement("input", { ref: ref, type: "file", accept: ".json", onChange: onInputValueChange, onClick: onResetInputValue, className: css({
                position: 'absolute',
                width: '100%',
                visibility: 'hidden',
            }) })));
});
