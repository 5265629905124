export function getPercentage(value) {
    return value * 100;
}
export function calculatePerformanceScore(performanceMetricsBreakdown) {
    if (!performanceMetricsBreakdown.length)
        return 0;
    const score = performanceMetricsBreakdown.reduce((average, item) => average + item.percentage, 0) /
        performanceMetricsBreakdown.length;
    return Math.round(getPercentage(score));
}
