import Mousetrap from 'mousetrap';
class WrappedMousetrap {
    constructor(m) {
        Object.defineProperty(this, "m", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: m
        });
        Object.defineProperty(this, "boundKbMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
    }
    bind(keys, callback) {
        this.boundKbMap.add(keys);
        this.m.bind(keys, callback);
    }
    reset() {
        this.m.reset();
    }
    getCurrentBindings() {
        return [...this.boundKbMap.keys()].map((keys) => ({ keys: keys }));
    }
}
export class KeyboardBinder {
    constructor() {
        Object.defineProperty(this, "instances", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
    }
    /**
     * Creates an instance of a keyboard binder scoped to an html element
     */
    createInstance(element) {
        const mousetrap = new Mousetrap(element);
        const wrapped = new WrappedMousetrap(mousetrap);
        let stopCb = mousetrap.stopCallback;
        mousetrap.stopCallback = (e, element, combo) => {
            const selection = window.getSelection();
            // don't execute our implement of "copy" when there's text selected
            if (selection && !selection.isCollapsed) {
                return true;
            }
            return stopCb.call(mousetrap, e, element, combo);
        };
        this.instances.add(wrapped);
        return wrapped;
    }
    getCurrentBindings() {
        return [...this.instances].flatMap((it) => it.getCurrentBindings());
    }
    remove(instance) {
        instance.reset();
        this.instances.delete(instance);
    }
}
