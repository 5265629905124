import { ConsoleTransport } from '@corti/logger-console-transport';
const LEVELS = {
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
};
export class Logger {
    constructor(parent) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_transports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "_parent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_level", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'inherit'
        });
        this._parent = parent;
        if (this.transports.length === 0) {
            this._transports = [new ConsoleTransport()];
        }
    }
    setName(name) {
        this.name = name;
    }
    setLevel(level) {
        this._level = level;
    }
    get level() {
        var _a, _b, _c;
        return (_c = (_a = this._level) !== null && _a !== void 0 ? _a : (_b = this._parent) === null || _b === void 0 ? void 0 : _b.level) !== null && _c !== void 0 ? _c : 'DEBUG';
    }
    get isLoggingEnabled() {
        var _a, _b;
        if (this.enabled === 'inherit') {
            return (_b = (_a = this._parent) === null || _a === void 0 ? void 0 : _a.isLoggingEnabled) !== null && _b !== void 0 ? _b : true;
        }
        return this.enabled;
    }
    setEnabled(val) {
        this.enabled = val;
    }
    addTransport(transport) {
        this._transports.push(transport);
    }
    _sendToTransportAndAddMeta(severity, ...messages) {
        this.sendToTransports({
            severity: severity,
            loggerName: this.name,
            date: new Date().toISOString(),
            data: messages.join(' '),
        });
    }
    sendToTransports(msg) {
        if (!this.isLoggingEnabled) {
            return;
        }
        if (LEVELS[this.level] > LEVELS[msg.severity]) {
            return;
        }
        this.transports.forEach((t) => {
            t.send(msg);
        });
    }
    debug(...args) {
        this._sendToTransportAndAddMeta('DEBUG', ...args);
    }
    info(...args) {
        this._sendToTransportAndAddMeta('INFO', ...args);
    }
    warn(...args) {
        this._sendToTransportAndAddMeta('WARN', ...args);
    }
    error(...args) {
        this._sendToTransportAndAddMeta('ERROR', ...args);
    }
    /**
     * Creates a new logger instance with prefix name
     */
    getLogger(name) {
        const l = new Logger(this);
        l.setName(name);
        return l;
    }
    get transports() {
        if (this._parent) {
            return [...this._parent.transports, ...this._transports];
        }
        return [...this._transports];
    }
}
