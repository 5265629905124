import { orderBy } from 'lodash';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { rbacService } from 'browser/services/init';
import { DataGrid } from 'lib/cortiUI';
import { getTeamsColumns, getTeamsRows } from './utils';
export function TeamsDataGrid({ teams, loading }) {
    const history = useHistory();
    const match = useRouteMatch();
    const columns = getTeamsColumns();
    const rows = getTeamsRows(orderBy(teams, 'name', 'asc'));
    return (React.createElement(DataGrid, { rows: rows, columns: columns, onRowClick: function handleTeamRowClick({ id }) {
            history.push(`${match.path}/${id}`);
        }, columnVisibilityModel: {
            actions: rbacService.teamsCRUD(),
        }, disableColumnMenu: true, loading: loading, sx: {
            border: 'hidden',
            '& .MuiDataGrid-row': {
                cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
            },
        } }));
}
