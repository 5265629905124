import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Box } from 'lib/cortiUI/components/Box/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { Popover } from 'lib/cortiUI/components/Popover/Popover';
import { TextField } from '../TextField';
/**
 * Simple Material UI popover with overridden styles for only confirm popover. They accept all Material UI props
 */
export function ConfirmPopover(props) {
    const { onConfirm, onClose, anchorEl, children, color = 'primary' } = props;
    const [confirmPhrase, setConfirmPhrase] = React.useState('');
    return (React.createElement(Popover, { id: "simple-popper", open: Boolean(anchorEl), anchorEl: anchorEl, onClose: onClose, PaperProps: {
            // @ts-ignore
            'data-cy': 'confirm-popover',
        }, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        } },
        React.createElement(Card, { p: 4, style: { maxWidth: 260 }, elevation: 8, onClick: (e) => e.stopPropagation() },
            children,
            props.requiredConfirmPhrase && (React.createElement(TextField, { fullWidth: true, value: confirmPhrase, onChange: (e) => setConfirmPhrase(e.target.value) })),
            React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: 1, mt: 4 },
                React.createElement(Button, { "data-cy": "confirm-popover-cancel-btn", className: css({ marginRight: 2 }), onClick: onClose, size: "small" }, intl.t('cancel', 'Cancel')),
                React.createElement(Button, { "data-cy": "confirm-popover-accept-btn", color: color, disabled: Boolean(props.requiredConfirmPhrase && props.requiredConfirmPhrase !== confirmPhrase), onClick: (e) => {
                        if (props.requiredConfirmPhrase) {
                            if (props.requiredConfirmPhrase === confirmPhrase) {
                                onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(e);
                            }
                        }
                        else {
                            onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(e);
                        }
                    }, size: "small" }, intl.t('confirm', 'Confirm'))))));
}
