var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import { css, getFocusCss, mix, transparentize } from '@corti/style';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI/components/baseHoc';
export const getCommonButtonStyles = (props) => {
    const { variant, color, disabled, theme } = props;
    switch (variant) {
        case 'contained': {
            if (disabled) {
                return {
                    borderColor: 'transparent',
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled,
                };
            }
            return Object.assign({ borderColor: 'transparent', backgroundColor: color ? theme.palette[color].main : theme.palette.action.active, color: theme.palette.text.inverted, '&:hover': {
                    backgroundColor: color
                        ? theme.palette[color].containedHoverBackground
                        : mix(0.3, 'black', theme.palette.action.active),
                } }, getFocusCss({ theme, asStyleObject: true }));
        }
        case 'outlined': {
            if (disabled) {
                return Object.assign({ borderColor: theme.palette.action.disabledBackground, color: theme.palette.action.disabled }, getFocusCss({ theme, asStyleObject: true }));
            }
            return Object.assign({ borderColor: color
                    ? theme.palette[color].outlinedRestingBorder
                    : transparentize(0.5, theme.palette.text.primary), color: color ? theme.palette[color].main : theme.palette.text.primary, '&:hover': {
                    backgroundColor: color
                        ? theme.palette[color].outlinedHoverBackground
                        : transparentize(0.8, theme.palette.text.primary),
                    borderColor: color
                        ? theme.palette[color].outlinedRestingBorder
                        : transparentize(0.5, theme.palette.text.primary),
                } }, getFocusCss({ theme, asStyleObject: true }));
        }
        case 'text': {
            if (disabled) {
                return Object.assign({ borderColor: 'transparent', color: theme.palette.action.disabled }, getFocusCss({ theme, asStyleObject: true }));
            }
            return Object.assign({ borderColor: 'transparent', color: color ? theme.palette[color].main : theme.palette.text.primary, '&:hover': {
                    backgroundColor: color
                        ? theme.palette[color].outlinedHoverBackground
                        : transparentize(0.8, theme.palette.text.primary),
                } }, getFocusCss({ theme, asStyleObject: true }));
        }
    }
};
export const ButtonBase = React.forwardRef(function ButtonBase(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    const baseCss = useBaseCss(rest);
    const filtered = filterBaseProps(rest);
    return (React.createElement(MUIButtonBase, Object.assign({ ref: ref, disableRipple: true, disableTouchRipple: true, className: css(baseCss, className) }, filtered)));
});
