var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import InputBase from '@mui/material/InputBase';
import React, { forwardRef } from 'react';
import { useMergeRefs } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
export const TextInput = forwardRef(function TextInput(props, forwardedRef) {
    const theme = useTheme();
    const { className, inputRef, value, classes } = props, rest = __rest(props, ["className", "inputRef", "value", "classes"]);
    const ref = React.useRef(null);
    const updateNode = (node) => {
        if (node) {
            ref.current = node;
            node.addEventListener('keypress', stopEventPropagation);
            node.addEventListener('keydown', stopEventPropagation);
            node.addEventListener('keyup', stopEventPropagation);
        }
        else if (ref.current) {
            ref.current.removeEventListener('keypress', stopEventPropagation);
            ref.current.removeEventListener('keydown', stopEventPropagation);
            ref.current.removeEventListener('keyup', stopEventPropagation);
        }
    };
    const refs = useMergeRefs(forwardedRef, updateNode);
    function stopEventPropagation(e) {
        e.stopPropagation();
    }
    return (React.createElement(InputBase, Object.assign({ inputRef: refs, fullWidth: true, className: css({
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            fontSize: theme.typography.fontSize,
        }, className), value: value, classes: Object.assign({ input: css({ display: 'flex', flexDirection: 'column', margin: 0, padding: 0 }, getScrollerCss({ theme })), multiline: css({ margin: 0, padding: 0 }) }, classes) }, rest)));
});
