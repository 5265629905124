import React from 'react';
import { GraphTraverser } from '@corti/lib/graphs';
import { logger } from '@corti/logger';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { CoreBackendSerializer } from 'lib/graphEditor/coreBackendSerializer';
import { TriageSessionController, TriageSessionView } from 'lib/triageSession';
export function GraphPreviewView() {
    const { editor } = useGraphEditorCtx();
    const theme = useTheme();
    const [state, setState] = React.useState({ type: 'initial' });
    React.useEffect(() => {
        void prepareAndLoadModel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function prepareAndLoadModel() {
        var _a, _b, _c, _d;
        setState({ type: 'loading' });
        const rawGraph = await CoreBackendSerializer.transform({
            graphData: editor.model,
            options: { checklistsEnabled: editor.checklistSettings.enabled },
        });
        const traverser = GraphTraverser.create(rawGraph);
        const startingNodeID = (_b = (_a = editor.state.activeBranch.selectedNodes[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = editor.model.startNode) === null || _c === void 0 ? void 0 : _c.id;
        if (!startingNodeID) {
            setState({
                type: 'error',
                error: {
                    message: 'No starting node specified in the graph. Either select a node to preview or set a starting node',
                },
            });
            return;
        }
        const sessionController = new TriageSessionController({
            graph: traverser,
            state: {
                session: {
                    id: '',
                    ownerUser: {
                        id: '',
                        name: '',
                    },
                    createdBy: {
                        id: '',
                        name: '',
                    },
                    graphVersionID: '',
                    caseID: '',
                    externalID: '',
                    createdAt: '',
                    startedAt: '',
                },
                currentUser: {
                    id: '',
                    name: '',
                },
            },
            media: editor.media.provider,
            settings: (_d = editor.graphPreviewSettings) === null || _d === void 0 ? void 0 : _d.triageSessionSettings,
            logger: logger.getLogger('graph preview'),
        });
        sessionController.flow.initFlow(startingNodeID);
        setState({ type: 'ok', controller: sessionController });
    }
    switch (state.type) {
        case 'initial': {
            return null;
        }
        case 'loading': {
            return React.createElement("div", null, 'loading...');
        }
        case 'error': {
            return React.createElement("div", null, state.error.message);
        }
        case 'ok': {
            return (React.createElement(Base, { background: theme.palette.background.default },
                React.createElement(TriageSessionView, { controller: state.controller })));
        }
    }
}
