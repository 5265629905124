import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { getInteraction } from '@corti/mission-control-api';
import { getCallsQuery } from '../graphql';
const initialState = {
    interaction: undefined,
    isLoading: true,
    refetchInteraction: () => { },
    coreCall: undefined,
};
const InteractionContext = React.createContext(initialState);
export const InteractionProvider = ({ children, id }) => {
    const [interaction, setInteraction] = React.useState();
    const [isInteractionLoading, setIsInteractionLoading] = React.useState(true);
    React.useEffect(() => {
        void fetchInteraction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    async function fetchInteraction() {
        try {
            const res = await getInteraction(id);
            setInteraction(res);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsInteractionLoading(false);
        }
    }
    const [getCoreCallQuery, getCoreCallResult] = useLazyQuery(getCallsQuery);
    React.useEffect(() => {
        if (interaction === null || interaction === void 0 ? void 0 : interaction.call_id)
            getCoreCallQuery({ variables: { id: [interaction.call_id] } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction]);
    const value = React.useMemo(() => {
        var _a;
        return ({
            interaction,
            isLoading: isInteractionLoading,
            refetchInteraction: fetchInteraction,
            coreCall: (_a = getCoreCallResult.data) === null || _a === void 0 ? void 0 : _a.calls[0],
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interaction, isInteractionLoading, getCoreCallResult.data]);
    return React.createElement(InteractionContext.Provider, { value: value }, children);
};
export const useInteraction = () => {
    const context = React.useContext(InteractionContext);
    if (!context) {
        throw new Error('useInteraction must be used within a InteractionProvider');
    }
    return context;
};
