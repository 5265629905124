import { memo, useEffect } from 'react';
import { keybindingsRegistry } from 'browser/services/init';
export const Keybinding = memo(function Keybinding(props) {
    useEffect(() => {
        const command = props;
        const registeredKeybinding = keybindingsRegistry.register(command);
        return () => {
            registeredKeybinding.unregister();
        };
    }, [props]);
    return null;
});
