import { intl } from '@corti/i18n';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { FactValueTransformer } from 'lib/triageFlow';
import { TriageSessionNotifications } from '../TriageSessionNofitications';
import { TriageSessionPublicApi } from '../publicapi';
import { analyticEventTransformer } from '../transformers/analyticEvent';
import { commentTransformer } from '../transformers/comment';
import { flowIntegrationEventTransformer } from '../transformers/flowIntegrationEvent';
import { flowStateChangeEventTransformer } from '../transformers/flowStateChangeEvent';
import { sessionTransformer } from '../transformers/session';
import { AlertsAdapter } from './AlertsAdapter';
import { CommentsAdapter } from './CommentsAdapter';
import { TakeoverRequestsAdapter } from './TakeoverRequestsAdapter';
import { resolveUser } from './utils';
export class TriageSessionAdapter {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "unsubscribers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionPublicApi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionNotifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertsAdapter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commentsAdapter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "takeoverRequestsAdapter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.unsubscribers = [];
        this.sessionNotifications = new TriageSessionNotifications(coreStore.notifications);
        this.sessionPublicApi = new TriageSessionPublicApi(this.input.sessionController, input.apiContributor);
        this.sessionPublicApi.init();
        const sessionID = this.input.sessionController.id;
        this.alertsAdapter = new AlertsAdapter({
            sessionController: this.input.sessionController,
            sessionRepository: this.input.sessionRepository,
            logger: this.input.logger,
        });
        this.commentsAdapter = new CommentsAdapter({
            sessionController: this.input.sessionController,
            sessionRepository: this.input.sessionRepository,
            apiContributor: this.input.apiContributor,
            logger: this.input.logger,
        });
        this.takeoverRequestsAdapter = new TakeoverRequestsAdapter({
            sessionController: this.input.sessionController,
            sessionRepository: this.input.sessionRepository,
            sessionNotifications: this.sessionNotifications,
            logger: this.input.logger,
        });
        this.unsubscribers.push(this.input.sessionController.connectedUsers.onUserConnect((user) => {
            if (this.input.sessionController.isOwnerUser(user.id) &&
                !this.input.sessionController.isCurrentUser(user.id)) {
                this.sessionNotifications.showOwnerConnectedNotification();
                this.sessionNotifications.clearTakeoverSuggestionNotification();
            }
        }));
        this.unsubscribers.push(this.input.sessionController.connectedUsers.onUserDisconnect((user) => {
            if (this.input.sessionController.isOwnerUser(user.id) &&
                !this.input.sessionController.isCurrentUser(user.id)) {
                this.sessionNotifications.showOwnerDisconnectedNotification();
            }
        }));
        this.unsubscribers.push(this.input.sessionController.onOwnerUserChange((user) => {
            this.sessionNotifications.clearAllNotifications();
            this.input.sessionController.isCurrentUser(user.id)
                ? this.sessionNotifications.showOwnershipGrantedNotification()
                : this.sessionNotifications.showOwnerChangedNotification(user);
        }));
        this.unsubscribers.push(this.input.sessionController.flow.onStateEvent(async (event) => {
            var _a;
            const transformed = flowStateChangeEventTransformer.toRepo(event);
            if (!transformed) {
                (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${sessionID}: could not transform event ${event.event}`);
                this.input.sessionController.setStatus({
                    type: 'error',
                    message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                });
                return;
            }
            await this.input.sessionRepository.addFlowStateChangeEvent(sessionID, transformed);
        }));
        this.unsubscribers.push(this.input.sessionController.flow.onIntegrationEvent(async (e) => {
            switch (e.event) {
                case 'action-block-triggered': {
                    const event = analyticEventTransformer.toActionBlockTriggered(e, this.input.sessionController);
                    await this.input.sessionRepository.addFlowAnalyticsEvent(event);
                    break;
                }
                case 'active-step-changed': {
                    const event = analyticEventTransformer.toActiveStepChanged(e, this.input.sessionController);
                    await this.input.sessionRepository.addFlowAnalyticsEvent(event);
                    break;
                }
                case 'fact-values-updated': {
                    const event = analyticEventTransformer.toFactValuesUpdated(e, this.input.sessionController);
                    if (!event) {
                        return;
                    }
                    await this.input.sessionRepository.addFlowAnalyticsEvent(event);
                    break;
                }
                case 'block-values-updated': {
                    const event = analyticEventTransformer.toBlockValuesUpdated(e, this.input.sessionController);
                    if (!event) {
                        return;
                    }
                    await this.input.sessionRepository.addFlowAnalyticsEvent(event);
                    break;
                }
            }
        }));
        this.unsubscribers.push(this.input.sessionController.flow.onIntegrationEvent((event) => {
            if (event.event === 'action-block-triggered') {
                trackerService.track('[Triage] action block triggered', {
                    blockPrototypeID: event.data.blockPrototype.id,
                    blockPrototypeName: event.data.blockPrototype.name,
                    blockPrototypeContent: event.data.blockPrototype.text,
                    sessionID: this.input.sessionController.id,
                    caseID: this.input.sessionController.caseID,
                    externalID: this.input.sessionController.externalID,
                });
            }
        }));
        this.unsubscribers.push(this.input.sessionController.flow.onCustomEvent(async (event) => {
            var _a;
            if (event.event === 'grouped-flow-value-collector-blocks-updated') {
                if (!this.input.sessionController.isCurrentUserOwner) {
                    return;
                }
                const transformed = flowIntegrationEventTransformer.toRepo(event);
                if (!transformed) {
                    (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${sessionID}: could not transform event`);
                    this.input.sessionController.setStatus({
                        type: 'error',
                        message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                    });
                    return;
                }
                await this.input.sessionRepository.addFlowIntegrationEvent(sessionID, transformed);
            }
        }));
        this.unsubscribers.push(this.input.sessionRepository.onFlowStateChangedEventAdded(sessionID, (event) => {
            var _a;
            const transformed = flowStateChangeEventTransformer.fromExternal(event);
            if (!transformed) {
                (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${sessionID}: could not transform event ${event.event}`);
                this.input.sessionController.setStatus({
                    type: 'error',
                    message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                });
                return;
            }
            this.input.sessionController.flow.applyStateEvent(transformed);
        }));
        this.unsubscribers.push(this.input.sessionRepository.onFlowCommand(sessionID, (event) => {
            switch (event.event) {
                case 'triage-flow-fact-values-update': {
                    this.input.sessionController.flow.factValueStore.put({
                        factValues: event.data.factValues.map((it) => (Object.assign(Object.assign({}, it), { value: FactValueTransformer.toInternal(it.value) }))),
                        source: 'integration',
                    });
                    return;
                }
                default: {
                    return;
                }
            }
        }));
        this.unsubscribers.push(this.input.sessionRepository.onSessionOwnerChanged(sessionID, (data) => {
            this.input.sessionController.changeOwnerUser(data.ownerUserID);
        }));
        this.unsubscribers.push(this.input.sessionRepository.onUserPresence(sessionID, (event) => {
            this.input.sessionController.connectedUsers.reset({
                connectedUsers: event.data.userIDs.map(resolveUser),
            });
        }));
        this.unsubscribers.push(this.input.sessionRepository.onUserConnected(sessionID, (event) => {
            this.input.sessionController.connectedUsers.connect(resolveUser(event.data.userID));
        }));
        this.unsubscribers.push(this.input.sessionRepository.onUserDisconnected(sessionID, (event) => {
            this.input.sessionController.connectedUsers.disconnect(resolveUser(event.data.userID));
        }));
        this.unsubscribers.push(this.input.sessionRepository.onSessionSynced(sessionID, (sessionExternal) => {
            var _a;
            // We want to update state of the session in memory with the latest data
            // received from the backend after successful synchronization.
            const session = sessionTransformer.fromRepo(sessionExternal);
            if (session === undefined) {
                (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${sessionExternal.id}: failed to transform session`);
                this.input.sessionController.setStatus({
                    type: 'error',
                    message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                });
                return;
            }
            this.input.sessionController.reset({
                session: session,
            });
        }));
        this.unsubscribers.push(this.input.sessionRepository.onSessionLiveCallStatusChanged(sessionID, (data) => {
            var _a;
            if (data.hasLiveCall === false &&
                this.input.sessionController.settings.isAutoSessionLeaveWithoutLiveCallEnabled) {
                (_a = input.onTriageSessionCallEnded) === null || _a === void 0 ? void 0 : _a.call(input);
            }
        }));
        this.unsubscribers.push(input.connectionChecker.onStateChange((state) => {
            this.input.sessionController.updateSettings({
                isTakeoverEnabled: state.current === 'online',
            });
        }));
        this.unsubscribers.push(this.input.sessionRepository.onRefreshRequired(() => {
            void this.refreshState();
        }));
        void this.prepareInitialState();
    }
    destroy() {
        this.sessionNotifications.destroy();
        this.sessionPublicApi.destroy();
        this.unsubscribers.forEach((it) => it());
        this.input.sessionController.destroy();
        this.alertsAdapter.destroy();
        this.commentsAdapter.destroy();
        this.takeoverRequestsAdapter.destroy();
    }
    async prepareInitialState() {
        var _a;
        await this.refreshState();
        // If, following a state refresh, the session does not have any active step,
        // it is necessary to manually initiate a new flow.
        if (!((_a = this.input.sessionController.flow.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.id)) {
            this.input.sessionController.flow.initFlow();
        }
    }
    async refreshState() {
        var _a, _b, _c;
        try {
            this.input.sessionController.setStatus({
                type: 'loading',
                message: intl.t('realtime:loading', 'Loading'),
            });
            const [sessionExternal, commentsExternal, flowStateChangeEventsExternal] = await Promise.all([
                this.input.sessionRepository.getSession(this.input.sessionController.id),
                this.input.sessionRepository.getAllComments(this.input.sessionController.id),
                this.input.sessionRepository.getAllFlowStateChangeEvents(this.input.sessionController.id),
            ]);
            if (sessionExternal === undefined) {
                (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${this.input.sessionController.id}: not found`);
                this.input.sessionController.setStatus({
                    type: 'error',
                    message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                });
                return;
            }
            const session = sessionTransformer.fromRepo(sessionExternal);
            if (session === undefined) {
                (_b = this.input.logger) === null || _b === void 0 ? void 0 : _b.error(`Session ${sessionExternal.id}: failed to transform session`);
                this.input.sessionController.setStatus({
                    type: 'error',
                    message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                });
                return;
            }
            const comments = commentsExternal.map(commentTransformer.fromRepo);
            const flowStateChangeEvents = flowStateChangeEventsExternal
                .map((event) => {
                var _a;
                const transformed = flowStateChangeEventTransformer.fromExternal(event);
                if (!transformed) {
                    (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Session ${this.input.sessionController.id}: could not transform event ${event.event}`);
                    this.input.sessionController.setStatus({
                        type: 'error',
                        message: intl.t('realtime:loadSessionError', 'Session failed to load'),
                    });
                    return;
                }
                return transformed;
            })
                .filter((it) => it !== undefined);
            this.input.sessionController.reset({
                comments: comments,
                totalCommentsCount: comments.length,
                session: session,
            });
            this.input.sessionController.flow.applyStateEvents(flowStateChangeEvents);
            this.input.sessionController.setStatus({
                type: 'ok',
            });
        }
        catch (err) {
            (_c = this.input.logger) === null || _c === void 0 ? void 0 : _c.error(`Session ${this.input.sessionController.id} error: ${err}`);
            this.input.sessionController.setStatus({
                type: 'error',
                message: intl.t('realtime:loadSessionError', 'Session failed to load'),
            });
        }
    }
}
