import React from 'react';
import { WindowPopup } from '@corti/corti-ui';
import { simpleIpc } from '@corti/ipc-renderer';
import { Alert } from './Alert';
export function RealTimeAlertWidget(props) {
    const { realTimeController, onAwaitingActionClick, onAnalysingActionClick, onAlertActionClick } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    function closeWindow() {
        setIsOpen(false);
    }
    function unhideMainWindow() {
        void simpleIpc.callMain('app:unhideMainWindow');
    }
    return (React.createElement(WindowPopup, { isOpen: isOpen, onClose: closeWindow, size: { width: 320, height: 78 }, windowFeatures: {
            title: 'Corti Suggestions',
            // Its important to keep this setting set to false.
            // Visibility of the widget is controlled in the main process
            // to ensure that its position is restored before rendering.
            show: false,
            frame: false,
            resizable: false,
            scrollbars: false,
            minimizable: false,
            maximizable: false,
            fullscreenable: false,
            alwaysOnTop: true,
            transparent: true,
            backgroundColor: 'transparent',
        } },
        React.createElement(Alert, { realTimeController: realTimeController, onAwaitingActionClick: () => {
                onAwaitingActionClick === null || onAwaitingActionClick === void 0 ? void 0 : onAwaitingActionClick();
                unhideMainWindow();
            }, onAnalysingActionClick: (session) => {
                onAnalysingActionClick === null || onAnalysingActionClick === void 0 ? void 0 : onAnalysingActionClick(session);
                unhideMainWindow();
            }, onAlertActionClick: (session, alert) => {
                onAlertActionClick === null || onAlertActionClick === void 0 ? void 0 : onAlertActionClick(session, alert);
                unhideMainWindow();
            } })));
}
