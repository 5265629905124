import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import React from 'react';
export const GradientStarIcon = (iconProps) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { width: 0, height: 0 },
            React.createElement("linearGradient", { id: "linearGradientColor", x1: "2.41504", y1: "1.70752", x2: "26.3478", y2: "9.01973", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { offset: "0.24", stopColor: "#FF9FA3" }),
                React.createElement("stop", { offset: "0.812513", stopColor: "#C2A5FF" }))),
        React.createElement(AutoAwesomeRoundedIcon, Object.assign({ sx: { fill: 'url(#linearGradientColor)' } }, iconProps))));
};
