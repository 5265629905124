export function parseQuery(input) {
    const filterDict = new Map();
    const regex = /(\w+):(\w+)/gi;
    const matches = [...input.matchAll(regex)];
    matches.forEach((m) => {
        const keyword = m[1];
        const value = m[2];
        if (filterDict.has(keyword)) {
            filterDict.get(keyword).push(value);
        }
        else {
            filterDict.set(keyword, [value]);
        }
    });
    return {
        textQuery: input.replace(regex, '').trim(),
        getValuesForKeyword(keyword) {
            var _a;
            return (_a = filterDict.get(keyword)) !== null && _a !== void 0 ? _a : [];
        },
    };
}
