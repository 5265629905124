import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { formatDate } from '@corti/date';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { IconButton } from 'lib/cortiUI/components/IconButton';
import { Typography } from 'lib/cortiUI/components/Typography';
import { RichTextEditor } from './RichTextEditor';
import { RichTextEditorToolbar } from './RichTextEditorToolbar';
import { RichTextRenderer } from './RichTextRenderer';
export function NoteCard({ isEditable, isEditing, date, userName, content, showMeta, placeholder, onNoteSave, onNoteEdit, onNoteRemove, onNoteCancel, onValueChange, }) {
    const theme = useTheme();
    const onInputChange = (value) => onValueChange(value);
    const onSaveClick = (e) => {
        e.preventDefault();
        onNoteSave === null || onNoteSave === void 0 ? void 0 : onNoteSave();
    };
    const onRemoveClick = (e) => {
        e.preventDefault();
        onNoteRemove === null || onNoteRemove === void 0 ? void 0 : onNoteRemove();
    };
    return (React.createElement(React.Fragment, null, isEditing ? (React.createElement(Card, { py: 4, px: 5, style: {
            background: theme.palette.background.card,
            width: 300,
        }, elevation: 6, onClick: (e) => e.stopPropagation() },
        React.createElement(RichTextEditor, { placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : 'Add new note...', style: {
                minHeight: 120,
                maxHeight: 260,
                marginBottom: theme.space[2],
            }, onChange: onInputChange, value: content },
            React.createElement(RichTextEditorToolbar, { disableNotes: true })),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                React.createElement(IconButton, { onClick: onRemoveClick, icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), size: "small" })),
            React.createElement(Box, { flexDirection: "row", alignItems: "center" },
                content && (React.createElement(Button, { onClick: onNoteCancel, size: "small" }, intl.t('cancel', 'Cancel'))),
                React.createElement(Button, { onClick: onSaveClick, size: "small", className: css({ marginLeft: 1 }) }, intl.t('save', 'Save')))))) : (React.createElement(Card, { py: 4, px: 5, style: {
            background: theme.palette.background.card,
            width: 300,
            border: `1px solid ${theme.palette.primary.main}`,
            borderTopLeftRadius: 0,
            position: 'relative',
            top: -1,
        }, elevation: 16, onClick: (e) => e.stopPropagation() },
        React.createElement(Box, null,
            showMeta && (React.createElement(Box, { flexDirection: "row", alignItems: "center", pb: 1, justifyContent: "flex-end" },
                userName && (React.createElement(Typography, { variant: "body2", color: "secondaryText" }, userName)),
                date && userName && (React.createElement(Typography, { variant: "body2", color: "secondaryText" }, "/")),
                date && (React.createElement(Typography, { variant: "body2", color: "secondaryText" }, formatDate(date))))),
            React.createElement(RichTextRenderer, { style: { maxHeight: 400 }, value: content, className: getScrollerCss({
                    showScroller: 'always',
                    theme: theme,
                }) })),
        isEditable && (React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", pt: 3 },
            React.createElement(IconButton, { onClick: onRemoveClick, icon: React.createElement(DeleteRoundedIcon, { fontSize: "small" }), size: "small" }),
            React.createElement(Button, { onClick: onNoteEdit, className: css({ marginLeft: 'auto' }), size: "small" }, intl.t('edit', 'Edit'))))))));
}
