export function initNotificationsApi(publicapi, notificationStore) {
    publicapi.exposeMethod({
        name: '/app/showNotification',
        paramsJSONSchema: {
            type: 'object',
            required: ['message'],
            properties: {
                id: { type: 'string', nullable: true },
                message: { type: 'string' },
                type: {
                    type: 'string',
                    enum: ['info', 'error', 'success', 'warning'],
                    nullable: true,
                },
                permanent: { type: 'boolean', nullable: true },
                closable: { type: 'boolean', nullable: true },
                detailText: { type: 'string', nullable: true },
                duration: { type: 'integer', nullable: true },
                showDurationTimer: { type: 'boolean', nullable: true },
            },
        },
        handler: (input) => {
            const id = notificationStore.showNotification(input);
            return {
                notificationID: id,
            };
        },
    });
    publicapi.exposeMethod({
        name: '/app/closeNotification',
        paramsJSONSchema: {
            type: 'object',
            required: ['notificationID'],
            properties: {
                notificationID: { type: 'string' },
            },
        },
        handler: (input) => {
            notificationStore.closeNotification(input.notificationID);
        },
    });
}
