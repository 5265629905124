var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Typography as TypographyMUI, } from '@mui/material';
import React from 'react';
import { useTheme } from '@corti/theme';
/**
 * This component is used to display text with theme typography styles
 */
export const Typography = React.forwardRef(function Typography(props, ref) {
    const { color, noSelect, uppercase, sx } = props, rest = __rest(props, ["color", "noSelect", "uppercase", "sx"]);
    const theme = useTheme();
    function getColor() {
        const colors = {
            hint: theme.palette.text.hint,
            default: theme.palette.text.primary,
            secondaryText: theme.palette.text.secondary,
            inverted: theme.palette.text.inverted,
            disabled: theme.palette.text.disabled,
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            error: theme.palette.error.dark,
            warning: theme.palette.warning.dark,
            success: theme.palette.success.dark,
            info: theme.palette.info.dark,
            inherit: 'inherit',
        };
        return color ? colors[color] : 'inherit';
    }
    return (React.createElement(TypographyMUI, Object.assign({ sx: Object.assign({ userSelect: noSelect ? 'none' : undefined, color: getColor(), textTransform: uppercase ? 'uppercase' : undefined }, sx), ref: ref, variantMapping: { body1: 'span', body2: 'span', inherit: 'span' } }, rest)));
});
