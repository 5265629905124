import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { uuid } from '@corti/uuid';
import { useAuth } from 'core/auth/browser';
import { Box, Button, Input, ModalContent, ModalHeader } from 'lib/cortiUI';
import { CoreBackendSerializer } from 'lib/graphEditor/coreBackendSerializer';
import { graphTemplates } from 'lib/graphEditor/graphTemplates';
import { useEnsureGraphVersionMutation, useEnsureProtocolGraphMutation } from '../api';
export function CreateGraphView({ onCancel }) {
    const authStore = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const [graphName, setGraphName] = React.useState('');
    const [ensureGraphVersion, ensureGraphVersionResult] = useEnsureGraphVersionMutation();
    const [ensureProtocolGraph, ensureProtocolGraphResult] = useEnsureProtocolGraphMutation();
    const handleCreateNewGraph = async () => {
        const graphID = uuid();
        await ensureProtocolGraph({
            id: graphID,
            organizationID: authStore.organization.id,
            name: graphName,
        });
        const versionID = uuid();
        await ensureGraphVersion({ id: versionID, graphID, name: 'Initial' });
        await api.protocolGraphs.ensureGraph({
            versionID,
            graph: await CoreBackendSerializer.transform({
                graphData: graphTemplates.minimalValidGraph(),
            }),
            commitMessage: 'init',
        });
        history.push(`/editor-v2/editor/${versionID}`);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: t('editorApp2:createGraphView.title', 'Graph name') }),
        React.createElement(ModalContent, null,
            React.createElement("form", { onSubmit: (e) => {
                    e.preventDefault();
                    void handleCreateNewGraph();
                } },
                React.createElement(Box, { "data-cy": "create-new-graph-dialog", gap: 8 },
                    React.createElement(Box, { gap: 3 },
                        React.createElement(Input, { id: "graph-name-field", autoFocus: true, autoComplete: "off", value: graphName, onChange: (e) => setGraphName(e.target.value) })),
                    React.createElement(Box, { flexDirection: "row", alignItems: "center", gap: 4 },
                        React.createElement(Button, { "data-cy": "create-new-graph-submit-button", color: "primary", disabled: ensureGraphVersionResult.loading || ensureProtocolGraphResult.loading, type: "submit" }, t('editorApp2:createGraphView.saveBtn', 'Save')),
                        React.createElement(Button, { onClick: onCancel }, t('editorApp2:createGraphView.cancelBtn', 'Cancel'))))))));
}
