var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep } from 'lodash';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { Observer } from '@corti/observer';
export class Alerts {
    constructor(input) {
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "priorityAlertID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.session = input.session;
        this.observer = new Observer();
        runInAction(() => {
            this.all = input.state.alerts;
            this.priorityAlertID = input.state.priorityAlertID;
        });
    }
    reset(state) {
        var _a, _b;
        this.all = (_a = state.alerts) !== null && _a !== void 0 ? _a : this.all;
        this.priorityAlertID = (_b = state.priorityAlertID) !== null && _b !== void 0 ? _b : this.priorityAlertID;
    }
    create(alert) {
        this.all.push(alert);
        this.priorityAlertID = alert.id;
        this.observer.fireEvent('alert-created', alert);
    }
    triggerAction(alert) {
        var _a, _b, _c;
        if (!this.session.isCurrentUserOwner) {
            return;
        }
        if (!((_a = alert.alertNode) === null || _a === void 0 ? void 0 : _a.targetNodeID)) {
            return;
        }
        const branch = this.session.flow.graphTraverser.getBranchByNodeID(alert.alertNode.targetNodeID);
        if (!branch) {
            (_b = this.session.logger) === null || _b === void 0 ? void 0 : _b.warn('Failed to trigger alert: branch not found');
            return;
        }
        const node = this.session.flow.graphTraverser.getNodeByTarget({
            branchID: branch.id,
            nodeID: alert.alertNode.targetNodeID,
        });
        if (!node) {
            (_c = this.session.logger) === null || _c === void 0 ? void 0 : _c.warn('Failed to trigger alert: node not found');
            return;
        }
        let step = this.session.flow.stepStore.getLastStepByNodeID(node.id);
        if (!step) {
            step = this.session.flow.stepStore.createStep(node);
            this.session.flow.addStep(step);
        }
        this.session.flow.changeActiveStep({
            step,
            source: 'user-action',
            sourceData: {
                location: 'alert-widget',
            },
        });
        this.observer.fireEvent('alert-action-triggered', alert);
    }
    serialize() {
        return cloneDeep({
            alerts: toJS(this.all),
            priorityAlertID: toJS(this.priorityAlertID),
        });
    }
    get priorityAlert() {
        var _a;
        return this.priorityAlertID
            ? (_a = this.all.find((it) => it.id === this.priorityAlertID)) !== null && _a !== void 0 ? _a : null
            : null;
    }
    onCreate(cb) {
        return this.observer.on('alert-created', cb);
    }
    onActionTrigger(cb) {
        return this.observer.on('alert-action-triggered', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Alerts.prototype, "priorityAlertID", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Alerts.prototype, "all", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], Alerts.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Alerts.prototype, "create", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Alerts.prototype, "priorityAlert", null);
