import React from 'react';
import { Redirect } from 'react-router-dom';
import { intl } from '@corti/i18n';
import { FallbackView } from 'lib/cortiUI';
export function DefaultModuleRedirect({ location, module }) {
    if (!module) {
        return (React.createElement(FallbackView, { title: intl.t('noAppsTitle', 'No Apps Available'), text: intl.t('noAppsText', "Change organization's feature config") }));
    }
    return React.createElement(Redirect, { to: { pathname: module.path, search: location.search } });
}
