import fuzzysort from 'fuzzysort';
import { deburr, orderBy } from 'lodash';
export function prepare(graph) {
    let items = prepItems(graph);
    return {
        find(query) {
            return find(query, items);
        },
    };
}
function prepItems(graph) {
    return graph
        .getBranches()
        .map((b) => {
        const it = {
            text: b.name,
            _text: deburr(b.name),
            target: b,
        };
        b.keywords.forEach((kwd, idx) => {
            // @ts-ignore
            it[`dynamic_kwd_${idx}`] = deburr(kwd);
        });
        return it;
    })
        .sort((a, b) => {
        return a.text.localeCompare(b.text, undefined, { numeric: true });
    });
}
function find(inputValue, indexableSearchItems) {
    if (!inputValue) {
        return indexableSearchItems;
    }
    // fuzzysort doesn't allow searching for object properties that contan arrays so
    // every keyword deserves it's own property on a indexable object
    const maxKeywordCount = Math.max(...indexableSearchItems.map((it) => it.target.keywords.length));
    const dynamicKeys = Array(maxKeywordCount)
        .fill('')
        .map((_, idx) => `dynamic_kwd_${idx}`);
    const results = fuzzysort.go(deburr(inputValue), indexableSearchItems, {
        all: true,
        keys: ['_text', ...dynamicKeys],
        // up from -1000 will be results where match happens only in the beginning of the word
        threshold: -1000,
    });
    return results.map((result) => {
        const [textMatch, ...keywordMatches] = result;
        if (textMatch) {
            // @ts-expect-error `target` is readonly property
            // but we want the non prepared text here instead of what was used for searching
            textMatch.target = result.obj.text;
        }
        // revert to non prepared strings for keywords
        keywordMatches.forEach((kwd, idx) => {
            if (kwd == null) {
                return;
            }
            // @ts-expect-error `target` is readonly property
            kwd.target = result.obj.target.keywords[idx];
        });
        const matchedKeywords = orderBy(keywordMatches.filter((it) => {
            if (it == null) {
                return false;
            }
            // individual keyword score is not the same as the `threshold` so it should not be a shared variable
            // -1000 works quite nicely however when matching keywords
            if (it.score < -1000) {
                return false;
            }
            return true;
        }), (it) => it.score);
        return Object.assign(Object.assign({}, result.obj), { searchResult: {
                text: textMatch,
                dynamicKeywords: matchedKeywords,
            } });
    });
}
