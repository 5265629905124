var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card } from 'lib/cortiUI';
export function Item(props) {
    const theme = useTheme();
    const { borderColor = transparentize(0.9, theme.palette.text.primary), children, className } = props, rest = __rest(props, ["borderColor", "children", "className"]);
    return (React.createElement(Card, Object.assign({ p: 0, height: "100%", width: "100%", elevation: 0 }, rest, { className: css({
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            overflow: 'hidden',
        }, className) }),
        React.createElement(Base, { width: 3, height: "100%", className: css({
                backgroundColor: borderColor,
            }) }),
        React.createElement(Base, { p: 5, width: "100%" }, children)));
}
