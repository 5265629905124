import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Subsection } from '../general/Subsection';
const Body = styled(motion.div) `
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 2px;

  & > *:last-child {
    margin-bottom: 12px;
  }
`;
export const Progress = styled.p `
  font-size: 16px;
  line-height: 100%;
  background: var(--heading-background, #333);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  margin: 0;
`;
const listVariants = {
    initial: { height: 0, opacity: 0, transition: { duration: 0.2 } },
    animate: { height: 'auto', opacity: 1, transition: { duration: 0.2 } },
    exit: { height: 0, opacity: 0, transition: { duration: 0.2 } },
};
const bodyVariants = {
    initial: { height: 0 },
    animate: { height: 'auto', transition: { when: 'beforeChildren', duration: 0.3 } },
    exit: { height: 0, transition: { when: 'afterChildren' } },
};
const mode2color = {
    idle: 'inactive',
    active: 'primary',
    completed: 'success',
};
export function Checklist(props) {
    const { mode, name, completedItems, totalItems, isExpanded, readOnly = false, children } = props;
    return (React.createElement(Subsection, { color: mode2color[mode], title: name, postfix: React.createElement(Progress, null,
            completedItems,
            " / ",
            totalItems), asButton: !readOnly, onClick: props.readOnly ? undefined : props.onToggle, "aria-expanded": isExpanded, variants: listVariants },
        React.createElement(AnimatePresence, { initial: false }, isExpanded && (React.createElement(Body, { variants: bodyVariants, initial: "initial", animate: "animate", exit: "exit" }, children)))));
}
