import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { Base, Button, Typography } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { useInteraction } from '../../context';
import { useNavigation } from '../context';
export const NoAssessment = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.noAssessment' });
    const { setView } = useNavigation();
    const { interaction } = useInteraction();
    return (React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", gap: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 3, textAlign: "center" },
            React.createElement(Typography, { variant: "subtitle2", color: "default" }, t('title', 'No Assessments to show')),
            React.createElement(Typography, { variant: "body2" }, t('text', 'This interaction has not been assessed yet or you don’t have the permission to see past assessments.'))),
        rbacService.feedbackSubmissionCRUD({ caseOwnerID: interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner }) && (React.createElement(Button, { color: "primary", size: "small", onClick: () => setView('manual-assessment') },
            React.createElement(AddRoundedIcon, { sx: { mr: 3 }, fontSize: "small" }),
            t('actionBtn', 'New Assessment')))));
};
