import { groupBy, isEqual, sortBy } from 'lodash';
import { intl } from '@corti/i18n';
import { TimelineEntryDefinitionSourceType, } from '@corti/lib/coreApiService';
import { getDisabledSCVTimelineEntryDefinitionShape } from '../utils';
export function getAnnotationGroupTitle(definitionSourceType) {
    const FILTER_NAMES = {
        [TimelineEntryDefinitionSourceType.callAnnotation]: intl.t('annotationManagement:timelineEntryTypes.callAnnotations', 'Call annotations'),
        [TimelineEntryDefinitionSourceType.customEvent]: intl.t('annotationManagement:timelineEntryTypes.customEvents', 'CAD events'),
        [TimelineEntryDefinitionSourceType.default]: intl.t('annotationManagement:timelineEntryTypes.default', 'Default'),
        [TimelineEntryDefinitionSourceType.detection]: intl.t('annotationManagement:timelineEntryTypes.detections', 'Detections'),
        [TimelineEntryDefinitionSourceType.insightsCallEvent]: intl.t('annotationManagement:timelineEntryTypes.insightsCallEvents', 'Insights call events'),
        [TimelineEntryDefinitionSourceType.searchTerms]: intl.t('annotationManagement:timelineEntryTypes.searchTerms', 'Search terms'),
        [TimelineEntryDefinitionSourceType.triageEvent]: intl.t('annotationManagement:timelineEntryTypes.triageEvents', 'Triage events'),
        [TimelineEntryDefinitionSourceType.triageFlowEvent]: intl.t('annotationManagement:timelineEntryTypes.triageFlowEvent', 'Triage flow events'),
    };
    return FILTER_NAMES[definitionSourceType];
}
const AVAILABLE_SOURCE_TYPES_FOR_VISIBILITY = [
    TimelineEntryDefinitionSourceType.callAnnotation,
    TimelineEntryDefinitionSourceType.insightsCallEvent,
    TimelineEntryDefinitionSourceType.triageEvent,
    TimelineEntryDefinitionSourceType.searchTerms,
    TimelineEntryDefinitionSourceType.customEvent,
    TimelineEntryDefinitionSourceType.triageFlowEvent,
];
const transformDefinitionShapeForAccordions = (timelineEntryDefinition, disabledDefinitions = []) => {
    const definitionShapeForComparison = getDisabledSCVTimelineEntryDefinitionShape(timelineEntryDefinition);
    const isDisabled = disabledDefinitions.find((disabledDefinition) => isEqual(definitionShapeForComparison, disabledDefinition));
    return { disabled: Boolean(isDisabled), definition: timelineEntryDefinition };
};
export const getDefinitionsGroups = (definitions, disabledDefinitions = []) => {
    const transformedDefinitions = sortBy(definitions, (d) => d.source.type).map((def) => transformDefinitionShapeForAccordions(def, disabledDefinitions));
    const definitionsGroupedByType = groupBy(transformedDefinitions, ({ definition }) => definition.source.type);
    for (const key in definitionsGroupedByType) {
        if (!AVAILABLE_SOURCE_TYPES_FOR_VISIBILITY.includes(key)) {
            delete definitionsGroupedByType[key];
        }
    }
    const definitionsGroup = {};
    for (const [defType, defs] of Object.entries(definitionsGroupedByType)) {
        definitionsGroup[defType] = groupBy(sortBy(defs, ['definition.title', 'definition.text']), ({ definition }) => definition.title);
    }
    return definitionsGroup;
};
