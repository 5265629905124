import React from 'react';
import { useInteraction } from '../../context';
import { Acknowledged } from './Acknowledged';
import { AcknowledgementRequested } from './AcknowledgementRequested';
import { InteractionStatusLoader } from './AcknowledgementStatusLoader';
import { NoAgentInput } from './NoAgentInput';
import { ReviewRequested } from './ReviewRequested';
export const AcknowledgementStatus = () => {
    const { interaction, isLoading } = useInteraction();
    function getContendByStatus(status) {
        switch (status) {
            case 'NO_AGENT_INPUT':
                return React.createElement(NoAgentInput, null);
            case 'REVIEW_REQUESTED':
                return React.createElement(ReviewRequested, null);
            case 'ACKNOWLEDGED':
                return React.createElement(Acknowledged, null);
            case 'ACKNOWLEDGEMENT_REQUESTED':
                return React.createElement(AcknowledgementRequested, null);
            default:
                return null;
        }
    }
    if (isLoading)
        return React.createElement(InteractionStatusLoader, null);
    if (!interaction)
        return null;
    return getContendByStatus(interaction.acknowledgement_status);
};
