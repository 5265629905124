import React from 'react';
import { intl } from '@corti/i18n';
import { createRichText } from '@corti/richtext';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Button } from 'lib/cortiUI/components/Button';
import { Card } from 'lib/cortiUI/components/Card';
import { RichTextEditor } from './RichTextEditor';
import { RichTextEditorToolbar } from './RichTextEditorToolbar';
export function NoteCreator(props) {
    const { onSave, onCancel } = props;
    const theme = useTheme();
    const [textValue, setTextValue] = React.useState(createRichText());
    return (React.createElement(Card, { minWidth: 300 },
        React.createElement(RichTextEditor, { placeholder: intl.t('richTextEditor.noteCreator.placeholder', 'Add new note...'), style: {
                minHeight: 120,
                maxHeight: 260,
                marginBottom: theme.space[2],
            }, onChange: setTextValue, value: textValue },
            React.createElement(RichTextEditorToolbar, { disableNotes: true })),
        React.createElement(Box, { flexDirection: "row", alignItems: "center", justifyContent: "flex-end" },
            React.createElement(Button, { onClick: onCancel, size: "small" }, intl.t('cancel', 'Cancel')),
            React.createElement(Button, { onClick: (e) => onSave === null || onSave === void 0 ? void 0 : onSave(textValue, e), size: "small", className: css({ marginLeft: 1 }) }, intl.t('save', 'Save')))));
}
