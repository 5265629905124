import { RolesRepo } from './Roles';
import { TimelineEntryDefinitionsRepo } from './TimelineEntryDefinitions';
import { UsersRepo } from './Users';
/**
 * Repositories store all the entities that application need to interact with
 * It's like client side cache of server data.
 * Ideally all the data should be taken from repositories
 * instead of fetching from the backend manually
 */
export let repositories;
export function initRepositories(appDB) {
    const roles = new RolesRepo();
    const users = new UsersRepo(appDB.users);
    const timelineEntryDefinitions = new TimelineEntryDefinitionsRepo(appDB.timelineEntryDefinitions);
    repositories = {
        users,
        timelineEntryDefinitions,
        roles,
    };
}
