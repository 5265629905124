import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { createTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Button, PageHeader, PageHeaderActionsSection, Typography } from 'lib/cortiUI';
export const Header = () => {
    const { t } = useTranslation('templateList', { keyPrefix: 'header' });
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleCreateNewTemplate() {
        setIsLoading(true);
        try {
            const res = await createTemplate({ name: 'New template' });
            history.push(`${history.location.pathname}/${res.id}`);
        }
        catch (e) {
            coreStore.notifications.showNotification({
                message: t('createTemplateError', 'Creating a new template failed'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(PageHeader, null,
        React.createElement(Typography, { variant: "h4", color: "default" }, t('feedbackFormTemplates', 'Feedback Form Templates')),
        React.createElement(PageHeaderActionsSection, null,
            React.createElement(Button, { color: "primary", onClick: handleCreateNewTemplate, disabled: isLoading }, t('createNewTemplate', 'Create New Template')))));
};
