import AddIcon from '@mui/icons-material/Add';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getReviewFeedback } from '@corti/mission-control-api';
import { useTheme } from '@corti/theme';
import { Base, Card, Typography } from 'lib/cortiUI';
import { GradientStarIcon } from '../components';
import { NoSummary } from './NoSummary';
import { SummaryList } from './SummaryList';
import { SummaryLoader } from './SummaryLoader';
export const Summary = ({ reviewID }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.summary' });
    const { palette } = useTheme();
    const [feedback, setfeedback] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchFeedback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewID]);
    async function fetchFeedback() {
        setIsLoading(true);
        try {
            const res = await getReviewFeedback(reviewID);
            setfeedback(res);
        }
        catch (e) {
            console.error('Could not fetch Feedback for review', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(SummaryLoader, null);
    }
    return (React.createElement(Card, { p: 6, background: `linear-gradient(57deg, rgb(251,242,253) 0%, ${palette.background.card} 48%, ${palette.background.card} 100%)` },
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(Base, { display: "flex", flexDirection: "row", gap: 2, alignItems: "center" },
                React.createElement(GradientStarIcon, { fontSize: "small" }),
                React.createElement(Typography, { color: "default", variant: "subtitle2" }, t('title', 'AI Summary'))),
            !feedback.length && React.createElement(NoSummary, null),
            React.createElement(SummaryList, { icon: React.createElement(AddIcon, { color: "success", fontSize: "small" }), title: t('positives', 'Positives'), summaryTexts: feedback.filter((it) => it.positive).map((it) => it.text) }),
            React.createElement(SummaryList, { icon: React.createElement(LightbulbOutlinedIcon, { color: "warning", fontSize: "small" }), title: t('improvements', 'Space for Improvement'), summaryTexts: feedback.filter((it) => !it.positive).map((it) => it.text) }))));
};
