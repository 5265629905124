var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import React from 'react';
import { css, darken } from '@corti/style';
import { withTheme } from '@corti/theme';
import { Box } from 'lib/cortiUI/components/Box';
import { Typography } from 'lib/cortiUI/components/Typography';
import { DefaultResizeHandle } from './DefaultResizeHandle';
//@ts-ignore
let DefaultHeaderCellRenderer = class DefaultHeaderCellRenderer extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "$cellRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                isMouseOver: false,
            }
        });
        Object.defineProperty(this, "onCellMouseEnter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isMouseOver: true });
            }
        });
        Object.defineProperty(this, "onCellMouseLeave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isMouseOver: false });
            }
        });
    }
    get isSortable() {
        var _a;
        const { disableSort, requiredProps } = this.props;
        return (!!((_a = requiredProps.tableControllerState) === null || _a === void 0 ? void 0 : _a.isSortable) && !requiredProps.disableSort && !disableSort);
    }
    get isResizable() {
        var _a;
        const { disableResize, requiredProps } = this.props;
        return !!((_a = requiredProps.tableControllerState) === null || _a === void 0 ? void 0 : _a.isResizable) && !disableResize;
    }
    render() {
        var _a;
        const _b = this.props, { requiredProps, disableSort, disableResize, className, labelClassName, resizeHandleClassName, theme } = _b, rest = __rest(_b, ["requiredProps", "disableSort", "disableResize", "className", "labelClassName", "resizeHandleClassName", "theme"]);
        const { isMouseOver } = this.state;
        const { label, sortDirection } = requiredProps;
        const sortIcon = sortDirection === 'ASC' ? (React.createElement(KeyboardArrowUpRoundedIcon, { fontSize: "small", className: css({ marginLeft: 7, color: theme.palette.text.primary }) })) : (React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small", className: css({ marginLeft: 7, color: theme.palette.text.primary }) }));
        const showHover = !((_a = requiredProps.tableControllerState) === null || _a === void 0 ? void 0 : _a.isResizing) &&
            isMouseOver &&
            (this.isSortable || this.isResizable);
        return (React.createElement(Box, Object.assign({ onMouseEnter: this.onCellMouseEnter, onMouseLeave: this.onCellMouseLeave, onClick: (e) => {
                if (!this.isSortable) {
                    e.stopPropagation();
                }
            }, ref: (ref) => (this.$cellRef = ref), flexDirection: "row", alignItems: "center", className: css({
                height: '100%',
                cursor: this.isSortable ? 'pointer' : 'default',
                paddingLeft: theme.space[6],
                paddingRight: theme.space[6],
                backgroundColor: showHover
                    ? darken(0.015, theme.palette.background.secondary)
                    : 'unset',
            }, className) }, rest),
            React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true, className: css({ textTransform: 'capitalize' }, labelClassName), noSelect: true }, label),
            this.isSortable && isMouseOver && sortIcon,
            requiredProps.tableControllerState && this.isResizable && this.$cellRef && (React.createElement(DefaultResizeHandle, { cellRef: this.$cellRef, columnID: requiredProps.dataKey, tableControllerState: requiredProps.tableControllerState, className: resizeHandleClassName }))));
    }
};
Object.defineProperty(DefaultHeaderCellRenderer, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        disableResize: false,
        disableSort: false,
    }
});
DefaultHeaderCellRenderer = __decorate([
    withTheme
], DefaultHeaderCellRenderer);
export { DefaultHeaderCellRenderer };
