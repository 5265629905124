import { Numbers } from '@mui/icons-material';
import { observer } from 'mobx-react';
import React from 'react';
import { InputAdornment, TextField } from 'lib/cortiUI';
import { FormElementWidget } from './FormElementWidget';
export const NumberInputElementWidget = observer(function NumberInputElementWidget({ model, }) {
    return (React.createElement(FormElementWidget, { model: model },
        React.createElement(TextField, { disabled: true, fullWidth: true, value: "", InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(Numbers, { fontSize: "small" }))),
            } })));
});
