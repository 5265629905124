import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { PerformanceApp } from './PerformanceApp';
const mod = {
    id: 'performance',
    title: () => intl.t('performanceApp:appName', 'Compliance'),
    path: '/performance',
    icon: DashboardRoundedIcon,
    requiresOrgFeature: 'performance-metrics',
    requiresUserPermission: 'performance_views:read',
    viewComponent: () => React.createElement(PerformanceApp, null),
    navGroupe: 1,
};
export default mod;
