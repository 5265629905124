var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { difference, intersection, isEqual } from 'lodash';
import { action, computed, makeObservable, observable, runInAction, } from 'mobx';
import { api } from '@corti/lib/coreApiService';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { TimelineEntryModel, } from './TimelineEntryModel';
export class TimelineEditor {
    constructor(caseModel) {
        Object.defineProperty(this, "caseModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRecordingChanges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isInstantCreation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "newTimelineEntryInitialInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempRegion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timelineEntries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeTimelineEntryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isTimelineLocked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSaving", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "undoStack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "redoStack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDirty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "removeTempRegion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.tempRegion = undefined;
            }
        });
        Object.defineProperty(this, "createTempRegion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (region) => {
                this.tempRegion = region;
                this.setActiveTimelineEntry();
            }
        });
        Object.defineProperty(this, "updateTempRegion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (region) => {
                this.tempRegion = region;
            }
        });
        makeObservable(this);
        this.caseModel = caseModel;
        this.isRecordingChanges = true;
        runInAction(() => {
            this.isDirty = false;
            this.isTimelineLocked = false;
            this.timelineEntries = [];
            this.isSaving = false;
            this.undoStack = observable.array([], { deep: false });
            this.redoStack = observable.array([], { deep: false });
        });
    }
    get currentCall() {
        return this.caseModel.activeCall;
    }
    /**
     * Indicates whether changes to the timeline should affect
     * the view model state - `isDirty` and undo/redo history stacks
     */
    recordChanges(val) {
        this.isRecordingChanges = val;
    }
    /**
     * Indicates whether there are any *actual* changes in the editor
     */
    get diffHasChanges() {
        return (this.diff.addedItems.length || this.diff.removedItems.length || this.diff.changedItems.length);
    }
    get canUndo() {
        return this.undoStack.length > 0;
    }
    get canRedo() {
        return this.redoStack.length > 0;
    }
    /**
     * Undo latest change
     */
    undo() {
        const snap = this.undoStack.pop();
        if (snap) {
            this.redoStack.push(this.captureSnapshot());
            this.resetToSnapshot(snap);
        }
    }
    redo() {
        const snap = this.redoStack.pop();
        if (snap) {
            this.undoStack.push(this.captureSnapshot());
            this.resetToSnapshot(snap);
        }
    }
    /**
     * Clears all the change history
     */
    clearViewmodel() {
        this.undoStack.clear();
        this.redoStack.clear();
        this.isDirty = false;
    }
    /** Save all changes to the server and exit edit mode */
    async saveAndExit() {
        if (this.undoStack.length && !this.isSaving) {
            this.recordChanges(false);
            runInAction(() => {
                this.isSaving = true;
            });
            const add = this.diff.addedItems.map((item) => {
                trackerService.track('Annotation added');
                return item.persist();
            });
            const remove = this.diff.removedItems
                .map((item) => {
                if (item.sourceID) {
                    trackerService.track('Annotation deleted');
                    return api.cases.deleteTimelineEntry({
                        organizationID: authStore.organization.id,
                        definitionSource: item.definition.source,
                        sourceID: item.sourceID,
                    });
                }
            })
                .filter((i) => i != null);
            const change = this.diff.changedItems.map((item) => {
                trackerService.track('Annotation edited');
                return item.persist();
            });
            // For now we cannot make a single transaction
            // thus error handling is really basic
            try {
                await Promise.all([...add, ...remove, ...change]);
                this.clearViewmodel();
            }
            catch (_a) {
                this.resetAndExit();
                void this.refreshStateFromServer();
            }
            finally {
                runInAction(() => {
                    this.isSaving = false;
                });
                this.recordChanges(true);
            }
        }
    }
    /**
     * Discard all recorded changes and revert to initial state before the first edit
     */
    resetAndExit() {
        if (this.undoStack.length) {
            const initial = this.undoStack[0];
            this.resetToSnapshot(initial);
        }
        this.clearViewmodel();
    }
    /**
     * Reset a single timeline entry to the initial state
     */
    resetTimelineEntry(timelineEntry) {
        const item = this.diff.changedItems.find((c) => c === timelineEntry);
        if (item) {
            const initialState = this.undoStack[0].state.timelineEntries.find((t) => t.timelineEntry === timelineEntry);
            if (initialState) {
                this.undoStack.push(this.captureSnapshot());
                timelineEntry.resetToSnapshot(initialState.snapshot);
            }
        }
    }
    async refreshStateFromServer() {
        const entries = await api.cases.getTimelineEntries(this.caseModel.id);
        this.populateTimelineEntriesFromServer(entries);
    }
    get diff() {
        const latestSnapshot = this.captureSnapshot();
        let initSnapshot = this.captureSnapshot();
        if (this.undoStack.length) {
            initSnapshot = this.undoStack[0];
        }
        return getDiff(initSnapshot, latestSnapshot);
    }
    handleBeforeChange() {
        if (!this.isRecordingChanges) {
            return;
        }
        this.isDirty = true;
        this.redoStack.clear();
        this.undoStack.push(this.captureSnapshot());
    }
    captureSnapshot() {
        return {
            state: {
                timelineEntries: this.timelineEntries.map((t) => ({
                    timelineEntry: t,
                    snapshot: t.captureSnapshot(),
                })),
            },
        };
    }
    resetToSnapshot(snapshot) {
        this.recordChanges(false);
        this.clearTimeline();
        snapshot.state.timelineEntries.forEach((e) => {
            e.timelineEntry.resetToSnapshot(e.snapshot);
            this.addTimelineEntry(e.timelineEntry);
        });
        this.recordChanges(true);
    }
    populateTimelineEntriesFromServer(timelineEntries) {
        this.recordChanges(false);
        timelineEntries
            .map((t) => {
            const input = TimelineEntryModel.serverValueToInput(t);
            return new TimelineEntryModel(input);
        })
            .forEach((t) => this.addTimelineEntry(t));
        this.recordChanges(true);
    }
    getTimelineEntryByID(id) {
        return this.timelineEntries.find((t) => t.id === id);
    }
    setNextTimelineEntryInitialInput(input) {
        this.newTimelineEntryInitialInput = input;
    }
    setActiveTimelineEntry(id) {
        this.activeTimelineEntryID = id;
        if (id) {
            this.bringToFront(id);
        }
    }
    get activeTimelineEntry() {
        return this.timelineEntries.find((t) => t.id === this.activeTimelineEntryID);
    }
    createTimelineEntry(input) {
        const definition = this.caseModel.timelineEntryDefinitions.find(({ source }) => { var _a; return isEqual(source, (_a = input.definition) === null || _a === void 0 ? void 0 : _a.source); });
        const newEntry = new TimelineEntryModel(Object.assign(Object.assign({ isEditable: true }, input), { definition }));
        this.addTimelineEntry(newEntry);
        // TODO: the following properties could be set from the caller
        // for that we need to add some event listeners on this class
        this.newTimelineEntryInitialInput = undefined;
        return newEntry;
    }
    addTimelineEntry(timelineEntry) {
        this.handleBeforeChange();
        timelineEntry.onBeforeChange(() => this.handleBeforeChange());
        timelineEntry.setParent(this);
        this.timelineEntries.push(timelineEntry);
    }
    /**
     * Remove all timeline entries from the timeline
     */
    clearTimeline() {
        // Need to copy array to keep correct indexes when removing items
        [...this.timelineEntries].forEach((t) => this.removeTimelineEntry(t.id));
    }
    removeTimelineEntry(id) {
        const idx = this.timelineEntries.findIndex((t) => t.id === id);
        if (idx !== -1) {
            this.handleBeforeChange();
            const t = this.timelineEntries[idx];
            t.setParent();
            t.unAll();
            this.timelineEntries.splice(idx, 1);
        }
    }
    bringToFront(timelineEntryID) {
        const currentIdx = this.timelineEntries.findIndex((t) => t.id === timelineEntryID);
        if (currentIdx !== -1) {
            const item = this.timelineEntries[currentIdx];
            this.timelineEntries.splice(currentIdx, 1);
            this.timelineEntries.push(item);
        }
    }
    sendToBack(timelineEntryID) {
        const currentIdx = this.timelineEntries.findIndex((t) => t.id === timelineEntryID);
        if (currentIdx !== -1) {
            const item = this.timelineEntries[currentIdx];
            this.timelineEntries.splice(currentIdx, 1);
            this.timelineEntries.unshift(item);
        }
    }
    lockTimeline() {
        this.isTimelineLocked = true;
    }
    unlockTimeline() {
        this.isTimelineLocked = false;
    }
    getCall(id) {
        return this.caseModel.getCall(id);
    }
    getTimelineEntry(id) {
        return this.timelineEntries.find((t) => t.id === id);
    }
    getTimelineEntryBySourceID(sourceID) {
        return this.timelineEntries.find((t) => t.sourceID && t.sourceID === sourceID);
    }
    handleCancel() {
        if (this.activeTimelineEntry) {
            this.setActiveTimelineEntry();
            return;
        }
        if (this.tempRegion) {
            this.removeTempRegion();
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TimelineEditor.prototype, "tempRegion", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TimelineEditor.prototype, "timelineEntries", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], TimelineEditor.prototype, "activeTimelineEntryID", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], TimelineEditor.prototype, "isTimelineLocked", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], TimelineEditor.prototype, "isSaving", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], TimelineEditor.prototype, "isDirty", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEditor.prototype, "removeTempRegion", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEditor.prototype, "createTempRegion", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TimelineEditor.prototype, "updateTempRegion", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "currentCall", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "diffHasChanges", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "canUndo", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "canRedo", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "undo", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "redo", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "clearViewmodel", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TimelineEditor.prototype, "saveAndExit", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "resetAndExit", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof TimelineEntryModel !== "undefined" && TimelineEntryModel) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "resetTimelineEntry", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "diff", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "handleBeforeChange", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "resetToSnapshot", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "populateTimelineEntriesFromServer", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "setActiveTimelineEntry", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TimelineEditor.prototype, "activeTimelineEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "createTimelineEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof TimelineEntryModel !== "undefined" && TimelineEntryModel) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "addTimelineEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "clearTimeline", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "removeTimelineEntry", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "bringToFront", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "sendToBack", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "lockTimeline", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TimelineEditor.prototype, "unlockTimeline", null);
function getDiff(a, b) {
    const aTimelineEntries = a.state.timelineEntries.map((t) => t.timelineEntry);
    const bTimelineEntries = b.state.timelineEntries.map((t) => t.timelineEntry);
    const removedItems = difference(aTimelineEntries, bTimelineEntries);
    const addedItems = difference(bTimelineEntries, aTimelineEntries);
    const sameItems = intersection(aTimelineEntries, bTimelineEntries);
    return {
        addedItems,
        removedItems,
        changedItems: sameItems.filter((entry) => {
            const snap = a.state.timelineEntries.find((t) => t.timelineEntry === entry);
            const diff = TimelineEntryModel.getDiff(snap.snapshot, entry.captureSnapshot());
            return Object.keys(diff).length !== 0;
        }),
    };
}
