import { intl } from '@corti/i18n';
import { TimelineEntryDefinitionSourceType } from '@corti/lib/coreApiService/timelineEntryDefinitions/types';
// This import is required for tests to pass
import { FieldsNames, FormFieldVariant, } from './types';
export const DEFAULT_COLOR = '#787878';
export const TEST_IDS = {
    textfield: 'textfield',
    radio: 'radiofield',
    checkbox: 'checkbox',
    colorPicker: 'colorpicker',
    singleAutocomplete: 'autocomplete-single',
    multipleAutocomplete: 'autocomplete-multiple',
    timelineEntryDefinitionForm: 'timeline-entry-def-form',
    createTimelineEntryDefBtn: 'create-timeline-entry-def-btn',
    deleteTimelineEntryDefBtn: 'delete-timeline-entry-def-btn',
    gridResultsCount: 'grid-results-count',
};
/**
 * To have more fields in the form, modify the returned object of the function, FormFieldVariant, and FieldsNames.
 */
export const getInitialFormField = ({ category, name, color, searchTerms, isCreatable, isObvious, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        [FieldsNames.category]: {
            variant: FormFieldVariant.autocompleteSingle,
            name: FieldsNames.category,
            label: intl.t('annotationManagement:form.title', 'Category'),
            initialValue: (_a = category === null || category === void 0 ? void 0 : category.value) !== null && _a !== void 0 ? _a : { label: '', value: '' },
            options: (_b = category === null || category === void 0 ? void 0 : category.options) !== null && _b !== void 0 ? _b : [],
            freeSolo: true,
            rules: {
                required: intl.t('annotationManagement:errorMessages.requiredField', 'Required'),
            },
        },
        [FieldsNames.color]: {
            variant: FormFieldVariant.colorPicker,
            name: FieldsNames.color,
            label: intl.t('annotationManagement:form.changeColor', 'Change color'),
            initialValue: (_c = color === null || color === void 0 ? void 0 : color.value) !== null && _c !== void 0 ? _c : DEFAULT_COLOR,
            rules: {},
        },
        [FieldsNames.isCreatable]: {
            variant: FormFieldVariant.checkbox,
            name: FieldsNames.isCreatable,
            label: intl.t('annotationManagement:form.isCreatable', 'Can be annotated manually'),
            initialValue: (_d = isCreatable === null || isCreatable === void 0 ? void 0 : isCreatable.value) !== null && _d !== void 0 ? _d : false,
            rules: {},
        },
        [FieldsNames.name]: {
            variant: FormFieldVariant.textfield,
            name: FieldsNames.name,
            label: intl.t('annotationManagement:form.text', 'Name'),
            initialValue: (_e = name === null || name === void 0 ? void 0 : name.value) !== null && _e !== void 0 ? _e : '',
            rules: {
                required: intl.t('annotationManagement:errorMessages.requiredField', 'Required'),
            },
        },
        [FieldsNames.searchTerms]: {
            variant: FormFieldVariant.autocompleteMultiple,
            name: FieldsNames.searchTerms,
            label: intl.t('annotationManagement:form.searchTerms', 'Search terms'),
            initialValue: (_f = searchTerms === null || searchTerms === void 0 ? void 0 : searchTerms.value) !== null && _f !== void 0 ? _f : [],
            options: (_g = searchTerms === null || searchTerms === void 0 ? void 0 : searchTerms.options) !== null && _g !== void 0 ? _g : [],
            rules: {
                validate: {
                    value: (value) => value.length > 0 ||
                        intl.t('annotationManagement:errorMessages.noSearchTerms', 'At least one search term'),
                },
            },
        },
        [FieldsNames.isObvious]: {
            variant: FormFieldVariant.toggle,
            name: FieldsNames.isObvious,
            label: intl.t('annotationManagement:form.isObvious', 'Mark as “Obvious” if the patient is the caller'),
            initialValue: (_h = isObvious === null || isObvious === void 0 ? void 0 : isObvious.value) !== null && _h !== void 0 ? _h : false,
            rules: {},
            tooltip: intl.t('annotationManagement:form.isObviousTooltip', 'Enabling this option will allow Corti to mark the detection as "Obvious" for calls where it is detected that the caller is the patient. "Obvious" detections have a positive impact on performance tracking, regardless of the absence of related search terms. This feature works best with annotations of vital character, where the call taker is already aware of the answer  if the caller is the patient (e.g. Breathing, Consciousness).'),
        },
    });
};
export const getFieldOption = (value) => ({
    label: value,
    value,
});
export const getFormDataBySourceType = (timelineEntrySourceType, timelineEntryDefinitionsCategories = [], values) => {
    if (!timelineEntrySourceType ||
        !Object.values(TimelineEntryDefinitionSourceType).includes(timelineEntrySourceType))
        return [];
    const initialData = {
        category: {
            value: values === null || values === void 0 ? void 0 : values.category,
            options: timelineEntryDefinitionsCategories.map(getFieldOption),
        },
        name: {
            value: values === null || values === void 0 ? void 0 : values.name,
        },
        color: {
            value: values === null || values === void 0 ? void 0 : values.color,
        },
        searchTerms: {
            value: values === null || values === void 0 ? void 0 : values.searchTerms,
        },
        isCreatable: {
            value: values === null || values === void 0 ? void 0 : values.isCreatable,
        },
        isObvious: {
            value: values === null || values === void 0 ? void 0 : values.isObvious,
        },
    };
    const { category, name, color, searchTerms, isCreatable, isObvious } = getInitialFormField(initialData);
    const commonField = [category, name, color];
    switch (timelineEntrySourceType) {
        case TimelineEntryDefinitionSourceType.callAnnotation:
            return [...commonField, isCreatable];
        case TimelineEntryDefinitionSourceType.searchTerms:
            return [...commonField, searchTerms, isObvious];
        default:
            return commonField;
    }
};
