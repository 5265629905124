import React from 'react';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
export function TwoColGridLayout(props) {
    const { children, sidepanelMaxWidth = 360 } = props;
    return (React.createElement(Base, { display: "grid", gridAutoFlow: 'column', gridAutoColumns: `minmax(320px, 1fr) minmax(min-content, ${sidepanelMaxWidth}px)`, overflow: "hidden" }, children));
}
export function Sidepanel(props) {
    const theme = useTheme();
    return (React.createElement(Base, { background: theme.palette.background.default, className: getScrollerCss({ theme }), borderLeft: `1px solid ${theme.palette.background.divider}` }, props.children));
}
