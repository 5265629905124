import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { trackerService } from 'browser/services/init';
import { Base, Button } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../CaseReviewRequestsContext';
import { AdvancedFilterPicker } from './AdvancedFilterPicker';
import { DateRangeFilterPicker } from './DateRangeFilterPicker';
import { QuickFilterPicker } from './QuickFilterPicker';
export const ParametersPanel = observer(function ParametersPanel() {
    const { model } = useCaseReviewRequestsContext();
    function handleClearFilters() {
        model.filter.reset();
        handleFilterChange();
        trackerService.track('[Review Queue] all filters cleared');
    }
    function handleFilterChange() {
        // promise never rejects
        void model.getCaseReviewRequests();
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 4, "data-cy": "review-queue-parameters-panel" },
        React.createElement(QuickFilterPicker, { loading: model.isLoadingCaseReviewRequests, onChange: handleFilterChange }),
        React.createElement(DateRangeFilterPicker, { loading: model.isLoadingCaseReviewRequests, onChange: handleFilterChange }),
        React.createElement(AdvancedFilterPicker, { loading: model.isLoadingCaseReviewRequests, onChange: handleFilterChange }),
        model.filter.hasFiltersSet && (React.createElement(Button, { size: "small", variant: "text", color: "primary", onClick: handleClearFilters, noWrap: true }, intl.t('caseReviewRequestsModule:clearAll', 'Clear all')))));
});
