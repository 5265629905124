import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { coreStore } from 'browser/stores';
import { CoreBackendSerializer } from 'lib/graphEditor/coreBackendSerializer';
export function useSaveGraph({ editor, setSavingState }) {
    const { t } = useTranslation();
    return async (commitMessage) => {
        setSavingState({
            type: 'saving',
        });
        const serialized = await CoreBackendSerializer.transform({
            graphData: editor.model,
            options: {
                checklistsEnabled: editor.checklistSettings.enabled,
            },
        });
        try {
            await api.protocolGraphs.ensureGraph({
                versionID: editor.model.id,
                graph: serialized,
                commitMessage,
            });
            setSavingState({ type: 'saved', lastChangeDate: new Date() });
            editor.changelog.clear();
        }
        catch (err) {
            const message = t('editorApp2:graphEditorView.statusText.errorSavingGraph', 'An error occurred while saving');
            const detailText = err instanceof Error ? err.message : undefined;
            const showError = () => coreStore.notifications.showNotification({
                type: 'error',
                message,
                detailText,
                closable: true,
            });
            showError();
            setSavingState({ type: 'error', showError });
        }
    };
}
