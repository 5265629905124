import { CheckCircleRounded, HighlightOff } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useErrorMap } from './useJSONImport';
export function ImportResultTable({ result }) {
    const { t } = useTranslation('libGraphEditor');
    const errorMap = useErrorMap();
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: "h6", sx: { mt: 8, mb: 2 } }, t('checklist.import.table.headline', 'Import results')),
        React.createElement(Table, { cellPadding: "1" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, t('checklist.import.table.checklist', 'Checklist')),
                    React.createElement(TableCell, null, t('checklist.import.table.key', 'Key')),
                    React.createElement(TableCell, null, t('checklist.import.table.status', 'Status')),
                    React.createElement(TableCell, null, t('checklist.import.table.details', 'Details')))),
            React.createElement(TableBody, null, result.checklists.map(({ hasError, errors, list }) => {
                const resultId = `checklist-import-result-${list.id}`;
                return (React.createElement(TableRow, { key: list.id, "aria-errormessage": hasError ? resultId : undefined, "aria-invalid": hasError },
                    React.createElement(TableCell, null, list.name || React.createElement("em", null, t('checklist.import.noNameAlternative', 'No name'))),
                    React.createElement(TableCell, null,
                        React.createElement("code", null, list.key)),
                    React.createElement(TableCell, null, !hasError ? (React.createElement(CheckCircleRounded, { sx: { color: 'success.main' } })) : (React.createElement(HighlightOff, { sx: { color: 'error.main' } }))),
                    React.createElement(TableCell, { id: resultId }, hasError ? (errors.map((error) => (React.createElement(Typography, { variant: "body2", key: error }, errorMap[error])))) : (React.createElement("em", null, t('checklist.import.noErrors', 'Ready for import'))))));
            })))));
}
