import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React from 'react';
import { saveFileToDisk } from '@corti/browser-file';
import { useTranslation } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { config } from 'core/configuration/browser';
import { Card, IconButton, Tooltip } from 'lib/cortiUI';
import { AudioLoader } from './AudioLoader';
import { Waveform } from './Waveform';
export const Audio = ({ callID }) => {
    const authStore = useAuth();
    const { palette } = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'call.audio' });
    const [blobURL, setBlobURL] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    React.useEffect(() => {
        if (authStore.authToken) {
            void loadAudioFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.authToken, callID]);
    async function loadAudioFile() {
        const url = `${config.getConfig().apiHost}/api/v1.0/call-audio/${callID}`;
        setIsLoading(true);
        try {
            const res = await api.files.downloadFile(url);
            setBlobURL(URL.createObjectURL(res));
        }
        catch (error) {
            console.error('Error fetching or loading audio file:', error);
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('audioFileError', "Couldn't load the audio"),
            });
            setIsError(true);
        }
        finally {
            setIsLoading(false);
        }
    }
    async function downloadAudioFile() {
        const url = `${config.getConfig().apiHost}/api/v1.0/call-audio/${callID}`;
        const blob = await api.files.downloadFile(url);
        const ext = '.wav';
        saveFileToDisk(blob, callID + ext);
        trackerService.track('[Case View] audio file exported');
    }
    if (isError) {
        return null;
    }
    if (isLoading) {
        return React.createElement(AudioLoader, null);
    }
    return (React.createElement(Card, { p: 2, display: "grid", borderRadius: 100, gridTemplateColumns: "1fr auto", alignItems: "center", gap: 3, className: css({ color: palette.text.primary }) },
        React.createElement(Waveform, { blobURL: blobURL }),
        rbacService.casesCallAudioDownload() && (React.createElement(Tooltip, { title: t('downloadAudio', 'Download audio'), placement: "top" },
            React.createElement(IconButton, { size: "small", color: "inherit", onClick: downloadAudioFile, icon: React.createElement(FileDownloadRoundedIcon, { fontSize: "small" }) })))));
};
