import React from 'react';
import { TextField, Typography } from 'lib/cortiUI';
import { Item } from './Item';
export function LongTextField(props) {
    const [inputValue, setInputValue] = React.useState(props.value || '');
    React.useEffect(() => {
        setInputValue(props.value || '');
    }, [props.value]);
    if (props.isPreviewMode) {
        return (React.createElement(Item, null,
            React.createElement(Typography, null, props.value || '-')));
    }
    return (React.createElement(TextField, { "data-cy": "feedback-form-long-text", fullWidth: true, name: props.name, multiline: true, rows: 3, value: inputValue, onChange: (e) => setInputValue(e.target.value), onBlur: () => props.onChange(inputValue) }));
}
