var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import MUIRadio from '@mui/material/Radio';
import React from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
export * from '@mui/material/RadioGroup';
export { default as RadioGroup } from '@mui/material/RadioGroup';
/**
 * Simple Material UI Radio with overridden styles. They accept all Material UI props.
 * Component allows users to select one option from group
 */
export function Radio(_a) {
    var { classes, size = 'small', color = 'primary' } = _a, rest = __rest(_a, ["classes", "size", "color"]);
    const theme = useTheme();
    return (React.createElement(MUIRadio, Object.assign({ color: color, size: size, classes: {
            root: css({
                padding: 0,
            }),
            colorPrimary: css({
                color: theme.palette.text.secondary,
            }),
        }, className: css(getFocusCss({ theme })) }, rest)));
}
