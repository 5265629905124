import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useWavesurfer } from '@wavesurfer/react';
import React from 'react';
import { Formatters } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, IconButton, Typography } from 'lib/cortiUI';
import { useCallControls } from '../CallControlsContext';
export const Waveform = ({ blobURL }) => {
    const { palette } = useTheme();
    const { changeCurrentCallTime, currentCallTime, shouldUpdateAudioTime } = useCallControls();
    const containerRef = React.useRef(null);
    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
        container: containerRef,
        waveColor: palette.grey[300],
        progressColor: palette.text.primary,
        cursorWidth: 0,
        height: 40,
        barWidth: 3,
        barRadius: 3,
        url: blobURL,
    });
    React.useEffect(() => {
        const currentTimeSeconds = Math.floor(currentTime);
        if (currentTimeSeconds !== currentCallTime) {
            changeCurrentCallTime(Math.floor(currentTimeSeconds));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime]);
    React.useEffect(() => {
        if (shouldUpdateAudioTime) {
            wavesurfer === null || wavesurfer === void 0 ? void 0 : wavesurfer.setTime(currentCallTime);
        }
    }, [currentCallTime, wavesurfer, shouldUpdateAudioTime]);
    const onPlayPause = () => {
        wavesurfer && wavesurfer.playPause();
    };
    const durationMS = ((wavesurfer === null || wavesurfer === void 0 ? void 0 : wavesurfer.getDuration()) || 0) * 1000;
    const currentTimeMS = currentTime * 1000;
    return (React.createElement(Base, { pr: 4, display: "flex", alignItems: "center", width: "100%", gap: 4, className: css({ color: palette.text.primary }) },
        React.createElement(IconButton, { onClick: onPlayPause, icon: isPlaying ? React.createElement(PauseRoundedIcon, null) : React.createElement(PlayArrowRoundedIcon, null), color: "inherit" }),
        React.createElement(Typography, { className: css({ fontVariantNumeric: 'tabular-nums' }), variant: "caption" }, Formatters.msToDuration(currentTimeMS, { showHours: false })),
        React.createElement("div", { id: "waveform", style: { width: '100%' }, ref: containerRef }),
        React.createElement(Typography, { className: css({ fontVariantNumeric: 'tabular-nums' }), variant: "caption" }, Formatters.msToDuration(durationMS, { showHours: false }))));
};
