import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { archiveTemplate, deleteTemplate } from '@corti/mission-control-api';
import { css } from '@corti/style';
import { theme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Button, Dialog, Loading, MenuItem, Typography } from 'lib/cortiUI';
export const Delete = ({ templateID, published, onDelete }) => {
    const { t } = useTranslation('templateList', { keyPrefix: 'dataGrid.actions.delete' });
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleDelete() {
        setIsLoading(true);
        try {
            if (published) {
                await archiveTemplate(templateID);
            }
            else {
                await deleteTemplate(templateID);
            }
            onDelete();
            coreStore.notifications.showNotification({
                message: t('success', 'Template deleted'),
            });
        }
        catch (e) {
            coreStore.notifications.showNotification({
                message: t('error', 'Failed to delete'),
            });
            console.error(`Failed to delete the template - ${templateID}`);
        }
        finally {
            setIsLoading(false);
        }
    }
    function getDescription() {
        const mainText = t('dialog.mainDescription', 'This action cannot be undone.');
        const additionalText = t('dialog.additionalDescription', 'All assessment submissions where this template was used will remain available.');
        return published ? `${mainText} ${additionalText}` : mainText;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: () => setIsDialogOpen(true), gap: 3, className: css({
                '&:hover': {
                    backgroundColor: theme.palette.error.outlinedHoverBackground,
                    color: theme.palette.text.error,
                },
            }) }, isLoading ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
            React.createElement(DeleteRoundedIcon, { color: "error" }),
            React.createElement(Typography, { variant: "body1" }, t('label', 'Delete'))))),
        React.createElement(Dialog, { open: isDialogOpen, onClose: () => setIsDialogOpen(false), title: t('dialog.title', 'Permanently delete this template?'), helperText: getDescription(), actionButtons: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "text", onClick: () => setIsDialogOpen(false) }, t('dialog.keep', 'Keep')),
                React.createElement(Button, { variant: "contained", color: "error", onClick: handleDelete }, t('dialog.delete', 'Delete'))) })));
};
