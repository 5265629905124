var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { uuid } from '@corti/uuid';
export class CustomProperties {
    constructor() {
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "addProperty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input = {}) => {
                var _a, _b;
                const attr = new CustomProperty({ key: (_a = input.key) !== null && _a !== void 0 ? _a : '', value: (_b = input.value) !== null && _b !== void 0 ? _b : '' });
                this.items.push(attr);
                return attr;
            }
        });
        Object.defineProperty(this, "removeProperty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const idx = this.items.findIndex((a) => a.id === id);
                if (idx !== -1) {
                    this.items.splice(idx, 1);
                }
            }
        });
        Object.defineProperty(this, "getEncodableData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.items.filter((it) => !it.isEmpty()).map((it) => it.getEncodableData());
            }
        });
        makeObservable(this);
        runInAction(() => {
            this.items = [];
        });
    }
    /** Removes all empty custom properties */
    removeAllEmpty() {
        this.items = this.items.filter((it) => !it.isEmpty());
    }
    getPropertiesByKey(key) {
        return this.items.filter((it) => it.key === key);
    }
    deserialize(data) {
        data.map((it) => {
            const attr = this.addProperty();
            attr.deserialize(it);
        });
    }
    copy() {
        const i = new CustomProperties();
        i.deserialize(this.getEncodableData());
        return i;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], CustomProperties.prototype, "items", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CustomProperties.prototype, "addProperty", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CustomProperties.prototype, "removeProperty", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CustomProperties.prototype, "removeAllEmpty", null);
class CustomProperty {
    constructor(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => {
                this.key = key;
            }
        });
        Object.defineProperty(this, "setValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.value = value;
            }
        });
        Object.defineProperty(this, "getEncodableData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return {
                    key: this.key,
                    value: this.value,
                };
            }
        });
        makeObservable(this);
        this.id = uuid();
        runInAction(() => {
            var _a, _b;
            this.key = (_a = data.key) !== null && _a !== void 0 ? _a : '';
            this.value = (_b = data.value) !== null && _b !== void 0 ? _b : '';
        });
    }
    isEmpty() {
        return this.hasEmptyKey() && this.hasEmptyValue();
    }
    hasEmptyKey() {
        return this.key.trim() === '';
    }
    hasEmptyValue() {
        return this.value.trim() === '';
    }
    deserialize(input) {
        this.key = input.key;
        this.value = input.value;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], CustomProperty.prototype, "key", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], CustomProperty.prototype, "value", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CustomProperty.prototype, "setKey", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CustomProperty.prototype, "setValue", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], CustomProperty.prototype, "deserialize", null);
