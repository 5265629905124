import { observer } from 'mobx-react';
import React from 'react';
import { asyncMountedComponent } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { ExpressionInputConnected } from 'lib/graphEditor/expressions/ExpressionInputConnected';
import { DefaultPortWidget } from '../common/DefaultPortWidget';
export const LogicGateContainerWidget = observer(function LogicGateContainerWidget(props) {
    const { node } = props;
    return (React.createElement(Base, { display: 'flex', flexDirection: "column", gap: 4 }, node.logicGates.map((gate) => {
        return React.createElement(LogicGateWidget, { key: gate.id, logicGate: gate });
    })));
});
const AsyncExpressionInput = asyncMountedComponent(ExpressionInputConnected);
const LogicGateWidget = observer(function LogicGateWidget(props) {
    const { logicGate } = props;
    const theme = useTheme();
    return (React.createElement(Base, { "data-cy": "logic-gate-option", display: "flex", alignItems: 'center' },
        React.createElement(Base, { flex: 1, className: css({
                padding: 4,
                borderRadius: 6,
                background: theme.palette.background.secondary,
                border: `1px solid ${theme.palette.background.divider}`,
                position: 'relative',
                paddingRight: 24,
                height: 29,
                overflow: 'hidden',
            }) },
            React.createElement(AsyncExpressionInput, { readOnly: true, disableSyntaxHighlighting: true, singleLine: true, context: { node: logicGate.parent }, value: logicGate.expression }),
            React.createElement("div", { className: css({
                    position: 'absolute',
                    right: 4,
                    top: '50%',
                    transform: 'translateY(-50%)',
                }) })),
        React.createElement(DefaultPortWidget, { model: logicGate.port })));
});
