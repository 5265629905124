import React from 'react';
import { ChecklistPanel } from '@corti/checklists';
import { Copilot } from '@corti/corti-ui';
import { Box, Tab, Tabs } from 'lib/cortiUI';
import { ChecklistEditable } from './ChecklistEditable';
import { useChecklistEdit } from './useChecklistEdit';
export function ChecklistTabs() {
    const checklist = useChecklistEdit((state) => state.checklist);
    const mode = useChecklistEdit((state) => state.mode);
    const setMode = useChecklistEdit((state) => state.setMode);
    const snapshot = {
        expandedChecklistsById: { [checklist.id]: true },
        manualChecksById: {},
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "center", borderBottom: "1px solid #bbb" },
            React.createElement(Tabs, { onChange: (_evt, value) => setMode(value), value: mode },
                React.createElement(Tab, { label: "Edit", value: "edit" }),
                React.createElement(Tab, { label: "Preview", value: "preview" }))),
        React.createElement(Box, { px: 32, pt: 48, pb: 32, overflow: "hidden" },
            React.createElement(Copilot, { sx: { height: '100%', overflow: 'auto' } }, mode === 'edit' ? (React.createElement(ChecklistEditable, null)) : (React.createElement(ChecklistPanel, { allChecklists: [checklist], currentChecklistIds: [checklist.id], snapshot: snapshot, autoCheckedEntryIds: [], listener: () => undefined }))))));
}
