import { Editor, Element as SlateElement, Text as SlateText, Transforms, } from 'slate';
const LIST_TYPES = ['ol', 'ul'];
export function toggleBold(editor) {
    toggleFormat(editor, 'bold');
}
export function toggleItalic(editor) {
    toggleFormat(editor, 'italic');
}
export function toggleUnderline(editor) {
    toggleFormat(editor, 'underline');
}
export function toggleStrikethrough(editor) {
    toggleFormat(editor, 'strikethrough');
}
export function toggleMetaText(editor) {
    toggleFormat(editor, 'meta');
}
export function toggleHeading1(editor) {
    toggleBlockType(editor, 'h1');
}
export function toggleHeading2(editor) {
    toggleBlockType(editor, 'h2');
}
export function toggleBulletList(editor) {
    toggleBlockType(editor, 'ul');
}
export function toggleNumberedList(editor) {
    toggleBlockType(editor, 'ol');
}
export function insertNote(editor, note) {
    const isActive = isBlockActive(editor, 'hasnote');
    if (isActive) {
        return removeNote(editor);
    }
    const hasnoteBlock = {
        type: 'hasnote',
        attributes: {
            note,
        },
        children: [],
    };
    Transforms.wrapNodes(editor, hasnoteBlock, { split: true });
}
export function removeNote(editor, options = {}) {
    Transforms.unwrapNodes(editor, Object.assign({ match: (n) => SlateElement.isElement(n) && n.type === 'hasnote' }, options));
}
export function insertLink(editor, url) {
    const linkElement = {
        type: 'link',
        attributes: {
            url,
        },
        children: [],
    };
    Transforms.wrapNodes(editor, linkElement, { split: true });
}
export function removeLink(editor) {
    Transforms.unwrapNodes(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === 'link',
    });
}
export function insertExpression(editor, expression) {
    const isActive = isBlockActive(editor, 'hasexpression');
    if (isActive) {
        return removeExpression(editor);
    }
    const hasexpressionBlock = {
        type: 'hasexpression',
        attributes: {
            expression,
        },
        children: [],
    };
    Transforms.wrapNodes(editor, hasexpressionBlock, { split: true });
}
export function removeExpression(editor, options = {}) {
    Transforms.unwrapNodes(editor, Object.assign({ match: (n) => SlateElement.isElement(n) && n.type === 'hasexpression' }, options));
}
const toggleFormat = (editor, format) => {
    var _a;
    const currentmarks = Object.assign({}, (_a = Editor.marks(editor)) === null || _a === void 0 ? void 0 : _a.attributes);
    if (currentmarks[format]) {
        delete currentmarks[format];
    }
    else {
        currentmarks[format] = true;
    }
    Transforms.setNodes(editor, {
        attributes: currentmarks,
    }, {
        match: SlateText.isText,
        split: true,
    });
};
const toggleBlockType = (editor, type) => {
    const isActive = isBlockActive(editor, type);
    const isList = LIST_TYPES.includes(type);
    Transforms.unwrapNodes(editor, {
        match: (n) => {
            // @ts-ignore
            return LIST_TYPES.includes(n.type);
        },
        split: true,
    });
    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'li' : type,
    });
    if (!isActive && isList) {
        const block = { type, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};
export const setFillColor = (editor, fill) => {
    Transforms.setNodes(editor, {
        attributes: { backgroundColor: fill },
    }, {
        match: SlateText.isText,
        split: true,
        merge: (a, b) => (Object.assign(Object.assign({}, a), b)),
    });
};
export const getFillColor = (editor) => {
    var _a, _b;
    const marks = Editor.marks(editor);
    return (_b = (_a = marks === null || marks === void 0 ? void 0 : marks.attributes) === null || _a === void 0 ? void 0 : _a.backgroundColor) !== null && _b !== void 0 ? _b : '';
};
export const isBlockActive = (editor, type) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === type,
    });
    return !!match;
};
export const isMarkActive = (editor, format) => {
    var _a;
    const marks = Editor.marks(editor);
    return marks ? ((_a = marks.attributes) === null || _a === void 0 ? void 0 : _a[format]) === true : false;
};
