/**
 * Not exhaustive list of timeline definition source types
 */
export var TimelineEntryDefinitionSourceType;
(function (TimelineEntryDefinitionSourceType) {
    TimelineEntryDefinitionSourceType["callAnnotation"] = "call-annotation";
    TimelineEntryDefinitionSourceType["customEvent"] = "custom-event";
    TimelineEntryDefinitionSourceType["default"] = "default-definition";
    TimelineEntryDefinitionSourceType["detection"] = "detection";
    TimelineEntryDefinitionSourceType["insightsCallEvent"] = "insights-call-event";
    TimelineEntryDefinitionSourceType["searchTerms"] = "search-term";
    TimelineEntryDefinitionSourceType["triageEvent"] = "triage-event";
    TimelineEntryDefinitionSourceType["triageFlowEvent"] = "triage-flow-event";
})(TimelineEntryDefinitionSourceType || (TimelineEntryDefinitionSourceType = {}));
