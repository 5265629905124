import { isEqual } from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { slugify } from '@corti/strings';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Autocomplete, Box, Button, MenuItem, Popper, Text, TextField, Typography, } from 'lib/cortiUI';
export function AddUpdateUserRoleForm(props) {
    const { userRole, onSubmit } = props;
    const theme = useTheme();
    const validationMessages = {
        titleExceedsLength: {
            text: intl.t('team:userRole.validationMessages.titleExceedsLength', 'Title cannot be longer than {{ numOfChars }} characters', { numOfChars: 64 }),
        },
        invalidRoleName: {
            text: intl.t('team:userRole.validationMessages.titleInvalidSymbols', "Title must contain English letters, digits and symbols ('_' or '-') only"),
        },
    };
    const [title, setTitle] = React.useState((userRole && userRole.name) || '');
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = React.useState(false);
    const [selectedPermissionIDs, setSelectedPermissionIDs] = React.useState(userRole ? userRole.permissions.map((p) => p.id) : []);
    React.useEffect(() => {
        repositories.roles.loadPremissions();
    }, []);
    const convertToSelectorItems = (items) => {
        return items.map((item) => ({ value: item.id, text: item.name }));
    };
    const handleTitleValueChange = (e) => {
        setTitle(e.target.value);
    };
    const validateTitle = () => {
        const result = [];
        if (title.length > 64) {
            result.push(validationMessages.titleExceedsLength.text);
        }
        const slugifiedTitle = slugify(title);
        if (title.toLowerCase() !== slugifiedTitle.toLowerCase()) {
            result.push(validationMessages.invalidRoleName.text);
        }
        return {
            status: result.length > 0 ? false : true,
            errors: result,
        };
    };
    const isFormValid = () => {
        return !!title && validateTitle().status;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let res;
        setIsLoadingOnSubmit(true);
        if (userRole) {
            res = await repositories.roles.updateRole(userRole.id, {
                name: title || '',
                permissionIDs: selectedPermissionIDs,
            });
        }
        else {
            res = await repositories.roles.createRole({
                name: title || '',
                permissionIDs: selectedPermissionIDs,
            });
        }
        setIsLoadingOnSubmit(true);
        !res.error && onSubmit();
    };
    return (React.createElement(Observer, null, () => {
        const permissionsItemList = convertToSelectorItems(repositories.roles.permissions);
        const selectedPermissionsItemList = selectedPermissionIDs
            .map((id) => permissionsItemList.find((i) => i.value === id))
            .filter((item) => !!item);
        return (React.createElement("form", { "data-cy": "add-update-user-role-form", onSubmit: handleFormSubmit, className: css({ display: 'flex', flexDirection: 'column', gap: theme.space[6] }) },
            React.createElement(Box, null,
                React.createElement(TextField, { label: intl.t('team:userRole.addUpdateForm.titleLabel', 'Title'), name: "title", value: title, onChange: handleTitleValueChange, fullWidth: true, helperText: !validateTitle().status
                        ? validateTitle().errors.map((error) => (React.createElement(Box, null,
                            React.createElement(Typography, { color: "error", variant: "caption" }, error))))
                        : null })),
            React.createElement(Autocomplete, { "data-cy": "multiple-select-permissions", size: "small", multiple: true, disableCloseOnSelect: true, PopperComponent: PopperWrapper, options: permissionsItemList, getOptionLabel: (option) => option.text, value: selectedPermissionsItemList, isOptionEqualToValue: (option, value) => isEqual(option, value), onChange: (_, value) => {
                    setSelectedPermissionIDs(value.map((v) => v.value));
                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('team:userRole.addUpdateForm.permissionsSelectorLabel', 'Permissions'), placeholder: intl.t('team:userRole.addUpdateForm.permissionsSelectorPlaceholder', 'Select permissions') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }),
                    React.createElement(Text, { noWrap: true }, option.text))), ChipProps: Object.assign({ 'data-cy': 'chip' }, { size: 'small' }) }),
            React.createElement(Button, { className: css({ alignSelf: 'flex-end' }), disabled: isLoadingOnSubmit || !isFormValid(), variant: "text", type: "submit", color: "primary" }, intl.t('save', 'Save'))));
    }));
}
const PopperWrapper = React.memo((params) => (React.createElement(Popper, Object.assign({}, params, { style: { maxHeight: '240px' }, placement: "top-end" }))));
