import React from 'react';
import { Base, Container, Page, Skeleton } from 'lib/cortiUI';
import { CallsDataGridLoader } from '../containers/CallsDataGrid';
import { ComplianceTrackersLoader } from '../containers/ComplianceTrackers/ComplianceTrackersLoader';
import { GeneralPerformancePanelLoader } from '../containers/GeneralPerformancePanel';
import { ChartLoader } from '../containers/PerformanceChart';
export const ViewLoader = () => {
    return (React.createElement(Page, { p: 7, alignItems: "center" },
        React.createElement(Container, null,
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7, mb: 9 },
                React.createElement(Skeleton, { variant: "text", width: "20%" }),
                React.createElement(Base, { display: "flex", gap: 6 },
                    React.createElement(Base, { display: "flex", alignItems: "center", gap: 5 },
                        React.createElement(Skeleton, { variant: "circular", width: 25, height: 25 }),
                        React.createElement(Skeleton, { variant: "rectangular", width: 180, height: 25, sx: { borderRadius: '4px' } })),
                    React.createElement(Base, { display: "flex", alignItems: "center", gap: 5 },
                        React.createElement(Skeleton, { variant: "circular", width: 25, height: 25 }),
                        React.createElement(Skeleton, { variant: "rectangular", width: 100, height: 25, sx: { borderRadius: '4px' } })))),
            React.createElement(Base, { display: "grid", gridTemplateRows: "auto", gap: 9 },
                React.createElement(Base, { display: "flex", gap: 5 },
                    React.createElement(Base, { flex: 1 },
                        React.createElement(GeneralPerformancePanelLoader, null)),
                    React.createElement(Base, { flex: 2 },
                        React.createElement(ChartLoader, null))),
                React.createElement(ComplianceTrackersLoader, null),
                React.createElement(CallsDataGridLoader, null)))));
};
