import { decode } from 'base64-arraybuffer';
import pako from 'pako';
/**
 * @param compressed - gzipped and base64 encodded string representation of the JSON data
 * @returns javascript object parsed from JSON
 */
function fromCompressedBase64JSON(compressed) {
    return JSON.parse(pako.inflate(new Uint8Array(decode(compressed)), { to: 'string' }));
}
export const Decoder = {
    fromCompressedBase64JSON,
};
