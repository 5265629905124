export function initApi(publicApi, authStore) {
    publicApi.exposeMethod({
        name: '/app/getCurrentUserToken',
        handler: () => {
            if (!authStore.isLoaded) {
                return {
                    userToken: undefined,
                };
            }
            return {
                userToken: authStore.authToken,
            };
        },
    });
    publicApi.exposeMethod({
        name: '/app/logout',
        handler: () => {
            authStore.logout();
        },
    });
    publicApi.exposeMethod({
        name: '/app/signInWithToken',
        paramsJSONSchema: {
            type: 'object',
            required: ['token'],
            properties: {
                token: { type: 'string' },
                options: {
                    type: 'object',
                    nullable: true,
                    properties: {
                        rememberUser: { type: 'boolean', nullable: true },
                    },
                },
            },
        },
        handler: async ({ token, options }) => {
            await authStore.signInWithToken(token, options);
        },
    });
    publicApi.exposeMethod({
        name: '/app/getCurrentUser',
        handler: () => {
            if (!authStore.isLoaded) {
                return undefined;
            }
            return {
                id: authStore.currentUser.id,
                name: authStore.currentUser.name,
                organizationID: authStore.currentUser.organization.id,
                extension: authStore.currentUser.extension,
                externalID: authStore.currentUser.externalID,
            };
        },
    });
    authStore.onLoaded(() => {
        publicApi.fireEvent({
            name: 'app.login',
            data: undefined,
        });
    });
    authStore.onLogout(() => {
        publicApi.fireEvent({
            name: 'app.logout',
            data: undefined,
        });
    });
}
