import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, darken, mix, readableColor } from '@corti/style';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, ButtonBase, CircularProgress, Typography } from 'lib/cortiUI';
import { getFocusWithinCss } from '../utils';
export const ActionBlock = observer(function ActionBlock(props) {
    const theme = useTheme();
    const { text, onClick, isPending = false, isActivated = false, isInvisible = false, isReadonly = false, innerRef, } = props;
    const buttonRef = React.useRef(null);
    const backgroundColor = props.backgroundColor
        ? props.backgroundColor
        : theme.palette.primary.main;
    React.useEffect(() => {
        if (buttonRef.current && innerRef) {
            innerRef(buttonRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleClick() {
        if (!isReadonly && !isPending) {
            coreStore.notifications.showNotification({
                type: 'success',
                message: intl.t('realtime:actionBlockSelected', "'{{actionTitle}}' was selected", {
                    actionTitle: text,
                    interpolation: { escapeValue: false },
                }),
            });
            onClick();
        }
    }
    if (isInvisible) {
        return null;
    }
    function getHoverCss() {
        return css({
            '&:hover': {
                backgroundColor: mix(0.3, theme.palette.common.black, backgroundColor),
            },
        });
    }
    return (React.createElement(ButtonBase, { ref: buttonRef, onClick: handleClick, tabIndex: isReadonly ? -1 : 0, "data-cy": 'flow:action-block', className: css({
            cursor: isReadonly ? 'default' : 'pointer',
            minHeight: 42,
            borderRadius: Number(theme.shape.borderRadius),
            paddingLeft: theme.space[6],
            paddingRight: theme.space[6],
            paddingTop: theme.space[4],
            paddingBottom: theme.space[4],
            fontSize: theme.typography.fontSize,
            color: readableColor(darken(0.2, backgroundColor)),
            backgroundColor: backgroundColor,
        }, isReadonly ? undefined : getHoverCss(), isReadonly ? undefined : getFocusWithinCss({ theme })) },
        React.createElement(Base, { display: "grid", alignItems: "center", gridTemplateColumns: '50px auto 50px', gridTemplateAreas: '"icon text empty"' },
            React.createElement(Base, { gridArea: "icon", display: "flex", justifyContent: "flex-end" },
                isPending && (React.createElement(CircularProgress, { "data-cy": "flow:action-block-spinner", color: "inherit", size: 25, mr: 5 })),
                isActivated && (React.createElement(CheckRoundedIcon, { fontSize: "medium", color: "inherit", className: css({ marginRight: 20 }) }))),
            React.createElement(Typography, { variant: "body2", gridArea: "text", color: "inherit" }, text))));
});
