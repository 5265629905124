import { assign, createMachine, pure, sendTo, spawn } from 'xstate';
import { startChecklistMachine } from './checklist';
const INITIAL_COLLECTION_CONTEXT = {
    checklists: [],
    firstUnfinishedIndex: -1,
};
const collectionMachine = createMachine({
    tsTypes: {},
    schema: {
        context: {},
        events: {},
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'Collection',
    initial: 'initializing',
    context: INITIAL_COLLECTION_CONTEXT,
    states: {
        initializing: {
            always: 'active',
            entry: ['initialize', 'syncFinished'],
        },
        active: {
            on: {
                FINISHED: {
                    actions: ['sendNotFirst', 'handleFinish', 'syncFinished', 'sendFirst'],
                },
                UNFINISHED: {
                    actions: ['sendNotFirst', 'handleFinish', 'syncFinished', 'sendFirst'],
                },
                REMOVE: {
                    actions: ['remove', 'syncFinished', 'sendFirst'],
                },
                ADD: {
                    actions: ['add', 'syncFinished', 'sendFirst'],
                },
                AUTOCHECK: {
                    actions: ['sendAutoCheck'],
                },
            },
        },
    },
}, {
    actions: {
        // Mark the recently altered checklist as finished/unfinished
        handleFinish: assign((context, { type, id }) => {
            return {
                checklists: context.checklists.map((list) => list.id === id ? Object.assign(Object.assign({}, list), { completed: type === 'FINISHED' }) : list),
            };
        }),
        // Update the first unfinished index - note it can be -1 if all lists are finished
        syncFinished: assign((context) => ({
            firstUnfinishedIndex: context.checklists.findIndex(({ completed }) => !completed),
        })),
        sendNotFirst: sendToUnfinishedIfExists('NOTFIRST'),
        sendFirst: sendToUnfinishedIfExists('FIRST'),
        // Remove a checklist from the collection
        remove: assign((context, { id }) => {
            const checklists = context.checklists.filter((list) => list.id !== id);
            return { checklists };
        }),
        // Add a checklist to the collection by spawning a new checklist machine
        add: assign((context, { checklist, snapshot }) => {
            const canBeFirst = context.firstUnfinishedIndex === -1;
            const [isCompleted, machine] = startChecklistMachine(checklist, canBeFirst, snapshot.expandedChecklistsById[checklist.id], snapshot);
            const ref = spawnMachine(machine, checklist);
            const checklists = [
                ...context.checklists,
                {
                    id: checklist.id,
                    entries: new Set(checklist.entries.map(({ id }) => id)),
                    completed: isCompleted,
                    ref,
                },
            ];
            return { checklists };
        }),
        // Send an event to a checklist machine to update the auto-checked state of an entry
        sendAutoCheck: pure((context, { id, checked }) => {
            var _a;
            const ref = (_a = context.checklists.find(({ entries }) => entries.has(id))) === null || _a === void 0 ? void 0 : _a.ref;
            if (ref) {
                return sendTo(ref, { type: 'AUTOCHECK', id, checked });
            }
        }),
    },
});
function spawnMachine(machine, checklist) {
    const ref = spawn(machine, { name: checklist.key });
    return ref;
}
function sendToUnfinishedIfExists(type) {
    // The annotation is needed to make the typechecker happy
    return pure((context) => {
        const ref = getFirstUnfinished(context);
        if (ref) {
            return sendTo(ref, { type });
        }
    });
}
export function startCollectionMachine(checklists, snapshot) {
    const machine = collectionMachine.withConfig({
        actions: {
            initialize: assign(() => {
                // This will remain true until a checklist actually claims to be first
                // this claim is checked from the startChecklistMachine response
                let nextIsFirst = true;
                const statefulChecklists = checklists.map((checklist) => {
                    const [isCompleted, machine] = startChecklistMachine(checklist, nextIsFirst, snapshot.expandedChecklistsById[checklist.id], snapshot);
                    nextIsFirst = nextIsFirst && isCompleted;
                    const ref = spawnMachine(machine, checklist);
                    return {
                        id: checklist.id,
                        entries: new Set(checklist.entries.map(({ id }) => id)),
                        completed: isCompleted,
                        ref,
                    };
                });
                return {
                    checklists: statefulChecklists,
                };
            }),
        },
    });
    return machine;
}
export function isCollectionEvent(event) {
    return ('type' in event &&
        typeof event.type === 'string' &&
        [
            'FINISHED',
            'UNFINISHED',
            'REMOVE',
            'ADD',
            'AUTOCHECK',
            'AUTOCOLLAPSED',
            'AUTOEXPANDED',
            'xstate.init',
        ].includes(event.type));
}
function getFirstUnfinished({ firstUnfinishedIndex, checklists }) {
    return firstUnfinishedIndex === -1 ? null : checklists[firstUnfinishedIndex].ref;
}
