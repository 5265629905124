var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CoreApiError, api } from '@corti/lib/coreApiService';
import { uuid } from '@corti/uuid';
export class UploadRequestModel {
    constructor(file, id) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "file", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "metadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "response", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isUploading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initialize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                try {
                    this.isUploading = true;
                    const res = await api.files.uploadCaseAudioFile(this.file, this.metadata);
                    runInAction(() => {
                        this.response = {
                            caseID: res.case_id,
                        };
                    });
                }
                catch (err) {
                    runInAction(() => {
                        if (err instanceof CoreApiError) {
                            this.error = err;
                        }
                    });
                }
                finally {
                    runInAction(() => {
                        this.isUploading = false;
                    });
                }
            }
        });
        Object.defineProperty(this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.response = undefined;
                this.error = undefined;
                this.isUploading = false;
            }
        });
        Object.defineProperty(this, "eraseMetadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.metadata = {};
            }
        });
        Object.defineProperty(this, "assignMetadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (metadata) => {
                this.metadata = metadata;
            }
        });
        makeObservable(this);
        this.id = (id === null || id === void 0 ? void 0 : id.toString()) || uuid();
        this.file = file;
        runInAction(() => {
            this.metadata = {
                caseID: undefined,
                timeStamp: undefined,
                protocolName: undefined,
            };
            this.response = undefined;
            this.error = undefined;
            this.isUploading = false;
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "metadata", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "response", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof CoreApiError !== "undefined" && CoreApiError) === "function" ? _a : Object)
], UploadRequestModel.prototype, "error", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], UploadRequestModel.prototype, "isUploading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "initialize", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "reset", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "eraseMetadata", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UploadRequestModel.prototype, "assignMetadata", void 0);
