import { uuid } from '@corti/uuid';
export function parseMessage(event) {
    if (typeof event.event !== 'string') {
        throw new Error('message must have `event` attribute of type string');
    }
    if ('correlationID' in event) {
        if (typeof event.correlationID !== 'string') {
            throw new Error('correlationID must be a string type');
        }
    }
    if ('stream' in event) {
        if (typeof event.stream.name !== 'string') {
            throw new Error('stream must have a name property of type string');
        }
    }
    return event;
}
export function createMessageEvent(input) {
    const e = {
        correlationID: uuid(),
        event: input.event,
    };
    if (input.data) {
        e.data = input.data;
    }
    if (input.stream) {
        e.stream = input.stream;
    }
    return e;
}
