import * as Sentry from '@sentry/browser';
import { callMain } from '@corti/ipc-renderer';
import { config } from 'core/configuration/browser';
import { getOptions, preventReportingOnce } from 'core/sentry/shared/sentryOptions';
export class ErrorReportService {
    async init() {
        let appPath = undefined;
        try {
            appPath = await callMain('app:getAppPath');
            appPath = appPath === null || appPath === void 0 ? void 0 : appPath.replace(/\\/g, '/');
        }
        catch (_a) {
            // An error is thrown if we are not in Electron.
            // For Browser App we will use 'undefined' for
            // appPath
        }
        const commonOptions = getOptions({
            appPath,
            sentryKey: config.getConfig()['externalServices:sentry:dsn'],
            apiHost: config.getConfig().apiHost,
        });
        if (commonOptions.enabled) {
            Sentry.init(commonOptions);
        }
    }
    setUserContext(user) {
        Sentry.configureScope((scope) => {
            scope.setUser(Object.assign({}, user));
        });
    }
    clearUserContext() {
        Sentry.configureScope((scope) => {
            scope.clear();
        });
    }
    captureException(error, options = {}) {
        Sentry.withScope((scope) => {
            if (options.extra) {
                scope.setExtra('extra', options.extra);
            }
            const id = Sentry.captureException(error);
            this.preventReportingOnce(error, id);
        });
    }
    captureMessage(text, options = {}) {
        Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.fromString(options.severity || 'info'));
            if (options.extra) {
                scope.setExtra('extra', options.extra);
            }
            Sentry.captureMessage(text);
        });
    }
    /**
     * Will prevent sentry's global error handler from reporting this particular error instance
     * It's useful when reporting error manually and do not want that same error
     * also being reported by the global handler
     */
    preventReportingOnce(error, eventID) {
        preventReportingOnce(error, eventID);
    }
}
