import React from 'react';
import { css } from '@corti/style';
import { useAuth } from 'core/auth/browser';
import { Base, Container, Page } from 'lib/cortiUI';
import { CaseReviewRequestsContext, } from './CaseReviewRequestsContext';
import { CaseReviewRequestsModel } from './CaseReviewRequestsModel';
import { CaseReviewRequestsTable } from './CaseReviewRequestsTable';
import { Header } from './Header';
import { ParametersPanel } from './ParametersPanel';
export function CaseReviewRequestsView(props) {
    const { organization } = useAuth();
    const [context] = React.useState(() => {
        return {
            model: new CaseReviewRequestsModel({
                api: props.dependencies.api.caseFeedback,
                organizationID: organization.id,
            }),
            dependencies: props.dependencies,
        };
    });
    return (React.createElement(CaseReviewRequestsContext.Provider, { value: context },
        React.createElement(Page, { "data-cy": "request-queue-view" },
            React.createElement(Container, { className: css({
                    height: '100%',
                }) },
                React.createElement(Base, { height: "100%", display: "grid", gridTemplateRows: "auto 1fr", gap: 4 },
                    React.createElement(Header, null),
                    React.createElement(Base, { height: "100%", display: "grid", gridTemplateRows: "auto 1fr", gap: 2 },
                        React.createElement(ParametersPanel, null),
                        React.createElement(CaseReviewRequestsTable, null)))))));
}
