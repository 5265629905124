import { useQuery } from '@apollo/client';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { Base } from 'lib/cortiUI';
import { OrganizationSettingsView } from './OrganizationSettingsView';
import { AdminPanelSidebar } from './components/AdminPanelSidebar';
import { QUERIES } from './graphql';
export const AdminView = () => {
    const [activeOrganizationID, setActiveOrganizationID] = React.useState(null);
    const [organizations, setOrganizations] = React.useState([]);
    const authState = useAuth();
    const orgID = authState.organization.id;
    const handleSetActiveOrganizationID = (organizationId) => {
        setActiveOrganizationID(organizationId);
    };
    const handleSetOrganizations = (data) => {
        if (data.organization) {
            const { organization } = data;
            setOrganizations([organization]);
            if (activeOrganizationID === null) {
                handleSetActiveOrganizationID(organization.id);
            }
        }
    };
    useQuery(QUERIES.GET_ORGANIZATION_NAME, {
        onCompleted: handleSetOrganizations,
        variables: {
            orgID,
        },
        fetchPolicy: 'cache-and-network',
    });
    return (React.createElement(Base, { display: "flex", flexDirection: "column", flex: 1, overflow: "hidden" },
        React.createElement(Base, { display: "flex", flexDirection: "row", height: "100%" },
            React.createElement(AdminPanelSidebar, { organizations: organizations, onOrganizationClick: handleSetActiveOrganizationID, activeOrganizationID: activeOrganizationID }),
            activeOrganizationID && React.createElement(OrganizationSettingsView, { organizationID: activeOrganizationID }))));
};
