import React from 'react';
import { useTranslation } from '@corti/i18n';
import { requestInteractionAcknowledgement, submitReview } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { Button } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
import { useReview, useValidation } from '../context';
export const Submit = ({ onSubmit, setIsSubmitting, isSubmitting, isRequestAcknowledgement, }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.footer.submit' });
    const { reviewID, templateID } = useReview();
    const { interaction, refetchInteraction } = useInteraction();
    const { notAnsweredRequiredQuestions, loadingAnswers } = useValidation();
    async function handleAcknowledgementRequest() {
        if ((interaction === null || interaction === void 0 ? void 0 : interaction.id) && isRequestAcknowledgement) {
            try {
                await requestInteractionAcknowledgement(interaction === null || interaction === void 0 ? void 0 : interaction.id, 'REVIEW_SERVICE');
                refetchInteraction();
                trackerService.track('[Acknowledgement Status] acknowledgment requested', {
                    interactionID: interaction.id,
                    status: 'REVIEW_SERVICE',
                });
            }
            catch (error) {
                coreStore.notifications.showNotification({
                    message: t('acknowledgementRequestError', 'Failed to request acknowledgment'),
                    type: 'error',
                });
                setIsSubmitting(false);
                throw error;
            }
        }
    }
    async function handleSubmit() {
        if (reviewID) {
            setIsSubmitting(true);
            await handleAcknowledgementRequest();
            try {
                await submitReview(reviewID);
                coreStore.notifications.showNotification({
                    message: t('success', 'Assessment submitted'),
                });
                trackerService.track('[Manual Assessment] Review submitted', {
                    interactionID: interaction === null || interaction === void 0 ? void 0 : interaction.id,
                    reviewID: reviewID,
                    templateID: templateID,
                });
                onSubmit(reviewID);
            }
            catch (error) {
                console.error(error);
                coreStore.notifications.showNotification({
                    message: t('error', 'Failed to submit'),
                    type: 'error',
                });
            }
            finally {
                setIsSubmitting(false);
            }
        }
    }
    return (React.createElement(Button, { color: "primary", onClick: handleSubmit, disabled: isSubmitting || !!notAnsweredRequiredQuestions.length || !!loadingAnswers.length, size: "small" }, t('btnLabel', 'Submit')));
};
