import React from 'react';
import { intl } from '@corti/i18n';
// this is the only thing that cannot be done with a functional component, so this single class
// component is allowed to exist!
// eslint-disable-next-line rulesdir/react-class-component
export class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                error: undefined,
                info: undefined,
            }
        });
        Object.defineProperty(this, "renderFallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.fallbackComponent) {
                    return this.props.fallbackComponent(this.state.error, this.state.info);
                }
                else {
                    return React.createElement("p", null, intl.t('errorBoundary.msg', 'Something unexpected happened. Try to refresh'));
                }
            }
        });
    }
    componentDidCatch(error, info) {
        this.props.onError && this.props.onError(error, info);
        this.setState({ error, info });
        console.error({ error, info });
    }
    render() {
        if (this.state.error) {
            return this.renderFallback();
        }
        else {
            return this.props.children;
        }
    }
}
