var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ListItem, ListItemText } from 'lib/cortiUI';
export const PinnedStepListItem = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { onClick, selected, text } = props, rest = __rest(props, ["onClick", "selected", "text"]);
    return (React.createElement(ListItem, Object.assign({}, rest, { ref: ref, onClick: onClick, classes: {
            root: css({
                cursor: 'pointer',
                backgroundColor: selected
                    ? theme.palette.primary.outlinedHoverBackground
                    : 'transparent',
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
            }, getFocusCss({ theme })),
        } }),
        React.createElement(ListItemText, { classes: {
                primary: css({
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.fontSize,
                }),
            } }, text)));
});
