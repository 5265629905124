var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Observer } from 'mobx-react';
import React from 'react';
import { formatTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { DEFAULT_DEFINITION_COLOR } from '@corti/lib/coreApiService';
import { Formatters } from '@corti/strings';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Card, Tooltip, Typography } from 'lib/cortiUI';
export function CaseTimelineEntry(props) {
    const { entry, className, baseDatetime } = props, rest = __rest(props, ["entry", "className", "baseDatetime"]);
    const theme = useTheme();
    return (React.createElement(Observer, null, () => {
        var _a, _b, _c, _d;
        const offsetPosition = entry.getOffsetPosition(baseDatetime);
        const formattedTimeStart = Formatters.msToDuration(offsetPosition.start, {
            showHours: false,
        });
        const formattedTimeEnd = offsetPosition.end
            ? Formatters.msToDuration(offsetPosition.end, { showHours: false })
            : undefined;
        const isActive = entry.isActive;
        const authorName = (_a = entry.createdBy) !== null && _a !== void 0 ? _a : intl.t('cortiAI', 'Corti AI');
        const authorNamePrefix = entry.createdAt.toISOString() === ((_b = entry.root) === null || _b === void 0 ? void 0 : _b.createdAt.toISOString())
            ? intl.t('explore:timelineView.addedBy', 'Added by')
            : intl.t('explore:timelineView.editedBy', 'Edited by');
        return (React.createElement(Card, Object.assign({ p: 6, width: 1, height: "100%", clickable: true, overflowX: "hidden", className: css({
                position: 'relative',
                backgroundColor: isActive
                    ? theme.palette.action.selected
                    : theme.palette.background.card,
                '&:hover': {
                    backgroundColor: isActive
                        ? theme.palette.action.selected
                        : theme.palette.action.hover,
                },
            }) }, rest),
            React.createElement(Base, { width: 8, className: css({
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: isActive
                        ? (_c = entry.definition.style.color) !== null && _c !== void 0 ? _c : DEFAULT_DEFINITION_COLOR
                        : transparentize(0.4, (_d = entry.definition.style.color) !== null && _d !== void 0 ? _d : DEFAULT_DEFINITION_COLOR),
                }) }),
            React.createElement(Box, { ml: 4 },
                React.createElement(Base, { display: "grid", gridTemplateColumns: "auto 1fr", mr: 1, alignItems: "center" },
                    React.createElement(Typography, { noWrap: true, variant: "footnote", uppercase: true }, entry.definition.title),
                    React.createElement(Base, { ml: "auto" },
                        React.createElement(Tooltip, { disableInteractive: true, title: `${intl.t('explore:timelineView.timestampLabel', 'Timestamp')}: ${formatTime(entry.startDatetime)}${entry.endDatetime ? ` - ${formatTime(entry.endDatetime)}` : ''}` },
                            React.createElement(Typography, { noWrap: true, variant: "footnote" }, formattedTimeStart + (formattedTimeEnd ? ' - ' + formattedTimeEnd : ''))))),
                entry.text && React.createElement(Typography, { variant: "subtitle1" }, entry.text),
                React.createElement(Box, { mt: 4, flexDirection: "row", alignItems: "center" },
                    React.createElement(Typography, { mr: 1, noWrap: true, variant: "footnote" },
                        authorNamePrefix,
                        ":"),
                    React.createElement(Typography, { noWrap: true, color: "default", variant: "footnote" }, authorName)))));
    }));
}
