import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { AlertThumbnailView } from './AlertThumbnailView';
import { AlertView } from './AlertView';
import { thumbnailAnimatedBase64 } from './static/thumbnailAnimatedBase64';
import { thumbnailBase64 } from './static/thumbnailBase64';
export const Alert = observer(function Alert(props) {
    var _a;
    const { realTimeController, onAwaitingActionClick, onAlertActionClick, onAnalysingActionClick } = props;
    const { t } = useTranslation('realtimeAlerts');
    const [sessionController, setSessionController] = React.useState(null);
    React.useEffect(() => {
        const disposer = reaction(() => realTimeController.activeTriageSession, (state) => {
            if ((state === null || state === void 0 ? void 0 : state.status) === 'ok') {
                setSessionController(state.controller);
            }
            else {
                setSessionController(null);
            }
        });
        return () => {
            disposer();
            setSessionController(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!sessionController) {
        return (React.createElement(AlertView, { title: t('widgetStates.awaiting', 'Awaiting call'), thumbnail: React.createElement(AlertThumbnailView, { imgUrl: thumbnailBase64 }), onActionClick: onAwaitingActionClick }));
    }
    const { priorityAlert } = sessionController.alerts;
    if (!priorityAlert) {
        return (React.createElement(AlertView, { title: t('widgetStates.analysing', 'Analyzing call...'), thumbnail: React.createElement(AlertThumbnailView, { imgUrl: thumbnailAnimatedBase64 }), onActionClick: () => {
                onAnalysingActionClick === null || onAnalysingActionClick === void 0 ? void 0 : onAnalysingActionClick(sessionController.serializeSession());
            } }));
    }
    return (React.createElement(AlertView, { title: priorityAlert.title, text: (_a = priorityAlert.text) !== null && _a !== void 0 ? _a : undefined, thumbnail: React.createElement(AlertThumbnailView, { imgUrl: thumbnailAnimatedBase64 }), backgroundColor: priorityAlert.color, onActionClick: () => {
            onAlertActionClick === null || onAlertActionClick === void 0 ? void 0 : onAlertActionClick(sessionController.serializeSession(), priorityAlert);
            sessionController.alerts.triggerAction(priorityAlert);
        } }));
});
