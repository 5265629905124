import React, { useState } from 'react';
import { uuid } from '@corti/uuid';
import { validateChecklist } from 'lib/graphEditor/validation';
import { useChecklistStorage } from '../useChecklistStorage';
import { ChecklistForm } from './ChecklistForm';
export function ChecklistAdd() {
    const { createChecklist } = useChecklistStorage();
    const newEmptyChecklist = {
        id: uuid(),
        name: '',
        description: '',
        visibilityConditionEnabled: false,
        visibilityConditionExpression: '',
        key: '',
        entries: [],
        collectors: [],
    };
    const [shouldValidate, setShouldValidate] = useState(false);
    const handleSave = (checklist) => {
        const validation = validateChecklist(checklist, { checklists: [] });
        if (validation.getIssuesBySeverity('error').length > 0) {
            setShouldValidate(true);
            return false;
        }
        createChecklist(checklist);
        return true;
    };
    return (React.createElement(ChecklistForm, { isAdding: true, hasValidation: shouldValidate, originalChecklist: newEmptyChecklist, onSave: handleSave }));
}
