import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useDelayedQuery } from '@corti/react';
import { css } from '@corti/style';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, Container, LinearProgress, Page, PageHeader, PageHeaderActionsSection, PageHeaderDescription, PageHeaderTitle, } from 'lib/cortiUI';
import { NoTeamView } from './NoTeamView';
import { TeamsDataGrid } from './TeamsDataGrid';
import { CreateTeam } from './actions';
import * as GQL from './graphql';
export function TeamsView() {
    var _a;
    const { t } = useTranslation('teams');
    const { organization } = useAuth();
    const { refetch, delayedLoading, data, loading } = useDelayedQuery(GQL.getTeamsQuery, {
        variables: {
            orgID: organization.id,
        },
    });
    const teams = (_a = data === null || data === void 0 ? void 0 : data.teams) !== null && _a !== void 0 ? _a : [];
    return (React.createElement(Page, { "data-cy": "teams-view" },
        React.createElement(Container, { className: css({
                height: '100%',
            }) },
            React.createElement(Base, { height: "100%", display: "grid", gridTemplateRows: "auto 1fr", gap: 4 },
                React.createElement(PageHeader, null,
                    React.createElement(PageHeaderActionsSection, null, rbacService.teamsCRUD() && React.createElement(CreateTeam, { onCreateTeam: refetch })),
                    React.createElement(PageHeaderTitle, null, t('teamsView.title', 'Teams')),
                    React.createElement(PageHeaderDescription, null, t('teamsView.description', 'Organize your users into teams for organizational, reporting, and partitioning purposes.'))),
                delayedLoading && (React.createElement(LinearProgress, { variant: "indeterminate", style: { position: 'fixed', top: 0, left: 0, right: 0 } })),
                teams.length === 0 ? (React.createElement(NoTeamView, { onCreateTeam: refetch })) : (React.createElement(TeamsDataGrid, { teams: teams, loading: loading }))))));
}
