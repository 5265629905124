import { gql } from '@apollo/client';
import { startOfDay, subDays } from '@corti/date';
const triageFlowEventFragment = gql `
  fragment TriageFlowEvent on TriageFlowEvent {
    id
    datetime
    type
    stepID
    prevStepID
    nodeID
    blockID
    checklistID
    checklistEntryID
    checklistExpanded
    checklistEntryCompleted
    checklistEntryCompletionSource
    blockValues {
      identifier {
        blockID
        optionID
      }
      value
    }
    factValues {
      factID
      value
    }
    valueCollectorBlockItems {
      id
      valueType
      values {
        blockID
        optionID
        factID
        value
      }
    }
    createdAt
    createdBy {
      id
    }
  }
`;
const ownershipRequestFragment = gql `
  fragment TriageSessionOwnershipRequest on TriageSessionOwnershipRequest {
    id
    type
    status
    createdAt
    createdBy {
      id
    }
  }
`;
const triageSessionFragment = gql `
  fragment TriageSession on TriageSession {
    id
    caseID
    createdAt
    updatedAt
    externalID
    startedAt
    graphVersionID
    createdBy {
      id
      name
    }
    ownerUser {
      id
      name
    }
  }
`;
const triageSessionCommentFragment = gql `
  fragment TriageSessionComment on TriageSessionComment {
    id
    text
    datetime
    createdBy {
      id
      name
    }
  }
`;
export const triageQueries = (gqlClient, restClient) => {
    return new TriageSessionQueries(gqlClient, restClient);
};
export class TriageSessionQueries {
    constructor(gqlClient, restClient) {
        Object.defineProperty(this, "gqlClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: gqlClient
        });
        Object.defineProperty(this, "restClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: restClient
        });
        /**
         * Returns `undefined` when session does not exist on the server
         */
        Object.defineProperty(this, "getSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (id) => {
                var _a;
                const res = await this.gqlClient.query({
                    query: gql `
        query triageSession($id: String!) {
          triageSession(id: $id) {
            ...TriageSession
          }
        }
        ${triageSessionFragment}
      `,
                    variables: {
                        id,
                    },
                    fetchPolicy: 'no-cache',
                });
                return (_a = res.data.triageSession) !== null && _a !== void 0 ? _a : undefined;
            }
        });
        Object.defineProperty(this, "getUsersRecentSessionsWithCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (organizationID, userId, offset, first) => {
                // to start of day 2 days ago
                const DAYS_AGO = 2;
                const dateString = startOfDay(subDays(new Date(), DAYS_AGO)).toISOString();
                const res = await this.gqlClient.query({
                    query: gql `
        query getTriageSessions(
          $organizationID: ID!
          $userId: String!
          $dateString: String!
          $offset: String
          $first: Int
        ) {
          cases(
            organizationID: $organizationID
            cursor: $offset
            first: $first
            filter: {
              junction: "and"
              entities: [
                {
                  id: "case"
                  junction: "and"
                  predicates: [
                    {
                      attributeID: "triageSession.participants"
                      comparison: "eq"
                      value: { type: "multiselect", objects: [$userId] }
                    }
                    {
                      attributeID: "case.startedAt"
                      comparison: "after"
                      value: { type: "datetime", objects: [$dateString] }
                    }
                  ]
                }
              ]
            }
          ) {
            cursor
            hasNextPage
            items {
              calls {
                user {
                  extension
                }
                active
                callSource
              }
              triageSessions {
                id
                caseID
                createdAt
                updatedAt
                externalID
                startedAt
                graphVersionID
                createdBy {
                  id
                  name
                }
                ownerUser {
                  id
                  name
                }
              }
            }
          }
        }
      `,
                    variables: {
                        organizationID,
                        offset,
                        first,
                        userId,
                        dateString,
                    },
                    fetchPolicy: 'no-cache',
                });
                const { cursor, hasNextPage, items } = res.data.cases;
                return {
                    cursor,
                    hasNextPage,
                    items: items
                        .map(({ calls, triageSessions }) => {
                        return triageSessions.map((session, index) => {
                            var _a, _b, _c, _d;
                            return (Object.assign(Object.assign({}, session), { call: {
                                    number: (_b = (_a = calls[index]) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.extension,
                                    active: (_d = (_c = calls[index]) === null || _c === void 0 ? void 0 : _c.active) !== null && _d !== void 0 ? _d : false,
                                } }));
                        });
                    })
                        .flat(),
                };
            }
        });
        Object.defineProperty(this, "createSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation createTriageSession($input: CreateTriageSessionInput!) {
          createTriageSession(session: $input) {
            ...TriageSession
          }
        }
        ${triageSessionFragment}
      `,
                    variables: {
                        input: input,
                    },
                });
                return res.data.createTriageSession;
            }
        });
        Object.defineProperty(this, "createSessions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                await this.gqlClient.mutate({
                    mutation: gql `
        mutation insertTriageSessions($input: [CreateTriageSessionInput!]) {
          insertTriageSessions(sessions: $input)
        }
      `,
                    variables: {
                        input,
                    },
                });
            }
        });
        Object.defineProperty(this, "updateSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (id, input) => {
                await this.gqlClient.mutate({
                    mutation: gql `
        mutation updateTriageSession($id: String!, $input: UpdateTriageSessionInput!) {
          updateTriageSession(id: $id, session: $input) {
            ...TriageSession
          }
        }
        ${triageSessionFragment}
      `,
                    variables: {
                        id: id,
                        input,
                    },
                });
                const s = await this.getSession(id);
                return s;
            }
        });
        Object.defineProperty(this, "getFlowEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.query({
                    query: gql `
        query triageSessionFlowEvents($sessionID: String!, $cursor: String, $first: Int) {
          triageSessionFlowEvents(sessionID: $sessionID, cursor: $cursor, first: $first) {
            hasNextPage
            cursor
            items {
              ...TriageFlowEvent
            }
          }
        }
        ${triageFlowEventFragment}
      `,
                    variables: Object.assign({}, input),
                    fetchPolicy: 'no-cache',
                });
                return res.data.triageSessionFlowEvents;
            }
        });
        Object.defineProperty(this, "createFlowAnalyticsEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (events) => {
                const url = '/triage-analytics-events';
                await this.restClient.axiosClient({
                    method: 'post',
                    url,
                    data: {
                        events,
                    },
                });
            }
        });
        Object.defineProperty(this, "getFlowEventsFullBatch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const events = [];
                const get = async (cursor) => {
                    const res = await this.getFlowEvents({
                        sessionID: input.sessionID,
                        cursor: cursor,
                    });
                    events.push(...res.items);
                    if (res.hasNextPage) {
                        await get(res.cursor);
                    }
                };
                await get();
                return events;
            }
        });
        Object.defineProperty(this, "createFlowEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                await this.gqlClient.mutate({
                    mutation: gql `
        mutation insertTriageSessionFlowEvents($input: [TriageFlowEventInput!]) {
          insertTriageSessionFlowEvents(flowEvents: $input)
        }
      `,
                    variables: {
                        input,
                    },
                    fetchPolicy: 'no-cache',
                });
            }
        });
        Object.defineProperty(this, "createComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation createTriageSessionComment($input: TriageSessionCommentInput!) {
          createTriageSessionComment(input: $input) {
            ...TriageSessionComment
          }
        }
        ${triageSessionCommentFragment}
      `,
                    variables: {
                        input,
                    },
                });
                return res.data.createTriageSessionComment;
            }
        });
        Object.defineProperty(this, "createComments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                await this.gqlClient.mutate({
                    mutation: gql `
        mutation insertTriageSessionComments($input: [TriageSessionCommentInput!]) {
          insertTriageSessionComments(comments: $input)
        }
      `,
                    variables: {
                        input,
                    },
                });
            }
        });
        Object.defineProperty(this, "getComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (commentID) => {
                const res = await this.gqlClient.query({
                    query: gql `
        query triageSessionComment($id: String!) {
          triageSessionComment(id: $id) {
            ...TriageSessionComment
          }
        }
        ${triageSessionCommentFragment}
      `,
                    variables: {
                        id: commentID,
                    },
                    fetchPolicy: 'no-cache',
                });
                return res.data.triageSessionComment;
            }
        });
        /**
         * Returns a paginated list of comments
         */
        Object.defineProperty(this, "getComments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.query({
                    query: gql `
        query triageSessionComments($sessionID: String!, $first: Int, $cursor: String) {
          triageSessionComments(sessionID: $sessionID, first: $first, cursor: $cursor) {
            totalCount
            hasNextPage
            cursor
            items {
              ...TriageSessionComment
            }
          }
        }
        ${triageSessionCommentFragment}
      `,
                    variables: Object.assign({}, input),
                    fetchPolicy: 'no-cache',
                });
                return res.data.triageSessionComments;
            }
        });
        /**
         * Returns all comments (non-paginated list)
         */
        Object.defineProperty(this, "getAllComments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const events = [];
                const get = async (cursor) => {
                    const res = await this.getComments({
                        sessionID: input.sessionID,
                        cursor: cursor,
                        first: 2000, // big hardcoded number in order to reduce likelyhood of recursive fetches
                    });
                    events.push(...res.items);
                    if (res.hasNextPage) {
                        await get(res.cursor);
                    }
                };
                await get();
                return events;
            }
        });
        Object.defineProperty(this, "requestSessionOwnershipTakeover", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation requestTriageSessionOwnershipTakeover(
          $input: TriageSessionOwnershipRequestInput!
        ) {
          requestTriageSessionOwnershipTakeover(input: $input) {
            ...TriageSessionOwnershipRequest
          }
        }
        ${ownershipRequestFragment}
      `,
                    variables: {
                        input,
                    },
                });
                return res.data.requestTriageSessionOwnershipTakeover;
            }
        });
        Object.defineProperty(this, "requestSessionOwnershipHandover", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation requestTriageSessionOwnershipHandover(
          $input: TriageSessionOwnershipRequestInput!
        ) {
          requestTriageSessionOwnershipHandover(input: $input) {
            ...TriageSessionOwnershipRequest
          }
        }
        ${ownershipRequestFragment}
      `,
                    variables: {
                        input,
                    },
                });
                return res.data.requestTriageSessionOwnershipHandover;
            }
        });
        Object.defineProperty(this, "acceptSessionOwnershipRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation acceptTriageSessionOwnershipRequest($requestID: String!) {
          acceptTriageSessionOwnershipRequest(requestID: $requestID) {
            ...TriageSessionOwnershipRequest
          }
        }
        ${ownershipRequestFragment}
      `,
                    variables: {
                        requestID: input.requestID,
                    },
                });
                return res.data.requestTriageSessionOwnershipTakeover;
            }
        });
        Object.defineProperty(this, "declineSessionOwnershipRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const res = await this.gqlClient.mutate({
                    mutation: gql `
        mutation declineTriageSessionOwnershipRequest($requestID: String!) {
          declineTriageSessionOwnershipRequest(requestID: $requestID) {
            ...TriageSessionOwnershipRequest
          }
        }
        ${ownershipRequestFragment}
      `,
                    variables: {
                        requestID: input.requestID,
                    },
                });
                return res.data.declineTriageSessionOwnershipRequest;
            }
        });
    }
}
