import { getTransformForBounds } from 'reactflow';
export function pointsAreEqual(p1, p2) {
    return p1.x === p2.x && p1.y === p2.y;
}
function toFullRect(input) {
    if ('x' in input) {
        return Object.assign(Object.assign({}, input), { left: input.x, top: input.y, bottom: input.y + input.height, right: input.x + input.width });
    }
    return Object.assign(Object.assign({}, input), { x: input.left, y: input.top, width: Math.abs(input.right - input.left), height: Math.abs(input.bottom - input.top) });
}
export class Point {
    constructor(p) {
        Object.defineProperty(this, "x", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "y", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.x = Math.floor(p.x);
        this.y = Math.floor(p.y);
    }
    serialize() {
        return {
            x: this.x,
            y: this.y,
        };
    }
    copy() {
        return new Point({ x: this.x, y: this.y });
    }
}
export function rectMidPoint(rect) {
    return {
        x: (rect.left + rect.right) / 2,
        y: (rect.top + rect.bottom) / 2,
    };
}
function getNodeRect(node) {
    var _a, _b;
    const rect = {
        x: node.position.x,
        y: node.position.y,
        width: (_a = node.width) !== null && _a !== void 0 ? _a : 1,
        height: (_b = node.height) !== null && _b !== void 0 ? _b : 1,
    };
    return toFullRect(rect);
}
export function getRectForNodes(nodes) {
    let result = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    };
    if (!nodes.length) {
        return toFullRect(result);
    }
    if (nodes.some((n) => n.width === undefined || n.height === undefined)) {
        return toFullRect(result);
    }
    nodes.forEach((no, idx) => {
        const nodeRect = getNodeRect(no);
        if (idx === 0) {
            result = {
                left: nodeRect.left,
                top: nodeRect.top,
                right: nodeRect.right,
                bottom: nodeRect.bottom,
            };
        }
        if (nodeRect.x < result.left) {
            result.left = nodeRect.x;
        }
        if (nodeRect.y < result.top) {
            result.top = nodeRect.y;
        }
        if (nodeRect.right > result.right) {
            result.right = nodeRect.right;
        }
        if (nodeRect.bottom > result.bottom) {
            result.bottom = nodeRect.bottom;
        }
    });
    return toFullRect(result);
}
export function getViewportToFitRect(rect1, canvasBounds) {
    const MIN_ZOOM = 0.1;
    const MAX_ZOOM = 1.5;
    // This could be as well our own implementation, but since the library provides a nice
    // pure utility to calculate this, we're just using it
    const [x, y, zoom] = getTransformForBounds(toFullRect(rect1), canvasBounds.width, canvasBounds.height, MIN_ZOOM, MAX_ZOOM);
    return { x, y, zoom };
}
