import { intl } from '@corti/i18n';
// loaded by webpack
// @ts-ignore
import resources from '../../locales';
const fallbackLocale = 'en';
export function initIntl(options = {}) {
    var _a;
    void intl.init({
        lng: (_a = localStorage.getItem('locale')) !== null && _a !== void 0 ? _a : fallbackLocale,
        debug: options.isDebugMode,
        fallbackLng: fallbackLocale,
        resources: resources,
        defaultNS: 'common',
        react: {
            useSuspense: false,
        },
    });
    intl.on('languageChanged', (lang) => {
        const prevLocale = localStorage.getItem('locale');
        if (lang !== prevLocale) {
            localStorage.setItem('locale', lang);
            window.location.reload();
        }
    });
}
