import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Input } from 'lib/cortiUI';
import { ChecklistExpression } from './ChecklistExpression';
import { ChecklistInspectorAttribute } from './ChecklistInspectorAttribute';
import { useChecklistEdit } from './useChecklistEdit';
const MonospaceInput = styled(Input) `
  font-family: 'Roboto Mono', monospace;
`;
export function ChecklistInspectorGeneral() {
    const { t } = useTranslation('libGraphEditor');
    const checklist = useChecklistEdit((state) => state.checklist);
    const updateChecklist = useChecklistEdit((state) => state.updateChecklist);
    return (React.createElement(React.Fragment, null,
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.visibilityCriteria', 'Visibility Criteria'), help: t('checklist.checklistInfo.visibilityCriteriaHelp', 'If enabled, this checklist will only be visible in a triage session, if the given expression evaluates to true. If the expression later evaluates to false, the checklist will be hidden again, but all choices will be remembered if it is later shown again.'), hasSwitch: true, switchValue: checklist.visibilityConditionEnabled, handleSwitchChange: (value) => updateChecklist('visibilityConditionEnabled', value) },
            React.createElement(ChecklistExpression, { value: checklist.visibilityConditionExpression, onChange: (value) => updateChecklist('visibilityConditionExpression', value), placeholder: checklist.visibilityConditionEnabled ? undefined : '', disabled: !checklist.visibilityConditionEnabled })),
        React.createElement(ChecklistInspectorAttribute, { label: t('checklist.checklistInfo.checklistKey', 'Checklist Key'), help: t('checklist.checklistInfo.checklistKeyHelpGeneral', 'The checklist key is a globally unique identifier that has to be unique among all checklists in this protocol.'), hasSwitch: false },
            React.createElement(MonospaceInput, { value: checklist.key, onChange: (evt) => updateChecklist('key', evt.target.value) }))));
}
