import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Button, IconButton, Typography } from 'lib/cortiUI';
import { NotificationActions } from './NotificationActions';
export function NotificationHeader({ notification, onClose, onActionClick, textColor, isExpanded, toggleExpand, }) {
    return (React.createElement(Base, { display: "grid", gridAutoFlow: "column", gap: 4, alignItems: "center", justifyContent: "space-between" },
        React.createElement(Typography, { py: 2, color: "inherit", variant: "body2", alignSelf: "center" }, notification.message),
        React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", flexShrink: 0, gap: 4 },
            notification.detailText && (React.createElement(Button, { variant: "text", size: "small", style: { color: textColor }, onClick: toggleExpand }, isExpanded
                ? intl.t('notifications.hideDetailsBtnText', 'Hide details')
                : intl.t('notifications.showDetailsBtnText', 'Details'))),
            React.createElement(NotificationActions, { actions: notification.actions, onActionClick: onActionClick, textColor: textColor }),
            notification.closable && (React.createElement(Base, null,
                React.createElement(IconButton, { "data-cy": "notification-close-btn", key: 'close-button', onClick: onClose, className: css({ color: 'inherit' }), icon: React.createElement(ClearRoundedIcon, { fontSize: "small" }) }))))));
}
