import { gql } from '@apollo/client';
import { richTextFragment } from '@corti/lib/coreApiService/richText/v2';
import { ProtocolGraphVersionCommitFragment } from '../api';
const protocolGraphVersionFragment = () => gql `
  fragment ProtocolGraphVersion on ProtocolGraphVersion {
    id
    name
    description {
      richText {
        ...RichText
      }
    }
    parentVersion {
      id
      name
    }
    graph {
      id
      name
    }
    valid
    commits {
      ...ProtocolGraphVersionCommit
    }
    updatedAt
    updatedBy {
      id
      name
    }
    release {
      releasedBy {
        id
        name
      }
      releasedAt
    }
  }
  ${richTextFragment}
  ${ProtocolGraphVersionCommitFragment()}
`;
export const getProtocolGraphDetails = () => gql `
  query ProtocolGraphDetails($id: String!) {
    protocolGraph(id: $id) {
      id
      name
      versions {
        ...ProtocolGraphVersion
      }
    }
  }
  ${protocolGraphVersionFragment()}
`;
export const getProtocolGraphVersionQuery = () => gql `
  query ProtocolGraphVersion($id: String!) {
    protocolGraphVersion(id: $id) {
      ...ProtocolGraphVersion
    }
  }
  ${protocolGraphVersionFragment()}
`;
