var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Base, Button } from 'lib/cortiUI';
export function StepperButton(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (React.createElement(Button, Object.assign({ size: "small", variant: "outlined", disableRipple: true, tabIndex: -1, sx: { padding: '1px 3px', minWidth: 'auto' } }, rest),
        React.createElement(Base, { margin: -2, display: "flex" }, children)));
}
