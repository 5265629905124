import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { ICaseReviewRequestStatus } from '@corti/lib/coreApiService';
import { useLazyRef } from '@corti/react';
import { trackerService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Button, Menu, MenuItem } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../CaseReviewRequestsContext';
export const QuickFilterPicker = observer(function QuickFilterPicker(props) {
    const { onChange } = props;
    const auth = useAuth();
    const ctx = useCaseReviewRequestsContext();
    const buttonRef = React.useRef();
    const options = useLazyRef(() => {
        return [
            {
                id: 'pending',
                text: intl.t('caseReviewRequestsModule:combinedFilters.pending', 'Open'),
                value: {
                    status: [
                        ICaseReviewRequestStatus.PendingReview,
                        ICaseReviewRequestStatus.PendingAcknowledgement,
                    ],
                },
            },
            {
                id: 'pending-review',
                text: intl.t('caseReviewRequestsModule:combinedFilters.pendingReview', 'To review'),
                value: {
                    status: [ICaseReviewRequestStatus.PendingReview],
                },
            },
            {
                id: 'pending-acknowledgement',
                text: intl.t('caseReviewRequestsModule:combinedFilters.pendingAcknowledgement', 'To acknowledge'),
                value: {
                    status: [ICaseReviewRequestStatus.PendingAcknowledgement],
                },
            },
            {
                id: 'completed',
                text: intl.t('caseReviewRequestsModule:combinedFilters.completed', 'Completed'),
                value: {
                    status: [ICaseReviewRequestStatus.Completed],
                },
            },
            {
                id: 'pending-review:current-user',
                text: intl.t('caseReviewRequestsModule:combinedFilters.pendingReviewCurrentUser', 'Awaiting my review'),
                value: {
                    status: [ICaseReviewRequestStatus.PendingReview],
                    assigneeIDs: [auth.currentUserID],
                },
            },
            {
                id: 'pending-acknowledgement:current-user',
                text: intl.t('caseReviewRequestsModule:combinedFilters.pendingAcknowledgementCurrentUser', 'Awaiting my acknowledgement'),
                value: {
                    status: [ICaseReviewRequestStatus.PendingAcknowledgement],
                    callTakerIDs: [auth.currentUserID],
                },
            },
        ];
    });
    const selectedOption = options.current.find(isOptionSelected);
    const [isOpen, setIsOpen] = React.useState(false);
    function handleOpen() {
        setIsOpen(true);
    }
    function handleClose() {
        setIsOpen(false);
    }
    function handleOptionSelect(option) {
        ctx.model.filter.reset(Object.assign({ createdAt: ctx.model.filter.createdAt }, option.value));
        handleClose();
        onChange();
        trackerService.track('[Review Queue] quick filter applied', {
            id: option.id,
            text: option.text,
        });
    }
    function isOptionSelected(option) {
        return isEqual(Object.assign(Object.assign(Object.assign({}, ctx.model.filter.empty), option.value), { createdAt: ctx.model.filter.createdAt }), ctx.model.filter.serialized);
    }
    function getTitle() {
        return selectedOption
            ? selectedOption.text
            : intl.t('caseReviewRequestsModule:combinedFilters.filterTitle', 'Quick filter');
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: (r) => (buttonRef.current = r), size: "small", variant: "outlined", color: !!selectedOption ? 'primary' : undefined, disabled: props.loading, onClick: handleOpen, noWrap: true, endIcon: React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }) }, getTitle()),
        React.createElement(Menu, { open: isOpen, anchorEl: buttonRef.current, onClose: handleClose }, options.current.map((it) => {
            return (React.createElement(MenuItem, { key: it.id, selected: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id) === it.id, onClick: () => handleOptionSelect(it) }, it.text));
        }))));
});
