var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { AvatarWithText, ChipV2, DefaultCellRenderer, DefaultMultiValueRenderer, DefaultValueRenderer, Tag, } from 'lib/cortiUI';
export function CasesTableCellRenderer(_a) {
    var { rowId } = _a, props = __rest(_a, ["rowId"]);
    return (React.createElement(DefaultCellRenderer, { "data-cy": rowId === 'caseID' ? 'cases-table-id-cell' : undefined, requiredProps: props, valueRenderer: (value) => {
            switch (rowId) {
                case 'caseCreatedBy':
                    return React.createElement(AvatarWithText, { title: value.toString() });
                case 'performanceMetricsExcluded':
                    if (typeof value === 'number' && value > 0) {
                        return (React.createElement(ChipV2, { size: "medium", icon: React.createElement(DoDisturbRoundedIcon, null), label: intl.t('explore:casesView.excludedCallsCount', {
                                count: value,
                                defaultValue_one: `{{count}} call`,
                                defaultValue_other: `{{count}} calls`,
                            }) }));
                    }
                    return;
                case 'callProperties': {
                    return (React.createElement("div", { className: css({
                            display: 'flex',
                            height: '100%',
                            overflow: 'scroll',
                            alignItems: 'center',
                        }) }, value));
                }
                default:
                    return React.createElement(DefaultValueRenderer, { value: value });
            }
        }, multiValueRenderer: (value) => {
            switch (rowId) {
                case 'caseTags': {
                    const tag = value;
                    return React.createElement(Tag, { text: tag.value.toString(), color: tag.color, variant: "pill" });
                }
                case 'sessionCreatedBy':
                case 'caseReviewedBy':
                case 'caseAssignedTo':
                case 'callParticipants':
                    return React.createElement(AvatarWithText, { title: value.toString() });
                default:
                    return React.createElement(DefaultMultiValueRenderer, { value: value });
            }
        } }));
}
