import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Base, Tabs as CortiUITabs, Tab } from 'lib/cortiUI';
export const Tabs = ({ value, onChange }) => {
    const { t } = useTranslation('tasks', { keyPrefix: 'tabs' });
    const { typography } = useTheme();
    const options = [
        { label: t('pending', 'To-Do'), value: 'PENDING' },
        { label: t('completed', 'Completed'), value: 'COMPLETED' },
    ];
    function handleTabChange(newValue) {
        onChange(newValue);
        trackerService.track('[Tasks] navigated to different tab', {
            type: newValue,
        });
    }
    return (React.createElement(Base, null,
        React.createElement(CortiUITabs, { value: value, onChange: (_, v) => handleTabChange(v), "aria-label": "task status tabs" }, options.map(({ label, value }) => (React.createElement(Tab, { key: value, label: label, value: value, sx: { fontSize: typography.body2 } }))))));
};
