import { gql } from '@apollo/client';
import { graphqlSchemaQueries } from '@corti/lib/coreApiService/graphqlSchema/graphqlSchema';
import { configGroupToQueryString, configGroupValuesToInput, inputFieldsToConfigGroup, populateConfigValuesWithResult, } from './helper';
export const organizationConfigQueries = (client) => ({
    async getGenericDefinition() {
        const inputFieldsRes = await graphqlSchemaQueries(client).getTypeInputFields('OrganizationConfigInput');
        if (!inputFieldsRes) {
            return inputFieldsRes;
        }
        return inputFieldsToConfigGroup(inputFieldsRes.inputFields);
    },
    async getGenericValues(organizationID) {
        const rootConfigGroup = await organizationConfigQueries(client).getGenericDefinition();
        if (!rootConfigGroup) {
            return rootConfigGroup;
        }
        const query = `
        query organization($organizationID: ID!) {
          organization(id: $organizationID) {
            ${configGroupToQueryString(rootConfigGroup)}
          }
        }
      `;
        const res = await client.query({
            query: gql(query),
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        if (!res) {
            return res;
        }
        let configValues = populateConfigValuesWithResult(rootConfigGroup, res.data.organization[rootConfigGroup.name]);
        return configValues.children;
    },
    async patchGenericValues(organizationID, values) {
        const res = await client.mutate({
            mutation: gql `
        mutation patchOrganizationConfig($organizationID: ID!, $config: OrganizationConfigInput!) {
          patchOrganizationConfig(organizationID: $organizationID, config: $config)
        }
      `,
            variables: {
                organizationID: organizationID,
                config: configGroupValuesToInput(values),
            },
        });
        if (!res) {
            return false;
        }
        return res.data.patchOrganizationConfig;
    },
});
