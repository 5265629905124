import { useQuery } from '@apollo/client';
import { union } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useLazyRef } from '@corti/react';
import { useAuth } from 'core/auth/browser';
import { Base, Sidebar, relativeToAbsolute } from 'lib/cortiUI';
import { Route } from 'lib/router';
import { api } from '../../services';
import { TranscriptQueryView } from '../TranscriptQueryView';
import { getTranscriptSearchFilterQuery, } from '../graphql';
import { RedirectView } from './RedirectView';
import { TranscriptQueryList } from './TranscriptQueryList';
import { TranscriptSearchViewModel } from './TranscriptSearchViewModel';
import { TranscriptSearchViewContext, } from './context';
export const TranscriptSearchView = observer(function TranscriptSearchView() {
    const { organization } = useAuth();
    const routeHistory = useHistory();
    const routeMatch = useRouteMatch();
    const viewModel = useLazyRef(() => {
        return new TranscriptSearchViewModel(api.transcripts);
    });
    const router = React.useRef({
        toRoute,
        getRoute,
        getNextRoute,
    });
    const utils = React.useRef({
        toAbsoluteDateRange,
        combineUserIDsAndTeamMemberIDs: combineUsersAndTeamMembers,
    });
    React.useEffect(() => {
        void viewModel.current.loadAllQueries();
        void viewModel.current.loadTeams(organization.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { loading } = useQuery(getTranscriptSearchFilterQuery, {
        variables: {
            organizationID: organization.id,
        },
        onCompleted: (resData) => {
            viewModel.current.state.setProtocolNames(resData.performanceMetricsFilter.protocolNames);
        },
    });
    React.useEffect(() => {
        viewModel.current.state.setIsLoadingProtocolNames(loading);
    }, [loading, viewModel]);
    function toRoute(route, state) {
        routeHistory.push(route, state);
    }
    function getRoute(queryID) {
        return `${routeMatch.path}/${queryID}`;
    }
    function getNextRoute(queries) {
        if (queries.length > 0) {
            return getRoute(queries[0].id);
        }
        else {
            return getRoute('draft');
        }
    }
    function toAbsoluteDateRange(dateRange) {
        if (dateRange.type === 'absolute') {
            return dateRange.absoluteRange;
        }
        return relativeToAbsolute({
            durationString: dateRange.relativeRange.duration,
            isCurrentPeriodIncluded: dateRange.relativeRange.isCurrentPeriodIncluded,
        });
    }
    function combineUsersAndTeamMembers(userIDs, teamIDs) {
        const teams = viewModel.current.state.teams.filter((it) => teamIDs.includes(it.id));
        return union(userIDs, teams.flatMap((it) => it.members.map((it) => it.user.id)));
    }
    return (React.createElement(TranscriptSearchViewContext.Provider, { value: {
            viewModel: viewModel.current,
            router: router.current,
            utils: utils.current,
        } },
        React.createElement(Base, { "data-cy": "transcriptSearch-app", width: "100%", height: "100%", display: "grid", gridTemplateColumns: "auto 1fr", overflow: "hidden" },
            React.createElement(Sidebar, { defaultMode: "opened-perm", keepMounted: true },
                React.createElement(TranscriptQueryList, null)),
            React.createElement(Switch, null,
                React.createElement(Route, { path: routeMatch.path, exact: true, render: () => React.createElement(RedirectView, null) }),
                React.createElement(Route, { path: `${routeMatch.path}/:transcriptQueryID`, render: ({ match }) => (React.createElement(TranscriptQueryView, { transcriptQueryID: match.params.transcriptQueryID })) })))));
});
