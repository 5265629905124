import { gql } from '@apollo/client';
// #region
export const getTeamsQuery = gql `
  query Teams($orgID: String!) {
    teams(organizationID: $orgID) {
      id
      name
      members {
        id
      }
    }
  }
`;
// #endregion
// #region
export const getTeamQuery = gql `
  query Team($id: String!) {
    team(id: $id) {
      id
      name
      description
      members {
        id
        user {
          id
          name
          deletedAt
        }
      }
    }
  }
`;
// #endregion
// #region
export const createTeamMutation = gql `
  mutation createTeam($team: CreateTeamInput!, $organizationID: String!) {
    createTeam(team: $team, organizationID: $organizationID) {
      id
    }
  }
`;
// #endregion
// #region
export const updateTeamMutation = gql `
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
    }
  }
`;
export const deleteTeamMutation = gql `
  mutation deleteTeam($id: String!) {
    deleteTeam(id: $id)
  }
`;
// #endregion
