var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { BlockRenderer } from '.';
import { observer } from 'mobx-react';
import React from 'react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card } from 'lib/cortiUI';
import { useScrollFading } from '../../../../packages/react/src/hooks';
export const Step = observer(function Step(props) {
    var _a;
    const { stepModel, flowStore, className, isReadonly = false, shouldAutoProgress = true } = props, rest = __rest(props, ["stepModel", "flowStore", "className", "isReadonly", "shouldAutoProgress"]);
    const theme = useTheme();
    const { hasOverflow, gradientOpacity, scrollerRef } = useScrollFading();
    return (React.createElement(Card, Object.assign({ ref: scrollerRef, p: 0, width: "100%", maxHeight: "100%", elevation: 0, className: css(getScrollerCss({ theme: theme }), {
            boxSizing: 'border-box',
        }, className), "data-cy": 'flow:step', "data-step-id": `${stepModel.id}`, "data-node-id": `${stepModel.node.id}` }, rest),
        React.createElement(Base, { p: 8, width: "100%", display: "grid", gridGap: theme.space[8], gridTemplateColumns: "minmax(0, 100%)", className: css({
                boxSizing: 'border-box',
            }) }, stepModel.node.view.blocks.map((block, idx) => {
            return (React.createElement(BlockRenderer, { key: `${block.id}-${idx}`, block: block, stepModel: stepModel, flowStore: flowStore, isReadonly: isReadonly, shouldAutoProgress: shouldAutoProgress }));
        })),
        hasOverflow && (React.createElement("span", { "data-testid": "scroll-gradient", style: {
                display: 'block',
                height: '8rem',
                width: '100%',
                position: 'sticky',
                bottom: '-10px',
                background: `linear-gradient(transparent, ${theme.palette.background.default} 90%)`,
                filter: 'blur(10px)',
                pointerEvents: 'none',
                borderRadius: (_a = theme === null || theme === void 0 ? void 0 : theme.shape.borderRadius) !== null && _a !== void 0 ? _a : 0,
                marginTop: '-8rem',
                opacity: gradientOpacity !== null && gradientOpacity !== void 0 ? gradientOpacity : 1,
            } }))));
});
