export var ICaseReviewRequestStatus;
(function (ICaseReviewRequestStatus) {
    ICaseReviewRequestStatus["PendingReview"] = "PENDING_REVIEW";
    ICaseReviewRequestStatus["PendingAcknowledgement"] = "PENDING_ACK";
    ICaseReviewRequestStatus["Completed"] = "COMPLETED";
})(ICaseReviewRequestStatus || (ICaseReviewRequestStatus = {}));
export var ICaseReviewRequestSortPropertyName;
(function (ICaseReviewRequestSortPropertyName) {
    ICaseReviewRequestSortPropertyName["AssigneeUserName"] = "ASSIGNEE_USER_NAME";
    ICaseReviewRequestSortPropertyName["CallTakerUserName"] = "CALLTAKER_USER_NAME";
    ICaseReviewRequestSortPropertyName["RequestCreatedAt"] = "REQUEST_CREATED_AT";
    ICaseReviewRequestSortPropertyName["ProtocolName"] = "PROTOCOL_NAME";
    ICaseReviewRequestSortPropertyName["CaseCreatedAt"] = "CASE_CREATED_AT";
})(ICaseReviewRequestSortPropertyName || (ICaseReviewRequestSortPropertyName = {}));
export var ICaseReviewRequestSortDirection;
(function (ICaseReviewRequestSortDirection) {
    ICaseReviewRequestSortDirection["Ascending"] = "ASC";
    ICaseReviewRequestSortDirection["Descending"] = "DESC";
})(ICaseReviewRequestSortDirection || (ICaseReviewRequestSortDirection = {}));
