import Mousetrap from 'mousetrap';
import React from 'react';
/**
 * React hook wrapper for using mousetrap keyboard event binding library
 */
export function useMousetrap(opt, deps) {
    React.useEffect(() => {
        if (opt.keys.length === 0) {
            // The above works for both a string and an array of strings
            // If empty array or empty string, don't bind anything
            return;
        }
        const mousetrap = new Mousetrap(opt.element);
        mousetrap.bind(opt.keys, opt.handle, opt.action);
        return () => {
            mousetrap.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
