export function aggregateTimeSeriesDataByPeriod(periodVariant, dataPoints) {
    if (!periodVariant || periodVariant.id === 'daily')
        return dataPoints;
    const newData = [];
    dataPoints.forEach((currentDataPoint, idx) => {
        if (idx === 0 || (idx - 1 !== 0 && idx % periodVariant.value === 0)) {
            newData.push(Object.assign({}, currentDataPoint));
        }
        if (currentDataPoint.startOfPeriod !== newData[newData.length - 1].startOfPeriod) {
            newData[newData.length - 1].callCount += currentDataPoint.callCount;
        }
    });
    return newData;
}
export function getTempTermGroupColor(tempTermGroupsColors, colorsList) {
    const searchTermsCount = tempTermGroupsColors.length;
    const colorsAlreadyInUse = colorsList.map(() => false);
    const isTermGroupsListLonger = searchTermsCount > colorsAlreadyInUse.length;
    for (let i = searchTermsCount - 1; isTermGroupsListLonger ? i > searchTermsCount - colorsAlreadyInUse.length : i > -1; i--) {
        const color = tempTermGroupsColors[i];
        const colourIndex = colorsList.indexOf(color);
        colorsAlreadyInUse[colourIndex] = true;
    }
    const nextColorIndex = colorsAlreadyInUse.indexOf(false);
    return colorsList[nextColorIndex > -1 ? nextColorIndex : 0];
}
export const getChartDataExtremes = (data) => data.reduce((acc, i) => {
    if (i.min < acc.min) {
        acc.min = i.min;
    }
    if (i.max > acc.max) {
        acc.max = i.max;
    }
    return acc;
}, { min: data[0].min, max: data[0].max });
