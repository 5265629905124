import React from 'react';
const initialState = {
    view: 'assessment',
    initialReviewId: undefined,
    setView: () => { },
    setInitialReviewId: () => { },
};
const NavigationContext = React.createContext(initialState);
export const NavigationProvider = ({ children }) => {
    const [view, setView] = React.useState('assessment');
    const [initialReviewId, setInitialReviewId] = React.useState();
    const value = React.useMemo(() => ({
        view,
        initialReviewId,
        setView,
        setInitialReviewId,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view, initialReviewId]);
    return React.createElement(NavigationContext.Provider, { value: value }, children);
};
export const useNavigation = () => {
    const context = React.useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};
