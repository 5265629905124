import React from 'react';
import { useToolbar } from 'lib/webappUI';
import { useTriageSession } from './TriageSessionContext';
export function useUIState() {
    const result = React.useContext(UIStateContext);
    if (!result) {
        throw new Error('useUIState should be used inside UIStateContext');
    }
    return result;
}
const UIStateContext = React.createContext(null);
/**
 * - holds session UI state that is reused accross multiple components.
 * - contains Toolbar state. Toolbar should be controlled via this API.
 */
export function UIStateProvider({ children, onChange, initialState, }) {
    var _a;
    const { openToolID } = useToolbar();
    const { controller } = useTriageSession();
    const [activePinnedStepID, setActivePinnedStepID] = React.useState((_a = initialState === null || initialState === void 0 ? void 0 : initialState.activePinnedStepID) !== null && _a !== void 0 ? _a : null);
    React.useEffect(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange({
            openToolID,
            activePinnedStepID,
        });
    }, [openToolID, activePinnedStepID, onChange]);
    React.useEffect(() => {
        const unsubscribe = controller.onOwnerUserChange(() => {
            setActivePinnedStepID(null);
        });
        return () => {
            unsubscribe();
        };
    }, [controller]);
    return (React.createElement(UIStateContext.Provider, { value: {
            activePinnedStepID,
            setActivePinnedStepID,
        } }, children));
}
