import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@corti/i18n';
import { stringIncludes } from '@corti/strings';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Button, ButtonBase, ContextMenuItem, DataGrid, GridRow, Input, InputAdornment, Typography, useContextMenu, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor';
import { BaseGraphEditorNode } from 'lib/graphEditor/canvas/nodes';
import { ContentBuilderLibraryComponent } from 'lib/graphEditor/contentBuilder';
import { AttributeList, AttributeRendererStatic, AttributeRendererString, GenericPanel, GenericPanelContent, GenericPanelHeader, Sidepanel, TwoColGridLayout, useGraphEditorStorage, } from 'lib/graphEditor/ui';
export const Row = (props) => {
    const { t } = useTranslation('libGraphEditor');
    const { openContextMenu } = useContextMenu();
    const onContextMenu = (event) => {
        const row = props.row.item;
        openContextMenu(event, React.createElement(React.Fragment, null,
            React.createElement(ContextMenuItem, { title: t('componentLibView.ctxMenu.deleteFromLib', 'Delete from library'), onClick: () => {
                    row === null || row === void 0 ? void 0 : row.remove();
                } })));
    };
    return React.createElement(GridRow, Object.assign({}, props, { "data-cy": "grid-row", onContextMenu: onContextMenu }));
};
export const ComponentLibraryView = observer((_) => {
    const { t } = useTranslation('libGraphEditor');
    const { editor } = useGraphEditorCtx();
    const theme = useTheme();
    const history = useHistory();
    const [searchInputValue, setSearchInputValue] = useGraphEditorStorage('', 'componentLibraryViewSearchInputValue');
    const [selectedComponentID, setSelectedComponentID] = useGraphEditorStorage(null, 'componentLibraryViewSelectedComponent');
    const getRows = () => {
        const rows = [];
        Array.from(editor.model.library.items.values()).forEach((item) => {
            if (stringIncludes(item.name, searchInputValue)) {
                rows.push({
                    id: item.id,
                    name: item.name,
                    instanceCount: item.instances.size,
                    item,
                });
            }
        });
        return rows;
    };
    const getColumns = () => {
        return [
            {
                field: 'name',
                headerName: t('componentLibView.table.colName', 'Component name'),
                flex: 1,
            },
            {
                field: 'instanceCount',
                headerName: t('componentLibView.table.instanceCount', 'Instance count'),
                flex: 1,
            },
        ];
    };
    const handleRowClick = (params) => {
        setSelectedComponentID(params.row.id);
    };
    const handleRowDoubleClick = (params) => {
        history.push(`/components/${params.row.id}`);
    };
    const selectedComponent = selectedComponentID
        ? editor.model.library.getComponentByID(selectedComponentID)
        : null;
    return (React.createElement(TwoColGridLayout, null,
        React.createElement(Base, { background: theme.palette.background.secondary, overflow: "hidden" },
            React.createElement(Base, { p: 5 },
                React.createElement(Input, { fullWidth: true, type: "search", placeholder: t('componentLibView.searchPlaceholder', 'Search library by component name'), value: searchInputValue, startAdornment: React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.hint }) })), onChange: (e) => {
                        setSearchInputValue(e.target.value);
                    } })),
            React.createElement(DataGrid, { rows: getRows(), columns: getColumns(), disableColumnMenu: true, disableColumnFilter: true, onRowClick: handleRowClick, onRowDoubleClick: handleRowDoubleClick, components: {
                    Row,
                }, classes: {
                    virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
                }, sx: {
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none',
                    },
                } })),
        selectedComponent && (React.createElement(Sidepanel, null,
            React.createElement(ComponentInfo, { component: selectedComponent })))));
});
function ComponentInfo(props) {
    const { component } = props;
    const history = useHistory();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(React.Fragment, null,
        React.createElement(GenericPanel, null,
            React.createElement(GenericPanelHeader, { header: t('componentLibView.info.title', 'Component Info') }),
            React.createElement(GenericPanelContent, { p: 4 },
                React.createElement(AttributeList, null,
                    React.createElement(AttributeRendererStatic, { name: t('componentLibView.info.id', 'Component ID'), value: component.id }),
                    React.createElement(AttributeRendererString, { name: t('componentLibView.info.name', 'Name'), value: component.name, onValueChange: (v) => component.setName(v) })),
                React.createElement(Base, { mt: 6, display: "grid", gridGap: 4 },
                    React.createElement(Button, { onClick: () => {
                            history.push(`/components/${component.id}`);
                        } }, t('componentLibView.info.editBtn', 'Edit content')),
                    React.createElement(Button, { color: "error", variant: "text", onClick: () => {
                            component.remove();
                        } }, t('componentLibView.info.deleteBtn', 'Delete from library'))))),
        React.createElement(GenericPanel, null,
            React.createElement(GenericPanelHeader, { header: t('componentLibView.info.instanceSectionTitle', 'Instances') }),
            React.createElement(GenericPanelContent, null,
                React.createElement(ComponentInstanceList, { component: component })))));
}
const ComponentInstanceList = observer(function ComponentInstanceList(props) {
    const { component } = props;
    const history = useHistory();
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const theme = useTheme();
    const [filterInputValue, setFilterInputValue] = React.useState('');
    if (!(component instanceof ContentBuilderLibraryComponent)) {
        return React.createElement("span", null, `instance list not supported for this type of component`);
    }
    const validInstances = [...component.instances.values()]
        .map((it) => {
        var _a;
        const nodeparent = ((_a = it.context) === null || _a === void 0 ? void 0 : _a.parent) instanceof BaseGraphEditorNode ? it.context.parent : undefined;
        return {
            nodeparent,
            instance: it,
            name: nodeparent ? getNodeName(nodeparent) : '',
        };
    })
        .filter((it) => it.nodeparent);
    const filteredInstances = validInstances.filter((it) => {
        if (filterInputValue === '')
            return true;
        return stringIncludes(it.name, filterInputValue);
    });
    return (React.createElement(Base, { p: 4 },
        React.createElement(Input, { fullWidth: true, placeholder: t('compInstanceList.filterPlaceholder', 'Filter'), type: "search", startAdornment: React.createElement(InputAdornment, { position: "start" },
                React.createElement(SearchRoundedIcon, { fontSize: "small", sx: { color: theme.palette.text.hint } })), value: filterInputValue, onChange: (e) => setFilterInputValue(e.target.value) }),
        filterInputValue && (React.createElement(Typography, { variant: "footnote", mt: 1, mb: 2 }, filteredInstances.length === 0
            ? t('compInstanceList.noInstancesMsg', 'No instances found')
            : t('compInstanceList.instancesFound', `Found {{foundCount}} of {{totalCount}}`, {
                foundCount: filteredInstances.length,
                totalCount: validInstances.length,
            }))),
        React.createElement(Base, { display: "grid", gridGap: 3, mt: 4 }, filteredInstances.map((it) => {
            return (React.createElement(ButtonBase, { display: "grid", gridAutoFlow: "column", gridGap: 3, title: t('compInstanceList.locateOnCanvasBtn', 'Locate node on canvas - {{name}}', {
                    name: it.name,
                }), justifyContent: "flex-start", onClick: () => {
                    history.push('/graph');
                    editor.dispatch([
                        {
                            type: 'canvas.fitNodesIntoView',
                            data: { nodeIDs: [it.nodeparent.id] },
                        },
                        {
                            type: 'canvas.selectEntities',
                            data: {
                                ids: [it.nodeparent.id],
                                type: 'replace',
                            },
                        },
                    ]);
                } },
                React.createElement(Typography, { variant: "body2", color: "default", noWrap: true }, it.name)));
        }))));
});
function getNodeName(node) {
    return [node.branchContext.name, node.name, node.id.substring(0, 8).toUpperCase()]
        .filter((it) => !!it)
        .join(' - ');
}
