var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { debounce } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { CallTagsValue, api, casesOutputConfigurationOptions, isGetCaseOutputType, } from '@corti/lib/coreApiService';
import { Observer } from '@corti/observer';
import { FilterBuilderUtils } from 'browser/components/FilterBuilder';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { CaseTableModel } from './CaseTableModel';
import { CasesQuickFilterState } from './CasesFiltering/QuickFiltering/CasesQuickFilterState';
import { CasesTableEditorState } from './CasesTableEditorView/CasesTableEditorState';
import { caseFilterToFilterBuilderState, filterBuilderStatetoValidQuery } from './transformers';
export class CasesViewState {
    constructor() {
        Object.defineProperty(this, "CASES_PAGE_SIZE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 50
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "isLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nextPageCursor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cases", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "casesTableScrollPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "casesFilterDescriptor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "advancedFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "casesQuickFilterState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "casesTableEditorState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "visitedCases", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.error = undefined;
                this.isLoading = false;
                this.casesTableScrollPosition = 0;
                this.cases = [];
                this.caseFilters = [];
                this.casesFilterDescriptor = undefined;
                this.hasNextPage = false;
                this.nextPageCursor = undefined;
                this.visitedCases = [];
                this.advancedFilter = FilterBuilderUtils.createEmptyState();
            }
        });
        Object.defineProperty(this, "resetAllFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.advancedFilter = FilterBuilderUtils.createEmptyState();
                void this.loadCases();
            }
        });
        Object.defineProperty(this, "setCaseAsVisited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (caseID) => {
                this.visitedCases.push(caseID);
            }
        });
        Object.defineProperty(this, "saveCasesTableScrollPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (position) => {
                this.casesTableScrollPosition = position;
            }
        });
        Object.defineProperty(this, "setAdvancedFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (filterValue) => {
                this.advancedFilter = filterValue;
            }
        });
        Object.defineProperty(this, "applyAdvancedFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if ((this.casesInput.filter && !this.isAdvancedFilterValid) || this.isAdvancedFilterValid) {
                    this.nextPageCursor = undefined;
                    this.hasNextPage = false;
                    void this.loadCasesDebounced();
                }
            }
        });
        Object.defineProperty(this, "applySavedFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (filterValue) => {
                const filterBuilderState = caseFilterToFilterBuilderState(filterValue);
                this.setAdvancedFilter(filterBuilderState);
                this.applyAdvancedFilter();
            }
        });
        Object.defineProperty(this, "loadCases", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                try {
                    runInAction(() => {
                        this.error = undefined;
                        this.isLoading = true;
                        this.hasNextPage = false;
                        this.nextPageCursor = undefined;
                        this.cases = [];
                    });
                    const res = await api.cases.getCasesForTable(this.casesInput, this.casesOutputConfiguration);
                    runInAction(() => {
                        this.cases = res.items.map((i) => new CaseTableModel(i));
                        this.nextPageCursor = res.cursor;
                        this.hasNextPage = res.hasNextPage;
                    });
                }
                catch (err) {
                    runInAction(() => {
                        if (err instanceof Error) {
                            this.error = err;
                        }
                    });
                }
                finally {
                    runInAction(() => {
                        this.isLoading = false;
                    });
                }
            }
        });
        Object.defineProperty(this, "loadCasesDebounced", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(this.loadCases, 300)
        });
        Object.defineProperty(this, "loadNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const res = await api.cases.getCasesForTable(this.casesInput, this.casesOutputConfiguration);
                runInAction(() => {
                    this.cases = [...this.cases, ...res.items.map((i) => new CaseTableModel(i))];
                    this.nextPageCursor = res.cursor;
                    this.hasNextPage = res.hasNextPage;
                });
            }
        });
        Object.defineProperty(this, "loadCasesFilterDescriptor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                this.error = undefined;
                const res = await api.cases.getCasesFilterDescriptor(authStore.organization.id);
                runInAction(() => {
                    this.casesFilterDescriptor = res;
                });
                this.observer.fireEvent('casesFilterDescriptorLoaded');
            }
        });
        Object.defineProperty(this, "loadCaseFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const userID = authStore.currentUserID;
                if (userID) {
                    const res = await api.caseFilters.getUserCaseFilters(authStore.organization.id, userID);
                    runInAction(() => {
                        this.caseFilters = res;
                    });
                }
            }
        });
        Object.defineProperty(this, "saveCurrentCaseFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                const userID = authStore.currentUserID;
                if (userID && this.isAdvancedFilterValid) {
                    const newFilterInput = {
                        organizationID: authStore.organization.id,
                        userID,
                        data: filterBuilderStatetoValidQuery(this.casesFilterDescriptor, this.advancedFilter),
                        name: input.name,
                        visibility: input.visibility,
                        subscriptions: input.isSubscribed ? [{ userID }] : undefined,
                    };
                    const res = await api.caseFilters.saveCaseFilter(newFilterInput);
                    runInAction(() => {
                        this.caseFilters.unshift(res);
                    });
                    trackerService.track('[Explore] cases filter saved', {
                        overall_junction: res.data.junction,
                        queries: res.data.entities.map((it) => ({
                            id: it.id,
                            junction: it.junction,
                            predicates: it.predicates.map((p) => ({
                                attributeID: p.attributeID,
                                comparison: p.comparison,
                                value: p.value,
                            })),
                        })),
                    });
                    input.isSubscribed &&
                        trackerService.track('[Explore] cases filter subscribed', { filter: { id: res.id } });
                }
            }
        });
        Object.defineProperty(this, "deleteCaseFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (filterID) => {
                const filterToDeleteIdx = this.caseFilters.findIndex((filter) => filter.id === filterID);
                await api.caseFilters.deleteCaseFilter(filterID);
                runInAction(() => {
                    this.caseFilters.splice(filterToDeleteIdx, 1);
                });
                trackerService.track('[Explore] cases filter deleted', { filter: { id: filterID } });
            }
        });
        Object.defineProperty(this, "subscribeToCaseFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (filterID) => {
                const userID = authStore.currentUserID;
                const filterToSubscribe = this.caseFilters.find((filter) => filter.id === filterID);
                if (userID && filterToSubscribe) {
                    const res = await api.caseFilters.subscribeToCaseFilter(filterID, userID);
                    runInAction(() => {
                        filterToSubscribe.subscriptions = [{ id: res.id }];
                    });
                    trackerService.track('[Explore] cases filter subscribed', { filter: { id: filterID } });
                }
            }
        });
        Object.defineProperty(this, "unsubscribeFromCaseFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (filterID) => {
                const filterToUnsubscribe = this.caseFilters.find((filter) => filter.id === filterID);
                if (filterToUnsubscribe) {
                    const subscriptionID = filterToUnsubscribe.subscriptions[0].id;
                    await api.caseFilters.unsubscribeFromCaseFilter(subscriptionID);
                    runInAction(() => {
                        filterToUnsubscribe.subscriptions = [];
                    });
                    trackerService.track('[Explore] cases filter unsubscribed', { filter: { id: filterID } });
                }
            }
        });
        Object.defineProperty(this, "isCaseVisited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (caseID) => {
                return this.visitedCases.includes(caseID);
            }
        });
        makeObservable(this);
        authStore.onLogout(this.resetState);
        this.resetState();
        runInAction(() => {
            this.casesTableEditorState = new CasesTableEditorState();
            this.casesQuickFilterState = new CasesQuickFilterState();
        });
    }
    get isAdvancedFilterValid() {
        return !!filterBuilderStatetoValidQuery(this.casesFilterDescriptor, this.advancedFilter);
    }
    get casesInput() {
        return {
            organizationID: authStore.organization.id,
            first: this.CASES_PAGE_SIZE,
            cursor: this.nextPageCursor,
            filter: this.casesFilterDescriptor && this.advancedFilter
                ? filterBuilderStatetoValidQuery(this.casesFilterDescriptor, this.advancedFilter)
                : undefined,
        };
    }
    get casesOutputConfiguration() {
        const configuration = {};
        casesOutputConfigurationOptions.forEach((it) => {
            configuration[it] = false;
        });
        this.casesTableEditorState.tableColumns.forEach((c) => {
            if (Object.values(CallTagsValue).includes(c.id)) {
                configuration['callTags'] = true;
            }
            if (isGetCaseOutputType(c.id)) {
                configuration[c.id] = true;
            }
        });
        const isCustomProperties = this.casesTableEditorState.tableColumns
            .map((c) => this.casesTableEditorState.getColumnDescriptorById(c.id))
            .some((c) => (c === null || c === void 0 ? void 0 : c.entityType) === 'customProperty');
        if (isCustomProperties) {
            configuration['customProperties'] = true;
        }
        return configuration;
    }
    onCasesFilterDescriptorLoaded(cb) {
        return this.observer.on('casesFilterDescriptorLoaded', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CasesViewState.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Error !== "undefined" && Error) === "function" ? _a : Object)
], CasesViewState.prototype, "error", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CasesViewState.prototype, "hasNextPage", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], CasesViewState.prototype, "nextPageCursor", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CasesViewState.prototype, "cases", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CasesViewState.prototype, "casesTableScrollPosition", void 0);
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], CasesViewState.prototype, "casesFilterDescriptor", void 0);
__decorate([
    observable.ref,
    __metadata("design:type", Object)
], CasesViewState.prototype, "advancedFilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CasesViewState.prototype, "caseFilters", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof CasesQuickFilterState !== "undefined" && CasesQuickFilterState) === "function" ? _b : Object)
], CasesViewState.prototype, "casesQuickFilterState", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof CasesTableEditorState !== "undefined" && CasesTableEditorState) === "function" ? _c : Object)
], CasesViewState.prototype, "casesTableEditorState", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CasesViewState.prototype, "visitedCases", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "resetState", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "resetAllFilters", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "setCaseAsVisited", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "saveCasesTableScrollPosition", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "setAdvancedFilter", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "applyAdvancedFilter", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "applySavedFilter", void 0);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CasesViewState.prototype, "isAdvancedFilterValid", null);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "loadCases", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "loadNextPage", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CasesViewState.prototype, "casesInput", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CasesViewState.prototype, "casesOutputConfiguration", null);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "loadCasesFilterDescriptor", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "saveCurrentCaseFilter", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "deleteCaseFilter", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "subscribeToCaseFilter", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CasesViewState.prototype, "unsubscribeFromCaseFilter", void 0);
