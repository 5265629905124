export const flowEventUtils = {
    isFlowCommand: (event) => {
        return ['triage-flow-fact-values-update'].includes(event.event);
    },
    isFlowStateChangeEvent: (event) => {
        return [
            'triage-flow-action-block-triggered',
            'triage-flow-alert-node-opened',
            'triage-flow-step-added',
            'triage-flow-active-step-changed',
            'triage-flow-fact-values-updated',
            'triage-flow-block-values-updated',
            'triage-flow-value-collector-block-values-updated',
            'checklist-updated',
            'checklist-entry-updated',
        ].includes(event.event);
    },
    // Flow events dont have ids.
    // This workaround is unreliable because some event could be emitted at the same microsecond.
    genID: (event) => {
        return event.data.datetime + event.event;
    },
};
