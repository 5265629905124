import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import React from 'react';
import { IconButton } from 'lib/cortiUI';
import { useNavigation } from '../../context';
import { DiscardDialog } from '../components';
import { useReview } from '../context';
export const BackButton = () => {
    const { reviewID } = useReview();
    const { setView } = useNavigation();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    function handleNavigateBack() {
        if (reviewID) {
            setIsDialogOpen(true);
        }
        else {
            setView('assessment');
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleNavigateBack, icon: React.createElement(ArrowBackIosOutlinedIcon, null), sx: { m: 1 } }),
        React.createElement(DiscardDialog, { isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), onDiscard: () => {
                setIsDialogOpen(false);
                setView('assessment');
            } })));
};
