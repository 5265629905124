var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
var _b;
import { findLast } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { Observer } from '@corti/observer';
export class CallPlayerState {
    constructor(call) {
        Object.defineProperty(this, "call", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: call
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        // Only updated 4x every second and only used to display current progress
        Object.defineProperty(this, "currentTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPlaying", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volume", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "duration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playbackRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "zoom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isPlaying = false;
                this.volume = 1;
                this.currentTime = 0;
                this.duration = 0;
                this.isLoading = true;
                this.playbackRate = 1;
                this.zoom = 100;
                this.error = undefined;
            }
        });
        Object.defineProperty(this, "togglePlay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isPlaying = !this.isPlaying;
            }
        });
        Object.defineProperty(this, "setError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (err) => {
                this.error = err;
            }
        });
        Object.defineProperty(this, "setZoom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pxPerSec) => {
                if (pxPerSec <= 0) {
                    this.zoom = 0;
                }
                else if (pxPerSec >= CallPlayerState.ZOOM_MAX) {
                    this.zoom = CallPlayerState.ZOOM_MAX;
                }
                else {
                    this.zoom = pxPerSec;
                }
            }
        });
        Object.defineProperty(this, "setCurrentTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (seconds) => {
                if (seconds < 0) {
                    return;
                }
                if (seconds > this.duration) {
                    this.currentTime = this.duration;
                    return;
                }
                this.currentTime = seconds;
            }
        });
        Object.defineProperty(this, "setCurrentTimeToClosestEntryOffset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (offsetsOfEntries, seekDirection) => {
                if (!offsetsOfEntries)
                    return;
                offsetsOfEntries.sort((a, b) => a - b);
                const currentTime = Math.round(this.currentTime * 1000) / 1000;
                switch (seekDirection) {
                    case 'backward': {
                        const prevOffset = findLast(offsetsOfEntries, (offset) => currentTime > offset);
                        this.setCurrentTime(prevOffset || 0);
                        break;
                    }
                    case 'forward': {
                        const nextOffset = offsetsOfEntries.find((offset) => currentTime < offset);
                        this.setCurrentTime(nextOffset || this.duration);
                        break;
                    }
                }
            }
        });
        Object.defineProperty(this, "setDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (seconds) => {
                this.duration = seconds;
                this.observer.fireEvent('durationChange', seconds);
            }
        });
        Object.defineProperty(this, "setIsPlaying", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isPlaying) => {
                this.isPlaying = isPlaying;
            }
        });
        Object.defineProperty(this, "setIsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isLoading) => {
                this.isLoading = isLoading;
            }
        });
        Object.defineProperty(this, "setPlaybackRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rate) => {
                this.playbackRate = rate;
            }
        });
        makeObservable(this);
        this.resetState();
    }
    get isControlsDisabled() {
        return this.isLoading || !!this.error || this.duration === 0;
    }
    onDurationChange(cb) {
        return this.observer.on('durationChange', cb);
    }
}
_a = CallPlayerState;
Object.defineProperty(CallPlayerState, "ZOOM_STEP", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 25
});
Object.defineProperty(CallPlayerState, "ZOOM_MIN", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: _a.ZOOM_STEP
});
Object.defineProperty(CallPlayerState, "ZOOM_MAX", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 400
});
Object.defineProperty(CallPlayerState, "PLAYBACK_RATE_MULTIPLIERS", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: [0.5, 0.75, 1, 1.25, 1.5, 1.75]
});
__decorate([
    observable,
    __metadata("design:type", Number)
], CallPlayerState.prototype, "currentTime", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CallPlayerState.prototype, "isPlaying", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CallPlayerState.prototype, "volume", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CallPlayerState.prototype, "duration", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CallPlayerState.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CallPlayerState.prototype, "playbackRate", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CallPlayerState.prototype, "zoom", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Error !== "undefined" && Error) === "function" ? _b : Object)
], CallPlayerState.prototype, "error", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "resetState", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "togglePlay", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setZoom", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setCurrentTime", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setCurrentTimeToClosestEntryOffset", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setDuration", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setIsPlaying", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setIsLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CallPlayerState.prototype, "setPlaybackRate", void 0);
