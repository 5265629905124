import { FileDownload } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Toolbar } from '@mui/material';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { slugify } from '@corti/strings';
import { IconButton, Tooltip, Typography } from 'lib/cortiUI';
import { generateChecklistJSON } from './export';
import { useChecklistView } from './useChecklistView';
export function ChecklistToolbar() {
    const { t } = useTranslation('libGraphEditor');
    const selected = useChecklistView(({ selected }) => selected);
    const checklists = useChecklistView(({ checklists }) => checklists);
    const startDelete = useChecklistView(({ startDelete }) => startDelete);
    const selectionCount = selected.length;
    const exportSelected = () => {
        const selectedChecklists = checklists.filter((c) => selected.includes(c.id));
        const fileContents = generateChecklistJSON(selectedChecklists);
        const blob = new Blob([fileContents], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // File name is checklist name slugs joined by underscores
        const fileName = selectedChecklists.map((c) => slugify(c.name)).join('_');
        link.download = `${fileName}.json`;
        link.click();
    };
    const deleteLabel = t('checklistsView.deleteSelectedChecklists', 'Delete selected checklists');
    const exportLabel = t('checklistsView.exportSelectedChecklists', 'Export selected checklists');
    return (React.createElement(Toolbar, { variant: "dense", sx: {
            pl: { sm: 4 },
            pr: { xs: 4 },
            py: 3,
            bgcolor: (theme) => theme.palette.primary.main,
            color: 'white',
        } },
        React.createElement(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div" }, t('checklistsView.selectionCount', {
            count: selectionCount,
            defaultValue_one: '{{count}} selected',
            defaultValue_other: '{{count}} selected',
        })),
        React.createElement(Tooltip, { title: deleteLabel, onClick: startDelete },
            React.createElement(IconButton, { color: "inherit", "aria-label": deleteLabel, icon: React.createElement(DeleteIcon, null) })),
        React.createElement(Tooltip, { title: exportLabel, onClick: exportSelected },
            React.createElement(IconButton, { color: "inherit", "aria-label": exportLabel, icon: React.createElement(FileDownload, null) }))));
}
