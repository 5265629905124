import React from 'react';
import { useHistory } from 'react-router';
import { load as loadFromIDB } from './indexDb';
import { saveCurrentGraph } from './storage';
export function PreloadFromIndexDBPage() {
    const history = useHistory();
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        loadFromIDB()
            .then((g) => {
            return saveCurrentGraph(g);
        })
            .then(() => {
            history.replace(`/emergency-triage`);
        })
            .catch((err) => {
            setError(`Error when loading graph from index db. ${String(err)}`);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (error) {
        return React.createElement("div", null, error);
    }
    return null;
}
