import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Button, Modal } from 'lib/cortiUI';
import { useDefinitionContext } from '../context';
import { AnnotationsVisibilityModalContent } from './VisibilityConfigurationModalContent';
export const VisibilityConfigurationTrigger = () => {
    const { definitions, isDataLoading } = useDefinitionContext();
    const theme = useTheme();
    const { t } = useTranslation('annotationManagement');
    const [isVisibilityModalOpen, setIsVisibilityModalOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { disabled: isDataLoading || definitions.length === 0, color: "primary", variant: "outlined", onClick: () => setIsVisibilityModalOpen(true), "data-cy": "set-annotations-visibility-btn" }, t('setVisibility', 'Set Visibility')),
        React.createElement(Modal, { open: isVisibilityModalOpen, onClose: () => setIsVisibilityModalOpen(false), keepMounted: false, className: css({
                backgroundColor: theme.palette.background.card,
            }), "data-cy": "set-annotations-visibility-modal" },
            React.createElement(AnnotationsVisibilityModalContent, { timelineEntryDefinitions: definitions, onClose: () => setIsVisibilityModalOpen(false) }))));
};
