import ClearRounded from '@mui/icons-material/ClearRounded';
import React from 'react';
import { useMediaQuery } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, IconButton, Typography } from 'lib/cortiUI';
import { useToolbar } from './context';
export function ToolPanelContent({ title, children, }) {
    const theme = useTheme();
    const { setOpenToolID } = useToolbar();
    const isMediumScreen = useMediaQuery(`(max-width: ${theme.breakpoints.values.lg}px)`);
    return (React.createElement(Base, { className: css({
            zIndex: theme.zIndex.drawer,
            width: isMediumScreen ? '310px' : '360px',
            height: '100%',
            backgroundColor: theme.palette.background.default,
            borderLeft: `1px solid ${theme.palette.background.divider}`,
            display: 'grid',
            gridTemplateRows: '80px 1fr',
            overflow: 'overlay',
        }) },
        React.createElement(Base, { className: css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '24px',
            }) },
            React.createElement(Typography, { variant: "h6", color: "default", fontSize: 20, fontWeight: 600 }, title),
            React.createElement(IconButton, { size: "small", icon: React.createElement(ClearRounded, null), onClick: () => setOpenToolID(null) })),
        React.createElement(Base, { className: getScrollerCss({ theme }) }, children)));
}
