import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base, Button, Typography } from 'lib/cortiUI';
export function UpdateStatusBanner({ swWrapper }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [shouldShowBanner, setShouldShowBanner] = React.useState(() => {
        return swWrapper.currentState() === 'waiting' ? true : false;
    });
    React.useEffect(() => {
        const u = swWrapper.onStateChange((s) => {
            if (s.type === 'waiting') {
                setShouldShowBanner(true);
            }
        });
        return () => {
            u();
        };
    }, [swWrapper]);
    if (!shouldShowBanner) {
        return null;
    }
    return (React.createElement(Base, { display: "flex", alignItems: 'center', justifyContent: "center", px: 5, py: 2, background: theme.palette.primary.outlinedHoverBackground, gap: 7 },
        React.createElement(Typography, { variant: "h6", color: "primary" }, t('updateStatusBanner.newVersionAvailableText', 'New version of the app')),
        React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: () => {
                swWrapper.skipWaiting();
            } }, t('updateStatusBanner.reloadBtn', 'Reload app to get new version'))));
}
