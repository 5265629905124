import { gql } from '@apollo/client';
const orgFeatureFragment = gql `
  fragment TranslatedFeature on TranslatedFeature {
    featureID
    translation
  }
`;
const organizationSecuritySettingsFragment = gql `
  fragment OrganizationSecuritySettings on OrganizationSecuritySettings {
    oldPasswordRequiredForPasswordReset
    passwordRequirements {
      minLength
      numberOfLowercase
      numberOfCapitals
      numberOfNumbers
      numberOfSymbols
    }
  }
`;
const orgTriageSettingsFragment = gql `
  fragment OrganizationTriageSettings on OrganizationTriageSettings {
    activeProtocolGraphVersionID
    automaticProgressToNextStep
  }
`;
const orgTriageConfigFragment = gql `
  fragment OrganizationTriageConfig on OrganizationTriageConfig {
    isSearchDisabled
    isCommentsDisabled
    automaticSessionTakeoverAccept
    automaticSessionTakeoverAcceptTimeout
    isAlertWidgetEnabled
    ownerAutoJoinNewSessionWithLiveCall
    autoLeaveSessionWithoutLiveCall
    isCopyButtonEnabled
    isManualSelectDisabled
  }
`;
export const orgFragment = gql `
  fragment Organization on Organization {
    id
    name
    clientSettings
    triageSettings {
      ...OrganizationTriageSettings
    }
    features {
      ...TranslatedFeature
    }
    organizationSecuritySettings {
      ...OrganizationSecuritySettings
    }
    config {
      triage {
        ...OrganizationTriageConfig
      }
    }
  }
  ${organizationSecuritySettingsFragment}
  ${orgFeatureFragment}
  ${orgTriageSettingsFragment}
  ${orgTriageConfigFragment}
`;
function parseServerOrgClientSettings(clientSettings) {
    const parsed = {
        triageCloseTabOnSessionEnd: !!clientSettings && clientSettings['triageCloseTabOnSessionEnd'] === true,
        allowSessionRecording: !!clientSettings && clientSettings['allowSessionRecording'] === true,
    };
    if (clientSettings) {
        if (typeof clientSettings.dateFormat === 'string') {
            parsed.dateFormat = clientSettings.dateFormat;
        }
        if (typeof clientSettings.timeFormat === 'string') {
            parsed.timeFormat = clientSettings.timeFormat;
        }
        if (typeof clientSettings.timeZone === 'string') {
            parsed.timeZone = clientSettings.timeZone;
        }
        if (typeof clientSettings.customPropertyAsCaseID === 'string') {
            parsed.customPropertyAsCaseID = clientSettings.customPropertyAsCaseID;
        }
        if (typeof clientSettings.defaultAppName === 'string') {
            parsed.defaultAppName = clientSettings.defaultAppName;
        }
        if (Array.isArray(clientSettings.casesTableColumns)) {
            parsed.casesTableColumns = clientSettings.casesTableColumns;
        }
        if (typeof clientSettings.teamPerformanceTargetScore === 'number') {
            parsed.teamPerformanceTargetScore = clientSettings.teamPerformanceTargetScore;
        }
        if (Array.isArray(clientSettings.disabledSCVTimelineEntryFilterDefinitions)) {
            parsed.disabledSCVTimelineEntryFilterDefinitions =
                clientSettings.disabledSCVTimelineEntryFilterDefinitions;
        }
    }
    return parsed;
}
export function toOrgConfig(serverConfig) {
    const automaticSessionTakeoverAcceptTimeout = Number(serverConfig.triage.automaticSessionTakeoverAcceptTimeout);
    return {
        triage: Object.assign(Object.assign({}, serverConfig.triage), { automaticSessionTakeoverAcceptTimeout: !Number.isNaN(automaticSessionTakeoverAcceptTimeout)
                ? automaticSessionTakeoverAcceptTimeout
                : 0 }),
    };
}
export class OrganizationService {
    constructor(client) {
        Object.defineProperty(this, "client", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: client
        });
    }
    async updateTriageSettings(orgID, input) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation updateOrganizationTriageSettings($input: OrganizationTriageSettingsInput!) {
          updateOrganizationTriageSettings(input: $input) {
            ...OrganizationTriageSettings
          }
        }
        ${orgTriageSettingsFragment}
      `,
            variables: {
                input: Object.assign(Object.assign({}, input), { organizationID: orgID }),
            },
        });
        return res.data.updateOrganizationTriageSettings;
    }
    async updateOrganizationName(organizationID, name) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation updateOrganization($organizationID: ID!, $name: String) {
          updateOrganization(organizationID: $organizationID, name: $name) {
            id
            name
          }
        }
      `,
            variables: {
                organizationID: organizationID,
                name: name,
            },
        });
        return res.data.updateOrganization.name;
    }
    async updateOrganizationClientSettings(organizationID, clientSettings) {
        const res = await this.client.mutate({
            mutation: gql `
        mutation updateOrganization($organizationID: ID!, $clientSettings: Map) {
          updateOrganization(organizationID: $organizationID, clientSettings: $clientSettings) {
            id
            clientSettings
          }
        }
      `,
            variables: {
                organizationID: organizationID,
                clientSettings: clientSettings,
            },
        });
        return parseServerOrgClientSettings(res.data.updateOrganization.clientSettings);
    }
}
