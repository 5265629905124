import { compact } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { repositories } from 'core/repositories';
import { Avatar, AvatarGroup, Base, Tooltip } from 'lib/cortiUI';
export const Assignees = ({ assignees }) => {
    const DISPLAY_LIMIT = 3;
    const { t } = useTranslation('tasks', { keyPrefix: 'task.assignees' });
    const assigneesUser = compact(assignees.map((assigneeID) => repositories.users.getUser(assigneeID)));
    return (React.createElement(Tooltip, { title: t('tooltip', 'Also assigned to {{assignees}}', {
            assignees: assigneesUser.map((it) => it.name).join(', '),
        }) },
        React.createElement(Base, null,
            React.createElement(AvatarGroup, { max: DISPLAY_LIMIT, additionalAvatar: { size: 'small' } }, assigneesUser.map((it) => (React.createElement(Avatar, { size: "small", key: it.id }, it.name)))))));
};
