import { observer } from 'mobx-react';
import React from 'react';
import { getTimeZone } from '@corti/date';
import { intl } from '@corti/i18n';
import { Card, Loading } from 'lib/cortiUI';
import { useTranscriptSearchViewContext } from '../../TranscriptSearchView/context';
import { TimeSeriesChart } from '../../components';
import { aggregateTimeSeriesDataByPeriod } from '../../utils';
import { useTranscriptQueryViewContext } from '../context';
import { ChartPanelHeader } from './ChartPanelHeader';
export const ChartsPanel = observer(function ChartsPanel() {
    const PERIOD_VARIANTS = [
        {
            id: 'daily',
            value: 1,
            text: intl.t('daily', 'Daily'),
        },
        {
            id: '7-days',
            value: 7,
            text: intl.t('dayCount', {
                count: 7,
                defaultValue_one: `{{ count }} day`,
                defaultValue_other: `{{ count }} days`,
            }),
        },
        {
            id: '28-days',
            value: 28,
            text: intl.t('dayCount', {
                count: 28,
                defaultValue_one: `{{ count }} day`,
                defaultValue_other: `{{ count }} days`,
            }),
        },
    ];
    const { utils } = useTranscriptSearchViewContext();
    const { viewModel } = useTranscriptQueryViewContext();
    const [periodVariant, setPeriodVariant] = React.useState(PERIOD_VARIANTS[0]);
    function changePeriodVariant(newPeriod) {
        setPeriodVariant(newPeriod);
    }
    async function loadCalls(input) {
        const { query } = viewModel.state;
        if (!query)
            return;
        const dateRange = input !== null && input !== void 0 ? input : utils.toAbsoluteDateRange(query.parameters.dateRange);
        if (!dateRange)
            return;
        const combinedUsers = utils.combineUserIDsAndTeamMemberIDs(query.parameters.tempUserIDs, query.parameters.tempTeamIDs);
        await viewModel.loadCalls({
            dateRange: dateRange,
            timeZone: getTimeZone(),
            termGroups: query.parameters.filteredTermGroups,
            userIDs: combinedUsers,
            protocolNameIDs: query.parameters.protocolNames,
        });
    }
    function getChartDataName(termGroup) {
        if (!termGroup || termGroup.terms.length === 0)
            return intl.t('transcriptSearchApp:timeSeriesChart.series', 'Series');
        return termGroup.terms.join(', ');
    }
    function getTimeSeriesData() {
        const { query, timeSeries } = viewModel.state;
        return timeSeries
            .filter((it) => it.dataPoints.length > 0)
            .map((it) => {
            var _a;
            return (Object.assign(Object.assign({}, it), { dataPoints: aggregateTimeSeriesDataByPeriod(periodVariant, it.dataPoints), termGroupColor: (_a = query === null || query === void 0 ? void 0 : query.parameters.getTermGroupByID(it.termGroupID)) === null || _a === void 0 ? void 0 : _a.style.color, title: getChartDataName(query === null || query === void 0 ? void 0 : query.parameters.getTermGroupByID(it.termGroupID)) }));
        });
    }
    if (viewModel.state.isLoadingTimeSeries) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", "data-cy": "transcript-query-view-charts-panel-loading" }));
    }
    if (!viewModel.state.query) {
        return null;
    }
    return (React.createElement(Card, { px: 6, pt: 8, pb: 6, display: "flex", flexDirection: "column", gap: 4, "data-cy": "transcript-query-view-charts-panel" },
        React.createElement(ChartPanelHeader, { activeChartType: "time-series", activePeriod: periodVariant, periodOptions: PERIOD_VARIANTS, onPeriodChange: changePeriodVariant }),
        React.createElement(TimeSeriesChart, { data: getTimeSeriesData(), onDateRangeChange: loadCalls })));
});
