import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Route } from 'lib/router';
import { CaseView } from '../CaseView';
import { SearchView } from './SearchView';
export const SearchApp = () => {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: match.path, exact: true, render: () => React.createElement(SearchView, null) }),
        React.createElement(Route, { path: `${match.path}/:interactionID`, render: () => React.createElement(CaseView, null), breadcrumb: ({ match }) => {
                return `${match.params.interactionID.substring(0, 8).toLocaleUpperCase()}`;
            } })));
};
