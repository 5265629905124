import { gql } from '@apollo/client';
export const getProtocolGraphsQuery = gql `
  query ProtocolGraphs($orgID: ID!) {
    protocolGraphs(organizationID: $orgID) {
      id
      name
    }
  }
`;
export const getActiveProtocolGraphIDQuery = gql `
  query protocolGraphVersion($versionID: String!) {
    protocolGraphVersion(id: $versionID) {
      id
      graph {
        id
      }
    }
  }
`;
//#endregion
