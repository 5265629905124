import React, { createContext } from 'react';
const initialState = {
    currentCallTime: 0,
    shouldUpdateAudioTime: false,
    changeCurrentCallTime: () => { },
};
const CallControlContext = createContext(initialState);
export const CallControlProvider = ({ children }) => {
    const [currentCallTime, setCurrentCallTime] = React.useState(0);
    const [shouldUpdateAudioTime, setShouldUpdateAudioTime] = React.useState(false);
    function handleCurrentCallTimeChange(value, shouldUpdateAudioTime = false) {
        setCurrentCallTime(value);
        setShouldUpdateAudioTime(shouldUpdateAudioTime);
    }
    const value = React.useMemo(() => ({
        currentCallTime,
        shouldUpdateAudioTime,
        changeCurrentCallTime: handleCurrentCallTimeChange,
    }), [currentCallTime, shouldUpdateAudioTime]);
    return React.createElement(CallControlContext.Provider, { value: value }, children);
};
export const useCallControls = () => {
    const context = React.useContext(CallControlContext);
    if (!context) {
        throw new Error('useCallControlsContext must be used within a CallControlProvider');
    }
    return context;
};
