import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Box, ButtonBase, Typography } from 'lib/cortiUI';
import { Item } from './Item';
const ChoiceHint = ({ timelineEntry, label, onClick }) => {
    const handleChoiceClick = () => onClick(timelineEntry);
    return (React.createElement(ButtonBase, { key: timelineEntry.id, onClick: handleChoiceClick },
        React.createElement(Item, { borderColor: timelineEntry.definition.style.color, background: "transparent", clickable: true },
            timelineEntry.definition.text && (React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "row", overflow: "hidden", mb: 2 },
                React.createElement(Typography, { noWrap: true, uppercase: true, color: "secondaryText", variant: "caption", mr: 2 }, timelineEntry.definition.text))),
            React.createElement(Box, { flexDirection: "row" },
                React.createElement(Typography, { noWrap: true, variant: "body2" }, label)))));
};
export const ChoiceHintsList = ({ items, onItemClick }) => {
    const { t } = useTranslation();
    return (React.createElement(Box, { pl: 8, gap: 2 },
        React.createElement(Typography, { color: "default", variant: "subtitle2" }, t('feedbackForm.choiceHintsTitle', 'Detections')),
        items
            .sort((a, b) => a.timelineEntry.startDatetime.getTime() - b.timelineEntry.startDatetime.getTime())
            .map(({ timelineEntry, label }) => {
            return (React.createElement(ChoiceHint, { key: timelineEntry.id, onClick: onItemClick, label: label, timelineEntry: timelineEntry }));
        })));
};
