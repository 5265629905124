import { Clear } from '@mui/icons-material';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Button, IconButton, Input, Typography } from 'lib/cortiUI';
import { AttributeList } from 'lib/graphEditor/ui';
export const CustomPropertiesRenderer = observer(function CustomPropertiesRenderer({ customProperties, }) {
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(Base, null,
        React.createElement(AttributeList, { headerTitle: t('contentBuilder.elementInfo.customProperties', 'Custom Attributes') },
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2 },
                React.createElement(Base, { display: "grid", gridTemplateColumns: '1fr 1fr 50px', gap: 2 },
                    React.createElement(Typography, { flexGrow: 1 }, t('contentBuilder.elementInfo.customPropertyKey', 'Key')),
                    React.createElement(Typography, { flexGrow: 1 }, t('contentBuilder.elementInfo.customPropertyValue', 'Value')),
                    React.createElement(Base, { width: 50 })),
                customProperties.items.map((attr) => {
                    return (React.createElement(Base, { key: attr.id, display: "grid", gridTemplateColumns: '1fr 1fr 50px', gap: 2 },
                        React.createElement(Input, { "data-cy": "custom-property-key", id: attr.id, name: attr.id, type: "text", value: attr.key, fullWidth: true, onChange: (e) => {
                                attr.setKey(e.target.value);
                            } }),
                        React.createElement(Input, { "data-cy": "custom-property-value", id: attr.id, name: attr.id, type: "text", value: attr.value, fullWidth: true, onChange: (e) => {
                                attr.setValue(e.target.value);
                            } }),
                        React.createElement(IconButton, { icon: React.createElement(Clear, { fontSize: "small" }), size: "small", className: css({ flexShrink: 0 }), onClick: () => {
                                customProperties.removeProperty(attr.id);
                            } })));
                }))),
        React.createElement(Button, { "data-cy": "add-custom-property-button", variant: "text", color: "primary", onClick: () => {
                customProperties.addProperty();
            } }, t('contentBuilder.elementInfo.addCustomPropertyBtn', 'Add attribute'))));
});
