var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { escapeRegExp } from '@corti/strings';
export class LogicRefContext {
    constructor(editor) {
        Object.defineProperty(this, "editor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: editor
        });
        makeObservable(this);
    }
    get items() {
        return new Map([...this.blockItems, ...this.factItems]);
    }
    async queryItems(query, options = {}) {
        let allItems = [];
        if (options.includeBlocks) {
            allItems = [...allItems, ...this.blockItems.values()];
        }
        if (options.includeFacts) {
            allItems = [...allItems, ...this.factItems.values()];
        }
        const result = chain(allItems)
            .filter((it) => {
            var _a, _b;
            if (it.type === 'block') {
                it.isLocal = (_b = (_a = options.context) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.contentBuilderContext.hasBlock(it.backingModel);
                if (it.isLocal) {
                    it.description = `local ${it.isComponent ? 'component' : 'block'}`;
                }
            }
            const rank = getItemRank(query, it);
            it.rank = rank;
            return rank > 1;
        })
            .orderBy((item) => {
            return item.rank;
        }, ['desc'])
            .take(20)
            .value();
        return result;
    }
    getRefItemByID(id) {
        return this.items.get(id);
    }
    getRefItemUsages(id) {
        const result = new Map();
        const refItem = this.getRefItemByID(id);
        if (!refItem)
            return result;
        const allGates = this.getLogicGates();
        for (const gate of allGates.values()) {
            if (gate.hasExpressionVariable(refItem.value)) {
                result.set(gate.id, gate);
            }
        }
        return result;
    }
    get model() {
        return this.editor.model;
    }
    get factItems() {
        const result = new Map();
        for (const f of this.model.facts.items.values()) {
            const value = `fact:${f.id}`;
            result.set(value, {
                type: 'fact',
                value: `fact:${f.id}`,
                text: f.name,
                altText: f.id,
                description: f.type,
                backingModel: f,
            });
        }
        return result;
    }
    get blockItems() {
        const result = new Map();
        this.editor.model.elementContext.getElements().forEach((el) => {
            el.wrappedElement.referenceableItems.forEach((refItem) => {
                var _a;
                const viewnode = (_a = refItem.refItemTarget.context) === null || _a === void 0 ? void 0 : _a.parent;
                result.set(refItem.refItemValue, {
                    type: 'block',
                    value: refItem.refItemValue,
                    text: refItem.refItemText,
                    altText: refItem.refItemContextLabel,
                    description: viewnode ? viewnode.branchContext.name : 'component',
                    backingModel: refItem.refItemTarget,
                    isComponent: !viewnode,
                });
            });
        });
        return result;
    }
    getLogicGates() {
        const result = new Map();
        const viewnodes = this.model.getNodesByType('viewnode');
        for (const node of viewnodes.values()) {
            node.logicGates.forEach((g) => {
                result.set(g.id, g);
            });
        }
        return result;
    }
}
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object),
    __metadata("design:paramtypes", [])
], LogicRefContext.prototype, "items", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_b = typeof Map !== "undefined" && Map) === "function" ? _b : Object),
    __metadata("design:paramtypes", [])
], LogicRefContext.prototype, "factItems", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_c = typeof Map !== "undefined" && Map) === "function" ? _c : Object),
    __metadata("design:paramtypes", [])
], LogicRefContext.prototype, "blockItems", null);
function getItemRank(query, item) {
    const queryTokens = query.split('/');
    let rank = 0;
    for (let token of queryTokens) {
        const r = rankSingleQueryToken(token, item);
        // shortcut
        if (r === 0) {
            return 0;
        }
        rank += r;
    }
    if (item.isLocal) {
        rank += 1;
    }
    return rank;
}
function rankSingleQueryToken(token, item) {
    var _a, _b;
    if (item.text.match(new RegExp(escapeRegExp(token), 'gi'))) {
        return 4;
    }
    if ((_a = item.altText) === null || _a === void 0 ? void 0 : _a.match(new RegExp(escapeRegExp(token), 'gi'))) {
        return 2;
    }
    if ((_b = item.description) === null || _b === void 0 ? void 0 : _b.match(new RegExp(escapeRegExp(token), 'gi'))) {
        return 2;
    }
    return 0;
}
