export const DEFAULT_SETTINGS = {
    formatDate: (value) => new Date(value).toISOString(),
    isAutoProgressToNextStepEnabled: false,
    isFlowValueCollectorCopyEnabled: false,
    isAutoSessionTakeoverEnabled: false,
    isAutoSessionLeaveWithoutLiveCallEnabled: false,
    autoSessionTakeoverTimeout: 0,
    isAlertsEnabled: true,
    isAlertsUIEnabled: true,
    isCommentsEnabled: true,
    isTakeoverEnabled: true,
    isSearchEnabled: true,
    isManualActionSelectEnabled: true,
    isFlowWindowingEnabled: true,
    isChecklistViewEnabled: true,
};
