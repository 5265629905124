import { intl } from '@corti/i18n';
import { createTheme } from '@corti/theme';
const light = createTheme();
const dark = createTheme({
    palette: {
        primary: {
            light: '#E6F3FB',
            main: '#67B7F7',
            dark: '#5EA3EF',
        },
        secondary: {
            light: '#FF99BB',
            main: '#FF6C9E',
            dark: '#BE476F',
        },
        success: {
            light: '#81C784',
            main: '#66BB6A',
            dark: '#388E3C',
        },
        error: {
            light: '#E57373',
            main: '#F44336',
            dark: '#C23F38',
        },
        warning: {
            light: '#FFB74D',
            main: '#FFA726',
            dark: '#F57C00',
        },
        info: {
            light: '#4FC3F7',
            main: '#29B6F6',
            dark: '#0288D1',
        },
        grey: {
            50: '#1B1B1B',
            100: '#171717',
            200: '#1E1E1E',
            300: '#2C2C2C',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
        },
        background: {
            default: '#1a1a21',
            secondary: '#1C1E25',
            grid: '#474747',
            card: '#1e2129',
            tooltip: '#17181f',
            snackbar: '#1e2129',
            divider: '#313335',
            paper: '#1e2129',
        },
        action: {
            active: 'rgba(255, 255, 255, 0.64)',
            disabled: 'rgba(255, 255, 255, 0.26)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
            hover: 'rgba(255, 255, 255, 0.04)',
            selected: 'rgba(255, 255, 255, 0.08)',
            focus: 'rgba(255, 255, 255, 0.12)',
            outlinedBorder: 'rgba(255, 255, 255, 0.23)',
            outlinedHoverBorder: 'rgb(255, 255, 255)',
        },
        text: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(255, 255, 255, 0.7)',
            hint: 'rgba(255, 255, 255, 0.6)',
            success: '#66BB6A',
            error: '#f44336',
            warning: '#FFA726',
            inverted: 'rgba(0, 0, 0, 1)',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
        mode: 'dark',
    },
});
const getDefaultTheme = () => {
    return getThemes().find((t) => t.id === 'light');
};
const getThemes = () => {
    return [
        {
            id: 'light',
            name: intl.t('theme.light', 'Light'),
            config: light,
        },
        {
            id: 'dark',
            name: intl.t('theme.dark', 'Dark'),
            config: dark,
        },
    ];
};
const getTheme = (id) => {
    return getThemes().find((t) => t.id === id);
};
export const ThemeUtils = {
    getDefaultTheme,
    getThemes,
    getTheme,
};
