import React from 'react';
import { Switch } from 'react-router-dom';
import { intl } from '@corti/i18n';
import { Route } from 'browser/containers';
import { rbacService } from 'browser/services/init';
import { FallbackView } from 'lib/cortiUI';
import { AdminView } from '../../AdminView';
import { OrganizationSettingsView } from '../../OrganizationSettingsView';
import { ProfileView } from '../../ProfileView';
import { DefaultModuleRedirect } from './DefaultModuleRedirect';
import { ExternalApiRoute } from './ExternalApiRoute';
export function AppRoutes({ defaultModule, modules }) {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/", render: (props) => React.createElement(DefaultModuleRedirect, Object.assign({ module: defaultModule }, props)) }),
        modules.map((module) => (React.createElement(Route, { key: module.path, path: module.path, isAllowed: canDo(module), render: module.viewComponent, breadcrumb: module.title, withErrorBoundary: true }))),
        React.createElement(Route, { withErrorBoundary: true, path: "/organisation-settings", component: OrganizationSettingsView, breadcrumb: intl.t('organizationSettings', 'Organization Settings') }),
        React.createElement(Route, { withErrorBoundary: true, path: "/profile", component: ProfileView, breadcrumb: intl.t('profile', 'Profile') }),
        React.createElement(Route, { withErrorBoundary: true, path: "/admin-panel", component: AdminView, breadcrumb: intl.t('adminPanel:adminPanel', 'Admin Panel') }),
        React.createElement(Route, { path: "/external/v1/case-search", render: ExternalApiRoute }),
        React.createElement(Route, { render: () => React.createElement(FallbackView, { title: intl.t('appNotFound', 'App Not Found') }) })));
}
function canDo(module) {
    return module.requiresUserPermission
        ? rbacService.hasPermission(module.requiresUserPermission)
        : true;
}
