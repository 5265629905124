export const userConfigSchema = {
    type: 'object',
    properties: {
        apiHost: {
            type: 'string',
            nullable: true,
        },
        apiReconnectInterval: {
            type: 'integer',
            default: 2000,
        },
        apiRealtimeMaxReconnectionDelay: {
            type: 'integer',
            default: 12000,
        },
        apiRealtimeConnectionTimeout: {
            type: 'integer',
            default: 6000,
        },
        apiRealtimePongTimeout: {
            type: 'integer',
            default: 6000,
        },
        apiRealtimeHealthcheckInterval: {
            type: 'integer',
            default: 6000,
        },
        debug: {
            type: 'boolean',
            default: false,
        },
        webappUrl: {
            type: 'string',
            nullable: true,
        },
        'logger:maxFiles': {
            type: 'integer',
            default: 10,
        },
        'logger:maxFileSize': {
            type: 'integer',
            default: 5000000,
        },
        'http-api:receiverHost': {
            type: 'string',
            default: 'http://localhost:45002',
        },
        'http-api:receiverEventsEndpoint': {
            type: 'string',
            default: '/events',
        },
        'http-api:port': {
            type: 'integer',
            default: 45001,
        },
        'wrapper:healthCheckUrl': {
            type: 'string',
            nullable: true,
        },
        'callSubscriber:configFileLocation': {
            type: 'string',
            nullable: true,
        },
        'windowOptions:alwaysOnTop': {
            type: 'boolean',
            default: false,
        },
        'windowOptions:resizable': {
            type: 'boolean',
            default: true,
        },
        'windowOptions:movable': {
            type: 'boolean',
            default: true,
        },
        'windowOptions:rememberLastState': {
            type: 'boolean',
            default: true,
        },
        'windowOptions:defaultX': {
            type: 'integer',
            nullable: true,
        },
        'windowOptions:defaultY': {
            type: 'integer',
            nullable: true,
        },
        'windowOptions:defaultWidth': {
            type: 'integer',
            nullable: true,
        },
        'windowOptions:defaultHeight': {
            type: 'integer',
            nullable: true,
        },
        'windowOptions:defaultZoomLevel': {
            type: 'number',
            nullable: true,
        },
        'childWindowOptions:defaultX': {
            type: 'integer',
            nullable: true,
        },
        'childWindowOptions:defaultY': {
            type: 'integer',
            nullable: true,
        },
        'childWindowOptions:defaultWidth': {
            type: 'integer',
            nullable: true,
        },
        'childWindowOptions:defaultHeight': {
            type: 'integer',
            nullable: true,
        },
        'alertWidgetWindowOptions:defaultX': {
            type: 'integer',
            nullable: true,
        },
        'alertWidgetWindowOptions:defaultY': {
            type: 'integer',
            nullable: true,
        },
        'login:enableRememberMe': {
            type: 'boolean',
            default: true,
        },
        'login:preferredAuthProvider': {
            type: 'string',
            enum: ['native', 'ad'],
            default: 'native',
        },
        'app:focusMode': {
            type: 'boolean',
            default: false,
        },
        'externalServices:sentry:dsn': {
            type: 'string',
            nullable: true,
        },
        'externalServices:segment:key': {
            type: 'string',
            nullable: true,
        },
        'externalServices:datadog:applicationId': {
            type: 'string',
            nullable: true,
        },
        'externalServices:datadog:clientToken': {
            type: 'string',
            nullable: true,
        },
        'externalServices:zendesk:key': {
            type: 'string',
            nullable: true,
        },
        'externalServices:zendesk:rumSessionIdField': {
            type: 'number',
            nullable: true,
        },
        'externalServices:satismeter:key': {
            type: 'string',
            nullable: true,
        },
    },
    required: [],
    additionalProperties: false,
};
