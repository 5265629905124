var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
export class ChecklistLibrary {
    constructor() {
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.items = [];
    }
    get(id) {
        return this.items.find((c) => c.id === id);
    }
    addChecklist(checklist) {
        this.items = this.items.concat([checklist]);
        return this.items.length - 1;
    }
    addChecklists(checklists) {
        this.items = this.items.concat(checklists);
    }
    updateChecklist(checklist) {
        const index = this.items.findIndex((c) => c.id === checklist.id);
        if (index === -1) {
            throw new Error(`Checklist with id ${checklist.id} not found`);
        }
        this.items = [...this.items.slice(0, index), checklist, ...this.items.slice(index + 1)];
    }
    removeChecklists(checklistIds) {
        this.items = this.items.filter((checklist) => !checklistIds.includes(checklist.id));
    }
    static clone(checklist) {
        if (!checklist) {
            return null;
        }
        return Object.assign(Object.assign({}, checklist), { entries: checklist.entries.map((entry) => (Object.assign({}, entry))) });
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], ChecklistLibrary.prototype, "items", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ChecklistLibrary.prototype, "addChecklist", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ChecklistLibrary.prototype, "addChecklists", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ChecklistLibrary.prototype, "updateChecklist", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ChecklistLibrary.prototype, "removeChecklists", null);
