var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { ChecklistEntry as ChecklistEntryDisplay } from '@corti/corti-ui';
import { useEntry } from '../data';
import { useUIListener } from '../data/ChecklistUIProvider';
export const ChecklistEntry = memo(function Entry({ checklistId, actor }) {
    // Recompose props with a slight renaming
    const _a = useEntry(actor), { manualCompletionEnabled: manual, automaticCompletionEnabled: automatic, onToggle, key } = _a, // This one we just throw away
    data = __rest(_a, ["manualCompletionEnabled", "automaticCompletionEnabled", "onToggle", "key"]);
    const listener = useUIListener();
    const handleToggle = () => {
        listener({ type: data.manuallyChecked ? 'uncheck' : 'check', checklistId, entryId: data.id });
        onToggle();
    };
    const handleLaunch = () => {
        listener({ type: 'launch', nodeId: data.inlineNodeID });
    };
    const props = Object.assign(Object.assign({}, data), { manual, automatic, onToggle: handleToggle, onLaunch: handleLaunch });
    return React.createElement(ChecklistEntryDisplay, Object.assign({}, props));
});
