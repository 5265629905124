import { cloneDeep } from 'lodash';
import { flowEventUtils } from './utils';
export const commentTransformer = {
    repoToDB(input, sessionID) {
        return Object.assign(Object.assign({}, input), { sessionID: sessionID });
    },
    DBToRepo(input) {
        const result = cloneDeep(input);
        // @ts-expect-error
        delete result.sessionID;
        return result;
    },
};
export const sessionTransformer = {
    repoToDB(input) {
        return input;
    },
    DBToRepo(input) {
        return input;
    },
};
export const flowStateChangeEventTransformer = {
    repoToDB(event, sessionID) {
        return Object.assign(Object.assign({}, event), { id: flowEventUtils.genID(event), sessionID });
    },
    DBToRepo(event) {
        const c = Object.assign({}, event);
        // @ts-expect-error
        delete c.id;
        // @ts-expect-error
        delete c.sessionID;
        return c;
    },
    DBToServer(event) {
        switch (event.event) {
            case 'triage-flow-action-block-triggered': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'action-block-triggered',
                    blockID: event.data.block.id,
                    blockValues: [],
                    factValues: [],
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-active-step-changed': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'active-step-changed',
                    stepID: event.data.stepID,
                    blockValues: [],
                    factValues: [],
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-alert-node-opened': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'alert-node-opened',
                    nodeID: event.data.node.id,
                    blockValues: [],
                    factValues: [],
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-block-values-updated': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'block-values-updated',
                    blockValues: event.data.blockValues.map((it) => ({
                        identifier: {
                            blockID: it.identifier.blockID,
                            optionID: it.identifier.optionID,
                        },
                        value: it.value,
                    })),
                    factValues: [],
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-fact-values-updated': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'fact-values-updated',
                    blockValues: [],
                    factValues: event.data.factValues.map((it) => ({
                        factID: it.factID,
                        value: it.value,
                    })),
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-step-added': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'step-added',
                    stepID: event.data.step.id,
                    nodeID: event.data.step.nodeID,
                    prevStepID: event.data.step.prevStepID,
                    blockValues: [],
                    factValues: [],
                    valueCollectorBlockItems: [],
                };
            }
            case 'triage-flow-value-collector-block-values-updated': {
                return {
                    sessionID: event.sessionID,
                    datetime: event.data.datetime,
                    type: 'value-collector-block-values-updated',
                    blockValues: [],
                    factValues: [],
                    valueCollectorBlockItems: event.data.items.map((it) => ({
                        id: it.id,
                        valueType: it.valueType,
                        values: it.values.map((v) => ({
                            blockID: v.blockID,
                            optionID: v.optionID,
                            factID: v.factID,
                            value: v.value,
                        })),
                    })),
                };
            }
        }
    },
    serverToRepo(event, graphTraverser) {
        var _a, _b, _c, _d;
        switch (event.type) {
            case 'action-block-triggered': {
                if (!event.blockID) {
                    return;
                }
                const blockProto = graphTraverser.getBlockProtoByID(event.blockID);
                if (!blockProto) {
                    return;
                }
                return {
                    event: 'triage-flow-action-block-triggered',
                    data: {
                        datetime: event.datetime,
                        block: {
                            id: blockProto.id,
                            customProperties: blockProto.customProperties.map((it) => ({
                                key: it.key,
                                value: it.value,
                            })),
                        },
                    },
                };
            }
            case 'active-step-changed': {
                if (!event.stepID) {
                    return;
                }
                return {
                    event: 'triage-flow-active-step-changed',
                    data: {
                        datetime: event.datetime,
                        stepID: event.stepID,
                    },
                };
            }
            case 'step-added': {
                if (!event.stepID) {
                    return;
                }
                if (!event.nodeID) {
                    return;
                }
                return {
                    event: 'triage-flow-step-added',
                    data: {
                        datetime: event.datetime,
                        step: {
                            id: event.stepID,
                            nodeID: event.nodeID,
                            prevStepID: (_a = event.prevStepID) !== null && _a !== void 0 ? _a : undefined,
                        },
                    },
                };
            }
            case 'block-values-updated': {
                return {
                    event: 'triage-flow-block-values-updated',
                    data: {
                        datetime: event.datetime,
                        blockValues: event.blockValues.map((it) => {
                            var _a;
                            return {
                                identifier: {
                                    blockID: it.identifier.blockID,
                                    optionID: (_a = it.identifier.optionID) !== null && _a !== void 0 ? _a : undefined,
                                },
                                value: it.value,
                            };
                        }),
                    },
                };
            }
            case 'fact-values-updated': {
                return {
                    event: 'triage-flow-fact-values-updated',
                    data: {
                        datetime: event.datetime,
                        factValues: event.factValues,
                    },
                };
            }
            case 'value-collector-block-values-updated': {
                if (!event.blockID) {
                    return;
                }
                return {
                    event: 'triage-flow-value-collector-block-values-updated',
                    data: {
                        datetime: event.datetime,
                        blockID: event.blockID,
                        displayValues: [],
                        blockPrototypeID: '',
                        items: event.valueCollectorBlockItems.map((it) => {
                            return {
                                id: it.id,
                                valueType: it.valueType,
                                values: it.values.map((v) => {
                                    var _a, _b, _c;
                                    return {
                                        blockID: (_a = v.blockID) !== null && _a !== void 0 ? _a : undefined,
                                        optionID: (_b = v.optionID) !== null && _b !== void 0 ? _b : undefined,
                                        factID: (_c = v.factID) !== null && _c !== void 0 ? _c : undefined,
                                        value: v.value,
                                    };
                                }),
                            };
                        }),
                    },
                };
            }
            case 'checklist-updated': {
                if (!event.checklistID) {
                    return;
                }
                return {
                    event: 'checklist-updated',
                    data: {
                        datetime: event.datetime,
                        checklistID: event.checklistID,
                        expanded: (_b = event.checklistExpanded) !== null && _b !== void 0 ? _b : false,
                    },
                };
            }
            case 'checklist-entry-updated': {
                if (!event.checklistID || !event.checklistEntryID) {
                    return;
                }
                return {
                    event: 'checklist-entry-updated',
                    data: {
                        datetime: event.datetime,
                        checklistID: event.checklistID,
                        checklistEntryID: event.checklistEntryID,
                        completed: (_c = event.checklistEntryCompleted) !== null && _c !== void 0 ? _c : false,
                        checklistEntryCompletionSource: (_d = event.checklistEntryCompletionSource) !== null && _d !== void 0 ? _d : 'manual',
                    },
                };
            }
            default: {
                return;
            }
        }
    },
};
