import { MoreHoriz } from '@mui/icons-material';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { saveFileToDisk } from '@corti/browser-file';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Base, Button, FileInput, IconButton, Menu, MenuItem, State, TextField, } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { CoreBackendDeserializer, CoreBackendSerializer, } from 'lib/graphEditor/coreBackendSerializer';
export const ToolPalette = observer(function ToolPalette(_props) {
    const { editor } = useGraphEditorCtx();
    const { model: graphModel } = editor;
    const { t } = useTranslation('libGraphEditor');
    const [isSerialising, setSerialising] = React.useState(false);
    async function handleDownloadAsJSON() {
        setSerialising(true);
        try {
            const serialized = await CoreBackendSerializer.transform({
                graphData: graphModel,
                options: {
                    checklistsEnabled: editor.checklistSettings.enabled,
                },
            });
            const f = new Blob([JSON.stringify(serialized)], { type: 'application/json' });
            saveFileToDisk(f, `${graphModel.name}.json`);
        }
        finally {
            setSerialising(false);
        }
    }
    async function handleSelectFile(file) {
        try {
            const data = await file.text();
            const parsed = JSON.parse(data);
            const model = await CoreBackendDeserializer.deserialize(parsed);
            model.id = graphModel.id;
            editor.setModel(model);
        }
        catch (err) {
            console.error(err);
            alert(err);
        }
    }
    return (React.createElement(Base, { id: "grapheditor-toolbarcontainer", display: 'flex', gap: 6, p: 3, alignItems: "center", justifyContent: '' },
        React.createElement(Base, { display: 'flex', gap: 4, flexShrink: 0, alignItems: "center" },
            React.createElement(Autocomplete, { "data-cy": "branch-selector", sx: { width: 300 }, size: "small", options: orderBy(graphModel.branches, (b) => b.name.toLowerCase()), getOptionLabel: (option) => option.name, value: editor.state.activeBranch, disableClearable: true, onChange: (_, value) => {
                    editor.state.setActiveBranch(value.id);
                }, renderInput: (params) => React.createElement(TextField, Object.assign({ label: "Branch" }, params)), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({ "data-cy": "select-branch-menu-item" }, props, { selected: state.selected, key: option.id }), option.name)) }),
            React.createElement(Button, { "data-cy": "add-branch", size: "small", color: "primary", variant: "text", onClick: () => {
                    let name = `Branch ${graphModel.branches.length + 1}`;
                    editor.dispatch({ type: 'canvas.addBranch', data: { name } });
                } }, t('toolPalette.addBranchBtn', 'Add Branch'))),
        React.createElement(Base, { display: 'flex', gap: 3, ml: 'auto', flexShrink: 0, alignItems: "center" },
            React.createElement(Base, { id: "grapheditor-toolbarcontainer-contrib-placeholder" }),
            React.createElement(State, { initialState: null }, ([state, setState]) => (React.createElement(React.Fragment, null,
                React.createElement(IconButton, { "data-cy": "grapheditor-toolpalette-more-actions-button", onClick: (e) => setState(e.currentTarget), icon: React.createElement(MoreHoriz, null) }),
                React.createElement(Menu, { anchorEl: state, open: !!state, onClose: () => setState(null) },
                    React.createElement(FileInput, { "data-cy": "upload-graph-input", accept: "application/json", multiple: false, onSelectFiles: (files) => {
                            setState(null);
                            if (files.length === 0) {
                                return;
                            }
                            void handleSelectFile(files[0]);
                        } }, ({ selectFiles }) => (React.createElement(MenuItem, { disabled: isSerialising, onClick: selectFiles }, t('toolPalette.importJson', 'Import from JSON')))),
                    React.createElement(MenuItem, { "data-cy": "download-graph-button", disabled: isSerialising, onClick: () => {
                            void handleDownloadAsJSON();
                            setState(null);
                        } }, t('toolPalette.saveAsJson', 'Download Graph as JSON')))))))));
});
export function ToolPalettePlaceholder(props) {
    const [container, setContainer] = React.useState();
    React.useEffect(() => {
        const c = document.getElementById('grapheditor-toolbarcontainer-contrib-placeholder');
        setContainer(c);
    }, []);
    if (!container) {
        return null;
    }
    return ReactDOM.createPortal(React.createElement(Base, Object.assign({}, props)), container);
}
