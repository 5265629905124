import { css } from '@corti/style';
export const NodeUtils = {
    hasLogicalGates(node) {
        return node.gates.length > 0;
    },
};
export const getFocusWithinCss = ({ theme }) => {
    return css({
        '&:focus-within': {
            boxShadow: `0 0 0 2px ${theme.palette.background.card}, 0 0 0 4px ${theme.palette.primary.main}`,
        },
    });
};
export const getFocusCss = ({ theme }) => {
    return css({
        '&:focus': {
            boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
        },
    });
};
