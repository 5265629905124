var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { filterBaseProps, useBaseCss } from 'lib/cortiUI/components/baseHoc';
/**
 * Component displays styled label. It's based on HTML <label/>
 */
export function Label(_a) {
    var { className, htmlFor = '' } = _a, rest = __rest(_a, ["className", "htmlFor"]);
    const baseCss = useBaseCss(rest);
    const theme = useTheme();
    const filtered = filterBaseProps(rest);
    return (React.createElement("label", Object.assign({ className: css(baseCss, {
            color: theme.palette.text.secondary,
            fontWeight: 'bold',
            fontSize: theme.typography.fontSize,
            letterSpacing: '-0.25px',
            userSelect: 'none',
        }, className), htmlFor: htmlFor }, filtered)));
}
