import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { groupBy } from 'lodash';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { Base, Collapse, SidebarActionButton, SidebarItem, SidebarSection, Typography, UnstyledLink, } from 'lib/cortiUI';
export const ViewsSection = ({ views, title, icon, showFolders = false, nestingLevel = 0, disabled = false, }) => {
    const { currentUserID } = useAuth();
    const [isExpanded, setIsExpanded] = React.useState(true);
    const folders = groupBy(views.filter((it) => it.folder), (it) => it.folder);
    const foldersViewsEntries = Object.entries(folders);
    const pureViews = showFolders ? views.filter((it) => !it.folder) : views;
    return (React.createElement(SidebarSection, { "data-cy": "performance-sidebar-section", icon: icon, title: title, marginBottom: 3, actions: React.createElement(SidebarActionButton, { tooltipTitle: "", icon: isExpanded ? React.createElement(ExpandLessIcon, { fontSize: "small" }) : React.createElement(ExpandMoreIcon, { fontSize: "small" }), disabled: disabled, onClick: () => {
                setIsExpanded(!isExpanded);
            } }) },
        React.createElement(Collapse, { in: isExpanded, timeout: "auto", unmountOnExit: true },
            showFolders && foldersViewsEntries.length > 0 && (React.createElement(Base, null, foldersViewsEntries
                .sort(([a], [b]) => a.localeCompare(b, undefined, { numeric: true }))
                .map(([title, items]) => (React.createElement(ViewsSection, { title: title, views: items, icon: React.createElement(FolderOpenRoundedIcon, { fontSize: "small" }), nestingLevel: 1, disabled: disabled }))))),
            pureViews.map((it) => (React.createElement(UnstyledLink, { key: it.id, to: `/performance/views/${it.id}`, disabled: disabled, tabIndex: disabled ? -1 : undefined }, ({ isActive }) => (React.createElement(SidebarItem, { sx: { marginLeft: nestingLevel ? 3 : undefined }, selected: isActive, justifyContent: "space-between", disabled: disabled },
                React.createElement(Typography, { variant: "subtitle2", width: "100%", noWrap: true }, it.title),
                it.locked &&
                    (it.createdBy.id !== currentUserID ? (React.createElement(LockRoundedIcon, { fontSize: "small", color: "disabled" })) : (React.createElement(LockOpenRoundedIcon, { fontSize: "small", color: "disabled" })))))))))));
};
