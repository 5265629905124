export const BlockValueUtils = {
    isTruthy(value) {
        if (value.identifier.optionID != undefined) {
            return value.value === 'true';
        }
        else {
            return value.value !== '';
        }
    },
    groupByBlockID(values) {
        const res = new Map();
        values.forEach((it) => {
            const target = res.get(it.identifier.blockPrototypeID);
            if (target) {
                res.set(it.identifier.blockPrototypeID, [...target, it]);
            }
            else {
                res.set(it.identifier.blockPrototypeID, [it]);
            }
        });
        return res;
    },
    getDefaultValuesForBlock(blockPrototype) {
        var _a;
        switch (blockPrototype.type) {
            case 'SELECT': {
                return (((_a = blockPrototype.options) === null || _a === void 0 ? void 0 : _a.map((option) => ({
                    identifier: {
                        blockPrototypeID: blockPrototype.id,
                        optionID: option.id,
                    },
                    value: 'false',
                }))) || []);
            }
            case 'TEXT_INPUT':
            case 'NUMBER_INPUT':
            case 'DATE_PICKER':
            case 'TEXTAREA_INPUT': {
                return [
                    {
                        identifier: {
                            blockPrototypeID: blockPrototype.id,
                        },
                        value: '',
                    },
                ];
            }
        }
    },
};
