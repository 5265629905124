var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import Mousetrap from 'mousetrap';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
const DEFAULT_KEYBOARD_SHORTCUTS = {
    focusNextElement: 'down',
    focusPrevElement: 'up',
    focusNextElementGroup: 'shift+down',
    focusPrevElementGroup: 'shift+up',
    triggerFocusedElement: ['space', 'enter'],
    blur: 'esc',
};
export class FocusContextController {
    constructor(registry, keyboardShortcuts) {
        Object.defineProperty(this, "registry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: registry
        });
        Object.defineProperty(this, "mousetrap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardEventBindingElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "focusedElementID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardShortcutsEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setKeyboardEventBindingElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bindingElement) => {
                const nextElement = bindingElement !== null && bindingElement !== void 0 ? bindingElement : undefined;
                if (this.keyboardEventBindingElement === nextElement) {
                    return;
                }
                this.keyboardEventBindingElement = nextElement;
                if (this.keyboardShortcutsEnabled) {
                    this._disableKeyboardShortcuts();
                    this._enableKeyboardShortcuts(this.keyboardEventBindingElement);
                }
            }
        });
        Object.defineProperty(this, "enableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.keyboardShortcutsEnabled) {
                    return;
                }
                this.keyboardShortcutsEnabled = true;
                this._enableKeyboardShortcuts(this.keyboardEventBindingElement);
            }
        });
        Object.defineProperty(this, "disableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.keyboardShortcutsEnabled) {
                    return;
                }
                this.keyboardShortcutsEnabled = false;
                this._disableKeyboardShortcuts();
            }
        });
        Object.defineProperty(this, "_enableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (bindingElement) => {
                this.mousetrap = new Mousetrap(bindingElement);
                if (this.keyboardShortcuts.focusNextElement) {
                    this.mousetrap.bind(this.keyboardShortcuts.focusNextElement, () => {
                        this.focusNextElement();
                    });
                }
                if (this.keyboardShortcuts.focusPrevElement) {
                    this.mousetrap.bind(this.keyboardShortcuts.focusPrevElement, () => {
                        this.focusPrevElement();
                    });
                }
                if (this.keyboardShortcuts.focusNextElementGroup) {
                    this.mousetrap.bind(this.keyboardShortcuts.focusNextElementGroup, () => {
                        this.focusNextGroup();
                    });
                }
                if (this.keyboardShortcuts.focusPrevElementGroup) {
                    this.mousetrap.bind(this.keyboardShortcuts.focusPrevElementGroup, () => {
                        this.focusPrevGroup();
                    });
                }
                if (this.keyboardShortcuts.blur) {
                    this.mousetrap.bind(this.keyboardShortcuts.blur, () => {
                        this.blur();
                    });
                }
                if (this.keyboardShortcuts.triggerFocusedElement) {
                    this.mousetrap.bind(this.keyboardShortcuts.triggerFocusedElement, () => {
                        this.triggerFocusedElement();
                    });
                }
            }
        });
        Object.defineProperty(this, "_disableKeyboardShortcuts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.mousetrap) === null || _a === void 0 ? void 0 : _a.reset();
                this.mousetrap = undefined;
            }
        });
        Object.defineProperty(this, "triggerFocusedElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.focusedElement) {
                    this.observer.fireEvent('focused-element-triggered', this.focusedElement);
                }
            }
        });
        Object.defineProperty(this, "blur", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.focusedElement) {
                    this.observer.fireEvent('element-blurred', this.focusedElement);
                }
                this.focusedElementID = undefined;
            }
        });
        Object.defineProperty(this, "focusElementByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (elementID) => {
                const element = this.registry.getElementByID(elementID);
                if (!element) {
                    return;
                }
                if (this.focusedElementID === elementID) {
                    return;
                }
                if (this.focusedElement) {
                    this.observer.fireEvent('element-blurred', this.focusedElement);
                }
                this.focusedElementID = element.id;
                if (this.focusedElement) {
                    this.observer.fireEvent('element-focused', this.focusedElement);
                }
            }
        });
        Object.defineProperty(this, "focusFirstElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (this.firstElement) {
                    this.focusElementByID(this.firstElement.id);
                }
                else {
                    (_a = document.querySelector('[data-cy="flow:step"]')) === null || _a === void 0 ? void 0 : _a.focus();
                }
            }
        });
        Object.defineProperty(this, "focusLastElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.lastElement) {
                    this.focusElementByID(this.lastElement.id);
                }
            }
        });
        Object.defineProperty(this, "focusPrevElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.focusedElementID) {
                    this.focusFirstElement();
                    return;
                }
                if (this.prevElement) {
                    this.focusElementByID(this.prevElement.id);
                }
                else {
                    this.focusLastElement();
                }
            }
        });
        Object.defineProperty(this, "focusNextElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.focusedElementID) {
                    this.focusFirstElement();
                    return;
                }
                if (this.nextElement) {
                    this.focusElementByID(this.nextElement.id);
                }
                else {
                    this.focusFirstElement();
                }
            }
        });
        Object.defineProperty(this, "focusFirstGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.firstGroupElement) {
                    this.focusElementByID(this.firstGroupElement.id);
                }
            }
        });
        Object.defineProperty(this, "focusLastGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.lastGroupElement) {
                    this.focusElementByID(this.lastGroupElement.id);
                }
            }
        });
        Object.defineProperty(this, "focusNextGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.focusedElementID) {
                    this.focusFirstGroup();
                    return;
                }
                if (this.nextGroupElement) {
                    this.focusElementByID(this.nextGroupElement.id);
                }
                else {
                    this.focusFirstGroup();
                }
            }
        });
        Object.defineProperty(this, "focusPrevGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.focusedElementID) {
                    this.focusFirstGroup();
                    return;
                }
                if (this.prevGroupElement) {
                    this.focusElementByID(this.prevGroupElement.id);
                }
                else {
                    this.focusLastGroup();
                }
            }
        });
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.observer.unAll();
                this.disableKeyboardShortcuts();
            }
        });
        makeObservable(this);
        this.id = uuid();
        this.observer = new Observer();
        this.mousetrap = undefined;
        this.keyboardEventBindingElement = undefined;
        this.keyboardShortcutsEnabled = false;
        this.keyboardShortcuts = Object.assign(Object.assign({}, DEFAULT_KEYBOARD_SHORTCUTS), keyboardShortcuts);
        runInAction(() => {
            this.focusedElementID = undefined;
        });
    }
    get focusedElement() {
        if (this.focusedElementID) {
            return this.registry.getElementByID(this.focusedElementID);
        }
    }
    get firstElement() {
        return this.focusableElements[0];
    }
    get lastElement() {
        return this.focusableElements[this.focusableElements.length - 1];
    }
    get prevElement() {
        const idx = this.focusableElements.findIndex((it) => it.id === this.focusedElementID);
        if (idx !== -1) {
            return this.focusableElements[idx - 1];
        }
    }
    get nextElement() {
        const idx = this.focusableElements.findIndex((it) => it.id === this.focusedElementID);
        if (idx !== -1) {
            return this.focusableElements[idx + 1];
        }
    }
    get firstGroupElement() {
        return this.firstFocusableElements[0];
    }
    get lastGroupElement() {
        return this.firstFocusableElements[this.firstFocusableElements.length - 1];
    }
    get prevGroupElement() {
        const idx = this.firstFocusableElements.findIndex((it) => { var _a; return it.groupID === ((_a = this.focusedElement) === null || _a === void 0 ? void 0 : _a.groupID); });
        if (idx !== -1) {
            return this.firstFocusableElements[idx - 1];
        }
    }
    get nextGroupElement() {
        const idx = this.firstFocusableElements.findIndex((it) => { var _a; return it.groupID === ((_a = this.focusedElement) === null || _a === void 0 ? void 0 : _a.groupID); });
        if (idx !== -1) {
            return this.firstFocusableElements[idx + 1];
        }
    }
    get focusableElements() {
        return this.registry.elements.filter((it) => it.isFocusable);
    }
    get firstFocusableElements() {
        const result = [];
        this.registry.groupedElements.forEach((group) => {
            const filteredGroup = group.filter((it) => it.isFocusable);
            if (filteredGroup.length) {
                result.push(filteredGroup[0]);
            }
        });
        return result;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], FocusContextController.prototype, "focusedElementID", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], FocusContextController.prototype, "focusedElement", null);
__decorate([
    action,
    __metadata("design:type", Object)
], FocusContextController.prototype, "blur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], FocusContextController.prototype, "focusElementByID", void 0);
