var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { makeObservable, observable, runInAction, when } from 'mobx';
export class ProtocolGraphLoader {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "refreshingGraphIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        runInAction(() => {
            this.refreshingGraphIDs = [];
        });
    }
    async loadGraph(graphVersionID, options) {
        var _a;
        try {
            await when(() => this.refreshingGraphIDs.includes(graphVersionID) === false);
            const cachedGraph = await this.input.db.getGraphByID(graphVersionID);
            if (cachedGraph) {
                const refreshed = await this.refreshGraph(cachedGraph);
                if (refreshed) {
                    await this.ensureCache(refreshed);
                    return refreshed;
                }
                return cachedGraph;
            }
            const graph = await this.input.api.getGraph(graphVersionID);
            if ((options === null || options === void 0 ? void 0 : options.cache) === true) {
                await this.ensureCache(graph);
            }
            return graph;
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Failed to load graph ${graphVersionID}`, String(err));
            return;
        }
    }
    async refreshGraph(graph) {
        var _a;
        try {
            runInAction(() => {
                this.refreshingGraphIDs.push(graph.version.id);
            });
            const latestGraphVersion = await this.input.api.getGraphVersion(graph.version.id);
            if (latestGraphVersion.updatedAt === graph.version.updatedAt) {
                return;
            }
            const result = await this.input.api.getGraph(graph.version.id);
            return result;
        }
        catch (err) {
            (_a = this.input.logger) === null || _a === void 0 ? void 0 : _a.error(`Failed to refresh graph ${graph.version.id}`, String(err));
            return;
        }
        finally {
            runInAction(() => {
                this.refreshingGraphIDs = this.refreshingGraphIDs.filter((id) => id !== graph.version.id);
            });
        }
    }
    async ensureCache(graph) {
        // We want to keep only one graph in the cache.
        await this.input.db.deleteAllGraphs();
        await this.input.db.putGraph(graph.version.id, graph);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], ProtocolGraphLoader.prototype, "refreshingGraphIDs", void 0);
