var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { observer } from 'mobx-react';
import React from 'react';
import { Base, ChipV2 } from 'lib/cortiUI';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
export const ValuePublisherWidget = observer(function ValuePublisherWidget(props) {
    const { valuePublisherConfig } = props, baseProps = __rest(props, ["valuePublisherConfig"]);
    const { editor } = useGraphEditorCtx();
    if (valuePublisherConfig.collectors.length === 0) {
        return null;
    }
    return (React.createElement(Base, Object.assign({ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap" }, baseProps), valuePublisherConfig.collectors.map((c, idx) => {
        const fvcProto = editor.model.elementContext.getPrototypeByID(c.prototypeID);
        let content = fvcProto ? fvcProto.readableTitle : '[missing ref]';
        return (React.createElement(ChipV2, { key: idx, sx: { mt: 2 }, color: fvcProto ? 'default' : 'error', label: content, variant: "outlined" }));
    })));
});
