import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { InfiniteLoader } from 'react-virtualized';
import { formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { authStore } from 'core/auth';
import { AvatarWithText, Button, DefaultCellRenderer, DefaultGhostRowRenderer, DefaultRowRenderer, DefaultValueRenderer, Table, TableColumn, Tag, } from 'lib/cortiUI';
export const NotificationsTable = observer((props) => {
    const { notificationsViewState } = props;
    const history = useHistory();
    const THRESHOLD_ROW_COUNT = 20;
    const customPropertyAsCaseID = authStore.organization.clientSettings.customPropertyAsCaseID;
    const [ghostRowsCount, setGhostRowsCount] = React.useState(0);
    const [isLoadingRows, setIsLoadingRows] = React.useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = React.useState();
    function getCommonRowData(notification) {
        const commonData = {
            id: notification.id,
            caseId: notification.data.case.id.substring(0, 8).toLocaleUpperCase(),
            createdAt: formatDateTime(new Date(notification.createdAt)),
            archiveBtn: intl.t('home:notificationsModule.markAsDone', 'Archive'),
        };
        return customPropertyAsCaseID
            ? Object.assign(Object.assign({}, commonData), { customPropertyAsCaseId: notification.data.case.customProperties[customPropertyAsCaseID] || '-' }) : commonData;
    }
    function getRowData(notification) {
        switch (notification.type) {
            case 'CASE_REVIEWED':
                return Object.assign({ notificationType: {
                        color: '#15c39e',
                        value: intl.t('home:notificationsModule.caseReviewed.tagText', 'Case Reviewed'),
                    }, user: notification.data.caseReviewerName || '' }, getCommonRowData(notification));
            case 'CASE_REVIEW_REQUEST':
                return Object.assign({ notificationType: {
                        color: '#ff9100',
                        value: intl.t('home:notificationsModule.caseReviewRequest.tagText', 'Assigned Case'),
                    }, user: notification.data.caseReviewRequesterName }, getCommonRowData(notification));
            case 'CASE_FILTER_SUBSCRIPTION_MATCH':
                return Object.assign({ notificationType: {
                        color: '#ff0089',
                        value: intl.t('home:notificationsModule.caseFilterSubscriptionMatch.tagText', 'New Case'),
                    }, user: notification.data.case.createdBy.name }, getCommonRowData(notification));
        }
    }
    function getColumns() {
        const staticColumns = [
            {
                id: 'notificationType',
                label: intl.t('home:notificationsModule.tableColumns.notificationType', 'Notification Type'),
                width: 300,
            },
            {
                id: 'caseId',
                label: intl.t('home:notificationsModule.tableColumns.caseId', 'Case ID'),
                width: 300,
            },
            {
                id: 'user',
                label: intl.t('home:notificationsModule.tableColumns.user', 'User'),
                width: 300,
            },
            {
                id: 'createdAt',
                label: intl.t('home:notificationsModule.tableColumns.createdAt', 'Created At'),
                width: 300,
            },
            {
                id: 'archiveBtn',
                label: '',
                width: 150,
            },
        ];
        if (customPropertyAsCaseID) {
            const customPropertyAsCaseIdColumn = {
                id: 'customPropertyAsCaseId',
                label: customPropertyAsCaseID,
                width: 300,
            };
            staticColumns.splice(1, 0, customPropertyAsCaseIdColumn);
            return staticColumns;
        }
        else {
            return staticColumns;
        }
    }
    const rows = notificationsViewState.notifications.map(getRowData);
    const columns = getColumns();
    function isRowLoaded(params) {
        return params.index < rows.length;
    }
    async function loadMoreRows() {
        const { hasNextPage, loadNextNotificationsPage } = notificationsViewState;
        if ((ghostRowsCount === 0 || !isLoadingRows) && hasNextPage) {
            try {
                setIsLoadingRows(true);
                await loadNextNotificationsPage();
            }
            catch (_a) {
            }
            finally {
                setIsLoadingRows(false);
                setGhostRowsCount(0);
            }
        }
    }
    const totalRowsCount = rows.length + ghostRowsCount;
    function onScroll(params) {
        const { hasNextPage } = notificationsViewState;
        const { scrollTop, scrollHeight, clientHeight } = params;
        const tableHeight = scrollTop + clientHeight;
        if (tableHeight >= scrollHeight && hasNextPage) {
            setGhostRowsCount(ghostRowsCount + 1);
        }
    }
    function getNotificationByRowIndex(index) {
        return notificationsViewState.notifications[index];
    }
    function getPathForNotification(notification) {
        switch (notification.type) {
            case 'CASE_REVIEWED':
                return `/explore/${notification.data.case.id}?sidebarView=feedback&feedbackView=feedback-preview&feedbackID=${notification.data.feedbackFormSubmissionID}`;
            case 'CASE_REVIEW_REQUEST':
                return `/explore/${notification.data.case.id}?sidebarView=feedback&feedbackView=feedback-create&templateID=${notification.data.suggestedFeedbackFormTemplateID}`;
            case 'CASE_FILTER_SUBSCRIPTION_MATCH':
                return `/explore/${notification.data.case.id}`;
        }
    }
    return (React.createElement(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: totalRowsCount + 1, threshold: THRESHOLD_ROW_COUNT }, ({ onRowsRendered }) => (React.createElement(Table, { rows: rows, columns: columns, disableResize: true, disableSort: true, onScroll: onScroll, onRowsRendered: onRowsRendered, rowCount: totalRowsCount, rowRenderer: (props) => {
            return rows[props.index] ? (React.createElement(DefaultRowRenderer, { "data-cy": "notification-row", key: props.index, requiredProps: props, onClick: () => history.push(getPathForNotification(getNotificationByRowIndex(props.index))), className: css({
                    cursor: 'pointer',
                }), onMouseEnter: () => setHoveredRowIndex(props.index), onMouseLeave: () => setHoveredRowIndex(undefined) })) : (React.createElement(DefaultGhostRowRenderer, { key: props.index, requiredProps: props }));
        } }, ({ columns }) => columns.map((col, idx) => (React.createElement(TableColumn, { key: idx, label: col.label, dataKey: col.id, width: col.width, cellRenderer: (props) => (React.createElement(DefaultCellRenderer, { requiredProps: props, valueRenderer: (value) => {
                switch (col.id) {
                    case 'archiveBtn':
                        return (React.createElement(Button, { "data-cy": "notification-archive-button", size: "small", className: css({ marginLeft: 'auto', flexShrink: 0 }), variant: "text", onClick: (e) => {
                                e.stopPropagation();
                                void notificationsViewState.archiveNotification(getNotificationByRowIndex(props.rowIndex).id);
                            }, hidden: props.rowIndex !== hoveredRowIndex },
                            React.createElement(InboxRoundedIcon, { fontSize: "small" }),
                            value));
                    case 'notificationType':
                        return (React.createElement(Tag, { "data-cy": "notification-tag", text: value.value.toString(), color: value.color }));
                    case 'user':
                        return value ? React.createElement(AvatarWithText, { title: value.toString() }) : null;
                    default:
                        return React.createElement(DefaultValueRenderer, { value: value });
                }
            } })) })))))));
});
