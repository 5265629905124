import { useEffect } from 'react';
import { intl } from '@corti/i18n';
import { logger } from '@corti/logger';
import { coreRealtimeClient, rbacService, swWrapper } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { moduleRegistry } from 'core/moduleRegistry/browser';
import { module as realTime } from 'modules/RealTimeApp';
export function useRealtimeConnection() {
    useEffect(() => {
        coreRealtimeClient.connect({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
        function initAutomaticNewSessionJoin() {
            const mod = moduleRegistry.getModule('real-time');
            if (!mod)
                return;
            if (mod.requiresUserPermission && !rbacService.hasPermission(mod.requiresUserPermission)) {
                return;
            }
            coreRealtimeClient.bind('triage-session-created', (data) => {
                if (realTime.module.status !== 'ok') {
                    return;
                }
                if (data.hasLiveCall &&
                    data.ownerUserID === authStore.currentUserID &&
                    authStore.organization.config.triage.ownerAutoJoinNewSessionWithLiveCall) {
                    realTime.module.controller.openTriageSessionView({ id: data.id });
                }
                logger.info('[stream message]: triage-session-created');
                // TODO: should update CORE-API to return ownerUser and the created_at date
                // to avoid this hack. But only the date is used when rendering, to sort the sessions.
                return realTime.module.controller.onTriageSessionCreatedTrigger(Object.assign(Object.assign({}, data), { createdAt: new Date().toISOString(), ownerUser: { id: '', name: '' } }));
            });
        }
        initAutomaticNewSessionJoin();
        let notificationID;
        const handleConnChange = ({ current }) => {
            if (current === 'unavailable') {
                if (!notificationID) {
                    notificationID = coreStore.notifications.showNotification({
                        message: intl.t('realtimeConnectionState.reconnecting', 'Reconnecting...'),
                        permanent: true,
                    });
                }
            }
            if (current === 'connected') {
                if (notificationID) {
                    coreStore.notifications.closeNotification(notificationID);
                    coreStore.notifications.showNotification({
                        message: intl.t('realtimeConnectionState.reconnected', 'Reconnected'),
                        type: 'success',
                    });
                    notificationID = undefined;
                }
            }
            if (current === 'disconnected') {
                if (notificationID) {
                    coreStore.notifications.closeNotification(notificationID);
                    coreStore.notifications.showNotification({
                        message: intl.t('realtimeConnectionState.disconnected', 'Disconnected'),
                    });
                    notificationID = undefined;
                }
            }
        };
        const unsubConnChange = coreRealtimeClient.connection.on('stateChanged', handleConnChange);
        return () => {
            coreRealtimeClient.disconnect();
            unsubConnChange();
            if (notificationID) {
                coreStore.notifications.closeNotification(notificationID);
            }
        };
    }, []);
    useEffect(() => {
        const u = coreRealtimeClient.connection.on('stateChanged', (e) => {
            if (e.current === 'connected') {
                swWrapper === null || swWrapper === void 0 ? void 0 : swWrapper.checkForUpdate();
            }
        });
        return () => {
            u();
        };
    }, []);
}
