export const sessionTransformer = {
    toRepo(input) {
        return {
            id: input.id,
            createdAt: input.createdAt,
            startedAt: input.startedAt,
            graphVersionID: input.graphVersionID,
            caseID: input.caseID,
            externalID: input.externalID,
            ownerUser: {
                id: input.ownerUser.id,
                name: input.ownerUser.name,
            },
            createdBy: {
                id: input.createdBy.id,
                name: input.createdBy.name,
            },
        };
    },
    fromRepo(input) {
        if (input.graphVersionID === undefined) {
            return;
        }
        return {
            id: input.id,
            createdAt: input.createdAt,
            startedAt: input.startedAt,
            graphVersionID: input.graphVersionID,
            caseID: input.caseID,
            externalID: input.externalID,
            ownerUser: {
                id: input.ownerUser.id,
                name: input.ownerUser.name,
            },
            createdBy: {
                id: input.createdBy.id,
                name: input.createdBy.name,
            },
        };
    },
};
