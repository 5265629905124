import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { openNewWindow } from './openNewWindow';
export const WindowPopup = ({ size, title, position, anchor, anchorOrigin = { vertical: 'top', horizontal: 'right' }, windowRef, windowFeatures, isOpen, onClose, children, }) => {
    const [newWindow, setNewWindow] = useState(null);
    const containerRef = useRef(null);
    useEffect(() => {
        if (isOpen && !newWindow) {
            const { newWindow, container } = openNewWindow(size, title, windowFeatures, position, anchor, anchorOrigin);
            if (!newWindow) {
                return;
            }
            setNewWindow(newWindow);
            containerRef.current = container;
            newWindow.onbeforeunload = () => {
                onClose === null || onClose === void 0 ? void 0 : onClose();
                setNewWindow(null);
            };
        }
        else if (!isOpen && newWindow) {
            newWindow.close();
            setNewWindow(null);
        }
    }, [isOpen, size, anchor, anchorOrigin, onClose, newWindow, title, windowFeatures, position]);
    useEffect(() => {
        if (windowRef) {
            windowRef.current = newWindow;
        }
    }, [newWindow, windowRef]);
    useEffect(() => {
        return () => {
            if (newWindow && !newWindow.closed) {
                newWindow.close();
            }
        };
    }, [newWindow]);
    const content = newWindow && containerRef.current
        ? createPortal(children instanceof Function ? children({ window: newWindow }) : children, containerRef.current)
        : null;
    return content;
};
