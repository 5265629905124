import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService, trackerService } from 'browser/services/init';
import { Base, Box, Button, List, ListItem, Typography } from 'lib/cortiUI';
import { AssignForReviewWidget } from '../AssignForReviewWidget';
import { useCaseViewContext } from '../CaseViewContext';
import { CaseFeedbackListItem } from './CaseFeedbackListItem';
import { ReviewersList } from './ReviewersList';
export const CaseFeedbackListView = observer(() => {
    const { caseViewState } = useCaseViewContext();
    const theme = useTheme();
    const { feedbackViewState, caseEntity } = caseViewState;
    if (!caseEntity) {
        return null;
    }
    const asignedReviewers = caseEntity.assignees;
    function onCreateNewFeedback() {
        feedbackViewState.setActiveView('feedback-create');
        trackerService.track('[Single case] case review started');
    }
    return (React.createElement(Base, { "data-cy": "feedback-list-view", display: "grid", gap: 7, overflow: "hidden" },
        React.createElement(Box, { mx: 8, mt: 2, flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" },
            React.createElement(Typography, { variant: "h6", fontWeight: "normal" }, intl.t('explore:feedbackView.title', 'Review')),
            React.createElement(AssignForReviewWidget, { caseEntity: caseEntity })),
        rbacService.feedbackSubmissionCRUD({
            caseOwnerID: caseEntity.createdBy.id,
        }) && (React.createElement(Button, { "data-cy": "create-feedback-btn", className: css({ marginLeft: 8, marginRight: 8 }), onClick: onCreateNewFeedback, color: "primary" }, intl.t('explore:feedbackView.addFeedbackButton', 'Create a new review'))),
        React.createElement(Typography, { mx: 8, variant: "body2", color: "secondaryText" }, intl.t('explore:feedbackView.assignedReviewersTitle', 'Assigned reviewers').toUpperCase()),
        asignedReviewers.length > 0 && (React.createElement(Base, { mx: 8 },
            React.createElement(ReviewersList, { reviewers: asignedReviewers }))),
        React.createElement(Typography, { mx: 8, variant: "body2", color: "secondaryText" }, intl.t('explore:feedbackView.createdReviewTitle', 'Created reviews').toUpperCase()),
        !!caseEntity.feedbackFormSubmissions.length && (React.createElement(Base, { className: css({
                overflow: 'overlay',
                alignSelf: 'stretch',
                justifySelf: 'stretch',
            }, getScrollerCss({ theme: theme })) },
            React.createElement(Base, { mx: 8 },
                React.createElement(List, { "data-cy": "submitted-reviews-list", style: { padding: 0, position: 'relative' } }, orderBy(caseEntity.feedbackFormSubmissions, (entry) => entry.createdAt, 'asc').map((entry) => (React.createElement(ListItem, { key: entry.id, classes: {
                        root: css({
                            padding: 0,
                            '&:not(:last-child)': { marginBottom: theme.space[6] },
                        }),
                    } },
                    React.createElement(CaseFeedbackListItem, { feedbackFormSubmission: entry, onClick: () => {
                            feedbackViewState.setActiveFeedbackID(entry.id);
                            feedbackViewState.setActiveView('feedback-preview');
                        } }))))))))));
});
