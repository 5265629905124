import { gql } from '@apollo/client';
export const timelineEntryDefinitionSourceFragment = gql `
  fragment TimelineEntryDefinitionSource on TimelineEntryDefinitionSource {
    type
    entityType
    entityID
  }
`;
const timelineEntryDefinitionFragment = gql `
  fragment TimelineEntryDefinition on TimelineEntryDefinition {
    source {
      ...TimelineEntryDefinitionSource
    }
    title
    text
    hint
    style {
      color
    }
    keywords
    requiresAcknowledgement
    isEntryCreatable
    mods
  }
  ${timelineEntryDefinitionSourceFragment}
`;
export const timelineEntryDefinitionsQueries = (client) => ({
    async getAll(organizationID) {
        const res = await client.query({
            query: gql `
        query getTimelineEntryDefinitions($organizationID: ID!) {
          timelineEntryDefinitions(organizationID: $organizationID) {
            ...TimelineEntryDefinition
          }
        }
        ${timelineEntryDefinitionFragment}
      `,
            variables: {
                organizationID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.timelineEntryDefinitions;
    },
    /**
     * This function uses old overwrite API. Sooner or later back-end should
     * provide a new api, e.i. @see createDefinition
     */
    async createCallAnnotationDefinition(input) {
        await client.mutate({
            mutation: gql `
        mutation ensureAnnotationType($input: AnnotationTypeInput!) {
          ensureAnnotationType(input: $input) {
            id
          }
        }
      `,
            variables: {
                input: {
                    objectiveName: input.title,
                    labelName: input.text,
                    organizationID: input.organizationID,
                },
            },
        });
    },
    async create(definition, organizationID) {
        const res = await client.mutate({
            mutation: gql `
        mutation createTimelineEntryDefinition(
          $definition: TimelineEntryDefinitionInput!
          $organizationID: ID!
        ) {
          createTimelineEntryDefinition(definition: $definition, organizationID: $organizationID) {
            ...TimelineEntryDefinition
          }
        }
        ${timelineEntryDefinitionFragment}
      `,
            variables: {
                definition,
                organizationID,
            },
        });
        return res.data.createTimelineEntryDefinition;
    },
    async update(definition, organizationID) {
        const res = await client.mutate({
            mutation: gql `
        mutation updateTimelineEntryDefinition(
          $definition: TimelineEntryDefinitionInput!
          $organizationID: ID!
        ) {
          updateTimelineEntryDefinition(definition: $definition, organizationID: $organizationID) {
            ...TimelineEntryDefinition
          }
        }
        ${timelineEntryDefinitionFragment}
      `,
            variables: {
                definition,
                organizationID,
            },
        });
        return res.data.updateTimelineEntryDefinition;
    },
    async delete(definition, organizationID) {
        await client.mutate({
            mutation: gql `
        mutation deleteTimelineEntryDefinition(
          $definition: TimelineEntryDefinitionSourceInput!
          $organizationID: ID!
        ) {
          deleteTimelineEntryDefinition(definition: $definition, organizationID: $organizationID)
        }
      `,
            variables: {
                definition,
                organizationID,
            },
        });
    },
});
