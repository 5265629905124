export function saveFileToDisk(file, name) {
    const anchor = document.createElement('a');
    const isString = typeof file === 'string';
    const url = isString ? file : URL.createObjectURL(file);
    anchor.href = url;
    anchor.download = name;
    anchor.target = '_blank';
    anchor.click();
    anchor.remove();
    if (!isString) {
        URL.revokeObjectURL(url);
    }
}
