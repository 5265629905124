var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _StateHandler_instances, _StateHandler_flowStepAdded, _StateHandler_flowActiveStepChanged, _StateHandler_flowFactValuesUpdated, _StateHandler_flowBlockValuesUpdated, _StateHandler_flowValueCollectorBlockValuesUpdated;
import { StateDeserializer } from './utils';
export class StateHandler {
    constructor(flowStore, stateObserver) {
        _StateHandler_instances.add(this);
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        Object.defineProperty(this, "stateObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: stateObserver
        });
    }
    applyStateChangeEvent(event) {
        this.stateObserver.pauseEmitter();
        switch (event.event) {
            case 'step-added': {
                __classPrivateFieldGet(this, _StateHandler_instances, "m", _StateHandler_flowStepAdded).call(this, event);
                break;
            }
            case 'active-step-changed': {
                __classPrivateFieldGet(this, _StateHandler_instances, "m", _StateHandler_flowActiveStepChanged).call(this, event);
                break;
            }
            case 'fact-values-updated': {
                __classPrivateFieldGet(this, _StateHandler_instances, "m", _StateHandler_flowFactValuesUpdated).call(this, event);
                break;
            }
            case 'block-values-updated': {
                __classPrivateFieldGet(this, _StateHandler_instances, "m", _StateHandler_flowBlockValuesUpdated).call(this, event);
                break;
            }
            case 'value-collector-block-values-updated': {
                __classPrivateFieldGet(this, _StateHandler_instances, "m", _StateHandler_flowValueCollectorBlockValuesUpdated).call(this, event);
                break;
            }
            case 'checklist-updated': {
                this.flowStore.checklistStore.updateChecklist(event);
                break;
            }
            case 'checklist-entry-updated': {
                this.flowStore.checklistStore.updateChecklistEntry(event);
                break;
            }
        }
        this.stateObserver.resumeEmitter();
    }
}
_StateHandler_instances = new WeakSet(), _StateHandler_flowStepAdded = function _StateHandler_flowStepAdded(event) {
    const state = StateDeserializer.deserializeStepAdded(event, this.flowStore);
    if (!state) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}`);
        return;
    }
    this.flowStore.addStep(state.step);
}, _StateHandler_flowActiveStepChanged = function _StateHandler_flowActiveStepChanged(event) {
    const state = StateDeserializer.deserializeActiveStepChanged(event, this.flowStore);
    if (!state) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}`);
        return;
    }
    this.flowStore.changeActiveStep({
        step: state.step,
        source: 'state-synchronization',
    });
}, _StateHandler_flowFactValuesUpdated = function _StateHandler_flowFactValuesUpdated(event) {
    const state = StateDeserializer.deserializeFactValuesUpdated(event);
    if (!state) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}`);
        return;
    }
    this.flowStore.factValueStore.put({
        factValues: state.factValues,
        source: 'state-synchronization',
    });
}, _StateHandler_flowBlockValuesUpdated = function _StateHandler_flowBlockValuesUpdated(event) {
    const state = StateDeserializer.deserializeBlockValuesUpdated(event);
    if (!state) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}`);
        return;
    }
    this.flowStore.blockValueStore.update({
        blockValues: state.blockValues,
        // we have this as we don't want updates from the server to auto progress for us
        options: { shouldAutoProgress: false },
        source: 'state-synchronization',
    });
}, _StateHandler_flowValueCollectorBlockValuesUpdated = function _StateHandler_flowValueCollectorBlockValuesUpdated(event) {
    const state = StateDeserializer.deserializeValueCollectorBlockValuesUpdated(event, this.flowStore);
    if (!state) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}`);
        return;
    }
    const block = this.flowStore.collectorStore.getCollectorInstanceByID(event.data.blockID);
    if (!block) {
        this.flowStore.logger.warn(`failed to deserialize ${event.event}: block not found`);
        return;
    }
    block.resetCollectedItems(state.collector.items);
};
