var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import SliderMUI from '@mui/material/Slider';
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
export function Slider(props) {
    const { classes } = props, rest = __rest(props, ["classes"]);
    const theme = useTheme();
    return (React.createElement(SliderMUI, Object.assign({ classes: Object.assign({ disabled: css({
                cursor: 'default !important',
            }), thumb: css({
                backgroundColor: theme.palette.text.primary,
            }) }, classes) }, rest)));
}
