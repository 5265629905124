import React from 'react';
import { simpleIpc } from '@corti/ipc-renderer';
const DEFAULT_SETTINGS = {
    resolution: '1280x720',
    frameRate: 30,
};
const settingsInitialState = {
    capturingSettings: DEFAULT_SETTINGS,
    availableCaptureSources: [],
    updateCapturingSettings: () => undefined,
    updateAvailableCaptureSources: () => undefined,
};
const SettingsContext = React.createContext(settingsInitialState);
export const SettingsProvider = ({ children }) => {
    const [capturingSettings, setCapturingSettings] = React.useState(settingsInitialState.capturingSettings);
    const [availableCaptureSources, setAvailableCaptureSources] = React.useState(settingsInitialState.availableCaptureSources);
    React.useEffect(() => {
        void getCaptureSources();
    }, []);
    async function getCaptureSources() {
        const sources = await simpleIpc.callMain('app:getCaptureSources', {
            types: ['screen'],
        });
        sources.map((s) => (Object.assign(Object.assign({}, s), { thumbnail: s.thumbnail.toDataURL(), selected: true })));
        setAvailableCaptureSources(sources.map((s) => (Object.assign(Object.assign({}, s), { thumbnail: s.thumbnail.toDataURL(), selected: true }))));
    }
    const value = React.useMemo(() => ({
        capturingSettings,
        availableCaptureSources,
        updateCapturingSettings: setCapturingSettings,
        updateAvailableCaptureSources: setAvailableCaptureSources,
    }), [capturingSettings, availableCaptureSources]);
    return React.createElement(SettingsContext.Provider, { value: value }, children);
};
export function useSettingsContext() {
    return React.useContext(SettingsContext);
}
