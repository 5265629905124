import React from 'react';
import ReactDOM from 'react-dom';
import { css } from '@corti/style';
import { waveSurferContext } from '../../WaveSurferContext';
import { Region } from './Region';
import { convertSecondsToPx, getMouseOffset } from './utils';
export class RegionsPlugin extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "newRegionId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'new-region'
        });
        Object.defineProperty(this, "mouseOffsetSnapshot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                wavesurferReady: false,
                newRegionStart: undefined,
                newRegionEnd: undefined,
            }
        });
        Object.defineProperty(this, "onMouseClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.stopPropagation();
                this.onEmptySpaceClick(e);
            }
        });
        Object.defineProperty(this, "onMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (!this.props.enableCreation) {
                    return;
                }
                this.mouseOffsetSnapshot = getMouseOffset(this.wavesurfer, e);
            }
        });
        Object.defineProperty(this, "onMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.isNewRegionInProgress) {
                    const currentMouseOffset = getMouseOffset(this.wavesurfer, e);
                    if (currentMouseOffset >= this.mouseOffsetSnapshot) {
                        this.setState((prevState) => (Object.assign(Object.assign({}, prevState), { newRegionStart: this.mouseOffsetSnapshot, newRegionEnd: currentMouseOffset })));
                    }
                    else if (currentMouseOffset < this.mouseOffsetSnapshot) {
                        this.setState((prevState) => (Object.assign(Object.assign({}, prevState), { newRegionStart: currentMouseOffset, newRegionEnd: this.mouseOffsetSnapshot })));
                    }
                    this.wavesurfer.fireEvent('region-update', this.asNewRegionEvent);
                }
            }
        });
        Object.defineProperty(this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.isNewRegionInProgress) {
                    if (this.state.newRegionStart !== undefined) {
                        this.onRegionCreateEnd(this.asNewRegionEvent);
                    }
                    this.resetState();
                }
            }
        });
        Object.defineProperty(this, "resetState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.mouseOffsetSnapshot = undefined;
                this.setState({
                    newRegionStart: undefined,
                    newRegionEnd: undefined,
                });
            }
        });
        Object.defineProperty(this, "onWavesurferReady", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => {
                    return Object.assign(Object.assign({}, prevState), { wavesurferReady: true });
                });
            }
        });
        Object.defineProperty(this, "onRegionCreateStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onRegionCreateStart) {
                    this.props.onRegionCreateStart(e);
                }
            }
        });
        Object.defineProperty(this, "onRegionCreateEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onRegionCreateEnd) {
                    this.props.onRegionCreateEnd(e);
                }
            }
        });
        Object.defineProperty(this, "onEmptySpaceClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.props.onEmptySpaceClick) {
                    this.props.onEmptySpaceClick(e);
                }
            }
        });
    }
    componentDidMount() {
        document.body.addEventListener('mousemove', this.onMouseMove);
        document.body.addEventListener('mouseup', this.onMouseUp);
        this.wavesurfer.on('ready', this.onWavesurferReady);
    }
    componentDidUpdate(_prevProps, prevState) {
        if (this.isNewRegionInProgress &&
            prevState.newRegionStart === undefined &&
            this.state.newRegionStart !== undefined) {
            this.onRegionCreateStart({
                id: this.newRegionId,
                start: this.state.newRegionStart,
                end: undefined,
                backgroundColor: this.props.newRegionBackgroundColor,
            });
        }
    }
    componentWillUnmount() {
        document.body.removeEventListener('mousemove', this.onMouseMove);
        document.body.removeEventListener('mouseup', this.onMouseUp);
        this.wavesurfer.un('ready', this.onWavesurferReady);
    }
    get asNewRegionEvent() {
        const { newRegionStart, newRegionEnd } = this.state;
        const end = newRegionEnd === newRegionStart ? undefined : newRegionEnd;
        return {
            id: this.newRegionId,
            start: newRegionStart,
            end: end,
            backgroundColor: this.props.newRegionBackgroundColor,
        };
    }
    get isNewRegionInProgress() {
        return !!this.mouseOffsetSnapshot;
    }
    get wavesurfer() {
        return this.context.wavesurfer;
    }
    render() {
        if (!this.state.wavesurferReady) {
            return null;
        }
        const { newRegionBackgroundColor } = this.props;
        const wsWrapperEl = this.wavesurfer.drawer.wrapper;
        const pluginElementWidth = convertSecondsToPx(this.wavesurfer, this.wavesurfer.getDuration());
        return ReactDOM.createPortal(React.createElement("div", { id: "ws-regions-plugin-wrapper", onMouseDown: this.onMouseDown, onClick: this.onMouseClick, style: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: pluginElementWidth,
                overflow: 'hidden',
                zIndex: 4,
            } },
            this.isNewRegionInProgress && (React.createElement(Region, { id: this.asNewRegionEvent.id, start: this.asNewRegionEvent.start, end: this.asNewRegionEvent.end, backgroundColor: newRegionBackgroundColor }, ({ getDragHandleProps, getResizeStartHandleProps, getResizeEndHandleProps }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", Object.assign({}, getDragHandleProps(), { className: css({
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'move',
                        }) })),
                    React.createElement("div", Object.assign({}, getResizeStartHandleProps(), { className: css({
                            position: 'absolute',
                            left: 0,
                            height: '100%',
                            width: 2,
                            cursor: 'col-resize',
                        }) })),
                    React.createElement("div", Object.assign({}, getResizeEndHandleProps(), { className: css({
                            position: 'absolute',
                            right: 0,
                            height: '100%',
                            width: 2,
                            cursor: 'col-resize',
                        }) }))));
            })),
            this.props.children), wsWrapperEl);
    }
}
Object.defineProperty(RegionsPlugin, "contextType", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: waveSurferContext
});
Object.defineProperty(RegionsPlugin, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        newRegionBackgroundColor: 'rgba(0, 0, 0, 0.1)',
    }
});
