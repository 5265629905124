import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Dialog, MenuItem } from 'lib/cortiUI';
import { DeleteTeamView } from './DeleteTeamView';
export const DeleteTeam = ({ id, onClose, onSubmit }) => {
    const { t } = useTranslation('teams');
    const [isOpen, setIsOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { "data-cy": "delete-team-btn", onClick: (e) => {
                e.stopPropagation();
                setIsOpen(true);
            } }, t('teamsView.table.row.delete', 'Delete')),
        React.createElement(Dialog, { title: t('deleteTeamModal.title', 'Delete Team'), helperText: `${t('deleteTeamModal.message', 'Are you sure you want to delete this team?')} ${t('deleteTeamModal.hint', 'This action will permanently remove the team from your organization. The team will still appear in saved reports and analytics.')}`, open: isOpen, onClose: (e) => {
                e.stopPropagation();
                setIsOpen(false);
                onClose();
            } },
            React.createElement(DeleteTeamView, { teamID: id, onClose: () => {
                    setIsOpen(false);
                    onSubmit();
                } }))));
};
