var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { gql } from '@apollo/client';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { uuid } from '@corti/uuid';
import { apolloClient, rbacService } from 'browser/services/init';
import { coreStore } from 'browser/stores/storeLocator';
import { Base, Button, Dialog, FallbackView, List, ListItem, MultipleValueRenderer, Page, Switch, TextField, Typography, } from 'lib/cortiUI';
async function fetchKeywordModelSubscriptions() {
    const res = await apolloClient.query({
        query: gql `
      query aiKeywordModelDetectSubscriptions {
        aiKeywordModelDetectSubscriptions {
          subscriptionID
          enabled
          keywords
          threshold
          title
        }
      }
    `,
    });
    return res.data.aiKeywordModelDetectSubscriptions;
}
async function ensureKeywordModelSubscription(subscriptionInput) {
    const res = await apolloClient.mutate({
        mutation: gql `
      mutation ensureAIKeywordModelDetectSubscription(
        $subscription: AIKeywordModelDetectSubscriptionInput!
      ) {
        ensureAIKeywordModelDetectSubscription(subscription: $subscription) {
          subscriptionID
          enabled
          keywords
          threshold
          title
        }
      }
    `,
        variables: {
            subscription: subscriptionInput,
        },
    });
    return res.data.ensureAIKeywordModelDetectSubscription;
}
async function deleteKeywordModelSubscription(id) {
    const res = await apolloClient.mutate({
        mutation: gql `
      mutation deleteAIKeywordModelDetectSubscription($subscriptionID: String!) {
        deleteAIKeywordModelDetectSubscription(subscriptionID: $subscriptionID) {
          success
          preventingReason
        }
      }
    `,
        variables: { subscriptionID: id },
    });
    return res.data.deleteAIKeywordModelDetectSubscription;
}
class Store {
    constructor() {
        Object.defineProperty(this, "models", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "editingModelID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.setModels([]);
    }
    async loadModels() {
        try {
            const models = await fetchKeywordModelSubscriptions();
            this.setModels(models.map((m) => (Object.assign(Object.assign({}, m), { isSaving: false, isSaved: true }))));
            runInAction(() => {
                this.hasLoaded = true;
            });
        }
        catch (err) {
            throw err;
        }
    }
    async enableModel(id, enabled) {
        const model = this.getModel(id);
        if (!model)
            return;
        runInAction(() => {
            model.enabled = enabled;
        });
        try {
            await ensureKeywordModelSubscription({
                subscriptionID: model.subscriptionID,
                keywords: model.keywords,
                threshold: model.threshold,
                title: model.title,
                enabled,
            });
        }
        catch (err) {
            runInAction(() => {
                model.enabled = !enabled;
            });
            throw err;
        }
    }
    async submitModelChanges(id, data) {
        const model = this.getModel(id);
        if (!model)
            return;
        runInAction(() => {
            model.isSaving = true;
        });
        try {
            await ensureKeywordModelSubscription(Object.assign({ subscriptionID: model.subscriptionID, enabled: model.enabled }, data));
            runInAction(() => {
                Object.assign(model, data);
                this.editingModelID = null;
                model.isSaving = false;
                model.isSaved = true;
            });
        }
        catch (err) {
            throw err;
        }
        finally {
            runInAction(() => {
                model.isSaving = false;
            });
        }
    }
    setModels(models) {
        this.models = models;
    }
    createDraftScoringModel() {
        const subscriptionInput = {
            subscriptionID: uuid(),
            enabled: true,
            keywords: [],
            threshold: 1,
            title: 'Alert',
        };
        let model = Object.assign(Object.assign({}, subscriptionInput), { isSaving: false, isSaved: false });
        runInAction(() => {
            this.models.unshift(model);
        });
        this.setEditing(model.subscriptionID);
    }
    setEditing(id) {
        if (this.editingModelID) {
            this.cancelEdit(this.editingModelID);
        }
        const model = this.getModel(id);
        if (model) {
            this.editingModelID = id;
        }
    }
    cancelEdit(id) {
        const model = this.getModel(id);
        if (!model) {
            return;
        }
        if (!model.isSaved) {
            this.models.splice(this.models.indexOf(model), 1);
        }
        this.editingModelID = null;
    }
    async deleteModel(id) {
        var _a;
        const idx = this.models.findIndex((m) => m.subscriptionID === id);
        if (idx !== -1) {
            const model = this.models[idx];
            runInAction(() => {
                this.models.splice(idx, 1);
            });
            try {
                const res = await deleteKeywordModelSubscription(id);
                if (!res.success) {
                    coreStore.notifications.showNotification({
                        type: 'error',
                        message: (_a = res.preventingReason) !== null && _a !== void 0 ? _a : 'Unable to delete model subscription for unknown reason',
                    });
                    throw new Error(res.preventingReason);
                }
            }
            catch (err) {
                runInAction(() => {
                    this.models.splice(idx, 0, model);
                });
                throw err;
            }
        }
    }
    getModel(id) {
        return this.models.find((m) => m.subscriptionID === id);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Store.prototype, "models", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Store.prototype, "editingModelID", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Store.prototype, "hasLoaded", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setModels", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "setEditing", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Store.prototype, "cancelEdit", null);
export const ModelManagementView = observer(() => {
    const store = React.useRef(new Store()).current;
    React.useEffect(() => {
        void store.loadModels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Page, { "data-cy": "model-management-view" },
        React.createElement(Typography, { variant: "h6", mb: 4, color: "default" }, intl.t('settings:navigationBar.modelManagementTab', 'Topic Management')),
        React.createElement(Base, { mb: 6 }, rbacService.aiModelDetectSubscriptionsCRUD() && (React.createElement(Button, { "data-cy": 'addModelBtn', color: "primary", onClick: () => {
                store.createDraftScoringModel();
            } }, intl.t('modelManagement:addModelBtn', 'Create Topic')))),
        store.hasLoaded ? (React.createElement(React.Fragment, null, store.models.length === 0 ? (React.createElement(FallbackView, { "data-cy": "viewLoaded", title: intl.t('modelManagement:noModelsMsg', 'There are no topic models created for your organization') })) : (React.createElement(List, { "data-cy": "viewLoaded" }, store.models.map((m) => (React.createElement(ListItem, { "data-cy": "modelListItem", key: m.subscriptionID },
            React.createElement(KeywordScoringModel, { modelInfo: m, isEditing: store.editingModelID === m.subscriptionID, onEdit: () => store.setEditing(m.subscriptionID), onCancelEdit: () => store.cancelEdit(m.subscriptionID), onSaveConfiguration: (data) => {
                    void store.submitModelChanges(m.subscriptionID, data);
                }, onToggleEnabled: (value) => {
                    void store.enableModel(m.subscriptionID, value);
                }, onDelete: () => {
                    void store.deleteModel(m.subscriptionID);
                } })))))))) : null));
});
const KeywordScoringModel = observer(function KeywordScoringModel(props) {
    const { modelInfo, isEditing, onEdit, onCancelEdit, onDelete, onSaveConfiguration, onToggleEnabled, } = props;
    const [keywordItems, setKeywordItems] = React.useState([...modelInfo.keywords]);
    const [threshold, setThreshold] = React.useState(modelInfo.threshold.toString());
    const [title, setTitle] = React.useState(modelInfo.title);
    const [newKeywordInputValue, setNewKeywordInputValue] = React.useState('');
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const isTitleValid = title.trim().length > 0;
    function reset() {
        setKeywordItems([...modelInfo.keywords]);
        setThreshold(modelInfo.threshold.toString());
        setNewKeywordInputValue('');
    }
    function getNormalizedThreshold() {
        const parsed = parseInt(threshold);
        if (Number.isNaN(parsed)) {
            return 0;
        }
        if (parsed < 0) {
            return 0;
        }
        return parsed;
    }
    function submitKeywordInput() {
        const escaped = newKeywordInputValue.trim().replaceAll(/[^\p{Letter} ]/gu, '');
        if (escaped !== '') {
            setNewKeywordInputValue('');
            if (!keywordItems.includes(escaped)) {
                setKeywordItems((i) => [...i, escaped]);
            }
        }
    }
    if (!isEditing) {
        return (React.createElement(Base, { width: "100%", "data-cy": 'keywordModelItem', flexDirection: "row", justifyContent: "space-between", display: "flex" },
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 6 },
                React.createElement(Switch, { color: "primary", checked: modelInfo.enabled, onChange: (_e, value) => {
                        onToggleEnabled(value);
                    } }),
                React.createElement(Typography, { variant: "body1", color: "default" }, title)),
            React.createElement(Base, { ml: 'auto' },
                React.createElement(Button, { variant: "text", onClick: () => {
                        reset();
                        onEdit();
                    } }, intl.t('modelManagement:editBtn', 'Edit')))));
    }
    return (React.createElement(Base, { width: "100%", "data-cy": 'keywordModelItem', gap: 8, display: "flex", flexDirection: "column" },
        React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 6 },
            React.createElement(Switch, { color: "primary", checked: modelInfo.enabled, onChange: (_e, value) => {
                    onToggleEnabled(value);
                } }),
            React.createElement(TextField, { value: title, onChange: (e) => setTitle(e.target.value), label: intl.t('modelManagement:keywordScoringModelTitleInputLabel', 'Topic Name'), error: !isTitleValid }),
            rbacService.aiModelDetectSubscriptionsCRUD() && modelInfo.isSaved && (React.createElement(Base, { ml: 'auto' },
                React.createElement(Button, { variant: "text", onClick: () => setShowDeleteDialog(true) }, intl.t('modelManagement:deleteBtn', 'Delete')),
                React.createElement(Dialog, { open: showDeleteDialog, title: intl.t('modelManagement:deleteModelWarningTitle', 'Permanently delete topic?'), helperText: intl.t('modelManagement:deleteModelWarningMsg', 'You are about to permanently delete the topic. The action cannot be undone.'), onClose: () => setShowDeleteDialog(false), actionButtons: [
                        React.createElement(Button, { variant: "text", onClick: () => {
                                setShowDeleteDialog(false);
                            }, color: "error" }, intl.t('cancel', 'Cancel')),
                        React.createElement(Button, { onClick: () => {
                                setShowDeleteDialog(false);
                                onDelete();
                            }, color: "error" }, intl.t('confirm', 'Confirm')),
                    ] }),
                React.createElement(Button, { variant: "text", "data-cy": 'cancelModelEditBtn', disabled: modelInfo.isSaving, onClick: () => {
                        reset();
                        onCancelEdit();
                    } }, intl.t('modelManagement:cancelModelConfigBtn', 'Cancel'))))),
        React.createElement(Base, { display: "flex", flexDirection: "row", gap: 4, alignItems: "center" },
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: (e) => {
                    e.preventDefault();
                    setThreshold(getNormalizedThreshold().toString());
                } },
                React.createElement(TextField, { label: intl.t('modelManagement:keywordScoringModelThreshold', 'Keyword Threshold'), onChange: (e) => {
                        setThreshold(e.target.value);
                    }, value: threshold, type: "number", onBlur: () => {
                        setThreshold(getNormalizedThreshold().toString());
                    } })),
            React.createElement("form", { onSubmit: (e) => {
                    e.preventDefault();
                    submitKeywordInput();
                } },
                React.createElement(TextField, { label: intl.t('modelManagement:keywordScoringModelKeywordsInputLabel', 'Add keywords'), onChange: (e) => {
                        setNewKeywordInputValue(e.target.value);
                    }, value: newKeywordInputValue })),
            React.createElement(Button, { type: "submit", variant: "outlined", color: "primary" }, intl.t('modelManagement:keywordScoringModelAddKeywordBtn', 'Add'))),
        React.createElement(MultipleValueRenderer, { selectedValue: keywordItems.map((i) => ({ text: i, value: i })), onChange: (items) => {
                setKeywordItems(items.map((i) => String(i.value)));
            } }),
        React.createElement(Base, null,
            React.createElement(Button, { color: "primary", disabled: modelInfo.isSaving || !isTitleValid, onClick: () => {
                    submitKeywordInput();
                    onSaveConfiguration({
                        keywords: keywordItems,
                        threshold: getNormalizedThreshold(),
                        title: title,
                    });
                } }, intl.t('modelManagement:saveModelConfigBtn', 'Save')),
            !modelInfo.isSaved && (React.createElement(Button, { variant: "text", "data-cy": 'cancelModelEditBtn', disabled: modelInfo.isSaving, onClick: () => {
                    reset();
                    onCancelEdit();
                } }, intl.t('modelManagement:cancelModelConfigBtn', 'Cancel'))))));
});
