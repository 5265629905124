import { fromJSON, toJSON } from './flatJson';
const CLIPBOARD_ID = 'corti_editor_clipboard';
function verifyClipboardContents(value) {
    return (typeof value === 'object' &&
        value != null &&
        'internalClipboardID' in value &&
        value.internalClipboardID === CLIPBOARD_ID);
}
async function read(clipboardID) {
    try {
        const c = await navigator.clipboard.readText();
        const parsed = fromJSON(c);
        if (verifyClipboardContents(parsed) && parsed.clipboardID === clipboardID) {
            return parsed.data;
        }
    }
    catch (_a) { }
}
async function write(clipboardID, data) {
    await navigator.clipboard.writeText(toJSON({
        internalClipboardID: CLIPBOARD_ID,
        clipboardID,
        data,
    }));
}
export const Clipboard = {
    write,
    read,
};
