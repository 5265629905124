import { orderBy, uniq } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from 'lib/cortiUI';
function AllColumnsListItem(props) {
    const { column, isSelected, onSelect, onRemove } = props;
    return (React.createElement(FormGroup, { key: column.id, "data-cy": "all-columns-list-item", "data-cy-selected": isSelected },
        React.createElement(FormControlLabel, { label: React.createElement(Typography, { variant: "caption", color: isSelected ? 'default' : 'secondaryText' }, column.label), checked: isSelected, control: React.createElement(Checkbox, { style: { padding: 0 } }), onChange: (_e, checked) => {
                checked ? onSelect(column.id) : onRemove(column.id);
            } })));
}
function AllColumnsListSection(props) {
    const { children, sectionTitle } = props;
    const sectionTranslation = {
        case: intl.t('explore:casesTableEditorView.entities.case', 'Case'),
        session: intl.t('explore:casesTableEditorView.entities.session', 'Session'),
        call: intl.t('explore:casesTableEditorView.entities.call', 'Call'),
        profile: intl.t('explore:casesTableEditorView.entities.profile', 'Profile'),
        customProperty: intl.t('explore:casesTableEditorView.entities.customProperty', 'Custom Property'),
    };
    return (React.createElement(Box, { mb: 5 },
        React.createElement(Typography, { mb: 3, variant: "caption" }, sectionTranslation[sectionTitle]),
        children));
}
export function AllColumnsList(props) {
    const { columnsDictionary: allColumns, selectedColumnsIDs, onSelect, onRemove } = props;
    const columnsEntities = uniq(Object.values(allColumns).map((col) => col.entityType));
    return (React.createElement(Box, null, orderBy(columnsEntities).map((entity, idx) => {
        return (React.createElement(AllColumnsListSection, { key: idx, sectionTitle: entity }, orderBy(allColumns, 'id')
            .filter((col) => col.entityType === entity)
            .map((col) => {
            const isSelected = selectedColumnsIDs.includes(col.id);
            return (React.createElement(AllColumnsListItem, { key: col.id, column: col, isSelected: isSelected, onSelect: onSelect, onRemove: onRemove }));
        })));
    })));
}
