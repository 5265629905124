import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Tooltip, Typography } from 'lib/cortiUI';
export const CopyableProperty = ({ title, value }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata' });
    const [showTooltip, setShowTooltip] = React.useState(false);
    async function onCopyClick() {
        try {
            await navigator.clipboard.writeText(value);
            setShowTooltip(true);
        }
        catch (error) {
            // we are not handling the error, but adding a error log to help devs
            console.error(error);
        }
    }
    return (React.createElement(Tooltip, { disableInteractive: true, placement: "top", title: `${title} ${t('copied', 'copied')}`, leaveDelay: 1000, open: showTooltip, onClose: () => setShowTooltip(false) },
        React.createElement(Base, { display: "flex", gap: 2, alignItems: "center", onClick: onCopyClick, className: css({ cursor: 'pointer' }) },
            React.createElement(ContentCopyRoundedIcon, null),
            React.createElement(Typography, null, title))));
};
