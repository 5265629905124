import AddRounded from '@mui/icons-material/AddRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { uuid } from '@corti/uuid';
import { coreStore } from 'browser/stores';
import { Base, Button, ConfirmableTarget, ToggleButton, ToggleButtonGroup, Typography, } from 'lib/cortiUI';
import { CY_SELECTORS, getConditionQuestionTitle } from '../utils';
import { Condition } from './Condition';
export const VisibleIfWidget = React.memo((props) => {
    var _a;
    const { fields, onChange, onClose } = props;
    const getDefaultCondition = () => ({
        id: uuid(),
        fieldID: props.fields[0].id,
        values: [''],
        comparison: 'eq',
    });
    const getDefaultVisibleIf = () => ({
        junction: 'AND',
        conditions: [getDefaultCondition()],
    });
    const data = (_a = props.data) !== null && _a !== void 0 ? _a : getDefaultVisibleIf();
    const { t } = useTranslation('libFeedbackFormManager');
    const [junction, setJunction] = React.useState(data.junction);
    const [conditions, setConditions] = React.useState(data.conditions);
    const handleConditionChange = (condition) => {
        setConditions((current) => current.map((c) => (c.id === condition.id ? Object.assign(Object.assign({}, c), condition) : c)));
    };
    function handleAddCondition() {
        const newCondition = getDefaultCondition();
        setConditions((current) => [...current, newCondition]);
    }
    function handleRemoveCondition(id) {
        setConditions((current) => [...current.filter((c) => c.id !== id)]);
    }
    function getQuestionOptions() {
        return fields.map((field, idx) => ({
            label: field.title ? field.title : getConditionQuestionTitle(idx),
            value: field.id,
        }));
    }
    function handleJunctionChange(value) {
        setJunction(value);
    }
    function validateConditions() {
        const data = { isValid: true, messages: [] };
        conditions.forEach((condition) => {
            if (condition.values.length === 0 || !condition.values[0]) {
                data.isValid = false;
                data.messages.push(t('errors.conditionTitle', "Some conditions don't have their value set."));
            }
        });
        return data;
    }
    function handleApply() {
        const validationData = validateConditions();
        if (!validationData.isValid) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: validationData.messages.join(' '),
            });
            return;
        }
        onChange({ junction, conditions });
        onClose();
    }
    return (React.createElement(Base, { py: 6 },
        React.createElement(Base, { pt: 6, display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { color: "default", variant: "h6" }, t('visibleIfWidget.conditionsListTitle', 'Visibility conditions')),
            React.createElement(ToggleButtonGroup, { "aria-label": "Radio group", value: junction, exclusive: true, size: "small", onChange: (_e, val) => handleJunctionChange(val), "data-cy": CY_SELECTORS.visibleIfWidget.visibilityJunctionWrapper },
                React.createElement(ToggleButton, { value: "AND", "data-cy": CY_SELECTORS.visibleIfWidget.visibilityAndJunctionBtn },
                    ' ',
                    t('visibleIfWidget.and', 'AND')),
                React.createElement(ToggleButton, { value: "OR", "data-cy": CY_SELECTORS.visibleIfWidget.visibilityOrJunctionBtn },
                    ' ',
                    t('visibleIfWidget.or', 'OR')))),
        React.createElement(Base, { display: "grid", gap: 6 }, conditions.map((c, i) => {
            const conditionField = fields.find((f) => f.id === c.fieldID);
            return (React.createElement(Condition, { key: c.id, condition: c, title: `${t('visibleIfWidget.condition', 'Condition')} ${i + 1}`, conditionField: conditionField, questionsOptions: getQuestionOptions(), onChange: handleConditionChange, onRemove: handleRemoveCondition }));
        })),
        React.createElement(Button, { startIcon: React.createElement(AddRounded, { fontSize: "small", color: "primary" }), variant: "text", color: "primary", className: css({ marginLeft: 5 }), size: "small", onClick: handleAddCondition, "data-cy": CY_SELECTORS.visibleIfWidget.addConditionBtn }, t('visibleIfWidget.addCondBtn', 'Add condition')),
        React.createElement(Base, { width: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", py: 5 },
            React.createElement(ConfirmableTarget, { message: t('actionsWarnings.discard', 'Are you sure you would like to discard recent changes?'), onConfirm: onClose, color: "error" }, ({ onClick }) => (React.createElement(Button, { color: "error", onClick: onClick, sx: { mr: 5 }, "data-cy": CY_SELECTORS.visibleIfWidget.closeVisibleIfModalBtn }, t('visibleIfWidget.discard', 'Discard')))),
            React.createElement(Button, { color: "primary", onClick: handleApply, "data-cy": CY_SELECTORS.visibleIfWidget.applyVisibilityConditionsBtn }, t('visibleIfWidget.apply', 'Apply')))));
});
