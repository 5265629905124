import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
// TODO: remove this global
import { trackerService } from 'browser/services/init';
import { Base, Button, Text, Tooltip } from 'lib/cortiUI';
import { CustomPropertiesRenderer } from '../../common/CustomPropertiesRenderer';
import { useGraphEditorCtx } from '../../core/view';
import { AttributeList, AttributeRendererBoolean, AttributeRendererStatic, AttributeRendererString, GenericPanelBundle, } from '../../ui';
import { TimelineEntryAlertNode, ViewNodeModel } from '../nodes';
import { NodeLogicGates } from './NodeLogicGates';
export const NodeInfoPanel = observer(function NodeInfoPanel() {
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const selectedNodes = editor.state.activeBranch.selectedNodes;
    return (React.createElement(GenericPanelBundle, { header: t('nodeInfoPanel.title', 'Node Info') }, selectedNodes.length === 0 ? (t('nodeInfoPanel.noNodesSelectedMsg', 'No nodes selected')) : selectedNodes.length === 1 ? (React.createElement(GraphNodeInfoPanel, { node: selectedNodes[0] })) : (t('nodeInfoPanel.multipleNodesSelectedMsg', 'Multiple nodes selected'))));
});
const GraphNodeInfoPanel = observer(function GraphNodeInfoPanel(props) {
    const { node } = props;
    const { editor } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(Base, { display: "grid", gridGap: 3, gridAutoRows: "max-content", "data-cy": "node-info-panel" },
        React.createElement(AttributeList, null,
            React.createElement(AttributeRendererStatic, { name: 'ID', value: node.id }),
            React.createElement(AttributeRendererStatic, { name: 'Short ID', value: node.shortid }),
            React.createElement(AttributeRendererStatic, { name: t('nodeInfoPanel.nodeTypeAttr', 'Type'), value: node.type }),
            React.createElement(AttributeRendererString, { name: t('nodeInfoPanel.nodeNameAttr', 'Name'), onValueChange: (v) => {
                    node.setName(v);
                }, value: node.name })),
        renderSpecificNodeInfoPanel(node),
        React.createElement(Button, { variant: "text", color: "error", "data-cy": "delete-node-button", onClick: () => {
                editor.dispatch({ type: 'canvas.deleteEntities', data: { ids: [node.id] } });
            } }, t('nodeInfoPanel.deleteNodeBtn', 'Delete Node'))));
});
function renderSpecificNodeInfoPanel(node) {
    if (node instanceof ViewNodeModel) {
        return React.createElement(ViewNodeInfoPanel, { node: node });
    }
    if (node instanceof TimelineEntryAlertNode) {
        return React.createElement(CustomPropertiesRenderer, { customProperties: node.customProperties });
    }
}
const ViewNodeInfoPanel = observer(function ViewNodeInfoPanel(props) {
    const { node } = props;
    const history = useHistory();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-cy": "edit-content-button", onClick: () => {
                history.push(`/content-builder/${node.contentBuilderContext.id}`);
            } }, t('viewNodeInfoPanel.editContentBtn', 'Edit content')),
        React.createElement(AttributeRendererBoolean, { "data-cy": "pinned-node-checkbox", name: t('viewNodeInfoPanel.pinnedInTriageAttrName', 'Add to the pin list in Triage'), onValueChange: (v) => {
                node.setPinnedInTriage(v);
            }, value: node.pinnedInTriage }),
        React.createElement(FavoriteNodeAttributeRenderer, { node: node }),
        React.createElement(NodeLogicGates, { gates: node.logicGates, node: node })));
});
const FavoriteNodeAttributeRenderer = observer((props) => {
    const { node } = props;
    const { editor: { model }, } = useGraphEditorCtx();
    const { t } = useTranslation('libGraphEditor');
    const getFavoriteNode = () => {
        const viewNodes = model.getNodesByType('viewnode');
        for (const viewNode of viewNodes.values()) {
            if (viewNode.favoriteNode) {
                return viewNode;
            }
        }
    };
    const getIsFavoriteNodeRendererDisabled = () => {
        const favoriteNode = getFavoriteNode();
        if (!favoriteNode) {
            return false;
        }
        if (node.id === favoriteNode.id) {
            return false;
        }
        return true;
    };
    const getTooltipTitle = () => {
        const favoriteNode = getFavoriteNode();
        const title = [];
        title.push(t('viewNodeInfoPanel.favoriteNode.attrNameDisabledMsg', 'You can select only one node for the quick access.'));
        if (favoriteNode === null || favoriteNode === void 0 ? void 0 : favoriteNode.branchContext.name) {
            title.push(t('viewNodeInfoPanel.favoriteNode.selectedBranch', 'Selected branch name: {{- selectedBranch}}', {
                selectedBranch: favoriteNode.branchContext.name,
            }));
        }
        if (favoriteNode === null || favoriteNode === void 0 ? void 0 : favoriteNode.name) {
            title.push(t('viewNodeInfoPanel.favoriteNode.selectedNode', 'Selected node name: {{- selectedNode}}', {
                selectedNode: favoriteNode.name,
            }));
        }
        return title.join('\n');
    };
    const attributeContent = (React.createElement(AttributeRendererBoolean, { "data-cy": "favorite-node-checkbox", name: t('viewNodeInfoPanel.favoriteNode.attrName', 'Add quick access to the node in Triage'), onValueChange: (v) => {
            node.setFavoriteNode(v);
            if (v === true) {
                trackerService === null || trackerService === void 0 ? void 0 : trackerService.addAction('[Protocol builder] Favorite Node selected', {
                    nodeName: node.name,
                });
            }
            else {
                trackerService === null || trackerService === void 0 ? void 0 : trackerService.addAction('[Protocol builder] Favorite Node deselected', {
                    nodeName: node.name,
                });
            }
        }, value: node.favoriteNode, disabled: getIsFavoriteNodeRendererDisabled() }));
    if (getIsFavoriteNodeRendererDisabled()) {
        return (React.createElement(Tooltip, { disableInteractive: true, placement: "left", title: React.createElement(Text, { className: css({
                    whiteSpace: 'pre-wrap',
                }) }, getTooltipTitle()) },
            React.createElement("span", null, attributeContent)));
    }
    return attributeContent;
});
