import CropSquareIcon from '@mui/icons-material/CropSquare';
import { observer } from 'mobx-react';
import React from 'react';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { SmartTarget } from 'lib/graphEditor/ui';
export const NodeSmartTarget = observer(function NodeSmartTarget(props) {
    const { nodeID } = props;
    const { editor } = useGraphEditorCtx();
    const node = editor.model.getNodeByID(nodeID);
    if (!node) {
        return React.createElement(SmartTarget, { label: '[missing ref]', color: "error", disabled: true, tooltipTitle: nodeID });
    }
    return (React.createElement(SmartTarget, { label: node.readableTitle, suffix: node.shortid, tooltipTitle: `${node.readableTitle} - ${node.branchContext.name} - #${node.shortid}`, startIcon: React.createElement(CropSquareIcon, { style: { color: 'inherit' } }), onClick: () => {
            editor.dispatch([
                {
                    type: 'canvas.fitNodesIntoView',
                    data: { nodeIDs: [nodeID] },
                },
                {
                    type: 'canvas.selectEntities',
                    data: { ids: [nodeID], type: 'replace' },
                },
            ]);
        } }));
});
