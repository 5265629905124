var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Box, Button, Card } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { Heading3, Heading4, Heading6 } from './Headings';
const MotionCard = motion(Card);
export function Subsection(_a) {
    var { title, smallHeading, postfix, pretitle, color = 'default', children, variants, cardSx } = _a, rest = __rest(_a, ["title", "smallHeading", "postfix", "pretitle", "color", "children", "variants", "cardSx"]);
    const { asButton } = rest;
    const Heading = smallHeading ? Heading4 : Heading3;
    const WrapperContent = (React.createElement(Box, { sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flewGrow: 1,
            gap: 4,
        } },
        pretitle ? (React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
                flexGrow: 1,
            } },
            React.createElement(Heading6, { color: "highlight" }, pretitle),
            React.createElement(Heading, null, title))) : (React.createElement(Box, { sx: { flexGrow: 1 } },
            React.createElement(Heading, null, title))),
        postfix && React.createElement(Heading6, null, postfix)));
    const wrappersx = {
        px: 4,
        py: pretitle ? 4 : 6,
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        textTransform: 'none',
        '--heading-background': `var(--${color}-gradient)`,
        '--heading-color': `var(--${color}-color)`,
    };
    return (React.createElement(MotionCard, { sx: Object.assign({ borderRadius: 2, background: 'rgba(255, 255, 255, 0.5)', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flexStart', alignSelf: 'stretch' }, cardSx), variants: variants, initial: "initial", animate: "animate", exit: "exit" },
        asButton ? (React.createElement(Button, Object.assign({ disableTouchRipple: true, sx: wrappersx }, rest), WrapperContent)) : (React.createElement(Box, Object.assign({ sx: wrappersx }, rest), WrapperContent)),
        children));
}
