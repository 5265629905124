var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { searchInteractions, } from '@corti/mission-control-api';
import { useIntersectionObserver } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, Card, DataGrid } from 'lib/cortiUI';
import { useParameters, useTemplate } from '../../context';
import { InteractionsDataGridLoader, NoRowsOverlay } from './components';
import { getDataGridColumnsData, getDataGridRowsData } from './utils';
export const InteractionsDataGrid = () => {
    const theme = useTheme();
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'callsDataGrid' });
    const history = useHistory();
    const { dateRange, teams, agents, filterRequestParams } = useParameters();
    const { template } = useTemplate();
    const [pagination, setPagination] = React.useState();
    const [items, setItems] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const rows = getDataGridRowsData(items, template === null || template === void 0 ? void 0 : template.id);
    const columns = getDataGridColumnsData();
    React.useEffect(() => {
        if (template === null || template === void 0 ? void 0 : template.id) {
            void loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRequestParams, template === null || template === void 0 ? void 0 : template.id]);
    const handleObserver = React.useCallback(async (entries) => {
        const [target] = entries;
        if (target.isIntersecting && !isDataLoading && pagination) {
            await loadData(pagination);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, isDataLoading, dateRange, teams, agents]);
    const { observerAnchorRef } = useIntersectionObserver(handleObserver);
    const loadData = async (paginationObject) => {
        if (paginationObject === null)
            return;
        const ITEMS_LIMIT = 100;
        setIsDataLoading(true);
        const { status, review_status, review_compliance_status } = filterRequestParams, restParams = __rest(filterRequestParams, ["status", "review_status", "review_compliance_status"]);
        const showLatestOnly = !!(review_compliance_status === null || review_compliance_status === void 0 ? void 0 : review_compliance_status.length) || !!(status === null || status === void 0 ? void 0 : status.length);
        try {
            const _a = await searchInteractions(Object.assign(Object.assign({}, restParams), { latest_only: showLatestOnly ? true : undefined, page_field_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.field_value, page_id_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.id_value, review_compliance_status, size: ITEMS_LIMIT, status, review_status: [...(review_status !== null && review_status !== void 0 ? review_status : []), 'SKIPPED'], template_id: template === null || template === void 0 ? void 0 : template.id })), { results } = _a, response = __rest(_a, ["results"]);
            if (paginationObject) {
                setItems((prev) => [...prev, ...results]);
            }
            else {
                setItems(results);
            }
            if (response.pagination.id_value === null || results.length < ITEMS_LIMIT) {
                setPagination(null);
            }
            else {
                setPagination(response.pagination);
            }
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchInteractionsError', 'Failed to fetch interactions. Please try again later.'),
            });
        }
        finally {
            setIsDataLoading(false);
        }
    };
    function handleRowClick(caseID) {
        history.push(`${history.location.pathname}/${caseID}`);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { p: 0 },
            React.createElement(DataGrid, { rows: rows, columns: columns, pageSize: rows.length, autoHeight: true, disableColumnMenu: true, hideFooter: true, onRowClick: (params) => handleRowClick(params.row.id), loading: isDataLoading, components: {
                    LoadingOverlay: !rows.length ? InteractionsDataGridLoader : Base,
                    NoRowsOverlay: !rows.length && isDataLoading ? Base : NoRowsOverlay,
                }, classes: {
                    virtualScroller: css(getScrollerCss({ theme, overflowHidden: 'none' })),
                }, sx: {
                    border: 'hidden',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                        display: 'none',
                    },
                } }),
            !!rows.length && isDataLoading && React.createElement(InteractionsDataGridLoader, null)),
        React.createElement(Base, { ref: observerAnchorRef, width: "100%", zIndex: 1, className: css({
                visibility: 'hidden',
            }) })));
};
