import React from 'react';
import { Base, Card, CardContent, Separator, Skeleton } from 'lib/cortiUI';
export const QuestionLoader = () => {
    return (React.createElement(Card, { p: 0 },
        React.createElement(CardContent, { sx: { px: 6 } },
            React.createElement(Base, { width: "100%", flexDirection: "column", justifyContent: "stretch", gap: 2, mb: 4 },
                React.createElement(Base, { display: "flex", gap: 2 },
                    React.createElement(Skeleton, { variant: "rounded", height: 40, sx: { flex: 1 } }),
                    React.createElement(Skeleton, { variant: "rounded", height: 40, sx: { flex: 1 } })))),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(Base, { p: 6, display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Skeleton, { variant: "rounded", height: 20, width: "15%" }),
            React.createElement(Base, { display: "flex", gap: 4 },
                React.createElement(Skeleton, { variant: "circular", height: 30, width: 30 }),
                React.createElement(Skeleton, { variant: "circular", height: 30, width: 30 })))));
};
