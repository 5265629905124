/**
 * Returns a human-readable representation of a quantity of bytes in the most reasonable unit of magnitude.
 * @example
 * bytesToHumanReadable(0) // returns "0 bytes"
 * bytesToHumanReadable(1) // returns "1 byte"
 * bytesToHumanReadable(1024, ByteFormat.IEC) // returns "1 KiB"
 * bytesToHumanReadable(1024, ByteFormat.SI) // returns "1.02 kB"
 * @param value The size in bytes.
 * @param format Format using SI (Base 10) or IEC (Base 2). Defaults to SI
 * @returns A string describing the bytes in the most reasonable unit of magnitude.
 */
export function formatBytesToHumanReadable(value, format = 'si') {
    const [multiple, k, suffix] = (format === 'si' ? [1000, 'k', 'B'] : [1024, 'K', 'iB']);
    const exp = (Math.log(value) / Math.log(multiple)) | 0;
    const size = Number((value / Math.pow(multiple, exp)).toFixed(2));
    return size + ' ' + (exp ? (k + 'MGTPEZY')[exp - 1] + suffix : 'byte' + (size !== 1 ? 's' : ''));
}
