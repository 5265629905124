var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ClearIcon from '@mui/icons-material/Clear';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, transparentize } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from '../Base';
import { IconButton } from '../IconButton';
import { InputAdornment, TextField } from '../TextField';
export const SearchBar = (props) => {
    const { value, InputProps, disabled, onClear, className } = props, rest = __rest(props, ["value", "InputProps", "disabled", "onClear", "className"]);
    const theme = useTheme();
    return (React.createElement(TextField, Object.assign({ fullWidth: true, placeholder: intl.t('search', 'Search'), value: value, disabled: disabled, className: css(className), InputProps: Object.assign(Object.assign({}, InputProps), { startAdornment: (React.createElement(InputAdornment, { position: "start" },
                React.createElement(SearchRoundedIcon, { fontSize: "small", className: css({
                        color: disabled
                            ? transparentize(0.2, theme.palette.text.hint)
                            : theme.palette.text.hint,
                    }) }))), endAdornment: (React.createElement(InputAdornment, { position: "end" }, value ? (React.createElement(IconButton, { icon: React.createElement(ClearIcon, null), size: "small", className: css({ color: theme.palette.text.hint }), onClick: onClear !== null && onClear !== void 0 ? onClear : (() => {
                    var _a;
                    return (_a = rest.onChange) === null || _a === void 0 ? void 0 : _a.call(rest, {
                        currentTarget: { value: '' },
                        target: { value: '' },
                    });
                }) })) : (React.createElement(Base, { className: css({ height: 34, width: 34 }) })))) }) }, rest)));
};
