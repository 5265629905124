import { uniq } from 'lodash';
export function getCollectorNamesFromCustomProperies(customProperties) {
    const CUST_PROP_COLLECTOR_NAMES = 'collectorNames';
    const property = customProperties.find((it) => it.key === CUST_PROP_COLLECTOR_NAMES);
    if (property) {
        return uniq(property.value.split(',').map((n) => n.trim()));
    }
    return [];
}
export function getFactIDsFromCustomProperies(customProperties) {
    const CUST_PROP_FACTS_TO_COLLECT = 'factsToCollect';
    const property = customProperties.find((it) => it.key === CUST_PROP_FACTS_TO_COLLECT);
    if (property) {
        return uniq(property.value.split(',').map((n) => n.trim()));
    }
    return [];
}
