import React from 'react';
export class TableColumnsResizeController extends React.Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "MIN_LAST_COLUMN_WIDTH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 300
        });
        Object.defineProperty(this, "mouseXOffsetSnapshot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: undefined
        });
        Object.defineProperty(this, "activeColumnWidthSpanshot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: undefined
        });
        Object.defineProperty(this, "activeColumn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: undefined
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                columns: [],
                isResizing: false,
            }
        });
        Object.defineProperty(this, "onResizeHandleMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e, columnID) => {
                this.mouseXOffsetSnapshot = e.clientX;
                this.activeColumn = this.state.columns.find((col) => col.id === columnID);
                this.activeColumnWidthSpanshot = this.activeColumn
                    ? this.activeColumn.width
                    : this.props.minColumnWidth;
                this.setState({ isResizing: true });
            }
        });
        Object.defineProperty(this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (this.state.isResizing) {
                    const isLastColumn = this.state.columns.length - 1 === this.state.columns.indexOf(this.activeColumn);
                    if (isLastColumn) {
                        //TODO: add resizing functionality for the last column;
                    }
                    else {
                        const { minColumnWidth } = this.props;
                        const delta = e.clientX - this.mouseXOffsetSnapshot;
                        const totalWidth = this.activeColumnWidthSpanshot + delta;
                        const nextWidth = totalWidth >= minColumnWidth ? totalWidth : minColumnWidth;
                        const indexOfColumn = this.state.columns.indexOf(this.activeColumn);
                        const activeColumn = Object.assign(Object.assign({}, this.activeColumn), { width: nextWidth });
                        this.setState((state) => {
                            state.columns.splice(indexOfColumn, 1, activeColumn);
                            return Object.assign(Object.assign({}, state), { columns: state.columns });
                        });
                    }
                    if (this.props.onResizeEnd) {
                        this.props.onResizeEnd(this.state.columns);
                    }
                    this.activeColumn = undefined;
                    this.activeColumnWidthSpanshot = undefined;
                    this.mouseXOffsetSnapshot = undefined;
                    this.setState({ isResizing: false });
                }
            }
        });
        Object.defineProperty(this, "adjustColumnsWidth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (columns) => {
                const { minColumnWidth, defaultColumnWidth } = this.props;
                const minLastColumnWidth = this.MIN_LAST_COLUMN_WIDTH > minColumnWidth ? this.MIN_LAST_COLUMN_WIDTH : minColumnWidth;
                return columns.map((col, idx) => {
                    if (col.width === undefined) {
                        const isColumnLast = columns.length - 1 === idx;
                        if (isColumnLast) {
                            col.width = minLastColumnWidth;
                        }
                        else {
                            col.width = defaultColumnWidth;
                        }
                    }
                    return col;
                });
            }
        });
        Object.defineProperty(this, "getResizeHandleProps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (columnID, props) => {
                if (!this.props.disableResize) {
                    return {
                        onMouseDown: (e) => {
                            this.onResizeHandleMouseDown(e, columnID);
                            if (props && props.onMouseDown) {
                                props.onMouseDown(e);
                            }
                        },
                    };
                }
            }
        });
        this.state = {
            columns: this.adjustColumnsWidth(this.props.columns),
            isResizing: false,
        };
    }
    componentDidMount() {
        document.body.addEventListener('mouseup', this.onMouseUp);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            this.setState({
                columns: this.adjustColumnsWidth(this.props.columns),
            });
        }
    }
    componentWillUnmount() {
        document.body.removeEventListener('mouseup', this.onMouseUp);
    }
    get tableWidth() {
        const totalColumnsWidth = this.state.columns.reduce((sum, col) => sum + (col.width || 0), 0);
        const nextTableWidth = totalColumnsWidth > this.props.tableContainerWidth
            ? totalColumnsWidth
            : this.props.tableContainerWidth;
        return nextTableWidth;
    }
    render() {
        const { columns, isResizing } = this.state;
        const { children } = this.props;
        return children({
            columns: columns,
            tableWidth: this.tableWidth,
            minColumnWidth: this.props.minColumnWidth,
            getResizeHandleProps: this.getResizeHandleProps,
            isResizing: isResizing,
        });
    }
}
Object.defineProperty(TableColumnsResizeController, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        minColumnWidth: 100,
        defaultColumnWidth: 200,
        disableResize: false,
    }
});
