import { Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { uuid } from '@corti/uuid';
import { Base, TextField } from 'lib/cortiUI';
import { useImport } from './ImportContext';
export function ImportJSON() {
    const { t } = useTranslation('libGraphEditor');
    const [inputJSON, setInputJSON] = useState('');
    const { hasError, errorMessage, setRawJSON } = useImport();
    const onJSONChange = (evt) => {
        setInputJSON(evt.target.value);
        setRawJSON(evt.target.value);
    };
    const helperTextId = useMemo(() => `checklist-import-json-helper-text-${uuid()}`, []);
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
        React.createElement(Typography, { variant: "body1" }, t('checklist.import.json.intro', 'Paste JSON into the text field to import')),
        React.createElement(TextField, { label: t('checklist.import.json.label', 'Checklist JSON'), placeholder: t('checklist.import.json.placeholder', 'Paste JSON here'), multiline: true, value: inputJSON, onChange: onJSONChange, rows: 10, error: hasError, helperText: errorMessage, inputProps: {
                sx: { fontSize: '80%', lineHeight: 1 },
                'aria-errormessage': hasError ? helperTextId : undefined,
            }, FormHelperTextProps: { id: helperTextId } })));
}
