import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getTemplatesList } from '@corti/mission-control-api';
import { Base, Skeleton, Typography } from 'lib/cortiUI';
import { TemplateSelector } from '../../components';
import { DiscardDialog } from '../components';
import { useReview } from '../context';
export const Templates = () => {
    const { templateID, setTemplateID, removeCurrentReview, reviewID } = useReview();
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.header.templates' });
    const [isLoading, setIsLoading] = React.useState(false);
    const [templates, setTemplates] = React.useState([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const temporarySelectedTemplateIdRef = React.useRef();
    React.useEffect(() => {
        void fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function fetchTemplates() {
        var _a, _b;
        setIsLoading(true);
        try {
            const res = await getTemplatesList({ status: ['ACTIVE'], published: true });
            if (res.length) {
                setTemplates(res);
                setTemplateID((_b = (_a = res.find((it) => it.is_default)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : res[0].id);
            }
        }
        catch (e) {
            console.error('[Case view]: Could not fetch templates', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    function handleTemplateChange(id) {
        if (id === templateID)
            return;
        if (!reviewID) {
            setTemplateID(id);
        }
        else {
            temporarySelectedTemplateIdRef.current = id;
            setIsDialogOpen(true);
        }
    }
    function handleDiscard() {
        removeCurrentReview();
        if (temporarySelectedTemplateIdRef.current)
            setTemplateID(temporarySelectedTemplateIdRef.current);
        setIsDialogOpen(false);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 3 },
        React.createElement(Typography, { variant: "body2" }, t('template', 'Template')),
        !isLoading && templateID ? (React.createElement(React.Fragment, null,
            React.createElement(TemplateSelector, { options: templates.map((it) => ({
                    id: it.id,
                    primaryText: it.name,
                    default: it.is_default,
                })), selectedTemplateID: templateID, onChange: handleTemplateChange }),
            React.createElement(DiscardDialog, { isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), onDiscard: handleDiscard }))) : (React.createElement(Skeleton, { variant: "text", width: 50 }))));
};
