import Mousetrap from 'mousetrap';
const wrapHandler = (handler, canExecute) => () => {
    if (canExecute === undefined || canExecute() === true) {
        handler();
    }
};
const toMousetrapKey = (keycode, modifier) => {
    if (!modifier) {
        return keycode;
    }
    const modifierArr = typeof modifier === 'string' ? [modifier] : modifier;
    return `${modifierArr.join('+')}+${keycode}`;
};
export class KeybindingsRegistry {
    constructor() {
        Object.defineProperty(this, "bindings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
    }
    register(keybinding) {
        const mousetrapKey = toMousetrapKey(keybinding.keycode, keybinding.modifier);
        if (this.bindings[mousetrapKey]) {
            throw new Error('This keybinding is already used');
        }
        Mousetrap.bind(mousetrapKey, wrapHandler(keybinding.handler, keybinding.canExecute), keybinding.eventType);
        this.bindings[mousetrapKey] = keybinding;
        return {
            unregister: () => {
                this.unregister(keybinding);
            },
        };
    }
    unregister(keybinding) {
        const mousetrapKey = toMousetrapKey(keybinding.keycode, keybinding.modifier);
        const bound = this.bindings[mousetrapKey];
        if (bound === keybinding) {
            delete this.bindings[mousetrapKey];
            Mousetrap.unbind(mousetrapKey, keybinding.eventType);
        }
    }
    get allBindings() {
        return Object.values(this.bindings);
    }
}
