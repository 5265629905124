var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { chain } from 'lodash';
import React from 'react';
import { createCsvFile, saveFileToDisk } from '@corti/browser-file';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { PasswordChangeForm } from 'browser/pages/ProfileView/PasswordChangeForm';
import { rbacService } from 'browser/services/init';
import { observer } from 'browser/stores';
import { authStore } from 'core/auth';
import { repositories } from 'core/repositories';
import { Box, Button, Dialog, Page } from 'lib/cortiUI';
import { SearchBar } from 'lib/cortiUI/components/SearchBar/SearchBar';
import { passwordRequirementsText } from 'lib/passwordRequirementsTexter';
import { AddUpdateUserForm } from './AddUpdateUserForm';
import { ChangeRoleForm } from './ChangeRoleForm';
import { PasswordResetForm } from './PasswordResetForm';
import { Actions } from './UsersDataGrid/Actions';
import { UsersDataGrid } from './UsersDataGrid/UsersDataGrid';
function downloadUsers(users) {
    const serializedUsers = users.map(({ id, name, email }) => ({ id, name, email }));
    const userData = chain(serializedUsers)
        .orderBy((user) => {
        return user.name.toLowerCase();
    }, ['asc'])
        .value();
    const csvFile = createCsvFile(userData);
    saveFileToDisk(csvFile, 'users.csv');
}
let TeamViewComponent = class TeamViewComponent extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                showAddUserModal: false,
                showPasswordChangeModal: false,
                showPasswordResetModal: false,
                showRoleChangeModal: false,
                showDeleteUserModal: false,
                editingUser: undefined,
                filterInput: '',
            }
        });
        Object.defineProperty(this, "closeModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    showAddUserModal: false,
                    showPasswordChangeModal: false,
                    showPasswordResetModal: false,
                    showRoleChangeModal: false,
                    showDeleteUserModal: false,
                    editingUser: undefined,
                });
            }
        });
        Object.defineProperty(this, "startEditingUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.setState({ editingUser: user, showAddUserModal: true });
            }
        });
        Object.defineProperty(this, "startCreatingUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ showAddUserModal: true, editingUser: undefined });
            }
        });
        Object.defineProperty(this, "startPasswordChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.setState({ showPasswordChangeModal: true, editingUser: user });
            }
        });
        Object.defineProperty(this, "startPasswordReset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.setState({ showPasswordResetModal: true, editingUser: user });
            }
        });
        Object.defineProperty(this, "startRoleChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.setState({ showRoleChangeModal: true, editingUser: user });
            }
        });
        Object.defineProperty(this, "startDeletingUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.setState({ showDeleteUserModal: true, editingUser: user });
            }
        });
        Object.defineProperty(this, "handleUserDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                repositories.users.deleteUser(id);
            }
        });
        Object.defineProperty(this, "getAllUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const allUsers = repositories.users.users;
                return chain(allUsers)
                    .filter((user) => {
                    if (this.state.filterInput) {
                        const input = this.state.filterInput.toLowerCase();
                        return (user.name.toLowerCase().includes(input) || user.email.toLowerCase().includes(input));
                    }
                    else {
                        return true;
                    }
                })
                    .orderBy((user) => {
                    return user.name.toLowerCase();
                }, ['asc'])
                    .value();
            }
        });
    }
    componentDidMount() {
        repositories.users.loadAllUsers();
    }
    render() {
        const { theme } = this.props;
        const { editingUser } = this.state;
        const allUsers = repositories.users.users;
        return (React.createElement(Page, { "data-cy": "users-view" },
            React.createElement(Box, { mb: 4, flexDirection: "row", width: '100%' },
                React.createElement(SearchBar, { fullWidth: true, placeholder: intl.t('search', 'Search'), value: this.state.filterInput, onChange: (e) => this.setState({ filterInput: e.target.value }), inputProps: {
                        'data-cy': 'users-search-input',
                    } }),
                React.createElement(Button, { className: css({ marginLeft: theme.space[2], flexShrink: 0 }), variant: "outlined", size: "large", onClick: () => downloadUsers(allUsers), "data-cy": "downloadAllUsersBtn" }, intl.t('team:downloadAllUsersBtn', 'Export')),
                rbacService.usersAndRolesCRUD() && (React.createElement(Button, { className: css({ marginLeft: 4, flexShrink: 0 }), size: "large", color: "primary", onClick: this.startCreatingUser, "data-cy": "add-new-user", startIcon: React.createElement(AddRoundedIcon, { fontSize: "small" }) }, intl.t('add', 'Add')))),
            React.createElement(UsersDataGrid, { users: this.getAllUsers(), renderActions: (user) => (React.createElement(Actions, { user: user, onEdit: () => this.startEditingUser(user), onChangePassword: () => this.startPasswordChange(user), onResetPassword: () => this.startPasswordReset(user), onDeleteUser: () => this.startDeletingUser(user), onChangeRole: () => this.startRoleChange(user) })) }),
            this.state.showAddUserModal && (React.createElement(Dialog, { "data-cy": "add-update-user-dialog", open: true, onClose: this.closeModal, title: editingUser
                    ? intl.t('team:editUser', 'Edit user')
                    : intl.t('team:createUser', 'Create new user') },
                React.createElement(AddUpdateUserForm, { user: editingUser, onSubmit: this.closeModal }))),
            React.createElement(Dialog, { open: this.state.showPasswordResetModal, onClose: this.closeModal, title: intl.t('team:resetPassword', 'Reset password') },
                React.createElement(PasswordResetForm, { initialEmail: editingUser == undefined ? undefined : editingUser.email, onSubmit: this.closeModal })),
            editingUser && (React.createElement(React.Fragment, null,
                React.createElement(Dialog, { open: this.state.showRoleChangeModal, onClose: this.closeModal, title: intl.t('team:changeRole', 'Change user role') },
                    React.createElement(ChangeRoleForm, { userId: editingUser.id, roleId: editingUser.role && editingUser.role.id, onSubmit: this.closeModal })),
                React.createElement(Dialog, { "data-cy": "change-password-dialog", open: this.state.showPasswordChangeModal, onClose: this.closeModal, title: intl.t('team:changePassword', 'Change password'), helperText: passwordRequirementsText() },
                    React.createElement(PasswordChangeForm, { userId: this.state.editingUser.id, onSuccess: this.closeModal, isOldPasswordRequired: authStore.organization.organizationSecuritySettings
                            .oldPasswordRequiredForPasswordReset })))),
            React.createElement(Dialog, { open: this.state.showDeleteUserModal, title: intl.t('team:deleteUserTitle', 'Permanently delete user?'), helperText: intl.t('team:deleteUserMessage', 'You are about to permanently delete the user. The action cannot be undone.'), onClose: this.closeModal, actionButtons: [
                    React.createElement(Button, { key: "cancel", variant: "text", color: "error", onClick: this.closeModal }, intl.t('cancel', 'Cancel')),
                    React.createElement(Button, { key: "confirm", "data-cy": "confirm-delete-user-btn", color: "error", onClick: () => {
                            this.closeModal();
                            editingUser && this.handleUserDelete(editingUser.id);
                        } }, intl.t('confirm', 'Confirm')),
                ] })));
    }
};
TeamViewComponent = __decorate([
    observer
], TeamViewComponent);
export { TeamViewComponent };
export const UsersView = withTheme(TeamViewComponent);
