var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Downshift from 'downshift';
import { truncate } from 'lodash';
import React, { useContext } from 'react';
import { Editor, Range as SlateRange, Text as SlateText, Transforms, createEditor, } from 'slate';
import { Editable, Slate, useFocused, useSelected, withReact, } from 'slate-react';
import { tokenize } from '@corti/lib/graphs/tokenizer';
import { css } from '@corti/style';
import { Base } from 'lib/cortiUI/components/Base';
import { Box } from 'lib/cortiUI/components/Box';
import { Card } from 'lib/cortiUI/components/Card';
import { Popper } from 'lib/cortiUI/components/Popper';
import { Tooltip } from 'lib/cortiUI/components/Tooltip';
import { Typography } from 'lib/cortiUI/components/Typography';
import { ExpressionInputValue, isCustomElement, } from './value';
const ExpressionCtx = React.createContext({});
const useExpressionInputCtx = () => {
    return useContext(ExpressionCtx);
};
const CustomNodeFactory = {
    ReferencedElement: (refID) => {
        return {
            type: 'ref-element',
            data: {
                refID: refID,
            },
            children: [{ type: 'text', text: '' }],
        };
    },
};
function extractSuggestableText(editor) {
    var _a;
    return (_a = findSuggestable(editor)) === null || _a === void 0 ? void 0 : _a.match;
}
function findSuggestable(editor) {
    if (!editor.selection) {
        return;
    }
    const [start] = SlateRange.edges(editor.selection);
    const wordBefore = Editor.before(editor, start, { unit: 'word' });
    const beforeRange = wordBefore && Editor.range(editor, wordBefore, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    const beforeMatch = beforeText && beforeText.match(/^\$(\S*)$/);
    if (beforeMatch) {
        return {
            match: beforeMatch[1],
            range: beforeRange,
        };
    }
}
const withReferencedElements = (editor) => {
    const { isInline, isVoid } = editor;
    // @ts-expect-error until slate allows better typescript typings
    editor.isInline = (element) => {
        var _a;
        // @ts-expect-error
        return (_a = isCustomElement(element)) !== null && _a !== void 0 ? _a : isInline(element);
    };
    // @ts-expect-error until slate allows better typescript typings
    editor.isVoid = (element) => {
        var _a;
        // @ts-expect-error
        return (_a = isCustomElement(element)) !== null && _a !== void 0 ? _a : isVoid(element);
    };
    return editor;
};
const decorate = ([node, path]) => {
    const ranges = [];
    if (!SlateText.isText(node)) {
        return ranges;
    }
    const tokens = tokenize(node.text);
    for (const token of tokens) {
        const end = token.start + token.text.length;
        ranges.push({
            tokenType: token.type,
            anchor: { path, offset: token.start },
            focus: { path, offset: end },
        });
    }
    return ranges;
};
const SINGLELINE_STYLES = {
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};
const READONLY_STYLES = {
    opacity: 0.5,
    cursor: 'not-allowed',
    filter: 'grayscale(1)',
};
export function ExpressionInput(props) {
    const { suggestions, value = '' } = props;
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const [editor] = React.useState(() => withReferencedElements(withReact(createEditor())));
    const [editorValueInitialisedOnceAndNotUsedAfter] = React.useState(() => ExpressionInputValue.fromString(value));
    const valueTracker = React.useRef(value);
    const tempSelection = React.useRef(null);
    const popupMenuAnchorRef = React.useRef(null);
    React.useEffect(() => {
        if (value !== valueTracker.current) {
            valueTracker.current = value;
            // @ts-expect-error until slate fixes types
            editor.children = ExpressionInputValue.fromString(value);
            Transforms.collapse(editor, { edge: 'end' });
            editor.onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    const insertBlockReference = (item) => {
        var _a;
        // restore selection if it was lost
        editor.selection = (_a = tempSelection.current) !== null && _a !== void 0 ? _a : editor.selection;
        const suggestable = findSuggestable(editor);
        const refNode = CustomNodeFactory.ReferencedElement(item.value);
        Transforms.select(editor, suggestable.range);
        // @ts-expect-error
        Transforms.insertNodes(editor, refNode);
        Transforms.insertText(editor, ' ');
        Transforms.move(editor);
    };
    const openSuggestionsMenu = () => {
        setMenuAnchor(popupMenuAnchorRef.current);
    };
    const hideSuggestionsMenu = () => {
        setMenuAnchor(null);
    };
    const handleSuggestionPreview = () => {
        var _a;
        const selection = getSelection();
        if (!selection || !selection.isCollapsed) {
            return;
        }
        const mentionText = extractSuggestableText(editor);
        if (mentionText == null) {
            hideSuggestionsMenu();
            return;
        }
        // this check prevents popover menu from jumping around on every value change
        if (!menuAnchor) {
            openSuggestionsMenu();
        }
        (_a = props.onBlockSearchRequest) === null || _a === void 0 ? void 0 : _a.call(props, mentionText);
    };
    return (React.createElement(ExpressionCtx.Provider, { value: {
            resolveItemReference: props.resolveItemReference,
        } },
        React.createElement(Downshift, { defaultHighlightedIndex: 0, itemToString: (item) => (item ? item.text : ''), isOpen: Boolean(menuAnchor), onSelect: (item) => {
                if (item) {
                    insertBlockReference(item);
                }
            }, onStateChange: (change) => {
                switch (change.type) {
                    case Downshift.stateChangeTypes.keyDownEscape:
                        hideSuggestionsMenu();
                        break;
                    case Downshift.stateChangeTypes.blurInput:
                        if (Boolean(menuAnchor)) {
                            hideSuggestionsMenu();
                        }
                        break;
                    default: {
                        if (change.isOpen === false) {
                            hideSuggestionsMenu();
                            return;
                        }
                    }
                }
            } }, ({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => {
            var _a, _b, _c, _d;
            // since downshift makes assumptions about `input` component and it's
            // callback event signatures, we need to manually modify some of these callbacks
            // to trick downshift that we're using a traditional "plain text" input element
            const _e = getInputProps({
                onBlur: props.onBlur,
            }), { onChange: onInputChange, onKeyDown: onInputKeyDown, value: inputValue } = _e, restInputProps = __rest(_e, ["onChange", "onKeyDown", "value"]);
            return (React.createElement("div", { style: Object.assign(Object.assign({}, (_a = props.rootProps) === null || _a === void 0 ? void 0 : _a.style), { position: 'relative' }), className: (_b = props.rootProps) === null || _b === void 0 ? void 0 : _b.className },
                React.createElement("div", { style: { position: 'absolute', width: '100%', height: '100%', zIndex: -1 }, ref: popupMenuAnchorRef }),
                React.createElement(Slate, { editor: editor, 
                    // @ts-expect-error until slate fixes types
                    value: editorValueInitialisedOnceAndNotUsedAfter, onChange: (value) => {
                        // short circuit when the change is the selection change and do not notify the parent
                        const isNonValChange = editor.operations.every((op) => op.type === 'set_selection');
                        if (isNonValChange) {
                            return;
                        }
                        const inputValue = extractSuggestableText(editor);
                        // trick downshift by faking `onChange` event
                        onInputChange && onInputChange({ target: { value: inputValue } });
                        handleSuggestionPreview();
                        // @ts-expect-error until slate fixes types
                        const valstring = ExpressionInputValue.toString(value);
                        valueTracker.current = valstring;
                        props.onChange && props.onChange(valstring);
                    } },
                    React.createElement(Editable, Object.assign({}, restInputProps, { as: "div", spellCheck: false, placeholder: props.placeholder, tabIndex: props.disabled ? -1 : 0, readOnly: props.disabled || props.readOnly, 
                        // @ts-expect-error
                        renderElement: CustomElementRenderer, renderLeaf: RenderLeaf, style: Object.assign(Object.assign(Object.assign({}, (props.singleLine ? SINGLELINE_STYLES : {})), (props.disabled ? READONLY_STYLES : {})), (_c = props.inputProps) === null || _c === void 0 ? void 0 : _c.style), className: css({
                            fontFamily: 'monospace',
                            fontWeight: 500,
                            lineHeight: 1.5,
                        }, (_d = props.inputProps) === null || _d === void 0 ? void 0 : _d.className), onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                // makes text input single line (although it won't work when pasting)
                                e.preventDefault();
                                // fixes issue where downshift makes text editor loose selection state
                                tempSelection.current = editor.selection;
                            }
                            if (isOpen) {
                                onInputKeyDown && onInputKeyDown(e);
                            }
                        }, onKeyUp: () => {
                            tempSelection.current = null;
                        }, decorate: props.disableSyntaxHighlighting ? undefined : decorate }))),
                React.createElement(Popper, { open: Boolean(isOpen && (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) !== 0), anchorEl: menuAnchor, placement: "bottom-start" },
                    React.createElement(Card, Object.assign({ "data-cy": "expression-input-suggestions", px: 0, py: 0, minWidth: 320, maxHeight: 290, overflowY: 'auto' }, (isOpen
                        ? getMenuProps({ refKey: 'innerRef' }, { suppressRefError: true })
                        : {})), suggestions === null || suggestions === void 0 ? void 0 : suggestions.map((suggestion, idx) => {
                        return (React.createElement(Box, Object.assign({ key: suggestion.value, background: highlightedIndex === idx ? '#dbecff' : undefined, px: 4, py: 3 }, getItemProps({
                            index: idx,
                            item: suggestion,
                        })),
                            React.createElement(Base, { display: "grid", gridTemplateColumns: "auto minmax(30%, 1fr)", alignItems: "center" },
                                React.createElement(Typography, { variant: "subtitle2", noWrap: true, color: "default", mr: 1, "data-cy": "expression-input-suggestion-text" }, suggestion.text),
                                suggestion.altText && (React.createElement(Typography, { variant: "footnote", color: "default", noWrap: true }, suggestion.altText))),
                            React.createElement(Typography, { variant: "footnote", noWrap: true }, suggestion.description)));
                    })))));
        })));
}
function CustomElementRenderer(props) {
    const { children, attributes, element } = props;
    const isSelected = useSelected();
    const isFocused = useFocused();
    const ctx = useExpressionInputCtx();
    if (isCustomElement(element)) {
        const resolvedRef = ctx.resolveItemReference({ id: element.data.refID });
        const tooltipContent = resolvedRef
            ? [resolvedRef.text, resolvedRef.altText, resolvedRef.description]
                .filter((it) => !!it)
                .join(' - ')
            : element.data.refID;
        return (React.createElement(ReferencedElementRenderer, { attributes: attributes, refElementID: element.data.refID, text: resolvedRef ? resolvedRef.text : 'missing ref', children: children, tooltipContent: tooltipContent, isSelected: isSelected, isFocused: isFocused, background: (resolvedRef === null || resolvedRef === void 0 ? void 0 : resolvedRef.type) === 'block' ? 'rgb(234 176 176 / 20%)' : 'rgb(157 70 236 / 13%)', color: (resolvedRef === null || resolvedRef === void 0 ? void 0 : resolvedRef.type) === 'block' ? '#ec4689' : 'rgb(157 70 236)', borderBottom: (resolvedRef === null || resolvedRef === void 0 ? void 0 : resolvedRef.external) ? `2px solid #fcc34a` : undefined }));
    }
    return React.createElement("span", Object.assign({}, attributes), children);
}
function ReferencedElementRenderer(props) {
    const { text, refElementID, attributes, children, background, color, tooltipContent, borderBottom, isSelected, isFocused, } = props;
    const component = (React.createElement("span", Object.assign({ "data-refelementid": refElementID, className: css({
            background,
            color,
            borderBottom,
            borderRadius: 2,
            boxShadow: isSelected && isFocused ? `0 0 0 1px black` : undefined,
        }) }, attributes),
        truncate(text, { omission: '\u2026', length: 24 }),
        children));
    if (tooltipContent) {
        return (React.createElement(Tooltip, { disableInteractive: true, title: tooltipContent }, component));
    }
    return component;
}
function RenderLeaf({ attributes, children, leaf }) {
    const type = leaf.tokenType;
    let color = 'inherit';
    switch (type) {
        case 'operator': {
            color = '#0095ff';
            break;
        }
        case 'keyword':
            color = '#d26b00';
            break;
        case 'function':
            color = '#a543c3';
            break;
        case 'number':
            color = '#ff5200';
            break;
        case 'string':
            color = '#13a561';
            break;
        case 'group':
            color = '#a909a9';
            break;
    }
    return (React.createElement("span", Object.assign({}, attributes, { className: css({
            color,
        }) }), children));
}
