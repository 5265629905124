import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import React from 'react';
import { Base } from '../Base';
import { FormControlLabel } from '../Form';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
export const SettingsCheckInput = ({ label, description, inputComponent, disabled = false, labelInfo, }) => {
    const renderText = (type, value) => {
        if (typeof value === 'string') {
            return (React.createElement(Typography, { color: type === 'label' && !disabled ? 'default' : 'inherit', variant: "body2" }, value));
        }
        return value;
    };
    return (React.createElement(Base, null,
        React.createElement(FormControlLabel, { control: inputComponent, label: React.createElement(Base, { display: "flex", flexDirection: "column" },
                React.createElement(Base, { display: "flex", gap: 4, alignItems: "center" },
                    renderText('label', label),
                    labelInfo && (React.createElement(Tooltip, { title: labelInfo },
                        React.createElement(InfoRoundedIcon, { color: "action", fontSize: "small" })))),
                description && renderText('description', description)), disabled: disabled })));
};
