import { useEffect } from 'react';
import { useGraphEditorCtx } from '../core/view';
import { useChecklistView } from './useChecklistView';
export function useSyncState() {
    const { editor: { model: { checklists }, }, } = useGraphEditorCtx();
    const setChecklists = useChecklistView(({ setChecklists }) => setChecklists);
    useEffect(() => {
        setChecklists(checklists.items);
    }, [setChecklists, checklists.items]);
}
