import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { css } from '@corti/style';
import { Popover } from 'lib/cortiUI/components/Popover';
import { Popper } from 'lib/cortiUI/components/Popper';
export function RichTextElement({ renderCard, isEditing, attributes, children, className }) {
    const [anchorEl, setAnchorEl] = useState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOpenCard = useCallback(debounce((anchor) => {
        setAnchorEl(anchor);
    }, 300), []);
    return (React.createElement("div", Object.assign({}, attributes, { tabIndex: 0, className: css({
            backgroundColor: 'transparent',
            color: 'inherit',
            display: 'inline-block',
        }, className), onMouseLeave: () => {
            debouncedOpenCard.cancel();
            setAnchorEl(null);
        }, onBlur: () => {
            debouncedOpenCard.cancel();
            setAnchorEl(null);
        }, onMouseEnter: (e) => debouncedOpenCard(e.currentTarget), onFocus: (e) => debouncedOpenCard(e.currentTarget) }),
        children,
        isEditing ? (React.createElement(Popover, { disableAutoFocus: true, anchorEl: anchorEl, open: Boolean(anchorEl), transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            } }, renderCard)) : (React.createElement(Popper, { id: "rich-text-popper", anchorEl: anchorEl, open: Boolean(anchorEl), placement: "bottom-start", modifiers: [
                {
                    name: 'computeStyles',
                    options: {
                        gpuAcceleration: false,
                    },
                },
            ] }, renderCard))));
}
