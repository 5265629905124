export const commentTransformer = {
    toRepo(input) {
        return {
            id: input.id,
            text: input.text,
            datetime: input.datetime,
            createdBy: {
                id: input.createdBy.id,
                name: input.createdBy.name,
            },
        };
    },
    fromRepo(input) {
        return {
            id: input.id,
            text: input.text,
            datetime: input.datetime,
            createdBy: {
                id: input.createdBy.id,
                name: input.createdBy.name,
            },
        };
    },
};
