var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observer } from '@corti/observer';
import { BlockValueUtils } from './BlockValueStore';
export class TempBlockValueStore {
    constructor(blockValueStore) {
        Object.defineProperty(this, "blockValueStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: blockValueStore
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_all", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.observer = new Observer();
        this.reset();
    }
    reset() {
        this._all = [];
    }
    add(value) {
        this._all.push(value);
        this.observer.fireEvent('value-added', value);
    }
    remove(identifier) {
        const idx = this._all.findIndex((it) => isEqual(it.identifier, identifier));
        if (idx > -1) {
            const target = this._all[idx];
            this._all.splice(idx, 1);
            this.observer.fireEvent('value-removed', target);
        }
    }
    /**
     * Temporary block values are added/removed when blocks are focused or hovered.
     * There might be multiple temporary values stored in the state if both actions are taken,
     * but we only care about the last one as a final value.
     */
    get activeValue() {
        const active = this._all[this._all.length - 1];
        if (!active) {
            return;
        }
        const existing = this.blockValueStore.all.find((it) => isEqual(it.identifier, active.identifier));
        if (!existing) {
            return active;
        }
        if (BlockValueUtils.isTruthy(existing) === false && BlockValueUtils.isTruthy(active) === true) {
            return active;
        }
    }
    onAdd(cb) {
        return this.observer.on('value-added', cb);
    }
    onRemove(cb) {
        return this.observer.on('value-removed', cb);
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], TempBlockValueStore.prototype, "_all", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TempBlockValueStore.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TempBlockValueStore.prototype, "add", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TempBlockValueStore.prototype, "remove", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TempBlockValueStore.prototype, "activeValue", null);
