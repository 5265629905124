import { map, omit } from 'lodash';
export function createCsvFile(data) {
    const sample = data[0];
    const omitVals = ['hit'];
    const dataRows = data.map((dataItem) => {
        return map(omit(dataItem, omitVals), (prop) => prop).join(',');
    });
    if (sample) {
        const header = Object.keys(sample)
            .filter((key) => !omitVals.includes(key))
            .join(',');
        dataRows.unshift(header);
    }
    const file = new Blob([dataRows.join('\n')], { type: 'text/csv' });
    return file;
}
