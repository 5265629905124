import React from 'react';
/**
 * Hook that extends useRef functionality and accepts lazy initial value.
 */
export function useLazyRef(init) {
    const ref = React.useRef(null);
    if (ref.current == null) {
        ref.current = init();
    }
    return ref;
}
