var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useContext } from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ButtonBase } from '../Button';
import { FocusElement } from '../FocusContext';
import { BreadcrumbFocusGroupIDContext } from './BreadcrumbFocusGroupIDContext';
export function Breadcrumb(props) {
    const groupID = useContext(BreadcrumbFocusGroupIDContext);
    if (groupID) {
        return (React.createElement(FocusElement, { defaultGroupID: groupID }, ({ registerElementRef }) => React.createElement(BreadcrumbInner, Object.assign({ ref: registerElementRef }, props))));
    }
    return React.createElement(BreadcrumbInner, Object.assign({}, props));
}
const BreadcrumbInner = forwardRef((_a, ref) => {
    var { hasFocusStyling, onClick, children, active, className } = _a, rest = __rest(_a, ["hasFocusStyling", "onClick", "children", "active", "className"]);
    const theme = useTheme();
    return (React.createElement(ButtonBase, Object.assign({ ref: ref, "data-cy": "breadcrumbs-item", onClick: onClick, className: css(Object.assign({ whiteSpace: 'nowrap', color: active ? theme.palette.text.primary : theme.palette.text.secondary, ':hover': {
                color: active ? theme.palette.text.primary : theme.palette.text.hint,
            } }, (hasFocusStyling && {
            '&:focus:before': {
                borderRadius: theme.shape.borderRadius,
            },
        })), hasFocusStyling && getFocusCss({ theme }), className) }, rest), children));
});
