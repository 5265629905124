import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, ContextMenuDivider, ContextMenuItem, Modal, ModalContent, ModalHeader, Typography, useContextMenu, } from 'lib/cortiUI';
import { ComponentInstance } from 'lib/graphEditor/contentBuilder/ComponentInstance';
import { ViewContentCard } from 'lib/graphEditor/contentBuilder/view';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { ElementRenderer as CustomElementRenderer } from './ElementRenderer';
import { SelectableElement } from './SelectableElement';
import { ContentBuilderCtx } from './contentBuilderCtx';
import { ElementDragSource, ElementDropTarget } from './dnd';
export const ElementTreeRenderer = observer((props) => {
    const { contentBuilderContext } = props;
    const [currentModal, setCurrentModal] = React.useState();
    const { editor } = useGraphEditorCtx();
    const { openContextMenu } = useContextMenu();
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(ContentBuilderCtx.Provider, { value: contentBuilderContext },
        React.createElement(ViewContentCard, { onContextMenu: (e) => {
                openContextMenu(e, React.createElement(React.Fragment, null,
                    React.createElement(ContextMenuItem, { title: "Paste element", onClick: () => {
                            editor.dispatch({
                                type: 'viewBuilder.pasteInstances',
                                data: {
                                    contentBuilderID: contentBuilderContext.id,
                                },
                            });
                        } })));
            } },
            props.contentBuilderContext.elements.length === 0 && (React.createElement(Typography, { variant: "body2", color: "hint" }, t('contentBuilder.renderer.emptyElementsPlaceholderMsg', 'This node has no elements. Drop elements here to create'))),
            React.createElement(ElementDropTarget, { onDrop: () => {
                    return { dropTarget: { element: contentBuilderContext, positionIndex: 0 } };
                } }),
            props.contentBuilderContext.elements.map((element, idx) => {
                return (React.createElement(React.Fragment, { key: element.id },
                    React.createElement(BaseInstanceRenderer, { model: element, renderElement: (model) => React.createElement(CustomElementRenderer, { model: model }), onContextMenu: (e) => {
                            openContextMenu(e, React.createElement(React.Fragment, null,
                                element instanceof ComponentInstance ? (React.createElement(React.Fragment, null,
                                    React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.explode', 'Explode component'), onClick: () => {
                                            setCurrentModal({ type: 'explode-component-instance', element });
                                        } }))) : (React.createElement(React.Fragment, null,
                                    React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.convertToComponent', 'Make component'), onClick: () => {
                                            editor.dispatch({
                                                type: 'viewBuilder.convertToComponentInstance',
                                                data: {
                                                    instanceID: element.id,
                                                },
                                            });
                                        } }))),
                                React.createElement(ContextMenuDivider, null),
                                React.createElement(SharedElementContextMenu, { graphEditor: editor, model: element })));
                        } }),
                    React.createElement(ElementDropTarget, { onDrop: () => {
                            return {
                                dropTarget: { element: contentBuilderContext, positionIndex: idx + 1 },
                            };
                        } })));
            })),
        (currentModal === null || currentModal === void 0 ? void 0 : currentModal.type) === 'explode-component-instance' ? (React.createElement(ExplodeComponentInstanceDialog, { componentInstance: currentModal.element, graphEditor: editor, onClose: () => {
                setCurrentModal(undefined);
            } })) : null));
});
const BaseInstanceRenderer = observer((props) => {
    const { model } = props;
    const unwrappedElement = model.wrappedElement;
    const isComponent = model instanceof ComponentInstance;
    const componentColor = {
        dark: '#4115c5',
        main: '#6944da',
    };
    const elementColor = {
        dark: '#4b9bd6',
        main: '#23a2ff',
    };
    const color = isComponent ? componentColor : elementColor;
    return (React.createElement(ElementDragSource, { elementType: unwrappedElement.type, onCreate: (target, position) => {
            target.addElement(model, position);
        }, children: ({ connector }) => {
            return (React.createElement(SelectableElement, { model: model, selectColor: color.dark, preselectColor: color.main },
                React.createElement(Base, { ref: connector, p: 3, onContextMenu: (e) => {
                        var _a;
                        e.preventDefault();
                        e.stopPropagation();
                        (_a = props.onContextMenu) === null || _a === void 0 ? void 0 : _a.call(props, e);
                    } },
                    React.createElement(Typography, { variant: "footnote", color: "hint", noSelect: true, mb: 4 }, isComponent ? `${unwrappedElement.type} (component)` : unwrappedElement.type),
                    props.renderElement(unwrappedElement))));
        } }));
});
function SharedElementContextMenu(props) {
    const { model, graphEditor } = props;
    const { t } = useTranslation('libGraphEditor');
    return (React.createElement(React.Fragment, null,
        React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.copy', 'Copy'), onClick: () => {
                graphEditor.dispatch({
                    type: 'viewBuilder.copyInstances',
                    data: {
                        instanceIDs: [model.id],
                    },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.paste', 'Paste'), onClick: async () => {
                graphEditor.dispatch({
                    type: 'viewBuilder.pasteInstances',
                    data: { contentBuilderID: model.context.id },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.duplicate', 'Duplicate'), onClick: () => {
                graphEditor.dispatch({
                    type: 'viewBuilder.duplicateBlockInstance',
                    data: {
                        instanceID: model.id,
                    },
                });
            } }),
        React.createElement(ContextMenuDivider, null),
        React.createElement(ContextMenuItem, { title: t('contentBuilder.elementCtxMenu.delete', 'Delete'), onClick: () => {
                graphEditor.dispatch({
                    type: 'viewBuilder.deleteBlockInstance',
                    data: { instanceID: props.model.id },
                });
            } })));
}
function ExplodeComponentInstanceDialog(props) {
    const { componentInstance, graphEditor, onClose } = props;
    const { t } = useTranslation('libGraphEditor');
    const [refCount] = React.useState(() => {
        return getRefCount();
    });
    function handleExplode(replaceRefs) {
        graphEditor.dispatch({
            type: 'viewBuilder.explodeComponentInstance',
            data: {
                instanceID: componentInstance.id,
                replaceRefs: replaceRefs,
            },
        });
        onClose();
    }
    function getRefCount() {
        let totalRefCount = 0;
        for (const refItem of componentInstance.wrappedElement.referenceableItems.values()) {
            const usages = graphEditor.logicContext.getRefItemUsages(refItem.refItemValue);
            totalRefCount += usages.size;
        }
        return totalRefCount;
    }
    const message = refCount === 0
        ? t('contentBuilder.explodeComponentDialog.componentHasZeroRefsMsg', `Component is not referenced in logic gates`)
        : t('contentBuilder.explodeComponentDialog.componentHasRefsMsg', `Component has references in {{refCount}} logic gates`, { refCount });
    return (React.createElement(Modal, { open: true, onClose: onClose },
        React.createElement(Base, null,
            React.createElement(ModalHeader, { title: t('contentBuilder.explodeComponentDialog.title', 'Explode Component') }),
            React.createElement(ModalContent, null,
                React.createElement(Base, { display: "grid", gridAutoFlow: "row", gridGap: 5 },
                    React.createElement(Typography, { variant: "body2", color: "default", mb: 5 }, message),
                    refCount !== 0 && (React.createElement(Button, { color: "primary", onClick: () => {
                            handleExplode('node');
                        } }, t('contentBuilder.explodeComponentDialog.replaceRefsInCurrentNodeBtn', 'Replace references in current node'))),
                    refCount !== 0 && (React.createElement(Button, { color: "primary", onClick: () => {
                            handleExplode('branch');
                        } }, t('contentBuilder.explodeComponentDialog.replaceRefsInCurrentBranchBtn', 'Replace references in current branch'))),
                    React.createElement(Button, { color: "primary", onClick: () => {
                            handleExplode('none');
                        } }, refCount === 0
                        ? t('contentBuilder.explodeComponentDialog.explodeBtn', 'Explode')
                        : t('contentBuilder.explodeComponentDialog.explodeWithoutReplacingRefsBtn', 'Do not replace references')),
                    React.createElement(Button, { onClick: onClose }, t('contentBuilder.explodeComponentDialog.cancelBtn', 'Cancel')))))));
}
