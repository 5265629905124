import React from 'react';
import { Autocomplete, Box, TextField, Typography } from 'lib/cortiUI';
import { ChoiceHintsList } from './ChoiceHintsList';
import { Item } from './Item';
export function DropdownField(props) {
    var _a, _b;
    const { isPreviewMode, value, choices, onChange, onChoiceHintClick } = props;
    const selectedItem = (_a = choices.find((c) => c.value === value)) !== null && _a !== void 0 ? _a : null;
    const handleChange = (_e, option) => {
        if (!option)
            return;
        onChange(option ? option.value : value);
    };
    if (isPreviewMode) {
        return (React.createElement(Item, null,
            React.createElement(Typography, null, selectedItem ? selectedItem.text : '-')));
    }
    const selectedChoiceHasHints = Boolean((_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.detectedTimelineEntries) === null || _b === void 0 ? void 0 : _b.length);
    return (React.createElement(Box, { gap: selectedChoiceHasHints ? 3 : 0 },
        React.createElement(Autocomplete, { "data-cy": "feedback-form-dropdown", size: "small", options: choices, getOptionLabel: (option) => option.text, value: selectedItem, onChange: handleChange, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)), clearIcon: null }),
        selectedChoiceHasHints && (React.createElement(ChoiceHintsList, { items: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.detectedTimelineEntries) || [], onItemClick: onChoiceHintClick }))));
}
