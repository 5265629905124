import { useMemo } from 'react';
import { authStore } from 'core/auth';
import { moduleRegistry } from 'core/moduleRegistry/browser';
const DEFAULT_MODULE_ID = 'home';
function getDefaultModule() {
    var _a;
    const priorityModuleID = (_a = authStore.organization.clientSettings.defaultAppName) !== null && _a !== void 0 ? _a : DEFAULT_MODULE_ID;
    const orgFeatureIDs = authStore.organization.features.map((it) => it.featureID);
    // Try returning prioritized module if available
    const m = moduleRegistry.getModule(priorityModuleID);
    if (m && hasAccess(m, orgFeatureIDs)) {
        return m;
    }
    // Return first available module
    for (let m of moduleRegistry.modules) {
        if (hasAccess(m, orgFeatureIDs)) {
            return m;
        }
    }
}
function hasAccess(module, orgFeatureIDs) {
    return !!module.requiresOrgFeature && orgFeatureIDs.includes(module.requiresOrgFeature);
}
export function useDefaultModule() {
    return useMemo(getDefaultModule, []);
}
