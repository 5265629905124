var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { GraphEditorModel } from './GraphEditorModel';
import { GraphEditorState } from './GraphEditorState';
import { GraphNodeFactory } from './GraphNodeFactory';
import { registerCommands as registerCanvasCommands } from './canvas/commands';
import { registerCommands as registerNodeCommands } from './canvas/nodes/commands';
import { Changelog } from './changelog';
import { registerCommands as registerContentBuilderCommands } from './contentBuilder/actions/commands';
import { DetectionsModule } from './detections';
import { KeyboardBinder } from './keyboard';
import { LogicRefContext } from './logicRefContext';
import { MediaModule } from './media';
import { UndoManager } from './undoManager';
import { ValidationModule } from './validation';
const UNDO_STACK_LIMIT = 50;
const DEFAULT_CHECKLIST_SETTINGS = {
    enabled: false,
};
export class GraphEditor {
    constructor(options = {}) {
        var _a, _b;
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commandRegistry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "undoManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "changelog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nodeFactory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "model", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logicContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "detections", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "media", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphPreviewSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checklistSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "validation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "$canvasElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyboardBinder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.keyboardBinder = new KeyboardBinder();
        this.observer = new Observer();
        this.commandRegistry = new Map();
        this.nodeFactory = new GraphNodeFactory();
        this.undoManager = new UndoManager({ maxStack: (_a = options.undoStackLimit) !== null && _a !== void 0 ? _a : UNDO_STACK_LIMIT });
        this.changelog = new Changelog(this);
        this.logicContext = new LogicRefContext(this);
        this.detections = new DetectionsModule(this);
        this.media = new MediaModule(this, options.mediaProvider);
        this.graphPreviewSettings = options.graphPreviewSettings;
        this.checklistSettings = Object.assign(Object.assign({}, DEFAULT_CHECKLIST_SETTINGS), options.checklistSettings);
        this.validation = new ValidationModule(this);
        this.setModel((_b = options.model) !== null && _b !== void 0 ? _b : new GraphEditorModel());
        this.state = new GraphEditorState(this.model);
        registerCanvasCommands(this);
        registerContentBuilderCommands(this);
        registerNodeCommands(this);
    }
    setModel(model) {
        model.setContext(this);
        this.model = model;
        this.state = new GraphEditorState(this.model);
        this.undoManager.reset();
        this.changelog.reset();
        this.validation.reset();
    }
    /**
     * Dispatch is the primary API to invoke actions in the editor. This should be the preferred
     * way of interacting with the editor state over calling model methods directly.
     *
     * There will be one action handler registered for every action type, i.e. actions will not have
     * more than one handler.
     *
     * The handlers themselves define whether they support the undo/redo functionality or not.
     *
     * Multiple actions can be dispatched at once which will optimise the view to render less times
     * when the state changes.
     */
    dispatch(action) {
        const actions = Array.isArray(action) ? action : [action];
        if (actions.length === 0) {
            return;
        }
        let commandInstances = [];
        actions.forEach((a) => {
            const i = this.createCommandInstance(a);
            if (i) {
                commandInstances.push(i);
            }
            else {
                console.warn('no handlers found for action', action);
            }
        });
        const revertableCommands = commandInstances.filter((cmd) => cmd.revert !== undefined);
        if (revertableCommands.length) {
            this.undoManager.register(revertableCommands);
        }
        this._runCommands(commandInstances);
    }
    _runCommands(cmds) {
        // TODO: do not pass editor ctx to run/revert
        const actionCtx = { editor: this };
        runInAction(() => {
            cmds.forEach((cmd) => {
                cmd.run(actionCtx);
                this.observer.fireEvent('actionHandled', cmd);
            });
        });
    }
    _revertCommands(cmds) {
        const actionCtx = { editor: this };
        runInAction(() => {
            cmds.forEach((cmd) => {
                cmd.revert(actionCtx);
                this.observer.fireEvent('actionReverted', cmd);
            });
        });
    }
    getCanvasBounds() {
        return this.$canvasElement ? this.$canvasElement.getBoundingClientRect() : undefined;
    }
    undo() {
        const actions = this.undoManager.undo();
        if (actions) {
            this._revertCommands(actions);
        }
    }
    redo() {
        const actions = this.undoManager.redo();
        if (actions) {
            this._runCommands(actions);
        }
    }
    /**
     * Register a command descriptor for a particular action type which will be used as a handler
     */
    registerCommandDescriptor(cmd) {
        if (this.commandRegistry.has(cmd.type)) {
            throw new Error('Only one command descriptor can be registered for the same action type');
        }
        this.commandRegistry.set(cmd.type, cmd);
    }
    onActionHandled(cb) {
        return this.observer.on('actionHandled', cb);
    }
    onActionReverted(cb) {
        return this.observer.on('actionReverted', cb);
    }
    createCommandInstance(action) {
        const descriptor = this.commandRegistry.get(action.type);
        if (!descriptor) {
            return;
        }
        const runner = descriptor.createHandler(action.data, { editor: this });
        const instance = Object.assign(Object.assign({ id: uuid(), revertable: runner.revert !== undefined }, action), runner);
        return instance;
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof GraphEditorModel !== "undefined" && GraphEditorModel) === "function" ? _a : Object)
], GraphEditor.prototype, "model", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof GraphEditorState !== "undefined" && GraphEditorState) === "function" ? _b : Object)
], GraphEditor.prototype, "state", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof GraphEditorModel !== "undefined" && GraphEditorModel) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], GraphEditor.prototype, "setModel", null);
