import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, FallbackView, Loading } from 'lib/cortiUI';
import { TimelineToolbar } from './TimelineToolbar';
import { Waveform } from './Waveform';
export const CasePlayer = observer((props) => {
    const MIN_WAVEFORM_HEIGHT = 290;
    const TIMELINE_HEIGHT = 24;
    const MINIMAP_HEIGHT = 64;
    const { caseModel, callPlayerState, className, height = 400 } = props;
    const theme = useTheme();
    const [waveformHeight, setWaveformHeight] = React.useState(null);
    React.useEffect(() => {
        setWaveformHeight(getWaveformHeight(height));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getWaveformHeight = (containerHeight) => {
        const height = containerHeight - TIMELINE_HEIGHT - MINIMAP_HEIGHT;
        return height >= MIN_WAVEFORM_HEIGHT ? height : MIN_WAVEFORM_HEIGHT;
    };
    const { error, isLoading } = callPlayerState;
    if (!waveformHeight) {
        return null;
    }
    const renderContent = () => {
        if (error) {
            return (React.createElement(FallbackView, { title: intl.t('explore:caseView.failedToFetchAudioView.title', 'Could not load audio'), className: css({
                    width: '100%',
                    height: '100%',
                }) }));
        }
        else {
            return (React.createElement(Base, { position: "relative", "data-cy": "case-player-audio-wave" },
                isLoading && (React.createElement(Box, { alignItems: "center", justifyContent: "center", className: css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }) },
                    React.createElement(Loading, { text: intl.t('explore:loadingAudioMessage', 'Loading audio...') }))),
                React.createElement(Base, { id: "timeline-plugin" }),
                React.createElement(Waveform, { caseModel: caseModel, callPlayerState: callPlayerState, height: waveformHeight, timelineHeight: TIMELINE_HEIGHT, minimapHeight: MINIMAP_HEIGHT }),
                React.createElement(Base, { style: { position: 'absolute', bottom: MINIMAP_HEIGHT, width: '100%' } },
                    React.createElement(TimelineToolbar, null)),
                React.createElement(Base, { id: "minimap-plugin", className: css({
                        boxShadow: `inset 0 1px 0 0 ${theme.palette.background.divider}`,
                    }) })));
        }
    };
    return (React.createElement(Base, { className: css({
            position: 'relative',
            height: '100%',
        }, className) }, waveformHeight > 0 && renderContent()));
});
