import { api } from '../../MissionControlService';
export async function getInteractionByCallID(callID) {
    return await api
        .get(`/interactions/by-call-id`, {
        params: { call_id: callID },
    })
        .then((response) => response.data);
}
export async function getInteraction(id) {
    return await api.get(`/interactions/${id}`).then((response) => response.data);
}
export async function acknowledgeInteraction(id) {
    return await api.post(`/interactions/${id}/acknowledge`).then((response) => response.data);
}
export async function requestInteractionReview(id, reason) {
    return await api
        .post(`/interactions/${id}/request-review`, { reason })
        .then((response) => response.data);
}
export async function requestInteractionAcknowledgement(id, reviewType) {
    return await api
        .post(`/interactions/${id}/request-acknowledgement`, { reviewType })
        .then((response) => response.data);
}
export async function withdrawInteractionAcknowledgement(id) {
    return await api
        .post(`/interactions/${id}/withdraw-acknowledgement`)
        .then((response) => response.data);
}
export async function withdrawInteractionReviewRequest(id) {
    return await api
        .post(`/interactions/${id}/withdraw-requested-review`)
        .then((response) => response.data);
}
