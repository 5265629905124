import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { Popover } from 'lib/cortiUI/components/Popover';
import { Separator } from 'lib/cortiUI/components/Separator';
import { Typography } from 'lib/cortiUI/components/Typography';
export const ContextMenuCtx = React.createContext({});
export function ContextMenuProvider(props) {
    const theme = useTheme();
    const [state, setContextMenuState] = React.useState(null);
    const openContextMenu = (e, menu) => {
        e.preventDefault();
        e.stopPropagation();
        setContextMenuState({
            children: menu,
            position: { left: e.clientX, top: e.clientY },
        });
    };
    const closeContextMenu = () => {
        setContextMenuState(null);
    };
    return (React.createElement(ContextMenuCtx.Provider, { value: {
            closeContextMenu,
            openContextMenu,
        } },
        props.children,
        React.createElement(Popover, { transitionDuration: 0, anchorReference: "anchorPosition", open: Boolean(state), anchorPosition: state === null || state === void 0 ? void 0 : state.position, onClose: closeContextMenu, onContextMenu: (e) => {
                e.preventDefault();
            }, PaperProps: {
                classes: {
                    root: css({ border: `1px solid ${theme.palette.background.divider}` }),
                },
            } },
            React.createElement(Base, { "data-cy": "context-menu", py: 3, minWidth: 180 }, state === null || state === void 0 ? void 0 : state.children))));
}
export function ContextMenuItem(props) {
    const theme = useTheme();
    const ctx = useContextMenu();
    return (React.createElement(Base, { py: 3, px: 6, role: "button", "data-cy": "context-menu-item", className: css({
            pointerEvents: props.disabled ? 'none' : 'all',
            cursor: 'default',
            color: props.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
            ':hover': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
        }), onClick: (e) => {
            var _a;
            e.stopPropagation();
            if (!props.disabled) {
                (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
                ctx.closeContextMenu();
            }
        } },
        React.createElement(Typography, { variant: "body2", noSelect: true, color: "inherit" }, props.title)));
}
export function ContextMenuDivider() {
    return React.createElement(Separator, { horizontal: true, my: 3 });
}
export function useContextMenu() {
    return React.useContext(ContextMenuCtx);
}
