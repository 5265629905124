import { observer } from 'mobx-react';
import React from 'react';
import { useMousetrap } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Carousel, FocusContext } from 'lib/cortiUI';
import { BlockValueUtils } from '../../stores/BlockValueStore';
import { NavigatorPanel } from '../NavigatorPanel';
import { Step } from '../Step';
import { useFlow } from './FlowProvider';
const FOCUS_ELEMENT_KEY = 'esc';
const DEFAULT_ANIMATION_DURATION = 0;
const CONTAINER_BREAKPOINTS = {
    XS: 400,
    SM: 660,
    LG: 1300,
};
export const Flow = observer(function Flow({ containerRef, disableWindowing, animationDuration = DEFAULT_ANIMATION_DURATION, }) {
    const container = React.useRef(null);
    const ctx = useFlow();
    React.useEffect(() => {
        const ctxRef = ctx;
        ctxRef.flowViewStore.enableKeyboardShortcuts();
        return () => {
            ctxRef.flowViewStore.disableKeyboardShortcuts();
        };
    }, [ctx]);
    React.useEffect(() => {
        containerRef === null || containerRef === void 0 ? void 0 : containerRef(container.current);
    }, [containerRef, container]);
    React.useEffect(() => {
        var _a;
        ctx.flowViewStore.setKeyboardBindingElement((_a = container.current) !== null && _a !== void 0 ? _a : undefined);
        if (ctx.flowViewStore.keyboardShortcutsEnabled) {
            ctx.flowViewStore.disableKeyboardShortcuts();
            ctx.flowViewStore.enableKeyboardShortcuts();
        }
    }, [ctx, container]);
    function handleFlowAnimationStart() {
        var _a;
        (_a = container.current) === null || _a === void 0 ? void 0 : _a.focus();
        ctx.flowViewStore.setIsFlowAnimating(true);
    }
    function handleFlowAnimationEnd() {
        var _a;
        ctx.flowViewStore.setIsFlowAnimating(false);
        if ((_a = ctx.flowStore.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.id) {
            ctx.flowStore.observer.fireEvent('active-step-transition-ended', {
                event: 'active-step-transition-ended',
                data: {
                    datetime: new Date().toISOString(),
                    stepID: ctx.flowStore.stepStore.activeStep.id,
                },
            });
        }
    }
    function handleContainerResize(props) {
        if (props.width < CONTAINER_BREAKPOINTS.XS) {
            if (ctx.flowViewStore.screenSize !== 'small') {
                ctx.flowViewStore.setScreenSize('small');
            }
        }
        else if (props.width < CONTAINER_BREAKPOINTS.SM) {
            if (ctx.flowViewStore.screenSize !== 'medium') {
                ctx.flowViewStore.setScreenSize('medium');
            }
        }
        else if (props.width < CONTAINER_BREAKPOINTS.LG) {
            if (ctx.flowViewStore.screenSize !== 'large') {
                ctx.flowViewStore.setScreenSize('large');
            }
        }
        else {
            if (ctx.flowViewStore.screenSize !== 'extra-large') {
                ctx.flowViewStore.setScreenSize('extra-large');
            }
        }
    }
    function handleFlowStepChange(stepIndex) {
        if (!ctx.flowViewStore.isFlowAnimating) {
            const step = getStepByIndex(stepIndex);
            if (step) {
                ctx.flowViewStore.navigator.navigateToStep(step);
            }
        }
    }
    function getVisibleStepCount() {
        switch (ctx.flowViewStore.screenSize) {
            case 'small':
            case 'medium': {
                return 1;
            }
            case 'large': {
                return 1.7;
            }
            case 'extra-large': {
                return 2;
            }
        }
    }
    function getActiveStepIndex() {
        const { activeStep } = ctx.flowStore.stepStore;
        return activeStep
            ? ctx.flowViewStore.navigator.renderPath.findIndex((it) => it.id === activeStep.id)
            : 0;
    }
    function getStepByIndex(index) {
        return ctx.flowViewStore.navigator.renderPath[index];
    }
    return (React.createElement(Base, { "data-cy": "flow:main-container", tabIndex: -1, ref: container, height: "100%", width: "100%", display: "grid", gridTemplateRows: "1fr auto", position: "relative" },
        React.createElement(Carousel, { steps: ctx.flowViewStore.navigator.renderPath, visibleSteps: getVisibleStepCount(), activeStepIdx: getActiveStepIndex(), onChange: handleFlowStepChange, disableKeyboardShortcuts: true, onAnimationStart: handleFlowAnimationStart, onAnimationEnd: handleFlowAnimationEnd, onContainerResize: handleContainerResize, disableWindowing: disableWindowing, renderStep: (step) => (React.createElement(CarouselStep, { eventBindingElement: container.current, stepModel: step, animationDuration: animationDuration })), animationDuration: animationDuration })));
});
const CarouselStep = observer(function CarouselStep(props) {
    var _a, _b, _c;
    const { stepModel, eventBindingElement, animationDuration } = props;
    const { flowStore, flowViewStore } = useFlow();
    const isReadonly = stepModel.id !== ((_a = flowStore.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.id);
    const theme = useTheme();
    const focusController = React.useRef(null);
    const focusElement = () => {
        var _a, _b, _c, _d;
        const selectedBlockValues = flowStore.blockValueStore.all.filter(BlockValueUtils.isTruthy);
        const selectedOptionIds = (_b = (_a = flowStore.stepStore.activeStep) === null || _a === void 0 ? void 0 : _a.node.view.blocks.map((block) => {
            var _a;
            const selectedBlockValue = selectedBlockValues.find((value) => value.identifier.blockPrototypeID === block.blockPrototype.id);
            return (_a = selectedBlockValue === null || selectedBlockValue === void 0 ? void 0 : selectedBlockValue.identifier.optionID) !== null && _a !== void 0 ? _a : '';
        }).filter(Boolean)) !== null && _b !== void 0 ? _b : [];
        if (selectedOptionIds.length) {
            (_c = focusController.current) === null || _c === void 0 ? void 0 : _c.focusElementByID(selectedOptionIds[0]);
        }
        else {
            (_d = focusController.current) === null || _d === void 0 ? void 0 : _d.focusFirstElement();
        }
    };
    useMousetrap({
        keys: FOCUS_ELEMENT_KEY,
        handle: focusElement,
    }, []);
    React.useEffect(() => {
        setTimeout(() => {
            focusElement();
        }, animationDuration);
        flowStore.observer.on('active-step-transition-ended', focusElement);
        flowStore.observer.on('toolbar-element-blurred', focusElement);
        return () => {
            flowStore.observer.un('active-step-transition-ended', focusElement);
            flowStore.observer.un('toolbar-element-blurred', focusElement);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(FocusContext, { controllerRef: (ref) => (focusController.current = ref), keyboardEventBindingElement: eventBindingElement, disableKeyboardShortcuts: isReadonly, keyboardShortcuts: {
            triggerFocusedElement: 'enter',
        } },
        React.createElement(Box, { py: [4, 6, 7], height: "100%", width: "100%", position: "relative", justifyContent: "center", className: css({ boxSizing: 'border-box' }) },
            React.createElement(NavigatorPanel, { flowStore: flowStore, screenSize: flowViewStore.screenSize, navigator: flowViewStore.navigator, isActiveStep: !isReadonly },
                React.createElement(Step, { flowStore: flowStore, stepModel: stepModel, isReadonly: isReadonly, className: css({
                        opacity: isReadonly ? 0.3 : 1,
                        cursor: isReadonly ? 'pointer' : undefined,
                        overflow: isReadonly ? 'hidden' : undefined,
                        boxShadow: isReadonly ? theme.shadows[1] : theme.shadows[2],
                        pointerEvents: isReadonly ? 'none' : undefined,
                        zIndex: 0,
                    }), "data-cy": ((_c = (_b = flowStore.stepStore.previewStep) === null || _b === void 0 ? void 0 : _b.model) === null || _c === void 0 ? void 0 : _c.id) === stepModel.id
                        ? 'flow:preview-step'
                        : 'flow:step' })))));
});
