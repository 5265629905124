import ToggleButtonMUI from '@mui/material/ToggleButton';
import React from 'react';
import { css, getFocusCss } from '@corti/style';
import { useTheme } from '@corti/theme';
/**
 * More information: https://mui.com/components/toggle-button/
 */
export function ToggleButton(props) {
    const theme = useTheme();
    return React.createElement(ToggleButtonMUI, Object.assign({}, props, { className: css(getFocusCss({ theme })), disableRipple: true }));
}
