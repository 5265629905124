import { observer } from 'mobx-react';
import React from 'react';
import { coreStore } from './storeLocator';
export const StoreContext = React.createContext(null);
/**
 * Inject "core" stores
 * @description "core" stores can be used globally in the app by any "subapp"
 * it can be viewed as the "reactive" API of our core app services
 */
export function useCoreStores() {
    const ctx = React.useContext(StoreContext);
    if (ctx === null) {
        throw new Error('Wrap the root of the app in StoreContext.Provider');
    }
    return {
        notifications: ctx.notificationStore,
        remoteRequest: ctx.remoteRequestStore,
        appShell: coreStore.appShell,
    };
}
// re-export
export { observer };
