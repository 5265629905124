var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { searchTasks, } from '@corti/mission-control-api';
import { useIntersectionObserver } from '@corti/react';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { getListRequestParams } from './utils';
const ITEMS_LIMIT = 20;
// eslint-disable-next-line import/no-unused-modules
export const usePaginatedTasks = ({ taskType, sortingValue, templateID, }) => {
    const { t } = useTranslation('tasks');
    const { currentUserID } = useAuth();
    const [items, setItems] = React.useState([]);
    const [taskCount, setTaskCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pagination, setPagination] = React.useState();
    React.useEffect(() => {
        //templateID is required for sorting by score
        //without this application crashes when sorting by score option is cached
        if (sortingValue.key === 'score' && !templateID)
            return;
        setItems([]);
        setTaskCount(0);
        setPagination(null);
        void fetchTasksList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskType, sortingValue, templateID]);
    const handleObserver = React.useCallback(async (entries) => {
        const [target] = entries;
        if (target.isIntersecting && !isLoading && pagination) {
            await fetchTasksList(pagination);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, isLoading, taskType, sortingValue]);
    const { observerAnchorRef } = useIntersectionObserver(handleObserver);
    const fetchTasksList = async (paginationObject) => {
        if (paginationObject === null)
            return;
        const params = getListRequestParams(taskType, sortingValue, templateID);
        try {
            setIsLoading(true);
            const _a = await searchTasks(Object.assign(Object.assign({}, params), { size: ITEMS_LIMIT, task_assignees: [currentUserID], latest_only: true, page_field_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.field_value, page_id_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.id_value })), { results } = _a, response = __rest(_a, ["results"]);
            if (paginationObject) {
                setItems((prev) => [...prev, ...results]);
            }
            else {
                setItems(results);
                setTaskCount(response.total_count);
            }
            if (response.pagination.id_value === null || results.length < ITEMS_LIMIT) {
                setPagination(null);
            }
            else {
                setPagination(response.pagination);
            }
        }
        catch (e) {
            console.error('Could not fetch tasks', e);
            coreStore.notifications.showNotification({
                message: t('fetchTasksError', 'Failed to get Tasks'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const removeTask = (taskID) => {
        setItems((prevTasks) => prevTasks.filter((item) => item.task.id !== taskID));
    };
    return {
        items,
        isLoading,
        observerAnchorRef,
        taskCount,
        removeTask,
    };
};
