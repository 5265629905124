import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { api } from '@corti/lib/coreApiService';
import { css } from '@corti/style';
import { coreStore } from 'browser/stores';
import { Base, Button, ConfirmableTarget, Loading, TextField, Typography } from 'lib/cortiUI';
import { ExceptionCard } from '../../CasesView/CallsUploadView/ExceptionCard';
import { CaseMergeItemWidget } from './CaseMergeItemWidget';
import { CaseMergeModel } from './CaseMergeModel';
export function CaseMergeView(props) {
    const parentCaseModel = React.useRef(new CaseMergeModel(props.parentCase)).current;
    const [inputValue, setInputValue] = React.useState('');
    const [childCaseStatusValue, setChildCaseStatusValue] = React.useState(undefined);
    const [isMerging, setIsMerging] = React.useState(false);
    async function loadChildCase() {
        setChildCaseStatusValue({ status: 'loading' });
        try {
            const res = await api.cases.getCaseForMerge(inputValue);
            res
                ? setChildCaseStatusValue({ status: 'fetched', case: new CaseMergeModel(res) })
                : setChildCaseStatusValue({
                    status: 'notExist',
                    label: intl.t('explore:caseView.caseMerge.validationMessages.caseNotExist', 'Case with this ID does not exist'),
                    caseId: inputValue,
                });
        }
        catch (err) {
            throw err;
        }
    }
    function getInputInformationText() {
        if (!!inputValue && inputValue.length !== 36) {
            return intl.t('explore:caseView.caseMerge.validationMessages.caseIdContainLength', 'Case id must contain {{ numOfChars }} characters', {
                numOfChars: 36,
            });
        }
        if ((childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'notExist' && inputValue === childCaseStatusValue.caseId) {
            return childCaseStatusValue.label;
        }
        if ((childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'fetched' &&
            childCaseStatusValue.case.id === (parentCaseModel === null || parentCaseModel === void 0 ? void 0 : parentCaseModel.id) &&
            inputValue === childCaseStatusValue.case.id) {
            return intl.t('explore:caseView.caseMerge.validationMessages.sameCaseId', 'The child case is same as the parent case');
        }
    }
    function isCheckDisabled() {
        return (inputValue.length !== 36 ||
            (childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'loading' ||
            ((childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'notExist' && inputValue === childCaseStatusValue.caseId));
    }
    function isFormValid() {
        return ((childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'fetched' &&
            (parentCaseModel === null || parentCaseModel === void 0 ? void 0 : parentCaseModel.id) !== childCaseStatusValue.case.id);
    }
    function onCaseMergeViewModalClose() {
        setChildCaseStatusValue(undefined);
        setInputValue('');
        props.onClose();
    }
    async function handleMergeCase() {
        if (parentCaseModel && (childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'fetched') {
            setIsMerging(true);
            try {
                await api.cases.mergeCases({
                    parentCaseId: parentCaseModel.id,
                    childCaseId: childCaseStatusValue.case.id,
                });
                coreStore.notifications.showNotification({
                    type: 'success',
                    message: intl.t('explore:caseView.caseMerge.casesMergedMsg', 'Cases merged'),
                });
                setIsMerging(false);
                onCaseMergeViewModalClose();
                props.onMerge();
            }
            catch (err) {
                setIsMerging(false);
                onCaseMergeViewModalClose();
                throw err;
            }
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column" },
        React.createElement(Base, { display: "flex", flexDirection: "column", ml: "auto", mb: 5 },
            React.createElement(Base, { display: "inline-flex", alignItems: "center" },
                React.createElement(TextField, { onChange: (e) => setInputValue(e.target.value), value: inputValue, label: intl.t('explore:caseView.caseId', 'Case ID'), id: "caseId", autoComplete: "off", sx: {
                        marginRight: 2,
                    } }),
                React.createElement(Button, { "data-cy": "caseId-check-btn", onClick: loadChildCase, disabled: isCheckDisabled(), size: "small" },
                    (childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'loading' && React.createElement(Loading, { size: "small", mr: 2 }),
                    intl.t('explore:caseView.caseMerge.check', 'Check'))),
            React.createElement(Typography, { my: 2, mx: 1, color: "error", variant: "body2" }, getInputInformationText())),
        React.createElement(Base, { display: "grid", gridTemplateColumns: "auto 1fr auto", gridGap: 5, my: 5, mx: 7, justifyContent: "center" },
            parentCaseModel && React.createElement(CaseMergeItemWidget, { entity: parentCaseModel, type: "parent" }),
            (childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'fetched' && (React.createElement(ArrowBackRoundedIcon, { fontSize: "medium", className: css({ margin: 'auto' }) })),
            (childCaseStatusValue === null || childCaseStatusValue === void 0 ? void 0 : childCaseStatusValue.status) === 'fetched' && (React.createElement(CaseMergeItemWidget, { entity: childCaseStatusValue.case, type: "child" }))),
        React.createElement(Base, { my: 5 }, isFormValid() && (React.createElement(ExceptionCard, { color: "warning", text: intl.t('explore:caseView.caseMerge.warningCardText', 'In case of conflicting data, the child case data will be overwritten. This cannot be undone.') }))),
        React.createElement(Base, { my: 5, ml: "auto" },
            React.createElement(Button, { "data-cy": "merge-case-cancel-btn", variant: "text", className: css({ marginRight: 2, width: 140 }), onClick: onCaseMergeViewModalClose }, intl.t('cancel', 'Cancel')),
            React.createElement(ConfirmableTarget, { message: intl.t('explore:caseView.caseMerge.mergeConfirmationText', 'Are you sure you want to merge these cases?'), onConfirm: handleMergeCase, color: "success" }, ({ onClick }) => (React.createElement(Button, { className: css({ width: 140 }), "data-cy": "merge-case-submit-btn", disabled: !isFormValid() || isMerging, color: "primary", onClick: onClick },
                isMerging && React.createElement(Loading, { size: "small", mr: 2 }),
                intl.t('explore:caseView.caseMerge.merge', 'Merge')))))));
}
