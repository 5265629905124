var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { ElementSerializer, } from 'lib/graphEditor/ElementSerializer';
import { ContentBuilderContext } from 'lib/graphEditor/contentBuilder/ContentBuilderContext';
import { BaseGraphEditorNode } from '../common/BaseGraphEditorNode';
import { LogicGateModel } from './LogicGateModel';
export class ViewNodeModel extends BaseGraphEditorNode {
    constructor() {
        super({ type: 'viewnode', hasInputPort: true });
        Object.defineProperty(this, "contentBuilderContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logicGates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pinnedInTriage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "favoriteNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setPinnedInTriage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (val) => {
                this.pinnedInTriage = val;
            }
        });
        Object.defineProperty(this, "setFavoriteNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.favoriteNode = value;
            }
        });
        makeObservable(this);
        this.setContentBuilderContext(new ContentBuilderContext());
        runInAction(() => {
            this.logicGates = [];
            this.pinnedInTriage = false;
            this.favoriteNode = false;
        });
    }
    setContentBuilderContext(contentBuilderContext) {
        contentBuilderContext.setParent(this);
        this.contentBuilderContext = contentBuilderContext;
    }
    setLogicGates(gates) {
        gates.forEach((g) => this.addLogicGate(g));
    }
    addNewLogicGate(input = {}) {
        const l = new LogicGateModel({ parent: this, expression: input.expression });
        this.addLogicGate(l);
        return l;
    }
    addLogicGate(gate, position = this.logicGates.length) {
        if (gate.parent) {
            gate.parent.removeLogicGate(gate);
        }
        gate.setParent(this);
        const gatesCopy = [...this.logicGates];
        gatesCopy.splice(position, 0, gate);
        this.logicGates = gatesCopy;
    }
    removeLogicGate(gate) {
        this.logicGates = this.logicGates.filter((l) => l !== gate);
    }
    changeLogicGatePosition(gate, pos) {
        const existingPos = this.logicGates.indexOf(gate);
        if (existingPos === -1) {
            return;
        }
        const gatesCopy = [...this.logicGates];
        gatesCopy.splice(existingPos, 1);
        gatesCopy.splice(pos, 0, gate);
        this.logicGates = gatesCopy;
    }
    get portIn() {
        return super.portIn;
    }
    get uniqueValuePublishDestinationCollectorPrototypeIDs() {
        const uniqueCollectors = new Set();
        [...this.contentBuilderContext.valuePublisherItems.values()].forEach((item) => {
            item.valuePublisher.collectors.forEach((c) => {
                uniqueCollectors.add(c.prototypeID);
            });
        });
        return uniqueCollectors;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { contentBuilderContext: this.contentBuilderContext, contentBuilderContextID: this.contentBuilderContext.id, logicGates: this.logicGates.map((l) => l.getEncodableData()), pinnedInTriage: this.pinnedInTriage, favoriteNode: this.favoriteNode });
    }
    deserialize(data, ctx) {
        super.deserialize(data, ctx);
        this.pinnedInTriage = data.pinnedInTriage;
        this.favoriteNode = data.favoriteNode;
        data.logicGates.forEach((l) => {
            const logicGate = new LogicGateModel({ parent: this });
            logicGate.deserialize(l);
            this.logicGates.push(logicGate);
            const port = this.ports.find((p) => p.id === l.portID);
            if (!port) {
                return;
            }
            logicGate.setPort(port);
        });
        ctx.getElement(data.contentBuilderContextID).then((el) => {
            this.contentBuilderContext = el;
        });
    }
    copy() {
        const i = super.copy();
        i.pinnedInTriage = this.pinnedInTriage;
        i.favoriteNode = this.favoriteNode;
        i.setContentBuilderContext(this.contentBuilderContext.copy());
        i.setLogicGates(this.logicGates.map((l) => l.copy()));
        return i;
    }
    get readableTitle() {
        if (this.name.trim() !== '') {
            return this.name;
        }
        if (this.contentSummary.trim() !== '') {
            return this.contentSummary;
        }
        return `View Node`;
    }
    get contentSummary() {
        let title = '';
        for (const element of this.contentBuilderContext.elements) {
            if (element.readableTitle.trim() !== '') {
                title = element.readableTitle.trim();
                break;
            }
        }
        return title;
    }
    toString() {
        return this.id;
    }
}
Object.defineProperty(ViewNodeModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val, ctx) => {
        const i = new ViewNodeModel();
        i.deserialize(val, ctx);
        return i;
    }
});
__decorate([
    observable,
    __metadata("design:type", Array)
], ViewNodeModel.prototype, "logicGates", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], ViewNodeModel.prototype, "pinnedInTriage", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], ViewNodeModel.prototype, "favoriteNode", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "setLogicGates", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_a = typeof LogicGateModel !== "undefined" && LogicGateModel) === "function" ? _a : Object)
], ViewNodeModel.prototype, "addNewLogicGate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof LogicGateModel !== "undefined" && LogicGateModel) === "function" ? _b : Object, Number]),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "addLogicGate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof LogicGateModel !== "undefined" && LogicGateModel) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "removeLogicGate", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof LogicGateModel !== "undefined" && LogicGateModel) === "function" ? _d : Object, Number]),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "changeLogicGatePosition", null);
__decorate([
    action,
    __metadata("design:type", Object)
], ViewNodeModel.prototype, "setPinnedInTriage", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], ViewNodeModel.prototype, "setFavoriteNode", void 0);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _e : Object, Object]),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ViewNodeModel.prototype, "copy", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ViewNodeModel.prototype, "readableTitle", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ViewNodeModel.prototype, "contentSummary", null);
ElementSerializer.register(ViewNodeModel, 'ViewNodeModel');
