import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Dialog, MenuItem } from 'lib/cortiUI';
import { UpdateTeamView } from './CreateUpdateTeamView';
export const EditTeam = ({ id, onClose, onSubmit }) => {
    const { t } = useTranslation('teams');
    const [isOpen, setIsOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { "data-cy": "edit-team-btn", onClick: (e) => {
                e.stopPropagation();
                setIsOpen(true);
            } }, t('teamsView.table.row.edit', 'Edit')),
        React.createElement(Dialog, { title: t('updateTeamView.title', 'Edit Team'), open: isOpen, onClose: (e) => {
                // prevents event from bubbling up outside from the Portal to the Row
                e.stopPropagation();
                setIsOpen(false);
                onClose();
            } },
            React.createElement(UpdateTeamView, { teamID: id, onSuccess: () => {
                    setIsOpen(false);
                    onSubmit();
                    onClose();
                } }))));
};
