var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { CoreApiError } from '@corti/lib/coreApiService/errors';
import { isValidEmail } from '@corti/strings';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { coreStore, observer } from 'browser/stores';
import { authStore } from 'core/auth';
import { repositories, } from 'core/repositories';
import { Autocomplete, Box, Button, IconButton, InputAdornment, MenuItem, TextField, } from 'lib/cortiUI';
import { passwordFeedbackText, passwordRequirementsText } from 'lib/passwordRequirementsTexter';
// @ts-ignore
let AddUpdateUserForm = class AddUpdateUserForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                name: this.props.user ? this.props.user.name : '',
                email: this.props.user ? this.props.user.email : '',
                extension: this.props.user ? this.props.user.extension || '' : '',
                password: '',
                externalID: this.props.user ? this.props.user.externalID || '' : '',
                isPasswordVisible: false,
                roleId: this.props.user ? this.props.user.role.id : '',
                isLoading: false,
                passwordValidationFeedback: '',
            }
        });
        Object.defineProperty(this, "isEditing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => Boolean(this.props.user)
        });
        Object.defineProperty(this, "handleInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { name, value } = e.target;
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), { [name]: value })));
                if (name === 'password') {
                    this.setState({ passwordValidationFeedback: '' });
                }
            }
        });
        Object.defineProperty(this, "isFormValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.isEditing()) {
                    return this.state.email && this.state.name && isValidEmail(this.state.email);
                }
                else {
                    return (this.state.email &&
                        this.state.name &&
                        this.state.password &&
                        this.state.roleId &&
                        isValidEmail(this.state.email));
                }
            }
        });
        Object.defineProperty(this, "setPasswordValidationFeedback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ passwordValidationFeedback: text });
            }
        });
        Object.defineProperty(this, "handleFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (e) => {
                e.preventDefault();
                const { name, password, email, roleId, extension, externalID } = this.state;
                const userInput = {
                    organizationID: authStore.organization.id,
                    name,
                    password,
                    email,
                    roleID: roleId,
                    extension: extension || undefined,
                    externalID: externalID || undefined,
                };
                const userUpdateInput = {
                    name,
                    email,
                    extension: extension || undefined,
                    externalID: externalID || undefined,
                };
                let res;
                this.setState({ isLoading: true });
                if (this.isEditing()) {
                    res = await repositories.users.updateUser(this.props.user.id, userUpdateInput);
                }
                else {
                    res = await repositories.users.createUser(userInput);
                }
                this.setState({ isLoading: false });
                if (!res.error) {
                    this.props.onSubmit();
                    coreStore.notifications.showNotification({
                        type: 'success',
                        message: intl.t('addUpdateUserForm.submitSuccess', 'Successfully saved'),
                    });
                    return;
                }
                else if (res.error.message) {
                    coreStore.notifications.showNotification({
                        type: 'error',
                        message: intl.t('addUpdateUserForm.submitError', 'Failed to save due to {{ error }}', {
                            error: res.error.message,
                        }),
                    });
                }
                if (CoreApiError.isCoreApiDomainError(res.error)) {
                    this.setPasswordValidationFeedback(passwordFeedbackText(res.error.domainErrors.map((e) => {
                        return e.code;
                    })));
                    coreStore.notifications.showNotification({
                        type: 'error',
                        message: intl.t('addUpdateUserForm.passwordSubmitError', 'Password does not meet requirements'),
                    });
                }
            }
        });
        Object.defineProperty(this, "togglePasswordVisibility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({ isPasswordVisible: !prevState.isPasswordVisible }));
            }
        });
    }
    componentDidMount() {
        repositories.roles.loadAllRoles();
    }
    convertToSelectorItems(roles) {
        return roles.map((role) => ({ value: role.id, text: role.name }));
    }
    render() {
        const { name, password, email, extension, externalID, isPasswordVisible, passwordValidationFeedback, } = this.state;
        const allRolesItems = this.convertToSelectorItems(repositories.roles.roles);
        return (React.createElement("form", { "data-cy": "add-update-user-form", onSubmit: this.handleFormSubmit, className: css({
                display: 'flex',
                flexDirection: 'column',
                gap: this.props.theme.space[6],
            }) },
            React.createElement(TextField, { label: intl.t('team:addUpdateUserForm.inputLabel1', 'Name'), name: "name", value: name, onChange: this.handleInputChange }),
            React.createElement(TextField, { label: intl.t('team:addUpdateUserForm.inputLabel2', 'Email'), name: "email", autoComplete: "off", type: "email", value: email, onChange: this.handleInputChange }),
            React.createElement(TextField, { label: intl.t('team:addUpdateUserForm.inputLabel3', 'Extension (Optional)'), name: "extension", value: extension, onChange: this.handleInputChange }),
            React.createElement(TextField, { label: intl.t('team:addUpdateUserForm.inputLabel4', 'External ID (Optional)'), name: "externalID", value: externalID, onChange: this.handleInputChange }),
            !this.isEditing() && (React.createElement(React.Fragment, null,
                React.createElement(TextField, { fullWidth: true, label: intl.t('team:addUpdateUserForm.inputLabel5', 'Password'), error: passwordValidationFeedback != '', helperText: passwordValidationFeedback != ''
                        ? passwordValidationFeedback
                        : passwordRequirementsText(), name: "password", autoComplete: "off", type: isPasswordVisible ? 'text' : 'password', value: password, onChange: this.handleInputChange, InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { onClick: this.togglePasswordVisibility, icon: isPasswordVisible ? (React.createElement(VisibilityRoundedIcon, { fontSize: "small" })) : (React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" })), size: "small" }))),
                    } }),
                React.createElement(Box, null,
                    React.createElement(Autocomplete, { size: "small", "data-cy": "add-user-role", options: allRolesItems, getOptionLabel: (option) => option.text, onChange: (_, role) => {
                            role && this.setState({ roleId: role.value.toString() });
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('team:role', 'Role') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({ "data-cy": "select-item" }, props, { selected: state.selected, key: option.value }), option.text)) })))),
            React.createElement(Box, null,
                React.createElement(Button, { className: css({ alignSelf: 'flex-end' }), color: "primary", disabled: this.state.isLoading || !this.isFormValid(), type: "submit", variant: "text", "data-cy": "add-update-user-submit" }, intl.t('team:save', 'Save')))));
    }
};
AddUpdateUserForm = __decorate([
    withTheme,
    observer
], AddUpdateUserForm);
export { AddUpdateUserForm };
