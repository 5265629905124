import FormData from 'form-data';
import { initRestClient } from './RestClient';
import { SCREEN_RECORDER_ERROR_NAME, ScreenRecorderError } from './ScreenRecorderError';
export class ScreenRecorderService {
    constructor(config) {
        Object.defineProperty(this, "restClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "host", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.restClient = initRestClient({
            host: config.host,
            authToken: config.authToken,
        });
        this.host = config.host;
    }
    static isServiceError(err) {
        return err instanceof ScreenRecorderError && err.name === SCREEN_RECORDER_ERROR_NAME;
    }
    objectToQueryString(queryParamsObj) {
        return `?${Object.keys(queryParamsObj)
            .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryParamsObj[k]))
            .join('&')}`;
    }
    async uploadVideo(file, metadata) {
        const url = '/screen-recordings';
        const formData = new FormData();
        formData.append('video', file);
        formData.append('metadata', JSON.stringify(metadata));
        await this.restClient.post(url, formData, {
            headers: Object.assign({}, formData.getHeaders()),
        });
    }
    async getRecordings(queryParamsObj) {
        const url = '/screen-recordings';
        const res = await this.restClient.get(queryParamsObj ? `${url}${this.objectToQueryString(queryParamsObj)}` : url);
        return res.data.data.map((it) => ({
            id: it.id,
            userID: it.user_id,
            caseID: it.case_id,
            organizationID: it.organization_id,
            callID: it.call_id,
            monitorNo: it.monitor_no,
            triageSessionID: it.triage_session_id,
            fileUrl: it.file_url,
            startedAt: it.started_at,
            endedAt: it.ended_at,
            createdAt: it.created_at,
            updatedAt: it.updated_at,
        }));
    }
}
