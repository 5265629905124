export function resolveReadOnlyChecklists({ allChecklists, currentChecklistIds, snapshot, autoCheckedEntryIds, }) {
    let isFirst = true;
    return allChecklists
        .filter((checklist) => currentChecklistIds.includes(checklist.id))
        .map((checklist) => {
        var _a;
        const { id, name, description } = checklist;
        const entries = checklist.entries.map((entry) => {
            var _a;
            const { id, label, details, manualCompletionEnabled: manual, automaticCompletionEnabled: automatic, } = entry;
            const automaticallyChecked = autoCheckedEntryIds.includes(entry.id);
            const manuallyChecked = (_a = snapshot.manualChecksById[entry.id]) !== null && _a !== void 0 ? _a : false;
            return { id, label, details, automaticallyChecked, manuallyChecked, manual, automatic };
        });
        const totalItems = entries.length;
        const completedItems = entries.filter((entry) => entry.automaticallyChecked || entry.manuallyChecked).length;
        const mode = getMode(totalItems, completedItems, isFirst);
        const isExpanded = (_a = snapshot.expandedChecklistsById[checklist.id]) !== null && _a !== void 0 ? _a : false;
        isFirst = isFirst && mode === 'completed';
        return { id, name, description, isExpanded, totalItems, completedItems, mode, entries };
    });
}
function getMode(totalItems, completedItems, isFirst) {
    if (completedItems === totalItems) {
        return 'completed';
    }
    if (isFirst || completedItems > 0) {
        return 'active';
    }
    return 'idle';
}
