import { orderBy } from 'lodash';
import React from 'react';
import { isObjectType } from './utils';
export function TableSortController(props) {
    const { rows = [], disableSort = false, children } = props;
    const [sortDirection, setSortDirection] = React.useState('ASC');
    const [sortBy, setSortBy] = React.useState('');
    const sort = (props) => {
        setSortBy(props.sortBy);
        setSortDirection(props.sortDirection);
    };
    const getSortedRows = () => {
        return orderBy(rows, (row) => {
            let value = row[sortBy];
            if (Array.isArray(value)) {
                value = value[0];
            }
            if (isObjectType(value)) {
                value = value.value;
            }
            if (typeof value === 'string') {
                value = value.toLowerCase();
            }
            return value;
        }, parseSortDirection(sortDirection));
    };
    const parseSortDirection = (direction) => {
        return direction === 'ASC' ? 'asc' : 'desc';
    };
    const getSortingProps = () => {
        if (!disableSort) {
            return {
                sort: sort,
                sortBy: sortBy,
                sortDirection: sortDirection,
            };
        }
    };
    return (React.createElement(React.Fragment, null, children({
        rows: getSortedRows(),
        getSortingProps: getSortingProps,
    })));
}
