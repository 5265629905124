import { useMemo } from 'react';
import { useGraphEditorCtx } from '../core/view';
export function useChecklistStorage() {
    const { editor: { model: { checklists: checklistLibrary }, }, } = useGraphEditorCtx();
    const { createChecklist, updateChecklist, removeChecklists, importChecklists } = useMemo(() => ({
        createChecklist: checklistLibrary.addChecklist.bind(checklistLibrary),
        updateChecklist: checklistLibrary.updateChecklist.bind(checklistLibrary),
        removeChecklists: checklistLibrary.removeChecklists.bind(checklistLibrary),
        importChecklists: checklistLibrary.addChecklists.bind(checklistLibrary),
    }), [checklistLibrary]);
    return { createChecklist, updateChecklist, removeChecklists, importChecklists };
}
