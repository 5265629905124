import { capitalize } from 'lodash';
export function convertData(input) {
    const splittedInput = splitServerInput(input);
    const groupedPhrases = groupPhrasesByMillisecondsDifference(splittedInput);
    const phrasesWithSeconds = groupedPhrases.map((it) => ({
        time: Math.floor(it.time / 1000),
        text: it.text,
        speaker: it.speaker,
    }));
    return phrasesWithSeconds;
}
function splitServerInput(input) {
    const startTimes = input.start.split(/\s+/);
    const endTimes = input.stop.split(/\s+/);
    const texts = input.text.split(/(?<=\S)\s+(?=\S)/);
    const speaker = input.party.split(/\s+/);
    //Log for easier debugging in production environment
    if (startTimes.length !== texts.length) {
        console.error('Error - different lengths of parsed data.', 'startTimes - ', startTimes.length, 'texts - ', texts.length);
    }
    // if all values of speaker are the same, set it to 'Unknown'
    const unknownSpeaker = speaker.every((val) => val === speaker[0]);
    return startTimes.map((time, index) => ({
        time: parseMillisecondsFromTimeString(time),
        endTime: parseMillisecondsFromTimeString(endTimes[index]),
        text: texts[index],
        speaker: unknownSpeaker ? 'UNKNOWN' : speaker[index],
    }));
}
function groupPhrasesByMillisecondsDifference(words, diff = 350) {
    const phrases = [];
    let currentPhrase = null;
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const prevWord = words[i - 1];
        // Correct endTime if it's less than startTime
        if (word.endTime < word.time) {
            console.warn(`End time is less than start time for: ${JSON.stringify(word)}`);
            word.endTime = word.time; // Fix endTime to be at least startTime
        }
        if (i === 0 || word.time - prevWord.endTime > diff || word.speaker !== prevWord.speaker) {
            if (currentPhrase !== null) {
                phrases.push(currentPhrase);
            }
            currentPhrase = Object.assign(Object.assign({}, word), { text: capitalize(word.text) });
        }
        else {
            if (currentPhrase !== null) {
                currentPhrase.text += ' ' + word.text;
            }
        }
    }
    if (currentPhrase !== null) {
        phrases.push(currentPhrase);
    }
    // sort phrases by start time
    return [...phrases].sort((a, b) => a.time - b.time);
}
export function parseMillisecondsFromTimeString(timeString) {
    const [hoursStr, minutesStr, secondsStrWithMillis] = timeString.split(':');
    const [secondsStr, millisStr = '0'] = secondsStrWithMillis.split('.');
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);
    const seconds = Number(secondsStr);
    const milliseconds = Number(millisStr.slice(0, 3)); // Only take the first 3 digits of the milliseconds
    return hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000 + milliseconds;
}
