import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useHistoryBlock } from '@corti/react';
import { Base, Button, Modal, ModalContent, ModalHeader, Separator, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../activeView/context/ParametersContext';
import { useGlobalContext } from '../../context';
export const NavigationBlockerModal = () => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'navigationWarningModal' });
    const { isChanged, updatePerformanceView, resetView } = useParametersContext();
    const { isEditMode } = useGlobalContext();
    const { isBlocked, proceed: proceedNavigation, cancel: cancelNavigation, } = useHistoryBlock(() => {
        return isEditMode && isChanged;
    }, [isEditMode, isChanged]);
    const handleSaveAndProceed = () => {
        updatePerformanceView();
        proceedNavigation();
    };
    const handleResetAndProceed = () => {
        resetView();
        proceedNavigation();
    };
    return (React.createElement(Modal, { open: isBlocked, size: "small", onClose: cancelNavigation },
        React.createElement(Base, null,
            React.createElement(ModalHeader, { title: t('title', 'Leave without saving edits?') }),
            React.createElement(ModalContent, { display: "flex", flexDirection: "column", gap: 5 },
                React.createElement(Typography, null, t('description', 'This View has unsaved edits. Continuing without saving your edits, will reset this View to its last saved state.')),
                React.createElement(Separator, { horizontal: true }),
                React.createElement(Base, { size: "small", display: "flex", alignItems: "center", justifyContent: "right", gap: 3 },
                    React.createElement(Button, { variant: "text", onClick: handleResetAndProceed }, t('leaveWithoutSavingBtn', 'Leave without saving')),
                    React.createElement(Button, { variant: "contained", color: "primary", size: "small", onClick: handleSaveAndProceed }, t('saveAndLeaveBtn', 'Save and leave')))))));
};
