var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ObservableMap, action, makeObservable } from 'mobx';
import { FactStatic } from './fact';
export class FactLibrary {
    constructor() {
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.items = new ObservableMap();
    }
    validateFactAdd(input) {
        const maybefact = FactStatic.createFact(input);
        if (this.getFactByID(maybefact.id)) {
            return { code: 'fact-with-id-already-exists' };
        }
        if (!FactStatic.isValidID(maybefact.id)) {
            return { code: 'invalid-id' };
        }
    }
    addNewFact(input) {
        return this.addFact(FactStatic.createFact(input), { cloneDuplicate: true });
    }
    addFact(fact, options = {}) {
        if (this.getFactByID(fact.id) && options.cloneDuplicate) {
            const dup = this.createDuplicate(fact);
            return this._addFact(dup);
        }
        return this._addFact(fact);
    }
    _addFact(fact) {
        const validationErr = this.validateFactAdd(fact);
        if (validationErr) {
            return { err: validationErr };
        }
        this.items.set(fact.id, fact);
        return { data: fact };
    }
    getFactByID(factID) {
        return this.items.get(factID);
    }
    createDuplicate(fact) {
        const d = FactStatic.copy(fact);
        d.id = this.createUniqueID(fact.id);
        return d;
    }
    removeFact(...factIDs) {
        factIDs.forEach((id) => {
            this.items.delete(id);
        });
    }
    createUniqueID(existingID) {
        let newid = existingID;
        while (this.getFactByID(newid)) {
            newid = `${newid}-copy`;
        }
        return newid;
    }
}
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", Object)
], FactLibrary.prototype, "addFact", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], FactLibrary.prototype, "_addFact", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FactLibrary.prototype, "removeFact", null);
