import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { Box, Button, TextField } from 'lib/cortiUI';
import { EntityFactory } from '../../utils';
import { useTriageSession } from '../TriageSessionContext';
export const QuickComment = observer(function QuickComment({ onSubmit, onCancel, }) {
    const ctx = useTriageSession();
    const [value, setValue] = React.useState('');
    function createComment() {
        if (!canSubmit()) {
            return;
        }
        ctx.controller.comments.create(EntityFactory.createComment(value, ctx.controller.currentUser));
        setValue('');
        onSubmit();
    }
    function handleCommentChange(e) {
        setValue(e.target.value);
    }
    function canSubmit() {
        return value !== '';
    }
    function handleInputKeyDown(e) {
        if (e.key === 'Enter' && !e.shiftKey && canSubmit()) {
            e.preventDefault();
            createComment();
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { autoFocus: true, fullWidth: true, multiline: true, onKeyDown: handleInputKeyDown, maxRows: 10, label: intl.t('realtime:quickCommentView.inputPlaceholder', 'Add comment'), value: value, onChange: handleCommentChange }),
        React.createElement(Box, { flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 4 },
            React.createElement(Button, { size: "small", variant: "text", color: "primary", onClick: onCancel }, intl.t('realtime:cancel', 'Cancel')),
            React.createElement(Button, { disabled: !canSubmit(), size: "small", variant: "text", color: "primary", onClick: createComment }, intl.t('realtime:add', 'Add')))));
});
