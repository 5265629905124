import { gql } from '@apollo/client';
export const getCallsQuery = gql `
  query Calls($id: [String!]!) {
    calls(id: $id) {
      id
      callServiceCallId
      protocolName
      case {
        id
      }
      user {
        name
      }
    }
  }
`;
