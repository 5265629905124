import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, IconButton, Tooltip } from 'lib/cortiUI';
import { useScreenRecordingContext, useSettingsContext } from './context';
/**
 * Use with caution. It's Electron only functionality
 */
export const ScreenRecorderTriggerButton = () => {
    const { t } = useTranslation('screenRecorder');
    const { isRecording, toggleRecording } = useScreenRecordingContext();
    const { availableCaptureSources } = useSettingsContext();
    return (React.createElement(Base, { display: "flex", justifyContent: "flex-end" },
        React.createElement(Tooltip, { title: isRecording
                ? t('screenRecordingBtnStop', 'Stop screen recording')
                : t('screenRecordingBtnStart', 'Start screen recording') },
            React.createElement(IconButton, { size: "small", color: isRecording ? 'error' : 'primary', disabled: !availableCaptureSources.some((s) => s.selected), onClick: toggleRecording, icon: React.createElement(RadioButtonCheckedIcon, null) }))));
};
