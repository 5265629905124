import React from 'react';
import { createReview } from '@corti/mission-control-api';
import { useInteraction } from '../../../context';
const initialState = {
    reviewID: undefined,
    isReviewLoading: false,
    templateID: undefined,
    setTemplateID: () => { },
    createNewReview: async () => '',
    removeCurrentReview: () => '',
};
const ReviewContext = React.createContext(initialState);
export const ReviewProvider = ({ children }) => {
    const { interaction } = useInteraction();
    const [reviewID, setReviewID] = React.useState();
    const [isReviewLoading, setIsReviewLoading] = React.useState(false);
    const [templateID, setTemplateID] = React.useState();
    async function createNewReview() {
        if (!interaction)
            return '';
        setIsReviewLoading(true);
        const { id } = await createReview({
            call_reference: {
                call_started_at: interaction.call_started_at,
                call_ended_at: interaction.call_ended_at,
                user_ids: interaction.call_user_ids,
            },
            template_id: templateID !== null && templateID !== void 0 ? templateID : '',
            reference_type: 'CALL',
            reference_id: interaction.call_id,
            acknowledgement_status: interaction.acknowledgement_status,
        });
        setReviewID(id);
        setIsReviewLoading(false);
        return id;
    }
    const value = React.useMemo(() => ({
        reviewID,
        isReviewLoading,
        templateID,
        setTemplateID,
        createNewReview,
        removeCurrentReview: () => setReviewID(undefined),
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewID, isReviewLoading, templateID]);
    return React.createElement(ReviewContext.Provider, { value: value }, children);
};
export const useReview = () => {
    const context = React.useContext(ReviewContext);
    if (!context) {
        throw new Error('useReview must be used within a ReviewProvider');
    }
    return context;
};
