import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { useGraphs } from './useGraphs';
export function useGraphSelectorOptions() {
    const auth = useAuth();
    const graphs = useGraphs();
    const { t } = useTranslation('realtimeApp');
    const { activeProtocolGraphVersionID } = auth.organization.triageSettings;
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        var _a;
        const SUFFIX_ACTIVE = t('landingScreen.graphItem.suffixActive', '(Active)');
        const SUFFIX_DRAFT = t('landingScreen.graphItem.suffixDraft', '(Draft)');
        const activeGraph = graphs.find((g) => g.versions.find((v) => v.id === activeProtocolGraphVersionID));
        const options = (_a = graphs
            .map((g) => ({
            value: g.id,
            text: (activeGraph === null || activeGraph === void 0 ? void 0 : activeGraph.id) === g.id ? `${g.name} ${SUFFIX_ACTIVE}` : g.name,
            versions: g.versions.map((v) => ({
                value: v.id,
                text: activeProtocolGraphVersionID === v.id
                    ? `${v.name} ${SUFFIX_ACTIVE}`
                    : !v.release
                        ? `${v.name} ${SUFFIX_DRAFT}`
                        : v.name,
            })),
        }))
            .sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true }))) !== null && _a !== void 0 ? _a : [];
        setOptions(options);
    }, [graphs, activeProtocolGraphVersionID, t]);
    return options;
}
