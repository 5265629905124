import { FlowValueCollectorElementModel, SelectElementModel } from '../contentBuilder/elements';
import { isValuePublisher } from '../valuePublisher/valuePublisher';
export function migrateFlowValueCollectorConfigs(editor) {
    console.log('\n');
    console.log('=================== migrating ======================');
    console.log('\n');
    const errors = [];
    // prep fvcs
    const fvcByName = new Map();
    editor.model.elementContext
        .getPrototypes({
        type: FlowValueCollectorElementModel.typename,
    })
        .forEach((fvc) => {
        if (fvc.name.trim() !== '') {
            fvcByName.set(fvc.name, fvc);
        }
    });
    function populateValuePublisher(extracted, valuePublisher, parentID, customProperties) {
        var _a, _b;
        let noFailures = true;
        // a case when Option blocks have only the alt text prop but the Select block has collector names
        if (((_a = valuePublisher.context) === null || _a === void 0 ? void 0 : _a.collectors.length) && ((_b = extracted.altText) === null || _b === void 0 ? void 0 : _b.trim())) {
            const parentCollector = valuePublisher.context.collectors[0];
            valuePublisher.pushNewCollectorConfig({
                prototypeID: parentCollector.prototypeID,
                customValueFormat: extracted.altText.trim(),
            });
        }
        extracted.prototypeNames.forEach((p) => {
            var _a;
            const normalisedName = p.trim();
            const resolvedFvc = fvcByName.get(normalisedName);
            if (!resolvedFvc) {
                errors.push(`Warning: cannot find fvc with name: "${normalisedName}". ID: ${parentID}`);
                noFailures = false;
            }
            valuePublisher.pushNewCollectorConfig({
                prototypeID: (_a = resolvedFvc === null || resolvedFvc === void 0 ? void 0 : resolvedFvc.id) !== null && _a !== void 0 ? _a : normalisedName,
                customValueFormat: extracted.altText,
            });
        });
        if (noFailures) {
            customProperties
                .getPropertiesByKey('collectorNames')
                .forEach((p) => customProperties.removeProperty(p.id));
            customProperties
                .getPropertiesByKey('altText')
                .forEach((p) => customProperties.removeProperty(p.id));
        }
    }
    editor.model.elementContext.instances.forEach((instance) => {
        const extracted = extractFromCustomProperties(instance.customProperties);
        if (extracted.prototypeNames.length !== 0) {
            errors.push('Warning: found fvc config on instance level but this is not supported now: ', instance.id);
        }
    });
    editor.model.elementContext.getPrototypes().forEach((proto) => {
        if (isValuePublisher(proto)) {
            const extracted = extractFromCustomProperties(proto.customProperties);
            if (extracted.prototypeNames.length !== 0) {
                populateValuePublisher(extracted, proto.valuePublisher, proto.id, proto.customProperties);
            }
        }
        if (proto instanceof SelectElementModel) {
            proto.options.forEach((opt) => {
                const extracted = extractFromCustomProperties(opt.customProperties);
                if (extracted.prototypeNames.length !== 0 || extracted.altText) {
                    populateValuePublisher(extracted, opt.valuePublisher, opt.id, opt.customProperties);
                }
            });
        }
    });
    // migrate fact collecting configs
    editor.model.elementContext
        .getPrototypes({ type: FlowValueCollectorElementModel.typename })
        .forEach((proto) => {
        const factProps = proto.customProperties.getPropertiesByKey('factsToCollect');
        if (factProps.length === 0) {
            return;
        }
        // combine fact ids from multiple props
        const factIDs = factProps
            .map((p) => p.value.trim())
            .join(',') // join chunks like: "col1, col2" + "col3, col4"
            .split(',') // convert to: [col1, col2, col3, col4]
            .map((f) => f.trim());
        factIDs.forEach((factid) => {
            const fact = editor.model.facts.getFactByID(factid);
            if (!fact) {
                errors.push(`Fact not found for fact id: ${factid} defined in FVC ${proto.id}`);
                return;
            }
            fact.valuePublisherConfig.pushNewCollectorConfig({ prototypeID: proto.id });
        });
        factProps.forEach((p) => proto.customProperties.removeProperty(p.id));
    });
    if (errors.length) {
        console.log('\n\nThese errors where found when migrating\n');
        console.log(errors.join('\n'));
        console.log('\n\n');
    }
    console.log('\n');
    console.log('============== migration finished ==================');
    console.log('\n');
}
function extractFromCustomProperties(cp) {
    var _a;
    let namesCP = cp.getPropertiesByKey('collectorNames').find((c) => c.value.trim() !== '');
    let names = [];
    if (namesCP) {
        names = namesCP.value.split(',');
    }
    const altText = (_a = cp.getPropertiesByKey('altText').find((t) => t.value.trim() !== '')) === null || _a === void 0 ? void 0 : _a.value;
    return {
        prototypeNames: names,
        altText,
    };
}
