var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Checklist as ChecklistDisplay, ChecklistEntry } from '@corti/corti-ui';
import { resolveReadOnlyChecklists } from './data/resolveReadOnlyChecklists';
export function ReadOnlyChecklistPanel(props) {
    const checklistsWithState = resolveReadOnlyChecklists(props);
    return (React.createElement(React.Fragment, null, checklistsWithState.map((_a) => {
        var { entries } = _a, checklist = __rest(_a, ["entries"]);
        return (React.createElement(ChecklistDisplay, Object.assign({ key: checklist.id, readOnly: true }, checklist), entries.map((entry) => (React.createElement(ChecklistEntry, Object.assign({ key: entry.id, readOnly: true }, entry))))));
    })));
}
