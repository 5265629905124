var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { withTheme } from '@corti/theme';
import { observer } from 'browser/stores';
import { repositories } from 'core/repositories';
import { Autocomplete, Button, MenuItem, TextField } from 'lib/cortiUI';
// @ts-ignore
let ChangeRoleForm = class ChangeRoleForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                roleId: this.props.roleId,
            }
        });
        Object.defineProperty(this, "handleFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (e) => {
                e.preventDefault();
                const updatedUser = {
                    roleID: this.state.roleId,
                };
                const { error } = await repositories.users.updateUser(this.props.userId, updatedUser);
                if (!error) {
                    this.props.onSubmit();
                }
            }
        });
    }
    componentDidMount() {
        repositories.roles.loadAllRoles();
    }
    render() {
        var _a;
        const { roleId } = this.state;
        const allRolesItems = repositories.roles.roles.map((role) => ({
            value: role.id,
            text: role.name,
        }));
        return (React.createElement("form", { className: css({
                display: 'flex',
                flexDirection: 'column',
                gap: (_a = this.props.theme) === null || _a === void 0 ? void 0 : _a.space[6],
            }), onSubmit: this.handleFormSubmit },
            React.createElement(Autocomplete, { "data-cy": "change-role-selector", size: "small", options: allRolesItems, getOptionLabel: (option) => option.text, value: allRolesItems.find((role) => role.value === roleId), onChange: (_, role) => {
                    if (role) {
                        this.setState({ roleId: role.value.toString() });
                    }
                }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: intl.t('team:role', 'Role') })), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { "data-cy": "select-item", selected: state.selected, key: option.value }), option.text)), clearIcon: null }),
            React.createElement(Button, { color: "primary", type: "submit", variant: "text", className: css({ alignSelf: 'flex-end' }) }, intl.t('team:save', 'Save'))));
    }
};
ChangeRoleForm = __decorate([
    withTheme,
    observer
], ChangeRoleForm);
export { ChangeRoleForm };
