import { isFinite, isNaN, isUndefined } from 'lodash';
import numeral from 'numeral';
const DurationKeys = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
const parseMs = (ms) => {
    const msAbs = Math.abs(ms || 0);
    const date = new Date(msAbs);
    const days = date.getUTCDay() - new Date(0).getUTCDay();
    return {
        hours: date.getUTCHours() + days * 24,
        minutes: date.getUTCMinutes(),
        seconds: date.getUTCSeconds(),
        milliseconds: date.getUTCMilliseconds(),
    };
};
const parseMsToDayTime = (ms) => {
    const msAbs = Math.abs(ms || 0);
    const date = new Date(msAbs);
    return {
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
        seconds: date.getUTCSeconds(),
    };
};
const parseMsToDate = (ms) => {
    const msAbs = Math.abs(ms || 0);
    const date = new Date(msAbs);
    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
    };
};
const zerofi = (num, minNumOfDigits = 2) => {
    const digits = minNumOfDigits < 2 ? 2 : Math.round(minNumOfDigits);
    const str = num.toString().split('.')[0];
    const numOfZeros = digits > str.length ? digits - str.length : 0;
    let res = num.toString();
    for (let i = 1; i <= numOfZeros; i++) {
        res = '0' + res;
    }
    return res;
};
export const Formatters = {
    /**
     * Format ms to "00:00:00" (hours:minutes:seconds)
     */
    msToDuration(ms, options = {}) {
        const { showHours = true, showMilliseconds = false } = options;
        if (!isFinite(ms) || isUndefined(ms)) {
            return '-';
        }
        const { hours, minutes, seconds, milliseconds } = parseMs(ms);
        const formattedHours = showHours ? zerofi(hours) + ':' : '';
        const formattedMinutes = showHours ? zerofi(minutes) : zerofi(hours * 60 + minutes);
        const formattedSeconds = zerofi(seconds);
        const formattedMilliseconds = showMilliseconds ? '.' + zerofi(milliseconds, 3) : '';
        const result = formattedHours + formattedMinutes + ':' + formattedSeconds + formattedMilliseconds;
        if (ms < 0) {
            return `-${result}`;
        }
        return result;
    },
    /**
     * Format ms to "00:00:00" (hours:minutes:seconds)
     * Format whole date ms to day time
     */
    msToDayTime(ms, options = {}) {
        const { showHours = true, separator = ':' } = options;
        if (!isFinite(ms) || isUndefined(ms) || ms < 0) {
            return '-';
        }
        const { hours, minutes, seconds } = parseMsToDayTime(ms);
        if (showHours) {
            return zerofi(hours) + separator + zerofi(minutes) + separator + zerofi(seconds);
        }
        else {
            return zerofi(hours * 60 + minutes) + separator + zerofi(seconds);
        }
    },
    /**
     * Format ms to readable format
     * e.g. 123h 59m 59s
     * or 59m 59s
     * or 59s
     */
    msToDurationReadable(ms) {
        if (isNaN(ms) || isUndefined(ms)) {
            return '-';
        }
        const { hours, minutes, seconds } = parseMs(ms);
        const isNegative = ms < 0;
        return `${isNegative ? '-' : ''}${hours ? `${zerofi(hours)} h ` : ''}${hours | minutes ? `${zerofi(minutes)} min ` : ''}${zerofi(seconds)} s`;
    },
    msToSeconds(ms) {
        if (isNaN(ms) || ms == undefined) {
            return '-';
        }
        else {
            return Math.round(ms / 1000).toFixed(0) + ' s';
        }
    },
    /**
     * Format ms to "YYYY-MM-DD"
     */
    msToFullYears(ms, options = {}) {
        const { separator = '-' } = options;
        if (!isFinite(ms) || isUndefined(ms) || ms < 0) {
            return '-';
        }
        const { year, month, day } = parseMsToDate(ms);
        return zerofi(year) + separator + zerofi(month) + separator + zerofi(day);
    },
    /**
     * E.g. 0.1234 to "12.3%"
     */
    ratioToPercentage(ratio) {
        if (Number.isNaN(ratio) || !ratio) {
            return '-';
        }
        return numeral(ratio).format('0.0%');
    },
    /**
     *  E.g. 12.34566 to 12.4%
     */
    roundPercentage(percent) {
        return Formatters.ratioToPercentage(percent / 100);
    },
    /**
     * Takes ISO 8601 duration string and outputs Duration object
     */
    durationStringToDuration(durationString) {
        var _a;
        const numbersPattern = '\\d+(?:[\\.,]\\d+)?';
        const datePattern = `(${numbersPattern}Y)?(${numbersPattern}M)?(${numbersPattern}W)?(${numbersPattern}D)?`;
        const timePattern = `T(${numbersPattern}H)?(${numbersPattern}M)?(${numbersPattern}S)?`;
        const exp = new RegExp(`P(?:${datePattern}(?:${timePattern})?)`);
        const defaultDuration = {
            years: 0,
            months: 0,
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        return (_a = durationString
            .match(exp)) === null || _a === void 0 ? void 0 : _a.slice(1).reduce((prev, next, idx) => {
            prev[DurationKeys[idx]] = parseFloat(next) || 0;
            return prev;
        }, defaultDuration);
    },
};
