import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { observer } from 'mobx-react';
import React from 'react';
import { getTimeZone } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Base, Button, Loading, Typography } from 'lib/cortiUI';
import { useTranscriptSearchViewContext } from '../TranscriptSearchView/context';
import { DateRangePicker, TermGroup } from '../components';
import { FilterPicker } from '../components/FilterPicker';
import { useTranscriptQueryViewContext } from './context';
export const ParametersPanel = observer(function ParametersPanel() {
    var _a;
    const { utils } = useTranscriptSearchViewContext();
    const { viewModel } = useTranscriptQueryViewContext();
    const { t } = useTranslation();
    const theme = useTheme();
    function handleSearch() {
        const { query } = viewModel.state;
        if (!query)
            return;
        query.parameters.mergeParameters();
        query.parameters.persistTempParameters();
        const dateRange = utils.toAbsoluteDateRange(query.parameters.dateRange);
        if (!dateRange)
            return;
        const combinedUsers = utils.combineUserIDsAndTeamMemberIDs(query.parameters.tempUserIDs, query.parameters.tempTeamIDs);
        // promises don't reject
        void Promise.all([
            viewModel.loadTimeSeries({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
            viewModel.loadCalls({
                dateRange: dateRange,
                timeZone: getTimeZone(),
                termGroups: query.parameters.filteredTermGroups,
                userIDs: combinedUsers,
                protocolNameIDs: query.parameters.protocolNames,
            }),
        ]);
        trackerService.track('[Transcript search] searched for calls', {
            transcriptQuery: {
                id: query.id,
                name: query.name,
                timeZone: getTimeZone(),
                dateRange: query.parameters.dateRange,
                termGroups: query.parameters.filteredTermGroups.map((it) => it.terms),
            },
        });
    }
    if (viewModel.state.isLoadingQuery) {
        return (React.createElement(Loading, { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", "data-cy": "transcript-query-view-parameters-panel-loading" }));
    }
    if (!viewModel.state.query) {
        return null;
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", "data-cy": "transcript-query-view-parameters-panel" },
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "flex-start", mb: 5, gap: 3 },
            React.createElement(DateRangePicker, { dateRange: viewModel.state.query.parameters.tempDateRange, onChange: viewModel.state.query.parameters.changeTempDateRange }),
            React.createElement(FilterPicker, null)),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            viewModel.state.query.parameters.tempTermGroups.map((it) => {
                return (React.createElement(TermGroup, { key: it.id, termGroup: it, isUnsuccessful: viewModel.unsuccessfulTermGroups.includes(it.id), onChangeTerms: viewModel.state.query.parameters.changeTempTerms, onRemoveTermGroup: viewModel.state.query.parameters.removeTempTermGroup }));
            }),
            React.createElement(Button, { onClick: (_a = viewModel.state.query) === null || _a === void 0 ? void 0 : _a.parameters.addTempTermGroup, variant: "outlined", "data-cy": "add-search-field-btn", startIcon: React.createElement(AddRoundedIcon, { fontSize: "small", className: css({ color: theme.palette.text.secondary }) }) },
                React.createElement(Typography, { variant: "body1", color: "secondaryText", mr: "auto" }, t('transcriptSearchApp:addSearchField', 'Add search field')))),
        React.createElement(Button, { "data-cy": "transcript-search-keyword-search-btn", className: css({ marginTop: 5, marginRight: 'auto' }), size: "small", disabled: !viewModel.state.query.parameters.hasChanges ||
                viewModel.state.query.parameters.hasNoTerms, onClick: handleSearch }, t('search', 'Search'))));
});
