import { observer } from 'mobx-react';
import React from 'react';
import { useTriageSession } from '../TriageSessionContext';
import { useUIEventObserver } from '../UIEventProvider';
import { Selector } from './Selector';
import { useActionSelector } from './hooks';
export const ActionSelector = observer(function ActionSelector({ keyboardShortcuts, }) {
    const { controller } = useTriageSession();
    const observer = useUIEventObserver();
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState(undefined);
    const { items } = useActionSelector({
        isMenuOpen: isOpen,
        searchQuery,
    });
    React.useEffect(() => {
        var _a;
        const blockProto = controller.flow.actionStore.activatedActions
            // if custom properties are defined, check if the action is explictly excluded from the manual action selector list
            .filter((it) => { var _a; return (_a = it.customProperties) === null || _a === void 0 ? void 0 : _a.every(({ key }) => key !== 'excludeFromActionSelector'); })[0];
        blockProto
            ? setSelectedItem({
                text: ((_a = blockProto.text) === null || _a === void 0 ? void 0 : _a.plainText) ? blockProto.text.plainText : '-',
                value: blockProto.id,
            })
            : setSelectedItem(undefined);
    }, [
        controller.flow.actionStore,
        controller.flow.actionStore.pendingActions,
        controller.flow.actionStore.activatedActions,
    ]);
    function handleItemSelect(item) {
        var _a;
        observer.fireEvent({
            type: 'action-selector-item-clicked',
            data: item,
        });
        const blockProto = controller.flow.graphTraverser.getBlockProtoByID(item.value);
        if (!blockProto)
            return;
        setSelectedItem({
            text: ((_a = blockProto.text) === null || _a === void 0 ? void 0 : _a.plainText) ? blockProto.text.plainText : '-',
            value: blockProto.id,
        });
        controller.flow.actionStore.trigger({
            blockPrototype: blockProto,
            triggeredBy: 'manual-action-selector',
            source: 'user-action',
            sourceData: {
                location: 'action-selector',
            },
        });
    }
    return (React.createElement(Selector, { items: items, keyboardShortcuts: keyboardShortcuts, isPending: selectedItem ? controller.flow.actionStore.isPending(selectedItem.value) : false, isReadonly: !controller.isCurrentUserOwner, selectedItem: selectedItem, onSearchQueryChange: setSearchQuery, onSelectItem: handleItemSelect, onOpen: () => setIsOpen(true), onClose: () => {
            setIsOpen(false);
            observer.fireEvent({ type: 'action-selector-closed' });
        } }));
});
