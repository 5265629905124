import React from 'react';
import { DateRangePicker, relativeToAbsolute, } from 'lib/cortiUI';
export function DateRangePickerPanel(props) {
    const { dateRange, onChange } = props;
    function handleDateRangeSelect(range) {
        onChange({
            type: 'absolute',
            absoluteRange: {
                start: range.startDate,
                end: range.endDate,
            },
        });
    }
    function handleRelativeDateRangeSelect(range) {
        onChange({
            type: 'relative',
            relativeRange: {
                duration: range.durationString,
                isCurrentPeriodIncluded: range.isCurrentPeriodIncluded,
            },
        });
    }
    function toDateRangePickerFormat(range) {
        if (range.type === 'absolute') {
            return {
                startDate: range.absoluteRange.start,
                endDate: range.absoluteRange.end,
            };
        }
        const absoluteRange = relativeToAbsolute({
            durationString: range.relativeRange.duration,
            isCurrentPeriodIncluded: range.relativeRange.isCurrentPeriodIncluded,
        });
        if (absoluteRange) {
            return {
                startDate: absoluteRange.start,
                endDate: absoluteRange.end,
            };
        }
        return {
            startDate: new Date(),
            endDate: new Date(),
        };
    }
    return (React.createElement(DateRangePicker, { minDate: new Date(2000, 0), range: toDateRangePickerFormat(dateRange), relativeDateRangePickerProps: {
            enabled: true,
            onSelect: handleRelativeDateRangeSelect,
            selectedDateRange: dateRange.type === 'relative'
                ? {
                    durationString: dateRange.relativeRange.duration,
                    isCurrentPeriodIncluded: dateRange.relativeRange.isCurrentPeriodIncluded,
                }
                : undefined,
            dateRanges: (ranges) => {
                return [
                    ranges.LAST_YEAR,
                    ranges.THIS_YEAR,
                    ranges.LAST_MONTH,
                    ranges.THIS_MONTH,
                    ranges.LAST_WEEK,
                    ranges.LAST_30_DAYS,
                    ranges.YESTERDAY,
                ];
            },
        }, onChange: handleDateRangeSelect, withTime: true }));
}
