import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button, Modal } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { useParametersContext } from '../../context/ParametersContext';
import { DiscardModalContent } from './DiscardModalContent';
export const DiscardButton = () => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'discardChanges' });
    const { isEditMode, isDraft, toggleEditMode } = useGlobalContext();
    const { isChanged, resetView, isCreateOrUpdateViewLoading, discardDraftView } = useParametersContext();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleModalClose = () => setIsModalOpen(false);
    const handleButtonClick = () => {
        if (isDraft || (isEditMode && isChanged)) {
            setIsModalOpen(true);
        }
        else {
            toggleEditMode();
        }
    };
    const draftModalOptions = {
        title: t('discardDraftView', 'Discard unsaved view'),
        description: t('discardDraftWarning', 'All applied changes will be reset and this View will be deleted forever.'),
        cancelText: t('backBtn', 'Back'),
        confirmText: t('discardDraftViewBtn', 'Discard view'),
    };
    const savedViewModalOptions = {
        title: t('discardViewChanges', 'Discard unsaved changes'),
        description: t('discardViewChangesWarning', 'Exiting edit mode will discard all unsaved changes.'),
        cancelText: t('keepEditingBtn', 'Keep Editing'),
        confirmText: t('discardViewChangesBtn', 'Discard changes'),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-cy": "performance-discard-view-btn", variant: "outlined", size: "small", disabled: isCreateOrUpdateViewLoading, onClick: handleButtonClick }, t('discard', 'Discard')),
        React.createElement(Modal, { open: isModalOpen, onClose: handleModalClose, size: "small" },
            React.createElement(DiscardModalContent, { options: isDraft ? draftModalOptions : savedViewModalOptions, onClose: handleModalClose, onConfirm: () => {
                    handleModalClose();
                    if (isDraft) {
                        discardDraftView();
                    }
                    else {
                        resetView();
                        toggleEditMode();
                    }
                } }))));
};
