import { isEqual } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { List, ListItemButton } from 'lib/cortiUI/components/List';
import { Typography } from 'lib/cortiUI/components/Typography';
import { relativeToAbsolute } from './utils';
const DEFAULT_RELATIVE_DATE_RANGES = {
    TODAY: {
        durationString: 'P0Y0M0W1DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    YESTERDAY: {
        durationString: 'P0Y0M0W1DT0H0M0S',
        isCurrentPeriodIncluded: false,
    },
    LAST_7_DAYS: {
        durationString: 'P0Y0M0W7DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    LAST_30_DAYS: {
        durationString: 'P0Y0M0W30DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    THIS_WEEK: {
        durationString: 'P0Y0M1W0DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    LAST_WEEK: {
        durationString: 'P0Y0M1W0DT0H0M0S',
        isCurrentPeriodIncluded: false,
    },
    THIS_MONTH: {
        durationString: 'P0Y1M0W0DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    LAST_MONTH: {
        durationString: 'P0Y1M0W0DT0H0M0S',
        isCurrentPeriodIncluded: false,
    },
    LAST_3_MONTHS: {
        durationString: 'P0Y3M0W0DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    THIS_YEAR: {
        durationString: 'P1Y0M0W0DT0H0M0S',
        isCurrentPeriodIncluded: true,
    },
    LAST_YEAR: {
        durationString: 'P1Y0M0W0DT0H0M0S',
        isCurrentPeriodIncluded: false,
    },
};
function RelativeDateRangePickerRaw(props) {
    const { selectedDateRange, dateRanges, onSelect } = props;
    const theme = useTheme();
    const defaultRelativeDateRanges = React.useRef({
        TODAY: Object.assign({ label: intl.t('today', 'today') }, DEFAULT_RELATIVE_DATE_RANGES.TODAY),
        YESTERDAY: Object.assign({ label: intl.t('relativeRange.yesterday', 'yesterday') }, DEFAULT_RELATIVE_DATE_RANGES.YESTERDAY),
        LAST_7_DAYS: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.dayCount', {
                count: 7,
                defaultValue_one: '{{ count }} day',
                defaultValue_other: '{{ count }} days',
            })}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_7_DAYS),
        LAST_30_DAYS: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.dayCount', {
                count: 30,
                defaultValue_one: '{{ count }} day',
                defaultValue_other: '{{ count }} days',
            })}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_30_DAYS),
        THIS_WEEK: Object.assign({ label: `${intl.t('relativeRange.this', 'this')} ${intl.t('relativeRange.week', 'week')}` }, DEFAULT_RELATIVE_DATE_RANGES.THIS_WEEK),
        LAST_WEEK: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.week', 'week')}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_WEEK),
        THIS_MONTH: Object.assign({ label: `${intl.t('relativeRange.this', 'this')} ${intl.t('relativeRange.month', 'month')}` }, DEFAULT_RELATIVE_DATE_RANGES.THIS_MONTH),
        LAST_MONTH: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.month', 'month')}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_MONTH),
        LAST_3_MONTHS: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.monthCount', {
                count: 3,
                defaultValue_one: '{{ count }} month',
                defaultValue_other: '{{ count }} months',
            })}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_3_MONTHS),
        THIS_YEAR: Object.assign({ label: `${intl.t('relativeRange.this', 'this')} ${intl.t('relativeRange.year', 'year')}` }, DEFAULT_RELATIVE_DATE_RANGES.THIS_YEAR),
        LAST_YEAR: Object.assign({ label: `${intl.t('relativeRange.last', 'last')} ${intl.t('relativeRange.year', 'year')}` }, DEFAULT_RELATIVE_DATE_RANGES.LAST_YEAR),
    });
    function handleSelect(relativeDateRange) {
        onSelect(relativeDateRange, relativeToAbsolute(relativeDateRange));
    }
    return (React.createElement(List, null, (dateRanges
        ? typeof dateRanges === 'function'
            ? dateRanges(defaultRelativeDateRanges.current)
            : dateRanges
        : Object.values(defaultRelativeDateRanges.current)).map((dateRange, idx) => (React.createElement(ListItemButton, { key: idx, onClick: () => handleSelect(dateRange), selected: isEqual(selectedDateRange, {
            durationString: dateRange.durationString,
            isCurrentPeriodIncluded: dateRange.isCurrentPeriodIncluded,
        }), classes: {
            root: css({
                borderRight: `1px solid ${theme.palette.background.default}`,
                borderBottom: `1px solid ${theme.palette.background.default}`,
                borderRadius: 0,
                backgroundColor: theme.palette.background.card,
                '&:not(:last-child)': {
                    marginBottom: 0,
                },
            }),
        } },
        React.createElement(Typography, { my: 1, variant: "body2", color: "default", noWrap: true }, dateRange.label))))));
}
export const RelativeDateRangePicker = Object.assign(RelativeDateRangePickerRaw, DEFAULT_RELATIVE_DATE_RANGES);
