import { env, init } from '../shared/env';
export function initEnv() {
    init({
        isDebugMode: () => {
            return process.env.NODE_ENV === 'development';
        },
        isDevMode: () => process.env.NODE_ENV === 'development',
    });
    if (env.isDebugMode()) {
        window.env = env;
    }
}
