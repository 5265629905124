import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { Card, CardContent, Separator } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';
import { DropdownField } from './DropdownField';
import { RadioField } from './RadioField';
export const FieldWidget = ({ timelineEntryDefinitions, fieldData, renderVisibilityConditionsTrigger, onFieldUpdate, onCopy, onRemove, onOptionRemove, onTypeUpdate, onOpenVisibleIfWidget, onChoiceUpdate, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation('libFeedbackFormManager');
    const { palette } = useTheme();
    const conditionsNumber = (_b = (_a = fieldData === null || fieldData === void 0 ? void 0 : fieldData.visibleIf) === null || _a === void 0 ? void 0 : _a.conditions) === null || _b === void 0 ? void 0 : _b.length;
    const fieldFormTypeEnum = [
        {
            value: 'short-text',
            text: t('fieldShortText', 'Single line answer'),
        },
        {
            value: 'long-text',
            text: t('fieldLongText', 'Multiline answer'),
        },
        {
            value: 'dropdown',
            text: t('fieldDropdown', 'Dropdown'),
        },
        {
            value: 'radio',
            text: t('fieldRadio', 'Multiple choice'),
        },
    ];
    const handleTitleChange = (value) => {
        onFieldUpdate(Object.assign(Object.assign({}, fieldData), { title: value }));
    };
    function handleTypeChange(type) {
        if (!type)
            return;
        onTypeUpdate(fieldData, type);
    }
    function handleRequiredChange(value) {
        onFieldUpdate(Object.assign(Object.assign({}, fieldData), { required: value }));
    }
    function handleTimelineEntryDefinitionChange(sources) {
        if (fieldData.type === 'radio') {
            onFieldUpdate(Object.assign(Object.assign({}, fieldData), { acceptedTimelineEntryDefinitionSources: sources }));
        }
    }
    function handleAddNewChoice() {
        const defaultChoice = {
            value: uuid(),
            text: '',
        };
        if (fieldData.type === 'dropdown' || fieldData.type === 'radio') {
            const newChoices = fieldData.choices
                ? [...fieldData.choices, defaultChoice]
                : [defaultChoice];
            onFieldUpdate(Object.assign(Object.assign({}, fieldData), { choices: newChoices }));
        }
    }
    function handleRemoveChoice(value) {
        if (fieldData.type === 'dropdown' || fieldData.type === 'radio') {
            onOptionRemove(fieldData, value);
        }
    }
    function handleChoiceTextChange(choice) {
        if (fieldData.type === 'dropdown' || fieldData.type === 'radio') {
            onChoiceUpdate(fieldData, choice);
        }
    }
    function handleChoiceTimelineEntryHintsChange(choiceValue, timelineEntries) {
        var _a;
        if (fieldData.type === 'dropdown' || fieldData.type === 'radio') {
            const newChoices = (_a = fieldData.choices) === null || _a === void 0 ? void 0 : _a.map((c) => c.value === choiceValue
                ? Object.assign(Object.assign({}, c), { timelineEntryDefinitionSourceHints: timelineEntries }) : Object.assign({}, c));
            onFieldUpdate(Object.assign(Object.assign({}, fieldData), { choices: newChoices }));
        }
    }
    function getFieldContentByType() {
        var _a, _b, _c;
        switch (fieldData.type) {
            case 'short-text':
            case 'long-text':
                return null;
            case 'radio':
                return (React.createElement(RadioField, { choices: (_a = fieldData.choices) !== null && _a !== void 0 ? _a : [], timelineEntryDefinitions: timelineEntryDefinitions, acceptedTimelineEntryDefinitionSources: (_b = fieldData.acceptedTimelineEntryDefinitionSources) !== null && _b !== void 0 ? _b : [], onAcceptedTimelineEntryDefinitionsToFieldChange: handleTimelineEntryDefinitionChange, onAddChoice: handleAddNewChoice, onRemoveChoice: handleRemoveChoice, onChoiceInputValueChange: handleChoiceTextChange, onTimelineEntryDefsHintsValueChange: handleChoiceTimelineEntryHintsChange }));
            case 'dropdown':
                return (React.createElement(DropdownField, { choices: (_c = fieldData.choices) !== null && _c !== void 0 ? _c : [], timelineEntryDefinitions: timelineEntryDefinitions, onAddChoice: handleAddNewChoice, onRemoveChoice: handleRemoveChoice, onChoiceInputValueChange: handleChoiceTextChange, onTimelineEntryDefsHintsValueChange: handleChoiceTimelineEntryHintsChange }));
        }
    }
    return (React.createElement(Card, { px: 6, py: 0, style: {
            borderLeft: `5px solid ${palette.primary.main}`,
        }, "data-cy": CY_SELECTORS.fieldWidget.fieldBuilder },
        React.createElement(CardContent, null,
            React.createElement(CardHeader, { fieldTitle: fieldData.title, fieldType: fieldFormTypeEnum.find((i) => i.value === fieldData.type), fieldTypeOptions: fieldFormTypeEnum, onFieldTitleChange: handleTitleChange, onFieldTypeChange: handleTypeChange }),
            getFieldContentByType()),
        React.createElement(Separator, { horizontal: true, px: 6 }),
        React.createElement(CardFooter, { isRequired: (_c = fieldData.required) !== null && _c !== void 0 ? _c : false, onRequiredChange: handleRequiredChange, onCopy: onCopy, onRemove: onRemove, renderConditionsWidgetTrigger: Boolean(renderVisibilityConditionsTrigger), conditionsCount: conditionsNumber !== null && conditionsNumber !== void 0 ? conditionsNumber : 0, onConditionsWidgetTriggerClick: () => onOpenVisibleIfWidget(fieldData.id), shouldConfirmDeletion: !isEqual({
                title: fieldData.title,
                visibilityConditionsCount: (_e = (_d = fieldData.visibleIf) === null || _d === void 0 ? void 0 : _d.conditions.length) !== null && _e !== void 0 ? _e : 0,
                type: fieldData.type,
                required: Boolean(fieldData.required),
            }, { title: '', visibilityConditionsCount: 0, type: 'short-text', required: false }) })));
};
