import { forEach, isPlainObject } from 'lodash';
import { queueMacrotask } from '@corti/timers';
const registry = new Map();
function isEncodeable(val) {
    return typeof val === 'object' && val !== null && 'getEncodableData' in val;
}
function createResult() {
    const result = {
        internalEntities: new Map(),
        entities: new Map(),
    };
    return result;
}
function serialize(elements) {
    let els = Array.isArray(elements) ? elements : [elements];
    const result = createResult();
    function encodeObject(obj, collection) {
        if (!obj.constructor.__serializerConstructorID) {
            throw new Error('Constructor function has not been registered for object');
        }
        if (collection.get(obj.id)) {
            return;
        }
        const encodedEntity = {
            id: obj.id,
            constructorName: obj.constructor.__serializerConstructorID,
            objectData: obj.getEncodableData(),
        };
        collection.set(obj.id, encodedEntity);
        processObjectData(encodedEntity.objectData);
    }
    function processObjectData(collection) {
        forEach(collection, (val, key) => {
            if (Array.isArray(val) || isPlainObject(val)) {
                processObjectData(val);
            }
            if (isEncodeable(val)) {
                encodeObject(val, result.internalEntities);
                delete collection[key];
                // this does not work very well when collection is array type
                collection[`__encoded_key_${key}`] = val.id;
                return;
            }
        });
    }
    els.forEach((it) => {
        encodeObject(it, result.entities);
    });
    return result;
}
function createDeserializationResult() {
    const ctx = {
        entities: new Map(),
    };
    return ctx;
}
async function deserialize(input) {
    const result = createDeserializationResult();
    const models = new Map();
    const promises = new Map();
    const resolvers = new Map();
    function registerModel(model) {
        models.set(model.id, model);
        if (resolvers.get(model.id)) {
            resolvers.get(model.id)(model);
        }
    }
    async function getElement(id) {
        if (models.get(id)) {
            return Promise.resolve(models.get(id));
        }
        if (!promises.get(id)) {
            promises.set(id, new Promise((resolve) => {
                resolvers.set(id, resolve);
            }));
        }
        return promises.get(id);
    }
    const deserializationCtx = {
        getElement,
    };
    function reconstructEntity(encodedEntity) {
        const DecodeConstructor = getConstructorFor(encodedEntity.constructorName);
        if (!DecodeConstructor) {
            throw new Error(`Cannot decode entity: constructor not register for type ${encodedEntity.constructorName}`);
        }
        const entity = DecodeConstructor(encodedEntity.objectData, deserializationCtx);
        entity.id = encodedEntity.id;
        registerModel(entity);
        return entity;
    }
    for (const [_, encodedEntity] of input.internalEntities) {
        reconstructEntity(encodedEntity);
    }
    for (const [_, encodedEntity] of input.entities) {
        const entity = reconstructEntity(encodedEntity);
        result.entities.set(entity.id, entity);
    }
    if (Object.values(promises).length) {
        await Promise.all(Object.values(promises));
    }
    else {
        // hacky??
        await queueMacrotask();
    }
    return result;
}
function register(constructor, constructorName) {
    // because of js minifaction, we cannot just use `constructor.name` in production
    constructor.__serializerConstructorID = constructorName;
    if (registry.has(constructorName)) {
        console.warn(`Constructor already registered for name ${constructorName}`);
    }
    registry.set(constructorName, constructor.decode);
}
function getConstructorFor(name) {
    return registry.get(name);
}
export const ElementSerializer = {
    register,
    serialize,
    deserialize,
};
