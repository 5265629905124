import { isPlainObject, merge } from 'lodash';
import { isElectron } from '@corti/electron-utils';
import { callMain } from '@corti/ipc-renderer';
import { env } from 'core/environment';
import { ConfigManager } from '../shared';
import { getCortiLiveSubdomain, shouldUseSubdomainAsEnv } from './utils';
export let config;
export async function initConfig(publicApi) {
    config = new ConfigManager({ initialConfig: env.buildInfo.applicationConfig });
    const obj = {};
    // infer apiHost from subdomain if possible
    if (shouldUseSubdomainAsEnv(location.host)) {
        const subdomain = getCortiLiveSubdomain(location.host);
        // we know from `shouldUseSubdomainAsEnv` that subdomain is a valid string here
        obj.apiHost = `https://api.${subdomain}.motocorti.io`;
    }
    // in case of electron, apiHost from user config should have a priority over the inferred one from the domain
    const userConf = await getUserConfig();
    config.init({
        userConfig: Object.assign(Object.assign({}, obj), userConf),
        inlineConfig: getBrowserInlineConfig(),
    });
    config.onDidChangeUserConfig((newConfig) => {
        if (isElectron()) {
            void callMain('config.sync', { userConfig: newConfig });
        }
        else {
            persistInBrowser(newConfig);
        }
    });
    publicApi.exposeMethod({
        name: '/app/getApiHost',
        handler: () => {
            return {
                apiHost: config.getConfig().apiHost,
            };
        },
    });
}
async function getUserConfig() {
    if (isElectron()) {
        return callMain('config.getConfig');
    }
    else {
        return getFromLocalStorage();
    }
}
export const USER_CONFIG_LOCAL_STORAGE_KEY = 'corti:userConfig';
function persistInBrowser(config) {
    localStorage.setItem(USER_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(config));
}
export function getBrowserInlineConfig() {
    const obj = {};
    // apiHost URL param has the highest priority
    // TODO(@elvis): any config can be overwritten via URL params, this might potentially be a
    // security concern
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
        obj[key] = value;
    });
    const sessionConf = getFromLocalStorage();
    const conf = merge(sessionConf, obj);
    localStorage.setItem(USER_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(conf));
    return conf;
}
export function getFromLocalStorage() {
    const storedConfig = localStorage.getItem(USER_CONFIG_LOCAL_STORAGE_KEY);
    if (storedConfig == null) {
        return {};
    }
    try {
        const conf = JSON.parse(storedConfig);
        if (!isPlainObject(conf)) {
            console.warn('config loader: invalid persisted config found', conf);
            throw new Error();
        }
        return conf;
    }
    catch (_a) {
        console.warn('config loader: invalidating persisted config');
        localStorage.removeItem(USER_CONFIG_LOCAL_STORAGE_KEY);
        return {};
    }
}
export function getFromSessionStorage() {
    const storedConfig = sessionStorage.getItem(USER_CONFIG_LOCAL_STORAGE_KEY);
    if (storedConfig == null) {
        return {};
    }
    try {
        const conf = JSON.parse(storedConfig);
        if (!isPlainObject(conf)) {
            console.warn('config loader: invalid session config found', conf);
            throw new Error();
        }
        return conf;
    }
    catch (_a) {
        console.warn('config loader: invalidating session config');
        localStorage.removeItem(USER_CONFIG_LOCAL_STORAGE_KEY);
        return {};
    }
}
