import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Button } from 'lib/cortiUI';
import { DiscardDialog } from '../components';
import { useReview } from '../context';
export const Discard = ({ onDiscard }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.footer.discard' });
    const { reviewID } = useReview();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    function handleDiscard() {
        if (reviewID) {
            setIsDialogOpen(true);
        }
        else {
            onDiscard();
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", size: "small", onClick: handleDiscard }, t('btnLabel', 'Discard')),
        React.createElement(DiscardDialog, { isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), onDiscard: () => {
                setIsDialogOpen(false);
                onDiscard();
            } })));
};
