export class Observer {
    constructor() {
        Object.defineProperty(this, "handlers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    /**
     * Attach a handler function for an event.
     */
    on(event, listener) {
        if (this.handlers == null) {
            this.handlers = {};
        }
        let handlers = this.handlers[event];
        if (!handlers) {
            handlers = this.handlers[event] = [];
        }
        handlers.push(listener);
        return () => this.un(event, listener);
    }
    /**
     * Remove an event handler.
     */
    un(event, listener) {
        if (!this.handlers) {
            return;
        }
        const handlers = this.handlers[event];
        let i;
        if (handlers) {
            if (listener) {
                for (i = handlers.length - 1; i >= 0; i--) {
                    if (handlers[i] == listener) {
                        handlers.splice(i, 1);
                    }
                }
            }
            else {
                handlers.length = 0;
            }
        }
    }
    /**
     * Remove all event handlers.
     */
    unAll() {
        this.handlers = {};
    }
    /**
     * Attach a handler to an event. The handler is executed at most once per
     * event type.
     */
    once(event, listener) {
        const proxy = (...args) => {
            this.un(event, proxy);
            return listener.apply(undefined, args);
        };
        return this.on(event, proxy);
    }
    /**
     * Manually fire an event
     *
     * @param {...any} args The arguments with which to call the listeners
     */
    fireEvent(event, ...args) {
        this.getEventListeners(event).forEach((fn) => {
            fn(...args);
        });
    }
    getEventListeners(event) {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.handlers) === null || _a === void 0 ? void 0 : _a[event]) === null || _b === void 0 ? void 0 : _b.slice()) !== null && _c !== void 0 ? _c : [];
    }
}
