var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { ViewNodeModel, } from 'lib/graphEditor/canvas/nodes';
import { BranchModel } from './BranchModel';
import { GlobalElementContext } from './GlobalElementContext';
import { ChecklistLibrary } from './checklists/ChecklistLibrary';
import { FactLibrary } from './facts/FactLibrary';
import { GraphEditorLibrary } from './library/GraphEditorLibrary';
export class GraphEditorModel extends Observer {
    constructor(options = {}) {
        var _a;
        super();
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ctx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "elementContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "library", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "facts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "branches", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startNodeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.id = (_a = options.id) !== null && _a !== void 0 ? _a : uuid();
        runInAction(() => {
            var _a;
            this.branches = (_a = options.branches) !== null && _a !== void 0 ? _a : [new BranchModel({ parent: this })];
        });
        this.elementContext = new GlobalElementContext(this);
        this.library = new GraphEditorLibrary(this);
        this.facts = new FactLibrary();
        this.checklists = new ChecklistLibrary();
    }
    setContext(ctx) {
        this.ctx = ctx;
    }
    setStartNodeID(nodeID) {
        this.startNodeID = nodeID;
    }
    addBranch(data = {}) {
        const b = new BranchModel({ parent: this, data: data });
        this.addPreparedBranch(b);
        return b;
    }
    addPreparedBranch(branch) {
        branch.setParent(this);
        this.branches.push(branch);
        this.fireEvent('branchAdded', { branch });
    }
    removeBranch(branch) {
        const idx = this.branches.findIndex((b) => b === branch);
        if (idx !== -1) {
            this.branches.splice(idx, 1);
            this.fireEvent('branchRemoved', { branch });
        }
    }
    getBranchByID(id) {
        return this.branches.find((b) => b.id === id);
    }
    /**
     * Convenience api to add node to the first branch.
     * Use branch api to be more specific when adding a node
     * */
    addNode(node) {
        const b = this.branches[0];
        if (!b) {
            return;
        }
        b.addNode(node);
        if (!this.startNodeID && node instanceof ViewNodeModel) {
            this.setStartNodeID(node.id);
        }
    }
    get startNode() {
        if (!this.startNodeID) {
            return;
        }
        return this.getNodeByID(this.startNodeID);
    }
    getNodeByID(id) {
        return this.nodes.get(id);
    }
    getNodesByType(type) {
        const result = new Map();
        for (const node of this.nodes.values()) {
            if (node.type === type) {
                result.set(node.id, node);
            }
        }
        return result;
    }
    get nodes() {
        const allNodes = new Map();
        for (const branch of this.branches) {
            branch.nodes.forEach((n) => {
                allNodes.set(n.id, n);
            });
        }
        return allNodes;
    }
    get logicGates() {
        let allGates = new Map();
        for (const branch of this.branches) {
            branch.logicGates.forEach((l) => {
                allGates.set(l.id, l);
            });
        }
        return allGates;
    }
    getLogicGateByID(id) {
        return this.logicGates.get(id);
    }
    get links() {
        const r = new Map();
        for (const b of this.branches) {
            b.links.forEach((l) => {
                r.set(l.id, l);
            });
        }
        return r;
    }
    getLinkByID(id) {
        for (const b of this.branches) {
            const found = b.links.find((l) => l.id === id);
            if (found) {
                return found;
            }
        }
    }
    get entities() {
        return new Map([...this.links, ...this.nodes]);
    }
    getEntitityByID(id) {
        return this.entities.get(id);
    }
    merge(model) {
        model.branches.forEach((b) => {
            const existingBranch = this.getBranchByID(b.id);
            if (existingBranch) {
                existingBranch.merge(b);
            }
            else {
                this.addPreparedBranch(b);
            }
        });
        if (model.startNodeID) {
            this.setStartNodeID(model.startNodeID);
        }
    }
    serialize() {
        return {
            id: this.id,
            startNodeID: this.startNodeID,
            branches: this.branches.map((c) => {
                return c.getEncodableData();
            }),
        };
    }
    deserialize(entity, ctx) {
        this.id = entity.id;
        this.branches = entity.branches.map((b) => {
            const branchModel = this.addBranch();
            branchModel.deserialize(b, ctx);
            return branchModel;
        });
        if (entity.startNodeID) {
            const startNode = this.getNodeByID(entity.startNodeID);
            if (startNode instanceof ViewNodeModel) {
                this.setStartNodeID(startNode.id);
            }
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], GraphEditorModel.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], GraphEditorModel.prototype, "branches", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], GraphEditorModel.prototype, "startNodeID", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], GraphEditorModel.prototype, "setStartNodeID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_a = typeof BranchModel !== "undefined" && BranchModel) === "function" ? _a : Object)
], GraphEditorModel.prototype, "addBranch", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof BranchModel !== "undefined" && BranchModel) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], GraphEditorModel.prototype, "addPreparedBranch", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], GraphEditorModel.prototype, "startNode", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", typeof (_d = typeof Map !== "undefined" && Map) === "function" ? _d : Object),
    __metadata("design:paramtypes", [])
], GraphEditorModel.prototype, "nodes", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], GraphEditorModel.prototype, "logicGates", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_e = typeof Map !== "undefined" && Map) === "function" ? _e : Object),
    __metadata("design:paramtypes", [])
], GraphEditorModel.prototype, "links", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_f = typeof Map !== "undefined" && Map) === "function" ? _f : Object),
    __metadata("design:paramtypes", [])
], GraphEditorModel.prototype, "entities", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [GraphEditorModel]),
    __metadata("design:returntype", void 0)
], GraphEditorModel.prototype, "merge", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _g : Object, Object]),
    __metadata("design:returntype", void 0)
], GraphEditorModel.prototype, "deserialize", null);
