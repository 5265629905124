import { da, enUS, fr, it, sv } from 'date-fns/locale';
import { timeZoneList } from './supportedTimeZones';
import { allDateFormats, allTimeFormats } from './types';
// Mapping between Corti supported and dateFns lacales
const LOCALES = {
    en: enUS,
    'en-US-sfd': enUS,
    da: da,
    fr: fr,
    it: it,
    sv: sv,
};
export const DEFAULT_DATE_FORMAT = 'P';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
const LOCAL_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DEFAULT_TIME_ZONE = isTimeZoneValid(LOCAL_TIME_ZONE) ? LOCAL_TIME_ZONE : 'UTC';
export const DEFAULT_LOCALE_KEY = 'en';
let dateFormat = DEFAULT_DATE_FORMAT;
let timeFormat = DEFAULT_TIME_FORMAT;
let locale = LOCALES[DEFAULT_LOCALE_KEY];
let timeZone = DEFAULT_TIME_ZONE;
export function getDateFormat() {
    return dateFormat;
}
export function getTimeFormat() {
    return timeFormat;
}
export function getLocale() {
    return locale;
}
export function getTimeZone() {
    return timeZone;
}
export function setDateFormat(value) {
    dateFormat = value;
}
export function setTimeFormat(value) {
    timeFormat = value;
}
export function setLocale(value) {
    locale = LOCALES[value];
}
export function setTimeZone(value) {
    timeZone = value;
}
export function isDateFormatValid(format) {
    return allDateFormats.includes(format);
}
export function isTimeFormatValid(format) {
    return allTimeFormats.includes(format);
}
export function isTimeZoneValid(timeZone) {
    return timeZoneList.includes(timeZone);
}
export function isLocaleKeyValid(locale) {
    return Object.keys(LOCALES).includes(locale);
}
