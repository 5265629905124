import React from 'react';
import { useTranslation } from '@corti/i18n';
import { TimelineEntryDefinitionMods } from '@corti/lib/coreApiService';
import { Box, ColorPickerTrigger, DataGrid, Typography, } from 'lib/cortiUI';
import { DEFAULT_COLOR } from './AnnotationForm';
export const getRowData = (definition) => {
    var _a, _b;
    return ({
        id: definition.source.entityID + definition.source.entityType,
        category: definition.title,
        name: (_a = definition.text) !== null && _a !== void 0 ? _a : '',
        searchTerms: definition.keywords.join(', '),
        color: (_b = definition.style.color) !== null && _b !== void 0 ? _b : DEFAULT_COLOR,
        isEntryCreatable: definition.isEntryCreatable,
        sourceType: definition.source.type,
        mods: definition.mods,
        isObvious: definition.isObvious,
        _definition: definition,
    });
};
export const AnnotationsDataGrid = ({ rows, onRowClick, onSelectionModelChange, }) => {
    const { t } = useTranslation('annotationManagement');
    const renderCell = (params) => (React.createElement(Typography, { variant: "body1", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }, params.value));
    const columns = [
        {
            field: 'category',
            headerName: t('tableHeader.title', 'Category'),
            minWidth: 200,
            flex: 1,
            editable: false,
            renderCell,
        },
        {
            field: 'name',
            headerName: t('tableHeader.text', 'Name'),
            minWidth: 200,
            flex: 1,
            editable: false,
            renderCell,
        },
        {
            field: 'searchTerms',
            headerName: t('tableHeader.searchTerms', 'Search terms'),
            minWidth: 100,
            flex: 0.5,
            editable: false,
            renderCell,
        },
        {
            field: 'color',
            headerName: t('tableHeader.color', 'Color'),
            renderCell: (p) => (React.createElement(ColorPickerTrigger, { color: p.value, height: 36, width: 39, borderRadius: 4, border: "hidden" })),
        },
        {
            field: 'isEntryCreatable',
            headerName: t('tableHeader.creatable', 'Creatable'),
            type: 'boolean',
            editable: false,
        },
    ];
    const handleRowClick = (params) => onRowClick(params.row);
    return (React.createElement(DataGrid, { autoHeight: true, checkboxSelection: true, columns: columns, components: {
            NoRowsOverlay: () => (React.createElement(NoRowsGridOverlay, { text: t('gridEmptyPlaceholder', 'Click create annotation to start') })),
        }, disableColumnFilter: true, disableColumnMenu: true, disableSelectionOnClick: true, isRowSelectable: (params) => params.row.mods.includes(TimelineEntryDefinitionMods.DELETABLE), hideFooter: true, onSelectionModelChange: onSelectionModelChange, onRowClick: handleRowClick, pageSize: 20, rows: rows, initialState: {
            sorting: {
                sortModel: [
                    { field: 'category', sort: 'asc' },
                    { field: 'name', sort: 'asc' },
                ],
            },
        }, sx: {
            border: 'hidden',
            '& .MuiDataGrid-row': {
                cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
        } }));
};
function NoRowsGridOverlay({ text }) {
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: 500 },
        React.createElement(Typography, { color: "secondaryText", variant: "body1" }, text)));
}
