import { Observer } from '@corti/observer';
export class ModuleRegistry {
    constructor(rootStore, publicApi) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: rootStore
        });
        Object.defineProperty(this, "publicApi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: publicApi
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        Object.defineProperty(this, "_modules", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "getAppContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return { rootStore: this.rootStore, publicApi: this.publicApi };
            }
        });
        Object.defineProperty(this, "onModuleAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('moduleAdded', cb);
            }
        });
        Object.defineProperty(this, "onModuleRemoved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('moduleRemoved', cb);
            }
        });
        Object.defineProperty(this, "onModulesChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.observer.on('modulesChanged', cb);
            }
        });
    }
    registerModule(mod) {
        var _a;
        this._modules.push(mod);
        (_a = mod.onInit) === null || _a === void 0 ? void 0 : _a.call(mod, this.getAppContext());
        this.observer.fireEvent('moduleAdded', mod);
        return mod.id;
    }
    unregisterModule(id) {
        var _a;
        const idx = this._modules.findIndex((mod) => mod.id === id);
        if (idx !== -1) {
            const mod = this._modules[idx];
            (_a = mod.onDestroy) === null || _a === void 0 ? void 0 : _a.call(mod, this.getAppContext());
            this._modules.splice(idx, 1);
            this.observer.fireEvent('modulesChanged');
            this.observer.fireEvent('moduleRemoved', id);
        }
    }
    get modules() {
        return this._modules;
    }
    getModule(id) {
        return this._modules.find((it) => it.id === id);
    }
}
