import { observer } from 'mobx-react';
import Mousetrap from 'mousetrap';
import React from 'react';
import { intl } from '@corti/i18n';
import { stringifyRichText } from '@corti/richtext';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Card, ClickAwayListener, CopyButton, FocusContext, FocusElement, FocusElementGroup, MenuItem, Popper, Text, Tooltip, Typography, } from 'lib/cortiUI';
import { getFocusCss } from '../../utils';
import { AddValueButton } from './AddValueButton';
import { ValueTag } from './ValueTag';
const ELEMENT_FOCUS_DELAY = 100;
export const CollectorBlock = observer(function CollectorBlock(props) {
    const { isReadonly, items, onItemClick, onCustomValueAdd, onCustomValueUpdate, onItemRemove, getClipboardContents, innerRef, } = props;
    const theme = useTheme();
    const containerRef = React.useRef(null);
    const focusControllerRef = React.useRef(null);
    const keybinder = React.useRef(null);
    const [_isFocus, _setIsFocus] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState(null);
    const isEmpty = items.length === 0;
    React.useEffect(() => {
        if (containerRef.current && innerRef) {
            innerRef(containerRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        var _a;
        if (isReadonly) {
            (_a = keybinder.current) === null || _a === void 0 ? void 0 : _a.reset();
        }
        else {
            enableKeyboardShortcuts();
        }
    }, [isReadonly]);
    function enableKeyboardShortcuts() {
        var _a;
        if (focusControllerRef.current && containerRef.current) {
            (_a = keybinder.current) === null || _a === void 0 ? void 0 : _a.reset();
            keybinder.current = new Mousetrap(containerRef.current);
            keybinder.current.bind('down', (e) => {
                e.preventDefault();
            });
            keybinder.current.bind('up', (e) => {
                e.preventDefault();
            });
        }
    }
    const emitHandler = () => {
        var _a;
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        _setIsFocus(false);
    };
    const itemRemoveHandler = (item) => {
        var _a;
        onItemRemove(item);
        (_a = focusControllerRef.current) === null || _a === void 0 ? void 0 : _a.focusElementByID('add-button');
    };
    const customValueUpdateHandler = (value, item) => {
        // delete the item of the user remove all text in exiting value
        if (value === '') {
            itemRemoveHandler(item);
        }
        else {
            onCustomValueUpdate(item, value);
        }
    };
    const customValueAddHandler = (value) => {
        if (value === '') {
            // if a user click 2 time in an empty FVC we want to re trigger the valueTag
            _setIsFocus(false);
        }
        else {
            onCustomValueAdd(value);
        }
        setTimeout(() => {
            var _a;
            (_a = focusControllerRef.current) === null || _a === void 0 ? void 0 : _a.focusElementByID('add-button');
        }, ELEMENT_FOCUS_DELAY);
    };
    const label = props.label
        ? typeof props.label === 'string'
            ? props.label
            : stringifyRichText(props.label)
        : '';
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { mouseEvent: "onMouseDown", onClickAway: () => {
                if (isEmpty) {
                    _setIsFocus(false);
                }
            } },
            React.createElement(Base, { position: 'relative' },
                React.createElement(Base, { tabIndex: -1, ref: containerRef, onClick: isEmpty ? () => _setIsFocus(true) : undefined, className: css({
                        cursor: isEmpty ? 'text' : undefined,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.action.outlinedBorder}`,
                        padding: label !== '' ? '24px 46px 7px 14px' : '16px 14px',
                        minHeight: '55px',
                        lineHeight: '21px',
                        '&:focus, &:focus-within': {
                            boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
                            borderColor: theme.palette.primary.main,
                        },
                    }) },
                    label && (React.createElement(Text, { className: css({
                            transition: 'all 0.2s ease-in-out',
                            position: 'absolute',
                            color: theme.palette.text.secondary,
                            top: '14px',
                            fontSize: '0.75rem',
                            left: '14px',
                            transform: 'translateY(-50%)',
                        }) }, label)),
                    React.createElement(FocusContext, { controllerRef: (ref) => (focusControllerRef.current = ref), disableKeyboardShortcuts: isReadonly, keyboardEventBindingElement: containerRef.current, keyboardShortcuts: {
                            focusNextElement: 'right',
                            focusPrevElement: 'left',
                        } },
                        React.createElement(FocusElementGroup, null, ({ groupID }) => {
                            return (React.createElement(React.Fragment, null, items.map((item) => {
                                switch (item.valueType) {
                                    case 'block':
                                    case 'block-option': {
                                        return (React.createElement(FocusElement, { key: item.id, defaultGroupID: groupID, isFocusable: !isReadonly }, ({ registerElementRef }) => {
                                            return (React.createElement(Tooltip, { placement: "top", disableFocusListener: true, disableInteractive: true, key: item.id, title: item.label },
                                                React.createElement(ValueTag, { disabled: isReadonly, key: item.id, registerRef: registerElementRef, className: css({
                                                        textDecoration: 'underline',
                                                    }, getFocusCss({ theme })), onClick: () => onItemClick(item), onContextMenu: (event) => {
                                                        event.preventDefault();
                                                        setContextMenu({
                                                            anchorEl: event.currentTarget,
                                                            item: item,
                                                        });
                                                    } }, item.text)));
                                        }));
                                    }
                                    case 'fact': {
                                        return (React.createElement(ValueTag, { key: item.id, disabled: true }, item.text));
                                    }
                                    case 'custom': {
                                        return (React.createElement(FocusElement, { key: item.id, defaultGroupID: groupID, isFocusable: !isReadonly }, ({ registerElementRef }) => {
                                            return (React.createElement(ValueTag, { key: item.id, disabled: isReadonly, className: css({ cursor: 'pointer' }, getFocusCss({ theme })), valueType: item.valueType, onSubmit: (value) => customValueUpdateHandler(value, item), registerRef: registerElementRef, onContextMenu: (event) => {
                                                    event.preventDefault();
                                                    setContextMenu({
                                                        anchorEl: event.currentTarget,
                                                        item: item,
                                                    });
                                                } }, item.text));
                                        }));
                                    }
                                }
                            })));
                        }),
                        isReadonly ? null : (React.createElement(FocusElement, { defaultElementID: "add-button", isFocusable: !isReadonly }, ({ registerElementRef }) => {
                            return (React.createElement(AddValueButton, { ref: registerElementRef, onSubmit: customValueAddHandler, onEmit: emitHandler }));
                        })))),
                props.isCopyButtonEnabled && (React.createElement(Base, { "data-cy": "copy-button", className: css({
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }) },
                    React.createElement(CopyButton, { tooltipTitle: intl.t('realtime:copyButton', 'Copied'), getValue: getClipboardContents !== null && getClipboardContents !== void 0 ? getClipboardContents : (() => '') }))))),
        React.createElement(Popper, { anchorEl: contextMenu === null || contextMenu === void 0 ? void 0 : contextMenu.anchorEl, open: !!(contextMenu === null || contextMenu === void 0 ? void 0 : contextMenu.anchorEl), disablePortal: true, placement: "bottom-start" },
            React.createElement(ClickAwayListener, { mouseEvent: "onMouseDown", onClickAway: () => {
                    setContextMenu(null);
                } },
                React.createElement(Card, { px: 0 },
                    React.createElement(MenuItem, { onClick: () => {
                            if (!contextMenu)
                                return;
                            itemRemoveHandler(contextMenu.item);
                        } },
                        React.createElement(Typography, { variant: "body1", color: "default" }, intl.t('realtime:deleteFVCValue', 'Delete Comment'))))))));
});
