var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { trackerService } from 'browser/services/init';
import { CaseReviewRequestsFilterModel } from './CaseReviewRequestsFilterModel';
import { CaseReviewRequestsSortModel } from './CaseReviewRequestsSortModel';
export class CaseReviewRequestsModel {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "nextPageCursor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseReviewRequestsTotalCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "caseReviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isLoadingCaseReviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "filter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getCaseReviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                this.reset();
                await this.fetchCaseReviewRequests();
            }
        });
        Object.defineProperty(this, "getCaseReviewRequestsNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (this.hasNextPage && this.nextPageCursor) {
                    await this.fetchCaseReviewRequests();
                }
            }
        });
        Object.defineProperty(this, "fetchCaseReviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (this.isLoadingCaseReviewRequests)
                    return;
                try {
                    this.isLoadingCaseReviewRequests = true;
                    const res = await this.input.api.getReviewRequests({
                        organizationID: this.input.organizationID,
                        filter: this.filter.caseReviewRequestFilterInput,
                        sort: this.sort.caseReviewRequestSortInput,
                        cursor: this.nextPageCursor,
                    });
                    runInAction(() => {
                        this.nextPageCursor = res.cursor;
                        this.hasNextPage = res.hasNextPage;
                        this.caseReviewRequests.push(...res.items);
                        this.caseReviewRequestsTotalCount = res.totalCount;
                        this.isLoadingCaseReviewRequests = false;
                    });
                }
                finally {
                    runInAction(() => {
                        this.isLoadingCaseReviewRequests = false;
                    });
                }
            }
        });
        Object.defineProperty(this, "removeCaseReviewRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (requestID) => {
                const idx = this.caseReviewRequests.findIndex((it) => it.id === requestID);
                const request = this.caseReviewRequests[idx];
                if (!request)
                    return;
                try {
                    this.caseReviewRequests.splice(idx, 1);
                    await this.input.api.deleteCaseReviewRequest(requestID);
                }
                catch (err) {
                    this.caseReviewRequests.splice(idx, 0, request);
                    throw err;
                }
                trackerService.track('[Review Queue] case review request deleted', { requestID });
            }
        });
        makeObservable(this);
        this.reset();
        this.filter = new CaseReviewRequestsFilterModel();
        this.sort = new CaseReviewRequestsSortModel();
    }
    reset() {
        this.nextPageCursor = undefined;
        this.hasNextPage = undefined;
        this.caseReviewRequestsTotalCount = 0;
        this.caseReviewRequests = [];
        this.isLoadingCaseReviewRequests = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", Number)
], CaseReviewRequestsModel.prototype, "caseReviewRequestsTotalCount", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CaseReviewRequestsModel.prototype, "caseReviewRequests", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CaseReviewRequestsModel.prototype, "isLoadingCaseReviewRequests", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CaseReviewRequestsModel.prototype, "reset", null);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsModel.prototype, "getCaseReviewRequests", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsModel.prototype, "fetchCaseReviewRequests", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CaseReviewRequestsModel.prototype, "removeCaseReviewRequest", void 0);
