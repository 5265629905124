export class RealTimeError extends Error {
    constructor(input) {
        super(input.message);
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.code = input.code;
        Object.setPrototypeOf(this, RealTimeError.prototype);
    }
    static InvalidInput(input) {
        return new RealTimeError(Object.assign({ code: 'invalid-input' }, input));
    }
    static DataNotFound(input) {
        return new RealTimeError(Object.assign({ code: 'data-not-found' }, input));
    }
}
