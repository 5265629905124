import { NotificationStore } from './NotificationStore';
import { RemoteRequestStore } from './RemoteRequestStore';
export class RootStore {
    constructor() {
        Object.defineProperty(this, "remoteRequestStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notificationStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initStores", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.remoteRequestStore = new RemoteRequestStore();
                this.notificationStore = new NotificationStore();
            }
        });
        this.initStores();
    }
}
