export function getEntitiesPathFromNode(startNode, depth = Infinity) {
    let currentDepth = 0;
    function recursiveTraversal(node, visited) {
        var _a;
        if (visited.has(node)) {
            return;
        }
        visited.add(node);
        (_a = node.portIn) === null || _a === void 0 ? void 0 : _a.links.forEach((l) => {
            // @ts-expect-error
            if (visited.has(l.parent)) {
                visited.add(l);
            }
        });
        if (currentDepth >= depth) {
            return;
        }
        currentDepth++;
        for (const child of node.outputTargets) {
            recursiveTraversal(child, visited);
        }
    }
    const result = new Set();
    recursiveTraversal(startNode, result);
    return [...result.values()];
}
