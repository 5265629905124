export function shortenID(input) {
    return djb2Hash(input).toString();
}
function djb2Hash(str) {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = hash * 33 + str.charCodeAt(i);
    }
    return hash % 100000; // returns a 5-digit number
}
