var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import AvatarGroupMUI from '@mui/material/AvatarGroup';
import React from 'react';
import { getSize } from '../Avatar/utils';
export const AvatarGroup = (_a) => {
    var _b, _c;
    var { additionalAvatar } = _a, rest = __rest(_a, ["additionalAvatar"]);
    // const _color = color ?? getColor(children);
    const _sizeInPx = (_b = additionalAvatar === null || additionalAvatar === void 0 ? void 0 : additionalAvatar.sizeInPx) !== null && _b !== void 0 ? _b : getSize((_c = additionalAvatar === null || additionalAvatar === void 0 ? void 0 : additionalAvatar.size) !== null && _c !== void 0 ? _c : 'regular');
    return (React.createElement(AvatarGroupMUI, Object.assign({ slotProps: {
            additionalAvatar: {
                sx: {
                    width: _sizeInPx,
                    height: _sizeInPx,
                    color: additionalAvatar === null || additionalAvatar === void 0 ? void 0 : additionalAvatar.color,
                    bgcolor: additionalAvatar === null || additionalAvatar === void 0 ? void 0 : additionalAvatar.bgColor,
                },
            },
        } }, rest)));
};
