import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export const PerformanceMetricsWidgetLoader = () => {
    const rowArr = [0, 1, 2];
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 8 },
        React.createElement(Base, { width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" },
            React.createElement(Skeleton, { variant: "circular", height: 180, width: 180 })),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6 }, rowArr.map((num) => (React.createElement(Base, { display: "flex", justifyContent: "space-between", gap: 8, key: num },
            React.createElement(Skeleton, { variant: "text", sx: { flex: 7 } }),
            React.createElement(Skeleton, { variant: "text", sx: { flex: 1 } })))))));
};
