import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { isEqual } from 'lodash';
import React from 'react';
import { Base, ChipV2, Menu, MenuItem, Typography } from 'lib/cortiUI';
export const Sorting = ({ options, value, onChange }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    function handleOptionClick(option) {
        if (isEqual(option, value))
            return;
        onChange(option);
        setMenuAnchorEl(null);
    }
    return (React.createElement(Base, null,
        React.createElement(ChipV2, { onClick: options.length <= 1
                ? undefined
                : ({ currentTarget }) => {
                    setMenuAnchorEl(currentTarget);
                }, label: React.createElement(Base, { display: "grid", gridTemplateColumns: "auto auto", gap: 3 },
                React.createElement(Typography, { variant: "caption", noWrap: true }, value.label),
                options.length > 1 ? (Boolean(menuAnchorEl) ? (React.createElement(KeyboardArrowUpRoundedIcon, { fontSize: "small" })) : (React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }))) : null) }),
        options.length > 1 && (React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl, disablePortal: true }, options.map((option) => (React.createElement(MenuItem, { onClick: () => handleOptionClick(option), selected: isEqual(option, value), gap: 9, key: `${option.value.key}-${option.value.value}` }, option.label)))))));
};
