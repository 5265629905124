import { isEqual } from 'lodash';
import React from 'react';
import { Autocomplete, TextField } from 'lib/cortiUI';
import { TEST_IDS } from './utils';
export const MultipleAutocompleteField = ({ name, options, value, onChange, label, freeSolo, rules, error, errorMessage, }) => {
    const handleChange = (_e, nextValue) => {
        onChange(nextValue.map((val) => (typeof val === 'string' ? { label: val, value: val } : val)));
    };
    return (React.createElement(Autocomplete, { "data-cy": TEST_IDS.multipleAutocomplete, options: options, freeSolo: freeSolo || !options.length, disableClearable: true, multiple: true, clearOnEscape: true, isOptionEqualToValue: (o, v) => isEqual(o, v), value: value, placeholder: label, includeInputInList: true, getOptionLabel: (option) => (typeof option === 'string' ? option : option.label), onChange: handleChange, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { name: name, label: label, placeholder: label, required: Boolean(rules === null || rules === void 0 ? void 0 : rules.required), error: error, helperText: errorMessage }))) }));
};
