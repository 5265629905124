var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export function generateChecklistJSON(checklists) {
    // Create new list of checklists without id and entries without ids and ranks
    const list = checklists.map((_a) => {
        var { id, entries } = _a, rest = __rest(_a, ["id", "entries"]);
        return (Object.assign(Object.assign({}, rest), { entries: entries.map((_a) => {
                var { id, rank } = _a, rest = __rest(_a, ["id", "rank"]);
                return rest;
            }) }));
    });
    return JSON.stringify(list, null, 2);
}
