import { api } from '@corti/lib/coreApiService';
import { history, navigateToApp } from 'browser/navigation';
import { authStore } from 'core/auth';
import { initApiBridge } from './initApiBridge';
let store;
export const browserApi = {
    setApi(name, api) {
        browserApi[name] = api;
    },
    removeApi(name) {
        delete browserApi[name];
    },
    getApi(name) {
        return browserApi[name];
    },
    router: {
        async push({ path }) {
            return history.push(path);
        },
        async replace({ path }) {
            return history.replace(path);
        },
    },
    app: {
        async changeApp({ view }) {
            navigateToApp(view);
        },
        async changeApiHost({ apiHost }) {
            return store.remoteRequestStore.changeApiHost(apiHost);
        },
        async showNotification(input) {
            return store.notificationStore.showNotification(input);
        },
        async closeNotification({ id }) {
            return store.notificationStore.closeNotification(id);
        },
        async getCurrentUser() {
            return {
                id: authStore.currentUser.id,
                name: authStore.currentUser.name,
                organizationID: authStore.currentUser.organization.id,
                extension: authStore.currentUser.extension,
                externalID: authStore.currentUser.externalID,
            };
        },
        async signInWithToken(token, options) {
            await authStore.signInWithToken(token, options);
        },
        async logout() {
            return authStore.logout();
        },
        onLogin(cb) {
            return authStore.onLoaded(cb);
        },
        onLogout(cb) {
            return authStore.onLogout(cb);
        },
    },
    cases: {
        async ensureCustomProperties(input) {
            return api.cases.ensureCustomProperties(input.caseID, input.customProperties);
        },
    },
};
export function init(rootStore) {
    store = rootStore;
    initApiBridge(browserApi);
    installApiOnWindow();
}
function installApiOnWindow() {
    console.log('window API enabled as `window.corti`');
    window.corti = browserApi;
}
