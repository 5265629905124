import { subDays } from '@corti/date';
import { intl } from '@corti/i18n';
export function getListRequestParams(tab, sortingValue, templateID) {
    const sortingParams = mapSortingValueToServerParams(sortingValue, tab, templateID);
    switch (tab) {
        case 'COMPLETED':
            return Object.assign({ task_status: [tab], task_completed_after: subDays(new Date(), 30).toISOString() }, sortingParams);
        case 'PENDING':
            return Object.assign({ task_status: [tab] }, sortingParams);
        default:
            return {};
    }
}
function mapSortingValueToServerParams(sortingValue, taskStatus, templateID) {
    switch (sortingValue.key) {
        case 'age':
            return taskStatus === 'COMPLETED'
                ? {
                    sort_by: 'task_completed_at',
                    sort_direction: sortingValue.value,
                }
                : {
                    sort_by: 'task_created_at',
                    sort_direction: sortingValue.value,
                };
        case 'score':
            return {
                sort_by: 'review_score_percentage',
                sort_direction: sortingValue.value,
                sort_review_template_id: templateID,
            };
        default:
            return {
                sort_by: 'task_created_at',
                sort_direction: sortingValue.value,
            };
    }
}
export function getSortingOptions() {
    return [
        {
            label: intl.t('tasks:sortingOptions.ageDesc', 'Newest first'),
            value: {
                key: 'age',
                value: 'desc',
            },
        },
        {
            label: intl.t('tasks:sortingOptions.ageAsc', 'Oldest first'),
            value: {
                key: 'age',
                value: 'asc',
            },
        },
        {
            label: intl.t('tasks:sortingOptions.scoreDesc', 'Highest Score first'),
            value: {
                key: 'score',
                value: 'desc',
            },
        },
        {
            label: intl.t('tasks:sortingOptions.scoreAsc', 'Lowest Score first'),
            value: {
                key: 'score',
                value: 'asc',
            },
        },
    ];
}
