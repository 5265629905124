import React from 'react';
import { WindowPopup } from '@corti/corti-ui';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ButtonBase, FilePreview, ProgressiveImage } from 'lib/cortiUI';
import { getFocusWithinCss } from '../utils';
export function ImageBlock(props) {
    const { innerRef, mediaAsset, isReadonly, newWindowPositionProps } = props;
    const theme = useTheme();
    const windowRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    function openFullscreen() {
        setIsOpen(true);
        if (windowRef.current) {
            windowRef.current.focus();
        }
    }
    function closeFullscreen() {
        setIsOpen(false);
    }
    return (React.createElement(ButtonBase, { ref: innerRef, onClick: openFullscreen, className: css({
            justifySelf: 'start',
            borderRadius: Number(theme.shape.borderRadius) * 2,
            overflow: 'hidden',
        }, isReadonly ? undefined : getFocusWithinCss({ theme })) },
        React.createElement(ProgressiveImage, { alt: mediaAsset.name, title: mediaAsset.name, width: mediaAsset.imageInfo.width, height: mediaAsset.imageInfo.height, className: css({
                // these settings will make the image scale to fit inside of parent
                // but it will maintain the aspect ratio
                maxWidth: '100%',
                height: 'auto',
                background: theme.palette.background.secondary,
                cursor: 'pointer',
            }), src: mediaAsset.getThumbnailURL({
                maxWidth: devicePixelRatio * 450,
            }), lowQualitySrc: mediaAsset.getThumbnailURL({
                maxWidth: 225,
            }), onClick: () => {
                openFullscreen();
            } }),
        React.createElement(WindowPopup, { isOpen: isOpen, windowRef: windowRef, title: mediaAsset.name, onClose: closeFullscreen, size: { width: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.width, height: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.height }, position: { top: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.top, left: newWindowPositionProps === null || newWindowPositionProps === void 0 ? void 0 : newWindowPositionProps.left } },
            React.createElement(FilePreview, { url: mediaAsset.url }))));
}
