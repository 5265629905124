import { observer } from 'mobx-react';
import React from 'react';
import Calendar from 'react-date-range/dist/components/Calendar';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { convertUtcToZonedTime, convertZonedToUtcTime, getLocale } from '@corti/date';
import { useLazyRef } from '@corti/react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { TimePicker } from './TimePicker';
/**
 *
 * Single date picker component
 */
export const DateSinglePicker = observer(function DateSinglePicker(props) {
    const theme = useTheme();
    const currentDate = useLazyRef(() => convertZonedToUtcTime(new Date()));
    const { onChange, minDate, maxDate = currentDate.current, withTime = false, onTimePickerOpen, onTimePickerClose, selectedDate, } = props;
    function handleDateSelect(value) {
        onChange(convertZonedToUtcTime(value));
    }
    function handleTimeSelect(value) {
        onChange(convertZonedToUtcTime(value));
    }
    return (React.createElement(Base, { width: 330, display: "flex", flexDirection: "column", className: css({
            backgroundColor: theme.palette.background.card,
        }) },
        withTime && (React.createElement(Base, { width: "50%", p: 4, pt: 5 },
            React.createElement(TimePicker, { onOpen: onTimePickerOpen, onClose: onTimePickerClose, value: selectedDate ? convertUtcToZonedTime(selectedDate) : null, onChange: handleTimeSelect }))),
        React.createElement(Calendar, { showDateDisplay: false, onChange: handleDateSelect, date: selectedDate ? convertUtcToZonedTime(selectedDate) : null, locale: getLocale(), maxDate: maxDate ? convertUtcToZonedTime(maxDate) : undefined, minDate: minDate ? convertUtcToZonedTime(minDate) : undefined, classNames: {
                calendarWrapper: css({ backgroundColor: theme.palette.background.secondary }),
                nextButton: css({ backgroundColor: theme.palette.background.secondary }),
                prevButton: css({ backgroundColor: theme.palette.background.secondary }),
                dayNumber: css({ '& span': { color: theme.palette.text.primary } }),
            } })));
});
