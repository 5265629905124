import React from 'react';
import { useTranslation } from '@corti/i18n';
import { unassignTask } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { ContextMenuItem } from 'lib/cortiUI';
export const UnassignUser = ({ onUnassignUser, taskID }) => {
    const { t } = useTranslation('tasks', { keyPrefix: 'task.unassignUser' });
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleUnassignMe() {
        try {
            setIsLoading(true);
            await unassignTask(taskID);
            coreStore.notifications.showNotification({
                message: t('unassignMeSuccess', 'You are unassigned'),
                type: 'success',
            });
            trackerService.track('[Tasks] User is unassigned from the task', {
                taskID: taskID,
            });
            onUnassignUser();
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                message: t('unassignMeError', 'Failed to unassign you'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(ContextMenuItem, { disabled: isLoading, title: t('unassignMe', 'Unassign Me'), onClick: handleUnassignMe }));
};
