var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { observer } from 'mobx-react';
import React from 'react';
import { css } from '@corti/style';
import { Base } from 'lib/cortiUI';
import { useContentBuilderContext } from './contentBuilderCtx';
export const SelectableElement = observer(function SelectableElement(props) {
    var _a;
    const { model, selectColor = '#4b9bd6', preselectColor = '#23a2ff' } = props, rest = __rest(props, ["model", "selectColor", "preselectColor"]);
    const contentBuilderContext = useContentBuilderContext();
    const isSelected = contentBuilderContext.selectedElement === model;
    const isPreselected = contentBuilderContext.preselectedElement === model;
    return (React.createElement(Base, Object.assign({}, rest, { width: (_a = rest.width) !== null && _a !== void 0 ? _a : 1, className: css({
            position: 'relative',
            cursor: 'move',
            borderRadius: 2,
            boxShadow: isPreselected
                ? `0 0 0 2px ${selectColor}`
                : isSelected
                    ? `0 0 0 2px ${preselectColor}`
                    : undefined,
        }, rest.className), onMouseOver: (e) => {
            var _a;
            e.stopPropagation();
            contentBuilderContext.setPreselectedElement(model);
            (_a = props.onMouseOver) === null || _a === void 0 ? void 0 : _a.call(props, e);
        }, onMouseOut: (e) => {
            var _a;
            e.stopPropagation();
            contentBuilderContext.setPreselectedElement(null);
            (_a = props.onMouseOut) === null || _a === void 0 ? void 0 : _a.call(props, e);
        }, onClick: (e) => {
            var _a;
            e.stopPropagation();
            contentBuilderContext.setSelectedElement(model);
            (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
        } }), props.children));
});
