import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import { minBy } from 'lodash';
import React from 'react';
import { addDays, convertUtcToZonedTime, endOfDay, startOfDay, subDays } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Card } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
import { useDataContext } from '../../../activeView/context/dataContext';
import { useGlobalContext } from '../../../context';
import { ChartDataBreakdownSelect } from './ChartDataBreakdownSelect';
import { ChartHeader } from './ChartHeader';
import { ChartLoader } from './ChartLoader';
import { ResizeObserverContainer } from './ResizeObserverContainer';
import { getDataPoints, tooltipLabelFormatter } from './utils';
export const PerformanceChart = () => {
    const CHART_COLOR = '#447AEA';
    const { palette, typography } = useTheme();
    const { t } = useTranslation('performanceApp', { keyPrefix: 'chartPanel' });
    const { isPerformanceViewMetricsLoading, performanceViewMetrics: { timeBasedMetrics }, } = useDataContext();
    const { isPerformanceViewLoading, isPerformanceMetricsFilterLoading, goal, dateRange } = useParametersContext();
    const { isEditMode, isDraft } = useGlobalContext();
    const PERIOD_VARIANTS = [
        {
            type: 'daily',
            text: t('dataBreakdownPicker.day', 'Day'),
        },
        {
            type: '7-days',
            text: t('dataBreakdownPicker.week', 'Week'),
        },
        {
            type: 'monthly',
            text: t('dataBreakdownPicker.month', 'Month'),
            tickInterval: 86400000 * 30,
        },
    ];
    const readOnly = isEditMode || isDraft;
    const [currentPeriodType, setCurrentPeriodType] = React.useState(PERIOD_VARIANTS[0]);
    const aggregatedDataPoints = getDataPoints(timeBasedMetrics, currentPeriodType.type);
    const handleChangePeriodVariant = (variant) => {
        setCurrentPeriodType(variant);
        trackerService.track('[Performance] period changed', {
            period: variant,
        });
    };
    const getChartOptions = () => {
        var _a;
        const options = {
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: typography.fontFamily,
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            time: {
                useUTC: false,
            },
            xAxis: {
                labels: {
                    style: {
                        color: palette.text.primary,
                    },
                },
                type: 'datetime',
                maxPadding: 0,
                crosshair: true,
                // Highchart config requires to expand a date range because Core data has different date range regarding time zones.
                min: startOfDay(subDays(dateRange.startDate, 1)).getTime(),
                max: endOfDay(addDays(dateRange.endDate, 1)).getTime(),
                startOnTick: true,
                tickInterval: currentPeriodType.tickInterval,
                dateTimeLabelFormats: {
                    month: '%b',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                min: (((_a = minBy(aggregatedDataPoints.filter((it) => it.detectionPercentage > 0), 'detectionPercentage')) === null || _a === void 0 ? void 0 : _a.detectionPercentage) || 0) * 100,
                max: 100,
                tickInterval: 20,
                gridLineColor: palette.grey[300],
                labels: {
                    style: {
                        color: palette.text.hint,
                    },
                },
                plotLines: [],
            },
            title: {
                text: '',
            },
            plotOptions: {
                series: {
                    clip: false,
                    enableMouseTracking: !readOnly,
                },
            },
            tooltip: {
                backgroundColor: palette.background.secondary,
                borderColor: palette.background.divider,
                style: {
                    color: palette.text.primary,
                },
                formatter: function () {
                    return tooltipLabelFormatter(currentPeriodType.type, dateRange, this);
                },
            },
            series: [
                {
                    name: t('averageCompliance', 'Average compliance'),
                    type: 'line',
                    color: CHART_COLOR,
                    data: [
                        ...aggregatedDataPoints.map((d) => [
                            convertUtcToZonedTime(d.timePoint).getTime(),
                            d.detectionPercentage ? Math.round(d.detectionPercentage * 100) : undefined,
                        ]),
                    ].sort(),
                },
            ],
        };
        if (goal.enabled) {
            options.yAxis.plotLines.unshift({
                value: goal.value,
                color: palette.text.hint,
                dashStyle: 'Dash',
                width: 1,
                zIndex: 5,
                label: {
                    text: t('target', {
                        count: goal.value,
                        defaultValue: `{{count}}% Goal`,
                    }),
                    style: {
                        color: palette.text.secondary,
                    },
                },
            });
        }
        return options;
    };
    if (isPerformanceViewMetricsLoading ||
        isPerformanceViewLoading ||
        isPerformanceMetricsFilterLoading) {
        return React.createElement(ChartLoader, null);
    }
    return (React.createElement(Card, { height: "100%", p: 6, className: readOnly ? css({ opacity: 0.5, pointerEvents: 'none' }) : undefined },
        React.createElement(ResizeObserverContainer, null, (initContainerSizeObserver) => (React.createElement(React.Fragment, null,
            React.createElement(ChartHeader, { renderRightComponent: () => (React.createElement(ChartDataBreakdownSelect, { value: currentPeriodType, options: PERIOD_VARIANTS, onValueChange: handleChangePeriodVariant, readOnly: readOnly })) }),
            React.createElement(HighchartsReact, { highcharts: Highcharts, options: getChartOptions(), callback: initContainerSizeObserver, immutable: true }))))));
};
