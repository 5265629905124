import { keyBy } from 'lodash';
import { Observer } from '@corti/observer';
import { createRichText } from '@corti/richtext';
import { queueMacrotask } from '@corti/timers';
import { uuid } from '@corti/uuid';
import { GraphEditorModel } from 'lib/graphEditor/GraphEditorModel';
import { ContentBuilderContext, ElementFactory, } from 'lib/graphEditor/contentBuilder';
import { ContentBuilderLibraryComponent } from 'lib/graphEditor/contentBuilder/ContentBuilderLibraryComponent';
import { FactStatic } from 'lib/graphEditor/facts';
import { GraphEditor } from '../GraphEditor';
class Deserializer {
    constructor(value) {
        Object.defineProperty(this, "emitter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Observer()
        });
        // in memory lookup objects
        Object.defineProperty(this, "rawOtherNodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawMetaNodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawMetaBlockProtos", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawViews", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawBlocks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawGateMeta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "rawFacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "restoredNodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "restoredPorts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "linksToRestore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "graphEditor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new GraphEditor()
        });
        Object.defineProperty(this, "graphModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new GraphEditorModel({
                branches: [],
            })
        });
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * Process library - recreate library items when needed or
         * build block element hash tables for easier lookup
         */
        Object.defineProperty(this, "processData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b, _c, _d, _e;
                this.input.data.prototypes.views.forEach((view) => {
                    this.rawViews.set(view.id, view);
                });
                this.input.data.prototypes.blocks.forEach((block) => {
                    this.rawBlocks.set(block.id, block);
                });
                this.input.data.prototypes.blockOptions.forEach((option) => {
                    this.rawOptions.set(option.id, option);
                });
                this.rawMetaNodes = new Map(Object.entries(keyBy((_a = this.input.metadata.editorApp) === null || _a === void 0 ? void 0 : _a.nodes, 'id')));
                this.rawOtherNodes = new Map(Object.entries(keyBy((_b = this.input.metadata.editorApp) === null || _b === void 0 ? void 0 : _b.extraNodes, 'id')));
                this.rawGateMeta = new Map(Object.entries(keyBy((_c = this.input.metadata.editorApp) === null || _c === void 0 ? void 0 : _c.connectorGates, 'id')));
                this.rawMetaBlockProtos = new Map(Object.entries(keyBy((_e = (_d = this.input.metadata.editorApp) === null || _d === void 0 ? void 0 : _d.prototypes) === null || _e === void 0 ? void 0 : _e.blocks, 'id')));
                this.rawFacts = new Map(Object.entries(keyBy(this.input.data.facts, 'id')));
            }
        });
        Object.defineProperty(this, "restoreLibrary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if (!this.input.metadata.editorApp) {
                    return;
                }
                const { library } = this.input.metadata.editorApp;
                (_a = library === null || library === void 0 ? void 0 : library.components) === null || _a === void 0 ? void 0 : _a.forEach(this.restoreLibraryComponent);
            }
        });
        Object.defineProperty(this, "restoreLibraryComponent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                const rawBlock = this.rawBlocks.get(input.elementID);
                if (!rawBlock) {
                    console.warn('library component cannot be restored because source is missing');
                    return;
                }
                const element = this.rawBlockToElement(rawBlock);
                if (!element) {
                    console.warn('library component cannot be restored because element cannot be recreated');
                    return;
                }
                const component = new ContentBuilderLibraryComponent({
                    id: input.elementID,
                    element,
                    name: input.name,
                });
                this.graphModel.library.addComponent(component);
            }
        });
        Object.defineProperty(this, "restoreFactLibrary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                for (const [id, fact] of this.rawFacts) {
                    const f = FactStatic.createFact({
                        id: id,
                        name: fact.name,
                        type: transformFactType(fact.type),
                        valuePublisherConfig: transformValuePublisherConfig(fact.valuePublisherConfig),
                    });
                    this.graphModel.facts.addFact(f);
                }
            }
        });
        Object.defineProperty(this, "restoreChecklists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.input.data.checklists) {
                    return;
                }
                const fixCollectors = (input) => {
                    var _a;
                    return ((_a = input.collectors) !== null && _a !== void 0 ? _a : []).map((c) => {
                        var _a;
                        return ({
                            prototypeID: c.prototypeID,
                            customValueFormat: (_a = c.customValueFormat) !== null && _a !== void 0 ? _a : undefined,
                        });
                    });
                };
                this.input.data.checklists.map((list) => {
                    var _a, _b, _c, _d;
                    this.graphModel.checklists.addChecklist({
                        id: list.id,
                        name: list.name,
                        description: (_a = list.description) !== null && _a !== void 0 ? _a : '',
                        key: (_b = list.key) !== null && _b !== void 0 ? _b : '',
                        visibilityConditionEnabled: (_c = list.visibilityConditionEnabled) !== null && _c !== void 0 ? _c : false,
                        visibilityConditionExpression: (_d = list.visibilityConditionExpression) !== null && _d !== void 0 ? _d : '',
                        collectors: fixCollectors(list),
                        entries: list.entries.map((entry, index) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            return ({
                                id: entry.id,
                                label: (_a = entry.label) !== null && _a !== void 0 ? _a : '',
                                details: (_b = entry.details) !== null && _b !== void 0 ? _b : '',
                                key: (_c = entry.key) !== null && _c !== void 0 ? _c : '',
                                // It doesn't actually matter what the rank is, as long as the order is correct
                                // and the server will have had them sorted by rank before they were serialized
                                rank: index,
                                manualCompletionEnabled: (_d = entry.manualCompletionEnabled) !== null && _d !== void 0 ? _d : true,
                                automaticCompletionEnabled: (_e = entry.automaticCompletionEnabled) !== null && _e !== void 0 ? _e : false,
                                automaticCompletionExpression: (_f = entry.automaticCompletionExpression) !== null && _f !== void 0 ? _f : '',
                                hasInlineNode: (_g = entry.hasInlineNode) !== null && _g !== void 0 ? _g : false,
                                inlineNodeID: (_h = entry.inlineNodeID) !== null && _h !== void 0 ? _h : '',
                                collectors: fixCollectors(entry),
                            });
                        }),
                    });
                });
            }
        });
        Object.defineProperty(this, "restoreBranches", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                const totalNodeCount = this.input.data.branches.reduce((r, b) => r + b.nodes.length, 0);
                let processedNodeCount = 0;
                if (this.input.data.branches.length === 0) {
                    this.graphModel.addBranch({ name: '' });
                    return;
                }
                for (const branch of this.input.data.branches) {
                    await queueMacrotask();
                    this.restoreBranch(branch);
                    processedNodeCount += branch.nodes.length;
                    this.emitter.fireEvent('progress', { total: totalNodeCount, loaded: processedNodeCount });
                }
            }
        });
        Object.defineProperty(this, "restoreBranch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (branchInput) => {
                const branch = this.graphModel.addBranch({
                    name: branchInput.name,
                    keywords: branchInput.keywords,
                });
                branch.id = branchInput.id;
                branchInput.nodes.forEach((n) => {
                    const node = this.restoreViewNode(n);
                    branch.addNode(node);
                    if (n.id === branchInput.startNodeID) {
                        branch.setStartNode(node.id);
                    }
                });
            }
        });
        Object.defineProperty(this, "restoreLinks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.linksToRestore.forEach((it) => {
                    const p1 = this.restoredPorts.get(it.fromPortID);
                    const n2 = this.restoredNodes[it.toNodeID];
                    if (!p1 || !n2 || !n2.portIn) {
                        console.warn('Incomplete link information found');
                        return;
                    }
                    const n1 = p1.parent;
                    if (n1.branchContext !== n2.branchContext) {
                        // this is exceptional situation and should not ever occur
                        // however if link portal metadata is lost
                        // we might need to deal with this situation here and recreate link portals
                        return;
                    }
                    p1.link(n2.portIn);
                });
            }
        });
        Object.defineProperty(this, "restoreOtherNodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.rawOtherNodes.forEach((n) => {
                    const node = this.deserializeOtherNode(n);
                    if (!node) {
                        return;
                    }
                    const branch = this.graphModel.getBranchByID(n.branchID);
                    if (!branch) {
                        console.warn('no branch for node');
                        return;
                    }
                    branch.addNode(node);
                    this.restoredNodes[node.id] = node;
                });
            }
        });
        Object.defineProperty(this, "restoreViewNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (node) => {
                const viewnode = this.graphEditor.nodeFactory.viewNode();
                viewnode.id = node.id;
                const meta = this.rawMetaNodes.get(node.id);
                if (!meta) {
                    console.warn('No metadata for graph node');
                }
                else {
                    viewnode.setPosition(meta.position.x, meta.position.y);
                    viewnode.setPinnedInTriage(meta.pinnedInTriage);
                    viewnode.setFavoriteNode(meta.favoriteNode);
                }
                viewnode.contentBuilderContext.id = node.viewInstance.id;
                const viewEntity = this.rawViews.get(node.viewInstance.viewID);
                if (!viewEntity) {
                    console.warn('view entity not found');
                }
                else {
                    viewnode.setName(viewEntity.name);
                    const elements = viewEntity.blockInstances
                        .map(this.deserializeBlockInstance)
                        .filter((it) => it !== undefined);
                    viewnode.contentBuilderContext.setElements(elements);
                }
                node.gates.forEach((g) => {
                    const logicGate = viewnode.addNewLogicGate();
                    logicGate.id = g.id;
                    logicGate.setExpression(g.expression);
                    this.restoredPorts.set(logicGate.port.id, logicGate.port);
                    if (g.targetBranchNode && g.targetBranchNode.nodeID) {
                        const gateMeta = this.rawGateMeta.get(g.id);
                        if (gateMeta === null || gateMeta === void 0 ? void 0 : gateMeta.linkPortalNodeID) {
                            this.linksToRestore.push({
                                fromPortID: logicGate.port.id,
                                toNodeID: gateMeta.linkPortalNodeID,
                            });
                        }
                        else {
                            this.linksToRestore.push({
                                fromPortID: logicGate.port.id,
                                toNodeID: g.targetBranchNode.nodeID,
                            });
                        }
                    }
                });
                this.restoredNodes[viewnode.id] = viewnode;
                return viewnode;
            }
        });
        Object.defineProperty(this, "deserializeOtherNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (input) => {
                switch (input.type) {
                    case 'linkPortalNode': {
                        const node = this.graphEditor.nodeFactory.linkPortalNode();
                        node.id = input.id;
                        node.setPosition(input.position.x, input.position.y);
                        if (input.target) {
                            node.setTargetNode(input.target.viewNodeID);
                        }
                        return node;
                    }
                    case 'timelineEntryAlertNode': {
                        const node = this.graphEditor.nodeFactory.timelineEntryAlertNode();
                        node.id = input.id;
                        node.setPosition(input.position.x, input.position.y);
                        if (input.timelineEntryDefinitionSource) {
                            node.setSource(input.timelineEntryDefinitionSource);
                        }
                        if (input.customProperties) {
                            node.customProperties.deserialize(input.customProperties);
                        }
                        if (input.targetNodeID) {
                            this.restoredPorts.set(node.portOut.id, node.portOut);
                            this.linksToRestore.push({
                                fromPortID: node.portOut.id,
                                toNodeID: input.targetNodeID,
                            });
                        }
                        return node;
                    }
                    default:
                        console.warn(`unknown node type ${input.type}`);
                        return;
                }
            }
        });
        // Library has to be deserialized beforehand
        Object.defineProperty(this, "deserializeBlockInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (blockInstance) => {
                // recreate component instances
                const libComponent = this.graphModel.library.getComponentByID(blockInstance.blockID);
                if (libComponent) {
                    const instance = libComponent.createInstance();
                    this.restoreBaseInstanceProperties(instance, blockInstance);
                    return instance;
                }
                // recreate inlined blocks
                const block = this.rawBlocks.get(blockInstance.blockID);
                if (!block) {
                    console.warn('block not found');
                    return;
                }
                const baseEl = this.rawBlockToElement(block);
                if (!baseEl) {
                    console.warn(`No deserializer for block type ${block.type}`);
                    return;
                }
                baseEl.id = blockInstance.blockID;
                const instance = ContentBuilderContext.createBlockInstance(baseEl);
                this.restoreBaseInstanceProperties(instance, blockInstance);
                if (baseEl.id === instance.id) {
                    const newid = uuid();
                    console.warn(`block id and instance id are the same ${instance.id}. Instance id will be replaced with ${newid}`);
                    instance.id = newid;
                }
                return instance;
            }
        });
        Object.defineProperty(this, "restoreBaseInstanceProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (instance, serialized) => {
                instance.id = serialized.id;
                if (serialized.customProperties) {
                    instance.customProperties.deserialize(serialized.customProperties);
                }
                if (serialized.triggerOnMount != null) {
                    instance.setTriggerOnMount(serialized.triggerOnMount);
                }
            }
        });
        Object.defineProperty(this, "rawBlockToElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (block) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
                if (block.type === 'DATE_PICKER') {
                    const e = ElementFactory.datePicker();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { label: transformRichText(block.label), isDateTime: block.isDateTime, attributes: Object.assign(Object.assign({}, base.attributes), { placeholder: (_a = block.placeholder) !== null && _a !== void 0 ? _a : '' }), valuePublisher: transformValuePublisherConfig(block.valuePublisherConfig) }));
                    return e;
                }
                if (block.type === 'ACTION') {
                    const e = ElementFactory.action();
                    const base = this.deserializeBaseBlock(block);
                    const meta = this.rawMetaBlockProtos.get(block.id);
                    e.deserialize(Object.assign(Object.assign({}, base), { attributes: Object.assign(Object.assign({}, base.attributes), { content: ((_b = block.text) === null || _b === void 0 ? void 0 : _b.plainText) || '', invisible: (_c = block.invisible) !== null && _c !== void 0 ? _c : false, backgroundColor: (_d = meta === null || meta === void 0 ? void 0 : meta.backgroundColor) !== null && _d !== void 0 ? _d : '' }), triggeredStateExpression: (_f = (_e = block.actionBlock) === null || _e === void 0 ? void 0 : _e.triggeredStateExpression) !== null && _f !== void 0 ? _f : undefined }));
                    return e;
                }
                if (block.type === 'DOCUMENT') {
                    const e = ElementFactory.document();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { attributes: Object.assign(Object.assign({}, base.attributes), { mediaAssetID: (_h = (_g = block.mediaAsset) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : '' }) }));
                    return e;
                }
                if (block.type === 'IMAGE') {
                    const e = ElementFactory.image();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { attributes: Object.assign(Object.assign({}, base.attributes), { mediaAssetID: (_k = (_j = block.mediaAsset) === null || _j === void 0 ? void 0 : _j.id) !== null && _k !== void 0 ? _k : '' }) }));
                    return e;
                }
                if (block.type === 'SELECT') {
                    const element = ElementFactory.select();
                    const base = this.deserializeBaseBlock(block);
                    element.deserialize(Object.assign(Object.assign({}, base), { max: (_l = block.max) !== null && _l !== void 0 ? _l : undefined, label: transformRichText(block.label), isSearchable: (_m = block.isSearchable) !== null && _m !== void 0 ? _m : false, isDropdown: ((_o = block.selectBlock) === null || _o === void 0 ? void 0 : _o.viewType) === 'DROPDOWN', attributes: Object.assign({}, base.attributes), options: [], valuePublisher: transformValuePublisherConfig(block.valuePublisherConfig) }));
                    (_p = block.optionInstances) === null || _p === void 0 ? void 0 : _p.forEach((o) => {
                        const rawOption = this.rawOptions.get(o.optionID);
                        if (!rawOption) {
                            console.warn('no option for option instance');
                            return;
                        }
                        const option = element.addOption({
                            id: o.id,
                        });
                        option.setText(transformRichText(rawOption.text));
                        option.customProperties.deserialize(rawOption.customProperties);
                        option.valuePublisher.deserialize(transformValuePublisherConfig(rawOption.valuePublisherConfig));
                    });
                    return element;
                }
                if (block.type === 'TEXT_INPUT') {
                    const e = ElementFactory.textInput();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { label: transformRichText(block.label), attributes: Object.assign(Object.assign({}, base.attributes), { placeholder: (_q = block.placeholder) !== null && _q !== void 0 ? _q : '' }), valuePublisher: transformValuePublisherConfig(block.valuePublisherConfig) }));
                    return e;
                }
                if (block.type === 'NUMBER_INPUT') {
                    const e = ElementFactory.numberInput();
                    const base = this.deserializeBaseBlock(block);
                    const numberInput = block.numberBlock || {};
                    e.deserialize(Object.assign(Object.assign({}, base), { label: transformRichText(block.label), attributes: Object.assign(Object.assign({}, base.attributes), { placeholder: (_r = block.placeholder) !== null && _r !== void 0 ? _r : '', min: (_s = numberInput.min) !== null && _s !== void 0 ? _s : null, max: (_t = numberInput.max) !== null && _t !== void 0 ? _t : null, step: (_u = numberInput.step) !== null && _u !== void 0 ? _u : 1, precision: (_v = numberInput.precision) !== null && _v !== void 0 ? _v : null }), valuePublisher: transformValuePublisherConfig(block.valuePublisherConfig) }));
                    return e;
                }
                if (block.type === 'PARAGRAPH') {
                    const e = ElementFactory.text();
                    const base = this.deserializeBaseBlock(block);
                    const meta = this.rawMetaBlockProtos.get(block.id);
                    e.deserialize(Object.assign(Object.assign({}, base), { content: transformRichText(block.text), attributes: Object.assign(Object.assign({}, base.attributes), { collapsable: (_w = meta === null || meta === void 0 ? void 0 : meta.collapsable) !== null && _w !== void 0 ? _w : false }) }));
                    return e;
                }
                if (block.type === 'TEXTAREA_INPUT') {
                    const e = ElementFactory.textArea();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { label: transformRichText(block.label), attributes: Object.assign(Object.assign({}, base.attributes), { placeholder: (_x = block.placeholder) !== null && _x !== void 0 ? _x : '' }), valuePublisher: transformValuePublisherConfig(block.valuePublisherConfig) }));
                    return e;
                }
                if (block.type === 'FLOW_VALUE_COLLECTOR') {
                    const e = ElementFactory.flowValueCollector();
                    const base = this.deserializeBaseBlock(block);
                    e.deserialize(Object.assign(Object.assign({}, base), { label: transformRichText(block.label), attributes: Object.assign({}, base.attributes), collectFromCriticalPath: (_y = block.collectFromCriticalPath) !== null && _y !== void 0 ? _y : false }));
                    return e;
                }
            }
        });
        Object.defineProperty(this, "deserializeBaseBlock", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (block) => {
                var _a;
                return {
                    id: block.id,
                    children: [],
                    name: block.name,
                    customProperties: block.customProperties,
                    attributes: {
                        name: (_a = block.name) !== null && _a !== void 0 ? _a : '',
                    },
                };
            }
        });
        Object.defineProperty(this, "onProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cb) => {
                return this.emitter.on('progress', cb);
            }
        });
        Object.defineProperty(this, "process", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                // warmup caches
                this.processData();
                this.graphModel.id = this.input.version.id;
                // restore data
                this.restoreLibrary();
                this.restoreFactLibrary();
                this.restoreChecklists();
                await this.restoreBranches();
                this.restoreOtherNodes();
                this.restoreLinks();
                if (this.input.data.startBranchNode) {
                    if (this.input.data.startBranchNode.nodeID) {
                        const node = this.restoredNodes[this.input.data.startBranchNode.nodeID];
                        if (node) {
                            this.graphModel.setStartNodeID(node.id);
                        }
                    }
                    else {
                        // TODO: handle when branch is set as a starting point
                    }
                }
                return this.graphModel;
            }
        });
        this.input = value;
    }
}
async function deserialize(value, options = {}) {
    const d = new Deserializer(value);
    if (options.onProgress) {
        d.onProgress(options.onProgress);
    }
    return await d.process();
}
function transformRichText(value) {
    var _a;
    if (value && value.richText) {
        return value.richText;
    }
    return createRichText((_a = value === null || value === void 0 ? void 0 : value.plainText) !== null && _a !== void 0 ? _a : '');
}
function transformFactType(input) {
    switch (input) {
        case 'BOOLEAN':
            return 'boolean';
        case 'TEXT':
            return 'string';
    }
}
function transformValuePublisherConfig(input) {
    if (!input) {
        return { collectors: [] };
    }
    return Object.assign(Object.assign({}, input), { collectors: input.collectors.map((c) => {
            var _a;
            return (Object.assign(Object.assign({}, c), { customValueFormat: (_a = c.customValueFormat) !== null && _a !== void 0 ? _a : undefined }));
        }) });
}
export const CoreBackendDeserializer = {
    deserialize,
};
