import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import React from 'react';
import { intl } from '@corti/i18n';
import { CoreApiError } from '@corti/lib/coreApiService';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Button, IconButton, InputAdornment, TextField } from 'lib/cortiUI';
import { passwordFeedbackText } from 'lib/passwordRequirementsTexter';
export function PasswordChangeForm(props) {
    const theme = useTheme();
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [isOldPasswordVisible, setIsOldPasswordVisible] = React.useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);
    const [passwordValidationFeedback, setPasswordValidationFeedback] = React.useState('');
    function isFormValid() {
        return props.isOldPasswordRequired ? oldPassword : true;
    }
    async function submitPassword(e) {
        e.preventDefault();
        const res = await repositories.users.updateUser(props.userId, {
            password: newPassword,
            oldPassword: oldPassword,
        });
        if (CoreApiError.isCoreApiDomainError(res.error)) {
            const errorCodes = res.error.domainErrors.map((e) => e.code);
            setPasswordValidationFeedback(passwordFeedbackText(errorCodes));
        }
        if (!res.error) {
            props.onSuccess();
        }
    }
    return (React.createElement("form", { onSubmit: submitPassword, className: css({ display: 'flex', flexDirection: 'column', gap: theme.space[6] }) },
        props.isOldPasswordRequired && (React.createElement(TextField, { label: intl.t('passwordChangeForm.inputLabel1', 'Old password'), name: "oldPassword", type: isOldPasswordVisible ? 'text' : 'password', placeholder: intl.t('passwordChangeForm.inputPlaceholder1', 'Enter old password'), value: oldPassword, onChange: (e) => setOldPassword(e.target.value), InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { onClick: () => setIsOldPasswordVisible(!isOldPasswordVisible), icon: isOldPasswordVisible ? (React.createElement(VisibilityRoundedIcon, { fontSize: "small" })) : (React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" })), size: "small" }))),
            } })),
        React.createElement(TextField, { "data-cy": "new-password-text-field", label: intl.t('passwordChangeForm.inputLabel2', 'New password'), name: "newPassword", type: isNewPasswordVisible ? 'text' : 'password', placeholder: intl.t('passwordChangeForm.inputPlaceholder2', 'Enter new password'), error: passwordValidationFeedback != '', helperText: passwordValidationFeedback != '' ? passwordValidationFeedback : null, value: newPassword, onChange: (e) => setNewPassword(e.target.value), InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { onClick: () => setIsNewPasswordVisible(!isNewPasswordVisible), icon: isNewPasswordVisible ? (React.createElement(VisibilityRoundedIcon, { fontSize: "small" })) : (React.createElement(VisibilityOffRoundedIcon, { fontSize: "small" })), size: "small" }))),
            } }),
        React.createElement(Button, { "data-cy": "change-password-form-sumbit-btn", color: "primary", disabled: !isFormValid(), type: "submit", variant: "text", className: css({ alignSelf: 'flex-end' }) }, intl.t('save', 'Save'))));
}
