import { Dialog } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useGraphEditorCtx } from 'lib/graphEditor/core/view';
import { MediaLibraryView } from 'lib/graphEditor/media/mediaLibrary/MediaLibraryView';
export const MediaModuleWidget = observer(function MediaModuleWidget({}) {
    var _a;
    const { editor, $editorEl } = useGraphEditorCtx();
    React.useEffect(() => {
        editor.media.provider.refreshMediaAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Dialog, { container: () => $editorEl, maxWidth: "lg", fullWidth: true, transitionDuration: 0, open: editor.media.isMediaPickerOpen, onClose: () => editor.media.closeMediaPicker(), TransitionProps: {
            onEntered: () => {
                editor.media.provider.refreshMediaAssets();
            },
        } },
        React.createElement(MediaLibraryView, { disableSelection: true, mediaAssets: editor.media.provider.mediaAssets, whitelistTypes: (_a = editor.media.mediaPickerOptions) === null || _a === void 0 ? void 0 : _a.whitelistTypes, fileUploadImplementation: editor.media.provider.uploadFile, onMediaClick: (m) => {
                editor.media.mediaAssetPicked(m);
            } })));
});
