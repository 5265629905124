import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export function DataGridLoader() {
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 2, px: 4 }, Array.from({ length: 3 }, (_, index) => (React.createElement(React.Fragment, { key: index },
        React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 1 }),
        React.createElement(Base, { display: "flex", "justify-content": "space-between", gap: 4 },
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 3 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 2 } }),
            React.createElement(Skeleton, { variant: "text", height: 40, sx: { flex: 1 } })))))));
}
