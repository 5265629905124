import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Typography } from 'lib/cortiUI';
import { getValueProgressBarColor } from '../utils';
const DEFAULT_SIZE = 120;
const DEFAULT_STROKE_WIDTH = 20;
export const DetectionsCircularProgressBar = ({ value = 0, size = DEFAULT_SIZE, strokeWidth = DEFAULT_STROKE_WIDTH, target, label, }) => {
    const theme = useTheme();
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 4 },
        label && (React.createElement(Typography, { variant: "body2", color: "default", className: css({
                fontVariantNumeric: 'tabular-nums',
            }) }, label)),
        React.createElement("svg", { width: size, height: size, viewBox: `0 0 ${size} ${size}` },
            React.createElement("circle", { cx: size / 2, cy: size / 2, r: radius, fill: "none", stroke: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300], strokeWidth: strokeWidth }),
            React.createElement("circle", { cx: size / 2, cy: size / 2, r: radius, fill: "none", stroke: getValueProgressBarColor({ value, target, theme }), strokeWidth: strokeWidth, strokeDasharray: `${circumference} ${circumference}`, strokeDashoffset: circumference * (1 - value), transform: `rotate(-90 ${size / 2} ${size / 2})` }))));
};
