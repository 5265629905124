import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { TimelineEntryDefinitionSourceType } from '@corti/lib/coreApiService';
import { Accordion, AccordionDetails, AccordionSummary, Base, Checkbox, FormControlLabel, Typography, } from 'lib/cortiUI';
import { AnnotationsAccordion } from './AnnotationsAccordion';
import { getAnnotationGroupTitle } from './utils';
export const DefinitionsGroup = ({ title, definitionsSubgroups, onDefinitionClick, }) => {
    const renderSubgroup = () => {
        return (React.createElement(React.Fragment, null, Object.entries(definitionsSubgroups).map(([subgroupTitle, items]) => (React.createElement(Base, { mb: 3, key: subgroupTitle },
            React.createElement(AnnotationsAccordion, { title: subgroupTitle }, items.map(({ definition, disabled }) => {
                var _a;
                return (React.createElement(FormControlLabel, { label: definition.text, key: `${definition.title}${(_a = definition.source.entityID) !== null && _a !== void 0 ? _a : ''}${definition.source.entityType}`, control: React.createElement(Checkbox, { checked: !disabled, onChange: (_, checked) => onDefinitionClick(definition, checked) }) }));
            })))))));
    };
    const renderTriageSubgroup = () => {
        return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7, mb: 3, px: 5 }, Object.entries(definitionsSubgroups).map(([subgroupTitle, [{ definition, disabled }]]) => (React.createElement(FormControlLabel, { label: subgroupTitle, key: subgroupTitle, control: React.createElement(Checkbox, { checked: !disabled, onChange: (_, checked) => onDefinitionClick(definition, checked) }) })))));
    };
    return (React.createElement(Base, { mb: 3 },
        React.createElement(Accordion, { "data-cy": `annotation-visibility-group-accordion`, "data-cy-state": title },
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                React.createElement(Typography, { variant: "subtitle2", color: "default" }, getAnnotationGroupTitle(title))),
            React.createElement(AccordionDetails, null, title === TimelineEntryDefinitionSourceType.triageEvent
                ? renderTriageSubgroup()
                : renderSubgroup()))));
};
