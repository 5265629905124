import { isEqual } from 'lodash';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { getSortingOptions } from './utils';
export const useSortingCache = () => {
    const LOCAL_STORAGE_KEY_PREFIX = 'tasks:';
    const LOCAL_STORAGE_ACTIVE_TAB_KEY = `${LOCAL_STORAGE_KEY_PREFIX}activeTab`;
    function persistActiveTab(tab) {
        userSessionStorage.setItem(LOCAL_STORAGE_ACTIVE_TAB_KEY, JSON.stringify(tab));
    }
    function persistSorting(tab, sortingValue) {
        userSessionStorage.setItem(`${LOCAL_STORAGE_KEY_PREFIX}${tab}`, JSON.stringify(sortingValue));
    }
    function getPersistedSorting(tab) {
        var _a;
        const KEY = `${LOCAL_STORAGE_KEY_PREFIX}${tab}`;
        const persistedSorting = JSON.parse((_a = userSessionStorage.getItem(KEY)) !== null && _a !== void 0 ? _a : 'null');
        if (!persistedSorting)
            return persistedSorting;
        return getSortingOptions().find((option) => isEqual(option.value, persistedSorting));
    }
    function getPersistedActiveTab() {
        var _a;
        const persistedTab = JSON.parse((_a = userSessionStorage.getItem(LOCAL_STORAGE_ACTIVE_TAB_KEY)) !== null && _a !== void 0 ? _a : 'null');
        return persistedTab;
    }
    function clearCache() {
        const cacheKeys = [
            `${LOCAL_STORAGE_KEY_PREFIX}COMPLETED`,
            `${LOCAL_STORAGE_KEY_PREFIX}PENDING`,
        ];
        userSessionStorage.removeItem(LOCAL_STORAGE_ACTIVE_TAB_KEY);
        cacheKeys.forEach((key) => userSessionStorage.removeItem(key));
    }
    return {
        persistActiveTab,
        persistSorting,
        getPersistedSorting,
        getPersistedActiveTab,
        clearCache,
    };
};
