var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep, uniqBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { CallTagsValue, CaseReviewRequestStatus, api, getReadableCaseID, } from '@corti/lib/coreApiService';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { createCall } from './CallModel';
import { CallPlayerState } from './CallPlayerState';
import { TimelineEditor } from './TimelineEditor';
export class CaseModel {
    constructor(input, definitions) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "updatedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timelineEntryDefinitions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentTimelineEditor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timelinePreview", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "callPlayerStates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feedbackFormSubmissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reviewRequests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeCall", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.id = input.id;
        this.startedAt = new Date(input.startedAt);
        this.updatedAt = input.updatedAt ? new Date(input.updatedAt) : undefined;
        this.endedAt = input.endedAt ? new Date(input.endedAt) : undefined;
        this.createdBy = input.createdBy;
        this.tags = input.tags;
        this.customProperties = input.customProperties;
        this.timelineEntryDefinitions = definitions;
        runInAction(() => {
            this.calls = observable.array(input.calls.map(createCall), { deep: false });
            this.profiles = observable.array(input.profiles, { deep: false });
            this.feedbackFormSubmissions = observable.array(input.feedbackFormSubmissions, {
                deep: true,
            });
            this.reviewRequests = observable.array(input.reviewRequests.filter((it) => it.status === CaseReviewRequestStatus.pendingReview), {
                deep: false,
            });
            this.currentTimelineEditor = new TimelineEditor(this);
            this.activeCall = this.calls.length ? this.calls[0] : undefined;
            this.callPlayerStates = this.calls.map((c) => {
                const callPlayerState = new CallPlayerState(c);
                callPlayerState.onDurationChange((duration) => {
                    c.callAudioDurationInSeconds = duration;
                });
                return callPlayerState;
            });
        });
    }
    async createReviewRequest(input) {
        try {
            const idx = this.reviewRequests.findIndex((it) => { var _a; return ((_a = it.assignee.user) === null || _a === void 0 ? void 0 : _a.id) === input.assignee.userID; });
            if (idx >= 0)
                return;
            runInAction(() => {
                this.reviewRequests.push({ assignee: input.assignee });
            });
            const res = await api.caseFeedback.createReviewRequest({
                caseID: this.id,
                assignee: {
                    userID: input.assignee.userID,
                    isAnonymised: input.assignee.isAnonymised,
                },
            });
            runInAction(() => {
                this.reviewRequests.splice(idx, 1, {
                    id: res.id,
                    assignee: res.assignee,
                });
            });
            trackerService.track('Reviewer assigned');
        }
        catch (err) {
            this.removeOptimisticReviewRequest(input.assignee.userID);
            throw err;
        }
    }
    async removeUserFromReviewingCase(assigneeID) {
        const idx = this.reviewRequests.findIndex((it) => { var _a; return ((_a = it.assignee.user) === null || _a === void 0 ? void 0 : _a.id) === assigneeID; });
        if (idx < 0)
            return;
        const request = this.reviewRequests[idx];
        if (!request.id)
            return;
        try {
            runInAction(() => {
                this.reviewRequests.splice(idx, 1);
            });
            await api.caseFeedback.removeUserFromReviewingCase(this.id, assigneeID);
        }
        catch (err) {
            runInAction(() => {
                this.reviewRequests.splice(idx, 0, request);
            });
            throw err;
        }
    }
    async acknowledgeFeedbackFormSubmission(submissionID) {
        const idx = this.feedbackFormSubmissions.findIndex((s) => s.id === submissionID);
        const submissionToUpdate = this.feedbackFormSubmissions[idx];
        if (submissionToUpdate) {
            try {
                const res = await api.caseFeedback.acknowledgeFeedbackFormSubmission(submissionID);
                runInAction(() => {
                    submissionToUpdate.dispatcherAcknowledgedAt = res;
                });
                trackerService.track('Feedback form acknowledged');
            }
            catch (err) {
                throw err;
            }
        }
    }
    setTimelineEntries(timelineEntries) {
        this.currentTimelineEditor.populateTimelineEntriesFromServer(timelineEntries);
    }
    removeOptimisticReviewRequest(assigneeID) {
        const idx = this.reviewRequests.findIndex((it) => { var _a; return ((_a = it.assignee.user) === null || _a === void 0 ? void 0 : _a.id) === assigneeID; });
        if (idx >= 0) {
            this.reviewRequests.splice(idx, 1);
        }
    }
    // this is not ideal API, that is why it requires `id`
    // but in order to achieve a proper API case feedback states need to be refactored
    enterTimelinePreview(id) {
        var _a;
        if (((_a = this.timelinePreview) === null || _a === void 0 ? void 0 : _a.id) === id) {
            return;
        }
        const timelineEditor = new TimelineEditor(this);
        // This is to show all current timeline entries in preview mode
        // Ideally this should be done with snapshoting in Backend
        const timelineEntries = cloneDeep(this.currentTimelineEditor.timelineEntries);
        timelineEntries.forEach((it) => {
            timelineEditor.addTimelineEntry(it);
        });
        timelineEditor.lockTimeline();
        this.timelinePreview = {
            id,
            timelineEditor,
        };
    }
    exitTimelinePreview() {
        this.timelinePreview = undefined;
    }
    async submitFeedback(input, suggestedTemplateID) {
        const submission = await api.caseFeedback.submitFeedbackForm(Object.assign(Object.assign({}, input), { caseReviewRequestID: input.caseReviewRequestID }));
        this.feedbackFormSubmissions.push(submission);
        // This is backend business logic, duplicated on the client side
        if (!suggestedTemplateID || suggestedTemplateID === input.templateID) {
            this.removeOptimisticReviewRequest(authStore.currentUserID);
        }
    }
    get assignees() {
        return uniqBy(this.reviewRequests
            .map((it) => {
            if (it.assignee.isAnonymised === false) {
                return it.assignee.user;
            }
        })
            .filter((it) => !!it), 'id');
    }
    setActiveCall(id) {
        this.activeCall = this.calls.find((c) => c.id === id);
    }
    getCall(id) {
        return this.calls.find((c) => c.id === id);
    }
    getIsFeedbackFormSubmissionAcknowledged(id) {
        var _a;
        return !!((_a = this.feedbackFormSubmissions.find((sub) => sub.id === id)) === null || _a === void 0 ? void 0 : _a.dispatcherAcknowledgedAt);
    }
    get allCalls() {
        return this.calls;
    }
    get isActiveCallExcluded() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.activeCall) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.includes(CallTagsValue.performanceMetricsExcluded)) !== null && _c !== void 0 ? _c : false;
    }
    get activeCallPlayerState() {
        if (this.activeCall) {
            return this.callPlayerStates.find((p) => p.call.id === this.activeCall.id);
        }
    }
    get timelineEditor() {
        return this.timelinePreview ? this.timelinePreview.timelineEditor : this.currentTimelineEditor;
    }
    get reviewRequestOfCurrentUser() {
        return this.reviewRequests.find((it) => { var _a; return ((_a = it.assignee.user) === null || _a === void 0 ? void 0 : _a.id) === authStore.currentUserID; });
    }
    get readableCaseID() {
        return getReadableCaseID(this.id);
    }
    get customReadableCaseIdDescriptor() {
        const customPropertyAsCaseID = authStore.organization.clientSettings.customPropertyAsCaseID;
        if (customPropertyAsCaseID) {
            return {
                propName: customPropertyAsCaseID,
                value: this.customProperties[customPropertyAsCaseID] || '-',
            };
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof TimelineEditor !== "undefined" && TimelineEditor) === "function" ? _a : Object)
], CaseModel.prototype, "currentTimelineEditor", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseModel.prototype, "timelinePreview", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CaseModel.prototype, "callPlayerStates", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseModel.prototype, "profiles", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseModel.prototype, "feedbackFormSubmissions", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseModel.prototype, "tags", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], CaseModel.prototype, "reviewRequests", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CaseModel.prototype, "activeCall", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CaseModel.prototype, "setTimelineEntries", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseModel.prototype, "removeOptimisticReviewRequest", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseModel.prototype, "enterTimelinePreview", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CaseModel.prototype, "exitTimelinePreview", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, String]),
    __metadata("design:returntype", Promise)
], CaseModel.prototype, "submitFeedback", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], CaseModel.prototype, "assignees", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CaseModel.prototype, "setActiveCall", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseModel.prototype, "isActiveCallExcluded", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseModel.prototype, "activeCallPlayerState", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseModel.prototype, "timelineEditor", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CaseModel.prototype, "reviewRequestOfCurrentUser", null);
