import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { GraphTraverser } from '@corti/lib/graphs';
import { useTheme } from '@corti/theme';
import { uuid } from '@corti/uuid';
import { Base, Button, IconButton, Typography, UnstyledLink } from 'lib/cortiUI';
import { TriageSessionController, TriageSessionView, } from 'lib/triageSession';
const ANONYMOUS_USER = {
    id: 'anonymous-user',
    name: 'Anonymous',
};
const createEmptySessionState = () => ({
    session: {
        id: uuid(),
        ownerUser: ANONYMOUS_USER,
        createdBy: ANONYMOUS_USER,
        graphVersionID: '',
        caseID: '',
        externalID: '',
        createdAt: new Date().toISOString(),
        startedAt: new Date().toISOString(),
    },
    currentUser: ANONYMOUS_USER,
});
export function TriageStandalone(props) {
    const theme = useTheme();
    const { t } = useTranslation('emergencyTriage');
    const [sessionController, setSessionController] = React.useState();
    const [error, setError] = React.useState();
    React.useEffect(() => {
        void prepareAndLoadModel(props.graph, props.triageSessionSettings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function prepareAndLoadModel(graph, settings) {
        try {
            const traverser = GraphTraverser.create(graph);
            const sessionController = new TriageSessionController({
                graph: traverser,
                state: createEmptySessionState(),
                settings: Object.assign(Object.assign({}, settings), { isTakeoverEnabled: false, isCommentsEnabled: false, isAlertsUIEnabled: false, isAlertsEnabled: false }),
            });
            sessionController.flow.initFlow();
            setSessionController(sessionController);
        }
        catch (err) {
            console.error(err);
            setError(String(err));
        }
    }
    if (error) {
        return React.createElement("div", null, error);
    }
    if (!sessionController) {
        return null;
    }
    return (React.createElement(Base, { height: '100%', display: "flex", flexDirection: 'column', background: theme.palette.background.default },
        React.createElement(Base, { p: 4, background: theme.palette.error.outlinedHoverBackground, borderBottom: `1px solid ${theme.palette.error.outlinedRestingBorder}`, display: "flex", alignItems: 'center' },
            React.createElement(Base, null,
                React.createElement(UnstyledLink, { to: "/login", render: () => React.createElement(IconButton, { icon: React.createElement(ArrowBackIcon, null) }) })),
            React.createElement(Base, { flex: 1, display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'center' },
                React.createElement(Base, { display: 'flex', gap: 4, alignItems: "center" },
                    React.createElement(CloudOffIcon, null),
                    React.createElement(Typography, { variant: "h6" }, t('emergencyOfflineBannerTitle', 'Emergency Offline Mode'))),
                React.createElement(Typography, null, t('emergencyOfflineBannerSubtitle', 'Data will not be saved and integrations will not function'))),
            React.createElement(Base, null,
                React.createElement(Button, { variant: "outlined", color: "primary", onClick: () => {
                        sessionController.reset(createEmptySessionState());
                        sessionController.flow.resetAll();
                        sessionController.flow.initFlow();
                    } }, t('resetSessionBtn', 'Reset session')))),
        React.createElement(TriageSessionView, { controller: sessionController })));
}
