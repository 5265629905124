var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { datadogRum } from '@datadog/browser-rum';
import { omitBy } from 'lodash';
import { action, autorun, makeObservable, observable } from 'mobx';
import { isElectron } from '@corti/electron-utils';
import { config } from 'core/configuration/browser';
import { env } from 'core/environment';
function getEnvironmentID(apiHost) {
    const match = apiHost.match(/api\.(.*).motocorti/);
    return match ? match[1] : undefined;
}
export class TrackerService {
    constructor(segmentKey, datadog) {
        Object.defineProperty(this, "_commonProperties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "dataDogTracking", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                allowSessionRecording: false,
                isEnabled: false,
                applicationId: null,
                clientToken: null,
            }
        });
        makeObservable(this);
        if (segmentKey) {
            initSegment(segmentKey);
        }
        if ((datadog === null || datadog === void 0 ? void 0 : datadog.applicationId) && (datadog === null || datadog === void 0 ? void 0 : datadog.clientToken)) {
            this.setupDatadogInfo({
                applicationId: datadog === null || datadog === void 0 ? void 0 : datadog.applicationId,
                clientToken: datadog === null || datadog === void 0 ? void 0 : datadog.clientToken,
            });
        }
        autorun(() => this.startDataDog());
    }
    startDataDog() {
        const { isEnabled, applicationId, clientToken } = this.dataDogTracking;
        if (!isEnabled && applicationId && clientToken) {
            datadogRum.init({
                applicationId,
                clientToken,
                site: 'datadoghq.eu',
                service: 'engage-&-clear-ux-monitoring',
                version: this.extraMetaProperties.appVersion,
                env: this.extraMetaProperties.environmentID,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackFrustrations: true,
                trackUserInteractions: true,
                trackResources: true,
                excludedActivityUrls: [/segment\.com/],
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask',
            });
            this.setupDatadogInfo({ isEnabled: true });
        }
    }
    setupDatadogInfo(overwrites) {
        this.dataDogTracking = Object.assign(Object.assign({}, this.dataDogTracking), overwrites);
    }
    /**
     * Set some properties that will be sent with every event
     * To modify properties, simply overwrite with new values
     * To remove - provide a value of `undefined`
     */
    setCommonProperties(properties) {
        Object.assign(this._commonProperties, properties);
    }
    identifyUser(user) {
        const _user = Object.assign(Object.assign({ name: user.name, email: user.email, role: user.role ? user.role.id : null, extension: user.extension, externalID: user.externalID, createdAt: user.createdAt }, this.extraMetaProperties), this.commonProperties);
        if (this.isEnabled) {
            window['analytics'].identify(`${user.id}-${this.envID}`, _user);
        }
        if (this.dataDogTracking.isEnabled) {
            datadogRum.setUser(Object.assign({ id: user.id }, _user));
        }
    }
    identifyGroup(organization) {
        if (this.isEnabled) {
            const features = organization.features.map((feature) => feature.featureID);
            window['analytics'].group(`${organization.id}-${this.envID}`, {
                name: organization.name,
                features,
            });
        }
    }
    track(eventName, properties) {
        if (this.isEnabled) {
            window['analytics'].track(eventName, Object.assign(Object.assign(Object.assign({}, properties), this.extraMetaProperties), this.commonProperties));
        }
    }
    trackPageVisit(pageName, properties) {
        if (this.isEnabled) {
            const dataToOverwrite = pageName ? { url: pageName } : undefined;
            window['analytics'].page(pageName, Object.assign(Object.assign(Object.assign(Object.assign({}, properties), dataToOverwrite), this.extraMetaProperties), this.commonProperties));
        }
    }
    reset() {
        if (this.isEnabled) {
            window['analytics'].reset();
        }
        if (this.dataDogTracking.isEnabled) {
            datadogRum.clearUser();
            datadogRum.clearGlobalContext();
        }
    }
    addAction(eventName, context = {}) {
        if (this.dataDogTracking.isEnabled) {
            datadogRum.addAction(eventName, Object.assign(Object.assign(Object.assign({}, context), this.extraMetaProperties), this.commonProperties));
        }
    }
    startSessionReplayRecording() {
        if (this.dataDogTracking.allowSessionRecording && this.dataDogTracking.isEnabled) {
            datadogRum.startSessionReplayRecording();
        }
    }
    stopSessionReplayRecording() {
        if (this.dataDogTracking.allowSessionRecording && this.dataDogTracking.isEnabled) {
            datadogRum.stopSessionReplayRecording();
        }
    }
    get isEnabled() {
        return Boolean(window['analytics'] && this.envID);
    }
    get envID() {
        return getEnvironmentID(config.getConfig().apiHost || '');
    }
    get extraMetaProperties() {
        var _a, _b;
        return {
            appVersion: env.buildInfo.version,
            appPlatform: isElectron() ? 'Electron' : 'Web',
            environmentID: (_a = this.envID) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
            apiHost: (_b = config.getConfig().apiHost) === null || _b === void 0 ? void 0 : _b.toLowerCase(),
        };
    }
    get commonProperties() {
        return omitBy(this._commonProperties, (value) => value === undefined);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TrackerService.prototype, "dataDogTracking", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Partial !== "undefined" && Partial) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TrackerService.prototype, "setupDatadogInfo", null);
// This is a snippet function from segment docs
// DO NOT MODIFY IT
function initSegment(segmentKey) {
    var analytics = (window['analytics'] = window['analytics'] || []);
    if (!analytics.initialize)
        if (analytics.invoked)
            window.console && console.error && console.error('Segment snippet included twice.');
        else {
            analytics.invoked = !0;
            analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
            ];
            analytics.factory = function (t) {
                return function () {
                    var e = Array.prototype.slice.call(arguments);
                    e.unshift(t);
                    analytics.push(e);
                    return analytics;
                };
            };
            for (var t = 0; t < analytics.methods.length; t++) {
                var e = analytics.methods[t];
                analytics[e] = analytics.factory(e);
            }
            analytics.load = function (t, e) {
                var n = document.createElement('script');
                n.type = 'text/javascript';
                n.async = !0;
                n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
                var a = document.getElementsByTagName('script')[0];
                a.parentNode.insertBefore(n, a);
                analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
            analytics.load(segmentKey);
        }
}
