let installed = false;
const NAMESPACE = 'publicapi';
// eslint-disable-next-line import/no-unused-modules -- used in tests
export function unexpose() {
    delete window[NAMESPACE];
    installed = false;
}
export function exposePublicApiOnWindow(api) {
    if (installed) {
        throw new Error('window api should be installed only once');
    }
    // don't want to expose the entire api framework directly to the window object
    // only these selected properties should be available
    const windowApi = {
        subscribeAll: api.subscribeAll.bind(api),
        callMethod: api.callMethod.bind(api),
        getAvailableMethods: api.getAvailableMethods.bind(api),
    };
    window[NAMESPACE] = windowApi;
    installed = true;
}
