/**
 * Creates a syncable resources. Does not modify the original object
 */
export function toSyncable(resource) {
    return Object.assign(Object.assign({}, resource), { __syncstatus: {
            synced: false,
        } });
}
/**
 * Converts syncable resource to original object. Does not modify the input object
 */
export function fromSyncable(syncable) {
    const c = Object.assign({}, syncable);
    // @ts-expect-error
    delete c['__syncstatus'];
    return c;
}
