import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { LoadGraphDebugPage } from './LoadGraphDebugPage';
import { PreloadFromFilePage } from './PreloadFromFilePage';
import { PreloadFromIndexDBPage } from './PreloadFromIndexDBPage';
import { TriageStandalonePage } from './TriageStandalonePage';
export function EmergencyTriagePage() {
    const routeMatch = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: `${routeMatch.path}/loadgraph/indexdb`, component: PreloadFromIndexDBPage }),
        React.createElement(Route, { path: `${routeMatch.path}/loadgraph/file`, component: PreloadFromFilePage }),
        React.createElement(Route, { path: `${routeMatch.path}/loadgraph`, component: LoadGraphDebugPage }),
        React.createElement(Route, { path: `${routeMatch.path}`, component: TriageStandalonePage })));
}
