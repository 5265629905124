import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base, IconButton, Menu, UnstyledLink } from 'lib/cortiUI';
import { Action } from './Action';
export const Actions = ({ user, onEdit, onChangePassword, onResetPassword, onChangeRole, onDeleteUser, }) => {
    const { t } = useTranslation();
    const { currentUserID } = useAuth();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const isCurrentUser = currentUserID === user.id;
    const canCRUD = rbacService.usersAndRolesCRUD();
    const closeMenu = () => {
        setMenuAnchorEl(null);
    };
    const actions = [
        {
            hasPermission: !isCurrentUser && canCRUD,
            testID: 'open-edit-user-form-button',
            title: t('team:editUser', 'Edit user'),
            onClick: () => {
                onEdit(user);
                closeMenu();
            },
        },
        {
            hasPermission: !isCurrentUser && canCRUD,
            testID: 'open-change-role-form-button',
            title: t('team:changeRole', 'Change user role'),
            onClick: () => {
                onChangeRole();
                closeMenu();
            },
        },
        {
            hasPermission: !isCurrentUser && canCRUD,
            testID: 'open-password-change-form-button',
            title: t('team:changePassword', 'Change password'),
            onClick: () => {
                onChangePassword();
                closeMenu();
            },
        },
        {
            hasPermission: !isCurrentUser && canCRUD,
            testID: 'open-reset-password-button',
            title: t('team:resetPassword', 'Reset password'),
            onClick: () => {
                onResetPassword();
                closeMenu();
            },
        },
        {
            hasPermission: !isCurrentUser && canCRUD,
            testID: 'open-user-delete-popover-button',
            title: t('team:deleteUser', 'Delete user'),
            onClick: () => {
                onDeleteUser();
                closeMenu();
            },
        },
    ];
    return (React.createElement(Base, null,
        React.createElement(IconButton, { "data-cy": "open-user-menu-button", onClick: ({ currentTarget }) => setMenuAnchorEl(currentTarget), icon: React.createElement(MoreHorizIcon, null) }),
        React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl },
            isCurrentUser && (React.createElement(UnstyledLink, { to: '/profile', render: () => {
                    return (React.createElement(Base, { "data-cy": "open-current-user-settings-button" },
                        React.createElement(Action, { title: t('team:openCurrentUserSettings', 'Go to settings page') })));
                } })),
            actions.map((action) => {
                return (action.hasPermission && (React.createElement(Base, { key: action.testID, "data-cy": action.testID },
                    React.createElement(Action, { title: action.title, onClick: action.onClick }))));
            }))));
};
