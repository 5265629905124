import React, { useState } from 'react';
import { css } from '@corti/style';
import { Base, Popover } from 'lib/cortiUI';
import { DateSelectContent } from './DateSelectContent';
import { DateSelectInputText } from './DateSelectInputText';
import { GroupOuterShell } from './GroupOuterShell';
export function DateSelect(props) {
    const { disabled, placeholder, selectedDate, onPopupOpen, onPopupClose } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(GroupOuterShell, { className: css({
                height: 30,
                width: 200,
            }), onClick: (e) => {
                onPopupOpen === null || onPopupOpen === void 0 ? void 0 : onPopupOpen();
                setAnchorEl(e.currentTarget);
            } },
            React.createElement(Base, { width: "100%" },
                React.createElement(DateSelectInputText, { selectedDate: selectedDate, placeholder: placeholder }))),
        React.createElement(Popover, { open: !disabled && Boolean(anchorEl), anchorEl: anchorEl, onClose: () => {
                onPopupClose === null || onPopupClose === void 0 ? void 0 : onPopupClose();
                setAnchorEl(null);
            } }, props.selectType === 'range' ? (React.createElement(DateSelectContent, { selectType: props.selectType, selectedDate: props.selectedDate, onChange: props.onChange })) : (React.createElement(DateSelectContent, { selectType: props.selectType, selectedDate: props.selectedDate, onChange: props.onChange })))));
}
