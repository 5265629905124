import React from 'react';
import { SimpleFilter, SoundTouch, getWebAudioNode } from 'soundtouchjs';
import WaveSurfer from 'wavesurfer.js-3';
import { css } from '@corti/style';
import { waveSurferContext } from './WaveSurferContext';
export class WaveSurferWrapper extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "containerElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "wavesurfer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isReady", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        // this is duplicated state, but it's here for performance reasons
        // we need to compare against this value in componentDidUpdate
        Object.defineProperty(this, "currentTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "regionsPluginInitialised", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "bindWavesurferEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.wavesurfer.on('audioprocess', (currentTime) => {
                    this.currentTime = currentTime;
                    this.props.onPositionChange({
                        type: 'audioprocess',
                        currentTime,
                        progress: this.secondsToProgress(currentTime),
                        duration: this.wavesurfer.getDuration(),
                    });
                });
                this.wavesurfer.on('seek', (progress) => {
                    this.currentTime = this.progressToSeconds(progress);
                    this.props.onPositionChange({
                        type: 'seek',
                        progress,
                        currentTime: this.currentTime,
                        duration: this.wavesurfer.getDuration(),
                    });
                });
                this.wavesurfer.on('finish', () => {
                    this.props.onFinish();
                });
                this.wavesurfer.on('error', (errorMessage) => {
                    this.props.onError(new Error(errorMessage));
                });
                this.wavesurfer.on('loading', this.props.onLoading);
            }
        });
        /**
         * This code preserves the pitch of the timeline audio when the playback rate changes.
         * The implementation follows that included in the wavesurfer.js 'stretcher' example on GitHub:
         * https://github.com/katspaugh/wavesurfer.js/blob/master/example/stretcher/app.js
         */
        Object.defineProperty(this, "bindSoundTouch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const st = new SoundTouch(this.wavesurfer.backend.ac.sampleRate);
                const buffer = this.wavesurfer.backend.buffer;
                const channels = buffer.numberOfChannels;
                const l = buffer.getChannelData(0);
                const r = channels > 1 ? buffer.getChannelData(1) : l;
                const length = buffer.length;
                let seekingPos = null;
                let seekingDiff = 0;
                const source = {
                    extract: (target, numFrames, position) => {
                        if (seekingPos != null) {
                            seekingDiff = seekingPos - position;
                            seekingPos = null;
                        }
                        position += seekingDiff;
                        for (let i = 0; i < numFrames; i++) {
                            target[i * 2] = l[i + position];
                            target[i * 2 + 1] = r[i + position];
                        }
                        return Math.min(numFrames, length - position);
                    },
                };
                let soundtouchNode;
                this.wavesurfer.on('play', () => {
                    seekingPos = ~~(this.wavesurfer.backend.getPlayedPercents() * length);
                    st.tempo = this.wavesurfer.getPlaybackRate();
                    if (st.tempo === 1) {
                        this.wavesurfer.backend.disconnectFilters();
                    }
                    else {
                        if (!soundtouchNode) {
                            let filter = new SimpleFilter(source, st);
                            soundtouchNode = getWebAudioNode(this.wavesurfer.backend.ac, filter);
                        }
                        this.wavesurfer.backend.setFilter(soundtouchNode);
                    }
                });
                this.wavesurfer.on('pause', () => {
                    soundtouchNode && soundtouchNode.disconnect();
                });
                this.wavesurfer.on('seek', () => {
                    seekingPos = ~~(this.wavesurfer.backend.getPlayedPercents() * length);
                });
            }
        });
        Object.defineProperty(this, "syncPropsWithWavesurferState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (nextProps, prevProps) => {
                if (nextProps.audio && nextProps.audio !== prevProps.audio) {
                    this.isReady = false;
                    this.wavesurfer.load(nextProps.audio);
                }
                if (this.isReady &&
                    (nextProps.isPlaying !== prevProps.isPlaying ||
                        this.wavesurfer.isPlaying() !== nextProps.isPlaying)) {
                    if (nextProps.isPlaying) {
                        void this.wavesurfer.play();
                    }
                    else {
                        void this.wavesurfer.pause();
                    }
                }
                if (nextProps.volume !== prevProps.volume && nextProps.volume) {
                    this.wavesurfer.setVolume(nextProps.volume);
                }
                if (nextProps.playbackRate !== prevProps.playbackRate && nextProps.playbackRate) {
                    this.wavesurfer.setPlaybackRate(nextProps.playbackRate);
                }
                if (nextProps.zoom !== prevProps.zoom) {
                    this.wavesurfer.zoom(nextProps.zoom);
                }
                if (nextProps.currentTime !== undefined && this.isReady) {
                    if (nextProps.currentTime !== this.currentTime &&
                        nextProps.currentTime !== prevProps.currentTime) {
                        this.seekTo(nextProps.currentTime);
                    }
                }
            }
        });
    }
    componentDidMount() {
        // todo: decide on default and customisation params
        this.wavesurfer = WaveSurfer.create({
            container: this.containerElement,
            cursorColor: this.props.cursorColor,
            progressColor: this.props.progressColor,
            waveColor: this.props.waveColor,
            barWidth: this.props.barWidth,
            barGap: this.props.barGap,
            xhr: this.props.xhr,
            normalize: this.props.normalize,
            responsive: this.props.responsive,
            height: this.props.height,
            backend: this.props.backend,
            // on 4k displays, when using device pixel ration
            // performance is downgraded and waveform is unusable for longer audio files
            pixelRatio: 1,
        });
        // Wavesurfer accepts hideScrollbar as a prop but sets ws wrapper to overflow hidden
        // which doesnt allow scrolling. To allow it we hide scrollbar using browser specific selectors.
        if (this.props.hideScrollbar) {
            this.wavesurfer.drawer.wrapper.classList.add(css({
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }));
        }
        // we need to force re-render so that our plugin wrappers can be rendered and receive wavesurfer instance
        this.forceUpdate();
        if (this.props.audio) {
            this.wavesurfer.load(this.props.audio, this.props.audioPeaks, this.props.preload, this.props.explicitDuration);
        }
        this.wavesurfer.on('ready', () => {
            this.isReady = true;
            this.props.onReady({ duration: this.wavesurfer.getDuration() });
            this.wavesurfer.zoom(this.props.zoom || 0);
            if (this.props.currentTime) {
                this.seekTo(this.props.currentTime);
            }
            this.bindSoundTouch();
        });
        this.bindWavesurferEvents();
    }
    componentDidUpdate(prevProps) {
        this.syncPropsWithWavesurferState(this.props, prevProps);
    }
    componentWillUnmount() {
        this.wavesurfer.destroy();
    }
    // Transform seconds to progress
    secondsToProgress(seconds) {
        return (1 / this.wavesurfer.getDuration()) * seconds;
    }
    // Transform progress to seconds
    progressToSeconds(progress) {
        return progress * this.wavesurfer.getDuration();
    }
    seekTo(seconds) {
        const pos = this.secondsToProgress(seconds);
        this.wavesurfer.seekAndCenter(pos);
    }
    render() {
        return (React.createElement(waveSurferContext.Provider, { value: { wavesurfer: this.wavesurfer } },
            React.createElement("div", { id: "wavesurfer-container", style: { background: this.props.backgroundColor, position: 'relative', zIndex: 0 }, onClick: this.props.onWaveClick, ref: (node) => (this.containerElement = node) }),
            this.wavesurfer && this.containerElement && this.props.children));
    }
}
Object.defineProperty(WaveSurferWrapper, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        onReady: () => { },
        onPositionChange: () => { },
        onFinish: () => { },
        onError: () => { },
        onLoading: () => { },
        progressColor: '#5277c9',
        waveColor: '#4f5768',
        barGap: 1,
        normalize: false,
        responsive: true,
        height: 128,
        hideScrollbar: false,
        backend: 'WebAudio',
    }
});
