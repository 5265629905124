import { simpleIpc } from '@corti/ipc-renderer';
export class InMemoryStorage {
    constructor() {
        Object.defineProperty(this, "_recordedChunks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
    }
    initStream(_screenId, _name) { }
    write(data) {
        this._recordedChunks.push(data);
    }
    flush() {
        // there is no disk to flush
    }
}
let nextId = 0;
export class FileSystemStorage {
    constructor({ path, authToken }) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = nextId++;
        void simpleIpc.callMain('fileSystemStorage:init', { id: this.id, path, authToken });
    }
    initStream(screenId, name) {
        void simpleIpc.callMain('fileSystemStorage:initStream', { id: this.id, screenId, name });
    }
    write(data, screenId) {
        void simpleIpc.callMain('fileSystemStorage:write', { id: this.id, data, screenId });
    }
    flush(screenId) {
        void simpleIpc.callMain('fileSystemStorage:flush', { id: this.id, screenId });
    }
    cleanUp() {
        void simpleIpc.callMain('fileSystemStorage:cleanUp', { id: this.id });
    }
    uploadToServer(metadata) {
        void simpleIpc.callMain('fileSystemStorage:uploadToServer', {
            id: this.id,
            metadata,
        });
    }
}
