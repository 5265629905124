import { RealtimeClient } from './client/realtime';
export * from './client/PrivateStream';
export * from './streams/triageSessionStream';
export * from './streams/globalStream';
/**
 * Typed - domain aware realtime client
 */
export class CoreRealtimeClient extends RealtimeClient {
    triageSessionStream(id) {
        return this.subscribe({ name: 'triage-session', id });
    }
    dummyStream(id = '1') {
        return this.subscribe({ name: 'dummy', id });
    }
}
