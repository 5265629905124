import { gql } from '@apollo/client';
const CasesFilterPredicateValueFragment = gql `
  fragment CasesFilterPredicateValueFragment on CasesFilterPredicateValue {
    objects
    type
  }
`;
const CasesFilterPredicateFragment = gql `
  fragment CasesFilterPredicateFragment on CasesFilterPredicate {
    attributeID
    comparison
    value {
      ...CasesFilterPredicateValueFragment
    }
  }
  ${CasesFilterPredicateValueFragment}
`;
const CasesFilterEntityFragment = gql `
  fragment CasesFilterEntityFragment on CasesFilterEntity {
    id
    junction
    predicates {
      ...CasesFilterPredicateFragment
    }
  }
  ${CasesFilterPredicateFragment}
`;
const CasesFilterFragment = gql `
  fragment CasesFilterFragment on CasesFilter {
    junction
    entities {
      ...CasesFilterEntityFragment
    }
  }
  ${CasesFilterEntityFragment}
`;
const CaseFilterSubscriptionFragment = gql `
  fragment CaseFilterSubscriptionFragment on SavedCaseFilterSubscription {
    id
  }
`;
const SavedCaseFilterFragment = gql `
  fragment SavedCaseFilterFragment on SavedCaseFilter {
    id
    name
    data {
      ...CasesFilterFragment
    }
    visibility
    subscriptions {
      ...CaseFilterSubscriptionFragment
    }
  }
  ${CasesFilterFragment}
  ${CaseFilterSubscriptionFragment}
`;
export const caseFiltersService = (client) => ({
    async getUserCaseFilters(organizationID, userID) {
        const res = await client.query({
            query: gql `
        query userCaseFilters($organizationID: ID!, $userID: ID!) {
          userCaseFilters(organizationID: $organizationID, userID: $userID) {
            ...SavedCaseFilterFragment
          }
        }
        ${SavedCaseFilterFragment}
      `,
            variables: {
                organizationID,
                userID,
            },
            fetchPolicy: 'no-cache',
        });
        return res.data.userCaseFilters;
    },
    async saveCaseFilter(input) {
        const res = await client.mutate({
            mutation: gql `
        mutation saveCaseFilter($input: SavedCaseFilterInput!) {
          saveCaseFilter(input: $input) {
            ...SavedCaseFilterFragment
          }
        }
        ${SavedCaseFilterFragment}
      `,
            variables: {
                input,
            },
        });
        return res.data.saveCaseFilter;
    },
    async deleteCaseFilter(id) {
        await client.mutate({
            mutation: gql `
        mutation deleteCaseFilter($id: String!) {
          deleteCaseFilter(id: $id)
        }
      `,
            variables: {
                id,
            },
        });
    },
    async subscribeToCaseFilter(filterID, userID) {
        const res = await client.mutate({
            mutation: gql `
        mutation ensureCaseFilterSubscription(
          $filterID: String!
          $input: SavedCaseFilterSubscriptionInput!
        ) {
          ensureCaseFilterSubscription(filterID: $filterID, input: $input) {
            id
          }
        }
      `,
            variables: {
                filterID,
                input: {
                    userID,
                },
            },
        });
        return res.data.ensureCaseFilterSubscription;
    },
    async unsubscribeFromCaseFilter(subscriptionID) {
        await client.mutate({
            mutation: gql `
        mutation deleteCaseFilterSubscription($id: String!) {
          deleteCaseFilterSubscription(id: $id)
        }
      `,
            variables: {
                id: subscriptionID,
            },
        });
    },
});
