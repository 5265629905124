import React from 'react';
import { useLazyRef } from '@corti/react';
import { GroupedFocusContextController } from './GroupedFocusContextController';
const Context = React.createContext(undefined);
export function useGroupedFocusContext() {
    return React.useContext(Context);
}
export function GroupedFocusContext(props) {
    const context = useLazyRef(() => new GroupedFocusContextController());
    return React.createElement(Context.Provider, { value: context.current }, props.children);
}
