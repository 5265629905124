import { WsService } from './WsService';
/**
 * @deprecated - use new WebSocket implementation (/events) to inform session about a new phone extension
 *
 */
class PhoneExtensionService {
    constructor() {
        Object.defineProperty(this, "connectionInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    /** @deprecated */
    connect(options) {
        this.connectionInstance = {
            ws: new WsService({
                endpoint: '/call',
                extension: options.phoneExtension,
                token: options.authToken,
            }),
            phoneExtension: options.phoneExtension,
        };
    }
    /** @deprecated */
    disconnect() {
        if (this.connectionInstance) {
            this.connectionInstance.ws.close();
            this.connectionInstance = undefined;
        }
    }
}
export const phoneExtensionServiceStatic = new PhoneExtensionService();
