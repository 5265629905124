var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useRef } from 'react';
import { intl } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, ToggleButton, ToggleButtonGroup } from 'lib/cortiUI';
import { DateSelect, GroupOuterShell, Input, MultipleSelectableList, SingleSelectableList, } from '../components';
const renderers = {
    boolean: BooleanValueRenderer,
    int: NumberValueRenderer,
    string: StringValueRenderer,
    datetime: DatetimeValueRenderer,
    select: SelectValueRenderer,
    multiselect: MultiselectValueRenderer,
    age: AgeValueRenderer,
};
const optionsToSelectItems = (options) => {
    return options.map((o) => {
        return {
            value: o.value,
            text: o.text || o.value,
        };
    });
};
function StringValueRenderer({ values, onChange, inputRef }) {
    return (React.createElement(GroupOuterShell, { className: css({
            width: 200,
            height: 30,
        }) },
        React.createElement(Input, { "data-cy": "filter-group-predicate-string-input", ref: inputRef, value: String(values), placeholder: intl.t('filterBuilder.value', 'Value'), onChange: (e) => onChange(e.target.value.split(',')) })));
}
function DatetimeValueRenderer({ values, onChange, comparison, onPopupClose, onPopupOpen, }) {
    const selectedDate = values.length ? values[0] : undefined;
    function getDateSelectProps() {
        switch (comparison) {
            case 'after':
            case 'before':
                return { selectType: 'single', selectedDate: selectedDate };
            case 'between':
            default:
                return { selectType: 'range', selectedDate: selectedDate };
        }
    }
    function handleDateChange(value) {
        switch (comparison) {
            case 'after':
            case 'before':
                onChange([value]);
                break;
            case 'between':
                onChange([value]);
                break;
        }
    }
    return (React.createElement(DateSelect, Object.assign({}, getDateSelectProps(), { placeholder: intl.t('filterBuilder.value', 'Value'), onChange: handleDateChange, onPopupOpen: onPopupOpen, onPopupClose: onPopupClose })));
}
function BooleanValueRenderer({ values, onChange }) {
    const normalizedValue = values.length
        ? values[0] === true || values[0] === 'true'
            ? 'true'
            : 'false'
        : undefined;
    const buttons = [
        { text: intl.t('true', 'True'), value: 'true' },
        { text: intl.t('false', 'False'), value: 'false' },
    ];
    return (React.createElement(ToggleButtonGroup, { exclusive: true, value: normalizedValue, onChange: (_e, value) => onChange([value]), size: "small", className: css({
            padding: 0,
            height: 30,
        }) }, buttons.map((b, idx) => (React.createElement(ToggleButton, { key: idx, value: b.value, color: normalizedValue === b.value ? 'primary' : undefined }, b.text)))));
}
function NumberValueRenderer({ values, onChange, inputRef }) {
    return (React.createElement(GroupOuterShell, { className: css({
            width: 200,
            height: 30,
        }) },
        React.createElement(Input, { ref: inputRef, type: "number", value: values[0] || '', placeholder: intl.t('filterBuilder.value', 'Value'), onChange: (e) => onChange([e.target.value]) })));
}
function SelectValueRenderer({ values, onChange, options }) {
    return (React.createElement(SingleSelectableList, { items: optionsToSelectItems(options), selectedItemId: values[0], onChange: (option) => onChange([option]), withSearch: true }));
}
function MultiselectValueRenderer({ values, onChange, options }) {
    return (React.createElement(MultipleSelectableList, { items: optionsToSelectItems(options), selectedItemsIds: values, onChange: (option) => onChange(option), withSearch: true }));
}
function AgeValueRenderer({ values, onChange, inputRef }) {
    const options = [
        { value: 'DAYS', text: intl.t('units.days', 'days') },
        { value: 'MONTHS', text: intl.t('units.months', 'months') },
        { value: 'YEARS', text: intl.t('units.years', 'years') },
    ];
    const value = (values[0] || { unit: options[0].value });
    const selectedUnitValue = value.unit;
    return (React.createElement(React.Fragment, null,
        React.createElement(GroupOuterShell, { className: css({
                width: 200,
                height: 30,
            }) },
            React.createElement(Input, { ref: inputRef, mr: 2, type: "number", placeholder: intl.t('filterBuilder.value', 'Value'), value: value.value || '', onChange: (e) => onChange([{ unit: value.unit, value: Number(e.target.value) }]) })),
        React.createElement(SingleSelectableList, { items: options, selectedItemId: options.find((o) => o.value === selectedUnitValue).value, onChange: (option) => {
                onChange([{ unit: option, value: value.value }]);
            } })));
}
export function PredicateValueRenderer(_a) {
    var { type, comparison, options = [], inputRef } = _a, rest = __rest(_a, ["type", "comparison", "options", "inputRef"]);
    const hasValues = useRef(rest.values.length > 0);
    const handleInputRef = useCallback((node) => {
        if (hasValues.current || !node) {
            return;
        }
        node === null || node === void 0 ? void 0 : node.focus();
    }, []);
    const ValueRenderer = renderers[type];
    return (React.createElement(Base, { mt: 4, mb: 6, maxWidth: "100%" },
        React.createElement(ValueRenderer, Object.assign({ inputRef: (i) => {
                handleInputRef(i);
                inputRef && inputRef(i);
            }, options: options, comparison: comparison }, rest))));
}
