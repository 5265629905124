import { Clear } from '@mui/icons-material';
import { observer } from 'mobx-react';
import React from 'react';
import { Autocomplete, Base, Button, Checkbox, FormControlLabel, IconButton, TextField, Typography, } from 'lib/cortiUI';
import { FlowValueCollectorElementModel } from 'lib/graphEditor/contentBuilder/elements/FlowValueCollectorElementModel';
import { useGraphEditorCtx } from '../../core/view/graphEditorContext';
export const ValuePublisherConfig = observer(function ValuePublisherConfig(props) {
    const { valuePublisher } = props;
    const { editor } = useGraphEditorCtx();
    const $inputRef = React.useRef();
    const [tempDestination, setTempDestination] = React.useState();
    const fvcProtosByID = editor.model.elementContext.getPrototypes({
        type: FlowValueCollectorElementModel.typename,
    });
    // exclude items that are already used from the options list
    const options = [...fvcProtosByID.values()].filter((proto) => {
        return !valuePublisher.getConfigByPrototypeID(proto.id);
    });
    React.useEffect(() => {
        var _a;
        if ((tempDestination === null || tempDestination === void 0 ? void 0 : tempDestination.prototypeID) === null) {
            (_a = $inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [tempDestination]);
    return (React.createElement(Base, { display: 'flex', gap: 3, flexDirection: "column", alignItems: "start" },
        React.createElement(Typography, { noSelect: true, fontWeight: "bold" }, 'Value publisher config'),
        React.createElement(Base, { display: 'flex', flexDirection: 'column', gap: 7, width: "100%" },
            valuePublisher.collectors.map((destination, idx) => {
                return (React.createElement(ValuePublisherDestination, { key: idx, destination: destination, selectableOptions: options, resolveProtoByID: (id) => fvcProtosByID.get(id), onDelete: () => {
                        valuePublisher.deleteCollectorConfig(idx);
                    } }));
            }),
            tempDestination && (React.createElement(Base, { display: "flex", gap: 3, alignItems: "center", width: "100%" },
                React.createElement(Autocomplete, { clearOnBlur: true, clearOnEscape: true, options: options, getOptionLabel: (o) => o.readableTitle, sx: { flex: 1 }, value: null, onBlur: () => {
                        if (tempDestination.prototypeID == null) {
                            setTempDestination(null);
                        }
                    }, onChange: (_, value) => {
                        if (value) {
                            valuePublisher.pushNewCollectorConfig({
                                prototypeID: value.id,
                            });
                        }
                        setTempDestination(null);
                    }, renderInput: (params) => (React.createElement(TextField, Object.assign({ inputRef: $inputRef }, params, { label: "Flow value collector" }))) }),
                React.createElement(IconButton, { size: "small", icon: React.createElement(Clear, { fontSize: "small" }) })))),
        React.createElement(Button, { variant: "text", color: "primary", onClick: () => {
                setTempDestination({ prototypeID: null, customText: '' });
            } }, 'Add collector')));
});
const ValuePublisherDestination = observer(function ValuePublisherDestination(props) {
    const { destination, selectableOptions, resolveProtoByID, onDelete } = props;
    const $customFormatTextInputRef = React.useRef();
    const prevCustomFormatTextValue = React.useRef(destination.customValueFormat);
    React.useEffect(() => {
        var _a;
        if (destination.customValueFormat !== undefined &&
            prevCustomFormatTextValue.current === undefined) {
            (_a = $customFormatTextInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        prevCustomFormatTextValue.current = destination.customValueFormat;
    }, [destination.customValueFormat]);
    const value = resolveProtoByID(destination.prototypeID);
    const options = new Set(selectableOptions);
    // ensure selected value exists in the list
    if (value) {
        options.add(value);
    }
    const customValueFormatChecked = destination.customValueFormat !== undefined || !!destination.getSuperConfig();
    return (React.createElement(Base, { width: "100%" },
        React.createElement(Base, { display: "flex", gap: 3, alignItems: "center", width: "100%" },
            React.createElement(Autocomplete, { clearOnBlur: true, clearOnEscape: true, disableClearable: true, fullWidth: true, getOptionLabel: (o) => o.readableTitle, options: [...options], 
                // @ts-expect-error: autocomplete doesn't handle `undefined` nicely in dev mode
                value: value !== null && value !== void 0 ? value : null, onChange: (_, b) => {
                    if (b == null) {
                        onDelete === null || onDelete === void 0 ? void 0 : onDelete();
                        return;
                    }
                    destination.setPrototypeID(b.id);
                }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: "Flow value collector", error: !value, helperText: !value ? 'Collector block does not exist in graph' : undefined, placeholder: !value ? destination.prototypeID : undefined }))) }),
            React.createElement(IconButton, { icon: React.createElement(Clear, null), onClick: onDelete })),
        React.createElement(Base, { mt: 4 },
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disabled: !!destination.getSuperConfig(), checked: customValueFormatChecked, onChange: (e) => {
                        destination.setCustomValueFormat(e.target.checked ? '' : undefined);
                    } }), label: "Add custom text value" }),
            customValueFormatChecked && (React.createElement(Base, { display: "flex", mt: 4, gap: 3, alignItems: "center", width: "100%" },
                React.createElement(TextField, { fullWidth: true, inputRef: $customFormatTextInputRef, label: "Custom text value", onChange: (e) => {
                        destination.setCustomValueFormat(e.target.value);
                    }, value: destination.customValueFormat }),
                React.createElement(Base, { flexShrink: 0, width: '40px' }))))));
});
