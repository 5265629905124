import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, isApolloError, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Observer } from '@corti/observer';
import { CoreApiError } from './errors';
function createErrorFromResponse(error, operation) {
    const extra = {};
    let message = 'message' in error
        ? error.message
        : error.graphQLErrors
            ? error.graphQLErrors.map((err) => err.message).join('\n')
            : '';
    if (error.graphQLErrors) {
        extra.graphqlErrors = error.graphQLErrors;
    }
    if (operation) {
        extra.graphqlOperation = operation;
    }
    if (error.networkError) {
        extra.networkError = {
            message: error.networkError.message,
            name: error.networkError.name,
        };
        if ('response' in error.networkError) {
            extra.networkError.response = error.networkError.response;
            extra.networkError.statusCode = error.networkError.statusCode;
        }
    }
    const { graphQLErrors, networkError } = error;
    if (networkError && /failed to fetch/i.test(networkError.message)) {
        return CoreApiError.Unavailable({
            message,
            extra,
        });
    }
    if (networkError && 'response' in networkError) {
        if (networkError.response.status === 422) {
            // graphql lib on the server uses 422 status code to explicitly tell about this error type
            return CoreApiError.SchemaMismatch({
                message,
                extra,
                devMessage: JSON.stringify(extra, null, 2),
            });
        }
        if (networkError.response.status === 401) {
            return CoreApiError.Unauthorized({
                message,
                extra,
            });
        }
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        // this part should is split into 2: known server errors and unknown server errors.
        // known gql errors have the extensions property populated
        if (graphQLErrors.some((gqe) => 'extensions' in gqe)) {
            return CoreApiError.DomainError({
                domainErrors: graphQLErrors.map((e) => {
                    var _a;
                    return {
                        message: e.message,
                        code: (_a = e.extensions) === null || _a === void 0 ? void 0 : _a.type,
                    };
                }),
            });
        }
    }
    // else: unknown internal error
    return CoreApiError.Internal({
        message,
        extra,
        devMessage: JSON.stringify(extra, null, 2),
    });
}
const cleanTypenameLink = () => new ApolloLink((operation, forward) => {
    if (operation.variables && !operation.variables.file) {
        // eslint-disable-next-line
        operation.variables = omitDeep(operation.variables, '__typename');
    }
    return forward(operation);
});
const DEFAULT_PATHNAME = '/query';
export const createClient = (options) => {
    var _a;
    const observer = new Observer();
    let apiHost = (_a = options.host) !== null && _a !== void 0 ? _a : '';
    const httpLink = new HttpLink();
    // This event is not meant for modifying error object or stop bubbling
    // the only purpose of it is to add some side effects to react to an error
    const errorLink = onError((errorResponse) => {
        // for some reason, apollo client does not provide all the information
        // that we have available here when query/mutation rejects with `ApolloError`
        // so we're forwarding this in a "hacky" way to be able to generate better
        // error reports by including failed operation and graphql errors
        if (errorResponse.networkError) {
            errorResponse.networkError.forwardedExtraInfo = {
                operation: errorResponse.operation,
                graphQLErrors: errorResponse.graphQLErrors,
            };
        }
        const newErr = createErrorFromResponse(errorResponse, errorResponse.operation);
        observer.fireEvent('error', newErr);
    });
    const contextLink = setContext(async (_, prevCtx) => {
        const ctx = Object.assign(Object.assign({}, prevCtx), { headers: Object.assign({}, prevCtx.headers) });
        if (options.apiKey) {
            ctx.headers['x-api-key'] = options.apiKey;
        }
        const existingUrlString = ctx.uri;
        const pathname = existingUrlString ? new URL(existingUrlString).pathname : DEFAULT_PATHNAME;
        ctx.uri = apiHost + pathname;
        return ctx;
    });
    const apollo = new ApolloClient({
        link: from([contextLink, errorLink, cleanTypenameLink(), httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        performanceViewMetricsGroupByCall: {
                            keyArgs: ['filter', 'startTime', 'endTime', 'sources'],
                            merge(prev, incoming, { args }) {
                                if (!prev || !(args === null || args === void 0 ? void 0 : args.offset))
                                    return incoming;
                                if (!incoming || prev.cursor === incoming.cursor)
                                    return prev;
                                const { cursor, performanceMetrics } = incoming;
                                const result = {
                                    cursor,
                                    performanceMetrics: [...prev.performanceMetrics, ...performanceMetrics],
                                };
                                return result;
                            },
                        },
                    },
                },
            },
        }),
        defaultOptions: {
            watchQuery: { fetchPolicy: 'cache-and-network' },
            query: { fetchPolicy: 'no-cache' },
        },
    });
    const client = Object.create(apollo);
    const originalQuery = client.query;
    client.query = async (...params) => {
        try {
            // not sure how to type generics with this method overwrite
            return (await originalQuery.call(client, ...params));
        }
        catch (err) {
            if (err instanceof Error && !isApolloError(err)) {
                throw err;
            }
            throw enhanceError(err);
        }
    };
    const originalMutate = client.mutate;
    client.mutate = async (options) => {
        try {
            // not sure how to type generics with this method overwrite
            return (await originalMutate.call(client, options));
        }
        catch (err) {
            if (err instanceof Error && !isApolloError(err)) {
                throw err;
            }
            throw enhanceError(err);
        }
    };
    client.setHost = (host) => {
        apiHost = host;
    };
    // apollo-boost has a `onError` hook, but it is only available when creating a client
    // thus we have to add our own observer
    client.onError = (cb) => {
        return observer.on('error', cb);
    };
    return client;
};
function enhanceError(err) {
    var _a, _b;
    const forwardedExtraInfo = (_a = err.networkError) === null || _a === void 0 ? void 0 : _a.forwardedExtraInfo;
    if (forwardedExtraInfo) {
        err.graphQLErrors = [...err.graphQLErrors, ...((_b = forwardedExtraInfo.graphQLErrors) !== null && _b !== void 0 ? _b : [])];
    }
    return createErrorFromResponse(err, forwardedExtraInfo === null || forwardedExtraInfo === void 0 ? void 0 : forwardedExtraInfo.operation);
}
const omitDeepArrayWalk = (arr, keyToOmit) => {
    return arr.map((val) => {
        if (Array.isArray(val))
            return omitDeepArrayWalk(val, keyToOmit);
        else if (typeof val === 'object')
            return omitDeep(val, keyToOmit);
        return val;
    });
};
/**
 * Deep traverse an object and omit the provided field.
 * @returns New object (does not modify original but it will not keep prototypes so instanceof checks will fail)
 **/
const omitDeep = (obj, keyToOmit) => {
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach((i) => {
        if (i !== keyToOmit) {
            const val = obj[i];
            if (val instanceof Date)
                newObj[i] = val;
            else if (Array.isArray(val))
                newObj[i] = omitDeepArrayWalk(val, keyToOmit);
            else if (typeof val === 'object' && val !== null)
                newObj[i] = omitDeep(val, keyToOmit);
            else
                newObj[i] = val;
        }
    });
    return newObj;
};
