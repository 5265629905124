var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { action, computed, makeObservable, observable } from 'mobx';
import { BranchModel } from './BranchModel';
export class GraphEditorState {
    constructor(model) {
        Object.defineProperty(this, "model", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: model
        });
        Object.defineProperty(this, "activeBranchID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hoveredEntityIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
        Object.defineProperty(this, "selectedEntityIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set()
        });
        makeObservable(this);
        this.setActiveBranch(model.branches[0].id);
    }
    setActiveBranch(branchID) {
        this.activeBranchID = branchID;
    }
    get activeBranch() {
        return this.model.getBranchByID(this.activeBranchID);
    }
    setHoveredEntityIDs(ids) {
        this.hoveredEntityIDs = new Set(ids);
    }
    addHoveredEntityID(id) {
        this.hoveredEntityIDs.add(id);
    }
    removeHoveredEntity(id) {
        this.hoveredEntityIDs.delete(id);
    }
    unsetHoveredEntities() {
        this.hoveredEntityIDs = new Set();
    }
    setSelectedEntityIDs(ids) {
        this.selectedEntityIDs = ids;
    }
    unsetSelectedEntities() {
        this.selectedEntityIDs = new Set();
    }
    removeSelectedEntities(ids) {
        ids.forEach((id) => this.selectedEntityIDs.delete(id));
    }
    addSelectedEntities(ids) {
        ids.forEach((id) => this.selectedEntityIDs.add(id));
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], GraphEditorState.prototype, "activeBranchID", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
], GraphEditorState.prototype, "hoveredEntityIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Set !== "undefined" && Set) === "function" ? _b : Object)
], GraphEditorState.prototype, "selectedEntityIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "setActiveBranch", null);
__decorate([
    computed,
    __metadata("design:type", typeof (_c = typeof BranchModel !== "undefined" && BranchModel) === "function" ? _c : Object),
    __metadata("design:paramtypes", [])
], GraphEditorState.prototype, "activeBranch", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "setHoveredEntityIDs", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "addHoveredEntityID", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "removeHoveredEntity", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "unsetHoveredEntities", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Set !== "undefined" && Set) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "setSelectedEntityIDs", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "unsetSelectedEntities", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "removeSelectedEntities", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], GraphEditorState.prototype, "addSelectedEntities", null);
