import React from 'react';
import { Base, Skeleton } from 'lib/cortiUI';
export const AssignForReviewPanelLoader = () => {
    const rowArr = [0, 1, 2];
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 7 },
        React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 48 }),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
            React.createElement(Skeleton, { variant: "text" }),
            rowArr.map((num) => (React.createElement(React.Fragment, { key: num },
                React.createElement(Base, { display: "flex", alignItems: "center", gap: 4 },
                    React.createElement(Skeleton, { variant: "rectangular", height: 20, width: 20 }),
                    React.createElement(Skeleton, { variant: "text", height: 40, width: "100%" }))))))));
};
