import React from 'react';
import { Typography } from 'lib/cortiUI';
export const ScoreLabel = ({ value, size = 'small', color = 'inherit', }) => {
    const getLabelVariant = () => {
        switch (size) {
            case 'small':
                return 'body2';
            case 'medium':
                return 'subtitle1';
            case 'large':
                return 'h4';
            default:
                return 'h4';
        }
    };
    return (React.createElement(Typography, { variant: getLabelVariant(), color: color },
        value,
        "%"));
};
