import React from 'react';
import { css } from '@corti/style';
import { Base, Container, Page } from 'lib/cortiUI';
import { DataGrid } from './DataGrid';
import { Header } from './Header';
export const ListView = () => {
    return (React.createElement(Page, null,
        React.createElement(Container, { className: css({
                height: '100%',
            }) },
            React.createElement(Base, { height: "100%", display: "grid", gridTemplateRows: "auto 1fr", gap: 4 },
                React.createElement(Header, null),
                React.createElement(DataGrid, null)))));
};
