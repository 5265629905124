import { BaseGraphEditorLibraryComponent } from 'lib/graphEditor/library/BaseGraphEditorLibraryComponent';
import { ElementSerializer } from '../ElementSerializer';
import { ComponentInstance } from './ComponentInstance';
import { ContentBuilderContext } from './ContentBuilderContext';
export class ContentBuilderLibraryComponent extends BaseGraphEditorLibraryComponent {
    constructor(input = {}) {
        super({ id: input.id, wrappedItem: input.element, name: input.name });
    }
    createInstance() {
        const instance = new ComponentInstance({
            definition: this,
        });
        return instance;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { wrappedItemID: this.wrappedItem.id, wrappedItem: this.wrappedItem });
    }
    dispose() {
        var _a;
        for (const instance of this.instances.values()) {
            const element = instance.explode();
            (_a = instance.context) === null || _a === void 0 ? void 0 : _a.addElement(ContentBuilderContext.createBlockInstance(element));
        }
    }
}
Object.defineProperty(ContentBuilderLibraryComponent, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val, ctx) => {
        const c = new ContentBuilderLibraryComponent({
            id: val.id,
            name: val.name,
        });
        void ctx.getElement(val.wrappedItemID).then((el) => {
            c.setWrappedItem(el);
        });
        return c;
    }
});
ElementSerializer.register(ContentBuilderLibraryComponent, 'ContentBuilderLibraryComponent');
