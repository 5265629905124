import React from 'react';
import { useTranslation } from '@corti/i18n';
import { ContextMenuItem, Separator } from 'lib/cortiUI';
export function NodeContextMenu(props) {
    const { editor, node } = props;
    const { t } = useTranslation('libGraphEditor', { keyPrefix: 'nodeContextMenu' });
    const nodeSpecificMenu = React.createElement(NodeSpecificMenu, Object.assign({}, props));
    return (React.createElement(React.Fragment, null,
        nodeSpecificMenu,
        nodeSpecificMenu ? React.createElement(Separator, { horizontal: true }) : null,
        React.createElement(ContextMenuItem, { title: t('baseNode.copyNode', 'Copy'), onClick: () => {
                editor.dispatch({
                    type: 'canvas.copyNodes',
                    data: { nodeIDs: [node.id] },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('baseNode.deleteNode', 'Delete'), onClick: () => {
                editor.dispatch({
                    type: 'canvas.deleteEntities',
                    data: { ids: [node.id] },
                });
            } })));
}
function NodeSpecificMenu(props) {
    switch (props.node.type) {
        case 'viewnode':
            return React.createElement(ViewnodeContextMenu, Object.assign({}, props, { node: props.node }));
        default:
            return null;
    }
}
function ViewnodeContextMenu(props) {
    const { editor, node } = props;
    const { t } = useTranslation('libGraphEditor', { keyPrefix: 'viewNodeContextMenu' });
    return (React.createElement(React.Fragment, null,
        React.createElement(ContextMenuItem, { title: t('makeGraphStartNode', 'Make graph start node'), disabled: editor.model.startNode === node, onClick: () => {
                editor.dispatch({
                    type: 'canvas.changeGraphStartNode',
                    data: {
                        nodeID: node.id,
                    },
                });
            } }),
        React.createElement(ContextMenuItem, { title: t('makeBranchStartNode', 'Make branch start node'), disabled: node.branchContext.startNode === node, onClick: () => {
                editor.dispatch({
                    type: 'canvas.changeBranchStartNode',
                    data: {
                        nodeID: node.id,
                        branchID: node.branchContext.id,
                    },
                });
            } })));
}
