var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { computed, makeObservable, override } from 'mobx';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { BaseElementModel } from './BaseElementModel';
export class ImageElementModel extends BaseElementModel {
    constructor(params = {}) {
        super(Object.assign(Object.assign({}, params), { attributes: {
                mediaAssetID: {
                    name: 'mediaAssetID',
                    defaultValue: '',
                    type: 'media-asset',
                    options: {
                        whitelistTypes: ['image'],
                    },
                },
            } }));
        makeObservable(this);
        this.type = ImageElementModel.typename;
    }
    get mediaAssetID() {
        return this.attributes.values.mediaAssetID;
    }
    get mediaAsset() {
        var _a;
        return (_a = this.graphEditor) === null || _a === void 0 ? void 0 : _a.media.provider.resolveMediaAsset(this.mediaAssetID);
    }
    setMediaAssetID(value) {
        this.attributes.setAttributeValue('mediaAssetID', value);
    }
    get readableTitle() {
        var _a, _b;
        if (!this.mediaAssetID) {
            return 'image';
        }
        return (_b = (_a = this.mediaAsset) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : `[missing ref ${this.mediaAssetID}]`;
    }
    getEncodableData() {
        return Object.assign({}, super.getEncodableData());
    }
    deserialize(input) {
        super.deserialize(input);
    }
    copy() {
        const i = super.copy();
        i.setMediaAssetID(i.mediaAssetID);
        return i;
    }
}
Object.defineProperty(ImageElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'image-element'
});
Object.defineProperty(ImageElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.imageElement.title', 'Image'),
    })
});
Object.defineProperty(ImageElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new ImageElementModel();
        i.deserialize(val);
        return i;
    }
});
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ImageElementModel.prototype, "mediaAssetID", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ImageElementModel.prototype, "mediaAsset", null);
__decorate([
    computed({ keepAlive: true }),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ImageElementModel.prototype, "readableTitle", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ImageElementModel.prototype, "deserialize", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ImageElementModel.prototype, "copy", null);
ElementSerializer.register(ImageElementModel, 'ImageElementModel');
