import { useCombobox } from 'downshift';
import React from 'react';
import { useMousetrap } from '@corti/react';
import { DropdownContainer, DropdownInput, DropdownMenu, DropdownMenuFallback, DropdownMenuOption, DropdownToggleButton, } from './components';
export function SingleDropdown(props) {
    var _a, _b;
    const inputRef = React.useRef(null);
    const toggleRef = React.useRef(null);
    const [filteredOptions, setFilteredOptions] = React.useState(props.options);
    const d = useCombobox({
        items: filteredOptions,
        defaultHighlightedIndex: undefined,
        selectedItem: (_a = props.selectedOption) !== null && _a !== void 0 ? _a : null,
        itemToString: (it) => { var _a; return (_a = it === null || it === void 0 ? void 0 : it.plainText) !== null && _a !== void 0 ? _a : ''; },
        onHighlightedIndexChange: (changes) => {
            var _a, _b;
            if (changes.highlightedIndex == null) {
                return;
            }
            const deemphasized = filteredOptions[d.highlightedIndex];
            if (deemphasized) {
                (_a = props.onOptionDeemphasize) === null || _a === void 0 ? void 0 : _a.call(props, deemphasized);
            }
            const emphasized = filteredOptions[changes.highlightedIndex];
            if (emphasized) {
                (_b = props.onOptionEmphasize) === null || _b === void 0 ? void 0 : _b.call(props, emphasized);
            }
        },
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick: {
                    setFilteredOptions(props.options);
                    if (selectedItem != null) {
                        props.onOptionSelect(selectedItem);
                    }
                    break;
                }
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                case useCombobox.stateChangeTypes.InputBlur: {
                    setFilteredOptions(props.options);
                    break;
                }
                case useCombobox.stateChangeTypes.InputChange: {
                    if (inputValue != null) {
                        setFilteredOptions(props.filterOptions(inputValue, props.options));
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEscape: {
                    if (state.inputValue === '') {
                        return Object.assign(Object.assign({}, changes), { selectedItem: null, inputValue: '' });
                    }
                    if (state.selectedItem !== null) {
                        return Object.assign(Object.assign({}, changes), { selectedItem: state.selectedItem, inputValue: state.selectedItem.plainText });
                    }
                    return changes;
                }
                case useCombobox.stateChangeTypes.InputBlur: {
                    return Object.assign(Object.assign({}, changes), { inputValue: changes.inputValue === ''
                            ? ''
                            : state.selectedItem
                                ? state.selectedItem.plainText
                                : state.inputValue });
                }
                case useCombobox.stateChangeTypes.ToggleButtonClick: {
                    return Object.assign(Object.assign({}, changes), { highlightedIndex: state.highlightedIndex, isOpen: !state.isOpen });
                }
                default: {
                    return changes;
                }
            }
        },
    });
    useMousetrap({
        keys: 'esc',
        handle: (e) => {
            var _a;
            if (d.isOpen === false) {
                (_a = props.onEscapeDown) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }
        },
        element: (_b = inputRef.current) !== null && _b !== void 0 ? _b : undefined,
    }, [inputRef.current, d]);
    return (React.createElement(DropdownContainer, { onClearButtonClick: (e) => {
            var _a, _b;
            e.stopPropagation();
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = props.onClearButtonClick) === null || _b === void 0 ? void 0 : _b.call(props);
        }, hasValue: props.selectedOption != null, downshiftProps: Object.assign({}, d.getComboboxProps()), isOpen: d.isOpen },
        React.createElement(DropdownToggleButton, { downshiftProps: Object.assign({}, d.getToggleButtonProps({
                ref: (el) => {
                    var _a;
                    (_a = props.menuToggleRef) === null || _a === void 0 ? void 0 : _a.call(props, el);
                    toggleRef.current = el;
                },
                onClick: () => {
                    var _a;
                    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                },
            })) },
            React.createElement(DropdownInput, { downshiftProps: Object.assign({}, d.getInputProps({
                    ref: inputRef,
                    onFocus: () => { var _a; return (_a = props.onInputFocus) === null || _a === void 0 ? void 0 : _a.call(props); },
                    onBlur: () => { var _a; return (_a = props.onInputBlur) === null || _a === void 0 ? void 0 : _a.call(props); },
                })) })),
        React.createElement(DropdownMenu, { isOpen: d.isOpen, anchorEl: toggleRef.current, downshiftProps: Object.assign({}, d.getMenuProps({}, { suppressRefError: true })) }, filteredOptions.length === 0 ? (React.createElement(DropdownMenuFallback, null)) : (filteredOptions.map((it, idx) => {
            var _a;
            const isSelected = ((_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.id) === it.id;
            const isHighlighted = d.highlightedIndex === idx;
            return (React.createElement(DropdownMenuOption, { key: it.id, option: it, isHighlighted: isHighlighted, isSelected: isSelected, downshiftProps: Object.assign({}, d.getItemProps({
                    item: it,
                    index: idx,
                    'aria-selected': isSelected,
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                })) }));
        })))));
}
