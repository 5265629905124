import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEqual } from 'lodash';
import React from 'react';
import { getLocale, isValid } from '@corti/date';
import { useLazyRef } from '@corti/react';
import { TextField } from 'lib/cortiUI/components/TextField';
import { getDateInMs, getTimeInMs, nulifySecMillisec } from './utils';
/**
 * Time picker component
 */
export function TimePicker(props) {
    const { label, onOpen, onClose, onChange, disabled = false } = props;
    const timeInMs = useLazyRef(() => {
        return props.value ? getTimeInMs(nulifySecMillisec(props.value)) : null;
    });
    const [dateInMs, setDateInMs] = React.useState(() => {
        return props.value ? getDateInMs(props.value) : null;
    });
    React.useEffect(() => {
        // Material UI time picker has a bug that resets any provided date to a current date (time is correct)
        // when changing it using text input.
        // To fix that we have to separate time and date and replace wrong date with correct one when this happens.
        if (props.value) {
            setDateInMs(getDateInMs(props.value));
            timeInMs.current = getTimeInMs(nulifySecMillisec(props.value));
        }
        else {
            setDateInMs(null);
            timeInMs.current = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    function handleSelectTime(date) {
        if (date && isValid(date)) {
            timeInMs.current = getTimeInMs(date);
        }
    }
    function handleInputSelectTime(date) {
        handleSelectTime(date);
        handleChange();
    }
    function handleClose() {
        handleChange();
        onClose && onClose();
    }
    function handleChange() {
        const date = getFullDate();
        if (date && !isEqual(props.value, date)) {
            onChange(date);
        }
    }
    function getFullDate() {
        let timestamp = 0;
        if (dateInMs) {
            timestamp += dateInMs;
        }
        if (timeInMs.current) {
            timestamp += timeInMs.current;
        }
        if (timestamp > 0) {
            return new Date(timestamp);
        }
        return null;
    }
    return (React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: getLocale() },
        React.createElement(DesktopTimePicker, { renderInput: (props) => React.createElement(TextField, Object.assign({}, props, { label: label })), disabled: disabled, value: getFullDate(), onChange: (date, value) => {
                if (Boolean(value)) {
                    handleInputSelectTime(date);
                }
                else {
                    handleSelectTime(date);
                }
            }, onOpen: onOpen, onClose: handleClose, ampm: false })));
}
