var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Popover } from 'lib/cortiUI/components/Popover';
import { ColorPickerMenu } from './ColorPickerMenu';
/**
 * Component allows to select predefined color from set or choose custom color
 */
export function ColorPicker(props) {
    const { children, onClose, defaultColorValue, onColorChange, colorValue, disableAutoFocus } = props, colorPickerMenuProps = __rest(props, ["children", "onClose", "defaultColorValue", "onColorChange", "colorValue", "disableAutoFocus"]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState(defaultColorValue !== null && defaultColorValue !== void 0 ? defaultColorValue : '');
    React.useEffect(() => {
        if (colorValue != null) {
            setSelectedColor(colorValue);
        }
    }, [colorValue]);
    React.useEffect(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEl]);
    return (React.createElement(React.Fragment, null,
        children({
            colorValue: selectedColor,
            getTriggerProps: (input) => {
                return {
                    onClick: (e) => {
                        var _a;
                        setAnchorEl(e.currentTarget);
                        (_a = input === null || input === void 0 ? void 0 : input.onClick) === null || _a === void 0 ? void 0 : _a.call(input, e);
                    },
                };
            },
        }),
        React.createElement(Popover, { anchorEl: anchorEl, open: Boolean(anchorEl), disableAutoFocus: disableAutoFocus, onClose: () => {
                setAnchorEl(null);
            } },
            React.createElement(ColorPickerMenu, Object.assign({ onColorClick: () => setAnchorEl(null), defaultColorValue: selectedColor, onColorChange: (c) => {
                    setSelectedColor(c);
                    onColorChange === null || onColorChange === void 0 ? void 0 : onColorChange(c);
                } }, colorPickerMenuProps)))));
}
