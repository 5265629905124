var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, makeObservable, observable } from 'mobx';
import { authStore } from 'core/auth';
export class CasesQuickFilterState {
    constructor() {
        Object.defineProperty(this, "searchValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.resetQuickFilterState();
    }
    resetQuickFilterState() {
        this.searchValue = '';
        this.selectedDateRange = undefined;
    }
    setSearchValue(value) {
        this.searchValue = value;
    }
    setSelectedDateRange(dateRange) {
        this.selectedDateRange = dateRange;
    }
    async setSelectedSearchTypeId(value) {
        await authStore.updateUserProfile({
            settings: {
                caseFilterSearchTypeId: value,
            },
        });
    }
    get selectedSearchTypeId() {
        return authStore.currentUser.settings.caseFilterSearchTypeId;
    }
    getSearchableFiltersFromDescriptor(descriptor) {
        let searchableFilters = [];
        function createSearchableFilters(attributes, filter) {
            attributes.forEach((a) => {
                if (a.comparisons.includes('contains') && a.valueType === 'string') {
                    searchableFilters.push({
                        text: filter.text ? filter.text : a.text,
                        entity: filter.entity,
                        attributeIds: [...filter.attributeIds, a.id],
                        data: [...filter.data, { comparison: 'contains', value: a.id }],
                    });
                }
                a.options.forEach((o) => {
                    if (o.attributes && o.attributes.length) {
                        const filterInput = {
                            text: o.text,
                            entity: filter.entity,
                            attributeIds: [...filter.attributeIds],
                            data: [...filter.data, { comparison: a.comparisons[0], value: o.value }],
                        };
                        createSearchableFilters(o.attributes, filterInput);
                    }
                });
            });
        }
        descriptor === null || descriptor === void 0 ? void 0 : descriptor.entities.forEach((e) => createSearchableFilters(e.attributes, {
            text: null,
            entity: e.id,
            data: [],
            attributeIds: [],
        }));
        return searchableFilters;
    }
    isCaseStartedAtSelected(advancedFilter) {
        const caseEntities = advancedFilter.entities.filter((e) => e.id === 'case');
        const result = caseEntities.some((e) => {
            return e.predicates.some((p) => p.attributeID === 'case.startedAt' && p.comparison === 'between');
        });
        return result;
    }
    getDateRangeFromAdvancedFilter(advancedFilter) {
        let dateRange = undefined;
        if (this.isCaseStartedAtSelected(advancedFilter)) {
            advancedFilter.entities
                .filter((e) => e.id === 'case')
                .forEach((e) => {
                e.predicates.forEach((p) => {
                    if (p.attributeID === 'case.startedAt' && p.comparison === 'between') {
                        dateRange = p.value[0];
                    }
                });
            });
        }
        return dateRange;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], CasesQuickFilterState.prototype, "searchValue", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], CasesQuickFilterState.prototype, "selectedDateRange", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CasesQuickFilterState.prototype, "resetQuickFilterState", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], CasesQuickFilterState.prototype, "setSearchValue", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CasesQuickFilterState.prototype, "setSelectedDateRange", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], CasesQuickFilterState.prototype, "selectedSearchTypeId", null);
