import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { isElectron } from '@corti/electron-utils';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { rbacService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { Base, Breadcrumbs, IconButton, Tooltip, Typography } from 'lib/cortiUI';
import { useBreadcrumbs } from 'lib/router';
import { ScreenRecorderControls } from 'modules/screenRecorder';
export function Topbar({ isFocusMode, showBurgerMenu, onBurgerMenuClick }) {
    const theme = useTheme();
    const { primary, secondary, externalId } = useBreadcrumbs();
    const { goBack, goForward } = useHistory();
    const iconStyles = css({ color: theme.palette.action.active });
    const getGridTemplateColumnsStyle = () => {
        if (isElectron()) {
            return isFocusMode === false ? 'auto 1fr auto' : '1fr auto';
        }
        else {
            return isFocusMode === false ? 'auto 1fr' : '1fr';
        }
    };
    const showExternalId = externalId !== undefined;
    const externalIdTooltip = showExternalId
        ? externalId || intl.t('realtime:noExternalID', 'No External ID')
        : '';
    const externalIdDisplayValue = showExternalId ? `ID ${externalId || '-'}` : '';
    return (React.createElement(Base, { className: css({
            gap: theme.space[6],
            padding: `0px ${theme.space[6]}px`,
            height: '64px',
            display: 'grid',
            gridTemplateColumns: getGridTemplateColumnsStyle(),
            gridArea: 'topbar',
            flexWrap: 'nowrap',
            alignItems: 'center',
            zIndex: theme.zIndex.drawer,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.background.divider}`,
        }) },
        isFocusMode === false && (React.createElement(Base, { width: "100%", display: "grid", gridTemplateColumns: showBurgerMenu ? 'auto auto 1fr' : 'auto 1fr', gap: theme.space[6] },
            showBurgerMenu && React.createElement(IconButton, { icon: React.createElement(MenuRoundedIcon, null), onClick: onBurgerMenuClick }),
            React.createElement(Base, null,
                React.createElement(IconButton, { "data-cy": "global-nav-back-btn", className: iconStyles, icon: React.createElement(ArrowBackIosRounded, { fontSize: "small" }), onClick: goBack }),
                React.createElement(IconButton, { "data-cy": "global-nav-forward-btn", className: iconStyles, icon: React.createElement(ArrowBackIosRounded, { fontSize: "small", className: css({ transform: 'rotate(180deg)' }) }), onClick: goForward })),
            React.createElement(Base, { width: "100%", display: "flex", flexDirection: "row", alignItems: "center", className: css(getScrollerCss({ theme, overflowHidden: 'vertical' })) },
                React.createElement(Breadcrumbs, { "data-cy": "global-nav-breadcrumbs", elements: primary })))),
        secondary.length > 0 && (React.createElement(Base, { width: "100%", height: "100%", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", gap: theme.space[6], overflow: "hidden" },
            React.createElement(Base, { height: "100%", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", className: css(getScrollerCss({ theme, overflowHidden: 'vertical' })) },
                React.createElement(Breadcrumbs, { "data-cy": "global-nav-breadcrumbs", hasFocusContext: true, hasLeadingSeparator: true, focusKey: "n", elements: secondary })),
            showExternalId && (React.createElement(Tooltip, { title: externalIdTooltip },
                React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", className: css({
                        backgroundColor: theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                        padding: '6px 10px',
                        borderRadius: 4,
                    }) },
                    React.createElement(Typography, { variant: "button", color: 'secondaryText', fontSize: 12, noWrap: true }, externalIdDisplayValue)))))),
        isElectron() &&
            authStore.isFeatureEnabled('screen-recorder') &&
            rbacService.appScreenRecorder() && React.createElement(ScreenRecorderControls, null)));
}
