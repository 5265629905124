var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable } from 'mobx';
import { ElementSerializer } from '../ElementSerializer';
import { BaseInstance } from './BaseInstance';
export class BlockInstance extends BaseInstance {
    constructor(props = {}) {
        super();
        Object.defineProperty(this, "wrappedElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        if (props.wrappedElement) {
            this.setWrappedElement(props.wrappedElement);
        }
    }
    get type() {
        return BlockInstance.typename;
    }
    setWrappedElement(element) {
        if (element.parent) {
            throw new Error(`element already has a parent`);
        }
        element.setParent(this);
        this.wrappedElement = element;
    }
    copy() {
        const i = super.copy();
        const wrappedElement = this.wrappedElement.copy();
        i.setWrappedElement(wrappedElement);
        return i;
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { wrappedElementID: this.wrappedElement.id, wrappedElement: this.wrappedElement });
    }
}
Object.defineProperty(BlockInstance, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'block-instance'
});
Object.defineProperty(BlockInstance, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('contentBuilder.blockInstance.title', 'Block instance'),
    })
});
Object.defineProperty(BlockInstance, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (value, ctx) => {
        const entity = new BlockInstance({});
        void ctx.getElement(value.wrappedElementID).then((el) => {
            entity.setWrappedElement(el);
        });
        entity.deserialize(value);
        return entity;
    }
});
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BlockInstance.prototype, "copy", null);
ElementSerializer.register(BlockInstance, 'BlockInstance');
