import { flatten, isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { authStore } from 'core/auth';
import { useAuth } from 'core/auth/browser';
import { Base, Button, Loading, ModalContent, ModalHeader, Separator, Typography, } from 'lib/cortiUI';
import { getDisabledSCVTimelineEntryDefinitionShape } from '../utils';
import { DefinitionsGroup } from './DefinitionsGroup';
import { getDefinitionsGroups } from './utils';
export const AnnotationsVisibilityModalContent = ({ timelineEntryDefinitions, onClose }) => {
    const { t } = useTranslation('annotationManagement');
    const { organization } = useAuth();
    const { palette } = useTheme();
    const [definitionsGroups, setDefinitionsGroups] = React.useState(getDefinitionsGroups(timelineEntryDefinitions, organization.clientSettings.disabledSCVTimelineEntryFilterDefinitions));
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const handleDefinitionClick = (definition, checked) => {
        setDefinitionsGroups((current) => {
            const updatedItemsList = current[definition.source.type][definition.title].map((i) => isEqual(i.definition, definition) ? Object.assign(Object.assign({}, i), { disabled: !checked }) : i);
            const newState = Object.assign({}, current);
            newState[definition.source.type][definition.title] = updatedItemsList;
            return newState;
        });
    };
    const handleApplyChanges = async () => {
        try {
            setIsSubmitting(true);
            const disabledDefinitionsMatrix = [];
            Object.values(definitionsGroups).forEach((group) => {
                const defs = flatten(Object.values(group));
                disabledDefinitionsMatrix.push(defs);
            });
            const disabledDefinitions = flatten(disabledDefinitionsMatrix)
                .filter((def) => def.disabled)
                .map(({ definition }) => getDisabledSCVTimelineEntryDefinitionShape(definition));
            await authStore.updateOrganizationClientSettings({
                disabledSCVTimelineEntryFilterDefinitions: disabledDefinitions,
            });
            trackerService.track('[Annotation management] visibility changed', {
                disabledSCVTimelineEntryFilterDefinitions: disabledDefinitions,
            });
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('successMessages.saved', 'Saved!'),
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('errorMessages.changesNotSaved', "Couldn't save changes."),
            });
        }
        finally {
            setIsSubmitting(false);
            onClose();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, { title: t('visibilityModal.title', 'Set Visibility'), "data-cy": "submit-visibility-modal-header" }),
        React.createElement(Typography, { variant: "body1", color: "default", px: 7 }, t('visibilityModal.description', 'Select the annotations you would like to see in calls.')),
        React.createElement(Typography, { variant: "body1", px: 7, mb: 4 }, t('visibilityModal.secondaryDescription', 'This is a global setting. Any changes will affect annotation visibility for all users, across all calls.')),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(ModalContent, null,
            React.createElement(Typography, { variant: "h6", color: "default", mb: 4 }, t('visibilityModal.existingAnnotations', 'Existing annotations')),
            React.createElement(Base, { mb: 9 }, Object.entries(definitionsGroups).map(([defType, subgroups]) => (React.createElement(DefinitionsGroup, { key: defType, title: defType, definitionsSubgroups: subgroups, onDefinitionClick: handleDefinitionClick }))))),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(Base, { display: "flex", justifyContent: "flex-end", px: 7, pb: 5, pt: 5 },
            React.createElement(Base, { display: "flex" },
                React.createElement(Button, { variant: "text", onClick: onClose, sx: { color: palette.text.hint }, "data-cy": "close-annotations-visibility-btn" }, t('visibilityModal.cancel', 'Cancel')),
                React.createElement(Button, { variant: "text", disabled: isSubmitting, onClick: handleApplyChanges, "data-cy": "set-annotations-visibility-btn" }, isSubmitting ? React.createElement(Loading, { size: "small" }) : t('visibilityModal.apply', 'Apply'))))));
};
