import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
import { Slider, SliderMark } from 'lib/cortiUI/components/Slider';
import { Tooltip } from 'lib/cortiUI/components/Tooltip';
import { Typography } from 'lib/cortiUI/components/Typography';
import { getReviewComplianceStatusData } from '../utils';
export const Bar = ({ value, threshold, partialThreshold, complianceStatus, }) => {
    const { palette } = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.score.bar' });
    const MIN_MARK = { value: 0, label: '' };
    const MAX_MARK = {
        value: 100,
        label: '',
    };
    const marks = [MIN_MARK, ...getThresholdsMarks(), MAX_MARK];
    function getThresholdsMarks() {
        const marks = [];
        if (threshold)
            marks.push({
                value: threshold,
                label: '',
                getTooltip() {
                    return partialThreshold
                        ? t('maxThresholdTooltip', '< {{count}}% Partially Compliant', {
                            count: threshold,
                        })
                        : t('minThresholdTooltip', '< {{count}}% Non-Compliant', {
                            count: threshold,
                        });
                },
            });
        if (partialThreshold)
            marks.push({
                value: partialThreshold,
                label: '',
                getTooltip() {
                    return t('minThresholdTooltip', '< {{count}}% Non-Compliant', {
                        count: partialThreshold,
                    });
                },
            });
        return marks;
    }
    const getHiddenMarkStyles = (markerIndex) => {
        const selectorKey = `&[data-index="${markerIndex}"]`;
        return {
            [selectorKey]: {
                backgroundColor: 'unset',
                height: 0,
                width: 0,
                border: 'unset',
            },
        };
    };
    return (React.createElement(Base, { width: "100%" },
        React.createElement(Slider, { value: value, valueLabelDisplay: "off", slots: {
                thumb: 'span',
                mark: (props) => {
                    var _a, _b;
                    return (React.createElement(Tooltip, { placement: "bottom", title: (_b = (_a = marks[props['data-index']]).getTooltip) === null || _b === void 0 ? void 0 : _b.call(_a) },
                        React.createElement(SliderMark, Object.assign({}, props))));
                },
            }, min: MIN_MARK.value, max: MAX_MARK.value, marks: marks, color: getReviewComplianceStatusData(complianceStatus).color, sx: {
                padding: 0,
                '& .MuiSlider-track': {
                    height: 8,
                },
                '& .MuiSlider-rail': {
                    height: 8,
                    color: palette.grey[300],
                },
                cursor: 'default',
                '& .MuiSlider-mark': Object.assign(Object.assign({ backgroundColor: palette.grey[300], height: 9, width: 9, mixBlendMode: 'multiply', borderRadius: '50%' }, getHiddenMarkStyles(0)), getHiddenMarkStyles(marks.length - 1)),
            } }),
        React.createElement(Base, { display: "flex", justifyContent: "space-between" },
            React.createElement(Typography, { variant: "body2", color: "disabled" },
                MIN_MARK.value,
                "%"),
            React.createElement(Typography, { variant: "body2", color: "disabled" },
                MAX_MARK.value,
                "%"))));
};
