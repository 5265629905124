import React, { useRef } from 'react';
import { useMousetrap } from '@corti/react';
import { Base } from '../Base';
import { FocusContext, FocusElementGroup } from '../FocusContext';
import { BreadcrumbFocusGroupIDContext } from './BreadcrumbFocusGroupIDContext';
export function BreadcrumbFocusWrapper({ focusKey, children, }) {
    const focusControllerRef = useRef(null);
    const containerRef = useRef(null);
    useMousetrap({ keys: focusKey || '', handle: () => { var _a; return void ((_a = focusControllerRef.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement()); } }, []);
    return (React.createElement(Base, { ref: containerRef },
        React.createElement(FocusContext, { controllerRef: focusControllerRef, keyboardEventBindingElement: containerRef.current, keyboardShortcuts: {
                triggerFocusedElement: 'enter',
                focusNextElement: 'right',
                focusPrevElement: 'left',
            } },
            React.createElement(FocusElementGroup, null, ({ groupID }) => (React.createElement(BreadcrumbFocusGroupIDContext.Provider, { value: groupID }, children))))));
}
