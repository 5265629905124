import { from, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import userSessionStorage from '@corti/lib/userSessionStorage';
import { Observer } from '@corti/observer';
const defaultPersistenceType = 'local';
const PERSISTENCE_KEY = 'auth:currentUser';
// This is to invalidate local cache
// If the user object changes, increase this number
const cacheVersion = 6;
function validateCachedData(data) {
    if (typeof data !== 'object')
        return false;
    if (data == null)
        return false;
    if ('cacheVersion' in data) {
        return data.cacheVersion === cacheVersion;
    }
    return false;
}
export class AuthService extends Observer {
    constructor(fetcher, graphqlClient) {
        super();
        Object.defineProperty(this, "fetcher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphqlClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cachedCurrentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "persistenceType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                switch (this.persistenceType) {
                    case 'local':
                        return localStorage;
                    case 'session':
                        return sessionStorage;
                    default:
                        throw new Error('Not supported persistence storage');
                }
            }
        });
        this.fetcher = fetcher;
        this.graphqlClient = graphqlClient;
        this.interceptRequests();
        if (sessionStorage.getItem(PERSISTENCE_KEY)) {
            this.persistenceType = 'session';
        }
        else if (localStorage.getItem(PERSISTENCE_KEY)) {
            this.persistenceType = 'local';
        }
        else {
            this.persistenceType = defaultPersistenceType;
        }
        this.rehydrateUserState();
    }
    interceptRequests() {
        this.fetcher.interceptors.request.use((request) => {
            const authToken = this.getAuthToken();
            if (authToken && request.headers) {
                request.headers.authorization = authToken;
                delete request.headers['x-api-key'];
            }
            return request;
        });
        const cLink = setContext(async (_, prevCtx) => {
            if (this.getAuthToken()) {
                const context = Object.assign(Object.assign({}, prevCtx), { headers: Object.assign({}, prevCtx.headers) });
                delete context.headers['x-api-key'];
                context.headers.authorization = this.getAuthToken();
                return context;
            }
        });
        this.graphqlClient.setLink(from([cLink, this.graphqlClient.link]));
    }
    /**
     * Set persistence type for the current or next user session
     * If there's no user logged in and setPersistence is not called, it will default to 'local'
     */
    setPersistence(persistenceType) {
        const cachedUser = this.getStorage().getItem(PERSISTENCE_KEY);
        this.clearPersistence();
        this.persistenceType = persistenceType;
        if (cachedUser) {
            this.getStorage().setItem(PERSISTENCE_KEY, cachedUser);
        }
    }
    async signInWithEmailAndPassword(arg) {
        const res = await this.fetcher.post(`/auth/sign-in`, {
            email: arg.email,
            password: arg.password,
        });
        this.performAfterAuthenticated(res.data);
        return res.data;
    }
    async signInWithKeycloakToken(providerID, token) {
        if (!this.fetcher.defaults.baseURL)
            return;
        const origin = new URL(this.fetcher.defaults.baseURL).origin;
        const baseURL = `${origin}/core/api/v1.0`; // This is needed to use new Core API
        const res = await this.fetcher.post(`/auth/sign-in-with-keycloak`, {
            provider_id: providerID,
            token,
        }, { baseURL });
        const transformedResponse = {
            token: res.data.token,
            user_id: res.data.user_id,
            organization_id: res.data.organization_id,
        };
        this.performAfterAuthenticated(transformedResponse);
        return transformedResponse;
    }
    async signInWithToken(arg) {
        const res = await this.graphqlClient.query({
            context: {
                headers: {
                    authorization: arg.token,
                },
            },
            query: gql `
        query getUserByToken {
          currentUser {
            id
            organization {
              id
            }
          }
        }
      `,
            variables: { token: arg.token },
        });
        const transformedResponse = {
            token: arg.token,
            user_id: res.data.currentUser.id,
            organization_id: res.data.currentUser.organization.id,
        };
        this.performAfterAuthenticated(transformedResponse);
        return transformedResponse;
    }
    performAfterAuthenticated(response) {
        this.persistUserSession(response);
        this.fireEvent('auth-state-changed', this.user);
    }
    signOut() {
        this.clearAllState();
        this.fireEvent('auth-state-changed', this.user);
        // this is not awaited action to make it not fail in cases where user needs to sign out
        // while having a already expired token (would be rejected by the server)
        // If there is a need for a "invalidate user sessions action" it should be a separate API
        void this.fetcher.post('/auth/sign-out');
    }
    get user() {
        return this.cachedCurrentUser ? Object.assign({}, this.cachedCurrentUser) : undefined;
    }
    getAuthToken() {
        return this.cachedCurrentUser ? this.cachedCurrentUser.token : undefined;
    }
    sendPasswordResetLink(email) {
        const url = '/users/password/reset/email/' + email;
        return this.fetcher({
            method: 'post',
            url,
            data: email,
        });
    }
    async getAvailableProviders() {
        const res = await this.fetcher.get('/user-auth-providers');
        return res.data;
    }
    rehydrateUserState() {
        const persistedData = this.getStorage().getItem(PERSISTENCE_KEY);
        if (!persistedData) {
            return;
        }
        let data;
        try {
            data = JSON.parse(persistedData);
        }
        catch (err) {
            console.warn('auth: unexpected data found in cached user object');
            this.clearAllState();
            return;
        }
        if (!validateCachedData(data)) {
            console.warn('auth: cache version is different when rehydrating user');
            this.clearAllState();
            return;
        }
        this.persistUserSession({
            user_id: data.id,
            token: data.token,
            organization_id: data.orgID,
        });
    }
    persistUserSession(user) {
        this.cachedCurrentUser = {
            id: user.user_id,
            token: user.token,
            orgID: user.organization_id,
        };
        this.getStorage().setItem(PERSISTENCE_KEY, JSON.stringify({
            cacheVersion,
            token: user.token,
            orgID: user.organization_id,
            id: user.user_id,
        }));
    }
    clearAllState() {
        this.cachedCurrentUser = undefined;
        this.clearPersistence();
    }
    clearPersistence() {
        localStorage.removeItem(PERSISTENCE_KEY);
        sessionStorage.removeItem(PERSISTENCE_KEY);
        userSessionStorage.clear();
    }
    onAuthStateChange(cb) {
        return this.on('auth-state-changed', cb);
    }
    onSignedIn(cb) {
        return this.onAuthStateChange((user) => {
            if (user) {
                cb(user);
            }
        });
    }
    onSignedOut(cb) {
        return this.onAuthStateChange((user) => {
            if (!user) {
                cb();
            }
        });
    }
}
