import { isTextNode } from './isTextNode';
export function stringifyRichText(value) {
    if (!value)
        return '';
    return value.nodes.map(nodeToString).join('\n');
}
function nodeToString(node) {
    var _a;
    if (isTextNode(node)) {
        return node.text;
    }
    return (_a = node.children) === null || _a === void 0 ? void 0 : _a.map(nodeToString).join('');
}
