import { DragIndicator } from '@mui/icons-material';
import React from 'react';
import { EditableChecklist, EditableChecklistEntry } from '@corti/corti-ui';
import { useTranslation } from '@corti/i18n';
import { Base, Box, Button, SortableContainer, SortableElement } from 'lib/cortiUI';
import { useChecklistEdit } from './useChecklistEdit';
export function ChecklistEditable() {
    const { t } = useTranslation('libGraphEditor');
    const checklist = useChecklistEdit((state) => state.checklist);
    const selection = useChecklistEdit((state) => state.selection);
    const clearSelection = useChecklistEdit((state) => state.clearSelection);
    const selectEntry = useChecklistEdit((state) => state.selectEntry);
    const reorderEntries = useChecklistEdit((state) => state.reorderEntries);
    const updateChecklist = useChecklistEdit((state) => state.updateChecklist);
    const updateEntry = useChecklistEdit((state) => state.updateEntry);
    const deleteEntry = useChecklistEdit((state) => state.deleteEntry);
    const addEntry = useChecklistEdit((state) => state.addEntry);
    const handleChange = (evt) => updateChecklist(evt.target.name, evt.target.value);
    const handleEntryChange = (index) => (evt) => updateEntry(index, evt.target.name, evt.target.value);
    const handleDelete = (index) => () => deleteEntry(index);
    const handleAdd = () => addEntry();
    return (React.createElement(EditableChecklist, { name: checklist.name, namePlaceholder: t('checklist.editor.checklistNamePlaceholder', 'Add a name'), description: checklist.description, descriptionPlaceholder: t('checklist.editor.checklistDescriptionPlaceholder', 'Add an optional description'), entries: checklist.entries.length, isSelected: selection === null, handleSelect: clearSelection, handleChange: handleChange },
        React.createElement(Box, { display: "flex", alignItems: "stretch", p: 2, overflow: "auto" },
            React.createElement(SortableContainer, { handleDragEnd: reorderEntries, handleDragStart: selectEntry }, ({ containerProps, placeholder }) => (React.createElement(Box, Object.assign({ display: "flex", alignItems: "stretch", p: 2, overflow: "auto" }, containerProps),
                checklist.entries.map((entry, index) => (React.createElement(SortableElement, { key: entry.id, index: index }, ({ itemProps, handleProps }) => (React.createElement(React.Fragment, null,
                    React.createElement(EditableChecklistEntry, Object.assign({ label: entry.label, labelPlaceholder: t('checklist.editor.entryLabelPlaceholder', 'Add a label'), details: entry.details, detailsPlaceholder: t('checklist.editor.entryDetailsPlaceholder', 'Add optional details'), manual: entry.manualCompletionEnabled, automatic: entry.automaticCompletionEnabled, isSelected: selection === index, handleSelect: () => selectEntry(index), handleChange: handleEntryChange(index), handleDelete: handleDelete(index) }, itemProps),
                        React.createElement(Base, Object.assign({ display: "flex" }, handleProps),
                            React.createElement(DragIndicator, null)))))))),
                placeholder))),
            React.createElement(Button, { color: "primary", variant: "outlined", onClick: handleAdd, sx: { m: 4 } }, t('checklist.editor.addEntry', 'Add entry')))));
}
