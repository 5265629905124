import { formatMessage, } from '@corti/logger-message-formatter';
export class ConsoleTransport {
    send(msg) {
        const m = formatMessage(msg);
        switch (msg.severity) {
            case 'DEBUG':
                return console.debug(m);
            case 'INFO':
                return console.info(m);
            case 'WARN':
                return console.warn(m);
            case 'ERROR':
                return console.error(m);
        }
    }
}
