import { ExpressionEvaluator as ExpressionEvaluatorRaw, } from '@corti/lib/graphs';
/**
 * This module is responsible for conversion of flow data
 * into environments that can then be passed to expression evaluator
 * */
export const ExpressionEvaluator = {
    evaluate,
    evaluateText,
    createEnvFromFlowValues,
};
function evaluate(input) {
    return Boolean(ExpressionEvaluatorRaw.compile(input.expression).eval(input.environment));
}
function evaluateText(input) {
    return ExpressionEvaluatorRaw.compile(input.expression).evalText(input.environment);
}
function createEnvFromFlowValues(input) {
    const env = {};
    const parsedBlockValues = parseBlockValues(input.blockValues, input.graph, input.evaluateToText);
    const expandedBlockValues = expandWithSelectBlockValues(parsedBlockValues, input.evaluateToText);
    expandedBlockValues.forEach((block) => {
        const { name, value } = blockValueToVarAssignment(block);
        env[name] = value;
    });
    input.factValues.forEach((fact) => {
        env[`fact:${fact.factID}`] = fact.value;
    });
    return env;
}
function blockValueToVarAssignment(submission) {
    return {
        name: submission.identifier.optionID
            ? `option:${submission.identifier.optionID}`
            : `block:${submission.identifier.blockPrototypeID}`,
        value: submission.value,
    };
}
/**
 * Our block value model is setup in a way that every individual option is assigned a value of `true` or `false`
 * while the parent select block does not hold any value.
 * This function expands array of values with computed select block values
 *
 *
 * @param evaluateToText - if true, the evaluated value of the expression for the select block will be the text of the selected option(s)
 *
 */
function expandWithSelectBlockValues(values, evaluateToText) {
    let selectBlockValues = new Map();
    values.forEach((v) => {
        var _a;
        if (v.identifier.optionID) {
            if (!selectBlockValues.has(v.identifier.blockPrototypeID)) {
                selectBlockValues.set(v.identifier.blockPrototypeID, []);
            }
            const vals = selectBlockValues.get(v.identifier.blockPrototypeID);
            if (v.value === true) {
                vals === null || vals === void 0 ? void 0 : vals.push(evaluateToText
                    ? ((_a = v.text) === null || _a === void 0 ? void 0 : _a.plainText)
                        ? v.text.plainText
                        : v.identifier.optionID
                    : v.identifier.optionID);
            }
            return;
        }
    });
    return [
        ...values,
        ...Array.from(selectBlockValues.entries()).map(([blockPrototypeID, value]) => {
            return {
                identifier: {
                    blockPrototypeID,
                },
                value,
            };
        }),
    ];
}
function parseBlockValues(blockValues, traverser, evaluateToText) {
    return blockValues.map((blockValue) => parseBlockValue(blockValue, traverser, evaluateToText));
}
function parseBlockValue(blockValue, traverser, evaluateToText) {
    var _a;
    const blockPrototype = traverser.getBlockProtoByID(blockValue.identifier.blockPrototypeID);
    if (blockValue.identifier.optionID != undefined) {
        let value;
        switch (blockValue.value) {
            case 'true':
                value = true;
                break;
            case 'false':
                value = false;
                break;
            default:
                value = blockValue.value;
        }
        // Because select values are stored as `true` or `false` we need to
        // find the text of the options in order to be able to evaluate
        // the expression correctly.
        if (evaluateToText) {
            const text = (blockPrototype === null || blockPrototype === void 0 ? void 0 : blockPrototype.type) === 'SELECT'
                ? (_a = blockPrototype.options.find((option) => option.id === blockValue.identifier.optionID)) === null || _a === void 0 ? void 0 : _a.text
                : undefined;
            return Object.assign(Object.assign({}, blockValue), { text,
                value });
        }
        return Object.assign(Object.assign({}, blockValue), { value });
    }
    if (!blockPrototype)
        return blockValue;
    // This should not be here, but be centralized somewhere with the defintion of the block type.
    if (blockPrototype.type === 'NUMBER_INPUT') {
        return Object.assign(Object.assign({}, blockValue), { value: parseFloat(blockValue.value) });
    }
    return blockValue;
}
