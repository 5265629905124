import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React from 'react';
import { List as VirtualList } from 'react-virtualized';
import { intl } from '@corti/i18n';
import { useMousetrap } from '@corti/react';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Box, Button, Input, Loading, Popper, Typography } from 'lib/cortiUI';
import { useSelector } from './hooks';
export function Selector(props) {
    var _a;
    const theme = useTheme();
    const buttonRef = React.useRef(null);
    const [inputRef, setInputRef] = React.useState(null);
    const [virtualListRef, setVirtualListRef] = React.useState(null);
    const selector = useSelector(Object.assign(Object.assign({}, props), { inputRef, virtualListRef }));
    const { items, selectedItem, isPending = false, isReadonly = false, keyboardShortcuts } = props;
    useMousetrap({
        keys: (_a = keyboardShortcuts === null || keyboardShortcuts === void 0 ? void 0 : keyboardShortcuts.open) !== null && _a !== void 0 ? _a : 'm',
        handle: () => {
            if (!isReadonly && !(keyboardShortcuts === null || keyboardShortcuts === void 0 ? void 0 : keyboardShortcuts.disabled)) {
                selector.openMenu();
            }
        },
    }, [isReadonly, keyboardShortcuts]);
    return (React.createElement(Base, Object.assign({ position: "relative" }, selector.getComboboxProps()),
        React.createElement(Button, Object.assign({}, selector.getToggleButtonProps({ refKey: 'ref' }), { variant: "text", noWrap: true, disabled: isReadonly, sx: { minWidth: 140, maxWidth: 340 }, color: selectedItem && !isPending ? 'primary' : undefined, ref: buttonRef, startIcon: isPending && React.createElement(Loading, { size: "small", mr: 4 }), endIcon: selector.isOpen ? (React.createElement(ArrowDropUpIcon, { fontSize: "small" })) : (React.createElement(ArrowDropDownIcon, { fontSize: "small" })) }),
            React.createElement(Typography, { uppercase: true, noWrap: true, color: "inherit", variant: "button" }, selectedItem ? selectedItem.text : intl.t('realtime:selectAction', 'Select action'))),
        React.createElement(Popper, { disablePortal: true, keepMounted: true, open: selector.isOpen, anchorEl: buttonRef.current, placement: "top-end" },
            React.createElement(Base, Object.assign({ my: 4, background: theme.palette.background.card, border: `1px solid ${theme.palette.background.divider}`, overflow: 'hidden', borderRadius: 2, gridTemplateRows: "max-content max-content", style: { display: selector.isOpen ? 'grid' : 'none' } }, selector.getMenuProps()),
                React.createElement(React.Fragment, null,
                    React.createElement(Input, Object.assign({ placeholder: intl.t('realtime:filterActions', 'Filter actions'), m: 3, mb: 4 }, selector.getInputProps({
                        refKey: 'inputRef',
                        ref: setInputRef,
                    }))),
                    React.createElement(VirtualList, { ref: setVirtualListRef, className: css(getScrollerCss({ theme })), rowCount: items.length, rowHeight: 36, height: Math.min(items.length * 36 + 36, 300), width: 360, noRowsRenderer: () => (React.createElement(Box, { pl: 5 },
                            React.createElement(Typography, { color: "default", variant: "body2" }, intl.t('realtime:noActionsMsg', 'No actions')))), rowRenderer: ({ index, key, style }) => {
                            const isHighlighted = selector.highlightedIndex === index;
                            const item = items[index];
                            return (React.createElement(Box, Object.assign({ key: key, flexDirection: "row", background: isHighlighted ? theme.palette.action.hover : undefined, alignItems: "center", justifyContent: "space-between", pl: 5, pr: 4, title: item.text }, selector.getItemProps({ item, index, style })),
                                React.createElement(Typography, { noSelect: true, noWrap: true, flexShrink: 0, variant: "body2", color: "default" }, item.text),
                                item.hintText !== null && (React.createElement(Typography, { flexShrink: 0, variant: "body2", color: "primary" }, item.hintText))));
                        } }))))));
}
