import React from 'react';
import { useAuth } from 'core/auth/browser';
import { useGraphSelectorOptions } from './useGraphSelectorOptions';
export function useGraphSelector() {
    var _a, _b;
    const auth = useAuth();
    const graphOptions = useGraphSelectorOptions();
    const [selectedGraphOptionID, setSelectedGraphOptionID] = React.useState(null);
    const [selectedGraphVersionOptionID, setSelectedGraphVersionOptionID] = React.useState(null);
    const selectedGraphOption = (_a = graphOptions.find((it) => it.value === selectedGraphOptionID)) !== null && _a !== void 0 ? _a : null;
    const selectedGraphVersionOption = (_b = selectedGraphOption === null || selectedGraphOption === void 0 ? void 0 : selectedGraphOption.versions.find((it) => it.value === selectedGraphVersionOptionID)) !== null && _b !== void 0 ? _b : null;
    React.useEffect(() => {
        var _a, _b;
        const { activeProtocolGraphVersionID: id } = auth.organization.triageSettings;
        const activeGraph = graphOptions.find((it) => it.versions.find((v) => v.value === id));
        const activeGraphID = activeGraph === null || activeGraph === void 0 ? void 0 : activeGraph.value;
        const activeGraphVersion = activeGraph === null || activeGraph === void 0 ? void 0 : activeGraph.versions.find((v) => v.value === id);
        const activeGraphVersionID = activeGraphVersion === null || activeGraphVersion === void 0 ? void 0 : activeGraphVersion.value;
        const graphOptionID = (_a = selectedGraphOptionID !== null && selectedGraphOptionID !== void 0 ? selectedGraphOptionID : activeGraphID) !== null && _a !== void 0 ? _a : null;
        setSelectedGraphOptionID(graphOptionID);
        if (graphOptionID === activeGraphID) {
            setSelectedGraphVersionOptionID(activeGraphVersionID !== null && activeGraphVersionID !== void 0 ? activeGraphVersionID : null);
            return;
        }
        const selectedGraphOption = graphOptions.find((it) => it.value === graphOptionID);
        const selectedGraphVersionOptionID = (_b = selectedGraphOption === null || selectedGraphOption === void 0 ? void 0 : selectedGraphOption.versions[0]) === null || _b === void 0 ? void 0 : _b.value;
        setSelectedGraphVersionOptionID(selectedGraphVersionOptionID !== null && selectedGraphVersionOptionID !== void 0 ? selectedGraphVersionOptionID : null);
    }, [selectedGraphOptionID, graphOptions, auth]);
    return {
        graphOptions,
        selectedGraphOption,
        setSelectedGraphOptionID,
        selectedGraphVersionOption,
        setSelectedGraphVersionOptionID,
    };
}
