var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { cloneDeep, isEqual } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { uuid } from '@corti/uuid';
import { getTempTermGroupColor } from '../utils';
const SEARCH_TERMS_COLORS = [
    '#206CC6',
    '#61D72A',
    '#1BCEFF',
    '#C96436',
    '#D1CB13',
    '#9F20C6',
    '#EE4B4B',
    '#280770',
    '#9F9F9F',
];
export class TranscriptQueryParametersModel {
    constructor(queryID, input) {
        var _a;
        Object.defineProperty(this, "_storageKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "termGroups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "teamIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "protocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_tempTermGroups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempUserIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempTeamIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tempProtocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mergeParameters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.termGroups = cloneDeep(this.filteredTempTermGroups);
                this.dateRange = cloneDeep(this.tempDateRange);
                this.userIDs = cloneDeep(this.tempUserIDs);
                this.teamIDs = cloneDeep(this.tempTeamIDs);
                this.protocolNames = cloneDeep(this.tempProtocolNames);
            }
        });
        Object.defineProperty(this, "changeTempDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tempDateRange) => {
                this.tempDateRange = tempDateRange;
            }
        });
        Object.defineProperty(this, "changeTempTerms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (termGroupID, terms) => {
                const group = this._tempTermGroups.get(termGroupID);
                if (group) {
                    group.terms = terms;
                }
            }
        });
        Object.defineProperty(this, "changeTempUserIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tempUserIDs) => {
                this.tempUserIDs = tempUserIDs;
            }
        });
        Object.defineProperty(this, "changeTempTeamIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tempTeamIDs) => {
                this.tempTeamIDs = tempTeamIDs;
            }
        });
        Object.defineProperty(this, "changeTempProtocolNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tempProtocolNames) => {
                this.tempProtocolNames = tempProtocolNames;
            }
        });
        Object.defineProperty(this, "addTempTermGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const color = getTempTermGroupColor(this.tempTermGroups.map((i) => i.style.color), SEARCH_TERMS_COLORS);
                const group = { id: uuid(), terms: [], style: { color } };
                this._tempTermGroups.set(group.id, group);
            }
        });
        Object.defineProperty(this, "removeTempTermGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (termGroupID) => {
                this._tempTermGroups.delete(termGroupID);
            }
        });
        Object.defineProperty(this, "getTermGroupByID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (termGroupID) => {
                return this.termGroups.find((it) => it.id === termGroupID);
            }
        });
        Object.defineProperty(this, "persistTempParameters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                sessionStorage.setItem(this._storageKey, JSON.stringify(this.serializedTempValues));
            }
        });
        Object.defineProperty(this, "clearPersistedTempParameters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                sessionStorage.removeItem(this._storageKey);
            }
        });
        makeObservable(this);
        this._storageKey = `tempTermGroups-${queryID}`;
        const persistedParameters = this.persistedParameters;
        const groups = (_a = persistedParameters === null || persistedParameters === void 0 ? void 0 : persistedParameters.tempTermGroups) !== null && _a !== void 0 ? _a : input.termGroups;
        const map = new Map();
        groups.forEach((it) => {
            map.set(it.id, cloneDeep(it));
        });
        runInAction(() => {
            var _a, _b, _c;
            this._tempTermGroups = map;
            if (persistedParameters) {
                this.tempDateRange = persistedParameters.tempDateRange;
                this.tempUserIDs = (_a = persistedParameters.tempUserIDs) !== null && _a !== void 0 ? _a : [];
                this.tempTeamIDs = (_b = persistedParameters.tempTeamIDs) !== null && _b !== void 0 ? _b : [];
                this.tempProtocolNames = (_c = persistedParameters.tempProtocolNames) !== null && _c !== void 0 ? _c : [];
            }
            else {
                this.tempDateRange = input.dateRange;
                this.tempUserIDs = input.userIDs;
                this.tempTeamIDs = input.teamIDs;
                this.tempProtocolNames = input.protocolNames;
            }
            this.mergeParameters();
        });
    }
    get hasChanges() {
        return (!isEqual(this.dateRange, this.tempDateRange) ||
            !isEqual(this.termGroups, this.tempTermGroups) ||
            !isEqual(this.userIDs, this.tempUserIDs) ||
            !isEqual(this.teamIDs, this.tempTeamIDs) ||
            !isEqual(this.protocolNames, this.tempProtocolNames));
    }
    get tempTermGroups() {
        return Array.from(this._tempTermGroups.values());
    }
    get serializedTempValues() {
        return cloneDeep({
            tempDateRange: this.tempDateRange,
            tempTermGroups: this.filteredTempTermGroups,
            tempUserIDs: this.tempUserIDs,
            tempTeamIDs: this.tempTeamIDs,
            tempProtocolNames: this.tempProtocolNames,
        });
    }
    get hasNoTerms() {
        return this.filteredTempTermGroups.length == 0;
    }
    get filteredTermGroups() {
        return this.termGroups.filter((it) => it.terms.length > 0);
    }
    get filteredTempTermGroups() {
        return this.tempTermGroups.filter((it) => it.terms.length > 0);
    }
    get persistedParameters() {
        const data = sessionStorage.getItem(this._storageKey);
        const parsedData = data ? JSON.parse(data) : undefined;
        if (!parsedData)
            return undefined;
        const parsedTempDateRange = parsedData.tempDateRange.type === 'absolute'
            ? Object.assign(Object.assign({}, parsedData.tempDateRange), { absoluteRange: {
                    start: new Date(parsedData.tempDateRange.absoluteRange.start),
                    end: new Date(parsedData.tempDateRange.absoluteRange.end),
                } }) : parsedData.tempDateRange;
        return Object.assign(Object.assign({}, parsedData), { tempDateRange: parsedTempDateRange });
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "termGroups", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "dateRange", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "userIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "teamIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "protocolNames", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], TranscriptQueryParametersModel.prototype, "_tempTermGroups", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "tempDateRange", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "tempUserIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "tempTeamIDs", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], TranscriptQueryParametersModel.prototype, "tempProtocolNames", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "mergeParameters", void 0);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TranscriptQueryParametersModel.prototype, "hasChanges", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TranscriptQueryParametersModel.prototype, "tempTermGroups", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TranscriptQueryParametersModel.prototype, "serializedTempValues", null);
__decorate([
    computed,
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TranscriptQueryParametersModel.prototype, "hasNoTerms", null);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "changeTempDateRange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "changeTempTerms", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "changeTempUserIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "changeTempTeamIDs", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "changeTempProtocolNames", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "addTempTermGroup", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], TranscriptQueryParametersModel.prototype, "removeTempTermGroup", void 0);
