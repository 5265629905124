var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable, override } from 'mobx';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { ValuePublisher } from 'lib/graphEditor/valuePublisher';
import { AbstractInputElementModel } from './AbstractInputElementModel';
export class DatePickerElementModel extends AbstractInputElementModel {
    constructor(props = {}) {
        super(props, {});
        Object.defineProperty(this, "isDateTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "valuePublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.type = DatePickerElementModel.typename;
        this.valuePublisher = new ValuePublisher();
    }
    setIsDateTime(value) {
        this.isDateTime = value;
    }
    deserialize(input) {
        super.deserialize(input);
        this.isDateTime = input.isDateTime;
        this.valuePublisher.deserialize(input.valuePublisher);
    }
    getEncodableData() {
        return Object.assign(Object.assign({}, super.getEncodableData()), { isDateTime: this.isDateTime, valuePublisher: this.valuePublisher.getEncodableData() });
    }
}
Object.defineProperty(DatePickerElementModel, "typename", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 'date-picker'
});
Object.defineProperty(DatePickerElementModel, "descriptor", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: ({ t }) => ({
        title: t('libGraphEditor:contentBuilder.datepickerElement.title', 'Date Picker'),
    })
});
Object.defineProperty(DatePickerElementModel, "decode", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (val) => {
        const i = new DatePickerElementModel();
        i.deserialize(val);
        return i;
    }
});
__decorate([
    observable,
    __metadata("design:type", Boolean)
], DatePickerElementModel.prototype, "isDateTime", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], DatePickerElementModel.prototype, "setIsDateTime", null);
__decorate([
    override,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ReturnType !== "undefined" && ReturnType) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], DatePickerElementModel.prototype, "deserialize", null);
ElementSerializer.register(DatePickerElementModel, 'DatePickerElementModel');
