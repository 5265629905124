import { intl } from '@corti/i18n';
import { coreStore } from 'browser/stores';
export function getErrorMessages() {
    return {
        COMPLIANT_RANGE: intl.t('template:builder.configuration.evaluationCriteriaModal.error.compliantRange', 'Compliant value must be between 1 and 100'),
        COMPLIANT_MUST_BE_HIGHER: intl.t('template:builder.configuration.evaluationCriteriaModal.error.compliant', 'Enter a value higher than Partially Compliant'),
        PARTIALLY_COMPLIANT_RANGE: intl.t('template:builder.configuration.evaluationCriteriaModal.error.partiallyCompliantRange', 'Partially Compliant value must be between 1 and 100'),
        SAVE_ERROR: intl.t('template:builder.configuration.evaluationCriteriaModal.error.saveThresholdsError', 'Failed to update the thresholds'),
    };
}
export function getDefaultThresholds(initialThresholds) {
    const DEFAULT_COMPLIANT_VALUE = 50;
    return Object.assign(Object.assign({}, initialThresholds), { threshold: initialThresholds.has_threshold
            ? initialThresholds.threshold
            : DEFAULT_COMPLIANT_VALUE });
}
export function getParsedValue(inputValue) {
    return inputValue ? +inputValue.replace(/^0+/, '') : 0;
}
export function notifyError(message) {
    coreStore.notifications.showNotification({ type: 'error', message });
}
export function notifySuccess(message) {
    coreStore.notifications.showNotification({ type: 'success', message });
}
