var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { VariableSizeList } from 'react-window';
import { OuterElementProvider, useOuterElementContext } from './OuterElementContext';
import { useResetCache } from './utils';
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = useOuterElementContext();
    return React.createElement("div", Object.assign({ ref: ref }, props, outerProps));
});
/**
 * Adapter for react-window
 */
export const VirtualizedList = React.forwardRef(function VirtualizedList(_a, ref) {
    var { children, RowComponent, itemSize, useOuterContext } = _a, otherProps = __rest(_a, ["children", "RowComponent", "itemSize", "useOuterContext"]);
    const LISTBOX_PADDING = 8;
    const itemData = children.flat();
    const itemCount = itemData.length;
    const getHeight = (itemCount) => (itemCount > 8 ? 8 * itemSize : itemCount * itemSize);
    const listRef = useResetCache(itemData);
    const ListContent = (React.createElement(VariableSizeList, { itemData: itemData, height: getHeight(itemData.length) + 2 * LISTBOX_PADDING, width: "100%", ref: listRef, innerElementType: "ul", outerElementType: useOuterContext ? OuterElementType : 'div', itemSize: () => itemSize, overscanCount: 5, itemCount: itemCount }, (props) => React.createElement(RowComponent, Object.assign({}, props, { listboxPadding: LISTBOX_PADDING }))));
    return (React.createElement("div", { ref: ref }, useOuterContext ? (React.createElement(OuterElementProvider, { value: otherProps }, ListContent)) : (ListContent)));
});
