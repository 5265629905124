var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { addMilliseconds } from 'date-fns';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { formatDate } from '@corti/date';
import { api } from '@corti/lib/coreApiService';
import { GraphTraverser } from '@corti/lib/graphs';
import { GqlMediaProvider } from '@corti/lib/graphs/media/gqlMediaProvider';
import { Observer } from '@corti/observer';
import { uuid } from '@corti/uuid';
import { history } from 'browser/navigation';
import { coreRealtimeClient, trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { TriageSessionController } from 'lib/triageSession';
import { ConnectionChecker } from '../ConnectionChecker';
import { ProtocolGraphLoader } from '../ProtocolGraphLoader';
import { TriageSessionAdapter } from '../TriageSessionAdapter';
import { RealTimeError } from '../errors';
import { RealTimePublicApi } from '../publicapi';
import { RecentTriageSessionRepository, TriageSessionRepository } from '../repositories';
import { sessionTransformer } from '../transformers/session';
export class RealTimeController {
    constructor(input) {
        Object.defineProperty(this, "input", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: input
        });
        Object.defineProperty(this, "connectionChecker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "realtimePublicApi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "protocolGraphLoader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "triageSessionRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recentTriageSessionRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeTriageSession", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onTriageSessionCreatedListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this);
        this.observer = new Observer();
        this.connectionChecker = new ConnectionChecker(coreRealtimeClient);
        this.realtimePublicApi = new RealTimePublicApi(this, this.input.apiContributor);
        runInAction(() => {
            this.activeTriageSession = null;
        });
        this.protocolGraphLoader = new ProtocolGraphLoader({
            api: api.protocolGraphs,
            db: this.input.protocolGraphDB,
            logger: this.input.logger,
        });
        this.recentTriageSessionRepository = new RecentTriageSessionRepository(this.input.realtimeUserDB.recentSessions);
        this.triageSessionRepository = new TriageSessionRepository({
            logger: this.input.logger,
            api: api.triage,
            realtimeClient: coreRealtimeClient,
            realtimeUserDB: this.input.realtimeUserDB,
            connectionChecker: this.connectionChecker,
            getGraphTraverser: async (graphVersionID) => {
                const graph = await this.protocolGraphLoader.loadGraph(graphVersionID);
                if (!graph) {
                    return;
                }
                return GraphTraverser.create(graph);
            },
        });
        if (authStore.organization.triageSettings.activeProtocolGraphVersionID) {
            void this.protocolGraphLoader.loadGraph(authStore.organization.triageSettings.activeProtocolGraphVersionID, { cache: true });
        }
        this.realtimePublicApi.init();
    }
    destroy() {
        this.realtimePublicApi.destroy();
        this.observer.unAll();
        this.triageSessionRepository.destroy();
    }
    /**
     * Initiates session creation in the backend (works offline)
     *
     * !IMPORTANT: may throws errors. This is intentional because its referenced by frontend public api and localy.
     * Even though errors are internal we still want to send them through public api until proper ones are implemented.
     */
    async createTriageSession(input) {
        var _a, _b, _c, _d, _e, _f, _g;
        const externalID = (_a = input.externalID) === null || _a === void 0 ? void 0 : _a.trim();
        const graphVersionID = (_b = input.graphVersionID) !== null && _b !== void 0 ? _b : authStore.organization.triageSettings.activeProtocolGraphVersionID;
        if (graphVersionID == null) {
            const message = 'Graph version id is missing';
            (_c = this.input.logger) === null || _c === void 0 ? void 0 : _c.error(message);
            throw RealTimeError.InvalidInput({ message });
        }
        const graph = await this.protocolGraphLoader.loadGraph(graphVersionID, {
            cache: graphVersionID === authStore.organization.triageSettings.activeProtocolGraphVersionID,
        });
        if (graph === undefined) {
            const message = `Graph ${graphVersionID} was not found`;
            (_d = this.input.logger) === null || _d === void 0 ? void 0 : _d.error(message);
            throw RealTimeError.DataNotFound({ message });
        }
        const traverser = GraphTraverser.create(graph);
        const graphStartNode = traverser.getStartNode();
        if (graphStartNode === undefined) {
            const message = `Start node of the graph ${graphVersionID} is missing`;
            (_e = this.input.logger) === null || _e === void 0 ? void 0 : _e.error(message);
            throw RealTimeError.DataNotFound({ message });
        }
        const session = {
            id: uuid(),
            caseID: input.caseID,
            createdAt: (_g = (_f = input.createdAt) === null || _f === void 0 ? void 0 : _f.toISOString()) !== null && _g !== void 0 ? _g : new Date().toISOString(),
            startedAt: new Date().toISOString(),
            externalID: externalID,
            graphVersionID: graphVersionID,
            ownerUser: {
                id: authStore.currentUser.id,
                name: authStore.currentUser.name,
            },
            createdBy: {
                id: authStore.currentUser.id,
                name: authStore.currentUser.name,
            },
        };
        await this.triageSessionRepository.addSession(sessionTransformer.toRepo(session));
        this.populateEmptySession({ sessionID: session.id, traverser });
        trackerService.track('[Triage] session created', {
            sessionID: session.id,
            caseID: session.caseID,
            externalID: session.externalID,
        });
        return session;
    }
    /**
     * Redirects app to the session view page
     */
    openTriageSessionView(input) {
        history.push(`/real-time/${input.id}`);
    }
    /**
     * Redirects app to the realtime home page
     */
    closeTriageSessionView() {
        history.push(`/real-time`);
    }
    /**
     * Initiates session loading from the backend (works offline)
     */
    async loadTriageSession(sessionID) {
        var _a, _b, _c, _d;
        if (((_a = this.activeTriageSession) === null || _a === void 0 ? void 0 : _a.status) === 'ok') {
            // Previous session was not shut down correctly, let's try to destroy it
            this.destroyTriageSession();
        }
        this.setActiveTriageSession({
            id: sessionID,
            status: 'initializing',
        });
        const sessionExternal = await this.triageSessionRepository.getSession(sessionID);
        if (sessionExternal === undefined) {
            (_b = this.input.logger) === null || _b === void 0 ? void 0 : _b.error(`Session ${sessionID}: not found`);
            this.setActiveTriageSession({
                id: sessionID,
                status: 'error',
                error: {
                    code: 'session-not-found',
                },
            });
            return;
        }
        const session = sessionTransformer.fromRepo(sessionExternal);
        if (session === undefined) {
            (_c = this.input.logger) === null || _c === void 0 ? void 0 : _c.error(`Session ${sessionID}: failed to transform session`);
            this.setActiveTriageSession({
                id: sessionID,
                status: 'error',
                error: {
                    code: 'load-session-error',
                },
            });
            return;
        }
        const graph = await this.protocolGraphLoader.loadGraph(session.graphVersionID, {
            cache: session.graphVersionID ===
                authStore.organization.triageSettings.activeProtocolGraphVersionID,
        });
        if (graph === undefined) {
            (_d = this.input.logger) === null || _d === void 0 ? void 0 : _d.error(`Session ${sessionID}: cannot load graph object for graph version id ${session.graphVersionID}`);
            this.setActiveTriageSession({
                id: sessionID,
                status: 'error',
                error: {
                    code: 'graph-not-found',
                },
            });
            return;
        }
        const appConf = config.getConfig();
        const graphTraverser = GraphTraverser.create(graph);
        const mediaProvider = new GqlMediaProvider();
        void mediaProvider.refreshMediaAssets();
        const controller = new TriageSessionController({
            graph: graphTraverser,
            state: {
                session,
                currentUser: {
                    id: authStore.currentUser.id,
                    name: authStore.currentUser.name,
                },
            },
            settings: {
                formatDate: formatDate,
                isFlowValueCollectorCopyEnabled: authStore.organization.config.triage.isCopyButtonEnabled,
                isAutoProgressToNextStepEnabled: authStore.organization.triageSettings.automaticProgressToNextStep,
                isAutoSessionLeaveWithoutLiveCallEnabled: authStore.organization.config.triage.autoLeaveSessionWithoutLiveCall,
                isAutoSessionTakeoverEnabled: authStore.organization.config.triage.automaticSessionTakeoverAccept,
                autoSessionTakeoverTimeout: authStore.organization.config.triage.automaticSessionTakeoverAcceptTimeout,
                flowSettings: {
                    childWindowOptions: {
                        defaultHeight: appConf['childWindowOptions:defaultHeight'],
                        defaultWidth: appConf['childWindowOptions:defaultWidth'],
                        defaultX: appConf['childWindowOptions:defaultX'],
                        defaultY: appConf['childWindowOptions:defaultY'],
                    },
                },
                isAlertsEnabled: !authStore.currentUser.settings.isAlertsDisabled,
                isAlertsUIEnabled: !authStore.organization.config.triage.isAlertWidgetEnabled,
                isCommentsEnabled: !authStore.organization.config.triage.isCommentsDisabled,
                isTakeoverEnabled: this.connectionChecker.getState() === 'online',
                isSearchEnabled: !authStore.organization.config.triage.isSearchDisabled,
                isManualActionSelectEnabled: !authStore.organization.config.triage.isManualSelectDisabled,
                isChecklistViewEnabled: authStore.isFeatureEnabled('smart-checklists'),
            },
            media: mediaProvider,
            logger: this.input.logger,
        });
        void this.recentTriageSessionRepository.addSession(session);
        // save settings for emergency triaging
        void this.input.protocolGraphDB.putLatestTriageSettings(Object.assign(Object.assign({}, toJS(controller.settings)), { 
            // this setting is not serializable
            formatDate: undefined }));
        const adapter = new TriageSessionAdapter({
            sessionController: controller,
            sessionRepository: this.triageSessionRepository,
            connectionChecker: this.connectionChecker,
            apiContributor: this.input.apiContributor,
            logger: this.input.logger,
            onTriageSessionCallEnded: () => {
                this.closeTriageSessionView();
            },
        });
        this.setActiveTriageSession({
            id: sessionID,
            status: 'ok',
            controller,
            adapter,
        });
    }
    populateEmptySession(input) {
        const stepID = uuid();
        const firstEventDatetime = new Date();
        // We know the node exists because otherwise we'd have thrown an error earlier
        const startNode = input.traverser.getStartNode();
        void this.triageSessionRepository.addFlowStateChangeEvent(input.sessionID, {
            event: 'triage-flow-step-added',
            data: {
                datetime: firstEventDatetime.toISOString(),
                step: {
                    id: stepID,
                    nodeID: startNode.id,
                },
            },
        });
        void this.triageSessionRepository.addFlowStateChangeEvent(input.sessionID, {
            event: 'triage-flow-active-step-changed',
            data: {
                datetime: addMilliseconds(firstEventDatetime, 1).toISOString(),
                stepID,
            },
        });
        // Find the first checklist in the graph without a visibility condition and expand it
        const firstChecklist = input.traverser
            .getChecklists()
            .find((checklist) => checklist.visibilityConditionEnabled === false);
        if (firstChecklist) {
            void this.triageSessionRepository.addFlowStateChangeEvent(input.sessionID, {
                event: 'checklist-updated',
                data: {
                    datetime: addMilliseconds(firstEventDatetime, 1).toISOString(),
                    checklistID: firstChecklist.id,
                    expanded: true,
                },
            });
        }
    }
    /**
     * Destroys session and removes it from the active session state
     */
    destroyTriageSession() {
        var _a;
        if (((_a = this.activeTriageSession) === null || _a === void 0 ? void 0 : _a.status) === 'ok') {
            this.activeTriageSession.adapter.destroy();
        }
        this.setActiveTriageSession(null);
    }
    setActiveTriageSession(triageSession) {
        // We want to ensure it's always a new reference (at least shallowly)
        this.activeTriageSession = triageSession !== null ? Object.assign({}, triageSession) : null;
    }
    onTriageSessionViewOpenedEvent(cb) {
        return this.observer.on('triage-session-view-opened', cb);
    }
    onTriageSessionViewClosedEvent(cb) {
        return this.observer.on('triage-session-view-closed', cb);
    }
    fireTriageSessionViewOpenedEvent(triageSessionController) {
        this.observer.fireEvent('triage-session-view-opened', triageSessionController);
    }
    fireTriageSessionViewClosedEvent(triageSessionController) {
        this.observer.fireEvent('triage-session-view-closed', triageSessionController);
    }
    /**
     * Used to update the session overview when a new session is created
     */
    onTriageSessionCreatedTrigger(data) {
        var _a;
        return (_a = this.onTriageSessionCreatedListener) === null || _a === void 0 ? void 0 : _a.call(this, data);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RealTimeController.prototype, "activeTriageSession", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_a = typeof Promise !== "undefined" && Promise) === "function" ? _a : Object)
], RealTimeController.prototype, "createTriageSession", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], RealTimeController.prototype, "openTriageSessionView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], RealTimeController.prototype, "closeTriageSessionView", null);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], RealTimeController.prototype, "setActiveTriageSession", null);
