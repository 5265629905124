import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Box, FocusContext, Typography } from 'lib/cortiUI';
import { useToolbar } from 'lib/webappUI';
import { useTriageSession } from '../TriageSessionContext';
import { useUIState } from '../UIStateProvider';
import { PinnedStepList } from './PinnedStepList';
export const PinnedSteps = observer(() => {
    const ctx = useTriageSession();
    const uiState = useUIState();
    const { openToolID, isToolPanelOpened } = useToolbar();
    const { t } = useTranslation('realtime');
    const [pinnedSteps, setPinnedSteps] = React.useState([]);
    const [containerRef, setContainerRef] = React.useState(null);
    const focusControllerRef = React.useRef(null);
    const handlePinnedStepClick = (item) => {
        uiState.setActivePinnedStepID(item.id);
    };
    React.useEffect(() => {
        var _a;
        if (isToolPanelOpened) {
            (_a = focusControllerRef.current) === null || _a === void 0 ? void 0 : _a.focusFirstElement();
        }
    }, [isToolPanelOpened]);
    React.useEffect(() => {
        const pinnedSteps = ctx.controller.flow.stepStore.pinnedSteps.map((s) => {
            return {
                id: s.id,
                title: s.node.view.name || s.id,
            };
        });
        setPinnedSteps(pinnedSteps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (pinnedSteps.length === 0) {
        return (React.createElement(Box, { height: "100%", width: "100%", justifyContent: "center", alignItems: "center" },
            React.createElement(Typography, { color: "secondaryText" }, t('pinsView.noPins', 'Session has no pins'))));
    }
    return (React.createElement(Base, { ref: (ref) => setContainerRef(ref), px: 7, py: 4 },
        React.createElement(FocusContext, { controllerRef: (ref) => (focusControllerRef.current = ref), keyboardEventBindingElement: containerRef, disableKeyboardShortcuts: !openToolID, keyboardShortcuts: {
                triggerFocusedElement: 'enter',
            } },
            React.createElement(PinnedStepList, { pinnedSteps: pinnedSteps, activePinnedStepID: uiState.activePinnedStepID, onPinnedStepClick: handlePinnedStepClick }))));
});
