import { useEffect, useRef, useState } from 'react';
export function useLoadFile({ url, headers }) {
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [fileContentsUrl, setFileContentsUrl] = useState();
    const [fileLoadingError, setFileLoadingError] = useState();
    const [contentType, setContentType] = useState(null);
    const lastUrl = useRef();
    useEffect(() => {
        const loadFile = async () => {
            revokeCurrent();
            setIsLoadingFile(true);
            setFileLoadingError(undefined);
            setFileContentsUrl(undefined);
            setContentType(null);
            try {
                const res = await fetch(url, { headers: Object.assign({}, headers) });
                // When status is > 300, check body is json and try to read an error
                if (!res.ok) {
                    let json;
                    try {
                        json = await res.json();
                    }
                    catch (_a) { }
                    if (json) {
                        if (json.error) {
                            throw new Error(json.error.message);
                        }
                        else {
                            throw new Error('Error while loading file: unexpected json error response');
                        }
                    }
                    else {
                        throw new Error(res.statusText);
                    }
                }
                const blob = await res.blob();
                lastUrl.current = URL.createObjectURL(blob);
                setFileContentsUrl(lastUrl.current);
                setContentType(res.headers.get('content-type'));
            }
            catch (err) {
                if (err instanceof Error) {
                    setFileLoadingError(err);
                }
            }
            finally {
                setIsLoadingFile(false);
            }
        };
        const revokeCurrent = () => lastUrl.current && URL.revokeObjectURL(lastUrl.current);
        void loadFile();
        return () => void revokeCurrent();
    }, [url, headers]);
    return { isLoadingFile, fileLoadingError, fileContentsUrl, contentType };
}
