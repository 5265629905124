var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { intl } from '@corti/i18n';
import { withTheme } from '@corti/theme';
import { Typography } from 'lib/cortiUI';
import { PasswordResetEmailForm } from './PasswordResetEmailForm';
import { PasswordResetSetNewForm } from './PasswordResetSetNewForm';
import { PasswordResetVerifyForm } from './PasswordResetVerifyForm';
// @ts-ignore
let PasswordResetForm = class PasswordResetForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                userID: '',
                token: '',
                email: '',
                success: false,
                errorMessage: '',
            }
        });
        Object.defineProperty(this, "handleEmailFormSuccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (email) => {
                this.setState({ email: email });
            }
        });
        Object.defineProperty(this, "handleVerifyFormSuccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (userID, token) => {
                this.setState({ userID: userID, token: token });
            }
        });
        Object.defineProperty(this, "handleSetNewFormSuccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ success: true });
            }
        });
        Object.defineProperty(this, "handleSetNewFormFailure", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    errorMessage: intl.t('team:passwordReset.invalidResetCodeErrorMsg', 'Password reset verification code is invalid or expired'),
                });
            }
        });
    }
    render() {
        const { userID, token, email, success, errorMessage } = this.state;
        if (success) {
            return (React.createElement(Typography, { variant: "subtitle1", textAlign: "center", color: "success", pb: 3, "data-cy": "reset-success-message" }, intl.t('team:passwordReset.resetSuccessMsg', 'Your password has been reset successfully')));
        }
        if (errorMessage) {
            return (React.createElement(Typography, { variant: "subtitle1", textAlign: "center", color: "error", pb: 3 }, errorMessage));
        }
        if (userID && token) {
            return (React.createElement(PasswordResetSetNewForm, { userID: userID, token: token, onSuccess: this.handleSetNewFormSuccess, onFailure: this.handleSetNewFormFailure }));
        }
        if (!email) {
            return (React.createElement(PasswordResetEmailForm, { initialEmail: this.props.initialEmail, onSuccess: this.handleEmailFormSuccess }));
        }
        return React.createElement(PasswordResetVerifyForm, { email: email, onSuccess: this.handleVerifyFormSuccess });
    }
};
PasswordResetForm = __decorate([
    withTheme
], PasswordResetForm);
export { PasswordResetForm };
