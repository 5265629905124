import { gql, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import { formatDistanceToNow } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { useDelayedQuery } from '@corti/react';
import { isEmpty } from '@corti/richtext';
import { getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { useAuth } from 'core/auth/browser';
import { Base, Box, Button, LinearProgress, RichTextRenderer, Tag, Typography, UnstyledLink, } from 'lib/cortiUI';
import { Route, RouteBreadcrumb } from 'lib/router';
import { CommitsList } from '../components/commits';
import { GraphSettingsView } from '../graphSettings/GraphSettingsView';
import { GraphVersionActionMenu } from './GraphVersionActionMenu';
import { getProtocolGraphDetails } from './graphql';
export function GraphDetailsView({ graphID }) {
    var _a;
    const match = useRouteMatch();
    const query = useQuery(gql `
      query ProtocolGraphDetails($id: String!) {
        protocolGraph(id: $id) {
          id
          name
        }
      }
    `, { variables: { id: graphID } });
    return (React.createElement(React.Fragment, null,
        React.createElement(RouteBreadcrumb, { breadcrumb: (_a = query.data) === null || _a === void 0 ? void 0 : _a.protocolGraph.name }),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: match.path, render: () => React.createElement(GraphVersionsView, { graphID: graphID }) }),
            React.createElement(Route, { path: `${match.path}/settings`, breadcrumb: "Settings", render: () => React.createElement(GraphSettingsView, { graphID: graphID }) }))));
}
function GraphVersionsView(props) {
    const { graphID } = props;
    const query = useDelayedQuery(getProtocolGraphDetails(), {
        variables: { id: graphID },
    });
    if (query.delayedLoading && !query.data) {
        return (React.createElement(LinearProgress, { variant: "indeterminate", style: { position: 'fixed', top: 0, left: 0, right: 0 } }));
    }
    if (query.error) {
        return null;
    }
    if (query.data) {
        return React.createElement(GraphDetailsDataView, { graph: query.data.protocolGraph });
    }
    return null;
}
function GraphDetailsDataView(props) {
    const { graph } = props;
    const theme = useTheme();
    const routematch = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    let drafts = [];
    let releases = [];
    graph.versions.forEach((v) => {
        if (v.release) {
            releases.push(v);
        }
        else {
            drafts.push(v);
        }
    });
    drafts = orderBy(drafts, (d) => d.updatedAt, 'desc');
    releases = orderBy(releases, (r) => r.release.releasedAt, 'desc');
    return (React.createElement(Base, { "data-cy": "graph-details-view", display: 'grid', height: "100%", overflow: "hidden", width: "100%", maxWidth: 960, mx: "auto", gridTemplateRows: "max-content 1fr", p: 6 },
        React.createElement(Box, { mt: 6, mb: 8, mx: 8, flexDirection: "row", gap: 6, alignItems: "center" },
            React.createElement(Base, null,
                React.createElement(Typography, { variant: "h6", color: "default" }, graph.name)),
            React.createElement(Box, { flex: 1, alignItems: "flex-end" },
                React.createElement(Button, { "data-cy": "open-graph-settings-button", variant: "outlined", size: "small", onClick: () => {
                        history.push(`${routematch.url}/settings`);
                    } }, t('editorApp2:graphDetailsView.settingsBtn', 'Graph Settings')))),
        React.createElement(Box, { gap: 8, className: getScrollerCss({ theme }) },
            drafts.map((version) => {
                return React.createElement(VersionRenderer, { key: version.id, graphVersion: version });
            }),
            releases.map((version) => {
                return React.createElement(VersionRenderer, { key: version.id, graphVersion: version });
            }))));
}
const VersionRenderer = observer(function VersionRenderer({ graphVersion, }) {
    var _a;
    const theme = useTheme();
    const authStore = useAuth();
    const { t } = useTranslation();
    return (React.createElement(Base, { "data-cy": "graph-version", display: 'grid', gridTemplateColumns: "150px 1fr", key: graphVersion.id, maxWidth: theme.breakpoints.values.md },
        React.createElement(Box, { py: 8, px: 7, alignItems: "flex-end", gap: 4 },
            authStore.organization.triageSettings.activeProtocolGraphVersionID === graphVersion.id ? (React.createElement(Tag, { text: t('editorApp2:graphDetailsView.activeLabel', 'Active'), color: theme.palette.success.dark })) : graphVersion.release ? null : (React.createElement(Tag, { text: t('editorApp2:graphDetailsView.draftLabel', 'Draft'), color: theme.palette.warning.dark })),
            React.createElement(Typography, { variant: "caption" }, graphVersion.parentVersion ? (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "body2", color: "default", display: "inline" },
                    t('editorApp2:graphDetailsView.versionFrom', 'From'),
                    ' '),
                React.createElement(Typography, { variant: "subtitle2", color: "default", display: "inline" }, graphVersion.parentVersion.name))) : (React.createElement(Typography, { variant: "body2", color: "default" }, t('editorApp2:graphDetailsView.rootVersion', 'Root'))))),
        React.createElement(Base, { px: 7, py: 7 },
            React.createElement(Box, { flexDirection: "row", justifyContent: 'space-between' },
                React.createElement(UnstyledLink, { to: `/editor-v2/editor/${graphVersion.id}`, "data-cy": "graph-version-link" }, () => (React.createElement(Typography, { variant: "h6", mb: 3, color: "primary" }, graphVersion.name))),
                React.createElement(GraphVersionActionMenu, { graphVersion: graphVersion })),
            React.createElement(Typography, { variant: "body2", mb: 8 },
                React.createElement(Typography, { variant: "subtitle2", color: "default", display: "inline-block" }, graphVersion.release
                    ? graphVersion.release.releasedBy.name
                    : graphVersion.updatedBy.name),
                ' ',
                graphVersion.release
                    ? t('editorApp2:graphDetailsView.releasedRelativeTime', 'released this {{date}}', {
                        date: formatDistanceToNow(new Date(graphVersion.release.releasedAt), {
                            addSuffix: true,
                        }),
                    })
                    : t('editorApp2:graphDetailsView.updatedRelativeTime', 'updated this {{date}}', {
                        date: formatDistanceToNow(new Date(graphVersion.updatedAt), {
                            addSuffix: true,
                        }),
                    })),
            !isEmpty((_a = graphVersion.description) === null || _a === void 0 ? void 0 : _a.richText) && (React.createElement(Base, { mt: 6 },
                React.createElement(RichTextRenderer, { value: graphVersion.description.richText }))),
            React.createElement(CommitsList, { commits: graphVersion.commits, initialCollapsed: !!graphVersion.release }))));
});
