import { ExpressionUtils } from '@corti/lib/graphs/expressionUtils';
import { ElementSerializer } from 'lib/graphEditor/ElementSerializer';
import { Clipboard } from 'lib/graphEditor/clipboard';
import { BaseInstance } from '../BaseInstance';
import { BlockInstance } from '../BlockInstance';
import { ComponentInstance } from '../ComponentInstance';
import { ContentBuilderContext } from '../ContentBuilderContext';
import { ElementFactory } from '../elementFactory';
export function registerCommands(editor) {
    editor.registerCommandDescriptor({
        type: 'viewBuilder.setBlockProtoProperty',
        createHandler: setBlockProtoProperty,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.createBlockInstance',
        createHandler: createElement,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.duplicateBlockInstance',
        createHandler: duplicateElement,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.deleteBlockInstance',
        createHandler: deleteElement,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.convertToComponentInstance',
        createHandler: convertBlockToComponent,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.explodeComponentInstance',
        createHandler: explodeComponentInstance,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.createComponentInstance',
        createHandler: createElementComponentInstance,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.copyInstances',
        createHandler: copyElements,
    });
    editor.registerCommandDescriptor({
        type: 'viewBuilder.pasteInstances',
        createHandler: pasteElements,
    });
}
const setBlockProtoProperty = (data) => {
    let block;
    let prevValue;
    return {
        run: ({ editor }) => {
            block = editor.model.elementContext.getPrototypeByID(data.protoID);
            if (!block) {
                return;
            }
            prevValue = block.attributes.getAttributeValue(data.propName);
            block.attributes.setAttributeValue(data.propName, data.propValue);
        },
        revert: () => {
            if (!block) {
                return;
            }
            block.attributes.setAttributeValue(data.propName, prevValue);
        },
    };
};
const createElement = (data) => {
    let addedElement;
    return {
        run: ({ editor }) => {
            const contentBuilder = editor.model.elementContext.getContentBuilderContextByID(data.contentBuilderID);
            if (!contentBuilder)
                return;
            const element = ElementFactory.createElement(data.elementType);
            if (!element) {
                return;
            }
            addedElement = ContentBuilderContext.createBlockInstance(element);
            contentBuilder.addElement(addedElement, data.position);
        },
        revert: () => {
            if (!addedElement) {
                return;
            }
            addedElement.remove();
        },
    };
};
const duplicateElement = (data) => {
    let addedElement;
    return {
        run: ({ editor }) => {
            const element = editor.model.elementContext.getElementByID(data.instanceID);
            if (!element || !element.context) {
                return;
            }
            addedElement = element.copy();
            element.context.addElement(addedElement, element.context.elements.indexOf(element) + 1);
        },
        revert: ({}) => {
            if (!addedElement) {
                return;
            }
            addedElement.remove();
        },
    };
};
const deleteElement = (data) => {
    let element;
    let idx;
    return {
        run: ({ editor }) => {
            var _a;
            element = editor.model.elementContext.getElementByID(data.instanceID);
            idx = (_a = element === null || element === void 0 ? void 0 : element.context) === null || _a === void 0 ? void 0 : _a.elements.indexOf(element);
            if (!element) {
                return;
            }
            element.remove();
        },
        revert: () => {
            var _a;
            if (element) {
                (_a = element.context) === null || _a === void 0 ? void 0 : _a.addElement(element, idx);
            }
        },
    };
};
const convertBlockToComponent = (data) => {
    let component;
    let block;
    let blockIdx;
    let instance;
    return {
        run: ({ editor }) => {
            const el = editor.model.elementContext.getElementByID(data.instanceID);
            const blockContext = el === null || el === void 0 ? void 0 : el.context;
            if (el instanceof BlockInstance && blockContext) {
                block = el;
                blockIdx = blockContext.elements.indexOf(block);
                if (blockIdx !== -1) {
                    block.remove();
                    component = editor.model.library.addElementComponent(block.wrappedElement);
                    instance = component.createInstance();
                    blockContext.addElement(instance, blockIdx);
                    instance.context.setSelectedElement(instance);
                }
            }
        },
        revert: ({ editor }) => {
            if (block && component) {
                editor.model.library.forceRemoveComponent(component);
                instance.context.addElement(block, blockIdx);
                instance.remove();
            }
        },
    };
};
const explodeComponentInstance = (data) => {
    let instance;
    let blockInstance;
    let element;
    let logicGateToPrevExpression = new Map();
    return {
        run: ({ editor }) => {
            var _a;
            data.replaceRefs = (_a = data.replaceRefs) !== null && _a !== void 0 ? _a : 'none';
            const el = editor.model.elementContext.getElementByID(data.instanceID);
            if (!(el instanceof ComponentInstance)) {
                return;
            }
            instance = el;
            const contentBuilderCtx = instance.context;
            const currentNode = instance.parent.parent;
            const currentBranch = currentNode.branchContext;
            // before exploding component instance, we need to collect
            // closure functions which will be executed after explosion
            // to replace existing references with new references
            const refReplacerFns = [];
            if (data.replaceRefs !== 'none') {
                for (const refItem of instance.wrappedElement.referenceableItems.values()) {
                    const gatesWithRefs = editor.logicContext.getRefItemUsages(refItem.refItemValue);
                    for (const gate of gatesWithRefs.values()) {
                        if ((data.replaceRefs === 'node' && gate.parent === currentNode) ||
                            (data.replaceRefs === 'branch' && gate.parent.branchContext === currentBranch)) {
                            logicGateToPrevExpression.set(gate, gate.expression);
                            refReplacerFns.push((getNewVar) => {
                                const newExpr = ExpressionUtils.replaceVariableValue(gate.expression, (v) => {
                                    if (v === refItem.refItemValue) {
                                        return getNewVar(v);
                                    }
                                    return v;
                                });
                                gate.setExpression(newExpr);
                            });
                        }
                    }
                }
            }
            const idx = contentBuilderCtx.elements.indexOf(instance);
            element = instance.explode();
            const varReplacementMap = new Map();
            [...instance.wrappedElement.referenceableItems.keys()].forEach((currentRef, idx) => {
                varReplacementMap.set(currentRef, [...element.referenceableItems.keys()][idx]);
            });
            refReplacerFns.forEach((replace) => {
                replace((currentRef) => varReplacementMap.get(currentRef));
            });
            blockInstance = ContentBuilderContext.createBlockInstance(element);
            contentBuilderCtx.addElement(blockInstance, idx);
        },
        revert: () => {
            if (instance && blockInstance) {
                instance.context.addElement(instance);
                blockInstance.remove();
                for (const [gate, prevExpr] of logicGateToPrevExpression.entries()) {
                    gate.setExpression(prevExpr);
                }
            }
        },
    };
};
const createElementComponentInstance = (data) => {
    let insertedInstance;
    return {
        run: ({ editor }) => {
            const component = editor.model.library.getComponentByID(data.componentID);
            if (!component) {
                return;
            }
            const target = editor.model.elementContext.getContentBuilderContextByID(data.contentBuilderID);
            if (!target) {
                return;
            }
            const instance = component.createInstance();
            target.addElement(instance, data.position);
            insertedInstance = instance;
        },
        revert: () => {
            if (insertedInstance) {
                insertedInstance.remove();
            }
        },
    };
};
const copyElements = (data) => {
    return {
        run: async ({ editor }) => {
            const elements = data.instanceIDs
                .map((id) => editor.model.elementContext.getElementByID(id))
                .filter((it) => it != null);
            Clipboard.write('contentbuilder', ElementSerializer.serialize(elements));
        },
    };
};
const pasteElements = (data) => {
    const addedElements = [];
    return {
        run: async ({ editor }) => {
            const t = await Clipboard.read('contentbuilder');
            if (!t)
                return;
            const target = editor.model.elementContext.getContentBuilderContextByID(data.contentBuilderID);
            if (!target) {
                return;
            }
            const deserialized = await ElementSerializer.deserialize(t);
            for (const entity of deserialized.entities.values()) {
                if (entity instanceof BaseInstance) {
                    const clone = entity.copy();
                    target.addElement(clone);
                    addedElements.push(clone);
                    return;
                }
            }
        },
        revert: () => {
            addedElements.forEach((it) => {
                it.remove();
            });
        },
    };
};
