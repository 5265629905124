export function getValueProgressBarColor(input) {
    const THRESHOLD = 0.1;
    const { value = 0, target, theme } = input;
    const roundedValue = Math.round(value * 100) / 100;
    if (!target) {
        return theme.palette.primary.main;
    }
    if (roundedValue >= target) {
        return theme.palette.success.dark;
    }
    if (roundedValue >= target - target * THRESHOLD) {
        return theme.palette.warning.light;
    }
    return theme.palette.error.light;
}
