var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _StateObserver_instances, _StateObserver_isEmitterPaused, _StateObserver_observer, _StateObserver_forwardEvent;
import { Observer } from '@corti/observer';
import { StateSerializer } from './utils';
export class StateObserver {
    constructor(flowStore) {
        _StateObserver_instances.add(this);
        Object.defineProperty(this, "flowStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: flowStore
        });
        _StateObserver_isEmitterPaused.set(this, void 0);
        _StateObserver_observer.set(this, void 0);
        __classPrivateFieldSet(this, _StateObserver_isEmitterPaused, false, "f");
        __classPrivateFieldSet(this, _StateObserver_observer, new Observer(), "f");
        this.flowStore.actionStore.onActionBlockTriggered((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeActionBlockTriggered({
                blockPrototype: input.blockPrototype,
                triggeredBy: input.triggeredBy,
            }));
        });
        this.flowStore.stepStore.onStepAdded((step) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeStepAdded({ step }));
        });
        this.flowStore.stepStore.onActiveStepChanged((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeActiveStepChanged({ step: input.step }));
        });
        this.flowStore.factValueStore.onUpdate((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeFactValuesUpdated({ factValues: input.factValues }));
        });
        this.flowStore.blockValueStore.onUpdate((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeBlockValuesUpdated({ blockValues: input.blockValues }));
        });
        this.flowStore.collectorStore.onCollectorValuesUpdated((collector) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeValueCollectorBlockValuesUpdated({ collector }, this.flowStore));
        });
        this.flowStore.checklistStore.onChecklistUpdate((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeChecklistUpdated(input));
        });
        this.flowStore.checklistStore.onChecklistEntryUpdate((input) => {
            __classPrivateFieldGet(this, _StateObserver_instances, "m", _StateObserver_forwardEvent).call(this, StateSerializer.serializeChecklistEntryUpdated(input));
        });
    }
    pauseEmitter() {
        __classPrivateFieldSet(this, _StateObserver_isEmitterPaused, true, "f");
    }
    resumeEmitter() {
        __classPrivateFieldSet(this, _StateObserver_isEmitterPaused, false, "f");
    }
    onEvent(cb) {
        return __classPrivateFieldGet(this, _StateObserver_observer, "f").on('state-changed', cb);
    }
}
_StateObserver_isEmitterPaused = new WeakMap(), _StateObserver_observer = new WeakMap(), _StateObserver_instances = new WeakSet(), _StateObserver_forwardEvent = function _StateObserver_forwardEvent(event) {
    if (__classPrivateFieldGet(this, _StateObserver_isEmitterPaused, "f")) {
        return;
    }
    __classPrivateFieldGet(this, _StateObserver_observer, "f").fireEvent('state-changed', event);
};
