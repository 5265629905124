import axios from 'axios';
import { TranscriptsApiError } from './TranscriptsApiError';
export function initRestClient(config) {
    const restClient = axios.create({
        baseURL: config.host,
        headers: {
            Authorization: config.authToken,
        },
    });
    restClient.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        throw createError(error);
    });
    return restClient;
}
function createError(error) {
    if (error.response) {
        return TranscriptsApiError.ServerInternal(error);
    }
    if (error.request) {
        return TranscriptsApiError.Unavailable(error);
    }
    return TranscriptsApiError.Internal(error);
}
