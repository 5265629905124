var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/**
 * This is like `<img />` component with some more advanced loading of image contents.
 * It first loads the smaller size image before loading the higher quality image
 */
export const ProgressiveImage = React.forwardRef(function ProgressiveImage(props, ref) {
    const { src, lowQualitySrc, style, onLoad } = props, imgProps = __rest(props, ["src", "lowQualitySrc", "style", "onLoad"]);
    const [mainImgLoaded, setMainImgLoaded] = React.useState(false);
    const [lowQualityImgLoaded, setLowQualityImgLoaded] = React.useState(false);
    const hiddenStyles = {
        position: 'fixed',
        visibility: 'hidden',
    };
    // proxy everything to img when low quality src is not provided
    if (!lowQualitySrc) {
        return React.createElement("img", Object.assign({ ref: ref, src: src, style: style, onLoad: onLoad }, imgProps));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("img", Object.assign({}, imgProps, { ref: ref, "data-cy": "progressive-img-low-quality", src: lowQualitySrc, style: Object.assign(Object.assign({}, style), (mainImgLoaded && hiddenStyles)), onLoad: (e) => {
                setLowQualityImgLoaded(true);
                onLoad === null || onLoad === void 0 ? void 0 : onLoad(e);
            } })),
        lowQualityImgLoaded && (React.createElement("img", Object.assign({}, imgProps, { ref: ref, "data-cy": "progressive-img-main-quality", src: src, style: Object.assign(Object.assign({}, style), (!mainImgLoaded && hiddenStyles)), onLoad: () => setMainImgLoaded(true) })))));
});
