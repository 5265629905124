import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, ChipV2, Typography } from 'lib/cortiUI';
export const Choice = ({ text, score, scoreEnabled, not_applicable, auto_fail, }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.questions' });
    return (React.createElement(Base, { display: "flex", flexDirection: "row", gap: 4, alignItems: "center", justifyContent: "space-between", key: text },
        React.createElement(Typography, { color: "default", variant: "subtitle2" }, text),
        scoreEnabled && (React.createElement(Base, { display: "flex", flexDirection: "row", gap: 4 },
            auto_fail && (React.createElement(ChipV2, { variant: "outlined", color: "error", label: t('autoFailure', 'Auto Failure') })),
            React.createElement(ChipV2, { label: not_applicable
                    ? '-'
                    : t('points', {
                        count: score,
                        defaultValue_one: `{{count}} point`,
                        defaultValue_other: `{{count}} points`,
                    }) })))));
};
