import React from 'react';
import { useTranslation } from '@corti/i18n';
import { TextField } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const LongTextField = ({ title, required }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    return (React.createElement(TextField, { required: required, error: !title, helperText: !title ? t('preview.validationErrors.noTitle', 'Title is missing.') : undefined, placeholder: t('preview.placeholders.typeAnswer', 'Type your answer here...'), InputProps: { readOnly: true }, multiline: true, minRows: 3, sx: { width: 1 }, "data-cy": CY_SELECTORS.preview.longTextFieldPreview, FormHelperTextProps: {
            'data-cy': CY_SELECTORS.preview.longTextPreviewHelperText,
        } }));
};
