import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Autocomplete, Box, FormControl, FormControlLabel, MenuItem, Slider, TextField, Typography, } from 'lib/cortiUI';
const RESOLUTION_OPTIONS = [
    '800x600',
    '1024x768',
    '1280x720',
    '1280x800',
    '1360x768',
    '1366x768',
    '1920x1080',
];
export function CaptureSettings({ initialSettings, onSettingsChanged }) {
    const { t } = useTranslation('screenRecorder');
    const [resolution, setResolution] = React.useState(initialSettings.resolution);
    const [frameRate, setFrameRate] = React.useState(initialSettings.frameRate);
    const handleResolutionChange = (newValue) => {
        if (newValue) {
            setResolution(newValue);
        }
    };
    const handleFrameRateChange = (_, newValue) => {
        setFrameRate(newValue);
    };
    React.useEffect(() => {
        onSettingsChanged({ resolution, frameRate });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resolution, frameRate]);
    return (React.createElement(Box, null,
        React.createElement(Typography, { variant: "h6" }, t('recordSettingsTitle', 'Record settings')),
        React.createElement(Box, { display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-around", gap: 8 },
            React.createElement(FormControl, { sx: { m: 1, minWidth: 120, flex: 1 }, size: "small" },
                React.createElement(Autocomplete, { options: RESOLUTION_OPTIONS, disableClearable: true, getOptionLabel: (option) => option, value: resolution, onChange: (_, nextValue) => {
                        handleResolutionChange(nextValue);
                    }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: t('captureResolutionsLabel', 'Capture resolution'), placeholder: t('resolutionInputPlaceholder', 'Resolution') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option }), option)) })),
            React.createElement(FormControl, { sx: { m: 2, minWidth: 100, flex: 1 } },
                React.createElement(FormControlLabel, { labelPlacement: "top", sx: { alignItems: 'unset' }, label: t('framerateLabel', 'Framerate'), control: React.createElement(Slider, { min: 1, max: 30, components: { Mark: 'span' }, value: frameRate, step: 1, onChange: handleFrameRateChange, valueLabelDisplay: "auto", marks: [
                            { value: 0, label: '0 fps' },
                            { value: 30, label: '30 fps' },
                        ] }) })))));
}
