import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { intl } from '@corti/i18n';
import { ICaseReviewRequestStatus } from '@corti/lib/coreApiService';
import { useLazyRef } from '@corti/react';
import { trackerService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Autocomplete, Base, Button, MenuItem, TextField } from 'lib/cortiUI';
import { useCaseReviewRequestsContext } from '../../CaseReviewRequestsContext';
import * as graphql from '../../graphql';
var IParticipantGroup;
(function (IParticipantGroup) {
    IParticipantGroup["User"] = "user";
    IParticipantGroup["Team"] = "team";
})(IParticipantGroup || (IParticipantGroup = {}));
export const AdvancedFilterPanel = observer(function AdvancedFilterPanel(props) {
    const { onApply, onClear } = props;
    const { organization } = useAuth();
    const ctx = useCaseReviewRequestsContext();
    const getTeamsQuery = useQuery(graphql.getTeamsQuery, {
        variables: {
            orgID: organization.id,
        },
        fetchPolicy: 'cache-first',
    });
    const userOptions = useLazyRef(() => {
        return ctx.dependencies.repositories.users.users
            .map((it) => ({
            id: it.id,
            text: it.name,
            groupID: IParticipantGroup.User,
            groupText: intl.t('caseReviewRequestsModule:advancedFilters.user', 'User'),
        }))
            .sort((a, b) => a.text.localeCompare(b.text));
    });
    const statusOptions = useLazyRef(() => {
        return [
            {
                id: ICaseReviewRequestStatus.PendingReview,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.pendingReview', 'To Review'),
            },
            {
                id: ICaseReviewRequestStatus.PendingAcknowledgement,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.pendingAcknowledgement', 'To Acknowledge'),
            },
            {
                id: ICaseReviewRequestStatus.Completed,
                text: intl.t('caseReviewRequestsModule:caseReviewRequestStatusTypes.completed', 'Completed'),
            },
        ];
    });
    const [participantOptions, setParticipantOptions] = React.useState([]);
    const [selectedAssignees, setSelectedAssignees] = React.useState([]);
    const [selectedCallTakers, setSelectedCallTakers] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    React.useEffect(() => {
        var _a, _b;
        if (getTeamsQuery.loading) {
            setParticipantOptions([]);
            return;
        }
        if (getTeamsQuery.data) {
            const teams = ((_b = (_a = getTeamsQuery.data) === null || _a === void 0 ? void 0 : _a.teams) !== null && _b !== void 0 ? _b : [])
                .map((it) => ({
                id: it.id,
                text: it.name,
                groupID: IParticipantGroup.Team,
                groupText: intl.t('caseReviewRequestsModule:advancedFilters.team', 'Team'),
            }))
                .sort((a, b) => a.text.localeCompare(b.text));
            setParticipantOptions([...userOptions.current, ...teams]);
        }
        else {
            setParticipantOptions(userOptions.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTeamsQuery.loading, getTeamsQuery.data]);
    React.useEffect(() => {
        setSelectedStatuses(statusOptions.current.filter((it) => {
            return ctx.model.filter.status.includes(it.id);
        }));
        setSelectedAssignees(participantOptions.filter((it) => {
            return (ctx.model.filter.assigneeIDs.includes(it.id) ||
                ctx.model.filter.assigneeTeams.map((a) => a.id).includes(it.id));
        }));
        setSelectedCallTakers(participantOptions.filter((it) => {
            return (ctx.model.filter.callTakerIDs.includes(it.id) ||
                ctx.model.filter.callTakerTeams.map((c) => c.id).includes(it.id));
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantOptions]);
    function handleApply() {
        ctx.model.filter.setStatus(selectedStatuses.map((it) => it.id));
        ctx.model.filter.setAssigneeIDs(selectedAssignees.filter((it) => it.groupID === IParticipantGroup.User).map((it) => it.id));
        ctx.model.filter.setCallTakerIDs(selectedCallTakers.filter((it) => it.groupID === IParticipantGroup.User).map((it) => it.id));
        if (getTeamsQuery.data) {
            ctx.model.filter.setAssigneeTeams(getTeamsQuery.data.teams.filter((a) => selectedAssignees
                .filter((a) => a.groupID === IParticipantGroup.Team)
                .map((it) => it.id)
                .includes(a.id)));
            ctx.model.filter.setCallTakerTeams(getTeamsQuery.data.teams.filter((a) => selectedCallTakers
                .filter((a) => a.groupID === IParticipantGroup.Team)
                .map((it) => it.id)
                .includes(a.id)));
        }
        onApply === null || onApply === void 0 ? void 0 : onApply();
        trackerService.track('[Review Queue] advanced filter applied', {
            status: selectedStatuses,
            selectedAssignee: selectedAssignees,
            selectedCallTaker: selectedCallTakers,
        });
    }
    function handleClear() {
        setSelectedStatuses([]);
        setSelectedAssignees([]);
        setSelectedCallTakers([]);
        onClear === null || onClear === void 0 ? void 0 : onClear();
    }
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "auto", gap: 5 },
        React.createElement(Autocomplete, { "data-cy": "status-input", multiple: true, options: statusOptions.current, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option, value) => isEqual(option, value), value: selectedStatuses, onChange: (_, value) => setSelectedStatuses(value), disableCloseOnSelect: true, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('caseReviewRequestsModule:advancedFilters.status', 'Review status') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.text)), ChipProps: {
                size: 'small',
            } }),
        React.createElement(Autocomplete, { "data-cy": "assignee-input", loading: getTeamsQuery.loading, loadingText: intl.t('caseReviewRequestsModule:loading', 'Loading'), multiple: true, options: participantOptions, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option, value) => isEqual(option, value), groupBy: (option) => option.groupText, value: selectedAssignees, onChange: (_, value) => setSelectedAssignees(value), disableCloseOnSelect: true, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('caseReviewRequestsModule:advancedFilters.assignee', 'Reviewer') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.text)), ChipProps: {
                size: 'small',
            } }),
        React.createElement(Autocomplete, { "data-cy": "call-taker-input", loading: getTeamsQuery.loading, loadingText: intl.t('caseReviewRequestsModule:loading', 'Loading'), multiple: true, options: participantOptions, getOptionLabel: (option) => option.text, isOptionEqualToValue: (option, value) => isEqual(option, value), groupBy: (option) => option.groupText, value: selectedCallTakers, onChange: (_, value) => setSelectedCallTakers(value), disableCloseOnSelect: true, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('caseReviewRequestsModule:advancedFilters.callTaker', 'Call Taker') }))), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected, key: option.id }), option.text)), ChipProps: {
                size: 'small',
            } }),
        React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 3 },
            React.createElement(Button, { size: "small", color: "primary", variant: "text", onClick: handleClear }, intl.t('caseReviewRequestsModule:clear', 'Clear')),
            React.createElement(Button, { size: "small", color: "primary", variant: "text", onClick: handleApply }, intl.t('caseReviewRequestsModule:apply', 'Apply')))));
});
