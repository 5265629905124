import AddRounded from '@mui/icons-material/AddRounded';
import { sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, Chip, Typography } from 'lib/cortiUI';
import { TimelineEntryDefinitionSourceType, } from '../../../types';
import { CY_SELECTORS, getTimelineEntryDefinitionsTitles } from '../../utils';
import { TimelineEntryDefinitionPicker } from '../TimelineEntryDefinitionPicker';
import { FieldOption } from './FieldOption';
export const RadioField = ({ choices, acceptedTimelineEntryDefinitionSources, timelineEntryDefinitions, onChoiceInputValueChange, onTimelineEntryDefsHintsValueChange, onAddChoice, onRemoveChoice, onAcceptedTimelineEntryDefinitionsToFieldChange, }) => {
    const { t } = useTranslation('libFeedbackFormManager');
    const [acceptedDefinitionsTitles, setAcceptedDefinitionsTitles] = React.useState([]);
    React.useEffect(() => {
        if (acceptedTimelineEntryDefinitionSources && acceptedTimelineEntryDefinitionSources.length) {
            const titles = getTimelineEntryDefinitionsTitles(acceptedTimelineEntryDefinitionSources, timelineEntryDefinitions);
            setAcceptedDefinitionsTitles(titles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timelineEntryDefinitions.length]);
    const callAnnotationsTimelineEntryDefinitions = sortBy([...timelineEntryDefinitions].filter((def) => def.source.type === TimelineEntryDefinitionSourceType.callAnnotation), (it) => it.title);
    const handleSetDefinitionsTitles = (titles) => setAcceptedDefinitionsTitles(titles);
    const handleAcceptSelectedTimelineEntryDefinitions = (definitionsSources) => {
        onAcceptedTimelineEntryDefinitionsToFieldChange(definitionsSources);
        const titles = getTimelineEntryDefinitionsTitles(definitionsSources, timelineEntryDefinitions);
        handleSetDefinitionsTitles(titles);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, { mb: 5 },
            React.createElement(Typography, { variant: "body1", color: "default" }, t('fieldWidget.manualOptions', 'Manual options'))),
        choices &&
            choices.map((c, i) => {
                var _a;
                return (React.createElement(FieldOption, { key: c.value, valueID: c.value, textValue: c.text, label: `${t('fieldWidget.option', 'Option')} ${i + 1}`, timelineEntryDefinitions: timelineEntryDefinitions, selectedTimelineEntryDefinitionsForHints: (_a = c.timelineEntryDefinitionSourceHints) !== null && _a !== void 0 ? _a : [], onTimelineEntryDefsHintsValueChange: onTimelineEntryDefsHintsValueChange, onChoiceInputValueChange: onChoiceInputValueChange, onRemoveChoice: onRemoveChoice }));
            }),
        React.createElement(Base, { width: 1, mb: 6, mt: 5 },
            React.createElement(Button, { startIcon: React.createElement(AddRounded, { fontSize: "small", color: "primary" }), variant: "text", color: "primary", size: "small", onClick: onAddChoice, "data-cy": CY_SELECTORS.fieldWidget.addOptionBtn }, t('fieldWidget.addChoiceBtn', 'Add manual option'))),
        React.createElement(Base, { width: 1, py: 2, display: "flex", flexDirection: "column", alignItems: "left" },
            React.createElement(Base, { mb: 5 },
                React.createElement(Typography, { variant: "body1", color: "default" }, t('fieldWidget.autodetectedOptions', 'Autodetected options'))),
            acceptedDefinitionsTitles.length > 0 && (React.createElement(Base, { display: "flex", flexWrap: "wrap" }, acceptedDefinitionsTitles.map((title, i) => (React.createElement(Base, { mb: 5, mr: i < acceptedDefinitionsTitles.length - 1 ? 3 : undefined, key: `${title}${i}` },
                React.createElement(Chip, { label: title, "data-cy": CY_SELECTORS.fieldWidget.selectedDefinitionChip })))))),
            React.createElement(Base, { mt: 6 },
                React.createElement(TimelineEntryDefinitionPicker, { timelineEntryDefinitions: callAnnotationsTimelineEntryDefinitions, selectedValues: acceptedTimelineEntryDefinitionSources, onChange: handleAcceptSelectedTimelineEntryDefinitions, tooltipTitle: t('actionsHints.selectAcceptableTimelineEntryDefinitions', 'Select acceptable timeline entry definitions'), renderDefaultTrigger: (onTriggerClick) => (React.createElement(Button, { startIcon: React.createElement(AddRounded, { fontSize: "small", color: "primary" }), variant: "text", color: "primary", size: "small", onClick: onTriggerClick, "data-cy": CY_SELECTORS.fieldWidget.selectAcceptedTimelineEntryDefsBtn }, t('fieldWidget.setAutodetectedOptionsBtn', 'Set autodetected options'))) })))));
};
