import React from 'react';
import { Base } from 'lib/cortiUI';
import { GlobalFilters } from './GlobalFilters';
import { Parameters } from './Parameters';
export const Toolbar = () => {
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr auto", gap: 6, alignItems: "center" },
        React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr auto", gap: 3, alignItems: "center" },
            React.createElement(Parameters, null)),
        React.createElement(GlobalFilters, null)));
};
