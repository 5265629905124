import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Typography } from 'lib/cortiUI';
import { TrackersAccordion } from '../../../components';
import { TrackersConfigModalContentLoader } from './TrackersConfigModalContentLoader';
export const TrackersGroupsList = ({ isLoading, metricSourcesList, onGroupCheckboxClick, children, }) => {
    const { t } = useTranslation('performanceApp');
    const data = getComplianceMetricsSourcesMatrix();
    function getComplianceMetricsSourcesMatrix() {
        if (!metricSourcesList) {
            return null;
        }
        const entries = Object.entries(metricSourcesList);
        return entries
            .sort(([titleA], [titleB]) => titleA.localeCompare(titleB))
            .map(([entryTitle, sources]) => [
            entryTitle,
            sources.sort((a, b) => a.text.localeCompare(b.text)),
        ]);
    }
    if (isLoading) {
        return React.createElement(TrackersConfigModalContentLoader, null);
    }
    if (!data) {
        return (React.createElement(Typography, null, t('trackersConfiguration.noTrackers', "Couldn't fetch available trackers.")));
    }
    return (React.createElement(React.Fragment, null, data.map(([title, items]) => {
        const isGroupSelected = metricSourcesList
            ? metricSourcesList[title].every((it) => it.isEnabled)
            : false;
        const isAnySelected = metricSourcesList
            ? metricSourcesList[title].some((it) => it.isEnabled)
            : false;
        return (React.createElement(TrackersAccordion, { key: title, title: title, indeterminate: isAnySelected && !isGroupSelected, isSelected: isGroupSelected, onCheckboxClick: onGroupCheckboxClick(title) }, children(items)));
    })));
};
